import React from "react";
import { Typography, Box } from '@mui/material';
import PickStatsCard from "./PickStatsCard/PickStatsCard";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const GameStats = ({
    leaderboard,
    teams,
    schedule,
    games,
    pageInterval,
    teamsOrNames,
    rounds,
    getPickStatsGameInfo,
    getPickStatsPickInfo,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("Games: ", games, "Schedule: ", schedule);
    //const gameIdsThisInterval =[]
    const gameIdsThisInterval = games.filter(game => game.round === pageInterval && game.GlobalGameID).map(game => game.GlobalGameID);
    //console.log("Game IDs This Interval: ", gameIdsThisInterval);

    const thisIntervalsName = rounds.find(round => round.round === pageInterval).name;
    //console.log("This Interval's Name: ", thisIntervalsName);

   

    return (
        <>
            {gameIdsThisInterval?.length === 0 && (
                <Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '20px' : '24px',
                            fontWeight: '700',
                            marginTop: '24px',
                            padding: '0 16px',
                        }}
                    >
                       {thisIntervalsName} Game Stats will be <br /> revealed when the matchups are set.
                    </Typography>
                </Box>
            )}
        
            {gameIdsThisInterval?.length > 0 && (  
                <>
                    <Box>
                        {gameIdsThisInterval?.map(gameId => {
                            const gameInfo = getPickStatsGameInfo(gameId, schedule, teams, teamsOrNames);
                            //console.log("This Has Game Started!!: ", gameInfo?.hasGameStarted);
                            const pickInfo = getPickStatsPickInfo(gameId, gameInfo.awayTeamId, gameInfo.homeTeamId, leaderboard);
                            return (
                                <PickStatsCard 
                                    key={gameId}
                                    gameInfo={gameInfo}
                                    pickInfo={pickInfo}
                                    thisIntervalsName={thisIntervalsName}
                                />
                            );
                        })}
                    </Box>
                </>
            )}
        </>
    );
}

export default GameStats;