import React, { useState, useEffect } from 'react';
import noTeamLogo from '../../assets/images/no-team-logo.svg';

import './BracketBox.scss';

const BracketSelectionBox = ({game, pickState, setPickState, seriesLength, setSeriesLength, NBATeams, boxLineClass, bracketBoxCustom, bracketRightCustom, backBoxLine}) => {
    const [topTeamSelected, setTopTeamSelected] = useState(false);
    const [bottomTeamSelected, setBottomTeamSelected] = useState(false);

    function nbaGetLogo(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return noTeamLogo
      }
      const team = NBATeams.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        return team.WikipediaLogoUrl;
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }


    function nbaGetKey(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return globalTeamID
      }
      const team = NBATeams.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        if(team.Key.startsWith("LA")){
          return "LA";
        } else {
        return team.Key;
      }
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }

    function nbaGetName(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return ''
      }
      const team = NBATeams.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        if (team.Name.startsWith("Timber")) {
          return "T'Wolves";
        } else {
          return team.Name;
        }
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }

    useEffect(() => {
      // Check if the top team is selected in the pickState
      const isTopTeamPicked = pickState.some(pick => pick.id === game.topTeamID && pick.round === game.round && game.conference === pick.conference);
      setTopTeamSelected(isTopTeamPicked);

      // Check if the bottom team is selected in the pickState
      const isBottomTeamPicked = pickState.some(pick => pick.id === game.bottomTeamID && pick.round === game.round && game.conference === pick.conference);
      setBottomTeamSelected(isBottomTeamPicked);
    }, [pickState, game]);

    useEffect(() => {
      // Check if the series length is set for the current game
      const seriesLengthForGame = seriesLength.find(entry =>
        entry.round === game.round &&
        entry.conference === game.conference &&
        entry.displayOrder === game.displayOrder
      );

      // If series length is set, update the dropdown menu
      if (seriesLengthForGame) {
        const dropdownId = `series-length-${game.round}-${game.conference}-${game.displayOrder}`;
        const dropdown = document.getElementById(dropdownId);
        if (dropdown) {
          dropdown.value = seriesLengthForGame.length;
        }
      }
    }, [seriesLength, game]);






    // Dummy logo URL
    const round = game.round;
    const conference = game.conference;
    const displayOrder = game.displayOrder;
    const topTeamID = game.topTeamID;
    const topTeamLogo = nbaGetLogo(topTeamID);
    const topTeamSeed = game.topTeamSeed;
    const topTeamKey = nbaGetKey(topTeamID);
    const topTeamNickname = nbaGetName(topTeamID);
    const topTeamName = `${topTeamKey} ${topTeamNickname} ${topTeamID === 'TBD'? conference : ''}`;
    const bottomTeamID = game.bottomTeamID;
    const bottomTeamLogo = nbaGetLogo(bottomTeamID);
    const bottomTeamSeed = game.bottomTeamSeed;
    const bottomTeamKey = nbaGetKey(bottomTeamID);
    const bottomTeamNickname = nbaGetName(bottomTeamID);
    const bottomTeamName = `${bottomTeamKey} ${bottomTeamNickname}  ${bottomTeamID === 'TBD'? conference : ''}`;







    const handleTopButtonClick = () => {
      // Check if topTeamID exists
      if (!topTeamID) {
        console.log("No top team ID available, ignoring the click");
        return;
    }

      setTopTeamSelected(true);
      setBottomTeamSelected(false);
      const pick = {
          id: topTeamID,
          seed: topTeamSeed,
          round: round,
          conference: conference,
          displayOrder: displayOrder
      };

      // Check if a pick for the same team already exists
      const existingPickIndex = pickState.findIndex(
          item =>
              item.round === round &&
              item.conference === conference &&
              item.displayOrder === displayOrder &&
              item.id === topTeamID
      );

      // If an existing pick for the same team is found, ignore the new click
      if (existingPickIndex !== -1) {
          console.log("Existing pick for the same team already exists, ignoring the new click");
          return;
      }

      // Check if a pick for another team already exists, which would then need to be removed
      const oppositeTeamPickIndex = pickState.findIndex(
          item =>
              item.round === round &&
              item.conference === conference &&
              item.displayOrder === displayOrder &&
              item.id !== topTeamID
      );

      if (oppositeTeamPickIndex !== -1) {
          console.log("Pick was already made for ANOTHER TEAM", oppositeTeamPickIndex, pick.id, pickState[oppositeTeamPickIndex].id, round);
      }

      // Declare filteredPickState outside of the if statement
      let filteredPickState = pickState;

      // If a pick for another team exists, filter the pickState array to remove the existing pick for the current game
      if (oppositeTeamPickIndex !== -1) {
        filteredPickState = pickState.filter(item =>
            (item.round !== round || item.conference !== conference || item.displayOrder !== displayOrder) &&
            !(item.id === pickState[oppositeTeamPickIndex].id && item.round > round) // Exclude the opposite team's future games
        );
        }


      console.log("Filtered Pick State:", filteredPickState, "Round:", round);

      // Add the new pick to the state
      setPickState([...filteredPickState, pick]);
      console.log("New pickState:", [...filteredPickState, pick]);
      };


      const handleSeriesLengthChange = (option) => {
        // Check if there's an existing entry for the same round, conference, and displayOrder with a different length
        const existingEntryIndex = seriesLength.findIndex(entry =>
          entry.round === game.round &&
          entry.conference === game.conference &&
          entry.displayOrder === game.displayOrder &&
          entry.length !== option
        );

        if (existingEntryIndex !== -1) {
          // If an entry already exists with a different length, update its length
          const updatedSeriesLength = [...seriesLength];
          updatedSeriesLength[existingEntryIndex].length = option;
          setSeriesLength(updatedSeriesLength);
          console.log("Updated Series Length:", updatedSeriesLength);
        } else {
          // If no entry exists, add a new entry
          const newGame = {
            round: game.round,
            conference: game.conference,
            displayOrder: game.displayOrder,
            length: option
          };
          const updatedSeriesLength = [...seriesLength, newGame];
          setSeriesLength(updatedSeriesLength);
          console.log("New Game Added:", newGame);
          console.log("Updated Series Length:", updatedSeriesLength);
        }
      };




    const handleBottomButtonClick = () => {
      // Check if bottomTeamID exists
      if (!bottomTeamID) {
        console.log("No bottom team ID available, ignoring the click");
        return;
    }

      setTopTeamSelected(false);
      setBottomTeamSelected(true);

      const pick = {
          id: bottomTeamID,
          seed: bottomTeamSeed,
          round: round,
          conference: conference,
          displayOrder: displayOrder
      };

      // Check if a pick for the same team already exists
      const existingPickIndex = pickState.findIndex(
          item =>
              item.round === round &&
              item.conference === conference &&
              item.displayOrder === displayOrder &&
              item.id === bottomTeamID
      );

      // If an existing pick for the same team is found, ignore the new click
      if (existingPickIndex !== -1) {
          console.log("Existing pick for the same team already exists, ignoring the new click");
          return;
      }

      // Check if a pick for another team already exists, which would then need to be removed
      const oppositeTeamPickIndex = pickState.findIndex(
          item =>
              item.round === round &&
              item.conference === conference &&
              item.displayOrder === displayOrder &&
              item.id !== bottomTeamID
      );

      if (oppositeTeamPickIndex !== -1) {
          console.log("Pick was already made for ANOTHER TEAM", oppositeTeamPickIndex, pick.id, pickState[oppositeTeamPickIndex].id, round);
      }

      // Declare filteredPickState outside of the if statement
      let filteredPickState = pickState;

      // If a pick for another team exists, filter the pickState array to remove the existing pick for the current game
      if (oppositeTeamPickIndex !== -1) {
        filteredPickState = pickState.filter(item =>
            (item.round !== round || item.conference !== conference || item.displayOrder !== displayOrder) &&
            !(item.id === pickState[oppositeTeamPickIndex].id && item.round > round) // Exclude the opposite team's future games
        );
      }

      console.log("Filtered Pick State:", filteredPickState, "Round:", round);

      // Add the new pick to the state
      setPickState([...filteredPickState, pick]);
      console.log("New pickState:", [...filteredPickState, pick]);
      };


      useEffect(() => {
        const isTopTeamPicked = pickState.some(pick => pick.id === game.topTeamID && pick.round === game.round);
        const isBottomTeamPicked = pickState.some(pick => pick.id === game.bottomTeamID && pick.round === game.round);

        // Update state to deselect the radio button if the corresponding game is not in pickState
        if (!isTopTeamPicked && topTeamSelected) {
          setTopTeamSelected(false);
        }
        if (!isBottomTeamPicked && bottomTeamSelected) {
          setBottomTeamSelected(false);
        }
      }, [pickState, game, topTeamSelected, bottomTeamSelected]);







return (
  <div className={`bracket-game-container ${bracketBoxCustom} ${bracketRightCustom}`}>
    <div className={backBoxLine}></div>
    <div className="bracketBox">
      <div className="team-section" onClick={handleTopButtonClick}>

        {/* Top Actual Team */}
        <div className="bracket-team-container">
          {topTeamLogo && (
            <>
              <img className="team-logo-img" src={topTeamLogo} alt="Team Logo" width="16" height="16" />
              <span className="team-seed" style={{ fontWeight: topTeamSelected ? 'bold' : 'normal' }}>{topTeamSeed}</span>
              <span className="team-name" style={{ fontWeight: topTeamSelected ? 'bold' : 'normal' }}>{topTeamName}</span>
            </>
          )}
          {!topTeamLogo && (
            <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9" />
            </svg>
          )}
           {topTeamID && ( // Render the radio button only if topTeamID exists
          <input type="radio" checked={topTeamSelected} onChange={() => {}} />
        )}
        </div>
      </div>
       {/* Dropdown menu */}
       <div className="dropdown">
        {/* <label htmlFor={`series-length-${game.round}-${game.conference}-${game.displayOrder}`}>Series Length:</label> */}
        <select id={`series-length-${game.round}-${game.conference}-${game.displayOrder}`} className="dropdown-select" onChange={(e) => handleSeriesLengthChange(e.target.value)}>
          <option value="">Predicted Series Length</option>
          <option value="4">4 Games</option>
          <option value="5">5 Games</option>
          <option value="6">6 Games</option>
          <option value="7">7 Games</option>
        </select>
      </div>
      <div className="team-section" onClick={handleBottomButtonClick}>

        {/* Bottom Actual Team */}
        <div className="bracket-team-container">
          {bottomTeamLogo && (
            <>
              <img className="team-logo-img" src={bottomTeamLogo} alt="Team Logo" width="16" height="16" />
              <span className="team-seed" style={{ fontWeight: bottomTeamSelected ? 'bold' : 'normal' }}>{bottomTeamSeed}</span>
              <span className="team-name" style={{ fontWeight: bottomTeamSelected ? 'bold' : 'normal' }}>{bottomTeamName}</span>
            </>
          )}
          {!bottomTeamLogo && (
            <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9" />
            </svg>
          )}
          {bottomTeamID && ( // Render the radio button only if bottomTeamID exists
            <input type="radio" checked={bottomTeamSelected} onChange={() => {}} />
          )}
        </div>

      </div>


    </div>
    <div className={boxLineClass}></div>
  </div>
);
};

  export default BracketSelectionBox;
