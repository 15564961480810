import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";

const SuperPickemBracketSubmitModal = ({ modalOpen, setModalOpen, checkerData, logo, countdownDeadline }) => { 
    const success = checkerData?.success;
    const totalPicksMade = checkerData?.totalPicksMade;
    const totalPicksToMake = checkerData?.totalPicksToMake;
    const error = checkerData?.error;

    console.log("Countdown Deadline: ", countdownDeadline);
    const formattedDate = DateTime.fromISO(countdownDeadline, { zone: 'America/New_York' }).toFormat("ccc LLL d '@' h:mm a 'ET'");


    return(
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{ height: 'auto', width: '308px' }}>
                {totalPicksMade > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '15px' }}>
                            {totalPicksMade} Pick{totalPicksMade > 1 ? 's' : ''} Submitted
                        </Typography>
                        
                        <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginBottom: '20px' }} />    
                </>
                )}
                
                
                <Typography variant="h1" style={{ fontSize: '16px' }}>
                    <span style={{ fontWeight: '700', color: totalPicksMade === totalPicksToMake ? '#00AA72' : '#CC293C' }}>
                        {totalPicksMade}/{totalPicksToMake}&nbsp;
                    </span> 
                    Picks Submitted
                </Typography>
                <Typography
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    The bracket locks on <br/> {formattedDate}
                </Typography>
                <img src={logo} alt="logo" style={{ width: '80px', margin: '0 auto', marginTop: '20px' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button sx={{ width: '115px'}} variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </div>
            </div>
        </Modal>
    )
}

export default SuperPickemBracketSubmitModal;