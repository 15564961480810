import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getTeamLogo } from '../../../../utils/SportsData/index.js';
import './BracketGamesStyles.scss';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const BracketSingleEntryView = ({ 
    entry, 
    totalEntriesCount, 
    hasFirstGameStarted, 
    picksToMake,
    league,
    teams,
    haveAllPicksBeenMade,
    findChampId,
    findChampResult, 
    setImageError,
    setImageLoading,
    handleImageError,
    handleImageLoad,
    getChampBoxStyle,
    imageError,
    imageLoading,
    findMyRank,
    findMyPoints,
    findMyMaxPossiblePoints,
    myLeaderboardInfo,
    hasJoinDeadlinePassed,
    setIndexToRemove,
    setRemoveModalOpen,
    isThisPoolPartyGame,
    pickIndex,
    isUserAdmin,
    tiebreakerCount,
}) => {
    //console.log('Entry:', entry);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    // Get champion ID once to reuse
    const champId = entry?.pool?.picks ? findChampId(entry?.pool?.picks) : null;

    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    };

    return (
        <Box>
            <Box className="bracket-single-entry-container">
                <Box className="bracket-single-entry-content">
                    <Box className="bracket-single-entry-column">
                        <Typography className="bracket-single-entry-header-text">
                            Rank
                        </Typography>
                        <Typography className="bracket-single-entry-text">
                            {findMyRank(myLeaderboardInfo, entry?.name)} of {totalEntriesCount}
                        </Typography>
                    </Box>

                    <Box className="bracket-single-entry-column">
                        <Typography className="bracket-single-entry-header-text">
                            Points
                        </Typography>
                        <Typography className="bracket-single-entry-text">
                            {findMyPoints(myLeaderboardInfo, entry?.name)}
                        </Typography>
                    </Box>

                    {!hasFirstGameStarted && (
                    <Box className="bracket-single-entry-column">
                        <Typography className="bracket-single-entry-header-text">
                        Picks In?
                        </Typography>
                        <Typography className="bracket-single-entry-text">
                        {haveAllPicksBeenMade(entry?.pool, picksToMake, tiebreakerCount) 
                            ? <CheckIcon sx={{ color: '#00AA72', fontSize: isMobile ? '24px' : '40px'}} /> 
                            : <CloseIcon sx={{ color: '#CC293C', fontSize: isMobile ? '24px' : '40px'}} />
                        }
                        </Typography>
                    </Box>
                    )}

                    {hasFirstGameStarted && (
                        <Box className="bracket-single-entry-column">
                            <Typography className="bracket-single-entry-header-text">
                            Max Points
                            </Typography>
                            <Typography className="bracket-single-entry-text">
                            {findMyMaxPossiblePoints(myLeaderboardInfo, entry?.name)}
                            </Typography>
                        </Box>
                    )}

                    <Box className="bracket-single-entry-column">
                        <Typography className="bracket-single-entry-header-text">
                            Champ
                        </Typography>
                        <Box 
                            className="bracket-single-entry-champ-box"
                            sx={{
                                ...getChampBoxStyle(findChampResult(entry?.pool?.picks)),
                                border: (champId && isNaN(champId)) ? 'none' : '1px solid #002129',
                                backgroundColor: (champId && isNaN(champId)) ? 'transparent' : 'none',
                            }}
                        >
                            {champId && (
                                <>
                                    {isNaN(champId) ? (
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                textAlign: "center",
                                                color: "#002129",
                                            }}
                                        >
                                            {champId}
                                        </Typography>
                                    ) : (
                                        <>
                                            {imageLoading && (
                                                <CircularProgress
                                                    size={16}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-8px',
                                                        marginLeft: '-8px'
                                                    }}
                                                />
                                            )}
                                            <img 
                                                src={getTeamLogo(league, champId, teams)}
                                                onLoad={handleImageLoad}
                                                onError={handleImageError}
                                                alt="Champion team logo"
                                                style={{ 
                                                    maxWidth: '100%', 
                                                    maxHeight: '100%', 
                                                    objectFit: 'contain',
                                                    opacity: imageLoading ? 0 : 1,
                                                    display: imageError ? 'none' : 'block'
                                                }}
                                            />
                                            {imageError && (
                                                <Typography 
                                                    variant="caption" 
                                                    sx={{ 
                                                        fontSize: '10px',
                                                        color: '#666'
                                                    }}
                                                >
                                                    !
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!hasJoinDeadlinePassed && !isThisPoolPartyGame && !isUserAdmin && (
                <Typography 
                    onClick={() => handleRemovedClick(Number(pickIndex))}
                    sx={{
                        color: "#CC293C",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: "500",
                        width: '100%',
                        textAlign: 'center',
                        paddingBottom: '10px'
                    }}
                >
                    Remove
                </Typography>
            )}
        </Box>
    );
};

export default BracketSingleEntryView;