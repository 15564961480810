import React from "react";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./bracketHeaderNav.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {gamePicksSave} from '../../../redux/actions/gameImport.js';
import TiebreakerModal from "../TiebreakerModal";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { DateTime } from "luxon";
import { set } from "lodash";

const BracketHeader = (props) => {
  //console.log("BracketHeader props:", props);
  let userData = getUserDataFromLocalStorage();
  const tiebreaker = userData?.Games?.find(game => game.pool?.gameName === "NBA Playoffs")?.pool?.tiebreaker;
  //console.log("Tiebreaker:", tiebreaker);
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  //console.log("BracketHeader userData:", userData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let LeaderboardInfo = props?.leaderboardInfo?.data;
  //console.log(LeaderboardInfo);
  const leaderboardUserData = LeaderboardInfo?.results;
  //console.log("LEADERBOARD USER DATA", leaderboardUserData);

  const isUserInGame = userData?.Games?.find(game => game.pool?.gameName === "NBA Playoffs");
  const [tiebreakerOpen, setTiebreakerOpen] = useState(false);
  const [tiebreakerValue, setTiebreakerValue] = useState('');
  const doesUserHaveTiebreaker = userData?.Games?.find(game => game.pool?.gameName === "NBA Playoffs")?.pool?.tiebreaker;
  const [tiebreakerError, setTiebreakerError] = useState(false);
  const [message, setMessage] = useState('');

  // Target date and time: Thursday, June 6 at 8:30 pm EST
  const targetDateTime = DateTime.fromObject(
    { year: 2024, month: 6, day: 14, hour: 20, minute: 30 },
    { zone: 'America/New_York' }
  );

  // Current date and time in EST
  const now = DateTime.now().setZone('America/New_York');

  // Check if it's before the target date and time
  const isBeforeTargetDateTime = now <= targetDateTime;

  useEffect(() => {
    if (!doesUserHaveTiebreaker && isUserInGame && isBeforeTargetDateTime) {
      setTiebreakerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (doesUserHaveTiebreaker) {
      setTiebreakerValue(tiebreaker);
    }
    }, []);

  function nbaGetLogo(globalTeamID) {
    const team = props?.NBATeams?.find(
      (team) => team.GlobalTeamID === globalTeamID
    );
    if (team) {
      return team.WikipediaLogoUrl;
    } else {
      return null;
    }
  }

  const nbaGamesByDate = props?.nbaGamesByDate;
  //console.log("NBA Games By Date:", nbaGamesByDate);

  const today = DateTime.now().setZone('America/New_York');

function formatDate(dateTime) {
  return dateTime.toFormat('yyyy-MM-dd');
}
  function areThereGamesToday() {
    const formattedToday = formatDate(today);
    // Check if there are games today and their status is not "NotNecessary"
    return nbaGamesByDate?.some(game => game.Day.startsWith(formattedToday) && game.Status !== "NotNecessary");
  }

  const gamesToday = areThereGamesToday();
  console.log("NBA Games By Date: Games Today:", nbaGamesByDate, gamesToday, today);

  const leaderboardData = [];

  if (leaderboardUserData && typeof leaderboardUserData === "object") {
    // Iterate over the values of leaderboardUserData object
    Object.values(leaderboardUserData).forEach((info) => {
      const champInfo = info?.bracketPicks.find((entry) => entry.round === 4);
      const champID = champInfo?.id;
      const champResult = champInfo?.Result;
      const champLogo = nbaGetLogo(champID);
      const champBoxColor =
        champResult === "Win"
          ? "#00AA72"
          : champResult === "Loss"
          ? "#CC293C"
          : null;
      const player = info?.user_name; // Fixed username typo
      const overallRank = info?.stats?.overallRank; // Accessing overallRank from stats object
      const totalPoints = info?.stats?.totalOverallPoints; // Accessing totalOverallPoints from stats object
      const bracketPoints = info?.stats?.totalBracketPoints; // Accessing totalBracketPoints from stats object
      const pickEmPoints = info?.stats?.onlyDailyPoints; // Accessing onlyBracketPicksPoints from stats object
      const bracketRank = info?.stats?.bracketRank; // Accessing bracketRank from stats object
      const pickEmRank = info?.stats?.dailyPicksRank; // Accessing onlyBracketPicksRank from stats object

      // Push the transformed data for the current entry into the leaderboardData array
      leaderboardData.push({
        overalLRank: overallRank,
        player: player,
        champLogo: champLogo,
        champBoxColor: champBoxColor,
        totalPoints: totalPoints,
        bracketPoints: bracketPoints,
        pickEmPoints: pickEmPoints,
        bracketRank: bracketRank,
        pickEmRank: pickEmRank,
      });
    });
  }

  //console.log("Leaderboard Data:", leaderboardData);

  function doesUserHaveAPickForSelectedDay() {
    if (!userData?.Games) {
      return false;
    }
    // Find the user's pick for the selected day
    let Games = userData?.Games;
    let selectedPick = null;
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 5); // EST is UTC-5
    let formattedDate = currentDate.toISOString().split("T")[0];
    //console.log(formattedDate);
    //console.log("doesUserHaveAPickForSelectedDay", Games, currentDate);

    // Iterate over Games to find the first matching pick
    Games.some((game) => {
      // Find the pick that matches the date
      const pick = game?.pool?.dailyPicks?.find((pick) =>
        pick.dateTime.startsWith(formattedDate)
      );
      if (pick) {
        selectedPick = pick;
        return true; // Stop the iteration once a matching pick is found
      }
      return false;
    });

    return selectedPick;
  }

  const getDuplicateOverallRanks = () => {
    const countMap = {};
    leaderboardData?.forEach((entry) => {
      const rank = entry?.overalLRank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicateRanks = Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));

    return duplicateRanks;
  };

  const duplicateOverallRanks = getDuplicateOverallRanks();
  //console.log("Duplicate overall ranks:", duplicateOverallRanks);

  const isUserInDuplicateRanks = (overalLRank) => {
    return duplicateOverallRanks?.includes(overalLRank);
  };
  const getDuplicateBracketRanks = () => {
    const countMap = {};
    leaderboardData?.forEach((entry) => {
      const rank = entry?.bracketRank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicateBracketRanks = Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));

    return duplicateBracketRanks;
  };

  const duplicateBracketRanks = getDuplicateBracketRanks();
  //console.log("Duplicate bracket ranks:", duplicateBracketRanks);

  const isUserinDuplicateBracketRanks = (bracketRank) => {
    return duplicateBracketRanks?.includes(bracketRank);
  };

  const getDuplicatePickEmRanks = () => {
    const countMap = {};
    leaderboardData?.forEach((entry) => {
      const rank = entry?.pickEmRank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicatePickEmRanks = Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));

    return duplicatePickEmRanks;
  };

  const duplicatePickEmRanks = getDuplicatePickEmRanks();
  //console.log("Duplicate pickEm ranks:", duplicatePickEmRanks);

  const isUserInDuplicatePickEmRanks = (pickEmRank) => {
    return duplicatePickEmRanks?.includes(pickEmRank);
  };

  function getLoggedInUserRank() {
    // Get the user object from the data array
    const user = leaderboardData?.find(
      (entry) => entry.player === userData.user_name
    );
    return user;
  }

  const userDashCheck = getLoggedInUserRank();
    const dailyPickCheck = doesUserHaveAPickForSelectedDay();
    const doesUserHaveDashInfo = userDashCheck ? true : false;
    //console.log("Logged In User Rank:", userDashCheck, dailyPickCheck);
    //console.log("Does User Have Dash Info:", doesUserHaveDashInfo);
    let loggedInUserRank = null;
    const player = userData.user_name;
    if (userDashCheck) {
        loggedInUserRank = userDashCheck;
    } else {
        loggedInUserRank = {
            "overalLRank": 'N/A',
            "player": player,
            "champLogo": '',
            "champBoxColor": "",
            "totalPoints": 'N/A',
            "bracketPoints": '',
            "pickEmPoints": '',
            "bracketRank": '',
            "pickEmRank": ''
        };
    }
  
    const handleChange = (event) => {
      const { value } = event.target;
      // Ensure the value is only digits and has at most 3 digits
      if (/^\d{0,3}$/.test(value)) {
          setTiebreakerValue(value);
      }
    };

    const handleTiebreakerSave = async () => {
      console.log("Tiebreaker Save Button Hit");
      let userPicks = {
        gameType: "SuperPickem",
        tiebreaker: tiebreakerValue,
        gameName: "NBA Playoffs",
        gameId: gameId// Use existing gameId or generate a new one if not present
      };
      console.log("User Picks: ", userPicks);

      if (!isBeforeTargetDateTime) {
        console.log("Cannot save picks after the target date and time");
        setTiebreakerError(true);
        setMessage("Sorry, the deadline has passed.")
        setTimeout(() => {
          window.location.reload();
        }
        , 3000);
        return;
      }


      try {
        const response = await dispatch(gamePicksSave(userPicks));
        console.log("Picks saved successfully:", response);
        if (!gameId) { // Check if it's a new game creation scenario
          console.log("New game created with ID:", userPicks.gameId);
          navigateToTop(`/nba-super-pickem/SuperPickem/${userPicks.gameId}`, { replace: true });
          window.location.reload(); // Force reload of the page
        } else {
          console.log("Updated existing game");
          window.location.reload(); // Force reload of the page
        }
      } catch (error) {
        console.error("Failed to save picks:", error);
        // Optionally handle error, e.g., show a notification
      }

    };



  return (
    <>
    <Box className={"bracket-box-header"}>
            <Box className={"bracket-box-header-trophy"}>
                <img className="bracket-box-trophy" src={bracketTrophy} alt='' />
                <Typography className={"bracket-box-header-username"} variant='h1'>
                    {userData.user_name}
                    {tiebreaker && (
                        <>
                            <Typography component="span" variant="h6" style={{ fontSize: isMobile ? '10px' : '20px', marginLeft: '8px' }}>
                                (Tiebreaker: {tiebreaker} pts)
                            </Typography>
                            {isBeforeTargetDateTime &&
                              <Typography
                                  component="span"
                                  variant="h6"
                                  style={{
                                      fontSize: isMobile ? '10px' : '20px',
                                      marginLeft: '8px',
                                      color: 'blue',
                                      textDecoration: 'underline',
                                      cursor: 'pointer'
                                  }}
                                  onClick={() => setTiebreakerOpen(true)}
                              >
                                  Edit
                              </Typography>
                            }
                        </>
                    )}
                </Typography>
            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              maxWidth: '1102px',
              margin: '0 auto',
              width:'90%'
            }}>
              {isMobile ? (
                // These items will be displayed on mobile devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>RANK</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{isUserInDuplicateRanks(loggedInUserRank?.overalLRank) ? 'T' : ''}{loggedInUserRank?.overalLRank}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>CHAMP</Typography>
                    {doesUserHaveDashInfo ? (

                      <img style={{display:'block', width:"24px", margin: '0 auto', border: `2px solid ${loggedInUserRank?.champBoxColor}`,}} src={loggedInUserRank?.champLogo} alt="TEAM LOGO"/>

                    ) : (
                    <div style={{fontWeight: 800, color: '#4A4A4A', marginTop: '-1px'}}>N/A</div>
                    )}
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>TOTAL</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{loggedInUserRank?.totalPoints}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>BRACKET</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{fontSize:'15px', fontWeight: '500'}}>
                        {!doesUserHaveDashInfo ? (
                            <span style={{ fontWeight: 800 }}>N/A</span>
                        ) : (
                            isUserinDuplicateBracketRanks(loggedInUserRank?.bracketRank) ? (
                                <>
                                    <span style={{ fontWeight: 800 }}>{loggedInUserRank?.bracketPoints}</span>
                                    {' '}
                                    <span style={{ fontWeight: 500, fontSize: '12px' }}> (T{loggedInUserRank?.bracketRank})</span>
                                </>
                            ) : (
                                <>
                                    <span style={{ fontWeight: 800 }}>{loggedInUserRank?.bracketPoints}</span>
                                    {' '}
                                    <span style={{ fontWeight: 500, fontSize: '12px' }}> ({loggedInUserRank?.bracketRank})</span>
                                </>
                            )
                        )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>PICK ‘EM</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{fontSize:'15px', fontWeight: '500'}}>
                        {!doesUserHaveDashInfo ? (
                            <span style={{ fontWeight: 800 }}>N/A</span>
                        ) : (
                            isUserInDuplicatePickEmRanks(loggedInUserRank?.pickEmRank) ? (
                                <>
                                    <span style={{ fontWeight: 800 }}>{loggedInUserRank?.pickEmPoints}</span>
                                    {' '}
                                    <span style={{ fontWeight: 500, fontSize: '12px' }}> (T{loggedInUserRank?.pickEmRank})</span>
                                </>
                            ) : (
                                <>
                                    <span style={{ fontWeight: 800 }}>{loggedInUserRank?.pickEmPoints}</span>
                                    {' '}
                                    <span style={{ fontWeight: 500, fontSize: '12px' }}> ({loggedInUserRank?.pickEmRank})</span>
                                </>
                            )
                        )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>PICK IN</Typography>
                    <Box className="bracket-box-header-values">
                        {!doesUserHaveDashInfo ? (
                            <span style={{ fontWeight: 800 }}>N/A</span>
                        ) : (
                            !gamesToday ? (
                                <Typography style={{ fontWeight: '800' }}>No Game</Typography>
                            ) : (
                                dailyPickCheck ? (
                                    <CheckIcon sx={{ color: '#00AA72', fontSize: 23 }} />
                                ) : (
                                    <CloseIcon sx={{ color: '#cc293c', fontSize: '19px' }} />
                                )
                            )
                        )}
                    </Box>
                  </Box>
                </>
              ) : (
                // These items will be displayed on desktop devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>RANK</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{isUserInDuplicateRanks(loggedInUserRank?.overalLRank) ? 'T' : ''}{loggedInUserRank?.overalLRank}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>CHAMP</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>
                    {doesUserHaveDashInfo ? (

                    <img style={{display:'block', width:"44px", margin: '0 auto', border: `2px solid ${loggedInUserRank?.champBoxColor}`,}} src={loggedInUserRank?.champLogo} alt="TEAM LOGO"/>

                    ) : (
                      <div style={{ fontWeight: 600 }}>N/A</div>
                    )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>TOTAL PTS</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{loggedInUserRank?.totalPoints}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>BRACKET PTS</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{!doesUserHaveDashInfo ? 'N/A' : (isUserinDuplicateBracketRanks(loggedInUserRank?.bracketRank) ? `${loggedInUserRank?.bracketPoints} (T${loggedInUserRank?.bracketRank})` : `${loggedInUserRank?.bracketPoints} (${loggedInUserRank?.bracketRank})`)}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>PICK ‘EM PTS</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{!doesUserHaveDashInfo ? 'N/A' : (isUserInDuplicatePickEmRanks(loggedInUserRank?.pickEmRank) ? `${loggedInUserRank?.pickEmPoints} (T${loggedInUserRank?.pickEmRank})` : `${loggedInUserRank?.pickEmPoints} (${loggedInUserRank?.pickEmRank})`)}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>PICKS IN</Typography>
                    <Box className="bracket-box-header-values">
                      {!doesUserHaveDashInfo ? (
                          <div style={{ fontWeight: 600, marginTop: '3px' }}>N/A</div>
                      ) : (
                          !gamesToday ? (
                              <Typography style={{ fontWeight: '600', fontSize: '28px', marginTop: '5px' }}>No Game</Typography>
                          ) : (
                              dailyPickCheck ? (
                                  <CheckIcon sx={{ color: '#00AA72', fontSize: '44px' }} />
                              ) : (
                                  <CloseIcon sx={{ color: '#cc293c', fontSize: '44px' }} />
                              )
                          )
                      )}
                  </Box>
                  </Box>
                </>
              )}
            </Box>
        </Box>
        {tiebreakerOpen && (
            <TiebreakerModal tiebreakerOpen={tiebreakerOpen} setTiebreakerOpen={setTiebreakerOpen} tiebreakerValue={tiebreakerValue} handleChange={handleChange} handleTiebreakerSave={handleTiebreakerSave} doesUserHaveTiebreaker={doesUserHaveTiebreaker} isBeforeTargetDateTime={isBeforeTargetDateTime} message={message} tiebreakerError={tiebreakerError}/>
        )}
      </>
    );
}

export default BracketHeader;