import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StaticPoolHeaderBar from '../../PoolHeaderBar/StaticPoolHeaderBar';
import SponsorPoolsCard from './SponsorPoolsCard';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetCommunityPoolsData } from '../../../hooks/pools/useGetCommunityPools';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const SponsoredPoolsPage = () => {
   
    const { mutate: fetchCommunityPoolsData, data: communityPoolsData } = useGetCommunityPoolsData();
    //console.log("Community Pools Data: ", communityPoolsData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [page, setPage] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [poolsFetched, setPoolsFetched] = useState(false);
    const [poolData, setPoolData] = useState([]);

    useEffect(() => {
            fetchCommunityPoolsData();
            setPoolsFetched(true);
    }, [fetchCommunityPoolsData, setPoolsFetched]);  

    // Process data after it's fetched
    useEffect(() => {
        // Only proceed if pools have been fetched and communityPoolsData exists
        if (poolsFetched && communityPoolsData) {
            // Filter sponsored games from the games array
            const sponsoredGames = communityPoolsData?.games?.filter(game => game?.sponsored) || [];
            
            // Check if randomization is needed - default to false if not specified
            const shouldRandomize = communityPoolsData?.randomize || false;
            
            if (shouldRandomize) {
                // Shuffle the array using Fisher-Yates algorithm
                const shuffledGames = [...sponsoredGames].sort(() => Math.random() - 0.5);
                setPoolData(shuffledGames);
            } else {
                // Keep original order
                setPoolData(sponsoredGames);
            }
        }
    }, [poolsFetched, communityPoolsData]);

    const types = ['All', ...new Set(poolData?.map(pool => pool.type))];
    
    // Filter pools by both category and search query
    const filteredPools = poolData?.filter(pool => {
        const matchesType = page === 'All' ? true : pool.type === page;
        const matchesSearch = !searchQuery || 
            (pool.name && pool.name.toLowerCase().includes(searchQuery.toLowerCase()));
        return matchesType && matchesSearch;
    });

    if (!communityPoolsData) {
        return <LoadingScreen />;
    }

    return (
        <>
            <StaticPoolHeaderBar
                navigationText={"Home"}
                headerText={"Community Pools"}
                linkForNavigation={"/"}
            />
            
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                    padding: '16px',
                }}
            >
                <img
                    src="https://poolpartyfilestorage.blob.core.windows.net/media/PPCommunityPoolsLogo.png"
                    alt="Community Pools"
                    style={{ 
                        height: isMobile ? '100px' : '200px', 
                        width: 'auto', 
                        objectFit: 'contain' ,
                        margin: '0 auto',
                        
                    }}
                />
            </Box>

            {types?.length > 2 && (
                <Box sx={{ width: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '100%',
                        margin: '15px auto',
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            px: 2,
                        }}>
                            {types?.map((tab) => (
                                <Box
                                    key={tab}
                                    component="span"
                                    onClick={() => setPage(tab)}
                                    sx={{
                                        display: 'block',
                                        padding: '12px 8px',
                                        borderRadius: '6px',
                                        backgroundColor: page === tab ? '#E8E8E8' : 'transparent',
                                        color: '#002129',
                                        fontSize: isMobile ? '12px' : '18px',
                                        fontWeight: '700',
                                        cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        marginRight: isMobile ? '8px' : '70px',
                                        '&:last-child': {
                                            marginRight: 0
                                        }
                                    }}
                                >
                                    {tab}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}

            {types?.length <= 2 && (
                <Box sx={{ height: '32px' }} />
            )}

            {/* Search Bar */}
            <Box sx={{ 
                width: '100%', 
                maxWidth: '600px', 
                margin: '0px auto 24px',
                padding: '0 16px'
            }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search pools by name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#002129' }} />
                            </InputAdornment>
                        ),
                        sx: {
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#002129',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#002129',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#002129',
                            }
                        }
                    }}
                />
            </Box>

            {poolData?.length === 0 ? (
                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '200px'
                    }}
                >
                    <Typography 
                        sx={{
                            fontSize: isMobile ? '20px' : '36px',
                            fontWeight: 700
                        }}
                    >
                        Community Pools
                    </Typography>
                    <Typography 
                        sx={{
                            fontSize: isMobile ? '20px' : '36px',
                            fontWeight: 700,
                            mt: 1
                        }}
                    >
                        Coming Soon!
                    </Typography>
                    <img
                        src="https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyGamingLogoWide.png"
                        alt="Coming Soon"
                        style={{
                            width: isMobile ? '200px' : '300px',
                            height: 'auto',
                            marginTop: '48px'
                        }}
                    />
                </Box>
            ) : (
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        justifyContent: 'center',
                        gap: isMobile ? '16px' : '32px',
                        width: '100%',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        {filteredPools?.map((pool, index) => (
                            <Box 
                                key={index} 
                                sx={{ 
                                    width: { 
                                        xs: '100%',
                                        sm: '100%', 
                                        md: '100%',
                                        lg: 'calc(50% - 16px)' 
                                    },
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <SponsorPoolsCard {...pool} />
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ height: '32px' }} />
                </>
            )}
        </>
    );
};

export default SponsoredPoolsPage;