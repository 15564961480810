import React, { useEffect, useCallback } from "react";
import { useCreateAPoolContext } from "./context/CreateAPoolContext";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";
import CreateAPoolHomePage from "./CreateAPoolHomePage";
import CreateABracketGame from "./GameTypes/CreateABracketGame";
import CreateASurvivorGame from "./GameTypes/CreateASurvivorGame";
import CreateAQuickPickGame from "./GameTypes/CreateAQuickPickGame";
import { useLocation } from "react-router-dom";
import ResponseModal from "../GameModals/ResponseModal/ResponseModal";

const CreateAPoolContainer = () => {
    const { 
        gameType,
        setGameType,
        setMasterPoolId,
        setGroupName,
        setGroupNameError,
        setUsePinCode,
        setPinCode,
        setMaxEntries,
        setAllowReferrals,
        setHostNote,
        setHostNoteError,
        createdPoolError,
        setCreatedPoolError,
        createdPoolErrorMessage,
    } = useCreateAPoolContext();
    //console.log("User Data in CreateAPoolContainer: ", userData);

    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const resetStates = useCallback(() => {
        setGameType(null);
        setMasterPoolId(null);
        setGroupName('');
        setGroupNameError(false);
        setUsePinCode(null);
        setPinCode('');
        setMaxEntries(0);
        setAllowReferrals(null);
        setHostNote('');
        setHostNoteError(false);
    }, [setGameType, setMasterPoolId, setGroupName, setGroupNameError, setUsePinCode, setPinCode, setMaxEntries, setAllowReferrals, setHostNote, setHostNoteError]);

    // Reset gameType when navigating back to the container
    useEffect(() => {
        // If we're on the main create-a-pool route without a gameType in the URL
        // This will allow the back button to reset the view properly
        if (location.pathname === "/create-a-pool" && !location.hash.includes("gameType")) {
            resetStates();
        }
    }, [location, setGameType, resetStates]);

    return (
        <>
            <StaticPoolHeaderBar navigationText="Home" headerText="Create a Pool" linkForNavigation="/" />
            
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                    padding: '16px',
                }}
            >
                <img
                    src="https://poolpartyfilestorage.blob.core.windows.net/media/PPCreateAPool.png"
                    alt="Create A Pool"
                    style={{ 
                        height: isMobile ? '100px' : '200px', 
                        width: 'auto', 
                        objectFit: 'contain' ,
                        margin: '0 auto',
                        
                    }}
                />
            </Box>

            {gameType === null && (
                <CreateAPoolHomePage />
            )}

            {gameType === 'Bracket' && (
                <CreateABracketGame />
            )}

            {gameType === 'Survivor' && (
                <CreateASurvivorGame />
            )}

            {gameType === 'QuickPick' && (
                <CreateAQuickPickGame />
            )}

            {createdPoolError && (
                <ResponseModal
                    open={createdPoolError}
                    onClose={() => {
                        setCreatedPoolError(false);
                        window.location.reload();
                    }}
                    status="error"
                    message={createdPoolErrorMessage}
                />
            )}

        </>
    );
}

export default CreateAPoolContainer;