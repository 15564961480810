import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import './BetSubmissionError.scss'; // Import the SCSS file

const BetSubmissionError = ({ errorMessage, betSubmitError }) => {
    return (
        <Modal
            open={betSubmitError}
            onClose={() => { window.location.reload(); }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                // Prevent closing when clicking outside
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box className="bet-submission-error-modal">
                <Typography 
                    id="modal-modal-title" 
                    variant="h6" 
                    component="h2"
                    className="modal-title"
                >
                    Error!
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    className="modal-description"
                >
                    {errorMessage}
                </Typography>
                <Button 
                    variant='contained'
                    onClick={() => {
                        window.location.reload(); // This will reload the page
                    }}
                    className="modal-button"
                >
                    OK
                </Button>
            </Box>
        </Modal>
    );
}

export default BetSubmissionError;
