import React from "react";
import PickStatsCard from "./PickStatsCard";
import { Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import QuickPickNoGamesCard from "../CardPieces/QuickPickNoGamesCard";
import GameInfoRow from "../CardPieces/GameInfoRowNew";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import QuickPickGamesComing from "../CardPieces/QuickPickGamesComing";

const PickStatsTab = ({ 
    leaderboardInfo, 
    selectedQuickPickGames, 
    schedule, 
    interval, 
    timeNowInNewYork, 
    currentPropIds, 
    league, 
    teamName, 
    sponsorName,
    myPicksDesktopImage,
    myPicksMobileImage,
    teams,
    standings, 
    oneWeekEvent,
    masterPoolData,
    propIdsToUse
}) => {
    //console.log("Selected Quick Pick Games in Pick Stats Tab: ", selectedQuickPickGames, "Leaderboard Info in Pick Stats Tab: ", leaderboardInfo);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const hasThisGameStarted = (gameId) => {
        const gameInfo = schedule.find(game => game.GlobalGameID === gameId);
        const gameTime = gameInfo?.DateTime;
        const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
        //console.log("Game Date Time", gameDateTime, "Time Now in NY", timeNowInNewYork, "Has Game Started?", timeNowInNewYork > gameDateTime);
        return timeNowInNewYork > gameDateTime;
    }


    // Function to process and count options for a specific game's props
    const processGameProps = (game) => {
        //console.log("Game in Pick Stats", game);
        const props = game?.props || {};
        const gameProps = props.gameProps || [];
        const homeProps = props.homeProps || [];
        const awayProps = props.awayProps || [];

        const allProps = [...gameProps, ...homeProps, ...awayProps];

        // Filter allProps to only include those that match currentPropIds
        const filteredProps = allProps.filter(prop => prop && currentPropIds.includes(prop.id));
        //console.log("All Props in Pick Stats", allProps, "Filtered Props in Pick Stats", filteredProps);
        // Sort and remove any null/undefined values in the final sortedProps
        const sortedProps = currentPropIds
            .map(id => filteredProps.find(prop => prop?.id === id))
            .filter(Boolean); // This will filter out both null and undefined

        //console.log("Sorted Props in Pick Stats", sortedProps);

        

        // Initialize the counts for each game prop
        const optionCounts = filteredProps?.map((prop) => ({
            id: prop.id,
            points: prop.points,
            line: prop.line,
            propChoice: prop.propChoice,
            propText: prop.propText,
            options: prop.options,
            correctOption: prop.correctOption,
            actualTotal: prop.actualTotal,
            counts: prop.options.reduce((acc, option) => {
                acc[option] = 0; // Initialize the count for each option
                return acc;
            }, {}),
            ...(prop.correctOptions ? { correctOptions: prop.correctOptions } : {}) // Conditionally include correctOptions
        }));

        // Count selections based on user picks for this specific game's props
        leaderboardInfo?.forEach((user) => {
            user?.picks?.forEach((pick) => {
                const matchingProp = optionCounts.find(prop => prop.id === pick.id);
                if (matchingProp) {
                    if (matchingProp.counts.hasOwnProperty(pick.option)) {
                        matchingProp.counts[pick.option]++;
                    }
                }
            });
        });

        return optionCounts;
    };

    const gamesComingTitle = masterPoolData?.modalInfo?.pickStats?.title;
    const gamesComingBody = masterPoolData?.modalInfo?.pickStats?.body;
    //console.log("Games Coming Title: ", gamesComingTitle, "Games Coming Body: ", gamesComingBody);

    return (
        <>
            {selectedQuickPickGames?.length === 0 ? (
                <>
                    {oneWeekEvent ? (
                        <QuickPickGamesComing
                            page={"pick-stats"}
                            title={gamesComingTitle}
                            body={gamesComingBody}
                        />
                    ) : (
                        <QuickPickNoGamesCard
                            league={league}
                            isThereAGame={false}
                            teamName={teamName}
                            sponsorName={sponsorName}
                        />
                    )}
                </>
            ) : (
                selectedQuickPickGames?.map((game, gameIndex) => {
                    const gameId = game.GlobalGameID;
                    const hasGameStarted = hasThisGameStarted(gameId);
                    const propsForThisGame = propIdsToUse?.filter(prop => prop.gameId === gameId);
                    const logoForThisGame = propsForThisGame?.[0]?.logo;
                    //console.log("Has Game Started?", hasGameStarted);

                    return (
                        <React.Fragment key={`game-${gameId}-${gameIndex}`}>
                            {/* Banner Image (Mobile or Desktop) */}
                            {logoForThisGame && (
                                <Box>
                                    <img 
                                        src={logoForThisGame}
                                        alt="My Picks Image"
                                        style={{ width: '100%', maxWidth: isMobile ? '311px' : '600px', maxHeight: isMobile ? '80px' : '120px', margin: '0px auto', objectFit: 'contain', padding: '32px 0px 16px 0px' }}
                                    />
                                </Box>
                            )}
                            
                            {/* Game Info Row */}
                            {!logoForThisGame && (
                                <Box sx={{borderTop: '2px solid #002129'}}>
                                    <GameInfoRow
                                        gameId={gameId}
                                        teams={teams}
                                        schedule={schedule}
                                        standings={standings}
                                        league={league}
                                    />
                                </Box>
                            )}
                            
                            {/* Game Content based on whether it has started */}
                            {!hasGameStarted ? (
                                <QuickPickNoGamesCard
                                    league={league}
                                    isThereAGame={true}
                                    teamName={teamName}
                                    sponsorName={sponsorName}
                                    picksComing={true}
                                />
                            ) : (
                                <>
                                    {processGameProps(game)?.map((prop, propIndex) => (
                                        <PickStatsCard
                                            key={`${gameId}-prop-${propIndex}`}
                                            prop={prop}
                                            interval={interval}
                                            displayIndex={propIndex + 1}
                                        />
                                    ))}
                                </>
                            )}
                            <Box height={40} />
                        </React.Fragment>
                    );
                })
            )}
            <Box height={40} />
        </>
    );
    
};

export default PickStatsTab;
