import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

const PoolPartyPopUpModal = ({ modalOpen, setModalOpen, modalInfo }) => {
    console.log("Modal Info: ", modalInfo);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '4px',
        p: 4,
    };

    const setModalAsSeen = (localStorageId, expirationDate) => {
        if (!localStorageId) {
            console.error("Missing localStorageId");
            return;
        }
        
        const key = `poolParty_${localStorageId}`;
        const data = {
            seen: true,
            expirationDate: expirationDate
        };
        
        localStorage.setItem(key, JSON.stringify(data));
    };

    // Handle primary button click
    const handlePrimaryClick = () => {
        // Mark as seen in localStorage
        setModalAsSeen(modalInfo?.localStorageId, modalInfo?.expirationDate);
        
        // Navigate if link provided
        if (modalInfo?.primaryButtonLink) {
            window.location.href = modalInfo.primaryButtonLink;
        }

        if (modalInfo?.primaryButtonAction === 'reload') {
            window.location.reload();
        }
        
        setModalOpen(false);
    };

    // Handle secondary button click
    const handleSecondaryClick = () => {
        // Mark as seen in localStorage
        setModalAsSeen(modalInfo?.localStorageId, modalInfo?.expirationDate);
        
        // Handle specific actions
        if (modalInfo?.secondaryButtonLink) {
            window.location.href = modalInfo.secondaryButtonLink;
        }

        if (modalInfo?.secondaryButtonAction === 'reload') {
            window.location.reload();
        }
        
        setModalOpen(false);
    };

    return (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box sx={style}>
                {/* Conditional Title */}
                {modalInfo?.title && (
                    <Typography
                        sx={{
                            fontSize: modalInfo.titleFontSize || '20px',
                            fontWeight: modalInfo.titleFontWeight || '800',
                            color: '#002129',
                            textAlign: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        {modalInfo.title}
                    </Typography>
                )}
                
                {/* Conditional Logo */}
                {modalInfo?.logo && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <img 
                            src={modalInfo.logo} 
                            alt="Logo" 
                            style={{ 
                                maxWidth: '100%', 
                                maxHeight: '80px' 
                            }} 
                        />
                    </Box>
                )}
                
                {/* Body Text - always expects an array of objects */}
                {modalInfo?.body && Array.isArray(modalInfo.body) && modalInfo.body.length > 0 && (
                    <Box sx={{ marginBottom: '30px' }}>
                        {modalInfo.body.map((item, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    fontSize: item.fontSize || '16px',
                                    fontWeight: item.fontWeight || '500',
                                    color: '#002129',
                                    textAlign: 'center',
                                    marginBottom: index < modalInfo.body.length - 1 ? '12px' : 0,
                                }}
                            >
                                {item.text}
                            </Typography>
                        ))}
                    </Box>
                )}
                
                {/* Buttons Container */}
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    gap: 2,
                    mt: 2 
                }}>
                    {/* Primary Button */}
                    {modalInfo?.primaryButtonText && (
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={handlePrimaryClick}
                            sx={{
                                backgroundColor: '#00bacc',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#0095a8',
                                }
                            }}
                        >
                            {modalInfo.primaryButtonText}
                        </Button>
                    )}
                    
                    {/* Secondary Button */}
                    {modalInfo?.secondaryButtonText && (
                        <Button 
                            variant="outlined"
                            fullWidth
                            onClick={handleSecondaryClick}
                            sx={{
                                borderColor: '#00bacc',
                                color: '#00bacc',
                                '&:hover': {
                                    borderColor: '#0095a8',
                                    backgroundColor: 'rgba(0, 186, 204, 0.04)',
                                }
                            }}
                        >
                            {modalInfo.secondaryButtonText}
                        </Button>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default PoolPartyPopUpModal;