import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { DateTime } from 'luxon';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';
import WeeklyWinnerDisplay from '../../ChampionDisplay/WeeklyWinnerDisplay';
import Top3Display from '../../ChampionDisplay/Top3Display';
import QuickPickLeaderboardDropdown from './QuickPickLeaderboardDropdown';
import QuickPickNoGamesCard from '../CardPieces/QuickPickNoGamesCard';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModal';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';


const QuickPickLeaderboard = ({ 
    mainGameId, 
    myUserId, 
    myUsername, 
    isGameOver, 
    currentInterval, 
    firstInterval, 
    lastInterval, 
    intervalType, 
    handleClickState, 
    leaderboardData, 
    partyState, 
    schedule,
    gameAddress, 
    teamsToInclude,
    gatherDisplayBarInfo,
    sponsorName,
    league,
    teams,
    oneWeekEvent,
    tiebreakerInfo,
    gameName,
    quickPickGames,
    byeWeek,
}) => {
  //console.log("Quick Pick Games Leaderboard", quickPickGames);
  
  const navigateToTop = useNavigateToTop();
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

const handleRulesClick = () => {
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
}


  

  const [sortBy, setSortBy] = useState('rank');
  const [sortedColumn, setSortedColumn] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');
  const [leaderboardInterval, setLeaderboardInterval] = useState('');

  useEffect(() => {
    console.log("Leaderboard Use Effect");
    let initialInterval = '';
    if (currentInterval && firstInterval && lastInterval) {
      initialInterval = currentInterval < firstInterval ? firstInterval : currentInterval > lastInterval ? lastInterval : currentInterval;
    }
    setLeaderboardInterval(initialInterval);
  }, [currentInterval, firstInterval, lastInterval]);
  
  // const [userStatsOpen, setUserStatsOpen] = useState(false);
  // const [userStatsInfo, setUserStatsInfo] = useState(null);
  const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);
  //console.log("SEASON VS WEEK", leaderboardInterval);

  const thisWeeksGame = leaderboardInterval === 'Season Standings' ? null : quickPickGames?.find((week) => week.Week === Number(leaderboardInterval));
  const thisWeeksTiebreaker = thisWeeksGame?.props?.tiebreakers[0] ?? null;
  const tiebreakerText = thisWeeksTiebreaker ? thisWeeksTiebreaker.propText : null;
  const tiebreakerAnswer = thisWeeksTiebreaker ? thisWeeksTiebreaker.correctOption : null;
  //console.log("THIS WEEKS GAME", thisWeeksGame, "thisWeeksTiebreaker", thisWeeksTiebreaker, "Text", tiebreakerText, "Answer", tiebreakerAnswer);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const seasonText = "Season Standings";

  let findTeamName;

    if (teamsToInclude && teamsToInclude.length > 0) {
        findTeamName = gatherDisplayBarInfo(teamsToInclude[0], teams);
    }    
    const teamName = findTeamName?.teamNickname;



  const handleSort = (column) => {
    // If clicking the same column, toggle the sortOrder
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc'); // Reset to ascending when sorting a new column
    }
  };



  const assignRanks = (data) => {
    // Sort data in descending order by totalPoints and then by totalMargin
    data?.sort((a, b) => {
      const aPoints = a.totalPoints ?? 0;
      const bPoints = b.totalPoints ?? 0;
      const aMargin = a.totalMargin ?? 0;
      const bMargin = b.totalMargin ?? 0;
  
      // Sort by points first, then by margin (descending for both)
      if (aPoints !== bPoints) {
        return bPoints - aPoints; // Descending order for points
      } 
      return aMargin - bMargin; // Descending order for margin
    });
  
    let rank = 1;
    let tiedRank = 1;
    let previousPoints = data[0]?.totalPoints ?? 0;
    let previousMargin = data[0]?.totalMargin ?? 0;
  
    return data.map((entry, index) => {
      const currentPoints = entry.totalPoints ?? 0;
      const currentMargin = entry.totalMargin ?? 0;
  
      // Determine rank for tied entries
      if (currentPoints === previousPoints && currentMargin === previousMargin) {
        entry.rank = tiedRank;
      } else {
        rank = index + 1; // Update rank to current index + 1
        tiedRank = rank; // Update tiedRank to current rank
        entry.rank = rank; // Assign current rank to entry
      }
  
      // Update previous values for next iteration
      previousPoints = currentPoints;
      previousMargin = currentMargin;
  
      return entry; // Return the updated entry
    });
  };
  


  let rankedData = assignRanks(leaderboardData);
  //console.log("RANKED DATA", rankedData);

  if (leaderboardInterval === 'Last Place') {
    rankedData = rankedData?.filter(entry => entry.noPicks === 0);
  }


  let weeklyLeaderboard = [];
  function findWeeklyLeaderboard() {
    const selectedWeek = leaderboardInterval;
    //console.log("SELECTED WEEK", selectedWeek);
    const weeklyLeaderboard = [];

    for (let i = 0; i < rankedData?.length; i++) {
        const user = rankedData[i];
        const username = user.username;
        const userId = user.userId;
        const picks = user.picks;
        const tiebreakers = user.tiebreakers;
        //console.log("PICKS SELECTED WEEK", picks);

        let wins = 0;
        let losses = 0;
        let points = 0;
        let totalMargin = 5000;

        if (picks) {
            for (let j = 0; j < picks.length; j++) {
                const game = picks[j];
                if (game.interval === selectedWeek) {
                    const result = game.result;
                    const gamePoints = game.points;
                    const isTiebreaker = game.tiebreaker;

                    if (result === 'Win') {
                        wins++;
                        points += gamePoints;
                    } else if (result === 'Loss' && game.teamId !== 'No Pick') {
                        losses++;
                    } 
                    if (isTiebreaker && game.margin !== null && game.margin !== undefined) {
                      totalMargin = game.margin;
                    }
                }
            }

        }

            // Create entry for the user
            const entry = {
                username,
                userId,
                totalWins: wins,
                totalLosses: losses,
                totalPoints: points,
                picks,
                totalMargin: totalMargin,
                tiebreakers,
            };

            // Push entry to leaderboard
            weeklyLeaderboard.push(entry);
        
    }

    return weeklyLeaderboard;
}
  const weeklyData = findWeeklyLeaderboard();
  //console.log("WEEKLY LEADERBOARD", weeklyData);

  const rankedWeeklyData = assignRanks(weeklyData);
  //console.log("RANKED WEEKLY DATA", rankedWeeklyData);


  const dataToSort = (leaderboardInterval === 'Season Standings' || leaderboardInterval === 'Last Place') ? rankedData : rankedWeeklyData;
  //console.log("Data to Sort", dataToSort);

  const sortedData = [...dataToSort].sort((a, b) => {
    if (sortBy === 'username') {
      // Sort alphabetically by player names
      const nameA = a[sortBy].toUpperCase();
      const nameB = b[sortBy].toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    } else if (sortBy === 'totalWins') {
      // Sort totalWins in descending order first, then asc
      const comparison = b[sortBy] - a[sortBy]; // Descending order by default
      return sortOrder === 'desc' ? -comparison : comparison; // Flip for ascending
    } else {
      // For other numeric columns
      const comparison = a[sortBy] - b[sortBy];
      return sortOrder === 'asc' ? comparison : -comparison;
    }
  });

  const thisIntervalsTiebreaker = (row) => {
    if (leaderboardInterval === 'Season Standings') {
        return 'N/A';
    } else {
        const tiebreakers = row?.tiebreakers;
        const thisTiebreaker = tiebreakers?.find(tiebreaker => tiebreaker.interval === leaderboardInterval);
        const option = thisTiebreaker?.option;
        const margin = thisTiebreaker?.margin; 
        const text = margin !== undefined && margin !== null 
            ? `Tiebreaker Margin: ${margin}` 
            : `Tiebreaker: ${option}`;
        return thisTiebreaker ? text : 'Tiebreaker: N/A';
    }
};

  
  const usersEntry = sortedData?.find(entry => entry.userId === myUserId);
  //console.log("USERS ENTRY in Leaderboard", usersEntry);



  const rankHeaderColor = sortedColumn === 'rank' ? '#FFC60A' : '#fff';
  const pointsHeaderColor = sortedColumn === 'points' ? '#FFC60A' : '#fff';
  const recordHeaderColor = sortedColumn === 'record' ? '#FFC60A' : '#fff';
  const playerHeaderColor = sortedColumn === 'username' ? '#FFC60A' : '#fff';


  const whosInFirst = sortedData?.[0]?.username;
  const whosInSecond = sortedData?.[1]?.username;
  const whosInThird = sortedData?.[2]?.username;
  const whosInFirstCharacterCount = whosInFirst?.length;
  const whosInSecondCharacterCount = whosInSecond?.length;
  const whosInThirdCharacterCount = whosInThird?.length;
  //console.log("WHOS IN FIRST", whosInFirst, whosInFirstCharacterCount);

  let selectedWeek = 1;
  if (leaderboardInterval !== 'Season Standings' && leaderboardInterval !== 'Last Place') {
    selectedWeek = leaderboardInterval;
  }
  const thisWeeksGames = schedule?.filter((week) => week.Week === Number(selectedWeek));
  const timeNowInNewYork = DateTime.now().setZone('America/New_York');
  const hasWeekStarted = thisWeeksGames?.some((game) => timeNowInNewYork > DateTime.fromISO(game.DateTime));
  //console.log("Has Week Started", hasWeekStarted);
  const hasWeekEnded = thisWeeksGames?.every((game) => game.Status === "Final" || game.Status === "F/OT");

  const isThisSegmentOver = (leaderboardInterval === 'Season Standings' || leaderboardInterval === 'Last Place') ? isGameOver : hasWeekEnded;
  //console.log("This Weeks Games", thisWeeksGames, "Has Week Ended", hasWeekEnded, "Is Pick Em Over", isGameOver, "Is This Segment Over", isThisSegmentOver, "Selected Week:", selectedWeek, "Season vs Week", leaderboardInterval);

  if (leaderboardInterval === '') {
    return <LoadingScreen />;
  }
  
  return (
    <>
      <Box>
          {!oneWeekEvent && (
            <QuickPickLeaderboardDropdown
              interval={leaderboardInterval}
              setInterval={setLeaderboardInterval}
              seasonText={seasonText}
              currentInterval={currentInterval}
              firstInterval={firstInterval}
              lastInterval={lastInterval}
              intervalType={intervalType}
            />
          )}
      
      {(leaderboardInterval === 'Season Standings' && isGameOver) || (oneWeekEvent && isGameOver) && whosInFirst !== undefined && (
          <>
              {oneWeekEvent && (
                  <Box sx={{ marginTop: '10px' }}></Box>
              )}
              <Top3Display
                  whosInFirst={whosInFirst}
                  whosInFirstCharacterCount={whosInFirstCharacterCount}   
                  line2={`${gameName} Top 3`}
                  whosInSecond={whosInSecond}
                  whosInSecondCharacterCount={whosInSecondCharacterCount}
                  whosInThird={whosInThird}
                  whosInThirdCharacterCount={whosInThirdCharacterCount}
              />
          </>
      )}

      {(leaderboardInterval === 'Last Place' && isGameOver) && whosInFirst !== undefined && (
          <ChampionDisplay
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            partyName={partyState}
            line2={"2024 NFL Pick 'Em - Fav 5 Last Place Winner"}
          />
      )}
      {leaderboardInterval !== 'Season Standings' && !oneWeekEvent && leaderboardInterval !== 'Last Place' && isThisSegmentOver && whosInFirst !== undefined && thisWeeksGames?.length !== 0 && (
          <WeeklyWinnerDisplay
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            selectedWeek={selectedWeek}
          />
      )}
      
       <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isGameOver || isThisSegmentOver ? '10px' : '0px',
                    marginBottom: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => setTiebreakInfoOpen(true)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        color: '#00AAD1',
                        marginRight: '5px',
                        marginTop: '10px',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <InfoIcon
                    sx={{
                        color: '#00AAD1',
                        fontSize: isMobile ? '16px' : '20px',
                    }}
                />
            </Box>

            {tiebreakerText && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginBottom: '10px',
                    }}
                >
                    Tiebreaker: {tiebreakerText}
                </Typography>
            )}
            {tiebreakerAnswer && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginBottom: '10px',
                    }}
                >
                    Result: {tiebreakerAnswer}
                </Typography>
            )}

      {(leaderboardInterval === byeWeek) ? (
        <>
          <QuickPickNoGamesCard
            sponsorName={sponsorName}
            league={league}
            teamName={teamName}
            isThereAGame={false}
          />
          <Box sx={{ marginTop: '40px' }}></Box>
        </>
      ) : (

      <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', marginBottom: '20px'}}>
        <Table>
          {usersEntry &&
            <TableHead sx={{ backgroundColor: '#F0F1F1'}}>
              <TableRow>
                <TableCell className="pickem-leaderboard-table-cells" style={{width:'57px', textAlign:'center', fontSize: isMobile ? '12px' : '14px'}}>{usersEntry?.rank}</TableCell>
                <TableCell
                    className="pickem-leaderboard-table-cells"
                    style={{ width: '160px', fontSize: isMobile ? '12px' : '16px' }}
                  >
                    <span
                      style={{ textDecoration: 'underline', fontWeight: 700 }}
                      // onClick={() => {
                      //   setUserStatsOpen(true);
                      //   setUserStatsInfo(usersEntry); // Pass the entire usersEntry object
                      // }}
                    >
                      {usersEntry?.username}
                    </span>
                    {hasWeekStarted && (
                      <div style={{ fontSize: isMobile ? '12px' : '16px'}}>{thisIntervalsTiebreaker(usersEntry)}</div>
                      )}
                    {/* {hasTiebreakerDeadlinePassed && leaderboardInterval === 'Season Standings' && (
                            <div style={{ fontSize: isMobile ? '12px' : '14px'}}>
                                Tiebreaker: {usersEntry?.tiebreaker ?? 'N/A'}
                            </div>
                        )} */}
                  </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '81px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {usersEntry?.totalPoints ?? 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {`${usersEntry?.totalWins ?? 0}-${usersEntry?.totalLosses ?? 0}`}
                </TableCell>

              </TableRow>
            </TableHead>
        }
          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('rank');
                }}
                sx={{ width: '57px', color: rankHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                RANK
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('username');
                  setSortedColumn('username');
                }}
                sx={{ width: '160px', color: playerHeaderColor, fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'}}
              >
                PLAYER
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('points');
                }}
                sx={{ width: '81px', color: pointsHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'} }
              >
                {leaderboardInterval === "Season Standings" ? "TOT PTS" : "PTS"}
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('totalWins');
                  setSortedColumn('record');
                }}
                sx={{ width: '77px', color: recordHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                W-L
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, index) => (
              //console.log("ROW", row),
                  <TableRow
                    key={row.userId}
                    sx={{
                      backgroundColor: row.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                    }}
                  >
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '57px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px'}}>
                      {row?.rank}
                    </TableCell>
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '162px', fontSize: isMobile ? '12px' : '16px' }}>
                      <span
                        style={{ textDecoration: 'underline', fontWeight: 700 }}
                        // onClick={() => {
                        //   setUserStatsOpen(true);
                        //   setUserStatsInfo(row); // Pass the entire row data
                        // }}
                      >                      
                          {row?.username}
                      </span>
                      {hasWeekStarted && (
                      <div style={{ fontSize: isMobile ? '12px' : '16px'}}>{thisIntervalsTiebreaker(row)}</div>
                      )}
                        {/* {hasTiebreakerDeadlinePassed && leaderboardInterval === 'Season Standings' && (
                            <div style={{ fontSize: isMobile ? '12px' : '14px'}}>
                                Tiebreaker: {row?.tiebreaker ?? 'N/A'}
                            </div>
                        )} */}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {row?.totalPoints ?? 0}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '47px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {`${row?.totalWins ?? 0}-${row?.totalLosses ?? 0}`}
                    </TableCell>

                  </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </Box>
      
    {tiebreakInfoOpen && (
      <TiebreakerModal
        tiebreakInfoOpen={tiebreakInfoOpen}
        setTiebreakInfoOpen={setTiebreakInfoOpen}
        tiebreakerInfo={tiebreakerInfo}
        handleRulesClick={handleRulesClick}
      />  
    )}

  </>

  );
};

export default QuickPickLeaderboard;