import React from "react";
import { Box, Typography } from "@mui/material";
import "./SuperPickemBracket.scss";
import { hexToRgba } from "../bracketUtils/HexToRgba.js";

const BracketTeamRow = ({ 
    game, 
    round, 
    teamInfo, 
    isOppositeSelected, 
    isSelected, 
    setIsSelected, 
    bracketState, 
    setBracketState, 
    champ,
    picksByRound,
    setBracketRound,
    roundDash,
    setRoundDash,
    setRoundDashPosition,
}) => {

    const teamId = teamInfo?.teamId;
    const seed = teamInfo?.seed;
    const teamDisplay = teamInfo?.nameDisplay;
    const teamDisplayCharacterCount = teamInfo?.teamDisplayCharacterCount;
    const teamLogo = teamInfo?.logo;
    const teamColor = teamInfo?.teamColor;

    
    const handleClick = (game, round, teamId) => {
        //console.log("Game: ", game, "Round: ", round, "Team ID: ", teamId);
        const pick = {
            game,
            round,
            teamId,
        };
    
        let newBracketState = [...bracketState];
    
        // Check and see if there's a pick for this game already
        const existingPickIndex = bracketState?.findIndex((p) => p.game === game && p.round === round && p.teamId === teamId);
        const opposingPickId = bracketState?.find((p) => p.game === game && p.round === round && p.teamId !== teamId)?.teamId;
        
        //console.log("Existing Pick Index: ", existingPickIndex, "Opposing Pick ID: ", opposingPickId);
    
        // Remove current and future picks for the existing team if a pick exists
        if (existingPickIndex > -1) {
            newBracketState = newBracketState.filter((p) => 
                p.round < round || p.teamId !== teamId
            );
        }
    
        // Remove current and future picks for the opposing team if it exists
        if (opposingPickId) {
            newBracketState = newBracketState.filter((p) => 
                p.round < round || p.teamId !== opposingPickId
            );
        }
    
        let scrollNeeded = false;
        let scrollRound = round + 1;
        // Add the new pick IF it wasn't already in bracketState
        if (existingPickIndex === -1) {
            newBracketState.push(pick);

            const picksThisRound = bracketState?.filter((p) => p.round === round)?.length;
            const gamesThisRound = picksByRound[round - 1];
            //console.log("Picks This Round: ", picksThisRound, "Games This Round: ", gamesThisRound, "Opposing Pick ID: ", opposingPickId);
            if (picksThisRound === gamesThisRound - 1 && !opposingPickId) {
                scrollNeeded = true;
            }
        }

        if (scrollNeeded) {
            const scrollFunction = setRoundDashPosition[scrollRound];
            if (scrollFunction && typeof scrollFunction === 'function') {
                scrollFunction(); // Call the function if it exists
            } 

            setRoundDash(scrollRound);
            setBracketRound(scrollRound);
        }
    
        setBracketState(newBracketState);
    };

    return (
        <Box 
            className="super-pickem-bracket-team-row" 
            style={{
                border: isSelected ? `3px solid ${teamColor}` : '',
                backgroundColor: isSelected ? `${hexToRgba(teamColor, 0.20)}` : '',
                cursor: champ ? 'default' : 'pointer',
            }} 
            onClick={() => !champ && handleClick(game, round, teamId)}         
        >
            {/* Team Seed */}
            <Box className="super-pickem-bracket-team-seed"
                style={{
                    marginLeft: isSelected ? '3px' : '5px',
                }}
            >
                <Typography className="seed-number">
                    {seed}
                </Typography>
            </Box>

            {/* Team Logo */}
            <Box 
                className="super-pickem-bracket-team-logo-placeholder"
                style={{
                    position: 'relative', // Required for overlay positioning
                }}
            >
                <img 
                    src={teamLogo}
                    alt="Team Logo"
                    className="team-logo"
                    style={{
                        filter: isOppositeSelected ? 'grayscale(100%) opacity(70%)' : 'none', // Greyscale effect
                    }}
                />
                {isOppositeSelected && (
                    <Box 
                        className="logo-overlay" 
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#D9D9D9',
                            opacity: 0.5, // Semi-transparent overlay
                            borderRadius: '4px', // Match the border radius of the logo placeholder
                        }}
                    />
                )}
            </Box>

            {/* Team Name/Record */}
            <Box className="super-pickem-bracket-team-name">
                <Typography 
                    className="team-name" 
                    style={{
                        color: isOppositeSelected ? '#B5B5B5' : teamColor,
                        fontSize: teamDisplayCharacterCount > 20 ? '10px' : teamDisplayCharacterCount > 16 ? '12px' : '14px',
                        lineHeight: teamDisplayCharacterCount > 20 ? '1.4' : teamDisplayCharacterCount > 16 ? '' : '1',
                    }}
                >
                    {teamDisplay}
                </Typography>
            </Box>
        </Box>
    );
};

export default BracketTeamRow;
