import React, { useState, useEffect } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import AddPropsModal from "./AddPropsModal";
import AddResultsModal from "./AddResultsModal";

const AddQuickPickResults = ({ selectedWeek, selectedSeason }) => {
    const { quickPickNFL, leagueState } = useAdminDashContext();
    const [selectedGame, setSelectedGame] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProp, setSelectedProp] = useState(null);
    const [selectedPropType, setSelectedPropType] = useState(null);

    const propFormat = [
        {
            propType: "tiebreakers",
            createdProps: []
        },
        {
            propType: "gameProps", 
            createdProps: []
        },
        {
            propType: "homeProps",
            createdProps: []
        },
        {
            propType: "awayProps",
            createdProps: []
        }
    ];

    const [propsToAdd, setPropsToAdd] = useState(propFormat);
    console.log("Props To Add:", propsToAdd);

    const thisWeeksGames = quickPickNFL?.filter((game) => game.Week === selectedWeek) || [];
    console.log("This Week's Games", thisWeeksGames);

    useEffect(() => {
        console.log("Resetting Selected Game");
        // Reset selected game when the selected week changes
        setSelectedGame(null);
    }, [selectedWeek]);

    const handleGameSelect = (event) => {
        const gameId = event.target.value;
        const game = thisWeeksGames?.find((g) => g.GlobalGameID.toString() === gameId);
        console.log("Event", event.target.value);
        console.log("Selected Game", game);
        setSelectedGame(game || null);
    };
    
    const formatGameDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    const handleEditProp = (prop, propType) => {
        console.log("Adding Prop", prop, propType);
        setSelectedProp(prop);
        setSelectedPropType(propType);
        setIsModalOpen(true);
    };

    const handleSaveProps = () => {
        const updatedGameInfo = {
            season: selectedSeason,
            league: leagueState,
            globalGameID: selectedGame.GlobalGameID,
            propData: propsToAdd,
        }
        console.log("Updated Game Info", updatedGameInfo);
        // Save the props to the selected game
    }

    const isPropsToAddEmpty = propsToAdd?.every((prop) => prop.createdProps.length === 0);
    
    console.log("Props To Add Empty?", isPropsToAddEmpty);
      

    return (
        <div>
            {/* Game Selection and Details */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label htmlFor="game-select" style={{ marginRight: '10px' }}>
                    Game:
                </label>
                <select 
                    id="game-select" 
                    value={selectedGame ? selectedGame.GlobalGameID : ""}
                    onChange={handleGameSelect}
                    style={{ padding: '5px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        {thisWeeksGames?.length === 0 ? "No Games" : "Select Game"}
                    </option>
                    {thisWeeksGames.map((game) => (
                        <option key={game?.GlobalGameID} value={game?.GlobalGameID}>
                            {game?.AwayTeam} at {game?.HomeTeam}
                        </option>
                    ))}
                </select>
            </div>

            {selectedGame && (
                <div style={{ marginBottom: '10px', maxWidth: '800px', margin: '0 auto' }}>
                    {/* Existing Game Details and Props Section */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <div>{formatGameDate(selectedGame?.Day)}</div>
                            <div>{selectedGame?.AwayTeam} at {selectedGame?.HomeTeam}</div>
                        </div>
                    </div>

                    <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                        {/* Props NOT Finalized Section */}
                        <div style={{ marginBottom: '20px' }}>
                            <h4 style={{ fontWeight: 'bold' }}>Props NOT Finalized</h4>
                            {["tiebreakers", "gameProps", "homeProps", "awayProps"].map((propType) => {
                                const notFinalizedProps = selectedGame?.props?.[propType]?.filter(prop => !prop.correctOption) || [];
                                return (
                                    notFinalizedProps.length > 0 && (
                                        <div style={{ marginBottom: '10px' }} key={`${propType}-notFinalized`}>
                                            <h5 style={{ fontWeight: 'bold' }}>
                                                {propType.charAt(0).toUpperCase() + propType.slice(1)}
                                            </h5>
                                            <ul style={{ paddingLeft: '20px' }}>
                                                {notFinalizedProps.map(prop => (
                                                    <React.Fragment key={prop.id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                            <button 
                                                                onClick={() => handleEditProp(prop, propType)} 
                                                                style={{ 
                                                                    background: 'none', 
                                                                    border: '1px solid #002129', 
                                                                    padding: '2px 5px',
                                                                    color: '#002129', 
                                                                    cursor: 'pointer', 
                                                                    fontSize: '16px',
                                                                    marginRight: '10px'
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <li style={{ listStyle: 'none' }}>{prop?.propText || "No propText available"}</li>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                        <div style={{ borderTop: '4px solid #002129' }}></div>
                        {/* Props FINALIZED Section */}
                        <div style={{ marginBottom: '20px' }}>
                            <h4 style={{ fontWeight: 'bold' }}>PROPS FINALIZED</h4>
                            {["tiebreakers", "gameProps", "homeProps", "awayProps"].map((propType) => {
                                const finalizedProps = selectedGame?.props?.[propType]?.filter(prop => prop.correctOption) || [];
                                return (
                                    finalizedProps.length > 0 && (
                                        <div style={{ marginBottom: '10px' }} key={`${propType}-finalized`}>
                                            <h5 style={{ fontWeight: 'bold' }}>
                                                {propType.charAt(0).toUpperCase() + propType.slice(1)}
                                            </h5>
                                            <ul style={{ paddingLeft: '20px' }}>
                                                {finalizedProps.map(prop => (
                                                    <React.Fragment key={prop.id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                            <button 
                                                                onClick={() => handleEditProp(prop, propType)} 
                                                                style={{ 
                                                                    background: 'none', 
                                                                    border: '1px solid #002129', 
                                                                    padding: '2px 5px',
                                                                    color: '#002129', 
                                                                    cursor: 'pointer', 
                                                                    fontSize: '16px',
                                                                    marginRight: '10px'
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <li style={{ listStyle: 'none' }}>{prop?.propText || "No propText available"}</li>
                                                        </div>
                                                        {prop?.correctOption && <li>Correct Option: {prop.correctOption}</li>}
                                                        {prop?.actualTotal && <li>Actual Total: {prop.actualTotal}</li>}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

                {/* Add Props Modal */}
                <AddResultsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    propsToAdd={propsToAdd}
                    setPropsToAdd={setPropsToAdd}
                    selectedProp={selectedProp}
                    setSelectedProp={setSelectedProp}
                    selectedPropType={selectedPropType}
                    setSelectedPropType={setSelectedPropType}
                />

           
                {!isPropsToAddEmpty && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button 
                            onClick={() => handleSaveProps()}
                            disabled={isPropsToAddEmpty} 
                            style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", marginLeft: '35px' }}
                        >
                            Save Props
                        </button>     
                    </div>
                )}
        </div>
            
    );
}

export default AddQuickPickResults;
