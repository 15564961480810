import React from 'react';
import { Box, Table, TableRow, TableHead, TableBody, TableCell, Typography, Checkbox } from "@mui/material";
import { useUpdatePool } from '../../../hooks/pools/useUpdatePool';
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const RankingsTable = ({
    gameData, 
    myUserId, 
    gameAddress, 
    mainGameId,
    userSubgroups,
    setPartyState,
    partyId,
    setPartyId,
    setDefaultPartyId,
    setDefaultPartyName,
    leaderboard,
    hasJoinDeadlinePassed,
    gameName,
}) => {
    //console.log("PARTY ID: ", partyId);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const { mutate } = useUpdatePool();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handlePoolClick = (id, name) => {
        //console.log("Navigate to Pool Page");
        setPartyState(name);
        setPartyId(id);
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("LEADERBOARD")}/${uriEncoded(id)}`);
    }

    const handleDefaultPartyChange = async (updateValue, updateName) => {
        if (updateValue === partyId) {
            return;
        }

        let picksData = {
            updateKey: "defaultParty",
            updateValue: updateValue,
            userID: myUserId,
            gameId: mainGameId,
        };
        //console.log("Picks Data:", picksData);
            
        try {
            await mutate(picksData, {
                onSuccess: async () => {
                    try {
                        //console.log("Default Party updated successfully");
                        setPartyState(updateName);
                        setPartyId(updateValue);
                        setDefaultPartyId(updateValue);
                        setDefaultPartyName(updateName);
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                },
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
        }
    }

    const myOverallRank = leaderboard?.find((rank) => rank.userId === myUserId)?.overallRank;

    const amITied = (rank, leaderboard, subgroup, myUserId) => {
        let tied = false;
    
        leaderboard.forEach((user) => {
            // Check if the user is not me
            if (user.userId !== myUserId) {
                // If subgroup is true, compare using subgroupRank, else use overallRank
                const rankToCompare = subgroup ? user.subgroupRank : user.overallRank;
                
                // If the ranks match, set tied to true
                if (rankToCompare === rank) {
                    tied = true;
                }
            }
        });
    
        return tied;
    }

    const amITiedOverall = amITied(myOverallRank, leaderboard, false, myUserId);
    const totalUsers = leaderboard?.length;

    const findSubgroupInfo = (subGroupId) => {
        // Get the subgroup name for the given subgroup ID
        const subGroupName = gameData?.subGroups?.find(subGroup => subGroup.groupID === subGroupId)?.groupName;

        // Get the list of user IDs for the given subgroup
        const subGroupUserIds = gameData?.subGroups?.find(subGroup => subGroup.groupID === subGroupId)?.groupMembers;
        
        // Filter the leaderboard to only include users in the subgroup
        const subGroupLeaderboard = leaderboard?.filter(user => subGroupUserIds.includes(user.userId));
        
        // Sort the subgroup leaderboard by overall rank (ascending)
        const sortedSubGroupLeaderboard = subGroupLeaderboard.sort((a, b) => a.overallRank - b.overallRank);
        
        // Re-assign ranks, handling ties by ensuring users with the same overallRank get the same subgroupRank
        let currentRank = 1;  // Start rank at 1
        const reRankedLeaderboard = sortedSubGroupLeaderboard.map((user, index, array) => {
            // If this user has the same overallRank as the previous one, they share the same rank
            if (index > 0 && user.overallRank === array[index - 1].overallRank) {
                return { ...user, subgroupRank: currentRank };  // Same rank as previous user
            }
            
            // Otherwise, assign the next rank
            currentRank = index + 1;  // Update current rank for the next user
            return { ...user, subgroupRank: currentRank };
        });

        const myRankInSubgroup = reRankedLeaderboard?.find((rank) => rank.userId === myUserId)?.subgroupRank;
        const amITiedSubgroup = amITied(myRankInSubgroup, reRankedLeaderboard, true, myUserId);
        const myRankWithTie = amITiedSubgroup ? `T${myRankInSubgroup}` : myRankInSubgroup;
        const totalUsersInSubgroup = reRankedLeaderboard?.length;
        return { subGroupName, myRankWithTie, totalUsersInSubgroup };
    }

    return (
        <Box
            sx={{
                padding: '10px 0px 20px 0px',
                justifyContent: 'center',
                margin: '0 auto',
                maxWidth: '675px',
                color: '#fff', // Set default text color for the container
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#fff', width: '55%' }}>Party</TableCell>
                        <TableCell sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#fff', textAlign: 'center' }}>Rank</TableCell>
                        <TableCell sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#fff', textAlign: 'center' }}>Default</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* First row remains the same for Pool Party Universe */}
                    <TableRow>
                        <TableCell 
                            onClick={() => handlePoolClick(mainGameId, gameName)} 
                            sx={{ 
                                color: '#fff', 
                                cursor: 'pointer', 
                                fontSize: '14px', 
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {gameName}
                        </TableCell>
                        <TableCell sx={{ color: '#fff' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: '600'}}>
                                    {!hasJoinDeadlinePassed ? 'T1' : amITiedOverall ? `T${myOverallRank}` : myOverallRank}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: isMobile ? '10px': '12px', color: '#fff', textAlign: 'center', fontWeight: '600' }}>
                                    Out of {totalUsers}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <Checkbox
                                checked={partyId === mainGameId}
                                sx={{
                                    color: '#fff', // Default color
                                    '&.Mui-checked': {
                                        color: '#fff', // Color when checked
                                    },
                                }}
                                onChange={() => handleDefaultPartyChange(mainGameId, gameName)}
                            />
                        </TableCell>
                    </TableRow>

                    {/* Dynamic rendering of subgroups */}
                    {gameData?.subGroups?.map((subGroup) => {
                        const { subGroupName, myRankWithTie, totalUsersInSubgroup } = findSubgroupInfo(subGroup.groupID);
                        //console.log("Subgroup ID: ", subGroup.groupID);
                        return (
                            <TableRow key={subGroup.groupID}>
                                <TableCell 
                                    onClick={() => handlePoolClick(subGroup.groupID)} 
                                    sx={{ 
                                        color: '#fff', 
                                        cursor: 'pointer', 
                                        fontSize: '14px', 
                                        fontWeight: '600',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {subGroupName}
                                </TableCell>
                                <TableCell sx={{ color: '#fff' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: '600'}}>
                                            {!hasJoinDeadlinePassed ? 'T1' : myRankWithTie}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: isMobile ? '10px': '12px', color: '#fff', textAlign: 'center', fontWeight: '600' }}>
                                            {`Out of ${totalUsersInSubgroup}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                    onChange={() => handleDefaultPartyChange(subGroup.groupID, subGroupName)}
                                >
                                    <Checkbox
                                        checked={partyId === subGroup.groupID}
                                        sx={{
                                            color: '#fff', // Default color
                                            '&.Mui-checked': {
                                                color: '#fff', // Color when checked
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
}

export default RankingsTable;