import './ChampionDisplay.scss';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ChampionDisplay = ({ whosInFirst, whosInFirstCharacterCount, partyName, line2, line4 }) => {
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    return (
    <Box
      className={`champion-display ${isMobile ? 'champion-display--mobile' : ''}`}
    >
      {/* Header */}
      <Box className="champion-display-header">
        <Typography
          className={`champion-display-header__text ${isMobile ? 'champion-display-header__text--mobile' : ''}`}
        >
          {partyName}'s <br /> {line2}
        </Typography>
      </Box>

      {/* Body */}
      <Box className="champion-display-body">
        <Box className="champion-display-content">
          {/* Left-side image */}
          <Box
            component="img"
            src={trophyImage}
            alt="Trophy Image"
            className="champion-display-image"
          />

          {/* Text in the middle */}
          <Box className="champion-display-text">
            <Typography
              className={`champion-display-text__title ${
                isMobile
                  ? whosInFirstCharacterCount > 17
                    ? 'champion-display-text__title--small-mobile'
                    : 'champion-display-text__title--mobile'
                  : ''
              }`}
            >
              {whosInFirst}
            </Typography>
          </Box>

          {/* Right-side image */}
          <Box
            component="img"
            src={trophyImage}
            alt="Trophy Image"
            className="champion-display-image"
          />
        </Box>
      </Box>

      {/* Footer
      {line4 && (
        <Box className="champion-display-footer">
            <Typography
            className={`champion-display-footer__text ${isMobile ? 'champion-display-footer__text--mobile' : ''}`}
            >
            We look forward to an even bigger and better <br /> {line4}
            </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default ChampionDisplay;
