import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import { getTeamLogo, getTeamName } from "../../../utils/SportsData";
import { formatAbbreviatedDateTime } from "../../../utils/DateFormatting/GameCardDateFormat";

const SurvivorSubmittedPickModal = ({ submitModalOpen, setSubmitModalOpen, teams, league, action, pick, intervalName, intervalDeadline }) => {
    //console.log('SurvivorSubmittedPickModal.js - pick: ', pick);
    const submittedTeamName = getTeamName(league, pick.teamId, teams);
    const submittedLogo = getTeamLogo(league, pick.teamId, teams);
    const submittedChangeTime = formatAbbreviatedDateTime(intervalDeadline, 'MMM D, h:mm A');
    return (
        <Modal
            open={submitModalOpen}
            onClose={() => setSubmitModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
            // Prevent closing when clicking outside
            onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    borderRadius: '8px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '328px',
                    height: 'auto',
                    bgcolor: '#FFF',
                    padding: '16px',
                    outline: 'none', // Remove default focus behavior
                }}
            >
                <Typography 
                    id="modal-modal-title" 
                    variant="h6" 
                    component="h2"
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        paddingBottom: '20px',
                    }}    
                >
                    Pick {action}
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        whiteSpace: 'nowrap',
                    }}>
                    {submittedTeamName} 
                    {/* <span style={{ fontWeight: '400'}}> ({restOfDisplay}) </span> */}
                </Typography>
                <img src={submittedLogo} alt="Team Logo" style={{ width: '48px', height: 'auto', margin: '20px auto', display: 'block' }} />
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginBottom: '20px'
                    }}>
                    You have successfully {action === 'Added' ? 'submitted' : 'removed'} your<br/> {intervalName} pick.
                </Typography><Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                    }}>
                    You can change this pick until<br/> {intervalDeadline !== 'TBD' ? 'the deadline on:' : 'first game of the day starts.'}
                </Typography>
                {intervalDeadline !== 'TBD' && (
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            textAlign: 'center',
                        }}
                    >
                        {submittedChangeTime}
                    </Typography>
                )}
                <Button 
                    variant='contained'
                    onClick={() => {
                        setSubmitModalOpen(false);
                        window.location.href = window.location.href;
                    }}
                    sx={{
                        width: '115px',
                        height: '38px',
                        marginTop: '20px', // Add top margin
                        display: 'block',
                        marginLeft: 'auto', // Center horizontally within a flex container
                        marginRight: 'auto', // Center horizontally within a flex container
                    }}
                >
                    OK
                </Button>
            </Box>
        </Modal>
    );
}

export default SurvivorSubmittedPickModal;