import React, { useState } from 'react';
import { Box } from '@mui/material'; // Import MUI components
import SponsorSelectionButtons from '../../SponsorTab/SponsorSelectionButtons';
import SponsorStoryPage from '../../SponsorTab/SponsorStoryPage';
import SponsorHighlightsPage from '../../SponsorTab/SponsorHighlightsPage';
import SponsorSisterLocationsPage from '../../SponsorTab/SponsorSisterLocationsPage';
import SponsorMerchPage from '../../SponsorTab/SponsorMerchPage';
import PageComponentsContainer from '../../PageComponents/PageComonentsContainer';
import '../../SponsorTab/SponsorTab.scss'; // Import SCSS file
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const LeaderboardSponsorPage = ({ gameData }) => {
    //console.log("Game Data in SponsorTab: ", gameData);

    const navigateToTop = useNavigateToTop();

    const mainGameId = gameData?.gameID;
    const gameAddressOld = gameData?.gameAddress;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    const dishes = gameData?.sponsorInfo?.dishes;
    const sisterLocations = gameData?.sponsorInfo?.sisterLocations;
    const merch = gameData?.sponsorInfo?.merch;
    //console.log("Dishes: ", dishes);

    const buttonsToDisplay = [`${sponsorAbbreviation}`];

    // Only add "Highlights" if dishes exist and have items
    if (dishes && dishes?.length > 0) {
        buttonsToDisplay.push('Highlights');
    }

    if (sisterLocations && sisterLocations?.length > 0) {
        buttonsToDisplay.push('Sister Locations');
    }

    if (merch && merch?.length > 0) {
        buttonsToDisplay.push(`Merch`);
    }

    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorTabImage = gameData?.sponsorInfo?.sponsorTabImage;
    const sponsorPageContent = sponsorInfo?.sponsorPageContent;
    const sponsorPageTwoContent = sponsorInfo?.sponsorPageTwoContent;
    //console.log("Sponsor Info: ", sponsorInfo);

    if (sponsorPageTwoContent) {
        const buttonName = sponsorPageTwoContent[0]?.buttonName;
        buttonsToDisplay.push(buttonName);
    }


    const [sponsorPageState, setSponsorPageState] = useState(buttonsToDisplay[0]);
    //console.log(sponsorPageState);
   

  return (
    <>
        {sponsorTabImage && (
            <Box className="sponsor-tab-image-container">
                <img
                    src={sponsorTabImage}
                    alt={sponsorName}
                    className="sponsor-tab-sponsor-header-image"
                />
            </Box>
        )}

        {buttonsToDisplay?.length > 1 && (
            <SponsorSelectionButtons 
                sponsorPageState={sponsorPageState} 
                setSponsorPageState={setSponsorPageState} 
                buttonsToDisplay={buttonsToDisplay}
                mainGameId={mainGameId}
                gameAddress={gameAddressOld}
            />
        )}

        {sponsorPageState === `${sponsorAbbreviation}` && !sponsorPageContent && 
            <SponsorStoryPage
                sponsorInfo={sponsorInfo}
            />
        }

        {sponsorPageState === `${sponsorAbbreviation}` && sponsorPageContent &&
            <PageComponentsContainer content={sponsorPageContent} />
        }

        {sponsorPageState === `${buttonsToDisplay[1]}` && sponsorPageTwoContent &&
            <PageComponentsContainer content={sponsorPageTwoContent} />
        }

        {sponsorPageState === 'Highlights' && 
            <SponsorHighlightsPage
                dishes={sponsorInfo?.dishes}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}    
            />
        }
        {sponsorPageState === 'Sister Locations' && 
            <SponsorSisterLocationsPage
                sisterLocations={sisterLocations}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}
            />
        }

        {sponsorPageState === `Merch` && 
            <SponsorMerchPage
                merch={merch}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}
                sponsorSecondaryLogo={sponsorSecondaryLogo}
            />
        }
    </>
  );
}

export default LeaderboardSponsorPage;