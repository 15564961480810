import React from 'react';
import '../bracketStyles/marchMadnessBracketHeading.scss';

const MarchMadnessBracketHeading = () => {
    return (
        <div className="march-madness-bracket-heading">
            <div className="top-section">
                <div className="name-date-pair">
                    <div className="name">Sweet 16</div>
                    <div className="bottom-dates">
                        <div className="date">March 20-21</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Elite 8</div>
                    <div className="bottom-dates">
                        <div className="date">March 27-28</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Final 4</div>
                    <div className="bottom-dates">
                        <div className="date">April 3</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Natty</div>
                    <div className="bottom-dates">
                        <div className="date">April 5</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Final 4</div>
                    <div className="bottom-dates">
                        <div className="date">April 3</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Elite 8</div>
                    <div className="bottom-dates">
                        <div className="date">March 27-28</div>
                    </div>
                </div>
                <div className="name-date-pair">
                    <div className="name">Sweet 16</div>
                    <div className="bottom-dates">
                        <div className="date">March 20-21</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarchMadnessBracketHeading;