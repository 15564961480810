// createGamesContext.js
import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWeeklyGames, fetchSeason, fetchNFLTeams, fetchBasicsSeason } from '../../../redux/actions/dataImport';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { createGame } from '../../../redux/actions/gameImport';
import BlockPoolChoices from '../createGamesAdditional/blockPoolChoices';
// import '../styles.scss';
const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {

  const dispatch = useDispatch();
  // Access the Redux state using useSelector
  const sportsData = useSelector(state => state.sportsData);
  const userData = getUserDataFromLocalStorage();
  const [gameName, setGameName] = useState('');
  const [gameCommissioner, setGameCommissioner] = useState('');
  const [gamePlayers, setGamePlayers] = useState([]);
  const [gameExpirationDate, setGameExpirationDate] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const [gameType, setGameType] = useState('');
  const [gameSport, setGameSport] = useState('');
  const [gameLeague, setGameLeague] = useState('');
  const [duration, setDuration] = useState('');
  const [eventDay, setEventDay] = useState('');
  const [gameTeams, setGameTeams] = useState([]);
  const [gameMembers, setGameMembers] = useState([]);
  const [weekOfGame, setWeekOfGame]  = useState('');
  const [commissionerNotes, setCommissionerNotes] = useState(`Thank you for joining ${gameName}, my NFL block pool. To pick your blocks, click on the (Action) tab above and select an open block. Please reach out to me at ${gameCommissioner} with any questions. Best of luck to everyone! -(Commissioner Name)`);
  const [selectedGameData, setSelectedGameData] = useState('');
  const [gameTypeData, setGameTypeData] = useState({
    numberOfBlocks: 100,
    pickNumbersFor: 'full',
    maxPicks: 'unlimited',
    numbersGenerated: '1',
    gameStart:'',
    teamSelection: '',
    gameSelections: [],
    revealNumbers:'all_claimed',
    axisConfiguration: 'HomeX',
    howNumbersGenerated: 'random',
  });
  const [pickemChoices, setPickemChoices] = useState({
    poolDuration: 'season',
    startingWeek: 1,
    picksPerWeek: 1,
    pickDeadline: 'game_start',
    spreadBet: 'straight',
    maxEntries: 1,
    tiebreaker: 'No',
    spreadInfo: null,
    allGames:[],
  });
  const formData = {
    gameName,
    gameCommissioner,
    gamePlayers,
    gameExpirationDate,
    isLive,
    gameSport,
    gameLeague,
    gameType,
    eventDay,
    gameTeams,
    gameTypeData,
    userData,
    commissionerNotes
  };
  const handleLeagueChange = (e) => {
    console.log(e.target.value);
    setGameLeague(e.target.value)
    handleGetNflTeams(e.target.value);
  }
  const LeagueSection = () => {
    if (gameSport === 'football') {
      return (
        <>
          <p>League</p>
            <div className="radio-button-container-league">
                <label
                    className={`radio-btn ${gameLeague === 'nfl' ? 'checked' : ''}`}
                    htmlFor="NFL"
                >
                NFL
                </label>
                <input
                    id="NFL"
                    name="league"
                    type="radio"
                    value="nfl"
                    style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                    onChange={(e) => handleLeagueChange(e)}
                />
                <label
                    className={`radio-btn ${gameLeague === 'cfb' ? 'checked' : ''}`}
                    htmlFor="NCAA"
                >
                CFB
                </label>
                <input
                    id="NCAA"
                    name="league"
                    type="radio"
                    value="cfb"
                    style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                    onChange={(e) => handleLeagueChange(e)}
                />
            </div>
        </>
      );
    } else if (gameSport === 'basketball') {
      return (
        <>
          <p>League</p>
          <div className="radio-button-container">
                <label
                    className={`radio-btn ${gameLeague === 'nba' ? 'checked' : ''}`}
                    htmlFor="NBA"
                >
                NBA
                </label>
                <input
                    id="NBA"
                    name="league"
                    type="radio"
                    value="nba"
                    style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                    onChange={(e) => handleLeagueChange(e)}
                />
                <label
                    className={`radio-btn ${gameLeague === 'ncaa' ? 'checked' : ''}`}
                    htmlFor="NCAA"
                >
                NCAA
                </label>
                <input
                    id="NCAA"
                    name="league"
                    type="radio"
                    value="ncaa"
                    style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                    onChange={(e) => handleLeagueChange(e)}
                />
            </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const handleGetNflTeams = () => {
    let data = {};
    dispatch(fetchNFLTeams(data));
  }

  const handleGetNFLSchedule = () => {
    let data = {
      seasonYear:'2023',
      league: 'NFL'
    }
    dispatch(fetchSeason(data));
  };
  const handleGetNFLScheduleBasics = () => {
    let data = {
      seasonYear:'2023',
      league: 'NFL'
    }
    dispatch(fetchBasicsSeason(data));
  };

  const handleGetNFLGamesByWeek = () => {
    let data = {
        weekNumber:gameTypeData.weekOfGame,
        seasonYear:'2023',
        league: gameLeague
    }
    dispatch(fetchWeeklyGames(data));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateGames();
  };

  const handleCreatePickemGames = () => {
    const {
      gameName,
      gameType,
      gameCommissioner,
      gamePlayers,
      gameSport,
      gameLeague,
      commissionerNotes,
      userData
    } = formData;
    const {
      poolDuration,
      startingWeek,
      picksPerWeek,
      pickDeadline,
      spreadBet,
      maxEntries,
      tiebreaker,
      spreadInfo,
      allGames
    } = pickemChoices;


    const gameStart = new Date(allGames[0].Date);
    const lastGame = new Date(allGames[allGames.length - 1].Date);
    const gameExpirationDate = gameStart ? new Date(lastGame?.getTime() + 12 * 60 * 60 * 1000) : null;
    const checkInTime = gameStart ? new Date(gameStart?.getTime() - 24 * 60 * 60 * 1000) : null;

    gamePlayers.push(userData.email);
    const pickemChoicePreReq = {
      poolDuration,
      startingWeek,
      picksPerWeek,
      pickDeadline,
      spreadBet,
      maxEntries,
      tiebreaker,
      spreadInfo,
    };

    let request = {
      gameName: gameName,
      gameCommissioner: gameCommissioner? gameCommissioner: userData.email,
      gamePlayers: gamePlayers,
      gameCreator: userData.email,
      commissionerNotes:commissionerNotes,
      pool:pickemChoicePreReq,
      poolType:gameType,
      gameExpirationDate: gameExpirationDate,
      gameSport: gameSport,
      gameLeague: gameLeague,
      selectedGameData: selectedGameData,
      checkInTime:checkInTime,
    };

    console.log(request)

    dispatch(createGame(request));

  };

  const handleCreateGames = () => {
    const {
      gameName,
      gameCommissioner,
      gamePlayers,
      gameSport,
      gameLeague,
      gameType,
      gameTypeData,
      commissionerNotes,
      userData} = formData;

    const gameSeries = gameTypeData?.gameSelections?.length > 0 ? gameTypeData?.gameSelections : [];

    const gameStart = new Date(selectedGameData[0].Date);
    const lastGame = new Date(selectedGameData[selectedGameData.length - 1].Date);
    const gameExpirationDate = gameStart ? new Date(lastGame?.getTime() + 12 * 60 * 60 * 1000) : null;
    const checkInTime = gameStart ? new Date(gameStart?.getTime() - 24 * 60 * 60 * 1000) : null;

    const gameDataFinal = [];
    selectedGameData.forEach((game) => {
      let eachGameByNumberGenerated = [];
      console.log(game);
      for (let i = 0; i < gameTypeData?.numbersGenerated; i++) {
        if (gameType === "Block_Pool") {
          console.log("Block_Pool");
          if (gameTypeData?.howNumbersGenerated === "random") {
            console.log("Random");
            let minX, maxX, minY, maxY;

            if (gameTypeData?.numberOfBlocks === 100) {
              // Define the range for X and Y axis numbers
              minX = 0;
              maxX = 10;
              minY = 0;
              maxY = 10;
            } else if (gameTypeData?.numberOfBlocks === 50) {
              // Define the range for X and Y axis numbers
              minX = 0;
              maxX = 10;
              minY = 0;
              maxY = 10;
            } else if (gameTypeData?.numberOfBlocks === 25) {
              // Define the range for X and Y axis numbers
              minX = 0;
              maxX = 5;
              minY = 0;
              maxY = 5;
            }

            let yAxis = [];
            let xAxis = [];
            let teamYAxis = '';
            let teamXAxis = '';

            if (gameTypeData?.axisConfiguration === 'HomeY') {
              teamYAxis = game.HomeTeam;
              teamXAxis = game.AwayTeam;
            }
            if (gameTypeData?.axisConfiguration === 'HomeX') {
              teamYAxis = game.AwayTeam;
              teamXAxis = game.HomeTeam;
            }
            if (gameTypeData?.axisConfiguration === 'AlwaysX') {
              teamXAxis = gameTypeData.teamSelection;
              if (gameTypeData.teamSelection !== game.HomeTeam) {
                teamYAxis = game.HomeTeam;
              } else {
                teamYAxis = game.AwayTeam;
              }
            }
            if (gameTypeData?.axisConfiguration === 'AlwaysY') {
              teamXAxis = gameTypeData.teamSelection;
              if (gameTypeData.teamSelection !== game.AwayTeam) {
                teamYAxis = game.AwayTeam;
              } else {
                teamYAxis = game.HomeTeam;
              }
            }

            // Generate an array of all possible numbers in the range
            const numbersX = Array.from({ length: maxX - minX }, (_, index) => index + minX);

            // Shuffle the numbers array using the Fisher-Yates algorithm
            for (let i = numbersX.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [numbersX[i], numbersX[j]] = [numbersX[j], numbersX[i]];
            }

            // Generate an array of all possible numbers in the range
            const numbersY = Array.from({ length: maxX - minX }, (_, index) => index + minX);

            // Shuffle the numbers array using the Fisher-Yates algorithm
            for (let i = numbersY.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [numbersY[i], numbersY[j]] = [numbersY[j], numbersY[i]];
            }
            // Take the first maxX numbers for X axis and the first maxY numbers for Y axis
            xAxis = numbersX.slice(0, maxX);
            yAxis = numbersY.slice(0, maxY);

            // Push the generated game data into eachGameByNumberGenerated array
            eachGameByNumberGenerated.push({
              gameId: game.GameKey,
              gameDate: game.Date,
              numberOfBlocks: gameTypeData?.numberOfBlocks,
              gameXAxis: xAxis,
              gameYAxis: yAxis,
              teamYAxis: teamYAxis,
              teamXAxis: teamXAxis,
              gameStadiums: game.StadiumDetails,
              gameStatus: game.Status,
              gameWeek: game.Week,
              seasonYear: game.Season,
            });
          }
        }
      }
      gameDataFinal.push({ generatedGame: eachGameByNumberGenerated });
    });

    gamePlayers.push(userData.email);
    gameTypeData['selectedGameData'] = gameDataFinal;
    let request = {
        gameName: gameName,
        gameCommissioner: gameCommissioner? gameCommissioner: userData.email,
        gamePlayers: gamePlayers,
        gameCreator: userData.email,
        commissionerNotes:commissionerNotes,
        pool:gameTypeData,
        poolType:gameType,
        gameExpirationDate: gameExpirationDate,
        gameSport: gameSport,
        gameLeague: gameLeague,
        gameStart: gameStart,
        checkInTime:checkInTime,
        teamSelection:gameTypeData.teamSelection,
        gameSeries:gameSeries,
        selectedGameData: selectedGameData,
    }
    console.log(request);
    dispatch(createGame(request));
  };
  const GameSchedule = () => {
    {sportsData?.sportsData.map((game) => {
        return (
            <option key={game.GameKey} value={game.id}>{game.AwayTeam} vs {game.HomeTeam}</option>
        );
    })}
  }
  const updateGameTypeData = (data) => {
    setGameTypeData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

    const updatePickem = (data) => {
    setPickemChoices((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const contextValue = {
    handleCreateGames,
    handleCreatePickemGames,
    handleGetNFLSchedule,
    handleGetNFLScheduleBasics,
    handleGetNflTeams,
    gameTypeData,
    updateGameTypeData,
    LeagueSection,
    gameName,
    setGameName,
    gameCommissioner,
    setGameCommissioner,
    gamePlayers,
    setGamePlayers,
    gameExpirationDate,
    setGameExpirationDate,
    isLive,
    setIsLive,
    gameType,
    setGameType,
    gameSport,
    setGameSport,
    gameLeague,
    setGameLeague,
    duration,
    setDuration,
    eventDay,
    setEventDay,
    gameTeams,
    setGameTeams,
    gameMembers,
    setGameMembers,
    gameTypeData,
    setGameTypeData,
    handleSubmit,
    weekOfGame,
    setWeekOfGame,
    GameSchedule,
    commissionerNotes,
    setCommissionerNotes,
    selectedGameData,
    setSelectedGameData,
    handleGetNFLGamesByWeek,
    pickemChoices,
    setPickemChoices,
    updatePickem
  };


  return <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>;
};