import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetSlip.scss';
import GameInfoRow from "./GameInfoRow";
import SingleBetWagerInput from "./SingleBetWagerInput";

const SingleBetBox = ({ bet, removeBet, pickState, setPickState, bankrollExceeded }) => {
    console.log("Bet in Bet Box", bet);
    return (
        <Box className="sportsbook-staight-bet-container">
            <GameInfoRow bet={bet} removeBet={removeBet}/>
            <Box className="spacer" />
            <SingleBetWagerInput bet={bet} pickState={pickState} setPickState={setPickState} bankrollExceeded={bankrollExceeded}/>
        </Box>
    )
}

export default SingleBetBox;