import { apiClient } from "../../apiClient";
import { apiUrl } from "../../config";
import { handleSetUserInLocalStorage } from "../../utils/users";

// Action types
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_SIGININ_WITH_GOOGLE_REQUEST =
  "USER_SIGININ_WITH_GOOGLE_REQUEST";
export const USER_SIGININ_WITH_GOOGLE_SUCCESS =
  "USER_SIGININ_WITH_GOOGLE_SUCCESS";
export const USER_SIGININ_WITH_GOOGLE_FAIL = "USER_SIGININ_WITH_GOOGLE_FAIL";

export const USER_CONTACT_REQUEST = "USER_CONTACT_REQUEST";
export const USER_CONTACT_SUCCESS = "USER_CONTACT_SUCCESS";
export const USER_CONTACT_FAIL = "USER_CONTACT_FAIL";

export const CHANGE_GENERAL_USER_DATA_REQUEST =
  "CHANGE_GENERAL_USER_DATA_REQUEST";
export const CHANGE_GENERAL_USER_DATA_SUCCESS =
  "CHANGE_GENERAL_USER_DATA_SUCCESS";
export const CHANGE_GENERAL_USER_DATA_FAIL = "CHANGE_GENERAL_USER_DATA_FAIL";

export const CHANGE_MARKETING_USER_DATA_REQUEST =
  "CHANGE_MARKETING_USER_DATA_REQUEST";
export const CHANGE_MARKETING_USER_DATA_SUCCESS =
  "CHANGE_MARKETING_USER_DATA_SUCCESS";
export const CHANGE_MARKETING_USER_DATA_FAIL =
  "CHANGE_MARKETING_USER_DATA_FAIL";

export const CHANGE_USER_PASSWORD_REQUEST = "CHANGE_USER_PASSWORD_REQUEST";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAIL = "CHANGE_USER_PASSWORD_FAIL";

export const UPDATE_USER_DATA_REQUEST = "UPDAT_USER_DATA_REQUEST";
export const UPDATE_USER_DATA_SUCCESS = "UPDAT_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDAT_USER_DATA_FAIL";

export const GET_USERS_FOR_LEADERBOARD_REQUEST =
  "GET_USERS_FOR_LEADERBOARD_REQUEST";
export const GET_USERS_FOR_LEADERBOARD_SUCCESS =
  "GET_USERS_FOR_LEADERBOARD_SUCCESS";
export const GET_USERS_FOR_LEADERBOARD_FAIL = "GET_USERS_FOR_LEADERBOARD_FAIL";

export const GET_USERS_BY_MULLIGEN_MADNESS_REQUEST =
  "GET_USERS_BY_MULLIGEN_MADNESS_REQUEST";
export const GET_USERS_BY_MULLIGEN_MADNESS_SUCCESS =
  "GET_USERS_BY_MULLIGEN_MADNESS_SUCCESS";
export const GET_USERS_BY_MULLIGEN_MADNESS_FAIL =
  "GET_USERS_BY_MULLIGEN_MADNESS_FAIL";

export const GET_NBA_PLAYOFF_LEADERBOARD_REQUEST =
  "GET_NBA_PLAYOFF_LEADERBOARD_REQUEST";
export const GET_NBA_PLAYOFF_LEADERBOARD_SUCCESS =
  "GET_NBA_PLAYOFF_LEADERBOARD_SUCCESS";
export const GET_NBA_PLAYOFF_LEADERBOARD_FAIL =
  "GET_NBA_PLAYOFF_LEADERBOARD_FAIL";

export const GET_SUBGROUP_LEADERBOARD_REQUEST =
  "GET_SUBGROUP_LEADERBOARD_REQUEST";
export const GET_SUBGROUP_LEADERBOARD_SUCCESS =
  "GET_SUBGROUP_LEADERBOARD_SUCCESS";
export const GET_SUBGROUP_LEADERBOARD_FAIL = "GET_SUBGROUP_LEADERBOARD_FAIL";

//TODO: add facebook login
// export const USER_SIGININ_WITH_FACEBOOK_REQUEST = 'USER_SIGININ_WITH_FACEBOOK_REQUEST';
// export const USER_SIGININ_WITH_FACEBOOK_SUCCESS = 'USER_SIGININ_WITH_FACEBOOK_SUCCESS';

// Action creators
export const userRegisterRequest = (data) => ({
  type: USER_REGISTER_REQUEST,
  payload: data,
});

export const userRegisterSuccess = (data) => ({
  type: USER_REGISTER_SUCCESS,
  payload: data,
});

export const userRegisterFail = (error) => ({
  type: USER_REGISTER_FAIL,
  payload: error,
});

export const userSigninRequest = (data) => ({
  type: USER_SIGNIN_REQUEST,
  payload: data,
});

export const userSigninSuccess = (data) => ({
  type: USER_SIGNIN_SUCCESS,
  payload: data,
});

export const userSigninFail = (error) => ({
  type: USER_SIGNIN_FAIL,
  payload: error,
});

export const userLogoutRequest = () => ({
  type: USER_LOGOUT_REQUEST,
});

export const userLogoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
});

export const userLogoutFail = (error) => ({
  type: USER_LOGOUT_FAIL,
  payload: error,
});

export const userContactRequest = (data) => ({
  type: USER_CONTACT_REQUEST,
  payload: data,
});

export const userContactSuccess = (data) => ({
  type: USER_CONTACT_SUCCESS,
  payload: data,
});

export const userContactFail = (error) => ({
  type: USER_CONTACT_FAIL,
  payload: error,
});

export const changeGeneralUserDataRequest = (data) => ({
  type: CHANGE_GENERAL_USER_DATA_REQUEST,
  payload: data,
});

export const changeGeneralUserDataSuccess = (data) => ({
  type: CHANGE_GENERAL_USER_DATA_SUCCESS,
  payload: data,
});

export const changeGeneralUserDataFail = (error) => ({
  type: CHANGE_GENERAL_USER_DATA_FAIL,
  payload: error,
});

export const changeMarketingUserDataRequest = (data) => ({
  type: CHANGE_MARKETING_USER_DATA_REQUEST,
  payload: data,
});

export const changeMarketingUserDataSuccess = (data) => ({
  type: CHANGE_MARKETING_USER_DATA_SUCCESS,
  payload: data,
});

export const changeMarketingUserDataFail = (error) => ({
  type: CHANGE_MARKETING_USER_DATA_FAIL,
  payload: error,
});

export const changeUserPasswordRequest = (data) => ({
  type: CHANGE_USER_PASSWORD_REQUEST,
  payload: data,
});

export const changeUserPasswordSuccess = (data) => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const changeUserPasswordFail = (error) => ({
  type: CHANGE_USER_PASSWORD_FAIL,
  payload: error,
});

export const updateUserDataRequest = (data) => ({
  type: UPDATE_USER_DATA_REQUEST,
  payload: data,
});

export const updateUserDataSuccess = (data) => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload: data,
});

export const updateUserDataFail = (error) => ({
  type: UPDATE_USER_DATA_FAIL,
  payload: error,
});

export const getUsersForLeaderboardRequest = () => ({
  type: GET_USERS_FOR_LEADERBOARD_REQUEST,
});

export const getUsersForLeaderboardSuccess = (data) => ({
  type: GET_USERS_FOR_LEADERBOARD_SUCCESS,
  payload: data,
});

export const getUsersForLeaderboardFail = (error) => ({
  type: GET_USERS_FOR_LEADERBOARD_FAIL,
  payload: error,
});

export const getUsersByMulligenMadnessRequest = () => ({
  type: GET_USERS_BY_MULLIGEN_MADNESS_REQUEST,
});

export const getUsersByMulligenMadnessSuccess = (data) => ({
  type: GET_USERS_BY_MULLIGEN_MADNESS_SUCCESS,
  payload: data,
});

export const getUsersByMulligenMadnessFail = (error) => ({
  type: GET_USERS_BY_MULLIGEN_MADNESS_FAIL,
  payload: error,
});

export const getNbaPlayoffLeaderboardRequest = () => ({
  type: GET_NBA_PLAYOFF_LEADERBOARD_REQUEST,
});

export const getNbaPlayoffLeaderboardSuccess = (data) => ({
  type: GET_NBA_PLAYOFF_LEADERBOARD_SUCCESS,
  payload: data,
});

export const getNbaPlayoffLeaderboardFail = (error) => ({
  type: GET_NBA_PLAYOFF_LEADERBOARD_FAIL,
  payload: error,
});

export const getSubgroupLeaderboardRequest = () => ({
  type: GET_SUBGROUP_LEADERBOARD_REQUEST,
});

export const getSubgroupLeaderboardSuccess = (data) => ({
  type: GET_SUBGROUP_LEADERBOARD_SUCCESS,
  payload: data,
});

export const getSubgroupLeaderboardFail = (error) => ({
  type: GET_SUBGROUP_LEADERBOARD_FAIL,
  payload: error,
});

// Async action: fetch data from the server
export const userSigninAction = (data, redirectPath = '/') => {
  return dispatch => {
    dispatch(userSigninRequest(data));
    apiClient
      .post(`${apiUrl}/user/login`, data, {
        withCredentials: true,
      })
      .then((response) => {
        handleSetUserInLocalStorage(response);
        window.location.href = redirectPath ? `/${redirectPath}` : '/';
        dispatch(userSigninSuccess(response));
      })
      .catch((error) => {
        dispatch(userSigninFail(error));
        return "Error";
      });
  };
};

// Action creators

export const userRegisterAction = (data, redirectPath = '/') => {
  return (dispatch) => {
    dispatch(userRegisterRequest(data));
    apiClient
      .post(`${apiUrl}/user/sign-up`, data)
      .then((response) => {
        handleSetUserInLocalStorage(response);
        //Set route to welcome page
        window.location.href = redirectPath ? `/${redirectPath}` : '/';

        dispatch(userRegisterSuccess(response.data));
      })
      .catch((error) => {
        dispatch(userRegisterFail(error));
      });
  };
};

export const userLogoutAction = (params) => {
  return (dispatch) => {
    let authorizationHeader = localStorage.getItem("authorization");

    dispatch(userLogoutRequest());
    apiClient
      .post(
        `${apiUrl}/user/logout`,
        {},
        {
          headers: {
            Authorization: authorizationHeader,
            "Content-Type": "application/json", // Set the appropriate content type
          },
        }
      )
      .then((response) => {
        const isTokenExpired = params?.logoutType === "token-expired";
        dispatch(userLogoutSuccess());
        removeUserDataFromLocalStorage();
        //Set route to welcome page
        window.location.href = `/login${
          isTokenExpired ? "?token-expired=true" : ""
        }`;
      })
      .catch((error) => {
        dispatch(userLogoutFail(error));
      });
  };
};

export const userSigninWithGoogleAction = () => {
  return (dispatch) => {
    dispatch(userSigninRequest());
    apiClient
      .post(`${apiUrl}/user/google`)
      .then((response) => {
        dispatch(userSigninSuccess(response.data));
      })
      .catch((error) => {
        dispatch(userSigninFail(error));
      });
  };
};

export const emailContactAction = (data) => {
  return (dispatch) => {
    dispatch(userSigninRequest());
    apiClient
      .post(`${apiUrl}/user/contact`, data)
      .then((response) => {
        dispatch(userSigninSuccess(response.data));
      })
      .catch((error) => {
        dispatch(userSigninFail(error));
      });
  };
};

export const changeMarketingUserDataAction = (data) => {
  return (dispatch) => {
    let authorizationHeader = localStorage.getItem("authorization");

    dispatch(changeMarketingUserDataRequest());

    apiClient
      .post(`${apiUrl}/user/change-marketing-data`, data, {
        headers: {
          Authorization: authorizationHeader,
          "Content-Type": "application/json", // Set the appropriate content type
        },
      })
      .then((response) => {
        dispatch(changeMarketingUserDataSuccess(response.data));

        let strinfiedUserData = JSON.stringify(response.data.updatedUser);

        localStorage.setItem("userData", strinfiedUserData);
      })
      .catch((error) => {
        dispatch(changeMarketingUserDataFail(error));
      });
  };
};

export const getUsersForLeaderboardAction = () => {
  return (dispatch) => {
    dispatch(getUsersForLeaderboardRequest());
    apiClient
      .get(`${apiUrl}/user/leaderboard`)
      .then((response) => {
        dispatch(getUsersForLeaderboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUsersForLeaderboardFail(error));
      });
  };
};

export const getUsersByMulligenMadnessAction = () => {
  return (dispatch) => {
    dispatch(getUsersByMulligenMadnessRequest());
    apiClient
      .get(`${apiUrl}/user/get-users-by-mulligen-madness`)
      .then((response) => {
        dispatch(getUsersByMulligenMadnessSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUsersByMulligenMadnessFail(error));
      });
  };
};

export const getNbaPlayoffLeaderboardAction = () => {
  let authorizationHeader = localStorage.getItem("authorization");

  return (dispatch) => {
    dispatch(getNbaPlayoffLeaderboardRequest());
    apiClient
      .get(`${apiUrl}/user/get-nba-playoff-leaderboard`, {
        headers: {
          Authorization: authorizationHeader,
          "Content-Type": "application/json", // Set the appropriate content type
        },
      })
      .then((response) => {
        dispatch(getNbaPlayoffLeaderboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getNbaPlayoffLeaderboardFail(error));
      });
  };
};

export const getSubgroupLeaderboardAction = () => {
  let authorizationHeader = localStorage.getItem("authorization");

  return (dispatch) => {
    dispatch(getSubgroupLeaderboardRequest());
    apiClient
      .get(`${apiUrl}/user/get-sub-leaderboard`, {
        headers: {
          Authorization: authorizationHeader,
          "Content-Type": "application/json", // Set the appropriate content type
        },
      })
      .then((response) => {
        dispatch(getSubgroupLeaderboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getSubgroupLeaderboardFail(error));
      });
  };
};

// Selector function to retrieve user data from local storage
export const getUserDataFromLocalStorage = () => {
  const userData = localStorage.getItem("userData");
  return userData ? JSON.parse(userData) : null;
};

// Selector function to retrieve user data from local storage
export const removeUserDataFromLocalStorage = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("authorization");
  localStorage.removeItem("currentGame");
};
