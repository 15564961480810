import React, { useCallback, useState } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { useValidateEmailIsUnique } from "../../hooks/auth";
import NextAndBackButtons from "./NextAndBackButtons";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const validateEmailAddressStructure = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export default function AccountSetup({ currentStep, goToNextStep, email, setEmail }) {
  const { mutate: validateEmailIsUnique } = useValidateEmailIsUnique();
  // const [email, setEmailState] = useState("");
  const [emailIsNotUnique, setEmailIsNotUnique] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();

  const handleEmailChange = useCallback((e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(true);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setEmailIsNotUnique(false); // Reset emailIsNotUnique state
      const isEmailValid = validateEmailAddressStructure(email);
      if (!email || !isEmailValid) {
        setIsValidEmail(false);
        return;
      }
      validateEmailIsUnique(
        { email },
        {
          onSuccess: (isUnique) => {
            if (isUnique) {
              goToNextStep();
              setEmail(email);
            } else {
              setEmailIsNotUnique(true);
            }
          },
        }
      );
    },
    [email, setEmail, goToNextStep, validateEmailIsUnique]
  );

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        id="email"
        name="email"
        label="Email address"
        variant="outlined"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={!isValidEmail}
        helperText={!isValidEmail && "Please enter a valid email address."}
      />
      {emailIsNotUnique && (
        <div className="text-center">
          <Alert severity="error" className="text-center w-full">
            <div>Email is already in use.</div>
            <Button variant="text">
              <Link className="hover:underline text-sm" to="/forgot-password">
                Forgot your password?
              </Link>
            </Button>
          </Alert>
          <div className="pt-2 pb-6   border border-solid border-t-0 border-l-0 border-r-0">
            <div className="mt-2" />
            <Button
              variant="outlined"
              color="secondary"
              className="block w-full mt-2"
            >
              <Link to="/login">Go to login</Link>
            </Button>
          </div>
        </div>
      )}
      <NextAndBackButtons
        nextButtonText={"Continue"}
        onNextButtonClick={handleSubmit}
        onBackButtonClick={() => navigateToTop(`/login`)}
      />
    </Box>
  );
}
