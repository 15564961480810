import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import './CardPieces.scss';

const GameInfoRow = ({ gameId, teams, schedule, standings, gatherGameInfo }) => {

  const gameInfo = gatherGameInfo(gameId, teams, schedule, standings);
    const {
        homeLogo,
        awayLogo,
        homeName,
        awayName,
        homeRank,
        awayRank,
        homeRecord,
        awayRecord,
        homeScore,
        awayScore,
        gameTime,
        gameDateTime,
        gameLine,
        status,
    } = gameInfo;
        //console.log("Game Line", gameLine);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  
  const homeNameAndRank = homeRank? `(${homeRank}) ${homeName}` : homeName;
  const awayNameAndRank = awayRank? `(${awayRank}) ${awayName}` : awayName;
  
  let textLine1 = null;
  if (status === 'Scheduled') {
    const datetime = DateTime.fromISO(gameTime);
    textLine1 = datetime.toFormat('EEE M/d');
  } else if (status === 'InProgress') {
    textLine1 = "LIVE";
  } else if (status === 'Final' || status === 'F/OT') {
    textLine1 = status;
  }
  let textLine2 = null;
  if (status === 'Scheduled') {
    const gameTime = DateTime.fromISO(gameDateTime, { zone: 'America/New_York' });
    
    if (gameTime.hour === 0 && gameTime.minute === 0) {
      textLine2 = 'TBD';
    } else {
      textLine2 = gameTime.toFormat('h:mma').toLowerCase() + ' ET';
    }
  } else if (status === 'InProgress') {
    textLine2 = gameLine;
  }

  let textLine3 = null; 
  if (status === 'Scheduled') {
    textLine3 = gameLine;
  }

  return (
    <Box className="quick-pick-game-info">
      <Box className="quick-pick-game-info__team quick-pick-game-info__away-team">
        <Box className="quick-pick-game-info__logo-container">
          <img src={awayLogo} alt="Away Team Logo" className="quick-pick-game-info__logo" />
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__record">
            {awayRecord}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__score-column quick-pick-game-info__away-score-column">
        <Box>
          <Typography className="quick-pick-game-info__team-name">
            {awayNameAndRank}
          </Typography>
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__score">
            {awayScore}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__game-details">
        <Typography className={`quick-pick-game-info__text-line ${textLine1 === "LIVE" ? "quick-pick-game-info__text-line--live" : ""}`}>
          {textLine1 ? textLine1 : ''}
        </Typography>
        <Typography className="quick-pick-game-info__text-line">
          {textLine2 ? textLine2 : ''}
        </Typography>
        <Typography className="quick-pick-game-info__text-line">
          {textLine3 ? textLine3 : ''}
        </Typography>
      </Box>

      <Box className="quick-pick-game-info__score-column quick-pick-game-info__home-score-column">
        <Box>
          <Typography className="quick-pick-game-info__team-name">
            {homeNameAndRank}
          </Typography>
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__score">
            {homeScore}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__team quick-pick-game-info__home-team">
        <Box className="quick-pick-game-info__logo-container">
          <img src={homeLogo} alt="Home Team Logo" className="quick-pick-game-info__logo" />
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__record">
            {homeRecord}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GameInfoRow;
