import React, { useState } from "react";
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext";
import { useQuickPickGamesContext } from "../../../QuickPickGames/context/QuickPickGamesContext";
import { Typography, Box } from "@mui/material";
import QuickPickMultipleEntryView from "./QuickPickMultipleEntryView";
import QuickPickSingleEntryView from "./QuickPickSingleEntryView";

const QuickPickGamesUserDash = ({ canIAddAnEntry, setIndexToRemove, setRemoveModalOpen }) => {
    const {
        allUsersEntries,
        usersIndexesInThisPool,
        myLeaderboardInfo,
        masterPoolData,
        pickIndex,
        hasJoinDeadlinePassed,
        setJoinOpen,
        isThisPoolPartyGame,
        isUserAdmin,
        intervalInfo,
    } = useUserAndGameContext();

    const {
        picksForTheCurrentInterval,
        theCurrentInterval,
        oneWeekEvent,
    } = useQuickPickGamesContext();

    const totalEntriesCount = myLeaderboardInfo?.entryCount|| 0;
    //console.log("My Leaderboard Info in QuickPickGamesUserDash: ", myLeaderboardInfo);

    const currentIntervalName = intervalInfo?.info?.find(interval => interval?.interval === Number(theCurrentInterval))?.label;
    //console.log("Current Interval Name in QuickPickGamesUserDash: ", currentIntervalName);
    function findMyRank(myLeaderboardInfo, entryName) {
        if (!myLeaderboardInfo || !entryName) return null;
        
        const rank = myLeaderboardInfo?.myInfo?.find(entry => entry?.username === entryName)?.rank;
        return rank;
    }

    function getTotalPoints(picks) {
        if (!Array.isArray(picks)) return 0; // Ensure picks is an array
    
        return picks.reduce((acc, pick) => {
            return acc + (Number(pick.points) || 0); // Convert to number, default to 0
        }, 0);
    }
    
    function getIntervalsPoints(picks, interval) {
        if (!Array.isArray(picks)) return 0; // Ensure picks is an array
    
        return picks.reduce((acc, pick) => {
            return Number(pick.interval) === Number(interval) 
                ? acc + (Number(pick.points) || 0) // Ensure pick.points is a valid number
                : acc;
        }, 0);
    }
    

     // Check if we have the necessary data
     if (!allUsersEntries || !usersIndexesInThisPool || usersIndexesInThisPool.length === 0) {
        return (
            <Box sx={{ padding: 2 }}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Loading your entries...
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {allUsersEntries && usersIndexesInThisPool ? 
                        `Looking for entries (${usersIndexesInThisPool.length} index(es) in pool)` : 
                        "Waiting for entry data..."
                    }
                </Typography>
            </Box>
        );
    }

    // Get entries for this pool
    const entriesInThisPool = usersIndexesInThisPool
        .filter(index => index >= 0 && index < allUsersEntries.length)
        .map(index => allUsersEntries[index]);

    return (
        <Box>
            {entriesInThisPool?.length === 1 && !canIAddAnEntry && (
                <QuickPickSingleEntryView
                    entry={entriesInThisPool[0]}
                    findMyRank={findMyRank}
                    theCurrentInterval={theCurrentInterval}
                    picksForTheCurrentInterval={picksForTheCurrentInterval}
                    getTotalPoints={getTotalPoints}
                    getIntervalsPoints={getIntervalsPoints}
                    setIndexToRemove={setIndexToRemove}
                    myLeaderboardInfo={myLeaderboardInfo}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isThisPoolPartyGame={isThisPoolPartyGame}
                    isUserAdmin={isUserAdmin}
                    totalEntriesCount={totalEntriesCount}
                    oneWeekEvent={oneWeekEvent}
                    pickIndex={pickIndex}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    currentIntervalName={currentIntervalName}
                />
            )}
            {(entriesInThisPool?.length > 1 || canIAddAnEntry) && (
                <QuickPickMultipleEntryView
                    entries={entriesInThisPool}
                    findMyRank={findMyRank}
                    theCurrentInterval={theCurrentInterval}
                    picksForTheCurrentInterval={picksForTheCurrentInterval}
                    getTotalPoints={getTotalPoints}
                    getIntervalsPoints={getIntervalsPoints}
                    setIndexToRemove={setIndexToRemove}
                    myLeaderboardInfo={myLeaderboardInfo}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isThisPoolPartyGame={isThisPoolPartyGame}
                    isUserAdmin={isUserAdmin}
                    totalEntriesCount={totalEntriesCount}
                    oneWeekEvent={oneWeekEvent}
                    currentIntervalName={currentIntervalName}
                    pickIndex={pickIndex}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    canIAddAnEntry={canIAddAnEntry}
                />
            )}  
        </Box>
    );
}

export default QuickPickGamesUserDash;
