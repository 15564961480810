import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import BracketGame from './BracketGame';
import BracketChamp from './BracketChamp';
import "./SixteenTeamBracket.scss";
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../../BracketGames/context/BracketGamesContext';
import { round } from 'lodash';

const SixteenTeamBracket = ({
    bracket,
    games,
    teams,
    league,
    handleGameClick,
    round1Ref,
    round2Ref,
    round3Ref,
    round4Ref,
    round5Ref,
    pickState,
}) => {
    const [isPastFirstColumn, setIsPastFirstColumn] = useState(false);
    const {
        setPickState,
        hasJoinDeadlinePassed,
        intervalInfo,
    } = useUserAndGameContext();

    useEffect(() => {
        const container = document.querySelector('.regional-bracket-container');
        if (!container) return;
    
        const handleScroll = () => {
            if (round2Ref.current) {
                const rect = round2Ref.current.getBoundingClientRect();
                const threshold = window.innerWidth * 0.01;
                
                setIsPastFirstColumn((prevState) => {
                    const newState = rect.left < threshold;
                    
                    // If we're transitioning into compressed state
                    if (!prevState && newState) {
                        // Scroll to top of column
                        requestAnimationFrame(() => {
                            const gameElement = round2Ref.current.querySelector('.regional-bracket-game');
                            if (gameElement) {
                                const rect = gameElement.getBoundingClientRect();
                                window.scrollTo({
                                    top: rect.top + window.pageYOffset - 150,
                                    behavior: 'smooth'
                                });
                            }
                        });
                    }
                    return newState;
                });
            }
        };
    
        container.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            container.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [round2Ref]);

    return (
        <Box className="regional-bracket-container" sx={{ marginTop: '50px'}}>
            <Box className="regional-bracket">
                {/* First Round Column */}
                <Box className="regional-bracket-column regional-bracket-column-games-first">
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '30px', marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[0]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[0]?.points} Point{intervalInfo?.info[0]?.points > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                    <Box ref={round1Ref} >
                        {Array(8).fill(null).map((_, index) => (
                            <Box key={`round1-${index}`} className="regional-bracket-game">
                                <BracketGame
                                    bracket={bracket}
                                    round={1}
                                    tournamentDisplayOrder={index + 1}
                                    games={games}
                                    teams={teams} 
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    pickState={pickState}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* First Round Connectors */}
                <Box className="regional-bracket-connector-column first-round">
                    {Array(4).fill(null).map((_, index) => (
                        <Box 
                            key={`connector1-${index}`} 
                            className={`connector-wrapper ${isPastFirstColumn ? 'first-round-compressed' : 'first-round-expanded'}`}
                        >
                            <div className="vertical-line" />
                        </Box>
                    ))}
                </Box>

                {/* Second Round Column */}
                <Box className="regional-bracket-column regional-bracket-column-games-second">
                    <Box sx={{ width: '230px', marginBottom: '84.5px', paddingBottom: '30px', marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[1]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[1]?.points} Point{intervalInfo?.info[1]?.points > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                    <Box ref={round2Ref}>
                        {Array(4).fill(null).map((_, index) => (
                            <React.Fragment key={`round2-${index}`}>
                                <Box className="regional-bracket-game">
                                    <BracketGame
                                        bracket={bracket}
                                        round={2}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams} 
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{ 
                                    height: isPastFirstColumn ? '40px' : '112px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>

                {/* Second Round Connectors */}
                <Box className="regional-bracket-connector-column second-round">
                    {Array(2).fill(null).map((_, index) => (
                        <Box 
                            key={`connector2-${index}`} 
                            className={`connector-wrapper ${isPastFirstColumn ? 'second-round-compressed' : 'second-round-expanded'}`}
                        >
                            <div className="vertical-line" />
                        </Box>
                    ))}
                </Box>

                {/* Sweet 16 Column */}
                <Box className="regional-bracket-column regional-bracket-column-games-third">
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '214.5px' : '250px', marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[2]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[2]?.points} Point{intervalInfo?.info[2]?.points > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                    <Box ref={round3Ref}>
                        {Array(2).fill(null).map((_, index) => (
                            <React.Fragment key={`round3-${index}`}>
                                <Box key={`round3-${index}`} className="regional-bracket-game">
                                    <BracketGame
                                        bracket={bracket}
                                        round={3}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams} 
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{ 
                                    height: isPastFirstColumn ? '288px' : '432.5px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>

                {/* Sweet 16 Connectors */}
                <Box className="regional-bracket-connector-column sweet-16">
                    <Box 
                        className={`connector-wrapper ${isPastFirstColumn ? 'sweet-16-compressed' : 'sweet-16-expanded'}`}
                    >
                        <div className="vertical-line" />
                    </Box>
                </Box>

                {/* Elite 8 Column */}
                <Box className="regional-bracket-column regional-bracket-column-games-final">
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '463px' : '570.5px', marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[3]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[3]?.points} Point{intervalInfo?.info[3]?.points > 1 ? 's' : ''}
                        </Typography>
                    </Box>
                    <Box ref={round4Ref}>
                        <Box className="regional-bracket-game">
                            <BracketGame
                                bracket={bracket}
                                round={4}
                                tournamentDisplayOrder={1}
                                games={games}
                                teams={teams} 
                                league={league}
                                handleGameClick={handleGameClick}
                                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                pickState={pickState}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Champion Column */}
                <Box className="regional-bracket-column regional-bracket-column-champion" sx={{ marginLeft: '14px'}}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '575px' : '675px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{bracket} Champion</Typography>
                    </Box>
                    <Box ref={round5Ref}>
                        <BracketChamp
                            bracket={bracket}
                            round={5}
                            games={games}
                            teams={teams} 
                            league={league}
                            pickState={pickState}
                            handleGameClick={handleGameClick}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SixteenTeamBracket;