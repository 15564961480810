import React, { useState } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HoleStats from './HoleStats';
import PoolBirdies from './PoolBirdies';
import AllBirdies from './AllBirdies';
import '../styles/birdiePool.scss';

const BirdieStats = ({ handleClickState }) => {
  const { birdiePoolData, clubLeaderboardInfo, groupState, headerDisplayName } = useBirdieContext();
  //console.log("STARTING STATS", birdiePoolData?.courseScorecardInfo, clubLeaderboardInfo);
  const [birdieStatsPage, setBirdieStatsPage] = useState('PoolBirdies');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      {/* <h1 style={{ textDecoration: 'underline' }}>{groupState ?  groupState : headerDisplayName} Birdie Stats</h1> */}
      <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '500px', margin:'15px auto'}}>
        <span
          style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: birdieStatsPage === 'PoolBirdies' ? 'underline' : 'none', backgroundColor: birdieStatsPage === 'PoolBirdies' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px',  }}
          onClick={() => setBirdieStatsPage('PoolBirdies')}
        >
          Pool Birdies
        </span>
        <span
          style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: birdieStatsPage === 'AllBirdies' ? 'underline' : 'none', backgroundColor: birdieStatsPage === 'AllBirdies' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px' }}
          onClick={() => setBirdieStatsPage('AllBirdies')}
        >
          All Birdies
        </span>
        <span
          style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: birdieStatsPage === 'HoleStats' ? 'underline' : 'none', backgroundColor: birdieStatsPage === 'HoleStats' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer' }}
          onClick={() => setBirdieStatsPage('HoleStats')}
        >
          Hole Stats
        </span>
      </div>

      {birdieStatsPage === 'PoolBirdies' && <PoolBirdies handleClickState={handleClickState}/>}
      {birdieStatsPage === 'AllBirdies' && <AllBirdies handleClickState={handleClickState}/>}
      {birdieStatsPage === 'HoleStats' && <HoleStats />}
    </Box>
  );
}

export default BirdieStats;