import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { userLogoutAction } from "../../redux/actions/loginImport";

const UserDropDown = ({ isDropDownOpen, toggleDropDown }) => {
  const dispatch = useDispatch();
  const dropdownRef = React.useRef(null);

  const logout = () => {
    dispatch(userLogoutAction());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropDown(event);
      }
    };

    if (isDropDownOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropDownOpen, toggleDropDown]);

  return (
    <>
      {isDropDownOpen && (
        <div
          className="user-dropdown"
          ref={dropdownRef}
          style={{
            position: "fixed",
            right: "25px",
            background: "#fff",
            zIndex: 200,
            width: "220px",
            top: "80px",
            boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.25)",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <ul style={{ listStyle: "none", textAlign: "left" }}>
            <li style={{ cursor: "pointer", padding: "8px", color: "#000" }}>
              <Link to="/profile/general">My Profile</Link>
            </li>
            <li
              style={{ cursor: "pointer", padding: "8px", color: "#000" }}
              onClick={logout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default UserDropDown;
