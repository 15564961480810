import React, { useRef } from 'react';
import { Box, TextField, Typography } from '@mui/material';

const PinCodeEntry = ({ 
    pin,
    setPin,
    error = false,
    errorMessage = 'Incorrect PIN. Please try again.',
    length = 4,
    onComplete,
    gameName,
}) => {
    const inputRefs = useRef([]);

    const handlePinChange = (element, index) => {
        const value = element.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            
            // Move to next input if value is entered and not last field
            if (value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }

            // Call onComplete for both complete and incomplete cases
            const completedPin = newPin.join('');
            onComplete?.(completedPin, newPin.every(digit => digit !== ''));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && pin[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Typography sx={{ 
                color: '#002129',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                mb: 2
            }}>
                Enter Pin Code to join {gameName}.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                {pin.map((num, index) => (
                    <TextField
                        key={index}
                        value={num}
                        onChange={(e) => handlePinChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        inputProps={{ 
                            maxLength: 1, 
                            style: { textAlign: 'center' } 
                        }}
                        sx={{ 
                            width: '40px',
                            '& .MuiInputBase-root': {
                                height: '40px'
                            }
                        }}
                        inputRef={(ref) => (inputRefs.current[index] = ref)}
                    />
                ))}
            </Box>
            {error && (
                <Typography sx={{ 
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '14px',
                    mt: 1
                }}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
};

export default PinCodeEntry;