import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

// Function to handle the POST request
export const useUpdatePool = () => {
  return useMutation({
    mutationFn: submitUpdatePoolRequest,
    onError: (error) => {
      console.error('Error submitting picks:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Picks submitted successfully:', data);
    // }
  });
};

const submitUpdatePoolRequest = async (pickData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.post(`${apiUrl}/games/update-pool`, pickData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });  return response.data;
};