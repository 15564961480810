import React, { useState, useEffect } from 'react';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useTiersGameContext } from '../context/TiersGameContext';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModal';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';


const TiersLeaderboard = () => {
    const {
        masterPoolData,
        isGameOver,
        myLeaderboardInfo,
        leaderboardData,
        myUserId,
    } = useUserAndGameContext();

    const {
        theCurrentInterval,
        oneIntervalEvent,
        firstInterval,
        lastInterval,
    } = useTiersGameContext();
    console.log("MY LEADERBOARD INFO", myLeaderboardInfo);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [sortBy, setSortBy] = useState('rank');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState('rank');
    const [tiebreakerInfoOpen, setTiebreakerInfoOpen] = useState(false);
    const [leaderboardInterval, setLeaderboardInterval] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    function findRank(entry) {
        if (leaderboardInterval === 'Overall') {
            return entry.rank;
        } else {
            return entry.intervals[leaderboardInterval]?.rank || '-';
        }
    };

    function findScore(entry) {
        if (leaderboardInterval === 'Overall') {
            return entry?.score || '-';
        } else {
            return entry?.intervals[leaderboardInterval]?.score || '-';
        }
    };

    // Use Effect to set the leaderboard interval
    useEffect(() => {
        console.log("Setting Interval")
        if ((isGameOver && !oneIntervalEvent) || oneIntervalEvent) {
            setLeaderboardInterval('Overall');
        } else if (theCurrentInterval >= firstInterval && theCurrentInterval <= lastInterval) {
            setLeaderboardInterval(theCurrentInterval);
        } else if (theCurrentInterval < firstInterval) {
            setLeaderboardInterval(firstInterval);
        } else if (theCurrentInterval > lastInterval) {
            setLeaderboardInterval(lastInterval);
        }
    }, [theCurrentInterval, oneIntervalEvent, firstInterval, lastInterval, isGameOver]);

    // Add pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Add useEffect to reset page when filters change
    useEffect(() => {
        setPage(0);
    }, [leaderboardInterval, sortBy, sortOrder]);

    const handleSort = (column) => {
        // If clicking the same column, toggle the sortOrder
        if (sortBy === column) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(column);
          setSortOrder('asc'); // Reset to ascending when sorting a new column
        }
    };

    // Get leaderboard info and process for display
    const leaderboardColumns = masterPoolData?.leaderboardInfo || [];
    console.log("Leaderboard Info", leaderboardColumns);
    
    // Filter columns based on current interval
    const visibleColumns = React.useMemo(() => {
        return leaderboardColumns.filter(column => {
            // Hide columns marked with hideOnOverall when in Overall view
            if (leaderboardInterval === 'Overall' && column.hideOnOverall) {
                return false;
            }
            return true;
        });
    }, [leaderboardColumns, leaderboardInterval]);

    const sortedLeaderboardData = leaderboardData;

    if (leaderboardInterval === '') {
        return <LoadingScreen />;
    }

    return (
        <>
            <Box sx={{ maxWidth: '750px', margin: '0 auto' }}>
                <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={sortedLeaderboardData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={Math.min(page, Math.max(0, Math.ceil((sortedLeaderboardData?.length || 0) / rowsPerPage) - 1))}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows"
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiTablePagination-toolbar': {
                            color: '#002129',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                            color: '#002129',
                        }
                    }}
                />
            </Box>

            <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', marginBottom: '20px'}}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                            <TableCell className="pickem-leaderboard-table-cells"
                                onClick={() => {
                                handleSort('rank');
                                setSortedColumn('rank');
                                }}
                                sx={{ width: '57px', color: sortedColumn === 'rank' ? '#FFC60A' : '#fff' , fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
                            >
                                RANK
                            </TableCell>
                            <TableCell className="pickem-leaderboard-table-cells"
                                onClick={() => {
                                handleSort('username');
                                setSortedColumn('username');
                                }}
                                sx={{ width: '160px', color: sortedColumn === 'username' ? '#FFC60A' : '#fff' , fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'}}
                            >
                                PLAYER
                            </TableCell>
                            {visibleColumns.map((column) => (
                                <TableCell 
                                    key={column.name}
                                    className="pickem-leaderboard-table-cells"
                                    onClick={() => {
                                    handleSort(column.name);
                                    setSortedColumn(column.name);
                                    }}
                                    sx={{ 
                                        color: sortedColumn === column.name ? '#FFC60A' : '#fff', 
                                        fontWeight: 700, 
                                        textAlign: 'center', 
                                        position: 'relative', 
                                        cursor: 'pointer', 
                                        fontSize: isMobile ? '14px' : '22px', 
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {column.name.toUpperCase()}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </>
    );
};

export default TiersLeaderboard;