import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';

const CFBTeamsSelectedBox = ({ logo, result, weekPicked, abbreviation }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
            }}
        >
            <Typography
                sx={{
                    alignSelf: 'stretch',
                    color: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129',
                    textAlign: 'center',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                }}
            >
                {weekPicked !== null ? isMobile ? `W${weekPicked}` : `Wk ${weekPicked}` : ''}
            </Typography>
            
            {/* Conditional rendering of logo, close icon, or fallback box */}
            {logo === "X" ? (
                <Box
                    sx={{
                        width: isMobile ? '32px' : '48px',
                        height: isMobile ? '32px' : '48px',
                        border: `2px solid #CC293C`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#FFF',
                        boxSizing: 'border-box',
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: '#CC293C',
                            fontSize: isMobile ? '24px' : '32px',
                        }}
                    />
                </Box>
            ) : logo ? (
                <Box
                    component="img"
                    src={logo}
                    alt="Team Logo"
                    sx={{
                        width: isMobile ? '32px' : '48px',
                        height: isMobile ? '32px' : '48px',
                        flexShrink: 0,
                        border: result === 'Win' ? '2px solid #00AA72' : (result === 'Loss' ? '2px solid #CC293C' : '2px solid #002129'),
                        padding: '2px',
                        backgroundColor: result === 'Win' ? '#CCEEE3' : result === 'Loss' ? '#F7DDE0' : result === 'Pending' ? '#E5E5E5' : '#FFF',
                        boxSizing: 'border-box',
                        objectFit: 'contain',
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: isMobile ? '32px' : '48px',
                        height: isMobile ? '32px' : '48px',
                        border: result === 'Win' ? '2px solid #00AA72' : (result === 'Loss' ? '2px solid #CC293C' : '2px solid #002129'),
                        backgroundColor: '#FFF',
                        boxSizing: 'border-box',
                    }}
                />
            )}

            <Typography
                sx={{
                    alignSelf: 'stretch',
                    color: abbreviation === 'Out' ? '#CC293C' : result === 'Win' ? '#00AA72' : result === "Loss" ? '#CC293C': '#002129',
                    textAlign: 'center',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                }}
            >
                {abbreviation || '\u00A0'}
            </Typography>
        </Box>
    );
}

export default CFBTeamsSelectedBox;
