import React from "react";
import { Box } from "@mui/material";
import BracketGamesUserDash from "./UserDashComponents/BracketGame/BracketGamesUserDash";
import SurvivorGamesUserDash from "./UserDashComponents/SurvivorGame/SurvivorGamesUserDash";
import QuickPickGamesUserDash from "./UserDashComponents/QuickPickGame/QuickPickGamesUserDash";

const UserDashGameHandler = ({
    gameType,
    canIAddAnEntry,
    setIndexToRemove,
    setRemoveModalOpen,
    isThisPoolPartyGame,
    pickIndex,
}) => {
    
    const renderGameHandler = () => {
        switch (gameType) {
            case 'Bracket':
                return (
                    <BracketGamesUserDash 
                        canIAddAnEntry={canIAddAnEntry}
                        setIndexToRemove={setIndexToRemove}
                        setRemoveModalOpen={setRemoveModalOpen}
                        isThisPoolPartyGame={isThisPoolPartyGame}
                    />
                );
            case 'Survivor':
                return (
                    <SurvivorGamesUserDash 
                        canIAddAnEntry={canIAddAnEntry}
                        setIndexToRemove={setIndexToRemove}
                        setRemoveModalOpen={setRemoveModalOpen}
                        isThisPoolPartyGame={isThisPoolPartyGame}
                    />
                );
            case 'QuickPick':
                return (
                    <QuickPickGamesUserDash 
                        canIAddAnEntry={canIAddAnEntry}
                        setIndexToRemove={setIndexToRemove}
                        setRemoveModalOpen={setRemoveModalOpen}
                        isThisPoolPartyGame={isThisPoolPartyGame}
                    />
                );
            default:
                return (
                    <div>
                        DEFAULT GAME HERE
                    </div>
                );
        }
    }

    return (
        <Box>
            {renderGameHandler()}
        </Box>
    );
}

export default UserDashGameHandler;