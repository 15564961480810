import { Box, Button } from '@mui/material';
import React from 'react';

export default function NextAndBackButtons({
  isCenter = false,
  backButtonText = 'Back',
  nextButtonText = 'Next',
  onBackButtonClick,
  onNextButtonClick,
  isBackButtonDisabled=false,
  isNextButtonDisabled=false,
}) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: isCenter ? 'center' : 'flex-end',
        margin: '10px 0'
      }}
    >
      <Button disabled={isBackButtonDisabled} onClick={onBackButtonClick} variant='text'>
        {backButtonText}
      </Button>
      <Button disabled={isNextButtonDisabled} onClick={onNextButtonClick} variant='contained'>
        {nextButtonText}
      </Button>
    </Box>
  );
}
