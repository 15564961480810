import React from 'react';
import { Box, Button } from '@mui/material';
import PrivatePoolsList from './PrivatePoolsList';
import { useGetPrivatePools } from './hooks/useGetPrivatePools';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { Typography } from '@mui/material';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from '../PoolHeaderBar/StaticPoolHeaderBar';

const PrivatePoolsContainer = () => {
  const { data, error, isLoading, refetch } = useGetPrivatePools();

  const navigateToTop = useNavigateToTop();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDemoClick = () => {
    navigateToTop('/birdie-pool-demo');
  }

  if (isLoading) return <LoadingScreen />;
  if (error) return <div>An error occurred: {error.message}</div>;
  //console.log("DATA", data)
  const filteredData = data?.filter(pool => pool.gameName !== "Pool Party Demo Birdie Pool");

  return (
    <Box sx={{ mx: "auto", textAlign: "center" }}>
      <StaticPoolHeaderBar navigationText="Home" headerText="Birdie Pools" linkForNavigation="/" />
      <Typography
        sx={{
          color: '#002129',
          textAlign: 'center',
          fontSize: isMobile ? '24px' : '32px',
          fontWeight: 700,
          marginTop: '24px',
          marginBottom: '40px',
        }}
      >
        Pool Party's Birdie Pool
      </Typography>
      <Typography
        sx={{
          color: '#002129',
          textAlign: 'center',
          fontSize: isMobile ? '18px' : '20px',
          fontWeight: 700,
        }}
      >
        The race to birdie all 18 holes.
      </Typography>
      <Typography
        sx={{
          color: '#002129',
          textAlign: 'center',
          fontSize: isMobile ? '16px' : '20px',
          fontWeight: 400,
          marginTop: '24px',
        }}
      >
        The Birdie Pool is a season long birdie<br/> challenge held within your club.
      </Typography>


      <PrivatePoolsList pools={filteredData} linkForLogin={'/login/private-pools'}/>
      <Typography
        sx={{
          color: '#00AAD1',
          textAlign: 'center',
          fontFamily: 'Inter, sans-serif',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '170%', // 40.8px
          letterSpacing: '-0.456px',
          textDecorationLine: 'underline',
          marginTop: '20px',
          marginBottom: '40px',
          cursor: 'pointer',
        }}
        onClick={handleDemoClick}
      >
        Birdie Pool Demo
      </Typography>

      <Box
        sx={{
          maxWidth: isMobile ? '327px' : '500px',
          margin: '0 auto',
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? '18px' : '20px',
            fontWeight: '700',
            textAlign: 'center',
            textDecoration: 'underline',
            color: '#292929'
          }}
        >
          Club-Wide Competitions
        </Typography>
        <Typography
          style={{
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: '400',
            textAlign: 'center',
            color: '#292929',
            marginTop: '8px'
          }}
        >
          Golfers will compete to be the first to birdie every 
          hole on your course. Even after a champion is crowned, 
          golfers can continue the quest to conquer all 18 holes 
          and track their total birdies for the year.
        </Typography>
        <Typography
          style={{
            fontSize: isMobile ? '18px' : '20px',
            fontWeight: '700',
            textAlign: 'center',
            textDecoration: 'underline',
            color: '#292929',
            marginTop: '32px'
          }}
        >
          My Party Competitions
        </Typography>
        <Typography
          style={{
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: '400',
            textAlign: 'center',
            color: '#292929',
            marginTop: '8px',
            marginBottom: '40px'
          }}
        >
          The My Party feature allows any member to create 
          their own custom group competition. Be it your friends, 
          rivals with similar handicaps, or even head-to-head matchups - 
          this feature opens up the pool to accommodate golfers of all skill levels.
        </Typography>
        </Box>
    </Box>
  );
}

export default PrivatePoolsContainer;
