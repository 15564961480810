import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../../config";

export const useCreateSubgroup = () => {
  return useMutation({
    mutationFn: createSubgroupRequest,
    onError: (error) => {
      console.error('Error creating subgroup:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Subgroup created successfully:', data);
    // }
  });
}

const createSubgroupRequest = async (subgroupData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.post(`${apiUrl}/user/create-subgroup`, subgroupData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};