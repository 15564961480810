import React, { useEffect, useMemo, useCallback, useState } from "react";
import dayjs from "dayjs";
import {
  createTheme,
  Box,
  TextField,
  ThemeProvider,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import debounce from "lodash/debounce";
import {
  useValidateUsernameIsUnique,
  useValidateEmailIsUnique,
} from "../../hooks/auth";
import { phoneNumberRegex, thirteenYearsAgo } from "../../utils/validation";
import { useUpdateUser } from "../../hooks/users";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

export default function GeneralInformation(props) {
  const { userData } = props;

  const { mutateAsync: validateUsernameIsUnique } =
    useValidateUsernameIsUnique();
  const { mutateAsync: validateEmailIsUnique } = useValidateEmailIsUnique();
  const { mutate: updateUser } = useUpdateUser();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [dobError, setDobError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formState, setFormState] = useState({
    firstName: userData.first_name,
    lastName: userData.last_name,
    dateOfBirth: userData.date_of_birth,
    phone: userData.phone,
    username: userData.user_name,
    email: userData.email,
  });

  const [formValidation, setFormValidation] = useState({
    firstName: {
      isError: false,
      message: "",
    },
    lastName: {
      isError: false,
      message: "",
    },
    dateOfBirth: {
      isError: false,
      message: "",
    },
    email: {
      isError: false,
      message: "",
    },
    phone: {
      isError: false,
      message: "",
    },
    username: {
      isError: false,
      message: "",
    },
  });
  const initalValidation ={
    firstName: {
      isError: false,
      message: "",
    },
    lastName: {
      isError: false,
      message: "",
    },
    dateOfBirth: {
      isError: false,
      message: "",
    },
    email: {
      isError: false,
      message: "",
    },
    phone: {
      isError: false,
      message: "",
    },
    username: {
      isError: false,
      message: "",
    },
  };
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              outlined: {
                backgroundColor: Boolean(formState?.dateOfBirth)
                  ? "white"
                  : "transparent",
                padding: "0 5px",
                borderRadius: "5px",
              },
            },
          },
        },
      }),
    [formState.dateOfBirth]
  );

  const debouncedValidateUsername = useCallback(
    debounce(async (username) => {
      const isUsernameUnique = await validateUsernameIsUnique({ username });
      if (!isUsernameUnique) {
        setUsernameError("Username is already taken.");
      } else {
        setUsernameError("");
      }
    }, 1000),
    [validateUsernameIsUnique, setUsernameError]
  );

  const debouncedValidateEmail = useCallback(
    debounce(async (email) => {
      const isEmailUnique = await validateEmailIsUnique({ email });
      if (!isEmailUnique) {
        setEmailError("Email is already in use.");
      } else {
        setEmailError("");
      }
    }, 1000),
    [validateEmailIsUnique, setEmailError]
  );

  const validateForm = useCallback(
    async ({
      validateUsername = true,
      validateEmail = true,
      validateDateOfBirth = true,
      validatePhone = true,
      validateFirstName = true,
      validateLastName = true,
    } = {}) => {
      if (validateFirstName) {
        setFormValidation((prev) => ({
          ...prev,
          firstName: {
            isError: !formState.firstName,
            message: !formState.firstName ? "First name is required." : "",
          },
        }));
      }

      if (validateLastName) {
        setFormValidation((prev) => ({
          ...prev,
          lastName: {
            isError: !formState.lastName,
            message: !formState.lastName ? "Last name is required." : "",
          },
        }));
      }

      if (validateUsername) {
        const usernameHasChanged = formState.username !== userData.user_name;
        if (!formState.username) {
          setUsernameError("Username is required.");
        } else if (usernameHasChanged) {
          await debouncedValidateUsername(formState.username);
        }
      }

      if (validateEmail) {
        const emailHasChanged = formState.email !== userData.email;
        if (!formState.email) {
          setEmailError("Email is required.");
        } else if (emailHasChanged) {
          await debouncedValidateEmail(formState.email);
        }
      }

      if (validateDateOfBirth) {
        const dobIsOldEnough =
          formState.dateOfBirth &&
          dayjs(formState.dateOfBirth).isBefore(thirteenYearsAgo);
        if (!formState?.dateOfBirth) {
          setDobError("Date of birth is required.");
        } else if (!dobIsOldEnough) {
          setDobError("Players must be at least 13 years of age.");
        } else {
          setDobError("");
        }
      }

      if (validatePhone) {
        const phoneNumberIsInvalid =
          Boolean(formState.phone) && !phoneNumberRegex.test(formState.phone);
        setFormValidation((prev) => ({
          ...prev,
          phone: {
            isError: phoneNumberIsInvalid,
            message: phoneNumberIsInvalid ? "Invalid phone number." : "",
          },
        }));
      }
    },
    [
      debouncedValidateEmail,
      debouncedValidateUsername,
      dobError,
      emailError,
      formState,
      usernameError,
      userData.user_name,
      userData.email,
    ]
  );




  useEffect(() => {
    validateForm(false);
  }, [formState, validateForm]);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      document.activeElement.blur();

      // Skip validation on submit
      await validateForm({
        validateUsername: false,
        validateEmail: false,
        validateDateOfBirth: false,
        validatePhone: false,
        validateFirstName: false,
        validateLastName: false,
      });

      // ensure there are no errors before submitting
      if (Object.values(formValidation).some((field) => field.isError)) return;

      updateUser(
        {
          id: userData._id,
          ...formState,
        },
        {
          onSuccess: () => {
            setIsSnackbarOpen(true);
            // Clear validation state
            setDobError("");
            setUsernameError("");
            setEmailError("");
            setFormValidation(initalValidation); // Reset to your initial validation state
          },
        }
      );
    },
    [validateForm, formValidation, formState, updateUser, userData]
  );



  const handleDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setFormState((prevState) => ({
        ...prevState,
        dateOfBirth: newValue,
      }));
    }
  };
  return (
    <Box component="form" onSubmit={submitForm}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ bottom: "100px" }}
        open={isSnackbarOpen}
        autoHideDuration={135000}
        onClose={() => setIsSnackbarOpen(false)}
        message="Changes saved successfully."
        key={"top" + "right"}
      />
      <div
        className="hidden md:block w-full flex justify-end pr-6 text-right"
        style={{ marginTop: "-90px", paddingBottom: "60px" }}
      >
        <Button variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        <TextField
          id="firstName"
          name="firstName"
          label="First Name"
          variant="outlined"
          type="text"
          sx={{
            width: {
              xs: "100%",
              md: "48%",
            },
            marginBottom: formValidation.firstName.isError ? "0" : "20px",
          }}
          value={formState.firstName}
          error={formValidation.firstName.isError}
          helperText={formValidation.firstName.message}
          onChange={(e) => {
            setFormState({ ...formState, firstName: e.target.value });
            validateForm();
          }}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Last Name"
          variant="outlined"
          type="text"
          sx={{
            width: {
              xs: "100%",
              md: "48%",
            },
            marginBottom: formValidation.lastName.isError ? "0" : "20px",
          }}
          value={formState.lastName}
          error={formValidation.lastName.isError}
          helperText={formValidation.lastName.message}
          onChange={(e) =>
            setFormState({ ...formState, lastName: e.target.value })
          }
        />
        <TextField
          id="username"
          name="username"
          label="Username"
          variant="outlined"
          type="text"
          sx={{
            width: {
              xs: "100%",
              md: "48%",
            },
            marginBottom: formValidation.username.isError ? "0" : "20px",
          }}
          value={formState.username}
          error={formValidation.username.isError}
          helperText={formValidation.username.message}
          onChange={(e) =>
            setFormState({ ...formState, username: e.target.value })
          }
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "48%",
                },
              }}
            >
              <Box
                className={`${dobError ? "bg-red-50 " : ""}`}
                style={
                  dobError
                    ? { outline: "1px solid red", borderRadius: "4px" }
                    : {}
                }
              >
                <DatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  label="Date of Birth"
                  variant="outlined"
                  className="w-full"
                  error={formValidation.dateOfBirth.isError}
                  value={dayjs(formState.dateOfBirth)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              {Boolean(dobError) ? (
                <div className="w-full text-left pl-3 bg-white my-0">
                  <Typography
                    color={"error"}
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      padding: "0px",
                      marginTop: "0px",
                    }}
                  >
                    {dobError}
                  </Typography>
                </div>
              ) : (
                <Typography>&nbsp;</Typography>
              )}
            </Box>
          </ThemeProvider>
        </LocalizationProvider>

        <TextField
          id="email"
          name="email"
          label="Email address"
          variant="outlined"
          type="email"
          sx={{
            width: {
              xs: "100%",
              md: "48%",
            },
            marginBottom: formValidation.email.isError ? "0" : "20px",
          }}
          value={formState.email}
          error={formValidation.email.isError}
          helperText={formValidation.email.message}
          onChange={(e) =>
            setFormState({ ...formState, email: e.target.value })
          }
        />
         <MuiTelInput
            id="phone"
            name="phone"
            label="Phone"
            variant="outlined"
            type="text"
            sx={{
              width: {
                xs: "100%",
                md: "48%",
              },
              marginBottom: formValidation.phone.isError ? "0" : "20px",
            }}
            value={formState.phone}
            error={formValidation.phone.isError}
            helperText={formValidation.phone.message}
            autoComplete="off"
            defaultCountry="US"
            onChange={(value) => {
              setFormState({ ...formState, phone: value });
            }}
          />
        <div className="block md:hidden w-full flex justify-center">
          <Button variant="contained" size="large" type="submit">
            Save Changes
          </Button>
        </div>
      </Box>
    </Box>
  );
}
