import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useQuickPickGamesContext } from "../context/QuickPickGamesContext";
import QuickPicksMyPicksNavBar from "./QuickPicksMyPicksNavBar";
import PickDeadline from "../CardPieces/PickDeadline";
import QuickPickCard from "../CardPieces/QuickPickCard";
import QuickPickNoGamesCard from "../CardPieces/QuickPickNoGamesCard";
import MyPicksStatus from "./MyPicksStatus";
import { DateTime, Interval } from 'luxon';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import PropIdeaText from "./PropIdeaText";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import isEqual from "lodash/isEqual";
import MyPicksNavBar from "../../NavigationBars/MyPicksNavigation/MyPicksNavBar";
import GameInfoRow from "../CardPieces/GameInfoRowNew";
import QuickPickSubmitBar from "../../GamePageComponents/SubmitBars/QuickPicksSubmitBar";
import { useSaveQuickPickPicks } from "../../../hooks/pools/useSaveQuickPickPicks";
import QuickPickSubmitPicksModal from "../../GameModals/QuickPickModals/QuickPickSubmitPickModal";
import ResponseModal from "../../GameModals/ResponseModal/ResponseModal";
import QuickPickGamesComing from "../CardPieces/QuickPickGamesComing";
import QuickPicksMorePicksComing from "../CardPieces/QuickPicksMorePicksComing";


const QuickPickMyPicks = () => {
    const {
        myUserId,
        gameAddress,
        masterPoolId,
        gameId,
        gameData,
        masterPoolData,
        pickState,
        setPickState,
        originalPicks,
        unsavedPicks,
        setUnsavedPicks,
        interval,
        league,
        pickIndex,
    } = useUserAndGameContext();

    const {
        schedule,
        teams,
        standings,
        quickPickGames,
        theCurrentInterval,
        firstInterval,
        lastInterval,
        propIdsToUse,
        oneWeekEvent,
        filteredSchedule,
        filteredPropIds,
        usingTiebreaker,
        picksThisInterval,
    } = useQuickPickGamesContext();
//console.log("Interval in QuickPickMyPicks: ", interval, "Pick State: ", pickState, "Prop IDs to Use!!!!", propIdsToUse);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { mutate: saveQuickPickPicks } = useSaveQuickPickPicks();

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    if (!myUserId) {
        navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home`);
    }

    const [submitBarOpen, setSubmitBarOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [checkerData, setCheckerData] = useState({});
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    //console.log("Error Modal Open: ", errorModalOpen, "Success Modal Open: ", successModalOpen);

    const gamesComingTitle = masterPoolData?.modalInfo?.myPicks?.title;
    const gamesComingBody = masterPoolData?.modalInfo?.myPicks?.body;
    //console.log("Games Coming Title: ", gamesComingTitle, "Games Coming Body: ", gamesComingBody);

    // Use Effect to see if orignalPicks is different from pickState
    // If it is, setUnsavedPicks to true and setSubmitBarOpen to true
    useEffect(() => {
        if (originalPicks && pickState) {
            const picksAreEqual = 
            originalPicks?.length === pickState?.length &&
            originalPicks?.every(originalPick =>
                pickState?.some(pick => isEqual(originalPick, pick))
            );

            setSubmitBarOpen(!picksAreEqual);
            setUnsavedPicks(!picksAreEqual);
        }
    }, [originalPicks, pickState, setUnsavedPicks]);

    const winsThisInterval = pickState?.filter(pick => Number(pick.interval) === Number(interval) && pick.result === 'win')?.length;
    const lossesThisInterval = pickState?.filter(pick => Number(pick.interval) === Number(interval) && pick.result === 'loss')?.length;
    const pointsThisInterval = pickState?.filter(pick => Number(pick.interval) === Number(interval) && pick.result === 'win')?.reduce((total, pick) => total + pick.points, 0);

    // MAKE THESE DYNAMIC ONCE ITS A MULTI-INTEVAL GAME!!!!!!!!!!!!!!!!!!!!!!!!!
    const teamName = "Team Name";
    const sponsorName = "Sponsor Name";

    const myPicksMobileImage = masterPoolData?.gameParameters?.myPicksMobileImage || null;
    const myPicksDesktopImage = masterPoolData?.gameParameters?.myPicksDesktopImage || null;
    const customBackgroundColor = masterPoolData?.gameParameters?.customBackgroundColor || null;
    const customTextColor = masterPoolData?.gameParameters?.customTextColor || null;
    const customBorderColor = masterPoolData?.gameParameters?.customBorderColor || null;

    const hasAnyGameThisIntervalStarted = filteredSchedule?.some(game => {
        const gameDateTime = DateTime.fromISO(game?.DateTime, { zone: 'America/New_York' });
        return timeNowInNewYork > gameDateTime;
    });
    //console.log("Has Any Game This Interval Started: ", hasAnyGameThisIntervalStarted);

    const handleCancel = () => {
        setPickState(originalPicks);
        setSubmitBarOpen(false);
    }

    const handleSubmit = async () => {
        if (hasSubmitted) return;

        setHasSubmitted(true);
        // Check pickState vs originalPicks for new picks
        const newPicks = pickState.filter(pick => !originalPicks.some(originalPick => isEqual(pick, originalPick)));
        //console.log("New Picks: ", newPicks);
        // Check pickState vs originalPicks for removed picks
        const removedPicks = originalPicks.filter(originalPick => !pickState.some(pick => isEqual(originalPick, pick)));
        //console.log("Removed Picks: ", removedPicks);
        
        const pickData = {
            masterPoolId,
            myUserId,
            pickIndex: Number(pickIndex),
            newPicks,
            removedPicks,
        };
        //console.log("Pick Data: ", pickData);

        // Update the try-catch block in handleSubmit
        try {
            await saveQuickPickPicks(pickData, {
                onSuccess: (response) => {  // Add response parameter here
                    //console.log("Successfully saved picks!", response);
                    // Access data from the response object
                    setUnsavedPicks(false);
                    setSubmitBarOpen(false);
                    setSuccessModalOpen(true);
                    
                    // Check if response data exists
                    if (response) {
                        setCheckerData(response);
                    }
                },
                onError: (error) => {
                    console.error("Error saving picks: ", error);
                    const errorMessage = error.response?.data?.message || error.message || "An error occurred while saving picks";
                    setErrorMessage(errorMessage);
                    setErrorModalOpen(true);
                    setSubmitBarOpen(false);
                    setUnsavedPicks(true);
                }
            });
        } catch (error) {
            console.error("Error saving picks: ", error);
            setErrorMessage("An unexpected error occurred");
            setErrorModalOpen(true);
            setSubmitBarOpen(false);
            setUnsavedPicks(true);
        }
    }

    return (
        <Box>
            {!oneWeekEvent && (
                <MyPicksNavBar
                    theCurrentInterval={theCurrentInterval}
                />
            )}

            {filteredSchedule?.length === 0 && oneWeekEvent && (
                <>
                    <QuickPickGamesComing 
                        page={"my-picks"}
                        title={gamesComingTitle}
                        body={gamesComingBody}
                    />

                    <Box sx={{ marginBottom: '40px' }} />
                </>
            )}


            {filteredSchedule?.length === 0 && !oneWeekEvent && (
                <>
                    <QuickPickNoGamesCard
                        league={league}
                        isThereAGame={false}
                        teamName={teamName}
                        sponsorName={sponsorName}
                    />

                    <Box sx={{ marginBottom: '40px' }} />
                </>
            )}

            {filteredSchedule?.length > 0 && hasAnyGameThisIntervalStarted && (
                <Box
                    sx={{
                        borderTop: '2px solid #002129',
                        width: '100%',
                        boxSizing: 'border-box', // Ensures border fits within 100% width
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '24px',
                            color: '#002129',
                            fontWeight: 700,
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        Record: {winsThisInterval} - {lossesThisInterval} ({pointsThisInterval} Points)
                    </Typography>
                </Box>
            )}

            {filteredSchedule?.map((game, index) => {
                const selectedGameId = game?.GlobalGameID;
                const propsForThisGame = propIdsToUse?.filter(prop => prop.gameId === selectedGameId);
                const propCountForThisGame = propsForThisGame[0]?.totalProps || propsForThisGame[0]?.propIdsToUse?.length;
                const logoForThisGame = propsForThisGame[0]?.logo;
                //console.log("Props for this game: !!!!", propsForThisGame, "Prop Count for this game: ", propCountForThisGame);
                const selectedGameTime = game?.DateTime;
                const selectedGameStatus = game?.Status;
                const selectedGameTimeInNewYork = DateTime.fromISO(selectedGameTime, { zone: 'America/New_York' });
                const hasGameStarted = timeNowInNewYork > selectedGameTimeInNewYork;
                const isGameFinal = game?.Status === 'Final' || game?.Status === 'F/OT';
                const selectedQuickPickGame = quickPickGames?.find(game => game?.GlobalGameID === selectedGameId);
                const selectedProps = selectedQuickPickGame?.props;
                const isLastGame = index === filteredSchedule.length - 1; // Check if this is the last game
                //console.log("Selected Quick Pick Game: ", selectedQuickPickGame, "selectedProps: ", selectedProps);

                 // Set default empty arrays for each category in case selectedProps is undefined
                 let allProps = [
                    ...(selectedProps?.gameProps || []),
                    ...(selectedProps?.homeProps || []),
                    ...(selectedProps?.awayProps || []),
                    ...(selectedProps?.tiebreakers || [])
                ];
                //console.log("All Props: ", allProps);
                // Filter allProps to only include those that match filteredPropIds
                let filteredProps = allProps.filter(prop => prop && filteredPropIds.includes(prop.id));
                //console.log("Filtered Props: ", filteredProps, "All Props: ", "Props for This Game: ", propsForThisGame, "Logo for This Game: ", logoForThisGame, "Filtered Schedule: ", filteredSchedule);

                // let sortedProps = selectedProps?.map(id => allProps.find(prop => prop.id === id)) // Find each prop by ID
                // .filter(prop => prop !== undefined); // Filter out undefined values (if any ID didn't match)
                // console.log("Sorted Props", sortedProps);
                return (
                    <Box key={selectedGameId || index}> 
                        {logoForThisGame && (
                            <Box>
                                <img 
                                    src={logoForThisGame}
                                    alt="My Picks Image"
                                    style={{ width: '100%', maxWidth: isMobile ? '311px' : '600px', maxHeight: isMobile ? '80px' : '120px', margin: '0px auto', objectFit: 'contain', padding: '32px 0px 16px 0px' }}
                                />
                            </Box>
                        )}
                        {/* Game Info Row */}
                        {!logoForThisGame && (
                            <Box sx={{borderTop: '2px solid #002129'}}>
                                <GameInfoRow
                                    gameId={selectedGameId}
                                    teams={teams}
                                    schedule={schedule}
                                    standings={standings}
                                    league={league}
                                />
                            </Box>
                        )}

                        {/* Only show Pick Deadline if the deadline hasn't passed */}
                        {!hasGameStarted && (
                            <>
                                <PickDeadline gameTime={selectedGameTime} customBackgroundColor={customBackgroundColor} customTextColor={customTextColor} />
                                <MyPicksStatus interval={interval} userPicksFromDB={originalPicks} picksPerInterval={propCountForThisGame} selectedGameId={selectedGameId}/>
                            </>
                        )}

                        {filteredProps?.length === 0 && (
                            <>
                                <QuickPickNoGamesCard
                                    league={league}
                                    isThereAGame={true}
                                    teamName={teamName}
                                    sponsorName={sponsorName}
                                />
                                <Box sx={{ marginBottom: '40px'}}></Box>
                            </>
                        )}

                        {/* Quick Pick Card */}
                        {filteredProps?.map((prop, propIndex) => (
                            <QuickPickCard 
                                key={propIndex}
                                userPicksFromDB={originalPicks}
                                pickState={pickState}
                                setPickState={setPickState}
                                prop={prop}
                                interval={interval}
                                selectedGameId={selectedGameId}
                                hasGameStarted={hasGameStarted}
                                isGameFinal={isGameFinal}
                                displayIndex={propIndex + 1} // Pass the index + 1 to display the correct number
                                customBackgroundColor={customBackgroundColor}
                                customTextColor={customTextColor}
                                customBorderColor={customBorderColor}
                            />
                        ))}
                        <Box sx={{ marginBottom: '20px'}}></Box>
                        {filteredProps?.length !== propCountForThisGame && (
                            <>
                                <QuickPicksMorePicksComing />
                                <Box sx={{ marginBottom: '20px'}}></Box>
                            </>
                        )}
                        {isLastGame && filteredProps?.length > 0 && filteredProps?.length === propCountForThisGame && (
                            <PropIdeaText
                                unsavedPicks={unsavedPicks}
                                userPicksFromDB={originalPicks}
                                setPickState={setPickState}
                            />
                        )}

                    </Box>
                );
            })}

            {successModalOpen && (
                <QuickPickSubmitPicksModal
                    open={successModalOpen}
                    handleClose={() => {
                        setSuccessModalOpen(false);
                        window.location.reload();
                    }}
                    checkerData={checkerData}
                    picksThisInterval={picksThisInterval}
                    usingTiebreaker={usingTiebreaker}
                    interval={interval}
                />
            )}

           
            {errorModalOpen && (
                <ResponseModal
                    open={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                    status="error"
                    message={errorMessage}
                />
            )}

            {submitBarOpen && (
                <QuickPickSubmitBar
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    usingTiebreaker={usingTiebreaker}
                    picksThisInterval={picksThisInterval}
                    hasSubmitted={hasSubmitted}
                />
            )}

        </Box>
    );
};

export default QuickPickMyPicks;