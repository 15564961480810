// College Football Utility Functions

export const gatherLogoCFB = (teamId, teams) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    return team?.TeamLogoUrl;
};

export const gatherStadiumInfoCFB = (stadiumId, stadiums) => {
    const stadium = stadiums?.find(stadium => stadium.StadiumID === stadiumId);
    return `${stadium?.City || ''}, ${stadium?.State || ''}`;
};

export const gatherLineDisplayCFB = (homeId, awayId, homeSpreadPicked, awaySpreadPicked, overPicked, underPicked, hasGameStarted, homeLine, awayLine, total, teamsOrNames) => {
    console.log("Game Info", homeId, awayId, homeSpreadPicked, awaySpreadPicked, overPicked, underPicked, hasGameStarted, homeLine, awayLine, total, teamsOrNames);
    let spreadDisplay = '';
    let totalDisplay = '';
    
    if (homeSpreadPicked || awaySpreadPicked) {
        if (homeSpreadPicked) {
            const homeTeam = teamsOrNames?.find(team => team.GlobalTeamID === homeId)?.ShortDisplayName;
            const homeSpread = homeLine > 0 ? `+${homeLine}` : homeLine === 0 ? 'PK' : homeLine;
            spreadDisplay = `${homeTeam} (${homeSpread})`;
        } else {
            const awayTeam = teamsOrNames?.find(team => team.GlobalTeamID === awayId)?.ShortDisplayName;
            const awaySpread = awayLine > 0 ? `+${awayLine}` : awayLine === 0 ? 'PK' : awayLine;
            spreadDisplay = `${awayTeam} (${awaySpread})`;
        }
    }

    if (overPicked || underPicked) {
        totalDisplay = `O/U (${total})`;
    }

    if (spreadDisplay !== '' && totalDisplay !== '') {
        return `${spreadDisplay}\u00A0\u00A0\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0\u00A0\u00A0${totalDisplay}`;
    } else if (spreadDisplay !== '') {
        return spreadDisplay;
    } else if (totalDisplay !== '') {
        return totalDisplay;
    } else {
        return '';
    }
}

export const gatherDisplayInfoCFB = (teamId, teams, seeds, isGameOver) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    const seed = seeds?.find(seed => seed.GlobalTeamID === teamId)?.seed;
    const school = team?.School;
    const name = team?.Name;
    const wins = team?.Wins;
    const losses = team?.Losses;
    const display = !isGameOver ? `(${seed}) ${school} ${name} (${wins}-${losses})` :
        `(${seed}) ${school} ${name}`;
    return display;
}

export const gatherScoresCFB = (game) => {
    const homeScore = game?.HomeTeamScore;
    const awayScore = game?.AwayTeamScore;
    return { homeScore, awayScore };
}
