import React, { useState, useEffect } from "react";
import { Typography, Box, MenuItem, Select, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./QuickPicksMyPicksNavBar.scss";
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";

const QuickPicksMyPicksNavBar = ({ gameAddress, mainGameId, gameData, userData, interval, setInterval, firstInterval, lastInterval, currentInterval, unsavedPicks, userPicksFromDB, setPickState }) => {
    const lowerBound = firstInterval;
    const upperBound = lastInterval;

    const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);
    const [pendingInterval, setPendingInterval] = useState(null);

    const navigate = useNavigate();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    //console.log("Interval NAV BAR:", interval);
  
    
    const handleIntervalSelectChange = (event) => {
        const newInterval = event.target.value;
        if (!unsavedPicks) {
          setInterval(newInterval);
          navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
        } else {
          setPicksNotSavedModal(true);
          setPendingInterval(newInterval);
        }
    };

    const handleIntervalChange = (direction) => {
        let newInterval = interval;
        if (direction === 'prev') {
            newInterval--;
            if (!unsavedPicks) {
              setInterval(newInterval);
              navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
            } else {
              setPicksNotSavedModal(true);
              setPendingInterval(newInterval);
            }
        } else {
            newInterval++;
            if (!unsavedPicks) {
              setInterval(newInterval);
              navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newInterval}`);
            } else {
              setPicksNotSavedModal(true);
              setPendingInterval(newInterval);
            }
        }
    }

    return (
      <>
        <Box className="quick-pick-my-picks-navigation">
          <Box className="quick-pick-my-picks-navigation__content">
            <div className="quick-pick-my-picks-navigation__game-date-bar">
              <button 
                className="quick-pick-my-picks-navigation__icon" 
                onClick={() => handleIntervalChange('prev')} 
                disabled={interval <= lowerBound}
              >
                <ArrowBackIosNewIcon />
              </button>
              <div className="quick-pick-my-picks-navigation__week-select-container">
                <Select
                  value={interval}
                  onChange={handleIntervalSelectChange}
                  variant="outlined"
                  className="quick-pick-my-picks-navigation__week-select"
                >
                  {Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
                    const intervalNumber = index + lowerBound;
                    return (
                      <MenuItem key={intervalNumber} value={intervalNumber}>
                        Week {intervalNumber}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <button 
                className="quick-pick-my-picks-navigation__icon" 
                onClick={() => handleIntervalChange('next')} 
                disabled={interval >= upperBound}
              >
                <ArrowBackIosNewIcon style={{ transform: 'rotate(180deg)' }} />
              </button>
            </div>
          </Box>
        </Box>

        <Modal
                open={picksNotSavedModal}
                onClose={() => setPicksNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
            >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPicksNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickState(userPicksFromDB);
                                    setInterval(pendingInterval);
                                    setPicksNotSavedModal(false);
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
      </>
    
    );
}

export default QuickPicksMyPicksNavBar;
