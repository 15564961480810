import React from 'react';
import { Box, Typography } from '@mui/material'; // Assuming you are using Material-UI (MUI)
import './GameHomePageComponents.scss'; // Import the SCSS file

const HomePageSponsor = ({ sponsorName, sponsorLogo, sponsorWebsite, gameType }) => {
    //console.log("HomePageSponsor", sponsorName, sponsorLogo, sponsorWebsite);
  return (
    <Box 
      className="home-page-sponsor-container"
      sx={{
        backgroundColor: "transparent",
      }}  
    >
      {/* Top Text */}
      {gameType !== 'QuickPick' && (
        <Typography className="home-page-sponsor-top-text">
          Presented By
        </Typography>
      )}

      {/* Sponsor Logo */}
      <img
        src={sponsorLogo}
        alt={sponsorName}
        className="home-page-sponsor-logo"
      />

      {/* Link Text */}
      <Typography className="home-page-sponsor-link">
        <a href={sponsorWebsite} target="_blank" rel="noopener noreferrer">
          {`${sponsorName} Website`}
        </a>
      </Typography>
    </Box>
  );
};

export default HomePageSponsor;
