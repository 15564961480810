import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const CreateAPool = () => {

    const navigateToTop = useNavigateToTop();

    const handleCreatePoolClick = () => {
        navigateToTop('/create-a-pool');
    }

    return (
        <Box
            sx={{
                marginBottom: '32px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#002129',
                    marginTop: '20px',
                }}
            >
                CREATE A POOL
            </Typography>
            <Typography
                sx={{
                    fontSize: '16px',
                    color: '#002129',
                    marginTop: '8px',
                }}
            >
                Custom. Private. Easy.
            </Typography>
            <Button
                variant="contained"
                sx={{
                    marginTop: '16px',
                }}
                onClick={handleCreatePoolClick}
            >
                CREATE A POOL
            </Button>
        </Box>
    );
};

export default CreateAPool;