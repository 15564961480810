import React from 'react';
import { Box, Typography, Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const CheckboxRadioGroup = ({text, choice, setChoice, infoIcon, setOpen }) => {
    return (
        <Box
            sx={{
                width: '100%',  // Take full width of parent
                display: 'flex',
                justifyContent: 'center', // Center the content box
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center the text and checkbox group
                }}
            >
                {text &&
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography 
                            sx={{ 
                                fontSize: '14px',
                                fontWeight: '400',
                                textAlign: 'center', // Center the text
                            }}
                        >
                            {text}
                        </Typography>
                        {infoIcon && 
                            <InfoIcon 
                                sx={{ 
                                    ml: 0.5, 
                                    fontSize: '18px', 
                                    cursor: 'pointer',
                                    color: 'primary.main'
                                }} 
                                onClick={() => setOpen(true)} 
                            />
                        }
                    </Box>
                }
                <Box 
                    sx={{ 
                        display: 'flex', 
                        gap: 2, 
                        mb: 1,
                        justifyContent: 'center', // Center the checkbox group
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={choice}
                            onChange={() => setChoice(true)}
                            size="small"
                        />
                        <Typography 
                            sx={{ 
                                fontSize: '14px', 
                            }}
                        >
                            Yes
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={!choice}
                            onChange={() => setChoice(false)}
                            size="small"
                        />
                        <Typography 
                            sx={{ 
                                fontSize: '14px', 
                            }}
                        >
                            No
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CheckboxRadioGroup;