// import React, { useState } from 'react';
// import './Modal.scss'; // Import the CSS for styling

// const Modal = ({ isOpen, onClose, game, gameData, awayTeamLogoUrl, homeTeamLogoUrl, homeTeamData, awayTeamData, addPick, pick, pickCount, rowKey, rowKeyLookup}) => {
//   // Use the 'isOpen' prop to determine whether to show the modal
//   const [isModalOpen, setIsModalOpen] = useState(isOpen);
//     console.log(rowKeyLookup)
//   // Close the modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//     onClose();
//   };
//   console.log("ROOOOOOOOOOOOOWWWWW KEYYYYYYYYYYYY___________________",rowKeyLookup);
//   const dateTime = (dateArg) => {
//     const date = new Date(dateArg);
//     const formattedDate = date.toLocaleDateString("en-US", {
//         month: "2-digit",
//         day: "2-digit",
//         year: "numeric",
//         hour: "2-digit",
//         minute: "2-digit",
//         timeZoneName: "short",
//     });
//     return formattedDate;
//   }
//   const checkGameDate = () => {
//     const currentDate = new Date();
//     const gameDate = new Date(game.Date);
//     if (currentDate < gameDate) {
//         return true;
//     }else{
//         return false;
//     }
//   }

//   const makePick = (pickTeam) => {
//     console.log(pickTeam);
//     const currentDate = new Date();
//     const gameDate = new Date(game.Date);

//     if (currentDate < gameDate) {
//         let pick = {
//             gameId: game.GameKey,
//             gameWeek: game.Week,
//             pickTeam: pickTeam,
//             rowKey: rowKey, // Pass the rowKey here
//         }
//         console.log(pick);
//         addPick(pick, rowKey);
//         onClose();
//     }else{
//         //TODO: Put a modal that says game is not available for pick
//         return;
//     }
//   }

//   return (
//     // Render the modal and its content based on the 'isModalOpen' state

//       <div className="modal-container">
//         <div className="modal-background" onClick={closeModal}></div>
//         <div className="modal-content">
//           <h2>Game Details</h2>
//             <div>
//                 <img style={{display:'block', width:100}} src={homeTeamLogoUrl} alt="Home Team Logo" />
//                 {/* {homeTeamData.FullName} */}
//                 {checkGameDate() && pickCount >= pick.length ? <button onClick={() => {makePick(game.HomeTeam)}}>Make Pick</button>
//  : <button disabled>Make Pick</button>}
//             </div>
//             <div>
//                 {/* {gameData} */}
//                 {dateTime(game.Date)}
//                 {game.Channel}
//                 {game.StadiumDetails.City},
//                 {game.StadiumDetails.State}
//                 {game.HomeTeam}{game.PointSpread}
//             </div>
//             <div>
//                 <img style={{display:'block', width:100}} src={awayTeamLogoUrl} alt="Away Team Logo" />
//                 {awayTeamData.FullName}
//                 {checkGameDate() && pickCount >= pick.length ? <button onClick={() => {makePick(game.AwayTeam)}}>Make Pick</button>
//  : <button disabled>Make Pick</button>}
//             </div>
//           <button onClick={closeModal}>Close</button>
//         </div>
//       </div>
//   );
// };

// export default Modal;

import React, { useState } from 'react';
// import './Modal.scss'; // Import the CSS for styling

const Modal = ({ isOpen, onClose, game, gameData, awayTeamLogoUrl, homeTeamLogoUrl, homeTeamData, awayTeamData, addPick, pick, pickCount, rowKey, rowKeyLookup, rowNumber}) => {
  // Use the 'isOpen' prop to determine whether to show the modal
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };
  console.log("ROOOOOOOOOOOOOWWWWW KEYYYYYYYYYYYY___________________",gameData);
  const dateTime = (dateArg) => {
    const date = new Date(dateArg);
    const formattedDate = date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
    });
    return formattedDate;
  }
  const checkGameDate = () => {
    const currentDate = new Date();
    const gameDate = new Date(gameData.Date);
    if (currentDate < gameDate) {
        return true;
    }else{
        return false;
    }
  }

  const makePick = (pickTeam) => {
    console.log(pickTeam);
    const currentDate = new Date();
    const gameDate = new Date(gameData.Date);

    if (currentDate < gameDate) {
        let pick = {
            gameId: gameData.GameKey,
            gameWeek: gameData.Week,
            pickTeam: pickTeam,
            rowKey: rowKey, // Pass the rowKey here
            rowNumber:rowNumber// rowIndex:
        }
        console.log(pick);
        addPick(pick, rowKey);
        onClose();
    }else{
        //TODO: Put a modal that says game is not available for pick
        return;
    }
  }

  return (
    // Render the modal and its content based on the 'isModalOpen' state

      <div className="modal-container">
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-content">
          <h2>Game Details</h2>
            <div>
                <img style={{display:'block', width:100}} src={homeTeamLogoUrl} alt="Home Team Logo" />
                {homeTeamData.FullName}
                {dateTime(new Date) <= dateTime(gameData.Date) ?
                    <button onClick={() => {makePick(gameData.HomeTeam)}}>Make Pick</button>

            //    {pickCount >= pick.length ? <button onClick={() => {makePick('MIN')}}>Make Pick</button>
 : <button disabled={dateTime(new Date) <= dateTime(gameData.Date)}>Make Pick</button>}
            </div>
            <div>
                {dateTime(gameData.Date)}
                {gameData.Channel}
                {gameData.StadiumDetails.City},
                {gameData.StadiumDetails.State}
                {gameData.HomeTeam}{gameData.PointSpread}
            </div>
            <div>
                <img style={{display:'block', width:100}} src={awayTeamLogoUrl} alt="Away Team Logo" />
                {awayTeamData.FullName}
                {dateTime(new Date) <= dateTime(gameData.Date) ? <button onClick={() => {makePick(gameData.AwayTeam)}}>Make Pick</button>
 : <button  disabled={dateTime(new Date) <= dateTime(gameData.Date)}>Make Pick</button>}
            </div>
          <button onClick={closeModal}>Close</button>
        </div>
      </div>
  );
};

export default Modal;