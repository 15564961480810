import { combineReducers } from 'redux';

import {
    CREATE_GAME_REQUEST,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAIL,
    EDIT_GAME_REQUEST,
    EDIT_GAME_SUCCESS,
    EDIT_GAME_FAIL,
    DELETE_GAME_REQUEST,
    DELETE_GAME_SUCCESS,
    DELETE_GAME_FAIL,
    FETCH_GAMES_REQUEST,
    FETCH_GAMES_SUCCESS,
    FETCH_GAMES_FAIL,
    FETCH_GAME_BY_ID_REQUEST,
    FETCH_GAME_BY_ID_SUCCESS,
    FETCH_GAME_BY_ID_FAIL,
    SAVE_PICKS_REQUEST,
    SAVE_PICKS_SUCCESS,
    SAVE_PICKS_FAIL,
    JOIN_GAME_REQUEST,
    JOIN_GAME_SUCCESS,
    JOIN_GAME_FAIL,
    JOIN_LINK_REQUEST,
    JOIN_LINK_SUCCESS,
    JOIN_LINK_FAIL,
    MULLIGEN_MADNES_REQUEST,
    MULLIGEN_MADNES_SUCCESS,
    MULLIGEN_MADNES_FAIL,
    GAME_PICKS_SAVE_REQUEST,
    GAME_PICKS_SAVE_SUCCESS,
    GAME_PICKS_SAVE_FAIL,
} from '../actions/gameImport';
// Initial state

const initialState = {
    games: [],
    game: {},
    usersGames: [],
    loading: false,
    error: null,
};

// Reducer

const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_GAME_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_GAME_SUCCESS:
            return {
                ...state,
                games: [...state.games, action.payload],
                loading: false,
            };
        case CREATE_GAME_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
            default:
                return state; // Return the current state for unrecognized actions

    }
}

const fetchGameByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GAME_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GAME_BY_ID_SUCCESS:
            return {
                ...state,
                game: action.payload,
                loading: false,
            };
        case FETCH_GAME_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}


const fetchGames = (state = initialState, action) => {
    switch (action.type) {
        case   FETCH_GAMES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GAMES_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case FETCH_GAMES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

const savePicks = (state = initialState, action) => {
    switch (action.type) {
        case   SAVE_PICKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAVE_PICKS_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case SAVE_PICKS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

const joinGame = (state = initialState, action) => {
    switch (action.type) {
        case   JOIN_GAME_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case JOIN_GAME_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case JOIN_GAME_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

const joinLink = (state = initialState, action) => {
    switch (action.type) {
        case JOIN_LINK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case JOIN_LINK_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case JOIN_LINK_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

const mulligenMadness = (state = initialState, action) => {
    switch (action.type) {
        case MULLIGEN_MADNES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MULLIGEN_MADNES_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case MULLIGEN_MADNES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

const gamePicksSave = (state = initialState, action) => {
    switch (action.type) {
        case GAME_PICKS_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GAME_PICKS_SAVE_SUCCESS:
            return {
                ...state,
                usersGames: action.payload,
                loading: false,
            };
        case GAME_PICKS_SAVE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state; // Return the current state for unrecognized actions
    }
}

// Combine your reducers using combineReducers
const gamesReducer = combineReducers({
    gameReducer: gameReducer,
    fetchGameByIdReducer: fetchGameByIdReducer,
    fetchGames: fetchGames,
    savePicks: savePicks,
    joinGame: joinGame,
    joinLink:joinLink,
    mulligenMadness:mulligenMadness,
    gamePicksSave:gamePicksSave,
  });

export default gamesReducer;
