import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const CountdownClock = ({ text, targetTimeISO, countdownTextColor, countdownBackgroundColor }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const targetTime = DateTime.fromISO(targetTimeISO, { zone: "America/New_York" });

    const textColor = countdownTextColor || '#002129';
    const backgroundColor = countdownBackgroundColor || '#FFC60A';

    const calculateTimeLeft = () => {
        const now = DateTime.now().setZone("America/New_York");
        const diff = targetTime.diff(now, ["days", "hours", "minutes", "seconds"]);
        return {
            days: Math.max(0, diff.days.toFixed(0)),
            hours: Math.max(0, diff.hours.toFixed(0)),
            minutes: Math.max(0, diff.minutes.toFixed(0)),
            seconds: Math.max(0, diff.seconds.toFixed(0)),
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, [targetTimeISO]);

    return (
        <div style={{ backgroundColor: backgroundColor, borderBottom: '2px solid #002129', borderTop: '2px solid #002129'}}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "8px" }}>
                <div style={{ fontWeight: "bold", color: textColor, fontSize: isMobile ? '16px' : '20px' }}>{text}</div>
                <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
                    {["days", "hours", "minutes", "seconds"].map((unit) => (
                        <div key={unit} style={{ textAlign: "center" }}>
                            <div style={{ fontSize: isMobile ? "16px" : '20px', fontWeight: "bold", color: textColor }}>
                                {timeLeft[unit]}
                            </div>
                            <div style={{ fontSize: isMobile ? "12px" : '16px', color: textColor }}>
                                {unit.charAt(0).toUpperCase() + unit.slice(1)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CountdownClock;
