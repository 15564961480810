import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import './CardPieces.scss';
import { getTeamLogo, getTeamNameShort, getTeamRecord } from '../../../utils/SportsData';

const GameInfoRow = ({ gameId, teams, schedule, standings, league }) => {

    const selectedGame = schedule.find(game => game.GlobalGameID === gameId);
    //console.log("Selected Game in GameInfoRow: ", selectedGame, gameId, teams, schedule, standings, league);

    const homeLogo = getTeamLogo(league, selectedGame.GlobalHomeTeamID, teams);
    const awayLogo = getTeamLogo(league, selectedGame.GlobalAwayTeamID, teams);
    const homeName = getTeamNameShort(league, selectedGame.GlobalHomeTeamID, teams);
    const awayName = getTeamNameShort(league, selectedGame.GlobalAwayTeamID, teams);
    const homeRank = selectedGame?.HomeTeamSeed || null;
    const awayRank = selectedGame?.AwayTeamSeed || null;
    const homeRecord = getTeamRecord(league, selectedGame.GlobalHomeTeamID, teams, standings);
    const awayRecord = getTeamRecord(league, selectedGame.GlobalAwayTeamID, teams, standings);
    const homeScore = selectedGame?.HomeScore || selectedGame?.HomTeamScore || selectedGame?.HomeTeamRuns || null;
    const awayScore = selectedGame?.AwayScore || selectedGame?.AwayTeamScore || selectedGame?.AwayTeamRuns || null;
    const gameTime = selectedGame?.Day;
    const gameDateTime = selectedGame?.DateTime;
    const pointSpread = selectedGame?.PointSpread;
    const gameLine = pointSpread == null 
      ? "No Line" 
      : `${homeName} ${pointSpread > 0 ? `+${pointSpread}` : pointSpread < 0 ? pointSpread : "PK"}`;
    const status = selectedGame?.Status;


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const homeNameAndRank = homeRank? `(${homeRank}) ${homeName}` : homeName;
    const awayNameAndRank = awayRank? `(${awayRank}) ${awayName}` : awayName;
    
    let textLine1 = null;
    if (status === 'Scheduled') {
        const datetime = DateTime.fromISO(gameTime);
        textLine1 = datetime.toFormat('EEE M/d');
    } else if (status === 'InProgress') {
        textLine1 = "LIVE";
    } else if (status === 'Final' || status === 'F/OT') {
        textLine1 = status;
    }
    let textLine2 = null;
    if (status === 'Scheduled') {
        const gameTime = DateTime.fromISO(gameDateTime, { zone: 'America/New_York' });
        
        if (gameTime.hour === 0 && gameTime.minute === 0) {
        textLine2 = 'TBD';
        } else {
        textLine2 = gameTime.toFormat('h:mma').toLowerCase() + ' ET';
        }
    } else if (status === 'InProgress') {
        textLine2 = gameLine;
    }

    let textLine3 = null; 
    if (status === 'Scheduled') {
        textLine3 = gameLine;
    }

  return (
    <Box className="quick-pick-game-info">
      <Box className="quick-pick-game-info__team quick-pick-game-info__away-team">
        <Box className="quick-pick-game-info__logo-container">
          <img src={awayLogo} alt="Away Team Logo" className="quick-pick-game-info__logo" />
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__record">
            {awayRecord}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__score-column quick-pick-game-info__away-score-column">
        <Box>
          <Typography className="quick-pick-game-info__team-name">
            {awayNameAndRank}
          </Typography>
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__score">
            {awayScore}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__game-details">
        <Typography className={`quick-pick-game-info__text-line ${textLine1 === "LIVE" ? "quick-pick-game-info__text-line--live" : ""}`}>
          {textLine1 ? textLine1 : ''}
        </Typography>
        <Typography className="quick-pick-game-info__text-line">
          {textLine2 ? textLine2 : ''}
        </Typography>
        <Typography className="quick-pick-game-info__text-line">
          {textLine3 ? textLine3 : ''}
        </Typography>
      </Box>

      <Box className="quick-pick-game-info__score-column quick-pick-game-info__home-score-column">
        <Box>
          <Typography className="quick-pick-game-info__team-name">
            {homeNameAndRank}
          </Typography>
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__score">
            {homeScore}
          </Typography>
        </Box>
      </Box>

      <Box className="quick-pick-game-info__team quick-pick-game-info__home-team">
        <Box className="quick-pick-game-info__logo-container">
          <img src={homeLogo} alt="Home Team Logo" className="quick-pick-game-info__logo" />
        </Box>
        <Box>
          <Typography className="quick-pick-game-info__record">
            {homeRecord}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GameInfoRow;
