import React from 'react';
import { Button, Table, TableHead, TableContainer, TableRow, TableCell, TableBody, Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useBirdieContext } from '../context/BirdiePoolContext';
import 'react-datepicker/dist/react-datepicker.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const EditModal = () => {
    const { startingScorecard, scorecardState, setScorecardState, setModalState, handleScoreCardSubmission, editHole, setEditHole, editDate, setEditDate, editIndex, setEditIndex, selectedUser, myUserName, selectedUserId, myUserId } = useBirdieContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleEditClick = (hole, index, date) => {
        //console.log(`Edit clicked for hole ${hole} at index ${index} for date ${date}`);
        setEditHole(hole);
        setEditIndex(index);
        setEditDate(new Date(date));
        setModalState("editCalendar");
    };

    const handleDateChange = (newDate) => {
        //console.log(`Date changed to ${newDate}`);
        setEditDate(newDate);
    };

    const handleDone = () => {
        //console.log("Done Button Hit");
        setModalState(false);
        setEditDate(null);
        setEditHole(null);
        setEditIndex(null);
        const haveEditsBeenMade = startingScorecard !== scorecardState;
        if (haveEditsBeenMade && (selectedUser === myUserName && selectedUserId === myUserId)) {
            window.location.href = window.location.href;
        }
    };

    const handleCancel = () => {
        //console.log("Cancel Button Hit");
        setEditHole(null);
        setEditIndex(null);
        setEditDate(null);
    };

    const handleSave = () => {
        //console.log("Save Button Hit");
        //console.log("Edit Hole:", editHole, "Edit Index:", editIndex, "Edit Date:", editDate.toLocaleDateString());
        const updatedScorecard = [...scorecardState];
        updatedScorecard[editHole - 1].birdieDates[editIndex] = editDate.toLocaleDateString();
        updatedScorecard[editHole - 1].birdieDates.sort((a, b) => new Date(a) - new Date(b));
        //console.log("Updated Scorecard:", updatedScorecard);
        setScorecardState(updatedScorecard);
        handleScoreCardSubmission();
        setEditHole(null);
        setEditIndex(null);
        setEditDate(null);
    };

    const today = new Date();
    const startDate = new Date(2024, 4, 1);
    const endDate = new Date(2024, 9, 31);
    let maxDate = today;
    if (maxDate > endDate) {
        maxDate = endDate;
    }

    const MAX_VISIBLE_ROWS = 10;

    // Flatten and sort birdie dates by date
    const allBirdieDates = [];
    scorecardState?.forEach(score => {
        score?.birdieDates?.forEach((date, index) => {
            allBirdieDates.push({ hole: score.hole, date, index });
        });
    });

    allBirdieDates.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date descending

    return (
        <>
            {(selectedUser === myUserName  && selectedUserId === myUserId) ? (<h2 style={{ padding: '10px 0'}}>Edit Birdies</h2>) : <h2 style={{ padding: '10px 0'}}>View Birdies</h2>}
            <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
                <TableContainer style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center', borderRight: 'none'}}>Hole</TableCell>
                                <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center', borderRight: 'none'}}>Date</TableCell>
                                {(selectedUser === myUserName  && selectedUserId === myUserId) ? (
                                <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center'}}>Edit</TableCell>
                                ) :
                                <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700'}}></TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allBirdieDates.map((entry, idx) => (
                                <TableRow key={`${entry.hole}-${entry.index}-${idx}`}>
                                    <TableCell sx={{ textAlign: 'center'}}>{entry.hole}</TableCell>
                                    <TableCell sx={{ textAlign: 'center'}}>{entry.date}</TableCell>
                                    <TableCell sx={{ textAlign: 'center'}}>
                                        {(selectedUser === myUserName  && selectedUserId === myUserId) ? (
                                            <CalendarMonthIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleEditClick(entry.hole, entry.index, entry.date)}
                                                fontSize="small"
                                            />
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <div>
                <Button style={{ marginTop: '18px' }} className='submit-button active' onClick={handleDone}>DONE</Button>
            </div>
        </>
    );
};

export default EditModal;
