import React from "react";
import Box from '@mui/material/Box';
import './bracketRoundNav.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const BracketRoundNav = (props) => {
    const { roundDash, setRoundDash, setRoundDasPosition } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function handleNavClick(round) {
        setRoundDasPosition[round](); // Access the function from the object using bracket notation
        setRoundDash(round); // Set the current round
    }

    return (
      <>
        {isMobile ? (
          <Box className={"bracket-round-nav"}>
            <Box className={"bracket-round-nav-container"}>
                <Box className={`bracket-round-nav-item ${roundDash === 'ROUND_ONE' ? 'active' : ''}`} onClick={() => handleNavClick('ROUND_ONE')}><span>1ST</span> 1PT</Box>
                <Box className={`bracket-round-nav-item ${roundDash === 'ROUND_TWO' ? 'active' : ''}`} onClick={() => handleNavClick('ROUND_TWO')}><span>2ND</span> 2PTS</Box>
                <Box className={`bracket-round-nav-item ${roundDash === 'CONF' ? 'active' : ''}`} onClick={() => handleNavClick('CONF')}><span>CONF</span> 4PTS</Box>
                <Box className={`bracket-round-nav-item ${roundDash === 'FINALS' ? 'active' : ''}`} onClick={() => handleNavClick('FINALS')}><span>FINALS</span> 8PTS</Box>
            </Box>
          </Box>
        ) : (
          <Box className={"bracket-round-nav"}>
            <Box className={"bracket-round-nav-container"}>
                <Box className={`bracket-round-nav-item`}><span>1ST</span> 1PT</Box>
                <Box className={`bracket-round-nav-item`}><span>2ND</span> 2PTS</Box>
                <Box className={`bracket-round-nav-item`}><span>CONF</span> 4PTS</Box>
                <Box className={`bracket-round-nav-item`}><span>FINALS</span> 8PTS</Box>
                <Box className={`bracket-round-nav-item`}><span>CONF</span> 4PTS</Box>
                <Box className={`bracket-round-nav-item`}><span>2ND</span> 2PTS</Box>
                <Box className={`bracket-round-nav-item`}><span>1ST</span> 1PT</Box>
            </Box>
          </Box>
        )}
      </>
    );
}

export default BracketRoundNav;