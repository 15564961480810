import React from "react";
import { Box, Typography } from "@mui/material";
import BracketGame from "./BracketGame";
import BracketChamp from "./BracketChamp";
import "./FourTeamBracket.scss";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import TiebreakerTwoTextInput from "../../GamePageComponents/Tiebreakers/TiebreakerTwoTextInput";
import TiebreakerSingleInput from "../../GamePageComponents/Tiebreakers/TiebreakerSingleInput";
import { over } from "lodash";

const FourTeamBracket = ({
    bracket,
    games,
    teams,
    league,
    handleGameClick,
    topLeft,
    bottomLeft,
    topRight,
    bottomRight,
    round1Ref,
    round2Ref,
    round3Ref,
    pickState,
    overrideTiebreaker,
    userTiebreaker,
}) => {
    const {
        setPickState,
        hasJoinDeadlinePassed,
        intervalInfo,
    } = useUserAndGameContext();

    const firstFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topLeft}-${bottomLeft}`)?.teamId;
    const secondFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topRight}-${bottomRight}`)?.teamId;

    return (
        // Add regional-bracket-container class for consistent navigation
        <Box className="final-four-container regional-bracket-container">
            <Box className="final-four regional-bracket" sx={{ marginTop: '35px'}}>
                {/*Final Four Column*/}
                <Box className="final-four-column final-four-column-games-first regional-bracket-column">
                    <Box sx={{ width: "230px", marginBottom: "24px", paddingBottom: "30px", marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{intervalInfo?.info[4]?.label}</Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                            {intervalInfo?.info[4]?.points} Point{intervalInfo?.info[4]?.points > 1 ? "s" : ""}
                        </Typography>
                    </Box>
                    <Box ref={round1Ref}>
                        {Array(2).fill(null).map((_, index) => (
                            <Box key={`round1-${index}`} className="final-four-game regional-bracket-game">
                                <BracketGame
                                    bracket={bracket}
                                    round={5}
                                    tournamentDisplayOrder={index + 1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    topLeft={topLeft}
                                    bottomLeft={bottomLeft}
                                    topRight={topRight}
                                    bottomRight={bottomRight}
                                    pickState={pickState}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/*Final Four Connectors*/}
                <Box className="final-four-connector-column first-round regional-bracket-connector-column">
                    <Box key={`connector1-1`} className="connector"></Box>
                </Box>

                {/*Championship Column*/}
                <Box className="final-four-column final-four-column-games-second regional-bracket-column">
                    <Box sx={{ width: "230px", marginBottom: "24px", paddingBottom: "134px", marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                            {intervalInfo?.info?.[5]?.label}
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                            {intervalInfo?.info?.[5]?.points ?? 0} Point{intervalInfo?.info?.[5]?.points !== 1 ? "s" : ""}
                        </Typography>
                    </Box>
                    <Box ref={round2Ref}>
                        {Array.from({ length: 1 }, (_, index) => (
                            <Box key={`round2-${index}`} className="final-four-game regional-bracket-game">
                                <BracketGame
                                    bracket={bracket}
                                    round={6}
                                    tournamentDisplayOrder={1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    topLeft={topLeft}
                                    bottomLeft={bottomLeft}
                                    topRight={topRight}
                                    bottomRight={bottomRight}
                                    pickState={pickState}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/*Championship Connectors*/}
                <Box className="final-four-connector-column second-round regional-bracket-connector-column">
                    <Box key={`connector1-1`} className="connector"></Box>
                </Box>

                {/*Championship Column*/}
                <Box
                    ref={round3Ref}
                    className="final-four-column final-four-column-games-second regional-bracket-column"
                    sx={{ marginLeft: '-6px', marginTop: '24px' }}
                >
                    <Box key={`round2-1`} className="final-four-game regional-bracket-game" sx={{ marginTop: '60px' }}>
                        <BracketChamp
                            bracket={bracket}
                            round={6}
                            tournamentDisplayOrder={1}
                            pickState={pickState}
                            games={games}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                        />
                    </Box>
                    <Box sx={{ marginTop: '-60px' }}>
                        <TiebreakerSingleInput
                            firstFinalist={firstFinalist}
                            secondFinalist={secondFinalist}
                            league={league}
                            teams={teams}
                            hasDeadlinePassed={hasJoinDeadlinePassed}
                            overrideTiebreaker={overrideTiebreaker}
                            userTiebreaker={userTiebreaker}
                        />
                    </Box>
                </Box>
                <Box sx={{ minWidth: '75px', minHeight: '20px' }} />
            </Box>
        </Box>
    );
}

export default FourTeamBracket;