import React from 'react';
import { Box, Typography } from '@mui/material';

const BracketTiebreakerInfo = ({ bracketTiebreakerInfo }) => {

    return (
        <Box
            sx={{
                margin: '0 auto',
                border: '2px solid #002129',
                width: '206px',
                padding: '22px 8px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    marginTop: '22px',
                    margin: '0 auto',
                }}
            >
                POOL TIEBREAKER
            </Typography>
            {bracketTiebreakerInfo?.lines.map((line, index) => (
                <Typography
                    key={index}
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                        margin: '0 auto',
                        padding: '16px 0px',
                    }}
                >
                    {line}
                </Typography>
            ))}

        </Box>
    )
}

export default BracketTiebreakerInfo;