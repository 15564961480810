import React, { useState, useEffect } from 'react';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useSurvivorGamesContext } from '../context/SurvivorGamesContext';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTeamLogo, getTeamNameShort } from '../../../utils/SportsData';
import './SurvivorPicksBar.scss';

const SurvivorPicksBar = ({ interval }) => {

    const {
        pickState,
        league,
        intervalInfo,
    } = useUserAndGameContext();

    const {
        schedule,
        teams,
        isEntryEliminated,
        eliminationInterval,
    } = useSurvivorGamesContext();
    //console.log("League:", league, "Teams", teams);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    
    const [leftSideGame, setLeftSideGame] = useState([]);
    const [rightSideGame, setRightSideGame] = useState([]);
    const [middleGame, setMiddleGame] = useState([]);

    const firstInterval = intervalInfo?.info[0].interval;
    const lastInterval = intervalInfo?.info[intervalInfo.info.length - 1].interval;
    //console.log("First Interval:", firstInterval, "Last Interval:", lastInterval, "isEntryEliminated:", isEntryEliminated, "Elimination Interval:", eliminationInterval);

    // useEffect to set the left, right, and middle games
    useEffect(() => {
        if (schedule) {
            const leftGame = (isEntryEliminated && eliminationInterval < Number(interval) - 1) 
                ? "eliminated" 
                : pickState?.find(pick => pick.interval === Number(interval) - 1);
            const middleGame = (isEntryEliminated && eliminationInterval < Number(interval))
                ? "eliminated"
                : pickState?.find(pick => pick.interval === Number(interval));
            const rightGame = (isEntryEliminated && eliminationInterval < Number(interval) + 1)
                ? "eliminated"
                : pickState?.find(pick => pick.interval === Number(interval) + 1);
            setLeftSideGame(leftGame);
            setMiddleGame(middleGame);
            setRightSideGame(rightGame);
        }
    }, [pickState, interval, schedule, isEntryEliminated, eliminationInterval]);

    //console.log("Interval", interval, "Left Game:", leftSideGame, "Middle Game:", middleGame, "Right Game:", rightSideGame);

    //Function to handle changing the interval
    const handleChange = (direction) => {
        if (direction === 'left') {
            if (Number(interval) > firstInterval) {
                setSearchParams(params => {
                    const newParams = new URLSearchParams(params);
                    newParams.set('interval', Number(interval) - 1);
                    return newParams;
                });
            } else {
                return;
            }
        } else if (direction === 'right') {
            if (Number(interval) < lastInterval) {
                setSearchParams(params => {
                    const newParams = new URLSearchParams(params);
                    newParams.set('interval', Number(interval) + 1);
                    return newParams;
                });
            } else {
                return;
            }
        }
    }

    // Function to display the top text
    const topDisplayText = (position) => {
        if (intervalInfo?.type === 'weeks') {
            if (position === 'left') {
                return `Week ${Number(interval) - 1}`;
            } else if (position === 'middle') {
                return `Week ${interval}`;
            } else if (position === 'right') {
                return `Week ${Number(interval) + 1}`;
            }
        } else if (intervalInfo?.type === 'date') {
            if (position === 'left') {
                const display = intervalInfo?.info.find(item => item.interval === Number(interval) - 1)?.shortLabel;
                return display || null;
            } else if (position === 'middle') {
                const display = intervalInfo?.info.find(item => item.interval === Number(interval))?.shortLabel;
                return display || null;
            } else if (position === 'right') {
                const display = intervalInfo?.info.find(item => item.interval === Number(interval) + 1)?.shortLabel;
                return display || null;
            }
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
    }

    // Function to display the bottom text
    const bottomDisplayText = (game, position) => {
        if (game === "eliminated") {
            return "OUT";
        } else if (!game) {
            const intervalToCheck = position === 'right' ? Number(interval) + 1 : position === 'left' ? Number(interval) - 1 : Number(interval);
            const isIntervalValid = intervalToCheck >= firstInterval && intervalToCheck <= lastInterval;
            if (!isIntervalValid) return null;
            const date = intervalInfo?.info.find(item => item.interval === intervalToCheck)?.date;
            return formatDate(date) || null;
        } else if (game?.teamId === 'No Pick') {
            return "No Pick";
        } else {
            const gameInSchedule = schedule?.find(scheduleGame => scheduleGame.GlobalGameID === game.gameId);
            const opponent = gameInSchedule?.GlobalAwayTeamID === game.teamId ? gameInSchedule?.GlobalHomeTeamID : gameInSchedule?.GlobalAwayTeamID;
            const opponentName = getTeamNameShort(league, opponent, teams);
            return `vs ${opponentName}`;
        }
    }
            


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: isMobile ? '24px' : '48px',
                padding: '16px 8px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box>
                <ArrowBackIosNew 
                    onClick={() => handleChange('left')} 
                    sx={{
                        fontSize: '24px',
                        color: Number(interval) === firstInterval ? 'grey' : 'black',
                        cursor: Number(interval) === firstInterval ? 'not-allowed' : 'pointer',
                    }}
                />
            </Box>

            <Box 
                className={`survivor-picks-bar-small-container ${Number(interval) === firstInterval ? 'placeholder' : ''}`}
                onClick={() => handleChange('left')}
            >
                <Box className="survivor-picks-bar-small-game">
                    <Typography className="survivor-picks-bar-small-top-text">
                        {topDisplayText('left')}
                    </Typography>
                    <Box 
                        className="survivor-picks-bar-small-logo-container"
                        sx={{
                            border: (leftSideGame === "eliminated" || leftSideGame?.result === 'loss') 
                                ? '1px solid #CC293C'
                                : leftSideGame?.result === 'win'
                                    ? '1px solid #00AA72'
                                    : '',
                            backgroundColor: leftSideGame?.result === 'loss'
                                ? 'rgba(204, 41, 60, 0.2)'
                                : leftSideGame?.result === 'win'
                                    ? 'rgba(0, 170, 114, 0.2)'
                                    : 'inherit',
                        }}
                    >
                        {(leftSideGame === "eliminated" || leftSideGame?.teamId === 'No Pick') ? (
                            <Box>
                                <CloseIcon className="survivor-picks-bar-small-eliminated-icon" />
                            </Box>    
                        ) : (
                            !leftSideGame ? (
                                <Box className="survivor-picks-bar-small-empty-logo" />
                            ) : (
                                <img
                                    className="survivor-picks-bar-small-logo"
                                    loading="lazy"
                                    alt="Team Logo"
                                    src={getTeamLogo(league, leftSideGame?.teamId, teams)}
                                />
                            )
                        )}
                    </Box>

                    <Box className="survivor-picks-bar-small-bottom-text-container">
                        <Typography 
                            className="survivor-picks-bar-small-bottom-text"
                            sx={{
                                color: (leftSideGame === "eliminated" || leftSideGame?.result === 'loss')
                                ? '#CC293C'
                                    : ''
                            }}
                        >
                            {bottomDisplayText(leftSideGame, 'left')}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="survivor-picks-bar-large-container">
                <Box className="survivor-picks-bar-large-game">
                    <Typography className="survivor-picks-bar-large-top-text">
                        {topDisplayText('middle')}
                    </Typography>
                    <Box 
                        className="survivor-picks-bar-large-logo-container"
                        sx={{
                            border: (middleGame === "eliminated" || middleGame?.result === 'loss') 
                                ? '1px solid #CC293C'
                                : middleGame?.result === 'win'
                                    ? '1px solid #00AA72'
                                    : '',
                            backgroundColor: middleGame?.result === 'loss'
                                ? 'rgba(204, 41, 60, 0.2)'
                                : middleGame?.result === 'win'
                                    ? 'rgba(0, 170, 114, 0.2)'
                                    : 'inherit',
                        }}
                    >
                        {(middleGame === "eliminated" || middleGame?.teamId === 'No Pick') ? (
                            <Box>
                                <CloseIcon className="survivor-picks-bar-large-eliminated-icon" />
                            </Box>
                        ) : (
                            !middleGame ? (
                                <Box className="survivor-picks-bar-large-empty-logo" />
                            ) : (
                                <img
                                    className="survivor-picks-bar-large-logo"
                                    loading="lazy"
                                    alt="Team Logo"
                                    src={getTeamLogo(league, middleGame?.teamId, teams)}
                                />
                            )
                        )}
                    </Box>

                    <Box className="survivor-picks-bar-large-bottom-text-container">
                        <Typography 
                            className="survivor-picks-bar-large-bottom-text"
                            sx={{
                                color: (middleGame === "eliminated" || middleGame?.result === 'loss')
                                ? '#CC293C'
                                    : ''
                            }}
                        >
                            {bottomDisplayText(middleGame, 'middle')}
                        </Typography>
                    </Box>
                </Box>
            </Box>



            <Box 
                className={`survivor-picks-bar-small-container ${Number(interval) === lastInterval ? 'placeholder' : ''}`}
                onClick={() => handleChange('right')}
            >
                <Box className="survivor-picks-bar-small-game">
                    <Typography className="survivor-picks-bar-small-top-text">
                        {topDisplayText('right')}
                    </Typography>
                    <Box 
                        className="survivor-picks-bar-small-logo-container"
                        sx={{
                            border: (rightSideGame === "eliminated" || rightSideGame?.result === 'loss') 
                                ? '1px solid #CC293C'
                                : rightSideGame?.result === 'win'
                                    ? '1px solid #00AA72'
                                    : '',
                            backgroundColor: rightSideGame?.result === 'loss'
                                ? 'rgba(204, 41, 60, 0.2)'
                                : rightSideGame?.result === 'win'
                                    ? 'rgba(0, 170, 114, 0.2)'
                                    : 'inherit',
                        }}
                    >
                        {(rightSideGame === "eliminated" || rightSideGame?.teamId === 'No Pick') ? (
                            <Box>
                                <CloseIcon className="survivor-picks-bar-small-eliminated-icon" />
                            </Box>    
                        ) : (
                            !rightSideGame ? (
                                <Box className="survivor-picks-bar-small-empty-logo" />
                            ) : (
                                <img
                                    className="survivor-picks-bar-small-logo"
                                    loading="lazy"
                                    alt="Team Logo"
                                    src={getTeamLogo(league, rightSideGame?.teamId, teams)}
                                />
                            )
                        )}
                    </Box>

                    <Box className="survivor-picks-bar-small-bottom-text-container">
                        <Typography 
                            className="survivor-picks-bar-small-bottom-text"
                            sx={{
                                color: (rightSideGame === "eliminated" || rightSideGame?.result === 'loss')
                                ? '#CC293C'
                                    : ''
                            }}
                        >
                            {bottomDisplayText(rightSideGame, 'right')}
                        </Typography>
                    </Box>
                </Box>
            </Box>


            <Box>
                <ArrowBackIosNew
                    onClick={() => handleChange('right')}
                    sx={{
                        fontSize: '24px',
                        color: Number(interval) === lastInterval ? 'grey' : 'black',
                        cursor: Number(interval) === lastInterval ? 'not-allowed' : 'pointer',
                        transform: 'rotate(180deg)',
                    }}
                />
            </Box>
        </Box>
    )
};

export default SurvivorPicksBar;