import React from 'react';
import NBAPlayoffsContainer from '../components/NBAPlayoffs/NBAPlayoffsContainer';

const NBAPlayoffsPage = () => {
    return (
        <>
            <NBAPlayoffsContainer />
        </>
    );
}

export default NBAPlayoffsPage;