import React from "react";
import { Box, Typography } from "@mui/material";
import BracketGameDesktop from "./BracketGameDesktop";
import BracketChamp from "./BracketChamp";
import "./FourTeamBracketDesktop.scss";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import TiebreakerTwoTextInput from "../../GamePageComponents/Tiebreakers/TiebreakerTwoTextInput";
import TiebreakerSingleInput from "../../GamePageComponents/Tiebreakers/TiebreakerSingleInput";

const FourTeamBracketDesktop = ({
    bracket,
    games,
    teams,
    league,
    handleGameClick,
    topLeft,
    bottomLeft,
    topRight,
    bottomRight,
    round1Ref,
    round2Ref,
    round3Ref,
    pickState,
    overrideTiebreaker,
    userTiebreaker,
}) => {
    const {
        // pickState,
        setPickState,
        hasJoinDeadlinePassed,
        intervalInfo,
    } = useUserAndGameContext();

    const firstFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topLeft}-${bottomLeft}`)?.teamId;
    const secondFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topRight}-${bottomRight}`)?.teamId;

    return (
        // Add regional-bracket-desktop-container class for consistent navigation
        <Box className="final-four-container regional-bracket-desktop-container" sx={{marginLeft: '-15px'}}>
            <Box className="final-four regional-bracket-desktop" sx={{ marginTop: '35px'}}>
                {/*Final Four Column*/}
                <Box className="final-four-column final-four-column-games-first regional-bracket-desktop-column">
                    {/* <Box sx={{ width: "230px", marginBottom: "24px", paddingBottom: "30px", marginLeft: '50px' }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>{intervalInfo?.info[4]?.label}</Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                            {intervalInfo?.info[4]?.points} Point{intervalInfo?.info[4]?.points > 1 ? "s" : ""}
                        </Typography>
                    </Box> */}
                    {/* <Box ref={round1Ref}>
                        {Array(2).fill(null).map((_, index) => (
                            <Box key={`round1-${index}`} className="final-four-game regional-bracket-desktop-game">
                                <BracketGameDesktop
                                    bracket={bracket}
                                    round={5}
                                    tournamentDisplayOrder={index + 1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    topLeft={topLeft}
                                    bottomLeft={bottomLeft}
                                    topRight={topRight}
                                    bottomRight={bottomRight}
                                />
                            </Box>
                        ))}
                    </Box> */}
                </Box>

                {/*Final Four Connectors*/}
                {/* <Box className="final-four-connector-column first-round regional-bracket-desktop-connector-column">
                    <Box key={`connector1-1`} className="connector"></Box>
                </Box> */}



                {/*Championship Connectors*/}
                {/* <Box className="final-four-connector-column second-round regional-bracket-desktop-connector-column">
                    <Box key={`connector1-1`} className="connector"></Box>
                </Box> */}

                {/*Championship Column*/}
                <Box
                    ref={round3Ref}
                    className="final-four-column final-four-column-games-second regional-bracket-desktop-column"
                    sx={{margin:'0 auto !important'}}
                >
                    <Box key={`round2-1`} className="final-four-game regional-bracket-desktop-game" sx={{ margin: '60px auto 270px auto', paddingLeft: hasJoinDeadlinePassed ? '104px' : '' }}>
                        <BracketChamp
                            bracket={bracket}
                            round={6}
                            tournamentDisplayOrder={1}
                            pickState={pickState}
                            games={games}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                        />
                    </Box>
                      {/*Championship Column*/}
                    <Box className="final-four-column final-four-column-games-second regional-bracket-desktop-column" sx={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '24px', marginTop: hasJoinDeadlinePassed ? '-20px' : '-65px'}}>
                        {/* <Box sx={{ width: "230px", marginBottom: "24px", paddingBottom: "134px", marginLeft: '50px' }}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
                                {intervalInfo?.info?.[5]?.label}
                            </Typography>
                            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                {intervalInfo?.info?.[5]?.points ?? 0} Point{intervalInfo?.info?.[5]?.points !== 1 ? "s" : ""}
                            </Typography>
                        </Box> */}
                         <Box ref={round1Ref}>
                            <Box key={`round1-0`} className="final-four-game regional-bracket-desktop-game final-four-left">
                                <BracketGameDesktop
                                    bracket={bracket}
                                    round={5}
                                    tournamentDisplayOrder={1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    topLeft={topLeft}
                                    bottomLeft={bottomLeft}
                                    topRight={topRight}
                                    bottomRight={bottomRight}
                                    pickState={pickState}
                                />
                            </Box>
                        </Box>
                        <Box ref={round2Ref}>
                            {Array.from({ length: 1 }, (_, index) => (
                                <Box key={`round2-${index}`} className="final-four-game regional-bracket-desktop-game" sx={{ marginLeft: '12px', marginRight: '12px'}}>
                                    <BracketGameDesktop
                                        bracket={bracket}
                                        round={6}
                                        tournamentDisplayOrder={1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        topLeft={topLeft}
                                        bottomLeft={bottomLeft}
                                        topRight={topRight}
                                        bottomRight={bottomRight}
                                        pickState={pickState}
                                    />
                                </Box>
                            ))}
                        </Box>
                        <Box ref={round1Ref}>
                            <Box key={`round1-1`} className="final-four-game regional-bracket-desktop-game final-four-right">
                                <BracketGameDesktop
                                    bracket={bracket}
                                    round={5}
                                    tournamentDisplayOrder={2}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    topLeft={topLeft}
                                    bottomLeft={bottomLeft}
                                    topRight={topRight}
                                    bottomRight={bottomRight}
                                    pickState={pickState}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '-60px', marginLeft: '256px', width: '250px', justifyContent: 'center' }}>
                        <TiebreakerSingleInput
                            firstFinalist={firstFinalist}
                            secondFinalist={secondFinalist}
                            league={league}
                            teams={teams}
                            hasDeadlinePassed={hasJoinDeadlinePassed}
                            desktop={true}
                            overrideTiebreaker={overrideTiebreaker}
                            userTiebreaker={userTiebreaker}
                        />
                    </Box>
                </Box>
                <Box sx={{ minWidth: '75px', minHeight: '20px' }} />
            </Box>
        </Box>
    );
}

export default FourTeamBracketDesktop;