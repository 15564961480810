import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Brackets from '../Brackets/Brackets';
import {getUserDataFromLocalStorage} from '../../redux/actions/loginImport';
import {fetchNBATeams, fetchNBAStandings, fetchNBAGameOddsByDate, fetchNBAGamesByDate, } from '../../redux/actions/dataImport';
import {getNbaPlayoffLeaderboardAction, getSubgroupLeaderboardAction} from '../../redux/actions/loginImport';
import SuperPickem from '../SuperPickem/SuperPickem';
import LeaderBoard from './Leaderboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BracketHeader from '../Brackets/BracketHeader/bracketHeader';
import BracketNav from '../Brackets/BracketHeader/bracketNav';
import PoolPicks from './PoolPicks';
import Rules from './rules';
import BracketsLive from '../Brackets/BracketsLive';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const NBAPlayoffsContainer = (props) => {
  console.log("incoming props", props)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const location = useLocation();
  const userData = getUserDataFromLocalStorage();
  const [clickState, setClickState ] = useState('BRACKET_VIEW');
  
  useEffect(() => {

    dispatch(fetchNBATeams());
    dispatch(fetchNBAStandings());
    dispatch(fetchNBAGameOddsByDate());
    dispatch(fetchNBAGamesByDate());
    dispatch(getNbaPlayoffLeaderboardAction());
    dispatch(getSubgroupLeaderboardAction());

  }, [dispatch]);

  useEffect(() => {
    // Check for existing game by name in userData
    const targetGameName = "NBA Playoffs";
    const foundGame = userData?.Games?.find(game => game.pool?.gameName === targetGameName);

    if (foundGame) {
      const newPath = `/nba-super-pickem/SuperPickem/${foundGame.gameID}`;
      if (location.pathname !== newPath) {
        navigateToTop(newPath);
      }
    }
  }, [userData, navigate, location.pathname]);

  const NBATeams = props?.sportsData?.nbaTeamsData;
  const NBAStandings = props?.sportsData?.nbaStandingsData;
  const nbaGameOddsByDate = props?.sportsData?.nbaGameOddsData;
  const nbaGamesByDate = props?.sportsData?.nbaGamesByDateData;
  const leaderboardInfo = props?.user?.login?.getNbaPlayoffLeaderboardReducer;
  const subGroupLeaderBoard = props?.user?.login?.getSubgroupLeaderboardReducer;
  const leaderBoardResults = leaderboardInfo?.data?.results;
  const subLeaderBoardResults = subGroupLeaderBoard?.data?.subGroupResults;
  console.log('SELECTED USERNAME', leaderboardInfo, leaderBoardResults?.length, subLeaderBoardResults);
  const hasData = leaderboardInfo.data &&  leaderBoardResults?.length > 0 && NBATeams.data && NBATeams.data.length > 0 && NBAStandings.data && NBAStandings.data.length > 0 && nbaGameOddsByDate.data && nbaGameOddsByDate.data.length > 0 && nbaGamesByDate.data && nbaGamesByDate.data.length > 0 && subLeaderBoardResults && subLeaderBoardResults?.length > 0;

  if (!userData) {
    navigateToTop('/login');
    return null; // Return null to prevent rendering
  }

  return (
    <>
      {userData && (
        <div>
          {hasData ? (
            <>
              <BracketHeader leaderboardInfo={leaderboardInfo} NBATeams={NBATeams.data} nbaGamesByDate={nbaGamesByDate.data}/>
              <BracketNav clickState={clickState} setClickState={setClickState} />
              {clickState === 'BRACKET_VIEW' ? (
                <BracketsLive NBATeams={NBATeams.data} NBAStandings={NBAStandings.data} leaderboardInfo={leaderboardInfo} />
              ) : ( <></> )}
              {clickState === 'PICKS_VIEW' ? (
                <SuperPickem NBATeams={NBATeams.data} NBAStandings={NBAStandings.data} nbaGameOddsByDate={nbaGameOddsByDate.data} nbaGamesByDate={nbaGamesByDate.data} />
              ) : ( <></> )}
              {clickState === 'LEADER_BOARD_VIEW' ? (
                <LeaderBoard leaderboardInfo={leaderboardInfo} NBATeams={NBATeams.data} subLeaderBoardResults={subLeaderBoardResults} nbaGamesByDate={nbaGamesByDate.data} />
              ) : ( <></> )}
              {clickState === 'POOL_PICKS_VIEW' ? (
                <PoolPicks leaderboardInfo={leaderboardInfo} NBATeams={NBATeams.data} nbaGamesByDate={nbaGamesByDate.data} />
              ) : ( <></> )}
              {clickState === 'RULES_VIEW' ? (
                <Rules />
              ) : ( <></> )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  sportsData: state.sportsData,
  user: state,
});

export default connect(mapStateToProps)(NBAPlayoffsContainer);