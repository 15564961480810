import React, { useState } from "react";
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext.js";
import { useBracketGamesContext } from "../../../BracketGames/context/BracketGamesContext.js";
import { Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import BracketSingleEntryView from "./BracketSingleEntryView.js";
import BracketMultipleEntryView from "./BracketMultipleEntryView.js";
import { hexToRgba } from "../../../Brackets/bracketUtils/HexToRgba.js";

const BracketGamesUserDash = ({ canIAddAnEntry, setIndexToRemove, setRemoveModalOpen }) => {
    const {
        allUsersEntries,
        usersIndexesInThisPool,
        myLeaderboardInfo,
        masterPoolData,
        pickIndex,
        hasJoinDeadlinePassed,
        setJoinOpen,
        isThisPoolPartyGame,
        isUserAdmin,    
    } = useUserAndGameContext();

    const { 
        teams,
        maxPossiblePoints,
     } = useBracketGamesContext();

    const [imageLoading, setImageLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    const firstGameTime = masterPoolData?.gameParameters?.firstGameTime;
    const firstGameTimeInNewYork = firstGameTime ? DateTime.fromISO(firstGameTime, { zone: 'America/New_York' }) : null;
    const nowInNewYork = DateTime.now().setZone('America/New_York');
    const hasFirstGameStarted = firstGameTimeInNewYork ? firstGameTimeInNewYork < nowInNewYork : false;
    const league = masterPoolData?.gameParameters?.league;

    const picksToMake = masterPoolData?.gameParameters?.picksToMake;
    const tiebreakerCount = masterPoolData?.gameParameters?.tiebreakerCount;
    const rounds = masterPoolData?.gameParameters?.rounds;
    const lastRound = rounds ? rounds[rounds.length - 1] : null;

    function haveAllPicksBeenMade(pool, picksToMake, tiebreakerCount) {
        if (!pool) return false;
        
        const picks = pool?.picks;
        const tiebreaker = pool?.tiebreaker;
    
        // Ensure picks?.length must always equal picksToMake
        if (!picks || picks?.length !== picksToMake) {
            return false;
        }
    
        // If tiebreakerCount is provided, ensure it matches the tiebreaker length
        if (tiebreakerCount !== undefined) {
            return tiebreaker?.length === tiebreakerCount;
        }
    
        // If no tiebreakerCount check is needed, return true
        return true;
    }
    
    function findChampId(picks) {
        if (!picks || !lastRound) return null;
        
        const champPick = picks?.find(pick => pick.round === lastRound);
        return champPick?.teamId;
    }

    function findChampResult(picks) {
        if (!picks || !lastRound) return null;
        
        const champPick = picks?.find(pick => pick.round === lastRound);
        return champPick?.result;
    }

    function findMyRank(myLeaderboardInfo, entryName) {
        if (!myLeaderboardInfo || !entryName) return null;
        
        const rank = myLeaderboardInfo?.myInfo?.find(entry => entry?.username === entryName)?.rank;
        return rank;
    }

    function findMyPoints(myLeaderboardInfo, entryName) {
        if (!myLeaderboardInfo || !entryName) return 0;

        const points = myLeaderboardInfo?.myInfo?.find(entry => entry?.username === entryName)?.bracketPoints;
        return points || 0;
    }

    function findMyMaxPossiblePoints(myLeaderboardInfo, entryName) {
        if (!myLeaderboardInfo || !entryName) return null;

        const maxPossiblePoints = myLeaderboardInfo?.myInfo?.find(entry => entry?.username === entryName)?.maxPossiblePoints;
        return maxPossiblePoints;
    }

    const totalEntriesCount = myLeaderboardInfo?.entryCount || 0;

    const handleImageLoad = () => {
        setImageLoading(false);
        setImageError(false);
    };

    const handleImageError = () => {
        setImageLoading(false);
        setImageError(true);
    };

    const getChampBoxStyle = (result) => {
        if (result === 'win') {
            return {
                border: '2px solid #00AA72',
                backgroundColor: hexToRgba('#00AA72', 0.2)
            };
        } else if (result === 'loss') {
            return {
                border: '2px solid #CC293C',
                backgroundColor: hexToRgba('#CC293C', 0.2)
            };
        }
        return {
            border: '1px solid #002129',
            backgroundColor: '#fff'
        };
    };

    // Check if we have the necessary data
    if (!allUsersEntries || !usersIndexesInThisPool || usersIndexesInThisPool.length === 0) {
        return (
            <Box sx={{ padding: 2 }}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Loading your entries...
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {allUsersEntries && usersIndexesInThisPool ? 
                        `Looking for entries (${usersIndexesInThisPool.length} index(es) in pool)` : 
                        "Waiting for entry data..."
                    }
                </Typography>
            </Box>
        );
    }

    // Get entries for this pool
    const entriesInThisPool = usersIndexesInThisPool
        .filter(index => index >= 0 && index < allUsersEntries.length)
        .map(index => allUsersEntries[index]);

    return (
        <Box>
            {entriesInThisPool?.length === 1 && !canIAddAnEntry && (
                <BracketSingleEntryView
                    entry={entriesInThisPool[0]}
                    totalEntriesCount={totalEntriesCount}
                    hasFirstGameStarted={hasFirstGameStarted}
                    picksToMake={picksToMake}
                    league={league}
                    teams={teams}
                    haveAllPicksBeenMade={haveAllPicksBeenMade}
                    findChampId={findChampId}
                    findChampResult={findChampResult}
                    handleImageError={handleImageError}
                    handleImageLoad={handleImageLoad}
                    getChampBoxStyle={getChampBoxStyle}
                    imageError={imageError}
                    imageLoading={imageLoading}
                    setImageError={setImageError}
                    setImageLoading={setImageLoading}
                    findMyRank={findMyRank}
                    findMyPoints={findMyPoints}
                    findMyMaxPossiblePoints={findMyMaxPossiblePoints}
                    myLeaderboardInfo={myLeaderboardInfo}
                    setIndexToRemove={setIndexToRemove}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isThisPoolPartyGame={isThisPoolPartyGame}
                    pickIndex={pickIndex}
                    isUserAdmin={isUserAdmin}
                    tiebreakerCount={tiebreakerCount}
                />
            )}
            {(entriesInThisPool?.length > 1 || canIAddAnEntry) && (
                <BracketMultipleEntryView
                    entries={entriesInThisPool}
                    totalEntriesCount={totalEntriesCount}
                    hasFirstGameStarted={hasFirstGameStarted}
                    picksToMake={picksToMake}
                    league={league}
                    teams={teams}
                    haveAllPicksBeenMade={haveAllPicksBeenMade}
                    findChampId={findChampId}
                    findChampResult={findChampResult}
                    setImageError={setImageError}
                    setImageLoading={setImageLoading}
                    handleImageError={handleImageError}
                    handleImageLoad={handleImageLoad}
                    getChampBoxStyle={getChampBoxStyle}
                    imageError={imageError}
                    imageLoading={imageLoading}
                    pickIndex={pickIndex}
                    findMyRank={findMyRank}
                    findMyPoints={findMyPoints}
                    findMyMaxPossiblePoints={findMyMaxPossiblePoints}
                    myLeaderboardInfo={myLeaderboardInfo}
                    canIAddAnEntry={canIAddAnEntry}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    setIndexToRemove={setIndexToRemove}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isUserAdmin={isUserAdmin}
                    tiebreakerCount={tiebreakerCount}
                />
            )}            
        </Box>
    );
};

export default BracketGamesUserDash;