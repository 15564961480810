import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import navbarLogo from "../../assets/images/navbar-logo.svg";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../redux/actions/loginImport";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useGetUserData } from "../BirdiePool/hooks/index";
import { useGetPrivatePools } from "./../PrivatePools/hooks/useGetPrivatePools"
import { useGetPublicPools } from "./../PublicPools/hooks/useGetPublicPools"
import { fetchGames } from '../../redux/actions/gameImport';
import UserDropDown from "../cornerDropDown/dropDownMenu";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  Drawer,
  List,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
// import './styles.scss';
import ThemeProvider from "../../contexts/ThemeProvider";
import MyPools from "./MyPools";
import BirdiePoolsDropdown from "./BirdiePoolsDropdown";
import { useNavigate } from "react-router-dom";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import MyPoolsDropdown from "./MyPoolsDropdown";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SubNav from "./subNav";


const activeStyle = {
  textDecoration: "none",
  fontWeight: "bold",
  background: "transparent",
  color: "#FFC60A", // Change as needed for active link
};

// Animated hamburger container in a 50x50px space.
const AnimatedHamburger = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }) => ({
  position: 'relative',
  width: '50px',
  // height: '50px',
  overflow: 'visible',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  transform: isActive ? 'rotate(45deg)' : 'none',
  '&:before': {
    content: '""',
    position: 'absolute',
    boxSizing: 'border-box',
    width: '50px',
    // height: '50px',
    border: '5px solid transparent',
    top: 0,
    left: 0,
    borderRadius: '50%',
    transition: 'all 0.3s ease-in-out',
    zIndex: 0,
  },
  ...(isActive && {
    '&:before': {
      border: '5px solid #ecf0f1',
    },
    '& .line': {
      width: '35px',
    },
    '& .line:nth-of-type(2)': {
      opacity: 0,
    },
    '& .line:nth-of-type(1)': {
      transform: 'translateY(9px)',
    },
    '& .line:nth-of-type(3)': {
      transform: 'translateY(-9px) rotate(90deg)',
    },
  }),
}));


const Line = styled('span')({
  display: 'block',
  width: '35px',
  height: '4px',
  backgroundColor: '#ecf0f1',
  margin: '5px auto',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  zIndex: 1,
});

const VisibleIconButton = styled(IconButton)({
  overflow: 'visible !important',
  padding: 0,
});
// Wrap the animated hamburger in an MUI IconButton
const CustomHamburgerButton = ({ isActive, toggle }) => {
  return (
    <VisibleIconButton
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={toggle}
      aria-label="open drawer"
      edge="end"
    >
      <AnimatedHamburger isActive={isActive}>
        <Line className="line" />
        <Line className="line" />
        <Line className="line" />
      </AnimatedHamburger>
    </VisibleIconButton>
  );
};

const NavLinkItem = (props) => {
  const { label, path } = props;
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path); // Navigate to the specified path
  };

  return (
    <NavLink
      style={({ isActive }) =>
        isActive && path
          ? activeStyle
          : {
              color: "var(--color-white)",
              textDecoration: "none",
              height: 38,
              alignItems: "center",
              display: "flex",
              background: "transparent",
            }
      }
      variant="text"
      to={path}
      onClick={handleLinkClick} // Attach the custom click handler
    >
      {label}
    </NavLink>
  );
};

const MobileNavItem = (props) => {
  const { handleClick, label, path } = props;

  const handleItemClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Call the original handleClick function if provided
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <ListItem
      sx={{ width: '100%', "&:hover, &:active": { color: "var(--color-yellow)" } }}
      onClick={handleItemClick}
    >
      <NavLinkItem label={label} path={path} />
    </ListItem>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const userDataId = getUserDataFromLocalStorage()?.user_id;
  const navigateToTop = useNavigateToTop();

  const [myPoolsOpen, setMyPoolsOpen] = useState(false);

  const [open, setOpen] = useState(false); // for hamburger animation


  const handleLogin = () => {
    if (!userDataId) {
    navigateToTop('/login');
    } else {
      setMyPoolsOpen(!myPoolsOpen);
      setIsDrawerOpen(false);
      handleCloseDrawer();
    }
  }

  const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  //console.log("USER DATA FROM HOOK", userData?.Games);
  const { data, error, isLoading, refetch } = useGetPrivatePools();
  const { data: publicPoolsData, publicIsLoading, publicIsError, publicError } = useGetPublicPools();
  //console.log("USER DATA PRIVATE GAMES", data)
  const [gamesData, setGamesData] =useState([]);
  // let matchingGames = [];
  useEffect(() => {
    if (userData && data && publicPoolsData) {
      const userGameIds = userData?.Games?.filter(game =>
        game.pool?.dontShowInMyPools === undefined || game.pool?.dontShowInMyPools === false
      )?.map(game => game.gameID) || [];

      // Combine both private and public pools data
      const allPools = [...data, ...publicPoolsData];
      // Declare matchingGames within the effect scope
      const matchingGames = allPools.filter(pool =>
        userGameIds?.includes(pool.gameID) && pool.dontShowInMyPools !== true
      ).reverse(); // Apply reverse directly to the result

      setGamesData(matchingGames);
    }
  }, [userData, data, publicPoolsData]);
  //console.log("USER DATA MATCHING OUTSIDE EFFECT", gamesData)
  const logout = () => {
    dispatch(userLogoutAction());
  };

  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



  const toggleDropDown = (e) => {
    setisDropDownOpen(!isDropDownOpen);
    e.stopPropagation();
  };

  useEffect(() => {
    if (userDataId) {
        fetchUserData(userDataId);
    }
}, [fetchUserData, userDataId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 900) {
        setIsDrawerOpen(false); // Close the drawer when resizing to desktop
      }
      if (window.innerWidth < 900) {
        setisDropDownOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update your toggleDrawer to update both:
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
    setIsDrawerOpen((prev) => !prev);
    setMyPoolsOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setOpen(false);
  };


  return (
    <ThemeProvider>
      <header>
        <AppBar
          component="nav"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            background: "#07356B",
            height: isDrawerOpen && windowWidth >= 900 ? "auto" : "80px",
          }}
        >
          <Toolbar
            style={{
              alignItems: "center",
              padding: windowWidth >= 900 ? "20px 50px" : "10px 20px",
              minHeight: "80px"
            }}
          >
            <Link to="/">
              <img src={navbarLogo} alt="" />
            </Link>
            {windowWidth >= 900 && (
              <Box
                style={{
                  marginLeft: "auto",
                  width: "100%",
                  alignItems: "center",
                  maxWidth: "650px",
                  display: "flex",
                  color: "var(--color-white)",
                  justifyContent: "right",
                  gap: 24,
                }}
              >
                {gamesData && (
                  <Button
                    onClick={() => setMyPoolsOpen(!myPoolsOpen)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#FFC60A',
                      color: "#002129",
                      padding: '8px',
                      fontSize: '16px',
                      fontWeight: 700,
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      marginRight: '5px',
                      borderRadius: '4px',
                      border: '1px solid #FFC60A',
                      '&:hover': {
                        background: '#FFC60A',
                        color: "#002129",
                      },
                    }}
                  >
                    <span>Pools</span>
                    <ArrowDropDownIcon
                      sx={{
                        fontSize: '24px',
                        display: 'block',
                        color: '#002129',
                        transform: myPoolsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease'
                      }}
                    />
                  </Button>
                )}
                {/* <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                  <NavLinkItem label="Pool Hall" path="/pool-hall" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                  <NavLinkItem label="Create Pool" path="/create-a-pool" />
                </div> */}
                {/* <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}> */}
                {/* <BirdiePoolsDropdown /> */}
                  {/* <NavLinkItem label="Merch" path="/merch" />
                </div> */}
                {/* <div style={{ whiteSpace: 'nowrap' }}>
                  <NavLinkItem label="Community Pools" path="/community-pools" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '5px'}}>
                  <NavLinkItem label="Contact Us" path="/contact" />
                </div> */}
                {/* <div style={{ whiteSpace: 'nowrap', marginRight: '5px'}}>
                  <NavLinkItem label="Partners" path="/Partners" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '5px'}}>
                  <NavLinkItem label="Contact Us" path="/Learn" />
                </div> */}
                {!userDataId ? (
                  <Link to="/login" style={{ whiteSpace: 'nowrap' }}>
                  <Button
                    sx={{
                      backgroundColor: "var(--color-yellow)",
                      color: "var(--primary-color)",
                      "&:hover": {
                        opacity: 0.95,
                        backgroundColor: "var(--color-yellow)",
                        color: "var(--primary-color)",
                      },
                    }}
                    variant="text"
                  >
                    Play Now
                  </Button>
                </Link>
                ) : (
                  <>
                    {userData?.Settings?.Profile?.profileImage ? (
                      <div
                        onClick={(e) => toggleDropDown(e)}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          cursor: "pointer",
                          backgroundImage: `URL(${userData?.Settings?.Profile?.profileImage})`,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: "var(--color-grey)",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          paddingTop: "3px",
                          boxSizing: "border-box",
                        }}
                      >
                        <PersonIcon
                          onClick={(e) => toggleDropDown(e)}
                          sx={{
                            fontSize: "1.8rem",
                            color: "var(--color-white)",
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
            {windowWidth < 900 && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', justifyContent: 'center' }}>
                  <Button
                    onClick={handleLogin}
                    sx={{
                      display: 'flex',
                      height: '28px',
                      padding: '8px',
                      flexDirection: 'row', // Ensure row direction
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 'var(--borderRadius, 4px)',
                      // border: '1px solid #EAEAEA',
                      background: '#FFC60A',
                      color: '#000',
                      fontSize: '12px',
                      fontWeight: 700,
                      marginRight: '16px',
                      gap: '4px', // This creates the space between elements in a more reliable way
                      '&:hover': {
                        background: '#FFC60A',
                        boxShadow: 'none',
                      },
                      '&:focus': {
                        background: '#FFC60A',
                        boxShadow: 'none',
                      },
                      '&:active': {
                        background: '#FFC60A',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    {!userDataId ? 'Play Now' : (
                      <>
                        <span>POOLS</span>
                        <ArrowDropDownIcon
                          sx={{
                            color: '#000',
                            fontSize: '16px',
                            display: 'block',
                            mt: '-1px',
                            transform: myPoolsOpen ? 'rotate(180deg)' : 'rotate(0deg)', // Flip icon when open
                            transition: 'transform 0.2s ease' // Smooth transition for rotation
                          }}
                        />
                      </>
                    )}
                  </Button>
                {/* <CustomIconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={toggleDrawer} // Ensure this is placed on the CustomIconButton itself
                >
                  <MenuIcon style={{ fontSize: "2.5rem" }} />
                </CustomIconButton> */}
                <CustomHamburgerButton isActive={open} toggle={toggleDrawer} />
              </Box>
            )}
            {windowWidth > 900 && (
              <UserDropDown
                isDropDownOpen={isDropDownOpen}
                toggleDropDown={toggleDropDown}
              />
            )}
            <Drawer
              variant="temporary"
              anchor="top"
              open={windowWidth <= 900 && isDrawerOpen}
              onClick={handleCloseDrawer}
              sx={{
                width: "100%",
                flexShrink: 0,
                zIndex: 1099,
              }}
            >
              <div style={{ height: "64px" }}></div>
              <List
                sx={{
                  display: "inline-grid",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  paddingTop: "25px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  // height: "100%", // Adjust height as needed
                  backgroundColor: "var(--primary-color)",
                  "& a": {
                    color: "white", // Default color for links
                    textDecoration: "none", // Remove underline from links
                  },
                  "& a:hover, & a:active": {
                    color: "var(--color-yellow)", // Color for hover and active states
                  },
                  zIndex: 1099,
                }}
              >
                {/* <MyPools games={gamesData} /> */}
                <MobileNavItem
                  path="/pool-hall"
                  label="Pool Hall"
                  handleClick={handleCloseDrawer}
                />
                {/* <MobileNavItem
                  path="/merch"
                  label="Merch"
                  handleClick={handleCloseDrawer}
                /> */}
                <MobileNavItem
                  path="/create-a-pool"
                  label="Create Pool"
                  handleClick={handleCloseDrawer}
                />
                <MobileNavItem
                  path="/community-pools"
                  label="Community Pools"
                  handleClick={handleCloseDrawer}
                />
                 <MobileNavItem
                  path="/leaderboards"
                  label="Leaderboards"
                  handleClick={handleCloseDrawer}
                />
                <MobileNavItem
                  path="/contact"
                  label="Contact Us"
                  handleClick={handleCloseDrawer}
                />
                {userDataId && <MobileNavItem
                  path="/profile/general"
                  label="My Profile"
                  handleClick={handleCloseDrawer}
                />}
                {userDataId? (
                  <MobileNavItem
                    path={null}
                    label="Sign out"
                    handleClick={(e) => {
                      handleCloseDrawer();
                      logout();
                    }}
                  />
                ) : (
                  <MobileNavItem
                    path="/login"
                    label="Login / Sign Up"
                    handleClick={handleCloseDrawer}
                  />
                )}
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
        <UserDropDown
          isDropDownOpen={isDropDownOpen}
          toggleDropDown={toggleDropDown}
        />

      </header>
      <SubNav />
      {myPoolsOpen && (
      <MyPoolsDropdown
        userData={userData}
        setMyPoolsOpen={setMyPoolsOpen}
      />
    )}
    </ThemeProvider>
  );
};

export default Header;
