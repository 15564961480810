import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../../config";

export const useJoinPrivatePool = () => {
  return useMutation({
    mutationFn: joinPrivatePoolRequest,
    onError: (error) => {
      console.error('Error creating subgroup:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Subgroup created successfully:', data);
    // }
  });
}

const joinPrivatePoolRequest = async (poolData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
  console.log(poolData);

  //TODO: Update the endpoint once we have adjusted for agnostic game joining
  const response = await axios.post(`${apiUrl}/games/join-golf-club`, poolData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};