import React, { useCallback } from 'react';
import { useUserAndGameContext } from '../../contexts/UserAndGameContext';
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './GameHeaderTemplate.scss';

const GameHeaderTemplate = () => {
    const {
        masterPoolId,
        page,
        gameId,
        pickIndex,
        gameAddress,
        gameData,
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
    } = useUserAndGameContext();

    const headerInfo = gameData?.headerInfo;
    const {
        headerBackgroundColor,
        headerTextColor,
        headerText,
        headerBottomBorderColor,
        sponsorHeaderInfo
    } = headerInfo || {};

    const {
        logos = [],
        backgroundColor,
        textColor,
        linkText,
        mobileFontSize,
        desktopFontSize,
        hideLogo,
    } = sponsorHeaderInfo ?? {};
    //console.log("Mobile Font Size: ", mobileFontSize, "Desktop Font Size: ", desktopFontSize, "Sponsor Header Info: ", sponsorHeaderInfo);
    const navigateToTop = useNavigateToTop();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const gameName = gameData?.gameName;
    //console.log("header background color", headerBackgroundColor);


    const handleClick = useCallback((clickType) => {
        const queryParams = new URLSearchParams();
        queryParams.set('mId', masterPoolId);
        queryParams.set('gId', gameId);
        if (pickIndex) {
            queryParams.set('pickIndex', pickIndex);
        }

        let newUrl;
        if (clickType === "link") {
            queryParams.set('page', 'sponsor');
            newUrl = `/${gameAddress}?${queryParams.toString()}`;
        } else if (page === 'home') {
            newUrl = '/pool-hall';
        } else {
            queryParams.set('page', 'home');
            newUrl = `/${gameAddress}?${queryParams.toString()}`;
        }

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(newUrl);
            setUnsavedPicksModalOpen(true);
            return;
        }

        navigateToTop(newUrl);
    }, [ unsavedPicks, unsavedBracketPicks, page, gameAddress, masterPoolId, gameId, pickIndex, navigateToTop, setUrlForNav, setUnsavedPicksModalOpen ]);


    return (
        <>
            <Box
                className="game-header-header-container"
                sx={{ backgroundColor: headerBackgroundColor || '#07356B' }}
            >
                <Box className="game-header-header-content">
                    <Box
                        className="game-header-back-button"
                        onClick={() => handleClick()}
                        sx={{
                            '&:hover': {
                                '& .MuiTypography-root, & .MuiSvgIcon-root': {
                                    color: headerBottomBorderColor || '#FFC60A'
                                }
                            }
                        }}
                    >
                        <ArrowBackIosNewIcon
                            className="game-header-back-icon"
                            sx={{ color: headerBottomBorderColor || 'white' }}
                        />
                        <Typography
                            variant="h1"
                            className="game-header-back-text"
                            sx={{ color: headerTextColor || 'white' }}
                        >
                            {page === "home" ? "Pool Hall" : "Home"}
                        </Typography>
                    </Box>
                    <Typography
                        className={`game-header-header-title${headerText?.length >= 30 || (!headerText && gameName?.length >= 30) ? " game-header-header-title-long-name" : ""}`}
                        sx={{ color: headerTextColor || 'white' }}
                    >
                        {headerText ? headerText : gameName}
                    </Typography>

                </Box>
                <Box
                    className="game-header-header-border"
                    sx={{ backgroundColor: headerBottomBorderColor || '#FFC60A' }}
                />
            </Box>
            {sponsorHeaderInfo && logos?.length > 0 && !hideLogo?.includes(page) && (
                <Box
                    className="game-header-sponsor-section"
                    sx={{ backgroundColor: backgroundColor || '#fff' }}
                >
                    <Box className="game-header-sponsor-logos">
                        {logos?.map((logo, index) => (
                            <Box
                                key={index}
                                component="img"
                                src={logo.logo}
                                alt={logo.altText}
                                className="game-header-sponsor-logo"
                                sx={{
                                    width: isMobile ? (logo?.mobileWidth || '100%') : (logo?.desktopWidth || '100%'),
                                    maxWidth: logo?.length === 1 ? '50%' : `${(100 / logo?.length) * 0.8}%`
                                }}
                            />
                        ))}
                    </Box>

                    <Box
                        className="game-header-sponsor-link"
                        sx={{ color: textColor || '#00AAD1', marginTop: '8px' }}
                        onClick={() => handleClick("link")}
                    >
                        {linkText}
                    </Box>
                </Box>
            )}



        </>
    );
};

export default GameHeaderTemplate;