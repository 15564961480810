import React from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const NoPickBox = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
            width: isMobile ? '310px' : '375px',
            height: isMobile ? '102px' : '130px',
            flexShrink: 0,
            borderRadius: '8px', // Fallback value for border-radius
            border: '2px solid #CC293C',
            backgroundColor: '#FFF',
            display: 'flex',
            alignItems: 'center',
            margin: '0 auto', // Center the box horizontally
            justifyContent: 'space-between', // Ensure space between text and icon
            padding: '0 16px', // Padding to add space around content inside the box
            boxSizing: 'border-box', // Include padding in width calculation
            marginBottom: '16px',
            }}
        >
            <Typography
                sx={{
                    color: '#CC293C',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: isMobile ? '48px' : '64px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                }}
            >
                NO PICK
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Center the icon within the container
                    width: isMobile ? '48px' : '64px',
                    height: isMobile ? '48px' : '64px',
                    border: '3px solid #CC293C',
                    flexShrink: 0,
                }}
            >
                <CloseIcon sx={{ width: isMobile ? '32px' : '48px', height: isMobile ? '32px' : '48px', color: '#CC293C' }} />
            </Box>
        </Box>
    );
}

export default NoPickBox;