import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Button, Modal } from '@mui/material';
import HostNoteComponent from '../../CreateAPool/CreationComponents/HostNoteComponent';
import { useUpdateHostNotes } from '../../../hooks/commissioner/useUpdateHostNotes';
import ResponseModal from '../../GameModals/ResponseModal/ResponseModal';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';


const HostNote = ({ hostNote, amICreator, myUserId, gameId }) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [newHostNote, setNewHostNote] = useState(hostNote);
    const [hostNoteError, setHostNoteError] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const contentRef = useRef(null);
    const hostNoteRef = useRef(null);
    
    // Response modal states
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseStatus, setResponseStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const { mutate: updateHostNotes } = useUpdateHostNotes();

    const dispatch = useDispatch();
    
    useEffect(() => {
        // Check if content overflows the container
        if (contentRef.current) {
            setIsOverflowing(contentRef.current.scrollHeight > 100);
        }
    }, [hostNote]);

    useEffect(() => {
        // Update the newHostNote state when hostNote prop changes
        setNewHostNote(hostNote);
    }, [hostNote]);

    const handleToggleExpand = () => {
        if (expanded) {
            // If we're collapsing, first collapse then scroll to component
            setExpanded(false);
            
            // Use setTimeout to allow state update to complete before scrolling
            setTimeout(() => {
                if (hostNoteRef.current) {
                    hostNoteRef.current.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 10);
        } else {
            // Just expand
            setExpanded(true);
        }
    };

    const handleEditClick = () => {
        setEditModalOpen(true);
    };

    const handleSaveEdit = async () => {
        setHasSubmitted(true);
    
        const hostNoteToSave = newHostNote || '';
    
        // Prepare the data for the API call
        const hostNotesData = {
            notes: hostNoteToSave,
            gameId: gameId,
            commissionerId: myUserId,
        };
    
        //console.log("Host Notes Data: ", hostNotesData);
    
        try {
            // Call the API to update the host notes
            await updateHostNotes(hostNotesData, {
                onSuccess: (data) => {
                    //console.log("Host notes updated successfully:", data);
                    setResponseStatus('success');
                    setResponseMessage('Host note updated successfully');
                    setResponseModalOpen(true);
                },
                onError: (error) => {
                    console.error("Error updating host notes:", error);
                    
                    // Check for token expiration (401 Unauthorized)
                    if (error?.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                        return;
                    }
                    
                    // Handle other errors
                    setResponseStatus('error');
                    setResponseMessage('Error updating host note');
                    setResponseModalOpen(true);
                }
            });
        } catch (error) {
            console.error("Unexpected error in handleSaveEdit:", error);
            
            // Check for token expiration (401 Unauthorized)
            if (error?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                return;
            }
            
            // Handle other errors
            setResponseStatus('error');
            setResponseMessage('Unexpected error updating host note');
            setResponseModalOpen(true);
        }
    };
    

    // Function to handle the response modal close
    const handleResponseModalClose = () => {
        setResponseModalOpen(false);
    };

    // Function to render text with line breaks
    const renderTextWithLineBreaks = (text) => {
        if (!text) return null;
        
        // Split the text by newline characters and map to paragraphs
        return text.split('\n').map((line, index) => (
            <Typography
                key={index}
                sx={{
                    fontSize: '16px',
                    color: '#333',
                    textAlign: 'left',
                    marginBottom: index < text.split('\n').length - 1 ? '8px' : 0,
                }}
            >
                {line || ' '} {/* Use space if line is empty to maintain height */}
            </Typography>
        ));
    };

    return (
        <>
            <Box
                ref={hostNoteRef}
                sx={{
                    marginBottom: '32px',
                    padding: '0px 16px',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        marginTop: '20px',
                        marginBottom: '16px',
                        maxWidth: '600px',
                        margin: '20px auto 16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center',
                        }}
                    >
                        HOST NOTE
                    </Typography>
                    
                    {amICreator && (
                        <Button
                            onClick={handleEditClick}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                transform: 'translateY(-50%)',
                                padding: '4px 12px',
                                minWidth: 'auto',
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#0066CC',
                                backgroundColor: 'rgba(0, 102, 204, 0.08)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 102, 204, 0.08)',
                                },
                            }}
                        >
                            Edit
                        </Button>
                    )}
                </Box>
                
                <Box 
                    sx={{
                        padding: '16px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '4px',
                        backgroundColor: '#f5f5f5',
                        maxWidth: '600px',
                        margin: '0 auto',
                        textAlign: 'left',
                    }}
                >
                    <Box
                        ref={contentRef}
                        sx={{
                            height: expanded ? 'auto' : '100px',
                            overflow: expanded ? 'visible' : 'hidden',
                            position: 'relative',
                        }}
                    >
                        {renderTextWithLineBreaks(hostNote)}
                    </Box>
                    
                    {isOverflowing && (
                        <Button
                            onClick={handleToggleExpand}
                            sx={{
                                marginTop: '8px',
                                padding: '0',
                                textTransform: 'none',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                color: '#0066CC',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {expanded ? 'Read Less' : 'Read More'}
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Edit Modal */}
            {editModalOpen && (
                <Modal
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    aria-labelledby="edit-host-note"
                    aria-describedby="edit-host-note"
                    BackdropProps={{
                        onClick: (e) => e.stopPropagation(),
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            maxWidth: '600px',
                            backgroundColor: '#fff',
                            border: '2px solid #0066CC',
                            borderRadius: '8px',
                            padding: '16px',
                            textAlign: 'center',
                        }}
                    >
                        <HostNoteComponent
                            hostNote={newHostNote}
                            setHostNote={setNewHostNote}
                            hostNoteError={hostNoteError}
                            setHostNoteError={setHostNoteError}
                            editing={true}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '32px',
                            }}
                        >
                            <Button
                                variant='contained'
                                onClick={() => setEditModalOpen(false)}
                                disabled={hasSubmitted}
                            >
                                CANCEL
                            </Button>
                            
                            <Button
                                variant='contained'
                                onClick={handleSaveEdit}
                                disabled={hasSubmitted}
                            >
                                SAVE
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}

            {/* Response Modal */}
            <ResponseModal 
                open={responseModalOpen}
                onClose={handleResponseModalClose}
                status={responseStatus}
                message={responseMessage}
            />
        </>
    );
};

export default HostNote;