import React from 'react';
import { CommunityHubProvider } from '../components/CommunityHub/context/CommunityHubContext';
import CommunityHubContainer from '../components/CommunityHub/CommunityHubContainer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const CommunityHubPage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <CommunityHubProvider>
                    <CommunityHubContainer />
                </CommunityHubProvider>
            </QueryClientProvider>
        </div>
    );
};

export default CommunityHubPage;