import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './BirdieHeaderNav.scss';
import bracketTrophy from '../../../assets/images/bracketTrophy.png';
import { useBirdieContext } from '../context/BirdiePoolContext';

const BirdieHeader = (props) => {
  const { clubLeaderboardInfo, myUserName, myUserId } = useBirdieContext();
  const data = clubLeaderboardInfo?.leaderboardInfo;
  //console.log("DATA1", data, myUserName);

  const usersEntry = data?.find(entry => entry.userName === myUserName && entry.userId === myUserId);
  //console.log("USERS ENTRY", usersEntry);

  const getDuplicateOverallRanks = () => {
    const countMap = {};
    data?.forEach(entry => {
      const rank = entry?.rank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    return Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));
  };

  const duplicateOverallRanks = getDuplicateOverallRanks();
  const isUserInDuplicateRanks = (rank) => {
    return duplicateOverallRanks.includes(rank);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Fallback values for the user's entry
  const userRank = usersEntry?.rank ?? '-';
  const userHolesBirdied = usersEntry?.holesBirdied ?? '-';
  const userBirdieCount = usersEntry?.birdieCount ?? '-';

  // Check if data is still loading
  const isLoading = !data || !usersEntry;

  return (
    <Box className={"bracket-box-header"} style={{ margin: '0 auto'}}>
      <Box className={"bracket-box-header-trophy"}>
        <img className="bracket-box-trophy" src={bracketTrophy} alt='' />
        <Typography className={"bracket-box-header-username"} variant='h1'>{myUserName}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        maxWidth: '628px',
        marginTop: '20px',
        justifyContent: "space-evenly",
        alignItems: 'stretch',
        // maxWidth: '1102px',
        margin: '0 auto',
        width:'90%'
      }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Typography variant='h4'>Loading...</Typography>
          </Box>
        ) : (
          isMobile ? (
            // These items will be displayed on mobile devices
            <>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>RANK</Typography>
                <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{isUserInDuplicateRanks(userRank) ? 'T' : ''}{userRank}</Typography>
              </Box>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>HOLES BIRDIED</Typography>
                <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{userHolesBirdied}/18</Typography>
              </Box>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>TOTAL BIRDIES</Typography>
                <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{userBirdieCount}</Typography>
              </Box>
            </>
          ) : (
            // These items will be displayed on desktop devices
            <>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>RANK</Typography>
                <Typography className={"bracket-box-header-values"} variant='h4'>{isUserInDuplicateRanks(userRank) ? 'T' : ''}{userRank}</Typography>
              </Box>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>HOLES BIRDIED</Typography>
                <Typography className={"bracket-box-header-values"} variant='h4'>{userHolesBirdied}/18</Typography>
              </Box>
              <Box>
                <Typography className={"bracket-box-header-headings"} variant='h4'>TOTAL BIRDIES</Typography>
                <Typography className={"bracket-box-header-values"} variant='h4'>{userBirdieCount}</Typography>
              </Box>
            </>
          )
        )}
      </Box>
    </Box>
  );
}

export default BirdieHeader;
