import React from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TeamDisplayCell from './TeamDisplayCell';
import PercentagePillBox from '../../GamePageComponents/PercentagePillBox/PercentagePillBox';

const SurvivorSingleIntervalTable = ({ teamStats, teams, league, schedule }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    // Convert object to array if needed
    const teamStatsArray = Object.values(teamStats || {});
    
    // Sort the array by timesSelected in descending order (most picks at the top)
    const sortedTeamStatsArray = [...teamStatsArray]?.sort((a, b) => 
        (b.timesSelected || 0) - (a.timesSelected || 0)
    );
    
    // Calculate total picks for percentage calculation
    const totalPicks = sortedTeamStatsArray?.reduce((sum, stat) => sum + (stat.timesSelected || 0), 0) || 1;

    return (
        <Box>
            <TableContainer component={Paper} style={{ overflowX: "clip", maxWidth:'930px', margin:'0 auto', marginBottom: '30px', borderRadius: '0' }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129'}}>
                        <TableRow>
                            <TableCell
                                sx={{ width: isMobile ? '176px' : '410px', color: '#fff', fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'}}
                            >
                                TEAM
                            </TableCell>
                            {!isMobile && (
                                <TableCell
                                    sx={{ width: '254px', color: '#fff', fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'} }
                                >
                                    TIMES PICKED
                                </TableCell>
                            )}
                            <TableCell
                                sx={{ width: isMobile ? '199px' : '377px', color: '#fff', fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'} }
                            >
                                {isMobile ? 'SELECTED' : 'PERCENTAGE'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!teamStats || Object.keys(teamStats).length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={isMobile ? 2 : 3} style={{ textAlign: 'center', padding: '20px', color: '#002129' }}>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '16px' : '20px',
                                            fontWeight: 700,
                                            marginBottom: '20px'
                                        }}
                                    >
                                        {isMobile
                                            ? (
                                                <>
                                                    Pick Stats will be available once the <br />
                                                    pick deadline has passed.
                                                </>
                                            )
                                            : 'Pick Stats will be available once the pick deadline has passed.'
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedTeamStatsArray?.map((teamStat, index) => {
                                const result = teamStat?.result;
                                const highestResult = result 
                                    ? Object.entries(result).reduce((a, b) => (b[1] > a[1] ? b : a))[0] 
                                    : null;

                                return (
                                    <TableRow key={index}>
                                        <TeamDisplayCell 
                                            team={teamStat}
                                            highestResult={highestResult} 
                                            isMobile={isMobile} 
                                            teams={teams} 
                                            league={league} 
                                            schedule={schedule} 
                                        />
                                        {!isMobile && (
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {teamStat.timesSelected || 0}
                                            </TableCell>
                                        )}
                                        {!isMobile && (
                                            <TableCell>
                                                <PercentagePillBox
                                                    percentage={teamStat.timesSelected/totalPicks * 100}
                                                    result={highestResult}
                                                />
                                            </TableCell>
                                        )}
                                        {isMobile && (
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '100%', // Ensures full height of the TableCell is used
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: '700',
                                                            color: highestResult === "loss" ? '#CC293C' : highestResult === "win" ? '#00AA72' : '#002129',
                                                        }}
                                                    >
                                                        {teamStat.timesSelected}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            mt: 1 // Margin top for spacing
                                                        }}
                                                    >
                                                        <PercentagePillBox
                                                            percentage={teamStat.timesSelected/totalPicks * 100}
                                                            result={highestResult}
                                                        />
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SurvivorSingleIntervalTable;