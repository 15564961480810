import React from "react";
import Box from '@mui/material/Box';
import '../NFLSurvivorStyles/SurvivorNav.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SurvivorNav = (props) => {
    const { clickState, setClickState, setSignUpState, doesUserBelong, isSurvivorOver, userSubgroups, sponsorAbbreviation } = props;

    //console.log("Is Survivor Over: ", isSurvivorOver, "User Subgroups Length: ", userSubgroups?.length);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box className={"bracket-nav"} style={{ margin: '0 auto'}}>
            <Box className={"bracket-nav-container"} style={{ width: '90%', maxWidth: doesUserBelong ? '960px' : isMobile ? '320px' : '575px' }}>
                {doesUserBelong && (
                <Box
                    className={`bracket-nav-item ${clickState === 'MY_PICKS' ? 'active' : ''}`}
                    onClick={() => { setClickState('MY_PICKS'); setSignUpState(false); }}
                    style={{ cursor: 'pointer', color: clickState === 'MY_PICKS' ? '#FFC60A' : 'white' }}
                >
                    My Picks
                </Box>
                )}
                <Box
                    className={`bracket-nav-item ${clickState === 'LEADERBOARD' ? 'active' : ''}`}
                    onClick={() => { setClickState('LEADERBOARD'); setSignUpState(false); }}
                    style={{ cursor: 'pointer', color: clickState === 'LEADERBOARD' ? '#FFC60A' : 'white' }}
                >
                    Leaderboard
                </Box>
                <Box
                    className={`bracket-nav-item ${clickState === 'PICK_STATS' ? 'active' : ''}`}
                    onClick={() => { setClickState('PICK_STATS'); setSignUpState(false); }}
                    style={{ cursor: 'pointer', color: clickState === 'PICK_STATS' ? '#FFC60A' : 'white' }}
                >
                    Pick Stats
                </Box>
                {doesUserBelong && (!isSurvivorOver || (isSurvivorOver && userSubgroups?.length !== 0)) && (
                <Box
                    className={`bracket-nav-item ${clickState === 'MY_PARTY' ? 'active' : ''}`}
                    onClick={() => { setClickState('MY_PARTY'); setSignUpState(false); }}
                    style={{ cursor: 'pointer', color: clickState === 'MY_PARTY' ? '#FFC60A' : 'white' }}
                >
                    My Party
                </Box>
                )}
                {sponsorAbbreviation && (
                <Box
                    className={`bracket-nav-item ${clickState === 'PRESENTED_BY' ? 'active' : ''}`}
                    onClick={() => { setClickState('PRESENTED_BY'); setSignUpState(false); }}
                    style={{ cursor: 'pointer', color: clickState === 'PRESENTED_BY' ? '#FFC60A' : 'white' }}
                >
                    {sponsorAbbreviation}
                </Box>
                )}
            </Box>
        </Box>
    );
}

export default SurvivorNav;
