import React from 'react';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { userSigninAction, userLogoutAction,getUserDataFromLocalStorage } from '../../redux/actions/loginImport';
import { Link } from 'react-router-dom';
// import './styles.scss';

const GameLists = (props) => {

    const {games} = props;

    return (
        <Link className="game-links" to={`/game/${games.poolType}/${games._id}`}>
            <div>
                <p>{games.gameName}</p>
                <p>{games.poolType}</p>
            </div>
        </Link>
    );
}

export default GameLists;