import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "../../../config";

// useGetPrivatePools.js
export const useGetPrivatePools = () => {
  return useQuery({
    queryKey: ['privatePools'],
    queryFn: fetchPrivatePools,
    onError: (error) => console.error('Error fetching private pools:', error),
    //onSuccess: (data) => console.log('Fetched private pools data:', data)
  });
};

// fetchPrivatePools.js
const fetchPrivatePools = async () => {
  const response = await axios.get(`${apiUrl}/games/get-private-pools`);
  return response?.data;
};
