import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import './CardPieces.scss';
import TwoChoiceCard from "./TwoChoiceCard";
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SingleInput from "./SingleInput";
import MultipleChoiceCard from "./MultipleChoiceCard";


const QuickPickCard = ({ userPicksFromDB, pickState, setPickState, prop, interval, selectedGameId, hasGameStarted, isGameFinal, displayIndex }) => {
    //console.log("INTERVAL: ", interval);
    
    //console.log("Prop: ", prop);
    // Props - in real usage, these should be dynamic
    const week = 6;
    const id = prop?.id;
    const globalGameId = selectedGameId;
    const propChoice = prop?.propChoice;
    const propText = prop?.propText;
    const options = prop?.options;
    const optionOne = prop?.options[0];
    const optionTwo = prop?.options[1];
    const line = prop?.line;
    const actualTotal = prop?.actualTotal;
    const correctOption = prop?.correctOption;
    const isTiebreaker = prop?.tiebreaker;
    const points = prop?.points;
    //console.log("POINTS: ", points, "Actual Total: ", actualTotal);
   


    const [isPropSelected, setIsPropSelected] = useState(false);
    const [propInfo, setPropInfo] = useState({});
    const [isCurrentSelectionSaved, setIsCurrentSelectionSaved] = useState(null);

    useEffect(() => {
        const isPropSelected = pickState?.find(pick => pick.gameId === globalGameId && pick.id === id);
        //console.log("Inside Use Effect", isPropSelected)
        setIsPropSelected(!!isPropSelected);
        setPropInfo(isPropSelected);
    }, [pickState]);
    //console.log("Is prop selected", isPropSelected, "PropInfo", propInfo);

    const savedProp = userPicksFromDB?.find(pick => pick.gameId === globalGameId && pick.id === id);
    //console.log("PropSaved", savedProp)

    // New useEffect to determine if the current selection is saved
    useEffect(() => {
        if (savedProp && propInfo) {
            const isSaved = savedProp.option === propInfo.option; // Compare only the 'option' key
            setIsCurrentSelectionSaved(isSaved);
            //console.log("Is Current selection saved:", isSaved);
        } else {
            setIsCurrentSelectionSaved(false); // or set to null depending on your logic
        }
    }, [savedProp, propInfo]);
    
    //console.log("Is Current selection saved", isCurrentSelectionSaved);

    const result = savedProp?.result || "Pending";
    const margin = savedProp?.margin;
    //console.log("Margin: ", margin);

    const pickStatusText = !isPropSelected ? 'No Pick' :
    (isPropSelected && !isCurrentSelectionSaved) ? 'Pending (Not Saved)' :
    (isPropSelected && hasGameStarted && result === 'Pending') ? 'Locked' :
    result;
    //console.log("PICK STATUS", pickStatusText);

    const pickStatusColor = pickStatusText === 'No Pick' ? '#CC293C' :
    pickStatusText?.startsWith('Pending') ? '#00AAD1' :
    pickStatusText === 'Locked' ? '#002129' :
    pickStatusText === 'Win' ? '#00AA72' : '#CC293C';

    const unlockedLock = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    style={{ color: pickStatusColor, marginLeft: '4px' }}
    >
    <g clipPath="url(#clip0_936_9440)">
        <path d="M4.03846 0C2.76346 0 1.66038 0.529038 0.973269 1.38808C0.287308 2.24769 0 3.36115 0 4.56115V6.34615H1.73077V4.56115C1.73077 3.64962 1.95519 2.93423 2.32558 2.46981C2.69654 2.00596 3.19673 1.73077 4.03846 1.73077C4.8825 1.73077 5.38154 1.99038 5.75135 2.45192C6.12058 2.91346 6.34615 3.64038 6.34615 4.56115V5.19231H8.07692V4.56115C8.07692 3.35423 7.77288 2.22808 7.08519 1.37019C6.39808 0.512308 5.31115 0 4.03846 0ZM5.19231 5.76923C4.23692 5.76923 3.46154 6.54462 3.46154 7.5V13.2692C3.46154 14.2246 4.23692 15 5.19231 15H13.2692C14.2246 15 15 14.2246 15 13.2692V7.5C15 6.54462 14.2246 5.76923 13.2692 5.76923H5.19231ZM9.23077 8.65385C9.53679 8.65385 9.83027 8.77541 10.0467 8.9918C10.263 9.20819 10.3846 9.50167 10.3846 9.80769C10.3846 10.2335 10.1515 10.5992 9.80769 10.7994V12.1154C9.80769 12.4333 9.54865 12.6923 9.23077 12.6923C8.91288 12.6923 8.65385 12.4333 8.65385 12.1154V10.7994C8.31 10.5992 8.07692 10.234 8.07692 9.80769C8.07692 9.50167 8.19849 9.20819 8.41488 8.9918C8.63126 8.77541 8.92475 8.65385 9.23077 8.65385Z" fill={pickStatusColor} />
    </g>
    <defs>
        <clipPath id="clip0_936_9440">
        <rect width="15" height="15" fill="white" />
        </clipPath>
    </defs>
    </svg>;

    const icon = (pickStatusText === 'No Pick' && !hasGameStarted) ? '' :
    (pickStatusText === 'No Pick' && hasGameStarted) ? <LockIcon sx={{ color: '#CC293C' }} /> :
    pickStatusText.startsWith('Pending') ? unlockedLock :
    pickStatusText === 'Locked' ? <LockIcon sx={{ color: pickStatusColor }} /> :
    pickStatusText === 'Win' ? <CheckIcon sx={{ color: pickStatusColor }} /> : <CloseIcon sx={{ color: pickStatusColor }} />;

    const topBorderColors = {
        notSelectedNotStartedTextColor: "#002129",
        selectedTextColor: "#fff",
        notSelectedStartedTextColor: "#9D9D9D",
        notSelectedBackground: "#C0C0C0",
        selectedUnlockedBackground: "#00AAD1",
        selectedLockedBackground: "#002129",
        selectedWinBackground: "#00AA72",
        selectedLossBackground: "#CC293C", 
        voidedBackground: "#000000"
    }

    const getTopBorderColors = () => {
        let topBorderStyles ={};
        if (correctOption === "Void") {
            topBorderStyles = {
                backgroundColor: topBorderColors.voidedBackground,
                textColor: topBorderColors.selectedTextColor
            }
         } else if (!isPropSelected) {
            if (!hasGameStarted) {
                topBorderStyles = {
                    backgroundColor: topBorderColors.notSelectedBackground,
                    textColor: topBorderColors.notSelectedNotStartedTextColor
                }
            } else {
                topBorderStyles = {
                    backgroundColor: topBorderColors.selectedLossBackground,
                    textColor: topBorderColors.selectedTextColor
                }
            }
        } else if (!hasGameStarted) {
            topBorderStyles = {
                backgroundColor: topBorderColors.selectedUnlockedBackground,
                textColor: topBorderColors.selectedTextColor
            }
        } else if (result === "Pending") {
            topBorderStyles = {
                backgroundColor: topBorderColors.selectedLockedBackground,
                textColor: topBorderColors.selectedTextColor
            }
        } else if (result === "Win") {
            topBorderStyles = {
                backgroundColor: topBorderColors.selectedWinBackground,
                textColor: topBorderColors.selectedTextColor
            }
        } else if (result === "Loss") {
            topBorderStyles = {
                backgroundColor: topBorderColors.selectedLossBackground,
                textColor: topBorderColors.selectedTextColor
            }
        }

        return { topBorderStyles };
    }

    const { topBorderStyles } = getTopBorderColors();
    
    return (
        <Box className="quick-pick-card-container">
            <Box 
                className="quick-pick-card-top-border"
                sx={{ backgroundColor: topBorderStyles.backgroundColor }}    
            >
                {!isTiebreaker &&
                    <>
                        <Typography 
                            className="quick-pick-card-top-border-left-text"
                            sx={{ color: topBorderStyles.textColor }}    
                        >
                            Prop #{displayIndex}
                        </Typography>
                        <Typography 
                            className="quick-pick-card-top-border-right-text"
                            sx={{ color: topBorderStyles.textColor }}    
                        >
                            {correctOption === 'Void' ? 'VOID' : `${points} Point${points > 1 ? 's' : ''}`}
                        </Typography>
                    </>
                }
                {isTiebreaker &&
                    <Typography 
                        className="quick-pick-card-top-border-text"
                        sx={{ color: topBorderStyles.textColor }}    
                    >
                        Tiebreaker Prop (Type In)
                    </Typography>
                }
            </Box>
            <Box className="quick-pick-card-info">
                <Typography className="quick-pick-card-info-text">
                    {propText}
                </Typography>
            </Box>
            {options?.length === 2 &&
                <TwoChoiceCard 
                    pickState={pickState}
                    setPickState={setPickState}
                    interval={interval}
                    hasGameStarted={hasGameStarted}
                    isGameFinal={isGameFinal}
                    prop={prop}
                    selectedGameId={selectedGameId}
                    isPropSelected={isPropSelected}
                    result={result}
                />
            }
            {options?.length > 2 &&
                <MultipleChoiceCard 
                    pickState={pickState}
                    setPickState={setPickState}
                    interval={interval}
                    hasGameStarted={hasGameStarted}
                    isGameFinal={isGameFinal}
                    prop={prop}
                    selectedGameId={selectedGameId}
                    isPropSelected={isPropSelected}
                    result={result}
                />
            }
            {options?.length === 1 &&
                <SingleInput
                    pickState={pickState}
                    setPickState={setPickState}
                    interval={interval}
                    hasGameStarted={hasGameStarted}
                    isGameFinal={isGameFinal}
                    prop={prop}
                    selectedGameId={selectedGameId}
                    isPropSelected={isPropSelected}
                    result={result}
                />
            }
            {correctOption &&
                <Box className="quick-pick-card-result-container">
                    <Typography className="quick-pick-card-result-text">
                        Result: {actualTotal ? actualTotal : correctOption}
                    </Typography>
                </Box>
            }
            <Box className="quick-pick-card-bottom-border">
                <Typography className="quick-pick-card-bottom-border-text">
                    {correctOption === 'Void' ? 'This prop is VOID.' : margin ? 'My Margin:' : 'My Pick:'} 
                </Typography>
                <Typography className="quick-pick-card-bottom-border-text" sx={{ color: pickStatusColor }}>
                    {correctOption === 'Void' ? '' : margin ? margin : pickStatusText}
                </Typography>
                <Box>
                    {(correctOption === 'Void' || margin) ? '' : icon}
                </Box>
            </Box>
        </Box>
    )
}

export default QuickPickCard;