import React, { useState, useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import PickemTableMobile from '../../assets/images/PickemTableMobile.png';
import PickemTableDesktop from '../../assets/images/PickemTableDesktop.png';
import { getUserDataFromLocalStorage } from '../../redux/actions/loginImport';

const PicksByDate = (props) => {
  const LeaderboardInfo = props?.leaderboardInfo?.data;
  const NBATeams = props?.NBATeams;
  const NBAGamesByDate = props?.nbaGamesByDate;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userData = getUserDataFromLocalStorage();
  //console.log("LeadboardInfo", LeaderboardInfo);

  function findUsersPickEmPoints(user) {
    if (!user) {
        return 0;
    }
    const userEntry = LeaderboardInfo?.results?.find(entry => entry.user_name === user);
    return userEntry?.stats?.onlyDailyPoints || 0;
    }

  function getUniqueDatesFromRedis() {
      if (!Array.isArray(NBAGamesByDate)) {
          return [];
      }
      // Use a Set to store unique dates
      const uniqueDates = new Set();
      // Get today's date
      let currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 4); // EST is UTC-4
      const today =  currentDate.toISOString().split('T')[0]; // Extract only the date part
      //console.log("Today's Date:", today)
      // Iterate over each object in NBAGamesByDate
      NBAGamesByDate?.forEach((game) => {
          // Check if the game object has the 'Day' property
          if (game && game.Day && game.Status !== "NotNecessary") {
              const gameDate = new Date(game.Day).toISOString().split('T')[0]; // Extract only the date part
              // Check if the game date is today or in the past
              if (gameDate <= today) {
                  uniqueDates.add(gameDate);
              }
          }
      });
              // Convert the Set back to an array and sort it in descending order

      const sortedDates = [...uniqueDates].sort((a, b) => new Date(b) - new Date(a));

      return sortedDates
  }

  const uniqueDates = getUniqueDatesFromRedis();
  //console.log("Unique Dates:", uniqueDates);

  //console.log("Before Sort", LeaderboardInfo);
  const leaderboardUserData = LeaderboardInfo?.results;
  //console.log("User Data Before Sort:", leaderboardUserData);

  function groupTotalWins(leaderboardUserData) {
    let totalWins = 0;
    let totalLosses = 0;
    let totalPushes = 0;
  
    leaderboardUserData?.forEach((info) => {
      info?.dailyPicks?.forEach((picks) => {
          if (picks.Result === "Loss") {
            totalLosses += 1;
          } else if (picks.Result === "Win") {
            totalWins += 1;
          }
          else if (picks.Result === "Push") {
            totalPushes += 1;
          }
        });
    });
  
    //console.log("Total Wins:", totalWins);
    //console.log("Total Losses:", totalLosses);
    //console.log("Total Pushes:", totalPushes);
    return { totalWins, totalLosses };
  }
  //const dataForAdam = groupTotalWins(leaderboardUserData);

  function individualRecord(leaderboardUserData) {
    const individualRecords = [];
    leaderboardUserData?.forEach((info) => {
      let totalWins = 0;
      let totalLosses = 0;
      let totalPushes = 0;
      info?.dailyPicks?.forEach((picks) => {
        if (picks.Result === "Loss") {
          totalLosses += 1;
        } else if (picks.Result === "Win") {
          totalWins += 1;
        } else if (picks.Result === "Push") {
          totalPushes += 1;
        }
      });
      individualRecords.push({ player: info.user_name, wins: totalWins, losses: totalLosses, pushes: totalPushes});
    });
    return individualRecords;
  }
  //const individualRecords = individualRecord(leaderboardUserData);
  //console.log("Individual Records:", individualRecords);

  leaderboardUserData.sort((a, b) => a.pickEmRank - b.pickEmRank);
  //console.log("SORTED", leaderboardUserData);

    const poolPicksData = [];

    if (leaderboardUserData && typeof leaderboardUserData === "object") {
        // Iterate over the values of leaderboardUserData object
        Object.values(leaderboardUserData).forEach((info) => {
            const rank = info?.stats?.dailyPicksRank;
            const player = info?.user_name;
            const matchingEntries = [];
            //console.log("dailyPicks", info?.dailyPicks);
            if (!info?.dailyPicks) {
                return;
            }

            uniqueDates.forEach(date => {
                // if (!info?.dailyPicks) {
                //     matchingEntries.push({ logo: "no pick entered yet image", spread: null, borderColor: "", date: date});
                //     return;
                // }
                const allDailyPicks = info?.dailyPicks;
                const entry = allDailyPicks.find(pick => pick.dateTime.includes(date));
                //console.log("Does pick fall on date?", entry, date);

                if (entry) {
                    const dateTime = new Date(entry.dateTime);
                    const spread = entry.spread;
                    const nowInNewYork = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
                    const nowInNewYorkTime = new Date(nowInNewYork).getTime();
                    const hasThisGameStarted = dateTime.getTime() < nowInNewYorkTime;
                    //console.log("Has this game started?", hasThisGameStarted, dateTime, new Date(nowInNewYork).toDateString(), nowInNewYork);
                    let spreadDisplay = "";
                    if (!hasThisGameStarted) {
                        spreadDisplay = null;
                    } else if (spread > 0) {
                        spreadDisplay = `(+${spread})`;
                    } else if (spread < 0) {
                        spreadDisplay = `(${spread})`;
                    } else if (spread === 0) {
                        spreadDisplay = "(Pk)";
                    }
                    const teamID = entry.teamId;
                    const result = entry.Result;

                    let borderColor = "";
                    if (result === "Win") {
                        borderColor = "#00AA72";
                    } else if (result === "Loss") {
                        borderColor = "#CC293C";
                    } else if (result === "Push") {
                        borderColor = "#002129";
                    }
                    else if (result === "Pending") {
                        borderColor = "";
                    }
                    let logo = "";
                    if (hasThisGameStarted) {
                        logo = nbaGetLogo(teamID);
                    } else {
                        logo = "check";
                    }
                    matchingEntries.push({ logo: logo, spread: spreadDisplay, borderColor: borderColor, date: date});
                } else {
                    // Get today's date in EDT
                    const today = new Date();
                    const todayEDT = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

                    // Convert provided date to EDT to ensure consistent comparison
                    const providedDateParts = date.split('-');
                    const providedDate = new Date(providedDateParts[0], providedDateParts[1] - 1, providedDateParts[2]); // Note: Month is 0-indexed
                    const providedDateEDT = `${providedDate.getFullYear()}-${(providedDate.getMonth() + 1).toString().padStart(2, '0')}-${providedDate.getDate().toString().padStart(2, '0')}`;

                    // Compare the EDT dates
                    const isDateInThePast = providedDateEDT < todayEDT;

                    //console.log("IS DATE IN THE PAST?", isDateInThePast, date, providedDateEDT, todayEDT);
                    if (isDateInThePast) {
                        matchingEntries.push({ logo: "X", spread: null, borderColor: "#CC293C", date: date});
                    } else {
                        matchingEntries.push({ logo: "", spread: null, borderColor: "", date: date});
                    }
                }

            });
            poolPicksData.push({
                rank: rank,
                player: player,
                picks: matchingEntries
            });

            //console.log("Matching Entries:", matchingEntries, rank, player);
        });
    }


console.log("Pool Picks Data:", poolPicksData);

const getDuplicateRanks = () => {
    const countMap = {};
    poolPicksData.forEach(entry => {
        const rank = entry.rank;
        countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicateRanks = Object.entries(countMap)
        .filter(([rank, count]) => count > 1)
        .map(([rank]) => parseInt(rank));

    return duplicateRanks;
};


const duplicates = getDuplicateRanks();
//console.log("Duplicates:", duplicates);

const isUserInDuplicateRanks = (userRank) => {
    return duplicates.includes(userRank);
};




  function nbaGetLogo(globalTeamID) {
    const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
    return team.WikipediaLogoUrl;
    }



  function amITied(data, index, filterCriteria) {
    const curr = data[index][filterCriteria];
    const prev = index > 0 ? data[index - 1][filterCriteria] : null;
    const next = index < data.length - 1 ? data[index + 1][filterCriteria] : null;

    // Check if it's the first entry
    if (index === 0) {
        // Check if there's a tie with the next entry
        if (next && curr === next) {
            return true;
        }
    } else if (index === data.length - 1) {
        // Check if there's a tie with the previous entry
        if (prev && curr === prev) {
            return true;
        }
    } else {
        // Check if there's a tie with the previous or next entry
        if ((prev && curr === prev) || (next && curr === next)) {
            return true;
        }
    }

    return false;
}


// Call the function with leaderboardData and the desired filterCriteria
const filterCriteria = 'rank'; // Change this to the desired filter criteria

// Sort the data based on the filterCriteria
poolPicksData?.sort((a, b) => a[filterCriteria] - b[filterCriteria]);
for (let i = 0; i < poolPicksData?.length; i++) {
    const isTied = amITied(poolPicksData, i, filterCriteria);
    if (isTied) {
        //console.log(`Entry ${i + 1} is tied. T${poolPicksData[i][filterCriteria]}`);
    } else {
        //console.log(`Entry ${i + 1} is not tied. ${poolPicksData[i][filterCriteria]} `);
    }
}
const formatDateString = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${parseInt(month, 10)}/${parseInt(day, 10)}`;
};


function getLoggedInUserRank() {
  // Get the user object from the data array
  const user = poolPicksData?.find(entry => entry.player === userData.user_name);
  return user;
}
const loggedInUserRank = getLoggedInUserRank();


return (
    <Grid container sx={{ borderRadius: '0px', maxWidth: '1116px', margin: '0 auto', display: 'block' }}>
      {isMobile ?
        <div style={{ width: '100%' }}><img src={PickemTableMobile} style={{ display: 'block', margin: '0 auto', width: '100%' }} alt='leaderboard header' /></div>
        :
        <div style={{ width: '100%', maxWidth: '1116px', margin: '0 auto' }}><img src={PickemTableDesktop} style={{ display: 'block', margin: '0 auto', width: '100%' }} alt='leaderboard header' /></div>
      }
      <Grid item sx={{ borderRadius: '0px', overflow: 'scroll', margin: '0 auto', display: 'block' }}>
        <TableContainer>
          <Table sx={isMobile ? { margin: '0 auto', width: '100%' } : { width: '1116px', margin: '0 auto', overflow: 'scroll' }}>
            <TableHead sx={{ background: '#002129', color: '#fff' }} className="MuiTableCell-stickyHeader">
              {loggedInUserRank?.rank &&
                <TableRow sx={{ background: '#F0F1F1', color: '#002129' }}>
                  <TableCell size="small" width="10px" sx={{ textAlign: 'center', maxWidth: '149px' }}>
                    {isUserInDuplicateRanks(loggedInUserRank?.rank) ? 'T' : ''}
                    {loggedInUserRank?.rank}
                  </TableCell>
                  <TableCell size="small" width="25px" sx={{ maxWidth: '149px' }}>{loggedInUserRank?.player} <br/>({findUsersPickEmPoints(loggedInUserRank?.player)} pts)</TableCell>
                  {loggedInUserRank?.picks.map((pick, index) => (
                    <TableCell size="small" key={index} sx={{ marginRight: '5px', maxWidth: '149px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{
                          width: '45px',
                          height: '45px',
                          border: `2px solid ${pick.borderColor}`,
                          borderRadius: '2px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          {pick.logo !== 'check' && pick.logo !== 'X' && pick.logo !== '' ?
                            <img src={pick.logo} alt='' style={{ margin: '0 auto', display: 'block', width: '78%' }} />
                            :
                            <>
                              {pick.logo === 'check' && <CheckIcon sx={{ color: '#00AA72' }} />}
                              {pick.logo === 'X' && <CloseIcon sx={{ color: '#cc293c' }} />}
                            </>
                          }
                        </div>
                        <div style={{ marginLeft: '8px' }}>{pick.spread}</div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              }
              <TableRow>
                <TableCell sx={{ color: '#FFF', fontWeight: 900 }}>Rank</TableCell>
                <TableCell sx={{ color: '#FFF', fontWeight: 900 }}>Player</TableCell>
                {uniqueDates.map((date, index) => (
                  <TableCell key={index} sx={{ color: '#FFF', fontWeight: 900 }}>{formatDateString(date)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {poolPicksData.map((row, index) => (
                <TableRow key={index} className={index % 2 === 0 ? '' : 'evenRow'}>
                  <TableCell size="small" width="10px" sx={{ textAlign: 'center', maxWidth: '149px' }}>
                    {isUserInDuplicateRanks(row?.rank) ? 'T' : ''}
                    {row?.rank}
                  </TableCell>
                  <TableCell size="small" width="25px" sx={{ maxWidth: '149px' }}>{row?.player} <br/>({findUsersPickEmPoints(row?.player)} pts) </TableCell>
                  {row?.picks.map((pick, index) => (
                    <TableCell size="small" key={index} sx={{ marginRight: '5px', maxWidth: '149px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{
                          width: '45px',
                          height: '45px',
                          border: `2px solid ${pick.borderColor}`,
                          borderRadius: '2px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          {pick.logo !== 'check' && pick.logo !== 'X' && pick.logo !== '' ?
                            <img src={pick.logo} alt='' style={{ margin: '0 auto', display: 'block', width: '78%' }} />
                            :
                            <>
                              {pick.logo === 'check' && <CheckIcon sx={{ color: '#00AA72' }} />}
                              {pick.logo === 'X' && <CloseIcon sx={{ color: '#cc293c' }} />}
                            </>
                          }
                        </div>
                        <div style={{ marginLeft: '8px' }}>{pick.spread}</div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
  
};

export default PicksByDate;
