import React, { useState } from 'react';
import { Box, Typography, Modal, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useUpdateTextOptIn } from '../../../hooks/pools/useUpdateTextOptIn';
import ResponseModal from '../ResponseModal/ResponseModal';
import { has, set } from 'lodash';

const TextOptInModal = ({
    textOptInModalOpen,
    setTextOptInModalOpen,
}) => {
    const {
        masterPoolId,
        gameData,
        masterPoolData,
        myUserId,
    } = useUserAndGameContext();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isOptIn, setIsOptIn] = useState(true);
    const [isOptOut, setIsOptOut] = useState(false);
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const { mutate, isLoading, isError } = useUpdateTextOptIn();
    
    const textOptInInfo = gameData?.textOptInInfo || masterPoolData?.textOptInInfo;
    const textOptInMessage = textOptInInfo?.text;
    const logo = textOptInInfo?.logo;

    const handleCancel = () => {
        setTextOptInModalOpen(false);
    }

    // Handler for the "Yes" Checkbox
    const handleOptInChange = (e) => {
        setIsOptIn(e.target.checked);
        if (e.target.checked) {
            setIsOptOut(false);
        }
    };

    // Handler for the "No" Checkbox
    const handleOptOutChange = (e) => {
        setIsOptOut(e.target.checked);
        if (e.target.checked) {
            setIsOptIn(false);
        }
    };

    const handleResponseModalClose = () => {
        setTextOptInModalOpen(false);
        setResponseModalOpen(false);
        window.location.reload();
    };

    const handleClose = async () => {
        console.log("Opt In: ", isOptIn);
        console.log("Opt Out: ", isOptOut);
        if (isOptOut) {
            handleCancel();
        } else {
            setHasSubmitted(true);
            const optInData = {
                masterPoolId,
                userId: myUserId,
                textOptIn: isOptIn,
            };

            try {
                await mutate(optInData, {
                    onSuccess: () => {
                        setTextOptInModalOpen(false);
                        window.location.reload();
                    },
                    onError: (error) => {
                        console.error('Error updating text opt-in:', error);
                        setResponseModalOpen(true);
                        setResponseMessage('Error updating text opt-in. Please try again.');
                    }
                });
            } catch (error) {
                console.error('Error updating text opt-in:', error);
                setResponseModalOpen(true);
                setResponseMessage('Error updating text opt-in. Please try again.');
            }
        }
    };

    const isChoiceMade = isOptIn || isOptOut;

    return (
        <>
            <Modal
                open={textOptInModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '335px',
                        maxHeight: '765px',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, 
                        overflow: 'scroll'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: '20px',
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        Text Message Reminders?
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <img 
                            src={logo} 
                            alt="logo" 
                            style={{
                                display: 'block',
                                maxHeight: '150px',
                                width: 'auto',
                            }}
                        />
                    </Box>

                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: '20px',
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        Would you like to opt-in for pick reminder text messages for this pool?
                    </Typography>

                    {textOptInMessage && textOptInMessage?.map((message, index) => (
                        <Typography
                            key={index}
                            sx={{
                                color: '#002129',
                                fontSize: '12px',
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            {message}
                        </Typography>
                    ))}

                    {!textOptInMessage && (
                        <>
                            <Typography
                                sx={{
                                    color: '#002129',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                }}
                            >
                                By selecting "Yes", you agree to receive text messages related to your game picks. Standard message and data rates may apply. You can opt-out at any time by replying STOP.
                            </Typography>

                            <Typography
                                sx={{
                                    color: '#002129',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                }}
                            >
                                Pick reminder texts will only be sent on game days if your picks have not been submitted.
                            </Typography>
                        </>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 4 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOptIn}
                                    onChange={handleOptInChange}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ fontWeight: 700 }}>
                                    Yes
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOptOut}
                                    onChange={handleOptOutChange}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ fontWeight: 700 }}>
                                    No
                                </Typography>
                            }
                        />
                    </Box>
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '16px',
                        }}
                    >
                        <Button
                            onClick={handleCancel}
                            variant="contained"
                            disabled={hasSubmitted}
                        >
                            CANCEL
                        </Button>
                        <Button 
                            onClick={handleClose}
                            variant="contained"
                            disabled={!isChoiceMade || hasSubmitted}
                        >
                            CONTINUE
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <ResponseModal
                open={responseModalOpen}
                onClose={handleResponseModalClose}
                message={responseMessage}
                status="error"
            />
        </>
    );
}

export default TextOptInModal;