import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PoolHallContainer from '../components/PublicPools/PoolHallContainer';
const PoolHall = () => {
  const queryClient = new QueryClient();

  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <PoolHallContainer />
      </QueryClientProvider>
    </Box>
  );
}

export default PoolHall;