import React from "react";
import { Box, Typography, Button } from "@mui/material";

const ConnectionError = () => {
    
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh',
                padding: 2,
                textAlign: 'center'
            }}
        >
            <Typography variant="h5" mb={2}>
                Unable to Load
            </Typography>
            <Typography mb={3}>
                Please check your internet connection and try again
            </Typography>
            <Button 
                variant="contained" 
                onClick={() => window.location.reload()}
            >
                Retry
            </Button>
        </Box>
    );
};

export default ConnectionError;