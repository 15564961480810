import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetSlip.scss';
import GameInfoRow from "./GameInfoRow";
import ParlayTeaserWagerInput from "./ParlayTeaserWagerInput";
import ParlayTeaserInfo from "./ParlayTeaserInfo";

const ParlayBetBox = ({ bet, removeBet, pickState, setPickState, wagerState, setWagerState, bankrollExceeded, toWinState, setToWinState, chosenBetStyle, betOdds }) => {
    console.log("Bet in Bet Box", bet);
    
    return (
        <Box className="sportsbook-staight-bet-container">
             {/* Add a box around the mapped items and make it scrollable */}
             <Box className="picks-container">
                {pickState?.map((pick, index) => (
                    <GameInfoRow 
                        key={index} 
                        bet={pick} 
                        removeBet={removeBet}
                    />
                ))}
            </Box>
            <Box className="spacer" />
            <ParlayTeaserInfo 
                chosenBetStyle={chosenBetStyle} 
                pickState={pickState} 
                betOdds={betOdds} 
            />
            <ParlayTeaserWagerInput 
                wagerState={wagerState} 
                setWagerState={setWagerState} 
                bankrollExceeded={bankrollExceeded} 
                betOdds={betOdds} 
                toWinState={toWinState} 
                setToWinState={setToWinState}
                pickState={pickState}    
            />
        </Box>
    )
}

export default ParlayBetBox;