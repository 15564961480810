import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetQuickPickData = () => {
    return useMutation({
        mutationFn: getQuickPickDataRequest,
        onError: (error) => {
            console.error('Error fetching quick pick data:', error);
        },
        // onSuccess: (data) => {
        //     console.log('Fetched quick pick data:', data);
        // }
    });
}

const getQuickPickDataRequest = async ({ redisKeys }) => {
    //console.log("Redis Keys:", redisKeys);
    const authorizationHeader = localStorage.getItem('authorization');
    
    const response = await axios.get(
        `${apiUrl}/sports-data/get-quick-pick-data`, 
        {
            params: { redisKeys },
            headers: {
                Authorization: authorizationHeader,
                'Content-Type': 'application/json'
            }
        }
    );
    
    return response.data.quickPickData;
}