import { apiClient } from "../../apiClient";
import { useMutation } from "@tanstack/react-query";
import { handleSetUserInLocalStorage } from "../../utils/users";
import { apiUrl } from '../../config';

const updateUserRequest = (opts) => {
  const authorizationHeader = localStorage.getItem('authorization');
  return apiClient.put(`${apiUrl}/user/${opts.id}`, {
    ...opts
  }, {
    headers: {
      Authorization: authorizationHeader,
    },

  }).then((res) => res);
};

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: updateUserRequest,
    onError: (error) => {
      return error;
    },
    onSuccess: (response) => {
      handleSetUserInLocalStorage({
        headers: response.headers,
        data: response.data?.data
      });
      return response;
    },
  });
};
