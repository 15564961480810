import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetGameReferrals = () => {
  return useMutation({
    mutationFn: getGameReferrals,
    onError: (error) => {
      console.error('Error fetching game:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Fetched game data:', data);
    // }
  });
};

const getGameReferrals = async ({ gameID }) => {  // Destructure to extract gameID
    //console.log("League:", gameID);
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    // Ensure we pass only the raw gameID value
    const gameIDValue = gameID;  // Since gameID is already the correct value (not an object), we can directly use it.

    const response = await axios.get(`${apiUrl}/sports-data/get-game-referrals`, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json'
        },
        params: { gameID: gameIDValue }  // Pass the raw gameID value to the query parameters
    });

    return response.data;
};

