import { useState, useEffect } from "react";
import './BracketBox.scss';
const BracketBoxLive = ( {game, series, actualGame, NBATeams, NBAStandings, boxLineClass, bracketBoxCustom, bracketRightCustom, backBoxLine} ) => {

    //console.log("Game, Series, and then Actual Game INSIDE OF BRACKET BOX LIVE: ", game, series, actualGame);
    function nbaGetLogo(globalTeamID) {
        const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
        if (team) {
          return team.WikipediaLogoUrl;
        } else {
          //console.log(`Team with ID ${globalTeamID} not found`);
          return null; // Or handle the missing data appropriately
        }
      }


      function nbaGetKey(globalTeamID) {
        const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
        if (team) {
          if(team.Key.startsWith("LA")){
            return "LA";
          } else {
          return team.Key;
        }
        } else {
          //console.log(`Team with ID ${globalTeamID} not found`);
          return null; // Or handle the missing data appropriately
        }
      }

      function nbaGetName(globalTeamID) {
        const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
        if (team) {
          if (team.Name.startsWith("Timber")) {
            return "T'Wolves";
          } else {
            return team.Name;
          }
        } else {
          //console.log(`Team with ID ${globalTeamID} not found`);
          return null; // Or handle the missing data appropriately
        }
      }

      function nbaGetSeed(globalTeamID) {
        const team = NBAStandings?.find(team => team.GlobalTeamID === globalTeamID);
        if (team) {
          return team.ConferenceRank;
        } else {
          //console.log(`Team with ID ${globalTeamID} not found`);
          return null; // Or handle the missing data appropriately
        }
      }


    const round = game?.round;
    const userTopChoiceID = game?.topTeamID;
    const userTopLogo = nbaGetLogo(userTopChoiceID);
    const userTopKey = nbaGetKey(userTopChoiceID);
    const userTopName = nbaGetName(userTopChoiceID);
    const userTopDisplayName = `${userTopKey} ${userTopName}`;
    const userTopSeed = game?.topTeamSeed;
    const userTopResult = game?.topUserResult;
    const userBottomChoiceID = game?.bottomTeamID;
    const userBottomLogo = nbaGetLogo(userBottomChoiceID);
    const userBottomKey = nbaGetKey(userBottomChoiceID);
    const userBottomName = nbaGetName(userBottomChoiceID);
    const userBottomDisplayName = `${userBottomKey} ${userBottomName}`;
    const userBottomSeed = game?.bottomTeamSeed;
    const userBottomResult = game?.bottomUserResult;
    const actualTopID = actualGame?.topTeam
    //console.log(actualTopID);
    const actualTopLogo = nbaGetLogo(actualTopID);
    const actualTopKey = nbaGetKey(actualTopID);
    const actualTopName = nbaGetName(actualTopID);
    const actualTopSeed = nbaGetSeed(actualTopID);
    const actualTopDisplayName = `${actualTopKey} ${actualTopName}`;
    const topWins = actualGame?.topTeamWins;
    const actualBottomID = actualGame?.bottomTeam;
    const actualBottomLogo = nbaGetLogo(actualBottomID);
    const actualBottomKey = nbaGetKey(actualBottomID);
    const actualBottomName = nbaGetName(actualBottomID);
    const actualBottomSeed = nbaGetSeed(actualBottomID);
    const actualBottomDisplayName = `${actualBottomKey} ${actualBottomName}`;
    const bottomWins = actualGame?.bottomTeamWins;
    const seriesWinner = actualGame?.winner;

    const lossColor = "#CC293C";
    const winColor = "#00AA72";
    const pendingColor = "#002129"
    let seriesColor = null;
    const seriesLength = series?.length; //Will become series.length
    const seriesLenghtDisplay = `${seriesLength} Games`;
    const seriesLengthResult = series?.seriesLengthResult; //Will become series.seriesLengthResult
    if (seriesLengthResult === "Win") {
        seriesColor = winColor;
    } else if (seriesLengthResult === "Loss") {
        seriesColor = lossColor;
    } else {
        seriesColor = pendingColor;
    }

    // Return the HTML divs with className attributes
    return (
        <>
        <div className={`bracket-game-container ${bracketBoxCustom} ${bracketRightCustom}`}>


        {userTopResult !== "Win" && round !== 1 ? (
          <div className="userpick-container">
            <div className="userpick-content">
              {userTopLogo ? (
                <div style={{display: 'flex', margin:'0px auto 5px auto'}}>
                  <img className="userpick-img team-logo-img" src={userTopLogo} width="16" height="16" />
                  <div className="userpick-number team-seed" style={userTopResult === "Loss" ? { color: lossColor, textDecoration: "line-through" } : {}}>
                    {userTopSeed}
                  </div>
                  <div className="userpick-team team-name" style={userTopResult === "Loss" ? { color: lossColor, textDecoration: "line-through" } : {}}>
                    {userTopDisplayName}
                  </div>
                </div>
              ) : (
                <div className="no-pick" style={{ color: "#CC293C", textDecoration: "line-through" }}>No Pick</div>
              )}
            </div>
          </div>
        ) : (
          <div className="userpick-container" style={{ width: '164px', height: '15px' }}></div>
        )}

<div className={backBoxLine}></div>
    <div className="container bracketBox">
    <div className="item-container team-section">
  {actualTopLogo && (
    <>
      <div className="bracket-team-container">
        <img className="team-logo-img"  src={actualTopLogo} alt="Team Logo"width="16" height="16" />
        <div className="number team-seed" style={{ color: userTopResult === "Win" ? winColor : null, fontWeight: actualTopID === seriesWinner ? 700 : 'normal' }}>{actualTopSeed}</div>
        <div className="team-name" style={{ color: userTopResult === "Win" ? winColor : null, fontWeight: actualTopID === seriesWinner ? 700 : 'normal' }}>{actualTopDisplayName}</div>
        <div className="number team-seed" style={{ color: userTopResult === "Win" ? winColor : null, fontWeight: actualTopID === seriesWinner ? 700 : 'normal' }}>{topWins}</div>
      </div>
    </>
  )}
  {!actualTopLogo && (
    <div className="bracket-team-container">
      <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9" />
      </svg>
    </div>
  )}
</div>

    <div className="series-container" style={{ border: `1px solid ${seriesColor}` }}>
        <div className="series-text" style={{ color: seriesColor }}>{seriesLenghtDisplay}</div>
    </div>

    <div className="item-container">
  {actualBottomLogo && (
    <>
      <div style={{display: 'flex'}}>
        <img src={actualBottomLogo} className="team-logo-img" alt="Team Logo" width="16" height="16"/>
        <div className="number team-seed" style={{ color: userBottomResult === "Win" ? winColor : null, fontWeight: actualBottomID === seriesWinner ? 700 : 'normal' }}>{actualBottomSeed}</div>
        <div className="team-name" style={{ color: userBottomResult === "Win" ? winColor : null, fontWeight: actualBottomID === seriesWinner ? 700 : 'normal' }}>{actualBottomDisplayName}</div>
        <div className="number team-seed" style={{ color: userBottomResult === "Win" ? winColor : null, fontWeight: actualBottomID === seriesWinner ? 700 : 'normal' }}>{bottomWins}</div>
     </div>
    </>
  )}
  {!actualBottomLogo && (
    <div style={{display: 'flex'}}>
      <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9" />
      </svg>
    </div>
  )}
</div>
</div>
<div className={boxLineClass}></div>



{userBottomResult !== "Win" && round !== 1 ? (
  <div className="userpick-container">
    <div className="userpick-content">
      {userBottomLogo ? (
        <div  style={{display: 'flex', margin:'5px auto 0px auto'}}>
          <img className="userpick-img team-logo-img" src={userBottomLogo} alt="pick" width="16" height="16"/>
          <div className="userpick-number team-seed" style={userBottomResult === "Loss" ? { color: lossColor, textDecoration: "line-through" } : {}}>
            {userBottomSeed}
          </div>
          <div className="userpick-team team-name" style={userBottomResult === "Loss" ? { color: lossColor, textDecoration: "line-through" } : {}}>
            {userBottomDisplayName}
          </div>
        </div>
      ) : (
        <div className="no-pick" style={{ color: "#CC293C", textDecoration: "line-through" }}>No Pick</div>
      )}
    </div>
  </div>
) : (
  <div className="userpick-container" style={{ width: '164px', height: '15px' }}></div>
)}

        </div>
    </>


    );
};

export default BracketBoxLive;
