export const calculateParlayOdds = (pickState) => {
    const toDecimal = (odds) => {
        if (odds < 0) {
            return 1 + (100 / Math.abs(odds));
        } else {
            return 1 + (odds / 100);
        }
    };

    const toAmerican = (decimal) => {
        if (decimal >= 2) {
            return Math.round((decimal - 1) * 100);
        } else {
            return Math.round(-100 / (decimal - 1));
        }
    };

    const combinedDecimalOdds = pickState
        .map(key => toDecimal(key.juice))
        .reduce((acc, decimal) => acc * decimal, 1);

    return toAmerican(combinedDecimalOdds);
};


export const hasConflictingBets = (bets) => {
    const groupedBets = bets.reduce((acc, bet) => {
        const { gameId, betType, betChoice, line, juice } = bet;
        if (!acc[gameId]) acc[gameId] = { Spread: [], Moneyline: [], Total: [] };

        if (betType === "Spread") acc[gameId].Spread.push({ betChoice, line });
        else if (betType === "Moneyline") acc[gameId].Moneyline.push({ betChoice, juice });
        else if (betType === "Total") acc[gameId].Total.push({ betChoice });

        return acc;
    }, {});

    return Object.values(groupedBets).some(({ Spread, Moneyline, Total }) => {
        const hasConflictingSpread = Spread.length > 1;
        const hasConflictingMoneyline = Moneyline.length > 1;
        const hasConflictingOverUnder = Total.length > 1;
        const hasConflictingLineAndJuice = Spread.some(spread =>
            Moneyline.some(moneyline => spread.line < 0 && moneyline.juice > 0)
        );
        const hasSameChoiceConflict = Spread.some(spread =>
            Moneyline.some(moneyline => spread.betChoice === moneyline.betChoice)
        );
        return hasConflictingSpread ||
            hasConflictingMoneyline ||
            hasConflictingOverUnder ||
            hasConflictingLineAndJuice ||
            hasSameChoiceConflict;
    });
};


export const hasValidBets = (bets) => {
    return bets.every(bet => bet.betType !== "Moneyline" && (bet.sport === "Football" || bet.sport === "Basketball"));
};


export const hasMixedSports = (bets) => {
    const uniqueSports = [...new Set(bets.map(bet => bet.sport))];
    return uniqueSports.length > 1;
};


export const hasInvalidSports = (bets) => {
    return bets.some(bet => bet.sport !== "Football" && bet.sport !== "Basketball");
};
