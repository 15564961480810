import React, { useContext } from 'react';
import { MarchMadnessBracketContext, MarchMadnessBracketProvider } from '../components/bracketEvents/bracketContext/marchMadnessBracketContext';
import BracketContainer from '../components/bracketEvents/bracketContainer';

const BracketPage = ({ bracketProvider }) => {
    // const {  } = useContext(MarchMadnessBracketContext);

    const getBracketProvider = () => {
        if (bracketProvider === 'provider1') {
            return MarchMadnessBracketProvider;
        } else if (bracketProvider === 'provider2') {
            // Return another bracket provider
        } else {
            // Return a default bracket provider
            return MarchMadnessBracketProvider;
        }
    };

    const BracketProviderToUse = getBracketProvider();

    return (
        <div>
            <BracketProviderToUse>
                <BracketContainer />
            </BracketProviderToUse>
        </div>
    );
};

export default BracketPage;
