import React, { useState, useEffect } from "react";
import MyPicks from "./MyPicks/MyPicks";
import PickEmNav from "./Headers/PickEmNav";
import PickEmHeader from "./Headers/PickEmHeader";
import PickEmUserDash from "./Headers/PickEmUserDash";
import PickEmLeaderboard from "./Leaderboard/PickEmLeaderboard";
import PoolPicks from "./PoolPicks/PoolPicks";
import TeamStats from "./TeamStats/TeamStats";
import NFLPickEmHome from "./PickEmHome/NFLPickEmHome";
import MyParty from "../MyParty/MyParty";
import PartyDropdown from "../PartyDropdown/MyPartyDropdown";
import NFLPickEmRules from "./Rules/NFLPickEmRules";
import { useNFLPickEmContext } from "./context/NFLPickEmContext";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const NFLPickEmContainer = () => {
  const { gameId, component, pageState } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  
  const {
    gameData,
    myUserId,
    gameAddress,
    handleCreateSubgroup,
    userSubgroups, 
    signUpState,
    setSignUpState,
    NFLStandings, 
    NFLStadiums, 
    NFLTeams,
    NFLGameOddsByWeek,
    setWeek,
    startingWeekState, 
    clickState,
    setClickState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    defaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    leaderboardInfo,
    doesUserBelong,
    joinOpen,
    setJoinOpen, 
    isPickEmOver,
  } = useNFLPickEmContext(); 

  //console.log("PartyState in Container: ", partyState, "Party Id in Container: ", partyId);
  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  let fullLeaderboard = leaderboardInfo ? leaderboardInfo : [];
  // Sorting function with a default value for points
  fullLeaderboard?.sort((a, b) => {
    const pointsA = a?.points ?? 0; // Use default 0 if points is undefined
    const pointsB = b?.points ?? 0; // Use default 0 if points is undefined
    return pointsB - pointsA;
  });
  //console.log("Sorted Leaderboard in Container: ", fullLeaderboard);

  const [leaderboardData, setLeaderboardData] = useState(fullLeaderboard);


  useEffect(() => {
  if (partyId !== gameId) {
    //console.log("PartyId in Container: ", partyId, gameData);
    const subgroups = gameData?.subGroups
    const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
    const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
    //console.log("Chosen Subgroup in Container: ", subgroups, filteredSubgroups, filteredUserIds);
    const filteredLeaderboard = fullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
    //console.log("Filtered Leaderboard in Container: ", filteredLeaderboard);
    setLeaderboardData(filteredLeaderboard);
  } else {
    setLeaderboardData(fullLeaderboard);
  }
  }, [partyId, partyState, fullLeaderboard]);

  // Ensure that all necessary data is available before rendering
  // const isLoading = !clubleaderboardInfo || !userData || !startingScorecard || !birdiePoolData || !birdieLeaderboardData || !headerDisplayName || !userScorecard || !myUserName;
  // console.log('isLoading', isLoading);
  // if (isLoading) {
  //   return <h2>Loading...</h2>; // Render loading state if data is not available yet
  // }

  //console.log("MY USER ID In Conatiner", myUserId);

  const navigateToTop = useNavigateToTop();



  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setWeek(startingWeekState);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    if (click === 'MY_PARTY' && userSubgroups.length === 0) {
      pageStateToUse = 'NO_PARTY';
    } else if (click === 'MY_PARTY' && partyId === gameId) {
      if (defaultPartyName !== 'Pool Party') {
        pageStateToUse = defaultPartyId;
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else {
        pageStateToUse = firstSubgroupId;
        setPartyState(firstSubgroupName);
        setPartyId(firstSubgroupId);
      }
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }

  return (
    <div>
      <PickEmHeader />

      {myUserId && <PickEmUserDash />}
      
      <PickEmNav 
        setClickState={handleClickState} 
        clickState={clickState} 
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isPickEmOver={isPickEmOver}
        userSubgroups={userSubgroups}
      />

      {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'TEAM_STATS' || clickState === 'POOL_PICKS')) ?
      <PartyDropdown 
        userSubgroups={userSubgroups} 
        gameAddress={gameAddress}
        partyState={partyState}
        setPartyState={setPartyState}
        clickState={clickState}
        setPartyId={setPartyId}
      /> 
        : 
      null}
      
      {clickState === 'MY_PICKS' && <MyPicks />}

      {clickState === 'LEADERBOARD' && <PickEmLeaderboard leaderboardData={leaderboardData }/>}

      {clickState === 'POOL_PICKS' && <PoolPicks leaderboardData={leaderboardData} />}

      {clickState === 'TEAM_STATS' && <TeamStats leaderboardData={leaderboardData} />}

      {clickState === 'HOME' && <NFLPickEmHome />}

      {clickState === 'RULES' && <NFLPickEmRules />}

      {clickState === 'MY_PARTY' && 
        <MyParty 
            gameData={gameData}
            myUserId={myUserId}
            handleCreateSubgroup={handleCreateSubgroup}
            userSubgroups={userSubgroups} 
            signUpState={signUpState}
            setSignUpState={setSignUpState}
            gameAddress={gameAddress}
            leaderboardData={leaderboardData}
            partyState={partyState}
            partyId={partyId}
            defaultPartyId={defaultPartyId}
            fullLeaderboard={fullLeaderboard}
            doesUserBelong={doesUserBelong}
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            isGameOver={isPickEmOver}
        />
      }
    </div>
  );
};

export default NFLPickEmContainer;
