import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import {getCurrentGameFromLocalStorage, fetchGameById} from '../../../redux/actions/gameImport';
import { useParams } from 'react-router-dom';


const GamePageContext = createContext();

export const useGameContext = () => useContext(GamePageContext);

export const GamePageProvider = ({ children }) => {
    // const gameData = getCurrentGameFromLocalStorage();
    // const gameID = gameData?.gameID;

    const dispatch = useDispatch();
    const { gameId } = useParams(); // Extract the gameId from the URL
  
    useEffect(() => {
        const userData = getUserDataFromLocalStorage();

        const payload = {
            gameID: gameId,
            poolType: "Block_Pool",
            email: userData.email,
          }
      dispatch(fetchGameById(payload)); // Pass the gameId to fetchGameById
    }, [dispatch, gameId]);
    const gameID = gameId;

    const contextValue = {
        gameId
    };

    return (
        <>
            {gameId?
                <GamePageContext.Provider  value={contextValue}>
                    {children}
                </GamePageContext.Provider>
                : 
                <div>Game ID not found</div>
            }
        </>
    );
}

