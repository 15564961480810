import React, { useState, useEffect, useRef } from "react";
import "./BracketBox.scss"; // Import CSS file
import { useDispatch, connect } from "react-redux";
import {
  fetchNBAStandings,
  fetchNBATeams,
} from "../../redux/actions/dataImport.js";
import { gamePicksSave } from "../../redux/actions/gameImport.js";
import BracketSelectionBox from "./BracketBoxWithRadio.js";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport.js";
import { getNbaPlayoffLeaderboardAction } from "../../redux/actions/loginImport";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import noTeamLogo from "../../assets/images/no-team-logo.svg";
import BracketRoundNav from "../NBAPlayoffs/bracket/bracketRoundNav.js";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BracketBoxLive from "./BracketBoxLive";
import DropdownMenu from "./DropdownMenu";
import VectorCrown from "../../assets/images/VectorCrown.svg";

const BracketsLive = (props) => {
  console.log("NBAPlayoffsContainer props:", props);

  const { gameId } = useParams(); // Extract the gameId from the URL
  const { NBAStandings, NBATeams, leaderboardInfo } = props;
  const userData = getUserDataFromLocalStorage();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actualResults = leaderboardInfo?.data?.actualResults;
  const LeaderboardResults = leaderboardInfo?.data?.results;

  const isUserInGame = LeaderboardResults?.find(entry => entry.user_name === userData.user_name);
    //console.log("Is User in Game: ", isUserInGame);
    let initialUser = null;
    if (!isUserInGame) {
      initialUser = initialUser = LeaderboardResults ? LeaderboardResults[0] : null;
    } else {
      initialUser = LeaderboardResults?.find(entry => entry.user_name === userData.user_name);
    }

  const initialBracketPicks = initialUser?.bracketPicks;
  const initialSeriesLengths = initialUser?.seriesLengths;

  console.log(
    "Actual Results from Redis: ",
    actualResults,
    initialUser?.bracketPicks,
    initialBracketPicks
  );

  let originalDisplayName = null;
  if (isUserInGame) {
  const whoseLoggedIn = userData.user_name; 
  originalDisplayName = whoseLoggedIn;
  } else {
  const leaderboardLeader = initialUser?.user_name;
  //console.log("Leaderboard Leader: ", leaderboardLeader);
  originalDisplayName = leaderboardLeader;
  }  
  
  const [selectedUsername, setSelectedUsername] = useState(originalDisplayName);

  const [seriesLengthState, setSeriesLengthState] = useState(
    initialUser?.seriesLengths
  );
  const [actualGameState, setActualGameState] = useState(actualResults);

  // State to hold selected username, bracket picks, and series lengths
  const [pickState, setPickState] = useState(initialUser?.bracketPicks);
  const [roundDash, setRoundDash] = useState("ROUND_ONE");
  console.log(selectedUsername, pickState);

  const roundOneRef = useRef(null);
  const roundTwoRef = useRef(null);
  const confRef = useRef(null);
  const finalsRef = useRef(null);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  console.log("LeadrboardInfo: ", leaderboardInfo);

  const getBracketPicksByUsername = (username) => {
    // Find the user object that matches the username
    const user = LeaderboardResults?.find(
      (entry) => entry.user_name === username
    );
    // Return the bracket picks of the user if found, otherwise return an empty array
    return user ? user.bracketPicks : [];
  };

  const getSeriesLengthsByUsername = (username) => {
    // Find the user object that matches the username
    const user = LeaderboardResults?.find(
      (entry) => entry.user_name === username
    );
    // Return the series lengths of the user if found, otherwise return an empty array
    return user ? user.seriesLengths : [];
  };

  // useEffect to set initial state based on DropdownMenu data
  // useEffect(() => {
  //   const userData = getUserDataFromLocalStorage();
  //   let leaderBoardStuff =  dispatch(getNbaPlayoffLeaderboardAction());

  //   console.log('SELECTED USERNAME', userData, leaderBoardStuff, props);
  //   const user = LeaderboardResults?.find(entry => entry.user_name === userData.user_name);

  //   const initialBracketPicks = user ? user.bracketPicks : [];
  //   const initialSeriesLengths = user ? user.seriesLengths : [];
  //   console.log('SELECTED USERNAME', initialBracketPicks, initialSeriesLengths);

  //   setPickState(initialBracketPicks);
  //   setSeriesLengthState(initialSeriesLengths);
  //   //console.log("AFTER USE EFFECT Pick State: ", pickState, "Series Length State: ", seriesLengthState)
  // }, [whoseLoggedIn, dispatch]);

  // Handle selection change
  const handleSelectionChange = (username, bracketPicks, seriesLengths) => {
    setSelectedUsername(username);
    setPickState(bracketPicks);
    setSeriesLengthState(seriesLengths);
    //console.log("AFTER HANDLE SELECTION Username:", username,  "Pick State: ", pickState, "Series Length State: ", seriesLengthState)
  };

  function nbaGetLogo(globalTeamID) {
    const team = NBATeams?.find((team) => team.GlobalTeamID === globalTeamID);
    if (team) {
      return team.WikipediaLogoUrl;
    } else {
      //console.log(`Team with ID ${globalTeamID} not found`);
      return null; // Or handle the missing data appropriately
    }
  }

  function nbaGetName(globalTeamID) {
    const team = NBATeams?.find((team) => team.GlobalTeamID === globalTeamID);
    if (team) {
      if (team.Name.startsWith("Timber")) {
        return "T'Wolves";
      } else {
        return team.Name;
      }
    } else {
      //console.log(`Team with ID ${globalTeamID} not found`);
      return null; // Or handle the missing data appropriately
    }
  }

  function getFirstRoundDisplay(round, conference, displayOrder) {
    const actualGame = actualGameState?.find(
      (game) =>
        game.conference === conference &&
        game.round === round &&
        game.displayOrder === displayOrder
    );
    //console.log("Actual Game: ", actualGame)
    if (actualGame) {
      //console.log("Actual Game FOUNDD")
      return actualGame;
    } else {
      console.log("Actual Game NOT FOUND!!!!!!!!!!!!!!!!!!"); // Or handle the case when the game is not found
      return null;
    }
  }

  function getSeriesLengthInfo(round, conference, displayOrder) {
    const seriesLength = seriesLengthState?.find(
      (series) =>
        series.conference === conference &&
        series.round === round &&
        series.displayOrder === displayOrder
    );
    //console.log("Series Length: ", seriesLength)
    if (seriesLength) {
      //console.log("Series Length FOUNDD")
      return seriesLength;
    } else {
      //console.log("Series Length NOT FOUND!!!!!!!!!!!!!!!!!!") // Or handle the case when the series length is not found
      return null; // Or handle the case when the series length is not found
    }
  }

  function getGameInfoToDisplay(round, conference, displayOrder) {
    let topTeamPick, bottomTeamPick;

    if (round === 4) {
      topTeamPick = pickState?.find(
        (item) => item.round === round - 1 && item.conference === "Western"
      );
      bottomTeamPick = pickState?.find(
        (item) => item.round === round - 1 && item.conference === "Eastern"
      );
    } else {
      // Find the top team for the current game
      topTeamPick = pickState?.find(
        (item) =>
          item.round === round - 1 &&
          item.conference === conference &&
          item.displayOrder === displayOrder * 2 - 1
      );

      // Find the bottom team for the current game
      bottomTeamPick = pickState?.find(
        (item) =>
          item.round === round - 1 &&
          item.conference === conference &&
          item.displayOrder === displayOrder * 2
      );
    }

    // Construct the game info object
    const gameInfo = {
      round: round,
      conference: conference,
      displayOrder: displayOrder,
      topTeamID: topTeamPick ? topTeamPick.id : null,
      topTeamSeed: topTeamPick ? topTeamPick.seed : null, // Assuming seed is stored in the pick object
      bottomTeamID: bottomTeamPick ? bottomTeamPick.id : null,
      bottomTeamSeed: bottomTeamPick ? bottomTeamPick.seed : null, // Assuming seed is stored in the pick object
      topUserResult: topTeamPick ? topTeamPick.Result : null,
      bottomUserResult: bottomTeamPick ? bottomTeamPick.Result : null,
    };

    return gameInfo;
  }

  const LossPlaceholder = () => (
    <div
      style={{
        width: "75%",
        height: "75%",
        backgroundColor: "#00000040", // Gray background color
        border: "2px solid #00000040", // Gray border
        borderRadius: "8px", // Border radius
        boxSizing: "border-box", // Include border in width and height
        padding: "25px", // Add padding to create space between border and content
        marginTop: "10px", // Add margin to create space between boxes
        marginLeft: "10px",
      }}
    ></div>
  );

  const West1st1Actual = getFirstRoundDisplay(1, "Western", 1);
  const West1st1Picks = getGameInfoToDisplay(1, "Western", 1);
  const West1st1Series = getSeriesLengthInfo(1, "Western", 1);
  const West1st2Actual = getFirstRoundDisplay(1, "Western", 2);
  const West1st2Picks = getGameInfoToDisplay(1, "Western", 2);
  const West1st2Series = getSeriesLengthInfo(1, "Western", 2);
  const West1st3Actual = getFirstRoundDisplay(1, "Western", 3);
  const West1st3Picks = getGameInfoToDisplay(1, "Western", 3);
  const West1st3Series = getSeriesLengthInfo(1, "Western", 3);
  const West1st4Actual = getFirstRoundDisplay(1, "Western", 4);
  const West1st4Picks = getGameInfoToDisplay(1, "Western", 4);
  const West1st4Series = getSeriesLengthInfo(1, "Western", 4);
  const East1st1Actual = getFirstRoundDisplay(1, "Eastern", 1);
  const East1st1Picks = getGameInfoToDisplay(1, "Eastern", 1);
  const East1st1Series = getSeriesLengthInfo(1, "Eastern", 1);
  const East1st2Actual = getFirstRoundDisplay(1, "Eastern", 2);
  const East1st2Picks = getGameInfoToDisplay(1, "Eastern", 2);
  const East1st2Series = getSeriesLengthInfo(1, "Eastern", 2);
  const East1st3Actual = getFirstRoundDisplay(1, "Eastern", 3);
  const East1st3Picks = getGameInfoToDisplay(1, "Eastern", 3);
  const East1st3Series = getSeriesLengthInfo(1, "Eastern", 3);
  const East1st4Actual = getFirstRoundDisplay(1, "Eastern", 4);
  const East1st4Picks = getGameInfoToDisplay(1, "Eastern", 4);
  const East1st4Series = getSeriesLengthInfo(1, "Eastern", 4);
  const West2nd1Actual = getFirstRoundDisplay(2, "Western", 1);
  const West2nd1Picks = getGameInfoToDisplay(2, "Western", 1);
  const West2nd1Series = getSeriesLengthInfo(2, "Western", 1);
  const West2nd2Actual = getFirstRoundDisplay(2, "Western", 2);
  const West2nd2Picks = getGameInfoToDisplay(2, "Western", 2);
  const West2nd2Series = getSeriesLengthInfo(2, "Western", 2);
  const East2nd1Actual = getFirstRoundDisplay(2, "Eastern", 1);
  const East2nd1Picks = getGameInfoToDisplay(2, "Eastern", 1);
  const East2nd1Series = getSeriesLengthInfo(2, "Eastern", 1);
  const East2nd2Actual = getFirstRoundDisplay(2, "Eastern", 2);
  const East2nd2Picks = getGameInfoToDisplay(2, "Eastern", 2);
  const East2nd2Series = getSeriesLengthInfo(2, "Eastern", 2);
  const West3rd1Actual = getFirstRoundDisplay(3, "Western", 1);
  const West3rd1Picks = getGameInfoToDisplay(3, "Western", 1);
  const West3rd1Series = getSeriesLengthInfo(3, "Western", 1);
  const East3rd1Actual = getFirstRoundDisplay(3, "Eastern", 1);
  const East3rd1Picks = getGameInfoToDisplay(3, "Eastern", 1);
  const East3rd1Series = getSeriesLengthInfo(3, "Eastern", 1);
  const FinalsActual = getFirstRoundDisplay(4, "Finals", 1);
  const FinalsPicks = getGameInfoToDisplay(4, "Finals", 1);
  const FinalsSeries = getSeriesLengthInfo(4, "Finals", 1);
  const finalWinner = FinalsActual?.winner;
  //const finalWinner = 20000020
  let finalWinnerName = "";
  if (finalWinner) {
    finalWinnerName = nbaGetName(finalWinner);
  }
  const userFinalWinner = pickState[14].id;
  const userFinalsResult = pickState[14].Result;
  // const userFinalsResult ="Win";
  let userFinalsName = "";
  if (userFinalsResult !== "Win") {
    userFinalsName = nbaGetName(userFinalWinner);
  }
  console.log(
    "Finals Winner: ",
    finalWinner,
    "User Finals Winner: ",
    userFinalWinner,
    "User Finals Result: ",
    userFinalsResult,
    "User Finals Name: ",
    userFinalsName
  );
  let champLogo = nbaGetLogo(userFinalWinner);
  const isFinalOver = finalWinner !== null;
  // const isFinalOver = true;
  console.log("Is Final Over: ", isFinalOver);
  if (isFinalOver) {
    champLogo = nbaGetLogo(finalWinner);
  }
  let champColor = "#F2F2F2";
  if (userFinalsResult === "Win") {
    champColor = "#00AA72";
  } else if (userFinalsResult === "Loss") {
    champColor = "#CC293C";
  }
  let textColor = null;
  if (userFinalsResult === "Win" || userFinalsResult === "Loss") {
    textColor = "#FFFFFF";
  }
  let bottomTextColor = "#002129";
  if (userFinalsResult === "Loss") {
    bottomTextColor = "#CC293C";
  }
  let bottomStrikeout = null;
  if (userFinalsResult === "Loss") {
    bottomStrikeout = "line-through";
  }

  return (
    <>
      <BracketRoundNav
        roundDash={roundDash}
        setRoundDash={setRoundDash}
        setRoundDasPosition={{
          ROUND_ONE: () =>
            roundOneRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest", // Use 'start', 'center', 'end', or 'nearest'
            }),
          ROUND_TWO: () =>
            roundTwoRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            }),
          CONF: () =>
            confRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            }),
          FINALS: () =>
            finalsRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            }),
        }}
      />
      <DropdownMenu
        selectedUsername={selectedUsername}
        pickState={pickState}
        seriesLength={seriesLengthState}
        onSelectionChange={handleSelectionChange}
        userData={LeaderboardResults}
        getBracketPicksByUsername={getBracketPicksByUsername}
        getSeriesLengthsByUsername={getSeriesLengthsByUsername}
      />
      <div className="conference-title-container">
        <h2 className="conference-title">WEST</h2>
        <h2 className="conference-title right">EAST</h2>
      </div>
      <div className={"bracket-box-wrapper"}>
        <div className={"bracket-box-container"}>
          <div style={{ display: "flex", marginRight: "12px" }}>
            <div className="column-one" ref={roundOneRef}>
              <BracketBoxLive
                game={West1st1Picks}
                series={West1st1Series}
                actualGame={West1st1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={""}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={West1st2Picks}
                series={West1st2Series}
                actualGame={West1st2Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={""}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={West1st3Picks}
                series={West1st3Series}
                actualGame={West1st3Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={""}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={West1st4Picks}
                series={West1st4Series}
                actualGame={West1st4Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={""}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
            </div>
            <div className="column-two" ref={roundTwoRef}>
              <BracketBoxLive
                game={West2nd1Picks}
                series={West2nd1Series}
                actualGame={West2nd1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={"round-two-box-one"}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={West2nd2Picks}
                series={West2nd2Series}
                actualGame={West2nd2Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={"round-two-box-two"}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
            </div>
            <div className="column-three" ref={confRef}>
              <BracketBoxLive
                game={West3rd1Picks}
                series={West3rd1Series}
                actualGame={West3rd1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={"round-three-box-one"}
                bracketRightCustom={""}
                backBoxLine={"back-line"}
              />
            </div>
          </div>

          <div className={"bottom-bracket"} style={{ display: "flex" }}>
            <div className="column-one">
              <BracketBoxLive
                game={East1st1Picks}
                series={East1st1Series}
                actualGame={East1st1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={""}
                bracketRightCustom={"bracket-right-side-top"}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={East1st2Picks}
                series={East1st2Series}
                actualGame={East1st2Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={""}
                bracketRightCustom={"bracket-right-side-bottom"}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={East1st3Picks}
                series={East1st3Series}
                actualGame={East1st3Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={""}
                bracketRightCustom={"bracket-right-side-top"}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={East1st4Picks}
                series={East1st4Series}
                actualGame={East1st4Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={""}
                bracketRightCustom={"bracket-right-side-bottom"}
                backBoxLine={"back-line"}
              />
            </div>
            <div className="column-two">
              <BracketBoxLive
                game={East2nd1Picks}
                series={East2nd1Series}
                actualGame={East2nd1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-top"}
                bracketBoxCustom={"round-two-box-one"}
                bracketRightCustom={"bracket-right-side-top"}
                backBoxLine={"back-line"}
              />
              <BracketBoxLive
                game={East2nd2Picks}
                series={East2nd2Series}
                actualGame={East2nd2Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={"round-two-box-two"}
                bracketRightCustom={"bracket-right-side-bottom"}
                backBoxLine={"back-line"}
              />
            </div>
            <div className="column-three">
              <BracketBoxLive
                game={East3rd1Picks}
                series={East3rd1Series}
                actualGame={East3rd1Actual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={"round-three-box-one"}
                bracketRightCustom={"bracket-right-side-top"}
                backBoxLine={"back-line"}
              />
            </div>
            <div className="column-final" ref={finalsRef}>
              {/* Render champion's name and logo if champSelected */}

              <>
                <div style={{ borderColor: champColor }} className="champ-box">
                  {userFinalsResult === "Loss" && !isFinalOver ? (
                    <LossPlaceholder />
                  ) : (
                    <img src={champLogo} alt="Champion Logo" />
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: champColor,
                    minHeight: "23px",
                    minWidth: "20px",
                  }}
                  className="champ-name"
                >
                  {isFinalOver ? finalWinnerName : ""}
                </div>
                <div
                  style={{
                    color: bottomTextColor,
                    textDecoration: bottomStrikeout,
                    minHeight: "24px",
                    minWidth: "20px",
                  }}
                  className="champ-result"
                >
                  {userFinalsName}
                </div>
              </>

              <BracketBoxLive
                game={FinalsPicks}
                series={FinalsSeries}
                actualGame={FinalsActual}
                NBATeams={NBATeams}
                NBAStandings={NBAStandings}
                boxLineClass={"bracket-line-bottom"}
                bracketBoxCustom={"round-final"}
                bracketRightCustom={"bracket-right-side-top"}
                backBoxLine={"back-line"}
              />
            </div>
          </div>
          <div className="conference-title-container mobile">
            <h2 className="conference-title mobile">EAST</h2>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log("PROP STATES", state);
  return {
    games: state.games,
    game: state.game,
    user: state.user,
    sportsData: state.sportsData,
    allUsers: state.login.getUsersForLeaderboardReducer.data,
    nbaTeamsData: state.nbaTeamsReducer,
    nbaStandingsData: state.nbaStandingsReducer,
  };
};

export default connect(mapStateToProps)(BracketsLive);
