import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useQuickPickNFLContext } from "./context/QuickPickNFLContext";
import GameRulesPage from "../../GameRulesPage/GameRulesPage";
import GameHomePage from "../../GameHomePage/GameHomePage";
import GameSponsorHeader from "../../PoolHeaderBar/GameSponsorHeader";
import DynamicPoolHeaderBar from "../../PoolHeaderBar/DynamicPoolHeaderBar";
import MyPicks from "../MyPicks/MyPicks";
import QuickPickNav from "../QuickPickNav/QuickPickNav";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import MyParty from "../../MyParty/MyParty";
import SponsorTabOld from "../../SponsorTab/SponsorTabOld";
import QuickPickLeaderboard from "../Leaderboard/QuickPickLeaderboard";
import QuickPickUserDash from "../UserDash/QuickPickUserDash";
import QuickPickPickStats from "../PickStats/QuickPickPickStats";
import QuickPickRewards from "../Rewards/QuickPickRewards";
import RewardsRedeemHeader from "../Rewards/RewardsRedeemHeader";
import TestPropBuilder from "./TestPropBuilder";
import { DateTime } from "luxon";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import TiebreakerGameButtons from "../TiebreakerGameButtons/TiebreakerGameButtons";
import TiebreakerPartyDropdown from "../../PartyDropdown/TiebreakerPartyDropdown";
import ReferralsLeaderboard from "../../ReferralsLeaderboard/ReferralsLeaderboard";
import GameLogoDisplay from "../../GameHomePage/GameHomePageComponents/GameLogoDisplay";
import Top3DisplayNew from "../../ChampionDisplay/Top3DisplayNew";
import GameHomePageNew from "../../GameHomePage/GameHomePageNew";
import { Typography } from "@mui/material";
import CommissionerPage from "../../MembersPage/MembersPageContainer";
import MembersPageContainer from "../../MembersPage/MembersPageContainer";
import RemovedFromGameModal from "../../GameModals/RemovedFromGameModal/RemovedFromGameModal";
import { click } from "@testing-library/user-event/dist/click";
import { set } from "lodash";


const QuickPickNFLContainer = () => {
    const {
        signUpState,
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData,
        interval, 
        setInterval, 
        currentInterval, 
        firstInterval,
        lastInterval,
        userSubgroups, 
        doesUserBelong,
        myUserId,
        myUsername,
        userPicksFromDB,
        pickState,
        setPickState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen,
        isGameOver,
        hasJoinDeadlinePassed,
        clickState,
        setClickState,
        gameName,
        nflStandings,
        gameSchedule,
        nflTeams,
        quickPickNFLGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamePropsToUse,
        selectedTeamPropsToUse,
        opponentTeamPropsToUse,
        leaderboardInfo,
        defaultPartyId,
        defaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
        byeWeek,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        customBackgroundColor,
        customTextColor,
        customBorderColor,
        myPicksMobileImage,
        myPicksDesktopImage,
        amIOptedInToTexts,
        referralPrizes,
        countdownBackground,
        countdownText,
        isTiebreakerGame,
        tiebreakerGamesInfo,
        myTiebreakerGames,
        gameMembersData,
        gamesToInclude, 
        gameReferrals,
        useColorsForHeader,
        isMainGame,
        mainGameInfo,
        matchedGames,
        doIHaveCommissionerAccess,
        gameCommissioners,
        gameAdministrator,
        amIRemoved,
        pinRequired,
        showMembersPage,
        lastSubgroupId,
        lastSubgroupName,
    } = useQuickPickNFLContext();
    //console.log("CONTAINER USER DATA", userData, "has Join Passed", hasJoinDeadlinePassed, "Does User Belong: ", doesUserBelong);
    
    const { component, pageState, optionalState } = useParams();
    //console.log("PAGE AND OPTIONAL STATE", pageState, optionalState);
    const navigateToTop = useNavigateToTop();
    const location = useLocation();

    const [referralId, setReferralId] = useState(null);
    const [newSignUp, setNewSignUp] = useState(false);

    let gameFilters = gameMembersData || [];

    const isPartyInGameFilters = gameFilters?.some(filter => filter.gameId === partyId);

    const gameSubgroups = gameData?.subGroups || [];
    const isPartyInSubgroups = gameSubgroups?.some(filter => filter.groupID === partyId);
  

    useEffect(() => {
      //console.log("Running Referral Use Effect")
      const queryParams = new URLSearchParams(location.search);
      const referralId = queryParams.get('referralId');
      const newSignUp = queryParams.get('signUp');
      if (referralId) {
        setReferralId(referralId);
      }
      if (newSignUp) {
        setNewSignUp(true);
      }
    }, [location.search]);

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const fromJoinCard = queryParams.get('fromJoinCard');
      if (fromJoinCard && ((hasJoinDeadlinePassed && !doesUserBelong) || isGameOver)) {
          navigateToTop(`/`);
      }
  }, [location.search, hasJoinDeadlinePassed, doesUserBelong, isGameOver, navigateToTop]);

    //console.log("Rewards in Container:", rewards);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward => 
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );
    
    //console.log("Any Unredeemed Rewards in Container: ", anyUnredeemedRewards);

    const [leaderboardForRanking, setLeaderboardForRanking] = useState([]);
    const [leaderboardData, setLeaderboardData] = useState(leaderboardInfo);

    const addSubgroupRankings = (leaderboardData, partyId) => {
      // Create a copy of the leaderboard data to avoid mutating the original
      const updatedLeaderboard = leaderboardData?.sort((a, b) => {
          const totalPointsA = a.totalPoints || 0;
          const totalPointsB = b.totalPoints || 0;
          const finalMarginA = a.finalMargin || 5000;
          const finalMarginB = b.finalMargin || 5000;
          
          // First compare by total points
          if (totalPointsB !== totalPointsA) {
              return totalPointsB - totalPointsA;
          }
          // If points are equal, compare by final margin (lower is better)
          return finalMarginA - finalMarginB;
      });
  
      // Keep track of the current rank and how many users share it
      let currentRank = 1;
      let currentPoints = updatedLeaderboard?.[0]?.totalPoints || 0;
      let currentMargin = updatedLeaderboard?.[0]?.finalMargin || 5000;
  
      // Add or update rankings for each user
      return updatedLeaderboard?.map((user, index) => {
          // Create rankings array if it doesn't exist
          if (!user.rankings) {
              user.rankings = [];
          }
  
          // Check if this user has different points/margin than previous user
          if (index > 0) {
              const userPoints = user.totalPoints || 0;
              const userMargin = user.finalMargin || 5000;
              const prevUser = updatedLeaderboard[index - 1];
              const prevPoints = prevUser.totalPoints || 0;
              const prevMargin = prevUser.finalMargin || 5000;
  
              if (userPoints !== prevPoints || userMargin !== prevMargin) {
                  currentRank = index + 1;
                  currentPoints = userPoints;
                  currentMargin = userMargin;
              }
          }
  
          // Find existing ranking for this partyId
          const existingRankingIndex = user.rankings.findIndex(r => r.gameId === partyId);
  
          // Create new ranking object
          const newRanking = {
              gameId: partyId,
              currentRank: currentRank
          };
  
          // Either update existing or add new ranking
          if (existingRankingIndex !== -1) {
              user.rankings[existingRankingIndex] = newRanking;
          } else {
              user.rankings.push(newRanking);
          }
  
          return user;
      });
  };




    useEffect(() => {
      if (leaderboardData && partyId === mainGameId) {
        setLeaderboardData(leaderboardInfo);
      } else if (leaderboardData && partyId !== mainGameId && isPartyInGameFilters) {
        const partyMembers = gameMembersData?.find(filter => filter.gameId === partyId)?.members;
        const partyLeaderboardData = leaderboardInfo?.filter(user => partyMembers?.includes(user.userId));
        setLeaderboardData(partyLeaderboardData);
      } else if (leaderboardData && partyId !== mainGameId && isPartyInSubgroups) {
        const subgroupMembers = gameSubgroups?.find(filter => filter.groupID === partyId)?.groupMembers;
        const subgroupLeaderboardData = leaderboardInfo?.filter(user => subgroupMembers?.includes(user.userId));
        const rankedSubgroupLeaderboardData = addSubgroupRankings(subgroupLeaderboardData, partyId);
        setLeaderboardData(subgroupLeaderboardData);
      }
    }, [leaderboardInfo, partyId, mainGameId, gameMembersData]);

    const lowestRankOnLeaderboardOverall = leaderboardData?.reduce((lowest, data) => {
      // If the current data.rank is lower than the lowest.rank, update the lowest object
      if (data.rank > lowest.rank) {
        return { rank: data.rank, totalPoints: data.totalPoints };
      }
      return lowest;
    }, { rank: 1, totalPoints: 0 }); // Changed initial rank from 0 to 1
    
    
    const howManyUsersWithLowestRankOverall = leaderboardData?.filter(
      data => data.rank === lowestRankOnLeaderboardOverall?.rank
    )?.length || 0;
    
    
    const rankForUsersWithoutRankOverall = !leaderboardData?.length ? 1 : 
      (lowestRankOnLeaderboardOverall?.totalPoints === 0 ? 
        lowestRankOnLeaderboardOverall?.rank : 
        lowestRankOnLeaderboardOverall?.rank + howManyUsersWithLowestRankOverall);
    
    //console.log("Rank for Users Without Rank", rankForUsersWithoutRankOverall, "Lowest Points on Leaderboard", lowestRankOnLeaderboardOverall?.totalPoints);

    //console.log("RANKED DATA in Conttainer", leaderboardForRanking);
    const myRank = leaderboardInfo?.find(user => user.userId === myUserId)?.rank || rankForUsersWithoutRankOverall;
    const amITied = leaderboardInfo?.some(user => user.rank === myRank && user.userId !== myUserId); 
    const totalEntries = leaderboardInfo?.length;
    //console.log("My Rank in Container", myRank, "Am I tied:", amITied, "Total Entries in Container", totalEntries);

    const getRankedUsernames = (users = [], partyId) => {
      // Helper function to get the current rank for a specific partyId
      const getCurrentRank = (rankings = [], partyId) => {
        const ranking = rankings?.find(r => r.gameId === partyId);
        return ranking ? ranking.currentRank : null; // Return the rank if found, otherwise null
      };
    
      // Filter and group users based on their current rank for the given partyId
      const rankedUsernames = {
        first: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 1)
          .map(user => user.username),
        second: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 2)
          .map(user => user.username),
        third: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 3)
          .map(user => user.username),
      };
    
      return rankedUsernames;
    };  
  
    const winners = getRankedUsernames(leaderboardInfo, mainGameId);
    //console.log("Winners Container", winners);

    const whosInFirst = leaderboardData?.[0]?.username || null;
    const whosInFirstCharacterCount = whosInFirst?.length || null;
    const whosInSecond = leaderboardData?.[1]?.username || null;
    const whosInSecondCharacterCount = whosInSecond?.length || null;
    const whosInThird = leaderboardData?.[2]?.username || null;
    const whosInThirdCharacterCount = whosInThird?.length || null;

    const homePageProps = { signUpState, setSignUpState, gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners, referralId, countdownTimer, countdownMessage, oneWeekEvent, timerDateTime: countdownDeadline, fullLeaderboard: leaderboardInfo, referralPrizes, amIOptedInToTexts, countdownBackground, countdownText }
    
    useEffect(() => {
      // Update the clickState whenever the URL changes
      if (component) {
        setClickState(component);
      }
    }, [component, location]);

    const uriEncoded = (link) => {
      return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    const sponsorHeaderInfo = gameData?.sponsorInfo?.sponsorHeaderInfo;
    //console.log("Sponsor Header Logo", sponsorHeaderLogo);
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    const hideSponsorInPoolHall = gameData?.sponsorInfo?.hideSponsorInPoolHall;
    const textOptInMessage = gameData?.gameParameters[0]?.textOptInMessage;
    const useOldHomePage = gameData?.gameParameters[0]?.useOldHomePage;
    const hideMyParty = gameData?.gameParameters[0]?.hideMyParty;
    const hideReferralLink = gameData?.gameParameters[0]?.hideReferralLink;

    const selectedGames = gameSchedule?.filter(game => game.Week === interval);
    const selectedQuickPickGames = quickPickNFLGames?.filter(game => game.Week === interval);
    
    function getVoidedPicksIds(games) {
      const voidedIds = [];
  
      games?.forEach(game => {
          const propCategories = ['tiebreakers', 'gameProps', 'homeProps', 'awayProps'];
  
          propCategories?.forEach(category => {
              if (game?.props[category]) {
                  game?.props[category].forEach(prop => {
                      if (prop?.correctOption === 'Void') {
                          voidedIds.push(prop.id);
                      }
                  });
              }
          });
      });
  
      return voidedIds;
    }
  
    const voidedPicksIds = getVoidedPicksIds(selectedQuickPickGames);
    const voidedPicksCount = voidedPicksIds?.length;
    //console.log("Voided Picks Ids: ", voidedPicksIds, "Length: ", voidedPicksCount);
    
    const [unsavedPicks, setUnsavedPicks] = useState(false);
    //console.log("Unsaved Picks", unsavedPicks);

    useEffect(() => {
      //console.log("Running Use Effect, resetting unsaved Picks");
      if (mainGameId) {
      setUnsavedPicks(false);
      }
    }, [mainGameId]);

   
    const handleClickState = (click) => {
      setClickState(click);
      let pageStateToUse = partyId;
      if (click === 'MY_PICKS') {
        pageStateToUse = 'CURRENT';
        setInterval(currentInterval);
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else if (click === 'HOME') {
        pageStateToUse = 'HOME';
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else if (click === 'MY_PARTY' && userSubgroups?.length === 0) {
        pageStateToUse = 'NO_PARTY';
      } else if (click === 'MY_PARTY' && !isPartyInSubgroups) {
        pageStateToUse = firstSubgroupId;
        setPartyState(firstSubgroupName);
        setPartyId(firstSubgroupId);
      } else if (click === 'MY_PARTY' && isPartyInSubgroups) {
        pageStateToUse = partyId;
      } else if (click === 'REWARDS') {
        pageStateToUse = 'ELIGIBLE';
      } else if (click === 'MEMBERS') {
        pageStateToUse = mainGameId;
      } else if (click === 'PRESENTED_BY') {
        pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation}`;
      }
      navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
    }



  const getLowestRankFromRankings = (leaderboardData, partyId) => {
    if (!leaderboardData?.length) return { rank: 1, totalPoints: 0 };
    
    return leaderboardData.reduce((lowest, data) => {
      const userRanking = data.rankings?.find(r => r.gameId === partyId);
      const currentRank = userRanking?.currentRank;
      
      // Skip if no ranking found
      if (!currentRank) return lowest;
  
      // If this is the first valid rank or if it's lower than current lowest
      if (lowest.rank === 1 || currentRank > lowest.rank) {
        return { 
          rank: currentRank, 
          totalPoints: data.totalPoints 
        };
      }
      return lowest;
    }, { rank: 1, totalPoints: 0 }); // Changed initial value from 0 to 1
  };
  
  const lowestRankOnLeaderboard = getLowestRankFromRankings(leaderboardData, partyId);
  
  
  const howManyUsersWithLowestRank = leaderboardData?.filter(data => {
    const userRanking = data.rankings?.find(r => r.gameId === partyId);
    return userRanking?.currentRank === lowestRankOnLeaderboard?.rank;
  })?.length || 0;
  
  
  const rankForUsersWithoutRank = !leaderboardData?.length ? 1 : 
    (lowestRankOnLeaderboard?.totalPoints === 0 
      ? Math.max(1, lowestRankOnLeaderboard?.rank)
      : lowestRankOnLeaderboard?.rank + howManyUsersWithLowestRank);
  
  //console.log("Rank for Users Without Rank", rankForUsersWithoutRank, "Lowest Points on Leaderboard", lowestRankOnLeaderboard?.totalPoints);
  

  return (
    <div>
      {sponsorLogo && 
          <GameSponsorHeader 
            sponsorLogo={sponsorHeaderLogo} 
            sponsorBannerText={sponsorBannerText} 
            sponsorName={sponsorName}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
            myUserId={myUserId}
            setJoinOpen={setJoinOpen}
            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            unsavedPicks={unsavedPicks}
            setUnsavedPicks={setUnsavedPicks}
            userPicksFromDB={userPicksFromDB}
            setPickState={setPickState}
            sponsorAbbreviation={sponsorAbbreviation}
            sponsorHeaderInfo={sponsorHeaderInfo}
          />
      }

      {anyUnredeemedRewards &&
        <RewardsRedeemHeader
          gameAddress={gameAddress}
          mainGameId={mainGameId}
        />
      }

      <DynamicPoolHeaderBar
        clickState={clickState}
        navigationText={`Home`}
        headerText={gameName}
        gameAddress={gameAddress}
        gameId={mainGameId}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        setPicksToEmpty={false}
        backgroundColor={customBorderColor}
        bottomLineColor={customTextColor}
        useColorsForHeader={useColorsForHeader}
      />

      {!doesUserBelong && !useOldHomePage && (
        <GameLogoDisplay
          doesUserBelong={doesUserBelong}
          gameName={gameName}
          logo={logo}
          gameLogoDisplayInfo={gameData?.gameLogoDisplayInfo}
          setJoinOpen={setJoinOpen}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          affiliatedGamesInfo={gameData?.gameParameters[0]?.affiliatedGamesInfo}
          gameAddress={gameAddress}
          isTeamGame={gameData?.gameParameters[0]?.isTeamGame}
        />
      )}

      {myUsername && doesUserBelong &&
        <QuickPickUserDash
          currentInterval={currentInterval}
          userPicksFromDB={userPicksFromDB}
          doesUserBelong={doesUserBelong}
          myUsername={myUsername}
          gameData={gameData}
          setJoinOpen={setJoinOpen}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          anyUnredeemedRewards={anyUnredeemedRewards}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          formattedGameStartTime={formattedGameStartTime}
          myRank={myRank}
          amITied={amITied}
          totalEntries={totalEntries}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          isGameOver={isGameOver}
          myUserId={myUserId}
          gameRewards={gameRewards}
          hideReferralLink={hideReferralLink}
        />
      }

      <QuickPickNav
        setClickState={handleClickState}
        clickState={clickState}
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isGameOver={isGameOver}
        userSubgroups={userSubgroups}
        sponsorAbbreviation={sponsorAbbreviation}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        gameRewards={gameRewards}
        doIHaveCommissionerAccess={doIHaveCommissionerAccess}
        isMainGame={isMainGame}
        showMembersPage={showMembersPage}
        hideMyParty={hideMyParty}
      />

      {isTiebreakerGame && tiebreakerGamesInfo?.length > 0 && myTiebreakerGames?.length > 0 && (
        <TiebreakerGameButtons
          myTiebreakerGames={myTiebreakerGames}
          isTiebreakerGame={isTiebreakerGame}
        />
      )}

      {mainGameInfo && mainGameInfo?.length > 0 &&
          <TiebreakerGameButtons
              myTiebreakerGames={mainGameInfo}
              gameName={gameName}
              mainGame={true}
              matchedGames={matchedGames}
          />
      }

      {((clickState === 'LEADERBOARD' || clickState === 'POOL_PICKS') && (myTiebreakerGames?.length > 0 || userSubgroups?.length > 0)) ||
        (clickState === 'MY_PARTY' && userSubgroups?.length > 0) ? (
          <TiebreakerPartyDropdown
            mainGameId={mainGameId}
            userSubgroups={userSubgroups}
            gameName={gameName}
            myTiebreakerGames={myTiebreakerGames}
            partyId={partyId}
            setPartyId={setPartyId}
            partyState={partyState}
            setPartyState={setPartyState}
            gameAddress={gameAddress}
            clickState={clickState}
          />
      ) : null}

      {isGameOver && !useOldHomePage && clickState === 'HOME' && (
        <Top3DisplayNew
          rankedUsernames={winners}
          partyName={sponsorName}
          line2={`${gameName} Top 3`}
          gameName={gameName}
        />
      )}

      {clickState === 'HOME' && useOldHomePage && <GameHomePage homePageProps={homePageProps} />}

      {clickState === 'HOME' && !useOldHomePage && <GameHomePageNew homePageProps={homePageProps} /> }
        
      {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

      {clickState === 'PRESENTED_BY' && <SponsorTabOld gameData={gameData} />}

      {clickState === 'REFERRAL_LEADERBOARD' &&
        <ReferralsLeaderboard
          gameReferrals={gameReferrals}
          myUsername={myUsername}
          referralPrizes={referralPrizes}
          gameAddress={gameAddress}
          mainGameId={mainGameId}
          myUserId={myUserId}
        />
      }

      {clickState === 'MEMBERS' && 
        <MembersPageContainer
          gameData={gameData}
          myUserId={myUserId}
          gameCommissioners={gameCommissioners}
          gameAdministrator={gameAdministrator}
          leaderboardInfo={leaderboardInfo}
          mainGameId={mainGameId}
          gameName={gameName}
          isGameOver={isGameOver}
          doIHaveCommissionerAccess={doIHaveCommissionerAccess}
          gameAddress={gameAddress}
        />
      }

      {clickState === 'POOL_PICKS' &&
        <QuickPickPickStats
          leaderboardInfo={leaderboardData}
          teams={nflTeams}
          schedule={gameSchedule}
          quickPickGames={quickPickNFLGames}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          currentInterval={currentInterval}
          intervalType={intervalType}
          propIdsToUse={propIdsToUse}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          myUserId={myUserId}
          oneWeekEvent={oneWeekEvent}
          byeWeek={byeWeek}
          userPicksFromDB={userPicksFromDB}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />    
      }

      {clickState === 'LEADERBOARD' &&
        <QuickPickLeaderboard
          mainGameId={mainGameId}
          userPicksFromDB={userPicksFromDB}
          myUserId={myUserId}
          isGameOver={isGameOver}
          currentInterval={currentInterval}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          intervalType={intervalType}
          handleClickState={handleClickState}
          leaderboardData={leaderboardData}
          partyState={partyState}
          teams={nflTeams}
          schedule={gameSchedule}
          gameAddress={gameAddress}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          oneWeekEvent={oneWeekEvent}
          tiebreakerInfo={tiebreakerInfo}
          gameName={gameName}
          quickPickGames={quickPickNFLGames}
          byeWeek={byeWeek}
          propIdsToUse={propIdsToUse}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />
      }

      {clickState === 'MY_PARTY' && 
        <MyParty
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={leaderboardData}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          isGameOver={isGameOver}
        />
      }

      {clickState === 'REWARDS' &&
        <QuickPickRewards
          rewards={rewards}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          sponsorHeaderLogo={sponsorHeaderLogo}
          sponsorName={sponsorName}
          intervalType={intervalType}
          schedule={gameSchedule}
          myUserId={myUserId} 
        />
      }

      {clickState === 'MY_PICKS' && 
        <MyPicks
          myUserId={myUserId}
          gameAddress={gameAddress} 
          mainGameId={mainGameId} 
          gameData={gameData} 
          userData={userData} 
          interval={interval} 
          setInterval={setInterval} 
          firstInterval={firstInterval} 
          lastInterval={lastInterval} 
          currentInterval={currentInterval}
          schedule={gameSchedule}
          teams={nflTeams}
          standings={nflStandings}
          quickPickInfo={quickPickNFLGames}
          gatherGameInfo={gatherGameInfo}
          selectedGames={selectedGames}
          currentPropIds={currentPropIds}
          selectedQuickPickGames={selectedQuickPickGames}
          userPicksFromDB={userPicksFromDB}
          pickState={pickState}
          setPickState={setPickState}
          teamsToInclude={teamsToInclude}
          gamePropsToUse={gamePropsToUse}
          selectedTeamPropsToUse={selectedTeamPropsToUse}
          opponentTeamPropsToUse={opponentTeamPropsToUse}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          league={league}
          gameType={gameType}
          intervalType={intervalType}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          rewards={rewards}
          joinReward={joinReward}
          gameRewards={gameRewards}
          unsavedPicks={unsavedPicks}
          setUnsavedPicks={setUnsavedPicks}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          customBackgroundColor={customBackgroundColor}
          customTextColor={customTextColor}
          customBorderColor={customBorderColor}
          myPicksMobileImage={myPicksMobileImage}
          myPicksDesktopImage={myPicksDesktopImage}
          matchedGames={matchedGames}
        />
      }

      {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
            sponsorSecondaryLogo={sponsorSecondaryLogo}
            sponsorName={sponsorName}
            sponsorLogo={sponsorLogo}
            mainGameId={mainGameId}
            propIdsToUse={propIdsToUse}
            joinModalText={joinModalText}
            referralId={referralId}
            newSignUp={newSignUp}
            hideSponsorInPoolHall={hideSponsorInPoolHall}
            textOptInMessage={textOptInMessage}
            matchedGames={matchedGames}
            isMainGame={isMainGame}
            mainGameInfo={mainGameInfo}
            pinRequired={pinRequired}
        />
      )}

      {amIRemoved && (
        <RemovedFromGameModal
          open={amIRemoved}
          gameName={gameName}
          gameAddress={gameAddress}
          mainGameId={mainGameId}
          myUserId={myUserId}
        />
      )}
  
    </div>
  );
};

export default QuickPickNFLContainer;