import React from 'react';
import { Box, Typography } from '@mui/material';

import poolPartyGamingImage from '../assets/images/pool-party-gaming.svg';

export default function JoinCard({ children, heading, description }) {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '650px',
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: '90px auto 40px auto',
      }}
    >
      <img className='m-auto' src={poolPartyGamingImage} alt='pool party gaming' />
      {heading && (
        <Typography
          variant='h2'
          sx={{
            color: 'var(--color-black)',
            fontFamily: 'Inter',
            fontSize: '45.78px',
            fontStyle: 'normal',
            fontWeight: '900',
            lineHeight: '150%',
            letterSpacing: '-0.87px',
            textAlign: 'center',
          }}
        >
          {heading}
        </Typography>
      )}
      {description && (
        <Typography
          variant='body1'
          sx={{
            color: 'var(--color-text)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '170%',
            letterSpacing: '-0.266px',
          }}
        >
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
}
