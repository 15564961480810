import React from 'react';
import { Box, Typography } from '@mui/material'; // Import Box and Typography from Material-UI
import './GameHomePageComponents.scss'; // Import the SCSS file

const HomePageTagLine = ({ homePageInfo}) => {
  return (
    <Box className="home-page-tagline-container">
      {homePageInfo?.tagLine && (
        <Typography className="home-page-tagline-text">
          {homePageInfo.tagLine}
        </Typography>
      )}

      <Box className="home-page-tagline-body">
        {homePageInfo?.body?.map((section, index) => (
          <Box 
            key={index} 
            className="home-page-tagline-section"
          >
            <Typography className="home-page-tagline-section-text">
              <span className="home-page-tagline-section-header" sx={{ textAlign: section?.text ? 'left' : 'center'}}>
                {section?.header}
              </span>
              {section?.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default HomePageTagLine;
