import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useGetTiersData } from "../../../hooks/data/useGetTiersData";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DateTime } from 'luxon';

const TiersGameContext = createContext();

export const useTiersGameContext = () => {
    const context = useContext(TiersGameContext);
    if (!context) {
        throw new Error("useTiersGameContext must be used within a TiersGameProvider");
    }
    return context;
}

export const TiersGameProvider = ({ children }) => {
    const {
        userData,
        gameData,
        masterPoolData,
        pickState,
        intervalInfo,
        hasJoinDeadlinePassed,
        leaderboardData,
        setInterval,
    } = useUserAndGameContext();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // Extract interval from URL params using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const intervalParam = queryParams.get('interval');

    const redisKeys = masterPoolData?.redisKeys;

    const [hasDataFetched, setHasDataFetched] = useState(false);

    const { mutate: getTiersData, data: tiersGameData, isLoading: tiersGameDataLoading, isError: tiersGameDataError, error: tiersGameDataErrorData } = useGetTiersData();

    // UseEffect to get tiers data
    useEffect(() => {
        if (!hasDataFetched && redisKeys) {
            getTiersData({ redisKeys });
            setHasDataFetched(true);
        }
    }, [hasDataFetched, redisKeys, getTiersData]);

    //Error handling for tiers data
    useEffect(() => {
        if (tiersGameDataError) {
            console.error("Error fetching tiers data:", tiersGameDataErrorData);
            if (tiersGameDataErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                navigate('/login');
            } else {
                navigate('/404/pool-hall');
            }
        }
    }, [tiersGameDataError, tiersGameDataErrorData, dispatch, navigate]);

    const { tiersData, theCurrentInterval } = useMemo(() => 
        tiersGameData || {}, 
        [tiersGameData]
    );
    console.log("Tiers Data", tiersData, "The Current Interval", theCurrentInterval);
    const firstInterval = masterPoolData?.gameParameters?.firstInterval;
    const lastInterval = masterPoolData?.gameParameters?.lastInterval;
    const oneIntervalEvent = useMemo(() => firstInterval === lastInterval, [firstInterval, lastInterval]);
    console.log("First Interval", firstInterval, "Last Interval", lastInterval, "One Interval Event", oneIntervalEvent);


    // Memoize the validity check for the URL interval parameter
    const isValidIntervalParam = useMemo(() => {
        if (!intervalParam) return false;
        
        const parsedInterval = parseInt(intervalParam, 10);
        return !isNaN(parsedInterval) && 
            parsedInterval >= firstInterval && 
            parsedInterval <= lastInterval;
    }, [intervalParam, firstInterval, lastInterval]);

    // Memoize the actual interval to use
    const effectiveInterval = useMemo(() => {
        // First priority: valid URL parameter
        if (isValidIntervalParam) {
            return parseInt(intervalParam, 10);
        }
        
        // Second priority: current interval from API, but constrained to valid range
        if (theCurrentInterval !== null && theCurrentInterval !== undefined) {
            // If current interval is before first interval, use first interval
            if (theCurrentInterval < firstInterval) {
                return firstInterval;
            }
            // If current interval is after last interval, use last interval
            if (theCurrentInterval > lastInterval) {
                return lastInterval;
            }
            // Otherwise use the current interval (it's within valid range)
            return theCurrentInterval;
        }
        
        // Fallback: use first interval
        return firstInterval;
    }, [isValidIntervalParam, intervalParam, theCurrentInterval, firstInterval, lastInterval]);
    
    // Use Effect to set the interval using the memoized effectiveInterval
    useEffect(() => {
        if (effectiveInterval !== null && effectiveInterval !== undefined) {
            console.log("Setting interval to:", effectiveInterval);
            setInterval(effectiveInterval);
        }
    }, [effectiveInterval, setInterval]);

    let intervalOptions = useMemo(() => {
        let options = [];
        if (!oneIntervalEvent && theCurrentInterval > firstInterval) {
            for (let i = firstInterval; i <= theCurrentInterval; i++) {
                tiersData?.forEach((tier) => {
                    if (tier.interval === i) {
                        options.push({
                            interval: tier.interval,
                            name: tier.name
                        });
                    }
                });          
            }
        }
        return options;
    }, [tiersData, theCurrentInterval, firstInterval, oneIntervalEvent]);

    console.log("Interval Options", intervalOptions);

    const TiersGameContextValue = useMemo(() => ({
        tiersData,
        theCurrentInterval,
        intervalOptions,
        oneIntervalEvent,
        firstInterval,
        lastInterval,
    }), [tiersData, theCurrentInterval, intervalOptions, oneIntervalEvent, firstInterval, lastInterval]);

    if (tiersGameDataLoading || !tiersData) {
        return <LoadingScreen />;
    }

    return (
        <TiersGameContext.Provider value={TiersGameContextValue}>
            {children}
        </TiersGameContext.Provider>
    );
}

export default TiersGameContext;