import React from 'react';
import { useCommunityHubContext } from './context/CommunityHubContext';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import CommunityHubNavBar from '../NavigationBars/CommunityHubNavBar/CommunityHubNavBar';
import SponsorTabOld from '../SponsorTab/SponsorTabOld';
import CommunityOurGames from './OurGames/CommunityOurGames';
import StaticPoolHeaderBar from '../PoolHeaderBar/StaticPoolHeaderBar';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const CommunityHubContainer = () => {
    const { 
        communityHubData,
        page, 
    } = useCommunityHubContext();
    //console.log("Community Hub Data in CommunityHubContainer: ", communityHubData);
    const logo = communityHubData?.logo;
    const headerText = communityHubData?.headerText;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();

    const handleHeaderClick = () => {
        // If already on sponsor page, do nothing
        if (page === 'sponsor') return;

        // Create a new URLSearchParams object from the current query string
        const searchParams = new URLSearchParams(location.search);
        
        // Set the page parameter to 'sponsor'
        searchParams.set('page', 'sponsor');
        
        // Navigate to the same path but with updated query parameters
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    // Show loading screen if logo is not available yet
    if (!logo) {
        return <LoadingScreen />;
    }

    return (
        <>
            <StaticPoolHeaderBar 
                navigationText={"Home"} 
                headerText={communityHubData?.headerBarText} 
                linkForNavigation="/" 
            />
            
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    margin: '0 auto',
                    padding: '16px 0',
                }}
            >
                <Box 
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',   
                        height: 'auto', 
                        margin: '0 auto', 
                    }}
                >
                    <img
                        src={logo}
                        alt="Community Hub Logo"
                        className="community-hub-logo"
                        style={{
                            maxHeight: isMobile ? '85px' : '150px',
                            display: 'block',
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        color: '#00AAD1',
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        marginTop: '16px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    onClick={handleHeaderClick}
                >
                    {headerText}
                </Typography>
            </Box>

            <CommunityHubNavBar />

            {page === 'our-games' && (
                <>
                    <CommunityOurGames />
                    <Box sx={{height: '64px'}} />
                </>
            )}

            {page === 'sponsor' && (
                <SponsorTabOld gameData={communityHubData}/>
            )}
        </>
    );
};

export default CommunityHubContainer;