import React, { useState, useEffect, useMemo } from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useSurvivorGamesContext } from "../context/SurvivorGamesContext";
import MyPicksNavBar from "../../NavigationBars/MyPicksNavigation/MyPicksNavBar";
import { Typography, Box, Grid } from "@mui/material";
import { DateTime } from "luxon";
import { useSearchParams } from 'react-router-dom';
import StaticDateDisplay from "../../GamePageComponents/DeadlineDisplays/StaticDateDisplay";
import SurvivorMyPickDisplay from "./SurvivorMyPicksDisplay";
import GameTimeVsSpreadSort from "../../GamePageComponents/PageSorts/GameTimeVsSpreadSort";
import SurvivorPicksBar from "./SurvivorPicksBar";
import SurvivorGameCard from "./SurvivorGameCard";
import { useSaveSurvivorPicks } from "../../../hooks/pools/useSaveSurvivorPicks";
import './SurvivorMyPicksPage.scss';
import ResponseModal from "../../GameModals/ResponseModal/ResponseModal";
import SurvivorSubmittedPickModal from "../../GameModals/SurvivorModals/SurvivorSubmittedPickModal";
import NoPickBox from "./NoPickBox";
import DuplicatePickModal from "../../GameModals/SurvivorModals/DuplicatePickModal";

const SurvivorMyPicksPage = () => {
    const {
        teams,
        schedule,
        isEntryEliminated,
        eliminationInterval,
        theCurrentInterval,
    } = useSurvivorGamesContext();

    const {
        intervalInfo,
        masterPoolData,
        pickState,
        setPickState,
        gameId,
        masterPoolId,
        league,
        myUserId,
        pickIndex,
    } = useUserAndGameContext();
    const { mutate: saveSurvivorPicks } = useSaveSurvivorPicks();

    const [filteredSchedule, setFilteredSchedule] = useState(schedule);
    const [hasSubmittedPick, setHasSubmittedPick] = useState(false);
    // const [removePreviousPickModalOpen, setRemovePreviousPickModalOpen] = useState(false);
    // const [removePickData, setRemovePickData] = useState({});
    // Error modal state
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseModalData, setResponseModalData] = useState({
        status: '',
        message: ''
    });
    // Success modal state
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [successData, setSuccessData] = useState({
        action: '',
        pick: null,
        intervalName: '',
        intervalDeadline: ''
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const interval = searchParams.get('interval');
    const sortBy = searchParams.get('sort') || "Game Time"; // Default to Game Time if not in URL

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const handleModalClose = () => {
        setResponseModalOpen(false);
    };

    const handleSortChange = (event) => {
        const newSort = event.target.value;
        // Preserve existing params while updating sort
        setSearchParams(params => {
            const newParams = new URLSearchParams(params);
            newParams.set('sort', newSort);
            return newParams;
        });
    };

    //console.log("Teams and Schedule My Picks Page: ", teams, schedule);

    // Memoize this week's pick(s) from PickState, where interval matches the current interval
    const thisIntervalsPicks = useMemo(() => {
        if (!pickState || !interval) return null;

        const currentInterval = parseInt(interval);
        if (isNaN(currentInterval)) return null;

        return pickState?.filter(pick => pick.interval === currentInterval);
    }, [pickState, interval, gameId, masterPoolId]);
    //console.log("This Interval's Picks:", thisIntervalsPicks);
    
    // Get info about the Pick Deadline
    const deadlineInfo = masterPoolData?.myPicksInfo?.deadline;
    const noNoonGames = masterPoolData?.gameParameters?.noNoonGames;
    //console.log("Deadline Info:", deadlineInfo, "No Noon Games:", noNoonGames);

    const intervalType = intervalInfo?.type;
    //console.log("Current interval:", interval, "Interval Type:", intervalType);

    // Get the user's selected game
    const usersSelectedGame = useMemo(() => {
        if (!schedule || !thisIntervalsPicks) return null;
        
        return schedule.find(game => {
            return thisIntervalsPicks.some(pick => pick.gameId === game.GlobalGameID);
        });
    }, [schedule, thisIntervalsPicks]);
    //console.log("User's Selected Game:", usersSelectedGame);

    const thisIntervalsGames = useMemo(() => {
        if (!schedule || !interval) return null;

        const targetInterval = parseInt(interval);
        if (isNaN(targetInterval)) return null;

        const games = schedule?.filter(game => {
            if (intervalType === 'date') {
                return game.Day === intervalInfo.info.find(item => item.interval === targetInterval)?.date;
            } else if (intervalType === 'weeks') {
                return game.Week === targetInterval;
            }
        });

        return games;
    }, [schedule, interval, intervalInfo]);
    //console.log("This Interval's Games:", thisIntervalsGames);




    // Filter the schedule based on the interval
    // If the interval type is date, find the matching interval in intervalInfo.info and use its date
    // If the interval type is week, use the interval directly to match the Week
    // Also can sort by Game Time or Largest Spread
    // AND filter out the user's selected game
    useEffect(() => {
        if (!schedule || !interval || !intervalInfo) return;

        let filtered = [];

        if (intervalInfo.type === 'date') {
            const targetInterval = parseInt(interval);
            if (isNaN(targetInterval)) return;

            const dateInfo = intervalInfo.info.find(item => item.interval === targetInterval);
            if (!dateInfo) return;
            
            filtered = schedule.filter(game => game.Day === dateInfo.date && game.GlobalHomeTeamID !== null && game.GlobalAwayTeamID !== null);
        } else if (intervalInfo.type === 'weeks') {
            const weekNumber = parseInt(interval);
            if (isNaN(weekNumber)) return;

            filtered = schedule.filter(game => game.Week === weekNumber);
        }

        // Filter out the user's selected game
        if (usersSelectedGame) {
            filtered = filtered.filter(game => game.GlobalGameID !== usersSelectedGame.GlobalGameID);
        }

        // Apply sorting
        const sortedGames = [...filtered].sort((a, b) => {
            if (sortBy === "Game Time") {
                return DateTime.fromISO(a.DateTime) - DateTime.fromISO(b.DateTime);
            } else if (sortBy === "Largest Spread") {
                return Math.abs(b.PointSpread) - Math.abs(a.PointSpread);
            }
            return 0;
        });

        setFilteredSchedule(sortedGames);
    }, [schedule, interval, intervalInfo, sortBy, gameId, masterPoolId, usersSelectedGame]);
    //console.log("Filtered Schedule:", filteredSchedule);

    const intervalDeadline = useMemo(() => {
        const thisIntervalsInfo = intervalInfo?.info.find(item => item.interval === Number(interval));
        const thisIntervalsDeadline = thisIntervalsInfo?.deadline || null;
        if (thisIntervalsDeadline === "TBD") {
            const thisIntervalsDate = thisIntervalsInfo?.date;
            const date = DateTime.fromISO(thisIntervalsDate, { zone: 'America/New_York' });
            return `${date.toFormat("EEE, MMM d")} @ First Tip-off`;
        } else {
            return thisIntervalsInfo?.deadline || null;
        }
    }, [interval, intervalInfo]);
    //console.log("Testing Deadline:", intervalDeadline);

    const theCurrentIntervalsDeadline = useMemo(() => {
        const currentIntervalsInfo = intervalInfo?.info.find(item => item.interval === Number(theCurrentInterval));
        const currentIntervalsDeadline = currentIntervalsInfo?.deadline || null;
        return currentIntervalsDeadline;
    }, [theCurrentInterval, intervalInfo]);

    //console.log("Current Interval Deadline:", theCurrentIntervalsDeadline);

    const hasTheCurrentIntervalsDeadlinePassed = theCurrentIntervalsDeadline 
    ? DateTime.fromISO(theCurrentIntervalsDeadline) < timeNowInNewYork 
    : false;
    //console.log("The Current Interval's Deadline Passed:", hasTheCurrentIntervalsDeadlinePassed, theCurrentIntervalsDeadline);

    // Memoize the interval deadline since it only needs to recalculate when
    // filtered schedule, deadline info, or interval changes
    // const intervalDeadline = useMemo(() => {
    //     if (!deadlineInfo) return null;

    //     const deadlineType = deadlineInfo?.type;
    //     let targetInterval = null;
        
    //     // Use the complete schedule for finding the earliest game, not filteredSchedule
    //     // This ensures we still get the correct deadline even if selected game is earliest
    //     if (deadlineType === 'earliest' && schedule?.length > 0) {
    //         // Get all games for this interval
    //         let scheduleForInterval = [];
    //         if (intervalInfo.type === 'date') {
    //             targetInterval = parseInt(interval);
    //             if (isNaN(targetInterval)) return null;
                
    //             const dateInfo = intervalInfo.info.find(item => item.interval === targetInterval);
    //             if (!dateInfo) return null;
                
    //             scheduleForInterval = schedule.filter(game => game.Day === dateInfo.date && game.GlobalHomeTeamID !== null && game.GlobalAwayTeamID !== null);
    //         } else if (intervalInfo.type === 'weeks') {
    //             const weekNumber = parseInt(interval);
    //             if (isNaN(weekNumber)) return null;
    
    //             scheduleForInterval = schedule.filter(game => game.Week === weekNumber);
    //         }
            
    //         // Sort by DateTime and return the earliest
    //         const earliestGame = [...scheduleForInterval].sort((a, b) => {
    //             return DateTime.fromISO(a.DateTime) - DateTime.fromISO(b.DateTime);
    //         })[0];

    //         if (earliestGame) {
    //             return earliestGame?.DateTime;
    //         } else {
    //             const dateInfo = intervalInfo.info.find(item => item.interval === targetInterval);
    //             const date = DateTime.fromISO(dateInfo?.date, { zone: 'America/New_York' });

    //             return `${date.toFormat("EEE, MMM d")} @ First Tip-off`;
    //         }
    //     } 
        
    //     if (deadlineType === 'set' && deadlineInfo?.deadlines) {
    //         // Use the set deadline from deadlineInfo where the interval matches
    //         const currentInterval = parseInt(interval);
    //         if (isNaN(currentInterval)) return null;
            
    //         const deadline = deadlineInfo.deadlines.find(item => item.interval === currentInterval);
    //         return deadline?.dateTime || null;
    //     }
        
    //     return null;
    // }, [schedule, deadlineInfo, interval, intervalInfo, gameId, masterPoolId]);
    //console.log("Current interval deadline:", intervalDeadline);

    const hasThisIntervalsDeadlinePassed = intervalDeadline 
    ? DateTime.fromISO(intervalDeadline) < timeNowInNewYork 
    : false;
    //console.log("This Interval's Deadline Passed:", hasThisIntervalsDeadlinePassed, intervalDeadline);

    // If there's a usersSelectedGame, determine if it has started
    // If hasThisIntervalsDeadlinePassed is true, return true and don't check the game
    // if hasThisIntervalsDeadlinePassed is false, check the game to see if it started
    const hasUsersGameStarted = useMemo(() => {
        if (hasThisIntervalsDeadlinePassed) return true;
        if (!usersSelectedGame) return false;

        const gameDateTime = DateTime.fromISO(usersSelectedGame.DateTime, { zone: 'America/New_York' });
        return timeNowInNewYork > gameDateTime;
    }, [usersSelectedGame, hasThisIntervalsDeadlinePassed, timeNowInNewYork]);
    //console.log("Has User's Game Started:", hasUsersGameStarted);


    const handleSurvivorSubmit = async (teamId, gameId, interval, hasGameStarted, action) => {
        // console.log("Team ID: ", teamId, "Game ID: ", gameId, "Interval: ", interval, "Has Game Started: ", hasGameStarted, "Action: ", action, "Has Pick Already Been Selected: ", hasPickAlreadyBeenSelected);
        // if (hasPickAlreadyBeenSelected && !removePreviousPickModalOpen) {
        //     console.log("TEAM HAS BEEN SELECTED ALREADY", hasPickAlreadyBeenSelected);
        //     setRemovePreviousPickModalOpen(true);
        //     setRemovePickData({teamId, gameId, interval, hasGameStarted, action, hasPickAlreadyBeenSelected});
        //     return;
        // } else {
        //     console.log("TEAM HAS NOT BEEN SELECTED ALREADY");
        // }
        setHasSubmittedPick(true);
        // If user can't make a pick, return
        if (isEntryEliminated || hasGameStarted || hasThisIntervalsDeadlinePassed || hasUsersGameStarted || hasSubmittedPick) {
            //console.log("Can't make a pick, isEntryEliminated: ", isEntryEliminated, "hasGameStarted: ", hasGameStarted, "hasThisIntervalsDeadlinePassed: ", hasThisIntervalsDeadlinePassed, "hasUsersGameStarted: ", hasUsersGameStarted);
            return;
        }
        //console.log("Team ID: ", teamId, "Game ID: ", gameId, "Interval: ", interval);
        // Compile picks data
        const pickToAdd = {
            teamId,
            gameId,
            interval: Number(interval),
        }

        const data = {
            myUserId,
            masterPoolId,
            pickIndex: Number(pickIndex),
            gameType: 'survivor',
        };

        if (action !== 'remove') {
            data.pickToAdd = pickToAdd;
        }

        // Check pick state to see if there's already a pick this interval
        const existingPick = pickState?.find(pick => pick?.interval === Number(interval));

        if (existingPick) {
            // If there is a pick, update it
            data.pickToRemove = existingPick;
        }

        // if (hasPickAlreadyBeenSelected) {
        //     console.log("Has Pick Already Been Selected");
        //     // Add it to the data.pickToRemove array if there is one, otherwise create it
        //     if (data.pickToRemove) {
        //         data.pickToRemove.push(hasPickAlreadyBeenSelected);
        //     } else {
        //         data.pickToRemove = [hasPickAlreadyBeenSelected];
        //     }
        // }

        //console.log("Data: ", data);

        try {
            saveSurvivorPicks(data, {
                onSuccess: async (response) => {
                    //console.log("Success Response: ", response);
                    setSuccessData(response);
                    setSubmitModalOpen(true);
                },
                onError: (error) => {
                    console.log("Error Response: ", error);
                    
                    // Extract error data from the error response
                    const errorData = error?.response?.data || { 
                        status: 'error', 
                        message: 'An unexpected error occurred. Please try again.' 
                    };
                    
                    // Set modal data and open it
                    setResponseModalData({
                        status: 'error',
                        message: errorData.message
                    });
                    setResponseModalOpen(true);
                }
            });
        } catch (error) {
            console.log("Catch Error: ", error);
            // Handle any errors that might occur outside the mutation
            setResponseModalData({
                status: 'error',
                message: 'An unexpected error occurred. Please try again.'
            });
            setResponseModalOpen(true);
        }
    };

    const selectedIntervalName = useMemo(() => {
        if (!intervalInfo) return null;

        const targetInterval = Number(interval);
        if (isNaN(targetInterval)) return null;

        if (intervalInfo.type === 'date') {
            const dateInfo = intervalInfo.info.find(item => item.interval === Number(targetInterval));
            return dateInfo?.label || null;
        } else if (intervalInfo.type === 'weeks') {
            return `Week ${targetInterval}`;
        }

        return null;
    }, [interval, intervalInfo]);
    //console.log("Selected Interval Name:", selectedIntervalName);
    

    return (
        <>
            <SurvivorPicksBar 
                interval={interval}
            />

            <MyPicksNavBar
                theCurrentInterval={theCurrentInterval}
            />

            <StaticDateDisplay
                date={intervalDeadline}
                deadlineInfo={deadlineInfo}
            />

            {!usersSelectedGame && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        color: '#CC293C',
                        textAlign: 'center',
                        justifyContent: 'center',
                        padding: '16px',
                        borderBottom: '2px solid #002129',
                    }}
                >
                    Select one team to win outright. <br/>
                    Spreads are for reference only.
                </Typography>
            )}
            
            {(filteredSchedule?.length > 0 || usersSelectedGame ) && (
                <>
                    <SurvivorMyPickDisplay
                        interval={interval}
                        thisIntervalsPicks={thisIntervalsPicks}
                        isEntryEliminated={isEntryEliminated}
                        eliminationInterval={eliminationInterval}
                        hasThisIntervalsDeadlinePassed={hasThisIntervalsDeadlinePassed}
                    />

                    {thisIntervalsPicks && thisIntervalsPicks[0]?.teamId === 'No Pick' && (
                        <NoPickBox />
                    )}

                    {usersSelectedGame && (
                        <>
                            <SurvivorGameCard
                                key={usersSelectedGame.GlobalGameID}
                                thisGamesId={usersSelectedGame.GlobalGameID}
                                game={usersSelectedGame}
                                interval={interval}
                                theCurrentInterval={theCurrentInterval}
                                teams={teams}
                                league={league}
                                isEntryEliminated={isEntryEliminated}
                                eliminationInterval={eliminationInterval}
                                pickState={pickState}
                                hasThisIntervalsDeadlinePassed={hasThisIntervalsDeadlinePassed}
                                timeNowInNewYork={timeNowInNewYork}
                                intervalInfo={intervalInfo}
                                hasUsersGameStarted={hasUsersGameStarted}
                                myUserId={myUserId}
                                pickIndex={pickIndex}
                                masterPoolId={masterPoolId}
                                handleSurvivorSubmit={handleSurvivorSubmit}
                                noNoonGames={noNoonGames}
                                hasTheCurrentIntervalsDeadlinePassed={hasTheCurrentIntervalsDeadlinePassed}
                            />
                            {(!hasThisIntervalsDeadlinePassed && !hasUsersGameStarted) && (
                                <Typography 
                                    className='survivor-my-picks-page-remove-text'
                                    onClick={() => handleSurvivorSubmit(null, null, interval, hasUsersGameStarted, 'remove')}
                                    >
                                    Remove Pick
                                </Typography>
                            )}
                            {thisIntervalsGames?.length === 1 && (
                                <Box sx={{ height: '96px' }} />
                            )}
                        </>
                    )}

                    {filteredSchedule?.length > 1 && (
                        <GameTimeVsSpreadSort
                            sortBy={sortBy}
                            handleSortChange={handleSortChange}
                        />
                    )}
                </>
            )}

            {filteredSchedule?.length === 0 && ((usersSelectedGame && thisIntervalsGames?.length > 1) || !usersSelectedGame) && (
                <Typography 
                    variant="h6"
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        textAlign: 'center',
                        width: '80%',
                        maxWidth: '500px',
                        margin: '0 auto',
                        padding: '32px 0px'
                    }}
                >
                    {selectedIntervalName} games will be displayed as the matchups are finalized.  Check back as the tournament progresses!
                </Typography>
            )}

<Box
    sx={{
        maxWidth: '975px',
        margin: '0 auto',
        padding: '0 16px',
    }}
>
    {/* If there's an even number of games, display them in a regular grid */}
    {filteredSchedule.length % 2 === 0 ? (
        <Grid container spacing={1}>
            {filteredSchedule.map((game) => (
                <Grid item xs={12} sm={12} md={6} key={game.GlobalGameID}>
                    <SurvivorGameCard
                        thisGamesId={game.GlobalGameID}
                        game={game}
                        interval={interval}
                        theCurrentInterval={theCurrentInterval}
                        teams={teams}
                        league={league}
                        isEntryEliminated={isEntryEliminated}
                        eliminationInterval={eliminationInterval}
                        pickState={pickState}
                        hasThisIntervalsDeadlinePassed={hasThisIntervalsDeadlinePassed}
                        timeNowInNewYork={timeNowInNewYork}
                        intervalInfo={intervalInfo}
                        hasUsersGameStarted={hasUsersGameStarted}
                        myUserId={myUserId}
                        pickIndex={pickIndex}
                        masterPoolId={masterPoolId}
                        handleSurvivorSubmit={handleSurvivorSubmit}
                        noNoonGames={noNoonGames}
                        hasTheCurrentIntervalsDeadlinePassed={hasTheCurrentIntervalsDeadlinePassed}
                    />
                </Grid>
            ))}
        </Grid>
    ) : (
        /* If there's an odd number of games, put all but the last in a grid */
        <>
            <Grid container spacing={1}>
                {filteredSchedule.slice(0, filteredSchedule.length - 1).map((game) => (
                    <Grid item xs={12} sm={12} md={6} key={game.GlobalGameID}>
                        <SurvivorGameCard
                            thisGamesId={game.GlobalGameID}
                            game={game}
                            interval={interval}
                            theCurrentInterval={theCurrentInterval}
                            teams={teams}
                            league={league}
                            isEntryEliminated={isEntryEliminated}
                            eliminationInterval={eliminationInterval}
                            pickState={pickState}
                            hasThisIntervalsDeadlinePassed={hasThisIntervalsDeadlinePassed}
                            timeNowInNewYork={timeNowInNewYork}
                            intervalInfo={intervalInfo}
                            hasUsersGameStarted={hasUsersGameStarted}
                            myUserId={myUserId}
                            pickIndex={pickIndex}
                            masterPoolId={masterPoolId}
                            handleSurvivorSubmit={handleSurvivorSubmit}
                            noNoonGames={noNoonGames}
                            hasTheCurrentIntervalsDeadlinePassed={hasTheCurrentIntervalsDeadlinePassed}
                        />
                    </Grid>
                ))}
            </Grid>
            
            {/* Display the last game centered */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 1 
            }}>
                <Box sx={{ 
                    width: '100%', 
                    maxWidth: { xs: '100%', md: '48%' } 
                }}>
                    <SurvivorGameCard
                        thisGamesId={filteredSchedule[filteredSchedule.length - 1].GlobalGameID}
                        game={filteredSchedule[filteredSchedule.length - 1]}
                        interval={interval}
                        theCurrentInterval={theCurrentInterval}
                        teams={teams}
                        league={league}
                        isEntryEliminated={isEntryEliminated}
                        eliminationInterval={eliminationInterval}
                        pickState={pickState}
                        hasThisIntervalsDeadlinePassed={hasThisIntervalsDeadlinePassed}
                        timeNowInNewYork={timeNowInNewYork}
                        intervalInfo={intervalInfo}
                        hasUsersGameStarted={hasUsersGameStarted}
                        myUserId={myUserId}
                        pickIndex={pickIndex}
                        masterPoolId={masterPoolId}
                        handleSurvivorSubmit={handleSurvivorSubmit}
                        noNoonGames={noNoonGames}
                        hasTheCurrentIntervalsDeadlinePassed={hasTheCurrentIntervalsDeadlinePassed}
                    />
                </Box>
            </Box>
        </>
    )}
</Box>

            {/* Submitted Pick Modal */}
            {submitModalOpen && (
                <SurvivorSubmittedPickModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    teams={teams}
                    league={league}
                    action={successData.action}
                    pick={successData.pick}
                    intervalName={successData.intervalName}
                    intervalDeadline={successData.intervalDeadline}
                />
            )}

            {/* <DuplicatePickModal
                open={removePreviousPickModalOpen}
                onClose={() => {
                    setRemovePreviousPickModalOpen(false);
                    setRemovePickData({});
                }}
                removePickData={removePickData}
                setRemovePickData={setRemovePickData}
                teams={teams}
                league={league}
                intervalInfo={intervalInfo}
                handleSurvivorSubmit={handleSurvivorSubmit}
            /> */}



            {/* Response Modal */}
            <ResponseModal
                open={responseModalOpen}
                onClose={handleModalClose}
                status={responseModalData.status}
                message={responseModalData.message}
            />
        </>
    );
}

export default SurvivorMyPicksPage;