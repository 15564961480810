import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useGetSurvivorData } from "../../../hooks/data/useGetSurvivorData";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';


const SurvivorGamesContext = createContext();

export const useSurvivorGamesContext = () => {
    const context = useContext(SurvivorGamesContext);
    if (!context) {
        throw new Error("useSurvivorGamesContext must be used within a SurvivorGamesProvider");
    }
    return context;
}

export const SurvivorGamesProvider = ({ children }) => {
    const {
        userData,
        gameData,
        masterPoolData,
        pickState,
        intervalInfo,
        hasJoinDeadlinePassed,
        leaderboardData,
    } = useUserAndGameContext();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redisKeys = masterPoolData?.redisKeys;
    //console.log("redisKeys in SurvivorGamesProvider: ", redisKeys);

    const [hasDataFetched, setHasDataFetched] = useState(false);

    const { mutate: getSurvivorData, data: survivorData, isLoading: survivorDataLoading, isError: survivorDataError, error: survivorDataErrorData } = useGetSurvivorData();

    //UseEffect to get survivor data
    useEffect(() => {
        if (!hasDataFetched && redisKeys) {
            getSurvivorData({ redisKeys });
            setHasDataFetched(true);
        }
    }, [hasDataFetched, redisKeys, getSurvivorData]);

    // Error handling for survivor data
    useEffect(() => {
        if (survivorDataError) {
            console.error("Error fetching survivor data:", survivorDataErrorData);
            if (survivorDataErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                navigate('/login');
            } else {
                navigate('/404/pool-hall');
            }
        }
    }, [survivorDataError, survivorDataErrorData, dispatch, navigate]);

    const { schedule, teams } = survivorData || {};
    //console.log("Survivor Schedule:", schedule, "Survivor Teams:", teams, "Test Leaderboard:", leaderboardData);

    const currentWeek = 1 //PLACEHOLDER FIX THIS WHEN WE DO A GAME WITH WEEKS


    // Get Pool Specific Data
    const picksPerInterval =  gameData?.gameParameters?.picksPerInterval ?? masterPoolData?.gameParameters?.picksPerInterval;
    const lossesForElimination = gameData?.gameParameters?.lossesForElimination ?? masterPoolData?.gameParameters?.lossesForElimination;
    //console.log("Picks Per Interval:", picksPerInterval, "Losses For Elimination:", lossesForElimination)

    // Memoize if the current entry (pickState) has been eliminated
    const isEntryEliminated = useMemo(() => {
        if (!pickState || !lossesForElimination) return false;

        const losses = pickState?.filter(pick => pick.result === 'loss');
        return losses.length >= lossesForElimination;
    }, [pickState, lossesForElimination]);
    //console.log("Is Entry Eliminated:", isEntryEliminated);

    // Memoize the interval when the entry was eliminated (if it was eliminated)
    const eliminationInterval = useMemo(() => {
        if (!pickState || !lossesForElimination || !isEntryEliminated) return null;

        // Sort picks by interval to ensure we process them in order
        const sortedPicks = [...pickState].sort((a, b) => a.interval - b.interval);
        
        // Keep track of loss count until we hit the elimination threshold
        let lossCount = 0;
        
        for (const pick of sortedPicks) {
            if (pick.result === 'loss') {
                lossCount++;
                if (lossCount === lossesForElimination) {
                    return pick.interval;
                }
            }
        }
        
        return null;
    }, [pickState, lossesForElimination, isEntryEliminated]);
    //console.log("Elimination Interval:", eliminationInterval);

    const theCurrentInterval = useMemo(() => {
        if (!intervalInfo) return null;
    
        if (intervalInfo.type === 'weeks') {
            // For weeks, return the currentWeek if it's within bounds
            if (currentWeek >= intervalInfo?.lowerBound && currentWeek <= intervalInfo?.upperBound) {
                return currentWeek;
            }
            return intervalInfo?.lowerBound;
        } else if (intervalInfo.type === 'date') {
            const timeNowInNewYork = DateTime.now().setZone('America/New_York');
            const isBeforeSixAM = timeNowInNewYork.hour < 6;
            const targetDate = isBeforeSixAM 
                ? timeNowInNewYork.minus({ days: 1 }).startOf('day')
                : timeNowInNewYork.startOf('day');
    
            // Find the appropriate item based on the date
            let appropriateItem = intervalInfo?.info?.find(item => {
                const itemDate = DateTime.fromISO(item.date);
                return itemDate >= targetDate;
            });
    
            // If no future date found, use the last interval
            if (!appropriateItem) {
                appropriateItem = intervalInfo?.info[intervalInfo.info.length - 1];
            }
    
            // Return the interval number instead of the date
            return appropriateItem?.interval ?? null;
        }
        
        return null;
    }, [intervalInfo, currentWeek]);
    
    //console.log("Starting Interval:", theCurrentInterval);

    const totalEntrants = leaderboardData?.length;
    const totalEntriesEliminated = leaderboardData?.filter(entry => {
        return entry.isUserEliminated;
    })?.length;
    const totalEntriesAlive = totalEntrants - totalEntriesEliminated;
    //console.log("Total Entrants:", totalEntrants, "Total Entries Eliminated:", totalEntriesEliminated, "Total Entries Alive:", totalEntriesAlive);

    const firstInterval = masterPoolData?.gameParameters?.firstInterval;
    const lastInterval = masterPoolData?.gameParameters?.lastInterval;

    const isGameOver = masterPoolData?.gameParameters?.isGameOver;
    const isThisSurvivorGameOver = isGameOver || (totalEntriesAlive <= 1 && hasJoinDeadlinePassed);


    const SurvivorGamesContextValues = useMemo(() => ({
        schedule,
        teams,
        picksPerInterval,
        lossesForElimination,
        isEntryEliminated,
        eliminationInterval,
        theCurrentInterval,
        leaderboardData,
        totalEntrants,
        totalEntriesEliminated,
        totalEntriesAlive,
        firstInterval,
        lastInterval,
        isGameOver,
        isThisSurvivorGameOver,
    }), [schedule, teams, picksPerInterval, lossesForElimination, isEntryEliminated, eliminationInterval, theCurrentInterval, leaderboardData, totalEntrants, totalEntriesEliminated, totalEntriesAlive, isThisSurvivorGameOver, firstInterval, lastInterval, isGameOver]);

    // ADD LOADING SCREEN INFO HERE
    if (!schedule || !teams || survivorDataLoading) {
        return <LoadingScreen />;
    }

    return (
        <SurvivorGamesContext.Provider value={SurvivorGamesContextValues}>
            {children}
        </SurvivorGamesContext.Provider>
    );
}

export default SurvivorGamesContext;