import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { getTeamLogo } from "../../../utils/SportsData";

const TiebreakerSingleInput = ({
    firstFinalist,
    secondFinalist,
    league,
    teams,
    hasDeadlinePassed,
    overrideTiebreaker = false,
    userTiebreaker,
}) => {
    const { 
        tiebreaker, 
        setTiebreaker 
    } = useUserAndGameContext();
    //console.log("Second Finalist: ", secondFinalist, "First Finalist: ", firstFinalist);
    //console.log("User Tiebreaker: ", userTiebreaker, userTiebreaker[0]?.tiebreaker, "overrideTiebreaker: ", overrideTiebreaker);

    const handleChange = (event) => {
        const newValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (newValue.length <= 3) {
            const numberValue = newValue === "" ? null : parseInt(newValue, 10);

            // Simply create a new array with just the single tiebreaker value
            // This will completely replace any existing tiebreaker data
            if (numberValue !== null) {
                setTiebreaker([{ tiebreaker: numberValue }]);
            } else {
                setTiebreaker([]); // Empty array if no value
            }
        }
    };

    // Get value safely
    const safeTiebreaker = Array.isArray(tiebreaker) ? tiebreaker : [];
    const value = overrideTiebreaker ? 
        userTiebreaker ?
        userTiebreaker[0]?.tiebreaker ?? "" : "" :
        safeTiebreaker.find(obj => obj.hasOwnProperty("tiebreaker"))?.tiebreaker ?? "";    

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, marginTop: '70px', border: '2px solid #002129', borderRadius: '8px', padding: '16px', marginBottom: '32px' }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>TIEBREAKER</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#555" }}>
                Total Combined Points
            </Typography>
            
            {/* Team Logos */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 6, mb: 1 }}>
                <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center" 
                }}>
                    {isNaN(firstFinalist) ? 
                    (
                        <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                            {firstFinalist}
                        </Typography>
                    ) :
                    (
                        <img 
                            src={getTeamLogo(league, firstFinalist, teams)} 
                            alt="First team logo"
                            style={{ 
                                maxWidth: "100%", 
                                maxHeight: "100%", 
                                objectFit: "contain" 
                            }}
                        />
                    )}
                </Box>
                <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center" 
                }}>
                    {isNaN(secondFinalist) ?
                    (
                        <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                            {secondFinalist}
                        </Typography>
                    ) :
                    (
                        <img 
                            src={getTeamLogo(league, secondFinalist, teams)} 
                            alt="Second team logo"
                            style={{ 
                                maxWidth: "100%", 
                                maxHeight: "100%", 
                                objectFit: "contain" 
                            }}
                        />
                    )}
                </Box>
            </Box>

            {/* Single Score Input/Display */}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {hasDeadlinePassed ? (
                    // After deadline - show text or X icon
                    value ? (
                        <Box sx={{ 
                            width: "80px", 
                            height: "40px", 
                            display: "flex", 
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px"
                        }}>
                            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                {value}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ 
                            width: "80px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px"
                        }}>
                            <CloseIcon sx={{ color: "#CC293C" }} />
                        </Box>
                    )
                ) : (
                    // Before deadline - show input field
                    <TextField
                        type="text"
                        value={value}
                        onChange={handleChange}
                        inputProps={{
                            maxLength: 3,
                            pattern: "[0-9]*",
                            style: { textAlign: "center" }
                        }}
                        placeholder="Points"
                        sx={{ width: "80px"}}
                    />
                )}
            </Box>
        </Box>
    );
};

export default TiebreakerSingleInput;