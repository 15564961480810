import React from "react";
import { useContext, createContext, useEffect, useState } from "react";

import nbaGameOddsByDate from '../data/nbaGameOdds.json'; // Import the JSON file
import nbaStadiums from '../data/nbaStadiums.json'; // Import the JSON file
import NBATeams from '../data/nbaTeamData.json'; // Import the JSON file
import nbaGamesByDate from '../data/nbaGamesByDate.json'; // Import the JSON file
import teaserOddsCalculations from '../data/betData/teaserOddsCalculations.json'; // TODO: MOVE TO LOCAL DB SOURCE OR CLOUD
import { v4 as uuidv4 } from 'uuid';

const SportsBookContext = createContext();
export const useSportsBookContext = () => useContext(SportsBookContext);

export const SportsBookProvider = ({ children }) => {

  const [sportsBookNav, setSportsBookNav] = useState('ALL');
  const [userSelections, setUserSelections] = useState([]);
  const [parlayMessage, setParlayMessage] = useState('');
  const [teaserMessage, setTeaserMessage] = useState('');
  const [bankrollAmount, setBankRollAmount] = useState(1000); // Replace with your actual Bankroll value
  const [teaserDropDown, setTeaserDropDown] = useState([]);
  const [teaserOdds, setTeaserOdds] = useState();
  const [winningTotal, setWinningTotal] = useState(
    userSelections.map(() => ({ wager: "", mirrored: "" }))
  );

  const [groupWager, setGroupWager] = useState(0);

  const newUUID = uuidv4();
  const [userBets, setUserBets] = useState([{

    GameSegment:1,
    Bets: []
  }]);

  useEffect(() => {
      let newUserBets = createUserBets(userSelections);
      userBets?.Bets?.push(newUserBets);
      console.log('newUserBets', newUserBets);
      userBets.Bankroll = bankrollAmount;
      // userBets.Bets.push(newUserBets);
      // userBets.Bets.push(newUserBets);
      return;
  }, [userSelections, userBets]);


  function caclulateAmericanToDecimalOdds(value, americanOdds, decimalOdds) {
    let convertedOdds = ((value * decimalOdds) - value).toFixed(2);
    return convertedOdds;
  }

  function caclulateDecimalOddsToAmerican(value, americanOdds, decimalOdds) {
    let convertedOdds = (value / (decimalOdds - 1)).toFixed(2)
    return convertedOdds;
  }

function filterGamesForDisplay(gameOddsArray) {

  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);

  return gameOddsArray.filter(game => {
      const gameDate = new Date(game.DateTime);

      // Check if the game is scheduled for today and hasn't started
      if (
        (gameDate.getDate() === currentDate.getDate() && gameDate > currentDate) ||
        (gameDate.getDate() === tomorrow.getDate() && gameDate.getMonth() === tomorrow.getMonth() && gameDate.getFullYear() === tomorrow.getFullYear())
      ) {
        // Check if the required odds are not all null for the specified sportsbook (SportsbookId: 22)
        const consensusOdds = game.PregameOdds.find(odd => odd.SportsbookId === 22);

        if (consensusOdds &&
          consensusOdds.HomeMoneyLine !== null &&
          consensusOdds.AwayMoneyLine !== null &&
          consensusOdds.HomePointSpread !== null &&
          consensusOdds.AwayPointSpread !== null &&
          consensusOdds.OverUnder !== null) {
          return true;
        }
      }

      return false;
    })
    .map(game => game.GlobalGameId); // Extract GameIds from the filtered games
}

// Example usage
const filteredGameIds = filterGamesForDisplay(nbaGameOddsByDate);
console.log(filteredGameIds);

function NbaGamblingDisplay(globalTeamID, nbaTeams) {
  const team = nbaTeams.find(team => team.GlobalTeamID === globalTeamID);

  if (team) {
    let key = team.Key;

    // Check if the first two letters of Key are "LA"
    if (key.startsWith("LA")) {
      key = key.substring(0, 2); // Take only the first two letters
    }

    return `${key} ${team.Name}`;
  } else {
    return 'Team not found';
  }
}

function getChannelByGameId(gameId, nbaGamesByDate) {
  const game = nbaGamesByDate.find(game => game.GlobalGameID === gameId);

  if (game) {
    const channel = game.Channel;
     return channel;
    }

  return null; // Return null if stadium information is not found
}


function multiplyAllOdds(selections) {
  const product = selections.reduce((accumulator, selection) => accumulator * selection.DecimalOdds, 1);
  return product;
}


function getStadiumInfoByGameId(gameId, nbaGamesByDate, nbaStadiums) {
  const game = nbaGamesByDate.find(game => game.GlobalGameID === gameId);

  if (game) {
    const stadiumId = game.StadiumID;

    if (stadiumId) {
      const stadium = nbaStadiums.find(stadium => stadium.StadiumID === stadiumId);

      if (stadium) {
        return {
          Name: stadium.Name,
          City: stadium.City,
          State: stadium.State,
          Country: stadium.Country,
        };
      }
    }
  }

  return null; // Return null if stadium information is not found
}

// Function to convert American odds to decimal odds
function americanToDecimalOdds(americanOdds) {
  return americanOdds >= 0 ? (americanOdds / 100) + 1 : (100 / Math.abs(americanOdds)) + 1;
}

function checkParlay(selections) {
  const productOfOdds = multiplyAllOdds(userSelections);
  const gameIDs = [];
  const spreadMoneylineMap = new Map();
  const gameBetTypes = {};

  for (const selection of selections) {
    const { GameID, BetType, BetChoice, Line, Sport, PlayerID } = selection;

    if (Sport !== "Golf") {
      const key = `${GameID}_${BetType}`;

      if (!gameBetTypes[key]) {
        gameBetTypes[key] = 0;
      }

      gameBetTypes[key]++;

      if (gameBetTypes[key] > 1) {
        console.log("Parlay Unavailable: Can't parlay both sides of the same bet.");
        return "Parlay Unavailable: Can't parlay both sides of the same bet.";
      }

      // Check if a GameID with negative SPREAD has MONEYLINE, disallow parlay
      if (BetType === "SPREAD" && Line <= 0) {
        if (selections.some((s) => s.GameID === GameID && s.BetType === "MONEYLINE")) {
          console.log("Parlay Unavailable. This combination cannot be parlayed.");
          return "Parlay Unavailable. This combination cannot be parlayed.";
        }
      }

      // Check if SPREAD and MONEYLINE share the same GameID and BetChoice
      if (BetType === "SPREAD" || BetType === "MONEYLINE") {
        if (spreadMoneylineMap.has(GameID) && spreadMoneylineMap.get(GameID) === BetChoice) {
          console.log("Parlay Unavailable. This combo canNOT be parlayed.");
          return "Parlay Unavailable. This combo canNOT be parlayed.";
        }
      }
    }

    // Check if Sport is "Golf", disallow 2 or more entries with the same PlayerID
    if (Sport === "Golf") {
      const playerIDCount = selections.reduce((count, s) => (s.PlayerID === PlayerID ? count + 1 : count), 0);
      if (playerIDCount >= 2) {
        console.log("Parlay Unavailable. Two or more entries with the same PlayerID are not allowed in Golf.");
        return "Parlay Unavailable. Two or more entries with the same PlayerID are not allowed in Golf.";
      }
    }

    spreadMoneylineMap.set(GameID, BetChoice);
    gameIDs.push(GameID);
  }

  const totalGameIDs = gameIDs.length;

  if (totalGameIDs >= 2) {
    console.log(totalGameIDs + " Leg Parlay");
    return totalGameIDs + " Leg Parlay, Odds:"+productOfOdds;
  } else {
    console.log("Fail");
    return "Fail";
  }
}

// Function to construct betsToDisplay object
function constructBetsToDisplay(gameIds, gameOddsArray, nbaTeams, nbaGamesByDate, nbaStadiums) {
  const betsToDisplay = [];
    console.log(gameIds, gameOddsArray, nbaTeams, nbaGamesByDate, nbaStadiums);
    gameIds.forEach(gameId => {
      const game = gameOddsArray.find(game => game.GlobalGameId === gameId);

      if (game) {
        const {
          DateTime,
          GlobalAwayTeamId,
          GlobalHomeTeamId,
          PregameOdds,
        } = game;

        const awayTeamName = NbaGamblingDisplay(GlobalAwayTeamId, nbaTeams);
        const homeTeamName = NbaGamblingDisplay(GlobalHomeTeamId, nbaTeams);

        // Filter odds for SportsbookId 22
        const odds22 = PregameOdds.filter(odd => odd.SportsbookId === 22);

        let gamesAvailable = {};

        gamesAvailable = {
          gameId,
          DateTime,
          Channel: getChannelByGameId(gameId, nbaGamesByDate),
          GlobalAwayTeamId,
          GlobalHomeTeamId,
          AwayTeamName: awayTeamName,
          HomeTeamName: homeTeamName,
          StadiumInfo: getStadiumInfoByGameId(gameId, nbaGamesByDate, nbaStadiums),
          Sport: "Basketball",
          League: "NBA",
          BetType: {
              SPREAD: odds22
                .filter(odd => odd.OddType === "pregame" && odd.HomePointSpreadPayout !== null && odd.AwayPointSpreadPayout !== null)
                .map(odd => ([
                  {
                    BetChoice: "AWAY",
                    Line: odd.AwayPointSpread,
                    Odds: odd.AwayPointSpreadPayout,
                    DecimalOdds: americanToDecimalOdds(odd.AwayPointSpreadPayout),
                  },
                  {
                    BetChoice: "HOME",
                    Line: odd.HomePointSpread,
                    Odds: odd.HomePointSpreadPayout,
                    DecimalOdds: americanToDecimalOdds(odd.HomePointSpreadPayout),
                  },
                ])),

              MONEYLINE: odds22
                .filter(odd => odd.OddType === "pregame" && odd.HomeMoneyLine !== null && odd.AwayMoneyLine !== null)
                .map(odd => ([
                  {
                    BetChoice: "AWAY",
                    Line: null,
                    Odds: odd.AwayMoneyLine,
                    DecimalOdds: americanToDecimalOdds(odd.AwayMoneyLine),
                  },
                  {
                    BetChoice: "HOME",
                    Line: null,
                    Odds: odd.HomeMoneyLine,
                    DecimalOdds: americanToDecimalOdds(odd.HomeMoneyLine),
                  },
                ])),

              TOTAL: odds22
                .filter(odd => odd.OddType === "pregame" && odd.OverPayout !== null && odd.UnderPayout !== null)
                .map(odd => ([
                  {
                    BetChoice: "OVER",
                    Line: odd.OverUnder,
                    Odds: odd.OverPayout,
                    DecimalOdds: americanToDecimalOdds(odd.OverPayout),
                  },
                  {
                    BetChoice: "UNDER",
                    Line: odd.OverUnder,
                    Odds: odd.UnderPayout,
                    DecimalOdds: americanToDecimalOdds(odd.UnderPayout),
                  },
                ])),
            }
        };
      betsToDisplay.push(gamesAvailable);
    }
  });

  return betsToDisplay;

}

function checkTeaserConditions(selections) {
  const gameIDs = new Map();
  let isEligibleForTeasers = true;

  for (const selection of selections) {
    if (gameIDs.has(selection.GameID)) {
      gameIDs.set(selection.GameID, gameIDs.get(selection.GameID) + 1);
    } else {
      gameIDs.set(selection.GameID, 1);
    }

    if (selection.Sport !== "Basketball" && selection.Sport !== "Football") {
      isEligibleForTeasers = false;
    }
  }

  const totalGameIDs = Array.from(gameIDs.values()).reduce((acc, count) => acc + count, 0);

  if (totalGameIDs < 2) {
    console.log("Add more picks");
    return "Add more picks";
  } else if (totalGameIDs > 10) {
    console.log("Too many games for a teaser");
    return "Too many games for a teaser";
    } else if (!isEligibleForTeasers) {
    console.log("Some sports are not eligible for Teasers");
    return "Some sports are not eligible for Teasers";
  } else if (selections.some(selection => selection.BetType === "MONEYLINE")) {
    console.log("Can't tease Moneylines");
    return "Can't tease Moneylines";
  } else {
    console.log(totalGameIDs + " Leg Teaser");
    return totalGameIDs + " Leg Teaser";
  }
}

const sportsInUserSelections = new Set(userSelections.map(selection => selection.Sport));


const filteredDropdownMenu = {};

// Create an object to count the occurrences of each order value for each sport
const orderValueCount = {};

const filterTeaserOddsCalculations = () => {

  // Iterate through teaserOddsCalculations
  for (const sport in teaserOddsCalculations) {
      for (const data of teaserOddsCalculations[sport]) {
        const orderValue = data.OrderValue;
        const points = data.Points;

        const matchingSport = userSelections.find(userSelection => userSelection.Sport === sport);

        if (matchingSport) {
          // Increase the count for this order value and sport
          if (!orderValueCount[orderValue]) {
            orderValueCount[orderValue] = new Set();
          }
          orderValueCount[orderValue].add(sport);

          if (!filteredDropdownMenu[orderValue]) {
            filteredDropdownMenu[orderValue] = [];
          }

          // Find the matching Info based on the number of teams
          const matchingInfo = data.Info.find(info => info.Teams === userSelections.length);

          if (matchingInfo) {
            filteredDropdownMenu[orderValue].push({
              Sport: sport,
              Points: points,
              DecimalOdds: matchingInfo.DecimalOdds,
              AmericanOdds: matchingInfo.AmericanOdds,
            });
          }
        }
      }
    }
  }





const createDropdownValues = () => {

  filterTeaserOddsCalculations();

  // Iterate through the filteredDropdownMenu
  for (const orderValue in filteredDropdownMenu) {
    const entries = filteredDropdownMenu[orderValue];
    const sportsInEntries = Array.from(orderValueCount[orderValue]);

    // Check if this order value is available for all sports in userSelections
    if (sportsInEntries.length === sportsInUserSelections.size) {
      const orderValueData = {};

      entries.forEach((entry, index) => {
        const sportKey = `Sport`;
        const pointsKey = `Points`;
        // const gameIndex = index + 1;

        orderValueData[sportKey] = entry.Sport;
        orderValueData[pointsKey] = entry.Points;
      });

      const decimalOddsSum = entries.reduce((sum, entry) => sum + entry.DecimalOdds, 0);
      const americanOddsSum = entries.reduce((sum, entry) => sum + entry.AmericanOdds, 0);
      const numEntries = entries.length;
      const averageDecimalOdds = decimalOddsSum / numEntries;
      const averageAmericanOdds = americanOddsSum / numEntries;

      orderValueData['DecimalOdds'] = averageDecimalOdds;
      orderValueData['AmericanOdds'] = averageAmericanOdds;

      teaserDropDown.push(orderValueData);
    }
  }
  console.log('display in dropdown');

}

/**
 * Calculates the result of a moneyline bet based on the game scores and the chosen team.
 * @param {number} awayScore - The score of the away team.
 * @param {number} homeScore - The score of the home team.
 * @param {string} betChoice - The chosen team (either "AWAY" or "HOME").
 * @returns {string} - The result of the moneyline bet ("Win" or "Loss").
 */
async function calculateMoneylineResult(awayScore, homeScore, betChoice) {
  // Check if the chosen team wins based on the game scores
  if (betChoice === "AWAY") {
      // If the chosen team is away, it wins if its score is greater than the home team's score
      return awayScore > homeScore ? "Win" : "Loss";
  } else if (betChoice === "HOME") {
      // If the chosen team is home, it wins if its score is greater than the away team's score
      return homeScore > awayScore ? "Win" : "Loss";
  }
}
/**
 * Calculate the result of a spread bet.
 * @param {number} awayScore - The score of the away team.
 * @param {number} homeScore - The score of the home team.
 * @param {string} betChoice - The choice made in the bet (e.g., AWAY, HOME).
 * @param {number} line - The line value for the bet.
 * @param {number} teaserPoints - The teaser points used in the bet.
 * @returns {Promise<string>} - The result of the spread bet (e.g., Win, Loss, Push).
 */
async function calculateSpreadResult(awayScore, homeScore, betChoice, line, teaserPoints) {
  // Determine the opponent's score based on the bet choice
  const opponentScore = betChoice === "AWAY" ? homeScore : awayScore;
  // Calculate the adjusted line based on the bet choice and teaser points
  const adjustedLine = betChoice === "AWAY" ? awayScore + line + teaserPoints : homeScore + line + teaserPoints;

  // Compare the adjusted line with the opponent's score to determine the result
  if (adjustedLine > opponentScore) {
      return "Win";
  } else if (adjustedLine === opponentScore) {
      return "Push";
  } else {
      return "Loss";
  }
}


/**
* Calculate the result of a total (over/under) bet.
* @param {number} awayScore - The score of the away team.
* @param {number} homeScore - The score of the home team.
* @param {string} betChoice - The choice made in the bet (e.g., OVER, UNDER).
* @param {number} line - The line value for the bet.
* @param {number} teaserPoints - The teaser points used in the bet.
* @returns {Promise<string>} - The result of the total bet (e.g., Win, Loss, Push).
*/
async function calculateTotalResult(awayScore, homeScore, betChoice, line, teaserPoints) {
  // Calculate the total score of the game
  const total = awayScore + homeScore;
  // Adjust the line based on the bet choice and teaser points
  const adjustedLine = betChoice === "OVER" ? line - teaserPoints : line + teaserPoints;

  // Compare the total score with the adjusted line to determine the result
  if (betChoice === "OVER" ? total > adjustedLine : total < adjustedLine) {
      return "Win";
  } else if (total === adjustedLine) {
      return "Push";
  } else {
      return "Loss";
  }
}



const submitBet = () => {

  // Example usage
  const result = checkParlay(userSelections);
  const teaserCheck = checkTeaserConditions(userSelections);
  console.log('Submit bet',result, teaserCheck);
  setParlayMessage(result);
  setTeaserMessage(teaserCheck);
}


/**
 * Helper function to calculate the result based on the bet type.
 * @param {string} betType - The type of the bet (e.g., MONEYLINE, SPREAD, TOTAL).
 * @param {number} awayScore - The score of the away team.
 * @param {number} homeScore - The score of the home team.
 * @param {string} betChoice - The choice made in the bet (e.g., AWAY, HOME).
 * @param {number} line - The line value for the bet.
 * @param {number} teaserPoints - The teaser points used in the bet.
 * @returns {Promise<string|null>} - The result of the bet (e.g., Win, Loss) or null if the bet type is invalid.
 */
async function calculateResult(betType, awayScore, homeScore, betChoice, line, teaserPoints) {
  switch (betType) {
      case "MONEYLINE":
          return calculateMoneylineResult(awayScore, homeScore, betChoice);
      case "SPREAD":
          return calculateSpreadResult(awayScore, homeScore, betChoice, line, teaserPoints);
      case "TOTAL":
          return calculateTotalResult(awayScore, homeScore, betChoice, line, teaserPoints);
      default:
          return null; // Invalid bet type
  }
}



/**
 * Check if an individual bet has been settled.
 * @param {Promise<object>} IndividualBetResult - The result of the individual bet calculation.
 * @returns {Promise<boolean>} - A boolean indicating if the individual bet has been settled.
 */
async function IndividualBetSettled(IndividualBetResult) {
  // Wait for the individual bet result
  const result = await IndividualBetResult;
  // Return true if the result is not Pending, indicating that the bet has been settled
  return result.result !== "Pending";
}

/**
 * Calculates the result of a group bet based on individual bet results.
 * @param {Array} userBets - Array of user bets.
 * @param {string} GroupBetID - ID of the group bet.
 * @returns {Promise<string>} - A promise that resolves to the result of the group bet.
 */
// Async version of GroupBetResult function
async function GroupBetResult(userBets, GroupBetID) {
  const groupBet = userBets.find(bet => bet.GroupBetID === GroupBetID);
  if (!groupBet) return { result: "Group bet not found" };

  const individualBetResults = [];
  const individualBetData = [];

  // Calculate individual bet results and data for each individual bet in the group bet
  for (const individualBet of groupBet.IndividualBets) {
      const { result, ...betData } = await IndividualBetResult(userBets, nbaGamesByDate, individualBet.IndividualBetID);
      individualBetResults.push(result);
      individualBetData.push(betData);
  }

  // Apply logic to determine group bet result based on individual bet results
  let groupResult = "Undetermined";
  if (individualBetResults.includes("Loss")) {
      groupResult = "Loss";
  } else if (individualBetResults.includes("Pending")) {
      groupResult = "Pending";
  } else if (individualBetResults.every(result => result === "Push")) {
      groupResult = "Push";
  } else if (individualBetResults.every(result => result === "Void")) {
      groupResult = "Void";
  } else if (individualBetResults.every(result => result === "Win")) {
      groupResult = "Win";
  } else if (
      (groupBet.BetStyle === "Parlay" || groupBet.BetStyle === "Single") &&
      individualBetResults.includes("Win") &&
      !individualBetResults.includes("Loss")
  ) {
      groupResult = "Win";
  } else if (
      groupBet.BetStyle === "Teaser" &&
      individualBetResults.filter(result => result === "Win").length >= 2 &&
      !individualBetResults.includes("Loss")
  ) {
      groupResult = "Win";
  } else if (
      groupBet.BetStyle === "Teaser" &&
      individualBetResults.filter(result => result === "Win").length === 1 &&
      !individualBetResults.includes("Loss")
  ) {
      groupResult = "Push";
  }

  // Log individual bet data for debugging
  //console.log("From Group Bet Result. This is: individualBetData:", individualBetData);
  //console.log("From Group Bet Result.  This is individualBetResult:", individualBetResults);
  //console.log("From Group Bet Result.  This is groupResult:", groupResult);

  // Return both group result, individual bet results, and individual bet data
  return { result: groupResult, individualBetResults, individualBetData };
}


/**
 * Checks if a group bet has been settled.
 * @param {string} groupResult - The result of the group bet.
 * @returns {Promise<boolean>} - A promise that resolves to true if the group bet is settled; otherwise, false.
 */
async function GroupBetSettled(groupResult) {
  return groupResult !== "Pending";
}


/**
 * Converts American odds to decimal odds.
 * @param {number} americanOdds - The American odds to be converted.
 * @returns {number} - The corresponding decimal odds.
 */
function americanToDecimal(americanOdds) {
  if (americanOdds >= 100) {
    return (americanOdds / 100) + 1;
  } else {
    return (100 / Math.abs(americanOdds)) + 1;
  }
}






/**
 * Calculates the decimal odds for a single bet within a group.
 * @param {array} userBets - The array of user bets.
 * @param {number} GroupBetID - The ID of the group bet.
 * @returns {number} - The decimal odds for the single bet.
 */
async function SingleBetDecimalOdds(userBets, GroupBetID) {
    // Find the group bet with the specified GroupBetID
    const groupBet = userBets.find(bet => bet.GroupBetID === GroupBetID);
    // If group bet not found, log an error and return
    if (!groupBet) {
        console.log("Group bet not found");
        return;
    }

    // Check if the group bet is of Single bet style
    if (groupBet.BetStyle !== "Single") {
        console.log("Group bet is not a Single bet");
        return;
    }

    // Extract the first individual bet from the group bet
    const individualBet = groupBet.IndividualBets[0]; // Assuming there's only one individual bet in a single group bet
    // If no individual bet found, log an error and return
    if (!individualBet) {
        console.log("No individual bet found in the group bet");
        return;
    }

    // If odds for the individual bet are null, log an error and return
    if (individualBet.Odds === null) {
        console.log("Odds for the individual bet are null");
        return;
    }

    // Calculate the decimal odds for the individual bet
    const decimalOdds = americanToDecimal(individualBet.Odds);
    return decimalOdds;
}


/**
 * Calculates the decimal odds for a parlay bet within a group.
 * @param {array} userSelections - The array of user bets.
 * @param {array} nbaGamesByDateData - The array of college basketball games by date.
 * @param {number} groupBetID - The ID of the group bet.
 * @returns {number} - The decimal odds for the parlay bet.
 */
async function ParlayBetDecimalOdds(userSelections, nbaGamesByDateData, groupBetID) {
    // Find the group bet with the specified groupBetID
    const groupBet = userSelections.find(bet => bet.GroupBetID === groupBetID);
    // If group bet not found or it's not a parlay bet, log an error and return
    if (!groupBet || groupBet.BetStyle !== "Parlay") {
        console.log("Parlay bet not found");
        return;
    }

    // Initialize an array to store the decimal odds of individual bets
    const oddsArray = [];

    // Iterate over each individual bet in the group bet
    for (const individualBet of groupBet.IndividualBets) {
        // Get the result of the individual bet
        const result = await IndividualBetResult(userSelections, nbaGamesByDateData, individualBet.IndividualBetID);

        // Check if the result is neither "Void" nor "Push"
        if (result.result !== "Void" && result.result !== "Push") {
            // Calculate the decimal odds for the individual bet
            const odds = await americanToDecimal(individualBet.Odds);
            // If valid odds are obtained, add them to the odds array
            if (odds !== undefined && !isNaN(odds)) {
                oddsArray.push(odds);
            }
        }
    }

    // If no valid odds are found in individual bets, log an error and return
    if (oddsArray.length === 0) {
        console.log("No valid odds found in individual bets");
        return;
    }

    // Calculate the product of all odds in the odds array
    const product = oddsArray.reduce((accumulator, currentValue) => accumulator * currentValue, 1);
    return product;
}

/**
 * Counts the number of individual bets in a group bet that are neither "Push" nor "Void".
 * @param {array} userSelections - The array of user bets.
 * @param {array} nbaGamesByDateData - The array of college basketball games by date.
 * @param {number} GroupBetID - The ID of the group bet.
 * @returns {number} - The count of individual bets that are neither "Push" nor "Void".
 */
async function TeaserNonPushOrVoidCount(userSelections, nbaGamesByDateData, GroupBetID) {
  // Initialize a counter for non-"Push" and non-"Void" individual bets
  let nonPushOrVoidCount = 0;

  // Find the group bet with the specified GroupBetID
  const groupBet = userSelections.find(bet => bet.GroupBetID === GroupBetID);
  // If group bet not found, log an error and return
  if (!groupBet) {
      console.log("Group bet not found");
      return;
  }

  // Iterate over each individual bet in the group bet
  for (const individualBet of groupBet.IndividualBets) {
      // Get the result of the individual bet
      const result = await IndividualBetResult(userSelections, nbaGamesByDateData, individualBet.IndividualBetID);
      // If result is not "Push" or "Void", increment the counter
      if (result && result.result !== "Push" && result.result !== "Void") {
          nonPushOrVoidCount++;
      }
  }

  return nonPushOrVoidCount;
}



/**
* Retrieves the teaser points for a teaser bet.
* @param {array} userSelections - The array of user bets.
* @param {number} GroupBetID - The ID of the group bet.
* @returns {number} - The teaser points for the teaser bet.
*/
async function TeaserBetTeaserPoints(userSelections, GroupBetID) {
  // Find the group bet with the specified GroupBetID
  const groupBet = userSelections.find(bet => bet.GroupBetID === GroupBetID);

  // If group bet not found, log an error and return
  if (!groupBet) {
      console.log("Group bet not found");
      return;
  }

  // Get the first individual bet in the group bet
  const firstIndividualBet = groupBet.IndividualBets[0];

  // If no individual bets found, log an error and return
  if (!firstIndividualBet) {
      console.log("No individual bets found in the group bet");
      return;
  }

  // Return the teaser points for the first individual bet
  return firstIndividualBet.TeaserPoints;
}



/**
* Retrieves the decimal odds for a teaser bet.
* @param {number} count - The number of teams in the teaser bet.
* @param {number} teaserPoints - The teaser points for the teaser bet.
* @param {string} sport - The sport for which the teaser bet is placed.
* @returns {number} - The decimal odds for the teaser bet.
*/
async function TeaserBetDecimalOdds(count, teaserPoints, sport) {
  // Find teaser odds information for the specified sport and teaser points
  const teaserOddsInfo = teaserOddsCalculations[sport].find(info => info.Points === teaserPoints);

  // If teaser odds information not found, log an error and return
  if (!teaserOddsInfo) {
      console.log(`Teaser odds information not found for sport ${sport} and teaser points ${teaserPoints}`);
      return;
  }

  // Find odds information for the specified count and teaser points
  const oddsInfo = teaserOddsInfo.Info.find(info => info.Teams === count);

  // If odds information not found, log an error and return
  if (!oddsInfo) {
      console.log(`Teaser odds information not found for count ${count} and teaser points ${teaserPoints}`);
      return;
  }

  // Return the decimal odds for the teaser bet
  return oddsInfo.DecimalOdds;
}



/**
 * Retrieves the decimal odds for a group bet based on its bet style.
 * @param {Array} userSelections - Array containing user bet data.
 * @param {Array} nbaGamesByDateData - Array containing college basketball games data by date.
 * @param {number} groupBetID - The ID of the group bet.
 * @param {string} sport - The sport for which the group bet is placed.
 * @returns {number} - The decimal odds for the group bet.
 */
async function GroupBetDecimalOdds(userSelections, nbaGamesByDateData, groupBetID, sport) {
  // Find the group bet with the specified ID
  const groupBet = userSelections.find(bet => bet.GroupBetID === groupBetID);

  // If group bet not found, log an error and return
  if (!groupBet) {
      console.log("Group bet not found");
      return;
  }

  //console.log("From GroupBetDecimalOdds function.  This is: groupBet.BetStyle:",groupBet.BetStyle); // Log the bet style for debugging

  // Determine the bet style of the group bet and calculate decimal odds accordingly
  switch (groupBet.BetStyle) {
      case "Single":
          return await SingleBetDecimalOdds(userSelections, groupBetID);
      case "Parlay":
          return await ParlayBetDecimalOdds(userSelections, nbaGamesByDateData, groupBetID);
      case "Teaser":
          // Calculate the number of non-push or void bets in the teaser
          const count = await TeaserNonPushOrVoidCount(userSelections, nbaGamesByDateData, groupBetID);
          // Retrieve the teaser points for the teaser bet
          const teaserPoints = await TeaserBetTeaserPoints(userSelections, groupBetID);
          // Calculate and return the decimal odds for the teaser bet
          return await TeaserBetDecimalOdds(count, teaserPoints, sport);
      default:
          console.log("Unsupported BetStyle");
          return;
  }
}


/**
* Calculates the payout and net profit/loss for a group bet.
* @param {Array} userBets - Array containing user bet data.
* @param {number} GroupBetID - The ID of the group bet.
* @param {string} sport - The sport for which the group bet is placed.
* @param {Array} nbaGamesByDate - Array containing college basketball games data by date.
* @returns {Object} - Object containing the payout and net profit/loss.
*/
async function PayoutAndNet(userBets, GroupBetID, sport, nbaGamesByDate) {
  // Retrieve the decimal odds for the group bet
  const groupDecimalOdds = await GroupBetDecimalOdds(userBets, nbaGamesByDate, GroupBetID, sport);

  // Retrieve the result of the group bet
  const groupResult = await GroupBetResult(userBets, GroupBetID);

  // Extract individual bet results and data from the group result
  const { result: groupResultValue, individualBetResults, individualBetData } = groupResult;

  // If the group bet result is pending, return null values for payout and net
  if (groupResultValue === "Pending") {
      //console.log(`From Payout and Net: This is groupResultValue: ${groupResultValue}, groupDecimalOdds: ${groupDecimalOdds}, Payout: null, and Net: null`);
      //console.log('From Payout and Net: This is individualBetResults:', individualBetResults);
      //console.log('From Payout and Net: This is individualBetData', individualBetData);
      return { GroupResult: groupResultValue, GroupDecimalOdds: groupDecimalOdds, Payout: null, Net: null, IndividualBetResults: individualBetResults, IndividualBetData: individualBetData };
  }

  // Find the group bet with the specified ID
  const groupBet = userBets.find(bet => bet.GroupBetID === GroupBetID);

  // If group bet not found, log an error and return
  if (!groupBet) {
      console.log("Group bet not found");
      return;
  }

  // Extract the wager amount from the group bet
  const wager = groupBet.Wager;

  let payout;
  // Calculate the payout based on the group result and decimal odds
  if (groupResultValue === "Void" || groupResultValue === "Push") {
      payout = wager;
  } else if (groupResultValue === "Loss") {
      payout = 0;
  } else if (groupResultValue === "Win" && groupDecimalOdds !== null) {
      payout = wager * groupDecimalOdds;
  }

  // Calculate the net profit/loss
  let net = payout - wager;

  // Ensure Payout and Net are treated as numbers and rounded to 2 decimal places
  if (!isNaN(payout)) {
      payout = parseFloat(payout.toFixed(2));
  }
  if (!isNaN(net)) {
      net = parseFloat(net.toFixed(2));
  }

  // Log the group result, wager amount, group decimal odds, payout, and net profit/loss
  //console.log(`From Payout and Net: This is groupResultValue: ${groupResultValue}, groupDecimalOdds: ${groupDecimalOdds}, Payout: ${payout}, and Net: ${net}`);
      //console.log('From Payout and Net: This is individualBetResults:', individualBetResults);
      //console.log('From Payout and Net: This is individualBetData', individualBetData);


  // Return an object containing the calculated payout and net profit/loss
  return { GroupResult: groupResultValue, GroupDecimalOdds: groupDecimalOdds, Payout: payout, Net: net, IndividualBetResults: individualBetResults, IndividualBetData: individualBetData };
}







/**
* Calculates the adjusted bankroll based on user bets, initial bankroll value, and game data.
* @param {Array} userBets - Array containing user bet data.
* @param {number} BankrollValue - The initial value of the bankroll.
* @param {string} sport - The sport for which the bets are placed.
* @param {Array} nbaGamesByDate - Array containing college basketball games data by date.
* @returns {number} - The adjusted bankroll value rounded to two decimal places.
*/
async function Bankroll(userBets, BankrollValue, sport, nbaGamesByDate, filteringCriteria) {
  let adjustedBankroll = bankrollAmount;
  let totalWagers = 0;
  let totalPayouts = 0;
  let betsToFilter = [];

  // Iterate over each bet
  for (const bet of userBets) {
      // Calculate the Payout and Net for the current group bet
      const { Payout, Net, GroupResult, GroupDecimalOdds, IndividualBetResults, IndividualBetData } = await PayoutAndNet(userBets, bet.GroupBetID, sport, nbaGamesByDate);
      const GroupBetID = bet.GroupBetID;
      const BetStyle = bet.BetStyle;
      const Wager = bet.Wager;

// Combine IndividualBetData with IndividualBetResults
  const IndividualBetInfo = IndividualBetData.map((data, index) => ({
      ...data,
      IndividualBetResult: IndividualBetResults[index] // Assuming the property name is IndividualBetResult, change it accordingly
  }));

  // Iterate over IndividualBetInfo and log desired properties
  for (const info of IndividualBetInfo) {
      const IndividualGameID = info.gameID;
      const IndividualBetID = info.individualBetID;
      const IndividualBetResult = info.IndividualBetResult; // Updated property name
      const BetType = info.betType;
      const BetChoice = info.betChoice;
      const Line = info.line;
      const Odds = info.odds;
      const TeaserPoints = info.teaserPoints;
      const GameStatus = info.gameStatus;
      const IsGameOver = info.isGameOver;

          // Push the bet data into betsToFilter array
          betsToFilter.push({
              GroupBetID,
              BetStyle,
              Wager,
              GroupResult,
              GroupDecimalOdds,
              Payout,
              Net,
              IndividualGameID,
              IndividualBetID,
              IndividualBetResult,
              BetType,
              BetChoice,
              Line,
              Odds,
              TeaserPoints,
              GameStatus,
              IsGameOver
          });
      }

      // Add the wager to the adjusted bankroll
      adjustedBankroll -= Wager;
      totalWagers += Wager;

      // Add the non-null payout to the adjusted bankroll
      if (Payout !== null) {
          adjustedBankroll += Payout;
          totalPayouts += Payout;
      }
      console.log("This is the adjustedBankroll:", adjustedBankroll);
      setBankRollAmount(adjustedBankroll);
  }

 // Apply filtering criteria to betsToFilter
const passedGroupBetIDs = new Set(); // Set to store GroupBetIDs that pass the filter

betsToFilter.forEach(bet => {
  // Check if the bet passes the filter criteria
  let passesFilter = true;

  for (const key in filteringCriteria) {
      if (filteringCriteria.hasOwnProperty(key)) {
          if (Array.isArray(filteringCriteria[key])) {
              if (!filteringCriteria[key].includes(bet[key])) {
                  passesFilter = false;
                  break;
              }
          } else {
              if (bet[key] !== filteringCriteria[key]) {
                  passesFilter = false;
                  break;
              }
          }
      }
  }

  // If the bet passes the filter, add its GroupBetID to the set
  if (passesFilter) {
      passedGroupBetIDs.add(bet.GroupBetID);
  }
});

// Create filteredBets array containing all bets with GroupBetIDs that passed the filter
const filteredBets = betsToFilter.filter(bet => passedGroupBetIDs.has(bet.GroupBetID));


  console.log("Filtered bets, for:",filteringCriteria, filteredBets);

  // Round the adjustedBankroll to two decimal places
  adjustedBankroll = parseFloat(adjustedBankroll.toFixed(2));

  const finishedFilterFormatted = [];

filteredBets.forEach(bet => {
  // Check if the GroupBetID already exists in finishedFilterFormatted
  const existingBet = finishedFilterFormatted.find(item => item.GroupBetID === bet.GroupBetID);

  // If the GroupBetID doesn't exist, create a new entry
  if (!existingBet) {
      finishedFilterFormatted.push({
          GroupBetID: bet.GroupBetID,
          BetStyle: bet.BetStyle,
          Wager: bet.Wager,
          GroupResult: bet.GroupResult,
          GroupDecimalOdds: bet.GroupDecimalOdds,
          Payout: bet.Payout,
          Net: bet.Net,
          IndividualBetInfo: [{
              IndividualGameID: bet.IndividualGameID,
              IndividualBetID: bet.IndividualBetID,
              IndividualBetResult: bet.IndividualBetResult,
              BetType: bet.BetType,
              BetChoice: bet.BetChoice,
              Line: bet.Line,
              Odds: bet.Odds,
              TeaserPoints: bet.TeaserPoints,
              GameStatus: bet.GameStatus,
              IsGameOver: bet.IsGameOver
          }]
      });
  } else {
      // If the GroupBetID already exists, add individual bet information to the existing entry
      existingBet.IndividualBetInfo.push({
          IndividualGameID: bet.IndividualGameID,
          IndividualBetID: bet.IndividualBetID,
          IndividualBetResult: bet.IndividualBetResult,
          BetType: bet.BetType,
          BetChoice: bet.BetChoice,
          Line: bet.Line,
          Odds: bet.Odds,
          TeaserPoints: bet.TeaserPoints,
          GameStatus: bet.GameStatus,
          IsGameOver: bet.IsGameOver
      });
  }
});

console.log("Finished Formatted Bets: This is finishedFilterFormatted:", finishedFilterFormatted);
console.log("Adjusted Bankroll:  This is adjustedBankroll:", adjustedBankroll);


  return { adjustedBankroll, finishedFilterFormatted, filteringCriteria };
}





/**
* Collects information about group bets from filtered bets and returns a map of unique values along with their counts and percentages.
* @param {Array} filteredBets - Array containing filtered group bet data.
* @param {string} infoToCollect - The information to collect from group bets.
* @returns {Object} - An object containing unique values as keys. Each value is an object with 'count' representing the number of occurrences and 'percentage' representing the percentage of occurrences out of the total count of filtered bets.
*/

async function collectGroupBetInfo(filteredBets, infoToCollect) {
  // Initialize an empty object to store unique values and their counts
  const valueCountMap = {};
  const totalCount = filteredBets.length;

  // Iterate over each filtered group bet
  for (const groupBet of filteredBets) {
      // Get the value of the specified information to collect
      const value = groupBet[infoToCollect];

      // If the value is not undefined, update its count in the valueCountMap
      if (value !== undefined) {
          // If the value already exists in the map, increment its count
          if (valueCountMap.hasOwnProperty(value)) {
              valueCountMap[value]++;
          } else {
              // If the value doesn't exist, initialize its count to 1
              valueCountMap[value] = 1;
          }
      }
  }

  // Calculate the percentage for each value
  for (const key in valueCountMap) {
      if (valueCountMap.hasOwnProperty(key)) {
          valueCountMap[key] = { count: valueCountMap[key], percentage: ((valueCountMap[key] / totalCount) * 100).toFixed(1) };
      }
  }

  // Return the map containing unique values, their counts, and percentages
  return valueCountMap;
}







/**
* Collects information about individual bets from filtered bets and returns a map of unique values along with their counts and percentages.
* @param {Array} filteredBets - Array containing filtered group bet data.
* @param {string} infoToCollect - The information to collect from individual bets.
* @returns {Object} - An object containing unique values as keys. Each value is an object with 'count' representing the number of occurrences and 'percentage' representing the percentage of occurrences out of the total count of filtered bets.
*/
async function collectIndividualBetInfo(filteredBets, infoToCollect) {
  // Initialize an object to store unique values and their counts
  const valueCountMap = {};
  const totalCount = filteredBets.reduce((total, bet) => total + bet.IndividualBetInfo.length, 0);

  // Iterate over each filtered group bet
  for (const bet of filteredBets) {
      // Iterate over each individual bet within the group bet
      for (const individualBet of bet.IndividualBetInfo) {
          // Get the value of the specified information to collect
          const value = individualBet[infoToCollect];

          // If the value is not undefined, update its count in the valueCountMap
          if (value !== undefined) {
              // If the value already exists in the map, increment its count
              if (valueCountMap.hasOwnProperty(value)) {
                  valueCountMap[value].count++;
              } else {
                  // If the value doesn't exist, initialize its count to 1
                  valueCountMap[value] = { count: 1 };
              }
          }
      }
  }

  // Calculate the percentage for each value
  for (const key in valueCountMap) {
      if (valueCountMap.hasOwnProperty(key)) {
          valueCountMap[key].percentage = ((valueCountMap[key].count / totalCount) * 100).toFixed(1);
      }
  }

  // Return the map containing unique values, their counts, and percentages
  return valueCountMap;
}



/**
* Retrieves information about bets from filtered bets based on the specified key.
* If the key corresponds to group bet data, it uses the collectGroupBetInfo function;
* otherwise, it uses the collectIndividualBetInfo function.
* @param {Array} filteredBets - Array containing filtered bet data.
* @param {string} key - The key for which information needs to be retrieved.
* @returns {Object} - An object containing unique values as keys and their corresponding counts as values.
*/
async function getBetsInfo(filteredBets, key) {
  // List of key values to use collectGroupBetInfo function
  const groupBetKeys = ["GroupBetID", "BetStyle", "Wager", "GroupResult", "GroupDecimalOdds", "Payout", "Net"];

  // Check if the key is in the groupBetKeys array
  if (groupBetKeys.includes(key)) {
      // Call collectGroupBetInfo function
      return await collectGroupBetInfo(filteredBets, key);
  } else {
      // Call collectIndividualBetInfo function for other keys
      return await collectIndividualBetInfo(filteredBets, key);
  }
}





/**
* Calculates various bankroll values based on filtered bets.
* @param {Array} filteredBets - Array containing filtered bet data.
* @returns {Object} - An object containing settled and pending bankroll values.
*/
async function FilteredBankrollValues(filteredBets) {
  // Initialize variables to store bankroll values
  let SettledWagers = 0;
  let SettledPayouts = 0;
  let SettledNet = 0;
  let PendingWagers = 0;
  let PendingPotentialPayouts = 0;
  let PendingPotentialNet = 0;

  // Iterate through each filtered bet
  for (const bet of filteredBets) {
      // Check if the bet is settled
      if (bet.GroupResult !== "Pending") {
          // Add settled wagers, payouts, and net
          SettledWagers += bet.Wager;
          SettledPayouts += (bet.Payout !== null) ? bet.Payout : 0;
          SettledNet += bet.Net;
      } else {
          // Add pending wagers and calculate pending potential payouts
          PendingWagers += bet.Wager;
          PendingPotentialPayouts += (bet.GroupDecimalOdds * bet.Wager);
      }
  }

  // Calculate pending potential net
  PendingPotentialNet = PendingPotentialPayouts - PendingWagers;

  // Round the values to two decimal places
  SettledPayouts = parseFloat(SettledPayouts.toFixed(2));
  SettledNet = parseFloat(SettledNet.toFixed(2));
  PendingPotentialPayouts = parseFloat(PendingPotentialPayouts.toFixed(2));
  PendingPotentialNet = parseFloat(PendingPotentialNet.toFixed(2));

  // Return bankroll values
  return {
      SettledWagers,
      SettledPayouts,
      SettledNet,
      PendingWagers,
      PendingPotentialPayouts,
      PendingPotentialNet
  };
}

/**
 * Retrieves the result of an individual bet, which could be Pending, Void, Win, Loss, or Push.
 * @param {Array} userSelections - Array of user bet data containing information about individual bets.
 * @param {Array} nbaGamesByDate - Array of college basketball game data sorted by date.
 * @param {string} individualBetID - The unique identifier of the individual bet.
 * @returns {Object} - An object containing information about the bet and its result.
 */
//Function that will get an Individual Bet result.  Either Pending, Void, Win, Loss, Push
async function IndividualBetResult(userSelections, nbaGamesByDate, individualBetID) {
  // Initialize variables to store bet data and result
  let betData = null;
  let result = null;


  console.log(userSelections);
  // Iterate over each bet in the userSelections array
  for (const bet of userSelections) {
      // Iterate over each individual bet within the current bet
      for (const individualBet of bet.IndividualBets) {
          // Check if the current individual bet ID matches the specified individualBetID
          if (individualBet.IndividualBetID === individualBetID) {
              // Find the game associated with the individual bet in the nbaGamesByDateData array
              const globalGameID = individualBet.GameID;
              const game = nbaGamesByDate.find(game => game.GlobalGameID === globalGameID);

              // If the game is found
              if (game) {
                  // Extract relevant game information
                  const gameStatus = game.Status;
                  const isGameOver = game.IsClosed;

                  // Determine the result of the individual bet based on game status and outcome
                  if (gameStatus === "Cancelled" || gameStatus === "Forfeit" || gameStatus === "Postponed" || gameStatus === "Suspended") {
                      // If the game is cancelled, forfeited, postponed, or suspended, the bet result is "Void"
                      result = "Void";
                  } else if (!isGameOver || (isGameOver && (gameStatus !== "Final" && gameStatus !== "F/OT"))) {
                      // If the game is not over or if it's over but not finalized, the bet result is "Pending"
                      result = "Pending";
                  } else {
                      // If the game is over and finalized, calculate the result of the bet
                      result = await calculateResult(individualBet.BetType, game.AwayTeamScore, game.HomeTeamScore, individualBet.BetChoice, individualBet.Line, individualBet.TeaserPoints, gameStatus, isGameOver);
                  }

                  // Store bet data including relevant game information
                  betData = {
                      globalGameID,
                      gameID: individualBet.GameID,
                      betType: individualBet.BetType,
                      betChoice: individualBet.BetChoice,
                      line: individualBet.Line,
                      teaserPoints: individualBet.TeaserPoints,
                      gameStatus,
                      isGameOver,
                      awayScore: game.AwayTeamScore,
                      homeScore: game.HomeTeamScore
                  };

                  // Exit the loop once the match is found
                  break;
              }
          }
      }

      // If betData is found, exit the loop
      if (betData) {
          break;
      }
  }

  // Return an object containing betData and result
  return { ...betData, result };
}


const createUserBets = (data, bankroll, GameSegment) => {
  console.log('what we are creating', data, groupWager);
  let BetStyle = "Single";
  const gatherUserBets = [];

  gatherUserBets['BetStyle'] = "Single";
  gatherUserBets['GroupBetID'] = newUUID;
  gatherUserBets['Wager'] = parseFloat(groupWager);
  gatherUserBets['IndividualBets'] = [];
  gatherUserBets['Payout'] = parseFloat(0); //SET POST GAME START
  gatherUserBets['Net'] = parseFloat(0); //SET POST GAME START
  gatherUserBets['GroupBetResult'] = "Pending";
  gatherUserBets['GroupBetSettled'] = false;
  gatherUserBets['GroupAmericanOdds'] = teaserOdds?.AmericanOdds;
  gatherUserBets['GroupDecimalOdds']= teaserOdds?.DecimalOdds;

  for(let i = 0; i < data.length; i++){
    let constructBet = {};

    if(BetStyle === "Single"){

      constructBet['GroupBetID'] = newUUID;
      constructBet['BetStyle'] = "Single";
      constructBet['Wager'] = data[i].Wager;
      constructBet['IndividualBets'] = data[i];


    }
    gatherUserBets.IndividualBets.push(constructBet);
  };



  console.log('what user bets will look like', gatherUserBets);
  return gatherUserBets;
};


const runAllIndividualBetChecks = async () => {

   // Calculate the result of an Individual Bet
   const result = await IndividualBetResult(userBets[0].Bets, nbaGamesByDate, 123700);
   //console.log(result);
   //console.log(result.result);

   // Check if the Individual Bet is Settled
   const settled = await IndividualBetSettled(result);
   //console.log("IndividualBetSettled:", settled);

   // Calculate group bet result
   const GroupBetID = 12370; // Specify the GroupBetID
   const groupResult = await GroupBetResult(userBets[0].Bets, GroupBetID);
   //console.log("GroupBetResult:", groupResult); // Output the group bet result

   // Check if the group bet is settled
   const groupSettled = await GroupBetSettled(groupResult);
   //console.log("GroupBetSettled:", groupSettled);



 const GroupBetID3 = 12373
   //Testing Single Odds Function
    const decimalOddTest = await SingleBetDecimalOdds(userBets[0].Bets, GroupBetID3);
   if (decimalOddTest !== undefined) {
       //console.log(`Decimal odds for GroupBetID ${GroupBetID3}: ${decimalOddTest}`);
   } else {
       console.log("Error retrieving decimal odds for GroupBetID 12374");
   }


 // Calculate Parlay Bet Decimal Odds
   const parlayBetDecimalOdds = await ParlayBetDecimalOdds(userBets[0].Bets, nbaGamesByDate, 12370);
   if (parlayBetDecimalOdds !== undefined) {
       //console.log(`Decimal odds for Parlay GroupBetID ${GroupBetID}: ${parlayBetDecimalOdds}`);
   } else {
       console.log(`Error retrieving decimal odds for Parlay GroupBetID ${GroupBetID}`);
   }

 const GroupBetID2 = 12369;
 // Calculate the count of non-push or void bets in the teaser
   const count = await TeaserNonPushOrVoidCount(userBets[0].Bets, nbaGamesByDate, GroupBetID2);
   //console.log(`Count of non-push or void bets for GroupBetID ${GroupBetID2}: ${count}`);


  // Get the teaser points for the group bet
   const teaserPoints = await TeaserBetTeaserPoints(userBets[0].Bets, GroupBetID2);

   if (teaserPoints !== undefined) {
       //console.log(`Teaser points for GroupBetID ${GroupBetID2}: ${teaserPoints}`);
   } else {
       console.log(`Error retrieving teaser points for GroupBetID ${GroupBetID2}`);
   }

   // Calculate the teaser bet decimal odds
   const teaserBetDecimalOdds = await TeaserBetDecimalOdds(count, teaserPoints, "Basketball");
   if (teaserBetDecimalOdds !== undefined) {
       //console.log(`Teaser bet decimal odds for GroupBetID ${GroupBetID2}: ${teaserBetDecimalOdds}`);
   } else {
       console.log(`Error retrieving teaser bet decimal odds for GroupBetID ${GroupBetID}`);
   }


 //Checking the Group Decimal Odds
 const groupBetIDtest = 12373; // Specify the GroupBetID
   const sporttest = "Basketball"; // Specify the sport
   try {
       const groupBetDecimalOddstest = await GroupBetDecimalOdds(userBets[0].Bets, nbaGamesByDate, groupBetIDtest, sporttest);
       //console.log(`Decimal odds for GroupBetID ${groupBetIDtest}: ${groupBetDecimalOddstest}`);
   } catch (error) {
       console.log(`Error retrieving decimal odds for GroupBetID ${groupBetIDtest}: ${error.message}`);
   }

 //Checking the Payout and Net
 try {
   const payoutAndNetTest = await PayoutAndNet(userBets[0].Bets, groupBetIDtest, sporttest, nbaGamesByDate);
   //console.log(`Payout and Net for GroupBetID ${groupBetIDtest}:`, payoutAndNetTest);
} catch (error) {
   console.log(`Error calculating payout and net for GroupBetID ${groupBetIDtest}: ${error.message}`);
}

 // Calculating Bankroll
   const filterCriteria = {BetStyle: ["Single"]};


   const bankrollTest = await Bankroll(userBets[0].Bets, parseFloat(1000), "Basketball", nbaGamesByDate, filterCriteria);
   console.log("Bankroll:", bankrollTest);
   const AdjustedBankroll = bankrollTest.adjustedBankroll;
   console.log("Adjusted Bankroll:", AdjustedBankroll);
   const FilteredBets = bankrollTest.finishedFilterFormatted;
  console.log("Filtered Bets:", FilteredBets);
   const WhatIsTheFilter = bankrollTest.filteringCriteria;
   console.log("The filters were:", WhatIsTheFilter);



 const uniqueBetStyles = await getBetsInfo(FilteredBets, 'BetStyle');
console.log("Bet Styles by User: ", uniqueBetStyles);

const uniqueGroupBetResults = await getBetsInfo(FilteredBets, 'GroupResult');
console.log("GROUP Bet Results for User: ", uniqueGroupBetResults);

const uniqueBetTypes = await getBetsInfo(FilteredBets, 'BetType');
console.log("Type of Bets user has made in an Ind Bet: ", uniqueBetTypes);

const uniqueBetChoices = await getBetsInfo(FilteredBets, 'BetChoice');
console.log("Bet Choice user has made an Ind Bet: ", uniqueBetChoices);


 FilteredBankrollValues(FilteredBets).then(filteredBankrollValues => {
   console.log("Settled Wagers:", filteredBankrollValues.SettledWagers);
   console.log("Settled Payouts:", filteredBankrollValues.SettledPayouts);
   console.log("Settled Net:", filteredBankrollValues.SettledNet);
   console.log("Pending Wagers:", filteredBankrollValues.PendingWagers);
   console.log("Pending Potential Payouts:", filteredBankrollValues.PendingPotentialPayouts);
   console.log("Pending Potential Net:", filteredBankrollValues.PendingPotentialNet);
});




}

// runAllIndividualBetChecks();


// Example usage
const betsToDisplay = constructBetsToDisplay(filteredGameIds, nbaGameOddsByDate, NBATeams, nbaGamesByDate, nbaStadiums);
console.log(betsToDisplay);


    const contextValue = {
        betsToDisplay,
        sportsBookNav,
        setSportsBookNav,
        userSelections,
        setUserSelections,
        submitBet,
        checkParlay,
        checkTeaserConditions,
        parlayMessage,
        setParlayMessage,
        teaserMessage,
        setTeaserMessage,
        bankrollAmount,
        setBankRollAmount,
        sportsInUserSelections,
        filteredDropdownMenu,
        teaserOddsCalculations,
        orderValueCount,
        createDropdownValues,
        teaserDropDown,
        setTeaserDropDown,
        winningTotal,
        setWinningTotal,
        createUserBets,
        groupWager,
        setGroupWager,
        teaserOdds,
        setTeaserOdds,
        caclulateAmericanToDecimalOdds,
        caclulateDecimalOddsToAmerican
    };

    return (

            <SportsBookContext.Provider value={contextValue}>{children}</SportsBookContext.Provider>
    );
}