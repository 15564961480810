import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useCFBNames = () => {
    return useMutation({
        mutationFn: cfbNamesRequest,
        onError: (error) => {
        console.error('Error getting CFB Names:', error);
        },
        // onSuccess: (data) => {
        // console.log('Get CFB Names:', data);
        // }
    });
}

const cfbNamesRequest = async () => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-cfb-names`, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });
    return response.data;
}