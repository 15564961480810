import React from 'react';
import Typography from '@mui/material/Typography';

const BracketRulesMulligenMadness = () => {
    return (
        <div>
            <Typography variant="h2">Premise</Typography>
            <Typography>
                Competitors will predict the winners for all 15 games of the NCAA Men’s Tournament, starting with the Sweet 16 games through the National Championship game.
            </Typography>

            <Typography variant="h2">How to Play</Typography>
            <Typography>
                At the completion of the second round games on Sunday March 24, 2024, the Sweet 16 field will be set. Competitors will select their winners for the remaining 15 games from the Sweet 16 through the National Championship game.
            </Typography>
            <Typography>
                Picks will lock at the start of the Sweet 16 on Thursday March 28, 2024. Competitors now root for their picks and watch the games and leaderboard update in real-time.
            </Typography>

            <Typography variant="h2">Rules</Typography>
            <Typography>
                Each registered account can create one (1) entry into Mulligan Madness.
            </Typography>
            <Typography>
                The pick window will be from Monday, March 25th through the start of the first game in the Sweet 16 round on Thursday, March 28th. Picks will not be able to be made outside of this window.
            </Typography>
            <Typography>
                Competitors will also predict the final score for the National Championship game on April 8th, 2024. Competitors will enter a predicted score for each of the teams. These amounts will be summed to determine your Total Tiebreaker.
            </Typography>
            <Typography>
                The Total Tiebreaker will be used in the event there is a tie between two or more competitors for any Prize offered in connection with this competition.
            </Typography>
            <Typography>
                Tie: A “Tie” occurs when the validly submitted Contest Brackets of two or more competitors each receive the same score. If this occurs in a Prize position, tiebreaker points will be used to determine a winner. Closest to the actual points scored during the National Championship game on April 8th, 2024 will win the tiebreaker.
            </Typography>

            <Typography variant="h2">Scoring</Typography>
            <Typography>
                Sweet 16: 1 point for each correctly picked winner.
            </Typography>
            <Typography>
                Elite 8: 2 points for each correctly picked winner.
            </Typography>
            <Typography>
                Final Four: 4 points for each correctly picked winner.
            </Typography>
            <Typography>
                National Championship: 8 points for correctly picked winner.
            </Typography>

            <Typography variant="h2">Prizes</Typography>
            <Typography>
                1st Place: TBD
            </Typography>
            <Typography>
                2nd Place: TBD
            </Typography>
            <Typography>
                3rd Place: TBD
            </Typography>
        </div>
    );
};

export default BracketRulesMulligenMadness;
