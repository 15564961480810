import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useDispatch } from "react-redux";
import { useGetQuickPickData } from "../../../hooks/data/useGetQuickPickData";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import { useLocation } from "react-router-dom";
import { has } from "lodash";

const QuickPickGamesContext = createContext();

export const useQuickPickGamesContext = () => {
    const context = useContext(QuickPickGamesContext);
    if (!context) {
        throw new Error("useQuickPickGamesContext must be used within a QuickPickGamesProvider");
    }
    return context;
}

export const QuickPickGamesProvider = ({ children }) => {
    const {
        gameData,
        masterPoolData,
        pickState,
        hasJoinDeadlinePassed,
        leaderboardData,
        setInterval,
        gameAddress,
        page,
        masterPoolId,
        gameId,
        pickIndex,
    } = useUserAndGameContext();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const interval = searchParams.get('interval');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const redisKeys = masterPoolData?.redisKeys;

    const [hasDataFetched, setHasDataFetched] = useState(false);
    const [hasTheCurrentIntervalStarted, setHasTheCurrentIntervalStarted] = useState(null);
    const [hasTheLastGameOfCurrentIntervalStarted, setHasTheLastGameOfCurrentIntervalStarted] = useState(null);

    const { mutate: getQuickPickData, data: quickPickData, isLoading: quickPickDataLoading, isError: quickPickDataError, error: quickPickDataErrorData } = useGetQuickPickData();

    //UseEffect to get quick pick data
    useEffect(() => {
        if (!hasDataFetched && redisKeys) {
            getQuickPickData({ redisKeys });
            setHasDataFetched(true);
        }
    }, [hasDataFetched, redisKeys, getQuickPickData]);

    // Error handling for quick pick data
    useEffect(() => {
        if (quickPickDataError) {
            console.error("Error fetching quick pick data:", quickPickDataErrorData);
            if (quickPickDataErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                navigate('/login');
            } else {
                navigate('/404/pool-hall');
            }
        }
    }, [quickPickDataError, quickPickDataErrorData, dispatch, navigate]);

    const { fullSchedule, teams, quickPickGames, currentInterval, standings } = quickPickData || {};

    const gamesToInclude = useMemo(() => {
        return gameData?.gameParameters?.gamesToInclude || masterPoolData?.gameParameters?.gamesToInclude || [];
    }, [gameData?.gameParameters?.gamesToInclude, masterPoolData?.gameParameters?.gamesToInclude]);

    const schedule = useMemo(() => {
        if (!fullSchedule || !gamesToInclude) return [];
        return fullSchedule.filter(game => gamesToInclude.includes(game.GlobalGameID));
    }, [fullSchedule, gamesToInclude]);

    const firstInterval = useMemo(() => {
        return gameData?.gameParameters?.firstInterval || masterPoolData?.gameParameters?.firstInterval || 0;
    }, [gameData?.gameParameters?.firstInterval, masterPoolData?.gameParameters?.firstInterval]);

    const lastInterval = useMemo(() => {
        return gameData?.gameParameters?.lastInterval || masterPoolData?.gameParameters?.lastInterval || 0;
    }, [gameData?.gameParameters?.lastInterval, masterPoolData?.gameParameters?.lastInterval]);

    const propIdsToUse = useMemo(() => {
        return gameData?.gameParameters?.propIdsToUse || masterPoolData?.gameParameters?.propIdsToUse || [];
    }, [gameData?.gameParameters?.propIdsToUse, masterPoolData?.gameParameters?.propIdsToUse]);

    const theCurrentInterval = useMemo(() => {
        if (!currentInterval) return {};
        if (currentInterval >= firstInterval && currentInterval <= lastInterval) {
            return currentInterval;
        } else if (currentInterval < firstInterval) {
            return firstInterval;
        } else if (currentInterval > lastInterval) {
            return lastInterval;
        } 
    }, [currentInterval, firstInterval, lastInterval]);
    
    // Use Effect to set interval from url params
    // If interval is not in url params, set interval to current interval
    // If interval is outside of first and last interval, set interval to current interval
    useEffect(() => {
        if (page !== 'my-picks' && page !== 'leaderboard' && page !== 'pool-picks') return;
        
        // Determine which interval to use
        let selectedInterval;
        
        if (interval) {
            // If interval is explicitly provided in URL, validate it's within range
            const isIntervalValid = (interval >= firstInterval && interval <= lastInterval);
            selectedInterval = isIntervalValid ? interval : firstInterval;
        } else {
            // No interval in URL, strictly check if currentInterval is valid
            const isCurrentIntervalValid = (currentInterval >= firstInterval && currentInterval <= lastInterval);
            selectedInterval = isCurrentIntervalValid ? currentInterval : firstInterval;
        }
        
        // Set the interval state
        setInterval(selectedInterval);
        
        // Navigate with the selected interval
        navigate(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=${page}&interval=${selectedInterval}&pickIndex=${pickIndex}`);
        
    }, [interval, currentInterval, setInterval, firstInterval, lastInterval, masterPoolId, gameId, gameAddress, pickIndex, navigate, page]);


    const isGameOver = masterPoolData?.gameParameters?.isGameOver || false;
    const oneWeekEvent = useMemo(() => {
        return firstInterval === lastInterval;
    }, [firstInterval, lastInterval]);

    // Get game IDs for the current interval from propIdsToUse
    const gameIdsForInterval = useMemo(() => {
        if (!propIdsToUse || !interval) return [];
        
        return propIdsToUse
            .filter(item => Number(item.interval) === Number(interval))
            .map(item => item.gameId);
    }, [propIdsToUse, interval]);

    // Filter schedule based on the selected interval's game IDs
    const filteredSchedule = useMemo(() => {
        if (!schedule || !gameIdsForInterval.length) return [];
        
        const filteredGames = schedule.filter(game => 
            gameIdsForInterval.includes(game.GlobalGameID)
        );
        
        return filteredGames.sort((a, b) => a.DateTime - b.DateTime);
    }, [schedule, gameIdsForInterval]);

    // Get filtered quickPickGames based on interval and gameIds
    const filteredQuickPickGames = useMemo(() => {
        if (!quickPickGames || !gameIdsForInterval.length) return [];
        
        const thisIntervalsGames = quickPickGames.filter(game => 
            gameIdsForInterval.includes(game.GlobalGameID)
        );
    
        thisIntervalsGames.sort((a, b) => 
            new Date(a.DateTime) - new Date(b.DateTime)
        );
    
        return thisIntervalsGames;
    }, [quickPickGames, gameIdsForInterval]);
    
    // Get filtered propIds for the selected interval
    const filteredPropIds = useMemo(() => {
        if (!propIdsToUse || !interval) return [];
        
        // Get only the propIdsToUse entries for the current interval
        const intervalProps = propIdsToUse.filter(item => 
            Number(item.interval) === Number(interval)
        );
        
        // Flatten all propIdsToUse arrays into a single array of IDs
        return intervalProps.flatMap(item => item.propIdsToUse);
    }, [propIdsToUse, interval]);

    // Get game IDs for the current active interval
    const gameIdsForCurrentInterval = useMemo(() => {
        if (!propIdsToUse || !theCurrentInterval) return [];
        
        return propIdsToUse
            .filter(item => Number(item.interval) === Number(theCurrentInterval))
            .map(item => item.gameId);
    }, [propIdsToUse, theCurrentInterval]);

    // Get filtered propIds for the current active interval
    const filteredPropIdsForTheCurrentInterval = useMemo(() => {
        if (!propIdsToUse || !theCurrentInterval) return [];
        
        return propIdsToUse.filter(item => 
            Number(item.interval) === Number(theCurrentInterval)
        );
    }, [propIdsToUse, theCurrentInterval]);

    // Calculate total picks for the current active interval
    const picksForTheCurrentInterval = useMemo(() => {
        const totalPicks = filteredPropIdsForTheCurrentInterval.reduce((acc, game) => {
            // Check if totalProps exists and use it, otherwise use propIdsToUse.length
            const propsCount = game.totalProps !== undefined ? game.totalProps : game.propIdsToUse.length;
            return acc + propsCount;
        }, 0);
        
        return totalPicks;
    }, [filteredPropIdsForTheCurrentInterval]);

    // Calculate total picks across all intervals
    const picksForAllIntervals = useMemo(() => {
        const totalPicks = propIdsToUse.reduce((acc, game) => {
            // Check if totalProps exists and use it, otherwise use propIdsToUse.length
            const propsCount = game.totalProps !== undefined ? game.totalProps : game.propIdsToUse.length;
            return acc + propsCount;
        }, 0);
        
        return totalPicks;
    }, [propIdsToUse]);

    // Use Effect to determine if the current interval has started and ended
    useEffect(() => {
        if (!filteredSchedule) {
            // Default values to prevent loading screen if no schedule is available
            setHasTheCurrentIntervalStarted(false);
            setHasTheLastGameOfCurrentIntervalStarted(false);
            return;
        }
        
        if (!filteredSchedule.length) {
            // If we have an empty array (valid but no games), set defaults
            setHasTheCurrentIntervalStarted(false);
            setHasTheLastGameOfCurrentIntervalStarted(false);
            return;
        }

        const currentIntervalStart = filteredSchedule[0]?.DateTime;
        const currentIntervalEnd = filteredSchedule[filteredSchedule.length - 1]?.DateTime;

        // Make sure we have date objects to compare
        if (currentIntervalStart && currentIntervalEnd) {
            const hasTheCurrentIntervalStarted = timeNowInNewYork > currentIntervalStart;
            const hasTheLastGameOfCurrentIntervalStarted = timeNowInNewYork > currentIntervalEnd;

            setHasTheCurrentIntervalStarted(hasTheCurrentIntervalStarted);
            setHasTheLastGameOfCurrentIntervalStarted(hasTheLastGameOfCurrentIntervalStarted);
        } else {
            // Set defaults if dates are not available
            setHasTheCurrentIntervalStarted(false);
            setHasTheLastGameOfCurrentIntervalStarted(false);
        }
    }, [filteredSchedule, timeNowInNewYork]);

    const usingTiebreaker = useMemo(() => {
        return gameData?.gameParameters?.usingTiebreaker || masterPoolData?.gameParameters?.usingTiebreaker || false;
    }, [gameData?.gameParameters?.usingTiebreaker, masterPoolData?.gameParameters?.usingTiebreaker]);
    
    // Calculate picks for the selected interval
    const picksThisInterval = useMemo(() => {
        const intervalProps = propIdsToUse.filter(item => 
            Number(item.interval) === Number(interval)
        );

        const totalPicks = intervalProps.reduce((acc, game) => {
            // Check if totalProps exists and use it, otherwise use propIdsToUse.length
            const propsCount = game.totalProps !== undefined ? game.totalProps : game.propIdsToUse.length;
            return acc + propsCount;
        }, 0);

        return totalPicks;
    }, [propIdsToUse, interval]);

    //console.log("Schedule", schedule, "Filtered Schedule", filteredSchedule, "Quick Pick Games", quickPickGames, "Filtered Quick Pick Games", filteredQuickPickGames, "Game IDs for Interval", gameIdsForInterval, "Filtered Prop IDs", filteredPropIds, "Using Tiebreaker", usingTiebreaker, "Picks This Interval", picksThisInterval, "Picks for the Current Interval", picksForTheCurrentInterval, "Has the Current Interval Started", hasTheCurrentIntervalStarted, "Has the Last Game of Current Interval Started", hasTheLastGameOfCurrentIntervalStarted);
    
    const QuickPickGamesContextValues = useMemo(() => ({
        schedule,
        teams,
        standings,
        quickPickGames,
        theCurrentInterval,
        isGameOver,
        firstInterval,
        lastInterval,
        propIdsToUse,
        oneWeekEvent,
        filteredSchedule,
        filteredQuickPickGames,
        gameIdsForInterval,
        filteredPropIds,
        usingTiebreaker,
        picksThisInterval,
        picksForTheCurrentInterval,
        hasTheCurrentIntervalStarted,
        hasTheLastGameOfCurrentIntervalStarted,
    }), [schedule, teams, standings, quickPickGames, theCurrentInterval, isGameOver, firstInterval, lastInterval, propIdsToUse, oneWeekEvent, filteredSchedule, filteredQuickPickGames, gameIdsForInterval, filteredPropIds, usingTiebreaker, picksThisInterval, picksForTheCurrentInterval, hasTheCurrentIntervalStarted, hasTheLastGameOfCurrentIntervalStarted]);

    // Only show loading screen if we're still loading core data
    if (!schedule || !teams || !quickPickGames) {
        return <LoadingScreen />;
    };

    return (
        <QuickPickGamesContext.Provider value={QuickPickGamesContextValues}>
            {children}
        </QuickPickGamesContext.Provider>
    );
}

export default QuickPickGamesContext;