import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from "@mui/material";

const RemoveEntryModal = ({
  open,
  onClose,
  indexToRemove,
  myUsername,
  gameName,
  removeEntry,
  setUsernameToRemove,
  hasSubmitted,
}) => {
  const handleRemove = () => {
    removeEntry(indexToRemove, displayUsername);
  };

  const displayUsername = indexToRemove === 0 
    ? myUsername 
    : `${myUsername} (${Number(indexToRemove) + 1})`;

    //Use Effect to set the username to remove
    useEffect(() => {
        setUsernameToRemove(displayUsername);
    }, [displayUsername, setUsernameToRemove]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="remove-entry-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle 
        id="remove-entry-dialog-title"
        sx={{ 
          justifyContent: "center",
          alignItems: "center",
          padding: "16px 24px"
        }}
      >
        <Typography
            sx={{
                fontSize: "20px",
                color: '#002129',
                fontWeight: '700',
                textAlign: 'center',
            }}
        >
          Remove Entry
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px" }}>
        <Box sx={{ textAlign: "center", my: 2 }}>
          <Typography variant="body1">
            Are you sure that you want to remove {displayUsername} from {gameName}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px", justifyContent: 'center', gap: '16px' }}>
        <Button 
            onClick={onClose} 
            variant="outlined"
            sx={{ 
                color: "#002129", 
                height: '30px',
                fontSize: '12px',
                borderColor: "#002129",
                '&:hover': {
                borderColor: "#003a4a",
                backgroundColor: "rgba(0, 33, 41, 0.04)"
                }
            }}
            disabled={hasSubmitted}
        >
          CANCEL
        </Button>
        <Button 
            onClick={handleRemove} 
            variant="contained"
            sx={{ 
                backgroundColor: "#002129", 
                height: '30px',
                color: "white",
                fontSize: '12px',
                '&:hover': {
                backgroundColor: "#002129"
                }
            }}
            disabled={hasSubmitted}
        >
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveEntryModal;