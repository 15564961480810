import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import dataReducer from './redux/reducers/dataReducer';
import loginReducer from './redux/reducers/loginReducer';
import gamesReducer from './redux/reducers/gameReducer';
import App from './App';

const store = configureStore({
  reducer:  {
    login: loginReducer,
    sportsData: dataReducer,
    game: gamesReducer,
    // Add other reducers here if needed
  },
  devTools: process.env.NODE_ENV !== 'production', // Only enable DevTools in non-production environment
  middleware: [thunk]
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
      <Provider store={store}><App /></Provider>
    </BrowserRouter>
);