import React from "react";

const GatherNFLSurvivorInfo = () => {

    const UserDB = {
        "user_id" : "d4d83a58-0389-45d0-bcb4-8190f0191d20",
        "first_name" : "Adam",
        "last_name" : "Braithwaite",
        "user_name" : "PoolPartyDemo",
        "phone" : "14124184554",
        "email" : "abraithwaite03@gmail.com",
        "password" : "$2b$10$CNtCrNTL1JPwwVm6LBE6keaqNo1.qfZcG.Vg9mkBiiFqguMkQPxfW",
        "date_of_birth" : {
            "$date" : 611121600000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    "defaultParty" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                    "picks" : [
                        {
                            "gameId" : 18671,
                            "teamId" : 9,
                            "spread" : 1,
                            "dateTime" : "2024-09-08T16:25:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18674,
                            "teamId" : 30,
                            "spread" : -5,
                            "dateTime" : "2024-09-08T16:05:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Push"
                        },
                        {
                            "gameId" : 18682,
                            "teamId" : 23,
                            "spread" : -1,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18678,
                            "teamId" : 7,
                            "spread" : -9,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18675,
                            "teamId" : 2,
                            "spread" : -2.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        }
                    ]
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "97kcw983-7y45-2c90-l2x1-57dr3pmg7031",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            }
        ],
        "__v" : 0
    }

    
    function gatherPickEmInfo(gameId) {
        const username = UserDB?.user_name;
        const userFullName = `${UserDB?.first_name} ${UserDB?.last_name}`;
        const email = UserDB?.email;
        const userId = UserDB?.user_id;
        const games = UserDB?.Games || [];
        console.log("GAMES:", games);
        const pickEmGame = games?.find(game => game.gameID === gameId);
        const picks = pickEmGame?.pool?.picks || [];
        console.log("PICKS:", picks);

        let totalWins = 0;
        let totalMargin = 0;
        let doesUserHaveALoss = false;
        let lossWeek = null;

        picks.forEach(pick => {
            if (pick?.result === "Win") {
                totalWins++;
            } else if (pick?.result === "Loss") {
                doesUserHaveALoss = true;
                lossWeek = pick?.week;
            } 

            if (pick?.margin) {
                totalMargin += pick?.margin;
            }
        });

        return {
            userId,
            totalWins,
            doesUserHaveALoss,
            lossWeek,
            username,
            userFullName,
            email,
            picks,
            totalMargin
        }
    }

    const Test = gatherPickEmInfo("97kcw983-7y45-2c90-l2x1-57dr3pmg7031");
    console.log("NFL Survivor Info:", Test);

    return (
        <div>
            <h1>Test</h1>
        </div>
    )
}

export default GatherNFLSurvivorInfo;