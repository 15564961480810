import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const BracketNavBar = ({
    rounds,
    roundDash,
    setRoundDash,
    setRoundDashPosition,
    pickState,
    hasJoinDeadlinePassed,
    regionInfo,
}) => {
    console.log("regionInfo in BracketNavBar: ", regionInfo);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleNavClick = (round) => {
        const scrollFunction = setRoundDashPosition[round];
        
        if (scrollFunction && typeof scrollFunction === 'function') {
            scrollFunction();
        } 
        
        setRoundDash(round);
    }

    const howManyPicksThisRegion = (regionName) => {
        if (regionName === 'Final Four') {
            const picksMade = pickState?.filter(pick => pick.round === 5 || pick.round === 6)?.length;
            const picksToMake = 3;
            return { picksMade, picksToMake };
        } else {
            const picksMade = pickState?.filter(pick => pick.bracket === regionName)?.length;
            const picksToMake = 15;
            return { picksMade, picksToMake };
        }
    }


    return (
        <Box sx={{ 
            display: "flex", 
            flexDirection: "column",
            justifyContent: "space-evenly", 
            alignItems: "center", 
            padding: '8px', 
            backgroundColor: '#FFF', 
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' 
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: isMobile ? '99%' : '90%',
                    alignItems: 'center',
                    margin: '0 auto',
                }}
            >
                {rounds?.map((regionName, index) => {
                    const isSelected = roundDash === index + 1;
                    const pickInfo = howManyPicksThisRegion(regionName);
                    return (
                        <Button
                            key={index}
                            onClick={() => handleNavClick(index + 1)}
                            sx={{
                                textTransform: "none",
                                fontSize: "12px",
                                fontWeight: 700,
                                display: "flex",
                                flexDirection: "column", // Stack text vertically
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "8px 8px",
                                borderRadius: isSelected ? "4px" : "0px",
                                backgroundColor: isSelected ? "#D9D9D9" : "transparent",
                                fontFamily: "inherit",
                                transition: "background-color 0.3s ease",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                ":hover": {
                                    backgroundColor: isSelected ? "#D9D9D9" : "transparent", 
                                },
                            }}
                        >
                            {/* First line always #002129 */}
                            <Typography sx={{ color: "#002129", fontSize: "12px", fontWeight: 700 }}>
                                {regionName}
                            </Typography>

                            {/* Second line with conditional color */}
                            {!hasJoinDeadlinePassed && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <Typography 
                                        sx={{ 
                                            fontSize: '12px', 
                                            fontWeight: '700', 
                                            color: pickInfo?.picksMade === pickInfo?.picksToMake ? '#00AA72' : '#CC293C' 
                                        }}
                                    >
                                        {pickInfo?.picksMade}/{pickInfo?.picksToMake}
                                    </Typography>
                                    {pickInfo?.picksMade === pickInfo?.picksToMake ? (
                                        <CheckIcon sx={{ color: '#00AA72', width: '16px', height: '16px' }} />
                                    ) : (
                                        <CloseIcon sx={{ color: '#CC293C', width: '16px', height: '16px' }} />
                                    )}
                                </Box>
                            )}

                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
};

export default BracketNavBar;
