import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './BracketPoolPicks.scss';
import PercentagePillBoxTwoSided from '../../GamePageComponents/PercentagePillBox/PercentagePillBoxTwoSided';
import { getTeamLogo } from '../../../utils/SportsData';
import { getTeamNameShort } from '../../../utils/SportsData';

const PoolPicksMatchupBox = ({ game, league, teams }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("Game in PoolPicksMatchupBox: ", game);
    return (
        <Box 
            className="pool-picks-matchup-box"
            sx={{
                width: '100%',  // Take full width of grid item
                minWidth: { xs: '172px', md: '320px' },  // Maintain minimum width
                margin: '0 auto'  // Center in grid item
            }}
        >
            <Box className="pool-picks-matchup-box-row">
                <Box className="team-group">
                    <Box className={`pool-picks-matchup-box-seed ${isMobile ? 'mobile' : ''}`}>
                        <Typography className={isMobile ? 'mobile' : ''}>
                            {game?.teams?.away?.seed}
                        </Typography>
                    </Box>
                    <Box className="pool-picks-matchup-box-team">
                        <img
                            src={getTeamLogo(league, game?.teams?.away?.teamId, teams)}
                            alt="Team 1"
                            className={isMobile ? 'mobile' : ''}
                        />
                    </Box>
                </Box>
                <Typography className={`pool-picks-matchup-box-vs ${isMobile ? 'mobile' : ''}`}>
                    vs
                </Typography>
                <Box className="team-group">
                    <Box className={`pool-picks-matchup-box-seed ${isMobile ? 'mobile' : ''}`}>
                        <Typography className={isMobile ? 'mobile' : ''}>
                            {game?.teams?.home?.seed}
                        </Typography>
                    </Box>
                    <Box className="pool-picks-matchup-box-team">
                        <img
                            src={getTeamLogo(league, game?.teams?.home?.teamId, teams)}
                            alt="Team 2"
                            className={isMobile ? 'mobile' : ''}
                        />
                    </Box>
                </Box>
            </Box>
            <PercentagePillBoxTwoSided
                leftCount={game?.teams?.away?.pickCount || 0}
                rightCount={game?.teams?.home?.pickCount || 0}
                leftText={getTeamNameShort(league, game?.teams?.away?.teamId, teams)}
                rightText={getTeamNameShort(league, game?.teams?.home?.teamId, teams)}
                leftResult={game?.teams?.away?.result}
                rightResult={game?.teams?.home?.result}
            />
        </Box>
    );
}

export default PoolPicksMatchupBox;