import React from 'react';
import { useGameContext } from '../context/createGamesContext';

// import '../styles.scss';


const CreateGameAside = () => {
    const {gameTypeData,gameName, gameType, gameSport, gameLeague, duration, gameMembers} = useGameContext();

    const gameParamKeyNameDict = {
        gridName: 'Grid Name',
        numberOfBlocks: 'Number of Blocks',
        pickNumbersFor: 'Pick Numbers For',
        maxPicks: 'Maximum Picks Per Player',
        numbersGenerated: 'Generate Numbers',
        weekOfGame: 'Game Week',
        game: 'Game',
        awayTeam: 'Away Team',
        homeTeam: 'Home Team',
        gameStart: 'Game Start',
    };

    return(
        <div className="game-preview-box">
            <h2 style={{textAlign:"center"}}>Play By Play:</h2>
            <ul className="game-preview-list">
                <li>Game Name:  {gameName}</li>
                <li>Game Type: {gameType}</li>
                <li>Sport: {gameSport}</li>
                <li>League: {gameLeague}</li>
                {/* <li>Duration: {duration}</li> */}
                {/*TODO: MAKE TOGGLE */}
                <li>Member Total: {gameMembers.length}</li>
                <h3>Game Parameters:</h3>
                <li>
                {Object.entries(gameTypeData).map(([key, value]) => (
                    <div key={key}>
                    {key === 'gameSelection'?
                       <>
                        <p>{value}</p>
                       </>
                    :
                    <p>
                        {(gameParamKeyNameDict[key]?<>{gameParamKeyNameDict[key]}</>:
                        <>{key}</>)}: {value}
                    </p>
                    }
                    </div>
                ))}
                </li>

            </ul>
        </div>
    );
}
export default CreateGameAside;