import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useCFBGameOddsByWeek = () => {
    return useMutation({
        mutationFn: cfbGameOddsByWeekRequest,
        onError: (error) => {
        console.error('Error getting CFB Game Odds By Week:', error);
        },
        // onSuccess: (data) => {
        // console.log('Get CFB Game Odds By Week:', data);
        // }
    });
    }

const cfbGameOddsByWeekRequest = async () => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-cfb-game-odds-by-week`, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });
    return response.data;
}