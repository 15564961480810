import React from 'react';
import { useState } from 'react';
import { Box, Button, Typography, Modal, TextField, Link } from '@mui/material';
import { usePrivatePartyContext } from "./context/PrivatePartyContext";
import { useNavigate, useLocation } from 'react-router-dom';
import './PrivatePartyInviteStyles.scss';
import PrivatePoolPasscode from '../PrivatePools/privatePoolPasscode';
import { useJoinPrivatePool } from '../PrivatePools/hooks/useJoinPrivatePool';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const NotInPrivateParty = () => {
  const { partyData, joinParty, inviteeDisplayName, clubLogo, foundSubgroupName, clubDisplayName, poolStartDate, poolEndDate, isUserInThisGame, isUserInThisSubgroup, userData, gameId, gameType, gameAddress } = usePrivatePartyContext();
  const { mutate: joinPool } = useJoinPrivatePool();

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [passcode, setPasscode] = useState(new Array(4).fill(''));

  const [status, setStatus] = useState(null);//UI Purposes
  const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

  const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
  };

    // Extract the entire path after the base URL
  const pathAfterBaseUrl = location.pathname;

  //console.log('Path after base URL:', pathAfterBaseUrl);

  const handleJoinPool = async () => {
    let data = {
      gameID: gameId,
      password: passcode,
      userID: userData.user_id,
      gameType: gameType
    };

    joinPool(data, {
      onSuccess: (data) => {
        setStatus("success");
        console.log("Join pool success:", data);
        joinParty();
        // window.location.href = `/birdie-pool/${gameId}/SCORE_CARD/${foundSubgroupName}`;
      },
      onError: (error) => {
        setStatus("fail");
        setErrorMessage(error.response?.data?.message || 'Invalid passcode. Please try again.');
        console.error("Join pool failed:", error);
        // Clear the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage('');
          setStatus(null);
        }, 5000);
      }
    });
  };

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  const handleClick = async (passcode) => {
      //console.log("handleClick");
      if (!userData) {
          console.log("User not logged in");
          navigateToTop(`/login${pathAfterBaseUrl}`);
          return;
      }  else if (!isUserInThisGame) {
          console.log("User is not in the game. Let them join the game first");
          setOpen(true);
      }   else if (isUserInThisGame && !isUserInThisSubgroup) {
          console.log("User is in the game,but not in the subgroup. Let them join the private party");
          await joinParty();
          // window.location.href = `/birdie-pool/${gameId}/SCORE_CARD`;
      } else {
          console.log("NONE OF THE CONDITIONS MET. ERROR????");
      }
  };

  return (
    <>
        <Box className="container" style={{ maxWidth: '1116px', margin: '0 auto'}}>
          <Typography className="typography-main-message" style={{ padding: '15px 0', margin: '0 auto', fontSize: isMobile ? '20px' : '24px', fontWeight: '700', maxWidth: '90%'}}>
            Pool Host {inviteeDisplayName} <br/>invites you to the <br/>{foundSubgroupName} Party
          </Typography>
          <img src={clubLogo} alt="club logo" className="club-logo" style={{ display: 'block', margin: '0 auto', width: isMobile ? '94px' : '150px' }}/>
          <Typography className="typography-club-name" style={{ padding: '15px 0', fontSize: isMobile ? '20px' : '24px', fontWeight: '700'}}>
            {clubDisplayName}
          </Typography>
          <Button variant="contained" sx={{ mb: 2, fontSize: isMobile ? '16px' : '20px', marginTop: '10px' }} onClick={() => handleClick()}>
            JOIN PARTY
          </Button>
          <Typography className="dont-join" style={{ fontSize: isMobile ? '20px' : '24px', fontWeight: '600', marginTop: '10px' }}>
            {isUserInThisGame ? (
                <Link href={`/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(clubDisplayName)}`}>Not yet?</Link>
              ) : (
                <Link href="/private-pools/">Not yet?</Link>
            )}
          </Typography>
        </Box>

        {open && (
        <Modal
          open={open}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <Box className="modal-container" style={{ minHeight: '250px', maxHeight: '350px', maxWidth: '400px'}}>
            <Typography className="modal-title" style={{ fontSize: isMobile ? '28px' : '30px', fontWeight: '700', marginBottom: '20px'}}>Pool Pin</Typography>
            <Typography className="modal-message" style={{ fontSize: isMobile ? '22px' : '26px', marginBottom: '25px'}}>Enter the {clubDisplayName} pin to join.</Typography>
            {status === "fail" && <Typography className="error-message" style={{ color: 'red'}}>{errorMessage}</Typography>}
            <PrivatePoolPasscode handleJoinPool={handleJoinPool} gameID={gameId} passcode={passcode} setPasscode={setPasscode} setOpen={setOpen} setStatus={setStatus}/>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default NotInPrivateParty;
