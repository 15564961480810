import React from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import "./BracketGamesStyles.scss";
import { getTeamLogo } from "../../../../utils/SportsData/index.js";
import { getRankSuffix } from "../../../../utils/GetRankSuffix.js";
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext.js";
import { useNavigate, useSearchParams } from "react-router-dom";

const BracketMultipleEntryView = ({
    entries,
    totalEntriesCount,
    hasFirstGameStarted,
    picksToMake,
    league,
    teams,
    haveAllPicksBeenMade,
    findChampId,
    findChampResult,
    getChampBoxStyle,
    pickIndex,
    findMyRank,
    findMyPoints,
    findMyMaxPossiblePoints,
    myLeaderboardInfo,
    canIAddAnEntry,
    setJoinOpen,
    hasJoinDeadlinePassed,
    setIndexToRemove,
    setRemoveModalOpen,
    isUserAdmin,
    tiebreakerCount,
}) => {

    const {
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
        masterPoolId,
        gameId,
        page,
        gameAddress,
    } = useUserAndGameContext();

    //console.log("Entries:", entries);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Create a map to track loading states for each entry
    const [loadingStates, setLoadingStates] = React.useState(
        new Map(entries?.map(entry => [entry?.id || entry?.rank, { loading: true, error: false }]))
    );

    const handleImageLoad = (entryId) => {
        setLoadingStates(prev => new Map(prev).set(entryId, { loading: false, error: false }));
    };

    const handleImageError = (entryId) => {
        setLoadingStates(prev => new Map(prev).set(entryId, { loading: false, error: true }));
    };

    const getEntryNumber = (entryName) => {
        //console.log('Entry Name:', entryName)
        const match = entryName?.match(/\((\d+)\)$/); // Find (x) at the end
        return match ? Number(match[1]) : 1; // Return (x) as a number or default to 11
    };

    const handleAddEntryClick = () => {
        setJoinOpen(true);
    };

    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    };

    const handleEntryChange = (index) => {
        // If the current pickIndex already matches the clicked entry, do nothing
        if (Number(pickIndex) === index) {
            //console.log("Already on this entry, no change needed");
            return;
        }

        const newParams = new URLSearchParams(searchParams);
        // Update the parameters we want to change
        newParams.set('mId', masterPoolId);
        newParams.set('gId', gameId);
        newParams.set('page', page);
        newParams.set('pickIndex', index);

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(`/${gameAddress}?${newParams.toString()}`);
            setUnsavedPicksModalOpen(true);
            return;
        }
        
        navigate(`/${gameAddress}?${newParams.toString()}`);
        
    }
   
    return (
        <Box className="bracket-multiple-entry-container">
            {entries?.map((entry, index) => {
                const entryId = entry?.id || entry?.rank;
                //console.log('Entry:', entry);
                const entryNumber = getEntryNumber(entry?.name);
                const selectedEntry = entryNumber === (Number(pickIndex) + 1);
                //console.log('Entry Number:', entryNumber, "Selected Entry:", selectedEntry);
                const { loading, error } = loadingStates.get(entryId) || { loading: true, error: false };
                const champId = findChampId(entry?.pool?.picks, picksToMake);

                return (
                    <Box 
                        key={index} 
                        className="bracket-multiple-entry-content-wrapper"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box 
                            className="bracket-multiple-entry-content"
                            sx={{
                                border: selectedEntry ? '2px solid #002129' : '2px solid #78838a',
                                borderRadius: '8px',
                                opacity: selectedEntry ? 1 : 0.5,
                                cursor: 'pointer',
                            }}    
                            onClick={() => handleEntryChange(entryNumber - 1)}
                        >
                            <Box className="bracket-multiple-entry-column">
                                <Typography className="bracket-multiple-entry-header-text">
                                    Entry ({entryNumber})
                                </Typography>

                                <Typography className="bracket-multiple-entry-text">
                                    {getRankSuffix(findMyRank(myLeaderboardInfo, entry?.name))} : {findMyPoints(myLeaderboardInfo, entry?.name)} Pts
                                </Typography>
                                {!hasFirstGameStarted && (
                                        <Typography 
                                            className="bracket-multiple-entry-text-small"
                                            sx={{ color: haveAllPicksBeenMade(entry?.pool, picksToMake, tiebreakerCount) ? "#00AA72" : "#CC293C" }}    
                                        >
                                            Picks In?{" "}
                                            {haveAllPicksBeenMade(entry?.pool, picksToMake, tiebreakerCount) ? (
                                                <CheckIcon style={{ color: "#00AA72", fontSize: isMobile ? '18px' : '28px' }} />
                                            ) : (
                                                <CloseIcon style={{ color: "#CC293C", fontSize: isMobile ? '18px' : '28px' }} />
                                            )}
                                        </Typography>
                                )}
                                {hasFirstGameStarted && (
                                    <Typography className="bracket-multiple-entry-text-small">
                                        Max Points: {findMyMaxPossiblePoints(myLeaderboardInfo, entry?.name)}
                                    </Typography>
                                )}
                                <Box
                                    className="bracket-multiple-entry-champ-box"
                                    style={{
                                        ...getChampBoxStyle(findChampResult(entry?.pool?.picks, picksToMake)),
                                        border: (champId && isNaN(champId)) ? 'none' : '1px solid #002129',
                                        backgroundColor: (champId && isNaN(champId)) ? 'transparent' : 'none',
                                    }}
                                >
                                    {champId && (
                                        <>
                                            {isNaN(champId) ? (
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: "700",
                                                        textAlign: "center",
                                                        color: "#002129",
                                                    }}
                                                >
                                                    {champId}
                                                </Typography>
                                            ) : (
                                                <>
                                                    {loading && (
                                                        <CircularProgress
                                                            size={isMobile ? 24 : 32}
                                                            sx={{
                                                                color: "#666",
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                marginTop: isMobile ? "-12px" : "-16px",
                                                                marginLeft: isMobile ? "-12px" : "-16px",
                                                            }}
                                                        />
                                                    )}
                                                    <img
                                                        className="bracket-multiple-entry-champ-logo"
                                                        src={getTeamLogo(league, champId, teams)}
                                                        alt="Champ Logo"
                                                        style={{
                                                            opacity: loading ? 0 : 1,
                                                            display: error ? "none" : "block",
                                                        }}
                                                        onLoad={() => handleImageLoad(entryId)}
                                                        onError={() => handleImageError(entryId)}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
                            {!selectedEntry ? (
                                <Typography 
                                    onClick={() => handleEntryChange(entryNumber - 1)}
                                    sx={{
                                        color: "#07356B",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        fontSize: isMobile ? '12px' : '14px',
                                        fontWeight: "700",
                                        textAlign: 'center',
                                    }}
                                >
                                    SELECT
                                </Typography>
                            ) : (
                                // Only show Remove option if join deadline hasn't passed
                                (!hasJoinDeadlinePassed && !(isUserAdmin && entries?.length === 1)) && (
                                    <Typography 
                                        onClick={() => handleRemovedClick(entryNumber - 1)}
                                        sx={{
                                            color: "#CC293C",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            fontSize: isMobile ? '12px' : '14px',
                                            fontWeight: "700",
                                            textAlign: 'center',
                                        }}
                                    >
                                        REMOVE
                                    </Typography>
                                )
                            )}
                        </Box>
                    </Box>
                );
            })}

            {canIAddAnEntry && (
                <Box 
                    className="bracket-multiple-entry-content"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleAddEntryClick}
                        sx={{
                            backgroundColor: '#002129',
                            color: 'white',
                            padding: '8px 16px',
                            '&:hover': {
                                backgroundColor: '#003a4a',
                            },
                            height: '30px',
                            fontSize: isMobile ? '12px' : '14px',
                        }}
                    >
                        Add Entry
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default BracketMultipleEntryView;