import React from "react";
import { Box, Typography } from "@mui/material";
import "../BetSlip.scss";

const BetUnavailableMessage = ({ emptyBetSlip, titleMessage, subtitleMessage}) => {

    const emptyBetSlipTitle = "Bet Slip Empty";
    const emptyBetSlipSubtitle = "Add selections to place your bet.";

    return (
        <Box className="sportsbook-bet-unavailable-container" sx={{borderTop: !emptyBetSlip ? '1px solid #002129' : ''}}>
                <Typography className="sportsbook-bet-unavailable-title">{emptyBetSlip ? emptyBetSlipTitle : titleMessage}</Typography>
                <Typography className="sportsbook-bet-unavailable-subtitle">{emptyBetSlip ? emptyBetSlipSubtitle : subtitleMessage}</Typography>
                {emptyBetSlip && (
                <Typography className="sportsbook-bet-unavailable-description">
                    As they say, scared money don’t make money.
                    Whether it is a straight bet, parlay, or teaser, 
                    let’s build a winning bet.
                </Typography>
                )}
            </Box>
    )
}

export default BetUnavailableMessage;