import React from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PickDeadline = ({ gameTime }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const formatDateString = (dateString) => {
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });
        const formattedDate = date.toFormat("ccc M/d");
        const formattedTime = date.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');

        // Combine the date and time parts with an "@" symbol
        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;

        return formattedDateTime;
    };

    const formattedDeadline = formatDateString(gameTime);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column', // Ensures items are stacked vertically
                alignItems: 'center',    // Centers items horizontally
                padding: '16px 0',       // Vertical padding around the content
                backgroundColor: '#FFC60A',
                borderTop: '2px solid #002129',
                borderBottom: '2px solid #002129',
                height: isMobile ? '44px' : '64px',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: '#002129',
                    fontSize: isMobile ? '16px' : '22px',
                    fontWeight: '700',
                }}
            >
                Pick Deadline: {formattedDeadline}
            </Typography>
        </Box>
    )
}

export default PickDeadline;