import React from "react";

const KOTNMissingPicks = () => {

    const leaderboard = []

    const gameIdsForToday = [19035,19036];

    const findUsersWithMissingPicks = (leaderboard, gameIdsForToday) => {
        const missingPicks = []; // Array to store userIds of users with missing picks
    
        // Iterate over each user in the leaderboard
        leaderboard?.forEach(user => {
            const dailyPicks = user.dailyPicks || []; // Default to an empty array if no daily picks
            let hasMissingPicks = false;
    
            // Check each gameId from gameIdsForToday
            gameIdsForToday?.forEach(gameId => {
                // Count the number of picks for the current gameId
                const picksForGame = dailyPicks?.filter(pick => pick.gameId === gameId);
    
                // If the user has less than 2 picks for the gameId, mark them as missing picks
                if (picksForGame?.length < 2) {
                    hasMissingPicks = true;
                }
            });
    
            // If the user has missing picks for any gameId, add their userId to missingPicks
            if (hasMissingPicks) {
                missingPicks.push(user.userId);
            }
        });
    
        return missingPicks; // Return the array of userIds with missing picks
    };
    
    // Call the function
    const missingPicks = findUsersWithMissingPicks(leaderboard, gameIdsForToday);
    
    // Log the result
    console.log("Users with missing picks:", missingPicks);

    const findUserswithMissingBracketPicks = (leaderboard) => {
        const missingBracketPicks = []; // Array to store userIds of users with missing bracket picks

        // Iterate over each user in the leaderboard
        leaderboard?.forEach(user => {
            const bracketPicks = user.bracketPicks || []; // Default to an empty array if no bracket picks
            let hasMissingBracketPicks = false;

            // Check if the user has less than 63 bracket picks
            if (bracketPicks?.length < 7) {
                hasMissingBracketPicks = true;
            }

            // If the user has missing bracket picks, add their userId to missingBracketPicks
            if (hasMissingBracketPicks) {
                missingBracketPicks.push(user.userId);
            }
        });

        return missingBracketPicks; // Return the array of userIds with missing bracket picks   

    };

    // Call the function
    const missingBracketPicks = findUserswithMissingBracketPicks(leaderboard);
    console.log("Missing Bracket Picks:", missingBracketPicks);

    const textOptInIds = []

    const allUsersWithMissingPicks = missingPicks?.concat(missingBracketPicks);

    // Filter out the missing picks that are NOT in textOptInIds
    const usersToText = missingPicks?.filter(userId => textOptInIds?.includes(userId));
    console.log("users to text:", usersToText)

    return (
        <div>
            <h1>KOTN Missing Picks</h1>
        </div>
    );
}

export default KOTNMissingPicks;