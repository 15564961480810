import React, { useState } from "react";
import { Box, Typography, Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import '../BetSlip.scss';

const RemoveBets = ({ setPickState, setChosenBetStyle }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const handleRemoveAll = () => {
        // Logic to clear all bets
        setPickState([]);
        setChosenBetStyle("straight");
        handleClose();
    };

    return (
        <>
            <Box className="sportsbook-remove-container" style={{ cursor: "pointer" }} onClick={handleOpen}>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="18"
                    height="18"
                    viewBox="0 0 10 10" 
                    fill="none"
                    className="sportsbook-remove-icon"
                >
                    <path d="M3.33247 0V0.555556H0.554688V1.66667H1.11024V8.88889C1.11024 9.18357 1.22731 9.46619 1.43568 9.67456C1.64405 9.88294 1.92667 10 2.22135 10H7.77691C8.0716 10 8.35421 9.88294 8.56258 9.67456C8.77096 9.46619 8.88802 9.18357 8.88802 8.88889V1.66667H9.44358V0.555556H6.6658V0H3.33247ZM2.22135 1.66667H7.77691V8.88889H2.22135V1.66667ZM3.33247 2.77778V7.77778H4.44358V2.77778H3.33247ZM5.55469 2.77778V7.77778H6.6658V2.77778H5.55469Z" fill="#CC293C"/>
                </svg>
                <Typography className="sportsbook-remove-text">
                    Remove All Selections
                </Typography>
            </Box>

            {/* Modal */}
            <Dialog 
                open={open} 
                onClose={handleClose} 
                PaperProps={{ style: { width: 308, height: 158, borderRadius: 4, backgroundColor: 'white', zIndex: 9999 } }}
            >
                <DialogContent>
                    <Typography style={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: '150%',
                        letterSpacing: '-0.304px',
                        marginBottom: 8
                    }}>
                        Remove All Selections?
                    </Typography>
                    <Typography style={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '150%',
                        letterSpacing: '-0.304px',
                        marginBottom: 16
                    }}>
                        Do you want to clear all your bets?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', padding: 16 }}>
                    <Button 
                        variant="contained" 
                        onClick={handleClose} 
                        style={{ width: 100, height: 38, marginRight: 8, cursor: 'pointer', color: '#002129', background: '#E5E5E5' }}
                    >
                        CANCEL
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleRemoveAll} 
                        style={{ width: 100, height: 38, whiteSpace: 'nowrap', cursor: 'pointer' }}
                    >
                        CLEAR ALL
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RemoveBets;
