import React, { useState, useEffect } from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useBracketGamesContext } from "../context/BracketGamesContext";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import TabSelectionButtons from "../../GamePageComponents/TabSelectionButtons/TabSelectionButtons";
import PoolPicksLists from "./PoolPicksLists";
import PoolPicksMatchups from "./PoolPicksMatchups";
import GameNotReadyText from "../../GameModals/GameNotReadyModal/GameNotReadyText";
import { useGetPoolPickStats } from "../../../hooks/pools/useGetPoolPicksStats";


// Optimized championship data processor
const processChampionshipData = (leaderboardData) => {
  // Use object for O(1) lookup instead of array.find()
  const teamsMap = {};
  
  const champData = {
    tableHeader: "NATIONAL CHAMPION",
    totalPicks: 0,
    Games: []
  };

  leaderboardData?.forEach(user => {
    const userChampPick = user?.picks?.find(pick => pick.round === 6);
    
    if (userChampPick) {
      // Increment total picks count
      champData.totalPicks += 1;
      
      const teamId = userChampPick.teamId;
      
      if (teamsMap[teamId]) {
        // If team already exists, just increment the pick count
        teamsMap[teamId].pickCount += 1;
      } else {
        // Create a new team entry
        const newTeam = {
          seed: userChampPick.teamSeed,
          teamId: teamId,
          pickCount: 1,
          result: userChampPick.result || "pending"
        };
        
        // Store in map and push to array
        teamsMap[teamId] = newTeam;
        champData.Games.push(newTeam);
      }
    }
  });

  // Sort by pickCount (most popular first)
  champData.Games.sort((a, b) => b.pickCount - a.pickCount);
  
  return champData;
};

// Optimized Final Four data processor
const processFinalFourData = (leaderboardData) => {
  // Use object for O(1) lookup
  const teamsMap = {};
  
  const finalFourData = {
    tableHeader: "FINAL FOUR",
    totalPicks: 0,
    Games: []
  };

  // Track unique users with a Set
  const usersWithPicks = new Set();

  leaderboardData?.forEach(user => {
    const userId = user.id || user.userId;
    const userFinalFourPicks = user?.picks?.filter(pick => pick.round === 4);
    
    if (userFinalFourPicks?.length > 0) {
      usersWithPicks.add(userId);
      
      userFinalFourPicks.forEach(pick => {
        const teamId = pick.teamId;
        
        if (teamsMap[teamId]) {
          teamsMap[teamId].pickCount += 1;
        } else {
          const newTeam = {
            seed: pick.teamSeed,
            teamId: teamId,
            pickCount: 1,
            result: pick.result || "pending"
          };
          
          teamsMap[teamId] = newTeam;
          finalFourData.Games.push(newTeam);
        }
      });
    }
  });

  finalFourData.totalPicks = usersWithPicks.size;
  finalFourData.Games.sort((a, b) => b.pickCount - a.pickCount);
  
  return finalFourData;
};

// Optimized round one data processor
const processRoundOneData = (tournamentSchedule, leaderboardData, firstRoundOfThisPool) => {
  // First get the round one games
  const roundOneGames = tournamentSchedule?.Games
    ?.filter(game => game.Round === Number(firstRoundOfThisPool))
    ?.map(game => ({
      GlobalGameID: game.GlobalGameID,
      GlobalHomeTeamID: game.GlobalHomeTeamID,
      GlobalAwayTeamID: game.GlobalAwayTeamID,
      AwayTeamSeed: game.AwayTeamSeed,
      HomeTeamSeed: game.HomeTeamSeed,
      Bracket: game.Bracket,
      IsClosed: game.IsClosed,
      HomeTeamScore: game.HomeTeamScore,
      AwayTeamScore: game.AwayTeamScore,
    }));

  // Create the formatted data
  const roundOneData = roundOneGames
    ?.sort((a, b) => a.Bracket - b.Bracket)
    .map(game => {
      let winnerId = null;
      if (game.IsClosed) {
        winnerId = game.HomeTeamScore > game.AwayTeamScore 
          ? game.GlobalHomeTeamID 
          : game.GlobalAwayTeamID;
      }
      
      return {
        region: game.Bracket,
        globalGameId: game.GlobalGameID,
        teams: {
          away: {
            seed: game.AwayTeamSeed,
            teamId: game.GlobalAwayTeamID,
            pickCount: 0,
            result: winnerId === null ? "pending" : winnerId === game.GlobalAwayTeamID ? "win" : "loss"
          },
          home: {
            seed: game.HomeTeamSeed,
            teamId: game.GlobalHomeTeamID,
            pickCount: 0,
            result: winnerId === null ? "pending" : winnerId === game.GlobalHomeTeamID ? "win" : "loss"
          }
        }
      };
    });

  // Create a map for O(1) game lookup
  const gameMap = {};
  roundOneData?.forEach(game => {
    gameMap[game.globalGameId] = game;
  });

  // Process user picks
  leaderboardData?.forEach(user => {
    user?.picks
      ?.filter(pick => pick.round === firstRoundOfThisPool)
      ?.forEach(pick => {
        const game = gameMap[pick.globalGameId];
        
        if (!game) return;
        
        // Increment the proper team's pick count
        if (pick.teamId === game.teams.home.teamId) {
          game.teams.home.pickCount += 1;
        } else if (pick.teamId === game.teams.away.teamId) {
          game.teams.away.pickCount += 1;
        }
      });
  });

  // Group by region
  const roundOneDataByRegion = roundOneData?.reduce((acc, game) => {
    if (!acc[game.region]) {
      acc[game.region] = [];
    }
    acc[game.region].push(game);
    return acc;
  }, {});

  return {
    roundOneData,
    roundOneDataByRegion
  };
};

const BracketPoolPicks = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedRound, setSelectedRound] = useState(null);
    const [poolPicksData, setPoolPicksData] = useState(null);
    const [hasDataFetched, setHasDataFetched] = useState(false);
    //console.log("Pool Picks Data: ", poolPicksData);

    const {
        masterPoolData,
        gameData,
        gameId,
        hasJoinDeadlinePassed,
        league,
        leaderboardData,
    } = useUserAndGameContext();

    const {
        teams,
        tournamentSchedule,
    } = useBracketGamesContext();

    const { mutate: getPoolPickStats, data: poolPickStats, isLoading: poolPickStatsLoading, isError: poolPickStatsError, error: poolPickStatsErrorData } = useGetPoolPickStats();
    //console.log("hasDataFetched: ", hasDataFetched, "gameId: ", gameId);

    const getPoolPicksFromRedis = gameData?.gameParameters?.getPoolPicksFromRedis;
    //console.log("Get Pool Picks From Redis: ", getPoolPicksFromRedis);
    //UseEffect to get pool pick stats
    useEffect(() => {
      if (!hasDataFetched && gameId && getPoolPicksFromRedis) {
        //console.log("Attempting to get pool pick stats with gameId:", gameId);
        getPoolPickStats({ gameID: String(gameId)  });
        setHasDataFetched(true);
      }
    }, [hasDataFetched, gameId, getPoolPickStats, getPoolPicksFromRedis]);
    //console.log("Pool Pick Stats: ", poolPickStats);


    //console.log("Schedule in BracketPoolPicks: ", tournamentSchedule);

    const tabs = masterPoolData?.picksPageInfo?.tabs;
    const notReadyModal = masterPoolData?.notReadyModal;
    const rounds = masterPoolData?.gameParameters?.rounds;
    const firstRoundOfThisPool = rounds?.[0];
    
    //console.log("First Round of This Pool: ", firstRoundOfThisPool);

    // Update the useEffect to conditionally process data or use Redis data
    useEffect(() => {
      // If we're fetching from Redis and have received the data, use that
      if (getPoolPicksFromRedis && poolPickStats && !poolPickStatsLoading) {
          //console.log("Using pool pick stats from Redis");
          setPoolPicksData(poolPickStats);
          return;
      }

      // If we're NOT fetching from Redis (or Redis fetch failed), calculate locally
      if ((!getPoolPicksFromRedis || poolPickStatsError) && 
          tournamentSchedule && leaderboardData && firstRoundOfThisPool) {
          
          //console.log("Calculating pool pick stats on client side");
          
          // Process championship data
          const champData = processChampionshipData(leaderboardData);
          
          // Process Final Four data
          const finalFourData = processFinalFourData(leaderboardData);
          
          // Process Round One data
          const { roundOneData, roundOneDataByRegion } = processRoundOneData(
              tournamentSchedule, 
              leaderboardData, 
              firstRoundOfThisPool
          );
          
          // Set the combined pool picks data
          setPoolPicksData({
              rounds: {
                  "6": champData,
                  "4": finalFourData,
                  [firstRoundOfThisPool]: roundOneData
              }
          });
      }
    }, [
      tournamentSchedule, 
      leaderboardData, 
      firstRoundOfThisPool, 
      getPoolPicksFromRedis, 
      poolPickStats, 
      poolPickStatsLoading, 
      poolPickStatsError
    ]);

    // Handle tab selection
    useEffect(() => {
        if (!tabs?.length) return;

        const tabFromUrl = searchParams.get('tab');
        let targetTab;

        if (tabFromUrl && tabs.some(t => t.tab === tabFromUrl)) {
          targetTab = tabs.find(t => t.tab === tabFromUrl);
      } else {
          targetTab = tabs.find(t => t.default) || tabs[0];
          // Only update URL if current tab is different from target tab
          if (tabFromUrl !== targetTab.tab) {
              setSearchParams({ ...Object.fromEntries(searchParams), tab: targetTab.tab });
          }
      }

        setSelectedTab(targetTab.tab);
        setSelectedPage(targetTab.page);
        setSelectedRound(targetTab.round);
    }, [tabs, searchParams, setSearchParams]);
     //console.log("Pool Picks Data: ", poolPicksData);

    if (!tabs || selectedTab === null || selectedPage === null || selectedRound === null || !poolPicksData) {
        return <LoadingScreen />;
    }

    return (
        <>
            {!hasJoinDeadlinePassed ? (
                <GameNotReadyText
                    headerText={notReadyModal?.poolPicksHeader}
                    text={notReadyModal?.poolPicksText}
                />
            ) : (
                <Box>
                    <TabSelectionButtons selectedTab={selectedTab} tabs={tabs} />
            
                    {selectedPage === "list" && 
                        <PoolPicksLists 
                            selectedRound={selectedRound}
                            poolPicksData={poolPicksData}
                            teams={teams}
                            league={league}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                            tab={selectedTab}
                        />
                    }
                    {selectedPage === "matchups" && 
                        <PoolPicksMatchups 
                            selectedRound={selectedRound}
                            poolPicksData={poolPicksData}
                        />
                    }
                </Box>
            )}
        </>
    );
};

export default BracketPoolPicks;