import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSaveSuperPickemPicks } from "../../../hooks/pools/useSaveSuperPickemPicks";

import ErrorModal from "../../GameModals/ErrorModal/ErrorModal";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";  
import SuperPickemSubmitPicksModal from "../../GameModals/SuperPickemModals/SuperPickemSubmitModal";
import PicksNotSavedModal from "../../GameModals/ErrorModal/PicksNotSavedModal";
import SuperPickemPicksWarningModal from "../../GameModals/SuperPickemModals/SuperPickemPicksWarningModal";

const SuperPickemSubmitBar = ({
    myUserId,
    gameType,
    mainGameId,
    picksThisInterval,
    howManyPickMadeThisInterval,
    league,
    schedule,
    names,
    pickState,
    setPickState,
    userPicksFromDB,    
    interval,
    gameRewards,
    joinReward,
    season,
    roundName,
    logo,
    stopPicksWarning,
    sponsorSecondaryLogo,
}) => {
    //console.log("Game Rewards MY PICKS: ", gameRewards, "Join Reward: ", joinReward);
    const { mutate, isLoading, isError, error, isSuccess } = useSaveSuperPickemPicks();
    const dispatch = useDispatch();

    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [checkerData, setCheckerData] = useState({});
    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [internalErrorOpen, setInternalErrorOpen] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState('');
    const [logoLoaded, setLogoLoaded] = useState(false); // Track logo load status
    const [picksSubmitted, setPicksSubmitted] = useState(false);

    // Preload the logo image when component mounts
    useEffect(() => {
        if (logo) {
            const img = new Image();
            img.src = logo;
            img.onload = () => setLogoLoaded(true); // Set logoLoaded to true when image loads
        }
    }, [logo]);

    const handleCancel = () => {
        setPickState([]);
    }

    const handleFirstSubmit = async () => {
        if (!stopPicksWarning) {
            setWarningModalOpen(true);
        } else {
            handleSubmit();
        }
    }

    const handleSubmit = async () => {
        if (picksSubmitted) return;
        setPicksSubmitted(true);
        //console.log("Submitting Picks");
        const picksData = {
            picks: pickState,
            userID: myUserId,
            gameType: gameType,
            gameId: mainGameId,
            league: league,
            season: season,
            interval: interval,
        }
        //console.log("Picks Data:", picksData);
        if (gameRewards?.length > 0 && !joinReward) {
            //console.log("Sending Join Reward");
            picksData.joinReward = true;
        }

        if (dontShowAgain) {
            picksData.stopPicksWarning = true;
        }

        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    const checkerData = response?.checkerData;
                    //console.log("Checker Data", checkerData);
                    if (checkerData?.success) {
                        setCheckerData(checkerData);
                        setSubmitModalOpen(true);
                    } else {
                        setSubmitError(true);
                        setErrorMessage(checkerData?.message);
                        setSubmitModalOpen(true);
                    }
                },
                onError: (error) => {
                    if (error.response.status === 401) {
                        dispatch(userLogoutAction());
                    } else {
                        setInternalErrorMessage(error.response.data.error);
                        setInternalErrorOpen(true);
                    }
                }
            });
        } catch (error) {
            setInternalErrorMessage(error.message);
            setInternalErrorOpen(true);
        }
    }

    const handleCheckboxChange = (event) => {
        setDontShowAgain(event.target.checked);
    }


    return (
        <>
            <Box className="quick-pick-submit-bar-container">
                {/* Content row */}
                <Box className="quick-pick-submit-bar-content-row">
                    <Box className="quick-pick-submit-bar-text-group">
                        <Typography className="quick-pick-submit-bar-typography">Picks:</Typography>
                        <Typography 
                            className="quick-pick-submit-bar-typography"
                            sx={{
                                color: howManyPickMadeThisInterval === picksThisInterval ? "#00AA72" : "#CC293C",
                                marginLeft: '5px'
                            }}
                        >
                            {howManyPickMadeThisInterval}/{picksThisInterval}
                        </Typography>
                        {howManyPickMadeThisInterval === picksThisInterval ? (
                            <CheckIcon className="quick-pick-submit-bar-icon" style={{ color: '#00AA72'}}/>
                        ) : (
                            <CloseIcon className="quick-pick-submit-bar-icon" style={{ color: '#CC293C'}}/>
                        )}
                    </Box>
                </Box>
        
                {/* Buttons */}
                <Box className="quick-pick-submit-bar-button-group">
                    <Button 
                        variant="contained" 
                        className="quick-pick-submit-bar-cancel-button" 
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleFirstSubmit}
                        disabled={picksSubmitted}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>

            {/* Modal for submitting picks */}
            {submitModalOpen && !submitError && logoLoaded && (
                <SuperPickemSubmitPicksModal
                    modalOpen={submitModalOpen}
                    setModalOpen={setSubmitModalOpen}
                    checkerData={checkerData}
                    roundName={roundName}
                    logo={logo}
                    names={names}
                    teamsOrNames={names}
                    schedule={schedule}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                />
            )}

            {warningModalOpen && (
                <SuperPickemPicksWarningModal
                    modalOpen={warningModalOpen}
                    setModalOpen={setWarningModalOpen}
                    dontShowAgain={dontShowAgain}
                    handleCheckboxChange={handleCheckboxChange}
                    handleWarningSubmit={handleSubmit}
                />
            )}

            {/* Error Modals */}
            {submitModalOpen && submitError && (
                <PicksNotSavedModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}

            {internalErrorOpen && (
                <ErrorModal
                    internalErrorOpen={internalErrorOpen}
                    setInternalErrorOpen={setInternalErrorOpen}
                    internalErrorMessage={internalErrorMessage}
                    setInternalErrorMessage={setInternalErrorMessage}
                />
            )}
        </>
    );
}

export default SuperPickemSubmitBar;
