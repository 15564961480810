import React, { useState, useEffect } from "react";
import { useAdminDashContext } from "./context/AdminDashContext";
import DashHome from "./DashHome/DashHome";
import QuickPickHome from "./QuickPickPages/QuickPickHome";
import GameEditingHome from "./Games/GameEditing/GameEditingHome";
import GameCreationHome from "./Games/GameCreation/GameCreationHome";

const AdminDashContainer = () => {
    const { 
        pageState, 
        setPageState, 
        leagueState, 
        setLeagueState 
    } = useAdminDashContext();

    return (
        <div>
            {pageState === 'Home' && <DashHome />}
            {pageState === 'QuickPick' && <QuickPickHome />}
            {pageState === 'CreateGames' && <GameCreationHome />}
            {pageState === 'EditGames' && <GameEditingHome />}
        </div>
    );
}

export default AdminDashContainer;