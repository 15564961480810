import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { usePublicPartyContext } from "./context/PublicPartyContext";
import '../PrivateParty/PrivatePartyInviteStyles.scss';
import AlreadyInPrivateParty from './AlreadyInPrivateParty';
import NotInPrivateParty from './NotInPrivateParty';
import UserIsRestricted from './UserIsRestricted';
import JoinDeadlineHasPassed from './JoinDeadlineHasPassed';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';

const PublicPartyInviteContainer = () => {
  const { 
    partyData, 
    joinParty, 
    inviteeDisplayName, 
    clubLogo, 
    foundSubgroupName, 
    displayName, 
    poolStartDate, 
    poolEndDate, 
    isUserInThisGame, 
    isUserInThisSubgroup, 
    amIRestricted, 
    lastDayToJoin,
    gameName,
    sponsorLogo,
  } = usePublicPartyContext();


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //console.log("Party Data", partyData,"Do user have data", inviteeDisplayName, clubLogo, foundSubgroupName, displayName, poolStartDate, poolEndDate, isUserInThisGame, isUserInThisSubgroup, amIRestricted);
  console.log("LAST DAY TO JOIN", lastDayToJoin);
  console.log("SPONSOR LOGO", sponsorLogo);

  let hasDeadlinePassed = false;
  if (lastDayToJoin) {
  // 1. Get the current time in New York
  const timeInNyNow = DateTime.now().setZone('America/New_York');

  // 2. Parse lastDayToJoin as a Luxon DateTime object
  const lastDayToJoinTime = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });

  // 3. Compare the current time to the join deadline
   hasDeadlinePassed = timeInNyNow > lastDayToJoinTime;
  }

  console.log(`Has the deadline passed? ${hasDeadlinePassed}`);
  
  const hasData = inviteeDisplayName && clubLogo && foundSubgroupName && displayName && poolStartDate && poolEndDate && isUserInThisGame !== null && isUserInThisSubgroup !== null && amIRestricted !== null;

  return (
    <>
      {hasData ? (
        <>
          <h1 className="h1-private-party-invite" style={{ margin: '0 auto', padding: '20px 0', fontSize: isMobile ? '24px' : '32px' }}>
            My Party Invite
          </h1>
          <hr className="line" style={{ margin: '0 auto' }} />
          {hasDeadlinePassed && !isUserInThisGame ? (
            <JoinDeadlineHasPassed
              gameName={gameName}
              deadline={lastDayToJoin}
              sponsorLogo={sponsorLogo}
              gameLogo={clubLogo}
            />
          ) : (
            amIRestricted ? (
              <UserIsRestricted />
            ) : (
              isUserInThisSubgroup ? (
                <AlreadyInPrivateParty />
              ) : (
                <NotInPrivateParty />
              )
            )
          )}
        </>
      ) : (
        <Box>
          Loading...
        </Box>
      )}
    </>
  );
  
};

export default PublicPartyInviteContainer;
