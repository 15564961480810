import React, { useEffect, useState, useCallback, useRef } from "react";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from "react-router-dom";
import CommissionerInvites from "./CommissionerInvitesNew";
import ManageMembers from "./ManageMembersNew";
import EmailMembers from "./EmailMembers";

const CommissionerPage = ({
    gameData,
    myUserId,
    gameCommissioners,
    gameAdministrators,
    gameId,
    masterPoolId,
    isGameOver,
    isUserAdmin,
    allGameMembers,
    gameAddress,
    hasJoinDeadlinePassed,
    gameName,
}) => {
    //console.log("All Game Members: ", allGameMembers);
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = useState(null);

    const handleTabChange = useCallback((newTab) => {
        setTab(newTab);
        navigate(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=members&tab=${newTab}`);
    }, [gameAddress, masterPoolId, gameId, navigate]);

    // Add this at the top of your component
    const isInitialRender = useRef(true);

    // Then modify your first useEffect like this:
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabParam = urlParams.get('tab');
        
        if (isInitialRender.current) {
            // First render - determine correct tab based on conditions
            if (!tabParam) {
                const defaultTab = !hasJoinDeadlinePassed ? 'invite' : 'manage';
                setTab(defaultTab);
                // Only navigate if we need to set a default
                handleTabChange(defaultTab);
            } else {
                // If tab param exists, just set it (but check deadline condition)
                if (tabParam === 'invite' && hasJoinDeadlinePassed) {
                    // If they're trying to go to invite but deadline passed, force manage
                    setTab('manage');
                    handleTabChange('manage');
                } else {
                    // Otherwise accept the URL's tab value
                    setTab(tabParam);
                }
            }
            isInitialRender.current = false;
        } else if (tabParam !== tab) {
            // For subsequent renders, only update if URL tab doesn't match state
            setTab(tabParam);
        }
    }, [location, hasJoinDeadlinePassed, handleTabChange, tab]);

    // Then modify the game over useEffect to use the same ref guard:
    useEffect(() => {
        if (!isInitialRender.current && isGameOver && tab === 'invite') {
            setTab('manage');
            handleTabChange('manage');
        }
    }, [isGameOver, tab, handleTabChange]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Typography
                variant="h3"
                style={{
                    color: "white",
                    background: "#002F3A",
                    fontWeight: '700',
                    margin: '0 auto',
                    padding: '5px 0',
                    fontSize: isMobile ? '12px' : '14px'
                }}
            >
                {`Manage ${gameName}`}
            </Typography>

            <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '675px', margin:'15px auto'}}>
              {!isGameOver && !hasJoinDeadlinePassed && (
                <span
                  style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: tab === 'invite' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                  onClick={() => {
                    handleTabChange('invite');
                  }}
                >
                  Send Invites
                </span>
              )}
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: tab === 'manage' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                    handleTabChange('manage');
                }}              
              >
                Manage Members
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: tab === 'email' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                    handleTabChange('email');
                }}              
              >
                Email Members 
              </span>
              {/* <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: tab === 'settings' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                    handleTabChange('settings');
                }}
                >
                <SettingsIcon sx={{ fontSize: '20px'}} />
                </span> */}
            </div>

            {tab === 'invite' && (
                <CommissionerInvites
                    gameData={gameData}
                    myUserId={myUserId}
                    masterPoolId={masterPoolId}
                    gameId={gameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    isUserAdmin={isUserAdmin}
                    gameAddress={gameAddress}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                />
            )}

            {tab === 'manage' && (
                <ManageMembers
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrators={gameAdministrators}
                    allGameMembers={allGameMembers}
                    masterPoolId={masterPoolId}
                    gameId={gameId}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    isUserAdmin={isUserAdmin}
                    gameAddress={gameAddress}
                />
            )}

            {tab === 'email' && (
                <EmailMembers
                    allGameMembers={allGameMembers}
                    isGameOver={isGameOver}
                    gameName={gameName}
                />
            )}

        </div>
    );
}

export default CommissionerPage;