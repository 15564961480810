import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import '../BetReceipt.scss';
import { DateTime } from 'luxon';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BetReceiptGameInfoParlayTeaser = ({ bet, betMade, reason, numberOfBetsPlaced, numberOfBetsNotPlaced, chosenBetStyle, teaserPointValue, first, last }) => {

    //console.log("Bet in Game Info Row", betMade, chosenBetStyle);
    //console.log("First", first, "Last", last);

    let betDisplay = '';
    let teaserLine = '';
    if (bet?.betType === 'Spread') {
        if (bet?.line > 0) {
            betDisplay = bet?.betChoice === "Home"  ? `${bet?.homeName} +${bet?.line}`  : `${bet?.awayName} +${bet?.line}`;
            if (chosenBetStyle === "teaser") {
               teaserLine = bet?.line + parseInt(teaserPointValue);
               betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} +${bet?.line} → +${teaserLine}` : `${bet?.awayName} +${bet?.line} → +${teaserLine}`;
            }
        } else if (bet?.line === 0) {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} PK` : `${bet?.awayName} PK`;
            if (chosenBetStyle === "teaser") {
                teaserLine = parseInt(teaserPointValue);
                betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} PK → +${teaserLine}` : `${bet?.awayName} PK → +${teaserLine}`;
            }
        } else {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} ${bet?.line}` : `${bet?.awayName} ${bet?.line}`;
            if (chosenBetStyle === "teaser") {
                teaserLine = bet?.line + parseInt(teaserPointValue);
                
                // Determine the display for teaserLine
                let teaserLineDisplay;
                if (teaserLine > 0) {
                    teaserLineDisplay = `+${teaserLine}`;
                } else if (teaserLine === 0) {
                    teaserLineDisplay = 'Pk';
                } else {
                    teaserLineDisplay = teaserLine; // Keep it as is if negative
                }
            
                // Construct betDisplay
                betDisplay = bet?.betChoice === "Home" 
                    ? `${bet?.homeName} ${bet?.line} → ${teaserLineDisplay}` 
                    : `${bet?.awayName} ${bet?.line} → ${teaserLineDisplay}`;
            }
            
        }
    } else if (bet?.betType === 'Moneyline') {
        if (bet?.juice > 0) {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} +${bet?.juice}` : `${bet?.awayName} +${bet?.juice}`;
        } else {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} ${bet?.juice}` : `${bet?.awayName} ${bet?.juice}`;
        }
    } else if (bet?.betType === 'Total') {
        betDisplay = bet?.betChoice === "Over" ? `Over ${bet?.line}` : `Under ${bet?.line}`;
        if (chosenBetStyle === "teaser") {
            teaserLine = bet?.betChoice === "Over" ? `Over ${bet?.line - parseInt(teaserPointValue)}` : `Under ${bet?.line + parseInt(teaserPointValue)}`;
            betDisplay = bet?.betChoice === "Over" ? `Over ${bet?.line} → ${teaserLine}` : `Under ${bet?.line} → ${teaserLine}`;
        }
    }

    let betType = '';
    if (bet?.betType === 'Spread') {
        betType = 'SPREAD';
    } else if (bet?.betType === 'Moneyline') {
        betType = 'MONEYLINE';
    } else if (bet?.betType === 'Total') {
        betType = 'TOTAL';
    }

    const dateTime = DateTime.fromISO(bet?.dateTime, { zone: 'America/New_York' });

    //console.log("Bet Display", betDisplay, teaserLine);

    // Conditional icons for lineChange and juiceChange
    const lineChangeIcon = bet?.lineChange === 'Favorable' 
    ? <ArrowDropUpIcon sx={{ color: '#00AA72', marginLeft: '5px'}} />
    : bet?.lineChange === 'Unfavorable'
    ? <ArrowDropDownIcon sx={{ color: '#CC293C', marginLeft: '5px' }} />
    : null;

    const juiceChangeIcon = bet?.juiceChange === 'Favorable' 
    ? <ArrowDropUpIcon sx={{ color: '#00AA72', marginRight: '5px' }} />
    : bet?.juiceChange === 'Unfavorable'
    ? <ArrowDropDownIcon sx={{ color: '#CC293C', marginRight: '5px' }} />
    : null;

    

    return (
        <Box className="sportsbook-bet-receipt-game-info-container-parlay-teaser">
            <Box className="sportsbook-bet-receipt-game-info-left-side">
                {/* Container for circle and lines */}
                <Box className="sportbook-bet-receipt-circle-container">
                    {/* Lines */}
                    {!first &&
                        <Box className="sportsbook-bet-receipt-circle-top-line" />
                    }
                    {!last &&
                        <Box className="sportsbook-bet-receipt-circle-bottom-line" />
                    }

                    {/* Background Circle */}
                    <Box className="sportsbook-bet-receipt-circle-background" sx={{ backgroundColor: betMade ? '#fff' : '#CC293C'}}>
                        {/* SVG Circle */}
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width='18'
                            height='18'
                            viewBox="0 0 24 24" 
                            fill="none"
                            style={{ 
                                position: 'absolute', 
                                top: '50%', 
                                left: '50%', 
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2 // Ensure the SVG circle is above the background circle
                            }}
                        >
                            <circle cx="12" cy="12" r="8" stroke="#00AAD1" strokeWidth="1" fill="none" />
                        </svg>
                    </Box>
                </Box>

                {/* Stacked Text */}
                <Box className="sportsbook-bet-receipt-game-info-left-text">
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '12px' }}>
                        <Typography className="sportsbook-bet-receipt-game-info-big-text" sx={{color: !betMade ? '#CC293C' : ''}}>
                            {betDisplay}
                        </Typography>
                        {lineChangeIcon}
                    </Box>
                    <Typography className="sportsbook-bet-receipt-game-info-small-text">{betType}</Typography>
                    <Typography className="sportsbook-bet-receipt-game-info-small-text">{bet?.awayName} @ {bet?.homeName}</Typography>
                    {!betMade &&
                        <Typography className="sportsbook-bet-receipt-game-info-big-text" sx={{ marginTop: '10px', color: !betMade ? '#CC293C' : '' }}>Bet Not Placed.</Typography>
                    }
                </Box>
            </Box>
            <Box className="sportsbook-bet-receipt-game-info-right-side" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Box className="single-bet-box" sx={{ display: 'flex', alignItems: 'center', height: '12px' }}>
                    {chosenBetStyle === 'parlay' ? juiceChangeIcon : lineChangeIcon}
                    <Typography className="sportsbook-bet-receipt-game-info-big-text-right" sx={{ color: !betMade ? '#CC293C' : ''}}>
                        {chosenBetStyle !== "teaser" ? (bet?.juice > 0 ? `+${bet.juice}` : bet.juice) : (teaserLine > 0 ? `+${teaserLine}` : teaserLine === 0 ? 'Pk' : teaserLine)}
                    </Typography>
                </Box>
                <Typography className="sportsbook-bet-receipt-game-info-small-text-right">
                    {dateTime.toFormat("ccc MMM d - h:mma 'ET'")}
                </Typography>
                {!betMade &&
                    <Typography className="sportsbook-bet-receipt-game-info-big-text-right" sx={{ marginTop: '10px', color: !betMade ? '#CC293C' : '' }}>{reason}</Typography>
                }
            </Box>
        </Box>
    )
}

export default BetReceiptGameInfoParlayTeaser;