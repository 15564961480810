import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { joinLink } from '../redux/actions/gameImport';

const GameInvitation = () => {

    const dispatch = useDispatch(); // Get the dispatch function
    const [searchParams] = useSearchParams();

    const {gameId, gamePlayer} = Object.fromEntries([...searchParams]);
  
    const joinGame = () => {
        // Dispatch the joinLink action with gameId and gamePlayer
        dispatch(joinLink(gameId, gamePlayer));
    }
    return(
        <>
        <button onClick={() => joinGame()}>Join Game</button>
        </>
    );
}

export default GameInvitation;