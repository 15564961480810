import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PublicPartyInviteContainer from '../components/PublicParty/PublicPartyInviteContainer';
import PublicPartyProvider from '../components/PublicParty/context/PublicPartyContext';

const PublicInvitationPage = () => {
  const queryClient = new QueryClient();
  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <PublicPartyProvider>
          <PublicPartyInviteContainer />
        </PublicPartyProvider>
      </QueryClientProvider>
    </Box>
  );
}

export default PublicInvitationPage;
