import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

const RemovePickModal = ({ removeModalOpen, setRemoveModalOpen, week, formattedDeadline }) => {

    return (
    <Modal
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                aria-labelledby="remove-pick-modal"
                aria-describedby="remove-pick-modal"
                BackdropProps={{
                    // Prevent closing when clicking outside
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        borderRadius: '8px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '328px',
                        height: '254px',
                        bgcolor: '#FFF',
                        padding: '16px',
                        outline: 'none', // Remove default focus behavior
                    }}
                >
                    <Typography 
                        id="modal-modal-title" 
                        variant="h6" 
                        component="h2"
                        sx={{ 
                            textAlign: 'center',
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '800',
                            paddingBottom: '20px',
                        }}    
                    >
                        Week {week} Pick Removed
                    </Typography>
                    <Typography 
                        id="modal-modal-description" 
                        sx={{ 
                            textAlign: 'center',
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            paddingBottom: '20px',
                            whiteSpace: 'nowrap',
                         }}>
                        You have removed your week {week} pick.
                    </Typography>
                    <Typography 
                        id="modal-modal-description" 
                        sx={{ 
                            textAlign: 'center',
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                         }}>
                        You can make a new pick up until<br/> the pick deadline on: 
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            textAlign: 'center',
                        }}
                    >
                        {formattedDeadline}
                    </Typography>
                    <Button 
                        variant='contained'
                        onClick={() => {
                            setRemoveModalOpen(false);
                            window.location.reload(); // This will reload the page
                        }}
                        sx={{
                            width: '115px',
                            height: '38px',
                            marginTop: '20px', // Add top margin
                            display: 'block',
                            marginLeft: 'auto', // Center horizontally within a flex container
                            marginRight: 'auto', // Center horizontally within a flex container
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
    );
}

export default RemovePickModal;