import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useRemoveEntryFromGame = () => {
    return useMutation({
        mutationFn: removeEntryFromGame,
        onError: (error) => {
            console.error('Error removing entry from game:', error);
        },
        // onSuccess: (data) => {
        // console.log('Entry removed successfully:', data);
        // }
    });
}

const removeEntryFromGame = async (entryData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/remove-entry-from-game`, entryData, {
        headers: {
            Authorization: authorizationHeader,  // Include the authorization header in the request
            'Content-Type': 'application/json'
        }
    });  
    console.log('Entry removed successfully:', response.data);
    return response.data.data;
}