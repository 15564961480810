import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import '../BetSlip.scss';
import { DateTime } from 'luxon';

const GameInfoRow = ({ bet, removeBet, chosenBetStyle, teaserPointValue }) => {

    //console.log("Bet in Game Info Row", bet);

    let betDisplay = '';
    let teaserLine = '';
    if (bet?.betType === 'Spread') {
        if (bet?.line > 0) {
            betDisplay = bet?.betChoice === "Home"  ? `${bet?.homeName} +${bet?.line}`  : `${bet?.awayName} +${bet?.line}`;
            if (chosenBetStyle === "teaser") {
               teaserLine = bet?.line + parseInt(teaserPointValue);
               betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} +${bet?.line} → +${teaserLine}` : `${bet?.awayName} +${bet?.line} → +${teaserLine}`;
            }
        } else if (bet?.line === 0) {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} PK` : `${bet?.awayName} PK`;
            if (chosenBetStyle === "teaser") {
                teaserLine = parseInt(teaserPointValue);
                betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} PK → +${teaserLine}` : `${bet?.awayName} PK → +${teaserLine}`;
            }
        } else {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} ${bet?.line}` : `${bet?.awayName} ${bet?.line}`;
            if (chosenBetStyle === "teaser") {
                teaserLine = bet?.line + parseInt(teaserPointValue);
                
                // Determine the display for teaserLine
                let teaserLineDisplay;
                if (teaserLine > 0) {
                    teaserLineDisplay = `+${teaserLine}`;
                } else if (teaserLine === 0) {
                    teaserLineDisplay = 'Pk';
                } else {
                    teaserLineDisplay = teaserLine; // Keep it as is if negative
                }
            
                // Construct betDisplay
                betDisplay = bet?.betChoice === "Home" 
                    ? `${bet?.homeName} ${bet?.line} → ${teaserLineDisplay}` 
                    : `${bet?.awayName} ${bet?.line} → ${teaserLineDisplay}`;
            }
            
        }
    } else if (bet?.betType === 'Moneyline') {
        if (bet?.juice > 0) {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} +${bet?.juice}` : `${bet?.awayName} +${bet?.juice}`;
        } else {
            betDisplay = bet?.betChoice === "Home" ? `${bet?.homeName} ${bet?.juice}` : `${bet?.awayName} ${bet?.juice}`;
        }
    } else if (bet?.betType === 'Total') {
        betDisplay = bet?.betChoice === "Over" ? `Over ${bet?.line}` : `Under ${bet?.line}`;
        if (chosenBetStyle === "teaser") {
            teaserLine = bet?.betChoice === "Over" ? `Over ${bet?.line - parseInt(teaserPointValue)}` : `Under ${bet?.line + parseInt(teaserPointValue)}`;
            betDisplay = bet?.betChoice === "Over" ? `Over ${bet?.line} → ${teaserLine}` : `Under ${bet?.line} → ${teaserLine}`;
        }
    }

    let betType = '';
    if (bet?.betType === 'Spread') {
        betType = 'SPREAD';
    } else if (bet?.betType === 'Moneyline') {
        betType = 'MONEYLINE';
    } else if (bet?.betType === 'Total') {
        betType = 'TOTAL';
    }

    const dateTime = DateTime.fromISO(bet?.dateTime, { zone: 'America/New_York' });

    //console.log("Bet Display", betDisplay, teaserLine);

    const handleRemoveBet = () => {
        // Call the function passed as a prop with the relevant data
        removeBet(bet?.gameId, bet?.betType, bet?.betChoice);
    };

    return (
            <Box className="sportsbook-game-info-container">
                <Box className="sportsbook-game-info-left-side">
                    {/* SVG Icon */}
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width='18'
                        height='18'
                        viewBox="0 0 10 10" 
                        fill="none"
                        style={{ cursor: 'pointer' }}
                        onClick={handleRemoveBet}
                    >
                        <path d="M3.33247 0V0.555556H0.554688V1.66667H1.11024V8.88889C1.11024 9.18357 1.22731 9.46619 1.43568 9.67456C1.64405 9.88294 1.92667 10 2.22135 10H7.77691C8.0716 10 8.35421 9.88294 8.56258 9.67456C8.77096 9.46619 8.88802 9.18357 8.88802 8.88889V1.66667H9.44358V0.555556H6.6658V0H3.33247ZM2.22135 1.66667H7.77691V8.88889H2.22135V1.66667ZM3.33247 2.77778V7.77778H4.44358V2.77778H3.33247ZM5.55469 2.77778V7.77778H6.6658V2.77778H5.55469Z" fill="#CC293C"/>
                    </svg>

                    {/* Stacked Text */}
                    <Box className="sportsbook-game-info-left-text">
                        <Typography className="sportsbook-game-info-big-text">{betDisplay}</Typography>
                        <Typography className="sportsbook-game-info-small-text">{betType}</Typography>
                        <Typography className="sportsbook-game-info-small-text">{bet?.awayName} @ {bet?.homeName}</Typography>
                    </Box>
                </Box>
                <Box className="sportsbook-game-info-right-side">
                    <Box className="sportsbook-game-info-right-text">
                        <Box className="single-bet-box">
                            <Typography className="sportsbook-game-info-big-text-right">
                                {chosenBetStyle !== "teaser" ? (bet?.juice > 0 ? `+${bet.juice}` : bet.juice) : (teaserLine > 0 ? `+${teaserLine}` : teaserLine === 0 ? 'Pk' : teaserLine)}
                            </Typography>
                        </Box>                        
                        <Typography className="sportsbook-game-info-small-text-right">{dateTime.toFormat("ccc MMM d - h:mma 'ET'")}</Typography>
                    </Box>
                </Box>
            </Box>
    )
}

export default GameInfoRow;