import React from "react";

const GatherCFBSurvivorInfo = () => {

    const UserDB = {
        "user_id" : "ca019a56-50ec-473c-b830-dfbeb45e72b4",
        "first_name" : "Adam",
        "last_name" : "Braithwaite",
        "user_name" : "OchoCinco85",
        "phone" : "14124184554",
        "email" : "demo5@gmail.com",
        "password" : "$2b$10$rlC9CpSHpCKTxDmX6x9DaOWhUHFg5L2w4m7ZF8J6tUQYBvacCgNiS",
        "date_of_birth" : {
            "$date" : 397972800000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    "picks" : [
                        {
                            "gameId" : 18680,
                            "teamId" : 19,
                            "spread" : -3.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18679,
                            "teamId" : 13,
                            "spread" : -2,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18677,
                            "teamId" : 6,
                            "spread" : -4.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18675,
                            "teamId" : 28,
                            "spread" : 1,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18671,
                            "teamId" : 8,
                            "spread" : -1,
                            "dateTime" : "2024-09-08T16:25:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        }
                    ]
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "97kcw983-7y45-2c90-l2x1-57dr3pmg7031",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [
                        {
                            "gameId" : 18862,
                            "teamId" : 9,
                            "dateTime" : "2024-11-28T16:30:00",
                            "week" : 13,
                            "result" : "Win",
                            "isHomeSelected" : true
                        },
                        {
                            "gameId" : 18865,
                            "teamId" : 16,
                            "dateTime" : "2024-12-08T20:20:00",
                            "week" : 14,
                            "result" : "Win",
                            "isHomeSelected" : true
                        },
                        {
                            "gameId" : 18888,
                            "teamId" : 24,
                            "dateTime" : "2024-12-15T13:00:00",
                            "week" : 15,
                            "result" : "Loss",
                            "isHomeSelected" : false
                        }
                    ]
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "21pqx742-1r39-5y82-d3b0-92wk5izx1984",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            }
        ],
        "__v" : 0
    }

    function GatherCFBSurvivorInfo(gameId) {
        const username = UserDB?.user_name;
        const userFullName = `${UserDB?.first_name} ${UserDB?.last_name}`;
        const email = UserDB?.email;
        const userId = UserDB?.user_id;
        const games = UserDB?.Games || [];
        //console.log("GAMES:", games);
        const survivorGame = games?.find(game => game.gameID === gameId);
        const picks = survivorGame?.pool?.picks || [];
        //console.log("PICKS:", picks);
    
        let totalWins = 0;
        let totalMargin = 0;
        let ACCWins = 0;
        let Big12Wins = 0;
        let BigTenWins = 0;
        let SECWins = 0;
        let ACCMargin = 0;
        let Big12Margin = 0;
        let BigTenMargin = 0;
        let SECMargin = 0;
        let doesUserHaveACCLoss = false;
        let doesUserHaveBig12Loss = false;
        let doesUserHaveBigTenLoss = false;
        let doesUserHaveSECLoss = false;
        let isUserEliminated = false;
        let lossWeekACC = null;
        let lossWeekBig12 = null;
        let lossWeekBigTen = null;
        let lossWeekSEC = null;
    
        picks.forEach(pick => {
            const margin = pick?.margin || 0;
    
            if (pick?.result === "Win") {
                totalWins++;
                totalMargin += margin;
                switch (pick.conference) {
                    case 'ACC':
                        ACCWins++;
                        ACCMargin += margin;
                        break;
                    case 'Big 12':
                        Big12Wins++;
                        Big12Margin += margin;
                        break;
                    case 'Big Ten':
                        BigTenWins++;
                        BigTenMargin += margin;
                        break;
                    case 'SEC':
                        SECWins++;
                        SECMargin += margin;
                        break;
                    default:
                        break;
                }
            } else if (pick?.result === "Loss") {
                totalMargin += margin;
                switch (pick.conference) {
                    case 'ACC':
                        doesUserHaveACCLoss = true;
                        lossWeekACC = pick?.week;
                        ACCMargin += margin;
                        break;
                    case 'Big 12':
                        doesUserHaveBig12Loss = true;
                        lossWeekBig12 = pick?.week;
                        Big12Margin += margin;
                        break;
                    case 'Big Ten':
                        doesUserHaveBigTenLoss = true;
                        lossWeekBigTen = pick?.week;
                        BigTenMargin += margin;
                        break;
                    case 'SEC':
                        doesUserHaveSECLoss = true;
                        lossWeekSEC = pick?.week;
                        SECMargin += margin;
                        break;
                    default:
                        break;
                }
            }
        });
    
        // Check if the user has been eliminated (i.e., has a loss in all 4 conferences)
        if (doesUserHaveACCLoss && doesUserHaveBig12Loss && doesUserHaveBigTenLoss && doesUserHaveSECLoss) {
            isUserEliminated = true;
        }
    
        return {
            userId,
            totalWins,
            ACCWins,
            Big12Wins,
            BigTenWins,
            SECWins,
            ACCMargin,
            Big12Margin,
            BigTenMargin,
            SECMargin,
            doesUserHaveACCLoss,
            doesUserHaveBig12Loss,
            doesUserHaveBigTenLoss,
            doesUserHaveSECLoss,
            isUserEliminated,
            lossWeekACC,
            lossWeekBig12,
            lossWeekBigTen,
            lossWeekSEC,
            username,
            userFullName,
            email,
            picks,
            totalMargin
        };
    }
    
    

    const Test = GatherCFBSurvivorInfo("21pqx742-1r39-5y82-d3b0-92wk5izx1984");
    console.log("CFB Survivor Info:", Test);

    return (
        <div>
            <h1>Test</h1>
        </div>
    )
}

export default GatherCFBSurvivorInfo;