import React from 'react';
import { connect } from 'react-redux';
import { useGameContext } from './context/createGamesContext';
import BlockPoolChoices from './createGamesAdditional/blockPoolChoices';
import CreateGameUniversalFormElements from './createGamesAdditional/createGameUniversal';
import CreateGameAside from './createGamesAdditional/createGameAside';
import UserInviteForm from './createGamesAdditional/userInviteForm';
import PickemChoices from './createGamesAdditional/pickemChoices';
// import './styles.scss';

const CreateGamesComponent = () => {

    const {
        handleCreateGames,
        gameType,
        handleCreatePickemGames
    } = useGameContext();

    return(
        <>
            <div className="create-game-grid">
                <div className='create-game-box'>
                    <h1>Create a Game</h1>
                    <h2>Game Info</h2>
                    <CreateGameUniversalFormElements />
                    {gameType === 'Block_Pool'?<><BlockPoolChoices /> <button onClick={handleCreateGames}>Create Game</button> </> : null}
                    {gameType === 'Pickem'? <> <PickemChoices /> <button onClick={handleCreatePickemGames}>Create Game</button> </> : null}
                    <UserInviteForm />

                </div>
                <div className="game-preview-container">
                   <CreateGameAside />
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({
    games: state.games,
    game: state.game,
    user: state.user,
});

export default connect(mapStateToProps)(CreateGamesComponent);
