import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import { getTeamLogo } from "../../../utils/SportsData";
import { getTeamName } from "../../../utils/SportsData";
import ChampPicksTeamCard from "../../SuperPickem/PoolPicks/ChampPicksTeamCard/ChampPicksTeamCard";
import PercentagePillBoxWithText from "../../GamePageComponents/PercentagePillBox/PercentagePillBoxWithText";

const PoolPicksLists= ({ tab, selectedRound, poolPicksData, league, teams, hasJoinDeadlinePassed }) => {

    //console.log("poolPicksData in PoolPicksLists: ", poolPicksData);    
    // Filter poolPicksData by selectedRound
    const poolPicksDataByRound = poolPicksData?.rounds[selectedRound];
    //console.log("poolPicksDataByRound in PoolPicksLists: ", poolPicksDataByRound, league);

    const totalPickCount = poolPicksDataByRound?.totalPicks;

    // State to manage sorting
    const [sortConfig, setSortConfig] = useState({
        key: 'count', // Default sort by count
        direction: 'descending' // Default to descending order
    });

    // Reset sort when tab changes
    useEffect(() => {
        setSortConfig({
            key: 'count',
            direction: 'descending'
        });
    }, [tab]);

     // Add sorted data computation
     const sortedGames = useMemo(() => {
        if (!poolPicksDataByRound?.Games) return [];
        
        const sortedData = [...poolPicksDataByRound.Games];
        sortedData.sort((a, b) => {
            if (sortConfig.key === 'seed') {
                return sortConfig.direction === 'ascending' 
                    ? a.seed - b.seed
                    : b.seed - a.seed;
            }
            // Sort by count
            return sortConfig.direction === 'ascending'
                ? a.pickCount - b.pickCount
                : b.pickCount - a.pickCount;
        });
        return sortedData;
    }, [poolPicksDataByRound?.Games, sortConfig]);

    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
                };
            }
            return {
                key,
                direction: key === 'count' ? 'descending' : 'ascending'
            };
        });
    };




    return (
        <TableContainer 
                component={Paper}
                sx={{
                    maxWidth: '700px',
                    margin: '0 auto',
                    borderRadius: '0px',
                    marginTop: '24px',
                }}    
            >
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: '#002129',
                                height: '56px',
                            }}
                        >
                            <TableCell
                                sx={{
                                    width: '51%',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleSort('seed')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        textDecoration: 'underline',
                                        color: sortConfig.key === 'seed' ? '#FFC60A' : '#fff',
                                    }}
                                >
                                    {poolPicksDataByRound?.tableHeader}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '49%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    cursor: hasJoinDeadlinePassed ? 'pointer' : 'default', // Change cursor style
                                }}
                                onClick={() => {
                                    if (hasJoinDeadlinePassed) {
                                        handleSort('count'); // Only call if condition is true
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                        color: sortConfig.key === 'count' ? '#FFC60A' : '#fff',
                                    }}
                                >
                                    TIMES PICKED
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedGames?.map((champStat, index) => {
                            const teamId = champStat?.teamId;
                            const result = champStat?.result;
                            const count = champStat?.pickCount;
                            const seed = champStat?.seed;
                            const logo = getTeamLogo(league, teamId, teams);
                            const name = getTeamName(league, teamId, teams);

                            return (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F4F4F4',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            padding: '16px 0px 16px 0px',
                                        }}
                                    >
                                        <ChampPicksTeamCard
                                            teamInfo={{
                                                seed: seed,
                                                nameDisplay: name,
                                                teamDisplayCharacterCount: name?.length,
                                                logo: logo,
                                            }}
                                            result={result}
                                            areSeedsIn={true}
                                            index={index}
                                        />
                                    </TableCell>
                                    {hasJoinDeadlinePassed ? (
                                        <TableCell
                                            sx={{
                                                padding: '16px 0px 16px 0px',
                                            }}
                                        >
                                            <PercentagePillBoxWithText
                                                count={count}
                                                total={totalPickCount}
                                                result={result}
                                            />
                                        </TableCell>
                                        ) : (
                                            <TableCell
                                                sx={{
                                                    padding: '16px 0px 16px 0px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '90%',
                                                        border: '1px solid #002129',
                                                        borderRadius: '8px',
                                                        margin: '0 auto',
                                                        height: '40px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            color: '#002129',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        TBD
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
    );
}

export default PoolPicksLists;