import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../NFLPickEmStyles/PickEmUserDash.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNFLPickEmContext } from "../context/NFLPickEmContext";
import { useParams } from "react-router-dom";
import PickEmUserStatsModal from "../../GameModals/PickEmModals/PickEmUserStats/PickEmUserStatsModal";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import PickEmTiebreakerModal from "../PickEmTiebreakerModal";

const PickEmUserDash = () => {
  const { 
    startingWeekState,
    userPicksFromDB,
    NFLScoresBySeason,
    myUsername,
    doesUserBelong, 
    mainGameId,
    gameType,
    userData,
    setSignUpState,
    gameAddress,
    gameData,
    myUserId,
    tiebreaker,
    NFLTeams,
    joinOpen,
    setJoinOpen
  } = useNFLPickEmContext();

  const { component } = useParams();

  const gameName = gameData?.gameName;
  const logo = gameData?.clubLogo;
  //console.log("GameName and Game Type in User Dash: ", gameName, gameType);

 
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [tiebreakerOpen, setTiebreakerOpen] = useState(false);

  useEffect (() => {
    if (startingWeekState === 18 && tiebreaker === null && doesUserBelong) {
      setTiebreakerOpen(true);
    }
  }, [startingWeekState, tiebreaker]);
    
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
 

  const seasonRecord = () => {
    let wins = 0;
    let losses = 0;
    let ties = 0;
    let points = 0;
    userPicksFromDB?.forEach((pick) => {
      if (pick.result === "Win") {
        wins++;
      } else if (pick.result === "Loss") {
        losses++;
      } else if (pick.result === "Push") {
        ties++;
      }
    });
    points = wins + (ties * 0.5);
    return { wins, losses, ties, points };
  };

  const { wins: seasonWins, losses: seasonLosses, ties: seasonTies, points: seasonPoints } = seasonRecord();

  const weeklyRecord = () => {
    let wins = 0;
    let losses = 0;
    let ties = 0;
    userPicksFromDB?.forEach((pick) => {
      if (pick.week === startingWeekState) {
        if (pick.result === "Win") {
          wins++;
        } else if (pick.result === "Loss") {
          losses++;
        } else if (pick.result === "Push") {
          ties++;
        }
      }
    });
    return { wins, losses, ties };
  };

  const { wins: weeklyWins, losses: weeklyLosses, ties: weeklyTies } = weeklyRecord();

  const totalWeeklyPicks = userPicksFromDB?.filter(
    (pick) => pick.week === startingWeekState && pick.teamId !== "No Pick"
  ).length;
  

  //console.log("Total Weekly Picks:", totalWeeklyPicks);

  return (
    <>
      <Box className={"bracket-box-header"} sx={{ height: isMobile ? '87px' : '200px'}}>
      <Box className="bracket-box-header-trophy" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <img className="bracket-box-trophy" src={bracketTrophy} alt='' />
        <Typography className="bracket-box-header-username" variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          {myUsername}
          {doesUserBelong && (
            <span 
              style={{ 
                color: '#00AAD1', 
                fontSize: isMobile ? '12px' : '20px', 
                fontWeight: 500, 
                textDecoration: 'underline',
                marginLeft: '16px',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer', // Add cursor pointer to indicate it's clickable
              }}
              onClick={() => setStatsModalOpen(true)}
            >
              My Stats
            </span>
          )}
          {/* {doesUserBelong && startingWeekState === 18 && (
            <span
              style={{
                color: '#00AAD1',
                fontSize: isMobile ? '12px' : '20px',
                fontWeight: 500,
                textDecoration: 'underline',
                marginLeft: '16px',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer', // Add cursor pointer to indicate it's clickable
              }}
              onClick={() => setTiebreakerOpen(true)}
            >
              Tiebreaker
            </span>
          )} */}
        </Typography>
      </Box>
          {doesUserBelong && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              maxWidth: '1102px',
              margin: '0 auto',
              width:'90%'
            }}>
              {isMobile ? (
                // These items will be displayed on mobile devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>POINTS</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>SEASON</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonWins}-{seasonLosses}-{seasonTies}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>WEEK {startingWeekState}</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>WEEK {startingWeekState} STATUS</Typography>
                    <Box className="bracket-box-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {totalWeeklyPicks === 5 ? (
                        <>
                          <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                          <Typography className="bracket-box-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/5)
                          </Typography>
                        </>
                      ) : (
                        <>
                          <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                          <Typography className="bracket-box-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/5)
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </>
              ) : (
                // These items will be displayed on desktop devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>POINTS</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{seasonPoints}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>SEASON</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{seasonWins}-{seasonLosses}-{seasonTies}</Typography>
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'> WEEK {startingWeekState}</Typography>
                    <Typography className={"bracket-box-header-values"} variant='h4'>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>WEEK {startingWeekState} STATUS</Typography>
                    <Box className="bracket-box-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {totalWeeklyPicks === 5 ? (
                        <>
                          <CheckIcon sx={{ color: '#00AA72', fontSize: '44px' }} />
                          <Typography className="bracket-box-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/5)
                          </Typography>
                        </>
                      ) : (
                        <>
                          <CloseIcon sx={{ color: '#cc293c', fontSize: '44px' }} />
                          <Typography className="bracket-box-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/5)
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}
          {!doesUserBelong && (
            <Button 
                variant="contained" 
                onClick={() => setJoinOpen(true)}
                sx={{ 
                    fontSize: '14px', 
                    fontWeight: '700', 
                    width: '100px', 
                    height: '36px', 
                    whiteSpace: 'nowrap',
                    marginTop: isMobile ? '0px' : '24px',
                }}
            >
                JOIN POOL
            </Button>
          )}
        </Box>
        {statsModalOpen && (
          <PickEmUserStatsModal 
            statsModalOpen={statsModalOpen} 
            setStatsModalOpen={setStatsModalOpen} 
            myUsername={myUsername} 
            usersPicks={userPicksFromDB}
            NFLTeams={NFLTeams}
            areTheseMyPicks={true}
            startingWeekState={startingWeekState}
            NFLScoresBySeason={NFLScoresBySeason}
          />
        )}
        {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                />
            )}
        {tiebreakerOpen && (
            <PickEmTiebreakerModal
                tiebreakerOpen={tiebreakerOpen}
                setTiebreakerOpen={setTiebreakerOpen}
            />
        )}

      </>
    );
}

export default PickEmUserDash;