import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button } from '@mui/material';
//import { useBirdieContext } from '../context/BirdiePoolContext';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import PickEmLeaderboardDropdown from '../DropdownMenus/PickEmLeaderboardDropdown';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import PickEmUserStatsModal from '../../GameModals/PickEmModals/PickEmUserStats/PickEmUserStatsModal';
import { DateTime } from 'luxon';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';
import WeeklyWinnerDisplay from '../../ChampionDisplay/WeeklyWinnerDisplay';
import Top3Display from '../../ChampionDisplay/Top3Display';


const PickEmLeaderboard = ({ handleClickState, leaderboardData }) => {
  
  const { 
    startingWeekState, 
    myUserId,
    myUsername,
    NFLTeams,
    NFLScoresBySeason,
    userPicksFromDB,
    mainGameId,
    partyState,
    isPickEmOver, 
  } = useNFLPickEmContext();

  const navigateToTop = useNavigateToTop();
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

const handleRulesClick = () => {
    navigateToTop(`/nfl-pickem/${uriEncoded(mainGameId)}/RULES/RULES`);
}

  /**
 * Checks if the current date and time in NYC, USA (Eastern Time) is on or after
 * January 5, 2025, 8:20 PM.
 *
 * @returns {boolean} - Returns true if the current date and time is on or after
 *                      January 5, 2025, 8:20 PM in NYC, USA. Returns false otherwise.
 */
function tiebreakerDeadlinePassed() {
  // Define the deadline in Eastern Time (New York)
  const deadline = DateTime.fromObject(
      { year: 2025, month: 1, day: 5, hour: 20, minute: 20 },
      { zone: 'America/New_York' }
  );

  // Get the current date and time in Eastern Time (New York)
  const now = DateTime.now().setZone('America/New_York');

  // Compare the current time to the deadline
  return now >= deadline;
}

const hasTiebreakerDeadlinePassed = tiebreakerDeadlinePassed();
//console.log("TIEBREAKER DEADLINE PASSED", hasTiebreakerDeadlinePassed);

  //console.log("LEADERBOARD DATA in Leaderboard", leaderboardData);
  
  //const usersEntry = data?.find(entry => entry.userName === myUserName && entry.userId === myUserId);
  //console.log("USERS ENTRY", usersEntry);



  const [sortBy, setSortBy] = useState('rank');
  const [sortedColumn, setSortedColumn] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');
  const [seasonVsWeek, setSeasonVsWeek] = useState('Season Standings');
  const [userStatsOpen, setUserStatsOpen] = useState(false);
  const [userStatsInfo, setUserStatsInfo] = useState(null);
  const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);

  // useEffect(() => {
  //   console.log("Season vs Week in Leaderboard", seasonVsWeek);
  // }, [seasonVsWeek]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const seasonText = "Season Standings";



  const handleSort = (column) => {
    // If clicking the same column, toggle the sortOrder
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc'); // Reset to ascending when sorting a new column
    }
  };



const assignRanks = (data) => {
  if (seasonVsWeek === 'Last Place') {
    // Filter out users who have noPicks > 0
    data = data.filter(entry => entry.noPicks === 0);
    //console.log("DATA FILTERED", data);
    data = data.filter(entry => (entry?.totalWins + entry?.totalLosses + entry?.totalPushes) >= (startingWeekState - 1)*5);
    //console.log("DATA FILTERED AGAIN", data);
    // Sort in ascending order for "Last Place"
    data.sort((a, b) => {
      const aPoints = a.totalPoints ?? 0;
      const bPoints = b.totalPoints ?? 0;
      const aMargin = a.totalMargin ?? 0;
      const bMargin = b.totalMargin ?? 0;
     

      // Sort by points first, then by margin (ascending for Last Place)
      if (aPoints !== bPoints) {
        return aPoints - bPoints;
      } else {
        return aMargin - bMargin;
      }
    });
  } else {
    // Sort in descending order for other cases
    data.sort((a, b) => {
      const aPoints = a.totalPoints ?? 0;
      const bPoints = b.totalPoints ?? 0;
      const aMargin = a.totalMargin ?? 0;
      const bMargin = b.totalMargin ?? 0;
       //console.log("A POINTS", aPoints, "B POINTS", bPoints, "A MARGIN", aMargin, "B MARGIN", bMargin);

      // Sort by points first, then by margin (descending for other cases)
      if (aPoints !== bPoints) {
        return bPoints - aPoints; // Descending order for points
      } else {
        return bMargin - aMargin; // Descending order for margin (highest is best)
      }
    });
  }

  let rank = 1;
  let tiedRank = 1;
  let previousPoints = data[0]?.totalPoints ?? 0;
  let previousMargin = data[0]?.totalMargin ?? 0;

  return data.map((entry, index) => {
    const currentPoints = entry.totalPoints ?? 0;
    const currentMargin = entry.totalMargin ?? 0;

    if (currentPoints === previousPoints && currentMargin === previousMargin) {
      entry.rank = tiedRank;
    } else {
      rank = index + 1;
      tiedRank = rank;
      entry.rank = rank;
    }

    previousPoints = currentPoints;
    previousMargin = currentMargin;
    return entry;
  });
};


  let rankedData = assignRanks(leaderboardData);
  //console.log("RANKED DATA", rankedData);

  if (seasonVsWeek === 'Last Place') {
    rankedData = rankedData?.filter(entry => entry.noPicks === 0);
  }


  let weeklyLeaderboard = [];
  function findWeeklyLeaderboard() {
    const selectedWeek = seasonVsWeek?.replace(/\D/g, '');
    //console.log("SELECTED WEEK", selectedWeek);

    const weeklyLeaderboard = [];

    for (let i = 0; i < rankedData?.length; i++) {
        const user = rankedData[i];
        const username = user.username;
        const userId = user.userId;
        const picks = user.picks;
        //console.log("PICKS SELECTED WEEK", picks);

        let wins = 0;
        let losses = 0;
        let pushes = 0;
        let points = 0;
        let totalMargin = 0;

        if (picks) {
            for (let j = 0; j < picks.length; j++) {
                const game = picks[j];
                if (String(game.week) === selectedWeek) {
                    const result = game.result;

                    if (result === 'Win') {
                        wins++;
                        totalMargin += game?.margin ?? 0;
                    } else if (result === 'Loss' && game.teamId !== 'No Pick') {
                        losses++;
                        totalMargin += game?.margin ?? 0;
                    } else if (result === 'Push') {
                        pushes++;
                    }
                }
            }

            points = wins + (pushes * 0.5);

        }

            // Create entry for the user
            const entry = {
                username,
                userId,
                totalWins: wins,
                totalLosses: losses,
                totalPushes: pushes,
                totalPoints: points,
                picks,
                totalMargin: totalMargin
            };

            // Push entry to leaderboard
            weeklyLeaderboard.push(entry);
        
    }

    return weeklyLeaderboard;
}
  const weeklyData = findWeeklyLeaderboard();
  weeklyData?.sort((a, b) => b.totalPoints - a.totalPoints);
  //console.log("WEEKLY LEADERBOARD", weeklyData);

  const rankedWeeklyData = assignRanks(weeklyData);
  //console.log("RANKED WEEKLY DATA", rankedWeeklyData);


  const dataToSort = (seasonVsWeek === 'Season Standings' || seasonVsWeek === 'Last Place') ? rankedData : rankedWeeklyData;
  //console.log("Data to Sort", dataToSort);

  const sortedData = [...dataToSort].sort((a, b) => {
    if (sortBy === 'username') {
      // Sort alphabetically by player names
      const nameA = a[sortBy].toUpperCase();
      const nameB = b[sortBy].toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    } else {
      // For numeric columns like 'points'
      const comparison = a[sortBy] - b[sortBy];
      return sortOrder === 'asc' ? comparison : -comparison;
    }
  });

  const usersEntry = sortedData?.find(entry => entry.userId === myUserId);
  //console.log("USERS ENTRY in Leaderboard", usersEntry);



  const rankHeaderColor = sortedColumn === 'rank' ? '#FFC60A' : '#fff';
  const pointsHeaderColor = sortedColumn === 'points' ? '#FFC60A' : '#fff';
  const recordHeaderColor = sortedColumn === 'record' ? '#FFC60A' : '#fff';
  const playerHeaderColor = sortedColumn === 'username' ? '#FFC60A' : '#fff';


  const whosInFirst = sortedData?.[0]?.username;
  const whosInSecond = sortedData?.[1]?.username;
  const whosInThird = sortedData?.[2]?.username;
  const whosInFirstCharacterCount = whosInFirst?.length;
  const whosInSecondCharacterCount = whosInSecond?.length;
  const whosInThirdCharacterCount = whosInThird?.length;
  //console.log("WHOS IN FIRST", whosInFirst, whosInFirstCharacterCount);

  let selectedWeek = 1;
  if (seasonVsWeek !== 'Season Standings' && seasonVsWeek !== 'Last Place') {
    selectedWeek = seasonVsWeek?.replace(/\D/g, '');
  }
  const thisWeeksGames = NFLScoresBySeason?.filter((week) => week.Week === Number(selectedWeek));
  const hasWeekEnded = thisWeeksGames?.every((game) => game.Status === "Final" || game.Status === "F/OT");

  const isThisSegmentOver = (seasonVsWeek === 'Season Standings' || seasonVsWeek === 'Last Place') ? isPickEmOver : hasWeekEnded;
  //console.log("This Weeks Games", thisWeeksGames, "Has Week Ended", hasWeekEnded, "Is Pick Em Over", isPickEmOver, "Is This Segment Over", isThisSegmentOver, "Selected Week:", selectedWeek, "Season vs Week", seasonVsWeek);

  return (
    <>
      <Box>
      <PickEmLeaderboardDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek} seasonText={seasonText} lastPlace={true}/>
      
      {(seasonVsWeek === 'Season Standings' && isPickEmOver) && whosInFirst !== undefined && (
          <Top3Display
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            partyName={partyState}
            line2={"2024 NFL Pick 'Em - Fav 5 Winners"}
            whosInSecond={whosInSecond}
            whosInSecondCharacterCount={whosInSecondCharacterCount}
            whosInThird={whosInThird}
            whosInThirdCharacterCount={whosInThirdCharacterCount}
          />
      )}
      {(seasonVsWeek === 'Last Place' && isPickEmOver) && whosInFirst !== undefined && (
          <ChampionDisplay
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            partyName={partyState}
            line2={"2024 NFL Pick 'Em - Fav 5 Last Place Winner"}
          />
      )}
      {seasonVsWeek !== 'Season Standings' && seasonVsWeek !== 'Last Place' && isThisSegmentOver && whosInFirst !== undefined && (
          <WeeklyWinnerDisplay
            whosInFirst={whosInFirst}
            whosInFirstCharacterCount={whosInFirstCharacterCount}
            selectedWeek={selectedWeek}
          />
      )}
      
      { seasonVsWeek === "Last Place" && (
        <Box
          sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px 0',
              justifyContent: 'center',
          }}
      >
          <Typography
              sx={{
                  fontSize: isMobile ? '12px' : '16px',
                  fontWeight: 500,
                  textAlign: 'center',
                  marginBottom: '10px'
              }}
          >
              The competition for last place.
          </Typography>
          <Typography
              sx={{
                  fontSize: isMobile ? '12px' : '16px',
                  fontWeight: 500,
                  textAlign: 'center',
                  marginTop: '10px',
                  marginBottom: '10px',
                  maxWidth: isMobile ? '320px' : '500px',
              }}
          >
              *An entrant must make 5 picks/week for ALL 18 weeks. A single missed pick results in disqualification.
          </Typography>
      </Box>
      )}
       <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isPickEmOver || isThisSegmentOver ? '10px' : '0px',
                    marginBottom: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => setTiebreakInfoOpen(true)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        color: '#00AAD1',
                        marginRight: '5px',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <InfoIcon
                    sx={{
                        color: '#00AAD1',
                        fontSize: isMobile ? '16px' : '20px',
                    }}
                />
            </Box>
      {/* <h1 style={{ textDecoration: 'underline' }}>{groupState ?  groupState : headerDisplayName} Leaderboard</h1> */}
      <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', marginBottom: '20px'}}>
        <Table>
          {usersEntry &&
            <TableHead sx={{ backgroundColor: '#F0F1F1'}}>
              <TableRow>
                <TableCell className="pickem-leaderboard-table-cells" style={{width:'57px', textAlign:'center', fontSize: isMobile ? '12px' : '14px'}}>{usersEntry?.rank}</TableCell>
                <TableCell
                    className="pickem-leaderboard-table-cells"
                    style={{ width: '160px', fontSize: isMobile ? '12px' : '16px' }}
                  >
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 700 }}
                      onClick={() => {
                        setUserStatsOpen(true);
                        setUserStatsInfo(usersEntry); // Pass the entire usersEntry object
                      }}
                    >
                      {usersEntry?.username}
                    </span>
                    <div style={{ fontSize: isMobile ? '12px' : '16px'}}>Margin ATS: {usersEntry?.totalMargin ? usersEntry?.totalMargin : 0}</div>
                    {/* {hasTiebreakerDeadlinePassed && seasonVsWeek === 'Season Standings' && (
                            <div style={{ fontSize: isMobile ? '12px' : '14px'}}>
                                Tiebreaker: {usersEntry?.tiebreaker ?? 'N/A'}
                            </div>
                        )} */}
                  </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '81px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {usersEntry?.totalPoints ?? 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                >
                  {`${usersEntry?.totalWins ?? 0}-${usersEntry?.totalLosses ?? 0}-${usersEntry?.totalPushes ?? 0}`}
                </TableCell>

              </TableRow>
            </TableHead>
        }
          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('rank');
                }}
                sx={{ width: '57px', color: rankHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                RANK
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('username');
                  setSortedColumn('username');
                }}
                sx={{ width: '160px', color: playerHeaderColor, fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'}}
              >
                PLAYER
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                onClick={() => {
                  handleSort('rank');
                  setSortedColumn('points');
                }}
                sx={{ width: '81px', color: pointsHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'} }
              >
                {seasonVsWeek === "Season Standings" ? "TOT PTS" : "PTS"}
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                // onClick={() => handleSort('record')}
                sx={{ width: '77px', color: recordHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px'}}
              >
                W-L-T
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, index) => (
                  <TableRow
                    key={row.userId}
                    sx={{
                      backgroundColor: row.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                    }}
                  >
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '57px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px'}}>
                      {row?.rank}
                    </TableCell>
                    <TableCell className="pickem-leaderboard-table-cells" style={{ width: '162px', fontSize: isMobile ? '12px' : '16px' }}>
                      <span
                        style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 700 }}
                        onClick={() => {
                          setUserStatsOpen(true);
                          setUserStatsInfo(row); // Pass the entire row data
                        }}
                      >                      
                          {row?.username}
                      </span>
                      <div style={{ fontSize: isMobile ? '12px' : '16px'}}>Margin ATS: {row?.totalMargin ? row?.totalMargin : 0}</div>
                        {/* {hasTiebreakerDeadlinePassed && seasonVsWeek === 'Season Standings' && (
                            <div style={{ fontSize: isMobile ? '12px' : '14px'}}>
                                Tiebreaker: {row?.tiebreaker ?? 'N/A'}
                            </div>
                        )} */}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {row?.totalPoints ?? 0}
                    </TableCell>
                    <TableCell
                      className="pickem-leaderboard-table-cells"
                      style={{ width: '47px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                    >
                      {`${row?.totalWins ?? 0}-${row?.totalLosses ?? 0}-${row?.totalPushes ?? 0}`}
                    </TableCell>

                  </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    {userStatsOpen && (
      <PickEmUserStatsModal
        statsModalOpen={userStatsOpen}
        setStatsModalOpen={setUserStatsOpen}
        myUsername={userStatsInfo?.username}
        usersPicks={myUsername === userStatsInfo?.username ? userPicksFromDB : userStatsInfo?.picks}
        NFLTeams={NFLTeams}
        NFLScoresBySeason={NFLScoresBySeason}
        startingWeekState={startingWeekState}
        areTheseMyPicks={myUsername === userStatsInfo?.username}
      />
    )}
    {tiebreakInfoOpen && (
                <Modal
                open={tiebreakInfoOpen}
                onClose={() => setTiebreakInfoOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 'none',
                }}
            >
                <Box
                    sx={{
                        width: '323px',
                        height: 'auto',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '16px', // Optional padding
                        boxShadow: 24, // Optional shadow for better visibility
                    }}
                >
                    {/* Modal content goes here */}
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '800',
                            textAlign: 'center',
                        }}
                    >
                        Tiebreaker Info
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      {(seasonVsWeek === 'Last Place' || seasonVsWeek === 'Season Standings') && (
                        <>
                          <span style={{ display: 'block' }}>
                            This pool will have a single outright winner. Tiebreakers will be used in the event:
                          </span>
                          <span style={{ display: 'block', marginTop: '10px' }}>
                            Multiple players are tied with the {seasonVsWeek === 'Last Place' ? 'least' : 'most'} points at the end of Week 18.
                          </span>
                        </>
                      )}
                      {(seasonVsWeek !== 'Last Place' && seasonVsWeek !== 'Season Standings') && (
                        <>
                          <span style={{ display: 'block' }}>
                            If your pool has a weekly competition, the tiebreaker will be used in the event:
                          </span>
                          <span style={{ display: 'block', marginTop: '10px' }}>
                            Multiple players are tied with the most points at the end of a week.
                          </span>
                        </>
                      )}
                    </Typography>

                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                textAlign: 'center',
                                marginTop: '20px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ display: 'block', marginTop: '10px' }}>
                                <span style={{ fontWeight: 700 }}>
                                    Tiebreaker:</span> {seasonVsWeek === "Last Place" ? "Lowest" : "Largest"} Win Margin ATS
                            </span>

                        </Typography>
                    </Box>
                    {(seasonVsWeek === 'Last Place' || seasonVsWeek === 'Season Standings')  && (
                      <Typography
                          sx={{
                              fontSize: '12px',
                              fontWeight: '500',
                              textAlign: 'center',
                              marginTop: '10px',
                          }}
                      >
                          See the pool{' '}
                          <span
                              style={{
                                  textDecoration: 'underline',
                                  color: '#00AAD1',
                                  cursor: 'pointer',
                                  fontWeight: '700',
                              }}
                              onClick={handleRulesClick}
                          >
                              RULES
                          </span>
                          {' '}for complete details!
                      </Typography>
                    )}
                    <Button
                        sx={{
                            backgroundColor: '#00AAD1', // Primary color
                            height: '38px',
                            width: '115px',
                            color: 'white', // Text color
                            display: 'block', // Center horizontally
                            margin: '0 auto', // Horizontally centers the button
                            marginTop: '20px',
                            '&:hover': {
                                backgroundColor: '#00AAD1', // Ensure the background color stays the same on hover
                            },
                            '&:focus': {
                                outline: 'none', // Remove focus outline
                            },
                            '&:active': {
                                backgroundColor: '#00AAD1', // Keep the same color when the button is active
                            },
                        }}
                        onClick={() => setTiebreakInfoOpen(false)}
                    >
                        OK
                    </Button>

                </Box>
            </Modal>
            )}

  </>

  );
};

export default PickEmLeaderboard;