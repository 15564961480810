import React, { useState } from 'react';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";


const PrivateGroupHomePage = () => {
    const { groupState, setGroupState, headerDisplayName, userSubgroups, setClubLeaderboardInfo, setSelectedUser, myUserId, myUserName, setSelectedUserId, AllGameInfo } = useBirdieContext();
    //console.log("Group State in Private Party Home Page", groupState, "Header Display Name", headerDisplayName);
    //console.log("User Subgroups in Private Party Home Page", userSubgroups);

    const { gameId, component } = useParams();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleGroupClick = (subGroupName) => {
        //console.log("Group Clicked");
        setGroupState(subGroupName);
        const matchingEntry = AllGameInfo.find(entry => entry.name === subGroupName);
        //console.log("Matching Entry", matchingEntry);
        setClubLeaderboardInfo(matchingEntry);
        setSelectedUser(myUserName);
        setSelectedUserId(myUserId);
        navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(subGroupName)}`);
    }

    //TODO:Fix the conditional rendering of the message.  It should be if user is in a subgroup or not.  Use userSubgroups from context.

    return (
        <>
            <div>
                {userSubgroups && userSubgroups.length === 0 && (
                <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px'}}>
                    My Party
                </Typography>
                )}
                {userSubgroups && userSubgroups.length >= 1 && (
                    <>
                        <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px'}}>
                            { userSubgroups.length > 1 ? "Active Parties" : "Active Party" }
                        </Typography>
                        {userSubgroups.map((subgroup, index) => (
                             <div key={index} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <Box sx={{ maxWidth: '500px', margin: '0 auto', marginLeft: isMobile ? '30px' : null}}>
                                    <Typography
                                        variant="h6"
                                        className="message"
                                        style={{ fontSize: isMobile ? '16px' : '20px', marginBottom: '15px', textAlign: 'left', marginLeft: '10px'}}
                                    >
                                        {subgroup.commissioner ? 'Host: ' : 'Member: '}
                                        <span
                                        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#00AAD1' }}
                                        onClick={() => handleGroupClick(subgroup.name)}
                                        >
                                        {subgroup.name}
                                        </span>
                                    </Typography>
                                    </Box>
                                </div>
                        ))}
                    </>
                )}
                {/* {userSubgroups && userSubgroups.length > 1 && (
                    <>
                        <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px'}}>
                            Active Parties
                        </Typography>
                        {userSubgroups.map((subgroup, index) => (
                            <div key={index} style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Typography
                                variant="h6"
                                className="message"
                                style={{ fontSize: '16px', marginBottom: '15px' }}
                            >
                                {subgroup.commissioner ? 'Host: ' : 'Member: '}
                                <span
                                style={{ cursor: 'pointer', textDecoration: 'underline', color: '#00AAD1' }}
                                onClick={() => handleGroupClick(subgroup.name)}
                                >
                                {subgroup.name}
                                </span>
                            </Typography>
                            </div>
                        ))}
                    </>
                )} */}
                {userSubgroups && userSubgroups.length === 0 && (
                <Typography variant="h6" className="message" style={{ fontSize: '16px', marginBottom: '15px'}}>
                    Create a competition with your custom group
                </Typography>
                )}
                {userSubgroups && userSubgroups.length >= 1 && (
                    <Typography variant="h4" style={{ marginBottom: '15px', marginTop:'45px'}}>
                    Create Another Party?
                    </Typography>
                )}
            </div>
        </>
    )
}

export default PrivateGroupHomePage;