import React from "react";
import { Typography, Box, Grid, Paper, TableContainer, TableBody, Table, TableHead, TableRow, TableCell } from '@mui/material';
import { amITied } from "../utils/sharedUtils";
import { hexToRgba } from "../../Brackets/bracketUtils/HexToRgba";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";

const PlayerPicks = ({
    myUserId,
    leaderboard,
    teams,
    schedule,
    games,
    gatherLogo,
    pageInterval,
    teamsOrNames,
    hasJoinDeadlinePassed,
    rounds,
    userPicksFromDB,
}) => {
    const picksInThisInterval = rounds?.find(round => round.round === pageInterval)?.games;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const filteredGames = games?.filter(game => game.round === pageInterval)?.map(game => game.GlobalGameID);
    //console.log("Filtered Games:", filteredGames, games, pageInterval);

    const filteredSchedule = schedule?.filter(game => filteredGames?.includes(game.GlobalGameID));
    //console.log("Filtered Schedule:", filteredSchedule);

    filteredSchedule?.sort((a, b) => {
        // Define the desired order for Status
        const statusOrder = ['InProgress', 'Final', 'F/OT'];

        // Get the index of each game's Status in the statusOrder array
        const statusA = statusOrder.indexOf(a.Status);
        const statusB = statusOrder.indexOf(b.Status);

        // Handle Status order first
        if (statusA !== statusB) {
            // If statusA or statusB isn't in the array, place them at the end (use Infinity)
            return (statusA === -1 ? Infinity : statusA) - (statusB === -1 ? Infinity : statusB);
        }

        // If Status is the same, sort by DateTime
        return new Date(a.DateTime) - new Date(b.DateTime);
    });

    const gatherPoolPicksHeaderInfo = (gameId, teamsOrNames, schedule, pageInterval) => {
        const game = schedule?.find(game => game.GlobalGameID === gameId);
        const gameStatus = game?.Status;
        const homeScore = game?.HomeTeamScore;
        const awayScore = game?.AwayTeamScore;
        const homeTeamId = game?.GlobalHomeTeamID;  
        const awayTeamId = game?.GlobalAwayTeamID;
        const homeName = teamsOrNames?.find(team => team.GlobalTeamID === homeTeamId)?.ShortDisplayName;
        const awayName = teamsOrNames?.find(team => team.GlobalTeamID === awayTeamId)?.ShortDisplayName;
        const middleSymobol = (gameStatus === 'Final' || gameStatus === 'F/OT') ? gameStatus : pageInterval === 1 ? '@' : 'vs';
        const homeDisplay = (gameStatus === 'Final' || gameStatus === 'F/OT') ? homeName + ' ' + homeScore : homeName;
        const awayDisplay = (gameStatus === 'Final' || gameStatus === 'F/OT') ? awayName + ' ' + awayScore : awayName;
        return { homeDisplay, awayDisplay, middleSymobol };
    }


    const gatherPickInfo = (gameId, pickType, usersPicks) => {
        return usersPicks?.find(pick => pick.gameId === gameId && pick.pickType === pickType);
    }

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const hasGameStarted = (dateTime) => {
        const gameDateTime = DateTime.fromISO(dateTime);
        return gameDateTime < timeNowInNewYork;
    }


    function truncateUsername(username) {
        // Determine character count based on screen width
        let charCount;
        const screenWidth = window.innerWidth;
    
        if (screenWidth < 400) {
            charCount = 14; // Small screens
        } else if (screenWidth < 500) {
            charCount = 20; // Medium screens
        } else {
            charCount = 30; // Large screens
        }
    
        // Truncate username if it exceeds charCount
        if (username?.length > charCount) {
            return username.slice(0, charCount) + '...';
        }
        return username;
    }

    const sortedData = leaderboard?.sort((a, b) => a.overallRank - b.overallRank);

    let usersInfo = leaderboard?.find(user => user.userId === myUserId);

    if (usersInfo) {
        usersInfo.dailyPicks = userPicksFromDB;
    } 

    const findLogoBorderColor = (result) => {
        if (result === 'Win') {
            return '#00AA72';
        } else if (result === 'Loss') {
            return '#CC293C';
        } else if (result === 'Push') {
            return '#5A5B5C';
        } else {
            return '#002129';
        }
    }

    const findLogoBackgroundColor = (result) => {
        if (result === 'Win') {
            return hexToRgba('#00AA72', 0.2);
        } else if (result === 'Loss') {
            return hexToRgba('#CC293C', 0.2);
        } else if (result === 'Push') {
            return hexToRgba('#5A5B5C', 0.2);
        } else {
            return 'transparent';
        }
    }

    return (
        <>
            <Grid item xs={12} sx={{overflow: 'scroll'}}>
                <TableContainer component={Paper}>
                    <Table className="super-pickem-leaderboard-table" sx={{ border: '1px solid #002129'}}>
                        <TableHead 
                            sx={{
                                background: '#002129',
                                color:'#fff'
                            }}>

                                {usersInfo ?
                                    <TableRow sx={{ background: '#F0F1F1', color: '#002129', borderTop: '1px solid #002129' }}>
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'left', 
                                                fontSize: '12px', 
                                                position: 'sticky', 
                                                borderRight: '1px solid #002129',
                                                left: 0,
                                                zIndex: 100, 
                                                backgroundColor: '#F0F1F1'// Ensure the sticky cell has a background
                                            }}
                                        >
                                            {truncateUsername(usersInfo?.username)} <br />
                                            {!hasJoinDeadlinePassed ? (
                                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                    T1 - 0 Pts
                                                </Typography>
                                            ) : amITied(usersInfo?.overallRank, 'overallRank', leaderboard) ? (
                                                <Typography sx={{ fontSize: '12px', color: '#002129', fontWeight: '400' }}>
                                                    T{usersInfo?.overallRank} - {usersInfo?.totalPoints} Pts
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ fontSize: '12px', color: '#002129', fontWeight: '400' }}>
                                                    {usersInfo?.overallRank} - {usersInfo?.totalPoints} Pts
                                                </Typography>
                                            )}
                                        </TableCell>

                                        {filteredSchedule?.length === 0 &&
                                            Array.from({ length: picksInThisInterval }).map((_, i) => (
                                                <TableCell
                                                    key={`placeholder-${i}`}
                                                    sx={{ 
                                                        textAlign: 'center', 
                                                        fontSize: '12px',
                                                        borderRight: '1px solid #002129',
                                                        padding: '0px', 
                                                    }}
                                                >
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{ fontSize: '12px', color: '#555' }} // Customize font and color
                                                        >
                                                            
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            ))
                                        }


                                        {filteredSchedule?.map((game, index) => (
                                            <TableCell
                                                key={`${game.GlobalGameID}-${index}`}
                                                sx={{ 
                                                    textAlign: 'center', 
                                                    fontSize: '12px',
                                                    borderRight: '1px solid #002129',
                                                    padding: '0px', 
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    {/* First Logo or Placeholder */}
                                                    {gatherPickInfo(game?.GlobalGameID, 'Spread', usersInfo?.dailyPicks) ? (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <img
                                                                className="super-pickem-leaderboard-champ-image"
                                                                style={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Spread', usersInfo?.dailyPicks)?.result)}`,
                                                                    backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Spread', usersInfo?.dailyPicks)?.result),
                                                                }}
                                                                src={gatherLogo(gatherPickInfo(game?.GlobalGameID, 'Spread', usersInfo?.dailyPicks)?.pick, teams)}
                                                                alt="TEAM LOGO"
                                                            />
                                                            <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                                {gatherPickInfo(game?.GlobalGameID, 'Spread', usersInfo?.dailyPicks)?.line}
                                                            </Typography>
                                                        </Box>
                                                    ) : !hasGameStarted(game?.DateTime) ? (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Box className="super-pickem-leaderboard-champ-placeholder"
                                                                sx={{ width: '32px', height: '32px' }}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Box className="super-pickem-leader-no-champ"
                                                                sx={{ width: '32px', height: '32px' }}
                                                            >
                                                                <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                            </Box>
                                                            <Typography sx={{ fontSize: '12px', color: '#CC293C', marginTop: '5px' }}>
                                                                Miss
                                                            </Typography>
                                                        </Box>
                                                    )}

                                                    {/* Second Logo or Placeholder */}
                                                    {gatherPickInfo(game?.GlobalGameID, 'Total', usersInfo?.dailyPicks) ? (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Box
                                                                style={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Total', usersInfo?.dailyPicks)?.result)}`,
                                                                    borderRadius: '4px',
                                                                    backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Total', usersInfo?.dailyPicks)?.result),
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center', 
                                                                }}
                                                            >
                                                                <Typography sx={{ fontSize: '10px', color: '#002129' }}>
                                                                    {gatherPickInfo(game?.GlobalGameID, 'Total', usersInfo?.dailyPicks)?.pick}
                                                                </Typography>
                                                            </Box>

                                                            <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                                {gatherPickInfo(game?.GlobalGameID, 'Total', usersInfo?.dailyPicks)?.line}
                                                            </Typography>
                                                        </Box>
                                                    ) : !hasGameStarted(game?.DateTime) ? (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Box className="super-pickem-leaderboard-champ-placeholder"
                                                                sx={{ width: '32px', height: '32px' }}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Box className="super-pickem-leader-no-champ"
                                                                sx={{ width: '32px', height: '32px' }}
                                                            >
                                                                <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                            </Box>
                                                            <Typography sx={{ fontSize: '12px', color: '#CC293C', marginTop: '5px' }}>
                                                                Miss
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </TableCell>
                                        ))}

                                    </TableRow>
                                    :
                                    <></>
                                }

                                <TableRow 
                                    sx={{
                                        color:'#fff'
                                    }}>
                                    <TableCell
                                        sx={{
                                            color: '#fff',
                                            fontWeight: 900,
                                            textAlign: 'center',
                                            width: isMobile ? '128px' : '200px',
                                            position: 'sticky',
                                            backgroundColor: '#002129',
                                            zIndex: 100,
                                            left: 0,
                                        }}
                                    >
                                        STANDINGS
                                    </TableCell>

                                    {filteredSchedule?.length === 0 &&
                                        Array.from({ length: picksInThisInterval }).map((_, i) => (
                                            <TableCell
                                                key={`placeholder-${i}`}
                                                sx={{ 
                                                    textAlign: 'center', 
                                                    fontSize: '12px',
                                                    borderRight: '1px solid #002129',
                                                    padding: '0px', 
                                                    minWidth: '60px',
                                                }}
                                            >
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ fontSize: '14px', fontWeight: '700', color: '#fff' }} // Customize font and color
                                                    >
                                                        TBD
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        ))
                                    }
                                    
                                    {filteredSchedule?.map((game, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                color: '#fff',
                                                textAlign: 'center',
                                                width: isMobile ? '90px' : '200px',
                                                minWidth: isMobile ? '90px' : '200px',
                                                backgroundColor: '#002129',
                                                padding: '2px',
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
                                                {gatherPoolPicksHeaderInfo(game.GlobalGameID, teamsOrNames, schedule, pageInterval).homeDisplay}
                                            </Typography>
                                            <Typography sx={{ fontSize: '10px' }}>
                                                {gatherPoolPicksHeaderInfo(game.GlobalGameID, teamsOrNames, schedule, pageInterval).middleSymobol}
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
                                                {gatherPoolPicksHeaderInfo(game.GlobalGameID, teamsOrNames, schedule, pageInterval).awayDisplay}
                                            </Typography>
                                        </TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {sortedData?.map((row, index) => (
                                <TableRow
                                    key={`${row.userId}-${index}`}
                                    sx={{
                                        backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                    }}
                                >
                                    <TableCell 
                                        sx={{
                                            textAlign:'left', 
                                            fontSize:'12px',
                                            borderRight: '1px solid #002129',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 100,
                                            backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                        {truncateUsername(row?.username)} <br />
                                        {!hasJoinDeadlinePassed ? (
                                            "T1 - 0 Pts"
                                        ) : amITied(row?.overallRank, 'overallRank', leaderboard) ? (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                                T{row?.overallRank} - {row?.totalPoints} Pts
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                                {row?.overallRank} - {row?.totalPoints} Pts
                                            </Typography>
                                        )}
                                    </TableCell>

                                    {filteredSchedule?.length === 0 &&
                                            Array.from({ length: picksInThisInterval }).map((_, i) => (
                                                <TableCell
                                                    key={`placeholder-${i}`}
                                                    sx={{ 
                                                        textAlign: 'center', 
                                                        fontSize: '12px',
                                                        borderRight: '1px solid #002129',
                                                        padding: '0px', 
                                                    }}
                                                >
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{ fontSize: '12px', color: '#555' }} // Customize font and color
                                                        >
                                                            
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            ))
                                        }
                                    
                                    {filteredSchedule?.map((game, index) => (
                                        <TableCell
                                            key={`${game.GlobalGameID}-${index}-${row.userId}`} 
                                            sx={{ 
                                                textAlign: 'center', 
                                                fontSize: '12px', 
                                                borderRight: '1px solid #002129',
                                                padding: '0px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                }}
                                            >
                                                {/* First Logo or Placeholder */}
                                                {(row?.userId === myUserId && gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <img
                                                            className="super-pickem-leaderboard-champ-image"
                                                            style={{
                                                                width: '32px',
                                                                height: '32px',
                                                                border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.result)}`,
                                                                backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.result),
                                                            }}
                                                            src={gatherLogo(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.pick, teams)}
                                                            alt="TEAM LOGO"
                                                        />
                                                        <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                            {gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.line}
                                                        </Typography>
                                                    </Box>
                                                ) : !hasGameStarted(game?.DateTime) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box className="super-pickem-leaderboard-champ-placeholder"
                                                            sx={{ width: '32px', height: '32px' }}
                                                        />
                                                    </Box>
                                                ) : (gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <img
                                                            className="super-pickem-leaderboard-champ-image"
                                                            style={{
                                                                width: '32px',
                                                                height: '32px',
                                                                border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.result)}`,
                                                                backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.result),
                                                            }}
                                                            src={gatherLogo(gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.pick, teams)}
                                                            alt="TEAM LOGO"
                                                        />
                                                        <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                            {gatherPickInfo(game?.GlobalGameID, 'Spread', row?.dailyPicks)?.line}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box className="super-pickem-leader-no-champ"
                                                            sx={{ width: '32px', height: '32px' }}
                                                        >
                                                            <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                        </Box>
                                                        <Typography sx={{ fontSize: '12px', color: '#CC293C', marginTop: '5px' }}>
                                                            Miss
                                                        </Typography>
                                                    </Box>
                                                )}

                                                {/* Second Logo or Placeholder */}
                                                {(row?.userId === myUserId && gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box
                                                            style={{
                                                                width: '32px',
                                                                height: '32px',
                                                                border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.result)}`,
                                                                borderRadius: '4px',
                                                                backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.result),
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center', 
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', color: '#002129' }}>
                                                                {gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.pick}
                                                            </Typography>
                                                        </Box>

                                                        <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                            {gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.line}
                                                        </Typography>
                                                    </Box>
                                                ) : !hasGameStarted(game?.DateTime) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box className="super-pickem-leaderboard-champ-placeholder"
                                                            sx={{ width: '32px', height: '32px' }}
                                                        />
                                                    </Box>
                                                ) : (gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)) ? (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box
                                                            style={{
                                                                width: '32px',
                                                                height: '32px',
                                                                border: `1px solid ${findLogoBorderColor(gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.result)}`,
                                                                borderRadius: '4px',
                                                                backgroundColor: findLogoBackgroundColor(gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.result),
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center', 
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '10px', color: '#002129' }}>
                                                                {gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.pick}
                                                            </Typography>
                                                        </Box>

                                                        <Typography sx={{ fontSize: '12px', color: '#002129', marginTop: '5px' }}>
                                                            {gatherPickInfo(game?.GlobalGameID, 'Total', row?.dailyPicks)?.line}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Box className="super-pickem-leader-no-champ"
                                                            sx={{ width: '32px', height: '32px' }}
                                                        >
                                                            <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                        </Box>
                                                        <Typography sx={{ fontSize: '12px', color: '#CC293C', marginTop: '5px' }}>
                                                            Miss
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </TableCell>
                                    ))}
                                    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
}

export default PlayerPicks;