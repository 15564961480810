// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-a-pool-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  max-width: 384px;
  min-width: 311px;
  height: 162px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
}

.create-a-pool-card-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #002129;
  text-align: left;
  margin-bottom: 12px !important;
}

.create-a-pool-card-text-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.create-a-pool-card-text-content {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #002129 !important;
  text-align: left;
}

.create-a-pool-card-button-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 20px !important;
}

.create-a-pool-card-button {
  display: flex;
  align-items: center;
  border: 1px solid #373737 !important;
  border-radius: 4px;
  height: 23px;
  width: auto;
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: nowrap;
  gap: 6px;
  padding: 4px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateAPool/CreateAPoolCards/CreateAPoolCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,2BAAA;EACA,cAAA;EACA,gBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,2BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,0BAAA;EACA,2BAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;AACJ","sourcesContent":[".create-a-pool-card-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 95%;\n    max-width: 384px;\n    min-width: 311px;\n    height: 162px;\n    padding: 16px;\n    background-color: #fff;\n    border-radius: 8px;\n    border: 1px solid #C1C1C1;\n}\n\n.create-a-pool-card-title {\n    font-size: 16px !important;\n    font-weight: 700 !important;\n    color: #002129;\n    text-align: left;\n    margin-bottom: 12px !important;\n}\n\n.create-a-pool-card-text-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n}\n\n.create-a-pool-card-text-content {\n    font-size: 12px !important;\n    font-weight: 400 !important;\n    color: #002129 !important;\n    text-align: left;\n}\n\n.create-a-pool-card-button-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    margin-top: 20px !important;\n}\n\n.create-a-pool-card-button {\n    display: flex;\n    align-items: center;\n    border: 1px solid #373737 !important;\n    border-radius: 4px;\n    height: 23px;\n    width: auto;\n    font-size: 12px !important;\n    font-weight: 700 !important;\n    white-space: nowrap;\n    gap: 6px;\n    padding: 4px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
