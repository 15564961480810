import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

const CFBSurvivorSubmittedPickModal = ({ submitModalOpen, setSubmitModalOpen, week, submitError, submittedTeamName, submittedLogo, restOfDisplay, userPicksFromDB, doesUserHaveACCLoss, lossWeekACC, doesUserHaveBig12Loss, lossWeekBig12, doesUserHaveBigTenLoss, lossWeekBigTen, doesUserHaveSECLoss, lossWeekSEC, teamFilter, }) => {
      
    let previousACCLoss = false, previousBigTenLoss = false, previousBig12Loss = false, previousSECLoss = false;
    if (doesUserHaveACCLoss && lossWeekACC < week) {
        previousACCLoss = true;
    }
    if (doesUserHaveBigTenLoss && lossWeekBigTen < week) {
        previousBigTenLoss = true;
    }
    if (doesUserHaveBig12Loss && lossWeekBig12 < week) {
        previousBig12Loss = true;
    }
    if (doesUserHaveSECLoss && lossWeekSEC < week) {
        previousSECLoss = true;
    }

    const currentACCPick = userPicksFromDB?.find((pick) => pick.week === week && pick.conference === 'ACC');

    const currentBigTenPick = userPicksFromDB?.find((pick) => pick.week === week && pick.conference === 'Big Ten');

    const currentBig12Pick = userPicksFromDB?.find((pick) => pick.week === week && pick.conference === 'Big 12');

    const currentSECPick = userPicksFromDB?.find((pick) => pick.week === week && pick.conference === 'SEC');
    //console.log("Weekly Picks", currentACCPick, currentBigTenPick, currentBig12Pick, currentSECPick);

    const totalLosses = [doesUserHaveACCLoss, doesUserHaveBigTenLoss, doesUserHaveBig12Loss, doesUserHaveSECLoss];
    const howManyLossesCount = totalLosses?.filter((loss) => loss === true).length;

    const lossWeeks = [lossWeekACC, lossWeekBigTen, lossWeekBig12, lossWeekSEC];
    const howManyLossesThisWeek = lossWeeks?.filter((lossWeek) => lossWeek === week).length;

    //console.log("HOW MANY LOSSES THIS WEEK:", howManyLossesThisWeek, lossWeeks, week);
    //console.log("HOW MANY LOSSES:", howManyLossesCount);


   // Calculate totalPicksThisWeek based on current picks
    const picksThisWeek = [currentACCPick, currentBigTenPick, currentBig12Pick, currentSECPick];
    let totalPicksThisWeek = picksThisWeek?.filter(pick => pick !== null && pick !== undefined).length;
    if (teamFilter === 'ACC' && currentACCPick === undefined) {
        totalPicksThisWeek++;
    } else if (teamFilter === 'Big Ten' && currentBigTenPick === undefined) {
        totalPicksThisWeek++;
    } else if (teamFilter === 'Big 12' && currentBig12Pick === undefined) {
        totalPicksThisWeek++;
    } else if (teamFilter === 'SEC' && currentSECPick === undefined) {
        totalPicksThisWeek++;
    }
    //console.log("TOTAL PICKS THIS WEEK:", picksThisWeek, totalPicksThisWeek);


    const picksAvailableToBeMade = 4 - howManyLossesCount + howManyLossesThisWeek;

    const submittedColor = '#00AA72';
    const eliminatedColor = '#CC293C';

    
    return (
        <Modal
            open={submitModalOpen && !submitError}
            onClose={() => setSubmitModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
            // Prevent closing when clicking outside
            onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    borderRadius: '8px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '328px',
                    height: '394px',
                    bgcolor: '#FFF',
                    padding: '16px',
                    outline: 'none', // Remove default focus behavior
                }}
            >
                <Typography 
                    id="modal-modal-title" 
                    variant="h6" 
                    component="h2"
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        paddingBottom: '20px',
                    }}    
                >
                    {teamFilter} Week {week} Pick Submitted
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        whiteSpace: 'nowrap',
                    }}>
                    {submittedTeamName} <br/><span style={{ fontWeight: '400'}}> ({restOfDisplay}) </span>
                </Typography>
                <img src={submittedLogo} alt="Team Logo" style={{ width: '48px', height: 'auto', margin: '20px auto', display: 'block' }} />
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginBottom: '20px'
                    }}
                >
                    Week {week} Status<br/> 
                    <span style={{ 
                        color: totalPicksThisWeek === picksAvailableToBeMade ? submittedColor : eliminatedColor,
                        fontWeight: '700'
                    }}>
                        {totalPicksThisWeek} / {picksAvailableToBeMade}
                    </span> Picks Submitted
                </Typography>

                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: previousACCLoss ? eliminatedColor : (currentACCPick || teamFilter === 'ACC') ? submittedColor : '#002129',
                        fontSize: '12px',
                        fontWeight: '700',
                    }}>
                    ACC: {teamFilter === 'ACC' ? 'Submitted' : previousACCLoss ? 'Eliminated' : (currentACCPick || teamFilter === 'ACC') ? 'Submitted' : 'Not Submitted'}
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: previousBig12Loss ? eliminatedColor : (currentBig12Pick || teamFilter === 'Big 12') ? submittedColor : '#002129',
                        fontSize: '12px',
                        fontWeight: '700',
                    }}>
                    Big 12: {teamFilter === 'Big 12' ? 'Submitted' : previousBig12Loss ? 'Eliminated' : (currentBig12Pick || teamFilter === 'Big 12') ? 'Submitted' : 'Not Submitted'}
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: previousBigTenLoss ? eliminatedColor : (currentBigTenPick || teamFilter === 'Big Ten') ? submittedColor : '#002129',
                        fontSize: '12px',
                        fontWeight: '700',
                    }}>
                    Big Ten: {teamFilter === 'Big Ten' ? 'Submitted' : previousBigTenLoss ? 'Eliminated' : (currentBigTenPick || teamFilter === 'Big Ten') ? 'Submitted' : 'Not Submitted'}
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: previousSECLoss ? eliminatedColor : (currentSECPick || teamFilter === 'SEC') ? submittedColor : '#002129',
                        fontSize: '12px',
                        fontWeight: '700',
                    }}>
                    SEC: {teamFilter === 'SEC' ? 'Submitted' : previousSECLoss ? 'Eliminated' : (currentSECPick || teamFilter === 'SEC') ? 'Submitted' : 'Not Submitted'}
                </Typography>
                <Button 
                    variant='contained'
                    onClick={() => {
                        setSubmitModalOpen(false);
                        window.location.reload(); // This will reload the page
                    }}
                    sx={{
                        width: '115px',
                        height: '38px',
                        marginTop: '20px', // Add top margin
                        display: 'block',
                        marginLeft: 'auto', // Center horizontally within a flex container
                        marginRight: 'auto', // Center horizontally within a flex container
                    }}
                >
                    OK
                </Button>
            </Box>
        </Modal>
    );
}

export default CFBSurvivorSubmittedPickModal;