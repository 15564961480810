import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { DateTime } from 'luxon';
import './SuperPickemTwoPickCard.scss';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatDateTime, handlePick, buttonState } from '../utils/sharedUtils';

const SuperPickemTwoPickCard = ({ 
    game, 
    pick, 
    setPick, 
    pickType, 
    gameDescription, 
    roundName, 
    teams, 
    stadiums, 
    names,
    teamsOrNames, 
    seeds, 
    picking, 
    pickState, 
    setPickState, 
    userPicksFromDB, 
    unsavedPicks, 
    setUnsavedPicks, 
    interval,
    gatherStadiumInfo,
    gatherLogo,
    gatherDisplayInfo,
    gatherScores,  
    gatherLineDisplay,
    gatherPickEmCardTopLeftInfo,
}) => {
    //console.log("Game in Two Pick Card:", game);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isPickSaved = (pickType, gameId) => {
        return userPicksFromDB?.some(pick => pick.pickType === pickType && pick.gameId === gameId);
    }

    const topLeftInfo = gatherPickEmCardTopLeftInfo(gameDescription, roundName, game);

    const gameId = game?.GlobalGameID;
    const awayTeamId = game?.GlobalAwayTeamID;
    const homeTeamId = game?.GlobalHomeTeamID;

    const picksEntered = pickState?.filter(pick => pick.gameId === gameId)?.length + userPicksFromDB?.filter(pick => pick.gameId === gameId)?.length;
    //console.log("Picks Entered:", picksEntered);
   
    const todayInNewYork = DateTime.now().setZone('America/New_York');
    const gameDateTime = DateTime.fromISO(game?.DateTime).setZone('America/New_York');
    //const hasGameStarted = true;
    const hasGameStarted = gameDateTime < todayInNewYork;
    //console.log("Has Game Started:", hasGameStarted);

    const gameStatus = game?.Status;
    //console.log("Game Status:", gameStatus);
    const isGameOver = gameStatus === 'Final' || gameStatus === 'F/OT';
    //const isGameOver = true;

    const stadiumId = game?.StadiumID;

    const stadiumLocation = gatherStadiumInfo(stadiumId, stadiums);

    const awayLogo = gatherLogo(awayTeamId, teams);
    const homeLogo = gatherLogo(homeTeamId, teams);

    const awayDisplay = gatherDisplayInfo(awayTeamId, teams, seeds, isGameOver);
    const homeDisplay = gatherDisplayInfo(homeTeamId, teams, seeds, isGameOver);

    const scoreInfo = gatherScores(game);
    //console.log("Score Info:", scoreInfo);
    const { awayScore, homeScore } = scoreInfo;
    
    const gameTime = formatDateTime(game?.DateTime);
    const channel = game?.Channel;

    const lockedInHomeSpread = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Spread' && pick.pick === homeTeamId)?.line;
    const lockedInAwaySpread = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Spread' && pick.pick === awayTeamId)?.line;
    const lockedInTotalOver = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Total' && pick.pick === 'Over')?.line;
    const lockedInTotalUnder = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === 'Total' && pick.pick === 'Under')?.line;
    //console.log("Locked In Home Spread:", lockedInHomeSpread, "Locked In Away Spread:", lockedInAwaySpread, "Locked In Total Over:", lockedInTotalOver, "Locked In Total Under:", lockedInTotalUnder);

    const homeSpread = hasGameStarted ? game?.PointSpread : game?.homeSpread !== undefined ? game?.homeSpread : "Off";
    const awaySpread = homeSpread !== "Off" ? homeSpread * -1 : "Off";
    const total = hasGameStarted ? game?.OverUnder : game?.total !== undefined ? game?.total : "Off";

    const lineDisplay = gatherLineDisplay(homeTeamId, awayTeamId, lockedInHomeSpread, lockedInAwaySpread, lockedInTotalOver, lockedInTotalUnder, hasGameStarted, homeSpread, awaySpread, total, teamsOrNames);
    //console.log("Line Display:", lineDisplay);

    const myWins = userPicksFromDB?.filter(pick => pick.gameId === gameId && pick.result === 'Win')?.length || 0;
    const myPushes = userPicksFromDB?.filter(pick => pick.gameId === gameId && pick.result === 'Push')?.length || 0;
    const myPoints = myWins + (myPushes * 0.5);

    const winningTeam = !isGameOver ? null : (homeScore + homeSpread) > awayScore ? homeTeamId : awayTeamId;
    const winningTotal = !isGameOver ? null : (homeScore + awayScore) > total ? 'Over' : 'Under';
    //const winningTeam = homeTeamId;
    //const winningTotal = 'Over';

    // const awayScore = 50;
    // const homeScore = 10;

    return (
        <Box className="super-pickem-traditional-card-container">
            <Box sx={{ width: '100%', backgroundColor: '#002129' }}>
                    <Box sx={{ width: '100%', backgroundColor: '#002129', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '25px', borderBottom: '2px solid #FFC60A' }}>
                        <Typography 
                            className="super-pickem-traditional-header-date"
                            sx={{ textAlign: 'left', marginLeft: '10px' }}
                        >
                            {topLeftInfo}
                        </Typography>
                        <Typography
                            className="super-pickem-traditional-header-date"
                            sx={{ textAlign: 'right', marginRight: '10px' }}
                        >
                            {channel}
                        </Typography>
                     </Box>
                </Box>

                <Box sx={{ display: 'flex', width: '100%', height: '25px', backgroundColor: '#002129', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'left', marginLeft: '10px', width: '60%' }}>
                    <Typography className="super-pickem-traditional-header-date">
                        {gameTime}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', width: '40%', alignItems: 'flex-end' }}>
                    {picking?.map((style, index) => (
                    <Typography
                        key={index}
                        className="super-pickem-traditional-header-bet-types"
                        sx={{ width: '20%' }} // Ensures each style takes up 23% of the total width
                    >
                        {style}
                    </Typography>
                    ))}
                </Box>
            </Box>




            {/* Second Row */}
            <Grid container sx={{ width: '100%', height: '92px' }}>
                {/* Teams Section */}
                <Grid 
                    item 
                    className='super-pickem-traditional-teams-container'
                    
                >
                    {/* Away Team Box */}
                    <Box className="super-pickem-traditional-teams">
                        <Box 
                            component="img"
                            src={awayLogo}
                            alt="Logo"
                            className="super-pickem-traditional-team-logo"
                        />
                            <Typography
                                className="super-pickem-traditional-team-name"
                                sx={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'left'
                                }}
                            >
                                {awayDisplay}
                            </Typography>
                            {awayScore && homeScore && isGameOver && (
                                <Typography className="super-pickem-traditional-team-score">
                                    {awayScore}
                                </Typography>
                            )}
                    </Box>

                    {/* Divider with @ symbol */}
                    <Box className="super-pickem-traditional-at-symbol-container">
                        <Typography className="super-pickem-traditional-at-symbol">
                            {game?.NeutralVenue ? 'vs' : '@'}
                        </Typography>
                        <Box 
                            sx={{
                                flexGrow: 1,
                                borderTop: '1px solid black'
                            }}
                        />
                    </Box>

                    {/* Home Team Box */}
                    <Box className="super-pickem-traditional-teams">
                        <Box 
                            component="img"
                            src={homeLogo}
                            alt="Logo"
                            className="super-pickem-traditional-team-logo"
                        />
                        <Typography className="super-pickem-traditional-team-name">
                            {homeDisplay}
                        </Typography>
                        {awayScore && homeScore && isGameOver && (
                            <Typography className="super-pickem-traditional-team-score">
                                {homeScore}
                            </Typography>
                        )}
                    </Box>
                </Grid>

                {/* Betting Buttons Section */}
                <Grid 
                    item 
                    className='super-pickem-traditional-bet-buttons-container'
                >
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingRight: '8px' }}>
                        <Grid item xs={6}>
                            <Box className="super-pickem-traditional-button-pair">
                                    <Button 
                                        className={`super-pickem-traditional-bet-button ${buttonState("Spread", awayTeamId, hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}
                                        onClick={() => awaySpread && handlePick("Spread", awayTeamId, gameId, interval, setPickState, setUnsavedPicks, pickState, awaySpread)}
                                        disabled={!awaySpread || awaySpread === "Off" || hasGameStarted || isPickSaved("Spread", gameId)}
                                    >
                                        <span className={`super-pickem-traditional-bet-button-text1 ${buttonState("Spread", awayTeamId, hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}>
                                            {lockedInAwaySpread ?
                                                (lockedInAwaySpread > 0 ? '+' + lockedInAwaySpread : lockedInAwaySpread === 0 ? 'Pk' : lockedInAwaySpread) 
                                                : ((awaySpread && awaySpread !== "Off") || lockedInHomeSpread) ? (awaySpread > 0 ? '+' + awaySpread : awaySpread === 0 ? 'Pk' : awaySpread)
                                                : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                            }
                                        </span>
                                    </Button>
                                    <Button 
                                        className={`super-pickem-traditional-bet-button ${buttonState("Spread", homeTeamId, hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}
                                        onClick={() => homeSpread && handlePick("Spread", homeTeamId, gameId, interval, setPickState, setUnsavedPicks, pickState, homeSpread)}
                                        disabled={!homeSpread || homeSpread === "Off" || hasGameStarted || isPickSaved("Spread", gameId)}
                                    >
                                        <span className={`super-pickem-traditional-bet-button-text1 ${buttonState("Spread", homeTeamId, hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}>
                                            {lockedInHomeSpread ?
                                                (lockedInHomeSpread > 0 ? '+' + lockedInHomeSpread : lockedInHomeSpread === 0 ? 'Pk' : lockedInHomeSpread)
                                                : ((homeSpread && homeSpread !== "Off") || lockedInAwaySpread) ? (homeSpread > 0 ? '+' + homeSpread : homeSpread === 0 ? 'Pk' : homeSpread)
                                                : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                            }
                                        </span>
                                    </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className="super-pickem-traditional-button-pair">
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${buttonState("Total", "Over", hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}
                                    onClick={() => total && handlePick("Total", "Over", gameId, interval, setPickState, setUnsavedPicks, pickState, total)}
                                    disabled={!total || total === "Off" || hasGameStarted || isPickSaved("Total", gameId)}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${buttonState("Total", "Over", hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}>
                                        {lockedInTotalOver ?
                                            `O ${lockedInTotalOver}` 
                                            : ((total && total !== "Off") || lockedInTotalUnder) ? `O ${total}` 
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${buttonState("Total", "Under", hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}
                                    onClick={() => total && handlePick("Total", "Under", gameId, interval, setPickState, setUnsavedPicks, pickState, total)}
                                    disabled={!total || total === "Off" || hasGameStarted || isPickSaved("Total", gameId)}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${buttonState("Total", "Under", hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB)}`}>
                                        {lockedInTotalUnder ?
                                            `U ${lockedInTotalUnder}` 
                                            : ((total && total !== "Off") || lockedInTotalOver) ? `U ${total}` 
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%', backgroundColor: '#002129', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0px' }}>
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'left', marginLeft: '10px' }}
                >
                    {stadiumLocation}
                </Typography>
                {lineDisplay !== '' && (
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'right', marginRight: '10px' }}
                >
                    {hasGameStarted ? `Close: ` : `Live: `}{lineDisplay}
                </Typography>
                )}
                
            </Box>

            <Box sx={{ width: '100%', backgroundColor: '#fff', alignItems: 'center', padding: '5px 0px' }}>
                {!isGameOver && (
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'center', color: picksEntered !== 2 ? '#CC293C' : '#00AA72' }}
                >
                    Game Picks: {picksEntered}/2 {picksEntered === 2 ? <CheckIcon sx={{ color: '#00AA72', fontSize: isMobile ? '16px' : '22px' }} /> : <CloseIcon sx={{ color: '#CC293C', fontSize: isMobile ? '16px' : '22px'  }} />}
                </Typography>
                )}
                {isGameOver && (
                    <Typography
                        className="super-pickem-traditional-header-date"
                        sx={{ textAlign: 'center', color: '#002129' }}
                    >
                        My Points: {myPoints}
                    </Typography>
                )}
            </Box>

            
        </Box>
    )
}

export default SuperPickemTwoPickCard;