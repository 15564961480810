import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { useNFLPickEmContext} from "../context/NFLPickEmContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const NFLPickEmRules = () => {
    const { gameData } = useNFLPickEmContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const gameLogo = gameData?.clubLogo;

    const [rulesPage, setRulesPage] = useState('How To Play');

    return (
        <Box className="rules-container">
            <img 
                src={gameLogo} 
                alt="game logo" 
                className="rules-logo" 
                style={{ 
                    width: '128px', 
                    height: '160px', 
                    objectFit: 'cover',
                    marginTop: '32px',
                    marginBottom: '32px', 
                }} 
            />

            <Typography 
                className="rules-header"
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: isMobile ? '24px' : '32px',
                    fontWeight: 700,
                    marginBottom: '32px',
                }}
            >
                Pool Rules
            </Typography>

            <div style={{display:'flex', justifyContent:'space-evenly', minWidth: isMobile ? '325px' : '750px', margin:'15px auto 30px auto'}}>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'How To Play' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('How To Play')}
              >
                How To Play
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'Competition' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('Competition')}
              >
                Competitions
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'Other Rules' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('Other Rules')}
              >
                Other Rules
              </span>
            </div>
            
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '83%',
                    minWidth: '311px',
                    maxWidth: '800px',
                }}
            >
                {rulesPage === 'How To Play' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            How To Play 
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            Each week during the NFL regular season, pool 
                            members will select five (5) NFL teams against 
                            the spread (ATS) from the full weekly NFL schedule. 
                            The objective is to accumulate the most points from 
                            your ATS picks at the end of week 18.

                        </Typography>
                        
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Live Lines 
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                The point spreads for each matchup are dynamic, meaning they can change at any time leading up to kickoff. 
                                Factors like injuries, breaking news, and overall betting trends can cause spreads to shift.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Multiple pool members may select the same team but with different point spreads, potentially leading to different outcomes.
                            </span>
                            <span style={{ display: 'block', }}>
                                *All point spreads are provided by SportsDataIO
                            </span>
                        </Typography>

                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Making Picks
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span style={{ fontWeight: '700' }}>
                                Pick Timing
                            </span>
                            <span style={{ display: 'block' }}>
                                Picks are available for selection for the current NFL week and for one 
                                week in advance. This will allow pool members the option to strategically 
                                lock in picks up to one week in advance.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Picks can only be made when a point spread for a game is available. At 
                                times the point spread will be off the board and unavailable. 
                            </span>
                            <span style={{ fontWeight: '700' }}>
                                Number of Picks
                            </span>
                            <span style={{ display: 'block' }}>
                                Pool members will select five (5) teams ATS each week. 
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Once a pick is made, the pick is final and cannot be changed or removed.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Picks can be made five at a time, one by one, or any combination in between.
                            </span>
                            <span style={{ fontWeight: '700' }}>
                                Deadline
                            </span>
                            <span style={{ display: 'block', }}>
                            Picks can be made up until the scheduled game time as indicated on the matchup card.
                            </span>
                        </Typography>

                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Scoring
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: isMobile ? 'center' : 'center',
                            }}
                        >
                            ATS Win: 1 Point<br/>
                            ATS Push: ½ Point<br/>
                            ATS Loss: 0 Points<br/>
                            No Pick: 0 Points
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Cancellations / Postponement
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                            In the event a pick is locked and that game is cancelled or 
                            postponed to a future week, pool members will receive an ATS 
                            Push designation for their pick resulting in 1/2 point. 
                            </span>
                        </Typography>
                    </>
                )}
                {rulesPage === 'Competition' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Competitions 
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center' }}>
                                NFL Pick 'Em - Fav 5
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>
                                Championship Competition
                            </span>
                            <span style={{ display: 'block',  textAlign: 'center' }}>
                                1st Place - $250
                            </span>
                            <span style={{ display: 'block', textAlign: 'center' }}>
                                2nd Place - $100
                            </span>
                            <span style={{ display: 'block', textAlign: 'center' }}>
                                3rd Place - $50
                            </span>
                            <span style={{ display: 'block', margin: '16px 0', textAlign: 'center' }}>
                            The Championship Competition is the main event where the pool member with
                            the most points at the end of week 18 will be crowned the NFL Pick ‘Em - Fav 5 
                            champion. In the event of a tie, the below tiebreaker will be used. 
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center' }}>
                                The Last Place Race
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>
                                Secondary Competition
                            </span>
                            <span style={{ display: 'block',  textAlign: 'center' }}>
                                1st Place - $100
                            </span>
                            <span style={{ display: 'block', margin: '16px 0', textAlign: 'center' }}>
                                The Last Place Race is a fun, unique competition designed to keep 
                                even those near the bottom of the leaderboard engaged come season’s end.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0', textAlign: 'center' }}>
                                The winner will be the individual with the least points at the end of week 18. 
                                In the event of a tie, the below tiebreaker will be used.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0', textAlign: 'center' }}>
                                ***In order to qualify for The Last Place Race, pool members must make 5 pick in
                                each of the 18 weeks. A single missed pick results in disqualification.
                            </span>
                            
                        </Typography>
                        
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Tiebreakers
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center' }}>
                                NFL Pick 'Em - Fav 5
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>
                                Championship Competition
                            </span>
                            <span>
                                <span style={{ fontWeight: 700 }}>Best Win Margin Against the Spread: </span>
                                The tiebreaker will be determined by the total yearly win margin against the spread of the teams 
                                picked by each pool member throughout the season.
                            </span>

                            <span style={{ display: 'block', margin: '16px 0' }}>
                                The win margin against the spread is calculated by summing the extent to which each selected 
                                team either covers or fails to cover the spread.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                For example, if a player picks Team A, which covers the spread by 7 points, they add 7 to their 
                                total win margin. If the same player then picks Team B, which fails to cover the spread by 3 points, 
                                they subtract 3 from their total, resulting in a net win margin of 4 for those two games.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                The pool member with the highest yearly win margin will win the tiebreaker.
                            </span>

                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center' }}>
                                The Last Place Race
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center', marginBottom: '16px' }}>
                                Secondary Competition
                            </span>
                            <span>
                                <span style={{ fontWeight: 700 }}>Worst Win Margin Against the Spread: </span>
                                The pool member with the lowest yearly win margin will win the tiebreaker.
                            </span>
                        
                        </Typography>
                    </>
                )}

                {rulesPage === 'Other Rules' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Pool Entry
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                Participation in the pool is free. Participants must be 13 years of age or older.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Each registered account can create up to one (1) entry into Pool Party’s NFL Pick ‘Em - Fav 5 Pool.
                            </span>
                        </Typography>

                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Pool Duration
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                The pool will run from Week 1 (starting September 5th, 2024) 
                                through the end of Week 18 (ending January 5th, 2025) of the NFL regular season.
                            </span>
                        </Typography>
                    </>
                )}

                <Typography
                    style={{
                        fontSize: isMobile ? '16px' : '24px',
                        marginBottom: '16px',
                        fontWeight: 700,
                        textDecoration: 'underline',
                        textAlign: 'center',
                    }}
                >
                    Rule Clarifications & Final Interpretations
                </Typography>
                <Typography
                    style={{
                        fontSize: isMobile ? '12px' : '16px',
                        marginBottom: '32px',
                        fontWeight: 500,
                        textAlign: 'left',
                    }}
                >
                    In the event of unforeseen circumstances, rule ambiguities, 
                    or situations not explicitly covered within the above established 
                    guidelines, the Pool Party team reserves the right to make final interpretations and rulings.
                </Typography>
            </Box>
        </Box>
    );
}

export default NFLPickEmRules;