import React from "react";
import { Button, Box } from "@mui/material";
import '../BetReceipt.scss';

const BetReceiptDoneButton = () => {

    const handleReload = () => {
        window.location.href = window.location.href;
    };

    return (
        <Box className="sportsbook-bet-receipt-done-button-container">
            <Button 
                variant="contained" 
                color="primary" 
                className="sportsbook-bet-receipt-done-button"
                onClick={handleReload}
            >
                DONE
            </Button>
        </Box>
    );
}

export default BetReceiptDoneButton;
