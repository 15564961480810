import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useUpdatePool } from '../../hooks/pools/useUpdatePool';
import { useNFLPickEmContext } from './context/NFLPickEmContext';


const PickEmTiebreakerModal = ({ tiebreakerOpen, setTiebreakerOpen }) => {
    const { myUserId, gameId, tiebreaker } = useNFLPickEmContext();
    //console.log("Tiebreaker: ", tiebreaker);

    const [tiebreakerValue, setTiebreakerValue] = useState('');
    const [tiebreakerError, setTiebreakerError] = useState(false);

    useEffect(() => {
        if (tiebreaker) {
            setTiebreakerValue(tiebreaker);
        }
    }, [tiebreaker]);

    const { mutate } = useUpdatePool();

    // Specified future date and time in New York
    const january5th = DateTime.fromObject(
        { year: 2025, month: 1, day: 5, hour: 20, minute: 20 },
        { zone: 'America/New_York' }
        );
      
    // Current date and time in New York
    const timeInNyNow = DateTime.now().setZone('America/New_York');
    
    // Comparing the two DateTime objects
    const hasDeadlinePassed = january5th < timeInNyNow;

    //console.log("Has Deadline Passed: ", hasDeadlinePassed, "Jan 5th: ", january5th, "Time in NY Now: ", timeInNyNow);

    const handleTiebreakerSave = async () => {
        if (hasDeadlinePassed) {
            setTiebreakerError('The tiebreaker deadline has passed.');
            return;
        }
    
        let picksData = {
            updateKey: "tiebreaker",
            updateValue: tiebreakerValue,
            userId: myUserId,
            gameId: gameId
        };
    
        try {
            await mutate(picksData, {
                onSuccess: () => {
                    window.location.reload();
                },
                onError: (error) => {
                    console.error(error);
                    setTiebreakerError('There was an error saving your tiebreaker. Please try again.');
                }
            });
        } catch (error) {
            console.error(error);
            setTiebreakerError('There was an error saving your tiebreaker. Please try again.');
        }
    };
    
        
    const handleChange = (event) => {
        const { value } = event.target;
        // Allow only digits and ensure the length is between 1 and 3
        if (/^\d*$/.test(value) && value.length <= 3) {
            setTiebreakerValue(value);
        }
    };
    
    
    
    
    return (
        <Modal
            open={tiebreakerOpen}
            onClose={() => {}}
            aria-labelledby="tiebreaker-modal"
            aria-describedby="tiebreaker-modal-description"
        >
            <Box className="tiebreaker-modal" sx={{ p: 3 }}>
                <Typography style={{ textDecoration: 'underline', fontSize: '20px', fontWeight: '700' }} variant="h5" id="tiebreaker-modal">
                    Pool Tiebreaker
                </Typography>
                <Typography sx={{ textAlign: 'center', marginBottom: '15px', fontSize: '16px', fontWeight: '500' }}>
                    Total points scored in <br /> the Week 18 SNF game.
                </Typography>
                <Typography sx={{ textAlign: 'center', marginBottom: '15px', fontSize: '16px', fontWeight: '500', whiteSpace: 'nowrap' }}>
                    WAS Commanders @ PIT Steelers
                </Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                    Enter Total Points Prediction:
                </Typography>
                <TextField
                    id="tiebreaker-input"
                    label="Tiebreaker"
                    variant="outlined"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={tiebreakerValue}
                    onChange={handleChange}
                    autoComplete='off'
                    sx={{ marginBottom: '10px', marginTop: '10px' }} // Add some space above and below the input
                />
                <Typography style={{ fontSize: '12px', marginBottom: '10px' }} variant="body1" id="tiebreaker-modal-description">
                    (Tiebreaker must be 3 digits or less)
                </Typography>
                <Typography style={{ fontSize: '20px', fontWeight: '700' }} variant="h5" id="tiebreaker-modal-description">
                    Tiebreaker Rules:
                </Typography>
                <Box sx={{ textAlign: 'left' }}>
                <Typography style={{ fontSize: '10px' }}>
                    1. Tiebreaker locks at the SNF kickoff - Sunday
                </Typography>
                <Typography style={{ fontSize: '10px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                    January 5th @ 8:20pm ET.
                </Typography>
                <Typography style={{ fontSize: '10px' }}>
                    2. Closest to the actual total points scored in the
                </Typography>
                <Typography style={{ fontSize: '10px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                     SNF game wins.
                </Typography>
                <Typography style={{ fontSize: '10px' }} variant="body1" id="tiebreaker-modal-description">
                    3. If multiple players are still tied following Rule 2,
                </Typography>
                <Typography style={{ fontSize: '10px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                the tiebreaker will go as follows:<br />
                    a. Most Wins <br />
                    b. Least Missed Picks
                </Typography>
                </Box>

                {tiebreakerError && (
                    <Typography color="error" variant="body2" sx={{ marginTop: '10px', textAlign: 'center' }}>
                        {tiebreakerError}
                    </Typography>
                )}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        {tiebreaker && (
                            <Button
                                variant="contained"
                                onClick={() => setTiebreakerOpen(false)}
                                sx={{
                                    marginRight: '20px',
                                    color: 'black',
                                    backgroundColor: 'white',
                                }}
                            >
                                CANCEL
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            disabled={tiebreakerValue?.length === 0}
                            onClick={handleTiebreakerSave}
                        >
                            SUBMIT
                        </Button>
                    </Box>
            </Box>
        </Modal>
    );
};

export default PickEmTiebreakerModal;
