import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import '../CardPieces/CardPieces.scss';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import QuickPickRedeemModal from "./QuickPickRedeemModal";

const RewardsCard = ({ selectedTab, reward, intervalType, schedule, league, isThereAGame, sponsorName, mainGameId, myUserId }) => {
    //console.log("Schedule in Rewards", schedule);
    const navigateToTop = useNavigateToTop();

    const [redemptionOpen, setRedemptionOpen] = useState(false);

    const rewardGame = schedule?.find((game) => {
        if (intervalType === 'Week') {
            return game.Week === reward?.interval; // Note: Use "Week" to match your data
        }
        // Add more conditions here for different interval types if needed
        return false; // Default case if no condition matches
    });

    const gameText = `${rewardGame?.AwayTeam} ${rewardGame?.AwayScore} ${rewardGame?.HomeTeam} ${rewardGame?.HomeScore} ${rewardGame?.Status === 'F/OT' ? 'F/OT' : 'F'}`;
    
    //console.log("Reward Game", rewardGame, intervalType, reward?.interval);

    const topLeftText = reward?.interval === 0 
    ? 'Join Reward' 
    : `${intervalType} ${reward?.interval}`;

  
    const topText = isThereAGame ? 'Props Coming Soon!' : (league === 'NFL' || league === 'CFB') ? 'Bye Week' : 'No Game Today';

    const formatExpirationDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "numeric",
            year: "numeric"
        }).replace(",", ""); // To remove the comma after weekday
    };
    
    const bottomText = selectedTab === 'ELIGIBLE' 
        ? `Expires: ${formatExpirationDate(reward?.expiration)}` 
        : selectedTab === 'REDEEMED' 
        ? 'Redeemed' 
        : 'Expired';
    
    //console.log(bottomText);
    

    

    
    return (
        <Box className="quick-pick-card-container">
            <Box 
                className="quick-pick-card-top-border"
                sx={{ backgroundColor: selectedTab === 'ELIGIBLE' ? '#C0C0C0' : selectedTab === 'REDEEMED' ? '#00AA72' : '#CC293C' }}    
            >
                <Typography 
                    className="quick-pick-card-top-border-rewards-text"
                    sx={{ 
                        color: selectedTab === 'ELIGIBLE' ? '#002129' : '#fff', 
                        textAlign: reward?.interval !== 0 ? 'left' : 'center',
                        marginLeft: reward?.interval !== 0 ? '8px' : '0',
                        margin: reward?.interval === 0 ? '0 auto' : '',
                    }}    
                >
                    {topLeftText}
                </Typography>
                {reward?.interval !== 0 && (
                    <Typography 
                        className="quick-pick-card-top-border-rewards-text"
                        sx={{ 
                            color: selectedTab === 'ELIGIBLE' ? '#002129' : '#fff',
                            textAlign: 'right',
                            marginRight: '8px', 
                        }}    
                    >
                        {gameText}
                    </Typography>
                )}
            </Box>
            <Box className="quick-pick-card-info">
                <Typography 
                    className="quick-pick-card-info-text" 
                    sx={{
                        minHeight: '90px',
                        textAlign: 'center', // Center text horizontally
                        display: 'flex', // Make sure the flex behavior is applied
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',
                        minHeight: '60px !important',
                      }}
                >
                    {reward?.reward}
                </Typography>
                {reward?.condition && (
                    <Typography 
                        className="quick-pick-card-condition-text" 
                        sx={{
                            textAlign: 'center', // Center text horizontally
                            display: 'flex', // Make sure the flex behavior is applied
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center', // Optionally center vertically within the minHeight
                          }}
                    >
                        ({reward?.condition})
                    </Typography>
                )}
                {selectedTab === 'ELIGIBLE' && (
                    <Button
                        variant="contained"
                        sx={{
                            marginBottom: '16px',
                        }}
                        onClick={() => setRedemptionOpen(true)}
                    >
                        REDEEM
                    </Button>    
                )}
            </Box>
            <Box className="quick-pick-card-bottom-border">
                <Typography 
                    className="quick-pick-card-bottom-border-text"
                    sx={{
                        color: selectedTab === 'ELIGIBLE' ? '#002129' : selectedTab === 'REDEEMED' ? '#00AA72' : '#CC293C',
                    }}
                >
                    {bottomText}
                </Typography>
            </Box>

            {redemptionOpen && (
                <QuickPickRedeemModal
                    open={redemptionOpen}
                    setOpen={setRedemptionOpen}
                    reward={reward}
                    sponsorName={sponsorName}
                    mainGameId={mainGameId}
                    myUserId={myUserId}
                />
            )}
        </Box>
    )
}

export default RewardsCard;