import React, { useState, useCallback } from 'react';
import { Button, TextField, Modal } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import { useAddCommissioner } from '../PrivateParty/hooks/useAddCommisioner';
import { useDemoteCommissioner } from '../PrivateParty/hooks/useDemoteCommissioner';
import { useDeletePrivateParty } from '../PrivateParty/hooks/useDeletePrivateParty';
import { useJoinPrivateParty } from '../PrivateParty/hooks/useJoinPrivateParty';
import { useRemoveUserFromPrivateParty } from '../PrivateParty/hooks/useRemoveUserFromPrivateParty';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import CreatePartyInfo from './CreatePartyInfo';
import "../BirdiePool/BirdiePrivateGroup/PrivateGroup.scss";

const MyPartyManageMembers = ({ 
    handleClickState, 
    gameData, 
    myUserId, 
    userSubgroups, 
    gameAddress, 
    leaderboardData,
    fullLeaderboard,
    partyState, 
    amICommissioner, 
    amICreator,
    subgroupInfo,
    setSignUpState,
    gameName,
    doesUserBelong,
    setJoinOpen
 }) => {
    const [modalState, setModalState] = useState(null); // State for modal
    const [modalUserInfo, setModalUserInfo] = useState(null); // State for modal user info
    //console.log("Leaderboard Data:", leaderboardData);
    const myInfo = {userId: myUserId};

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { gameId } = useParams();

    // Hooks for Private Party Requests
    const {mutate: addCommissioner} = useAddCommissioner();
    const {mutate: demoteCommissioner} = useDemoteCommissioner();
    const {mutate: deletePrivateParty} = useDeletePrivateParty();
    const {mutate: joinPrivateParty} = useJoinPrivateParty();
    const {mutate: removeUserFromPrivateParty} = useRemoveUserFromPrivateParty();

    
    const groupLength = leaderboardData?.length;
    //console.log("Group Length:", groupLength);
    
    const subGroupId = subgroupInfo?.subGroupID;
    //console.log("SUBGROUP ID:", subGroupId);

    const poolSubgroupInfo = gameData?.subGroups?.find(subgroup => subgroup.groupID === subGroupId);
    //console.log("Pool Subgroup Info:", poolSubgroupInfo);

    const poolCreator = poolSubgroupInfo?.gameCreator;
    const poolCoCommissioners = poolSubgroupInfo?.coCommissioners;
    // console.log("Am I Creator:", amICreator, "Am I Commissioner:", amICommissioner);
    // console.log("Pool Creator:", poolCreator, "Pool Co-Commissioners:", poolCoCommissioners);

   
    const inactiveUsers = poolSubgroupInfo?.inactiveUsers;
    //console.log("Inactive Users", inactiveUsers);

    // const allClubUsers = AllGameInfo[0]?.leaderboardData;
    // const myPartyUsers = clubleaderboardData?.leaderboardData;

    // let usersForList = [];
    // for (let i = 0; i < allClubUsers?.length; i++) {
    //     const user = allClubUsers[i];
    //     const userInMyParty = myPartyUsers?.find(partyUser => partyUser.userId === user.userId);
    //     if (!userInMyParty) {
    //         usersForList.push(user);
    //     }
    // }
    // //console.log("Club Email Modal, Users for List:", usersForList);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    let inactiveUserDetails = [];
    if (inactiveUsers && inactiveUsers?.length > 0) {
        inactiveUserDetails = fullLeaderboard
            .filter(user => inactiveUsers.includes(user.userId))
            .map(user => ({
                userId: user.userId,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
            }));
        }
            //console.log("Inactive User Details", inactiveUserDetails);


            leaderboardData?.sort((a, b) => {
                // Sort by creator first
                const aIsCreator = a.userId === poolCreator;
                const bIsCreator = b.userId === poolCreator;
            
                if (aIsCreator && !bIsCreator) return -1; // 'a' is creator, 'b' is not
                if (!aIsCreator && bIsCreator) return 1;  // 'b' is creator, 'a' is not
            
                // Sort by commissioner next
                const aIsCommissioner = poolCoCommissioners?.includes(a.userId);
                const bIsCommissioner = poolCoCommissioners?.includes(b.userId);
            
                if (aIsCommissioner && !bIsCommissioner) return -1; // 'a' is commissioner, 'b' is not
                if (!aIsCommissioner && bIsCommissioner) return 1;  // 'b' is commissioner, 'a' is not
            
                // If both are equal in terms of creator and commissioner, sort alphabetically by userFullName
                return a.userFullName.localeCompare(b.userFullName); // Sort alphabetically by userFullName (A to Z)
            });
            
            

    

    const handleAddCommissioner = async (member) => {

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };

        try {
            await addCommissioner(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(subGroupId)}/MANAGE`;;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };

    const handleRemoveCommissioner = async (member) => {
        // Define your handleRemoveCommissioner function here
        //console.log("Find the Game with", gameId, "then find the SubGroup with groupID:", subGroupId, "And REMOVE this userID into co-commissioners:", member.userId)
        //console.log("Find the user wiht this userID:", member.userId, "then find the SubGroup with subGroupID:", subGroupId, "and change Commissioner to false")

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };


        try {
            await demoteCommissioner(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(subGroupId)}/${uriEncoded('MANAGE')}`;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };


    const handleDeleteGame = async (member) => {
        // Define your handleDeleteGame function here
        //console.log("Find the game with gameID:", gameId, "then the subGroup with groupID:", subGroupId, "and gather all of the userIDs from that subGroups groupMembers.")
        //console.log("Then we have to go to find each individual users entry for that subgroup and remove it.")
        //console.log("Go into gameID:", gameId, "And find the subGroup with groupID:", subGroupId, "And delete that subGroup");

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };

        try {
            await deletePrivateParty(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('HOME')}/${uriEncoded('HOME')}`;;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };

    const handleRemoveUser = async (member, removeSelf) => {
        //console.log("Remove User:", member, "Remove Self:", removeSelf);
        let removedUserData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member,
            removingId: myUserId
        };
        //console.log("Removed User Data:", removedUserData);

        try {
            await removeUserFromPrivateParty(removedUserData, {
                onSuccess: async () => {
                    try {
                        if (removeSelf) {
                            window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('HOME')}/${uriEncoded('HOME')}`;
                        } else {
                            window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(subGroupId)}/${uriEncoded('MANAGE')}`;
                        }
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }

    };

    const handleReInstateUser = async (member) => {
        let reInstateUserData = {
            gameID: gameId,
            groupID: subGroupId,
            groupName: partyState,
            userID: member.userId,
        };

        try {
            await joinPrivateParty(reInstateUserData, {
                onSuccess: async () => {
                    try {

                        window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(subGroupId)}/${uriEncoded('MANAGE')}`;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }

    };


    const commissionerLogo = (
        <div className="commissioner-logo-circle">
            H
        </div>
    );

    const handleUserClick = (username, userId) => {
        // setSelectedUser(username);
        // setSelectedUserId(userId);
        if (userId === myUserId) {
        //   setScorecardState(userScorecard);
        } else {
        // const selectedUsersScorecard = getUserScorecard(username, userId);
        // setScorecardState(selectedUsersScorecard);
        }
        // handleClickState('SCORE_CARD');
      };


      const handleRemovedUserClick = (username, userId) => {
        // setpartyState(headerDisplayName);
        // const matchingEntry = AllGameInfo.find(entry => entry.name === headerDisplayName);
        // //console.log("Matching Entry", matchingEntry.leaderboardData);
        // setClubleaderboardData(matchingEntry);
        // setSelectedUser(username);
        // setSelectedUserId(userId);
        // const selectedUsersScorecard = getUserScorecard(username, userId);
        // //console.log("Selected Users Scorecard for:", username, userId, selectedUsersScorecard);
        // setScorecardState(selectedUsersScorecard);
        // handleClickState('SCORE_CARD');
    };

    const truncatedPartyState = partyState?.length > 25 ? `${partyState.slice(0, 25)}...` : partyState;

    return (
        <div>
            <Typography 
                variant="h2" 
                style={{ 
                    fontSize: isMobile ? '20px' : '28px', 
                    fontWeight: '700', 
                    marginTop:'32px',
                    marginBottom: '32px', 
                }}
            >
                {(amICommissioner || amICreator) ? "Manage" : "Pool"} Members ({groupLength})
            </Typography>

            <TableContainer 
                component={Paper} 
                style={{ 
                    width:'100%', 
                    maxWidth:'800px', 
                    margin:'0 auto', 
                    borderRadius:'0px', 
                    borderLeft:"2px solid #002129",
                    borderRight:"2px solid #002129",
                }}
            >
                 <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#002129', borderBottom: "2px solid #002129" }}>
                        <TableRow>
                             <TableCell 
                                className='private-commissioner-table' 
                                sx={{ 
                                    color: 'white', 
                                    fontSize: isMobile ? '14px' : '20px', 
                                    fontWeight: '700' 
                                }}
                            >
                                Player
                            </TableCell>
                            <TableCell 
                                className='private-commissioner-table' 
                                align="center" 
                                sx={{ 
                                    color: 'white', 
                                    fontSize: isMobile ? '14px' : '20px', 
                                    fontWeight: '700' 
                                }}
                            >
                                Host
                            </TableCell>
                            {amICommissioner && (
                                <TableCell 
                                    className='private-commissioner-table' 
                                    align="center" 
                                    sx={{ 
                                        color: 'white', 
                                        fontSize: isMobile ? '14px' : '20px', 
                                        fontWeight: '700' 
                                    }}
                                >
                                    Remove
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {leaderboardData?.map((leaderboardData, index) => (
                            //console.log("Leaderboard Data:", leaderboardData),
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                            >
                                <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            overflow: 'hidden', // Ensures text does not overflow the container
                                            textOverflow: 'ellipsis', // Adds ellipsis if text is too long
                                            whiteSpace: 'nowrap', // Prevents text from wrapping to a new line
                                        }}
                                    >
                                        {leaderboardData?.userFullName}
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                        {leaderboardData?.username}
                                    </div>
                                </TableCell>


                                <TableCell className='private-commissioner-table' align="center" sx={{ padding: '0px'}}>
                                    {(leaderboardData?.userId === poolCreator || poolCoCommissioners?.includes(leaderboardData?.userId)) ? (
                                        <>
                                            {commissionerLogo}
                                            {leaderboardData?.userId !== poolCreator && amICommissioner && (
                                                <RemoveIcon
                                                    sx={{ color: '#CC293C', cursor: 'pointer', marginLeft: isMobile ? '5px' : '10px' }}
                                                    onClick={() => { setModalUserInfo(leaderboardData); setModalState("confirmRemoveCommissioner")}}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        amICommissioner && (
                                            <AddIcon
                                                 sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                 onClick={() => { setModalUserInfo(leaderboardData); setModalState("confirmAdd")}}
                                            />
                                        )
                                    )}
                                </TableCell>

                                {amICommissioner && (
                                    <TableCell className='private-commissioner-table' align="center">
                                        {leaderboardData?.userId === poolCreator ? (
                                            <div>N/A</div>
                                        ) : (
                                            <CloseIcon
                                                sx={{ color: '#CC293C', cursor: 'pointer' }}
                                                onClick={() => {setModalUserInfo(leaderboardData); setModalState("confirmRemoveUser")}}
                                            />
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
            </TableContainer>

            { inactiveUserDetails && inactiveUserDetails?.length > 0 &&
                <>
                {/* <Typography variant="h2" style={{ fontSize: isMobile ? '20px' : '28px', fontWeight: '700' }}>
                    Removed Members ({inactiveUserDetails.length})
                </Typography> */}
                <TableContainer component={Paper} style={{maxWidth:'800px', margin:'0 auto', borderRadius:'0px', borderLeft:"2px solid #002129"}}>
                    <Table aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#002129' }}>
                            <TableRow>
                                <TableCell className='private-commissioner-table' sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}>Removed Player</TableCell>
                                {amICommissioner && <TableCell className='private-commissioner-table' sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }} align="center">Reinstate</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inactiveUserDetails?.map((inactiveUserDetails, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                                    >
                                    <TableCell className='private-commissioner-table' component="th" scope="row">
                                        <div
                                            style={{
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            overflow: 'hidden', // Ensures text does not overflow the container
                                            textOverflow: 'ellipsis', // Adds ellipsis if text is too long
                                            whiteSpace: 'nowrap', // Prevents text from wrapping to a new line
                                            cursor: 'pointer',
                                            fontStyle: 'italic',
                                            color: 'red'
                                            }}
                                            onClick={() => {
                                            handleRemovedUserClick(inactiveUserDetails?.username, inactiveUserDetails?.userId);
                                            }}
                                        >
                                            {inactiveUserDetails?.firstName} {inactiveUserDetails?.lastName}
                                        </div>
                                        <div style={{ fontSize: '10px', color: 'red' }}>
                                            {inactiveUserDetails?.username}
                                        </div>
                                    </TableCell>


                                    {amICommissioner && (
                                        <TableCell className='private-commissioner-table' align="center">
                                            {leaderboardData?.creator === true ? (
                                                <div>N/A</div>
                                            ) : (
                                                <AddIcon
                                                    sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                    onClick={() => {setModalUserInfo(inactiveUserDetails); setModalState("confirmReinstateUser")}}
                                                />
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
                </TableContainer>
                </>
            }
            {amICreator ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(gameId); setModalState("confirmDelete")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    DELETE POOL
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(myInfo); setModalState("confirmLeave")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    LEAVE POOL
                </Button>
            )}
            {!amICommissioner && !amICreator && (
                <>                
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '16px',
                            marginTop: '64px'
                        }}
                    >
                        Create Another Party?
                    </Typography>

                    <CreatePartyInfo
                        setSignUpState={setSignUpState}
                        gameAddress={gameAddress}
                        doesUserBelong={doesUserBelong}
                        gameName={gameName}
                        setJoinOpen={setJoinOpen}
                    />
                </>
            )}

                <Modal
                    open={modalState === "confirmAdd"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box className="modal-container" style={{ height: 'auto', maxHeight: '350px', maxWidth: '350px'}}>
                        Add {modalUserInfo?.username}<br></br>as a Co-Host?
                        <br></br>
                        <Box className="button-container">
                            <Button
                                variant="contained"
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => {setModalUserInfo(null); setModalState(null)}}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {handleAddCommissioner(modalUserInfo); setModalState(null); setModalUserInfo(null)}}
                            >
                                ADD
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={modalState === "confirmRemoveCommissioner"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                        <Box className="modal-container" style={{ height: 'auto', maxHeight: '350px', maxWidth: '350px'}}>
                        Remove {modalUserInfo?.username} as <br></br>a Co-Host?
                        <br></br>
                            <Box className="button-container">
                                <Button
                                    variant='contained'
                                    sx={{
                                        bgcolor: 'white',
                                        color: '#002129',
                                        border: '1px solid #002129',
                                        '&:hover': {
                                        bgcolor: 'white', // Maintain white background on hover
                                        color: '#002129', // Maintain text color on hover
                                        border: '1px solid #002129', // Maintain border color on hover
                                        },
                                    }}
                                    onClick={() => {setModalUserInfo(null); setModalState(null)}}
                                    >
                                        CANCEL
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => { handleRemoveCommissioner(modalUserInfo); setModalState(null); setModalUserInfo(null)}}
                                    >
                                    REMOVE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmRemoveUser"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{  height: 'auto', maxHeight: '350px', maxWidth: '350px', bgcolor: 'background.paper', p: 4 }}>
                            {(modalUserInfo?.userId === myUserId) ? (
                            <>
                                Do you want to leave <br /> {partyState}?
                            </>
                            ) : (
                            <>
                                Remove {modalUserInfo?.username} <br /> from {partyState}?
                            </>
                            )}
                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleRemoveUser(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                {modalUserInfo?.userId === myUserId ? (
                                <>
                                    LEAVE
                                </>
                                ) : (
                                <>
                                    REMOVE
                                </>
                                )}
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmLeave"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{  height: 'auto', maxHeight: '350px', maxWidth: '350px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Do you want to leave <br /> {partyState}?
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleRemoveUser(modalUserInfo, true); setModalState(null); setModalUserInfo(null); }}
                                >
                                LEAVE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmDelete"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{  height: 'auto', maxHeight: '350px', maxWidth: '350px', fontSize: '18px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Are you sure you want to <br /> delete {truncatedPartyState}? <br /> This CANNOT be undone.
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleDeleteGame(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                DELETE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                            open={modalState === "confirmReinstateUser"}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{  height: 'auto', maxHeight: '350px', maxWidth: '350px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Do you want to reinstate <br /> {modalUserInfo?.username}?
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleReInstateUser(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                REINSTATE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>


        </div>
            
    );
}

export default MyPartyManageMembers;
