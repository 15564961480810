// Hex to Rgba converter, for light backgrounds

export const hexToRgba = (hex = "#000000", opacity = 1) => {
    if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
        hex = "#000000"; // Default to black if the input is not a valid hex color
    }

    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};