import React from "react";
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext";
import { Typography, Box, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

const QuickPickMultipleEntryView = ({
    entries,
    findMyRank,
    theCurrentInterval,
    picksForTheCurrentInterval,
    getTotalPoints,
    getIntervalsPoints,
    myLeaderboardInfo,
    totalEntriesCount,
    oneWeekEvent,
    currentIntervalName,
    canIAddAnEntry,
    picksToMake,
    setIndexToRemove,
    setRemoveModalOpen,
    hasJoinDeadlinePassed,
    isUserAdmin,
    isThisPoolPartyGame,
    pickIndex,
}) => {

    const { 
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
        masterPoolId,
        gameId,
        page,
        gameAddress,
        setJoinOpen,
    } = useUserAndGameContext();

    const theme = useTheme();
    const isMobile = window.innerWidth < 768;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getEntryNumber = (entryName) => {
        const match = entryName?.match(/\((\d+)\)$/); // Find (x) at the end
        return match ? Number(match[1]) : 1; // Return (x) as a number or default to 1
    };

    const handleAddEntryClick = () => {
        setJoinOpen(true);
    };

    const handleEntryChange = (index) => {
        // If the current pickIndex already matches the clicked entry, do nothing
        if (Number(pickIndex) === index) {
            //console.log("Already on this entry, no change needed");
            return;
        }

        const newParams = new URLSearchParams(searchParams);
        // Update the parameters we want to change
        newParams.set('mId', masterPoolId);
        newParams.set('gId', gameId);
        newParams.set('page', page);
        newParams.set('pickIndex', index);

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(`/${gameAddress}?${newParams.toString()}`);
            setUnsavedPicksModalOpen(true);
            return;
        }
        
        navigate(`/${gameAddress}?${newParams.toString()}`);
        
    }
    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    };

    return (
        <Box className="quick-pick-multiple-entry-container">
            {entries?.map((entry, index) => {
                const entryId = entry?.id;
                const entryNumber = getEntryNumber(entry?.name);
                const selectedEntry = entryNumber === (Number(pickIndex) + 1);
                const picks = entry?.pool?.picks;
                const picksThisInterval = picks?.filter(pick => Number(pick.interval) === Number(theCurrentInterval)).length;
                const arePicksIn = picksThisInterval === picksForTheCurrentInterval;
                //console.log("Entry Number", entryNumber, "Entry Number Match?", entryNumber, (Number(pickIndex) + 1), (entryNumber === (Number(pickIndex) + 1)), "Selected Entry", selectedEntry, "Picks This Interval", picksThisInterval, "Picks For The Current Interval", picksForTheCurrentInterval, "Are Picks In", arePicksIn);
                return (
                    <Box 
                        key={index} 
                        className="quick-pick-multiple-entry-content-wrapper"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box 
                            className="quick-pick-multiple-entry-content"
                            sx={{
                                border: selectedEntry ? '2px solid #002129' : '2px solid #78838a',
                                borderRadius: '8px',
                                opacity: selectedEntry ? 1 : 0.5,
                                cursor: 'pointer',
                            }}    
                            onClick={() => handleEntryChange(entryNumber - 1)}
                        >
                            <Box className="quick-pick-multiple-entry-column">
                                <Typography className="quick-pick-multiple-entry-header-text">
                                    Entry ({entryNumber})
                                </Typography>

                                <Typography className="quick-pick-multiple-entry-text">
                                    Points: {getTotalPoints(picks)}
                                </Typography>

                                <Typography className="quick-pick-multiple-entry-text">
                                    {!isMobile && "Rank: "}{findMyRank(myLeaderboardInfo, entry?.name)} of {totalEntriesCount}
                                </Typography>

                                <Typography 
                                    className="quick-pick-multiple-entry-text"
                                    sx={{ color: arePicksIn ? "#00AA72" : "#CC293C" }}
                                >
                                    {!isMobile && "Picks? "} {/* Fixed syntax */}
                                    {picksThisInterval}/{picksForTheCurrentInterval}{" "}
                                    {arePicksIn ? (
                                        <CheckIcon sx={{ color: "#00AA72", fontSize: isMobile ? 16 : 28 }} />
                                    ) : (
                                        <CloseIcon sx={{ color: "#CC293C", fontSize: isMobile ? 16 : 28 }} />
                                    )}
                                </Typography>

                            </Box>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
                            {!selectedEntry ? (
                                <Typography 
                                    onClick={() => handleEntryChange(entryNumber - 1)}
                                    sx={{
                                        color: "#07356B",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        fontSize: isMobile ? '12px' : '14px',
                                        fontWeight: "700",
                                        textAlign: 'center',
                                    }}
                                >
                                    SELECT
                                </Typography>
                            ) : (
                                // Only show Remove option if join deadline hasn't passed
                                (!hasJoinDeadlinePassed && !(isUserAdmin && entries?.length === 1)) && (
                                    <Typography 
                                        onClick={() => handleRemovedClick(entryNumber - 1)}
                                        sx={{
                                            color: "#CC293C",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            fontSize: isMobile ? '12px' : '14px',
                                            fontWeight: "700",
                                            textAlign: 'center',
                                        }}
                                    >
                                        REMOVE
                                    </Typography>
                                )
                            )}
                        </Box>
                    </Box>
                );
            })}

            {canIAddAnEntry && (
                <Box 
                    className="quick-pick-multiple-entry-content"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="default"
                        onClick={handleAddEntryClick}
                        sx={{
                            backgroundColor: '#002129',
                            color: 'white',
                            padding: '8px 16px',
                            '&:hover': {
                                backgroundColor: '#003a4a',
                            },
                            height: '30px',
                            fontSize: isMobile ? '12px' : '14px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Add Entry
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default QuickPickMultipleEntryView;