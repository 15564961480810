import React, { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useCFBSurvivorContext } from "../context/CFBSurvivorContext";
import "../../NFLSurvivor/NFLSurvivorStyles/SurvivorUserDash.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useParams } from "react-router-dom";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import CFBSurvivorUserStatsModal from "../../GameModals/SurvivorModals/CFBSurvivorUserStats/CFBSurvivorUserStatsModal";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CFBSurvivorConferencePick from "./CFBSurvivorConferencePick";
import { DateTime } from "luxon";

const CFBSurvivorUserDash = ({ currentWeeksDeadlineDateTime }) => {
    //console.log("CUrrnet Week's Deadline Date Time: ", currentWeeksDeadlineDateTime);

    const { 
        doesUserHaveALoss, 
        userPicksFromDB, 
        startingWeekState,
        setSignUpState, 
        NFLTeams,
        CFBTeams,
        CFBNames,
        myUsername,
        lossWeek,
        losingGame, 
        gameAddress,
        gameType,
        gameData,
        mainGameId,
        myUserId,
        doesUserBelong,
        doesUserHaveACCLoss,
        lossWeekACC,
        doesUserHaveBigTenLoss,
        lossWeekBigTen,
        doesUserHaveBig12Loss,
        lossWeekBig12,
        doesUserHaveSECLoss, 
        lossWeekSEC,
        teamFilter,
        setTeamFilter,
        teamsToInclude,
        joinOpen,
        setJoinOpen
    } = useCFBSurvivorContext();

    const { component } = useParams();

    const gameName = gameData?.gameName;
    const logo = gameData?.clubLogo;

    const [statsModalOpen, setStatsModalOpen] = useState(false);

    const totalWins = userPicksFromDB?.filter((pick) => pick.result === 'Win').length;


  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    let previousACCLoss = false, previousBigTenLoss = false, previousBig12Loss = false, previousSECLoss = false;
    if (doesUserHaveACCLoss && lossWeekACC < startingWeekState) {
        previousACCLoss = true;
    }
    if (doesUserHaveBigTenLoss && lossWeekBigTen < startingWeekState) {
        previousBigTenLoss = true;
    }
    if (doesUserHaveBig12Loss && lossWeekBig12 < startingWeekState) {
        previousBig12Loss = true;
    }
    if (doesUserHaveSECLoss && lossWeekSEC < startingWeekState) {
        previousSECLoss = true;
    }

    const currentACCPick = userPicksFromDB?.find((pick) => pick.week === startingWeekState && pick.conference === 'ACC');
    const ACCResult = currentACCPick?.result;
    const ACCLogo = CFBTeams?.find((team) => team.GlobalTeamID === currentACCPick?.teamId)?.TeamLogoUrl;

    const currentBigTenPick = userPicksFromDB?.find((pick) => pick.week === startingWeekState && pick.conference === 'Big Ten');
    const BigTenResult = currentBigTenPick?.result;
    const BigTenLogo = CFBTeams?.find((team) => team.GlobalTeamID === currentBigTenPick?.teamId)?.TeamLogoUrl;

    const currentBig12Pick = userPicksFromDB?.find((pick) => pick.week === startingWeekState && pick.conference === 'Big 12');
    const Big12Result = currentBig12Pick?.result;
    const Big12Logo = CFBTeams?.find((team) => team.GlobalTeamID === currentBig12Pick?.teamId)?.TeamLogoUrl;

    const currentSECPick = userPicksFromDB?.find((pick) => pick.week === startingWeekState && pick.conference === 'SEC');
    const SECResult = currentSECPick?.result;
    const SECLogo = CFBTeams?.find((team) => team.GlobalTeamID === currentSECPick?.teamId)?.TeamLogoUrl;

    const totalLosses = [doesUserHaveACCLoss, doesUserHaveBigTenLoss, doesUserHaveBig12Loss, doesUserHaveSECLoss];
    const howManyLossesCount = totalLosses?.filter((loss) => loss === true).length;
    const lossWeeks = [lossWeekACC, lossWeekBigTen, lossWeekBig12, lossWeekSEC];
    const howManyLossesThisWeek = lossWeeks?.filter((week) => week === startingWeekState).length;
    //console.log("Current Picks:", currentACCPick, currentBigTenPick, currentBig12Pick, currentSECPick);
    //console.log("HOW MANY LOSSES THIS WEEK:", howManyLossesThisWeek);
    //console.log("HOW MANY LOSSES:", howManyLossesCount);

    // Calculate totalPicksThisWeek based on current picks
    const currentPicks = [currentACCPick, currentBigTenPick, currentBig12Pick, currentSECPick];
    const picksThisWeek = [ACCResult, BigTenResult, Big12Result, SECResult];
    const picksWithEmptyFilteredOut = picksThisWeek?.filter(result => result !== null && result !== undefined).length;
    const noPicksThisWeek = currentPicks?.filter(pick => pick?.teamId === "No Pick")?.length;
    const totalPicksThisWeek = picksWithEmptyFilteredOut - noPicksThisWeek;
    //console.log("TOTAL PICKS THIS WEEK:", totalPicksThisWeek, noPicksThisWeek, picksThisWeek);

    const picksAvailableToBeMade = 4 - howManyLossesCount + howManyLossesThisWeek;

    //console.log("TOTAL PICKS THIS WEEK:", totalPicksThisWeek);
    //console.log("PICKS AVAILABLE TO BE MADE:", picksAvailableToBeMade);

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    // Define the date and time in New York timezone
    const deadlineTimeInNewYork = DateTime.fromObject({
        year: 2024,
        month: 8, // August
        day: 31,
        hour: 12, // 12 PM in 24-hour format
        minute: 0,
        second: 0,
        millisecond: 0,
    }, { zone: 'America/New_York' });
    const hasJoinDeadlinePassed = nowInNewYork > deadlineTimeInNewYork;


  return (
    <>
    <Box className={"bracket-box-header"} sx={{ height: isMobile ? '87px' : '235px'}}>

        <Box sx={{ maxWidth: '1116px', margin: '0 auto', width: '100%' }}>
            <Box className="bracket-box-header-trophy" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <img className="bracket-box-trophy" src={bracketTrophy} alt='' />
                <Typography className={"bracket-box-header-username"} variant='h1' sx={{ display: 'flex', alignItems: 'center', fontSize: isMobile ? '16px' : '60px'}}>
                    {myUsername}
                    {doesUserBelong && (
                      <span 
                        style={{ 
                          color: '#00AAD1', 
                          fontSize: isMobile ? '12px' : '20px', 
                          fontWeight: 500, 
                          textDecoration: 'underline',
                          marginLeft: '16px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                        }}
                        onClick={() => setStatsModalOpen(true)}
                      >
                        My Picks
                      </span>
                    )}
                </Typography>
                </Box>
                {doesUserBelong && (
                <Box 
                    sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: isMobile ? '327px' :'850px',
                    margin: '0 auto',
                    width:'90%'
                    }}
                >
         
                <>
                    <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4' style={{ color: '#002129'}}>WINS</Typography>
                    <Typography variant='h3' sx={{ fontSize: isMobile ? '12px' : '32px', color: '#002129', fontWeight: '700', marginTop: '5px'}}>{totalWins}</Typography>
                    
                  </Box>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4' style={{ color: '#002129'}}>WEEK {startingWeekState} STATUS</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px', justifyContent: 'center' }}>
                        {totalPicksThisWeek === picksAvailableToBeMade ? (
                            <>
                            <CheckIcon sx={{ color: '#00AA72', fontSize: isMobile ? '19px' : '40px' }} />
                            <Typography
                                variant="h3"
                                sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px', fontSize: isMobile ? '12px' : '32px' }}
                            >
                                ({totalPicksThisWeek}/{picksAvailableToBeMade})
                            </Typography>
                            </>
                        ) : (
                            <>
                            <CloseIcon sx={{ color: '#cc293c', fontSize: isMobile ? '19px' : '40px' }} />
                            <Typography
                                variant="h3"
                                sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px', fontSize: isMobile ? '12px' : '32px' }}
                            >
                                ({totalPicksThisWeek}/{picksAvailableToBeMade})
                            </Typography>
                            </>
                        )}
                    </Box>

                  </Box>
                    <CFBSurvivorConferencePick
                        conferenceName="ACC"
                        pick={currentACCPick}
                        result={ACCResult}
                        logo={ACCLogo}
                        previousLoss={previousACCLoss}
                        anyLoss={doesUserHaveACCLoss}
                    />
                    <CFBSurvivorConferencePick
                        conferenceName="Big 12"
                        pick={currentBig12Pick}
                        result={Big12Result}
                        logo={Big12Logo}
                        previousLoss={previousBig12Loss}
                        anyLoss={doesUserHaveBig12Loss}
                    />
                    <CFBSurvivorConferencePick
                        conferenceName="Big Ten"
                        pick={currentBigTenPick}
                        result={BigTenResult}
                        logo={BigTenLogo}
                        previousLoss={previousBigTenLoss}
                        anyLoss={doesUserHaveBigTenLoss}
                    />
                    <CFBSurvivorConferencePick
                        conferenceName="SEC"
                        pick={currentSECPick}
                        result={SECResult}
                        logo={SECLogo}
                        previousLoss={previousSECLoss}
                        anyLoss={doesUserHaveSECLoss}
                    />
                </>
            </Box>
            )}
           {!doesUserBelong && (
                !hasJoinDeadlinePassed ? (
                    <Button 
                    variant="contained" 
                    onClick={() => setJoinOpen(true)}
                    sx={{ 
                        fontSize: '14px', 
                        fontWeight: '700', 
                        width: '100px', 
                        height: '36px', 
                        whiteSpace: 'nowrap',
                        marginTop: isMobile ? '0px' : '24px'
                    }}
                    >
                    JOIN POOL
                    </Button>
                ) : (
                    <Typography 
                    variant="h6" 
                    sx={{ 
                        fontSize: isMobile ? '12px' : '24px', 
                        fontWeight: '700', 
                        marginTop: isMobile ? '6px' : '12px',
                        marginBottom: '12px',
                        color: '#CC293C'
                    }}
                    >
                    The pick deadline has passed. <br/> Pool entry is closed.
                    </Typography>
                )
            )}

          </Box>
      </Box>
      {statsModalOpen && (
        <CFBSurvivorUserStatsModal 
            statsModalOpen={statsModalOpen} 
            setStatsModalOpen={setStatsModalOpen} 
            myUsername={myUsername}
            doesUserHaveALoss={doesUserHaveALoss}
            lossWeek={lossWeek}
            startingWeekState={startingWeekState}
            currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
            CFBTeams={CFBTeams}
            CFBNames={CFBNames}
            areTheseMyStats={true}
            usersPicks={userPicksFromDB}
            teamFilter={teamFilter}
            doesUserHaveACCLoss={doesUserHaveACCLoss}
            lossWeekACC={lossWeekACC}
            doesUserHaveBig12Loss={doesUserHaveBig12Loss}
            lossWeekBig12={lossWeekBig12}
            doesUserHaveBigTenLoss={doesUserHaveBigTenLoss}
            lossWeekBigTen={lossWeekBigTen}
            doesUserHaveSECLoss={doesUserHaveSECLoss}
            lossWeekSEC={lossWeekSEC}
        />
      )}
      {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
        />
      )}
      </>
    );
}

export default CFBSurvivorUserDash;