import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useGetLeaderboardPageData } from '../../../hooks/pools/useGetLeaderboardPageData';
import { useGetUserData } from '../../BirdiePool/hooks';

const LeaderboardsPageContext = createContext();

export const useLeaderboardsPageContext = () => {
    const context = useContext(LeaderboardsPageContext);
    if (!context) {
        throw new Error('useLeaderboardsPageContext must be used within a LeaderboardsPageProvider');
    }
    return context;
}

export const LeaderboardsPageProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);

    const [hasDataFetched, setHasDataFetched] = useState(false);

    const { mutate: fetchLeaderboardPageData, data: leaderboardPageData, isLoading: leaderboardPageDataLoading, isError: leaderboardPageDataError, error: leaderboardPageDataErrorData } = useGetLeaderboardPageData();
    const { mutate: fetchUserData, data: userData, isLoading: userDataLoading, isError: userDataError, error: userDataErrorData } = useGetUserData();

    // useEffect to fetch user data (if myUserId is not null)
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [myUserId, fetchUserData]);
    //console.log("userData:", userData);

    const myUsername = userData?.user_name;
    //console.log("myUsername:", myUsername);


    // useEffect to fetch leaderboard data
    useEffect(() => {
        if (!hasDataFetched) {
            fetchLeaderboardPageData();
            setHasDataFetched(true);
        }
    }, [hasDataFetched, fetchLeaderboardPageData]);
    //console.log('leaderboardPageData:', leaderboardPageData);

    const LeaderboardsPageContextValues = useMemo(() => {
        return {
            myUserId,
            myUsername,
            leaderboardPageData,
        };
    }, [myUserId, myUsername, leaderboardPageData]);

    if (!hasDataFetched) {
        return <LoadingScreen />;
    }

    return (
        <LeaderboardsPageContext.Provider value={LeaderboardsPageContextValues}>
            {children}
        </LeaderboardsPageContext.Provider>
    );
}

export default LeaderboardsPageContext;