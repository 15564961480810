import React, { useState, useEffect } from "react";
import { useCFBGamesByWeek, useCFBTeams } from "../hooks/cfb";
import { useNFLScoresBySeason } from "../hooks/nfl";
import { MenuItem, Select, FormControl, InputLabel, Box, Typography, Button, TextField } from "@mui/material";

const AdminFootballGames = () => {
    const { mutate: fetchCFBGamesByWeek, data: CFBGamesByWeek, isLoading: CFBGamesByWeekLoading, isError: CFBGamesByWeekError, error: CFBGamesByWeekErrorData } = useCFBGamesByWeek();
    const { mutate: fetchNFLScoresBySeason, data: NFLScoresBySeason, isLoading: NFLScoresBySeasonLoading, isError: NFLScoresBySeasonError, error: NFLScoresBySeasonErrorData } = useNFLScoresBySeason();
    const { mutate: fetchCFBTeams, data: CFBTeams, isLoading: CFBTeamsLoading, isError: CFBTeamsError, error: CFBTeamsErrorData } = useCFBTeams();

    useEffect(() => {
        fetchNFLScoresBySeason();
        fetchCFBGamesByWeek();
        fetchCFBTeams();
    }, [fetchNFLScoresBySeason, fetchCFBGamesByWeek, fetchCFBTeams]);

    //console.log("CFB Games By Week", CFBGamesByWeek, "NFL Scores By Season", NFLScoresBySeason, "CFB Teams", CFBTeams);

    const [pickState, setPickState] = useState([]);
    const [selectedSport, setSelectedSport] = useState('NFL');
    const [selectedGame, setSelectedGame] = useState('NFL Pick Em');
    const [selectedWeek, setSelectedWeek] = useState(1);
    const [selectedConference, setSelectedConference] = useState('ACC');
    const [teamsToInclude, setTeamsToInclude] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState([]);
    const [spreads, setSpreads] = useState({});

    useEffect(() => {
        if (selectedSport === 'NFL') {
            const filteredNFLSchedule = NFLScoresBySeason?.filter(game => game.Week == selectedWeek);
            setSelectedSchedule(filteredNFLSchedule);
        } else {
            //console.log("CFB HAS BEEN SELECTED");
            //console.log("Selected Week", selectedWeek);
            const filteredCFBSchedule = CFBGamesByWeek?.filter(game => game.Week == selectedWeek && 
                (teamsToInclude.includes(game.GlobalHomeTeamID) || teamsToInclude.includes(game.GlobalAwayTeamID))
            );
            setSelectedSchedule(filteredCFBSchedule);
        }
    }, [NFLScoresBySeason, CFBGamesByWeek, selectedSport, selectedWeek, teamsToInclude]);
    console.log("PickState", pickState);
    //console.log("Selected Sport", selectedSport, "Selected Game", selectedGame, "Selected Week", selectedWeek, "Selected Conference", selectedConference);
    //console.log("Teams to Include", teamsToInclude);
    console.log("Selected Schedule", selectedSchedule);
    const CFBConferences = [
        {Name: "Atlantic Coast", Abbreviation: "ACC", ConferenceID: 3,},
        {Name: "Big 12", Abbreviation: "Big 12", ConferenceID: 5,},
        {Name: "Big Ten", Abbreviation: "Big Ten", ConferenceID: 6,},
        {Name: "SEC", Abbreviation: "SEC", ConferenceID: 17,},
    ];

    function gatherConferenceTeams(conferenceIDs) {
        // Ensure conferenceIDs is always an array
        if (!Array.isArray(conferenceIDs)) {
            conferenceIDs = [conferenceIDs];
        }
    
        const conferenceTeams = [];
        for (let i = 0; i < CFBTeams?.length; i++) {
            const team = CFBTeams[i];
            if (conferenceIDs.includes(team.ConferenceID)) {
                conferenceTeams.push(team.GlobalTeamID);
            }
        }
        return conferenceTeams;
    }

    useEffect(() => {
        if (selectedConference !== "Top 25" && selectedConference !== "All Games") {
            const conference = CFBConferences?.find(conf => conf.Abbreviation === selectedConference);
            const conferenceIDs = conference?.ConferenceID;
            const conferenceTeams = gatherConferenceTeams(conferenceIDs);
            setTeamsToInclude(conferenceTeams);
        }
    }, [CFBTeams, selectedConference]);



    const handleGameChange = (event) => {
        setSelectedGame(event.target.value);
        if (event.target.value === 'CFB Survivor') {
            setSelectedSport('CFB');
        } else {
            setSelectedSport('NFL');
        }
    }

    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
    }

    // Create week options based on the selected sport
    const weekOptions = selectedSport === 'NFL' ? [...Array(18).keys()].map(i => i + 1) : [...Array(14).keys()].map(i => i + 1);

     // Function to format the DateTime string
     const formatDateTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        const options = { weekday: 'short', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

     // Handler for when the spread input changes
     const handleSpreadChange = (gameID, value) => {
        setSpreads(prevSpreads => ({
            ...prevSpreads,
            [gameID]: value,
        }));
    };

    function gameResult(homeOrAwaySelected, homeScore, awayScore, spread) {
        let result = "Pending";
        let margin = 0;
        if (homeOrAwaySelected === "Home") {
            if (homeScore + spread > awayScore) {
                result = "Win";
                margin = homeScore + spread - awayScore;
            } else if (homeScore + spread < awayScore) {
                result = "Loss";
                margin = homeScore + spread - awayScore;
            } else {
                result = "Push";
            }
        } else {
            if (awayScore + spread > homeScore) {
                result = "Win";
                margin = awayScore + spread - homeScore;
            } else if (awayScore + spread < homeScore) {
                result = "Loss";
                margin = awayScore + spread - homeScore;
            } else {
                result = "Push";
            }
        }

        return { result, margin };
    }


    const handleAddClick = (gameInfo, teamType) => () => {
        const spread = spreads[gameInfo?.GlobalGameID] || 0;
        //console.log("Game Info", gameInfo, "Team Type", teamType, "Spread", spread, "Selected Game", selectedGame);

            const isGameFinal = gameInfo?.Status === "Final" || gameInfo?.Status === "F/OT";
            const homeScore = gameInfo?.HomeScore || gameInfo?.HomeTeamScore;
            const awayScore = gameInfo?.AwayScore || gameInfo?.AwayTeamScore;

            let newPick = {
                gameId: gameInfo?.GlobalGameID,
                teamId: teamType === 'Home' ? gameInfo?.GlobalHomeTeamID : gameInfo?.GlobalAwayTeamID,
                dateTime: gameInfo?.DateTime,
                week: gameInfo?.Week,
                isHomeSelected: teamType === 'Home',
                result: "Pending",
            };

            if (selectedGame === 'NFL Pick Em') {
                newPick.spread = parseFloat(spread);
            }

            if (isGameFinal) {
                const { result, margin } = gameResult(teamType, homeScore, awayScore, parseFloat(spread));
                newPick.result = result;
                newPick.margin = margin;
            }

            if (selectedGame === 'CFB Survivor') {
                newPick.conference = selectedConference;
            }

            setPickState(prevPicks => [...prevPicks, newPick]);
            console.log("New Pick", newPick);
    };
    
    

    return (
        <div>
            <h1>Admin Football Game Pick Helper</h1>
            
            <FormControl variant="outlined">
                <InputLabel id="select-game-label">Select Game</InputLabel>
                <Select
                    labelId="select-game-label"
                    value={selectedGame}
                    onChange={handleGameChange}
                    label="Select Game"
                >
                    <MenuItem value="NFL Pick Em">NFL Pick Em</MenuItem>
                    <MenuItem value="NFL Survivor">NFL Survivor</MenuItem>
                    <MenuItem value="CFB Survivor">CFB Survivor</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined">
                <InputLabel id="select-week-label">Select Week</InputLabel>
                <Select
                    labelId="select-week-label"
                    value={selectedWeek}
                    onChange={handleWeekChange}
                    label="Select Week"
                >
                    {weekOptions.map(week => (
                        <MenuItem key={week} value={week}>{`Week ${week}`}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedSport === 'CFB' && (
                
                <FormControl variant="outlined">
                    <InputLabel id="select-conference-label">Select Conference</InputLabel>
                    <Select
                        labelId="select-conference-label"
                        value={selectedConference}
                        onChange={(event) => setSelectedConference(event.target.value)}
                        label="Select Conference"
                    >
                        <MenuItem value="ACC">ACC</MenuItem>
                        <MenuItem value="Big 12">Big 12</MenuItem>
                        <MenuItem value="Big Ten">Big Ten</MenuItem>
                        <MenuItem value="SEC">SEC</MenuItem>
                    </Select>
                </FormControl>
            )}

            {/* Map through selectedSchedule to display game details */}
            {selectedSchedule?.map((game, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="space-between" p={2}>

                    {/* Date and Time */}
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        {formatDateTime(game.DateTime)}
                    </Typography>

                    {selectedGame === 'NFL Pick Em' && (
                        <Box display="flex" alignItems="center" sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                            Spread:
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"  // Small input box
                            inputProps={{ maxLength: 5 }}  // Limit to 4 characters
                            sx={{ width: '70px' }}  // Width of the input box
                            value={spreads[game.GlobalGameID] || ''}  // Get the current spread value
                            onChange={(e) => handleSpreadChange(game.GlobalGameID, e.target.value)}
                        />
                    </Box>
                    )}

                    {/* Button 1 */}
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleAddClick(game, 'Home')}
                        sx={{ marginRight: 1 }}
                    >
                        ADD
                    </Button>

                    {/* Home Team */}
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        Home: {game.HomeTeamName || game.HomeTeam}
                    </Typography>

                    {/* Away Team */}
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        Away: {game.AwayTeamName || game.AwayTeam}
                    </Typography>

                    {/* Button 2 */}
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleAddClick(game, 'Away')}
                    >
                        ADD
                    </Button>

                    {/* Game Status */}
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        {game.Status}
                    </Typography>

                    {/* Home Score */}
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        Home Score: {game.HomeTeamScore || game.HomeScore}
                    </Typography>

                    {/* Away Score */}
                    <Typography variant="body1">
                        Away Score: {game.AwayTeamScore || game.AwayScore}
                    </Typography>

                </Box>
            ))}
        </div>
    );
};

export default AdminFootballGames;
