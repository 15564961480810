import React, { useState, useEffect } from 'react';
import PicksNotSaved from '../../PicksNotSaved/PicksNotSaved';
import '../../NFLPickEm/NFLPickEmStyles/PickEmWeekNav.scss';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { MenuItem, Select, Modal, Box, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const SurvivorWeekNav = ( {setLeadingPlaceholder, setTrailingPlaceholder} ) => {
    const { 
        week, 
        setWeek, 
        pickEmState, 
        setPickEmState,
        mainGameId,
        gameAddress
    } = useNFLSurvivorContext();
    const lowerBound = 1;
    const upperBound = 18;

    const [pickNotSavedModal, setPickNotSavedModal] = useState(false);
    const [pendingWeek, setPendingWeek] = useState(null);

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    useEffect(() => {
        if (week === 1) {
            setLeadingPlaceholder(true);
        } else if (week === 18) {
            setTrailingPlaceholder(true);
        }
        if (week !== 1) {
            setLeadingPlaceholder(false);
        }
        if (week !== 18) {
            setTrailingPlaceholder(false);
        }
    }, [week]);

    
    const handleWeekSelectChange = (event) => {
            const newWeek = event.target.value;
            setWeek(newWeek);
            if (newWeek === 1) {
                setLeadingPlaceholder(true);
            } else if (newWeek === 18) {
                setTrailingPlaceholder(true);
            }
    
            if (newWeek !== 1) {
                setLeadingPlaceholder(false);
            } 
            if (newWeek !== 18) {
                setTrailingPlaceholder(false);
            }
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newWeek}`);
        };

        const handleWeekChange = (direction) => {
            let newWeek = week;
            if (direction === 'prev') {
                newWeek -= 1;
                setWeek(newWeek);
            } else {
                newWeek += 1;
                setWeek(newWeek);
            }

            if (newWeek === 1) {
                setLeadingPlaceholder(true);
            }
            if (newWeek === 18) {
                setTrailingPlaceholder(true);
            }

            if (newWeek !== 1) {
                setLeadingPlaceholder(false);
            }
            if (newWeek !== 18) {
                setTrailingPlaceholder(false);
            }
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newWeek}`);


        }
        
        return (
            <>
           <Box 
                sx={{ 
                    width: '100%', // Full width container
                    backgroundColor: '#002129', // Set the background color
                    padding: '0 16px', // Adjust padding to ensure the inner content is centered
                    boxSizing: 'border-box'
                }}
            >
                <Box sx={{ 
                    margin: '0 auto',
                    width: isMobile ? '310px' : '467px'
                }}>
                    <div className="game-date-navigation-bar">
                        <button className="navigation-element-icon" onClick={() => handleWeekChange('prev')} disabled={week <= lowerBound}>
                            <ArrowBackIosNewIcon />
                        </button>
                        <div className="week-select-container">
                            <Select
                                value={week}
                                onChange={handleWeekSelectChange}
                                variant="outlined"
                                className="week-select"
                                sx={{
                                    color: 'white',
                                    '.MuiSelect-select': {
                                        color: 'white',
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '.MuiSvgIcon-root': {
                                        color: 'white',
                                        fontSize: '32px',
                                        marginLeft: '8px',
                                        marginRight: '-8px',
                                    },
                                    '.MuiMenuItem-root': {
                                        color: 'black',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                        },
                                    },
                                }}
                            >
                                {Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
                                    const weekNumber = index + lowerBound;
                                    return (
                                        <MenuItem key={weekNumber} value={weekNumber}>
                                            Week {weekNumber}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <button className="navigation-element-icon" onClick={() => handleWeekChange('next')} disabled={week >= upperBound}>
                            <ArrowBackIosNewIcon style={{ transform: 'rotate(180deg)' }} />
                        </button>
                    </div>
                </Box>
            </Box>
            </>
        );
};

export default SurvivorWeekNav;
