import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useCFBSuperPickemContext } from './CFBSuperPickemContext';
import DynamicPoolHeaderBar from '../../PoolHeaderBar/DynamicPoolHeaderBar';
import SuperPickemMyPicks from '../MyPicks/SuperPickemMyPicks';
import SuperPickemMyBracket from '../MyBracket/SuperPickemMyBracket';
import { DateTime } from 'luxon';
import GameRulesPage from '../../GameRulesPage/GameRulesPage';
import GameHomePage from '../../GameHomePage/GameHomePage';
import GameSponsorHeader from '../../PoolHeaderBar/GameSponsorHeader';
import JoinGameModal from '../../GameModals/JoinGameModal/JoinGameModal';
import MyParty from '../../MyParty/MyParty';
import SponsorTab from '../../SponsorTab/SponsorTab';
import SuperPickemNav from '../SuperPickemNav/SuperPickemNav';
import { gatherLogoCFB, gatherStadiumInfoCFB, gatherDisplayInfoCFB, gatherScoresCFB, gatherLineDisplayCFB } from '../utils/cfbUtils';
import AzureStuffDELETE from './AzureStuffDELETE';

const CFBSuperPickemContainer = () => {
    const {
        myUserId,
        mainGameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        cfbTeams,
        cfbNames,
        cfbStadiums,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        totalReferrals,
        newSignUps,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup, 
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
    } = useCFBSuperPickemContext();

    const [unsavedPicks, setUnsavedPicks] = useState(false);

    const { component, pageState, optionalState } = useParams();

    const location = useLocation();
    const navigateToTop = useNavigateToTop();

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward =>
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );

    useEffect(() => {
        console.log("Updating Click State");
        //Update the clickState whenever the URL changes
        if (component) {
            setClickState(component);
        }
    }, [component, location]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    console.log("LOGO:", logo);
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    //console.log("Sponsor Header Logo", sponsorHeaderLogo);
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    

    //MAKE THESE DYNAMIC ONCE LEADERBOARD IS SET UP
    const whosInFirst = "Winner";
    const whosInFirstCharacterCount = 6;
    const whosInSecond = "Runner-Up";
    const whosInSecondCharacterCount = 8;
    const whosInThird = "Third Place";
    const whosInThirdCharacterCount = 10;
    const howManyWinners = 3;

    const homePageProps = {gameAddress, userData, gameData, setInterval, currentInterval: currentRound, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners}

    console.log("Picks By Interval:", picksByInterval, "interval:", interval);
    const picksThisInterval = picksByInterval?.find(picks => picks.interval === interval)?.picks;
    console.log("Picks This Interval:", picksThisInterval);

    const picksThisIntervalInDB = userPicksFromDB?.filter(pick => pick.interval === interval);
    const picksThisIntervalInState = pickState?.filter(pick => pick.interval === interval);
    
    //Reset unsaved picks when game id changes
    useEffect(() => {
        console.log("Resetting unsaved picks");
        if (mainGameId) {
            setUnsavedPicks(false);
        }
    }, [mainGameId]);

    const handleClickState = (click) => {
        setClickState(click);
        let pageStateToUse = partyId;
        if (click === 'MY_PICKS') {
          pageStateToUse = 'CURRENT';
          setInterval(currentRound);
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        } else if (click === 'HOME') {
          pageStateToUse = 'HOME';
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        }
        // if (click === 'MY_PARTY' && userSubgroups.length === 0) {
        //   pageStateToUse = 'NO_PARTY';
        // } else if (click === 'MY_PARTY' && partyId === mainGameId) {
        //   if (defaultPartyName !== 'Pool Party') {
        //     pageStateToUse = defaultPartyId;
        //     setPartyState(defaultPartyName);
        //     setPartyId(defaultPartyId);
        //   } else {
        //     pageStateToUse = firstSubgroupId;
        //     setPartyState(firstSubgroupName);
        //     setPartyId(firstSubgroupId);
        //   }
        // }
        if (click === 'REWARDS') {
          pageStateToUse = 'ELIGIBLE';
        }
    
        if (click === 'PRESENTED_BY') {
          pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
        }
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
      }

    return (
        <div>
            <h1>CFB Super Pickem Container</h1>
            <AzureStuffDELETE />

            <DynamicPoolHeaderBar
                clickState={clickState}
                navigationText={'Home'}
                headerText={gameName}
                gameAddress={gameAddress}
                gameId={mainGameId}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                setBracketState={setBracketState}
                setPicksToEmpty={true}
            />

            <SuperPickemNav
                setClickState={handleClickState}
                clickState={clickState}
                setSignUpState={setSignUpState}
                doesUserBelong={doesUserBelong}
                isGameOver={isGameOver}
                userSubgroups={userSubgroups}
                sponsorAbbreviation={sponsorAbbreviation}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
            />

            {clickState === 'HOME' && <GameHomePage homePageProps={homePageProps} /> }

            {clickState === 'MY_PICKS' && 
                <SuperPickemMyPicks
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={cfbTeams}
                    names={cfbNames}
                    stadiums={cfbStadiums}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    highestRound={highestRound}
                    picking={picking}
                    gameDescription={gameDescription}
                    pickState={pickState}
                    setPickState={setPickState}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    interval={interval}
                    setInterval={setInterval}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    league={league}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    picksThisIntervalInState={picksThisIntervalInState}
                    season={season}
                    logo={logo}
                    gatherLogo={gatherLogoCFB}
                    gatherStadiumInfo={gatherStadiumInfoCFB}
                    gatherDisplayInfo={gatherDisplayInfoCFB}
                    gatherScores={gatherScoresCFB}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayCFB}
                />
            }

            {clickState === 'MY_PARTY' &&
                <MyParty
                    gameData={gameData}
                    myUserId={myUserId}
                    handleCreateSubgroup={handleCreateSubgroup}
                    userSubgroups={userSubgroups}
                    signUpState={signUpState}
                    setSignUpState={setSignUpState}
                    gameAddress={gameAddress}
                    leaderboardData={leaderboardData}
                    partyState={partyState}
                    partyId={partyId}
                    defaultPartyId={defaultPartyId}
                    fullLeaderboard={leaderboardData}
                    doesUserBelong={doesUserBelong}
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    isGameOver={isGameOver}
                />    
            }

            {clickState === 'MY_BRACKET' &&
                <SuperPickemMyBracket />
            }

            {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    sponsorName={sponsorName}
                    sponsorLogo={sponsorLogo}
                    mainGameId={mainGameId}
                    joinModalText={gameData?.joinModalText}
                />
            )}
        </div>
    )
}

export default CFBSuperPickemContainer;