import React from "react";
import { Typography, Box } from '@mui/material';
import './PillBox.scss';

const PercentagePillBoxWithText = ({ count, total, result }) => {
    const percentage = total > 0 ? (count / total) * 100 : 0;

    // Format the percentage
    const formattedPercentage = Number.isInteger(percentage)
        ? percentage
        : percentage.toFixed(1);

    // Normalize result for case-insensitive comparison
    const normalizedResult = result?.toLowerCase(); // Convert to lowercase

    // Determine border color based on result
    const borderColor = normalizedResult === 'win' 
        ? '#00AA72' 
        : normalizedResult === 'loss' 
            ? '#CC293C' 
            : '#002129';
    
    // Background color for the filled section
    const bgColor = normalizedResult === 'win' 
        ? '#00AA72' 
        : normalizedResult === 'loss' 
            ? '#CC293C' 
            : '#002129';

    return (
        <Box className="pill-box-with-text-container">
            {/* Only render left side if it has some percentage */}
            {percentage > 0 && (
                <Box 
                    className="pill-box-with-text-left-half-container"
                    sx={{
                        borderRadius: percentage === 100 ? '8px' : '8px 0px 0px 8px',
                        border: `1px solid ${borderColor}`,
                        width: `${percentage}%`,
                        backgroundColor: bgColor,
                    }}
                >
                    <Typography className="pill-box-with-text-left-half-text">
                        {percentage >= 50 ? `${count} (${formattedPercentage}%)` : ''}
                    </Typography>
                </Box>
            )}
            
            {/* Only render right side if it has some percentage */}
            {percentage < 100 && (
                <Box
                    className="pill-box-with-text-right-half-container"
                    sx={{
                        borderRadius: percentage === 0 ? '8px' : '0px 8px 8px 0px',
                        border: `1px solid ${borderColor}`,
                        width: `${100 - percentage}%`,
                    }}
                >
                    <Typography
                        className="pill-box-with-text-right-half-text"
                        sx={{
                            color: borderColor
                        }}
                    >
                        {percentage < 50 ? `${count} (${formattedPercentage}%)` : ''}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default PercentagePillBoxWithText;