// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-pick-pick-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.quick-pick-pick-stats-button {
  width: 108px;
  height: 33px;
  white-space: nowrap;
  background-color: white !important; /* Ensure background stays white */
  border: 1px solid #9D9D9D !important; /* Ensure the border is always visible */
  color: #9D9D9D !important; /* Ensure text color stays #9D9D9D */
  cursor: pointer;
}
.quick-pick-pick-stats-button:hover {
  background-color: white !important; /* Force background to stay white on hover */
  color: #9D9D9D !important; /* Ensure text color stays #9D9D9D on hover */
  border: 1px solid #9D9D9D !important; /* Ensure border stays the same on hover */
}
.quick-pick-pick-stats-button.active {
  background-color: #E8E8E8 !important; /* Active state background */
  border: 1px solid #002129 !important; /* Active state border */
  color: #002129 !important; /* Active state text color */
}`, "",{"version":3,"sources":["webpack://./src/components/QuickPickGames/PickStats/QuickPickPickStats.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,kCAAA,EAAA,kCAAA;EACA,oCAAA,EAAA,wCAAA;EACA,yBAAA,EAAA,oCAAA;EACA,eAAA;AACJ;AACI;EACE,kCAAA,EAAA,4CAAA;EACA,yBAAA,EAAA,6CAAA;EACA,oCAAA,EAAA,0CAAA;AACN;AAEI;EACE,oCAAA,EAAA,4BAAA;EACA,oCAAA,EAAA,wBAAA;EACA,yBAAA,EAAA,4BAAA;AAAN","sourcesContent":[".quick-pick-pick-stats-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 40px;\n    margin-top: 24px;\n    margin-bottom: 24px;\n  }\n  \n  .quick-pick-pick-stats-button {\n    width: 108px;\n    height: 33px;\n    white-space: nowrap;\n    background-color: white !important; /* Ensure background stays white */\n    border: 1px solid #9D9D9D !important; /* Ensure the border is always visible */\n    color: #9D9D9D !important; /* Ensure text color stays #9D9D9D */\n    cursor: pointer;\n  \n    &:hover {\n      background-color: white !important; /* Force background to stay white on hover */\n      color: #9D9D9D !important; /* Ensure text color stays #9D9D9D on hover */\n      border: 1px solid #9D9D9D !important; /* Ensure border stays the same on hover */\n    }\n  \n    &.active {\n      background-color: #E8E8E8 !important; /* Active state background */\n      border: 1px solid #002129 !important; /* Active state border */\n      color: #002129 !important; /* Active state text color */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
