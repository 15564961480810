import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetReceipt.scss';

const BetReceiptUnsuccessfulBet = ({ betReceiptInfo }) => {
    const { saveSuccess, picksToSave, gamesThatChanged, gamesThatStarted, gamesUnavailable, groupBetOdds, groupBetPayout, betStyle } = betReceiptInfo;
   
    const howManyBetsNotPlaced = gamesUnavailable?.length + gamesThatChanged?.length + gamesThatStarted?.length;
    const teaserPoints = picksToSave?.map(pick => pick.teaserPoints);
    const uniqueTeaserPoints = [...new Set(teaserPoints)];
    console.log("uniqueTeaserPoints: ", uniqueTeaserPoints);
    console.log("Group Bet Odds: ", groupBetOdds);

    return (
        <Box className="sportsbook-bet-receipt-unsuccessful-container">
            {/* Left Side: Parlay or Teaser Info */}
            <Box className="sportsbook-bet-receipt-unsuccessful">
                {betStyle !== 'straight' &&
                    <Typography className="sportsbook-bet-receipt-unsuccessful-text">Your bet could NOT be placed.</Typography>
                }
                {betStyle === 'straight' &&
                    <Typography className="sportsbook-bet-receipt-unsuccessful-text">{howManyBetsNotPlaced} bet{howManyBetsNotPlaced > 1 ? 's' : ''} could NOT be placed.</Typography>
                }
            </Box>
        </Box>
    );
};

export default BetReceiptUnsuccessfulBet;