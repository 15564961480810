import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useBirdieContext } from "../context/BirdiePoolContext";
import "./BirdieRules.scss"; // Adjust the path if necessary
import ChartiersCCBirdieRules from "./ClubRules/ChartiersCC";
import HickoryHeightsBirdieRules from "./ClubRules/HickoryHeights";
import PoolPartyDemoRules from "./ClubRules/PoolPartyDemoRules";

const BirdieRules = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { clubName } = useBirdieContext();

  return (
    <Box className="rules-container">
      {clubName === "Chartiers Country Club" && <ChartiersCCBirdieRules />}
      {clubName === "Hickory Heights" && <HickoryHeightsBirdieRules />}
      {clubName === "Pool Party" && <PoolPartyDemoRules />}

      {/* <Typography variant="h4" className="club-headers" style={{ marginTop: '35px' }}>
        Pool Party Extras
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        <span style={{ fontWeight: 'bold' }}>Private Parties</span> - In addition to the club-wide
        pool, you can create a competition with your custom group.
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '20px', marginBottom: '30px'}}>
        <span style={{ fontWeight: 'bold' }}>Total Birdies</span> - Beyond tiebreakers,
        feel free to implement Total Birdies into your My Party Pools.
      </Typography> */}
    </Box>
  );
}

export default BirdieRules;
