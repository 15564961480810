import React, { useState } from "react";
import { Button, Modal, Typography, Box, Checkbox } from "@mui/material";
import "./WarningModal.scss";

const WarningModal = ({ 
        warningModalOpen, 
        setWarningModalOpen, 
        dontShowAgain, 
        setDontShowAgain, 
        setWarningSubmitted, 
        warningModalText,
    }) => {


    
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleCheckboxChange = (event) => {
        setDontShowAgain(event.target.checked);
    };

    const handleSubmitClick = () => {
        setWarningSubmitted(true);
        setSubmitDisabled(true);
    };

    return (
        <Modal
            open={warningModalOpen}
            onClose={() => setWarningModalOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box className="modal-warning-container">
                <Typography variant="h1" className="modal-warning-header">
                    {warningModalText?.header}
                </Typography>
                {warningModalText?.lines?.map((line, index) => (
                    <Typography
                        key={index}
                        variant="body1"
                        className="modal-warning-text"
                        dangerouslySetInnerHTML={{ __html: line }} // This allows the use of <span> tags in the string
                    />
                ))}

                <Box className="modal-warning-checkbox-container">
                    <Checkbox
                        checked={dontShowAgain}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'do not show again checkbox' }}
                        sx={{ width: '24px', height: '24px' }}
                    />
                    <Typography variant="body2" className="modal-warning-checkbox-text">Do not show this message again.</Typography>
                </Box>

                <Box className="modal-warning-button-container">
                    <Button
                        variant="contained"
                        onClick={() => setWarningModalOpen(false)}
                        disabled={submitDisabled}
                        className="modal-warning-cancel-button"
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmitClick}
                        disabled={submitDisabled}
                        className="modal-warning-submit-button"
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default WarningModal;
