import React from 'react';
import { Box, Typography, TextField, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CreateABracketGame = ({
    groupNameError,
    setGroupNameError,
    groupName,
    setGroupName,
    order
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    
    // Handle input change
    const handleGroupNameChange = (event) => {
        const input = event.target.value;
        setGroupName(input);
        
        // Validate input length
        if (input.length > 40) {
            setGroupNameError(true);
        } else {
            setGroupNameError(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '15px' : '20px',
                    fontWeight: '700',
                    marginBottom: '10px',
                    textAlign: 'left',
                }}
            >
                {order}. Pool Name (Max 40 characters)
            </Typography>
            
            <TextField
                fullWidth
                label="Pool Name"
                variant="outlined"
                value={groupName}
                onChange={handleGroupNameChange}
                error={groupNameError}
                inputProps={{ maxLength: 100 }} // Allow typing beyond 40 to show error state
                sx={{ 
                    marginTop: '8px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: groupNameError ? 'red' : 'inherit',
                        },
                        '&.Mui-error fieldset': {
                            borderColor: 'red',
                        },
                    },
                }}
            />
            
            {groupNameError && (
                <FormHelperText error>
                    Group name cannot exceed 40 characters. Current length: {groupName.length}
                </FormHelperText>
            )}
        </Box>
    );
};

export default CreateABracketGame;