import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCommunityHubContext } from '../../CommunityHub/context/CommunityHubContext';
import { useNavigate } from 'react-router-dom';

const CommunityHubNavBar = () => {
    const {
        navBarInfo,
        organizationId,
        page,
    } = useCommunityHubContext();
    
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Early return if no navBarInfo
    if (!navBarInfo || !navBarInfo.navBarOptions) {
        console.log("No navBarInfo found, returning null");
        return null;
    }

    const handleNavClick = (navPage) => {
        const queryParams = new URLSearchParams();
        queryParams.set('oId', organizationId);
        queryParams.set('page', navPage);
        navigate(`?${queryParams.toString()}`);
    }

    return (
        <Box
            sx={{
                margin: '0 auto',
                //borderBottom: `4px solid ${navBarInfo.navBarBorderColor}`,
                backgroundColor: navBarInfo.navBarBackgroundColor || '#07356B',
                marginBottom: '32px'
            }}
        >
            <Box
                sx={{
                    width: isMobile ? '98%' : '90%',
                    maxWidth: '960px',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: navBarInfo.navBarOptions.length === 1 ? 'center' : 'space-evenly',
                    alignItems: 'center',
                    gap: '4px',
                }}
            >
                {navBarInfo.navBarOptions.map((item, index) => (
                    <Box
                        key={`${item.page}-${index}`}
                        onClick={() => handleNavClick(item.page)}
                        sx={{
                            cursor: 'pointer',
                            color: page === item.page 
                                ? navBarInfo.navBarActiveTextColor
                                : navBarInfo.navBarTextColor,
                            backgroundColor: page === item.page
                                ? navBarInfo.navBarActiveBackgroundColor
                                : navBarInfo.navBarBackgroundColor,
                            marginTop: '4px',
                            marginBottom: '4px',
                            padding: '4px',
                            border: `1px solid ${navBarInfo.navBarBorderColor}`,
                            borderRadius: '4px',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                                opacity: 0.8
                            },
                            width: isMobile ? '100%' : 'auto',
                            maxWidth: '33%',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: 700,
                                whiteSpace: 'nowrap',
                                padding: isMobile ? '0px' : '0px 24px'
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default CommunityHubNavBar;