// NCAA Men's Survivor Card Formatting
import { getTeamName } from '../../SportsData/GetTeamName';

export const findTeamRow = (home, game, league, teams) => {
    const seed = home ? game?.HomeTeamSeed : game?.AwayTeamSeed;
    const name = getTeamName(league, home ? game?.GlobalHomeTeamID : game?.GlobalAwayTeamID, teams);
    
    return `(${seed}) ${name}`;
};

export const findBottomLeftText = (round, bracketName) => {
    if (round <= 4) {
        return `${bracketName} - Round ${round}`;
    } else if (round === 5) {
        return `Final Four`;
    } else if (round === 6) {
        return `Championship`;
    }
};

export const findBottomRightText = (homeKey, spread) => {
    if (spread === null || spread === undefined) {
        return "No Line";
    } else if (spread === 0) {
        return "PK";
    } else {
        const formattedSpread = spread > 0 ? `+${spread}` : spread;
        return `${homeKey} ${formattedSpread}`;
    }
};
