import React, { useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import TextOptInModal from '../../GameModals/TextOptInModal/TextOptInModal';

const TextMessageReminder = () => {
    const [textOptInModalOpen, setTextOptInModalOpen] = useState(false);

    const handleTextOptInClick = () => {
        setTextOptInModalOpen(true);
    }

    return (
        <>
            <Box>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        color: '#002129',
                        marginTop: '20px',
                    }}
                >
                    PICK REMINDER?
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: '20px',
                    }}
                    onClick={handleTextOptInClick}
                >
                    TEXT MESSAGE PICK REMINDERS?
                </Button>
            </Box>

            <TextOptInModal
                textOptInModalOpen={textOptInModalOpen}
                setTextOptInModalOpen={setTextOptInModalOpen}
            />
        </>
    );
};

export default TextMessageReminder;