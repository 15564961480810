import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const HowToPlay = ({
    doesUserBelong,
    hasJoinDeadlinePassed,
    setJoinOpen,
    mainGameId,
    gameAddress,
    howToPlayInfo,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleJoinClick = () => {
        //console.log("Setting Join Open True")
        setJoinOpen(true);
    }

    const handleButtonClick = (buttonText) => {
        if (buttonText === "MY BRACKET") {
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("MY_BRACKET")}/${uriEncoded(mainGameId)}`);
        } else if (buttonText === "MY PICKS") {
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("MY_PICKS")}/${uriEncoded("CURRENT")}`);
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: doesUserBelong ? '#002129' : '#fff',
                padding: '16px 0px',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: doesUserBelong ? '#fff' : '#002129',
                    marginBottom: '16px',
                }}
            >
                HOW TO PLAY
            </Typography>

            {howToPlayInfo?.tagline?.map((tagline, index) => (
                <Typography
                    key={index}
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        color: doesUserBelong ? '#fff' : '#002129',
                        width: '80%',
                        textAlign: 'center',
                        margin: '0 auto',
                        marginBottom: '8px',
                    }}
                >
                    {tagline}
                </Typography>
            ))}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: isMobile ? '20px' : '40px',
                    padding: '8px 0px',
                }}
            >
                {!hasJoinDeadlinePassed && !doesUserBelong && (
                    <Button
                        variant="contained"
                        onClick={() => handleJoinClick()}
                        sx={{
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '700',
                            width: isMobile ? '90px' : '120px',
                            height: '36px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        JOIN POOL
                    </Button>
                )}

                {doesUserBelong && 
                    howToPlayInfo?.buttons?.map((buttonText, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            sx={{
                                fontSize: isMobile ? '12px' : '14px',
                                fontWeight: '700',
                                width: isMobile ? '90px' : '120px',
                                height: '36px',
                                whiteSpace: 'nowrap',
                            }}
                            onClick={() => handleButtonClick(buttonText)}
                        >
                            {buttonText}
                        </Button>
                    ))
                }

                <Button
                    variant="contained"
                    onClick={() => { navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("RULES")}/${uriEncoded("RULES")}`); }}
                    sx={{
                        fontSize: isMobile ? '12px' : '14px',
                        fontWeight: '700',
                        width: isMobile ? '90px' : '120px',
                        height: '36px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    POOL RULES
                </Button>
            </Box>

            {howToPlayInfo?.howToPlay?.map((section, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        padding: '0px 32px',
                        marginTop: '16px',
                        maxWidth: '750px',
                        margin: '0 auto',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: '700',
                            color: doesUserBelong ? '#fff' : '#002129',
                            marginBottom: '8px',
                            marginTop: '16px',
                        }}
                    >
                        {section?.header}
                    </Typography>

                    {section?.lines?.map((line, index) => (
                        <Typography
                            key={index}
                            sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '500',
                                color: doesUserBelong ? '#fff' : '#002129',
                                marginBottom: '8px',
                            }}
                        >
                            {line}
                        </Typography>
                    ))}
                </Box>
            ))}
        </Box>
    );
}

export default HowToPlay;
