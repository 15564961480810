import React, { useState, useEffect } from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useBracketGamesContext } from "../context/BracketGamesContext";
import { Typography, Box, Grid } from "@mui/material";
import PoolPicksMatchupBox from "./PoolPicksMatchupBox";
import CheckboxBanner from "../../GamePageComponents/CheckboxBanner/CheckboxBanner";
import { useSearchParams, useNavigate } from "react-router-dom";

const PoolPicksMatchups = ({ selectedRound , poolPicksData }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {
        tournamentSchedule,
        teams,
    } = useBracketGamesContext();

    const {
        league,
    } = useUserAndGameContext();

    const regionOptions = [
        tournamentSchedule?.LeftTopBracketConference,
        tournamentSchedule?.LeftBottomBracketConference,
        tournamentSchedule?.RightTopBracketConference,
        tournamentSchedule?.RightBottomBracketConference,
    ].filter(Boolean);

    const [selectedRegion, setSelectedRegion] = useState(null);

    useEffect(() => {
        if (regionOptions.length > 0) {
            const urlRegion = searchParams.get('region');
            if (!urlRegion || !regionOptions.includes(urlRegion)) {
                // No valid region in URL, set first region option
                setSelectedRegion(regionOptions[0]);
                const currentParams = Object.fromEntries(searchParams.entries());
                navigate({
                    search: new URLSearchParams({
                        ...currentParams,
                        region: regionOptions[0]
                    }).toString()
                }, { replace: true });
            } else {
                // Valid region in URL, just set it
                setSelectedRegion(urlRegion);
            }
        }
    }, [regionOptions, searchParams, navigate]);

    //console.log("Selected Region: ", selectedRegion);

    // Filter games by round and region
    const filteredGames = poolPicksData?.rounds[selectedRound]?.filter(
        game => game.region === selectedRegion
    ) || [];

    const handleRegionChange = (newRegion) => {
        setSelectedRegion(newRegion);
        const currentParams = Object.fromEntries(searchParams.entries());
        navigate({
            search: new URLSearchParams({
                ...currentParams,
                region: newRegion
            }).toString()
        });
    };


    return (
        <Box>
            <CheckboxBanner
                regionOptions={regionOptions}
                selectedRegion={selectedRegion}
                onRegionChange={handleRegionChange}
            />
            <Typography
                sx={{
                    fontSize: '32px',
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#002129',
                    margin: '16px 0px 24px 0px',
                }}
            >
                The {selectedRegion} Region
            </Typography>
            <Box 
                sx={{ 
                    maxWidth: '1200px',  // Limit max width for centering
                    margin: '0 auto',    // Center the container
                    padding: { xs: '8px 2px', md: '0 16px' } // Add padding on sides
                }}
            >
                <Grid 
                    container 
                    spacing={{ xs: 0, md: 3 }} // 18px on mobile, 54px on desktop
                    columns={2}  // Force 2 columns
                >
                    {filteredGames?.map((game, index) => (
                        <Grid 
                            item 
                            xs={1}  // Take up 1 of 2 columns
                            key={index}
                        >
                            <PoolPicksMatchupBox 
                                game={game} 
                                league={league}
                                teams={teams}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default PoolPicksMatchups;