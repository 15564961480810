import React from "react";
import { Box, Typography } from "@mui/material";
import TeamsAvailableBox from "./TeamsAvailableBox";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NFLTeamsByConference from "./NFLTeamsByConference";
import { DateTime } from "luxon";

const SurvivorDivisionBox = ({ conference, division, usersPicks, startingWeekState, areTheseMyStats, currentWeeksDeadlineDateTime }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //console.log("Users Picks: ", usersPicks);

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    //console.log("Current Week Deadline",currentWeeksDeadlineDateTime, "Time Now in New York:", timeNowInNewYork);

    //const dummyTimeForTesting = DateTime.fromISO('2024-07-29T13:00:00.000-04:00', { zone: 'America/New_York' });

    const hasThisWeeksDeadlinePassed = currentWeeksDeadlineDateTime ? timeNowInNewYork > currentWeeksDeadlineDateTime : false;
    //const hasThisWeeksDeadlinePassedDummy = timeNowInNewYork > dummyTimeForTesting;
    //console.log("Has This Week's Deadline Passed?", hasThisWeeksDeadlinePassed, "Has This Week's Deadline Passed Dummy?", hasThisWeeksDeadlinePassedDummy);

    function didUserPickTeam(teamId) {
        const pick = usersPicks?.find(pick => pick.teamId === teamId);
        if (!pick) {
            return null;
        }
    
        const { week, result, dateTime } = pick;
    
        if (areTheseMyStats) {
            return { week, result };
        }
    
        // Check if the pick is valid based on the current week state and deadlines
        const isFuturePick = week > startingWeekState;
        const isPendingCurrentWeekPick = 
            week === startingWeekState && 
            !hasThisWeeksDeadlinePassed && 
            DateTime.fromISO(dateTime).setZone('America/New_York') > timeNowInNewYork;
    
        if (isFuturePick || isPendingCurrentWeekPick) {
            return null;
        }
    
        return { week, result };
    }
    
   
    // Find the teams based on the conference and division
    const conferenceData = NFLTeamsByConference.find(c => c.Conference === conference);
    const divisionData = conferenceData?.Divisions[division]?.Teams || [];

    return (
        <Box className="survivor-division-box">
            <Box>
                <Typography 
                    sx={{
                        color: '#002129',
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '16px' : '20px',
                        fontWeight: 700,
                    }}
                >
                    {division}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: isMobile ? '8px' : '16px',
                }}
            >
                {divisionData.map(team => {
                    const pickDetails = didUserPickTeam(team.GlobalTeamID) || {};
                    return (
                        <TeamsAvailableBox 
                            key={team.GlobalTeamID} 
                            logo={team.WikipediaLogoURL} 
                            result={pickDetails.result || null} // Default to 'Pending' if no result
                            weekPicked={pickDetails.week || null} // Default to null if no pick week
                        />
                    );
                })}
            </Box>      
        </Box>
    );
};

export default SurvivorDivisionBox;
