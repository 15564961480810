import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BetSlipHeader from "./BetSlipComponents/BetSlipHeader";
import BetTypeBar from "./BetSlipComponents/BetTypeBar";
import WhatIsABet from "./BetSlipComponents/WhatIsABet";
import RemoveBets from "./BetSlipComponents/RemoveBets";
import ParlayTeaserInfo from "./BetSlipComponents/ParlayTeaserInfo";
import BetUnavailableMessage from "./BetSlipComponents/BetUnavailableMessage";
import SingleBetBox from "./BetSlipComponents/SingleBetBox";
import ParlayBetBox from "./BetSlipComponents/ParlayBetBox";
import TeaserBetBox from "./BetSlipComponents/TeaserBetBox";
import BetSlipSubmitButton from "./BetSlipComponents/BetSlipSubmitButton";
import AcceptAllLineChangesCheckbox from "./AcceptAllChangesCheckbox";

// Importing utility functions from utils.js
import {
    calculateParlayOdds,
    hasConflictingBets,
    hasValidBets,
    hasMixedSports,
    hasInvalidSports
} from '../../FantasySportsbook/utils/sportsbookUtils';

const BetSlip = ({
    pickState,
    setPickState,
    currentGameInterval,
    setBetReceiptInfo,
    betSubmitOpen,
    setBetSubmitOpen,
    stopPicksWarning,
    warningModalText,
    bankroll,
    betStyles,
    maxParlayLegs,
    maxTeaserLegs,
}) => {

    const [betSlipOpen, setBetSlipOpen] = useState(true);
    const [chosenBetStyle, setChosenBetStyle] = useState('straight');
    const [submitBetActive, setSubmitBetActive] = useState(false);
    const [bankrollExceeded, setBankrollExceeded] = useState(false);
    const [parlayBankrollExceeded, setParlayBankrollExceeded] = useState(false);
    const [teaserBankrollExceeded, setTeaserBankrollExceeded] = useState(false);
    const [parlayWager, setParlayWager] = useState('');
    const [parlayToWin, setParlayToWin] = useState('');
    const [parlayOdds, setParlayOdds] = useState('');
    const [teaserWager, setTeaserWager] = useState('');
    const [teaserToWin, setTeaserToWin] = useState('');
    const [selectedTeaserValue, setSelectedTeaserValue] = useState('');
    const [teaserPointValue, setTeaserPointValue] = useState('');
    const [teaserAmericanOdds, setTeaserAmericanOdds] = useState(0);
    const [parlayAvailable, setParlayAvailable] = useState(false);
    const [parlayUnavailableHeader, setParlayUnavailableHeader] = useState('More Legs Required');
    const [parlayUnavailableMessage, setParlayUnavailableMessage] = useState('A parlay needs to have 2 or more legs. \nAdd selections to build your parlay!');
    const [teaserAvailable, setTeaserAvailable] = useState(false);
    const [teaserUnavailableHeader, setTeaserUnavailableHeader] = useState('More Legs Required');
    const [teaserUnavailableMessage, setTeaserUnavailableMessage] = useState('A teaser needs to have 2 or more legs. \nAdd selections to build your teaser!');
    const [acceptAllChanges, setAcceptAllChanges] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isNotDesktop = useMediaQuery('(max-width:959px)');

    const totalWager = useMemo(() => {
        return pickState?.reduce((acc, bet) => {
            const wager = parseFloat(bet.wager) || 0;
            return acc + wager;
        }, 0).toFixed(2);
    }, [pickState]);

    // Check if the total wagers on straight bets exceed the bankroll
    useEffect(() => {
        if (totalWager > bankroll) {
            setBankrollExceeded(true);
        } else {
            setBankrollExceeded(false);
        }
    }, [totalWager, bankroll]);

    // Check if the parlay wager exceeds the bankroll
    useEffect(() => {
        if (parlayWager > bankroll) {
            setParlayBankrollExceeded(true);
        } else {
            setParlayBankrollExceeded(false);
        }
    }, [parlayWager, bankroll]);

    // Check if the teaser wager exceeds the bankroll
    useEffect(() => {
        if (teaserWager > bankroll) {
            setTeaserBankrollExceeded(true);
        } else {
            setTeaserBankrollExceeded(false);
        }
    }, [teaserWager, bankroll]);

    const totalPayout = useMemo(() => {
        return pickState?.reduce((acc, bet) => {
            const toWin = parseFloat(bet.toWin) || 0;
            return acc + toWin;
        }, 0).toFixed(2);
    }, [pickState]);

    // Control the submit bet button state based on bankroll and availability checks
    useEffect(() => {
        if (
            (chosenBetStyle === 'straight' && totalWager > 0 && !bankrollExceeded) ||
            (chosenBetStyle === 'parlay' && parlayWager > 0 && !parlayBankrollExceeded && parlayAvailable) ||
            (chosenBetStyle === 'teaser' && teaserWager > 0 && !teaserBankrollExceeded && teaserAvailable)
        ) {
            setSubmitBetActive(true);
        } else {
            setSubmitBetActive(false);
        }
    }, [chosenBetStyle, totalWager, bankrollExceeded, parlayWager, parlayBankrollExceeded, teaserWager, teaserBankrollExceeded, parlayAvailable, teaserAvailable]);

    // Reset parlay wager when parlay is unavailable
    useEffect(() => {
        if (!parlayAvailable && parlayWager > 0) {
            setParlayWager('');
            setParlayToWin('');
        }
    }, [parlayAvailable]);

    // Reset teaser wager when teaser is unavailable
    useEffect(() => {
        if (!teaserAvailable && teaserWager > 0) {
            setTeaserWager('');
            setTeaserToWin('');
        }
    }, [teaserAvailable]);

    // Checking for Parlay Availability
    useEffect(() => {
        let tooManyBets = false;
        if (maxParlayLegs > 0) {
            tooManyBets = pickState?.length > maxParlayLegs;
        }

        if (pickState?.length >= 2 && !tooManyBets && !hasConflictingBets(pickState)) {
            setParlayAvailable(true);
        } else {
            setParlayAvailable(false);
            if (pickState?.length < 2) {
                setParlayUnavailableHeader('More Legs Required');
                setParlayUnavailableMessage('A parlay needs to have 2 or more legs. \nAdd selections to build your parlay!');
            } else if (tooManyBets) {
                setParlayUnavailableHeader('Too Many Legs');
                setParlayUnavailableMessage(`A parlay can have a maximum of ${maxParlayLegs} legs.`);
            } else {
                setParlayUnavailableHeader('Parlay Ineligible Picks');
                setParlayUnavailableMessage('Your current selections cannot be parlayed.');
            }
        }
    }, [pickState, maxParlayLegs]);

    // Checking for Teaser Availability
    useEffect(() => {
        let tooManyBets = false;
        if (maxTeaserLegs > 0) {
            tooManyBets = pickState?.length > maxTeaserLegs;
        }

        if (
            pickState?.length >= 2 &&
            hasValidBets(pickState) &&
            !tooManyBets &&
            !hasMixedSports(pickState) &&
            !hasConflictingBets(pickState)
        ) {
            setTeaserAvailable(true);
        } else {
            setTeaserAvailable(false);

            if (pickState?.length < 2) {
                setTeaserUnavailableHeader('More Legs Required');
                setTeaserUnavailableMessage('A teaser needs to have 2 or more legs. \nAdd selections to build your teaser!');
            } else if (tooManyBets) {
                setTeaserUnavailableHeader('Too Many Legs');
                setTeaserUnavailableMessage(`A teaser can have a maximum of ${maxTeaserLegs} legs.`);
            } else if (!hasValidBets(pickState)) {
                setTeaserUnavailableHeader('Teaser Ineligible Picks');
                setTeaserUnavailableMessage('Your current selections cannot be placed in a teaser.');
            } else if (hasConflictingBets(pickState)) {
                setTeaserUnavailableHeader('Conflicting Bets');
                setTeaserUnavailableMessage('You have conflicting Spread or Total bets from the same game. Please remove conflicting selections.');
            } else if (hasMixedSports(pickState)) {
                setTeaserUnavailableHeader('Mixed Sports Teaser');
                setTeaserUnavailableMessage('Football and Basketball can be teased, but not together. \nPlease select bets from only one sport.');
            } else if (hasInvalidSports(pickState)) {
                setTeaserUnavailableHeader('Ineligible Sports');
                setTeaserUnavailableMessage('Some of your selections include sports that cannot be placed in a teaser.');
            }
        }
    }, [pickState, maxTeaserLegs]);

    const removeBet = (gameId, betType, betChoice) => {
        setPickState(prevState =>
            prevState.filter(bet =>
                !(bet.gameId === gameId && bet.betType === betType && bet.betChoice === betChoice)
            )
        );
    };

    // Calculate parlay odds based on current picks
    useEffect(() => {
        if (chosenBetStyle === 'parlay') {
            setParlayOdds(calculateParlayOdds(pickState));
        }
    }, [pickState, chosenBetStyle]);


        return (
            <>
                {isNotDesktop && betSlipOpen && pickState?.length > 0 && (
                    <Box
                        className="sportsbook-bet-slip-mobile-transparent-overlay"
                        onClick={() => setBetSlipOpen(false)}
                    />
                )}
        
                {(!betSubmitOpen || !isNotDesktop) && (
                    <Box className="sportsbook-bet-slip-container">
                        {(!isNotDesktop || pickState?.length > 0) && (
                            <>
                                <BetSlipHeader
                                    isNotDesktop={isNotDesktop}
                                    betSlipOpen={betSlipOpen}
                                    setBetSlipOpen={setBetSlipOpen}
                                    pickState={pickState}
                                />
                                {pickState?.length === 0 && (<BetUnavailableMessage emptyBetSlip={true} />)}
                            </>
                        )}
        
                        {betSlipOpen && (
                            <>
                                {pickState?.length > 0 && (
                                    <>
                                        <BetTypeBar
                                            chosenBetStyle={chosenBetStyle}
                                            setChosenBetStyle={setChosenBetStyle}
                                            betStyles={betStyles}
                                        />
                                        <WhatIsABet chosenBetStyle={chosenBetStyle} />
        
                                        {/* Scrollable Bet Container */}
                                        <Box className="scrollable-bet-container">
                                            {chosenBetStyle === 'straight' && (
                                                pickState?.map((bet, index) => (
                                                    <SingleBetBox
                                                        key={index}
                                                        bet={bet}
                                                        removeBet={removeBet}
                                                        pickState={pickState}
                                                        setPickState={setPickState}
                                                        bankrollExceeded={bankrollExceeded}
                                                    />
                                                ))
                                            )}
                                            {chosenBetStyle === 'parlay' && (
                                                !parlayAvailable ? (
                                                    <BetUnavailableMessage
                                                        titleMessage={parlayUnavailableHeader}
                                                        subtitleMessage={parlayUnavailableMessage}
                                                    />
                                                ) : (
                                                    <ParlayBetBox
                                                        pickState={pickState}
                                                        setPickState={setPickState}
                                                        removeBet={removeBet}
                                                        bankrollExceeded={parlayBankrollExceeded}
                                                        setParlayWager={setParlayWager}
                                                        wagerState={parlayWager}
                                                        setWagerState={setParlayWager}
                                                        toWinState={parlayToWin}
                                                        setToWinState={setParlayToWin}
                                                        chosenBetStyle={chosenBetStyle}
                                                        betOdds={parlayOdds}
                                                    />
                                                )
                                            )}
        
                                            {chosenBetStyle === 'teaser' && (
                                                !teaserAvailable ? (
                                                    <BetUnavailableMessage
                                                        titleMessage={teaserUnavailableHeader}
                                                        subtitleMessage={teaserUnavailableMessage}
                                                    />
                                                ) : (
                                                    <TeaserBetBox
                                                        pickState={pickState}
                                                        setPickState={setPickState}
                                                        removeBet={removeBet}
                                                        bankrollExceeded={parlayBankrollExceeded}
                                                        setParlayWager={setParlayWager}
                                                        wagerState={teaserWager}
                                                        setWagerState={setTeaserWager}
                                                        toWinState={teaserToWin}
                                                        setToWinState={setTeaserToWin}
                                                        chosenBetStyle={chosenBetStyle}
                                                        betOdds={parlayOdds}
                                                        selectedTeaserValue={selectedTeaserValue}
                                                        setSelectedTeaserValue={setSelectedTeaserValue}
                                                        teaserPointValue={teaserPointValue}
                                                        setTeaserPointValue={setTeaserPointValue}
                                                        teaserAmericanOdds={teaserAmericanOdds}
                                                        setTeaserAmericanOdds={setTeaserAmericanOdds}
                                                    />
                                                )
                                            )}
                                        </Box>
                                        {/* End Scrollable Bet Container */}
        
                                        <AcceptAllLineChangesCheckbox
                                            acceptAllChanges={acceptAllChanges}
                                            setAcceptAllChanges={setAcceptAllChanges}
                                        />
        
                                        <RemoveBets 
                                            setPickState={setPickState} 
                                            setChosenBetStyle={setChosenBetStyle}
                                        />
        
                                        <BetSlipSubmitButton
                                            submitBetActive={submitBetActive}
                                            pickState={pickState}
                                            chosenBetStyle={chosenBetStyle}
                                            totalPayout={totalPayout}
                                            parlayToWin={parlayToWin}
                                            teaserToWin={teaserToWin}
                                            totalWager={totalWager}
                                            parlayWager={parlayWager}
                                            parlayOdds={parlayOdds}
                                            teaserWager={teaserWager}
                                            bankroll={bankroll}
                                            selectedTeaserValue={selectedTeaserValue}
                                            setSelectedTeaserValue={setSelectedTeaserValue}
                                            teaserPointValue={teaserPointValue}
                                            setTeaserPointValue={setTeaserPointValue}
                                            teaserAmericanOdds={teaserAmericanOdds}
                                            setTeaserAmericanOdds={setTeaserAmericanOdds}
                                            acceptAllChanges={acceptAllChanges}
                                            currentGameInterval={currentGameInterval}
                                            setBetReceiptInfo={setBetReceiptInfo}
                                            setBetSubmitOpen={setBetSubmitOpen}
                                            stopPicksWarning={stopPicksWarning}
                                            warningModalText={warningModalText}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                )}
            </>
        );
    
      
}

export default BetSlip;
