import React from 'react';
import GamePageContainer from '../components/gamePage/gamePageContainer';
import {GamePageProvider} from '../components/gamePage/context/gamePageContext';

const GamePage = () => {
  return (
    <GamePageProvider>
      <GamePageContainer />
    </GamePageProvider>
  );
};

export default GamePage;