import React, { useState } from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { Box, Typography } from "@mui/material";
import bracketTrophy from "../../assets/images/bracketTrophy.png";
import UserDashCopyLink from "./UserDashCopyLink";
import UserDashGameHandler from "./UserDashGameHandler";
import './UserDash.scss';
import { useRemoveEntryFromGame } from "../../hooks/pools/useRemoveEntryFromGame";
import RemoveEntryModal from "./RemoveEntryModal";
import ResponseModal from "../MembersPage/ResponseModal";

const UserDash = () => {
    const {
        myUsername,
        masterPoolId,
        gameId,
        gameAddress,
        myUserId,
        gameData,
        hasJoinDeadlinePassed,
        pickIndex,
        usersIndexesInThisPool,
        maxEntriesThisPool,
        isThisPoolPartyGame,
    } = useUserAndGameContext();

    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [indexToRemove, setIndexToRemove] = useState(null);
    const [usernameToRemove, setUsernameToRemove] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalStatus, setModalStatus] = useState('success');
    const [modalMessage, setModalMessage] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    //console.log("Remove Modal Open: ", removeModalOpen, "Index to Remove: ", indexToRemove);

    const { mutate: removeEntryFromGame } = useRemoveEntryFromGame();
    
    const gameType = gameData?.gameType;
    const referralLink = gameData?.referrals?.referralLink;
    const referralLeaderboard = gameData?.referrals?.referralLeaderboard;
    const addEntryNumberToUsername = usersIndexesInThisPool?.length === 1 && Number(pickIndex) !== 0;
    //console.log("referralLink in UserDash: ", referralLink, "referralLeaderboard: ", referralLeaderboard, "addEntryNumberToUsername: ", addEntryNumberToUsername, "Game Type: ", gameType);
    const canIAddAnEntry = (usersIndexesInThisPool?.length < maxEntriesThisPool) && !hasJoinDeadlinePassed;
    //console.log("Can I Add An Entry: ", canIAddAnEntry);

    const gameAdministration = gameData?.gameAdministration;
    const isUserInGameAdministration = (gameAdministration, myUserId) => {
        if (!myUserId || !gameAdministration) return false; // ✅ Return false if myUserId is missing
    
        return Object.values(gameAdministration || {}).some(array => 
            Array.isArray(array) && array.includes(myUserId)
        );
    };
    const userInGameAdministration = isUserInGameAdministration(gameAdministration, myUserId);
    //console.log("User in Game Administration: ", userInGameAdministration);

    const removeEntry = () => {
        setHasSubmitted(true);
    
        const removalData = {
            masterPoolId,
            gameId,
            indexToRemove: indexToRemove,
            userId: myUserId,
            usernameToRemove: usernameToRemove,
        };
        //console.log("Removal Data: ", removalData);
    
        removeEntryFromGame(removalData, {
            onSuccess: (response) => {
                // Handle success
                setRemoveModalOpen(false);
                setIndexToRemove(null);
                setUsernameToRemove(null);
                setModalStatus('success');
                setModalMessage(response?.message || `Entry ${usernameToRemove} has been successfully removed.`);
                setModalOpen(true);
                console.log("Successfully removed entry from game");
            }, 
            onError: (error) => {
                // Handle error
                console.error("Error removing entry from game: ", error);
                setModalStatus('error');
                setModalMessage(
                    error.response?.data?.message || 
                    error.message || 
                    'An error occurred while removing the entry. Please try again.'
                );
                setModalOpen(true);
            }
        });
    };

    return (
        <Box className="user-dash-container">
            <Box className="user-dash-header">
                <img className="user-dash-trophy" src={bracketTrophy} alt='Pool Party Trophy' />
                <Typography className="user-dash-username" variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {myUsername}{addEntryNumberToUsername && `(${Number(pickIndex) + 1})`}
                </Typography>
                {!hasJoinDeadlinePassed && referralLink && (
                    <UserDashCopyLink
                        masterPoolId={masterPoolId}
                        gameId={gameId}
                        gameAddress={gameAddress}
                        myUserId={myUserId}
                        referralLeaderboard={referralLeaderboard}
                        userInGameAdministration={userInGameAdministration}
                    />
                )}
            </Box>
            <UserDashGameHandler 
                gameType={gameType}
                canIAddAnEntry={canIAddAnEntry}
                setIndexToRemove={setIndexToRemove}
                setRemoveModalOpen={setRemoveModalOpen}
                isThisPoolPartyGame={isThisPoolPartyGame}
            />

            <ResponseModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                status={modalStatus}
                message={modalMessage}
            />

            <RemoveEntryModal
                open={removeModalOpen}
                onClose={() => {
                    setRemoveModalOpen(false);
                    setIndexToRemove(null);
                    setUsernameToRemove(null);
                }}
                indexToRemove={indexToRemove}
                myUsername={myUsername}
                gameName={gameData?.gameName}
                removeEntry={removeEntry} // No need for an arrow function if just passing
                setUsernameToRemove={setUsernameToRemove}
                hasSubmitted={hasSubmitted}
            />

        </Box>
    );
}

export default UserDash;