import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';

const CFBTeamAvailableBox = ({ logo, color, abbreviation }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px',
                    paddingLeft: isMobile ? '1px' : '33px',
                    paddingRight: isMobile ? '0px' : '33px',
                    paddingTop: '0px', // Ensure no padding on top
                    paddingBottom: '0px', // Ensure no padding on bottom
                }}
            >
                    <Box
                        component="img"
                        src={logo}
                        alt="Team Logo"
                        sx={{
                            width: isMobile ? '32px' : '48px',
                            maxWidth: isMobile ? '32px' : '48px',
                            height: isMobile ? '32px' : '48px',
                            flexShrink: 0,
                            border: color ? '2px solid #002129' : null,
                            padding: '2px',
                            backgroundColor: color ? '#D6D2D2' : '#FFF',
                            boxSizing: 'border-box',
                            objectFit: 'contain',
                        }}
                    />
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        color: '#002129',
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: 700,
                        whiteSpace: 'nowrap',
                        width: isMobile ? '32px' : '48px',  
                    }}
                >
                    {abbreviation || '\u00A0'}
                </Typography>
            </Box>
        </Box>
    );
}

export default CFBTeamAvailableBox;
