import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useGetUserData } from "../../BirdiePool/hooks/index";
import { useGetPrivatePools } from "../../PrivatePools/hooks/useGetPrivatePools";
import { useGetPublicPools } from "../../PublicPools/hooks/useGetPublicPools";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const MyPoolsPageContext = createContext();

export const useMyPoolsPageContext = () => {
    const context = useContext(MyPoolsPageContext);
    if (!context) {
        throw new Error("useMyPoolsPageContext must be used within a MyPoolsPageProvider");
    }
    return context;
};

const MyPoolsPageProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    const navigateToTop = useNavigateToTop();
    const dispatch = useDispatch();

    useEffect(() => { 
        if (!myUserId) {
            navigateToTop('/login/my-pools');
        }
    }, [myUserId, navigateToTop]);

    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { data: privatePoolsData, error: privateError, isLoading: privateLoading, refetch: refetchPrivatePools } = useGetPrivatePools();
    const { data: publicPoolsData, error: publicError, isLoading: publicLoading, refetch: refetchPublicPools } = useGetPublicPools();

    const [gamesData, setGamesData] = useState([]);
    
    // Unified loading state
    const [isLoading, setIsLoading] = useState(true); 

     // Handle errors and check for 401 Unauthorized
     useEffect(() => {
        if (userError || privateError || publicError) {
            const error = userErrorData || privateError || publicError;
            
            console.log("Error fetching data:", error);

            // If the error status is 401 (Unauthorized)
            if (error.response?.status === 401) {
                dispatch(userLogoutAction({ logoutType: 'token-expired' }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }
    }, [userError, privateError, publicError, userErrorData, privateError, publicError, dispatch, navigateToTop]);

    useEffect(() => {
        //console.log("Running MyPoolsPageProvider useEffect For Combining Data");
        // If userData, privatePoolsData, and publicPoolsData are available, combine the data.
        if (userData && privatePoolsData && publicPoolsData) {
            const userGameIds = userData.Games?.map(game => game.gameID) || [];
            const allPools = [...privatePoolsData, ...publicPoolsData];
            const matchingGames = allPools.filter(pool => userGameIds?.includes(pool.gameID));
            setGamesData(matchingGames);
            setIsLoading(false); // Data fully loaded
        }
    }, [userData, privatePoolsData, publicPoolsData]);

    useEffect(() => {
        //console.log("Running MyPoolsPageProvider useEffect For User Data");
        // Fetch user data when myUserId is available
        if (myUserId) {
            setIsLoading(true); // Set loading to true when fetching starts
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    // Show a loading screen if any part of the data is still being fetched
    if (isLoading || userLoading || privateLoading || publicLoading) {
        return <LoadingScreen />;
    }

    const MyPoolsPageContextValues = {
        myUserId,
        userData,
        gamesData,
    };

    return (
        <MyPoolsPageContext.Provider value={MyPoolsPageContextValues}>
            {children}
        </MyPoolsPageContext.Provider>
    );
}

export default MyPoolsPageProvider;
