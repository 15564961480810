import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import './QuickPickPickStats.scss'; // Import your SCSS file
import QuickPickLeaderboardDropdown from "../Leaderboard/QuickPickLeaderboardDropdown";
import PlayerPicks from "./PlayerPicks";
import PickStatsTab from "./PickStatsTab";
import { DateTime } from "luxon";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

const QuickPickPickStats = ({ 
    leaderboardInfo, 
    teams, 
    schedule, 
    quickPickGames, 
    firstInterval, 
    lastInterval, 
    currentInterval, 
    intervalType, 
    propIdsToUse, 
    gatherDisplayBarInfo, 
    teamsToInclude,
    sponsorName, 
    league,
    myUserId,
    oneWeekEvent,
}) => {
    const [selectedTab, setSelectedTab] = useState('PLAYER_PICKS'); // Default selected tab
    const [picksTabInterval, setPicksTabInterval] = useState(''); // Default interval for the Picks tab

    useEffect(() => {
        if (currentInterval && firstInterval) {
            if (currentInterval < firstInterval) {
                setPicksTabInterval(firstInterval);
            } else if (currentInterval > lastInterval) {
                setPicksTabInterval(lastInterval);
            } else {
                setPicksTabInterval(currentInterval);
            }
        }
    }, [currentInterval, firstInterval, lastInterval]);
    //console.log("Prop Ids To Use Pick Stats", propIdsToUse);
    const selectedQuickPickGames = quickPickGames?.filter((game) => game[intervalType] === picksTabInterval);
    //console.log("Selected Quick Pick Games", selectedQuickPickGames, "Interval Type", intervalType);

    const currentPropIds = propIdsToUse?.filter((entry) => entry.interval === picksTabInterval)[0]?.propIdsToUse || [];
    //console.log("Current Prop Ids in Pick Stats: ", currentPropIds);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const weekOptions = [];
    if (intervalType === 'Week') {
        for (let i = firstInterval; i <= currentInterval; i++) {
            weekOptions.push(
                <MenuItem key={i} value={i}>
                    Week {i}
                </MenuItem>
            );
        }
    }
    //console.log("Week Options", weekOptions);

    const handleTabChange = (tab) => {
    setSelectedTab(tab);
    };

    let findTeamName;

    if (teamsToInclude && teamsToInclude.length > 0) {
        findTeamName = gatherDisplayBarInfo(teamsToInclude[0], teams);
    }    
    const teamName = findTeamName?.teamNickname;


    const assignRanks = (data) => {
        // Sort data in descending order by totalPoints and then by totalMargin
        data?.sort((a, b) => {
          const aPoints = a.totalPoints ?? 0;
          const bPoints = b.totalPoints ?? 0;
          const aMargin = a.totalMargin ?? 0;
          const bMargin = b.totalMargin ?? 0;
      
          // Sort by points first, then by margin (descending for both)
          if (aPoints !== bPoints) {
            return bPoints - aPoints; // Descending order for points
          } 
          return aMargin - bMargin; // Descending order for margin
        });
      
        let rank = 1;
        let tiedRank = 1;
        let previousPoints = data[0]?.totalPoints ?? 0;
        let previousMargin = data[0]?.totalMargin ?? 0;
      
        return data.map((entry, index) => {
          const currentPoints = entry.totalPoints ?? 0;
          const currentMargin = entry.totalMargin ?? 0;
      
          // Determine rank for tied entries
          if (currentPoints === previousPoints && currentMargin === previousMargin) {
            entry.rank = tiedRank;
          } else {
            rank = index + 1; // Update rank to current index + 1
            tiedRank = rank; // Update tiedRank to current rank
            entry.rank = rank; // Assign current rank to entry
          }
      
          // Update previous values for next iteration
          previousPoints = currentPoints;
          previousMargin = currentMargin;
      
          return entry; // Return the updated entry
        });
      };

    function findWeeklyLeaderboard() {
        const selectedWeek = picksTabInterval;
        //console.log("SELECTED WEEK", selectedWeek);
        const weeklyLeaderboard = [];
    
        for (let i = 0; i < leaderboardInfo?.length; i++) {
            const user = leaderboardInfo[i];
            const username = user.username;
            const userId = user.userId;
            const picks = user.picks;
            const tiebreakers = user.tiebreakers;
            //console.log("PICKS SELECTED WEEK", picks);
    
            let wins = 0;
            let losses = 0;
            let points = 0;
            let totalMargin = 5000;
    
            if (picks) {
                for (let j = 0; j < picks.length; j++) {
                    const game = picks[j];
                    if (game.interval === selectedWeek) {
                        const result = game.result;
                        const gamePoints = game.points;
                        const isTiebreaker = game.tiebreaker;
    
                        if (result === 'Win') {
                            wins++;
                            points += gamePoints;
                        } else if (result === 'Loss' && game.teamId !== 'No Pick') {
                            losses++;
                        } 
                        if (isTiebreaker && game.margin) {
                            totalMargin = game.margin;
                        }
                    }
                }
    
            }
    
                // Create entry for the user
                const entry = {
                    username,
                    userId,
                    totalWins: wins,
                    totalLosses: losses,
                    totalPoints: points,
                    picks,
                    totalMargin: totalMargin,
                    tiebreakers,
                };
    
                // Push entry to leaderboard
                weeklyLeaderboard.push(entry);
            
        }
    
        return weeklyLeaderboard;
    }
      const weeklyData = findWeeklyLeaderboard();
      //console.log("WEEKLY LEADERBOARD", weeklyData);
    
      const rankedWeeklyData = assignRanks(weeklyData);
      //console.log("RANKED WEEKLY DATA", rankedWeeklyData);

  return (
    <>
        <Box className="quick-pick-pick-stats-container">

            <Button
                className={`quick-pick-pick-stats-button ${selectedTab === 'PLAYER_PICKS' ? 'active' : ''}`}
                onClick={() => handleTabChange('PLAYER_PICKS')}
            >
                Player Picks
            </Button>

            <Button
                className={`quick-pick-pick-stats-button ${selectedTab === 'PICK_STATS' ? 'active' : ''}`}
                onClick={() => handleTabChange('PICK_STATS')}
            >
                Pick Stats
            </Button>

        </Box>

        {oneWeekEvent && (
            <Typography
            sx={{
              fontSize: isMobile ? '16px' : '20px',
              fontWeight: '700',
              textAlign: 'center',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            Week {picksTabInterval} Picks
          </Typography>
          )}
        {!oneWeekEvent && (
            <QuickPickLeaderboardDropdown
                interval={picksTabInterval}
                setInterval={setPicksTabInterval}
                currentInterval={currentInterval}
                firstInterval={firstInterval}
                lastInterval={lastInterval}
                intervalType={intervalType}
            />
        )}

        {selectedTab === 'PLAYER_PICKS' && (
            <PlayerPicks
                leaderboardInfo={weeklyData}
                teams={teams}
                schedule={schedule}
                selectedQuickPickGames={selectedQuickPickGames}
                interval={picksTabInterval}
                timeNowInNewYork={timeNowInNewYork}
                currentPropIds={currentPropIds}
                sponsorName={sponsorName}
                league={league}
                teamName={teamName}
                myUserId={myUserId}
            />
        )}
        {selectedTab === 'PICK_STATS' && (
            <PickStatsTab
                leaderboardInfo={leaderboardInfo}
                schedule={schedule}
                selectedQuickPickGames={selectedQuickPickGames}
                interval={picksTabInterval}
                timeNowInNewYork={timeNowInNewYork}
                currentPropIds={currentPropIds}
                sponsorName={sponsorName}
                league={league}
                teamName={teamName}
            />
        )}
    </>
  );
};

export default QuickPickPickStats;
