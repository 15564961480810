import { useEffect } from "react";
import { Box, Paper, Button, Typography, Grid, TextField  } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import homeBanner from '../assets/images/hero_background.png';
import trophyImage from '../assets/images/Layer_1.svg';
import bannerContent from '../assets/images/Hero_Contents_Desktop.png';
import { useTheme } from '@mui/material/styles';
import mobileBannerContent from '../assets/images/nba_super_picke_em_hero_text.svg'
import poolPartyLogoStacked from '../assets/images/pool_party_gaming_stacked_logo.svg';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserDataFromLocalStorage } from "../redux/actions/loginImport";
import { useGetUserData } from "../components/BirdiePool/hooks";
import useNavigateToTop from "../hooks/general/useNavigateToTop";
import RedbeardsForHomePage from "../components/HomePageGames/RedbeardsForHomePage";
import KOTNForHomePage from "../components/HomePageGames/KOTNForHomePage";
import NFLBarBattleForHomePage from "../components/HomePageGames/NFLBarBattleForHomePage";
import NFLSuperPickemForHomePage from "../components/HomePageGames/NFLSuperPickemForHomePage";
import NattyQuickPickForHomePage from "../components/HomePageGames/NattyQuickPickForHomePage";
import CountdownClock from "../components/GameHomePage/GameHomePageComponents/CountdownClock";
import Carousel from 'react-material-ui-carousel'
import { useGetHomePageCarousel } from "../hooks/general/useGetHomePageCarousel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EastIcon from '@mui/icons-material/East';
import InfoIcon from '@mui/icons-material/Info';
export default function HomePage() {
  const userData = getUserDataFromLocalStorage();
  const userDataId = getUserDataFromLocalStorage()?.user_id;
  const { mutate: fetchUserData, data: userGamesData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  //console.log("UserGames Data:", userGamesData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (userDataId) {
        fetchUserData(userDataId);
      }
  }, [fetchUserData, userDataId]);

  const { mutate: fetchCarouselData, data: carouselData, isLoading: carouselLoading, isError: carouselError, error: carouselErrorData } = useGetHomePageCarousel();

  useEffect(() => {
    fetchCarouselData();
  }, [fetchCarouselData]);
  // console.log(carouselData);
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }



  const navigateToTop = useNavigateToTop();

  const handleURL = (link) => {
      const url = `/${uriEncoded(link)}`;
      navigateToTop(url);
  }
  const handleRegURL = (link) => {
    navigateToTop(link);
}
  if (!carouselData) {
    return <div>Loading...</div>;
  }

  const { items, partners, players } = carouselData;
  return (
    <Box sx={{width: '100%' }}>
      {/* SNACK BAR */}
      <Box sx={{
        // position: 'fixed',
        top: isMobile? 80 : 136,
        left: 0,
        zIndex: 1000,
        backgroundColor: '#076652',
        width: '100%',
        padding: '16px',
        display: 'flex',
        justifyContent: isMobile? 'center': 'flex-start',
        alignItems: 'center',
        color: 'white',
        fontSize: isMobile? '10px' : '16px',
      }}>
        <InfoIcon sx={{marginRight:'10px'}}/> Pool Party's Majors Tour: Starts Thurs 4/10 in Augusta, GA
      </Box>
      {/* SECTION 1 - HERO BANNER */}
      {/* <Carousel
        navButtonsAlwaysVisible={true}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: 0,
            zIndex: 900,
            pointerEvents: 'auto'
          }
        }}
        indicatorIconButtonProps={{
          style: {
            // The base style for the indicators
            color: 'white',     // inactive indicator color
            opacity: 1        // slightly transparent for inactive
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#444444',     // the text/icon color (if any)
            backgroundColor: 'white', // making the active indicator "filled in"
            borderRadius: '50%',      // ensure it's a circle if desired
            opacity: 1                // make it fully opaque
          }
        }}
        sx={{
          '& .MuiButtonBase-root': {
            color: 'white'
          },
          height: { xs: '506px', sm: '506px', md: '600px', lg: '600px' },
        }}
      > */}
        {/* {carouselData?.map((item, index) => ( */}
          <Box
            // key={index}
            sx={{
              // height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
              display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' },
              alignItems: 'center',
              // minHeight: '600px'
            }}
          >
            <Link to={'/pool-hall'} style={{ display: 'block', flex: '1' }}>
              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  // backgroundImage: !isMobile ? `url(${item.imageDesktop})` : `url(${item.imageMobile})`,
                  // backgroundSize: 'cover',
                  // backgroundPosition: 'center',
                  // backgroundRepeat: 'no-repeat',
                  backgroundColor: 'var(--color-black)',
                  minHeight: '300px',
                  height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
                  padding: '12px',
                }}
              > */}
                {/* <Box sx={{ width: '100%' }}>
                  {!isMobile ? (
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '51px',
                        margin: '0 auto',
                        maxWidth: '1206px'
                      }}
                    >
                      <img
                        src={item.contentDesktop}
                        alt='Logo'
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        margin: '0 auto',
                        padding: '0px',
                        paddingTop: '0px'
                      }}
                    >
                      <img
                        src={item.contentMobile}
                        alt='Logo'
                        style={{
                          width: item.width ? item.width : '100%',
                          height: 'auto',
                          margin: '0px auto',
                          maxWidth: '320px',
                          marginBottom: '30px'
                        }}
                      />
                    </Box>
                  )}
                </Box> */}
                {isMobile ?
                  <img src="https://poolpartyfilestorage.blob.core.windows.net/media/CBB-Tourney-Takeover-Mobile.png" alt="College Basket Ball MM Hero" style={{width:'100%', height: 'auto', display:'block',}}/>
                  :
                  <img src="https://poolpartyfilestorage.blob.core.windows.net/media/CBBTakeOverHeroContents.png" alt="College Basket Ball MM Hero" style={{width:'100%', height: 'auto', display:'block'}}/>
                }
              {/* </Box> */}
            </Link>
          </Box>
          {isMobile?
            <Box sx={{
              background: '#040C16',
              paddingBottom: '64px'
            }}>
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Button
                    sx={{
                      background: '#FFC60A',
                      color: '#1C1C1C',
                      width: '220px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      padding: '10px',
                      border: '1px solid #FFC60A',
                    }}
                    onClick={() => handleRegURL('pool-hall')}
                  >
                    Sitewide Main Event Pools
                  </Button>

                  <Button
                    sx={{
                      background: 'transparent',
                      color: '#fff',
                      width: '220px',
                      border: '1px solid #ffffff',
                      marginBottom: '32px',
                    }}
                    onClick={() => handleRegURL('community-pools')}
                  >
                    Community Pools
                  </Button>
                </Box>
                <Typography sx={{
                  color: '#ffffff',
                  fontWeight:'900',
                  fontSize: '48px',
                  lineHeight: '1.2',
                  fontStyle: 'italic',
                  marginBottom: '32px'
                }}>
                  THE CBB
                  TOURNEY
                  TAKOVER
                </Typography>
                <Typography sx={{
                  color: '#ffffff',
                  fontWeight:'900',
                  fontSize: '32px',
                  lineHeight: '1.2',
                  fontStyle: 'italic',
                  margin: '0 auto 32px auto',
                  width: '220px',

                }}>
                  BRACKETS & BEYOND
                </Typography>
                <Typography sx={{
                   color: '#ffffff',
                   fontWeight:'600',
                   fontSize: '16px',
                   lineHeight: '1.2',
                   fontStyle: 'italic',
                   margin: '0 auto 32px auto',
                   maxWidth: '300px',
                   width: '100%',
                }}>
                The MADNESS has arrived.<br />
                Get yourself skin in the game!
                </Typography>
                <Box sx={{
                  borderRadius: '16px',
                  border: '2px solid #07356B',
                  background: '#071526',
                  maxWidth:'260px',
                  margin:"0 auto 32px auto",
                  padding: '10px',
                }}>
                  <Typography sx={{
                    color: '#FFF',
                    textSlign: 'center',
                    fontSize: '16px',
                    fontWeight: '600',
                    textDecoration:'underline',
                    marginBottom: '16px',
                  }}>
                    Tournament Tip-Off Pools
                  </Typography>
                  <Typography sx={{
                    color: '#FFF',
                    textSlign: 'center',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                    The Men’s Bracket<br />
                    The Women’s Bracket<br />
                    Men’s Survivor Pool
                  </Typography>
                </Box>
                <Box sx={{
                  borderRadius: '16px',
                  border: '2px solid #07356B',
                  background: '#071526',
                  maxWidth:'260px',
                  margin:"0 auto",
                  padding: '16px',
                }}>
                  <Typography sx={{
                    color: '#FFF',
                    textSlign: 'center',
                    fontSize: '16px',
                    fontWeight: '600',
                    textDecoration:'underline',
                    marginBottom: '16px',
                  }}>
                    Home Stretch Pools
                  </Typography>
                  <Typography sx={{
                    color: '#FFF',
                    textSlign: 'center',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                   Men’s Sweet 16 Restart Pool<br />
                    Men’s Final Four Prop Pool<br />
                    Women’s Final Four Prop Pool<br />
                  </Typography>
                </Box>
              </Box>
            </Box>
            :
            <></>
          }
       {/*  ))}
      </Carousel> */}

      {/* SECTION 2 - Featured Games */}
      <Typography
        variant='h1'
        sx={{
          color: 'var(--color-black)',
          fontStyle: 'normal',
          fontWeight: '800',
          lineHeight: '150%',
          textAlign: 'center',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '60px',
          marginBottom: '0',
          letterSpacing: '-0.87px',
          maxWidth: isMobile ? '322px' : '650px',
          fontSize: isMobile ? '28px' : '46px',
        }}
      >
        {isMobile ? (
          <>
            <Typography sx={{
              color: '#373737',
              fontSize: '42px',
              fontWeight: 800,
              textAlign:'left',
              lineHeight: '1.2'
            }} >
              Featured <br /> Games
            </Typography>
          </>
        ) : (
          <span style={{ textDecoration: 'none', position: 'relative' }}>
            Featured Games
          </span>
        )}
      </Typography>
      {/* <Box className="featured-games">

      </Box> */}
      <Box
        sx={{
          width: { xs: '100%', lg: '100%' },
          margin: 'auto',
          textAlign: 'center',

          background: 'linear-gradient(180deg, #FFF 0%, #ECE9E6 100%)',
          padding:'0px 0 64px 0'
        }}
      >
        <Box sx={{ maxWidth: isMobile ? '322px' : '845px', margin: '0 auto' }}>
          <Typography
            variant='h3'
            sx={{
              color: "#4F4E4E",
              textAlign: isMobile? 'left' : "center",
              fontSize: "16px",
              fontWeight: 500,
              marginBottom:'32px'
            }}
          >
          We are delivering 4 Men’s Pools along with 2 Women’s Pools.<br/>
          {isMobile? <span style={{paddingBottom:'16px', display:'block'}}></span>:<></>}
          3 pools launch at the Tournament Tip-Off followed by 3 more pools starting for the Sweet 16 and Final Fours.<br/>
          </Typography>
        </Box>
        <Box sx={{
           width: { xs: '100%', lg: '87%' },
           margin: 'auto',
        }}>
          <Grid container spacing={3}>
            {items.map((item, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{
                  display: "flex",
                  justifyContent: "center", // centers the Paper in the grid cell
                }}
                onClick={() => {
                  if (item?.linkText !== "Coming Soon") {
                    handleRegURL(item.link);
                  }
                }}
              >
                <Paper
                  sx={{
                    width: isMobile? '90%' : "384px", // fixed width
                    p: 2,
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    border:'1px solid #C1C1C1'
                  }}
                >
                  <Box sx={{
                    height: isMobile? "unset" : "65px",
                    margin:'auto'
                    }}>
                    <img src={item.gameImage} alt="" style={{ display: "block",  maxWidth:'250px' }} />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: "30px",
                      color: "#373737",
                      fontSize: "16px",
                      fontWeight: 700,
                      textAlign: "left",
                      marginBottom: '10px'
                    }}
                  >
                    {item.gameTitle}
                  </Typography>
                  <Grid container spacing={0}>
                    <Grid item xs={3} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        Pool Opens
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {item.poolOpens}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={3} sx={{ textAlign: "left" }}>
                      {/* <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        Commitment
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {item.commitment}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={9} sx={{ textAlign: "left", paddingLeft: '10px' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        Prizes
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#373737",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {item.prizes}
                      </Typography>
                    </Grid>
                    <Box className="my-pools-card-button-container">
                    <Button
                        className="my-pools-card-button"
                        sx={{ cursor: item?.linkText === "Coming Soon" ? 'default' : 'pointer' }}
                        onClick={() => {handleRegURL(item.link)}}
                        disabled={item?.linkText === "Coming Soon"}
                    >
                        {item?.linkText}
                        {( item?.linkText !== "Coming Soon") && (
                            <EastIcon sx={{ fontSize: '16px' }} />
                        )}
                        </Button>
                    </Box>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {/* SECTION 3 - CREATE A POOL */}
      <Box
       sx={{
        paddingTop: '32px',
        backgroundColor: '#07356B',

      }}
      >
        <Box sx={{
             width: isMobile? '90%' : '100%',
             margin: '0 auto',
        }}>
        <Typography
          sx={{
            fontSize: isMobile ? '32px' : '56px',
            fontWeight: 800,
            color: '#ffffff',
            textAlign: isMobile? 'left' : "center",
          }}
        >
          Create a Pool
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            marginBottom: '24px',
            maxWidth: '845px',
            color: '#ffffff',
            margin: "0 auto",
            textAlign: isMobile? 'left' : "center",
          }}
        >
          The Create Your Pool feature allows you to create a private pool with your group. Personalize, customize and get ready to battle your people.
        </Typography>
        <Button sx={{
          background: '#FFC60A',
          color: '#1C1C1C',
          padding: '10px 16px',
          margin: '16px auto'
        }}
        onClick={() => {handleRegURL('create-a-pool')}}
        >CREATE YOUR POOL</Button>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile? 'column' : 'row',
            flexFlow: isMobile? 'column-reverse' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '32px 0',
            columnGap: '42px',

          }}
        >
          <Box sx={{
            maxWidth:'558px',
            marginTop: isMobile? '32px': '0',
          }}>
            <ul>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#00AAD1', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700}}>
                    Personalize your pool
                  </Typography>
                  <Typography>
                    Select a unique name, adjust the gameplay settings to your liking, and set your access / invite controls.
                  </Typography>
                </Box>
              </li>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#00AAD1', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700}}>
                    Invite everyone
                  </Typography>
                  <Typography>
                  With the click of a button, invite your friends, family, and even your rivals via text message or email.
                  </Typography>
                </Box>
              </li>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#00AAD1', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700}}>
                    Manage effortlessly
                  </Typography>
                  <Typography>
                    Run the pool solo, or appoint co-hosts who can invite, remove, and track the pool members.
                  </Typography>
                </Box>
              </li>
            </ul>
          </Box>
          <Box>
            <img src="https://poolpartyfilestorage.blob.core.windows.net/media/phone-peeps.png" alt="" />
          </Box>
        </Box>
        </Box>
      </Box>
      {/* SECTION 4 - HOW IT ALL WORKS */}
      <Box
      sx={{
        padding: '32px 0',
      }}
      >
          <Box sx={{
             width: isMobile? '90%' : '100%',
             margin: '0 auto',
        }}>
          <Typography
            sx={{
              fontSize: isMobile ? '32px' : '56px',
              fontWeight: 800,
              color:"#373737",
              textAlign: isMobile? 'left' : "center",
            }}
          >
            How it all works
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              maxWidth: '845px',
              margin: "0 auto 16px auto",
              color:"#4F4E4E",
              width: isMobile? '100%' :  "90%",
              textAlign: isMobile? 'left' : "center",
            }}
          >
            <span style={{fontWeight:700}}>Main Event Pools:</span> The Pool Party Main Events are platform-wide competitions.
            Every user battles it out against the Pool Party Universe.


          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              maxWidth: '845px',
              margin: "0 auto 16px auto",
              color:"#4F4E4E",
              width: isMobile? '100%' :  "90%",
              textAlign: isMobile? 'left' : "center",
            }}
          >
            <span style={{fontWeight:700}}>Community Pools:</span> Community Pools are hosted by establishments of all kinds.
              Bars/Restaurants, Fundraisers, Businesses, Creators and more.

          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              maxWidth: '845px',
              margin: "0 auto 16px auto",
              color:"#4F4E4E",
              width: isMobile? '100%' :  "90%",
              textAlign: isMobile? 'left' : "center",
            }}
          >
            <span style={{fontWeight:700}}>Create A Pool:</span> Create Your Pool with Your People.
          </Typography>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', columnGap: '42px', marginTop:'32px', flexDirection: isMobile? 'column' : 'row',
              flexFlow: isMobile? 'column' : 'row',}}>
            <Box sx={{
              display: 'block',
              width: isMobile? '100%' : '400px',
              height: isMobile? 'auto' : '400px',
              aspectRatio: '1/1'
            }}>
              <img src="https://poolpartyfilestorage.blob.core.windows.net/media/guys-at-the-bar.png" alt="" />
            </Box>
            <Box sx={{
                textAlign:'left',
                maxWidth: '468px',
                marginTop: isMobile? '32px': '0',
              }}>
              <Typography sx={{
                fontSize: '22px',
                color: '#373737',
                fontWeight: 700
              }}>
                Pool Party Main Events
              </Typography>
              <Typography sx={{
                color: '#4F4E4E',
                fontSize: '16px',
                fontWeight: 400,
                marginBottom: '16px'
              }}>
                Pool Party Gaming thrives on community and competition. We are looking for the most competitive sports poolers on this planet. To be the best, you have to beat the best.
              </Typography>
              <Typography sx={{
                color: '#4F4E4E',
                fontSize: '16px',
                fontWeight: 400,
                marginBottom: '16px'
              }}>
                All players will have an entry into the Pool Party Main Events, our <span style={{fontWeight:700}}>FREE</span> site-wide competition with a opportunities to win some incredible prizes.
              </Typography>
              <Typography sx={{
                color: '#4F4E4E',
                fontSize: '16px',
                fontWeight: 400,
                marginBottom: '16px'
              }}>
                There’s no need for different entries as they can be shared across all of your pools.
              </Typography>
              <Button sx={{
                height: '48px',
                padding: '6px 16px',
                borderRadius: "8px",
                border: "1px solid #2C74FF",
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 600,
                background: "#07356B",
                boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                margin: isMobile? '35px auto 0 auto' : '0 auto 0 0 ',
                display: 'block'
              }}
              onClick={() => {handleRegURL('pool-hall?page=cbb')}}
              >
                Enter the Pool Hall
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* SECTION 5 - Become a partner */}
      <Box
       sx={{
        paddingTop: '32px',
        backgroundColor: '#07356B'
      }}
      >
          <Box sx={{
             width: isMobile? '90%' : '100%',
             margin: '0 auto',
        }}>
          <Typography
            sx={{
              fontSize: isMobile ? '42px' : '56px',
              fontWeight: 800,
              color: '#ffffff',
              marginBottom: '10px',
              textAlign: isMobile? 'left' : 'center',
            }}
          >
            Become a partner
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: isMobile? 'left' : 'center',
              marginBottom: '24px',
              fontSize: '16px',
              maxWidth: '860px',
              color: '#ffffff',
              margin: "0 auto 16px auto"
            }}
          >
            Pool Party gaming is dedicated to driving customer and audience engagement & growth through the world of sports and entertainment.
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: isMobile? 'left' : 'center',
              marginBottom: '24px',
              fontSize: '16px',
              maxWidth: '860px',
              color: '#ffffff',
              margin: "0 auto 16px auto"
            }}
          >
            Whether sponsoring a main event site-wide pool, hosting a Community Pool, or finding a new way to collaborate,  Pool Party is a versatile social platform to boost your branding & grow your community.
          </Typography>
          <Box sx={{
            maxWidth:'1070px',
            margin: '0 auto 64px auto'
          }}>
            <ul style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '32px 0',
              columnGap: '42px',
              flexDirection: isMobile? 'column' : 'row',
              flexFlow: isMobile? 'column' : 'row',

            }}>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#FFC60A', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700, marginBottom: '8px'}}>
                    Main Event Partner
                  </Typography>
                  <Typography>
                    Receive unparalleled exposure to our vast audience, leveraging creative marketing to make a memorable impact across sitewide pools.
                  </Typography>
                </Box>
              </li>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#FFC60A', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700, marginBottom: '8px'}}>
                    Host a Community Pool
                  </Typography>
                  <Typography>
                    Host a Community Pool where you can engage and grow your community, fundraise, or create some fun your establishment / patrons.
                  </Typography>
                </Box>
              </li>
              <li style={{display:'flex', textAlign:'left', color:'#fff', marginBottom:42, columnGap: '16px'}}>
                <CheckCircleIcon sx={{color:'#FFC60A', width:42, height:42}}/>
                <Box>
                  <Typography sx={{fontSize:22, fontWeight:700, marginBottom: '8px'}}>
                    Secondary Sponsor
                  </Typography>
                  <Typography>
                    Join the fun & the community in a cost effective ways. We love to get creative to incorporating our partners into the Pool Party world.
                  </Typography>
                </Box>
              </li>
            </ul>
          </Box>
          {/* <Button sx={{
            height: '48px',
            padding: '6px 16px',
            borderRadius: '8px',
            border: '1px solid #07356B',
            background: '#FFF',
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
            marginBottom:'64px'
          }}>
            Learn more
          </Button> */}
        </Box>
      </Box>
      {/* SECTION 6 - Sponsors */}
      <Box sx={{ maxWidth: '1295px', margin: '60px auto' }}>
        <Grid container spacing={2}>
          {partners.map((image, index) => (
            <Grid item xs={12} md={3} key={index}>
              <img
                src={image.src}
                alt={image?.alt}
                style={{
                  width: '100%',
                  minWidth: image.maxWidth,
                  maxWidth: image.maxWidth,
                  display: 'block',
                  margin: '0 auto 32px auto'
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* SECTION 7 - Our Players*/}
      <Box sx={{
        paddingTop: '32px',
        paddingBottom: '64px',
      }}>
          <Box sx={{
             width: isMobile? '90%' : '100%',
             margin: '0 auto',
        }}>
          <Typography
            sx={{
              fontSize: isMobile? '32px' : '56px',
              fontWeight: 800,
              color: '#373737',
              marginBottom: '32px',
              textAlign: isMobile? 'left' : 'center',
            }}
          >
            Hear it from our players
          </Typography>
          <Box sx={{ maxWidth: '1295px', margin: '0 auto' }}>
            <Grid container spacing={2}>
              {players.map((player, index) => (
                <Grid item xs={12} md={4} key={index} >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '16px',
                      maxWidth: '350px',
                      margin:'0 auto'
                    }}
                  >
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.5" y="0.521484" width="42" height="42" rx="21" fill="#00AAD1" />
                      <path d="M15.7852 27.1152C16.3438 26.6855 17.0742 26.5996 17.7188 26.8145C18.8359 27.2441 20.125 27.459 21.5 27.459C26.8281 27.459 30.4375 24.0215 30.4375 20.584C30.4375 17.1895 26.8281 13.709 21.5 13.709C16.1289 13.709 12.5625 17.1895 12.5625 20.584C12.5625 21.959 13.0781 23.291 14.0664 24.4512C14.4531 24.8379 14.625 25.3965 14.582 25.9551C14.5391 26.7285 14.3242 27.459 14.1094 28.1035C14.8398 27.7598 15.4414 27.373 15.7852 27.1152ZM11.4023 28.834C11.4883 28.748 11.5312 28.6191 11.6172 28.4902C12.0469 27.8027 12.4336 26.8574 12.5195 25.7832C11.2305 24.3223 10.5 22.5605 10.5 20.584C10.5 15.6855 15.3984 11.6465 21.5 11.6465C27.5586 11.6465 32.5 15.6855 32.5 20.584C32.5 25.5254 27.5586 29.5215 21.5 29.5215C19.8672 29.5215 18.3633 29.2637 16.9883 28.791C16.5156 29.1348 15.6562 29.6504 14.668 30.0801C14.0234 30.3809 13.293 30.6387 12.5195 30.7676C12.4766 30.7676 12.4336 30.8105 12.4336 30.8105C12.2188 30.8535 12.0469 30.8535 11.832 30.8965C11.6172 30.8965 11.4023 30.9395 11.1445 30.9395C10.8867 30.9395 10.6289 30.7676 10.543 30.5098C10.4141 30.252 10.5 29.9512 10.6719 29.7363C10.8438 29.5645 11.0156 29.3926 11.1445 29.1777C11.2305 29.0488 11.3164 28.9629 11.3594 28.877C11.3594 28.877 11.3594 28.877 11.3594 28.834H11.4023Z" fill="white" />
                    </svg>
                    <Box sx={{ flex: 1 }}>  {/* This box now fills the remaining space */}
                      <Typography
                        sx={{
                          fontSize: '22px',
                          fontWeight: 700,
                          color: '#373737',
                          marginBottom: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {player.userName}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#4F4E4E',
                          marginBottom: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {player.review}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* SECTION 8 - Sign Up */}
      <Box sx={{
        paddingTop: '32px',
        paddingBottom: '32px',
        backgroundImage:'url(https://poolpartyfilestorage.blob.core.windows.net/media/champ-belt-cut.png)',
        backgroundPosition: '0 20%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
          <Box sx={{
             width: isMobile? '90%' : '100%',
             margin: '0 auto',
        }}>
        <Box sx={{
          maxWidth: '1295px',
          margin: '0 auto',
          padding: '32px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: isMobile? 'column' : 'row',
          flexFlow: isMobile? 'column' : 'row',
        }}>
          <Box
            sx={{
              maxWidth:'535px'
            }}
          >
            <Typography
              sx={{
                fontSize: '44px',
                fontWeight: 800,
                color: '#FFFFFF',
                textAlign: 'left',
                textShadow: '1px 1px 10px #222'
              }}
            >
            Start playing today
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#FFFFFF',
                textAlign: 'left',
                marginBottom: '32px',
                textShadow: '1px 1px 8px #111'

              }}>
              Pool Party Gaming wants you! Signing up is easy, so that you can get playing in minutes! Start by entering in your email address.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            marginBottom: '32px',
            flexDirection: isMobile? 'column' : 'row',
            flexFlow: isMobile? 'column' : 'row',
          }}>
            {/* <TextField
              sx={{ width: '300px', backgroundColor: '#FFFFFF',
                border:'1px solid #C1C1C1',
                borderRadius: '8px' }}
              label="Your Email"
              variant="filled"
            /> */}
            {/* <Button variant="contained" sx={{
              background: '#FFC60A',
              color:'#1C1C1C',
              width:'300px'
            }}
            onClick={() => {handleRegURL('register')}}
            >
              Sign Up
            </Button> */}
          </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}