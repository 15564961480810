import { apiClient } from '../../apiClient';
import { useMutation } from "@tanstack/react-query";

const forgotPasswordRequest = (email) =>
apiClient.post("/user/forgot-password", { email });

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPasswordRequest,
  });
};
