import React, { useCallback, useState } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { AlternateEmail } from "@mui/icons-material";
import { useUpdateUser } from "../../hooks/users";

export default function GeneralInformation(props) {
  const { userData } = props;
  const { mutate: updateUser } = useUpdateUser();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [formState, setFormState] = useState({
    instagramHandle: userData.socials?.instagram_handle,
    xHandle: userData.socials?.x_handle,
    tiktokHandle: userData.socials?.tiktok_handle,
  });

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      updateUser(
        {
          id: userData._id,
          socials: {
            instagramHandle: formState.instagramHandle,
            xHandle: formState.xHandle,
            tiktokHandle: formState.tiktokHandle,
          },
        },
        {
          onSuccess: () => {
            setIsSnackbarOpen(true);
          },
        }
      );
    },
    [formState, updateUser, userData]
  );

  return (
    <Box component="form" onSubmit={submitForm}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ bottom: "100px" }}
        open={isSnackbarOpen}
        autoHideDuration={135000}
        onClose={() => setIsSnackbarOpen(false)}
        message="Changes saved successfully."
        key={"top" + "right"}
      />
      <div
        className="hidden md:block w-full flex justify-end pr-6 text-right"
        style={{ marginTop: "-90px", paddingBottom: "60px" }}
      >
        <Button variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <TextField
          id="instagramHandle"
          name="instagramHandle"
          label="Instagram Handle"
          variant="outlined"
          type="text"
          sx={{ width: "100%" }}
          value={formState.instagramHandle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmail />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setFormState({ ...formState, instagramHandle: e.target.value });
          }}
        />
        <TextField
          id="xHandle"
          name="xHandle"
          label="X Handle"
          variant="outlined"
          type="text"
          sx={{ width: "100%" }}
          value={formState.xHandle}
          onChange={(e) =>
            setFormState({ ...formState, xHandle: e.target.value })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmail />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="tiktokHandle"
          name="tiktokHandle"
          label="TikTok Handle"
          variant="outlined"
          type="text"
          sx={{ width: "100%" }}
          value={formState.tiktokHandle}
          onChange={(e) =>
            setFormState({ ...formState, tiktokHandle: e.target.value })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmail />
              </InputAdornment>
            ),
          }}
        />

        <div className="block md:hidden w-full flex justify-center">
          <Button variant="contained" size="large" type="submit">
            Save Changes
          </Button>
        </div>
      </Box>
    </Box>
  );
}
