import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import Close from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import '../NFLSurvivorStyles/SurvivorLeaderboard.scss';
import SurvivorUserStatsModal from '../../GameModals/SurvivorModals/SurvivorUserStats/SurvivorUserStatsModal';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';

const SurvivorLeaderboard = ({ leaderboardData, currentWeeksDeadlineDateTime }) => {

    const { startingWeekState, NFLTeams, NFLScoresBySeason, myUsername, userPicksFromDB, mainGameId, partyState, isSurvivorOver, hasJoinDeadlinePassed, areAllFirstWeekGamesFinal, firstWeek } = useNFLSurvivorContext();
    //console.log("PARTY NAME", partyState);
    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleRulesClick = () => {
        navigateToTop(`/nfl-survivor/${uriEncoded(mainGameId)}/RULES/RULES`);
    }



    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    //console.log("Current Week Deadline",currentWeeksDeadlineDateTime, "Time Now in New York:", timeNowInNewYork);

    //const dummyTimeForTesting = DateTime.fromISO('2024-08-11T13:00:00.000-04:00', { zone: 'America/New_York' });

    //const hasThisWeeksDeadlinePassed = currentWeeksDeadlineDateTime ? timeNowInNewYork > currentWeeksDeadlineDateTime : false;
    //const hasThisWeeksDeadlinePassedDummy = timeNowInNewYork > dummyTimeForTesting;
    //console.log("Has This Week's Deadline Passed?", hasThisWeeksDeadlinePassed, "Has This Week's Deadline Passed Dummy?", hasThisWeeksDeadlinePassedDummy);

    const usersRow = leaderboardData?.find(entry => entry.username === myUsername);
    //console.log("Users Row:", usersRow);

    const [sortBy, setSortBy] = useState('rank');
    const [sortOrder, setSortOrder] = useState('asc');
    const [userStatsOpen, setUserStatsOpen] = useState(false);
    const [userStatsInfo, setUserStatsInfo] = useState(null);
    const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);
    const [leaderboardWithRank, setLeaderboardWithRank] = useState([]);
    //console.log("Leaderboard Data", leaderboardData);

    useEffect(() => {
        // Sort leaderboard data
        const sortedLeaderboardData = [...leaderboardData].sort((a, b) => {
            const aDoesUserHaveALoss = a.doesUserHaveALoss || false;
            const aTotalWins = a.totalWins || 0;
            const aTotalMargin = a.totalMargin || 0;
    
            const bDoesUserHaveALoss = b.doesUserHaveALoss || false;
            const bTotalWins = b.totalWins || 0;
            const bTotalMargin = b.totalMargin || 0;
    
            // Prioritize sorting based on these conditions
            if (aDoesUserHaveALoss !== bDoesUserHaveALoss) {
                return aDoesUserHaveALoss - bDoesUserHaveALoss;
            }
            if (aTotalWins !== bTotalWins) {
                return bTotalWins - aTotalWins;
            }
            return bTotalMargin - aTotalMargin;
        });
    
        // Insert rank for each user
        let currentRank = 1;
        sortedLeaderboardData.forEach((user, index) => {
            // Ensure default values
            const userDoesUserHaveALoss = user.doesUserHaveALoss || false;
            const userTotalWins = user.totalWins || 0;
            const userTotalMargin = user.totalMargin || 0;
    
            if (index === 0) {
                user.rank = currentRank;
            } else {
                const previousUser = sortedLeaderboardData[index - 1];
                const previousUserDoesUserHaveALoss = previousUser.doesUserHaveALoss || false;
                const previousUserTotalWins = previousUser.totalWins || 0;
                const previousUserTotalMargin = previousUser.totalMargin || 0;
    
                // Compare current user with the previous one to determine if they should have the same rank
                if (
                    userDoesUserHaveALoss === previousUserDoesUserHaveALoss &&
                    userTotalWins === previousUserTotalWins &&
                    userTotalMargin === previousUserTotalMargin
                ) {
                    user.rank = previousUser.rank; // Same rank as previous user
                } else {
                    user.rank = ++currentRank; // Increment rank for next distinct user
                }
            }
        });
    
        // Update the state with ranked leaderboard data
        setLeaderboardWithRank(sortedLeaderboardData);
    }, [leaderboardData]); // Dependency array
    

    //console.log("Leaderboard With Rank", leaderboardWithRank);


    const teamLogo = (teamId) => {
        return NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    

    // Click handler to update sort state
    const handleSort = () => {
        setSortBy('rank'); // Always sort by rank
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sorting order
    };

    const sortedData = [...leaderboardWithRank]?.sort((a, b) => {
        // Primary sort by rank
        if (sortBy === 'rank') {
            const rankDiff = sortOrder === 'asc' ? a.rank - b.rank : b.rank - a.rank;
            if (rankDiff !== 0) return rankDiff; // If ranks are different, sort by rank
    
            // Secondary sort by totalMargin if ranks are tied
            const marginDiff = sortOrder === 'asc' ? b.totalMargin - a.totalMargin : a.totalMargin - b.totalMargin;
            if (marginDiff !== 0) return marginDiff; // If totalMargins are different, sort by totalMargin
    
            // Tertiary sort by the highest single game margin if totalMargins are tied
            const highestMarginA = Math.max(...a.picks.map(pick => pick.margin || 0)); // Handle null margins
            const highestMarginB = Math.max(...b.picks.map(pick => pick.margin || 0));
            //console.log("Highest Margin A", highestMarginA, "Highest Margin B", highestMarginB, "Sort Order", sortOrder, "Sort By", sortBy);
    
            return sortOrder === 'asc' ? highestMarginB - highestMarginA : highestMarginA - highestMarginB;
        }
        
        return 0;
    });
    

      // Calculate the number of entrants, alive, and eliminated
    const numberOfEntrants = leaderboardData.length;
    const numberOfAlive = leaderboardData.filter(user => !user.doesUserHaveALoss).length;
    const numberOfEliminated = leaderboardData.filter(user => user.doesUserHaveALoss).length;

    const whosInFirst = sortedData[0]?.username;
    const whosInFirstCharacterCount = whosInFirst?.length;
    const howManyUsersAlive = sortedData.filter(user => !user.doesUserHaveALoss).length;
    //console.log("How Many Users Alive", howManyUsersAlive, "Sorted Data", sortedData);

    const lastWeekAPickWasMade = leaderboardData?.reduce((acc, user) => {
        // Filter out picks where teamId is "No Pick"
        const userPicks = (user?.picks || []).filter(pick => pick.teamId !== "No Pick");
        
        const lastPick = userPicks?.reduce((acc, pick) => {
            return pick.week > acc ? pick.week : acc;
        }, 0);
        
        return lastPick > acc ? lastPick : acc;
    }, 0);
    
    //console.log("Last Week A Pick Was Made", lastWeekAPickWasMade);
    const adjustedLastWeekAPickWasMade = lastWeekAPickWasMade === 0 ? firstWeek : lastWeekAPickWasMade > startingWeekState ? startingWeekState : lastWeekAPickWasMade; 
    
    const lowWeek = firstWeek;  // We want to start from the first week defined, which is 6
    let highWeek = Math.max(firstWeek, startingWeekState);  // Ensure we show at least from firstWeek, but only up to startingWeekState
    
    let weeksToShow = highWeek;
    //console.log("Adjusted Last Week A Pick Was Made", adjustedLastWeekAPickWasMade, "Starting Week State", startingWeekState, "First Week", firstWeek, "High Week", highWeek, "Low Week", lowWeek, "Weeks to Show", weeksToShow);
    
    const isThisGroupOver = (howManyUsersAlive <= 1) && areAllFirstWeekGamesFinal;
    if (isSurvivorOver || isThisGroupOver) {
        weeksToShow = Math.min(adjustedLastWeekAPickWasMade, startingWeekState);
        highWeek = adjustedLastWeekAPickWasMade;
    }
    //console.log("Weeks to Show", weeksToShow, "Is Survivor Over?", isSurvivorOver, "Is This Group Over?", isThisGroupOver, "Adjusted Last Week A Pick Was Made", adjustedLastWeekAPickWasMade, "How Many Users Alive", howManyUsersAlive, "Are All First Week Games Over?", areAllFirstWeekGamesFinal, "Has Join Deadline Passed?", hasJoinDeadlinePassed);
    //console.log("HIGH WEEK MINUS LOW WEEK PLUS ONE!!!!!!!!!!!!!!!!!!!!!!!", (highWeek - lowWeek + 1));

    // Ensure highWeek and lowWeek are valid numbers, default to 1 if not
    const safeHighWeek = typeof highWeek === 'number' ? highWeek : 0;
    const safeLowWeek = typeof lowWeek === 'number' ? lowWeek : 0;

    // Calculate the number of weeks, default to 1 if the result is NaN or less than 1
    const numberOfWeeks = Math.max((safeHighWeek - safeLowWeek + 1), 1);

    //console.log("Safe High Week", safeHighWeek, "Safe Low Week", safeLowWeek, "Number of Weeks", numberOfWeeks);

    return (
        <>
       

        {/* Info about winner once the game has eneded */}
        {(((isSurvivorOver || howManyUsersAlive <= 1) && areAllFirstWeekGamesFinal) && hasJoinDeadlinePassed) ? (
            <Box
                sx={{
                    marginTop: '32px'
                }}
            >
                <ChampionDisplay 
                    whosInFirst={whosInFirst} 
                    whosInFirstCharacterCount={whosInFirstCharacterCount} 
                    partyName={partyState}
                    line2={"2024 NFL Survivor Champion"}
                    line4={"2025 NFL Survivor Competition."}    
                />
            </Box>
        ) : (

        <Box className="leaderboard-entrants-info-container">
            <Box
                className="leaderboard-entrants-info"
                sx={{
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: isMobile ? '24px' : '150px',
                }}
            >
                <Box className="leaderboard-entrants-info-text-container">
                    <Typography
                        className="leaderboard-entrants-header"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit' }}
                    >
                        Entrants
                    </Typography>
                    <Typography
                        className="leaderboard-entrants-text"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit' }}
                    >
                        {numberOfEntrants}
                    </Typography>
                </Box>
                <Box className="leaderboard-entrants-info-text-container">
                    <Typography
                        className="leaderboard-entrants-header"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit' }}
                    >
                        Alive
                    </Typography>
                    <Typography
                        className="leaderboard-entrants-text"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit', alignItems: 'center' }}
                    >
                        {numberOfAlive}
                    </Typography>
                </Box>
                <Box
                    className="leaderboard-entrants-info-text-container"
                    sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', alignItems: 'center' }}
                >
                    <Typography
                        className="leaderboard-entrants-header"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit', minWidth: '60px' }}
                    >
                        Eliminated
                    </Typography>
                    <Typography
                        className="leaderboard-entrants-text"
                        sx={{ width: isMobile ? '60px' : '110px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit', alignItems: 'center' }}
                    >
                        {numberOfEliminated}
                    </Typography>
                </Box>
            </Box>
        </Box>
        )}

        <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: (isSurvivorOver || isThisGroupOver) ? '10px' : '0px',
                    marginBottom: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => setTiebreakInfoOpen(true)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        color: '#00AAD1',
                        marginRight: '5px',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <InfoIcon
                    sx={{
                        color: '#00AAD1',
                        fontSize: isMobile ? '16px' : '20px',
                    }}
                />
            </Box>

        <TableContainer
            component={Paper}
            className="leaderboard-table-container"
            sx={{
                maxWidth: `calc(515px + ${weeksToShow * 58}px)`, // Limit the maximum width if needed
                minWidth: isMobile ? 'auto' : '646px', // Set the minimum width
                margin: '0 auto',
                borderRadius: '0',
            }}
        >
            <Table sx={{ tableLayout: isMobile ? null : 'fixed' }}>
                {usersRow && (
                    //console.log("Users Row", usersRow),
                    <TableHead sx={{ backgroundColor: '#F0F1F1' }}>
                        <TableRow>
                            <TableCell
                            sx={{
                                color: '#002129',
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: isMobile ? '160px' : '200px',
                                maxWidth: isMobile ? '160px' : '200px',
                                position: 'sticky', // Make the column sticky
                                backgroundColor: '#F0F1F1', // Background color to prevent overlap
                                left: 0, // Position it on the left
                                zIndex: 10, // Ensure it stays above other cells
                                
                            }}
                            onClick={() => {
                                setUserStatsOpen(true);
                                setUserStatsInfo(usersRow); // Pass the entire row data
                              }}
                            >
                            <Typography
                                sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '700',
                                color: usersRow.doesUserHaveALoss ? '#CC293C' : '#002129',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                }}
                            >
                                {usersRow?.username}
                            </Typography>
                            <Typography
                                sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '500',
                                color: usersRow.doesUserHaveALoss ? '#CC293C' : '#002129',
                                }}
                            >
                                Win Margin: {usersRow?.totalMargin}
                            </Typography>
                            </TableCell>
                            {[...Array(numberOfWeeks)].map((_, weekIndex) => {
                            const weekNumber = weeksToShow - weekIndex;
                            const pick = userPicksFromDB?.find(pick => pick.week === weekNumber) || null;
                            //console.log("Pick", pick);
                            const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                            const isCurrentWeek = weekNumber === weeksToShow;
                            const hasDeadlinePassed = timeNowInNewYork > timeNowInNewYork;
                            const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                            //console.log("Pick Time", pickTime, "Has Deadline Passed?", hasDeadlinePassed, "Time Now in New York:", timeNowInNewYork, "Dummy Time for Testing:", dummyTimeForTesting, "Has Game Started?", hasGameStarted);

                            return (
                                <TableCell
                                key={weekIndex}
                                sx={{
                                    textAlign: 'center',
                                    minWidth: isMobile ? '48px' : '58px',
                                    width: isMobile ? '48px' : '58px',
                                    padding: 0,
                                }}
                                >
                                {pick ? (
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: pick.result === 'Win' ? '2px solid #00AA72' : (pick.result === 'Loss' ? '2px solid #CC293C' : '1px solid #ccc'),
                                        background: pick.result === 'Win' ? '#CCEEE3' : ((pick.result === 'Loss' && pick.teamId !== 'No Pick') ? '#F5D4D8' : '#f9f9f9'),
                                        width: isMobile ? '32px' : '40px',
                                        height: isMobile ? '32px' : '40px',
                                        overflow: 'hidden',
                                        margin: '0 auto',
                                    }}
                                    >
                                    {pick.teamId === 'No Pick' ? (
                                        <Close
                                            sx={{
                                            color: '#CC293C',
                                            width: isMobile ? '18px' : '24px',
                                            height: isMobile ? '18px' : '24px',
                                            }}
                                        />
                                        ) : (
                                        <img
                                            src={teamLogo(pick.teamId)}
                                            alt={`Team ${pick.teamId}`}
                                            style={{
                                            width: '100%',
                                            height: 'auto',
                                            }}
                                        />
                                        )
                                    }
                                    </Box>
                                ) : (
                                    <Box
                                    sx={{
                                        width: isMobile ? '32px' : '40px',
                                        height: isMobile ? '32px' : '40px',
                                        border: (usersRow?.doesUserHaveALoss && usersRow?.lossWeek !== weekNumber) ? '' : '1px solid #ccc',
                                        backgroundColor: (usersRow?.doesUserHaveALoss && usersRow?.lossWeek !== weekNumber) ? 'transparent' : '#FFF',
                                        margin: '0 auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    >
                                    {/* Blank cell or placeholder */}
                                        <Typography 
                                            variant="body1" 
                                            sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}  // Example font size and color
                                        >
                                            {/* Your text here */}
                                            {usersRow?.doesUserHaveALoss && usersRow?.lossWeek !== weekNumber && weekNumber === weeksToShow ? `W${usersRow?.lossWeek}` : ''}
                                        </Typography>
                                    </Box>
                                )}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        </TableHead>
                        )}
                <TableHead sx={{ backgroundColor: '#002129' }}>
                    <TableRow>
                        <TableCell
                            onClick={handleSort}
                            sx={{
                                width: isMobile ? '160px' : '200px',
                                maxWidth: isMobile ? '160px' : '200px',
                                minWidth: isMobile ? '160px' : '200px',
                                color: '#FFC60A',
                                fontWeight: 700,
                                textAlign: 'left',
                                position: 'sticky', // Make the column sticky
                                left: 0, // Position it on the left
                                backgroundColor: '#002129', // Background color to prevent overlap
                                zIndex: 1, // Ensure it stays above other cells
                                cursor: 'pointer',
                                fontSize: isMobile ? '14px' : '22px',
                                textDecoration: 'underline',
                            }}
                        >
                            STANDINGS
                        </TableCell>
                        {[...Array(numberOfWeeks)].map((_, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    minWidth: isMobile ? '48px' : '58px',
                                    maxWidth: isMobile ? '48px' : '58px',
                                    color: '#FFF',
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    padding: '0',
                                }}
                            >
                                <div>
                                    {isMobile ? 'Wk' : 'Week'}
                                    <span style={{ display: 'block' }}>
                                        {weeksToShow - index}
                                    </span>
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData?.map((user, userIndex) => (
                        <TableRow
                            key={user.userId}
                            sx={{
                                backgroundColor:
                                user.username === myUsername
                                    ? '#FFF6DA' // Highlight color for the user's row
                                    : userIndex % 2 === 0
                                    ? '#FFFFFF' // White background for even rows
                                    : '#F0F1F1', // Grey background for odd rows
                            }}
                            >
                            <TableCell
                                sx={{
                                color: '#002129',
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                position: 'sticky', // Make the column sticky
                                backgroundColor:
                                    user.username === myUsername
                                    ? '#FFF6DA'
                                    : userIndex % 2 === 0
                                    ? '#FFFFFF'
                                    : '#F0F1F1', // Ensure the sticky column matches the row's background color
                                left: 0, // Position it on the left
                                zIndex: 1, // Ensure it stays above other cells
                                }}
                                onClick={() => {
                                    setUserStatsOpen(true);
                                    setUserStatsInfo(user); // Pass the entire row data
                                  }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        fontWeight: '700',
                                        width: isMobile ? '160px' : '200px',
                                        maxWidth: isMobile ? '160px' : '200px',
                                        color: user.doesUserHaveALoss ? '#CC293C' : '#002129',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {user.username}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        fontWeight: '500',
                                        color: user.doesUserHaveALoss ? '#CC293C' : '#002129',
                                    }}
                                >
                                    Win Margin: {user.totalMargin ?? 0}
                                </Typography>
                            </TableCell>
                            {[...Array(numberOfWeeks)].map((_, weekIndex) => {
                                const weekNumber = weeksToShow - weekIndex;
                                const userHaveALoss = user?.doesUserHaveALoss || false;
                                const userLossWeek = user?.lossWeek || null;
                                const pick = user?.username === myUsername ? userPicksFromDB?.find(pick => pick.week === weekNumber) : user?.picks?.find(pick => pick.week === weekNumber);
                                const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                                //console.log("Pick", pick, user.username);
                                const isCurrentWeek = weekNumber === weeksToShow;
                                const hasDeadlinePassed = timeNowInNewYork > currentWeeksDeadlineDateTime;
                                const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                                //console.log("Pick Time", pickTime, "Has Deadline Passed?", hasDeadlinePassed, "Time Now in New York:", timeNowInNewYork, "Current Weeks Deadline", currentWeeksDeadlineDateTime ,"Has Game Started?", hasGameStarted);

                                return (
                                    <TableCell
                                        key={weekIndex}
                                        sx={{
                                            textAlign: 'center',
                                            minWidth: isMobile ? '48px' : '58px',
                                            width: isMobile ? '48px' : '58px',
                                            padding: 0,
                                        }}
                                    >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: pick?.result === 'Win' ? '2px solid #00AA72' : (pick?.result === 'Loss' ? '2px solid #CC293C' : (userHaveALoss && userLossWeek !== weekNumber) ? '' : '1px solid #ccc'),
                                                    background: pick?.result === 'Win' ? '#CCEEE3' : ((pick?.result === 'Loss' && pick?.teamId !== 'No Pick') ? '#F5D4D8' : (userHaveALoss && userLossWeek !== weekNumber) ? 'transparent' : '#f9f9f9'),
                                                    width: isMobile ? '32px' : '40px',
                                                    height: isMobile ? '32px' : '40px',
                                                    overflow: 'hidden',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {(isCurrentWeek && !hasDeadlinePassed && !hasGameStarted && pick?.teamId !== "No Pick" && (user?.username !== myUsername)) || !pick ? (
                                                    <Box
                                                        sx={{
                                                            width: isMobile ? '32px' : '40px',
                                                            height: isMobile ? '32px' : '40px',
                                                            backgroundColor: (userHaveALoss && userLossWeek !== weekNumber) ? 'transparent' : '#FFF',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        >
                                                        <Typography 
                                                            variant="body1" 
                                                            sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}  // Example font size and color
                                                        >
                                                            {/* Your text here */}
                                                            {userHaveALoss && userLossWeek !== weekNumber && weekNumber === weeksToShow ? `W${userLossWeek}` : ''}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    pick.teamId === 'No Pick' ? (
                                                        <Close
                                                            sx={{
                                                                color: '#CC293C',
                                                                width: isMobile ? '18px' : '24px',
                                                                height: isMobile ? '18px' : '24px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={teamLogo(pick.teamId)}
                                                            alt={`Team ${pick.teamId}`}
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        {userStatsOpen && (
            <SurvivorUserStatsModal
                statsModalOpen={userStatsOpen}
                setStatsModalOpen={setUserStatsOpen}
                myUsername={userStatsInfo?.username}
                doesUserHaveALoss={userStatsInfo?.doesUserHaveALoss}
                lossWeek={userStatsInfo?.lossWeek}
                startingWeekState={startingWeekState}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                NFLTeams={NFLTeams}
                areTheseMyStats={userStatsInfo?.username === myUsername}
                usersPicks={userStatsInfo?.username === myUsername ? userPicksFromDB : userStatsInfo?.picks}
                firstWeek={firstWeek}
            />
        )}

        {tiebreakInfoOpen && (
                <Modal
                open={tiebreakInfoOpen}
                onClose={() => setTiebreakInfoOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 'none',
                }}
            >
                <Box
                    sx={{
                        width: '323px',
                        height: '284px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '16px', // Optional padding
                        boxShadow: 24, // Optional shadow for better visibility
                    }}
                >
                    {/* Modal content goes here */}
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '800',
                            textAlign: 'center',
                        }}
                    >
                        Tiebreaker Info
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <span style={{ display: 'block' }}>
                            This pool will have a single outright winner. Tiebreakers will be used in the event:
                        </span>
                        <span style={{ display: 'block', marginTop: '10px' }}>
                            Multiple survivors remain at the end of Week 18.
                        </span>
                        <span style={{ display: 'block' }}>
                            OR
                        </span>
                        <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
                            The last survivors are eliminated in the same week.
                        </span>
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                textAlign: 'center',
                                marginTop: '20px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ display: 'block' }}>
                            <span style={{ fontWeight: 700 }}>
                                Tiebreaker:</span> Largest Win Margin
                            </span>
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        See the pool{' '}
                        <span
                            style={{
                                textDecoration: 'underline',
                                color: '#00AAD1',
                                cursor: 'pointer',
                                fontWeight: '700',
                            }}
                            onClick={handleRulesClick}
                        >
                            RULES
                        </span>
                        {' '}for complete details!
                    </Typography>
                    <Button
                        sx={{
                            backgroundColor: '#00AAD1', // Primary color
                            height: '38px',
                            width: '115px',
                            color: 'white', // Text color
                            display: 'block', // Center horizontally
                            margin: '0 auto', // Horizontally centers the button
                            marginTop: '20px',
                            '&:hover': {
                                backgroundColor: '#00AAD1', // Ensure the background color stays the same on hover
                            },
                            '&:focus': {
                                outline: 'none', // Remove focus outline
                            },
                            '&:active': {
                                backgroundColor: '#00AAD1', // Keep the same color when the button is active
                            },
                        }}
                        onClick={() => setTiebreakInfoOpen(false)}
                    >
                        OK
                    </Button>

                </Box>
            </Modal>
            )}
       
    </>
    )
}

export default SurvivorLeaderboard;