import { DateTime } from 'luxon';

// Date formatting for GameCard component
// Takes in a time and if game is over
// Returns like FRI MAR 21 - 8:00 PM EST or FRI MAR 21
// Also, if noNoonGames is true, it will return TBD for noon games (NCAA tourney uses noon as a placeholder, isn't actually the game time)

export const formatGameCardDate = (dateString, isClosed, noNoonGames) => {
    // Ensure the input is treated as Eastern Time
    const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

    // Format day of the week, month, and day
    const formattedDate = date.toFormat("EEE MMM dd").toUpperCase();

    if (isClosed) {
        return formattedDate; // Only return "FRI MAR 21"
    }

    // Check if it's a noon game (12:00 PM or 12:00 AM) and noNoonGames is true
    const isNoonGame = date.hour === 12 && date.minute === 0;

    if (noNoonGames && isNoonGame) {
        return `${formattedDate} - TBD`;
    }

    // Format time with AM/PM in EST
    const formattedTime = date.toFormat("h:mm a").toUpperCase();

    return `${formattedDate} - ${formattedTime} EST`;
};


// Date formatting for Abbreviated Date with @ time
// Takes in a time and returns like FRI, MAR 21 @ 8:00 PM ET
export const formatAbbreviatedDateTime = (dateString) => {
    const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

    return `${date.toFormat("EEE, MMM d")} @ ${date.toFormat("h:mm a").toLowerCase()} ET`;
};

