import React, { useState } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import PickEmLeaderboardDropdown from '../DropdownMenus/PickEmLeaderboardDropdown';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import DisplayPicksRow from './DisplayPicksRow';
import { DateTime } from 'luxon';
import PickEmUserStatsModal from '../../GameModals/PickEmModals/PickEmUserStats/PickEmUserStatsModal';


const PoolPicks = ({ handleClickState, leaderboardData }) => {
 
  const { 
    startingWeekState,
    NFLScoresBySeason,
    week,
    userPicksFromDB,
    myUsername,
    myUserId,
    NFLTeams,
   } = useNFLPickEmContext();
  
  const [seasonVsWeek, setSeasonVsWeek] = useState(`Week ${startingWeekState}`);
  const [userStatsOpen, setUserStatsOpen] = useState(false);
  const [userStatsInfo, setUserStatsInfo] = useState(null);
  //console.log("Pool Picks Leaderboard Data:", leaderboardData);
  const [value, setValue] = useState('weekly');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery('(max-width:550px)');


  const selectedWeek = seasonVsWeek?.replace(/\D/g, '');


  const weeklySchedule = NFLScoresBySeason?.filter(game => String(game.Week) === selectedWeek);
  const sortedSchedule = weeklySchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
  //console.log("Pool Picks Weekly Schedule:", sortedSchedule);
  

  // //const gamesNotStartedCount = 1;
  // const gamesNotStartedCount = weeklySchedule?.filter(game => game.Status === 'Scheduled' || game.Status === 'Delayed').length;
  // console.log("Games Not Started Count:", gamesNotStartedCount);

  const sortedData = leaderboardData?.sort((a, b) => b.totalPoints - a.totalPoints);
  //console.log("Pool Picks Sorted Data:", sortedData);
  

  const assignRanks = (data) => {
    // Sort by totalPoints first, then by totalMargin in case of a tie
    const sortedData = data.sort((a, b) => {
        if (a.totalPoints === b.totalPoints) {
            return b.totalMargin - a.totalMargin; // Use totalMargin to break the tie
        }
        return b.totalPoints - a.totalPoints; // Sort by totalPoints in descending order
    });

    let rank = 1;
    let tiedRank = 1;
    let previousPoints = sortedData[0]?.totalPoints;
    let previousMargin = sortedData[0]?.totalMargin;

    return sortedData.map((entry, index) => {
        if (entry.totalPoints === previousPoints && entry.totalMargin === previousMargin) {
            // If both totalPoints and totalMargin are the same, assign the tied rank
            entry.rank = tiedRank;
        } else {
            // Otherwise, update the rank and tiedRank
            rank = index + 1;
            tiedRank = rank;
            entry.rank = rank;
        }
        // Update previous points and margin
        previousPoints = entry.totalPoints;
        previousMargin = entry.totalMargin;
        return entry;
    });
};


  const sortedDataWithRank = assignRanks(sortedData);


  const weeklyLeaderboard = leaderboardData.map(user => {
    const { picks } = user;
    let wins = 0, losses = 0, pushes = 0, points = 0, totalMargin = 0;
  
    // Calculate weekly picks for the selected week
    const weeklyPicks = picks?.filter(pick => String(pick.week) === selectedWeek);
    if (weeklyPicks) {
      weeklyPicks.forEach(pick => {
        if (pick.result === 'Win') {
          wins++;
          totalMargin += pick.margin ?? 0;
        } else if (pick.result === 'Loss') {
          losses++;
          totalMargin += pick.margin ?? 0;
        } else if (pick.result === 'Push') {
          pushes++;
        }
      });
      points = wins + pushes * 0.5;
    }
  
    return {
      ...user,
      totalWins: wins,
      totalLosses: losses,
      totalPushes: pushes,
      totalPoints: points,
      totalMargin,
      picks: weeklyPicks,
    };
  });
  
  const sortedWeeklyLeaderboard = weeklyLeaderboard.sort((a, b) => {
    if (a.totalPoints === b.totalPoints) {
      return b.totalMargin - a.totalMargin; // Tie-break by margin
    }
    return b.totalPoints - a.totalPoints; // Sort by points in descending order
  });
  
  const rankedWeeklyLeaderboard = assignRanks(sortedWeeklyLeaderboard);
  //console.log("Pool Picks Ranked Weekly Leaderboard:", rankedWeeklyLeaderboard);
  
  const dataToDisplay = (value === 'season' || userStatsOpen) ? sortedDataWithRank : rankedWeeklyLeaderboard;
  //console.log("Pool Picks Data To Display:", dataToDisplay);
  //console.log("SortedDataWithRank:", sortedDataWithRank, "RankedWeeklyLeaderboard:", rankedWeeklyLeaderboard);

  function findUsersOverallInfo(data, username) {
    const userEntry = data?.find(entry => entry.username === username);
    return userEntry?.picks || []; // Return the user's picks or an empty array if no match
  }
  
  

  const usersRow = dataToDisplay?.find(entry => entry.username === myUsername);
  const userPicks = userPicksFromDB?.filter(pick => String(pick.week) === selectedWeek);
  //console.log("Pool Picks Users Row:", usersRow);
  const userPicksSortedByDate = userPicks?.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));



  function addNumberSuffix(number) {
    if (number % 100 >= 11 && number % 100 <= 13) {
      return `${number}th`;
    }
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }


  // Current time in New York timezone
  const timeInNYNow = DateTime.now().setZone('America/New_York');
    
  //const dummyDate = DateTime.fromISO('2024-09-12T12:00:00.000-05:00').setZone('America/New_York');
  //console.log("DUMMY DATE", dummyDate);

  
//   const handleUserClick = (username, userId) => {
//     setSelectedUser(username);
//     setSelectedUserId(userId);
//     if (username === myUserName && userId === myUserId) {
//       setScorecardState(userScorecard);
//     } else {
//     const selectedUsersScorecard = getUserScorecard(username, userId);
//     setScorecardState(selectedUsersScorecard);
//     }
//     handleClickState('SCORE_CARD');
//   };








  return (
    <>
    <Box>
      <PickEmLeaderboardDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek}/>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      }}>
        {/* <Typography variant="body1" sx={{ marginRight: 2, fontSize: isMobile ? '12px' : '16px' }}>
          Sort By:
        </Typography> */}
        <RadioGroup
          row
          name="sort-by"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="weekly"
            control={<Radio />}
            label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Weekly Standings</Typography>}
          />
          <FormControlLabel
            value="season"
            control={<Radio />}
            label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Season Standings</Typography>}
          />
        </RadioGroup>
      </Box>

      {/* <h1 style={{ textDecoration: 'underline' }}>{groupState ?  groupState : headerDisplayName} Leaderboard</h1> */}
      <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto'}}>
        <Table>
          {usersRow &&
          <TableHead sx={{ backgroundColor: '#F0F1F1'}}>
            <TableRow style={{ height: '78px'}}>
              <TableCell className="pool-picks-table-cells" style={{ width: '128px', height: isMobile ? '78px' : '96px', textAlign: 'left', fontSize: '12px' }}>
                <Box
                    sx={{
                      overflow: 'hidden',
                      color: '#002129',
                      textOverflow: 'ellipsis',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                      cursor: 'pointer', // Change cursor to pointer to indicate clickability
                    }}
                    onClick={() => {
                      setUserStatsOpen(true);
                      setUserStatsInfo(usersRow);
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: isMobile ? '12px' : '16px',
                        marginLeft: isMobile ? '2px' : '8px',
                        textDecoration: 'underline', // Underline the username
                        fontWeight: 700,
                        width: isSmallScreen ? '120px' : '175px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', // Add ellipsis for truncated text
                      }}
                    >
                      {myUsername}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: isMobile ? '12px' : '16px',
                        marginLeft: isMobile ? '2px' : '8px',
                      }}
                    >
                      {addNumberSuffix(usersRow?.rank)} - {usersRow?.totalPoints} {usersRow?.totalPoints === 1 ? "Pt" : "Pts"}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: isMobile ? '12px' : '16px',
                        marginLeft: isMobile ? '2px' : '8px',
                      }}
                    >
                      Margin ATS: {usersRow?.totalMargin}
                    </Typography>
                  </Box>
                </TableCell>
              <TableCell className="pool-picks-table-cells"style={{width:'247px', height: '78px', fontSize: '12px'}}><DisplayPicksRow weeklySchedule={sortedSchedule} userPicks={userPicksSortedByDate} hiddenPicks={0} /></TableCell>
            </TableRow>
          </TableHead>
          }

          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
              <TableCell className="pickem-leaderboard-table-cells"
                sx={{ fontWeight: 700, color: '#FFF', textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', paddingLeft: isMobile ? '2px' : '8px', transform: isMobile ? 'translateX(2px)' : 'translateX(8px)', }}
              >
                STANDINGS
              </TableCell>
              <TableCell className="pickem-leaderboard-table-cells"
                sx={{ fontWeight: 700, color: '#FFF', textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px' }}
              >
                PICKS
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataToDisplay?.map((usersEntry, index) => {
                // Destructure usersEntry with a default value for picks
                const { username, totalPoints, picks, rank, userId, totalMargin } = usersEntry;
                //console.log("Pool Picks User Entry:", usersEntry);

                // Ensure picks is an array, if not present default to []
                const userPicks = Array.isArray(picks) ? picks : [];

                let filteredPicks = userPicks
                .filter(pick => 
                    String(pick.week) === selectedWeek &&
                    (pick.teamId === "No Pick" || DateTime.fromISO(pick.dateTime) < timeInNYNow)
                )
                .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));
            

                // Filter and sort picks for the selected week and after timeInNYNow
                const gamesToHide = userPicks
                    .filter(pick => String(pick.week) === selectedWeek && DateTime.fromISO(pick.dateTime) > timeInNYNow)
                    .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));

                let hiddenPicks = gamesToHide.length;

                // Adjust the logic if the username matches myUsername
                if (username === myUsername) {
                    hiddenPicks = 0;
                    filteredPicks = userPicksFromDB
                        .filter(pick => String(pick.week) === selectedWeek)
                        .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));
                }

                return (
                    <TableRow key={userId}
                        sx={{
                            backgroundColor: userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                        }}
                    >
                        <TableCell className="pool-picks-table-cells" style={{ width: '128px', height: isMobile ? '78px' : '96px', textAlign: 'left', fontSize: isMobile ? '12px' : '16px' }}>
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    color: '#002129',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        marginLeft: isMobile ? '2px' : '8px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontWeight: 700,
                                        width: isSmallScreen ? '120px' : '175px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis', // Add ellipsis for truncated text
                                    }}
                                    onClick={() => {
                                        setUserStatsOpen(true);
                                        setUserStatsInfo(usersEntry);
                                    }}
                                >
                                    {username}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        marginLeft: isMobile ? '2px' : '8px',
                                    }}
                                >
                                    {addNumberSuffix(rank)} - {totalPoints ?? 0} {totalPoints === 1 ? "Pt" : "Pts"}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: isMobile ? '12px' : '16px',
                                    marginLeft: isMobile ? '2px' : '8px',
                                  }}
                                >
                                  Margin ATS: {totalMargin}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell className="pool-picks-table-cells" style={{ width: '247px', height: '78px', fontSize: '12px' }}>
                            <DisplayPicksRow weeklySchedule={sortedSchedule} userPicks={filteredPicks} hiddenPicks={hiddenPicks} selectedWeek={selectedWeek} />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>

        </Table>
      </TableContainer>
  </Box>

    {userStatsOpen && (
      <PickEmUserStatsModal
        statsModalOpen={userStatsOpen}
        setStatsModalOpen={setUserStatsOpen}
        myUsername={userStatsInfo?.username}
        usersPicks={myUsername === userStatsInfo?.username ? userPicksFromDB : findUsersOverallInfo(sortedDataWithRank, userStatsInfo?.username)}
        NFLTeams={NFLTeams}
        NFLScoresBySeason={NFLScoresBySeason}
        startingWeekState={startingWeekState}
        areTheseMyPicks={myUsername === userStatsInfo?.username}
      />
    )}
</>

  );
};

export default PoolPicks;