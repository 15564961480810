// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-members-page-header {
  font-size: 28px !important;
  font-weight: 700 !important;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
@media (max-width: 768px) {
  .manage-members-page-header {
    font-size: 20px !important;
  }
}

.manage-members-page-table-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 0px !important;
  border-left: 2px solid #002129;
  border-right: 2px solid #002129;
}

.manage-members-page-table-header-text {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff !important;
}
@media (max-width: 768px) {
  .manage-members-page-table-header-text {
    font-size: 14px !important;
  }
}

.manage-members-page-table-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #002129 !important;
  text-align: left;
}
@media (max-width: 768px) {
  .manage-members-page-table-text {
    font-size: 12px !important;
  }
}

.manage-members-page-table-text-small {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #002129 !important;
  text-align: left;
}
@media (max-width: 768px) {
  .manage-members-page-table-text-small {
    font-size: 10px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MembersPage/ManageMembers.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,2BAAA;EACA,2BAAA;EACA,8BAAA;AACJ;AACI;EANJ;IAOQ,0BAAA;EAEN;AACF;;AACA;EACI,WAAA;EACA,gBAAA;EACA,cAAA;EACA,6BAAA;EACA,8BAAA;EACA,+BAAA;AAEJ;;AACA;EACI,0BAAA;EACA,2BAAA;EACA,sBAAA;AAEJ;AAAI;EALJ;IAMQ,0BAAA;EAGN;AACF;;AAAA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;EACA,gBAAA;AAGJ;AADI;EANJ;IAOQ,0BAAA;EAIN;AACF;;AADA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;EACA,gBAAA;AAIJ;AAFI;EANJ;IAOQ,0BAAA;EAKN;AACF","sourcesContent":[".manage-members-page-header {\n    font-size: 28px !important;\n    font-weight: 700 !important;\n    margin-top: 32px !important;\n    margin-bottom: 32px !important;\n\n    @media (max-width: 768px) {\n        font-size: 20px !important;\n    }\n}\n\n.manage-members-page-table-container {\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto;\n    border-radius: 0px !important;\n    border-left: 2px solid #002129;\n    border-right: 2px solid #002129;\n}\n\n.manage-members-page-table-header-text {\n    font-size: 20px !important;\n    font-weight: 700 !important;\n    color: #fff !important;\n\n    @media (max-width: 768px) {\n        font-size: 14px !important;\n    }\n}\n\n.manage-members-page-table-text {\n    font-size: 14px !important;\n    font-weight: 400 !important;\n    color: #002129 !important;\n    text-align: left;\n\n    @media (max-width: 768px) {\n        font-size: 12px !important;\n    }\n}\n\n.manage-members-page-table-text-small {\n    font-size: 12px !important;\n    font-weight: 400 !important;\n    color: #002129 !important;\n    text-align: left;\n\n    @media (max-width: 768px) {\n        font-size: 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
