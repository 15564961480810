import React, { useState, useEffect } from 'react';
import  { DateTime } from "luxon";
import './superPickemStyles/pickEmCard.scss';

const lockImage = "https://static.thenounproject.com/png/10982-200.png";

const PickEmCard = ({ game, pickEmState, setPickEmState, userAlreadyHasPick, userPickForSelectedDay,  nbaAwayPointSpread, nbaHomePointSpread, formatDate, quarterForDisplay, nbaGetConference, nbaGetKey, nbaGetLogo, nbaGetShortDisplayName, nbaGetTeamSeed, allGamesAreOver }) => {

    const [topSpreadClicked, setTopSpreadClicked] = useState(false);
    const [bottomSpreadClicked, setBottomSpreadClicked] = useState(false);
    //console.log("USER ALREADY HAVE A PICK?", userAlreadyHasPick);

    useEffect(() => {
        console.log('Updated pickEmState:', pickEmState);
    }, [pickEmState]);

    // Effect to update UI state when pickEmState changes
    useEffect(() => {
        console.log("USE EFFECT is running");
        if (userAlreadyHasPick) {
            const userSelection = userPickForSelectedDay.teamId;
            //console.log("USER ALREADY HAS A PICK", userPickForSelectedDay, userSelection);
            const isTopSpreadSelected = userSelection === game.GlobalAwayTeamID;
            const isBottomSpreadSelected = userSelection === game.GlobalHomeTeamID;
            //console.log("IS TOP SPREAD SELECTED?", isTopSpreadSelected, "IS BOTTOM SPREAD SELECTED?", isBottomSpreadSelected);
            setTopSpreadClicked(isTopSpreadSelected);
            setBottomSpreadClicked(isBottomSpreadSelected);
        } else {
        // Check if the current game is selected in pickEmState
        const isTopSpreadSelected = pickEmState && pickEmState.teamId === game.GlobalAwayTeamID;
        const isBottomSpreadSelected = pickEmState && pickEmState.teamId === game.GlobalHomeTeamID;

        // Update UI state based on pickEmState
        setTopSpreadClicked(isTopSpreadSelected);
        setBottomSpreadClicked(isBottomSpreadSelected);
        }
    }, [pickEmState, game, userAlreadyHasPick, userPickForSelectedDay]);

    const handleTopSpreadClick = () => {
        if (userAlreadyHasPick || hasGameStarted || topSpread === null) {
            return;
        }
        const newPick = {
            gameId: game.GlobalGameID,
            teamId: game.GlobalAwayTeamID,
            spread: nbaAwayPointSpread(game.GlobalGameID),
            dateTime: game.DateTime,
            round: round
        };

        // Check if the clicked pick is already in pickEmState
        const isAlreadyPicked = pickEmState && pickEmState.gameId === newPick.gameId && pickEmState.teamId === newPick.teamId;

        // If the clicked pick is already in pickEmState, remove it
        if (isAlreadyPicked || hasGameStarted) {
            setPickEmState([]);
            //console.log('Top Spread Removed:', newPick);
        } else {
            // Otherwise, set pickEmState to the new pick
            setPickEmState(newPick);
            //console.log('Top Spread Chosen:', newPick);
        }

        setTopSpreadClicked(!topSpreadClicked);
    };

    const handleBottomSpreadClick = () => {
        if (userAlreadyHasPick || hasGameStarted || bottomSpread === null) {
            return;
        }
        const newPick = {
            gameId: game.GlobalGameID,
            teamId: game.GlobalHomeTeamID,
            spread: nbaHomePointSpread(game.GlobalGameID),
            dateTime: game.DateTime,
            round: round
        };

        // Check if the clicked pick is already in pickEmState
        const isAlreadyPicked = pickEmState && pickEmState.gameId === newPick.gameId && pickEmState.teamId === newPick.teamId;

        // If the clicked pick is already in pickEmState, remove it
        if (isAlreadyPicked) {
            setPickEmState([]);
            //console.log('Bottom Spread Removed:', newPick);
        } else {
            // Otherwise, set pickEmState to the new pick
            setPickEmState(newPick);
            //console.log('Bottom Spread Chosen:', newPick);
        }

        setBottomSpreadClicked(!bottomSpreadClicked);
    };





    const gameID = game.GlobalGameID;
    const topID = game.GlobalAwayTeamID;
    const topLogo = nbaGetLogo(topID);
    const topSeed = nbaGetTeamSeed(topID);
    const topName = nbaGetShortDisplayName(topID);
    const topConference = nbaGetConference(topID);
    let topSpread = nbaAwayPointSpread(gameID);
    //let topSpread = null;
    const bottomID = game.GlobalHomeTeamID;
    const bottomLogo = nbaGetLogo(bottomID);
    const bottomSeed = nbaGetTeamSeed(bottomID);
    const bottomName = nbaGetShortDisplayName(bottomID);
    const bottomConference = nbaGetConference(bottomID);
    let bottomSpread = nbaHomePointSpread(gameID);
    const status = game.Status;
    const isClosed = game.IsClosed;
    const dateTime = game.DateTime;
    const gameDate = game.Day;
    let dateTimeForDisplay = null;
    if (dateTime){
    dateTimeForDisplay = formatDate(dateTime);
    } else {
    dateTimeForDisplay = formatDate(gameDate);
    }
    const topScore = game.AwayTeamScore;
    const bottomScore = game.HomeTeamScore;
    const quarter = game.Quarter;
    const quarterDisplay = quarterForDisplay(quarter);
    const minutes = game.TimeRemainingMinutes;
    const seconds = game.TimeRemainingSeconds;
    const homeKey = nbaGetKey(bottomID);
    const awayKey = nbaGetKey(topID);
    const gameNumber = game.SeriesInfo.GameNumber;
    const homeSeriesWins = game.SeriesInfo.HomeTeamWins;
    const awaySeriesWins = game.SeriesInfo.AwayTeamWins;
    const channel = game.Channel;
    const currentTimeInNY = DateTime.now().setZone("America/New_York");
    const currentDateTimeFormatted =  currentTimeInNY.toFormat("yyyy-MM-dd'T'HH:mm:ss")
    const gameTimeInNY = DateTime.fromISO(game.DateTime).setZone("America/New_York");

    const hasGameStarted = currentTimeInNY >= gameTimeInNY;   //PUT THIS BACK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // const hasGameStarted = false;
    //console.log("CURRENT TIME:", currentDateTimeFormatted, "GAME TIME:", game.DateTime, "HAS GAME STARTED?", hasGameStarted);




    const conferenceName = topConference === bottomConference ? topConference : "The Finals";

    const round =
        topConference !== bottomConference
            ? null
            : topSeed + bottomSeed === 9
            ? "Round 1"
            : [1, 8, 4, 5].includes(topSeed) &&
            [1, 8, 4, 5].includes(bottomSeed)
            ? "Round 2"
            : [2, 7, 3, 6].includes(topSeed) &&
            [2, 7, 3, 6].includes(bottomSeed)
            ? "Round 2"
            : "Round 3";


    const topLeftText = round ? `${conferenceName} - ${round} - Game ${gameNumber}` : `${conferenceName} - Game ${gameNumber}`;

    const bottomRightText = status === ("Final" || "F/OT") ? null : channel;
    //console.log("BOTTOM RIGHT TEXT:", status, bottomRightText);

    const bottomLeftText = (isClosed || status === "Final"  || status === "F/OT") ? status : status === "InProgress" ? (
        <span>
        <span style={{ color: 'red', fontWeight: 'bold' }}>LIVE</span> {minutes}:{seconds} {quarterDisplay}
        </span>
    ) : dateTimeForDisplay;


    const topRightText =
        homeSeriesWins === 4
            ? `${homeKey} wins ${homeSeriesWins}-${awaySeriesWins}`
            : awaySeriesWins === 4
            ? `${awayKey} wins ${awaySeriesWins}-${homeSeriesWins}`
            : homeSeriesWins === awaySeriesWins
            ? `Tied ${homeSeriesWins}-${awaySeriesWins}`
            : homeSeriesWins > awaySeriesWins
            ? `${homeKey} leads ${homeSeriesWins}-${awaySeriesWins}`
            : `${awayKey} leads ${awaySeriesWins}-${homeSeriesWins}`;


    const pickSavedForThisGame = userAlreadyHasPick && userPickForSelectedDay.gameId === gameID;
    const pickSavedThisGameBottomSelected = pickSavedForThisGame && userPickForSelectedDay.teamId !== game.GlobalAwayTeamID;
    const pickSavedThisGameTopSelected = pickSavedForThisGame && userPickForSelectedDay.teamId !== game.GlobalHomeTeamID;
    const pickSavedAndNotForThisGame = (userAlreadyHasPick && userPickForSelectedDay.gameId !== gameID) || (hasGameStarted && !userAlreadyHasPick);
    let userSelection = null;
    if (pickSavedForThisGame) {
        userSelection = userPickForSelectedDay.teamId;
    }
    if (pickSavedThisGameBottomSelected) {
        bottomSpread = userPickForSelectedDay.spread;
        topSpread = userPickForSelectedDay.spread * -1;
    }
    if (pickSavedThisGameTopSelected) {
        topSpread = userPickForSelectedDay.spread;
    }
    const gameWinnerActual = !isClosed ? null : topScore > bottomScore ? topID : bottomID;
    const topActualWinner = gameWinnerActual === topID;
    const bottomActualWinner = gameWinnerActual === bottomID;
    const gameWinnerWithSpread = !isClosed ? null : topScore + topSpread > bottomScore ? topID : topScore + topSpread < bottomScore ? bottomID : "Push";
    //console.log("GAME WINNER with Spread:", gameWinnerWithSpread === "Push" ? "Push" : nbaGetKey(gameWinnerWithSpread), "ACTUAL WINNER:", nbaGetKey(gameWinnerActual), "TOP ACTUAL WINNER:", topActualWinner, "BOTTOM ACTUAL WINNER:", bottomActualWinner);
    const result = !isClosed ? "Pending" : gameWinnerWithSpread === userSelection ? "Win" : gameWinnerWithSpread === "Push" ? "Push" : "Loss";
    const buttonColorNotSelected = !isClosed ? null : gameWinnerWithSpread === "Push" ? "#BFC7C9" : "#CCFFEF";
    const buttonBorderColorNotSelected = !isClosed ? null : gameWinnerWithSpread === "Push" ? "#002129" : "#00AA72";
    //console.log("BUTTON COLOR NOT SELECTED:", buttonColorNotSelected, "BUTTON BORDER COLOR NOT SELECTED:", buttonBorderColorNotSelected);
    const borderAndButtonColorSelected = result === "Push" ? "#002129"  : result === "Win" ? "#00AA72" : result === "Loss" ? "#CC293C" : "#00AAD1" ;

    const pickSavedNotForThisGameTopLoser = gameWinnerWithSpread && gameWinnerWithSpread === bottomID;
    const pickSavedNotForThisGameBottomLoser = gameWinnerWithSpread && gameWinnerWithSpread === topID;
    const isGameAPush = gameWinnerWithSpread === "Push";
    //console.log("PICK SAVED NOT FOR THIS GAME TOP LOSER:", pickSavedNotForThisGameTopLoser, "PICK SAVED NOT FOR THIS GAME BOTTOM LOSER:", pickSavedNotForThisGameBottomLoser, "GAME WINNER:", gameWinnerWithSpread === "Push" ? "Push" : nbaGetKey(gameWinnerWithSpread));


    return (
            <div className="pickem-card">
            <div className='top-border' style={pickSavedForThisGame ? { backgroundColor: borderAndButtonColorSelected } : null}>

            <div className='top-left-text' style={pickSavedForThisGame ? { color: "#FFF" } : (pickSavedAndNotForThisGame || hasGameStarted)? {color: "#9D9D9D"} : null}>{topLeftText}</div>
            <div className='top-right-text' style={pickSavedForThisGame ? { color: "#FFF"} : (pickSavedAndNotForThisGame || hasGameStarted)? {color: "#9D9D9D"} : null}>{topRightText}</div>
            </div>


            <div className="middle-box">
            <div className="team-row">
                <div className="logo"><img src={topLogo} alt="Top Team Logo" /></div>
                <div className='seed' style={topActualWinner ? { fontWeight: "bold" } : null}>{topSeed}</div>
                <div className="name" style={topActualWinner ? { fontWeight: "bold"} : null}>{topName}</div>
                <div className="score" style={topActualWinner ? { fontWeight: "bold"} : null}>{topScore}</div>
                {pickSavedThisGameBottomSelected || (pickSavedAndNotForThisGame && pickSavedNotForThisGameTopLoser) ? (
                    <div className="empty-button"></div>
                    ) :
                    (
                    <div className={`pick-button ${topSpreadClicked ? 'clicked' : ''} ${(pickSavedForThisGame && borderAndButtonColorSelected) || (pickSavedAndNotForThisGame && buttonBorderColorNotSelected) ? 'custom-style' : ''}`} onClick={handleTopSpreadClick} style={(pickSavedForThisGame && borderAndButtonColorSelected) ? { border: borderAndButtonColorSelected, background: borderAndButtonColorSelected } : (pickSavedAndNotForThisGame && buttonBorderColorNotSelected) ? { border: `1px solid ${buttonBorderColorNotSelected}`, background: buttonColorNotSelected } : null}>
                        <div className={`pick-button-text ${pickSavedForThisGame ? 'saved' : ((pickSavedAndNotForThisGame && result === "Pending") ? 'other' : '')}`}>
                        {/* Display spread or lock icon */}
                        {topSpread === 0 ? 'Pk' : (topSpread > 0 ? `+ ${topSpread}` : topSpread === null ? <img src={lockImage} alt="Lock Icon" className="lock-icon" /> : `${topSpread}`)}
                    </div>
                </div>
                )
                    }

            </div>
            <div className="team-row">
                <div className="logo"><img src={bottomLogo} alt="Bottom Team Logo" /></div>
                <div className="seed" style={bottomActualWinner ? { fontWeight: "bold"} : null}>{bottomSeed}</div>
                <div className="name" style={bottomActualWinner ? { fontWeight: "bold"} : null}>{bottomName}</div>
                <div className="score" style={bottomActualWinner ? { fontWeight: "bold"} : null}>{bottomScore}</div>
                {pickSavedThisGameTopSelected || (pickSavedAndNotForThisGame && pickSavedNotForThisGameBottomLoser)? (
                    <div className="empty-button"></div>
                    ) :
                (
                    <div className={`pick-button ${bottomSpreadClicked ? 'clicked' : ''} ${(pickSavedForThisGame && borderAndButtonColorSelected) || (pickSavedAndNotForThisGame && buttonBorderColorNotSelected) ? 'custom-style' : ''}`} onClick={handleBottomSpreadClick} style={(pickSavedForThisGame && borderAndButtonColorSelected) ? { border: borderAndButtonColorSelected, background: borderAndButtonColorSelected } : (pickSavedAndNotForThisGame && buttonBorderColorNotSelected) ? { border: `1px solid ${buttonBorderColorNotSelected}`, background: buttonColorNotSelected } : null}>

                    <div className={`pick-button-text ${pickSavedForThisGame ? 'saved' : ((pickSavedAndNotForThisGame && result === "Pending") ? 'other' : '')}`}>
                                {/* Display spread or lock icon */}
                                {bottomSpread === 0 ? 'Pk' : (bottomSpread > 0 ? `+ ${bottomSpread}` : bottomSpread === null ? <img src={lockImage} alt="Lock Icon" className="lock-icon" /> : `${bottomSpread}`)}
                            </div>
                </div>
                ) }
            </div>

            </div>


            <div className="bottom-border">
                <div className="bottom-left-text">{bottomLeftText}</div>
                <div className="bottom-right-text">{bottomRightText}</div>
            </div>
            </div>


    );


  };

export default PickEmCard;