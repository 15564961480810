import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import DynamicModal from "./DynamicModal";
import ResponseModal from "./ResponseModal";
import { usePromoteDemoteCommissioner, useAddRemoveUserFromGame, useUpdatePaidUsers } from "../../hooks/commissioner/index";
import "./ManageMembers.scss";
import RemoveUserModal from "./RemoveUserModal";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../redux/actions/loginImport";

const ManageMembers = ({
    gameData,
    myUserId,
    gameCommissioners,
    gameAdministrators,
    allGameMembers,
    masterPoolId,
    gameId,
    gameName,
    isGameOver,
    doIHaveCommissionerAccess,
    gameAddress,
}) => {
    const dispatch = useDispatch();

    const [modalState, setModalState] = useState(null); // State for modal
    const [modalUserInfo, setModalUserInfo] = useState(null); // State for modal user info
    const [removeModalOpen, setRemoveModalOpen] = useState(false); // State for remove modal
    //console.log("Modal User Info: ", modalUserInfo);

    // In your ManageMembers component, add these new states:
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [addInfoState, setAddInfoState] = useState(false);

    const { mutate: promoteDemoteCommissioner } = usePromoteDemoteCommissioner();
    const { mutate: addRemoveUserFromGame } = useAddRemoveUserFromGame();
    const { mutate: updatePaidUsers } = useUpdatePaidUsers();

    const myInfo = {userId: myUserId};

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Initialize paidUsersArray with an empty array first
    const [paidUsersArray, setPaidUsersArray] = useState([]);

    // Then use useEffect to update it whenever allGameMembers changes
    useEffect(() => {
    if (allGameMembers && allGameMembers.length > 0) {
        const initialPaidUsers = allGameMembers
        .filter(member => Boolean(member.paid))
        .map(member => member.userId);
        
        setPaidUsersArray(initialPaidUsers);
    }
    }, [allGameMembers]);
    const amIAdministrator = gameAdministrators?.includes(myUserId);
    const amICommissioner = gameCommissioners?.includes(myUserId);
    //console.log("game Administrator: ", gameAdministrators, "game Commissioners: ", gameCommissioners);
    //console.log("Am I Admin: ", amIAdministrator, "Am I Commish: ", amICommissioner);

    const trackingInfo = gameData?.trackingInfo;
    const createdGame = gameData?.category === 'created';
    //console.log("Tracking Info: ", trackingInfo, "Created Game: ", createdGame);

    const formatPhoneNumber = (phone) => {
        // Remove all non-numeric characters
        let cleaned = phone.replace(/\D/g, "");
    
        // Remove leading "1" if present
        if (cleaned.startsWith("1")) {
            cleaned = cleaned.slice(1);
        }
    
        // Ensure it's exactly 10 digits before formatting
        if (cleaned.length === 10) {
            return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        }
    
        return phone; // Return original if not 10 digits
    };
    

    const commissionerLogo = (
        <div className="commissioner-logo-circle">
            H
        </div>
    );

    const handlers = {
    handleAddCommissioner: (userInfo) => {
        promoteDemoteCommissioner({
            gameId: gameId,
            memberId: userInfo.userId,
            addOrRemove: 'add',
            commissionerId: myUserId
        }, {
            onSuccess: () => {
                setResponseStatus('success');
                setResponseMessage(`${userInfo.username} has been added as a Co-Host.`);
                setResponseModalOpen(true);
            },
            onError: (error) => {
                // Check for token expiration (401 Unauthorized)
                if (error?.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                    return;
                }
                
                setResponseStatus('error');
                setResponseMessage(error.message || 'Failed to add Co-Host.');
                setResponseModalOpen(true);
            }
        });
    },
    handleRemoveCommissioner: (userInfo) => {
        promoteDemoteCommissioner({
            gameId: gameId,
            memberId: userInfo.userId,
            addOrRemove: 'remove',
            commissionerId: myUserId
        }, {
            onSuccess: () => {
                setResponseStatus('success');
                setResponseMessage(`${userInfo.username} has been removed as a Co-Host.`);
                setResponseModalOpen(true);
            },
            onError: (error) => {
                // Check for token expiration (401 Unauthorized)
                if (error?.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                    return;
                }
                
                setResponseStatus('error');
                setResponseMessage(error.message || 'Failed to remove Co-Host.');
                setResponseModalOpen(true);
            }
        });
    },
    handleRemoveUser: (userInfo, isLeaving) => {
        addRemoveUserFromGame({
            gameId: gameId,
            memberId: userInfo.userId,
            username: userInfo.username,
            userFullName: userInfo.userFullName,
            addOrRemove: 'remove',
            commissionerId: myUserId,
        }, {    
            onSuccess: () => {
                setResponseStatus('success');
                setResponseMessage(`${userInfo.username} has been removed from the pool.`);
                setResponseModalOpen(true);
            },
            onError: (error) => {
                // Check for token expiration (401 Unauthorized)
                if (error?.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                    return;
                }
                
                setResponseStatus('error');
                setResponseMessage(error.message || 'Failed to remove user.');
                setResponseModalOpen(true);
            }
        });
    },
    handleReInstateUser: (userInfo) => {
        addRemoveUserFromGame({
            gameId: gameId,
            memberId: userInfo.userId,
            username: userInfo.username,
            userFullName: userInfo.userFullName,
            addOrRemove: 'add',
            commissionerId: myUserId,
        }, {    
            onSuccess: () => {
                setResponseStatus('success');
                setResponseMessage(`${userInfo.username} has been\nre-instated to the pool.`);
                setResponseModalOpen(true);
            },
            onError: (error) => {
                // Check for token expiration (401 Unauthorized)
                if (error?.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                    return;
                }
                
                setResponseStatus('error');
                setResponseMessage(error.message || 'Failed to re-instate user.');
                setResponseModalOpen(true);
            }
        });
    },
    handleDeleteGame: (userInfo) => {
      console.log("Delete Game: ", userInfo);
    },
    handleAddInfoClick: () => {
        if (addInfoState) {
            updatePaidUsers({
                gameId: gameId,
                paidUsersArray,
                commissionerId: myUserId
            }, {
                onSuccess: () => {
                    setResponseStatus('success');
                    setResponseMessage('User info saved successfully.');
                    setResponseModalOpen(true);
                    setAddInfoState(false);
                },
                onError: (error) => {
                    // Check for token expiration (401 Unauthorized)
                    if (error?.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                        return;
                    }
                    
                    setResponseStatus('error');
                    setResponseMessage(error.message || 'Failed to save paid user info');
                    setResponseModalOpen(true);
                }
            });
        } else {
            setAddInfoState(!addInfoState);
        }
    }
};

    const closeModal = () => {
        setModalState(null);
        setModalUserInfo(null);
    };

    const handlePaidStatusChange = (userId) => {
        setPaidUsersArray(prev => {
            if (prev.includes(userId)) {
                return prev.filter(id => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const handleCancel = () => {
        // Reset to original paid statuses from allGameMembers
        if (allGameMembers && allGameMembers.length > 0) {
            const originalPaidUsers = allGameMembers
                .filter(member => member.paid === true)
                .map(member => member.userId);
            setPaidUsersArray(originalPaidUsers);
        } else {
            setPaidUsersArray([]);
        }
        setAddInfoState(false);
    };

    // All Active Users should be allGameMembers who do not have a removed flag
    const activeUsers = allGameMembers?.filter(user => !user.removed);
    //console.log("activeUsers:", activeUsers, allGameMembers);
    // Removed Users should be allGameMembers who have a removed flag
    const removedUsers = allGameMembers?.filter(user => user.removed);
    //console.log("Removed Users:", removedUsers);

    return (
        <div>
            <Typography className="manage-members-page-header">
                {(amICommissioner || amIAdministrator) ? "Manage" : "Pool"} Members ({activeUsers?.length})
            </Typography>

            {(amIAdministrator || amICommissioner) && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlers.handleAddInfoClick}
                        sx={{ marginBottom: '20px', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                    >
                        {!addInfoState ? 'Add Member Info' : 'Save Info'}
                    </Button>
                    {addInfoState && (
                        <Button
                            variant="contained"
                            onClick={handleCancel}
                            sx={{ marginLeft: '10px', marginBottom: '20px', color: '#002129', border: '1px solid #002129', backgroundColor: '#fff', '&:hover': { backgroundColor: '#fff', color: '#002129' }}}
                        >
                            Cancel
                        </Button>
                    )}
                </>
            )}

            <TableContainer 
                component={Paper} 
                className="manage-members-page-table-container"
            >
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#002129', borderBottom: "2px solid #002129" }}>
                        <TableRow>
                            <TableCell className="manage-members-page-table-header-text">
                                Player (Entries)
                            </TableCell>
                            {!addInfoState ? (
                                <>
                                    <TableCell 
                                        className="manage-members-page-table-header-text" 
                                        align="center"
                                    >
                                        Host
                                    </TableCell>
                                    {(amICommissioner || amIAdministrator) && (
                                        <TableCell 
                                            className="manage-members-page-table-header-text"
                                            align="center" 
                                        >
                                            Remove
                                        </TableCell>
                                    )}
                                </>
                            ) : (
                                <>
                                    <TableCell 
                                        className="manage-members-page-table-header-text"
                                        align="center"
                                    >
                                        {createdGame ? "In?" : "Paid?"}
                                    </TableCell>
                                    {trackingInfo && (
                                        <TableCell 
                                            className="manage-members-page-table-header-text"
                                            align="center"
                                        >
                                            Referral
                                        </TableCell>
                                    )}
                                </>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {activeUsers?.map((user, index) => (
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                            >
                                <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                    <div className="manage-members-page-table-text">
                                        <span>{user?.userFullName} {`(${user?.entries?.length})`}</span>
                                        {(amICommissioner || amIAdministrator) && user?.paid && (
                                            createdGame ? 
                                                <CheckIcon sx={{ color: '#00AA72', fontSize: '20px' }} />
                                                : <AttachMoneyIcon sx={{ color: '#00AA72', fontSize: '20px' }} />
                                        )}
                                    </div>
                                    <div className="manage-members-page-table-text-small">
                                        {user?.username}
                                    </div>
                                    {(amIAdministrator || amICommissioner) && (
                                        <>
                                            <div className="manage-members-page-table-text-small">
                                                {user?.email}
                                            </div>
                                            <div className="manage-members-page-table-text-small">
                                                {user?.phone ? formatPhoneNumber(user.phone) : ""}
                                            </div>
                                        </>
                                    )}
                                </TableCell>

                                {!addInfoState ? (
                                    <>
                                        <TableCell className='private-commissioner-table' align="center" sx={{ padding: '0px'}}>
                                            {(gameAdministrators?.includes(user?.userId) || gameCommissioners?.includes(user?.userId)) ? (
                                                <>
                                                    {commissionerLogo}
                                                    {!gameAdministrators?.includes(user?.userId) && (amICommissioner || amIAdministrator) && (
                                                        <RemoveIcon
                                                            sx={{ color: '#CC293C', cursor: 'pointer', marginLeft: isMobile ? '5px' : '10px' }}
                                                            onClick={() => { setModalUserInfo(user); setModalState("confirmRemoveCommissioner")}}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                (amICommissioner || amIAdministrator) && (
                                                    <AddIcon
                                                         sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                         onClick={() => { setModalUserInfo(user); setModalState("confirmAdd")}}
                                                    />
                                                )
                                            )}
                                        </TableCell>

                                        {(amICommissioner || amIAdministrator) && (
                                            <TableCell className='private-commissioner-table' align="center">
                                                {gameAdministrators?.includes(user?.userId) ? (
                                                    <div>N/A</div>
                                                ) : (
                                                    <CloseIcon
                                                        sx={{ color: '#CC293C', cursor: 'pointer' }}
                                                        onClick={() => {setModalUserInfo(user); setRemoveModalOpen(true)}}
                                                    />
                                                )}
                                            </TableCell>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <TableCell className='private-commissioner-table' align="center" sx={{ padding: '6px' }}>
                                            <Checkbox
                                                checked={paidUsersArray?.includes(user?.userId)}
                                                onChange={() => handlePaidStatusChange(user?.userId)}
                                                sx={{
                                                    color: '#002129',
                                                    '&.Mui-checked': {
                                                        color: '#00AA72',
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                        {trackingInfo && (
                                            <TableCell className='private-commissioner-table' align="center" sx={{ padding: '6px' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {user?.referredBy}
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
            </TableContainer>

            {/* {removedUsers?.length > 0 && (
                <>
                    <Typography className="manage-members-page-header">
                        Removed Members ({removedUsers?.length})
                    </Typography>

                    <TableContainer 
                        component={Paper} 
                        className="manage-members-page-table-container"
                    >
                        <Table aria-label="removed members table">
                            <TableHead sx={{ backgroundColor: '#002129', borderBottom: "2px solid #002129" }}>
                                <TableRow>
                                    <TableCell 
                                        className="manage-members-page-table-header-text"
                                    >
                                        Player
                                    </TableCell>
                                    {(amICommissioner || amIAdministrator) && (
                                        <TableCell 
                                            className="manage-members-page-table-header-text"
                                            align="center"
                                        >
                                            Restore
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {removedUsers?.map((user, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                                    >
                                        <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    fontSize: '12px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {user.userFullName}
                                            </div>
                                            <div style={{ fontSize: '10px' }}>
                                                {user.username}
                                            </div>
                                            {(amIAdministrator || amICommissioner) && (
                                                <>
                                                    <div className="manage-members-page-table-text-small">
                                                        {user?.email}
                                                    </div>
                                                    <div className="manage-members-page-table-text-small">
                                                        {user?.phone ? formatPhoneNumber(user.phone) : ""}
                                                    </div>
                                                </>
                                            )}
                                        </TableCell>

                                        {(amICommissioner || amIAdministrator) && (
                                            <TableCell className='private-commissioner-table' align="center">
                                                <AddIcon
                                                    sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                    onClick={() => { 
                                                        setModalUserInfo(user); 
                                                        //console.log("User Info: ", user);
                                                        setModalState("confirmReinstateUser");
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
                    </TableContainer>
                </>
            )} */}

            
            {/* {amIAdministrator ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(gameId); setModalState("confirmDelete")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    DELETE POOL
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(myInfo); setModalState("confirmLeave")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    LEAVE POOL
                </Button>
            )} */}

            <Box sx={{ height: '32px' }} />

            <RemoveUserModal
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                userInfo={modalUserInfo}
                gameName={gameName}
                masterPoolId={masterPoolId}
                gameId={gameId}
                myUserId={myUserId}
            />

            <DynamicModal
              open={!!modalState}
              onClose={closeModal}
              modalState={modalState}
              modalUserInfo={modalUserInfo}
              gameName={gameName}
              myUserId={myUserId}
              handlers={handlers}
            />

            <ResponseModal
              open={responseModalOpen}
              onClose={() => setResponseModalOpen(false)}
              status={responseStatus}
              message={responseMessage}
            />
               
        </div>
            
    );
}

export default ManageMembers;
