import React, {useEffect} from 'react';
import LeaderBoardCountdown from './leaderBoardCountdown';
import LeaderBoardHeader from './leaderBoardHeader';
import LeaderBoard from './leaderBoard';
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage, getUsersForLeaderboardAction } from '../../../redux/actions/loginImport';
import { fetchCBBTeams, fetchCBBLeagueHierarchy, fetchNCAATournamentHierarchy } from '../../../redux/actions/dataImport';

function LeaderBoardContainer(props) {
    const dispatch = useDispatch();
    console.log('LeaderBoardProps', props);
    const {cbbTeamsData} = props;
    useEffect(() => {
        dispatch(getUsersForLeaderboardAction());
        dispatch(fetchCBBTeams());
    }, [dispatch]); // Runs only once when the component mounts

    const newAllUsers = useSelector(state => state.login.getUsersForLeaderboardReducer.data);
    console.log('LeaderBoardContainer', newAllUsers);

    return (
        <div>

            {/* <LeaderBoardHeader /> */}
            <LeaderBoard props={props} cbbTeamsData={cbbTeamsData} newAllUsers={newAllUsers}/>
            {/* <LeaderBoardCountdown /> */}
            {/* Rest of your component code */}
        </div>
    );
}

export default LeaderBoardContainer;
