import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordStrength from "./PasswordStrength"; // Import the PasswordStrength component
export default function PasswordSetup({
  goToNextStep,
  goToPrevStep,
  password,
  setPassword,
  passwordConfirmation,  
  setPasswordConfirmation,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [allFormFieldsValid, setAllFormFieldsValid] = useState(false);

  const handlePasswordValidationChange = (isValid) => {
    //console.log("THIS IS VALID___________________________________", isValid);
    setAllFormFieldsValid(isValid);
  };

  // Define the requirements object
  const requirements = {
    letters: 1,
    digits: 1,
    caseDiff: 1,
    special: 1,
    minLength: 8,
    maxLength: 40,
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setPasswordConfirmation(newConfirmPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const passwordsMatch = password === confirmPassword && password.length > 0;

  const handlePasswordValidation = () => {
    const isValidLength = password.length > requirements.minLength;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (isValidLength && hasUpperCase && hasLowerCase && hasNumber & hasSpecialCharacter) {
      goToNextStep();
    }
  };


  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <TextField
        id="password"
        name="password"
        label="Password"
        variant="outlined"
        type={showPassword ? "text" : "password"} // Change type based on showPassword state
        value={password}
        onChange={handlePasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        variant="outlined"
        type={showConfirmPassword ? "text" : "password"} // Change type based on showPassword state
        value={passwordConfirmation}
        onChange={handleConfirmPasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleConfirmPasswordVisibility}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
        <Button onClick={goToPrevStep}>Back</Button>
        <Button
          variant="contained"
          onClick={handlePasswordValidation}
          disabled={!allFormFieldsValid}
        >
          Continue
        </Button>
      </Box>
      <PasswordStrength
        password={password}
        confirmPassword={passwordConfirmation}
        requirements={requirements}
        onValidationChange={handlePasswordValidationChange}
      />
    </Box>
  );
}
