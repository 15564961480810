import { apiClient } from '../../apiClient';
import { userLogoutAction } from './loginImport';
import { apiUrl } from '../../config';

// Action types
export const FETCH_GAMES_BY_WEEK_REQUEST = 'FETCH_GAMES_BY_WEEK_REQUEST';
export const FETCH_GAMES_BY_WEEK_FAIL = 'FETCH_GAMES_BY_WEEK_FAIL';
export const FETCH_GAMES_BY_WEEK_SUCCESS = 'FETCH_GAMES_BY_WEEK_SUCCESS';
export const FETCH_SEASON_SCHEDULE_REQUEST = 'FETCH_SEASON_SCHEDULE_REQUEST';
export const FETCH_SEASON_SCHEDULE_FAIL = 'FETCH_SEASON_SCHEDULE_FAIL';
export const FETCH_SEASON_SCHEDULE_SUCCESS = 'FETCH_SEASON_SCHEDULE_SUCCESS';
export const FETCH_NFL_TEAMS_REQUEST = 'FETCH_NFL_TEAMS_REQUEST';
export const FETCH_NFL_TEAMS_FAIL = 'FETCH_NFL_TEAMS_FAIL';
export const FETCH_NFL_TEAMS_SUCCESS = 'FETCH_NFL_TEAMS_SUCCESS';
export const FETCH_SEASON_SCHEDULE_BASICS_REQUEST = 'FETCH_SEASON_SCHEDULE_BASICS_REQUEST';
export const FETCH_SEASON_SCHEDULE_BASICS_FAIL = 'FETCH_SEASON_SCHEDULE_BASICS_FAIL';
export const FETCH_SEASON_SCHEDULE_BASICS_SUCCESS = 'FETCH_SEASON_SCHEDULE_BASICS_SUCCESS';
export const FETCH_NFL_SCORES_REQUEST = 'FETCH_NFL_SCORES_REQUEST';
export const FETCH_NFL_SCORES_FAIL = 'FETCH_NFL_SCORES_FAIL';
export const FETCH_NFL_SCORES_SUCCESS = 'FETCH_NFL_SCORES_SUCCESS';
export const FETCH_CBB_SCORES_REQUEST = 'FETCH_CBB_SCORES_REQUEST';
export const FETCH_CBB_SCORES_FAIL = 'FETCH_CBB_SCORES_FAIL';
export const FETCH_CBB_SCORES_SUCCESS = 'FETCH_CBB_SCORES_SUCCESS';
export const FETCH_CBB_TEAMS_REQUEST = 'FETCH_CBB_TEAMS_REQUEST';
export const FETCH_CBB_TEAMS_FAIL = 'FETCH_CBB_TEAMS_FAIL';
export const FETCH_CBB_TEAMS_SUCCESS = 'FETCH_CBB_TEAMS_SUCCESS';
export const FETCH_CBB_LEAGUE_HIERARCHY_REQUEST = 'FETCH_CBB_LEAGUE_HIERARCHY_REQUEST';
export const FETCH_CBB_LEAGUE_HIERARCHY_FAIL = 'FETCH_CBB_LEAGUE_HIERARCHY_FAIL';
export const FETCH_CBB_LEAGUE_HIERARCHY_SUCCESS = 'FETCH_CBB_LEAGUE_HIERARCHY_SUCCESS';
export const FETCH_NCAA_TOURNAMENT_HIERARCHY_REQUEST = 'FETCH_NCAA_TOURNAMENT_HIERARCHY_REQUEST';
export const FETCH_NCAA_TOURNAMENT_HIERARCHY_FAIL = 'FETCH_NCAA_TOURNAMENT_HIERARCHY_FAIL';
export const FETCH_NCAA_TOURNAMENT_HIERARCHY_SUCCESS = 'FETCH_NCAA_TOURNAMENT_HIERARCHY_SUCCESS';
export const FETCH_NBA_TEAMS_REQUEST = 'FETCH_NBA_TEAMS_REQUEST';
export const FETCH_NBA_TEAMS_FAIL = 'FETCH_NBA_TEAMS_FAIL';
export const FETCH_NBA_TEAMS_SUCCESS = 'FETCH_NBA_TEAMS_SUCCESS';
export const FETCH_NBA_STANDINGS_REQUEST = 'FETCH_NBA_STANDINGS_REQUEST';
export const FETCH_NBA_STANDINGS_FAIL = 'FETCH_NBA_STANDINGS_FAIL';
export const FETCH_NBA_STANDINGS_SUCCESS = 'FETCH_NBA_STANDINGS_SUCCESS';
export const FETCH_NBA_GAME_ODDS_BY_DATE_REQUEST = 'FETCH_NBA_GAME_ODDS_BY_DATE_REQUEST';
export const FETCH_NBA_GAME_ODDS_BY_DATE_FAIL = 'FETCH_NBA_GAME_ODDS_BY_DATE_FAIL';
export const FETCH_NBA_GAME_ODDS_BY_DATE_SUCCESS = 'FETCH_NBA_GAME_ODDS_BY_DATE_SUCCESS';
export const FETCH_NBA_GAMES_BY_DATE_REQUEST = 'FETCH_NBA_GAMES_BY_DATE_REQUEST';
export const FETCH_NBA_GAMES_BY_DATE_FAIL = 'FETCH_NBA_GAMES_BY_DATE_FAIL';
export const FETCH_NBA_GAMES_BY_DATE_SUCCESS = 'FETCH_NBA_GAMES_BY_DATE_SUCCESS';

// export const fetchData = () => {
//   return async (dispatch) => {
//     try {
//       const response = await axios.get('https://api.example.com/data');
//       dispatch({
//         type: 'FETCH_DATA_SUCCESS',
//         payload: response.data,
//       });
//     } catch (error) {
//       dispatch({
//         type: 'FETCH_DATA_FAILURE',
//         payload: error.message,
//       });
//     }
//   };
// };

export const fetchGamesByWeekRequest = (data) => ({
  type: FETCH_GAMES_BY_WEEK_REQUEST,
  payload: data
});

export const fetchGamesByWeekSuccess = (data) => ({
  type: FETCH_GAMES_BY_WEEK_SUCCESS,
  payload: data,
});

export const fetchGamesByWeekFail = (error) => ({
  type:  FETCH_GAMES_BY_WEEK_FAIL,
  payload: error,
});

export const fetchSeasonScheduleRequest = (data) => ({
  type: FETCH_SEASON_SCHEDULE_REQUEST,
  payload: data
});

export const fetchSeasonScheduleSuccess = (data) => ({
  type: FETCH_SEASON_SCHEDULE_SUCCESS,
  payload: data,
});

export const fetchSeasonScheduleFail = (error) => ({
  type:  FETCH_SEASON_SCHEDULE_FAIL,
  payload: error,
});

export const fetchSeasonScheduleBasicsRequest = (data) => ({
  type: FETCH_SEASON_SCHEDULE_BASICS_REQUEST,
  payload: data
});

export const fetchSeasonScheduleBasicsSuccess = (data) => ({
  type: FETCH_SEASON_SCHEDULE_BASICS_SUCCESS,
  payload: data,
});

export const fetchSeasonScheduleBasicsFail = (error) => ({
  type:  FETCH_SEASON_SCHEDULE_BASICS_FAIL,
  payload: error,
});

export const fetchNFLTeamsRequest = (data) => ({
  type: FETCH_NFL_TEAMS_REQUEST,
  payload: data
});

export const fetchNFLTeamsSuccess = (data) => ({
  type: FETCH_NFL_TEAMS_SUCCESS,
  payload: data,
});

export const fetchNFLTeamsFail = (error) => ({
  type:  FETCH_NFL_TEAMS_FAIL,
  payload: error,
});

export const fetchNFLScoresRequest = (data) => ({
  type: FETCH_NFL_SCORES_REQUEST,
  payload: data
});

export const fetchNFLScoresSuccess = (data) => ({
  type: FETCH_NFL_SCORES_SUCCESS,
  payload: data,
});

export const fetchNFLScoresFail = (error) => ({
  type:  FETCH_NFL_SCORES_FAIL,
  payload: error,
});

export const fetchCBBScoresRequest = (data) => ({
  type: FETCH_CBB_SCORES_REQUEST,
  payload: data
});

export const fetchCBBScoresSuccess = (data) => ({
  type: FETCH_CBB_SCORES_SUCCESS,
  payload: data,
});

export const fetchCBBScoresFail = (error) => ({
  type:  FETCH_CBB_SCORES_FAIL,
  payload: error,
});

export const fetchCBBTeamsRequest = (error) => ({
  type: FETCH_CBB_TEAMS_REQUEST,
  payload: error,
});

export const fetchCBBTeamsSuccess = (error) => ({
  type: FETCH_CBB_TEAMS_SUCCESS,
  payload: error,
});

export const fetchCBBTeamsFail = (error) => ({
  type: FETCH_CBB_TEAMS_FAIL,
  payload: error,
});

export const fetchCBBLeagueHierarchyRequest = (error) => ({
  type: FETCH_CBB_LEAGUE_HIERARCHY_REQUEST,
  payload: error,
});

export const fetchCBBLeagueHierarchySuccess = (error) => ({
  type: FETCH_CBB_LEAGUE_HIERARCHY_SUCCESS,
  payload: error,
});

export const fetchCBBLeagueHierarchyFail = (error) => ({
  type: FETCH_CBB_LEAGUE_HIERARCHY_FAIL,
  payload: error,
});

export const fetchNCAATournamentHierarchyRequest = (error) => ({
  type: FETCH_NCAA_TOURNAMENT_HIERARCHY_REQUEST,
  payload: error,
});

export const fetchNCAATournamentHierarchySuccess = (error) => ({
  type: FETCH_NCAA_TOURNAMENT_HIERARCHY_SUCCESS,
  payload: error,
});

export const fetchNCAATournamentHierarchyFail = (error) => ({
  type: FETCH_NCAA_TOURNAMENT_HIERARCHY_FAIL,
  payload: error,
});

export const fetchNBATeamsRequest = (error) => ({
  type: FETCH_NBA_TEAMS_REQUEST,
  payload: error,
});

export const fetchNBATeamsSuccess = (error) => ({
  type: FETCH_NBA_TEAMS_SUCCESS,
  payload: error,
});

export const fetchNBATeamsFail = (error) => ({
  type: FETCH_NBA_TEAMS_FAIL,
  payload: error,
});

export const fetchNBAStandingsRequest = (error) => ({
  type: FETCH_NBA_STANDINGS_REQUEST,
  payload: error,
});

export const fetchNBAStandingsSuccess = (error) => ({
  type: FETCH_NBA_STANDINGS_SUCCESS,
  payload: error,
});

export const fetchNBAStandingsFail = (error) => ({
  type: FETCH_NBA_STANDINGS_FAIL,
  payload: error,
});

export const fetchNBAGameOddsByDateRequest = (error) => ({
  type: FETCH_NBA_GAME_ODDS_BY_DATE_REQUEST,
  payload: error,
});

export const fetchNBAGameOddsByDateSuccess = (error) => ({
  type: FETCH_NBA_GAME_ODDS_BY_DATE_SUCCESS,
  payload: error,
});

export const fetchNBAGameOddsByDateFail = (error) => ({
  type: FETCH_NBA_GAME_ODDS_BY_DATE_FAIL,
  payload: error,
});

export const fetchNBAGamesByDateRequest = (error) => ({
  type: FETCH_NBA_GAMES_BY_DATE_REQUEST,
  payload: error,
});

export const fetchNBAGamesByDateSuccess = (error) => ({
  type: FETCH_NBA_GAMES_BY_DATE_SUCCESS,
  payload: error,
});

export const fetchNBAGamesByDateFail = (error) => ({
  type: FETCH_NBA_GAMES_BY_DATE_FAIL,
  payload: error,
});

export const fetchWeeklyGames = (data) => async (dispatch) => {
  dispatch(fetchGamesByWeekRequest(data));
  const {seasonYear, weekNumber, league} = data;
  try {
    const response = await apiClient.get(`https://api.sportsdata.io/v3/${league}/scores/json/ScoresBasic/${seasonYear}/${weekNumber}?key=bdb8603d3de34d20a50402c388633d20`);
    console.log(response.data);
      dispatch(fetchGamesByWeekSuccess(response.data));
  } catch (e) {
      dispatch(fetchGamesByWeekFail(e.response.data));
  }
}

export const fetchSeason = (data) => async (dispatch) => {
  dispatch(fetchSeasonScheduleRequest(data));
  const {seasonYear, league} = data;
  try {
    const response = await apiClient.get(`${apiUrl}/sports-data/get-nfl-schedule`);
    console.log('fetch season', response.data);
      dispatch(fetchSeasonScheduleSuccess(response.data));
  } catch (e) {
      dispatch(fetchSeasonScheduleFail(e.response.data));
      dispatch(userLogoutAction());
  }
}

export const fetchBasicsSeason = (data) => async (dispatch) => {
  dispatch(fetchSeasonScheduleBasicsRequest(data));
  const {seasonYear, league} = data;
  try {
    const response = await apiClient.get(`${apiUrl}/sports-data/get-nfl-schedule-basics`);
      console.log('fetch basics season', response.data);
      dispatch(fetchSeasonScheduleBasicsSuccess(response.data));
  } catch (e) {
      dispatch(fetchSeasonScheduleBasicsFail(e.response.data));
      dispatch(userLogoutAction());
  }
}

export const fetchNFLTeams = (data) => async (dispatch) => {
  dispatch(fetchNFLTeamsRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nfl-teams`);
    console.log('fetch nfl teams', response);
      dispatch(fetchNFLTeamsSuccess(response.data.data));
  } catch (e) {
      dispatch(fetchNFLTeamsFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNFLScores = (data) => async (dispatch) => {
  dispatch(fetchNFLScoresRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nfl-scores`);
    console.log('fetch nfl scores', response);
      dispatch(fetchNFLScoresSuccess(response.data.data));
  } catch (e) {
      dispatch(fetchNFLScoresFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchCBBScores = (data) => async (dispatch) => {
  dispatch(fetchCBBScoresRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-cbb-scores`);
    console.log('fetch cbb scores', response);
      dispatch(fetchCBBScoresSuccess(response.data.data));
  } catch (e) {
      dispatch(fetchCBBScoresFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchCBBTeams = (data) => async (dispatch) => {
  dispatch(fetchCBBTeamsRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-cbb-teams`);
    console.log('fetch cbb teams', response);
      dispatch(fetchCBBTeamsSuccess(response.data));
  } catch (e) {
      dispatch(fetchCBBTeamsFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchCBBLeagueHierarchy = (data) => async (dispatch) => {
  dispatch(fetchCBBLeagueHierarchyRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-cbb-league-hierarchy`);
    console.log('fetch cbb league hierarchy', response);
      dispatch(fetchCBBLeagueHierarchySuccess(response.data));
  } catch (e) {
      dispatch(fetchCBBLeagueHierarchyFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNCAATournamentHierarchy = (data) => async (dispatch) => {
  dispatch(fetchNCAATournamentHierarchyRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-ncaa-tournament-hierarchy`);
    console.log('fetch ncaa tournament hierarchy', response);
      dispatch(fetchNCAATournamentHierarchySuccess(response.data));
  } catch (e) {
      dispatch(fetchNCAATournamentHierarchyFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNBATeams = (data) => async (dispatch) => {
  dispatch(fetchNBATeamsRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nba-teams`);
    console.log('fetch nba teams', response);
      dispatch(fetchNBATeamsSuccess(response.data));
  } catch (e) {
      dispatch(fetchNBATeamsFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNBAStandings = (data) => async (dispatch) => {
  dispatch(fetchNBAStandingsRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nba-standings`);
    console.log('fetch nba standings', response);
      dispatch(fetchNBAStandingsSuccess(response.data));
  } catch (e) {
      dispatch(fetchNBAStandingsFail(e.response?.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNBAGameOddsByDate = (data) => async (dispatch) => {
  dispatch(fetchNBAGameOddsByDateRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nba-game-odds-by-date`);
    console.log('fetch nba game odds by date', response);
      dispatch(fetchNBAGameOddsByDateSuccess(response.data));
  } catch (e) {
      dispatch(fetchNBAGameOddsByDateFail(e.response.data));
      dispatch(userLogoutAction());
  }

}

export const fetchNBAGamesByDate = (data) => async (dispatch) => {
  dispatch(fetchNBAGamesByDateRequest(data));
  try {

    let response = await apiClient.get(`${apiUrl}/sports-data/get-nba-games-by-date`);
    console.log('fetch nba games by date', response);
      dispatch(fetchNBAGamesByDateSuccess(response.data));
  } catch (e) {
      dispatch(fetchNBAGamesByDateFail(e.response.data));
      dispatch(userLogoutAction());
  }

}
