import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const GameLogoDisplay = ({ doesUserBelong, logo, gameName, gameLogoDisplayInfo, setJoinOpen, hasJoinDeadlinePassed }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { text, bottomText } = gameLogoDisplayInfo;

    const handleJoinClick = () => {
        setJoinOpen(true);
    }

    return (
        <Box
            sx={{
                backgroundColor: "#F2F2F2",
                padding: '8px 0px 12px 0px',
            }}
        >
            {/* <Box>
                {text?.map((textItem, index) => (  
                    <Typography
                        key={index}
                        sx={{
                            fontWeight: '700',
                            fontSize: index === 0 ? (isMobile ? '14px' : '20px') : (isMobile ? '16px' : '24px'),
                            fontStyle: textItem.italic ? 'italic' : 'normal',
                        }}
                    >
                        {textItem.line}
                    </Typography>
                ))}
            </Box> */}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: isMobile ? '235px' : '400px',
                    margin: '0 auto',
                    marginBottom: '12px',
                    marginTop: '8px',
                }}
            >
                <img src={logo} alt={gameName} />
            </Box>

            {bottomText && !doesUserBelong && (
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '12px',
                        fontWeight: '700',
                        fontSize: isMobile ? '12px' : '14px',
                    }}
                >
                    {bottomText}
                </Typography>
            )}

            {!hasJoinDeadlinePassed && !doesUserBelong && (
                <Button
                    variant="contained"
                    className="game-home-page-button join-pool-button"
                    onClick={() => handleJoinClick()}
                >
                    JOIN POOL
                </Button>
            )}
        </Box>
    );
}

export default GameLogoDisplay;