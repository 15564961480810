import React from "react";
import { Typography, Box } from '@mui/material';
import './PillBox.scss';

const PercentagePillBoxWithText = ({
    count,
    total,
    result,
}) => {

    const percentage = total > 0 ? (count / total) * 100 : 0;

    // Format the percentage
    const formattedPercentage = Number.isInteger(percentage)
    ? percentage
    : percentage.toFixed(1);

    return (
        <Box className="pill-box-with-text-container">
            <Box 
                className="pill-box-with-text-left-half-container"
                sx={{
                    border: `1px solid ${
                        result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129'
                    }`,
                    width: `${percentage}%`,
                    backgroundColor: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129',
                }}
            >
                <Typography className="pill-box-with-text-left-half-text">
                    {percentage >= 50 ? `${count} (${formattedPercentage}%)` : ''}
                </Typography>
            </Box>
            <Box
                className="pill-box-with-text-right-half-container"
                sx={{
                    border: `1px solid ${
                        result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129'
                    }`,
                    width: `${100-percentage}%`,
                }}
            >
                <Typography
                    className="pill-box-with-text-right-half-text"
                    sx={{
                        color: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129'
                    }}
                >
                    {percentage < 50 ? `${count} (${formattedPercentage}%)` : ''}
                </Typography>
            </Box>
        </Box>
    )
}

export default PercentagePillBoxWithText;