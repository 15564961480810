import React from "react";
import { Box, Typography } from "@mui/material";
import "./GameHomePageComponents.scss";

// Then, refactor your component to use this data
const HomePageScreenshots = ({ images, handleOpenModal }) => {
    // Split the images array into chunks of 2 for our rows
    const rows = images?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);
        
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new row
        }
        
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <Box className="game-home-page-screen-capture-main-container">
            <Box className="game-home-page-screen-capture-row">
                {rows?.map((row, rowIndex) => (
                    <Box key={`row-${rowIndex}`} className="game-home-page-screen-capture-pair-container">
                        {row.map((item) => (
                            <Box key={item.name} className="game-home-page-screen-capture-container">
                                <Typography className="game-home-page-screen-capture-title">
                                    {item.name}
                                </Typography>
                                <Box 
                                    className="game-home-page-screen-capture-image-box"
                                    onClick={() => handleOpenModal(item.image)}
                                >
                                    <img 
                                        src={item.image}
                                        alt={item.name}
                                        className="game-home-page-screen-capture-image" 
                                    />
                                </Box>       
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default HomePageScreenshots;