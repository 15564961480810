import React from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const SponsorPoolsCard = (props) => {
    const theme = useTheme(); 
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); 
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const navigateToTop = useNavigateToTop();
    
    const handleButtonClick = () => {
        let url = props?.link;
        window.location.href = url;
    };
    

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // This helps distribute space
                padding: '16px',
                border: isMobile ? '2px solid #E8E8E8' : '4px solid #E8E8E8',
                borderRadius: '8px',
                width: '90%', // Take full width of parent container
                maxWidth: isMobile ? '350px' : '500px', // Fixed width on mobile
                height: isLargeScreen ? '400px' : 'auto', // Fixed height on large screens
                margin: '0 auto',
            }}
            onClick={handleButtonClick}
        >
            {/* Header Section - Top */}
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                mb: 2
            }}>
                {props?.header && (
                    <Typography
                        sx={{
                            fontSize: isMobile ? '14px' : '20px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center'
                        }}
                    >
                        {props?.header}
                    </Typography>
                )}

                {props?.subHeader && (
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '18px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center'
                        }}
                    >
                        {props?.subHeader}
                    </Typography>
                )}
            </Box>

            {/* Content Section - Middle (with flex-grow to take available space) */}
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                flexGrow: 1, 
                justifyContent: 'center',
                overflow: 'hidden'
            }}>
                {props?.image && (
                    <Box
                        sx={{
                            height: 'auto',
                            width: 'auto',
                            maxWidth: isMobile ? '200px' : '250px',
                            maxHeight: isMobile ? '100px' : '150px',
                            margin: '8px 0',
                        }}
                    >
                        <img
                            src={props?.image}
                            alt="pool"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </Box>
                )}

                <Box sx={{ 
                    overflow: 'auto', 
                    maxHeight: '150px',
                    width: '100%',
                    textAlign: 'center',
                    mb: 2,
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#E8E8E8',
                        borderRadius: '4px',
                    }
                }}>
                    {props?.message?.map((message, index) => (
                        <Typography
                            key={index}
                            sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '500',
                                color: '#002129',
                                mb: 1
                            }}
                        >
                            {message}
                        </Typography>
                    ))}
                </Box>
            </Box>

            {/* Footer Section - Bottom */}
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                mt: 'auto'
            }}>
                {props?.prizes && (
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center',
                            margin: '8px 0',
                        }}
                    >
                        Prizes: 
                        <Box
                            component="span"
                            sx={{
                                fontWeight: '500',
                            }}
                        >
                            {` ${props?.prizes}`}
                        </Box>
                    </Typography>
                )}

                {props?.buttonText && (
                    <Button
                        variant='contained'
                        color='primary'
                        sx={{
                            padding: '8px 16px',
                            borderRadius: '4px',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            border: 'none',
                            cursor: 'pointer',
                            mt: 2,
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                                backgroundColor: '#002129',
                            }
                        }}
                        onClick={handleButtonClick}
                    >
                        {props?.buttonText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default SponsorPoolsCard;