

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";
import { mulligenMadnes } from '../../../redux/actions/gameImport';
import Button from '@mui/material/Button';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';

import '@mui/material/styles';
import '../bracketStyles/marchMadnessBracket.scss';
import { Input, Typography } from '@mui/material';
import VectorCrown from '../../../assets/images/VectorCrown.svg';

const MarchMadnessBracket = (props) => {
  const { cbbTeamsData, ncaaTournamentHierarchyData } = props;
  const CBBTeams = cbbTeamsData?.data;
  const ncaaTournamentHierarchy = ncaaTournamentHierarchyData?.data;
  const dispatch = useDispatch();
  const userData = getUserDataFromLocalStorage();

  const [pickState, setPickState] = useState(userData?.MulligenMadness[0]?.picks || []);
  const [displayState, setDisplayState] = useState(userData?.MulligenMadness[0]?.display || {});
  const [champion, setChampion] = useState('');
  const [finalFourMatchups, setFinalFourMatchups] = useState({
    finalFourTeams: {}, // To hold the winning team from each bracket for the Final Four
    championshipTeams: [] // To hold the two teams competing in the Championship
  });

  const [leftScore, setLeftScore] = useState(userData?.MulligenMadness[0]?.tieBreaker?.leftPoints || '');
  const [rightScore, setRightScore] = useState(userData?.MulligenMadness[0]?.tieBreaker?.rightPoints || '');

  const handleLeftScoreChange = (event) => {
      setLeftScore(event.target.value);
  };

  const handleRightScoreChange = (event) => {
      setRightScore(event.target.value);
  };


  useEffect(() => {
    // Log pickState when it changes to see the updates
    console.log('Updated PICKSTATE REMAINING', pickState);
    console.log('Updated DISPLAYSTATE', displayState);
  }, [pickState, displayState]); // This useEffect will run every time pickState changes

  const findTeamLogoUrlBySchoolName = (TeamID) => {
    console.log('pickState',TeamID)
    const team = CBBTeams.find(team => team.GlobalTeamID === TeamID);
    return team ? team.TeamLogoUrl : null;
  };

   async function handleSavePicks() {
        let data = {
            picks: pickState,
            display: displayState,
            tieBreaker: {
                leftPoints: leftScore,
                rightPoints: rightScore
            }
        }

        await dispatch(mulligenMadnes(data));
        
    }

  function isGameAlreadyPicked(pickState, whatRound, bracket, displayOrder) {
    try {
        // Search for an entry in pickState that matches the criteria
        const pickedGame = pickState.find(entry =>
            entry.Round === whatRound && entry.Bracket === bracket && entry.TournamentDisplayOrder === displayOrder
        );

        // Return true if a matching entry is found, otherwise return false
        return !!pickedGame;
    } catch (error) {
        // If an error occurs, throw it
        throw new Error(`Error while checking if the game is already picked: ${error.message}`);
    }
 }

/**
 * Removes a pick and future picks with the same UserChoice from the pickState array.
 * @param {Array} pickState - The array containing the current picks.
 * @param {number} whatRound - The round of the pick.
 * @param {string} bracket - The bracket of the pick.
 * @param {number} displayOrder - The tournament display order of the pick.
 * @returns {Array} - The updated pickState array after removal.
 * @throws {Error} - If an error occurs while removing.
 */
function removePickAndFuturePicks(whatRound, bracket, displayOrder, newPickState) {

        // Find the index of the pick to remove
        const indexToRemove = newPickState.findIndex(entry =>
            entry.Round >= whatRound && entry.Bracket === bracket && entry.TournamentDisplayOrder === displayOrder
        );
        console.log('INDEX TO REMOVE!!!!!!', indexToRemove);
        // If no matching pick is found, just return, no need to modify state
        if (indexToRemove === -1) {
            console.log("No matching pick found to remove.");
            return;
        }

        // Extract the UserChoice from the pick to be removed
        const removedUserChoice = newPickState[indexToRemove].UserChoice;
        const championCheck = newPickState[indexToRemove].Team;

        // Log the pick that is being removed
        console.log("Removed pick:", newPickState[indexToRemove], pickState);

         // Remove the pick from pickState
         const removedPick = newPickState.splice(indexToRemove, 1)[0];
         console.log("Removed pick:", removedPick, whatRound);


         pickState.forEach(entry => {
            console.log('I JUST WANT THE LOOP NOW', entry, removedPick)
        });
         // Remove future picks with the same UserChoice
         const remainingPicks = newPickState.filter(entry =>
             !(entry.UserChoice === removedUserChoice && entry.Round > whatRound)
         );

         console.log("Removed pick AFTER:", newPickState[indexToRemove], pickState, remainingPicks);

         // Log removed future picks
         const removedFuturePicks = newPickState.filter(entry =>
             entry.UserChoice === removedUserChoice && entry.Round >= whatRound
         );
         console.log('removedFuturePicks AFTER CONST ', removedFuturePicks)
         removedFuturePicks.forEach(entry => {
             console.log("Removed future pick:", entry);
             // Remove future pick from pickState
             const index = newPickState.indexOf(entry);
             if (index !== -1) {
                 pickState.splice(index, 1);
             }
         });
           // Filter out the current pick and any future picks with the same UserChoice
        const updatedPickState = newPickState.filter(entry =>
            !(entry.UserChoice === removedUserChoice && entry.Round >= whatRound)
        );


         console.log('removedFuturePicks LOOP CONST ', removedFuturePicks, pickState, updatedPickState, whatRound,'pickState')

        // Remove the team from finalFourMatchups if it matches the removed UserChoice
        if (finalFourMatchups.finalFourTeams[bracket] === removedUserChoice) {
            const updatedFinalFourMatchups = {
                ...finalFourMatchups,
                finalFourTeams: {
                    ...finalFourMatchups.finalFourTeams
                }
            };
            delete updatedFinalFourMatchups.finalFourTeams[bracket];

            setFinalFourMatchups(updatedFinalFourMatchups);
        }


        console.log('DISPLAYSTATE NOW', displayState, bracket, removedUserChoice, pickState, champion, removedUserChoice, championCheck, newPickState);

        if (champion === championCheck ){
            console.log('CHAMPION with remainingPicks', champion, championCheck, remainingPicks);
            setChampion('');
        }

        if(bracket === 'East'){
            if(displayState[`East-West-5-1`]?.home !== null && displayState[`East-West-5-1`]?.home !== undefined){
                if(displayState[`East-West-5-1`]?.home === removedUserChoice){
                    displayState[`East-West-5-1`].home = null;
                }
            }
            if(displayState[`null-6-1`]?.home === removedUserChoice && displayState[`null-6-1`]?.home !== undefined){
                displayState[`null-6-1`].home = null;
                setChampion('');
            }
        }else if(bracket === 'West'){
            if(displayState[`East-West-5-1`]?.away !== null && displayState[`East-West-5-1`]?.away !== undefined){
                if(displayState[`East-West-5-1`]?.away === removedUserChoice){
                    console.log('DISPLAYSTATE NOW it removed')
                    displayState[`East-West-5-1`].away = null;
                }
            }
            if(displayState[`null-6-1`]?.home === removedUserChoice && displayState[`null-6-1`]?.home !== undefined){
                displayState[`null-6-1`].home = null;
                setChampion('');
            }
        }else if(bracket === 'South'){
            if(displayState[`South-Midwest-5-1`]?.home !== null && displayState[`South-Midwest-5-1`]?.home !== undefined){
                if(displayState[`South-Midwest-5-1`]?.home === removedUserChoice){
                    displayState[`South-Midwest-5-1`].home = null;
                }
            }
            if(displayState[`null-6-1`]?.away === removedUserChoice && displayState[`null-6-1`]?.away !== undefined){
                displayState[`null-6-1`].away = null;
                setChampion('');
            }
        }else if(bracket === 'Midwest'){
            if(displayState[`South-Midwest-5-1`]?.away !== null && displayState[`South-Midwest-5-1`]?.away !== undefined){
                if(displayState[`South-Midwest-5-1`]?.away === removedUserChoice){
                    displayState[`South-Midwest-5-1`].away = null;
                }
            }
            if(displayState[`null-6-1`]?.away === removedUserChoice && displayState[`null-6-1`]?.away !== undefined){
                displayState[`null-6-1`].away = null;
                setChampion('');
            }
        }else{
            // setPickState(remainingPicks);
        }
        console.log('DISPLAYSTATE NOW AFTER', displayState, bracket, removedUserChoice, pickState, updatedPickState);

        console.log('updatedPickState', remainingPicks)
        // // Update the pickState with the filtered array
        // setPickState(remainingPicks);
        setPickState(updatedPickState);
}


  const updatePickStateForTeam = (game, teamID, isHomeTeam) => {
    let seed = teamSeed(teamID);
    let teamName = bracketDisplayName(CBBTeams, teamID, 9);
    // First, find if there was an existing pick for this game.
    const existingPick = pickState.find(pick =>
        pick.Round === game.Round &&
        pick.TournamentDisplayOrder === game.TournamentDisplayOrder &&
        pick.Bracket === game.Bracket);

    // Filter out the current game's pick from the state to prepare for the update.
    let newState = pickState.filter(pick =>
        !(pick.Round === game.Round &&
          pick.TournamentDisplayOrder === game.TournamentDisplayOrder &&
          pick.Bracket === game.Bracket));

    let tournamentDisplayOrder = game.TournamentDisplayOrder;

    // Check if the game has already been picked
    const hasGameBeenPicked = isGameAlreadyPicked(pickState, game.Round, game.Bracket, tournamentDisplayOrder);
    console.log("Has this game been picked already: ", hasGameBeenPicked);

    if (hasGameBeenPicked) {
        console.log('hasGameBeenPicked', hasGameBeenPicked);
        removePickAndFuturePicks( game.Round, game.Bracket, tournamentDisplayOrder, pickState);
        handleFinalFourAndChampionshipPicks(game, teamID, isHomeTeam);
    }

    // Create a new pick object for the current selection.
    const currentPick = {
        Round: game.Round,
        Bracket: game.Bracket,
        TournamentDisplayOrder: game.TournamentDisplayOrder,
        UserChoice: teamID,
        Team: teamName,
        Seed: seed,
    };
    console.log('Remaining Current Pick',currentPick);
    // Add the new pick to the state.
    newState.push(currentPick);

    console.log('HELOO THIS IS THE NEW STATE', newState);

    // Update pickState with the new selection.
    setPickState(newState);
    // Remove the game and future picks if it has been picked

    console.log(game, 'game.Round');
    console.log("Updating pick state for game:", game.Round);
    if (game.Round >= 4) {
        console.log("Handling Final Four or Championship pick:", game);
        handleFinalFourAndChampionshipPicks(game, teamID, isHomeTeam);
        return;
    }
    // Now, update the displayState for the current pick.
    updateDisplayStateForFutureRounds(game, currentPick, isHomeTeam);

    console.log('Remaining', existingPick, hasGameBeenPicked, pickState, newState )

    if(hasGameBeenPicked && currentPick.UserChoice === teamID){
        updateFutureRoundsOnTeamChange(currentPick.UserChoice, teamID, game.Bracket);
    }

    // Check if there was an existing pick and if it's different from the new selection.
    // This is where you check if a change in the selection for an earlier round
    // might affect future rounds due to the originally selected team advancing.
    if (existingPick && existingPick.UserChoice !== teamID) {
        // If so, call updateFutureRoundsOnTeamChange to adjust future round selections accordingly.
        // This step ensures that if the originally selected team was advanced in future rounds,
        // those advancements are updated to reflect the new selection.
        updateFutureRoundsOnTeamChange(existingPick.UserChoice, teamID, game.Bracket);
    }


};


const updateDisplayStateForFutureRounds = (game, currentPick, isHomeTeam) => {
    const nextRound = game.Round + 1;
    const nextTournamentDisplayOrder = Math.ceil(game.TournamentDisplayOrder / 2);
    const nextDisplayKey = `${game.Bracket}-${nextRound}-${nextTournamentDisplayOrder}`;

    const position = game.TournamentDisplayOrder % 2 === 0 ? 'away' : 'home';

    // Use GlobalTeamID for consistency
    const teamIdentifier = isHomeTeam ? game.GlobalHomeTeamID : game.GlobalAwayTeamID;

    setDisplayState(prev => {
        const updatedGame = prev[nextDisplayKey] || {};
        updatedGame[position] = teamIdentifier; // Use GlobalTeamID

        return { ...prev, [nextDisplayKey]: updatedGame };
    });
};
const updateFutureRoundsOnTeamChange = (game, originalTeamID, newTeamID) => {
    const updatedPickState = pickState.map(pick => {
        // Only update if the original team was selected to advance from this game
        if (pick.Bracket === game.Bracket && pick.Round > game.Round) {
            let shouldUpdate = false;
            const correspondingDisplayKey = `${pick.Bracket}-${pick.Round}-${pick.TournamentDisplayOrder}`;
            const displayMatch = displayState[correspondingDisplayKey];

            // Determine if the original team was in a position (home/away) to advance
            if (displayMatch.home === originalTeamID || displayMatch.away === originalTeamID) {
                shouldUpdate = true;
            }

            // If the original team was set to advance, update the pick to the new team
            if (shouldUpdate && pick.UserChoice === originalTeamID) {
                return { ...pick, UserChoice: newTeamID };
            }
        }
        return pick;
    });

    // Similar logic for displayState, ensuring to preserve matchup integrity
    const updatedDisplayState = Object.keys(displayState).reduce((acc, key) => {
        const match = displayState[key];
        if (key.startsWith(game.Bracket)) {
            if (match.home === originalTeamID) {
                acc[key] = { ...match, home: newTeamID };
            } else if (match.away === originalTeamID) {
                acc[key] = { ...match, away: newTeamID };
            } else {
                acc[key] = match; // No change for this match
            }
        } else {
            acc[key] = match; // No change for matches in other brackets
        }
        return acc;
    }, {});

    // Update states
    setPickState(updatedPickState);
    setDisplayState(updatedDisplayState);
};


  const isTeamSelected = (game, teamID) => {
    return pickState.some(pick =>
        pick.Round === game.Round &&
        pick.Bracket === game.Bracket &&
        pick.TournamentDisplayOrder === game.TournamentDisplayOrder &&
        pick.UserChoice === teamID
    );
  };


    function bracketDisplayName(CBBTeams, teamID, maxCharacters) {
        // Find the team with the matching GlobalTeamID
        const team = CBBTeams.find(team => team.GlobalTeamID === teamID);

        if (team) {
            // Extract and check the length of the School name
            const school = team.School;
            const shortenedName = team.ShortDisplayName;
            if (school.length <= maxCharacters) {
                return school;
            } else {
                let modifiedSchool = school;
                if (school.includes('State')) {
                    modifiedSchool = school.replace('State', 'St.');
                }
                if (modifiedSchool.length <= maxCharacters) {
                    return modifiedSchool;
                } else {
                    return shortenedName;
                }
            }
        } else {
            return "Team not found";
        }
    }

    function teamSeed(teamID) {
        // Ensure that ncaaTournamentHierarchy and ncaaTournamentHierarchy.Games are loaded
        if (!ncaaTournamentHierarchy || !ncaaTournamentHierarchy.Games) {
            // If data is not loaded yet, show a loading indicator or return null
            return null;
        }

        console.log("GAMES IMPORT LOG");

        // Safely access Games as it's confirmed to be non-null/undefined
        const gamesImport = Object.values(ncaaTournamentHierarchy.Games);
        console.log("GAMES IMPORT LOG", gamesImport);

        // Filter for games where Round === 1
        const games = gamesImport.filter(game => game.Round === 1);

        for (const game of games) {
            // Check if the teamID matches the GlobalHomeTeamID or GlobalAwayTeamID
            if (game.GlobalHomeTeamID === teamID) {
                return game.HomeTeamSeed || null; // Return the HomeTeamSeed if the teamID matches the GlobalHomeTeamID
            } else if (game.GlobalAwayTeamID === teamID) {
                return game.AwayTeamSeed || null; // Return the AwayTeamSeed if the teamID matches the GlobalAwayTeamID
            }
        }

        return null; // Return null if the teamID is not found in any game with Round === 1
    }


  const renderTeamSelection = (team, game, isHomeTeam) => {
    const teamID = isHomeTeam ? game.GlobalHomeTeamID : game.GlobalAwayTeamID;
    const teamLogoUrl = findTeamLogoUrlBySchoolName(teamID);
    console.log('GAME__________________________________________________', game)
    const isSelected = isTeamSelected(game, teamID);
    console.log('isSelected', isSelected, teamID, game, 'isSelected');
    const schoolNameLookup = bracketDisplayName(CBBTeams, teamID, 9);
    return (
      <div className="team-selection" style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}} onClick={() => updatePickStateForTeam(game, teamID, isHomeTeam)}>
        <div style={{flex:2, display:'flex', alignItems:'center'}}>
            {teamLogoUrl && <div  style={{ width: '20px' }}><img style={{ width: '100%', display:"block" }} src={teamLogoUrl} alt={`${team} logo`} /></div>}

            <div style={{marginLeft:'10px'}}>{isHomeTeam ? game.HomeTeamSeed : game.AwayTeamSeed}</div>
            <div style={{maxWidth:"100px", fontSize:'9px', fontWeight:500, margin:"0 auto 0 7px"}}>{schoolNameLookup}</div>
        </div>
        <input
          type="radio"
          checked={isSelected}
          onChange={() => updatePickStateForTeam(game, teamID, isHomeTeam)}
          // This is to prevent the radio button from changing state before the click event is processed
        //   onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  };

  const renderGame = (game, round, key) => {
    console.log('updateFutureRoundsOnTeamChange', game);

    const handleClick = (teamID, isHomeTeam) => {
        console.log('TEAMID 1 CHECK', teamID);
        let getSeed = teamSeed(teamID);
        let teamName = bracketDisplayName(CBBTeams, teamID, 9);
        updatePickStateForTeam(game, teamID, isHomeTeam, getSeed, teamName);
    };
    return (
        <div style={{display:'flex', alignItems:'center'}}>
        <div className="line-beginning"></div>
            <div key={key} className="bracket-box" style={{border: '1px solid #002129', background: '#fff', boxShadow:"0px 2px 2px 0px #00000060", width:'169px', height:'78px', padding:'16px', borderRadius:'8px', marginBottom:'32px' }}>
                {/* <div>Round: {round}</div>
                <div>Bracket: {game.Bracket}</div> */}
                <div className="team-selection" style={{display:'flex',flexDirection:'row'}} onClick={() => handleClick(game.GlobalHomeTeamID, true)}>
                    {renderTeamSelection(game.HomeTeam, game, true)}
                </div>
                <div className="team-selection" style={{display:'flex',}}  onClick={() => handleClick(game.GlobalAwayTeamID, false)}>
                    {renderTeamSelection(game.AwayTeam, game, false)}
                </div>
            </div>
            <div className="line-end"></div>
        </div>
    );
  };

  const handleTeamSelection = (bracketName, round, key, teamID, isHomeTeam) => {
    // Assuming you can construct a 'game' object with the necessary details
    const game = {
      Bracket: bracketName,
      Round: round,
      TournamentDisplayOrder: key, // Ensure this aligns with how your game keys are structured
      GlobalHomeTeamID: isHomeTeam ? teamID : null, // Simplification for example
      GlobalAwayTeamID: isHomeTeam ? null : teamID, // Simplification for example
    };

    updatePickStateForTeam(game, teamID, isHomeTeam);
  };


  const renderFutureMatchup = (bracketName, round, key, homeTeamDetails, awayTeamDetails, handleTeamSelection) => {
    console.log('FUTURE MATCHUP', bracketName, round, key, homeTeamDetails, awayTeamDetails, handleTeamSelection);
    // Fallback for when team details are null (TBD scenario)
    const renderTeamOrPlaceholder = (teamDetails, isHomeTeam) => {
        if (!teamDetails) {
            return <div className="team-placeholder" >TBD</div>;
        }
        const renderSeed = teamSeed(teamDetails.GlobalTeamID);
        const schoolNameLookup = bracketDisplayName(CBBTeams, teamDetails.GlobalTeamID, 9);
        console.log('teamDetails -------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',teamDetails, homeTeamDetails, awayTeamDetails);
        return (
            <div className="team-selection" style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}} onClick={() => handleTeamSelection(bracketName, round, key, teamDetails.GlobalTeamID, isHomeTeam)}>
                {teamDetails.TeamLogoUrl && <div style={{ width: '20px' }}> <img style={{ width: '100%', display:"block" }}  src={teamDetails.TeamLogoUrl} alt={`${schoolNameLookup} logo`}/></div>}
                {renderSeed}
                <div style={{maxWidth:"100px", fontSize:'12px', fontWeight:500, margin:"0 auto 0 7px"}}>{schoolNameLookup}</div>
            </div>
        );
    };

    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <div className="line-beginning"></div>

            <div key={key} className="future-matchup bracket-box"  style={{border: '1px solid #002129', background: '#fff', boxShadow:"0px 2px 2px 0px #00000060",width:'208px', height:'78px', padding:'16px', borderRadius:'8px', marginBottom:'32px' }}>
                {renderTeamOrPlaceholder(homeTeamDetails, true)}
                {renderTeamOrPlaceholder(awayTeamDetails, false)}
            </div>
            <div className="line-end"></div>
        </div>
    );
};

const renderRoundOneGames = (bracketName) => {
    if (!ncaaTournamentHierarchy || !ncaaTournamentHierarchy.Games) {
        return <div>Loading games...</div>;
    }

     // Only fetch round one games for the specified bracket/quadrant
  const roundOneGames = ncaaTournamentHierarchy.Games.filter(game => game.Round === 3 && game.Bracket === bracketName);
  return roundOneGames.map((game, index) => renderGame(game, 1, `round1-${bracketName}-game-${index}`));

};
const handleFinalFourAndChampionshipPicks = (game, teamID, ) => {
    console.log('handleFinalFourAndChampionshipPicks', game)
    if (game.Round === 4) { // Elite Eight, determining Final Four
        const nextRound = game.Round + 1;
        const nextTournamentDisplayOrder = Math.ceil(game.TournamentDisplayOrder / 2);

        let roundFourBracket = game.Bracket;
        const eastWestKey = `East-West-${nextRound}-${nextTournamentDisplayOrder}`;
        const southMidwestKey = `South-Midwest-${nextRound}-${nextTournamentDisplayOrder}`;

        setDisplayState(prev => {
            // Get the existing matchups or initialize them if they don't exist
            const existingEastWestMatchup = prev[eastWestKey] || { home: null, away: null };
            const existingSouthMidwestMatchup = prev[southMidwestKey] || { home: null, away: null };

            // Update the matchups based on the bracket of the current game
            if (roundFourBracket === 'East') {
                existingEastWestMatchup.home = teamID;
            } else if (roundFourBracket === 'South') {
                existingSouthMidwestMatchup.home = teamID;
            } else if (roundFourBracket === 'Midwest') {
                existingSouthMidwestMatchup.away = teamID;
            } else if (roundFourBracket === 'West') {
                existingEastWestMatchup.away = teamID;
            }

            // Return the updated display state
            return {
                ...prev,
                [eastWestKey]: existingEastWestMatchup,
                [southMidwestKey]: existingSouthMidwestMatchup
            };
        });

        setFinalFourMatchups(prev => ({
            ...prev,
            finalFourTeams: {
                ...prev.finalFourTeams,
                [game.Bracket]: teamID // Add the winner to the Final Four
            }
        }));
    } else if (game.Round === 5) { // Final Four, determining Championship

        const championshipKey = 'null-6-1';
        console.log('BRACKET IN ROUND 6', game.Bracket);
        setDisplayState(prev => {
            const existingChampionshipMatchup = prev[championshipKey] || { home: null, away: null };

            if (game.Bracket === 'East-West') {
                existingChampionshipMatchup.home = teamID;
            } else if(game.Bracket === 'South-Midwest') {
                existingChampionshipMatchup.away = teamID;
            }

            return { ...prev, [championshipKey]: existingChampionshipMatchup };
        });

        setFinalFourMatchups(prev => ({
            ...prev,
            championshipTeams: [...prev.championshipTeams, teamID]
        }));
    }
};

const getFinalFourMatchups = (bracketName, roundNumber, location) => {
    // Assuming a single Final Four matchup for each bracket
    console.log('get Final four matchups:',bracketName, roundNumber, location);

    return [];
};

const getChampionshipMatchup = (bracketName, location) => {
    const key = 'null-6-1'; // Key for the championship matchup in your displayState
    const matchup = displayState[key];

    if (matchup) {
        const homeTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.home);
        const awayTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.away);

        // Assuming renderFutureMatchup is a function that correctly renders the matchup
        return renderFutureMatchup(null, 6, 1, homeTeamDetails, awayTeamDetails, handleTeamSelection);
    }
};

// const renderFinalFour = () => {
//     const finalFourMatchups = getFinalFourMatchups();
//     return finalFourMatchups.map((matchup, index) => {
//         // Your rendering logic for each matchup
//         return renderMatchup(matchup, index);
//     });
// };

// const renderChampionship = () => {
//     const championshipMatchup = getChampionshipMatchup();
//     // Rendering logic for the Championship matchup
//     return renderMatchup(championshipMatchup, 0); // Assuming only one matchup
// };

const handleFinalFourSelection = (teamID) => {
    setFinalFourMatchups(prev => ({
      ...prev,
      championshipTeams: prev.championshipTeams.includes(teamID) ? prev.championshipTeams : [...prev.championshipTeams, teamID]
    }));
  };

const renderFinalFour = () => {
    return Object.entries(finalFourMatchups.finalFourTeams).map(([bracket, teamID], index) => {
      const teamDetails = CBBTeams.find(team => team.GlobalTeamID === teamID);
      const renderSeed = teamSeed(teamDetails.GlobalTeamID);
      const teamName = bracketDisplayName(CBBTeams, teamID, 9);
      console.log('teamDetails',teamDetails, teamID,bracket, 'teamDetails');

      return (
        <div key={index} className="final-four-matchup" onClick={() => handleFinalFourSelection(teamID, renderSeed, teamName)}>
          {teamDetails &&
            <div>
              <img src={teamDetails.TeamLogoUrl} alt={`${teamDetails.Name} logo`} style={{ width: '45px' }} />
              <div>{teamDetails.Name}</div>
            </div>
          }
        </div>
      );
    });
  };

  const renderChampionship = () => {
      return finalFourMatchups.championshipTeams.map((teamID, index) => {
        const teamDetails = CBBTeams.find(team => team.GlobalTeamID === teamID);
        return (
          <div key={index} className="championship-team" onClick={renderChampion}>
            <img src={teamDetails.TeamLogoUrl} alt={`${teamDetails.Name} logo`} style={{ width: '45px' }} />
            <div>{teamDetails.Name}</div>
          </div>
        );
      });
  };

  const renderChampion = () => {
    setChampion();
    // if (finalFourMatchups.championshipTeams.length < 2) {
    //   return <div>Championship matchup to be decided</div>;
    // } else {
    //   return finalFourMatchups.championshipTeams.map((teamID, index) => {
    //     const teamDetails = CBBTeams.find(team => team.GlobalTeamID === teamID);
    //     return (
    //       <div key={index} className="championship-team">
    //         <img src={teamDetails.TeamLogoUrl} alt={`${teamDetails.Name} logo`} style={{ width: '45px' }} />
    //         <div>{teamDetails.Name}</div>
    //       </div>
    //     );
    //   });
    // }
  };
  const renderFinalFourNormal = (bracketName, location) => {
    console.log('Round 5 - Final Four', location);

    const finalFourKey = `${bracketName}-5-1`; // Adjust based on your key structure
    const matchup = displayState[finalFourKey];
    console.log('matchup round 5',matchup, finalFourKey, displayState, 'matchup')
    if (matchup) {
        const homeTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.home);
        const awayTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.away);

        return renderFutureMatchup(
            bracketName,
            5,
            1, // Assuming there's only one matchup in Final Four
            homeTeamDetails,
            awayTeamDetails,
            handleTeamSelection
        );
    }
};

const renderRound = (bracketName, roundNumber, location) => {

    console.log('YO CHECK THE ROUND',roundNumber, location)
    if (roundNumber === 3) return renderRoundOneGames(bracketName);

    if (roundNumber === 4) {
        if (!ncaaTournamentHierarchy || !ncaaTournamentHierarchy.Games) return <div>Loading...</div>;
        // const numMatchups = Math.pow(2, (6 - roundNumber));
        // Correctly calculate the number of matchups for this round and bracket
        const gamesPerRound = {
            1: 8, // 8 games in the 1st round for each of the 4 brackets
            2: 4, // 4 games in the 2nd round
            3: 2, // 2 games in the 3rd round (Sweet 16)
            4: 1, // 1 game in the 4th round (Elite 8)
            // 6: 1, // Championship game
        };

        const numMatchups = gamesPerRound[roundNumber];

        let matchups = [];

        for (let i = 1; i <= numMatchups; i++) {
            const displayKey = `${bracketName}-${roundNumber}-${i}`;
            const gameInfo = displayState[displayKey];

            if (gameInfo) {
                // Lookup using GlobalTeamID
                const homeTeamDetails = gameInfo.home ? CBBTeams.find(team => team.GlobalTeamID === gameInfo.home) : null;
                const awayTeamDetails = gameInfo.away ? CBBTeams.find(team => team.GlobalTeamID === gameInfo.away) : null;

                if(roundNumber === 5) {
                    console.log('numMatchups',homeTeamDetails, awayTeamDetails);
                }
                matchups.push(renderFutureMatchup(
                    bracketName,
                    roundNumber,
                    i, // This assumes 'i' is acting as the TournamentDisplayOrder or a unique identifier
                    homeTeamDetails,
                    awayTeamDetails,
                    handleTeamSelection // Make sure this function is defined and passed correctly
                ));
            } else {
                // If no info in displayState, render placeholders for both teams
                matchups.push(renderFutureMatchup(roundNumber, `future-${bracketName}-${i}`));
            }
        }
        console.log('matchups',matchups);
        return matchups;
    }else  if (roundNumber === 5) {
        // Call the function for rendering the Final Four matchups
        return renderFinalFourNormal(bracketName, location);
    }
    else if (roundNumber === 6 && location === 'championship') {
        return getChampionshipMatchup();
    }
};
    // const getFinalFourMatchups = (bracketName, roundNumber, location) => {
    //     console.log(bracketName, roundNumber, location);

    //     return;
    // };
    // const getChampionshipMatchup = (bracketName, roundNumber, location) => {
    //     console.log(bracketName, roundNumber, location);
    //     return;
    // };
    const renderMatchup = (matchup) => {
        if (!matchup) return <div></div>;

        const homeTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.home);
        const awayTeamDetails = CBBTeams.find(team => team.GlobalTeamID === matchup.away);

        return (
            <>
                <div className="line-beginning"></div>
                <div className="matchup bracket-box" style={{border:" 1px solid #6D6D6D", background: '#fff', boxShadow:"0px 2px 2px 0px #00000060", width:'208px', height:'44px', padding:'16px', borderRadius:'8px'}}>
                    <div className="team home-team">
                        {homeTeamDetails && <img src={homeTeamDetails.TeamLogoUrl} alt={`${homeTeamDetails.School} logo`} />}
                        <span>{homeTeamDetails ? homeTeamDetails.School : "TBD"}</span>
                    </div>
                    <div className="team away-team">
                        {awayTeamDetails && <img src={awayTeamDetails.TeamLogoUrl} alt={`${awayTeamDetails.School} logo`} />}
                        <span>{awayTeamDetails ? awayTeamDetails.School : "TBD"}</span>
                    </div>
                </div>
                <div className="line-end"></div>
            </>
        );
    };


  const renderBracket = (bracketName , location) => {
    console.log(location, 'location');
    let bracketNameCaps = bracketName?.toUpperCase();
    let roundsToRender = [3, 4];
    if (location.startsWith('final-four')) {
        console.log('Final Four bracket', location, bracketName)
        // For Final Four, we only need to render one specific round
        roundsToRender = [5]; // Assuming round 5 is for Final Four
    } else if (location === 'championship') {
        roundsToRender = [6]; // For Championship, only render round 6
    }
    return (
        <>
        {location.startsWith('final-four') ||location === 'championship'?
            <>
            </>
            :
            <h2 style={{ margin:"0 auto" }} className='region-title'>{bracketNameCaps}</h2>
        }


        <div className="bracket" style={{display:'flex'}}>
        {roundsToRender.map(roundNumber => (
        // If this is a Final Four bracket, we might want to handle it differently

            <div key={roundNumber} className={`round round-${roundNumber} bracket-column-${roundNumber}`}>
                {/* <h3>Round {roundNumber}</h3> */}
                {renderRound(bracketName, roundNumber, location)}
            </div>
        ))}
        </div>
        </>
    );
  };

  const DisplayChampion = (champion) => {
    let championPick = pickState.filter(entry => entry.Round === 6 && entry.Bracket === null);

    let logoUrl = '';
    if(champion){
        if (championPick.length > 0) {
            console.log('Updated PICKSTATE REMAINING CHAMP', championPick, championPick[0].UserChoice, champion);
            setChampion(championPick[0].Team);
            logoUrl = findTeamLogoUrlBySchoolName(championPick[0].UserChoice);
        }else{
            setChampion('');
        }
    }

    return (
        <>
        {champion === ''?
        <>
            <div className='champion-box'>
                <div className="place-holder-team">

                </div>
            </div>
            <div className='champion-title'>champion</div>
        </>
        :
        <>
        {console.log('PICKSTATE REMAINING CHAM', champion, logoUrl)}
                <div className='champion-box'>
                    <div className="place-holder-team">
                        <img style={{display:'block', width:'100%'}} src={logoUrl} alt='champion' />
                    </div>
                </div>
                <div className='champion-title'>{championPick[0]?.Team}</div>
            </>
    }    </>
    )

  }

  if (!ncaaTournamentHierarchy) {
    // If data is not loaded yet, show a loading indicator or return null
    return <div>Loading tournament data...</div>;
  }

  let leftBottom = {"bracketName":ncaaTournamentHierarchy.LeftBottomBracketConference,"location":"left-bottom"};
  let leftTop = {"bracketName":ncaaTournamentHierarchy.LeftTopBracketConference,"location":"left-top"};
  let rightBottom = {"bracketName":ncaaTournamentHierarchy.RightBottomBracketConference,"location":"right-bottom"};
  let rightTop = {"bracketName":ncaaTournamentHierarchy.RightTopBracketConference,"location":"right-top"};
  let finalFourLeft = {"bracketName":ncaaTournamentHierarchy.LeftTopBracketConference  + '-' +
 ncaaTournamentHierarchy.LeftBottomBracketConference,"location":"final-four-left"};
// Make sure the location for finalFourRight is unique
let finalFourRight = {"bracketName": ncaaTournamentHierarchy.RightTopBracketConference + '-' + ncaaTournamentHierarchy.RightBottomBracketConference, "location":"final-four-right"};

  let championship = {"bracketName":null,"location":"championship"};
  return (
    <>
    <div className="tournament-bracket">
  {[leftBottom, leftTop, rightBottom, rightTop].map(bracket => (

        <div key={bracket.bracketName} className={`bracket-quadrant-${bracket.location}`}>
            {console.log('bracketName',bracket.bracketName)}
            {renderBracket(bracket.bracketName, bracket.location)}
        </div>
    ))}
        <div className="final-four">
            <div>
                <img src={VectorCrown} alt='crown' />
                <div style={{margin:'5px auto 32px auto', width:'123px'}}>
                   <DisplayChampion champion={champion}/>
                </div>
            </div>

            <h2>Final Four</h2>
            {/* {renderFinalFour()} */}
            <div style={{display:'flex'}}>
                {/* {[finalFourLeft, finalFourRight].map(bracket => (
                    <div key={bracket.bracketName} className={`${bracket.location}`}>
                        {renderBracket(bracket.bracketName, bracket.location)}
                    </div>
                ))} */}
                <div key={finalFourLeft.bracketName} className={`${finalFourLeft.location}`}>
                    {renderBracket(finalFourLeft.bracketName, finalFourLeft.location)}
                </div>
                <div className="championship">
                    {renderBracket(null, 'championship')}
                </div>
                <div key={finalFourRight.bracketName} className={`${finalFourRight.location}`}>
                    {renderBracket(finalFourRight.bracketName, finalFourRight.location)}
                </div>
            </div>
            <div className="tiebreaker-box">
                <Typography variant='h6' sx={{
                    color: '#002129',
                    textAlign: 'center',
                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
                    fontFamily: 'Inter',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textDecorationLine: 'underline',
                    marginBottom:'8px', marginTop:'8px'}}>Final Tiebreaker - Final Scores</Typography>
                <div style={{display:'flex', justifyContent:'space-around'}}>
                    <Input value={leftScore}
                onChange={handleLeftScoreChange} type="text" placeholder="0" style={{ border:'1px solid #002129', padding:'0 10px', width: "46px",height: '33px', marginRight:"0px"}}/>
                    -
                    <Input value={rightScore}
                onChange={handleRightScoreChange} type="text" placeholder="0" style={{ border:'1px solid #002129', padding:'0 10px', width: "46px",height: '33px', marginRight:"0px"}}/>
                </div>
                <Typography variant='body2' sx={{textAlign:'center', marginTop:'8px', marginBottom:'0px', fontWeight:'700', fontSize:'6px'}}>Enter the final score for the championship game</Typography>
            </div>
            {/* <Button sx={{
                  display: 'block',
                  backgroundColor: 'var(--secondary-color)',
                  color: 'var(--color-white)',
                  margin:'0 auto',
                  marginTop: '16px',
                  marginBottom: '16px',
                  fontWeight:700,
                  '&:hover': {
                    opacity: 0.95,
                    backgroundColor: 'var(--secondary-color)',
                    color: 'var(--color-white)',
                  },
                }}
                variant='text'
                onClick={()=>handleSavePicks()}>Save Picks</Button>

            <Button sx={{
                    display: 'block',
                  backgroundColor: 'var(--color-yellow)',
                  margin:'0 auto',
                  color: 'var(--primary-color)',
                  fontWeight:500,
                  '&:hover': {
                    opacity: 0.95,
                    backgroundColor: 'var(--color-yellow)',
                    color: 'var(--primary-color)',
                  },
                }}
                variant='text'>Clear Picks</Button> */}
      </div>
    </div>
    </>
  );
};

export default MarchMadnessBracket;
