import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const SurvivorGameInfo = ({ gameData, myPoolsData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const currentInterval = myPoolsData?.currentInterval;
    
    const isUserEliminated = (pool) => {
        return pool?.picks?.some(pick => pick?.result === 'loss');
    };

    const thisIntervalsPick = (pool) => {
        return pool?.picks?.find(pick => pick?.interval === currentInterval);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '8px',
            }}
        >
            {gameData?.entries?.map((entry, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '700',
                            color: isUserEliminated(entry?.pool) ? '#CC293C' : '#002129',
                            textAlign: 'center',
                            marginBottom: '4px'
                        }}
                    >
                        ({index + 1})
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '24px', // Fixed height for all status rows
                            justifyContent: 'center'
                        }}
                    >
                        {isUserEliminated(entry?.pool) ? (
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '14px',
                                    fontWeight: '700',
                                    color: '#CC293C',
                                    textAlign: 'center',
                                }}
                            >
                                Eliminated
                            </Typography>
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '14px',
                                        fontWeight: '700',
                                        color: '#002129',
                                        textAlign: 'center',
                                        marginRight: '4px'
                                    }}
                                >
                                    Pick In
                                </Typography>
                                {thisIntervalsPick(entry?.pool) ? (
                                    <CheckIcon sx={{ color: '#00AA72', fontSize: isMobile ? '18px' : '20px' }} />
                                ) : (
                                    <CloseIcon sx={{ color: '#CC293C', fontSize: isMobile ? '18px' : '20px' }} />
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

export default SurvivorGameInfo;