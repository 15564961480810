import React from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const EntryDropdown = ({hideEntryDropdown}) => {
    const {
        gameAddress,
        masterPoolId,
        gameId,
        page,
        pickIndex,
        usersIndexesInThisPool,
        allUsersEntries,
        usersGameIdsInMasterPool,
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
    } = useUserAndGameContext();
    
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleGameChange = (event) => {
        const newGameId = event.target.value;
        // Create new URLSearchParams from current params
        const newParams = new URLSearchParams(searchParams);
        // Update the parameters we want to change
        newParams.set('mId', masterPoolId);
        newParams.set('gId', newGameId);
        newParams.set('page', page);

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(`/${gameAddress}?${newParams.toString()}`);
            setUnsavedPicksModalOpen(true);
            return;
        }
    
        navigate(`/${gameAddress}?${newParams.toString()}`);
    }

    const handleEntryChange = (event) => {
        const newPickIndex = event.target.value;
        // Create new URLSearchParams from current params
        const newParams = new URLSearchParams(searchParams);
        // Update the parameters we want to change
        newParams.set('mId', masterPoolId);
        newParams.set('gId', gameId);
        newParams.set('page', page);
        newParams.set('pickIndex', newPickIndex);

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(`/${gameAddress}?${newParams.toString()}`);
            setUnsavedPicksModalOpen(true);
            return;
        }
        
        navigate(`/${gameAddress}?${newParams.toString()}`);
    };

    const dropdownSx = {
        backgroundColor: 'white',
        color: '#002129',
        minWidth: isMobile ? '150px' : '200px',
        width: '100%', // ✅ Ensure full width when stacked
        maxWidth: isMobile ? '100%' : '400px', // ✅ Allow full width when stacked
        '& .MuiSelect-select': {
            py: 0.75,
            color: '#002129',
            fontSize: isMobile ? '12px' : '16px',
        },
        '& .MuiSvgIcon-root': {
            color: '#002129',
        }
    };
    

    const labelSx = {
        color: '#002129',
        fontSize: isMobile ? '16px' : '20px',
        fontWeight: 'bold',
        mr: 1,
        minWidth: '50px'
    };

    const menuItemSx = {
        fontSize: '12px',
        color: '#002129',
        '&:hover': {
            backgroundColor: 'rgba(0, 33, 41, 0.1)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 33, 41, 0.2)',
            '&:hover': {
                backgroundColor: 'rgba(0, 33, 41, 0.3)',
            }
        }
    };

    // Modify this line to consider hideEntryDropdown prop
    const showBothDropdowns = usersGameIdsInMasterPool?.length > 1 && usersIndexesInThisPool?.length > 1 && !hideEntryDropdown;
    
    // Check if we show the pool dropdown
    const showPoolDropdown = usersGameIdsInMasterPool?.length > 1;
    
    // Check if we show the entry dropdown, considering hideEntryDropdown prop
    const showEntryDropdown = usersIndexesInThisPool?.length > 1 && !hideEntryDropdown;

    const renderDropdown = (label, value, handleChange, items, getItemLabel) => (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            maxWidth: '400px',
        }}>
            <Typography sx={labelSx}>
                {label}
            </Typography>
            <Select
                value={value || ""}
                onChange={handleChange}
                sx={dropdownSx}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            backgroundColor: 'white',
                        }
                    }
                }}
            >
                {items?.map((item, index) => (
                    <MenuItem 
                        key={typeof item === 'object' ? item.gameId : item} 
                        value={typeof item === 'object' ? item.gameId : item}
                        sx={menuItemSx}
                    >
                        {getItemLabel(item, index)}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );

    return (
        <Box sx={{ 
            backgroundColor: '#FFC60A',
            borderTop: '2px solid #002129',
            borderBottom: '2px solid #002129',
            p: '4px',
        }}>
            {isMobile && showBothDropdowns ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        gap: 2,
                    }}>
                        {/* {showPoolDropdown && (
                            <Box sx={{ 
                                width: showEntryDropdown ? '48%' : '100%', 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <Typography sx={labelSx}>Pool</Typography>
                                <Select
                                    value={gameId || ""}
                                    onChange={handleGameChange}
                                    sx={dropdownSx}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: { backgroundColor: 'white' }
                                        }
                                    }}
                                >
                                    {usersGameIdsInMasterPool?.map((game) => (
                                        <MenuItem 
                                            key={game.gameId} 
                                            value={game.gameId}
                                            sx={menuItemSx}
                                        >
                                            {game.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )} */}
                        {showEntryDropdown && (
                            <Box sx={{ 
                                width: showPoolDropdown ? '48%' : '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={labelSx}>Entry</Typography>
                                <Select
                                    value={pickIndex || ""}
                                    onChange={handleEntryChange}
                                    sx={dropdownSx}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: { backgroundColor: 'white' }
                                        }
                                    }}
                                >
                                    {usersIndexesInThisPool?.map((index) => (
                                        <MenuItem 
                                            key={index} 
                                            value={index}
                                            sx={menuItemSx}
                                        >
                                            {allUsersEntries[index]?.name || `Entry ${index + 1}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                    justifyContent: 'center',
                    maxWidth: showBothDropdowns ? '1000px' : isMobile ? '400px' : '500px',
                    margin: '0 auto',
                    width: '100%',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        {/* {showPoolDropdown && renderDropdown(
                            "Pool",
                            gameId,
                            handleGameChange,
                            usersGameIdsInMasterPool,
                            item => item.name
                        )} */}
                        {showEntryDropdown && renderDropdown(
                            "Entry",
                            pickIndex,
                            handleEntryChange,
                            usersIndexesInThisPool,
                            (item, index) => allUsersEntries[item]?.name || `Entry ${item + 1}`
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default EntryDropdown;