import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useGetMyPoolsData = () => {
    return useMutation({
        mutationFn: getMyPoolsData,
        onError: (error) => {
            console.error("Error fetching my pools data:", error);
        },
        // onSuccess: (data) => {
        //     console.log("Fetched my pools data:", data);
        // }
    });
}

const getMyPoolsData = async () => {
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-my-pools-data`, {
        headers: {
            Authorization: authorizationHeader,
            "Content-Type": "application/json"
        }
    });

    return response.data;
}