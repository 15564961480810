import React, { useState } from 'react';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Button } from '@mui/material';
import { Typography, Box } from '@mui/material';
import { DateTime } from 'luxon';

const BirdieConfirmationModal = () => {
    const { scorecardState, setScorecardState, modalState, setModalState, startingScorecard, handleScoreCardSubmission, selectedHole, confirmDate } = useBirdieContext();

    const isDuplicate = modalState === "confirmDuplicate";
    // if (isDuplicate) {
    //     console.log("Duplicate Modal Active, ScorecardState:", scorecardState);
    // }

    // Function to get the ordinal suffix for a given day
const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Special case for numbers between 4 and 20
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  // Function to format the date as "Month Dayth"
  const formatDate = (dateString) => {
    const dt = DateTime.fromFormat(dateString, 'M/d/yyyy');
    const day = dt.day;
    const month = dt.toFormat('MMMM'); // Full month name
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${month} ${day}${ordinalSuffix}`;
  };

    return (
        <div>
            <Typography variant="h4" sx={{ marginTop: '20px', fontSize: '28px' }}>Twice In One Day?</Typography>
            <Typography variant="h4">Hole {selectedHole}</Typography>
            <Typography variant="body1" sx={{ fontSize: '16px', marginTop: '10px' }}>
                You already have a birdie on Hole {selectedHole} on {formatDate(confirmDate)}. Do you want to add an additional birdie?
            </Typography>
            <br></br>
            <Button
                variant='conatined'
                sx={{
                height: '36px',
                bgcolor: 'white',
                color: '#002129',
                border: '1px solid #002129',
                '&:hover': {
                bgcolor: 'white', // Maintain white background on hover
                color: '#002129', // Maintain text color on hover
                border: '1px solid #002129', // Maintain border color on hover
                }, }}
                onClick={() => {
                    setModalState(null);
                    window.location.href = window.location.href;
                }}
                >
                     CANCEL
            </Button>
            <Button
                variant='contained'
                color='primary'
                style={{ marginLeft: '12px', width: '125px' }}
                onClick={() => {
                    // setModalState(null);
                    handleScoreCardSubmission();
                    // window.location.href = window.location.href;

                }}
                >
                    ADD BIRDIE
             </Button>
        </div>
    );
};

export default BirdieConfirmationModal;
