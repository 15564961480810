import React from "react";
import { Box, Table, TableCell, TableRow, TableHead, TableBody, TableContainer, Paper, Typography, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import { filter } from "lodash";

const TeamScheduleTable = ({ teamSchedule }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // const formatDateTime = (dateTime) => {
    //     //console.log("DateTime: ", dateTime);
    //     const dt = DateTime.fromISO(dateTime, { zone: 'America/New_York' });

    //     if ((dt.hour === 0 && dt.minute === 0 && dt.second === 0) || dt.invalid) {
    //         return 'TBD';
    //     }

    //     return dt.toFormat('h:mma') + ' ET';
    // };
    

    // function gatherScheduleInfo() {
    //     const filteredForTeam = CFBGamesByWeek?.filter(game =>
    //         (game.GlobalHomeTeamID === selectedTeamId || game.GlobalAwayTeamID === selectedTeamId) &&
    //         game.Status !== 'Canceled'
    //     );
    //     // const filteredForTeam = [
    //     //     {
    //     //         "GameID": 14582,
    //     //         "Season": 2024,
    //     //         "SeasonType": 1,
    //     //         "Week": 1,
    //     //         "Status": "In Progress",
    //     //         "Day": "2024-08-29T00:00:00",
    //     //         "DateTime": "2024-08-29T20:00:00",
    //     //         "AwayTeam": "MURST",
    //     //         "HomeTeam": "MISSR",
    //     //         "AwayTeamID": 240,
    //     //         "HomeTeamID": 280,
    //     //         "AwayTeamName": "Murray State Racers",
    //     //         "HomeTeamName": "Missouri Tigers",
    //     //         "AwayTeamScore": 20,
    //     //         "HomeTeamScore": 29,
    //     //         "Period": null,
    //     //         "TimeRemainingMinutes": null,
    //     //         "TimeRemainingSeconds": null,
    //     //         "PointSpread": -48.5,
    //     //         "OverUnder": 57.5,
    //     //         "AwayTeamMoneyLine": null,
    //     //         "HomeTeamMoneyLine": null,
    //     //         "Updated": "2024-08-18T05:17:25",
    //     //         "Created": "2024-01-15T00:08:02",
    //     //         "GlobalGameID": 50014582,
    //     //         "GlobalAwayTeamID": 50000240,
    //     //         "GlobalHomeTeamID": 50000280,
    //     //         "StadiumID": 110,
    //     //         "YardLine": null,
    //     //         "YardLineTerritory": null,
    //     //         "Down": null,
    //     //         "Distance": null,
    //     //         "Possession": null,
    //     //         "IsClosed": true,
    //     //         "GameEndDateTime": null,
    //     //         "Title": null,
    //     //         "HomeRotationNumber": 308942,
    //     //         "AwayRotationNumber": 308941,
    //     //         "Channel": "SECN",
    //     //         "NeutralVenue": false,
    //     //         "AwayPointSpreadPayout": -110,
    //     //         "HomePointSpreadPayout": -110,
    //     //         "OverPayout": -115,
    //     //         "UnderPayout": -106,
    //     //         "DateTimeUTC": "2024-08-30T00:00:00",
    //     //         "Attendance": null,
    //     //         "Stadium": {
    //     //             "StadiumID": 110,
    //     //             "Active": true,
    //     //             "Name": "Faurot Field at Memorial Stadium",
    //     //             "Dome": false,
    //     //             "City": "Columbia",
    //     //             "State": "MO",
    //     //             "GeoLat": 38.935833,
    //     //             "GeoLong": -92.333056
    //     //         },
    //     //         "Periods": []
    //     //     },
    //     //     {
    //     //         "GameID": 14826,
    //     //         "Season": 2024,
    //     //         "SeasonType": 1,
    //     //         "Week": 12,
    //     //         "Status": "Scheduled",
    //     //         "Day": "2024-11-16T00:00:00",
    //     //         "DateTime": "2024-11-16T13:30:00",
    //     //         "AwayTeam": "MURST",
    //     //         "HomeTeam": "UK",
    //     //         "AwayTeamID": 240,
    //     //         "HomeTeamID": 268,
    //     //         "AwayTeamName": "Murray State Racers",
    //     //         "HomeTeamName": "Kentucky Wildcats",
    //     //         "AwayTeamScore": null,
    //     //         "HomeTeamScore": null,
    //     //         "Period": null,
    //     //         "TimeRemainingMinutes": null,
    //     //         "TimeRemainingSeconds": null,
    //     //         "PointSpread": null,
    //     //         "OverUnder": null,
    //     //         "AwayTeamMoneyLine": null,
    //     //         "HomeTeamMoneyLine": null,
    //     //         "Updated": "2024-08-16T11:44:45",
    //     //         "Created": "2024-01-15T00:10:13",
    //     //         "GlobalGameID": 50014826,
    //     //         "GlobalAwayTeamID": 50000240,
    //     //         "GlobalHomeTeamID": 50000268,
    //     //         "StadiumID": 68,
    //     //         "YardLine": null,
    //     //         "YardLineTerritory": null,
    //     //         "Down": null,
    //     //         "Distance": null,
    //     //         "Possession": null,
    //     //         "IsClosed": false,
    //     //         "GameEndDateTime": null,
    //     //         "Title": null,
    //     //         "HomeRotationNumber": null,
    //     //         "AwayRotationNumber": null,
    //     //         "Channel": "ESPN+",
    //     //         "NeutralVenue": false,
    //     //         "AwayPointSpreadPayout": null,
    //     //         "HomePointSpreadPayout": null,
    //     //         "OverPayout": null,
    //     //         "UnderPayout": null,
    //     //         "DateTimeUTC": "2024-11-16T18:30:00",
    //     //         "Attendance": null,
    //     //         "Stadium": {
    //     //             "StadiumID": 68,
    //     //             "Active": true,
    //     //             "Name": "Kroger Field",
    //     //             "Dome": false,
    //     //             "City": "Lexington",
    //     //             "State": "KY",
    //     //             "GeoLat": 38.022778,
    //     //             "GeoLong": -84.505278
    //     //         },
    //     //         "Periods": []
    //     //     }
    //     // ]
    //     console.log("Filtered For Team: ", filteredForTeam);

    //     filteredForTeam.sort((a, b) => new Date(a.Day) - new Date(b.Day));

    //     let scheduleDisplay = [];

    //     for (let i = 1; i < 15; i++) {
    //         const gamesInWeek = filteredForTeam?.filter(game => game.Week === i);

    //         if (gamesInWeek.length === 0 && filteredForTeam?.length > 6) {
    //             scheduleDisplay.push({ week: i, opponent: 'BYE WEEK', result: null });
    //         } else {
    //             gamesInWeek.forEach(game => {
    //                 const opponentId = game?.GlobalHomeTeamID === selectedTeamId ? game?.GlobalAwayTeamID : game?.GlobalHomeTeamID;
    //                 const leadingSymbol = game?.GlobalHomeTeamID === selectedTeamId ? 'vs' : '@';
    //                 const opponentLogo = CFBTeams?.find(team => team.GlobalTeamID === opponentId)?.TeamLogoUrl;
    //                 const opponentName = CFBNames?.find(team => team.GlobalTeamID === opponentId)?.ShortDisplayName;
    //                 const status = game?.Status;

    //                 let resultLine1 = null;
    //                 let resultLine2 = null;
    //                 let result = null;

    //                 if (status === 'Final' || status === 'F/OT') {
    //                     const selectedTeamScore = game?.GlobalHomeTeamID === selectedTeamId ? game?.HomeTeamScore : game?.AwayTeamScore;
    //                     const opponentScore = game?.GlobalHomeTeamID === selectedTeamId ? game?.AwayTeamScore : game?.HomeTeamScore;
    //                     result = selectedTeamScore > opponentScore ? 'W' : 'L';
    //                     resultLine1 = result === 'W' ? `${result} ${selectedTeamScore}-${opponentScore}` : `${result} ${opponentScore}-${selectedTeamScore}`;
    //                 } else if (status === 'In Progress') {
    //                     resultLine1 = 'LIVE';
    //                 } else if (status === 'Scheduled') {
    //                     const gameDay = game?.Day;
    //                     const gameTime = game?.DateTime;
    //                     resultLine1 = DateTime.fromISO(gameDay).toFormat('ccc M/d');
    //                     resultLine2 = formatDateTime(gameTime);
    //                 }

    //                 scheduleDisplay.push({
    //                     week: i,
    //                     opponent: `${leadingSymbol} ${opponentName}`,
    //                     opponentLogo,
    //                     result,
    //                     resultLine1,
    //                     resultLine2
    //                 });
    //             });
    //         }
    //     }

    //     return scheduleDisplay;
    // }

    // const teamSchedule = gatherScheduleInfo();
    // console.log("Team Schedule: ", teamSchedule, teamSchedule?.length);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
            }}
        >
            <TableContainer
                component={Paper}
                sx={{
                    width: isMobile ? '300px' : '600px',
                    maxHeight: isMobile ? '305px' : '420px', // Adjust maxHeight for scrolling
                    overflowY: 'auto',
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    width: isMobile ? '56px' : '112px',
                                    height: isMobile ? '33px' : '55px',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    color: '#fff',
                                    backgroundColor: '#002129', // Ensure background color is set here
                                    zIndex: 2, // Increase zIndex to ensure visibility
                                    position: 'sticky', // Make sure it's sticky
                                    top: 0,
                                    padding: '0px'
                                }}
                            >
                                WEEK
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    width: isMobile ? '149px' : '298px',
                                    height: isMobile ? '33px' : '55px',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    color: '#fff',
                                    backgroundColor: '#002129', // Ensure background color is set here
                                    zIndex: 2, // Increase zIndex to ensure visibility
                                    position: 'sticky', // Make sure it's sticky
                                    top: 0,
                                    padding: '0px'
                                }}
                            >
                                OPPONENT
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    width: isMobile ? '76px' : '152px',
                                    height: isMobile ? '33px' : '55px',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    color: '#fff',
                                    backgroundColor: '#002129', // Ensure background color is set here
                                    zIndex: 2, // Increase zIndex to ensure visibility
                                    position: 'sticky', // Make sure it's sticky
                                    top: 0,
                                    padding: '0px'
                                }}
                            >
                                RESULT
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamSchedule?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        fontSize: isMobile ? '10px' : '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        padding: '4px'
                                    }}
                                >
                                    {row.week}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                        padding: '4px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                            gap: isMobile ? '8px' : '16px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: isMobile ? '24px' : '40px',
                                                height: isMobile ? '24px' : '40px',
                                                marginRight: '8px',
                                                display: 'inline-block',
                                                objectFit: 'contain',
                                                marginLeft: isMobile ? '24px' : '100px',
                                            }}
                                        >
                                            {row.opponentLogo ? (
                                                <Box
                                                    component="img"
                                                    src={row.opponentLogo}
                                                    alt="Opponent Logo"
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            ) : null}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '10px' : '14px',
                                                fontWeight: '700',
                                                whiteSpace: 'nowrap',
                                                alignText: 'left'
                                            }}
                                        >
                                            {row.opponent}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                        padding: '4px'
                                    }}
                                >
                                   <Typography
                                        sx={{
                                            fontSize: isMobile ? '10px' : '14px',
                                            fontWeight: '700',
                                            color: row.resultLine1 === 'LIVE' ? '#CC293C' : 'inherit',  // Entire text colored if "LIVE"
                                        }}
                                    >
                                        {row.resultLine1 === 'LIVE' ? (
                                            row.resultLine1
                                        ) : (
                                            <>
                                                <span
                                                    style={{
                                                        color: row.result === 'L' ? '#CC293C' : row.result === 'W' ? '#00AA72' : 'inherit',
                                                    }}
                                                >
                                                    {row.resultLine1?.charAt(0)}
                                                </span>
                                                {row.resultLine1?.slice(1)}
                                            </>
                                        )}
                                    </Typography>

                                    {row.resultLine2 && (
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '10px' : '14px',
                                                fontWeight: '700',
                                            }}
                                        >
                                            {row.resultLine2}
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {teamSchedule?.length < 7 && (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontSize: isMobile ? '10px' : '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        padding: '4px',
                                        height: isMobile ? '235px' : '316px',
                                    }}
                                    colSpan={3}
                                >
                                    {'Only Games Against FBS Opponents Are Displayed'}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default TeamScheduleTable;
