export const handleSetUserInLocalStorage = (opts) => {
  const { headers, data } = opts;

  //Get authorization header from response and set it to local storage
  const authorizationHeader = headers.authorization;
  localStorage.removeItem("authorization");
  localStorage.setItem("authorization", authorizationHeader);

  //Save user data to local storage
  const stringifiedUserData = JSON.stringify(data);
  localStorage.setItem("userData", stringifiedUserData);
};
