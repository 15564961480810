import React, { useState } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNFLPickEmContext } from "../context/NFLPickEmContext";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";

const PickEmHeader = () => {
    const { 
        clickState, 
        setClickState,
        pickEmState,
        setPickEmState,
        gameAddress
     } = useNFLPickEmContext();

     const [pickNotSavedModal, setPickNotSavedModal] = useState(false);
     const [pendingPage, setPendingPage] = useState(null);

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { gameId } = useParams();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleClickState = (click) => {
        if (pickEmState.length === 0) {
            if (clickState === "HOME") {
                navigateToTop(`/pool-hall`);
            } else {
                setClickState("HOME");
                navigateToTop(`/${gameAddress}/${uriEncoded(gameId)}/${uriEncoded("HOME")}/${uriEncoded("HOME")}`);
            }
        } else {
            setPickNotSavedModal(true);
            setPendingPage(click);
        }
    };

    const handleLeavePageClick = () => {
        setClickState("HOME");
        setPickNotSavedModal(false);
        navigateToTop(`/${gameAddress}/${uriEncoded(gameId)}/${uriEncoded("HOME")}/${uriEncoded("HOME")}`);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    backgroundColor: '#002129',
                }}
                className="pickem-header"
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        padding: '0px 20px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: isMobile ? '32px' : '68px',
                    }}
                >
                    <Box
                        onClick={() => handleClickState()}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer', // Change cursor to pointer
                            '&:hover': {
                                '& .MuiTypography-root': {
                                    color: '#FFC60A' // Change text color on hover
                                },
                                '& .MuiSvgIcon-root': {
                                    color: '#FFC60A' // Change icon color on hover
                                }
                            }
                        }}
                    >
                        <ArrowBackIosNewIcon 
                            sx={{ 
                                color: 'white',
                                fontSize: isMobile ? '10px' : '14px',
                                marginLeft: isMobile ? '0px' : '25px', 
                            }} 
                        />
                        <Typography 
                            variant="h1" 
                            sx={{ 
                                color: 'white', 
                                fontSize: isMobile ? '12px' : '16px', 
                                fontWeight: '500', 
                                marginLeft: '8px' 
                            }}
                        >
                            {clickState === "HOME" ? "Pool Hall" : "Fav 5 Home"}
                        </Typography>
                    </Box>

                    <Typography 
                        variant="h1" 
                        sx={{ 
                            color: 'white', 
                            fontSize: isMobile ? '16px' : '28px', 
                            fontWeight: '700',
                            marginRight: isMobile ? '0px' : '32px' 
                        }}
                    >
                        NFL Pick 'Em - Fav 5
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'var(--borderRadius, 4px)',
                        flexShrink: 0,
                        backgroundColor: '#FFC60A',
                    }}
                />
            </Box>
            <Modal
                open={pickNotSavedModal}
                onClose={() => setPickNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
            >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPickNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickEmState([]);
                                    handleLeavePageClick();
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
};

export default PickEmHeader;
