import React, { useState } from "react";
import { Menu, MenuItem, Button, createTheme, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: "var(--primary-color)",
            color: "white",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "var(--color-white)",
          textTransform: "none",
          fontSize: '1rem',
          "&:hover": {
            color: "var(--color-yellow)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.MuiListItem-root": {
            "&:hover": {
              backgroundColor: "transparent", // Remove default hover background
            },
          },
        },
      },
    },
  },
});

export default function BirdiePoolsDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path);
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-controls={open ? "birdie-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        sx={{
          whiteSpace: 'nowrap', // Prevents text from wrapping
          overflow: 'hidden', // Ensures overflow content is hidden
          display: 'inline-block' // Ensures the button remains inline
        }}
      >
        Birdie Pools
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "birdie-button",
        }}
      >
        <MenuItem onClick={() => navigateTo("/private-pools")}>
          Birdie Pools
        </MenuItem>
        <MenuItem onClick={() => navigateTo("/birdie-pool-demo")}>
          Birdie Pool Demo
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
