import React from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { Box, Typography } from "@mui/material";
import trophyImage from '../../assets/images/Layer_1.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './ChampDisplayTemplate.scss';

const ChampDisplayTemplate = ({ leaderboardData }) => {
    const {
        masterPoolData,
        gameData,
    } = useUserAndGameContext();

    const gameName = gameData?.gameName;

    const champDisplayInfo = masterPoolData?.champDisplayInfo;

    //console.log("Game Name: ", gameName, "Champ Display Info: ", champDisplayInfo, "Leaderboard Data: ", leaderboardData);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const getUsersWithRank = (rank) => {
        const usersWithRank = leaderboardData?.filter(player => player.rank === rank);
        return usersWithRank?.map(user => user.username);
    };

    const getUsersGameRank = (gameId, rank) => {
        // Ensure leaderboardData is an array
        if (!Array.isArray(leaderboardData)) {
            console.error("leaderboardData is not an array:", leaderboardData);
            return [];
        }
        
        // Filter users who have the specified rank for the specified game
        const usersWithRank = leaderboardData.filter(user => {
            // Make sure user.gameRanks exists and is an array
            if (!user.gameRanks || !Array.isArray(user.gameRanks)) {
                return false;
            }
            
            // Check if any gameRank entry matches both gameId and rank
            return user.gameRanks.some(
                gameRank => gameRank.gameId === gameId && gameRank.rank === rank
            );
        });
        
        // Map the filtered users to just their usernames
        return usersWithRank.map(user => user.username);
    };

    const getUsersIntervalRank = (interval, rank) => {
        // Ensure leaderboardData is an array
        if (!Array.isArray(leaderboardData)) {
            console.error("leaderboardData is not an array:", leaderboardData);
            return [];
        }

        // Filter users who have the specified rank for the specified interval
        const usersWithRank = leaderboardData.filter(user => {
            // Make sure user.intervalRanks exists and is an array
            if (!user.intervalRanks || !Array.isArray(user.intervalRanks)) {
                return false;
            }

            // Check if any intervalRank entry matches both interval and rank
            return user.intervalRanks.some(
                intervalRank => intervalRank.interval === interval && intervalRank.rank === rank
            );
        });

        // Map the filtered users to just their usernames
        return usersWithRank.map(user => user.username);
    };

    //console.log("Users with Rank 1: ", getUsersWithRank(1));
    //console.log("Users with Rank 2: ", getUsersWithRank(2));
    //console.log("Users with Rank 3: ", getUsersWithRank(3));
    //console.log("Users with Rank 4: ", getUsersWithRank(4));

    return (
        <Box
            className={`champion-display ${isMobile ? 'champion-display--mobile' : ''}`}
            sx={{ backgroundColor: champDisplayInfo?.backgroundColor ? champDisplayInfo.backgroundColor : "#002129" }}
        >
            {/* Header */}
            <Box className="champion-display-header">
                <Typography
                    className={`champion-display-header__text ${isMobile ? 'champion-display-header__text--mobile' : ''}`}
                >
                    {gameName}'s {champDisplayInfo?.header}
                </Typography>
            </Box>

            {champDisplayInfo?.display?.map((display, index) => {
                let usersWithCurrentRank = [];
                if (display?.key === "rank") {
                    usersWithCurrentRank = getUsersWithRank(display.value);
                } else if (display?.key === "gameRank") {
                    usersWithCurrentRank = getUsersGameRank(display.gameId, display.value);
                } else if (display?.key === "intervalRank") {
                    usersWithCurrentRank = getUsersIntervalRank(display.interval, display.value);
                }
            
                const usersText = usersWithCurrentRank?.length > 0 
                    ? usersWithCurrentRank.join(", ") 
                    : "No winners yet";
                
                    return (
                        <React.Fragment key={`${index}-${display.value}`}>
                            <Box className="champion-display-body">
                                {/* Content Row: Trophy - Title - Trophy */}
                                <Box className="champion-display-content">
                                    {/* Left-side trophy */}
                                    <Box
                                        component="img"
                                        src={trophyImage}
                                        alt="Trophy Image"
                                        className="champion-display-image"
                                        sx={{ 
                                            marginRight: '15px',
                                            filter: display.trophyColor === "#FFC60A" 
                                                ? "" 
                                                : display.trophyColor === "#D6D6D6" 
                                                    ? "grayscale(1) brightness(1.1)"
                                                    : "sepia(0.9) saturate(2.5) brightness(0.6) contrast(1.3) hue-rotate(-15deg)"
                                        }}
                                    />
                        
                                    {/* Title in the center */}
                                    <Box className="champion-display-text">
                                        <Typography
                                            className={`champion-display-text__title ${
                                                isMobile
                                                    ? display.title.length > 17
                                                        ? 'champion-display-text__title--small-mobile'
                                                        : 'champion-display-text__title--mobile'
                                                    : ''
                                            }`}
                                        >
                                            {display.title}
                                        </Typography>
                                    </Box>
                        
                                    {/* Right-side trophy */}
                                    <Box
                                        component="img"
                                        src={trophyImage}
                                        alt="Trophy Image"
                                        className="champion-display-image"
                                        sx={{ 
                                            marginLeft: '15px',
                                            filter: display.trophyColor === "#FFC60A" 
                                                ? "" 
                                                : display.trophyColor === "#D6D6D6" 
                                                    ? "grayscale(1) brightness(1.1)"
                                                    : "sepia(0.9) saturate(2.5) brightness(0.6) contrast(1.3) hue-rotate(-15deg)"
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box 
                                className="champion-display-users"
                                sx={{
                                    width: "100%",
                                    maxWidth: isMobile ? '300px' : '500px',
                                    textAlign: "center",
                                    margin: "0 auto",
                                    marginBottom: '16px',
                                }}
                            >
                                <Typography
                                    className="champion-display-text__subtitle"
                                    sx={{ color: "white", fontWeight: "bold" }} // Ensures same white color
                                >
                                    {usersText}
                                </Typography>
                            </Box>
                        </React.Fragment>
                    );
            })}
        </Box>
    );
};

export default ChampDisplayTemplate;