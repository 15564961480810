import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetSlip.scss';

const ParlayTeaserInfo = ({ chosenBetStyle, pickState, betOdds, teaserPoints }) => {
    const numberOfPicks = pickState?.length;

    // Function to capitalize the first letter of the chosenBetStyle
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <Box className="sportsbook-parlay-teaser-box">
            <Typography className="sportsbook-parlay-teaser-line1">
                {numberOfPicks} Leg {chosenBetStyle === "teaser" ? `${teaserPoints}-Point` : null} {capitalizeFirstLetter(chosenBetStyle)}
            </Typography>
            <Typography className="sportsbook-parlay-teaser-line2">
                {chosenBetStyle === "parlay" ? (betOdds > 0 ? `+${betOdds}` : betOdds) : betOdds}
            </Typography>
        </Box>
    );
}

export default ParlayTeaserInfo;
