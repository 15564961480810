import React from "react";
import { UserAndGameProvider } from "../contexts/UserAndGameContext";
import { SurvivorGamesProvider } from "../components/SurvivorGames/context/SurvivorGamesContext";
import SurvivorGamesContainer from "../components/SurvivorGames/SurvivorGamesContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const SurvivorGamePage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <UserAndGameProvider>
                    <SurvivorGamesProvider>
                        <SurvivorGamesContainer />
                    </SurvivorGamesProvider>
                </UserAndGameProvider>
            </QueryClientProvider>
        </div>
    );
};

export default SurvivorGamePage;