import React from "react";
import { CreateAPoolProvider } from "../components/CreateAPool/context/CreateAPoolContext";
import CreateAPoolContainer from "../components/CreateAPool/CreateAPoolContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const CreateAPoolPage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <CreateAPoolProvider>
                    <CreateAPoolContainer />
                </CreateAPoolProvider>
            </QueryClientProvider>
        </div>
    );
}

export default CreateAPoolPage;