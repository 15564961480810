import React, { useState, useEffect } from 'react';
import { Box, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopRow from './TopRow';
import TeamSelectionButtons from './TeamSelectionButtons';
import TeamScheduleTable from './TeamScheduleTable';
import { DateTime } from 'luxon';

const NFLMoreInfoModal = ({ moreInfoOpen, setMoreInfoOpen, selectedGame, setSelectedGame, NFLTeams, NFLScoresBySeason }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//console.log("SCORES BY SEASON: ", NFLScoresBySeason);
  const { homeId, awayId, status, gameTime, gameDateTime, gameLine, homeRank, awayRank, homeName, awayName } = selectedGame;

  const [team, setTeam] = useState('Away Schedule');
  const [selectedTeamId, setSelectedTeamId] = useState(awayId);

  useEffect(() => {
    if (team === 'Away Schedule') {
      setSelectedTeamId(awayId);
    } else {
      setSelectedTeamId(homeId);
    }
  }, [team, homeId, awayId]);

  const homeNameAndRank = homeRank? `(${homeRank}) ${homeName}` : homeName;
  const awayNameAndRank = awayRank? `(${awayRank}) ${awayName}` : awayName;
  
  let textLine1 = null;
  if (status === 'Scheduled') {
    const datetime = DateTime.fromISO(gameTime);
    textLine1 = datetime.toFormat('EEE M/d');
  } else if (status === 'InProgress') {
    textLine1 = "LIVE";
  } else if (status === 'Final' || status === 'F/OT') {
    textLine1 = status;
  }
  let textLine2 = null;
  if (status === 'Scheduled') {
    const gameTime = DateTime.fromISO(gameDateTime, { zone: 'America/New_York' });
    
    if (gameTime.hour === 0 && gameTime.minute === 0) {
      textLine2 = 'TBD';
    } else {
      textLine2 = gameTime.toFormat('h:mma').toLowerCase() + ' ET';
    }
  } else if (status === 'InProgress') {
    textLine2 = gameLine;
  }

  let textLine3 = null; 
  if (status === 'Scheduled') {
    textLine3 = gameLine;
  }

  //console.log("textLine1: ", textLine1, "textLine2: ", textLine2, "textLine3: ", textLine3);


  const formatDateTime = (dateTime) => {
    //console.log("DateTime: ", dateTime);
    const dt = DateTime.fromISO(dateTime, { zone: 'America/New_York' });

    if ((dt.hour === 0 && dt.minute === 0 && dt.second === 0) || dt.invalid) {
        return 'TBD';
    }

    return dt.toFormat('h:mma') + ' ET';
};


function gatherScheduleInfo() {
    const filteredForTeam = NFLScoresBySeason?.filter(game =>
        (game.GlobalHomeTeamID === selectedTeamId || game.GlobalAwayTeamID === selectedTeamId) &&
        game.Status !== 'Canceled'
    );
    //console.log("Filtered For Team: ", filteredForTeam);

        filteredForTeam?.sort((a, b) => new Date(a.Day) - new Date(b.Day));

        let scheduleDisplay = [];

        for (let i = 1; i < 19; i++) {
            const gamesInWeek = filteredForTeam?.filter(game => game.Week === i);

            if (gamesInWeek?.length === 0) {
                scheduleDisplay.push({ week: i, opponent: 'BYE WEEK', result: null });
            } else {
                gamesInWeek?.forEach(game => {
                    const opponentId = game?.GlobalHomeTeamID === selectedTeamId ? game?.GlobalAwayTeamID : game?.GlobalHomeTeamID;
                    const leadingSymbol = game?.GlobalHomeTeamID === selectedTeamId ? 'vs' : '@';
                    const opponentLogo = NFLTeams?.find(team => team.GlobalTeamID === opponentId)?.WikipediaLogoURL;
                    const opponentName = NFLTeams?.find(team => team.GlobalTeamID === opponentId)?.Key;
                    const status = game?.Status;

                    let resultLine1 = null;
                    let resultLine2 = null;
                    let result = null;

                    if (status === 'Final' || status === 'F/OT') {
                        const selectedTeamScore = game?.GlobalHomeTeamID === selectedTeamId ? game?.HomeScore : game?.AwayScore;
                        const opponentScore = game?.GlobalHomeTeamID === selectedTeamId ? game?.AwayScore : game?.HomeScore;
                        result = selectedTeamScore > opponentScore ? 'W' : 'L';
                        resultLine1 = result === 'W' ? `${result} ${selectedTeamScore}-${opponentScore}` : `${result} ${opponentScore}-${selectedTeamScore}`;
                    } else if (status === 'InProgress') {
                        resultLine1 = 'LIVE';
                    } else if (status === 'Scheduled') {
                        const gameDay = game?.Day;
                        const gameTime = game?.DateTime;
                        resultLine1 = DateTime.fromISO(gameDay).toFormat('ccc M/d');
                        resultLine2 = formatDateTime(gameTime);
                    }

                    scheduleDisplay.push({
                        week: i,
                        opponent: `${leadingSymbol} ${opponentName}`,
                        opponentLogo,
                        result,
                        resultLine1,
                        resultLine2
                    });
                });
            }
        }

        return scheduleDisplay;
    }

    const teamSchedule = gatherScheduleInfo();
    //console.log("Team Schedule: FROM MODAL ", teamSchedule, teamSchedule?.length);


  return (
    <Modal
      open={moreInfoOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent="div" // Use 'div' for a custom backdrop
      // BackdropProps={{
      //   sx: {
      //     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      //   },
      // }}
      
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '350px' : '650px',
          height: 'auto',
          backgroundColor: '#FFF',
          borderRadius: '4px',
          boxShadow: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column', // Ensure items are stacked vertically
          alignItems: 'center',     // Center items horizontally
          outline: 'none',          // Remove focus border
        }}
      >
        {/* Your content goes here */}
        <TopRow selectedGame={selectedGame} textLine1={textLine1} textLine2={textLine2} textLine3={textLine3} homeNameAndRank={homeNameAndRank} awayNameAndRank={awayNameAndRank}/>
        <TeamSelectionButtons team={team} setTeam={setTeam} selectedGame={selectedGame}/>
        <TeamScheduleTable teamSchedule={teamSchedule}/>
        <Button 
          variant="contained"
          onClick={() => setMoreInfoOpen(false)}
          sx={{
            width: '115px',
            height: '38px',
            marginTop: '16px',
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default NFLMoreInfoModal;
