import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../../BirdiePool/hooks/index";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../../redux/actions/loginImport";
import useNavigateToTop from "../../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../../redux/actions/loginImport";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import { useCFBTeams, useCFBGamesByWeek, useCFBNames, useCFBCurrentWeek, useQuickPickCFB } from "../../../../hooks/cfb/index";

const QuickPickCFBContext = createContext();

export const useQuickPickCFBContext = () => {
    const context = useContext(QuickPickCFBContext);
    if (!context) {
        throw new Error("useQuickPickCFBContext must be used within a QuickPickCFBProvider");
    }
    return context;
};

const QuickPickCFBProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    console.log("myUserId: ", myUserId);

    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchCFBTeams, data: cfbTeams, isLoading: cfbTeamsLoading, isError: cfbTeamsError, error: cfbTeamsErrorData } = useCFBTeams();
    const { mutate: fetchCFBGamesByWeek, data: cfbGamesByWeekData, isLoading: cfbGamesByWeekLoading, isError: cfbGamesByWeekError, error: cfbGamesByWeekErrorData } = useCFBGamesByWeek();
    const { mutate: fetchCFBNames, data: cfbNames, isLoading: cfbNamesLoading, isError: cfbNamesError, error: cfbNamesErrorData } = useCFBNames();
    const { mutate: fetchCFBCurrentWeek, data: cfbCurrentWeek, isLoading: cfbCurrentWeekLoading, isError: cfbCurrentWeekError, error: cfbCurrentWeekErrorData } = useCFBCurrentWeek();
    const { mutate: fetchQuickPickCFB, data: quickPickCFBData, isLoading: quickPickCFBLoading, isError: quickPickCFBError, error: quickPickCFBErrorData } = useQuickPickCFB();

    const gameId = "13f7bc16-9363-460b-be69-3d149b38ce3b";
    const gameType = "QuickPick";

    const dispatch = useDispatch();
    const navigateToTop = useNavigateToTop();


    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    console.log("userData: ", userData);

    // Fetch game data second
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    console.log("gameData: ", gameData);

    // Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);

    
    const [pickEmState, setPickEmState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(null);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState("");
    const [partyId, setPartyId] = useState(gameId);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    console.log("Data Fetched????? ", hasFetchedData);


    useEffect(() => {
        console.log("Running Use Effect");
        if (!hasFetchedData) {
                console.log("Fetching CFB Data");
                fetchCFBTeams();
                fetchCFBGamesByWeek();
                fetchCFBNames();
                fetchCFBCurrentWeek();
                fetchQuickPickCFB();

            setHasFetchedData(true);
        }
    }, [hasFetchedData, fetchCFBTeams, fetchCFBGamesByWeek, fetchCFBNames, fetchCFBCurrentWeek, fetchQuickPickCFB]);
    console.log("cfbTeams: ", cfbTeams, "cfbGamesByWeek: ", cfbGamesByWeekData, "cfbNames: ", cfbNames, "cfbCurrentWeek: ", cfbCurrentWeek, "quickPickCFBData: ", quickPickCFBData);

    const gameParameters = gameData?.gameParameters[0];
    //console.log("gameParameters: ", gameParameters);

    const startingWeek = gameParameters?.startingWeek;
    const endingWeek = gameParameters?.endingWeek;
    const teamsToInclude = gameParameters?.teamsToInclude; // Leave this as undefined or null for no filtering
    const daysToInclude = gameParameters?.daysToInclude;  // Leave this as undefined or null for no filtering, format is ["Sunday", "Saturday"]
    const filterTime = gameParameters?.filterTime;  // Leave this as undefined or null for no filtering, format is "HH:MM" (18:00 for 6:00 PM)
    const filterDate = gameParameters?.filterDate; // Leave this as undefined or null for no filtering, format is "YYYY-MM-DD" (2024-12-25 for December 25, 2024)
    const homeOnly = gameParameters?.homeOnly;  // If true, only include games where the home team is in teamsToInclude
    const awayOnly = gameParameters?.awayOnly;  // If true, only include games where the away team is in teamsToInclude

    console.log("teamsToInclude: ", teamsToInclude, "startingWeek: ", startingWeek, "daysToInclude:", daysToInclude, "filterTime:", filterTime, "filterDate:", filterDate);
    
    let cfbGamesByWeek = cfbGamesByWeekData;
    //console.log("cfbGamesByWeek BEFORE filter: ", cfbGamesByWeek);

    if (startingWeek && cfbGamesByWeekData && gameParameters) {
        cfbGamesByWeek = cfbGamesByWeekData?.filter((game) => {
            // Default condition: Check if the game week is >= startingWeek
            const isWeekValid = game.Week >= Number(startingWeek) && game.Week <= Number(endingWeek); // Check if the game week is within the range

            // Check homeOnly and awayOnly conditions
            const isHomeTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalHomeTeamID); // Include all if teamsToInclude is undefined
            const isAwayTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalAwayTeamID); // Include all if teamsToInclude is undefined

            // Get the day of the week from the game's date
            const gameDay = new Date(game.Date).toLocaleString('en-US', { weekday: 'long' });
            const isDayValid = !daysToInclude || daysToInclude.includes(gameDay); // Ignore days filter if it doesn't exist

            // Time Filter (in ET)
            const gameTimeET = new Date(game.DateTime).toLocaleString('en-US', { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit', hour12: false });
            const isTimeUndetermined = gameTimeET === "00:00"; // Check if time is undetermined
            const isTimeValid = !isTimeUndetermined && (!filterTime || gameTimeET >= filterTime); // Only allow games after filterTime if provided

            // Date Filter (check if the game's date matches the filterDate)
            const gameDate = new Date(game.DateTime).toISOString().split('T')[0]; // Extract the date part in "YYYY-MM-DD" format
            const isDateValid = !filterDate || gameDate === filterDate; // Only include games on the specific date

            // Logic to handle homeOnly and awayOnly flags
            if (homeOnly) {
                // If homeOnly is true, include games where the home team is in teamsToInclude
                return isWeekValid && isHomeTeamIncluded && isDayValid && isTimeValid && isDateValid;
            } else if (awayOnly) {
                // If awayOnly is true, include games where the away team is in teamsToInclude
                return isWeekValid && isAwayTeamIncluded && isDayValid && isTimeValid && isDateValid;
            } else {
                // Default behavior: Include games where either home or away team is in teamsToInclude
                return isWeekValid && (isHomeTeamIncluded || isAwayTeamIncluded) && isDayValid && isTimeValid && isDateValid;
            }
        });
    }

    console.log("cfbGamesByWeek AFTER filter: ", cfbGamesByWeek);

    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;

    // Make ALL OF THESE DYNAMIC
    const currentInterval = cfbCurrentWeek;
    const userSubgroups = userData?.subgroups;
    const doesUserBelong = false;
    const whosInFirst = "John Doe";
    const whosInFirstCharacterCount = 10;
    const isGameOver = false;
    const hasJoinDeadlinePassed = false;
    const showConsenus = false;

    // const redisData = cfbGamesByWeekData?.filter(game => game.Week >= currentInterval && (game.GlobalAwayTeamID === 50000028 || game.GlobalHomeTeamID === 50000028 || game.GlobalAwayTeamID === 50000027 || game.GlobalHomeTeamID === 50000027)).map(game => {
    //     return {
    //         GlobalGameID: game.GlobalGameID,
    //         OverUnder: game.OverUnder,
    //         HomeTeam: game.HomeTeam,
    //         AwayTeam: game.AwayTeam,
    //     };
    // });
    // console.log("Redis Data: ", redisData);

    

    const QuickPickCFBContextValues = {
        pickEmState,
        setPickEmState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        gameAddress,
        gameData,
        myUserId,
        mainGameId: gameId,
        userSubgroups,
        doesUserBelong,
        currentInterval,
        gameType,
        whosInFirst,
        whosInFirstCharacterCount,
        isGameOver,
        hasJoinDeadlinePassed,
        gameName,
    };

    // ADD LOADING SCREEN HERE

    return (
        <QuickPickCFBContext.Provider value={QuickPickCFBContextValues}>
            {children}
        </QuickPickCFBContext.Provider>
    );
};

export default QuickPickCFBProvider;