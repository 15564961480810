import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import MyPartyInvitePage from "./MyPartyInvitePage";
import MyPartyManageMembers from "./MyPartyManageMembers";
import MyPartyEmailMembers from "./MyPartyEmailMembers";
import CreatePartyInfo from "./CreatePartyInfo";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams, useNavigate } from "react-router-dom";

const MyPartyCommissionerPage = ( {
    gameData,
    myUserId,
    userSubgroups,
    gameAddress,
    leaderboardData,
    fullLeaderboard,
    partyState,
    amICommissioner,
    amICreator,
    subgroupInfo,
    setSignUpState,
    doesUserBelong,
    setJoinOpen,
    isGameOver,
  } ) => {
  const [page, setPage] = useState('Send Invites');
  const { gameId, component, pageState, optionalState } = useParams();
  //console.log("optionalState:", gameId, component, pageState, optionalState);
  //console.log("Is Game Over? ", isGameOver);

  useEffect(() => {
    if (isGameOver && page === 'Send Invites') {
      setPage('Manage Members');
    }
  }, [isGameOver]);
  
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (optionalState) {
      if (optionalState === 'INVITE') {
        setPage('Send Invites');
      } else if (optionalState === 'MANAGE') {
        setPage('Manage Members');
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('MANAGE')}`);
      } else if (optionalState === 'EMAIL') {
        setPage('Email Members');
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('EMAIL')}`);
      }
    }
  }, [optionalState]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const gameName = gameData?.gameName;

  //console.log("Does User Belong? MY PARTY COMMISSIONER PAGE:", doesUserBelong);


    return (
        <div>
            <Typography
                variant="h3"
                style={{
                    color: "white",
                    background: "#002F3A",
                    fontWeight: '700',
                    margin: '0 auto',
                    padding: '5px 0',
                    fontSize: isMobile ? '12px' : '14px'
                }}
            >
                {amICommissioner ? `Manage ${partyState}` : `${partyState} Overview`}
            </Typography>

            <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '675px', margin:'15px auto'}}>
              {!isGameOver && (
                <span
                  style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Send Invites' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                  onClick={() => {
                    setPage('Invite Members');
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('INVITE')}`);
                  }}
                >
                  Send Invites
                </span>
              )}
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Manage Members' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                  setPage('Manage Members');
                  navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('MANAGE')}`);
                }}              
              >
                Manage Members
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'Email Members' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => {
                  setPage('Email Members');
                  navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(pageState)}/${uriEncoded('EMAIL')}`);
                }}              
              >
                Email Members
              </span>
            </div>
            {page === 'Send Invites' && <MyPartyInvitePage gameData={gameData} myUserId={myUserId} userSubgroups={userSubgroups} gameAddress={gameAddress} partyState={partyState}/>}
            {page === 'Manage Members' && <MyPartyManageMembers gameData={gameData} myUserId={myUserId} userSubgroups={userSubgroups} gameAddress={gameAddress} leaderboardData={leaderboardData} partyState={partyState} amICommissioner={amICommissioner} amICreator={amICreator} subgroupInfo={subgroupInfo} fullLeaderboard={fullLeaderboard}/>}
            {page === 'Email Members' && <MyPartyEmailMembers leaderboardData={leaderboardData} isGameOver={isGameOver}/>}

            {!isGameOver &&
              <>
                <Typography
                    style={{
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '700',
                        marginBottom: '16px',
                        marginTop: '64px'
                    }}
                >
                    Create Another Party?
                </Typography>

              
                <CreatePartyInfo
                  setSignUpState={setSignUpState}
                  gameAddress={gameAddress}
                  doesUserBelong={doesUserBelong}
                  gameName={gameName}
                  setJoinOpen={setJoinOpen}
                />
              </>
            }
        </div>
    );
}

export default MyPartyCommissionerPage;