import React, { useState, useMemo } from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ReferralsPrizes from './ReferralsPrizes';
import './ReferralsLeaderboard.scss';

const ReferralsLeaderboard = ({ gameReferrals, myUsername, referralPrizes }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const myReferral = gameReferrals?.find(referral => referral.username === myUsername);

    // Sorting state
    const [sort, setSort] = useState({
        column: 'referralCount',
        direction: 'desc'
    });

    // Sorting function
    const sortedReferrals = useMemo(() => {
        return [...gameReferrals]?.sort((a, b) => {
            // Determine sorting logic based on current column
            switch (sort.column) {
                case 'username':
                    const nameCompare = a.username.localeCompare(b.username);
                    return sort.direction === 'asc' ? nameCompare : -nameCompare;
                
                case 'referralCount':
                    // Primary sort by referral count
                    if (a.referralCount !== b.referralCount) {
                        return sort.direction === 'asc'
                            ? a.referralCount - b.referralCount
                            : b.referralCount - a.referralCount;
                    }
                    // Secondary sort by new sign-ups if referral count is equal
                    return sort.direction === 'asc'
                        ? a.newSignUps - b.newSignUps
                        : b.newSignUps - a.newSignUps;
                
                case 'newSignUps':
                    // Primary sort by new sign-ups
                    if (a.newSignUps !== b.newSignUps) {
                        return sort.direction === 'asc'
                            ? a.newSignUps - b.newSignUps
                            : b.newSignUps - a.newSignUps;
                    }
                    // Secondary sort by referral count if new sign-ups are equal
                    return sort.direction === 'asc'
                        ? a.referralCount - b.referralCount
                        : b.referralCount - a.referralCount;
                
                default:
                    return 0;
            }
        });
    }, [gameReferrals, sort]);

    // Function to handle column click for sorting
    const handleColumnClick = (column) => {
        setSort(prevSort => ({
            column,
            // Toggle direction if same column, otherwise start with desc
            direction: prevSort.column === column 
                ? (prevSort.direction === 'asc' ? 'desc' : 'asc')
                : 'desc'
        }));
    };

    return (
        <Paper
            sx={{
                boxShadow: 'none',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#002129',
                    padding: '16px',
                }}
            >
                Referrals Leaderboard
            </Typography>

            <ReferralsPrizes
                referralPrizes={referralPrizes}
            />

            {myReferral && (
                <TableContainer
                    sx={{
                        maxWidth: '600px',
                        margin: '0 auto',
                        backgroundColor: '#F0F1F1',
                    }}
                >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        color: '#002129',
                                    }}
                                >
                                    {myUsername}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                        color: '#002129',
                                    }}
                                >
                                    {myReferral.referralCount}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                        color: '#002129',
                                    }}
                                >
                                    {myReferral.newSignUps}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <TableContainer
                sx={{
                    maxWidth: '600px',
                    margin: '0 auto',
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell 
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('username')}
                                sx={{ 
                                    width: '40%', 
                                    color: sort?.column === 'username' ? '#FFC60A' : '#fff', 
                                }}>
                                PLAYER
                            </TableCell>
                            <TableCell 
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('referralCount')}
                                sx={{ 
                                    width: '30%', 
                                    textAlign: 'center',
                                    color: sort?.column === 'referralCount' ? '#FFC60A' : '#fff',
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        Total<br />Referrals
                                    </>
                                ) : (
                                    'Total Referrals'
                                )}
                            </TableCell>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('newSignUps')}
                                sx={{
                                    width: '30%',
                                    textAlign: 'center',
                                    color: sort?.column === 'newSignUps' ? '#FFC60A' : '#fff',
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        New<br />Sign-Ups
                                    </>
                                ) : (
                                    'New Sign-Ups'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedReferrals.map((referral, index) => (
                            <TableRow 
                                key={referral.username}
                                sx={{
                                    backgroundColor: referral?.username === myUsername ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                }}    
                            >
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                >
                                    {referral.username}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {referral.referralCount}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {referral.newSignUps}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ReferralsLeaderboard;