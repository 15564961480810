import React from "react";
import { Typography, Box } from '@mui/material';
import './PillBox.scss';

const PercentagePillBoxTwoSided = ({
    leftCount,
    rightCount,
    leftText,
    rightText,
    leftResult,
    rightResult,
}) => {

    // Total count
    const total = leftCount + rightCount;

    // Formatter for dynamic decimals (only shows decimal if necessary)
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1, // Allows up to 1 decimal place
    });

    // Handle division by zero
    const leftPercentage = total > 0 ? formatter.format((leftCount / total) * 100) : 0;
    const rightPercentage = total > 0 ? formatter.format((rightCount / total) * 100) : 0;
    
    // Calculate actual width percentages for the boxes
    const leftWidth = total > 0 ? (leftCount / total) * 100 : 0;
    const rightWidth = total > 0 ? (rightCount / total) * 100 : 0;

    // Normalize result to lowercase for case-insensitive comparison
    const overallResult = leftCount >= rightCount ? leftResult : rightResult;
    const normalizedResult = overallResult?.toLowerCase(); // Convert to lowercase

    // Determine border color based on result
    const borderColor = normalizedResult === 'win' 
        ? '#00AA72' 
        : normalizedResult === 'loss' 
            ? '#CC293C' 
            : '#002129';

    return (
        <Box className="pill-box-two-sided-container">
            {/* Only render left side if it has some percentage */}
            {leftWidth > 0 && (
                <Box 
                    className="pill-box-two-sided-left-half-container"
                    sx={{
                        borderRadius: rightWidth === 0 ? '8px' : '8px 0px 0px 8px',
                        border: `1px solid ${borderColor}`,
                        width: `${leftWidth}%`,
                        backgroundColor: leftWidth < rightWidth ? '#fff' : 
                            normalizedResult === 'win' ? '#00AA72' : 
                            normalizedResult === 'loss' ? '#CC293C' : '#002129',
                    }}
                >
                    <Typography 
                        className="pill-box-two-sided-text"
                        sx={{
                            color: leftWidth < rightWidth ? '#002129' : '#fff'
                        }}
                    >
                        {leftCount >= rightCount ? `${leftText} ${leftPercentage}%` : ''}
                    </Typography>
                </Box>
            )}
            
            {/* Only render right side if it has some percentage */}
            {rightWidth > 0 && (
                <Box
                    className="pill-box-two-sided-right-half-container"
                    sx={{
                        borderRadius: leftWidth === 0 ? '8px' : '0px 8px 8px 0px',
                        border: `1px solid ${borderColor}`,
                        width: `${rightWidth}%`,
                        backgroundColor: rightWidth <= leftWidth ? '#fff' : 
                            normalizedResult === 'win' ? '#00AA72' : 
                            normalizedResult === 'loss' ? '#CC293C' : '#002129',
                    }}
                >
                    <Typography 
                        className="pill-box-two-sided-text"
                        sx={{
                            color: rightWidth <= leftWidth ? '#002129' : '#fff'
                        }}
                    >
                        {rightCount > leftCount ? `${rightText} ${rightPercentage}%` : ''}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default PercentagePillBoxTwoSided;