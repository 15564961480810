import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./../BirdieRules.scss";
import PoolPartyTrophyGold from '../../../../assets/images/PoolPartyTrophyGold.svg';
import PoolPartyTrophyGray from '../../../../assets/images/PoolPartyTrophyGray.svg';
import BronzeTrophy from '../../../../assets/images/BronzeTrophy.svg';
import { useBirdieContext } from "../../context/BirdiePoolContext";


const PoolPartyDemoRules = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { headerDisplayName, groupState, birdiePoolData, clubLeaderboardInfo } = useBirdieContext();
  const isThisMainGame = headerDisplayName === groupState;
  const gameLeaderboard = clubLeaderboardInfo?.leaderboardInfo;
  const gameCreator = gameLeaderboard?.find(entry => entry.creator === true);
  const creatorName = gameCreator?.userName;
  const coHostsNames = gameLeaderboard?.filter(entry => entry.commissioner === true && entry.creator === false).map(entry => entry.userName);
  //console.log("HEADER DISPLAY NAME and GROUP STATE", headerDisplayName, groupState, isThisMainGame, clubLeaderboardInfo, gameCreator, creatorName, coHostsNames);


  return (
    <Box className="rules-container">
      {isThisMainGame && (
      <>
        <Typography variant="h4" className="club-headers">
          Your Club Rules <Typography variant="h6" style={{fontSize: isMobile ? '16px' : '20px'}}>(Customizable - Template Below)</Typography>
          </Typography>

        <Typography variant="h5" className="rules-headers" style={{ marginTop: '20px', fontWeight: '600'}}>
          Pool Objective
        </Typography>

        <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
          {isMobile ? (
            <>
              Be the first player to birdie <br/> all 18 holes at (Your Club).
            </>
          ) : (
            <>
              Be the first player to birdie all 18 holes at (Your Club).
            </>
          )}
        </Typography>
      
      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px', maxWidth: '400px'}}>
        (If players do not acheive this feat, the winners will be determined by the most holes birdied at the pool end date.  Ties will be settled by the player with the highest overall birdie count.)
      </Typography>
      </>
      )}

      {!isThisMainGame && (
        <>
          <Typography variant="h4" className="club-headers" style={{ fontWeight: '600'}}>
            Demo Party Rules <Typography variant="h6" style={{fontSize: isMobile ? '16px' : '20px'}}>(Customizable - Template Below)</Typography>
          </Typography>

          <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
            The following rules remain from the {headerDisplayName}.  Please reach out 
            to Host {creatorName} for any custom rules.
          </Typography>
        </>
      )}

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Duration
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        <b>Start Date:</b> Tuesday April 1st <br />
        <b>End Date:</b> Sunday November 30th
      </Typography>
        {isThisMainGame && (
        <>
      {/* <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Buy In
        </Typography>   

        <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
            $25 Entry (Added to account by the pro-shop.)
        </Typography> */}

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Prizes
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        TBD
      </Typography>
      
        </> 
        )}

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
          Pool Rules  
        </Typography>

        <Box sx={{ maxWidth: '475px', margin: '0 auto', textAlign: 'left' }}>
          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>1. Gross</span> 
            <span> - Only birdies achieved on your gross score (without handicap adjustments) will be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>2. Birdies or Better</span> 
            <span> - Birdies, eagles, and albatrosses will count as eligible birdies.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>3. No solo round birdies</span> 
            <span> - You must be playing with at least 1 other person during your round for a birdie to be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>4. Official Rounds Only</span> 
            <span> - Only birdies that occur during an Official Round (9 or 18 holes) will be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>5. Tee Selection</span> 
            <span> - Eligible birdies must be from competition level tees.<br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>  <b>Non-Senior Men:</b> White or Blue Tees <br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>  <b>Senior Men:</b> Gold, White or Blue Tees <br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}><b>Women:</b> Red Tees (or beyond)</span>
          </Typography>
        </Box>

        {isThisMainGame && (
          <>
        <Typography variant="h4" className="club-headers" style={{ marginTop: '35px' }}>
          Pool Party Extras
        </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        <span style={{ fontWeight: 'bold' }}>Private Parties</span> - In addition to the club-wide
        pool, you can create a competition with your custom group.
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '20px', marginBottom: '30px'}}>
        <span style={{ fontWeight: 'bold' }}>Total Birdies</span> - Beyond tiebreakers,
        feel free to implement Total Birdies into your My Party Pools.
      </Typography>
      </>
      )}

    </Box>
  );
}

export default PoolPartyDemoRules;

