import React from 'react';
import { Box, Button } from '@mui/material';
import './SportsbookSportSelector.scss';  // Import the SCSS file

const LeagueSelector = ({ leagues, setCurrentLeague }) => {
    return (
        <Box className="sportsbook-league-selector-container">
            {leagues.map((league) => (
                <Button 
                    key={league}
                    variant="outlined" 
                    onClick={() => setCurrentLeague(league)} 
                    className="sportsbook-league-button"
                >
                    {league}
                </Button>
            ))}
        </Box>
    );
};

export default LeagueSelector;
