import React, { useMemo } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Typography, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import './SurvivorGameCard.scss';
import { formatGameCardDate } from "../../../utils/DateFormatting/GameCardDateFormat";
import { getTeamLogo } from "../../../utils/SportsData";
import { getTeamNameShort } from "../../../utils/SportsData";
import { getSurvivorUtils } from "../../../utils/GameCardFormatting/SurvivorFormatting";
import { DateTime } from 'luxon';
import { useSaveSurvivorPicks } from "../../../hooks/pools/useSaveSurvivorPicks";


const SurvivorGameCard = React.memo(({
    thisGamesId,
    game,
    interval,
    theCurrentInterval,
    teams,
    league,
    isEntryEliminated,
    eliminationInterval,
    pickState,
    hasThisIntervalsDeadlinePassed,
    timeNowInNewYork,
    intervalInfo,
    hasUsersGameStarted,
    myUserId,
    pickIndex,
    masterPoolId,
    handleSurvivorSubmit,
    noNoonGames,
    hasTheCurrentIntervalsDeadlinePassed,
}) => {
    //const hasThisIntervalsDeadlinePassed = false;
    const { mutate: saveSurvivorPicks } = useSaveSurvivorPicks();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    const gameTimeInNewYork = DateTime.fromISO(game?.DateTime, { zone: 'America/New_York' });
    const hasGameStarted = gameTimeInNewYork < timeNowInNewYork;
    //console.log("Has Game Started: ", hasGameStarted);

    const whoWon = useMemo(() => {
        if (!game?.IsClosed || (game?.AwayTeamScore === undefined && game?.AwayScore === undefined)) {
            return null;
        } else {
            const awayScore = game?.AwayTeamScore ?? game?.AwayScore ?? 0;
            const homeScore = game?.HomeTeamScore ?? game?.HomeScore ?? 0;
            
            if (awayScore > homeScore) {
                return 'away';
            } else {
                return 'home';
            }
        }
    }, [game?.IsClosed, game?.AwayTeamScore, game?.AwayScore, game?.HomeTeamScore, game?.HomeScore]);
    //console.log("Who Won: ", whoWon, game?.AwayTeam);

    // Memoize the survivorUtils to prevent unnecessary recreation
    const survivorUtils = useMemo(() => getSurvivorUtils(), []);

    const isGameSelected = useMemo(() => {
        return pickState?.find(pick => pick?.gameId === thisGamesId);
    }, [pickState, thisGamesId]);

    const isTopSelectedNow = useMemo(() => {
        return pickState?.some(pick => pick?.teamId === game?.GlobalAwayTeamID && pick?.interval === Number(interval));
    }, [game?.GlobalAwayTeamID, interval, pickState]);

    const isBottomSelectedNow = useMemo(() => {
        return pickState?.some(pick => pick?.teamId === game?.GlobalHomeTeamID && pick?.interval === Number(interval));
    }, [game?.GlobalHomeTeamID, interval, pickState]);

    const isTopSelectedOtherGame = useMemo(() => {
        return pickState?.find(pick => pick?.gameId !== thisGamesId && pick?.teamId === game?.GlobalAwayTeamID);
    }, [game?.GlobalAwayTeamID, pickState, thisGamesId]);

    const isBottomSelectedOtherGame = useMemo(() => {
        return pickState?.find(pick => pick?.gameId !== thisGamesId && pick?.teamId === game?.GlobalHomeTeamID);
    }, [game?.GlobalHomeTeamID, pickState, thisGamesId]);

    const isTopRestricted = useMemo(() => {
        return (
            // Case 1: Selected in another game with earlier or current interval (with passed deadline)
            (isTopSelectedOtherGame && 
                (Number(isTopSelectedOtherGame?.interval) < Number(theCurrentInterval) || 
                 (Number(isTopSelectedOtherGame?.interval) === Number(theCurrentInterval) && hasTheCurrentIntervalsDeadlinePassed))
            ) || 
            // Case 2: Current game restrictions
            ((hasGameStarted || hasThisIntervalsDeadlinePassed) && !isTopSelectedNow)
        );
    }, [isTopSelectedOtherGame, theCurrentInterval, hasGameStarted, hasThisIntervalsDeadlinePassed, isTopSelectedNow, hasTheCurrentIntervalsDeadlinePassed]);
    
    const isBottomRestricted = useMemo(() => {
        return (
            // Case 1: Selected in another game with earlier or current interval (with passed deadline)
            (isBottomSelectedOtherGame && 
                (Number(isBottomSelectedOtherGame?.interval) < Number(theCurrentInterval) || 
                 (Number(isBottomSelectedOtherGame?.interval) === Number(theCurrentInterval) && hasTheCurrentIntervalsDeadlinePassed))
            ) || 
            // Case 2: Current game restrictions
            ((hasGameStarted || hasThisIntervalsDeadlinePassed) && !isBottomSelectedNow)
        );
    }, [isBottomSelectedOtherGame, theCurrentInterval, hasGameStarted, hasThisIntervalsDeadlinePassed, isBottomSelectedNow, hasTheCurrentIntervalsDeadlinePassed]);
    //console.log("Is Game Selected: ", isGameSelected, "Is Top Selected Now: ", isTopSelectedNow, "Is Bottom Selected Now: ", isBottomSelectedNow, "Is Top Selected Other Game: ", isTopSelectedOtherGame, "Is Bottom Selected Other Game: ", isBottomSelectedOtherGame, "Interval", interval);
    //console.log("Is Top Restricted: ", isTopRestricted, "Is Bottom Restricted: ", isBottomRestricted);

    //console.log("Survivor Game Card: ", game);

    const findSelectedIntervalName = (interval) => {
        if (intervalInfo?.type === 'weeks') {
            return isMobile ? `Wk${interval}` : `Week ${interval}`;
        } else if (intervalInfo?.type === 'date') {
            // Find the abbreviation in the intervalInfo.info
            const dateInfo = intervalInfo?.info.find(item => item.interval === Number(interval));
            //console.log("Date Info: ", dateInfo);
            return dateInfo?.abbreviation || '';
        }
    };

    const topHeaderColor = useMemo(() => {
        if (!isGameSelected) {
            return;
        } else if (!hasGameStarted && !hasThisIntervalsDeadlinePassed) {
            return '#00AAD1';
        } else if (!isGameSelected?.result) {
            return '#002129';
        } else if (isGameSelected?.result === 'win') {
            return '#00AA72';
        } else if (isGameSelected?.result === 'loss') {
            return '#CC293C';
        }
    }, [isGameSelected, hasGameStarted, hasThisIntervalsDeadlinePassed]);
    

    // Memoize the getButtonText function results
    const getButtonText = useMemo(() => {
        return (home, teamId) => {
            if ((hasGameStarted || hasThisIntervalsDeadlinePassed) && !game?.IsClosed && !isGameSelected) {
                return <LockIcon sx={{ fontSize: isMobile ? '20px' : '24px'}} />;
            } else if (game.IsClosed) {
                if (home) {
                    return (game?.HomeTeamScore || game?.HomeScore) 
                        ? game?.HomeTeamScore || game?.HomeScore 
                        : isBottomSelectedNow ? getTeamNameShort(league, teamId, teams) : <LockIcon sx={{ fontSize: isMobile ? '20px' : '24px'}} />;
                } else {
                    return (game?.AwayTeamScore || game?.AwayScore) 
                        ? game?.AwayTeamScore || game?.AwayScore 
                        : isTopSelectedNow ? getTeamNameShort(league, teamId, teams) : <LockIcon sx={{ fontSize: isMobile ? '20px' : '24px'}} />;
                }
            } else if (isEntryEliminated && eliminationInterval < Number(interval)) {
                return 'OUT';
            } else if ((home && isBottomRestricted) || (!home && isTopRestricted)) {
                return <LockIcon sx={{ fontSize: isMobile ? '20px' : '24px'}} />;
            }
            return getTeamNameShort(league, teamId, teams);
        };
    }, [game.IsClosed, game.HomeTeamScore, game.HomeScore, game.AwayTeamScore, game.AwayScore, league, teams, isEntryEliminated, eliminationInterval, isTopRestricted, isBottomRestricted, isGameSelected, hasGameStarted, hasThisIntervalsDeadlinePassed, isTopSelectedNow, isBottomSelectedNow, interval, isMobile]);

    // Memoize button color
    const getButtonColor = useMemo(() => {
        return (home) => {
            if (!isGameSelected) {
                if (!hasGameStarted && !hasThisIntervalsDeadlinePassed) {
                    return '#E5E5E5';
                } else if ((home && whoWon === 'home') || (!home && whoWon === 'away')) {
                    return '#CCFFEF';
                } else {
                    return '#fff';
                }
            } else if (home && isBottomSelectedNow) {
                if (isGameSelected?.result === 'win') {
                    return '#00AA72';
                } else if (isGameSelected?.result === 'loss') {
                    return '#CC293C';
                } else if (hasGameStarted || hasThisIntervalsDeadlinePassed) {
                    return '#002129';
                } else {
                    return '#00AAD1';
                }
            } else if (!home && isTopSelectedNow) {
                if (isGameSelected?.result === 'win') {
                    return '#00AA72';
                } else if (isGameSelected?.result === 'loss') {
                    return '#CC293C';
                } else if (hasGameStarted || hasThisIntervalsDeadlinePassed) {
                    return '#002129';
                } else {
                    return '#00AAD1';
                }
            }
        }
    }, [isGameSelected, whoWon, hasGameStarted, hasThisIntervalsDeadlinePassed, isTopSelectedNow, isBottomSelectedNow]);


    // Memoize the team names and game details that are used in the UI
    const formattedDate = useMemo(() => 
        formatGameCardDate(game.DateTime, game.IsClosed, noNoonGames),
        [game.DateTime, game.IsClosed, noNoonGames]
    );

    const gameStatus = useMemo(() => 
        game?.IsClosed ? game?.Status : game?.Channel || "",
        [game?.IsClosed, game?.Status, game?.Channel]
    );

    const awayTeamLogo = useMemo(() => 
        getTeamLogo(league, game?.GlobalAwayTeamID, teams),
        [league, game?.GlobalAwayTeamID, teams]
    );

    const homeTeamLogo = useMemo(() => 
        getTeamLogo(league, game?.GlobalHomeTeamID, teams),
        [league, game?.GlobalHomeTeamID, teams]
    );

    const awayTeamRow = useMemo(() => 
        survivorUtils?.findTeamRow(false, game, league, teams),
        [survivorUtils, game, league, teams]
    );

    const homeTeamRow = useMemo(() => 
        survivorUtils?.findTeamRow(true, game, league, teams),
        [survivorUtils, game, league, teams]
    );

    const bottomLeftText = useMemo(() => 
        survivorUtils?.findBottomLeftText(game?.Round, game?.Bracket),
        [survivorUtils, game?.Round, game?.Bracket]
    );

    const bottomRightText = useMemo(() => 
        survivorUtils?.findBottomRightText(game?.HomeTeam, game?.PointSpread),
        [survivorUtils, game?.HomeTeam, game?.PointSpread]
    );
    
    return (
        <Box className='survivor-game-card-container'>

            <Box 
                className='survivor-game-card-top-border'
                style={{ backgroundColor: topHeaderColor }}
            >
                <Typography
                    className="survivor-game-card-top-left-text"
                    sx={{ color: isGameSelected ? '#fff' : '' }}
                >
                    {formattedDate}
                </Typography>
                <Typography
                    className="survivor-game-card-top-right-text"
                    sx={{ color: isGameSelected ? '#fff' : '' }}
                >
                    {gameStatus}
                </Typography>
            </Box>

            <Box>
                <Box className='survivor-game-card-team-row'>
                    <Box className='survivor-game-card-logo'>
                        <img
                            src={awayTeamLogo}
                            alt={game?.AwayTeam}
                            style={{
                                opacity: (isTopSelectedOtherGame && Number(isTopSelectedOtherGame?.interval) <= Number(theCurrentInterval)) ? 0.3 : 1
                            }}
                        />
                    </Box>
                    <Typography 
                        className="survivor-game-card-team-name"
                        style={{
                            opacity: (isTopSelectedOtherGame && Number(isTopSelectedOtherGame?.interval) <= Number(theCurrentInterval)) ? 0.4 : 1
                        }}
                    >
                        {awayTeamRow}
                        <span style={{ color: '#757575', marginLeft: '4px'}} >{isTopSelectedOtherGame ? findSelectedIntervalName(isTopSelectedOtherGame?.interval) : ''}</span>
                    </Typography>
                    <Button 
                        className='survivor-game-card-button'
                        style={{ backgroundColor: getButtonColor(false), color: isTopSelectedNow ? '#fff' : ''}}
                        onClick={() => handleSurvivorSubmit(game?.GlobalAwayTeamID, game?.GlobalGameID, interval, hasGameStarted, 'add', isTopSelectedOtherGame)}
                        disabled={isEntryEliminated || hasGameStarted || hasThisIntervalsDeadlinePassed || isTopRestricted}
                    >
                        {getButtonText(false, game?.GlobalAwayTeamID)}
                    </Button>
                </Box>

                <Box className='survivor-game-card-team-row'>
                    <Box className='survivor-game-card-logo'>
                        <img
                            src={homeTeamLogo}
                            alt={game?.HomeTeam}
                            style={{
                                opacity: (isBottomSelectedOtherGame && Number(isBottomSelectedOtherGame?.interval) <= Number(theCurrentInterval)) ? 0.3 : 1
                            }}
                        />
                    </Box>
                    <Typography 
                        className="survivor-game-card-team-name"
                        style={{
                            opacity: (isBottomSelectedOtherGame && Number(isBottomSelectedOtherGame?.interval) <= Number(theCurrentInterval)) ? 0.4 : 1
                        }}
                    >
                        {homeTeamRow}
                        <span style={{ color: '#757575', marginLeft: '4px'}} >{isBottomSelectedOtherGame ? findSelectedIntervalName(isBottomSelectedOtherGame?.interval) : ''}</span>
                    </Typography>
                    <Button 
                        className='survivor-game-card-button'
                        style={{ backgroundColor: getButtonColor(true), color: isBottomSelectedNow ? '#fff' : ''}}
                        onClick={() => handleSurvivorSubmit(game?.GlobalHomeTeamID, game?.GlobalGameID, interval, hasGameStarted, 'add', isBottomSelectedOtherGame)}
                        disabled={isEntryEliminated || hasGameStarted || hasThisIntervalsDeadlinePassed || isBottomRestricted}
                    >
                        {getButtonText(true, game?.GlobalHomeTeamID)}
                    </Button>
                </Box>

            </Box>

            <Box className='survivor-game-card-bottom-border'>
                <Typography className='survivor-game-card-bottom-left-text'>
                    {bottomLeftText}
                </Typography>
                <Typography className='survivor-game-card-bottom-right-text'>
                    {bottomRightText}
                </Typography>
            </Box>
        </Box>
    );
});

export default SurvivorGameCard;