import React from "react";
import BirdiePoolProvider from "../components/BirdiePool/context/BirdiePoolContext";
import BirdiePoolContainer from "../components/BirdiePool/BirdiePoolContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const BirdiePoolPage = () => {
  const queryClient = new QueryClient();

  return (
    <div>
		<QueryClientProvider client={queryClient}>
			<BirdiePoolProvider>
				<BirdiePoolContainer />
			</BirdiePoolProvider>
		</QueryClientProvider>
    </div>
  );
}

export default BirdiePoolPage;