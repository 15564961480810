import React, { useState, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Box } from '@mui/material';
import './SuperPickemLeaderboard.scss';
import { amITied } from "../utils/sharedUtils";

const PickemLeaderboard = ({
    leaderboard,
    myUserId,
    hasBracketDeadlinePassed,
    usersInfo,
    truncateUsername,
    canShowPickemRanks,
    pickemRankPlaceholder,
}) => {
    const [sortConfig, setSortConfig] = useState({
        key: 'dailyPicksRank', // Default sort by overallRank
        direction: 'asc'
    });

    const sortedData = useMemo(() => {
        if (!leaderboard) return [];
    
        return [...leaderboard].sort((a, b) => {
            const rankColumns = ['overallRank', 'bracketRank', 'dailyPicksRank'];
            const pointsColumns = ['totalPoints', 'bracketPoints', 'dailyPicksPoints', 'maxBracketPoints', 'spreadWins', 'overUnderWins'];
    
            const key = sortConfig.key; // Current column being sorted
            const direction = sortConfig.direction; // Sort direction: 'asc' or 'desc'
    
            // Rank Columns Logic
            if (rankColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing ranks go to bottom in ascending, top in descending
                if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Points Columns Logic
            if (pointsColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'desc' ? 1 : -1; // Missing points go to bottom in descending, top in ascending
                if (bIsMissing) return direction === 'desc' ? -1 : 1; // Reverse for ascending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Fallback for String Columns
            const aValue = a[key];
            const bValue = b[key];
            const aIsMissing = aValue == null || aValue === ''; // Missing or empty
            const bIsMissing = bValue == null || bValue === '';
    
            if (aIsMissing && bIsMissing) return 0; // Both missing are equal
            if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing goes to bottom in ascending
            if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
            return direction === 'asc'
                ? (aValue || '').localeCompare(bValue || '')
                : (bValue || '').localeCompare(aValue || '');
        });
    }, [leaderboard, sortConfig]);

    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            // If sorting by the same column, toggle direction
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
                };
            }
            
            if (key === 'dailyPicksRank') {
                return {
                    key,
                    direction: 'asc'
                };
            }

            // If sorting by a new column, default to ascending
            return {
                key,
                direction: 'desc'
            };
        });
    };

    const rankHeaderColor = sortConfig.key === 'dailyPicksRank' ? '#FFC60A' : '#FFFFFF';
    const pickemPointsHeaderColor = sortConfig.key === 'dailyPicksPoints' ? '#FFC60A' : '#FFFFFF';
    const spreadRecordHeaderColor = sortConfig.key === 'spreadWins' ? '#FFC60A' : '#FFFFFF';
    const overUnderRecordHeaderColor = sortConfig.key === 'overUnderWins' ? '#FFC60A' : '#FFFFFF';
    const playerHeaderColor = sortConfig.key === 'username' ? '#FFC60A' : '#FFFFFF';
    
    return (
        <>
        <Grid item xs={12} sx={{overflow: 'scroll'}}>
        <TableContainer component={Paper}>
            <Table className="super-pickem-leaderboard-table">
               <TableHead sx={{
                    background: '#002129',
                    color:'#fff'
                }}>


                {usersInfo ?
                    <TableRow sx={{ background: '#F0F1F1', color: '#002129' }}>
                        <TableCell 
                            sx={{ 
                                textAlign: 'center', 
                                fontSize: '12px', 
                                position: 'sticky', 
                                left: 0, 
                                zIndex: 100, 
                                backgroundColor: '#F0F1F1' // Ensure the sticky cell has a background
                            }}
                        >
                            {!canShowPickemRanks ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    T1
                                </Typography>
                            ) : !usersInfo?.dailyPicksRank ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    {pickemRankPlaceholder}
                                </Typography>
                            ) :
                            amITied(usersInfo?.dailyPicksRank, 'dailyPicksRanks', leaderboard) ? (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    T{usersInfo?.dailyPicksRank}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                    {usersInfo?.dailyPicksRank}
                                </Typography>
                            )}
                        </TableCell>

                        <TableCell 
                            sx={{ 
                                textAlign: 'left', 
                                fontSize: '12px', 
                                position: 'sticky', 
                                left: '75px', // Adjust based on the width of the previous sticky cell
                                zIndex: 100, 
                                backgroundColor: '#F0F1F1'// Ensure the sticky cell has a background
                            }}
                        >
                            {truncateUsername(usersInfo?.username)} <br />
                            {/* {hasBracketDeadlinePassed ? 'Tiebreaker:' : null} */}
                        </TableCell>

                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{sm:"45%", md:"25%", lg:"25%"} }}>
                            {usersInfo?.dailyPicksPoints || 0}
                        </TableCell>
                        
                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"} }}>
                            {usersInfo?.spreadWins || 0} - {usersInfo?.spreadLosses || 0} - {usersInfo?.spreadPushes || 0}
                        </TableCell>
                        
                        <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"}}}>
                            {usersInfo?.overUnderWins || 0} - {usersInfo?.overUnderLosses || 0} - {usersInfo?.overUnderPushes || 0}
                        </TableCell>
                    </TableRow>
                    :
                    <></>
                  }

                    <TableRow 
                        sx={{
                            color:'#fff'
                        }}>
                                <TableCell
                                    onClick={() => handleSort('dailyPicksRank')}
                                    sx={{
                                        color: rankHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        // width: "30px",
                                        textDecoration: 'underline',
                                        position: 'sticky',
                                        backgroundColor: '#002129',
                                        zIndex: 100,
                                        left: 0,
                                    }}
                                >
                                    RANK
                                </TableCell>

                                <TableCell 
                                    onClick={() => handleSort('username')}
                                    sx={{ 
                                        color: playerHeaderColor, 
                                        fontWeight: 900, 
                                        textAlign: 'left',
                                        textDecoration: 'underline',
                                        position: 'sticky',
                                        backgroundColor: '#002129',
                                        zIndex: 100,
                                        left: '74px', // Adjust based on the width of the previous sticky cell
                                    }}
                                >
                                    PLAYER
                                </TableCell>
                                
                                <TableCell
                                    onClick={() => handleSort('dailyPicksPoints')}
                                    sx={{
                                        color: pickemPointsHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        minWidth: '100px',
                                        position: 'relative',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    PICK 'EM PTS
                                </TableCell>

                                <TableCell
                                    onClick={() => handleSort('spreadWins')}
                                    sx={{
                                        color: spreadRecordHeaderColor,
                                        fontWeight: 900,
                                        textAlign: 'center',
                                        position: 'relative',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    ATS RECORD
                                </TableCell>

                                <TableCell
                                    onClick={() => handleSort('overUnderWins')} 
                                    sx={{ 
                                        color: overUnderRecordHeaderColor, 
                                        fontWeight: 900, 
                                        textAlign: 'center', 
                                        position: 'relative',
                                        textDecoration: 'underline',
                                        minWidth: '100px',
                                    }}
                                >
                                   O/U RECORD
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                    }}
                                >
                                    <TableCell 
                                        sx={{ 
                                            textAlign: 'center', 
                                            fontSize: '12px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 100,
                                            backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                        {!canShowPickemRanks ? (
                                            "T1"
                                        ) : !row?.dailyPicksRank ? (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                {pickemRankPlaceholder}
                                            </Typography>
                                        ) :
                                        amITied(row?.dailyPicksRank, 'dailyPicksRank', leaderboard) ? (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                T{row?.dailyPicksRank}
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                {row?.dailyPicksRank}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    
                                    <TableCell 
                                        sx={{
                                            textAlign:'left', 
                                            fontSize:'12px',
                                            position: 'sticky',
                                            left: '75px',
                                            zIndex: 100,
                                            backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                        {truncateUsername(row?.username)} <br />
                                        {/* {hasBracketDeadlinePassed ? 'Tiebreaker:' : null} */}
                                    </TableCell>
                                    
                                    <TableCell sx={{textAlign:'center', fontSize:'12px',}}>{row?.dailyPicksPoints || 0}</TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px'}}>
                                        {row?.spreadWins || 0} - {row?.spreadLosses || 0} - {row?.spreadPushes || 0}
                                    </TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px' }}>
                                        {row?.overUnderWins || 0} - {row?.overUnderLosses || 0} - {row?.overUnderPushes || 0}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
</>

    )
}

export default PickemLeaderboard;