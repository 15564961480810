import { combineReducers } from 'redux';

import {
        USER_REGISTER_REQUEST,
        USER_REGISTER_FAIL,
        USER_REGISTER_SUCCESS,
        USER_SIGNIN_REQUEST,
        USER_SIGNIN_FAIL,
        USER_SIGNIN_SUCCESS,
        USER_LOGOUT_REQUEST,
        USER_LOGOUT_SUCCESS,
        USER_LOGOUT_FAIL,
        USER_SIGININ_WITH_GOOGLE_REQUEST,
        USER_SIGININ_WITH_GOOGLE_SUCCESS,
        USER_SIGININ_WITH_GOOGLE_FAIL,
        USER_CONTACT_REQUEST,
        USER_CONTACT_SUCCESS,
        USER_CONTACT_FAIL,
        CHANGE_GENERAL_USER_DATA_REQUEST,
        CHANGE_GENERAL_USER_DATA_SUCCESS,
        CHANGE_GENERAL_USER_DATA_FAIL,
        CHANGE_MARKETING_USER_DATA_REQUEST,
        CHANGE_MARKETING_USER_DATA_SUCCESS,
        CHANGE_MARKETING_USER_DATA_FAIL,
        CHANGE_USER_PASSWORD_REQUEST,
        CHANGE_USER_PASSWORD_SUCCESS,
        CHANGE_USER_PASSWORD_FAIL,
        // USER_SIGININ_WITH_FACEBOOK_REQUEST,
        // USER_SIGININ_WITH_FACEBOOK_SUCCESS,
        // USER_SIGININ_WITH_FACEBOOK_FAIL,
        UPDATE_USER_DATA_REQUEST,
        UPDATE_USER_DATA_SUCCESS,
        UPDATE_USER_DATA_FAIL,
        GET_USERS_FOR_LEADERBOARD_REQUEST,
        GET_USERS_FOR_LEADERBOARD_SUCCESS,
        GET_USERS_FOR_LEADERBOARD_FAIL,
        GET_USERS_BY_MULLIGEN_MADNESS_REQUEST,
        GET_USERS_BY_MULLIGEN_MADNESS_SUCCESS,
        GET_USERS_BY_MULLIGEN_MADNESS_FAIL,
        GET_NBA_PLAYOFF_LEADERBOARD_REQUEST,
        GET_NBA_PLAYOFF_LEADERBOARD_SUCCESS,
        GET_NBA_PLAYOFF_LEADERBOARD_FAIL,
        GET_SUBGROUP_LEADERBOARD_SUCCESS,
        GET_SUBGROUP_LEADERBOARD_REQUEST,
        GET_SUBGROUP_LEADERBOARD_FAIL,
 } from '../actions/loginImport';

const initialState = {
  data: [],
  isLoading: false,
  user: null,
};

const registerUser = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case USER_REGISTER_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
      case USER_SIGNIN_REQUEST:
          return {
              ...state,
              isLoading: true,
              error: null,  // Resetting any previous errors
          };
      case USER_SIGNIN_SUCCESS:
          return {
              ...state,
              user: action.payload,
              isLoading: false,
              error: null,
          };
      case USER_SIGNIN_FAIL:
          return {
              ...state,
              isLoading: false,
              error: action.payload,  // Storing the error from action
          };
      default:
          return state;
  }
};

  const logoutReducer = (state = initialState, action) => {
    console.log('logoutReducer action.type: ', action);

    switch (action.type) {
      case USER_LOGOUT_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case USER_LOGOUT_SUCCESS:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
      case USER_LOGOUT_FAIL:
          return {
            ...state,
            data: action.payload,
            isLoading: false,
          };
      default:
        return state;
  }
};

const userContactReducer = (state = initialState, action) => {
  console.log('userContactReducer action.type: ', action);
  switch (action.type) {
    case USER_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case USER_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case USER_CONTACT_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
};

const changeGeneralUserDataReducer = (state = initialState, action) => {
  console.log('changeGeneralUserDataReducer action.type: ', action);
  switch (action.type) {
    case CHANGE_GENERAL_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_GENERAL_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case CHANGE_GENERAL_USER_DATA_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const changeMarketingUserDataReducer = (state = initialState, action) => {
  console.log('changeMarketingUserDataReducer action.type: ', action);
  switch (action.type) {
    case CHANGE_MARKETING_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_MARKETING_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case CHANGE_MARKETING_USER_DATA_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const changeUserPasswordReducer = (state = initialState, action) => {
  console.log('changeUserPasswordReducer action.type: ', action);
  switch (action.type) {
    case CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case CHANGE_USER_PASSWORD_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const updateUserDataReducer = (state = initialState, action) => {
  console.log('updateUserDataReducer action.type: ', action);
  switch (action.type) {
    case UPDATE_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case UPDATE_USER_DATA_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const getUsersForLeaderboardReducer = (state = initialState, action) => {
  console.log('getUsersForLeaderboardReducer action.type: ', action);
  switch (action.type) {
    case GET_USERS_FOR_LEADERBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USERS_FOR_LEADERBOARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_USERS_FOR_LEADERBOARD_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const getUsersByMulligenMadnessReducer = (state = initialState, action) => {
  console.log('getUsersByMulligenMadnessReducer action.type: ', action);
  switch (action.type) {
    case GET_USERS_BY_MULLIGEN_MADNESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USERS_BY_MULLIGEN_MADNESS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_USERS_BY_MULLIGEN_MADNESS_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const getNbaPlayoffLeaderboardReducer = (state = initialState, action) => {
  console.log('getNbaPlayoffLeaderboardReducer action.type: ', action);
  switch (action.type) {
    case GET_NBA_PLAYOFF_LEADERBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NBA_PLAYOFF_LEADERBOARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_NBA_PLAYOFF_LEADERBOARD_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}

const getSubgroupLeaderboardReducer = (state = initialState, action) => {
  console.log('getSubgroupLeaderboardReducer action.type: ', action);
  switch (action.type) {
    case GET_SUBGROUP_LEADERBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUBGROUP_LEADERBOARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_SUBGROUP_LEADERBOARD_FAIL:
        return {
          ...state,
          data: action.payload,
          isLoading: false,
        };
    default:
      return state;
  }
}


// Combine your reducers using combineReducers
const gamesReducer = combineReducers({
  logoutReducer: logoutReducer,
  loginReducer: loginReducer,
  registerUser: registerUser,
  userContactReducer: userContactReducer,
  changeGeneralUserDataReducer: changeGeneralUserDataReducer,
  changeMarketingUserDataReducer: changeMarketingUserDataReducer,
  changeUserPasswordReducer: changeUserPasswordReducer,
  updateUserDataReducer: updateUserDataReducer,
  getUsersForLeaderboardReducer:getUsersForLeaderboardReducer,
  getUsersByMulligenMadnessReducer:getUsersByMulligenMadnessReducer,
  getNbaPlayoffLeaderboardReducer:getNbaPlayoffLeaderboardReducer,
  getSubgroupLeaderboardReducer:getSubgroupLeaderboardReducer
});

export default gamesReducer;