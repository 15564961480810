import React from 'react';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CFBPicksDisplayRow = ({ seasonVsWeek, weeklySchedule, userPicks, hiddenPicks }) => {
    const { 
        CFBTeams, 
        CFBNames,
        week, 
        winBoxColor, 
        winBoxBackgroundColor, 
        lossBoxColor, 
        lossBoxBackgroundColor, 
        pushBoxColor, 
        pushBoxBackgroundColor 
    } = useCFBSurvivorContext();
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // console.log("USER PICKS IN PICKS DISPLAY ROW:", userPicks);
    //console.log("SeasonVsWeek:", seasonVsWeek);
    const weekNumber = parseInt(seasonVsWeek.match(/\d+/)[0], 10);  // Now it's a number
    //console.log("Week Number:", weekNumber);
    
    const conferences = ['ACC', 'Big 12', 'Big Ten', 'SEC'];
    
    function cfbTeamName(teamId) {
        return CFBNames?.find(team => team.GlobalTeamID === teamId)?.ShortDisplayName;
    }


    function getPickInfo() {
        if (!userPicks) {
            return [];
        }
        
        return userPicks.map(pick => {
            const { spread, teamId, gameId, isHomeSelected, result, conference } = pick;
            const teamLogo = CFBTeams?.find(team => team.GlobalTeamID === teamId)?.TeamLogoUrl;
            const game = weeklySchedule?.find(game => game.GlobalGameID === gameId);
            const isPickHome = isHomeSelected;
            let borderBackgroundColor = "#FFFFFF";
            let borderColor = "#002129";
            if (result === "Win") {
                borderColor = winBoxColor;
                borderBackgroundColor = winBoxBackgroundColor;
            } else if (result === "Push") {
                borderColor = pushBoxColor;
                borderBackgroundColor = pushBoxBackgroundColor;
            } else if (result === "Loss") {
                borderColor = lossBoxColor;
                borderBackgroundColor = lossBoxBackgroundColor;
            }
            const opponentKey = isPickHome ? cfbTeamName(game?.GlobalAwayTeamID) : cfbTeamName(game?.GlobalHomeTeamID);
            const leadingSymbol = isPickHome ? 'vs' : '@';
            return { spread, teamLogo, opponentKey, leadingSymbol, borderColor, borderBackgroundColor, conference };
        });
    }
    
    const weekPicks = getPickInfo();
    const picksMap = conferences?.reduce((acc, conf) => {
        const pick = weekPicks?.find(p => p.conference === conf);
        acc[conf] = pick || null;
        return acc;
    }, {});
    
    const noPickConferences = userPicks?.filter(pick => pick.teamId === "No Pick" && conferences.includes(pick.conference));
    // console.log("No Pick Conferences:", noPickConferences);
    const placeholders = conferences.map(conf => picksMap[conf] ? null : conf);
    
    return (
        <div className="weekly-picks-bar" style={{ maxWidth: '1116px', height: '76px', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {conferences.map((conf, index) => {
                const pick = picksMap[conf];
                // console.log("Pick:", pick);
                if (pick && pick?.teamLogo !== undefined) {
                    return (
                        <div key={index} className="pick-info" style={{ textAlign: 'center', flex: '1' }}>
                            {/* <div style={{ display: 'flex', width: '24px', height: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', flexShrink: 0, margin: '0 auto' }}>
                                <Typography variant="body1" style={{ fontSize: isMobile ? '10px' : '14px', fontWeight: '500' }}>
                                    {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                                </Typography>
                            </div> */}
                            <img src={pick.teamLogo} alt="Team Logo" style={{ width: '32px', height: '32px', margin: '0 auto', marginTop: '10px', marginBottom: '10px', objectFit: 'contain', border: `2px solid ${pick.borderColor}`, backgroundColor: pick.borderBackgroundColor }} />
                            <div style={{ display: 'flex', width: 'auto', height: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', flexShrink: 0, margin: '0 auto' }}>
                                <Typography variant="body1" style={{ fontSize: isMobile ? '10px' : '14px', fontWeight: '500', whiteSpace: 'nowrap' }}>
                                    {pick.leadingSymbol} {pick.opponentKey}
                                </Typography>
                            </div>
                        </div>
                    );
                } else if (noPickConferences?.some(p => p.conference === conf)) {
                    const matchedPick = noPickConferences?.find(p => p.conference === conf);
                
                    if (matchedPick) {
                        //console.log("No Pick Conference:", conf, matchedPick); // Log the specific pick that matches
                        return (
                            <div key={index} className="pick-info" style={{ textAlign: 'center', flex: '1' }}>
                                <div style={{ width: '32px', height: '32px', margin: '0 auto', marginTop: '10px', marginBottom: '10px', border: `2px solid #CC293C`, backgroundColor: matchedPick?.week === weekNumber ? 'rgba(204, 41, 60, 0.20)': '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CloseIcon style={{ width: '20px', height: '20px', fill: '#CC293C' }} />
                                </div>
                                <div style={{ display: 'flex', width: 'auto', height: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', flexShrink: 0, margin: '0 auto' }}>
                                    <Typography variant="body1" style={{ fontSize: isMobile ? '10px' : '14px', fontWeight: '500', color: '#CC293C' }}>
                                        {matchedPick.week === weekNumber ? "Miss" : "Out"}
                                    </Typography>
                                </div>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div key={index} className="pick-info" style={{ textAlign: 'center', flex: '1' }}>
                            {/* <div style={{ display: 'flex', width: '24px', height: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', flexShrink: 0, margin: '0 auto' }}>
                                <Typography variant="body1" style={{ fontSize: '8px', fontWeight: '500' }}>
                                    &nbsp;
                                </Typography>
                            </div> */}
                            <div style={{ width: '32px', height: '32px', margin: '0 auto', marginTop: '10px', marginBottom: '10px', border: `2px solid #000000`, backgroundColor: '#FFFFFF' }} />
                            <div style={{ display: 'flex', width: 'auto', height: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', flexShrink: 0, margin: '0 auto' }}>
                                <Typography variant="body1" style={{ fontSize: isMobile ? '10px' : '14px', fontWeight: '500' }}>
                                    {conf}
                                </Typography>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default CFBPicksDisplayRow;
