import React, { useState, useEffect } from "react";
import MyScorecard from "./MyScorecard/MyScorecard";
import BirdieLeaderboard from "./BirdieLeaderboard/BirdieLeaderboard";
import BirdieStats from "./BirdieStats/BirdieStats";
import BirdieNav from "./BirdieHeader/BirdieNav";
import BirdieHeader from "./BirdieHeader/BirdieHeader";
import BirdieClubHeader from "./BirdieHeader/BirdieClubHeader";
import SubgroupDropdown from "./BirdieHeader/SubGroupDropdown";
import BirdiePrivateGroup from "./BirdiePrivateGroup/BirdiePrivateGroup";
import BirdieRules from "./BirdieRules/BirdieRules";
import { useBirdieContext } from "./context/BirdiePoolContext";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const BirdiePoolContainer = () => {
  const { gameId, component, groupStateCheck } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [clickState, setClickState] = useState(component || 'SCORE_CARD');
  const { clubleaderboardInfo, userData, startingScorecard, birdiePoolData, birdieLeaderboardData, userSubgroups, myUserName, groupState, headerDisplayName, setSelectedUser, setSelectedUserId, myUserId, userScorecard, setScorecardState } = useBirdieContext();

  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // Ensure that all necessary data is available before rendering
  // const isLoading = !clubleaderboardInfo || !userData || !startingScorecard || !birdiePoolData || !birdieLeaderboardData || !headerDisplayName || !userScorecard || !myUserName;
  // console.log('isLoading', isLoading);
  // if (isLoading) {
  //   return <h2>Loading...</h2>; // Render loading state if data is not available yet
  // }

  

  const navigateToTop = useNavigateToTop();

  const handleClickState = (click) => {
    setClickState(click);
    navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/${uriEncoded(click)}/${uriEncoded(groupState)}`);
  };

  return (
    <div>
      <BirdieClubHeader />

      <BirdieHeader />

      <BirdieNav setClickState={handleClickState} clickState={clickState} setSelectedUser={setSelectedUser} setSelectedUserId={setSelectedUserId} myUserName={myUserName} myUserId={myUserId} setScorecardState={setScorecardState} userScorecard={userScorecard}/>

      {userSubgroups?.length > 0 && <SubgroupDropdown />}

      {clickState === 'SCORE_CARD' && <MyScorecard />}

      {clickState === 'MY_PARTY' && <BirdiePrivateGroup handleClickState={handleClickState} />}

      {clickState === 'LEADERBOARD' && <BirdieLeaderboard handleClickState={handleClickState} />}

      {clickState === 'BIRDIE_STATS' && <BirdieStats handleClickState={handleClickState} />}

      {clickState === 'RULES' && <BirdieRules />}
    </div>
  );
};

export default BirdiePoolContainer;
