import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetReceipt.scss';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const BetReceiptHeader = ({ betReceiptInfo }) => {
    const { saveSuccess, picksToSave, gamesThatChanged, gamesThatStarted, gamesUnavailable, groupBetOdds, groupBetPayout, betStyle } = betReceiptInfo;
    const anyBetsPlaced = picksToSave?.length > 0;
    const howManyBetsPlaced = picksToSave?.length;
    //console.log("anyBetsPlaced: ", anyBetsPlaced);
    const anyBetsNotPlaced = gamesUnavailable?.length > 0 || gamesThatChanged?.length > 0 || gamesThatStarted?.length > 0;
    //console.log("anyBetsNotPlaced: ", anyBetsNotPlaced);
    const howManyBetsNotPlaced = gamesUnavailable?.length + gamesThatChanged?.length + gamesThatStarted?.length;
    const howManyTotalPicks = picksToSave?.length + gamesUnavailable?.length + gamesThatChanged?.length + gamesThatStarted?.length;
    const teaserPoints = picksToSave?.map(pick => pick.teaserPoints);
    const uniqueTeaserPoints = [...new Set(teaserPoints)];
    //console.log("uniqueTeaserPoints: ", uniqueTeaserPoints);

    return (
        <Box className="sportsbook-bet-receipt-header">
            {/* Left Side: Circle with Check or X and Bet Slip Text */}
            <Box className="sportsbook-bet-receipt-left-info">
                {/* Circle with Check or X */}
                {((anyBetsPlaced && betStyle === 'straight') || (betStyle !== 'straight' && saveSuccess)) && 
                <Box className="sportsbook-bet-receipt-success-circle">
                    <Typography className="sportsbook-bet-receipt-circle-text"><CheckIcon /></Typography>
                </Box>
                }
                {anyBetsNotPlaced &&
                <Box className="sportsbook-bet-receipt-failure-circle">
                    <Typography className="sportsbook-bet-receipt-circle-text"><CloseIcon /></Typography>
                </Box>
                }

                {/* Bet Receipt Text */}
                <Typography className="sportsbook-bet-receipt-text">Receipt</Typography>
            </Box>

            {/* Right Side: Bet Info - 1 or 2 lines*/}
            {betStyle === 'straight' &&
                <Box className="sportsbook-bet-receipt-right-info">
                    {anyBetsPlaced &&
                        <Typography className="sportsbook-bet-receipt-text">
                            {howManyBetsPlaced} Straight Bet{howManyBetsPlaced > 1 ? 's' : ''} Placed
                        </Typography>
                    }
                    {anyBetsNotPlaced &&
                        <Typography className="sportsbook-bet-receipt-text" sx={{color: '#CC293C'}}>
                            {howManyBetsNotPlaced} Straight Bet{howManyBetsNotPlaced > 1 ? 's' : ''} Not Placed
                        </Typography>
                    }
                </Box>
            }
            {betStyle === 'parlay' &&
                <Box className="sportsbook-bet-receipt-right-info">
                    {anyBetsPlaced && saveSuccess &&
                        <Typography className="sportsbook-bet-receipt-text">
                            {howManyBetsPlaced} Leg Parlay Placed
                        </Typography>
                    }
                    {anyBetsNotPlaced &&
                        <Typography className="sportsbook-bet-receipt-text" sx={{color: '#CC293C'}}>
                            {howManyTotalPicks} Leg Parlay Not Placed
                        </Typography>
                    }
                </Box>
            }
            {betStyle === 'teaser' &&
                <Box className="sportsbook-bet-receipt-right-info">
                    {anyBetsPlaced && saveSuccess &&
                        <Typography className="sportsbook-bet-receipt-text">
                            {howManyBetsPlaced} Leg {uniqueTeaserPoints}-Point Teaser Placed
                        </Typography>
                    }
                    {anyBetsNotPlaced &&
                        <Typography className="sportsbook-bet-receipt-text" sx={{color: '#CC293C'}}>
                            {howManyTotalPicks} Leg Teaser Not Placed
                        </Typography>
                    }
                </Box>
            }
        </Box>
    );
};

export default BetReceiptHeader;