import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getTeamLogo } from "../../../../utils/SportsData";
import './SurvivorGamesStyles.scss';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const SurvivorSingleEntryView = ({
    entry,
    league,
    teams,
    lossesForElimination,
    picksPerInterval,
    theCurrentInterval,
    isEntryEliminated,
    handleImageError,
    handleImageLoad,
    imageError,
    imageLoading,
    setImageError,
    setImageLoading,
    intervalInfo,
    totalEntriesAlive,
    totalEntriesEliminated,
    hasJoinDeadlinePassed,
    setIndexToRemove,
    setRemoveModalOpen,
    isThisPoolPartyGame,
    pickIndex,
    isUserAdmin,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    // Memoize entry elimination status since it depends on complex calculations
    const entryEliminated = useMemo(() => 
        isEntryEliminated(entry?.pool?.picks, lossesForElimination),
        [entry?.pool?.picks, lossesForElimination, isEntryEliminated]
    );

    // Memoize eliminated picks calculation
    const eliminatedPicks = useMemo(() => 
        entry?.pool?.picks?.filter(pick => pick.result === "loss"),
        [entry?.pool?.picks]
    );

    // Memoize the eliminated pick calculation
    const eliminatedPick = useMemo(() => {
        if (!eliminatedPicks?.length) return null;
        return eliminatedPicks.reduce((prev, curr) => {
            return curr.interval > prev.interval ? curr : prev;
        }, eliminatedPicks[0]);
    }, [eliminatedPicks]);

    // Memoize this interval's pick
    const thisIntervalsPick = useMemo(() => 
        entry?.pool?.picks?.find(pick => pick.interval === theCurrentInterval),
        [entry?.pool?.picks, theCurrentInterval]
    );

    // Memoize interval name calculation
    const intervalName = useMemo(() => {
        if (!intervalInfo) return null;

        if (intervalInfo?.type === 'weeks') {
            return `Week ${theCurrentInterval}`;
        } else if (intervalInfo?.type === 'date') {
            return intervalInfo?.info?.find(entry => 
                entry.interval === theCurrentInterval
            )?.shortLabel;
        }
    }, [intervalInfo, theCurrentInterval]);

    // Memoize team logo URL to prevent unnecessary getTeamLogo calls
    const eliminatedTeamLogo = useMemo(() => 
        eliminatedPick?.teamId !== 'No Pick' 
            ? getTeamLogo(league, eliminatedPick?.teamId, teams)
            : null,
        [league, eliminatedPick?.teamId, teams]
    );

    const currentPickTeamLogo = useMemo(() => 
        thisIntervalsPick
            ? getTeamLogo(league, thisIntervalsPick?.teamId, teams)
            : null,
        [league, teams, thisIntervalsPick]
    );

    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    }
    

    return (
        <Box>
            <Box className="survivor-single-entry-container">
                <Box className="survivor-single-entry-content">
                    <Box className="survivor-single-entry-column">
                        <Typography className="survivor-single-entry-header-text">
                            Alive
                        </Typography>
                        <Typography className="survivor-single-entry-text">
                            {totalEntriesAlive}
                        </Typography>
                    </Box>

                    <Box className="survivor-single-entry-column">
                        <Typography className="survivor-single-entry-header-text">
                            Eliminated
                        </Typography>
                        <Typography className="survivor-single-entry-text">
                            {totalEntriesEliminated}
                        </Typography>
                    </Box>

                    <Box className="survivor-single-entry-column">
                        <Typography className="survivor-single-entry-header-text">
                            My Status
                        </Typography>
                        <Typography 
                            className="survivor-single-entry-text"
                            sx={{
                                color: entryEliminated ? "#CC293C" : "#00AA72"
                            }}    
                        >
                            {entryEliminated ? "Eliminated" : "Alive"}
                        </Typography>
                    </Box>
                    
                    {entryEliminated && (
                        <Box className="survivor-single-entry-column">
                            <Typography className="survivor-single-entry-header-text">
                                Elimination
                            </Typography>
                            
                            {eliminatedPick?.teamId !== 'No Pick' ? (
                                <Box className="survivor-single-entry-elimination-box">
                                    <img
                                        className="survivor-single-entry-team-logo"
                                        src={eliminatedTeamLogo}
                                        alt="Team Logo"
                                        onLoad={handleImageLoad}
                                        onError={handleImageError}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box className="survivor-single-entry-elimination-box">
                                        <CloseIcon sx={{ color: "#CC293C", fontSize: isMobile ? '24px' : '40px' }} />
                                    </Box>
                                    <Typography 
                                        className="survivor-single-entry-text"
                                        sx={{
                                            color: "#CC293C",
                                            marginLeft: '4px',
                                            marginTop: '0px !important'
                                        }}
                                    >
                                        No Pick
                                    </Typography>
                                </Box>
                            )} 
                        </Box>
                    )}

                    {!entryEliminated && (
                        <Box className="survivor-single-entry-column">
                            <Typography className="survivor-single-entry-header-text">
                                {intervalName} Pick
                            </Typography>
                            <Box
                                sx={{
                                    border: thisIntervalsPick?.result === 'win' ? '1px solid #00AA72' : thisIntervalsPick?.result === 'loss' ? '1px solid #CC293C' : '1px solid #002129',
                                    backgroundColor: thisIntervalsPick?.result === 'win' ? 'rgba(0, 170, 114, 0.2)' : thisIntervalsPick?.result === 'loss' ? 'rgba(204, 41, 60, 0.2)' : '#fff',
                                    display: 'flex',
                                    width: isMobile ? '28px' : '44px',
                                    height: isMobile ? '28px' : '44px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {thisIntervalsPick && (
                                    <img
                                        className="survivor-single-entry-team-logo"
                                        src={currentPickTeamLogo}
                                        alt="Team Logo"
                                        onLoad={handleImageLoad}
                                        onError={handleImageError}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {!hasJoinDeadlinePassed && !isThisPoolPartyGame && !isUserAdmin && (
                <Typography 
                    onClick={() => handleRemovedClick(Number(pickIndex))}
                    sx={{
                        color: "#CC293C",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: "500",
                        width: '100%',
                        textAlign: 'center',
                        paddingBottom: '10px'
                    }}
                >
                    Remove
                </Typography>
            )}
        </Box>
    );
}

export default SurvivorSingleEntryView;