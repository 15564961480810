import React from 'react';
import { Button, Modal, Typography, Box } from '@mui/material';

export const ResponseModalConfig = {
  success: {
    title: "Success!",
    action: "OK"
  },
  error: {
    title: "Error",
    action: "OK"
  }
};

const ResponseModal = ({ 
  open, 
  onClose, 
  status, 
  message 
}) => {
  const config = ResponseModalConfig[status];
  
  if (!open || !config) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="response-modal-title"
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
    }}
    >
      <Box className="modal-container" sx={{ 
        height: 'auto', 
        maxHeight: '350px', 
        maxWidth: '350px', 
        bgcolor: 'background.paper', 
        p: 4 
      }}>
        <Typography variant="h6" component="div" sx={{ 
          whiteSpace: 'pre-line',
          color: status === 'error' ? '#CC293C' : '#00AA72',
          marginBottom: 2,
          fontWeight: '700'
        }}>
          {config.title}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
          {message}
        </Typography>
        <Box className="button-container">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              window.location.reload();
            }}
          >
            {config.action}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResponseModal;