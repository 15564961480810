import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";

const QuickPickJoinedModal = ({ joinOpen, setJoinOpen, status, arePropsAvailable, sponsorLogo, sponsorName, gameId, gameAddress, joinModalText }) => {
    
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const noPropsHeader = joinModalText?.noPropsHeader;
    const noPropsBody = joinModalText?.noPropsBody;
    const listItems = joinModalText?.listItems;
    const propsHeader = joinModalText?.propsHeader;
    const propsBody = joinModalText?.propsBody;

    return (
        <Modal
            open={joinOpen && status === "success"}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: '308px',
                    maxHeight: '765px',
                    bgcolor: '#FFF', 
                    borderRadius: '8px', 
                    boxShadow: 24, 
                    p: 2, // Padding of 16px
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2, // Gap of 20px
                    overflow: 'scroll'
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    {arePropsAvailable ? propsHeader : noPropsHeader}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    {arePropsAvailable ? propsBody : noPropsBody}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ 
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700,
                        marginBottom: '16px'
                    }}
                >
                    <ul style={{ padding: 0, listStyleType: 'none' }}> {/* Added a list to contain the items */}
                        {listItems.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Typography>

                {sponsorLogo && (
                    <img
                        src={sponsorLogo}
                        alt={sponsorName}
                        style={{
                            height: '73px',
                            marginBottom: '16px'
                        }}
                    />
                )}
               
                <Button
                    variant="contained"
                    sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        width: '182px',
                        height: '36px',
                        mb: 1 // MarginBottom of 8px
                    }}
                    onClick={() => window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`}
                >
                    ENTER POOL HOME
                </Button>
            </Box>
        </Modal>
    );
}

export default QuickPickJoinedModal;