import React, { useEffect, useState } from "react";
import { useAdminDashContext } from "../../context/AdminDashContext";
import useNavigateToTop from "../../../../hooks/general/useNavigateToTop";
import { useLocation } from "react-router-dom"; // Import useLocation

const GameCreationHome = () => {
    const {
        pageState,
        setPageState,
        handleBackClick,
        gameTypes,
    } = useAdminDashContext();

    const navigateToTop = useNavigateToTop();
    const location = useLocation(); // Access query parameters

    const [selectedGameType, setSelectedGameType] = useState(''); // Default to empty

    const handleGameTypeChange = (event) => {
        setSelectedGameType(event.target.value); // Update the selected game type
    }

    return (
        <div>
            {/* Admin Dash Home Header */}
            <h1 style={{ fontSize: '36px', fontWeight: 700, marginBottom: '20px' }}>
                Game Creation Home
            </h1>

            {/* Back to Admin Home Link */}
            <p 
                style={{ 
                    color: 'blue', 
                    textDecoration: 'underline', 
                    cursor: 'pointer' 
                }} 
                onClick={handleBackClick}
            >
                Back to Admin Home
            </p>

            {/* Dropdown of Game Types */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label htmlFor="game-type-select" style={{ marginRight: '10px' }}>
                    Game Type:
                </label>
                <select 
                    id="game-type-select" 
                    value={selectedGameType} 
                    onChange={handleGameTypeChange}
                    style={{ 
                        padding: '5px', 
                        borderRadius: '4px', 
                        border: '1px solid #000' // Add border here
                    }}
                >
                    <option value="" disabled>Select a game type</option>
                    {gameTypes?.map((gameType) => (
                        <option key={gameType} value={gameType}>
                            {gameType}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    )
}

export default GameCreationHome;