import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useQuickPickCFB = () => {
    return useMutation({
        mutationFn: quickPickCFBRequest,
        onError: (error) => {
        console.error('Error getting Quick Pick CFB:', error);
        },
        onSuccess: (data) => {
        console.log('Get Quick Pick CFB:', data);
        }
    });
}

const quickPickCFBRequest = async () => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-quick-pick-cfb`, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });
    return response.data;
};