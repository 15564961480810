import React, { useState } from 'react';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Button, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';

const PoolNotStartedYetModal = () => {
    const { birdiePoolData, setModalState } = useBirdieContext();
    const startingDate = birdiePoolData?.startDate;
    //console.log("Starting Date in NOT STARTED:", startingDate);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Function to get the ordinal suffix for a given day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // Special case for numbers between 4 and 20
        switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
    };
    
    // Function to format the date as "Day, Month Dayth"
    const formatDate = (dateString) => {
        const dt = DateTime.fromFormat(dateString, 'yyyy/MM/dd'); // Correct format
        const dayOfWeek = dt.toFormat('cccc'); // Full day of the week
        const day = dt.day;
        const month = dt.toFormat('MMMM'); // Full month name
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${dayOfWeek}, ${month} ${day}${ordinalSuffix}`;
    };
    

    return (
        <div>
            <Typography variant='h2' style={{ fontWeight: '700', marginBottom: '50px' }}>Too Early</Typography>
            <Typography variant='h6' style={{ marginBottom: '50px'}}>Great birdie, but the competition does not start until {formatDate(startingDate)}.</Typography>
            <Button
                variatnt='contained'
                color='primary'
                className='submit-button active'
                onClick={() => {
                    setModalState(null);
                    window.location.href = window.location.href;
                }}
                >
                     EXIT
            </Button>
        </div>
    );
};

export default PoolNotStartedYetModal;
