import React, { useState, useEffect } from "react";
import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MyPartyEmailMembers = ({ leaderboardData, isGameOver }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const allEmailsInitial = leaderboardData?.map((leaderboardData) => leaderboardData.email);
    const [selectedEmails, setSelectedEmails] = useState(allEmailsInitial);
    const [selectAll, setSelectAll] = useState(true);
    const [copyMessage, setCopyMessage] = useState("");

    useEffect(() => {
        if (selectedEmails.length === allEmailsInitial.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedEmails, allEmailsInitial]);

    const handleCheckboxChange = (email) => {
        setSelectedEmails(prevSelectedEmails =>
            prevSelectedEmails.includes(email)
                ? prevSelectedEmails.filter(e => e !== email)
                : [...prevSelectedEmails, email]
        );
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedEmails([]);
        } else {
            setSelectedEmails(allEmailsInitial);
        }
        setSelectAll(!selectAll);
    };

    const copyEmails = () => {
        navigator.clipboard.writeText(selectedEmails.join(", "))
            .then(() => {
                setCopyMessage('Emails have been copied!');
                setTimeout(() => setCopyMessage(''), 3000); // Hide message after 3 seconds
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };

    const sortedLeaderboardData = leaderboardData?.sort((a, b) => a.userFullName.localeCompare(b.userFullName));

    return (
        <div>
            <Typography 
                variant="h2" 
                style={{ 
                    fontSize: isMobile ? '20px' : '28px', 
                    fontWeight: '700', 
                    marginTop:'32px',
                    marginBottom: '32px', 
                }}
            >
                Email Party
            </Typography>

            <Typography 
                variant="h2" 
                style={{ 
                    fontSize: isMobile ? '16px' : '22px', 
                    fontWeight: '700',
                    marginBottom: '32px', 
                }}
            >
                Send an email to pool participants:
            </Typography>

            <Box sx={{
                width: isMobile ? '314px' : '520px',
                margin: '0 auto',
                textAlign: 'left',
            }}>
                <Typography 
                    variant="h2" 
                    style={{ 
                        fontSize: isMobile ? '16px' : '22px', 
                        fontWeight: '400',
                        marginBottom: '5px', 
                    }}
                >
                    1. Select pool members (defaulted to all members.)
                </Typography>

                <Typography 
                    variant="h2" 
                    style={{ 
                        fontSize: isMobile ? '16px' : '22px', 
                        fontWeight: '400',
                        marginBottom: '5px', 
                    }}
                >
                    2. Copy & paste the emails below into your email client’s “To:”, “Cc:”, or “Bcc:” field.
                </Typography>

                <Typography 
                    variant="h2" 
                    style={{ 
                        fontSize: isMobile ? '16px' : '22px', 
                        fontWeight: '400', 
                    }}
                >
                    3. Add your subject and message and send!
                </Typography>
            </Box>

            <Typography 
                variant="h2" 
                style={{ 
                    fontSize: isMobile ? '16px' : '22px', 
                    fontWeight: '700',
                    marginBottom: '32px',
                    marginTop: '32px' 
                }}
            >
                Select Pool Members: {selectedEmails?.length === leaderboardData?.length ?  "All": ""} ({selectedEmails?.length})
            </Typography>

            {copyMessage && (
                <Typography
                    sx={{
                        color: 'green',
                        marginBottom: '16px'
                    }}
                >
                    {copyMessage}
                </Typography>
            )}

            <Button
                variant="contained"
                color="primary"
                onClick={copyEmails}
                disabled={selectedEmails.length === 0}
                sx={{
                    width: '182px',
                    marginBottom: '32px',
                }}
            >
                COPY EMAILS
            </Button>

            <TableContainer 
                component={Paper} 
                style={{ 
                    width:'100%', 
                    maxWidth:'800px', 
                    margin:'0 auto', 
                    borderRadius:'0px', 
                    borderLeft:"2px solid #002129",
                    borderRight:"2px solid #002129",
                    marginBottom: isGameOver ? '32px' : '0px',
                }}
            >
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                            <TableCell 
                                className='private-commissioner-table' 
                                sx={{ 
                                    color: 'white', 
                                    fontSize: isMobile ? '14px' : '20px', 
                                    fontWeight: '700', 
                                    width: '257px' 
                                }}
                            >
                                Player
                            </TableCell>
                            <TableCell 
                                className='private-commissioner-table' 
                                align="center" 
                                sx={{ 
                                    color: 'white', 
                                    fontSize: isMobile ? '14px' : '20px', 
                                    fontWeight: '700',
                                    width: '117px' 
                                }}
                            >
                                <Checkbox 
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                    sx={{ 
                                        color: '#FFF',
                                        '&.Mui-checked': {
                                            color: '#FFF',
                                        }
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedLeaderboardData?.map((sortedLeaderboardData, index) => (
                            <TableRow
                                key={index}
                                sx={{ 
                                    backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1',
                                }}
                            >
                                <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                <Typography
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: isMobile ? '12px' : '14px',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {sortedLeaderboardData.userFullName}
                                </Typography>
                                <Typography 
                                    sx={{ 
                                        fontSize: isMobile ? '10px' : '14px' 
                                    }}
                                >
                                    {sortedLeaderboardData.username}
                                </Typography>
                                </TableCell>

                                <TableCell 
                                    className='private-commissioner-table' 
                                    align="center" 
                                >
                                    <Checkbox 
                                        checked={selectedEmails.includes(sortedLeaderboardData.email)}
                                        onChange={() => handleCheckboxChange(sortedLeaderboardData.email)}
                                        sx={{ 
                                            color: '#002129',
                                            '&.Mui-checked': {
                                                color: '#002129',
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
            </TableContainer>
        </div>
    );
}

export default MyPartyEmailMembers;
