// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-party-join-deadline-has-passed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.public-party-join-deadline-has-passed .public-party-join-deadline-has-passed-text {
  font-weight: 700;
  margin: 10px 0;
  font-size: 20px;
}
@media (min-width: 900px) {
  .public-party-join-deadline-has-passed .public-party-join-deadline-has-passed-text {
    font-size: 24px;
  }
}
.public-party-join-deadline-has-passed .public-party-join-deadline-has-passed-game-logo {
  margin-top: 20px;
  width: 94px;
  height: auto;
}
@media (min-width: 900px) {
  .public-party-join-deadline-has-passed .public-party-join-deadline-has-passed-game-logo {
    width: 150px;
  }
}

.public-party-join-deadline-has-passed-sponsor-logo {
  margin-top: 20px;
  width: 100%;
  max-width: 375px;
  height: auto;
}

@media (min-width: 900px) {
  .public-party-join-deadline-has-passed-sponsor-logo {
    width: 100%;
    max-width: 500px;
  }
}
.public-party-join-deadline-has-passed-button {
  margin-top: 20px !important;
  padding: 10px 20px;
  font-size: 16px !important;
}

@media (min-width: 900px) {
  .public-party-join-deadline-has-passed-button {
    font-size: 20px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PublicParty/JoinDeadlineHasPassed.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AACI;EACE,gBAAA;EACA,cAAA;EAGA,eAAA;AADN;AAIM;EARF;IASI,eAAA;EADN;AACF;AAII;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAFN;AAKI;EACE;IACE,YAAA;EAHN;AACF;;AAOE;EACI,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AAJN;;AAOI;EACE;IACE,WAAA;IACA,gBAAA;EAJN;AACF;AAOI;EACI,2BAAA;EACA,kBAAA;EACA,0BAAA;AALR;;AAQQ;EACI;IACA,0BAAA;EALV;AACF","sourcesContent":[".public-party-join-deadline-has-passed {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    padding: 20px;\n  \n    .public-party-join-deadline-has-passed-text {\n      font-weight: 700;\n      margin: 10px 0;\n  \n      // Mobile styles\n      font-size: 20px;\n  \n      // Desktop styles\n      @media (min-width: 900px) {\n        font-size: 24px;\n      }\n    }\n  \n    .public-party-join-deadline-has-passed-game-logo {\n      margin-top: 20px;\n      width: 94px;\n      height: auto;\n    }\n\n    @media (min-width: 900px) {\n      .public-party-join-deadline-has-passed-game-logo {\n        width: 150px;\n      }\n    }\n  }\n\n  .public-party-join-deadline-has-passed-sponsor-logo {\n      margin-top: 20px;\n      width: 100%;\n      max-width: 375px;\n      height: auto;\n    }\n\n    @media (min-width: 900px) {\n      .public-party-join-deadline-has-passed-sponsor-logo {\n        width: 100%;\n        max-width: 500px;\n      }\n    }\n\n    .public-party-join-deadline-has-passed-button {\n        margin-top: 20px !important;\n        padding: 10px 20px;\n        font-size: 16px !important;\n      }\n  \n        @media (min-width: 900px) {\n            .public-party-join-deadline-has-passed-button {\n            font-size: 20px !important;\n            }\n        }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
