import React, { useState } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";

const AddQuickPickGames = ({ selectedSeason, selectedWeek }) => {
    const {
        pageState,
        setPageState,
        leagueState,
        setLeagueState,
        quickPickLeagues,
        NFLRegularSeasonSchedule,
        quickPickNFL,
        NFLCurrentWeek
    } = useAdminDashContext();

 
    const [gamesToAdd, setGamesToAdd] = useState([]);
    console.log("GAMES TO ADD", gamesToAdd);

    // Filter the schedule based on the selected week
    const thisWeeksSchedule = NFLRegularSeasonSchedule?.filter((game) => game.Week === selectedWeek);

    // Sort the schedule by DateTime, earliest date/time first
    thisWeeksSchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));

    // Function to format the date string
    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    const handleAddGame = (game) => {
        // Check if the game is already in gamesToAdd
        const isGameAlreadyAdded = gamesToAdd.some((g) => g.GlobalGameID === game.GlobalGameID);

        if (isGameAlreadyAdded) {
            // Remove the game from gamesToAdd
            setGamesToAdd(gamesToAdd.filter((g) => g.GlobalGameID !== game.GlobalGameID));
            console.log(`Removed game: ${game.AwayTeam} at ${game.HomeTeam}`);
        } else {
            // Add the game to gamesToAdd
            const gameInfo = {
                GlobalGameID: game.GlobalGameID,
                Day: game.Day,
                Week: game.Week,
                HomeTeam: game.HomeTeam,
                GlobalHomeTeamID: game.GlobalHomeTeamID,
                AwayTeam: game.AwayTeam,
                GlobalAwayTeamID: game.GlobalAwayTeamID,
                props: {
                    tiebreakers: [],
                    gameProps: [],
                    homeProps: [],
                    awayProps: []
                }
            };
            setGamesToAdd([...gamesToAdd, gameInfo]);
            console.log(`Adding game: ${game.AwayTeam} at ${game.HomeTeam}`);
        }
    };

    const isGameInQuickPicksAlready = (GlobalGameID) => {
        return quickPickNFL?.some((game) => game.GlobalGameID === GlobalGameID);
    };

    // Handle cancel button click
    const handleCancel = () => {
        setGamesToAdd([]); // Clear the gamesToAdd array
        console.log("Canceled adding games");
    };

    // Handle add games button click
    const handleAddGames = () => {
        console.log("Adding Games", gamesToAdd); // Console log the games being added
        const gameData = {
            league: leagueState,
            season: selectedSeason,
            gameData: gamesToAdd
        };
        console.log("Game Data to add in Mongo and Redis", gameData);
    };

    return (
        <div>
            <h2>Add Quick Pick Games</h2>

            {/* Display This Week's Schedule */}
            <div>
                {thisWeeksSchedule?.map((game, index) => (
                    <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-start' }}>
                        {isGameInQuickPicksAlready(game.GlobalGameID) ? (
                            <div style={{ marginRight: '37px', marginLeft: '30px' }}>
                                ADDED
                            </div>
                        ) : (
                            <button 
                                onClick={() => handleAddGame(game)} 
                                style={{ 
                                    marginRight: '10px', 
                                    marginLeft: '10px',
                                    padding: '5px 10px', 
                                    borderRadius: '4px',
                                    border: '1px solid #ccc', // Add your desired border color
                                    backgroundColor: gamesToAdd.some(g => g.GlobalGameID === game.GlobalGameID) ? '#C0C0C0' : 'transparent' // Change background color
                                }}
                            >
                                Add Game
                            </button>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                {formatDateTime(game.DateTime)} {/* Display formatted DateTime */}
                            </div>
                            <div>
                                {game.AwayTeam} at {game.HomeTeam} {/* Display team matchup */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Conditional Section for Adding Games */}
            {gamesToAdd.length > 0 && (
                <div style={{
                    marginTop: '20px',
                    borderTop: '2px solid #002129', // 2px solid top border
                    padding: '10px',
                    position: 'fixed', // Fixed position at the bottom
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#fff', // Optional: background color for visibility
                    zIndex: 1000 // Ensure it is above other content
                }}>
                    <div>
                        Add {gamesToAdd.length} Games?
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <button 
                            onClick={handleCancel} 
                            style={{
                                marginRight: '10px',
                                padding: '5px 10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc'
                            }}
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={handleAddGames} 
                            style={{
                                padding: '5px 10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc'
                            }}
                        >
                            Add Games
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddQuickPickGames;
