
export const SOCIAL_LINKS = [
    {
      id: 'instagram-social-link',
      text: 'Instagram',
      link: 'https://www.instagram.com/poolpartygaming'
    },{
      id: 'twitter-social-link',
      text: 'Twitter / X',
      link: 'https://twitter.com/poolpartygaming'
    },
    {
      id: 'tiktok-social-link',
      text: 'TikTok',
      link: 'https://www.tiktok.com/@poolpartygaming'
    }
  ]