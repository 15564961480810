import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PercentagePillBox = ({percentage, result}) => {
    //console.log("Percentage Pill Box Percentage:", percentage);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //const percentageTest = "34.1%"; // Dummy value for testing
    const roundedPercentage = Math.round(parseFloat(percentage));
    const totalWidth = isMobile ? 168 : 310;
    const percentageWidth = `${(roundedPercentage / 100) * totalWidth}px`;
    const nonSelectedWidth = `${totalWidth - (roundedPercentage / 100) * totalWidth}px`;
    let resultColor = '#002129';
    if (result === 'Win' || result === 'win') {
        resultColor = '#00AA72';
    } else if (result === 'Loss' || result === 'loss') {
        resultColor = '#CC293C';
    }


    return (

        <Box sx={{ padding: '5px', display: 'flex' }}>
            <Box 
                sx={{ 
                width: percentageWidth, 
                height: isMobile ? '16px' : '40px', 
                borderRadius: '8px 0px 0px 8px',
                border: isMobile ? `1px solid ${resultColor}` : `2px solid ${resultColor}`,
                backgroundColor: resultColor, 
                color: '#ffffff', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'right', 
                margin: 0 
                }}
            >
                <Typography 
                    variant="body2"
                    sx={{
                        fontSize: isMobile ? '12px' : '22px',
                        fontWeight: '500',
                        marginRight: isMobile ? '4px' : '8px'
                    }}
                >
                    {roundedPercentage >= 50 
                        ? `${percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1)}%` 
                        : ''
                    }

                </Typography>
            </Box>
            <Box 
                sx={{ 
                width: nonSelectedWidth, 
                height: isMobile ? '16px' : '40px', 
                border: isMobile ? `1px solid ${resultColor}` : `2px solid ${resultColor}`,
                borderRadius: '0px 8px 8px 0px',
                backgroundColor: '#ffffff', 
                color: resultColor, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'left', 
                margin: 0 
                }}
            >
                <Typography 
                    variant="body2"
                    sx={{
                        fontSize: isMobile ? '12px' : '22px',
                        fontWeight: '500',
                        marginLeft: isMobile ? '4px' : '8px'
                    }}
                >
                {roundedPercentage < 50
                    ? `${percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1)}%` 
                    : ''
                }
                </Typography>
            </Box>
        </Box>

    );
}

export default PercentagePillBox;