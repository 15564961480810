import React from "react";
import { Box, Typography } from "@mui/material";
import { use } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const GameNotReadyText = ({
    headerText,
    text,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: '32px',
                border: '4px solid #002129',
                backgroundColor: '#fff',
                width: '90%',
                maxWidth: isMobile ? '400px' : '500px',
                margin: '0 auto',
                marginTop: '32px',
                marginBottom: '64px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '18px' : '24px',
                    fontWeight: 800,
                    color: '#002129',
                }}
            >
                {headerText}
            </Typography>
            {text?.map((text, index) => (
                    <Typography 
                    key={index}
                    sx={{
                        fontSize: isMobile ? '18px' : '24px',
                        fontWeight: 500,
                        color: '#002129',
                        margin: '16px 0',
                    }}
                >
                    {text}
                </Typography>
                ))}
        </Box>
    );
}

export default GameNotReadyText;
