import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, FormControl, Select, MenuItem } from '@mui/material';
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useSurvivorGamesContext } from "../context/SurvivorGamesContext";
import { DateTime } from "luxon";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import SurvivorSingleIntervalTable from "./SurvivorSingleIntervalTable";

const SurvivorPickStats = () => {
    const {
        intervalInfo,
        league,
        leaderboardData,
    } = useUserAndGameContext();

    const {
        teams,
        schedule,
        theCurrentInterval,
        firstInterval,
        totalEntriesEliminated,
        totalEntriesAlive,
        isThisSurvivorGameOver,
    } = useSurvivorGamesContext();
    //console.log("LEaderboard Data in SurvivorPickStats: ", leaderboardData);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();

    const intervalsToShow = Number(theCurrentInterval) - (Number(firstInterval) - 1);
    const highInterval = Number(theCurrentInterval);

    // Create the values for the dropdown.  Intervals to show is the length, starting with highInterval and going down to firstInterval
    // Also need to add in Overall as a choice
    const intervalValues = [];
    for (let i = highInterval; i >= Number(firstInterval); i--) {
        intervalValues.push(i);
    }
    //intervalValues.push('Overall');

    function findIntervalName(interval) {
        if (interval === 'Overall') {
            return 'Overall';
        } else if (intervalInfo?.type === 'weeks') {
            return `Week ${interval}`;
        } else if (intervalInfo?.type === 'date') {
            const appropriateItem = intervalInfo?.info.find(item => item.interval === Number(interval));
            return appropriateItem?.label ?? null;
        }
        return interval;
    }

    function findIntervalShortLabel(interval) {
        if (interval === 'Overall') {
            return 'Overall';
        } else if (intervalInfo?.type === 'weeks') {
            return `Wk ${interval}`;
        } else if (intervalInfo?.type === 'date') {
            const appropriateItem = intervalInfo?.info.find(item => item.interval === Number(interval));
            return appropriateItem?.shortLabel ?? null;
        }
        return interval;
    }

    // Parse current interval from URL or use default
    const getIntervalFromUrl = () => {
        const searchParams = new URLSearchParams(location.search);
        const intervalParam = searchParams.get('interval');
        return intervalParam || theCurrentInterval.toString();
    };

    const [selectedInterval, setSelectedInterval] = useState(getIntervalFromUrl());

    // Update URL when interval changes
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('interval', selectedInterval);
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }, [selectedInterval, navigate, location.pathname, location.search]);

    // Update selected interval if URL changes externally
    useEffect(() => {
        setSelectedInterval(getIntervalFromUrl());
    }, [location.search]);

    const [sortBy, setSortBy] = useState('timesPicked');
    const [sortOrder, setSortOrder] = useState('desc');

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');


    const handleIntervalChange = (event) => {
        setSelectedInterval(event.target.value);
    };

    const totalAlive = leaderboardData?.filter(entry => !entry.isUserEliminated)?.length;
    const totalEliminated = leaderboardData?.filter(entry => entry.intervalEliminated === Number(selectedInterval))?.length;

    const hasThisIntervalsDeadlinePassed = useMemo(() => {
        if (Number(selectedInterval) < Number(theCurrentInterval)) {
            return true;
        } else if (Number(selectedInterval) === Number(theCurrentInterval)) {
            const intervalInfoItem = intervalInfo?.info.find(item => item.interval === Number(selectedInterval));
            const intervalDeadline = intervalInfoItem?.deadline;
            if (intervalDeadline === 'TBD') {
                return false;
            } else {
                const deadlineInNewYork = DateTime.fromISO(intervalDeadline, { zone: 'America/New_York' });
                return timeNowInNewYork > deadlineInNewYork;
            }
        } else if (selectedInterval === 'Overall') {
            const intervalInfoItem = intervalInfo?.info.find(item => item.interval === Number(theCurrentInterval));
            const intervalDeadline = intervalInfoItem?.deadline;
            if (intervalDeadline === 'TBD') {
                return false;
            } else {
                const deadlineInNewYork = DateTime.fromISO(intervalDeadline, { zone: 'America/New_York' });
                return timeNowInNewYork > deadlineInNewYork;
            }
        }
        return false;
    }, [selectedInterval, theCurrentInterval, intervalInfo, timeNowInNewYork]);
    //console.log("Has this intervals deadline passed: ", hasThisIntervalsDeadlinePassed);

  // Function to get the users picks for the selected interval
    function getUserPicks() {
        const pickStats = [];
        
        if (selectedInterval === 'Overall') {
            for (let i = 0; i < leaderboardData?.length; i++) {
                const userPicks = leaderboardData[i]?.picks || [];
                const filteredPicks = userPicks?.filter(pick => 
                    hasThisIntervalsDeadlinePassed 
                        ? pick.interval <= Number(theCurrentInterval) 
                        : pick.interval < Number(theCurrentInterval)
                );
                
                for (let j = 0; j < filteredPicks?.length; j++) {
                    pickStats.push({
                        gameId: filteredPicks[j].gameId,
                        teamId: filteredPicks[j].teamId,
                        result: filteredPicks[j].result,
                    });
                }
            }
        } else if ((Number(selectedInterval) === Number(theCurrentInterval)) && !hasThisIntervalsDeadlinePassed) {
            return pickStats; // Empty array for current interval when deadline hasn't passed
        } else {
            for (let i = 0; i < leaderboardData?.length; i++) {
                const userPicks = leaderboardData[i]?.picks || [];
                const userPick = userPicks?.find(pick => pick.interval === Number(selectedInterval));
                
                if (userPick) {
                    pickStats.push({
                        gameId: userPick.gameId,
                        teamId: userPick.teamId,
                        result: userPick.result,
                    });
                }
            }
        }
        
        return pickStats;
    }

    const userPickInfo = useMemo(() => getUserPicks(), [selectedInterval, leaderboardData, theCurrentInterval, hasThisIntervalsDeadlinePassed]);
    //console.log("User Pick Info: ", userPickInfo);

    // Function to compile the team stats for the selected interval
    function getTeamStats(userPickInfo) {
        const teamStats = {};

        userPickInfo?.forEach(pick => {
            const { gameId, teamId, result } = pick;

            if(!teamStats[teamId]) {
                teamStats[teamId] = {
                    timesSelected: 0,
                    gameId: gameId,
                    teamId: teamId,
                    result: {
                        win: 0,
                        loss: 0,
                        pending: 0,
                    }
                };
            }

            // Increment times selected
            teamStats[teamId].timesSelected++;

            // Increment result
            if (result === 'win') {
                teamStats[teamId].result.win++;
            } else if (result === 'loss') {
                teamStats[teamId].result.loss++;
            } else {
                teamStats[teamId].result.pending++;
            }
        });
    
        return teamStats;
    };

    const teamStats = useMemo(() => getTeamStats(userPickInfo), [userPickInfo]);
    //console.log("Team Stats: ", teamStats);

    const entriesAliveBeginningOfInterval = leaderboardData?.filter(entry => !entry.isUserEliminated || entry.intervalEliminated >= Number(selectedInterval))?.length;
    //console.log("Entries Alive Beginning of Interval: ", entriesAliveBeginningOfInterval, selectedInterval);

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
            }}>
                <FormControl variant="outlined" size="small" sx={{ width: 225, padding: '16px 0px' }}>
                    <Select
                        value={selectedInterval}
                        onChange={handleIntervalChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select interval' }}
                    >
                        {intervalValues.map((interval) => (
                            <MenuItem key={interval} value={interval.toString()}>
                                {findIntervalName(interval)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            
            <Box className="survivor-entry-info-container">
                <Box className="survivor-entry-info">
                    <Box className="status-section">
                        <Typography variant="h6" className="status-header" sx={{ whiteSpace: 'nowrap'}}>
                            {selectedInterval !== 'Overall' ? `Alive Entering ${findIntervalShortLabel(selectedInterval)}` : 'Total Alive'}
                        </Typography>
                        <Typography variant="body1" className="status-details">
                            {selectedInterval !== 'Overall' ? entriesAliveBeginningOfInterval : totalEntriesAlive}
                        </Typography>
                    </Box>
                    <Box className="status-section">
                        <Typography variant="h6" className="status-header" sx={{ whiteSpace: 'nowrap'}}>
                            {selectedInterval !== 'Overall' ? `${findIntervalShortLabel(selectedInterval)} Eliminations` : 'Total Eliminations'}
                        </Typography>
                        <Typography variant="body1" className="status-details">
                            {selectedInterval !== 'Overall' ? totalEliminated : totalEntriesEliminated}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {selectedInterval !== 'Overall' && (
                <SurvivorSingleIntervalTable
                    teamStats={teamStats}
                    teams={teams}
                    schedule={schedule}
                    league={league}
                />
            )}
        </Box>
    );
};

export default SurvivorPickStats;