import React from "react";
import { useSportsBookContext } from "./context/sportsBookContext";
import SportsBook from "./SportsBook";
import SportsBookNav from "./context/SportsBookNav";
import NFLSportsBook from "./NFLSportsBook";
import NHLSportsBook from "./NHLSportsBook";
import CBBSportsBook from './CBBSportsBook';
import BetSlip from "./BetSlip";
const SportsBookContainer = () => {

    const { betsToDisplay, sportsBookNav, setSportsBookNav, userSelections, setUserSelections, submitBet, parlayMessage, setParlayMessage,  teaserMessage,  setTeaserMessage, bankroll, setBankRoll,  sportsInUserSelections, filteredDropdownMenu, orderValueCount, teaserOddsCalculations, } = useSportsBookContext();

    return(
        <>
            <SportsBookNav />
            <div style={{
                display:'flex',
            }}>
            <div style={{flex:2}}>
                {sportsBookNav === 'ALL'?
                    <>
                        <NHLSportsBook userSelections={userSelections} setUserSelections={setUserSelections} />
                        <NFLSportsBook userSelections={userSelections} setUserSelections={setUserSelections} />
                        <SportsBook userSelections={userSelections} setUserSelections={setUserSelections} />
                        <CBBSportsBook userSelections={userSelections} setUserSelections={setUserSelections} />
                    </>
                    :
                    <></>
                }
                {sportsBookNav === 'NFL'?
                    <NFLSportsBook  userSelections={userSelections} setUserSelections={setUserSelections}/>
                    :
                    <></>
                }
                {sportsBookNav === 'NBA'?
                    <SportsBook  userSelections={userSelections} setUserSelections={setUserSelections}/>
                    :
                    <></>
                }
                {sportsBookNav === 'NHL'?
                    <NHLSportsBook  userSelections={userSelections} setUserSelections={setUserSelections} parlayMessage={parlayMessage} setParlayMessage={setParlayMessage} teaserMessage={teaserMessage}  setTeaserMessage={setTeaserMessage}/>
                    :
                    <></>
                }
            </div>
            <div style={{flex:1}}>
                <BetSlip userSelections={userSelections} setUserSelections={setUserSelections} submitBet={submitBet}  parlayMessage={parlayMessage} setParlayMessage={setParlayMessage} teaserMessage={teaserMessage}  setTeaserMessage={setTeaserMessage}  bankroll={bankroll} setBankRoll={setBankRoll}/>
            </div>
            </div>
        </>
    );
};

export default SportsBookContainer;