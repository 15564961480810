import React, { useState, useEffect } from 'react';
import './PasswordStrength.scss';

const PasswordStrength = ({ password, confirmPassword, requirements, onValidationChange }) => {
  //console.log('incoming password', password)
  //console.log('incomign confirm', confirmPassword)
  const [isValidLength, setIsValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  useEffect(() => {
    if (requirements) {
      setIsValidLength(password.length > requirements.minLength);
      setHasUpperCase(/[A-Z]/.test(password));
      setHasLowerCase(/[a-z]/.test(password));
      setHasNumber(/\d/.test(password));
      setHasSpecialCharacter(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    }
  }, [password, requirements]);

  useEffect(() => {
    const passwordsMatch = password === confirmPassword && password !== '' && confirmPassword !== '';
    setPasswordsMatch(passwordsMatch);
    onValidationChange(isValidLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialCharacter && passwordsMatch);
  }, [password, confirmPassword, isValidLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter, onValidationChange]);

  return (
    <div className="password-requirements-container">
      <ul className="password-requirements-list">
        <li className={isValidLength ? "" : "bulletPoint error"}>
          <span className={isValidLength ? "checkmark" : "cross"}>{isValidLength ? "✓" : "✗"}</span> <span className={isValidLength ? "text-pass" : "text-invalid"}>Must contain at least 8 characters</span>
        </li>
        <li className={hasUpperCase ? "" : "bulletPoint error"}>
          <span className={hasUpperCase ? "checkmark" : "cross"}>{hasUpperCase ? "✓" : "✗"}</span> <span className={hasUpperCase ? "text-pass" : "text-invalid"}>Must contain at least 1 capital letter</span>
        </li>
        <li className={hasLowerCase ? "" : "bulletPoint error"}>
          <span className={hasLowerCase ? "checkmark" : "cross"}>{hasLowerCase ? "✓" : "✗"}</span> <span className={hasLowerCase ? "text-pass" : "text-invalid"}>Must contain at least 1 lowercase letter</span>
        </li>
        <li className={hasNumber ? "" : "bulletPoint error"}>
          <span className={hasNumber ? "checkmark" : "cross"}>{hasNumber ? "✓" : "✗"}</span> <span className={hasNumber ? "text-pass" : "text-invalid"}>Must contain at least 1 number</span>
        </li>
        <li className={hasSpecialCharacter ? "" : "bulletPoint error"}>
          <span className={hasSpecialCharacter ? "checkmark" : "cross"}>{hasSpecialCharacter ? "✓" : "✗"}</span> <span className={hasSpecialCharacter ? "text-pass" : "text-invalid"}>Must contain at least 1 special character</span>
        </li>
        <li className={passwordsMatch ? "" : "bulletPoint error"}>
          <span className={passwordsMatch ? "checkmark" : "cross"}>{passwordsMatch ? "✓" : "✗"}</span> <span className={passwordsMatch ? "text-pass" : "text-invalid"}>Passwords match</span>
        </li>
      </ul>
    </div>
  );
};

export default PasswordStrength;
