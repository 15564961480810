import React, { useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams } from "react-router-dom";

const SponsorSelectionButtons = ({ buttonsToDisplay, sponsorPageState, setSponsorPageState, mainGameId, gameAddress }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    
    const navigate = useNavigate();
    const { pageState } = useParams();
    //console.log("Optional State: ", pageState);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    useEffect(() => {
        if (pageState !== sponsorPageState) {
            setSponsorPageState(pageState);
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('PRESENTED_BY')}/${uriEncoded(pageState)}`);
        }
    }, []);

    const handleClick = (sponsorState) => {
        setSponsorPageState(sponsorState);
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('PRESENTED_BY')}/${uriEncoded(sponsorState)}`);

    }


    return (
    <Box>
        <Box
            display="flex"
            justifyContent="space-evenly"
            maxWidth={isMobile ? '300px' : '500px'}
            margin="15px auto"
        >
            {buttonsToDisplay?.map((sponsorState) => (
                console.log("Sponsor Page State: ", sponsorPageState, "Sponsor State: ", sponsorState),
                <Typography
                    key={sponsorState}
                    component="span"
                    onClick={() => handleClick(sponsorState)}
                    sx={{
                        display: 'block',
                        padding: isMobile ? '8px 4px' : '12px 8px',
                        borderRadius: '6px',
                        backgroundColor: sponsorPageState === sponsorState ? '#E8E8E8' : 'transparent',
                        color: '#002129',
                        fontSize: isMobile ? '16px' : '24px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        marginRight: isMobile ? '0px' : '20px',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    }}
                >
                    {sponsorState}
                </Typography>
            ))}
        </Box>
    </Box>
    );
}

export default SponsorSelectionButtons;
