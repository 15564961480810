import React, { useState } from 'react';
import { Box, Typography, Checkbox, Button, Modal } from '@mui/material';
import PdfViewer from '../../layouts/pdfViewer';
import { Link } from 'react-router-dom';

export default function FinishAndSubmit({ goToNextStep, goToPrevStep, handleRegister, isTermsAccepted, setIsTermsAccepted }) {
  const [modalState, setModalState] = useState(null);
  const handleCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };
  const handleModalState = (val) => {
    setModalState(val);
  };
  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {/* <Typography variant='body1'>
        By clicking on sign-up you agree to Pool Party’s Terms and Services. To
        learn more about our data protection policies, please see Pool Party
        Privacy Policy.
      </Typography> */}
      <Box>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'left' }}>
          <Checkbox
            id='isTermsAndConditionsChecked'
            onChange={handleCheckboxChange}
            checked={isTermsAccepted}
            sx={{paddingTop:'0px', paddingBottom:'0px'}}
          />
          <Typography
            variant='body1'
            sx={{ textAlign: 'left' }}
          >
            I accept and agree to Pool Party's{" "}
            <Link
              target={"_blank"}
              style={{
                fontWeight: '700',
                textDecoration: 'underline',
                color: '#00AAD1',
              }}
              to="https://poolpartyfilestorage.blob.core.windows.net/documents/Pool_Party_Terms_of_Service_2024.pdf"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              target={"_blank"}
              style={{
                fontWeight: '700',
                textDecoration: 'underline',
                color: '#00AAD1',
              }}
              to="https://poolpartyfilestorage.blob.core.windows.net/documents/Pool_Party_LLC_Privacy_Policy_2024.pdf"
            >
              Privacy Policy
            </Link>
          </Typography>

        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={goToPrevStep}
          sx={{
            bgcolor: 'grey.400',
            ':hover': {
              bgcolor: 'grey.500',
            },
          }}
        >
          Back
        </Button>
        <Button
          variant='contained'
          onClick={handleRegister}
          disabled={!isTermsAccepted}
          sx={{
            bgcolor: isTermsAccepted ? '#00AAD1' : 'grey.400',
            color: isTermsAccepted ? 'white' : 'black',
            ':hover': {
              bgcolor: isTermsAccepted ? 'primary.dark' : 'grey.500',
            },
          }}
        >
          Sign Up
        </Button>
      </Box>
      <Modal
          open={modalState === true}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
      >
          <Box className="modal-container" style={{ height: '600px'}}>
            <PdfViewer url={'https://poolpartyfilestorage.blob.core.windows.net/documents/Pool_Party_Terms_of_Service_2024.pdf'} />
              <Button
                  variant="contained"
                  sx={{
                      bgcolor: 'white',
                      color: '#002129',
                      border: '1px solid #002129',
                      '&:hover': {
                        bgcolor: 'white', // Maintain white background on hover
                        color: '#002129', // Maintain text color on hover
                        border: '1px solid #002129', // Maintain border color on hover
                      },
                    }}
                  onClick={() => {setModalState(null)}}
              >
                  CLOSE
              </Button>
          </Box>
      </Modal>
    </Box>
  );
}
