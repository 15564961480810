import { Box, TextField } from '@mui/material';
import React from 'react';
import NextAndBackButtons from './NextAndBackButtons';

export default function Preferences({ goToNextStep, goToPrevStep }) {
  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <TextField
        id='favouriteSport'
        name='favouriteSport'
        label='Favorite Sport'
        variant='outlined'
        type='text'
      />
      <TextField
        id='favouriteTeam'
        name='favouriteTeam'
        label='Favorite Team'
        variant='outlined'
        type='text'
      />
      <NextAndBackButtons
        nextButtonText={'Continue'}
        onBackButtonClick={goToPrevStep}
        onNextButtonClick={goToNextStep}
      />
    </Box>
  );
}
