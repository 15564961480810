import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Stack
} from '@mui/material';

const TwoButtonModal = ({
    modalOpen,
    headerText,
    headerSize,
    contentText,
    buttonOneText,
    buttonOneAction,
    buttonTwoText,
    buttonTwoAction
}) => {

    return (
        <Modal
            open={modalOpen}
            disableEscapeKeyDown
            disableAutoFocus
            disableEnforceFocus
            disablePortal
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'white',
                    borderRadius: '8px',
                    p: 4,
                    width: 310,
                }}
            >
                <Typography
                    sx={{
                        fontSize: headerSize || '16px',
                        fontWeight: 700,
                        mb: 2
                    }}
                >
                    {headerText}
                </Typography>
                
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        mb: 3
                    }}
                >
                    {contentText}
                </Typography>

                <Stack
                    direction="row"
                    spacing={2.5}
                    justifyContent="center"
                >
                   <Button
                        variant="contained"
                        onClick={buttonOneAction}
                        sx={{
                            backgroundColor: '#CECECE', // Set background color
                            color: '#002129', // Set text color
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            whiteSpace: 'nowrap',
                            height: '36px',
                            '&:hover': {
                                backgroundColor: '#CECECE',
                                color: '#002129',
                            }
                        }}
                    >
                        {buttonOneText}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={buttonTwoAction}
                        sx={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            whiteSpace: 'nowrap',
                            height: '36px',
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                                backgroundColor: '#002129',
                            },
                        }}
                    >
                        {buttonTwoText}
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default TwoButtonModal;