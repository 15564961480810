import React from 'react';
import './GameNotReadyModal.scss';

const GameNotReadyModal = ({
    modalOpen,
    setModalOpen,
    notReadyModal,
    hideOverlay=false
}) => {
    const headerText = "Selection Sunday is March 16th!";
    const contentText = ["Come back that evening and complete your bracket."];
    //const logo = "https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyStackedLogo.png";
    const subHeaderText = "Bracket Lock";
    const subContentText = ["Thurs March 20th at 12:15pm ET"];

    console.log("Hide Overlay: ", hideOverlay, notReadyModal);
    
    return (
        <>
            {!hideOverlay && (
                <div className="modal-overlay" />
            )}
            
            <div className="modal-content">
                {/* Header Section */}
                {notReadyModal?.headerText && (
                    <h2 className="header">
                        {notReadyModal?.headerText}
                    </h2>
                )}
                
                {/* Content Text */}
                {notReadyModal?.contentText && (
                    notReadyModal?.contentText.map((text, index) => (
                        <p key={index} className="text">
                            {text}
                        </p>
                    ))
                )}
                
                
                {/* Logo */}
                {notReadyModal?.logo && (
                    <div className="logo-container">
                        <img src={notReadyModal?.logo} alt="Logo" />
                    </div>
                )}
                
                {/* Subheader */}
                <h3 className={"subheader"}>
                    {notReadyModal?.subHeaderText}
                </h3>
                
                {/* Subcontent */}
                {notReadyModal?.subContentText && (
                    notReadyModal?.subContentText.map((text, index) => (
                        <p key={index} className="text">
                            {text}
                        </p>
                    ))
                )}
            </div>
        </>
    );
}

export default GameNotReadyModal;