import axios from "axios";

const apiClient = axios.create();
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { status, data } = error.response;

    if (status === 401 && data.error === "Token expired.") {
      window.dispatchEvent(new CustomEvent("token-expired"));
    }
    return Promise.reject(error);
  }
);
export default apiClient;
