// GameLeaderboard.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Snackbar, Alert } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SimpleSelectionButtons from '../../GamePageComponents/TabSelectionButtons/SimpleSelectionButtons';
import './GameLeaderboard.scss';
import PoolPartyTrophyGold from '../../../assets/images/PoolPartyTrophyGold.svg';
import ReferralLeaderboard from '../LeaderboardByType/ReferralLeaderboard';
import GameCreationLeaderboard from '../LeaderboardByType/GameCreationLeaderboard';
import InfoModal from './InfoModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 
import GameNotReadyBox from '../../GameModals/GameNotReadyModal/GameNotReadyBox';

const GameLeaderboard = ({ gameData, myUserId, myUsername }) => {
    //console.log("Game Leaderboard Data: ", gameData);
    const navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();
    const mainTab = searchParams.get('tab') || '0';
    const subTab = searchParams.get('subTab') || '0';
    
    const [currentSubPageData, setCurrentSubPageData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const tabTexts = gameData?.subPages?.map(page => page.tabText);
    //console.log("Sub Tab Texts", tabTexts);

    const handleCopyLink = () => {
        let link = currentSubPageData?.buttonLink;
        if (myUserId) {
            // append &referral_id= to the link
            link += `&referral_id=${myUserId}`;
        }
        navigator.clipboard.writeText(link).then(() => {
            setSnackbarOpen(true);
        }).catch((error) => {
            console.error('Failed to copy text: ', error);
        });
    };

    // Close the snackbar after 3 seconds
    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false);
    };

    const handleButtonClick = () => {
        if(currentSubPageData?.leaderboardType === 'referral') {
            handleCopyLink();
        }
        else {
            navigate(currentSubPageData?.buttonLink);
        }
    };

    
    // Update current sub page data when subTab changes or when data loads
    useEffect(() => {
        if (gameData?.subPages && gameData.subPages.length > 0) {
            const subTabIndex = parseInt(subTab, 10);
            // Make sure the index is valid
            if (subTabIndex >= 0 && subTabIndex < gameData.subPages.length) {
                setCurrentSubPageData(gameData.subPages[subTabIndex]);
            } else {
                // Default to first sub tab if index is invalid
                setCurrentSubPageData(gameData.subPages[0]);
            }
        }
    }, [subTab, gameData]);
    
    // Inside GameLeaderboard component
    const handleSubTabChange = (index) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('subTab', index.toString());
        setSearchParams(newSearchParams);
    };

    // Render the right leaderboard based on sub tab
    // Referral is RefferalLeaderboard, Create a Pool is GameCreationLeaderboard
    const renderLeaderboard = () => {
        if (currentSubPageData?.leaderboardType === 'referral') {
            return (
                <ReferralLeaderboard
                    myUsername={myUsername}
                    gameId={gameData?.gameId}
                />
            );
        } else if (currentSubPageData?.leaderboardType === 'created') {
            return (
                <GameCreationLeaderboard 
                    myUsername={myUsername}
                    masterPoolId={gameData?.masterPoolId}
                />
            );
        }

        return null;
    }

    const hanleModalClick = () => {
        //console.log("Modal Clicked");
        setModalOpen(true);
    }

    return (
        <Box className="game-leaderboard-container">
            
            <Box className="game-leaderboard-content">
                {currentSubPageData?.buttonText && (
                    <Button 
                        className="game-leaderboard-button"
                        onClick={handleButtonClick}
                        disabled={currentSubPageData?.buttonDisabled}
                    >
                        {currentSubPageData?.buttonText}
                        {currentSubPageData?.showCopyIcon && (
                            <ContentCopyIcon sx={{ marginLeft: '8px'}} />
                        )}
                    </Button>
                )}

                <SimpleSelectionButtons 
                    selectedTab={subTab} 
                    tabTexts={tabTexts || []}
                    customHandler={handleSubTabChange}
                />

                {currentSubPageData?.notReadyModal && (
                    <GameNotReadyBox
                        notReadyModal={currentSubPageData?.notReadyModal}
                    />
                )}

                {currentSubPageData?.title && (
                    <Typography className="game-leaderboard-title">
                        {currentSubPageData?.title}
                    </Typography>
                )}

                {currentSubPageData?.subTitle && (
                    <Typography className="game-leaderboard-subtitle">
                        {currentSubPageData?.subTitle}
                    </Typography>
                )}

                {currentSubPageData?.prizeText && (
                    <Box className="game-leaderboard-trophy-container">
                        <img
                            src={PoolPartyTrophyGold}
                            alt="Gold Trophy"
                            className="game-leaderboard-trophy-left"
                        />
                        <Typography className="game-leaderboard-prize-text">
                            {currentSubPageData?.prizeText}
                        </Typography>
                        <img
                            src={PoolPartyTrophyGold}
                            alt="Gold Trophy"
                            className="game-leaderboard-trophy-right"
                        />
                    </Box>
                )}

                {currentSubPageData?.prizes && (
                    <Box className="game-leaderboard-prizes-list" sx={{ padding: '16px', maxWidth: '550px'}}>
                        {currentSubPageData?.prizes.map((prize, index) => (
                            <Typography key={index} className="game-leaderboard-prize-text" sx={{ marginBottom: '8px'}}>
                                {prize}
                            </Typography>
                        ))}
                    </Box>
                )}

                {currentSubPageData?.modalLinkText && (
                    <Typography 
                        className="game-leaderboard-modal-link"
                        onClick={hanleModalClick}    
                    >
                        {currentSubPageData?.modalLinkText}
                    </Typography>
                )}
            </Box>

            
            {currentSubPageData?.leaderboardType && (
                renderLeaderboard()
            )}

            <InfoModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                modalInfo={currentSubPageData?.modalInfo}
            />


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // The snackbar will automatically close after 3 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Link copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>

        
    );
};

export default GameLeaderboard;