import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import FantasySportsbookContainer from '../components/FantasySportsbook/FantasySportsbookContainer';
import FantasySportsbookProvider from '../components/FantasySportsbook/context/FantasySportsbookContext';

const FantasySportsbook = () => {
  const queryClient = new QueryClient();
  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <FantasySportsbookProvider>
          <FantasySportsbookContainer />
        </FantasySportsbookProvider>
      </QueryClientProvider>
    </Box>
  );
}

export default FantasySportsbook;
