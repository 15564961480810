import React, {useState} from 'react';
import MarchMadnessBracketHeading from './bracketComponents/marchMadnessBracketHeading';
import BracketNav from './bracketComponents/bracketNav';
import MarchMadnessBracket from './bracketEvents/marchMadnessBracket';
import BracketUserHeader from './bracketComponents/bracketUserHeader';
import BracketRulesMulligenMadness from './bracketComponents/bracketRulesMulligenMadness';
import LeaderBoardContainer from './bracketLeaderBoard/leaderBoardContainer';
import MarchMadnessBracketLive from './bracketEvents/marchMadnessBracketLiveCopy';

import { connect } from 'react-redux';

const BracketContainer = (props) => {
    console.log('BracketContainer props:', props);
    const {cbbLeagueHierarchyData, cbbTeamsData, ncaaTournamentHierarchyData } = props?.sportsData;
    const areWeLive = true;
    console.log('NCAATOURNEY 1', ncaaTournamentHierarchyData, cbbTeamsData.data);
    const [clickState, setClickState] = useState('BRACKET_VIEW');
    console.log(clickState, "CLICKSTATE")
    return (
        <div>
            {/* Render your bracket components here */}
            <BracketUserHeader sportsData={props?.sportsData} />
            <MarchMadnessBracketHeading />
            <BracketNav setClickState={setClickState} clickState={clickState}/>
            {'BRACKET_VIEW' === clickState?
            <>
            {areWeLive?
                <MarchMadnessBracketLive ncaaTournamentHierarchyData={ncaaTournamentHierarchyData} cbbTeamsData={cbbTeamsData} cbbLeagueHierarchyData={cbbLeagueHierarchyData}/>
                :
                <MarchMadnessBracket ncaaTournamentHierarchyData={ncaaTournamentHierarchyData} cbbTeamsData={cbbTeamsData} cbbLeagueHierarchyData={cbbLeagueHierarchyData}/>
            }
            </>
            :
            <>
            </>}
            {'LEADER_BOARD_VIEW' === clickState?

                <LeaderBoardContainer sportsData={props?.sportsData} ncaaTournamentHierarchyData={ncaaTournamentHierarchyData} cbbTeamsData={cbbTeamsData.data} cbbLeagueHierarchyData={cbbLeagueHierarchyData}/>
                :
                null
            }
            {'RULES_VIEW' === clickState?
                <BracketRulesMulligenMadness />
                :
                null
            }
            {/* Add other bracket components as needed */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    games: state.games,
    game: state.game,
    user: state.user,
    sportsData: state.sportsData,
    cbbLeagueHierarchy: state.cbbLeagueHierarchy,
    ncaaTournamentHierarchy: state.ncaaTournamentHierarchy,
    cbbTeams: state.cbbTeams,
});

export default connect(mapStateToProps)(BracketContainer);
