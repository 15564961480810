import React from 'react';
import { Box, Button, Typography, Modal, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getTeamLogo } from '../../../utils/SportsData';

const RegionCompleteModal = ({
    modalOpen,
    setModalOpen,
    bracket,
    pickState,
    setRoundDash,
    setRoundDashPosition,
    teams,
    league,
    regions,
    regionInfo,
    picksComplete
}) => {

    const regionWinner = pickState?.find(pick => pick.bracket === bracket && pick.round === 4)?.teamId;
    //console.log("Region Winner: ", regionWinner);
    const regionWinnerLogo = getTeamLogo(league, regionWinner, teams);
    //console.log("Region Winner Logo: ", regionWinnerLogo);
    
    const getPicksStatus = (region) => {
        const picksMadeThisRegion = pickState?.filter(pick => pick.bracket === region)?.length || 0;
        const picksToMakeThisRegion = regionInfo?.find(info => info.name === region)?.games || 0;
        return {
            picksMade: picksMadeThisRegion,
            totalPicks: picksToMakeThisRegion,
            isComplete: picksMadeThisRegion === picksToMakeThisRegion
        };
    };

    const getFinalFourStatus = () => {
        const picksMade = pickState?.filter(pick => (pick.round === 5 || pick.round === 6))?.length || 0;
        const totalPicks = 3;
        return {
            picksMade,
            totalPicks,
            isComplete: picksMade === totalPicks
        };
    };

    const getButtonContent = (region) => {
        const status = getPicksStatus(region);
        return (
            <>
                {region} {status.picksMade}/{status.totalPicks}
                {status.isComplete ? 
                    <CheckIcon sx={{ ml: '4px', color: '#00AA72' }} /> : 
                    <CloseIcon sx={{ ml: '4px', color: '#CC293C' }} />}
            </>
        );
    };

    const getFinalFourContent = () => {
        const status = getFinalFourStatus();
        return (
            <>
                Final Four {status.picksMade}/{status.totalPicks}
                {status.isComplete ? 
                    <CheckIcon sx={{ ml: 1, color: '#00AA72' }} /> : 
                    <CloseIcon sx={{ ml: 1, color: '#CC293C' }} />}
            </>
        );
    };

    const buttonSx = (region) => {
        const status = region === 'finalFour' ? getFinalFourStatus() : getPicksStatus(region);
        return {
            width: '120px',
            fontSize: '12px',
            backgroundColor: '#E5E5E5',
            color: status.isComplete ? '#00AA72' : '#CC293C',
            whiteSpace: 'nowrap',
            '&:hover': { backgroundColor: '#D5D5D5' }
        };
    };

    const handleClosing = () => {
        setModalOpen(false);
    };

    const handleRegionClick = (region, position) => {
        setModalOpen(false);  // Close the modal
        setRoundDashPosition[position]();  // Navigate to the region
        setRoundDash(position);  // Set the region dash
    };

    return (
        <Modal
            open={modalOpen}
            disableEscapeKeyDown
            disableAutoFocus
            disableEnforceFocus
            disablePortal
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'white',
                    borderRadius: '8px',
                    p: 2,
                    minWidth: 310,
                    maxWidth: 500,
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 800,
                    }}
                >
                    Your {bracket} Region is set!
                </Typography>
                
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        mb: 3
                    }}
                >
                    {picksComplete ? 'Your bracket is complete! Make sure your picks are saved.' : 'Keep going to finish your bracket.'}
                </Typography>

                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        mb: 1
                    }}
                >
                    {bracket} Winner
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #002129',
                        borderRadius: '4px',
                        width: '50px',
                        height: '50px',
                        margin: '0 auto',
                        mb: 3
                    }}
                >
                    <img
                        src={regionWinnerLogo}
                        alt="Region Winner"
                        style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                    />
                </Box>

                <Stack direction="row" spacing={2} justifyContent="center" mb={1}>
                    <Button
                        variant="contained"
                        sx={buttonSx(regions?.topLeft)}
                        onClick={() => handleRegionClick(regions?.topLeft, 1)}
                    >
                        {getButtonContent(regions?.topLeft)}
                    </Button>
                    <Button
                        variant="contained"
                        sx={buttonSx(regions?.topRight)}
                        onClick={() => handleRegionClick(regions?.topRight, 3)}
                    >
                        {getButtonContent(regions?.topRight)}
                    </Button>
                </Stack>

                <Button
                    variant="contained"
                    sx={buttonSx('finalFour')}
                    onClick={() => handleRegionClick('finalFour', 5)}
                >
                    {getFinalFourContent()}
                </Button>

                <Stack direction="row" spacing={2} justifyContent="center" mt={1}>
                    <Button
                        variant="contained"
                        sx={buttonSx(regions?.bottomLeft)}
                        onClick={() => handleRegionClick(regions?.bottomLeft, 2)}
                    >
                        {getButtonContent(regions?.bottomLeft)}
                    </Button>
                    <Button
                        variant="contained"
                        sx={buttonSx(regions?.bottomRight)}
                        onClick={() => handleRegionClick(regions?.bottomRight, 4)}
                    >
                        {getButtonContent(regions?.bottomRight)}
                    </Button>
                </Stack>

                <Button
                    variant="contained"
                    sx={{
                        mt: 3,
                        height: '36px',
                        color: '#fff',
                        backgroundColor: '#002129',
                        '&:hover': { backgroundColor: '#002129' }
                    }}
                    onClick={handleClosing}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default RegionCompleteModal;