import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import GameBanner from "./gamePageAdditional/gamePageBanner";
import {getCurrentGameFromLocalStorage, fetchGameById, savePicks} from '../../redux/actions/gameImport';
import {fetchWeeklyGames, fetchSeason, fetchNFLTeams, fetchNFLScores} from '../../redux/actions/dataImport';
import GameDetails from "./gamePageAdditional/gameDetails";
import BlockPoolGameDetails from "./gamePageAdditional/blockPoolDetails";
import PickemPoolGameDetails from "./pickem/pickemPoolDetails";

// import './styles.scss';
const {useGameContext} = require('./context/gamePageContext');


const GamePageContainer = (props) => {

    const { gameId } = useGameContext();
    const dispatch = useDispatch();
    console.log(props);
    let newGameData = props?.game?.fetchGameByIdReducer?.game?.data?.gameData;

    const [gamePageData, setGamePageData] = useState({});
    const [gridSize, setGridSize] = useState(newGameData?.pool?.numberOfBlocks);
    const [userData, setUserData] = useState({});
    const [gameWeek, setGameWeek] = useState();
    const [loading, setLoading] = useState(true);
    const [gameData, setGameData] = useState({});

    useEffect(() => {
      const fetchData = async () => {
          try {
              const userData = getUserDataFromLocalStorage();
              const payload = {
                  gameID: gameId,
                  poolType: "Block_Pool",
                  email: userData.email,
              };

              await dispatch(fetchGameById(payload));
              const gameData = props.game?.fetchGameByIdReducer?.game?.data?.gameData;
              setGameData(gameData);
              setUserData(userData);
              setGridSize(gameData?.pool?.numberOfBlocks);

              let data = {
                  seasonYear: '2023',
                  league: 'NFL'
              };
              await dispatch(fetchSeason(data));

              data = {};
              await dispatch(fetchNFLTeams(data));
              await dispatch(fetchNFLScores(data));
              // await dispatch(fetchNFLTeams({}));
              setLoading(false);
          } catch (err) {
              console.log('error', err);
              setLoading(false);
          }
      };

      fetchData();
  }, [gameId, dispatch]);

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try{
    //       const userData = getUserDataFromLocalStorage();

    //       const payload = {
    //         gameID: gameId,
    //         poolType: "Block_Pool",
    //         email: userData.email,
    //       };

    //       const gameData = await dispatch(fetchGameById(payload));
    //       setGameData(gameData);
    //       setUserData(userData);
    //       setGridSize(gameData?.pool?.numberOfBlocks);
    //       let data = {
    //         seasonYear:'2023',
    //         league: 'NFL'
    //       }
    //       const weekGameData = await dispatch(fetchSeason(data));
    //       dispatch(fetchNFLTeams());
    //       setGameWeek(weekGameData);
    //     }catch(err){
    //       console.log('error', err);
    //     }
    //   };


    //   fetchData();

    //   setLoading(false);
    // }, [gameId, dispatch]);
    const dateTime = (dateArg) => {
      const date = new Date(dateArg);
      const formattedDate = date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      });
      return formattedDate;
    }

    let gridSizeOne = newGameData?.pool?.numberOfBlocks;

    if (loading) {
      return <div>Loading...</div>;
    }
    const sportsGameData = props?.sportsData?.seasonData?.seasonData;
    const nflTeamsData = props?.sportsData?.nflTeamsData?.nflTeams;
    const allGames = newGameData?.pool?.selectedGameData;

    const lookupGameByKey = (gameKey) => {
      let sportsReduced = sportsGameData.filter(game => game.GameKey === gameKey) || null;
      return {...sportsReduced["0"]};
    };

    const filterTeams = (gameTeams) => {
      let homeTeamData = nflTeamsData.filter(game => game.Key === gameTeams.HomeTeam) || null;
      let awayTeamData = nflTeamsData.filter(game => game.Key === gameTeams.AwayTeam) || null;
      return {homeData:{...homeTeamData["0"]}, awayData:{...awayTeamData["0"]}}
    }
    console.log('newGameData', props?.sportsData?.seasonData, props?.sportsData?.nflTeamsData);
    return (
      <>
       <GameBanner gameData={newGameData} userData={userData}/>
      <PickemPoolGameDetails gameData={newGameData} userData={userData} dispatch={dispatch} savePicks={savePicks} />
      {/*    {gameId && newGameData ? (
        <>
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', maxWidth:900, margin:'0 auto', color:'#fff', marginBottom:"25px", padding:20, background:'#232323', borderRadius:20, boxShadow:'0 0 8px #000'}}>
                <div>Game Details</div>
                <div>View Pool</div>
                <div>View Schedule</div>
                <div>Entrants</div>
                <div>Pool Info</div>
                <div>Settings</div>
              </div>
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', maxWidth:900, margin:'0 auto', color:'#fff', marginBottom:"25px", padding:20, background:'#232323', borderRadius:20, boxShadow:'0 0 8px #000'}}>
                <div>Commissioners Notes:</div>

                <div style={{display:'flex'}}>
                  <div>
                    <div>
                      Total Entrants
                    </div>
                    <div>
                      {newGameData.gamePlayers.length}
                    </div>
                  </div>
                  <div>
                    <div>
                      Blocks Taken
                    </div>
                    <div>
                      0
                    </div>
                  </div>
                  <div>
                    <div>
                      Blocks Available
                    </div>
                    <div>
                      100
                    </div>
                  </div>
                </div>
              </div>
              <h2 style={{textAlign: 'left',marginBottom: '20px', maxWidth:'900px', margin: '35px auto',color: '#fff'}}>Block Pool Schedule</h2>
              {
                 allGames.map((game, index) => {
                  let gameData = lookupGameByKey(game.generatedGame[0].gameId);
                  let teamData = filterTeams(gameData);
                  let gameDate = dateTime(game.generatedGame[0]?.gameDate);
                  return (

                    <>
                      <div style={{ maxWidth:900, margin:'0 auto', color:'#fff', marginBottom:"25px", padding:20, background:'#232323', borderRadius:20, boxShadow:'0 0 8px #000', cursor:'pointer'}} key={index}>
                      <div  style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%',}}>
                        <div style={{width:'100px'}}>Week {gameData.Week}</div>
                          <img style={{display:'block', maxWidth:75, width:'100%', }} src={teamData.awayData.WikipediaLogoUrl} alt={teamData.awayData.FullName} />
                            <div style={{width: '40%', maxWidth:350, textAlign:'center', }}>
                              <div>{teamData.awayData.FullName} @ {teamData.homeData.FullName}</div>
                              <div>{gameDate}</div>
                            </div>
                          <img style={{display:'block', maxWidth:75, width:'100%', }} src={teamData.homeData.WikipediaLogoUrl} alt={teamData.homeData.FullName} />
                          <button>VIEW MATCHUP</button>
                          <button>VIEW BLOCKS</button>

                        </div>
                        <div>
                            <h3>Matchup Details</h3>
                            <div>Broadcasting on {gameData.Channel}</div>
                            <div>{gameData?.StadiumDetails?.Name}</div>
                            <div>{gameData?.StadiumDetails?.City}, {gameData?.StadiumDetails?.State}</div>
                          </div>
                      </div>
                    </>
                  );
                })
              }
              <GameDetails gameDetails={allGames}/>
              {newGameData?.pool?.selectedGameData?.map((game, index) => (
                console.log(game.generatedGame),
                <div key={index}>


                </div>
              ))}
                <div>{newGameData?.gameName}</div>
                <div>{newGameData?.gameDescription}</div>
                <div>{newGameData?.gameLeague}{newGameData?.poolType}</div>
                <div>{newGameData?.gameStart}</div>
                <div>{newGameData?.pool.gameSchedule}</div>

                <BlockPoolGameDetails gameData={newGameData} allGames={allGames} sportsGameData={sportsGameData} nflTeamsData={nflTeamsData}/>
            </>
        ) : (
          <div>Not a match</div>
        )} */}
      </>
    );
}


const mapStateToProps = (state) => ({
    games: state.games,
    game: state.game,
    user: state.user,
    sportsData: state.sportsData,
    seasonData: state.seasonData,
    nflTeamsData: state.nflTeamsData,
});

export default connect(mapStateToProps)(GamePageContainer);