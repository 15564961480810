import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useNFLSportsbookOdds = () => {
    return useMutation({
        mutationFn: nflSportsbookOddsRequest,
        onError: (error) => {
            console.error('Error getting NFL Sportsbook Odds:', error);
        },
        onSuccess: (data) => {
          console.log('Get NFL Sportsbook Odds:', data);
        }
    });
}

const nflSportsbookOddsRequest = async () => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-nfl-sportsbook-odds`, {
        headers: {
            Authorization: authorizationHeader,  // Include the authorization header in the request
            'Content-Type': 'application/json'
        }
    });
    return response.data;
};