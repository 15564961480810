import React, { useState } from "react";
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info'; // Assuming you're using this icon
import "../BetSlip.scss";

const WhatIsABet = ({ chosenBetStyle }) => {
    const [whatIsABetOpen, setWhatIsABetOpen] = useState(false);

    const straightBetText = "A straight bet is a wager on the outcome of a single game or event. The bet is made against a point spread, moneyline, or total.";
    const parlayBetText = "A parlay is a combined wager on two or more selections into one larger bet with larger payout odds. For a parlay to be a winner, each selection must win. \n\nIn the event of a tie in one of the selections, that selection is removed from the bet and the odds are adjusted accordingly.";
    const teaserBetText = `A teaser is a combined wager on two or more spread or total selections where the bettor can adjust the point spreads and/or total in their favor. This will lower the payout odds. For a teaser to be a winner, each selection must win. \n\nIn the event of a tie in one of the selections, that selection is removed from the bet and the odds are adjusted accordingly. In the event of a teaser where all but one leg are a tie, the bet becomes a push and funds are returned.`;

    const getBetText = (chosenBetStyle) => {
        switch (chosenBetStyle) {
            case "straight":
                return straightBetText;
            case "parlay":
                return parlayBetText;
            case "teaser":
                return teaserBetText;
            default:
                return "No information available.";
        }
    };

    return (
        <>
            <Box className="sportsbook-what-is-a-bet-bar">
                <Box
                    className="sportsbook-what-is-a-bet-container"
                    onClick={() => setWhatIsABetOpen(true)}
                >
                    <Typography className="sportsbook-what-is-a-bet-text">
                        What is a {chosenBetStyle} bet?
                    </Typography>
                    <InfoIcon className="sportsbook-what-is-a-bet-info-icon " />
                </Box>
            </Box>

            <Dialog
                open={whatIsABetOpen}
                onClose={() => setWhatIsABetOpen(false)}
                aria-labelledby="dialog-title"
                classes={{ paper: 'custom-dialog-paper' }} // Applies the custom class to the dialog paper
            >
                <DialogTitle id="dialog-title" className="dialog-title">
                    What is a {chosenBetStyle} bet?
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Typography variant="body1">
                        {getBetText(chosenBetStyle)}
                    </Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button 
                        onClick={() => setWhatIsABetOpen(false)} 
                        className="dialog-close-button"
                    >
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default WhatIsABet;
