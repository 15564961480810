import React, { useState, useEffect } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import AddPropsModal from "./AddPropsModal";
import EditPropsModal from "./EditPropsModal";
import DeletePropsConfirmation from "./DeletePropsConfirmation";

const AddQuickPickProps = ({ selectedWeek, selectedSeason }) => {
    const { quickPickNFL, leagueState } = useAdminDashContext();
    const [selectedGame, setSelectedGame] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [deletePropOpen, setDeletePropOpen] = useState(false);
    const [propToDelete, setPropToDelete] = useState(null);  // Track prop to delete
    const [confirmClear, setConfirmClear] = useState(false);
    const [propToEdit, setPropToEdit] = useState('');  // Track prop to edit
    const [propTypeToEdit, setPropTypeToEdit] = useState('');  // Track prop type to edit

    const propFormat = [
        {
            propType: "gameProps", 
            createdProps: []
        },
        {
            propType: "homeProps",
            createdProps: []
        },
        {
            propType: "awayProps",
            createdProps: []
        },
        {
            propType: "tiebreakers",
            createdProps: []
        }
    ];

    const [propsToAdd, setPropsToAdd] = useState(propFormat);
    console.log("Props To Add:", propsToAdd);

    const thisWeeksGames = quickPickNFL?.filter((game) => game.Week === selectedWeek) || [];
    console.log("This Week's Games", thisWeeksGames);

    useEffect(() => {
        console.log("Resetting Selected Game");
        // Reset selected game when the selected week changes
        setSelectedGame(null);
    }, [selectedWeek]);

    const handleAddGame = (game) => {
        console.log("Adding Game", game);
        setIsModalOpen(true); // Open modal when adding game
    };

    const handleGameSelect = (event) => {
        const gameId = event.target.value;
        const game = thisWeeksGames?.find((g) => g.GlobalGameID.toString() === gameId);
        console.log("Event", event.target.value);
        console.log("Selected Game", game);
        setSelectedGame(game || null);
    };
    
    const formatGameDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    const handleDeleteClick = (prop, propGroupType) => {
        setPropToDelete({ ...prop, propType: propGroupType });  // Set prop to delete
        setDeletePropOpen(true); // Open delete confirmation modal
    };

    const handleClearProps = () => {
        console.log("Clearing Props");
        setConfirmClear(true); // Set confirmClear to true
        setDeletePropOpen(true); // Open delete confirmation modal
    };

    const handleSaveProps = () => {
        const updatedGameInfo = {
            season: selectedSeason,
            league: leagueState,
            globalGameID: selectedGame.GlobalGameID,
            propData: propsToAdd,
        }
        console.log("Updated Game Info", updatedGameInfo);
        // Save the props to the selected game

        const intervalForMongo = {
            interval: selectedWeek,
            propIdsToUse: propsToAdd.map((group) => group.createdProps.map((prop) => prop.id)).flat()
        }
        console.log("Interval for Mongo", intervalForMongo);
    }

    const isPropsToAddEmpty = propsToAdd?.every((prop) => prop.createdProps.length === 0);
    
    console.log("Props To Add Empty?", isPropsToAddEmpty);


    const handleEditClick = (prop, propGroup) => {
        // Logic to open the modal and populate it with the prop for editing
        console.log("Editing Prop", prop, propGroup);
        const propType = propGroup.propType;
        setPropToEdit(prop);  // Set the prop to edit
        setPropTypeToEdit(propType);  // Set the prop type to edit
        setIsEditModalOpen(true); // Open the modal for editing
    };

    const handleMoveUp = (propType, index) => {
        setPropsToAdd((prevProps) => 
            prevProps.map((propGroup) => {
                if (propGroup.propType === propType) {
                    const newProps = [...propGroup.createdProps];
                    const [movedProp] = newProps.splice(index, 1);
                    newProps.splice(index - 1, 0, movedProp);
                    return { ...propGroup, createdProps: newProps };
                }
                return propGroup;
            })
        );
    };
    
    const handleMoveDown = (propType, index) => {
        setPropsToAdd((prevProps) => 
            prevProps.map((propGroup) => {
                if (propGroup.propType === propType) {
                    const newProps = [...propGroup.createdProps];
                    const [movedProp] = newProps.splice(index, 1);
                    newProps.splice(index + 1, 0, movedProp);
                    return { ...propGroup, createdProps: newProps };
                }
                return propGroup;
            })
        );
    };
      

    return (
        <div>
            {/* Game Selection and Details */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label htmlFor="game-select" style={{ marginRight: '10px' }}>
                    Game:
                </label>
                <select 
                    id="game-select" 
                    value={selectedGame ? selectedGame.GlobalGameID : ""}
                    onChange={handleGameSelect}
                    style={{ padding: '5px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        {thisWeeksGames.length === 0 ? "No Games" : "Select Game"}
                    </option>
                    {thisWeeksGames.map((game) => (
                        <option key={game.GlobalGameID} value={game.GlobalGameID}>
                            {game.AwayTeam} at {game.HomeTeam}
                        </option>
                    ))}
                </select>
            </div>

            {selectedGame && (
                <div style={{ marginBottom: '10px', maxWidth: '800px', margin: '0 auto' }}>
                    {/* Existing Game Details and Props Section */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button 
                            onClick={() => handleAddGame(selectedGame)} 
                            style={{ 
                                marginLeft: '10px', 
                                padding: '5px 10px', 
                                borderRadius: '4px',
                                border: '1px solid #ccc', 
                            }}
                        >
                            Add Props
                        </button>
                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <div>{formatGameDate(selectedGame.Day)}</div>
                            <div>{selectedGame.AwayTeam} at {selectedGame.HomeTeam}</div>
                        </div>
                    </div>

                    <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                        {/* First Props Display */}
                        {["tiebreakers", "gameProps", "homeProps", "awayProps"].map((propType) => (
                            selectedGame.props?.[propType]?.length > 0 && (
                                <div style={{ marginBottom: '10px' }} key={propType}>
                                    <h4 style={{ fontWeight: 'bold' }}>
                                        {propType.charAt(0).toUpperCase() + propType.slice(1)}
                                    </h4>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        {selectedGame.props[propType].map((prop) => (
                                            <li key={prop.id}>- {prop.propText}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            )}

            {/* Second Display of propsToAdd with Close Button */}
            <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                <h3>New Props to Add</h3>
                {propsToAdd.map((propGroup) => (
    <div key={propGroup.propType} style={{ marginBottom: '10px' }}>
        <h4 style={{ fontWeight: 'bold' }}>
            {propGroup.propType.charAt(0).toUpperCase() + propGroup.propType.slice(1)}
        </h4>
        <ul style={{ paddingLeft: '20px' }}>
            {propGroup.createdProps.map((prop, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flexGrow: 1 }}>{prop.propText}</span>
                    
                    {/* Up Arrow Button */}
                    {index > 0 && (
                        <button
                            onClick={() => handleMoveUp(propGroup.propType, index)}
                            style={{
                                background: 'none',
                                border: '1px solid #002129',
                                padding: '2px 5px',
                                color: 'green',
                                cursor: 'pointer',
                                fontSize: '16px',
                                marginRight: '5px'
                            }}
                        >
                            ↑
                        </button>
                    )}
                    
                    {/* Down Arrow Button */}
                    {index < propGroup.createdProps.length - 1 && (
                        <button
                            onClick={() => handleMoveDown(propGroup.propType, index)}
                            style={{
                                background: 'none',
                                border: '1px solid #002129',
                                padding: '2px 5px',
                                color: 'green',
                                cursor: 'pointer',
                                fontSize: '16px',
                                marginRight: '20px'
                            }}
                        >
                            ↓
                        </button>
                    )}
                    
                    <button 
                        onClick={() => handleDeleteClick(prop, propGroup.propType)} 
                        style={{ 
                            background: 'none', 
                            border: '1px solid #002129', 
                            padding: '2px 5px',
                            color: 'red', 
                            cursor: 'pointer', 
                            fontSize: '16px',
                            marginRight: '20px'
                        }}
                    >
                        x
                    </button>
                    <button 
                        onClick={() => handleEditClick(prop, propGroup)} 
                        style={{ 
                            background: 'none', 
                            border: '1px solid #002129', 
                            padding: '2px 5px',
                            color: 'blue', 
                            cursor: 'pointer', 
                            fontSize: '16px',
                            marginRight: '10px'
                        }}
                    >
                        Edit
                    </button>
                </li>
            ))}
        </ul>
    </div>
))}
                {!isPropsToAddEmpty && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button 
                            onClick={() => handleClearProps()} 
                            disabled={isPropsToAddEmpty}
                            style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }}
                        >
                            Clear Props
                        </button>
                        <button 
                            onClick={() => handleSaveProps()}
                            disabled={isPropsToAddEmpty} 
                            style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", marginLeft: '35px' }}
                        >
                            Save Props
                        </button>
                        
                    </div>
                )}
            </div>

            {/* Render the modal and pass props */}
            <AddPropsModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                propsToAdd={propsToAdd}
                setPropsToAdd={setPropsToAdd}
                propToEdit={propToEdit}
                setPropToEdit={setPropToEdit}
                setPropTypeToEdit={setPropTypeToEdit}
                propTypeToEdit={propTypeToEdit}
            />

            {/* Render the edit modal and pass props */}
            <EditPropsModal 
                isOpen={isEditModalOpen} 
                onClose={() => setIsEditModalOpen(false)} 
                propToEdit={propToEdit}
                setPropToEdit={setPropToEdit}
                propTypeToEdit={propTypeToEdit}
                setPropTypeToEdit={setPropTypeToEdit}
                propsToAdd={propsToAdd}
                setPropsToAdd={setPropsToAdd}
            />

            {/* Render the delete confirmation modal */}
            <DeletePropsConfirmation 
                isOpen={deletePropOpen} 
                onClose={() => setDeletePropOpen(false)} 
                prop={propToDelete}  // Pass the selected prop to delete
                propsToAdd={propsToAdd}
                setPropsToAdd={setPropsToAdd}
                propFormat={propFormat}
                confirmClear={confirmClear}
                setConfirmClear={setConfirmClear}
            />
        </div>
    );
}

export default AddQuickPickProps;
