import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useQuickPickCFBContext } from "./context/QuickPickCFBContext";
import GameRulesPage from "../../GameRulesPage/GameRulesPage";
import GameHomePageNew from "../../GameHomePage/GameHomePageNew";
import GameSponsorHeader from "../../PoolHeaderBar/GameSponsorHeader";
import DynamicPoolHeaderBar from "../../PoolHeaderBar/DynamicPoolHeaderBar";
import RewardsRedeemHeader from "../Rewards/RewardsRedeemHeader";
import QuickPickNav from "../QuickPickNav/QuickPickNav";
import SponsorTabOld from "../../SponsorTab/SponsorTabOld";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import { DateTime } from "luxon";
import QuickPickUserDash from "../UserDash/QuickPickUserDash";
import QuickPickPickStats from "../PickStats/QuickPickPickStats";
import QuickPickLeaderboard from "../Leaderboard/QuickPickLeaderboard";
import QuickPickRewards from "../Rewards/QuickPickRewards";
import MyPicks from "../MyPicks/MyPicks";
import TiebreakerGameButtons from "../TiebreakerGameButtons/TiebreakerGameButtons";
import TiebreakerPartyDropdown from "../../PartyDropdown/TiebreakerPartyDropdown";
import ReferralsLeaderboard from "../../ReferralsLeaderboard/ReferralsLeaderboard";
import Top3Display from "../../ChampionDisplay/Top3Display";
import GameLogoDisplay from "../../GameHomePage/GameHomePageComponents/GameLogoDisplay";
import Top3DisplayNew from "../../ChampionDisplay/Top3DisplayNew";


const QuickPickCFBContainer = () => {
  const {
    pickState,
    setPickState,
    clickState,
    setClickState,
    interval,
    setInterval,
    signUpState,
    setSignUpState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    setDefaultPartyId,
    defaultPartyName,
    setDefaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    joinOpen,
    setJoinOpen,
    gameAddress,
    gameData,
    myUserId,
    mainGameId,
    userSubgroups,
    doesUserBelong,
    currentInterval,
    firstInterval,
    lastInterval,
    gameType,
    isGameOver,
    hasJoinDeadlinePassed,
    gameName,
    myUsername,
    userPicksFromDB,
    cfbCurrentWeek,
    cfbTeams,
    cfbGamesByWeekData,
    cfbNames,
    cfbPostseasonScheduleData,
    quickPickCFBGames,
    gatherGameInfo,
    gatherDisplayBarInfo,
    teamsToInclude,
    gamesToInclude,
    gamePropsToUse,
    leaderboardInfo,
    userData,
    handleCreateSubgroup,
    picksPerInterval,
    usingTiebreaker,
    league,
    intervalType,
    currentPropIds,
    propIdsToUse,
    rewards,
    joinReward,
    gameRewards,
    formattedGameStartTime,
    howManyWinners,
    oneWeekEvent,
    tiebreakerInfo,
    joinModalText,
    byeWeek,
    countdownDeadline,
    countdownTimer,
    countdownMessage,
    customBackgroundColor,
    customTextColor,
    customBorderColor,
    myPicksDesktopImage,
    myPicksMobileImage,
    amIOptedInToTexts,
    referralPrizes,
    countdownBackground,
    countdownText,
    gameSchedule,
    isTiebreakerGame,
    tiebreakerGamesInfo,
    myTiebreakerGames,
    gameMembersData,
    gameReferrals,
    useColorsForHeader,
    showMembersPage,
} = useQuickPickCFBContext();
  //console.log("My Tiebreaker Games", myTiebreakerGames, "User Subgroups", userSubgroups, "Game Members Data", gameMembersData);
  const { component, pageState, optionalState } = useParams();
  //console.log("Page State", pageState, "Optional State", optionalState);
  //console.log("Party State in Container", partyState, "Party ID in Container", partyId);

  const navigateToTop = useNavigateToTop();
  const location = useLocation();

  const [referralId, setReferralId] = useState(null);
  const [newSignUp, setNewSignUp] = useState(false);

  let gameFilters = gameMembersData || [];

  const isPartyInGameFilters = gameFilters?.some(filter => filter.id === partyId);
  //console.log("Is Page State in Game Filters", isPartyInGameFilters, gameFilters, partyId);

  // Use Effect for Referral ID and New Sign Up
  useEffect(() => {
    //console.log("Running Referral Use Effect")
    const queryParams = new URLSearchParams(location.search);
    const referralId = queryParams.get('referralId');
    const newSignUp = queryParams.get('signUp');
    if (referralId) {
      setReferralId(referralId);
    }
    if (newSignUp) {
      setNewSignUp(true);
    }
  }, [location.search]);

  useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const fromJoinCard = queryParams.get('fromJoinCard');
        if (fromJoinCard && ((hasJoinDeadlinePassed && !doesUserBelong) || isGameOver)) {
            navigateToTop(`/`);
        }
    }, [location.search, hasJoinDeadlinePassed, doesUserBelong, isGameOver, navigateToTop]);

  const timeNowInNewYork = DateTime.now().setZone('America/New_York');
  const anyUnredeemedRewards = rewards?.some(reward => 
    !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
  );

  
  const [leaderboardData, setLeaderboardData] = useState(leaderboardInfo);
  
  const findRankings = (userEntry, id) => {
    const ranking = userEntry.rankings.find(r => r.gameId === id); // Find the matching ranking
    return ranking ? ranking.rank : null; // Return the rank if found, otherwise null
  };


  useEffect(() => {
    //console.log("Leaderboard Setting")
    if (leaderboardData && partyId === mainGameId) {
      //console.log("Setting Leaderboard Data")
      setLeaderboardData(leaderboardInfo);
    } else if (leaderboardData && partyId !== mainGameId) {
      //console.log("Setting Leaderboard Data for Party")
      const partyMembers = gameMembersData?.find(filter => filter.gameId === partyId)?.members;
      //console.log("Party Members", partyMembers);
      const partyLeaderboardData = leaderboardInfo?.filter(user => partyMembers?.includes(user.userId));
      setLeaderboardData(partyLeaderboardData);
    }
  }, [leaderboardInfo, partyId, mainGameId, gameMembersData]);

  const lowestRankOnLeaderboardOverall = leaderboardData?.reduce((lowest, data) => {
    // If the current data.rank is lower than the lowest.rank, update the lowest object
    if (data.rank > lowest.rank) {
      return { rank: data.rank, totalPoints: data.totalPoints };
    }
    return lowest;
  }, { rank: 1, totalPoints: 0 }); // Changed initial rank from 0 to 1
  
  //console.log("Lowest Rank on Leaderboard", lowestRankOnLeaderboardOverall);
  
  const howManyUsersWithLowestRankOverall = leaderboardData?.filter(
    data => data.rank === lowestRankOnLeaderboardOverall?.rank
  )?.length || 0;
  
  //console.log("How Many Users with Lowest Rank", howManyUsersWithLowestRankOverall);
  
  const rankForUsersWithoutRankOverall = !leaderboardData?.length ? 1 : 
    (lowestRankOnLeaderboardOverall?.totalPoints === 0 ? 
      lowestRankOnLeaderboardOverall?.rank : 
      lowestRankOnLeaderboardOverall?.rank + howManyUsersWithLowestRankOverall);
  
  //console.log("Rank for Users Without Rank", rankForUsersWithoutRankOverall, "Lowest Points on Leaderboard", lowestRankOnLeaderboardOverall?.totalPoints);

  
    //console.log("RANKED DATA in Conttainer", leaderboardForRanking);
    const myRank = leaderboardInfo?.find(user => user.userId === myUserId)?.rank || rankForUsersWithoutRankOverall;
    const amITied = leaderboardInfo?.some(user => user.rank === myRank && user.userId !== myUserId); 
    const totalEntries = leaderboardInfo?.length;
    //console.log("My Rank in Container", myRank, "Am I tied:", amITied, "Total Entries in Container", totalEntries);

    const getRankedUsernames = (users = [], partyId) => {
      // Helper function to get the current rank for a specific partyId
      const getCurrentRank = (rankings = [], partyId) => {
        const ranking = rankings?.find(r => r.gameId === partyId);
        return ranking ? ranking.currentRank : null; // Return the rank if found, otherwise null
      };
    
      // Filter and group users based on their current rank for the given partyId
      const rankedUsernames = {
        first: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 1)
          .map(user => user.username),
        second: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 2)
          .map(user => user.username),
        third: users
          .filter(user => getCurrentRank(user.rankings, partyId) === 3)
          .map(user => user.username),
      };
    
      return rankedUsernames;
    };  
  
    const winners = getRankedUsernames(leaderboardInfo, mainGameId);
    //console.log("Winners Container", winners);

    const whosInFirst = leaderboardData[0]?.username || null;
    const whosInFirstCharacterCount = whosInFirst?.length || null;
    const whosInSecond = leaderboardData[1]?.username || null;
    const whosInSecondCharacterCount = whosInSecond?.length || null;
    const whosInThird = leaderboardData[2]?.username || null;
    const whosInThirdCharacterCount = whosInThird?.length || null;

  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  const logo = gameData?.clubLogo;
  const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
  const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
  const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
  //console.log("Sponsor Header Logo", sponsorHeaderLogo);
  const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
  const sponsorName = gameData?.sponsorInfo?.sponsorName;
  const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
  const hideSponsorInPoolHall = gameData?.sponsorInfo?.hideSponsorInPoolHall;
  const textOptInMessage = gameData?.gameParameters[0]?.textOptInMessage;
  //console.log("Text Opt In Message", textOptInMessage);

  const selectedGames = cfbPostseasonScheduleData?.filter(game => game.Week === interval && quickPickCFBGames?.some(quickPickGame => quickPickGame.GlobalGameID === game.GlobalGameID));
  const selectedQuickPickGames = quickPickCFBGames?.filter(game => game.Week === interval);
  
  function getVoidedPicksIds(games) {
    const voidedIds = [];

    games?.forEach(game => {
        const propCategories = ['tiebreakers', 'gameProps', 'homeProps', 'awayProps'];

        propCategories?.forEach(category => {
            if (game?.props[category]) {
                game?.props[category].forEach(prop => {
                    if (prop?.correctOption === 'Void') {
                        voidedIds.push(prop.id);
                    }
                });
            }
        });
    });

    return voidedIds;
  }

  const voidedPicksIds = getVoidedPicksIds(selectedQuickPickGames);
  const voidedPicksCount = voidedPicksIds?.length;
  //console.log("Voided Picks Ids: ", voidedPicksIds, "Length: ", voidedPicksCount);
  
  
  const [unsavedPicks, setUnsavedPicks] = useState(false);
  //console.log("Unsaved Picks", unsavedPicks);

  useEffect(() => {
    //console.log("Running Use Effect, resetting unsaved Picks");
    if (mainGameId) {
    setUnsavedPicks(false);
    }
  }, [mainGameId]);





  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setInterval(currentInterval);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    // if (click === 'MY_PARTY' && userSubgroups.length === 0) {
    //   pageStateToUse = 'NO_PARTY';
    // } else if (click === 'MY_PARTY' && partyId === mainGameId) {
    //   if (defaultPartyName !== 'Pool Party') {
    //     pageStateToUse = defaultPartyId;
    //     setPartyState(defaultPartyName);
    //     setPartyId(defaultPartyId);
    //   } else {
    //     pageStateToUse = firstSubgroupId;
    //     setPartyState(firstSubgroupName);
    //     setPartyId(firstSubgroupId);
    //   }
    // }
    if (click === 'REWARDS') {
      pageStateToUse = 'ELIGIBLE';
    }

    if (click === 'PRESENTED_BY') {
      pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation}`;
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }

   //const homePageProps = {gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, isGameOver, hasJoinDeadlinePassed,}
   const homePageProps = { signUpState, setSignUpState, gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners, referralId, countdownTimer, countdownMessage, timerDateTime: countdownDeadline, fullLeaderboard: leaderboardInfo, referralPrizes, amIOptedInToTexts, countdownText, countdownBackground }


   const getLowestRankFromRankings = (leaderboardData, partyId) => {
    return leaderboardData?.reduce((lowest, data) => {
      const userRanking = data.rankings?.find(r => r.gameId === partyId);
      const currentRank = userRanking?.currentRank;
      
      // Skip if no ranking found
      if (!currentRank) return lowest;
  
      // If this is the first valid rank or if it's lower than current lowest
      if (lowest.rank === 0 || currentRank > lowest.rank) {
        return { 
          rank: currentRank, 
          totalPoints: data.totalPoints 
        };
      }
      return lowest;
    }, { rank: 0, totalPoints: 0 });
  };
  
  const lowestRankOnLeaderboard = getLowestRankFromRankings(leaderboardData, partyId);
  
  //console.log("Lowest Rank on Leaderboard", lowestRankOnLeaderboard);
  
  const howManyUsersWithLowestRank = leaderboardData?.filter(data => {
    const userRanking = data.rankings?.find(r => r.gameId === partyId);
    return userRanking?.currentRank === lowestRankOnLeaderboard?.rank;
  })?.length || 0;
  
  //console.log("How Many Users with Lowest Rank", howManyUsersWithLowestRank);
  
  const rankForUsersWithoutRank = lowestRankOnLeaderboard?.totalPoints === 0 
    ? lowestRankOnLeaderboard?.rank 
    : lowestRankOnLeaderboard?.rank + howManyUsersWithLowestRank;
  
  //console.log("Rank for Users Without Rank", rankForUsersWithoutRank, "Lowest Points on Leaderboard", lowestRankOnLeaderboard.totalPoints);

  return (
    <div>
        {sponsorLogo && 
          <GameSponsorHeader 
            sponsorLogo={sponsorHeaderLogo} 
            sponsorBannerText={sponsorBannerText} 
            sponsorName={sponsorName}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
            myUserId={myUserId}
            setJoinOpen={setJoinOpen}
            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            unsavedPicks={unsavedPicks}
            setUnsavedPicks={setUnsavedPicks}
            userPicksFromDB={userPicksFromDB}
            setPickState={setPickState}
            sponsorAbbreviation={sponsorAbbreviation}
          />
      }

      {anyUnredeemedRewards &&
        <RewardsRedeemHeader
          gameAddress={gameAddress}
          mainGameId={mainGameId}
        />
      }

      <DynamicPoolHeaderBar
        clickState={clickState}
        navigationText={`Home`}
        headerText={gameName}
        gameAddress={gameAddress}
        gameId={mainGameId}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        setPicksToEmpty={false}
        backgroundColor={customBorderColor}
        bottomLineColor={customTextColor}
        useColorsForHeader={useColorsForHeader}
      />

      {(!doesUserBelong) && (
        <GameLogoDisplay
          doesUserBelong={doesUserBelong}
          gameName={gameName}
          logo={logo}
          gameLogoDisplayInfo={gameData?.gameLogoDisplayInfo}
          setJoinOpen={setJoinOpen}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          isHostGame={gameData?.gameParameters[0]?.isHostGame}
          affiliatedGamesInfo={gameData?.gameParameters[0]?.affiliatedGamesInfo}
          gameAddress={gameAddress}
          isTeamGame={gameData?.gameParameters[0]?.isTeamGame}
        />
      )}

      {myUsername && doesUserBelong && 
        <QuickPickUserDash
          currentInterval={currentInterval}
          userPicksFromDB={userPicksFromDB}
          doesUserBelong={doesUserBelong}
          myUsername={myUsername}
          gameData={gameData}
          setJoinOpen={setJoinOpen}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          anyUnredeemedRewards={anyUnredeemedRewards}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          formattedGameStartTime={formattedGameStartTime}
          myRank={myRank}
          amITied={amITied}
          totalEntries={totalEntries}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          isGameOver={isGameOver}
          myUserId={myUserId}
          gameRewards={gameRewards}
        />
      }

      <QuickPickNav
        setClickState={handleClickState}
        clickState={clickState}
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isGameOver={isGameOver}
        userSubgroups={userSubgroups}
        sponsorAbbreviation={sponsorAbbreviation}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
        gameRewards={gameRewards}
        showMembersPage={showMembersPage}
      />

      {isTiebreakerGame && tiebreakerGamesInfo?.length > 0 && myTiebreakerGames?.length > 0 &&
        <TiebreakerGameButtons
          myTiebreakerGames={myTiebreakerGames}
          isTiebreakerGame={isTiebreakerGame}
        />
      }

      {(clickState === 'LEADERBOARD' || clickState === 'POOL_PICKS') && (myTiebreakerGames?.length > 0 || userSubgroups?.length > 0) &&
        <TiebreakerPartyDropdown
          mainGameId={mainGameId}
          userSubgroups={userSubgroups}
          gameName={gameName}
          myTiebreakerGames={myTiebreakerGames}
          partyId={partyId}
          setPartyId={setPartyId}
          partyState={partyState}
          setPartyState={setPartyState}
          gameAddress={gameAddress}
        />
      }

      {isGameOver && (clickState === 'HOME') && (
        // <Top3Display
        //   whosInFirst={whosInFirst}
        //   whosInFirstCharacterCount={whosInFirstCharacterCount}
        //   partyName={sponsorName}
        //   line2={`${gameName} Top 3`}
        //   whosInSecond={whosInSecond}
        //   whosInSecondCharacterCount={whosInSecondCharacterCount}
        //   whosInThird={whosInThird}
        //   whosInThirdCharacterCount={whosInThirdCharacterCount}
        // />
        <Top3DisplayNew
          rankedUsernames={winners}
          partyName={sponsorName}
          line2={`${gameName} Top 3`}
          gameName={gameName}
        />
      )}

      {clickState === 'HOME' && <GameHomePageNew homePageProps={homePageProps} />}

      {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

      {clickState === 'PRESENTED_BY' && <SponsorTabOld gameData={gameData} />}

      {clickState === 'REFERRAL_LEADERBOARD' &&
        <ReferralsLeaderboard
          gameReferrals={gameReferrals}
          myUsername={myUsername}
          referralPrizes={referralPrizes}
          gameAddress={gameAddress}
          mainGameId={mainGameId}
          myUserId={myUserId}
        />
      }

      {clickState === 'POOL_PICKS' &&
        <QuickPickPickStats
          leaderboardInfo={leaderboardData}
          teams={cfbTeams}
          schedule={gameSchedule}
          quickPickGames={quickPickCFBGames}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          currentInterval={currentInterval}
          intervalType={intervalType}
          propIdsToUse={propIdsToUse}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          myUserId={myUserId}
          oneWeekEvent={oneWeekEvent}
          byeWeek={byeWeek}
          userPicksFromDB={userPicksFromDB}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />    
      }

      {clickState === 'LEADERBOARD' &&
        <QuickPickLeaderboard
          mainGameId={mainGameId}
          userPicksFromDB={userPicksFromDB}
          myUserId={myUserId}
          isGameOver={isGameOver}
          currentInterval={currentInterval}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          intervalType={intervalType}
          handleClickState={handleClickState}
          leaderboardData={leaderboardData}
          partyState={partyState}
          teams={cfbTeams}
          schedule={gameSchedule}
          gameAddress={gameAddress}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          oneWeekEvent={oneWeekEvent}
          tiebreakerInfo={tiebreakerInfo}
          gameName={gameName}
          quickPickGames={quickPickCFBGames}
          byeWeek={byeWeek}
          propIdsToUse={propIdsToUse}
          partyId={partyId}
          rankForUsersWithoutRank={rankForUsersWithoutRank}
        />
      }

      {/* {clickState === 'MY_PARTY' && 
        <MyParty
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={leaderboardInfo}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          isGameOver={isGameOver}
        />
      } */}

      {clickState === 'REWARDS' &&
        <QuickPickRewards
          rewards={rewards}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          sponsorHeaderLogo={sponsorHeaderLogo}
          sponsorName={sponsorName}
          intervalType={intervalType}
          schedule={gameSchedule}
          myUserId={myUserId} 
        />
      }

      {clickState === 'MY_PICKS' && 
        <MyPicks
          myUserId={myUserId}
          gameAddress={gameAddress} 
          mainGameId={mainGameId} 
          gameData={gameData} 
          userData={userData} 
          interval={interval} 
          setInterval={setInterval} 
          firstInterval={firstInterval} 
          lastInterval={lastInterval} 
          currentInterval={currentInterval}
          schedule={gameSchedule}
          teams={cfbTeams}
          standings={cfbTeams}
          names={cfbNames}
          quickPickInfo={quickPickCFBGames}
          gatherGameInfo={gatherGameInfo}
          selectedGames={selectedGames}
          currentPropIds={currentPropIds}
          selectedQuickPickGames={selectedQuickPickGames}
          userPicksFromDB={userPicksFromDB}
          pickState={pickState}
          setPickState={setPickState}
          teamsToInclude={teamsToInclude}
          gamePropsToUse={gamePropsToUse}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          league={league}
          gameType={gameType}
          intervalType={intervalType}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          rewards={rewards}
          joinReward={joinReward}
          gameRewards={gameRewards}
          unsavedPicks={unsavedPicks}
          setUnsavedPicks={setUnsavedPicks}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
          customBackgroundColor={customBackgroundColor}
          customTextColor={customTextColor}
          customBorderColor={customBorderColor}
          myPicksMobileImage={myPicksMobileImage}
          myPicksDesktopImage={myPicksDesktopImage}
        />
      }


      {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
            sponsorSecondaryLogo={sponsorSecondaryLogo}
            sponsorName={sponsorName}
            sponsorLogo={sponsorLogo}
            mainGameId={mainGameId}
            propIdsToUse={propIdsToUse}
            joinModalText={joinModalText}
            referralId={referralId}
            newSignUp={newSignUp}
            hideSponsorInPoolHall={hideSponsorInPoolHall}
            textOptInMessage={textOptInMessage}
        />
      )}

    </div>
  );
};

export default QuickPickCFBContainer;