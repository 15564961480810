import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Box } from '@mui/material';

const AcceptAllLineChangesCheckbox = ({ acceptAllChanges, setAcceptAllChanges }) => {

    const handleCheckboxChange = () => {
        setAcceptAllChanges(!acceptAllChanges);
    };

    return (
        <Box sx={{ backgroundColor: '#FFF', borderRight: '1px solid #002129', borderLeft: '1px solid #002129'}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={acceptAllChanges}
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                }
                label="Accept ALL line changes"
            />
        </Box>
    );
};

export default AcceptAllLineChangesCheckbox;
