import React, { useState } from "react";
import { Button, Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from "react-router-dom";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CreatePartyInfo = ({ setSignUpState, gameAddress, doesUserBelong, gameName, setJoinOpen, refreshNeeded, flipColor }) => {
    const navigateToTop = useNavigateToTop();
    const { gameId, component } = useParams();

    const [showMore, setShowMore] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleCreatePartyClick = () => {
        setSignUpState(true);
        setJoinOpen(false);
        if (refreshNeeded === true) {
            window.location.href = `/${encodeURIComponent(gameAddress)}/${encodeURIComponent(gameId)}/${encodeURIComponent('MY_PARTY')}/${encodeURIComponent('MY_PARTY')}`;
        } else {
            navigateToTop(`/${encodeURIComponent(gameAddress)}/${encodeURIComponent(gameId)}/${encodeURIComponent('MY_PARTY')}/${encodeURIComponent('MY_PARTY')}`);
        }       
    }


    return (
        <Box sx={{ textAlign: 'center' }}>
            <Button 
                variant="contained" 
                onClick={handleCreatePartyClick}
                disabled={!doesUserBelong}
                sx={{ 
                    fontSize: '14px', 
                    fontWeight: 700, 
                    width: '225px', 
                    height: '36px', 
                    marginBottom: '8px' 
                }}
            >
                CREATE PRIVATE GROUP
            </Button>

            <Box 
                onClick={() => setShowMore(!showMore)}
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
            >
                <Typography
                    sx={{
                        color: '#00AAD1',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 500,
                        marginRight: '3px'
                    }}
                >
                    {showMore ? 'Show Less' : 'Learn More'}
                </Typography>
                <KeyboardArrowDownIcon 
                    sx={{ 
                        color: '#00AAD1', 
                        fontSize: '14px', 
                        transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease'
                    }} 
                />
            </Box>
            {showMore && (
                <Box sx={{ marginTop: '16px' }}>
                    <Typography
                        sx={{
                            color: flipColor ? '#fff' : '#002129',
                            textAlign: 'center',
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: 700,
                            marginBottom: '14px',
                            textDecorationLine: 'underline'
                        }}
                    >
                        What to Know
                    </Typography>
                    <Box
                        sx={{
                            maxWidth: '625px', // Maximum width
                            margin: '0 auto',
                            padding: '0 32px' 
                        }}
                    >
                        <Typography
                            sx={{
                                color: flipColor ? '#fff' : '#002129',
                                textAlign: 'center',
                                fontSize: isMobile ? '12px' : '14px',
                                fontWeight: 500,
                                marginBottom: '16px'
                            }}
                        >
                            1. You only make one set of picks.  They will apply to all of your Parties.
                        </Typography>
                        <Typography
                            sx={{
                                color: flipColor ? '#fff' : '#002129',
                                textAlign: 'center',
                                fontSize: isMobile ? '12px' : '14px',
                                fontWeight: 500,
                                marginBottom: '16px'
                            }}
                        >
                            2. You can easily switch between pool leaderboards using the Party dropdown.
                        </Typography>
                        <Typography
                            sx={{
                                color: flipColor ? '#fff' : '#002129',
                                textAlign: 'center',
                                fontSize: isMobile ? '12px' : '14px',
                                fontWeight: 500,
                                marginBottom: component === 'MY_PARTY' ? '32px' : '0px'
                            }}
                        >
                            3. Pool Hosts can easily invite new members and promote members to Co-Hosts.
                        </Typography>
                    </Box>
                </Box>
            )}
            {component === 'MY_PARTY' && !showMore &&(
                <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Typography
                    style={{
                        color: flipColor ? '#fff' : '#002129',
                        textAlign: 'center',
                        fontSize: isMobile ? '12px' : '14px',
                        fontWeight: '700',
                        marginTop: '32px',
                        marginBottom: '32px',
                        maxWidth: isMobile ? '311px' : '600px'
                    }}
                >
                    *Note: Your account only gets one set of picks.<br/> The picks you make will apply to all of your<br/> {gameName} competitions.
                </Typography>
            </Box>
            )}
        </Box>
    );
}

export default CreatePartyInfo;
