import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useQuickPickCFBContext } from "./context/QuickPickCFBContext";
import GameRulesPage from "../../GameRulesPage/GameRulesPage";
import GameHomePage from "../../GameHomePage/GameHomePage";
import GameSponsorHeader from "../../PoolHeaderBar/GameSponsorHeader";
import DynamicPoolHeaderBar from "../../PoolHeaderBar/DynamicPoolHeaderBar";


const QuickPickCFBContainer = () => {
    const {setSignUpState, 
        gameAddress, 
        userData, 
        gameData, 
        setInterval, 
        currentInterval, 
        userSubgroups, 
        doesUserBelong,
        myUserId,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen,
        whosInFirst,
        whosInFirstCharacterCount,
        isGameOver,
        hasJoinDeadlinePassed,
        clickState,
        setClickState,
        gameName,
    } = useQuickPickCFBContext();
    const homePageProps = {gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, isGameOver, hasJoinDeadlinePassed,}

  const navigate = useNavigate();
  const location = useLocation();

  const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
  const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
  const sponsorName = gameData?.sponsorInfo?.sponsorName;
  const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;

  return (
    <div>
        Quick Pick CFB page
        {/* {sponsorLogo && 
            <GameSponsorHeader 
              sponsorLogo={sponsorLogo} 
              sponsorBannerText={sponsorBannerText} 
              sponsorName={sponsorName}
              mainGameId={mainGameId}
              gameAddress={gameAddress}
              myUserId={myUserId}
              setJoinOpen={setJoinOpen}
            />
        } */}

        {/* <DynamicPoolHeaderBar
          clickState={clickState}
          navigationText={`Home`}
          headerText={gameName}
          gameAddress={gameAddress}
          gameId={mainGameId}
          setPicksToEmpty={false}
        /> */}

        {/* <GameHomePage homePageProps={homePageProps} /> */}
    </div>
  );
};

export default QuickPickCFBContainer;