// hooks/useJoinGameClick.js
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserAndGameContext } from '../../contexts/UserAndGameContext';
import useNavigateToTop from './useNavigateToTop';

// This hook returns JUST the click handler function
const useJoinGameClick = () => {
  const {
    setJoinOpen,
    myUserId,
    masterPoolId,
    gameId,
    gameAddress
  } = useUserAndGameContext();
  
  const location = useLocation();
  const navigateToTop = useNavigateToTop();
  
  // Return just the handler function directly
  return useCallback(() => {
    if (!myUserId) {
      const currentParams = new URLSearchParams(location.search);
      
      const params = new URLSearchParams();
      params.append('mId', encodeURIComponent(masterPoolId));
      params.append('gId', encodeURIComponent(gameId));
      params.append('page', 'home');
      
      for (const [key, value] of currentParams.entries()) {
        if (!['mId', 'gId', 'page'].includes(key)) {
          params.append(key, value);
        }
      }
      
      const queryString = params.toString();
      const redirectPath = `${encodeURIComponent(gameAddress)}?${queryString}`;
      
      navigateToTop(`/login/${redirectPath}`);
    } else {
      setJoinOpen(true);
    }
  }, [myUserId, masterPoolId, gameId, gameAddress, location.search, navigateToTop, setJoinOpen]);
};

export default useJoinGameClick;