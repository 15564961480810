// DropdownMenu.js

import React, { useEffect, useState } from 'react';

const DropdownMenu = ({ selectedUsername, pickState, seriesLength, onSelectionChange, userData, getBracketPicksByUsername, getSeriesLengthsByUsername }) => {
  console.log("SELECTED USERNAME: ", selectedUsername);

  // Sort the data based on overallRank
  const sortedData = userData?.sort((a, b) => a.stats.overallRank - b.stats.overallRank);
  // Extract the user_name strings
  const usernames = sortedData?.map(entry => entry.user_name);

  // Function to handle selection change
  const handleSelectionChange = (e) => {
    const username = e.target.value;
    const bracketPicks = getBracketPicksByUsername(username);
    const seriesLengths = getSeriesLengthsByUsername(username);
    onSelectionChange(username, bracketPicks, seriesLengths);
    console.log("HANDLE RUNNING IN DROPDOWN MENU: ", username, bracketPicks, seriesLengths);
  };


  // const getBracketPicksByUsername = (username) => {
  //   // Find the user object that matches the username
  //   const user = userData?.find(entry => entry.user_name === username);
  //   // Return the bracket picks of the user if found, otherwise return an empty array
  //   return user ? user.bracketPicks : [];
  // };

  // const getSeriesLengthsByUsername = (username) => {
  //   // Find the user object that matches the username
  //   const user = userData?.find(entry => entry.user_name === username);
  //   // Return the series lengths of the user if found, otherwise return an empty array
  //   return user ? user.seriesLengths : [];
  // };

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', margin:'20px auto', }}>
      <h3 className="font-bold text-lg" style={{marginRight:"10px"}}>Pool Brackets: </h3>
      <select className='border border-solid border-gray-900 box-border rounded text-sm p-1' style={{textAlign:'center', maxWidth: '150px'}} value={selectedUsername} onChange={handleSelectionChange}>
        {/* Option for the selected username (whoseLoggedIn) */}
        <option key={selectedUsername} value={selectedUsername}>{selectedUsername}</option>
        {/* Options for other usernames */}
        {usernames?.map(username => (
          // Skip the selected username since it's already shown
          username !== selectedUsername && (
            <option key={username} value={username}>{username}</option>
          )
        ))}
      </select>
    </div>
  );
};

export default DropdownMenu;