// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  text-align: center;
}

.h1-private-party-invite {
  color: #002129;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.line {
  background-color: #FFC60A;
  height: 4px;
  border: none;
  margin-top: 16px;
}

.typography-main-message {
  color: #292929;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 34px */
  letter-spacing: -0.38px;
}

.typography-club-name {
  color: #292929;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%; /* 34px */
  letter-spacing: -0.38px;
}`, "",{"version":3,"sources":["webpack://./src/components/PrivateParty/PrivatePartyInviteStyles.scss"],"names":[],"mappings":"AAAA;EAGI,kBAAA;AADJ;;AAIE;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAIE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAIE;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADJ;;AAIE;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADJ","sourcesContent":[".container {\n\n    // height: var(--borderRadius, 4px);\n    text-align: center;\n  }\n\n  .h1-private-party-invite {\n    color: #002129;\n    text-align: center;\n    font-family: 'Inter';\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n  }\n\n  .line {\n    background-color: #FFC60A;\n    height: 4px;\n    border: none;\n    margin-top: 16px;\n  }\n\n  .typography-main-message {\n    color: #292929;\n    text-align: center;\n    font-family: 'Inter';\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 170%; /* 34px */\n    letter-spacing: -0.38px;\n  }\n\n  .typography-club-name {\n    color: #292929;\n    text-align: center;\n    font-family: 'Inter';\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 170%; /* 34px */\n    letter-spacing: -0.38px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
