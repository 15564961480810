import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PickRemovedTooLateModal = ({ removeTooLateOpen, setRemoveTooLateOpen, removeTooLateMessage, setRemoveTooLateMessage, week }) => {

    return (
        <Modal
        open={removeTooLateOpen}
        onClose={() => setRemoveTooLateOpen(false)}
        aria-labelledby="remove-pick-modal"
        aria-describedby="remove-pick-modal"
        BackdropProps={{
            onClick: (e) => e.stopPropagation(), // Prevent closing when clicking outside
        }}
        >
        <Box
            sx={{
            position: 'absolute',
            borderRadius: '8px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '328px',
            height: '275px',
            bgcolor: '#FFF',
            padding: '16px',
            outline: 'none',
            }}
        >
            <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
                textAlign: 'center',
                color: '#000000',
                fontSize: '16px',
                fontWeight: '800',
                paddingBottom: '20px',
            }}
            >
            Week {week} Pick NOT Removed
            </Typography>
            <Typography
            id="modal-modal-description"
            sx={{
                textAlign: 'center',
                color: '#000000',
                fontSize: '16px',
                fontWeight: '400',
                paddingBottom: '20px',
                whiteSpace: 'nowrap',
            }}
            >
            Sorry, but you cannot remove<br /> your week {week} pick.
            </Typography>
            <Typography
            id="modal-modal-description"
            sx={{
                textAlign: 'center',
                color: '#000000',
                fontSize: '16px',
                fontWeight: '400',
            }}
            >
            {removeTooLateMessage}
            </Typography>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
            }}
            >
            <CloseIcon 
                sx={{ 
                color: '#CC293C', 
                height: '32px',
                width: '32px',
                }} 
            />
            </Box>
            <Button
            variant='contained'
            onClick={() => {
                setRemoveTooLateOpen(false);
                setRemoveTooLateMessage('');
                window.location.reload(); // This will reload the page
            }}
            sx={{
                width: '115px',
                height: '38px',
                marginTop: '20px',
                marginBottom: '30px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
            >
            Close
            </Button>
        </Box>
        </Modal>
    );
}

export default PickRemovedTooLateModal;