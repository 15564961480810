import React from 'react';
import './superPickemStyles/DateNavBar.scss';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const DateNavBar = ({ dateState, setDateState, dateForDisplay, today}) => {
    const lowerBound = new Date('2024-04-20');
    // Calculate upper bound as today + 2 days, capped at June 23
    const upperBound = new Date();
    upperBound.setDate(upperBound.getDate() + 2);
    const april22 = new Date('2024-04-22');
    const june23 = new Date('2024-06-23');
    const cappedUpperBound = upperBound > june23 ? june23 : (upperBound < april22 ? april22 : upperBound);
    const handleDateChange = (direction, currentDate) => {
        const newDate = new Date(currentDate);
        // Define lower bound
        const lowerBound = new Date('2024-04-20');
        if (direction === 'prev') {
            newDate.setDate(currentDate.getDate() - 1);
        } else {
            newDate.setDate(currentDate.getDate() + 1);
        }
        // Calculate upper bound as today + 2 days, capped at June 23
        const upperBound = new Date(currentDate);
        upperBound.setDate(currentDate.getDate() + 2);
        const april22 = new Date('2024-04-22');
        const june23 = new Date('2024-06-23');
        const cappedUpperBound = upperBound > june23 ? june23 : (upperBound < april22 ? april22 : upperBound);
        // Check against the lower and capped upper bounds
        if (newDate >= lowerBound && newDate <= cappedUpperBound) {
            setDateState(newDate.toISOString().split('T')[0]);
        }
    };
    return (
        <div className="game-date-navigation-bar">
            <button className="navigation-element-icon" onClick={() => handleDateChange('prev', new Date(dateState))} disabled={new Date(dateState) <= lowerBound}><ArrowBackIosNewIcon/></button>
            <span className="date">{dateForDisplay}</span>
            <button className="navigation-element-icon1" onClick={() => handleDateChange('next', new Date(dateState))} disabled={new Date(dateState) >= cappedUpperBound}><ArrowBackIosNewIcon/></button>
        </div>
    );
};
export default DateNavBar;