import './Top3Display.scss';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import BronzeTrophy from '../../assets/images/BronzeTrophy.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Top3Display = ({
  whosInFirst,
  whosInFirstCharacterCount,
  whosInSecond,
  whosInSecondCharacterCount,
  whosInThird,
  whosInThirdCharacterCount,
  partyName,
  line2
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={`top-three-display ${isMobile ? 'top-three-display--mobile' : ''}`}>
      {/* Header */}
      <Box className="top-three-display-header">
            <Typography 
                className={`top-three-display-header__text ${isMobile ? 'top-three-display-header__text--mobile' : ''}`}
                sx={{ marginBottom: isMobile ? '16px' : '0px' }}
            >
                {partyName && (
                <>
                    {partyName}'s <br />
                </>
                )}
                {line2}
            </Typography>
        </Box>


      {/* 1st Place */}
        <Box className="top-three-display-body">
            <Box className="top-three-display-content">
                <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image" />
                <Box className="top-three-display-text">
                    <Typography 
                        className={`top-three-display-text__title ${isMobile
                        ? 'top-three-display-text__title--mobile'
                        : 'top-three-display-text__title'
                        }`}
                    >
                        Champion
                    </Typography>
                    <Typography
                    className={`top-three-display-text__title ${isMobile
                    ? whosInFirstCharacterCount > 17
                        ? 'top-three-display-text__title--small-mobile'
                        : 'top-three-display-text__title--mobile'
                    : 'top-three-display-text__title'
                    }`}
                >
                    {whosInFirst}
                </Typography>
                </Box>
                <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image" />
            </Box>
        </Box>



      {/* 2nd Place */}
        {whosInSecond && whosInSecond !== undefined && (
            <Box className="top-three-display-body">
                <Box className="top-three-display-content top-three-display-content--second">
                    <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image top-three-display-image--second" />
                    <Box className="top-three-display-text top-three-display-text--second">
                        <Typography
                            className={`top-three-display-text__title top-three-display-text__title--second ${isMobile
                            ? 'top-three-display-text__title--mobile'
                            : 'top-three-display-text__title--second'
                            }`}
                        >
                            2nd Place
                        </Typography>
                        <Typography
                            className={`top-three-display-text__title top-three-display-text__title--second ${isMobile
                                ? whosInSecondCharacterCount > 17
                                ? 'top-three-display-text__title--small-mobile'
                                : 'top-three-display-text__title--mobile'
                                : 'top-three-display-text__title--second'}`}
                        >
                        {whosInSecond}
                        </Typography>
                    </Box>
                    <Box component="img" src={trophyImage} alt="Trophy Image" className="top-three-display-image top-three-display-image--second" />
                </Box>
            </Box>
        )}


      {/* 3rd Place */}
      {whosInThird && whosInThird !== undefined && (
            <Box className="top-three-display-body--third">
                <Box className="top-three-display-content top-three-display-content--third">
                <Box component="img" src={BronzeTrophy} alt="Trophy Image" className="top-three-display-image top-three-display-image--third" />
                <Box className="top-three-display-text top-three-display-text--third">
                        <Typography
                        className={`top-three-display-text__title top-three-display-text__title--third ${isMobile
                            ? 'top-three-display-text__title--mobile'
                            : 'top-three-display-text__title--third'}`}
                        >
                        3rd Place
                        </Typography>
                        <Typography
                            className={`top-three-display-text__title top-three-display-text__title--third ${isMobile
                                ? whosInThirdCharacterCount > 17
                                ? 'top-three-display-text__title--small-mobile'
                                : 'top-three-display-text__title--mobile'
                                : 'top-three-display-text__title--third'}`}
                        >
                            {whosInThird}
                        </Typography>
                </Box>
                <Box component="img" src={BronzeTrophy} alt="Trophy Image" className="top-three-display-image top-three-display-image--third" />
                </Box>
            </Box>
      )}
    </Box>
  );
};

export default Top3Display;
