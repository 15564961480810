import React from "react";
import { Box, Modal, Button, Typography } from "@mui/material";
import bracketTrophy from "../../../../assets/images/bracketTrophy.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SurvivorUserStatsPickBox from "./SurvivorUserStatsPickBox";
import NFLTeamsByConference from "./NFLTeamsByConference";
import SurvivorTeamsAvailable from "./SurvivorTeamsAvailable";
import { usePrevious } from "@react-pdf-viewer/core";

const SurvivorUserStatsModal = ({ statsModalOpen, setStatsModalOpen, myUsername, doesUserHaveALoss, lossWeek, startingWeekState, currentWeeksDeadlineDateTime, NFLTeams, areTheseMyStats, usersPicks, firstWeek }) => {
    //console.log("Are These My Stats: ", areTheseMyStats, usersPicks);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => setStatsModalOpen(false);

    return (
        <Modal
            open={statsModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}    
        >
            <Box sx={{
                display: 'flex',
                width: isMobile ? '359px' : '1115px',
                height: isMobile ? 'auto' : '885px',
                maxHeight: isMobile ? '635px' : '997px',
                padding: '16px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                borderRadius: '8px',
                backgroundColor: '#FFF',
                boxShadow: '2px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25), -2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                outline: 'none',
            }}>
                <Box sx={{ height: isMobile ? '50px' : '140px' }}> {/* Replace with your height logic */}
                    <Box className="user-stats-header-trophy" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <img className="user-stats-trophy" src={bracketTrophy} alt='' />
                        <Typography className="user-stats-username" variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
                            {myUsername}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',  // Center horizontally
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            sx={{
                                fontSize: isMobile ? '16px' : '24px',
                                fontWeight: 800,
                                marginRight: '8px' // Space between text items
                            }}
                        >
                            STATUS:
                        </Typography>
                        <Typography 
                            variant="h6"
                            sx={{
                                fontSize: isMobile ? '16px' : '24px',
                                fontWeight: 800,
                                color: doesUserHaveALoss ? '#CC293C' : '#00AA72'
                            }}
                        >
                            {doesUserHaveALoss ? `ELIMINATED - WEEK ${lossWeek}` : 'ALIVE'}
                        </Typography>
                    </Box>
                </Box>
                <SurvivorUserStatsPickBox 
                    startingWeekState={startingWeekState} 
                    usersPicks={usersPicks} 
                    NFLTeams={NFLTeams} 
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime} 
                    areTheseMyStats={areTheseMyStats}
                    firstWeek={firstWeek}
                />
                <SurvivorTeamsAvailable 
                    usersPicks={usersPicks} 
                    startingWeekState={startingWeekState}
                    areTheseMyStats={areTheseMyStats}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                />
                <Button variant="contained" onClick={() => setStatsModalOpen(false)}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default SurvivorUserStatsModal;