import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { usePrivatePartyContext } from "./context/PrivatePartyContext";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './PrivatePartyInviteStyles.scss';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";


const AlreadyInPrivateParty = () => {
  const { partyData, joinParty, inviteeDisplayName, clubLogo, foundSubgroupName, privatePartyId, clubDisplayName, poolStartDate, poolEndDate, gameId, gameAddress, gameType } = usePrivatePartyContext();

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigate = () => {
    if (gameType === "BirdiePool") {
      navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(foundSubgroupName)}`);
    } else {
      navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/MY_PARTY/${uriEncoded(privatePartyId)}`);
    }
  };

  return (
    <Box className="container" style={{ maxWidth: '1116px', margin: '0 auto'}}>
        <Typography className="typography-main-message" style={{ padding: '15px 0', fontSize: isMobile ? '20px' : '24px', fontWeight: '700', maxWidth: '90%', margin: '0 auto'}}>
            You are already a member of The {foundSubgroupName} Party.
        </Typography>
            <img src={clubLogo} alt="club logo" className="club-logo" style={{ display: 'block', margin: '0 auto', width: isMobile ? '94px' : '150px' }}/>
        <Box sx={{ display: 'block', mt: 2 }}>  {/* Added Box to wrap the button and adjusted the margin-top */}
            <Button variant="contained"  style={{ fontSize: isMobile ? '16px' : '20px', marginTop: '10px'}} onClick={() => handleNavigate()}>
                ENTER PARTY
            </Button>
        </Box>
     </Box>

  );
};

export default AlreadyInPrivateParty;
