import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetReceipt.scss';

const BetReceiptDataBox = ({ betReceiptInfo }) => {

    //console.log("BetReceiptInfo in BetReceiptDataBox: ", betReceiptInfo);
    const totalWager = betReceiptInfo?.totalWagerAmount?.toFixed(2);
    const totalToWin = betReceiptInfo?.totalToWinAmount?.toFixed(2);
    const totalPayout = (Number(totalWager) + Number(totalToWin))?.toFixed(2);

    return (
        <>
            <Box className="sportsbook-bet-receipt-data-box-container">
                <Typography className="sportsbook-bet-receipt-data-box-text">Wager</Typography>
                <Typography className="sportsbook-bet-receipt-data-box-text">{totalWager}</Typography>
            </Box>
            <Box className="sportsbook-bet-receipt-data-box-container">
                <Typography className="sportsbook-bet-receipt-data-box-text">To Win</Typography>
                <Typography className="sportsbook-bet-receipt-data-box-text">{totalToWin}</Typography>
            </Box>
            <Box className="sportsbook-bet-receipt-data-box-container">
                <Typography className="sportsbook-bet-receipt-data-box-text">Total Payout</Typography>
                <Typography className="sportsbook-bet-receipt-data-box-text">{totalPayout}</Typography>
            </Box>
        </>
    );
}

export default BetReceiptDataBox;