import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuickPickNoGamesCard from '../CardPieces/QuickPickNoGamesCard';
import { DateTime } from 'luxon';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

const PlayerPicks = ({ leaderboardInfo, selectedQuickPickGames, currentPropIds, schedule, sponsorName, teamName, league, myUserId }) => {
  //console.log("Leaderboard Info PLAYER PICKS", leaderboardInfo); 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // Extract headers (propId and tableHeader) from selectedQuickPickGames
  let columnHeaders = [];
  selectedQuickPickGames?.forEach((game) => {
    const gameProps = game?.props?.gameProps || [];
    const homeProps = game?.props?.homeProps || [];
    const awayProps = game?.props?.awayProps || [];
    const tiebreakerProps = game?.props?.tiebreakers || [];
    const allProps = [...gameProps, ...homeProps, ...awayProps, ...tiebreakerProps];

    // Filter allProps to only include those that match currentPropIds
    const filteredProps = allProps.filter(prop => prop && currentPropIds.includes(prop.id));

    // Sort and remove any null/undefined values in the final sortedProps
    const sortedProps = currentPropIds
      .map(id => filteredProps.find(prop => prop?.id === id))
      .filter(Boolean); // This will filter out both null and undefined


    sortedProps?.forEach((prop) => {
      const propId = prop?.id;
      const propHeader = prop?.tableHeader;
      const line = prop?.line;
      const options = prop?.options;
      const optionsAbbrev = prop?.optionsAbbrev;
      const correctOption = prop?.correctOption;
      if (propId && propHeader) {
        columnHeaders.push({ propId, propHeader, line, options, optionsAbbrev, correctOption });
      }
    });
  });

  //console.log("Test Column Headers", columnHeaders);

  const timeNowInNewYork = DateTime.now().setZone('America/New_York');
  const hasThisGameStarted = (gameId) => {
    const thisGamesId = gameId[0];
    const gameInfo = schedule.find(game => game.GlobalGameID === thisGamesId);
    const gameTime = gameInfo?.DateTime;
    const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
    //console.log("Game Date Time", gameDateTime, "Time Now in NY", timeNowInNewYork, "Has Game Started?", timeNowInNewYork > gameDateTime);
    return timeNowInNewYork > gameDateTime;
  }

  const thisGamesIds = selectedQuickPickGames?.map(game => game.GlobalGameID);
  const hasGameStarted = hasThisGameStarted(thisGamesIds);
  //console.log("Has Game Started", hasGameStarted, thisGamesIds);

  const userPicks = leaderboardInfo?.find(user => user.userId === myUserId);
  //console.log("User Picks", userPicks);

  function getAbbreviatedOption(option) {
    for (const prop of columnHeaders) {
        // Ensure that prop and its properties are defined
        if (prop && Array.isArray(prop.options)) {
            const index = prop.options.indexOf(option);
            // Check if the index is valid
            if (index !== -1) {
                // Return optionsAbbrev if it exists; otherwise, return the original option
                return Array.isArray(prop.optionsAbbrev) ? prop.optionsAbbrev[index] : prop.options[index];
            }
        }
    }
    // Log an error message if the option is not found
    //console.error(`Option "${option}" not found in columnHeaders.`);
    return null; // Return null if the option is not found
  }

  function getRank(n) {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = n % 100;

    // Check for the special cases of 11, 12, and 13
    const suffix = (value >= 11 && value <= 13) ? suffixes[0] : suffixes[value % 10] || suffixes[0];
    
    return `${n}${suffix}`;
  }

  

  

  return (
    <>
    {(!selectedQuickPickGames || selectedQuickPickGames.length === 0) ? (
      <>
        <QuickPickNoGamesCard 
          sponsorName={sponsorName} 
          teamName={teamName} 
          league={league} 
          isThereAGame={false}
        />
        <Box sx={{ marginTop: '40px' }} />
      </>
    ) : columnHeaders.length === 0 ? (
      <>
        <QuickPickNoGamesCard
          sponsorName={sponsorName}
          teamName={teamName}
          league={league}
          isThereAGame={true}
          playerPicksComing={true}
        />
        <Box sx={{ marginTop: '40px' }} />
      </>
    ) : (
    
        <TableContainer component={Paper} sx={{ overflow: 'auto', borderRadius: 0, maxWidth: '950px', margin: '0 auto' }}>
          
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* Fixed "STANDINGS" header */}
                <TableCell 
                  sx={{ 
                    backgroundColor: '#002129', 
                    color: '#fff', 
                    fontSize: '14px', 
                    fontWeight: 700, 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 10,
                    textAlign: 'center',
                    lineHeight: 1,
                    padding: '4px', // Tight padding
                  }}
                >
                  STANDINGS
                </TableCell>

                {/* Dynamic headers from columnHeaders */}
                {columnHeaders?.map(({ propHeader }) => (
                  <TableCell 
                    key={propHeader} 
                    sx={{ 
                      backgroundColor: '#002129', 
                      color: '#fff', 
                      fontSize: '10px', 
                      fontWeight: 500, 
                      position: 'sticky', 
                      top: 0, 
                      lineHeight: 1.2,
                      zIndex: 1,
                      textAlign: 'center',
                      whiteSpace: 'nowrap', // Prevent horizontal overflow
                      padding: '4px', // Snug padding
                      height: 'auto', // Allow height to adjust
                    }}
                  >
                    {/* Split the header text by spaces and add <br/> to stack them vertically */}
                    {propHeader?.split(' ').map((word, index) => (
                      <React.Fragment key={index}>
                        {word} <br/>
                      </React.Fragment>
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* Loop through leaderboardInfo to render rows */}
              {leaderboardInfo?.map((user, index) => (
                <TableRow 
                  key={index}
                  sx={{
                    backgroundColor: user.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                  }}  
                >
                  {/* First column: username */}
                  <TableCell 
                    sx={{ 
                      position: 'sticky', 
                      left: 0, 
                      zIndex: 1, 
                      backgroundColor: user.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                      padding: '4px', // Snug padding for username
                      minWidth: '128px', // Fixed width for username
                      height: '32px', // Fixed height for rows
                      textAlign: 'left',
                      marginLeft: '6px', // Add a bit of space to the left
                    }} 
                  >
                     <Typography sx={{fontSize: '10px', fontWeight: '500'}}>
                        {user.username}
                    </Typography> {/* First row of text */}
                    <Typography sx={{ fontSize: '10px', fontWeight: '500', marginTop: '2px' }}> {/* Optional styling for the second row */}
                        {getRank(user.rank)} - Pts: {user.totalPoints}
                    </Typography>
                  </TableCell>

                  {/* Loop through columnHeaders to match picks and display option */}
                  {columnHeaders?.map(({ propId, line, correctOption }) => {
                    // Find the matching pick for the current propId
                    const matchingPick = user?.picks?.find(pick => pick.id === propId);
                    
                    return (
                      <TableCell 
                        key={propId} 
                        sx={{ 
                          fontSize: '10px', 
                          fontWeight: 500, 
                          textAlign: 'center',
                          lineHeight: 1,
                          padding: '2px 4px', // Minimal padding around text
                          height: '32px', // Fixed height for rows
                        }}
                      >
                        <div 
                          style={{ 
                            border: matchingPick?.result === "Win" ? '1px solid #00AA72' : (matchingPick?.result === 'Loss' || (hasGameStarted && !matchingPick)) ? '1px solid #CC293C' : '1px solid #002129',
                            backgroundColor: matchingPick?.result === "Win" ? '#CCFFEF' : matchingPick?.result === 'Loss' ? '#FFEBEB' : 'transparent',
                            padding: '2px 4px', // Snug padding inside the div
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            minWidth: isMobile ? '40px' : '60px',
                            alignItems: 'center',
                            height: '100%', // Ensure the div takes the full cell height
                            boxSizing: 'border-box', // Include border in height calculation
                            lineHeight: 1.3,
                          }}
                        >
                          {correctOption === 'Void' ? (
                              <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: '12px' }}>
                                  VOID
                              </Typography>
                          ) : !hasGameStarted ? (
                            '' // Render nothing if the game has not started
                          ) : (
                            matchingPick ? (
                              <>
                                {/* Show the option on a separate line */}
                                <div style={{ marginBottom: '2px' }}>
                                  {matchingPick?.tiebreaker ? 
                                    matchingPick.option :
                                    getAbbreviatedOption(matchingPick?.option) // Remove the extra curly braces here
                                  }
                                </div>

                                {/* If there's a line, show it below the option */}
                                {line && (
                                  <div>
                                    {line}
                                  </div>
                                )}
                              </>
                            ) : (
                              <CloseIcon style={{ color: '#CC293C', fontSize: '20px' }} />
                            )
                          )}
                        </div>

                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    )}
    </>
  );
};

export default PlayerPicks;
