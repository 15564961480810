import React, { useState } from "react";
import { Box, Button, Select, MenuItem, FormControl } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import PlayerPicks from "./PlayerPicks";
import GameStats from "./GameStats";
import ChampPicks from "./ChampPicks";

const SuperPickemPoolPicks = ({
    gameAddress,
    mainGameId,
    currentInterval,
    games,
    rounds,
    seeds,
    leaderboard,
    teams,
    myUserId,
    schedule,
    gatherLogo,
    teamsOrNames,
    getPickStatsGameInfo,
    getPickStatsPickInfo,
    getChampInfo,
    hasJoinDeadlinePassed,
    countdownDeadline,
    pickemLogo,
    userPicksFromDB,
}) => {

    const lowerBound = 1;
    const upperBound = currentInterval;
    //console.log("SuperPickemPoolPicks Props:", currentInterval, games, rounds);

    const location = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [pageInterval, setPageInterval] = useState(currentInterval);
    //console.log("pageInterval:", pageInterval);

    const handleIntervalSelectChange = (event) => {
        const newInterval = event.target.value;
        setPageInterval(newInterval);
        navigate(`/${gameAddress}/${mainGameId}/POOL_PICKS/${newInterval}`);
    }

    // Initialize selectedTab based on URL query parameter
    const [selectedTab, setSelectedTab] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');
        
        // Check if the tab parameter is valid (overall, bracket, or picks)
        const validTabs = ['playerpicks', 'gamestats', 'champ'];
        return validTabs.includes(tabParam) ? tabParam : 'playerpicks';
    });

    const handleTabChange = (tab) => {
        // Update local state
        setSelectedTab(tab);

        // Update URL parameters
        const searchParams = new URLSearchParams(location.search);
        
        // If tab is overall, remove the tab parameter
        if (tab === 'playerpicks') {
            searchParams.delete('tab');
        } else {
            searchParams.set('tab', tab);
        }

        // Update URL without triggering a page reload
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }

    return (
        <>
            <Box className="super-pickem-leaderboard-button-container">

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'playerpicks' ? 'active' : ''}`}
                    onClick={() => handleTabChange('playerpicks')}
                >
                    Player Picks
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'gamestats' ? 'active' : ''}`}
                    onClick={() => handleTabChange('gamestats')}
                >
                    Game Stats
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${selectedTab === 'champ' ? 'active' : ''}`}
                    onClick={() => handleTabChange('champ')}
                >
                   Champ Picks
                </Button>

            </Box>

            {selectedTab !== 'champ' && (
                <Box>
                    <FormControl
                        variant="outlined"
                        size="small"
                        style={{ 
                            marginTop: isMobile ? '15px' : '15px',
                            marginBottom: isMobile ? '15px' : '15px',
                            width: isMobile ? '163px' : '220px' 
                        }}
                    >
                        <Select
                            value={pageInterval}
                            onChange={handleIntervalSelectChange}
                            variant="outlined"
                            style={{
                                textAlign: "center",
                                fontSize: isMobile ? '12px' : '20px',
                                padding: isMobile ? '5px' : '10px',
                                height: isMobile ? '24px' : '42px',
                                border: "1px solid #000000",
                                borderRadius: "4px",
                                boxShadow: "none", // Remove default shadow if you want a flat look
                                width: '100%'
                            }}
                            >
                            {Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
                                const intervalNumber = index + lowerBound;
                                const round = rounds.find(round => round.round === intervalNumber);
                                const displayName = round ? round.name : `Week ${intervalNumber}`;
                                return (
                                <MenuItem key={intervalNumber} value={intervalNumber}>
                                    {displayName}
                                </MenuItem>
                                );
                            })}
                            </Select>
                        </FormControl>
                </Box>
            )}

            {selectedTab === 'playerpicks' && (
                <PlayerPicks
                    myUserId={myUserId}
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    games={games}
                    gatherLogo={gatherLogo}
                    pageInterval={pageInterval}
                    teamsOrNames={teamsOrNames}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    rounds={rounds}
                    userPicksFromDB={userPicksFromDB}
                />
            )}

            {selectedTab === 'gamestats' && (
                <GameStats
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    games={games}
                    gatherLogo={gatherLogo}
                    pageInterval={pageInterval}
                    teamsOrNames={teamsOrNames}
                    rounds={rounds}
                    getPickStatsGameInfo={getPickStatsGameInfo}
                    getPickStatsPickInfo={getPickStatsPickInfo}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                />
            )}

            {selectedTab === 'champ' && (
                <ChampPicks
                    myUserId={myUserId}
                    leaderboard={leaderboard}
                    teams={teams}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    gatherLogo={gatherLogo}
                    pageInterval={pageInterval}
                    teamsOrNames={teamsOrNames}
                    getChampInfo={getChampInfo}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    countdownDeadline={countdownDeadline}
                />
            )}

            {selectedTab !== 'playerpicks' && pickemLogo && (
                <img
                    src={pickemLogo}
                    alt="Pickem Logo"
                    style={{
                        width: isMobile ? '185px' : '300px',
                        padding: '32px',
                        marginTop: '15px',
                        marginBottom: '15px',
                        margin: '0 auto',
                    }}
                />
            )}

        </>
    );
}

export default SuperPickemPoolPicks;