import React from 'react';
import CreateGamesComponent from '../components/createGames/createGames';
import { GameProvider } from '../components/createGames/context/createGamesContext';

const CreateGames = () => {
  return(
  <GameProvider>
    <CreateGamesComponent />
  </GameProvider>
  );
};

export default CreateGames;