// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.march-madness-bracket-heading {
  width: 100%;
  height: 99px;
  display: flex;
  background: #002129;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.top-section {
  display: flex;
  width: 100%;
  max-width: 1366px;
  justify-content: space-around;
  align-items: center;
}

.name {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 29.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bottom-dates {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/bracketEvents/bracketStyles/marchMadnessBracketHeading.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,+CAAA;AACJ;;AACA;EACI,aAAA;EACA,WAAA;EACA,iBAAA;EACA,6BAAA;EACA,mBAAA;AAEJ;;AACA;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".march-madness-bracket-heading{\n    width: 100%;\n    height: 99px;\n    display: flex;\n    background: #002129;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n}\n.top-section{\n    display: flex;\n    width: 100%;\n    max-width: 1366px;\n    justify-content: space-around;\n    align-items: center;\n\n}\n.name{\n    color: #FFF;\n    text-align: center;\n    font-family: Inter;\n    font-size: 29.3px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n\n.bottom-dates{\n    color: #FFF;\n    text-align: center;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
