import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./QuickPickUserDash.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import UserDashCopyLink from "../../GameHomePage/GameHomePageComponents/UserDashCopyLink";
import { DateTime } from "luxon";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { pick, set } from "lodash";
//import PickEmTiebreakerModal from "../PickEmTiebreakerModal";

const QuickPickUserDash = ({ 
    currentInterval, 
    userPicksFromDB,
    myUsername, 
    doesUserBelong,
    gameData, 
    setJoinOpen,
    picksPerInterval,
    usingTiebreaker, 
    anyUnredeemedRewards,
    firstInterval,
    lastInterval,
    hasJoinDeadlinePassed,
    mainGameId,
    gameAddress,
    formattedGameStartTime,
    myRank,
    amITied,
    totalEntries,
    oneWeekEvent,
    quickPickGames,
    voidedPicksCount,
    voidedPicksIds,
}) => {
  //console.log("First Interval and Current Interval: ", firstInterval, currentInterval);
  // const intervalForDash = firstInterval > currentInterval ? firstInterval : currentInterval;
  const [intervalForDash, setIntervalForDash] = useState(null);
  const [totalWeeklyPicks, setTotalWeeklyPicks] = useState(null);
  const [totalWeeklyPicksToMake, setTotalWeeklyPicksToMake] = useState(null);
  useEffect(() => {
    //console.log("Running Effect");
    if (firstInterval && currentInterval && lastInterval) {
      if (firstInterval > currentInterval) {
        setIntervalForDash(firstInterval);
      } else if (currentInterval > lastInterval) {
        setIntervalForDash(lastInterval);
      } else { 
        setIntervalForDash(currentInterval);
      }
    }
  }, [firstInterval, currentInterval, lastInterval]); 

  const [voidedPicksLoaded, setVoidedPicksLoaded] = useState(false);

  useEffect(() => {
    //console.log("2nd Use Effect");
    if (
      intervalForDash !== null &&
      userPicksFromDB !== undefined &&
      voidedPicksIds !== undefined &&
      voidedPicksCount !== undefined &&
      picksPerInterval !== undefined &&
      usingTiebreaker !== undefined
    ) {
      const totalWeeklyPicks = userPicksFromDB?.filter(
        (pick) => pick.interval === intervalForDash && !voidedPicksIds?.includes(pick.id)
      )?.length;
  
      const totalWeeklyPicksToMake =
        (usingTiebreaker ? picksPerInterval + 1 : picksPerInterval) - (voidedPicksCount || 0);
        //console.log("Interval for Dash: ", intervalForDash, "Voided Picks IDs: ", voidedPicksIds, "Voided Picks Count: ", voidedPicksCount, "Picks Per Interval: ", picksPerInterval, "Using Tiebreaker: ", usingTiebreaker);
      //console.log("Total Weekly Picks to Make Calculated: ", totalWeeklyPicksToMake);
      setTotalWeeklyPicks(totalWeeklyPicks);
      setTotalWeeklyPicksToMake(totalWeeklyPicksToMake);
    }
  }, [intervalForDash, userPicksFromDB, voidedPicksIds, voidedPicksCount, picksPerInterval, usingTiebreaker]);
  

  useEffect(() => {
    //console.log("Voided Picks Count and Voided Picks IDs: ", voidedPicksCount, voidedPicksIds);
    if (voidedPicksCount !== undefined && voidedPicksIds !== undefined && picksPerInterval !== undefined) {
      setVoidedPicksLoaded(true);
    }
  }, [voidedPicksCount, voidedPicksIds]);
  


  //console.log("Interval for Dash: ", intervalForDash);
  //console.log("Voided Picks Count and Voided Picks IDs: ", voidedPicksCount, voidedPicksIds);
  const gameName = gameData?.gameName;
  const logo = gameData?.clubLogo;
  //console.log("GameName and Game Type in User Dash: ", gameName, gameType);

  const timeNowInNewYork = DateTime.now().setZone("America/New_York");
  const hasGameStartTimePassed = timeNowInNewYork > formattedGameStartTime;
  //console.log("Has Game Start Time Passed: ", hasGameStartTimePassed);

  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [tiebreakerOpen, setTiebreakerOpen] = useState(false);
    
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
 

  const seasonRecord = () => {
    let wins = 0;
    let losses = 0;
    let points = 0;
  
    userPicksFromDB?.forEach((pick) => {
      if (pick.result === "Win") {
        wins++;
      } else if (pick.result === "Loss") {
        losses++;
      }
  
      // Accumulate points from each pick
      points += +pick.points || 0; // Ensure pick.points is a number and handles undefined/null
    });
  
    return { wins, losses, points };
  };
  

  const { wins: seasonWins, losses: seasonLosses, points: seasonPoints } = seasonRecord();

  const weeklyRecord = () => {
    let wins = 0;
    let losses = 0;
    let points = 0;
    userPicksFromDB?.forEach((pick) => {
      if (pick.interval === currentInterval) {
        //console.log("Pick:", pick);
        if (pick.result === "Win") {
          wins++;
        } else if (pick.result === "Loss") {
          losses++;
        } 
        //console.log("Pick Points: ", pick.points);
        // Accumulate points from each pick
        points += +pick.points || 0; // Ensure pick.points is a number and handles undefined/null
      }
      
    });
    return { wins, losses, points };
  };

  const { wins: weeklyWins, losses: weeklyLosses, points: weeklyPoints } = weeklyRecord();

  


  // // Filter user picks to ensure they match the current interval and their IDs are not in voidedPicksIds
  // const totalWeeklyPicks = userPicksFromDB?.filter(
  //   (pick) => pick.interval === intervalForDash && !voidedPicksIds?.includes(pick.id)
  // )?.length;
  // //console.log("Total Weekly Picks: ", totalWeeklyPicks);

  // // Adjust totalWeeklyPicksToMake by subtracting the count of voided picks
  // const totalWeeklyPicksToMake = (usingTiebreaker ? picksPerInterval + 1 : picksPerInterval) - voidedPicksCount;

  //console.log("Total Weekly Picks:", totalWeeklyPicks);

  if (intervalForDash === null || totalWeeklyPicks === undefined || voidedPicksCount === undefined || voidedPicksIds === undefined || usingTiebreaker === undefined || voidedPicksLoaded === false || totalWeeklyPicksToMake === undefined || totalWeeklyPicks === undefined) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Box className={"bracket-box-header"} sx={{ height: isMobile ? '95px' : '215px'}}>
      <Box className="user-dash-header" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <img className="user-dash-trophy" src={bracketTrophy} alt='' />
        <Typography className="user-dash-username" variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          {myUsername}
          {!hasGameStartTimePassed && (
          <UserDashCopyLink
            mainGameId={mainGameId}
            gameAddress={gameAddress}
          />
          )}
          {/* {doesUserBelong && (
            <span 
              style={{ 
                color: '#00AAD1', 
                fontSize: isMobile ? '12px' : '20px', 
                fontWeight: 500, 
                textDecoration: 'underline',
                marginLeft: '16px',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer', // Add cursor pointer to indicate it's clickable
              }}
              onClick={() => setStatsModalOpen(true)}
            >
              My Stats
            </span>
          )} */}
          {/* {doesUserBelong && currentInterval === 18 && (
            <span
              style={{
                color: '#00AAD1',
                fontSize: isMobile ? '12px' : '20px',
                fontWeight: 500,
                textDecoration: 'underline',
                marginLeft: '16px',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer', // Add cursor pointer to indicate it's clickable
              }}
              onClick={() => setTiebreakerOpen(true)}
            >
              Tiebreaker
            </span>
          )} */}
        </Typography>
      </Box>
          {doesUserBelong && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              maxWidth: '1102px',
              margin: '0 auto',
              width:'90%'
            }}>
              {isMobile ? (
                // These items will be displayed on mobile devices
                <>
                <Box>
                  <Typography className={"user-dash-headings"} variant='h4'>{!oneWeekEvent ? `SEASON PTS` : 'POINTS'}</Typography>
                  <Typography className={"user-dash-double-line-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}<br/>{amITied ? `(T${myRank} of ${totalEntries})` : `(${myRank} of ${totalEntries})`}</Typography>
                </Box>
                {!oneWeekEvent && (
                  <Box>
                    <Typography className={"user-dash-headings"} variant='h4'>{`WEEK ${intervalForDash} PTS`}</Typography>
                    <Typography className={"user-dash-values"} variant='h3' sx={{ fontWeight: '800', marginTop: '5px'}}>{weeklyPoints}</Typography>
                  </Box>
                )}
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography className={"user-dash-headings"} variant='h4'>{!oneWeekEvent ? `WEEK ${intervalForDash} STATUS` : 'PICK STATUS'}</Typography>
                    <Box className="user-dash-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                      {totalWeeklyPicks === totalWeeklyPicksToMake ? (
                        <>
                          <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                          <Typography className="user-dash-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/{totalWeeklyPicksToMake})
                          </Typography>
                        </>
                      ) : (
                        <>
                          <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                          <Typography className="user-dash-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/{totalWeeklyPicksToMake})
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box>
                      <Typography className={"user-dash-headings"} variant='h4'>REWARD?</Typography>
                      {anyUnredeemedRewards ? (
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '23px', marginTop: '5px' }} />
                      ) : (
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '23px', marginTop: '5px' }} />
                      )}
                  </Box>
                </>
              ) : (
                // These items will be displayed on desktop devices
                <>
                  <Box>
                    <Typography className={"user-dash-headings"} variant='h4'>{!oneWeekEvent ? `SEASON PTS` : 'POINTS'}</Typography>
                    <Typography className={"user-dash-double-line-values"} variant='h4'>{seasonPoints}<br/>{amITied ? `(T${myRank} of ${totalEntries})` : `(${myRank} of ${totalEntries})`}</Typography>
                  </Box>
                  {!oneWeekEvent && (
                    <Box>
                      <Typography className={"user-dash-headings"} variant='h4'>{`WEEK ${intervalForDash} PTS`}</Typography>
                      <Typography className={"user-dash-values"} variant='h4' sx={{ marginTop: '10px'}}>{weeklyPoints}</Typography>
                    </Box>
                  )}
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography className={"user-dash-headings"} variant='h4'>{!oneWeekEvent ? `WEEK ${intervalForDash} STATUS` : 'PICK STATUS'}</Typography>
                    <Box className="user-dash-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                      {totalWeeklyPicks === totalWeeklyPicksToMake ? (
                        <>
                          <CheckIcon sx={{ color: '#00AA72', fontSize: '44px' }} />
                          <Typography className="user-dash-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px'}}>
                            ({totalWeeklyPicks}/{totalWeeklyPicksToMake})
                          </Typography>
                        </>
                      ) : (
                        <>
                          <CloseIcon sx={{ color: '#cc293c', fontSize: '44px' }} />
                          <Typography className="user-dash-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                            ({totalWeeklyPicks}/{totalWeeklyPicksToMake})
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box>
                      <Typography className={"user-dash-headings"} variant='h4'>REWARD?</Typography>
                      {anyUnredeemedRewards ? (
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '44px', marginTop: '10px' }} />
                      ) : (
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '44px', marginTop: '10px' }} />
                      )}
                  </Box>
                </>
              )}
            </Box>
          )}
          {!doesUserBelong && (
            <Button 
                variant="contained" 
                onClick={() => setJoinOpen(true)}
                sx={{ 
                    fontSize: '14px', 
                    fontWeight: '700', 
                    width: '100px', 
                    height: '36px', 
                    whiteSpace: 'nowrap',
                    marginTop: isMobile ? '0px' : '24px',
                }}
            >
                JOIN POOL
            </Button>
          )}
        </Box>
        {/* {statsModalOpen && (
          <PickEmUserStatsModal 
            statsModalOpen={statsModalOpen} 
            setStatsModalOpen={setStatsModalOpen} 
            myUsername={myUsername} 
            usersPicks={userPicksFromDB}
            teams={teams}
            areTheseMyPicks={true}
            currentInterval={currentInterval}
            schedule={schedule}
          />
        )} */}
        {/* {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                />
            )} */}
        {/* {tiebreakerOpen && (
            <PickEmTiebreakerModal
                tiebreakerOpen={tiebreakerOpen}
                setTiebreakerOpen={setTiebreakerOpen}
            />
        )} */}

      </>
    );
}

export default QuickPickUserDash;