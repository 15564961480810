import React, { useState } from 'react';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Button, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import PoolPartyTrophyGold from '../../../assets/images/PoolPartyTrophyGold.svg';

const BirdieAlertModal = () => {
    const { AllGameInfo, scorecardState, setScorecardState, modalState, setModalState, selectedHole, startingScorecard, handleScoreCardSubmission, pushDate } = useBirdieContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("Birdie Alert, ScorecardState then startingScorecard:", scorecardState, startingScorecard, selectedHole);
    
    const isThisFirstBirdie = scorecardState[selectedHole - 1]?.birdieDates?.length === 1 ? true : false;
    //console.log("Birdie Alert, Is this the first birdie?", isThisFirstBirdie);

    const isThisEighteenthBirdie = scorecardState?.every(hole => hole?.birdieDates?.length >= 1) ? true : false;
    //console.log("Birdie Alert, Is this the eighteenth birdie?", isThisEighteenthBirdie);

    const howManyHolesBirdied = scorecardState?.filter(hole => hole?.birdieDates?.length >= 1).length || 1;
    const howManyBirdiesLeft = 18 - howManyHolesBirdied;
    //console.log("Birdie Alert, How many holes birdied?", howManyHolesBirdied, howManyBirdiesLeft);
    //console.log("Birdie Alert, AllGameInfo:", AllGameInfo);

    function haveIWonAnything() {
        let winningGroups = [];
        for (let i = 0; i < AllGameInfo?.length; i++) {
            const entry = AllGameInfo[i];
            const leaderBoardInfo = entry?.leaderboardInfo;
            const leaderBoardName = entry?.name;
            //console.log("Birdie Alert, Leaderboard Info:", leaderBoardInfo, leaderBoardName);
            let allBirdied = false;
            for (let j = 0; j < leaderBoardInfo?.length; j++) {
                const playerInfo = leaderBoardInfo[j];
                //console.log("Birdie Alert, Player Info:", playerInfo?.holesBirdied, playerInfo?.userName)
                if (playerInfo?.holesBirdied === 18) {
                    allBirdied = true;
                    break; // No need to check other players in the same leaderboard
                }
            }
            if (!allBirdied) {
                winningGroups.push(leaderBoardName);
            
        }
        }
        return winningGroups;
    }
    let winningGroups = [];
    let haveIWon = false;
    if (isThisEighteenthBirdie) {
        winningGroups = haveIWonAnything();
        if (winningGroups?.length > 0) {
        haveIWon = true;
        }
        //console.log("Birdie Alert, Winning Groups:", winningGroups);
    }
    //console.log("Birdie Alert, Have I won anything?", haveIWon);
    

    return (
        <div>
            {!haveIWon && <Typography variant='h4'>Birdie Alert!</Typography>}
            {!haveIWon && <Typography variant='h4'>Hole {selectedHole}</Typography>}
            {isThisFirstBirdie && !isThisEighteenthBirdie && (
                <>
                    <CheckIcon sx={{ color: 'green', fontSize: 50 }} />
                    <Typography variant='h5'>Nice birdie!</Typography>
                    <Typography variant="h5"> Only {howManyBirdiesLeft} {howManyBirdiesLeft === 1 ? 'hole' : 'holes'} to go.</Typography>                
                </>
            )}
            {!isThisFirstBirdie && (
                <>
                <Typography variant='h5' style={{marginTop: '35px', fontSize: isMobile ? '18px' : '24px'}}>You already birdied Hole {selectedHole}, but this will add to your Total Birdie count.</Typography>
                </> 
            )}
            {isThisFirstBirdie && isThisEighteenthBirdie && !haveIWon && (
                <>
                <Typography variant='h6'>Congratulations!</Typography>
                <Typography variant='h6'>You have birdied every hole on the course.</Typography>
                <Typography variant='h6' style={{marginTop: '10px'}}>Keep adding to your</Typography>
                <Typography variant='h6'> Total Birdies.</Typography>
                </>
            )}
            {isThisFirstBirdie && isThisEighteenthBirdie && haveIWon && (
                <>
                    <Typography variant='h6' style={{ fontWeight: '700'}}>Congratulations! You have birdied every hole on the course.</Typography>
                    
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{margin:"0 auto"}}>
                        <img src={PoolPartyTrophyGold} alt="Gold Trophy" style={{ width: '45px', marginRight: '5px' }} />
                        <Typography variant='h6' style={{marginTop: '10px', fontWeight: '700' }}>You have won:</Typography>
                        <img src={PoolPartyTrophyGold} alt="Gold Trophy" style={{ width: '45px', marginLeft: '5px' }} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyItems="center">
                        <Box style={{ maxHeight: '130px', overflowY: 'auto', margin: '0 auto' }}>
                            {winningGroups.map((group, index) => (
                                <Typography
                                    key={index}
                                    variant='h6'
                                    style={{
                                        fontWeight: '700',
                                        whiteSpace: 'nowrap', // Prevent text wrapping
                                        overflow: 'hidden', // Hide overflow text
                                        textOverflow: 'ellipsis', // Show ellipsis for overflow text
                                        maxWidth: '200px' // Limit the width of the Typography component
                                    }}
                                >
                                    {group.length > 25 ? group.substring(0, 25) + '...' : group}
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                </>
            )}

            <br></br>
            <Button
                variatnt='contained'
                sx={{ marginTop: '-10px'}}
                color='primary'
                className='submit-button active'
                onClick={() => {
                    setModalState(null);
                    window.location.href = window.location.href;
                }}
                >
                     EXIT
            </Button>
        </div>
    );
};

export default BirdieAlertModal;
