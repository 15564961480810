import React from "react";
import axios from "axios";

const redisCache = {
    "NFL": [
        {
            "GlobalGameID": 18760,
            "Date": "2024-10-10T00:00:00",
            "homeTeam": "SEA",
            "awayTeam": "SF",
            "gameStatus": "Scheduled",
            "survivorFinalized": false,
            "pickemFinalized": false,
            "sportsbookFinalized": false
        },
        {
            "GlobalGameID": 18763,
            "Date": "2024-10-10T00:00:00",
            "homeTeam": "SEA",
            "awayTeam": "SF",
            "gameStatus": "Scheduled",
            "survivorFinalized": true,
            "pickemFinalized": true,
            "sportsbookFinalized": true
        }
    ],
    "CFB": [
        {
            "GlobalGameID": 50015145,
            "Date": "2024-10-10T00:00:00",
            "homeTeam": "LOUTCH",
            "awayTeam": "MTNST",
            "gameStatus": "Scheduled",
            "survivorFinalized": false,
            "sportsbookFinalized": false
        },
        {
            "GlobalGameID": 50015146,
            "Date": "2024-10-09T00:00:00",
            "homeTeam": "JAXST",
            "awayTeam": "NMXST",
            "gameStatus": "Scheduled",
            "survivorFinalized": true,
            "sportsbookFinalized": true
        }
    ]
};

const datesAndGamesToLookUp = (sportGames) => {
    const uniqueDates = new Set();
    const gameIds = [];

    for (const game of sportGames) {
        // Check if any finalized keys are false
        if (
            (game.survivorFinalized === false) ||
            (game.sportsbookFinalized === false)
        ) {
            // Extract only the date part (before 'T')
            const dateWithoutTime = game.Date.split('T')[0];
            uniqueDates.add(dateWithoutTime); // Add the date to the set
            gameIds.push(game.GlobalGameID); // Add the game ID to the array
        }
    }

    return {
        dates: Array.from(uniqueDates), // Return unique dates as an array
        gameIds: gameIds // Return game IDs as an array
    };
};



// Function that will get the results of completed games for the day
const GetGameResults = () => {

    try {
        for (const sport in redisCache) {
            const dataToLookUp = datesAndGamesToLookUp(redisCache[sport]);
            console.log("Dates to look up for", sport, ":", dataToLookUp);
            const dates = dataToLookUp.dates;
            const gameIds = dataToLookUp.gameIds;
        }

    }
    catch (error) {
        console.error('Error fetching game results:', error);
    }

    return (
        <div>
            <h1>Get Game Results</h1>
        </div>
    );
}

export default GetGameResults;