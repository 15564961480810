import React, { useState } from "react";
import { Box, Button, Snackbar, Alert, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const HomePageCopyLink = ({ mainGameId, gameAddress, myUserId }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyClick = () => {

        let link = `https://staging.client.poolpartygaming.com/${gameAddress}/${mainGameId}/HOME/HOME`;
         // If the user is logged in, append the user ID to the link
        if (myUserId) {
            link += `?referralId=${myUserId}`;
        }

        navigator.clipboard.writeText(link).then(() => {
            setSnackbarOpen(true); // Open the snackbar when the link is copied
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; // Prevent closing when clicking away
        }
        setSnackbarOpen(false); // Close the snackbar
    };

    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                endIcon={<ContentCopyIcon />}
                sx={{
                    marginTop: '16px'
                }}
                onClick={handleCopyClick}
            >
                INVITE FRIENDS (Copy Link)
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // The snackbar will automatically close after 3 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Link copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default HomePageCopyLink;
