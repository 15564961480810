import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const STEPS_NAME = ['Email', 'Password', 'Basic Info'];

export default function HorizontalLinearStepper({ currentStep }) {
  const activeStep = currentStep + 1 ;

  return (
    <Box sx={{ width: '100%', marginTop: '50px', maxWidth: '750px', margin: '25px auto' }}>
      <Stepper activeStep={activeStep}>
        {STEPS_NAME.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
