import React from "react";
import { Typography, Box } from '@mui/material';
import PickStatsCard from "./PickStatsCard/PickStatsCard";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const BowlGameStats = ({
    leaderboard,
    teams,
    schedule,
    bowlGames,
    teamsOrNames,
    getPickStatsGameInfo,
    getPickStatsPickInfo,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const bowlGameIds = bowlGames?.map(game => game.GlobalGameID);
    console.log("Bowl Game IDs: ", bowlGameIds);
    const filteredSchedule = schedule?.filter(game => bowlGameIds.includes(game.GlobalGameID));
    
    filteredSchedule?.sort((a, b) => {
        // Define the desired order for Status
        const statusOrder = ['InProgress', 'Final', 'F/OT'];

        // Get the index of each game's Status in the statusOrder array
        const statusA = statusOrder.indexOf(a.Status);
        const statusB = statusOrder.indexOf(b.Status);

        // Handle Status order first
        if (statusA !== statusB) {
            // If statusA or statusB isn't in the array, place them at the end (use Infinity)
            return (statusA === -1 ? Infinity : statusA) - (statusB === -1 ? Infinity : statusB);
        }

        // If Status is the same, sort by DateTime
        return new Date(a.DateTime) - new Date(b.DateTime);
    });

    const gameIdsThisInterval = filteredSchedule?.map(game => game.GlobalGameID);
    //console.log("Game IDs This Interval: ", gameIdsThisInterval);

    return (
        <>
            {gameIdsThisInterval?.length === 0 && (
                <Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '20px' : '24px',
                            fontWeight: '700',
                            marginTop: '24px',
                            padding: '0 16px',
                        }}
                    >
                       Game Stats will be <br /> revealed when the matchups are set.
                    </Typography>
                </Box>
            )}
        
            {gameIdsThisInterval?.length > 0 && (  
                <>
                    <Box>
                        {gameIdsThisInterval?.map(gameId => {
                            const gameInfo = getPickStatsGameInfo(gameId, schedule, teams, teamsOrNames);
                            //console.log("This Has Game Started!!: ", gameInfo?.hasGameStarted);
                            const pickInfo = getPickStatsPickInfo(gameId, gameInfo.awayTeamId, gameInfo.homeTeamId, leaderboard);
                            return (
                                <PickStatsCard 
                                    key={gameId}
                                    gameInfo={gameInfo}
                                    pickInfo={pickInfo}
                                    isMobile={isMobile}
                                />
                            );
                        })}
                    </Box>
                </>
            )}
        </>
    );
}

export default BowlGameStats;