import { Alert, Box, Button, TextField } from "@mui/material";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForgotPassword } from "../hooks/auth/useForgotPassword";
import MainLayout from "../layouts/mainLayouts";

const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

const defaultValues = {
  email: "",
};

const ForgotPassword = () => {
  const form = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues,
  });
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const { status, error, mutate: forgotPassword } = useForgotPassword();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const submitForgotPasswordForm = useCallback(() => {
    form.handleSubmit(async (formData) => {
      forgotPassword(formData.email);
    })();
  }, [form, forgotPassword]);

  return (
    <MainLayout>
      <div className="flex-1 flex items-center justify-center flex-col">
        <Box
          component="form"
          onSubmit={form.handleSubmit(submitForgotPasswordForm)}
          sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}
          className="max-w-md w-full"
        >
          <div className="text-2xl font-bold">Forgot Password</div>
          <div className="text-gray-500">
            Enter your email address{isMobile && <br />} to reset your password.
          </div>
          <TextField
            id="email"
            name="email"
            label="Email address"
            variant="outlined"
            type="email"
            required
            {...form.register("email")}
            sx={{ width: isMobile ? '95%' : null, margin: '0 auto' }}
          />
          <Button
            disabled={form.formState.isSubmitting}
            variant="contained"
            type="submit"
            sx={{ margin: '0 auto'}}
          >
            Send Reset Link
          </Button>
          {status === "success" && (
            <div className="text-center  max-w-sm m-auto">
              <Alert severity="success" className="text-center">
                If there is an account associated with the email you provided,
                you will receive an email with a link to reset your password.
              </Alert>
            </div>
          )}
          {status === "error" && (
            <div className="text-center max-w-sm m-auto">
              <Alert severity="error" className="text-center">
                An error occurred. Please try again later.
                {showErrorDetails && (
                  <div className="font-semibold pl-2">{error?.message}</div>
                )}
                <div className="mt-2">
                  <Button
                    variant="outlined"
                    size="xsmall"
                    onClick={() => setShowErrorDetails(!showErrorDetails)}
                  >
                    {showErrorDetails ? "Hide" : "Show"} Details
                  </Button>
                </div>
              </Alert>
            </div>
          )}
        </Box>
      </div>
    </MainLayout>
  );
};

export default ForgotPassword;
