import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const PicksNotSaved = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                width: '308px',
                height: '206px',
                margin: '0 auto',
                padding: '16px',
                borderRadius: '10px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: isMobile ? '320px' : '320px',
            }}
        >
            <Typography 
                variant="h5" 
                style={{ 
                    fontSize: '16px',
                    fontWeight: '800',
                    marginBottom: '20px' 
                }}
            >
                Unsaved Picks
            </Typography>
            <Typography
                variant="h5"
                style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    marginBottom: '20px'
                }}
            >
                You have picks that have not been saved. If you leave this page, your picks will be lost.
            </Typography>
            {children}
        </Box>
    );
};

export default PicksNotSaved;
