// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  color: #002129;
  font-family: Inter;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content; /* Ensure the component only takes the space it needs */
}

.sort-by {
  font-size: 16px;
  font-weight: 700;
  color: #002129;
  margin-right: 8px; /* Adjust spacing between text and dropdown */
}

.sort-by-select {
  min-width: 120px; /* Adjust width as needed */
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/NFLSurvivor/NFLSurvivorStyles/SortByDropdown.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,cAAA;EACA,uBAAA;EAAA,kBAAA,EAAA,uDAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA,EAAA,6CAAA;AACJ;;AAEA;EACI,gBAAA,EAAA,2BAAA;EACA,YAAA;AACJ","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #fff;\n    padding: 16px;\n    box-sizing: border-box;\n    color: #002129;\n    font-family: Inter;\n    margin: 0 auto;\n    width: fit-content; /* Ensure the component only takes the space it needs */\n}\n\n.sort-by {\n    font-size: 16px;\n    font-weight: 700;\n    color: #002129;\n    margin-right: 8px; /* Adjust spacing between text and dropdown */\n}\n\n.sort-by-select {\n    min-width: 120px; /* Adjust width as needed */\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
