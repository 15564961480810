import React from "react";
import { Button, Modal, Typography, Box, Checkbox } from "@mui/material";

const PickEmPicksWarningModal = ({ modalOpen, setModalOpen, dontShowAgain, handleCheckboxChange, handleWarningSubmit }) => {

    return(
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{width: '308px', height: 'auto'}}>
                <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '20px'}}>Warning!</Typography>
                <Typography variant="body1"style={{ fontSize: '16px', marginBottom: '20px'}}>Your picks are FINAL and cannot be edited once submitted.</Typography>
                <Typography variant="body1"style={{ fontSize: '16px', marginBottom: '20px'}}>(You do not have to submit all 5 picks at once.)</Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <Checkbox
                        checked={dontShowAgain}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        sx={{ width: '24px', height: '24px' }}
                    />
                    <Typography variant="body2" style={{ fontSize: '16px', marginLeft: '5px', whiteSpace: 'nowrap'}}>Do not show this message again.</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant="contained" onClick={() => setModalOpen(false)}>Cancel</Button>
                    <Button variant="contained" sx={{ marginLeft: '24px'}} onClick={handleWarningSubmit}>Submit Picks</Button>
                </div>
            </div>
        </Modal>
    )
};

export default PickEmPicksWarningModal;