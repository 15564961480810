// Sports Short Name Function

const logoConfigs = {
    NCAAM: 'ShortDisplayName',
    NCAAW: 'ShortDisplayName',
    MLB: 'Key',
};

// Modified getTeamNameShort function with enhanced debugging
export const getTeamNameShort = (league, teamId, teams) => {
    if (!teams || !league || !teamId) return null;

    const propertyName = logoConfigs[league];
    if (!propertyName) return null;

    const team = teams.find(team => team.GlobalTeamID === teamId);
    if (!team) return null;

    const teamShortName = team[propertyName];

    return teamShortName && teamShortName.length > 4 
        ? teamShortName.slice(0, 4)  // Cut to 4 characters if longer
        : teamShortName;             // Keep as is if 4 or fewer
};
