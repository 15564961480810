import React from 'react';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { userSigninAction, userLogoutAction,getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { Link } from 'react-router-dom';

const GameBanner = (props) => {

    const {userData, gameData} = props;
    console.log('gameData', gameData)
    return (
    <div className="user-profile-main-header" style={{backgroundImage:`URL(${userData?.profile?.profileMainBanner && ''})`, backgroundColor:' rgb(2,0,36)', background:"linear-gradient(0deg, rgba(11,56,213,1) 0%, rgba(38,114,199,1) 35%, rgba(0,212,255,1) 100%)"
    }}>
        <div style={{color:'#fff'}}>
            <div className="user-profile-container" style={{display:'block',background:'#5D4CB1'}}>
            {/* {userData?.profile?.profileMainImage ?
                <img src={userData?.profile?.profileMainImage} alt="profile image"/>
                :
                null
            } */}
            </div>
            {/* <h1>{gameData.gameName}</h1> */}
            <h2>Currently you have active games.</h2>
        </div>
    </div>
    );
}
export default GameBanner;