import React, { useState, useEffect } from "react";
import { useBirdieContext } from "../context/BirdiePoolContext";
import { set } from "lodash";

const PoolScorecardDropdown = () => {
  const {
    selectedUser,
    setSelectedUser,
    setSelectedUserId,
    startingScorecard,
    scorecardState,
    setScorecardState,
    groupState,
    myUserName,
    clubLeaderboardInfo,
    userScorecard,
    firstLoad,
    setFirstLoad,
    getUserScorecard,
    myUserId,
    selectedUserId
  } = useBirdieContext();

  // console.log(
  //   "Pool Dropdown Club Leaderboard Info:",
  //   clubLeaderboardInfo.leaderboardInfo,
  //   "Starting Scorecard:",
  //   startingScorecard,
  //   scorecardState,
  //   myUserName
  // );

  useEffect(() => {
    if (firstLoad) {
      //console.log("First Load in Pool Dropdown");
      setScorecardState(startingScorecard);
      setSelectedUser(myUserName);
      setSelectedUserId(myUserId);
      setFirstLoad(false);
    }
    // ... rest of your useEffect logic
  }, []);

  const usernamesWithId = clubLeaderboardInfo?.leaderboardInfo?.map((entry) => ({
    userId: entry.userId,
    userName: entry.userName,
  }));

  const handleSelectionChange = (e) => {
    const userId = e.target.value; // Access userId from the value attribute
    const username = usernamesWithId?.find((user) => user.userId === userId)?.userName;
    const selectedUsersScorecard = getUserScorecard(username, userId);
    setSelectedUser(username);
    setSelectedUserId(userId);
    setScorecardState(selectedUsersScorecard);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop:'10px'}}>
      <h3 style={{ marginRight: "10px", fontWeight: '700'  }}>Pool Scorecards: </h3>
      <select
        className="border border-solid border-gray-900 box-border rounded text-sm p-1"
        style={{ textAlign: "center", fontSize:"12px", width:'162px' }}
        value={selectedUser}
        onChange={handleSelectionChange}
      >
        {/* Option for the selected username (myUserName) */}
        <option key={selectedUser} value={selectedUser}>
          {selectedUser}
        </option>
        {/* Options for other usernames */}
        {usernamesWithId?.filter((user) => user.userId !== selectedUserId)?.map((user) => (
          <option key={user.userId} value={user.userId}>
            {user.userName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PoolScorecardDropdown;
