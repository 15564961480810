import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import BracketGameDesktop from './BracketGameDesktop';
import BracketChamp from './BracketChamp';
import "./SixteenTeamBracketDesktop.scss";
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../../BracketGames/context/BracketGamesContext';
import { round } from 'lodash';

const SixteenTeamBracketDesktop = ({
    bracket,
    leftBracket,
    rightBracket,
    games,
    teams,
    league,
    handleGameClick,
    round1Ref,
    round2Ref,
    round3Ref,
    round4Ref,
    round5Ref,
    showDesktopNavBar,
    secondBracket,
    pickState,
}) => {
    const [isPastFirstColumn, setIsPastFirstColumn] = useState(false);
    const {
        // pickState,
        setPickState,
        hasJoinDeadlinePassed,
        intervalInfo,
    } = useUserAndGameContext();

    useEffect(() => {
        const container = document.querySelector('.regional-bracket-desktop-container');
        if (!container) return;

        const handleScroll = () => {
            if (round2Ref.current) {
                const rect = round2Ref.current.getBoundingClientRect();
                const threshold = window.innerWidth * 0.01;

                setIsPastFirstColumn((prevState) => {
                    const newState = rect.left < threshold;

                    // If we're transitioning into compressed state
                    if (!prevState && newState) {
                        // Scroll to top of column
                        requestAnimationFrame(() => {
                            const gameElement = round2Ref.current.querySelector('.regional-bracket-desktop-game');
                            if (gameElement) {
                                const rect = gameElement.getBoundingClientRect();
                                window.scrollTo({
                                    top: rect.top + window.pageYOffset - 150,
                                    behavior: 'smooth'
                                });
                            }
                        });
                    }
                    return newState;
                });
            }
        };

        container.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [round2Ref]);

    return (
        <Box className="regional-bracket-desktop-container" sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingRight: '32px' }}>
            <Box className="regional-bracket-desktop">
                {/* First Round Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-first">
                     <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '30px', marginLeft: '50px' }}>
                     {showDesktopNavBar?
                        <>
                            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[0]?.label}</Typography>
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                                {intervalInfo?.info[0]?.points} Point{intervalInfo?.info[0]?.points > 1 ? 's' : ''}
                            </Typography>
                            </>
                            :
                        <></>}
                    </Box>
                    <Box ref={round1Ref} >
                        {Array(8).fill(null).map((_, index) => (
                            <Box key={`round1-${index}`} className="regional-bracket-desktop-game">
                                <BracketGameDesktop
                                    bracket={leftBracket}
                                    round={1}
                                    tournamentDisplayOrder={index + 1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    pickState={pickState}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* First Round Connectors */}
                <Box className="regional-bracket-desktop-connector-column first-round-desktop">
                    {Array(4).fill(null).map((_, index) => (
                        <Box
                            key={`connector1-${index}`}
                            className={`connector-wrapper-desktop ${isPastFirstColumn ? 'first-round-desktop-compressed' : 'first-round-desktop-expanded'}`}
                        >
                            <div className="vertical-line-desktop" />
                        </Box>
                    ))}
                </Box>

                {/* Second Round Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-second">

                    <Box sx={{ width: '230px', marginBottom: secondBracket ? '96px' : '95.5px', paddingBottom: '30px', marginLeft: '50px' }}>
                        {showDesktopNavBar?<>
                            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[1]?.label}</Typography>
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                                {intervalInfo?.info[1]?.points} Point{intervalInfo?.info[1]?.points > 1 ? 's' : ''}
                            </Typography>
                        </>
                        :
                        <></>}
                    </Box>
                    <Box ref={round2Ref}>
                        {Array(4).fill(null).map((_, index) => (
                            <React.Fragment key={`round2-${index}`}>
                                <Box className="regional-bracket-desktop-game">
                                    <BracketGameDesktop
                                        bracket={leftBracket}
                                        round={2}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{
                                    height: isPastFirstColumn ? '40px' : '142px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>

                {/* Second Round Connectors */}
                <Box className="regional-bracket-desktop-connector-column second-round">
                    {Array(2).fill(null).map((_, index) => (
                        <Box
                            key={`connector2-${index}`}
                            className={`connector-wrapper-desktop ${isPastFirstColumn ? 'second-round-compressed' : 'second-round-expanded'}`}
                        >
                               <div className="vertical-line-desktop" style={{
                            left:'-120px !important'
                        }} />
                        </Box>
                    ))}
                </Box>

                {/* Sweet 16 Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-third left">
                <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '214.5px' : '291.5px', marginLeft: '50px' }}>
                {showDesktopNavBar?<>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[2]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[2]?.points} Point{intervalInfo?.info[2]?.points > 1 ? 's' : ''}
                        </Typography>
                        </>
                        :
                        <></>
                    }
                    </Box>

                    <Box ref={round3Ref}>
                        {Array(2).fill(null).map((_, index) => (
                            <React.Fragment key={`round3-${index}`}>
                                <Box key={`round3-${index}`} className="regional-bracket-desktop-game" sx={{
                                        marginLeft: '-120px'
                                }}>
                                    <BracketGameDesktop
                                        bracket={leftBracket}
                                        round={3}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{
                                    height: isPastFirstColumn ? '288px' : '522.5px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>

                {/* Sweet 16 Connectors */}
                <Box className="regional-bracket-desktop-connector-column sweet-16">
                    <Box
                        className={`connector-wrapper-desktop ${isPastFirstColumn ? 'sweet-16-compressed' : 'sweet-16-expanded'}`}
                    >
                        <div className={`vertical-line-desktop ${secondBracket ? "vertical-line-desktop-second" : ""}`} style={{
                            left:'120px !important'
                        }}/>
                    </Box>
                </Box>

                {/* Elite 8 Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-final" sx={{marginLeft: '-148px', width:'170px'}}>
                        <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '463px' : '670.5px', marginLeft: '50px' }}>
                        {showDesktopNavBar?<> <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[3]?.label}</Typography>
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                                {intervalInfo?.info[3]?.points} Point{intervalInfo?.info[3]?.points > 1 ? 's' : ''}
                            </Typography>
                        </>
                          :
                          <></>
                      }
                        </Box>

                    <Box ref={round4Ref}>
                        <Box className="regional-bracket-desktop-game"  sx={{marginLeft: '-151px'}}>
                            <BracketGameDesktop
                                bracket={leftBracket}
                                round={4}
                                tournamentDisplayOrder={1}
                                games={games}
                                teams={teams}
                                league={league}
                                handleGameClick={handleGameClick}
                                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                pickState={pickState}
                            />
                        </Box>
                    </Box>
                </Box>


            </Box>
            <Box className="regional-bracket-desktop ">

                {/* Elite 8 Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-final" sx={{marginRight: '-364px !important'}}>

                        <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '463px' : '670.5px', marginLeft: '50px' }}>
                        {showDesktopNavBar?<>
                            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[3]?.label}</Typography>
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                                {intervalInfo?.info[3]?.points} Point{intervalInfo?.info[3]?.points > 1 ? 's' : ''}
                            </Typography>
                            </>
                            :
                        <></>
                    }
                        </Box>

                    <Box ref={round4Ref}>
                        <Box className="regional-bracket-desktop-game"  sx={{marginRight: '-151px'}}>
                            <BracketGameDesktop
                                bracket={rightBracket}
                                round={4}
                                tournamentDisplayOrder={1}
                                games={games}
                                teams={teams}
                                league={league}
                                handleGameClick={handleGameClick}
                                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                pickState={pickState}
                            />
                        </Box>
                    </Box>
                </Box>
                {/* Sweet 16 Connectors */}
                <Box className="regional-bracket-desktop-connector-column sweet-16">
                    <Box
                        className={`connector-wrapper-desktop ${isPastFirstColumn ? 'sweet-16-compressed' : 'sweet-16-expanded'}`}
                    >
                           <div className="vertical-line-desktop vertical-line-desktop-mirror"/>
                    </Box>
                </Box>
                {/* Sweet 16 Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-third right">
                 <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: isPastFirstColumn ? '214.5px' : '291.5px', marginLeft: '50px' }}>
                {showDesktopNavBar?<> <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[2]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[2]?.points} Point{intervalInfo?.info[2]?.points > 1 ? 's' : ''}
                        </Typography>
                        </>
                         :
                         <></>
                     }
                    </Box>

                    <Box ref={round3Ref} sx={{marginRight: '-120px'}}>
                        {Array(2).fill(null).map((_, index) => (
                            <React.Fragment key={`round3-${index}`}>
                                <Box key={`round3-${index}`} className="regional-bracket-desktop-game" sx={{
                                        marginLeft: '120px'
                                }}>
                                    <BracketGameDesktop
                                        bracket={rightBracket}
                                        round={3}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{
                                    height: isPastFirstColumn ? '288px' : '522.5px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
                {/* Second Round Connectors */}
                <Box className="regional-bracket-desktop-connector-column second-round">
                    {Array(2).fill(null).map((_, index) => (
                        <Box
                            key={`connector2-${index}`}
                            className={`connector-wrapper-desktop ${isPastFirstColumn ? 'second-round-compressed' : 'second-round-expanded'}`}
                        >
                            <div className="vertical-line-desktop" />
                        </Box>
                    ))}
                </Box>


                {/* Second Round Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-second">

                    <Box sx={{ width: '230px', marginBottom: '95.5px', paddingBottom: '30px', marginLeft: '50px' }}>
                        {showDesktopNavBar?<> <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[1]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[1]?.points} Point{intervalInfo?.info[1]?.points > 1 ? 's' : ''}
                        </Typography>
                        </>
                          :
                          <></>
                      }
                    </Box>

                    <Box ref={round2Ref}>
                        {Array(4).fill(null).map((_, index) => (
                            <React.Fragment key={`round2-${index}`}>
                                <Box className="regional-bracket-desktop-game">
                                    <BracketGameDesktop
                                        bracket={rightBracket}
                                        round={2}
                                        tournamentDisplayOrder={index + 1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        pickState={pickState}
                                    />
                                </Box>
                                <Box sx={{
                                    height: isPastFirstColumn ? '40px' : '142px',
                                    transition: 'height 0.3s ease'
                                }} />
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>


                {/* First Round Connectors */}
                <Box className="regional-bracket-desktop-connector-column first-round-desktop">
                    {Array(4).fill(null).map((_, index) => (
                        <Box
                            key={`connector1-${index}`}
                            className={`connector-wrapper-desktop ${isPastFirstColumn ? 'first-round-desktop-compressed' : 'first-round-desktop-expanded'}`}
                        >
                            <div className="vertical-line-desktop" />
                        </Box>
                    ))}
                </Box>
                {/* First Round Column */}
                <Box className="regional-bracket-desktop-column regional-bracket-desktop-column-games-first-desktop-right">
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '30px', marginLeft: '50px' }}>
                         {showDesktopNavBar?<>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{intervalInfo?.info[0]?.label}</Typography>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {intervalInfo?.info[0]?.points} Point{intervalInfo?.info[0]?.points > 1 ? 's' : ''}
                        </Typography>
                        </>
                         :
                         <></>
                     }
                    </Box>

                    <Box ref={round1Ref} >
                        {Array(8).fill(null).map((_, index) => (
                            <Box key={`round1-${index}`} className="regional-bracket-desktop-game">
                                <BracketGameDesktop
                                    bracket={rightBracket}
                                    round={1}
                                    tournamentDisplayOrder={index + 1}
                                    games={games}
                                    teams={teams}
                                    league={league}
                                    handleGameClick={handleGameClick}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    pickState={pickState}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SixteenTeamBracketDesktop;