import React from "react";
import { Typography, Box } from '@mui/material';
import PickStatsHeader from "./PickStatsCardHeader";
import PickStatsStatBox from "./PickStatsStatBox";

const PickStatsCard = ({
    gameInfo,
    pickInfo,
    thisIntervalsName,
    isMobile,
}) => {

    const awayLogo = gameInfo?.awayLogo;
    const homeLogo = gameInfo?.homeLogo;
    const awayName = gameInfo?.awayName;
    const homeName = gameInfo?.homeName;
    const gameStatus = gameInfo?.gameStatus;
    const awayScore = gameInfo?.awayScore;
    const homeScore = gameInfo?.homeScore;
    const awaySpreadResult = pickInfo?.awayResult;
    const homeSpreadResult = pickInfo?.homeResult;
    const overResult = pickInfo?.overResult;
    const underResult = pickInfo?.underResult;
    const awaySpreadCount = pickInfo?.awayCount;
    const homeSpreadCount = pickInfo?.homeCount;
    const overCount = pickInfo?.overCount;
    const underCount = pickInfo?.underCount;
    const hasGameStarted = gameInfo?.hasGameStarted;
    const awaySpreadRecord = `${pickInfo?.awayWins}-${pickInfo?.awayLosses}-${pickInfo?.awayPushes}`;
    const homeSpreadRecord = `${pickInfo?.homeWins}-${pickInfo?.homeLosses}-${pickInfo?.homePushes}`;
    const overRecord = `${pickInfo?.overWins}-${pickInfo?.overLosses}-${pickInfo?.overPushes}`;
    const underRecord = `${pickInfo?.underWins}-${pickInfo?.underLosses}-${pickInfo?.underPushes}`;
    const dateTime = gameInfo?.formattedDate;
    const closingSpread = gameInfo?.homePointSpread > 0 ? `Closing Line: ${gameInfo?.awayName} (-${gameInfo?.homePointSpread})` : gameInfo?.homePointSpread === 0 ? `Closing Line: Pick` : `Closing Line: ${gameInfo?.homeName} (${gameInfo?.homePointSpread})`;
    const closingTotal = `Closing Line: ${gameInfo?.overUnder}`;
    const title = gameInfo?.title;

    return (
        <Box className="super-pickem-pick-stats-card-container">
            <PickStatsHeader
                awayLogo={awayLogo}
                homeLogo={homeLogo}
                awayName={awayName}
                homeName={homeName}
                gameStatus={gameStatus}
                awayScore={awayScore}
                homeScore={homeScore}
            />
            <Box className="super-pickem-pick-stats-label-container">
                <Box className="super-pickem-pick-stats-label">
                    <Typography className="super-pickem-pick-stats-label">
                        ATS
                    </Typography>   
                </Box>
                <Box className="super-pickem-pick-stats-label">   
                    <Typography className="super-pickem-pick-stats-label">
                        O/U
                    </Typography>
                </Box>
            </Box>
            <Box className="super-pickem-pick-stats-stat-boxes-main-container">
                <PickStatsStatBox 
                    pickType="spread"
                    hasGameStarted={hasGameStarted}
                    awayLogo={awayLogo}
                    homeLogo={homeLogo}
                    leftText={awayName}
                    rightText={homeName}
                    gameStatus={gameStatus}
                    leftCount={awaySpreadCount}
                    rightCount={homeSpreadCount}
                    leftResult={awaySpreadResult}
                    rightResult={homeSpreadResult}
                    leftRecord={awaySpreadRecord}
                    rightRecord={homeSpreadRecord}
                />
                <PickStatsStatBox
                    pickType="total"
                    hasGameStarted={hasGameStarted}
                    leftText="OVER"
                    rightText="UNDER"
                    gameStatus={gameStatus}
                    leftCount={overCount}
                    rightCount={underCount}
                    leftResult={overResult}
                    rightResult={underResult}
                    leftRecord={overRecord}
                    rightRecord={underRecord}
                />
            </Box>

            <Box 
                className="super-pickem-pick-stats-card-footer"
                sx={{
                    height: (thisIntervalsName || hasGameStarted) ?  undefined : isMobile ? '35px' : '45px',
                }}
            >
                {/* Left-Aligned Text */}
                <Box 
                    className="super-pickem-pick-stats-card-footer-text-container"
                    sx={{ justifyContent: hasGameStarted ? 'center' : 'flex-start' }}
                >
                    <Typography 
                        className="super-pickem-pick-stats-card-footer-text"
                        sx={{ textAlign: hasGameStarted ? 'center' : 'left' }}
                    >
                        {!hasGameStarted ? dateTime : closingSpread}
                    </Typography>
                </Box>

                {/* Right-Aligned Text */}
                <Box  
                    className="super-pickem-pick-stats-card-footer-text-container"
                    sx={{ justifyContent: hasGameStarted ? 'center' : 'flex-end'}}
                >
                    <Typography
                        className="super-pickem-pick-stats-card-footer-text"
                        sx={{ textAlign: hasGameStarted ? 'center' : 'right' }}
                    >
                        {!hasGameStarted ? thisIntervalsName ? thisIntervalsName : title : closingTotal}
                    </Typography>
                </Box>
            </Box>

        </Box>
    );
}

export default PickStatsCard;