import React, {useState} from 'react';
import { Box, Typography } from '@mui/material';
import '../bracketStyles/bracketNav.scss';


const BracketNav = ({setClickState, clickState}) => {
    const navItems = ['My Bracket', 'Leaderboard', 'Pool Rules'];
    console.log(clickState, "CLICKSTATE")
    const [navState, setNavState] = useState('My Bracket');
    const handleItemClick = (index) => {
        if(index === 0) {
            setClickState('BRACKET_VIEW');
            setNavState('My Bracket')
        };
        if(index === 1) {
            setClickState('LEADER_BOARD_VIEW');
            setNavState('Leaderboard');
        };
        if(index === 2) {
            setClickState('RULES_VIEW');
            setNavState('Pool Rules');
        }
    };



    return (
        <Box className="navBar">
            {navItems.map((itemText, index) => (
                <Typography
                    key={index}
                    className={`navItem ${navState === itemText ? 'active' : ''}`}
                    onClick={() => handleItemClick(index)}
                >
                    {itemText}
                </Typography>
            ))}
        </Box>
    );
};

export default BracketNav;