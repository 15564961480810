import React from "react";
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';

const PrizesInfoBox = ({ prizes }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src={trophyImage} alt="Trophy" style={{ width: isMobile ? '18px' : '24px'}} />
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        padding: '0px 8px',
                        textDecoration: 'underline',
                    }}
                >
                    {prizes?.header}
                </Typography>
                <img src={trophyImage} alt="Trophy" style={{ width: isMobile ? '18px' : '24px'}} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '8px 0px',
                }}
            >
                {prizes?.lines?.map((line, index) => (
                    <Typography
                        key={index}
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                        }}
                    >
                        {line}
                    </Typography>
                ))}
            </Box>
        </Box>

    );

}

export default PrizesInfoBox;