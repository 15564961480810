import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../../config";

export const useDemoteCommissioner = () => {
  return useMutation({
    mutationFn: demoteCommissionerRequest,
    onError: (error) => {
      console.error('Error promoting commissioner:', error);
    },
    onSuccess: (data) => {
      console.log('Subgroup commissioner promoted successfully:', data);
    }
  });
}

const demoteCommissionerRequest = async (subgroupData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
  console.log(subgroupData);
  const response = await axios.post(`${apiUrl}/user/demote-subgroup-commissioner`, subgroupData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};