import React, { useState } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import { useJoinPublicPool } from "../../PublicPools/hooks/useJoinPublicPools";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import QuickPickJoinedModal from "./QuickPickJoinedModal";
import TextAcceptanceModal from "./TextAcceptanceModal";


const JoinGameModal = ({ myUserId, logo, gameName, gameType, gameId, gameAddress, joinOpen, setJoinOpen, setSignUpState, sponsorSecondaryLogo, sponsorName, sponsorLogo, mainGameId, propIdsToUse, joinModalText, referralId, newSignUp }) => {
    //console.log("Prop Ids To Use: ", propIdsToUse, propIdsToUse.length);
    const navigateToTop = useNavigateToTop();

    const { mutate: joinPool } = useJoinPublicPool();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const [status, setStatus] = useState('confirmJoin');
    const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const joinGame = async (gameID, gameType) => {
        let data = {
            gameID: gameID,
            userID: myUserId,
            gameType: gameType
        };

        if (referralId) {
            data.referralId = referralId;
        }
        if (newSignUp) {
            data.newSignUp = newSignUp;
        }
    
        joinPool(data, {
            onSuccess: () => {
                setJoinOpen(true);
                setStatus("success");
                // Optionally, navigate to the game page or handle success logic
            },
            onError: (error) => {
                // Dispatch logout action for any error
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
        
                // No need to handle status or error message since the user is logged out
                console.error("Join pool failed:", error);
            }
        });
        
    };
    
    
    
    const handleJoinPool = (gameID, gameType) => {
        if(!myUserId) {
            //Base URL for navigation
            let url = `/login/${gameAddress}/${uriEncoded(gameID)}/HOME/HOME`;
            
            //If there is a referral ID, append it to the URL
            if (referralId) {
                url += `?referralId=${referralId}`;
            }
            
            navigateToTop(url);
        } else {
            joinGame(gameID, gameType);
            //console.log("JOIN POOL HERE")
        }
    };

    return (
        <div>
            <Modal
                open={joinOpen && status === "confirmJoin"}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        minHeight: isMobile ? '330px' : '350px',
                        height: 'auto',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    {/* <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 800,
                            marginBottom: '16px'
                        }}
                    >
                        Join {gameName}
                    </Typography> */}
                    {sponsorSecondaryLogo && (
                        <img
                            src={sponsorSecondaryLogo}
                            alt={sponsorName}
                            style={{
                                width: '120px',
                                marginBottom: '16px'
                            }}
                        />
                    )}
                    <img 
                        src={logo}
                        alt={gameName}
                        style={{
                            height: '140px',
                            marginBottom: '16px'
                        }}
                    />
                   <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 400,
                            marginBottom: '16px'
                        }}
                    >
                        Would you like to join {sponsorName ? '' : "Pool Party's"} <br /> {gameName}?
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2 // Gap of 20px
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => setJoinOpen(false)}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                whiteSpace: 'nowrap',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => handleJoinPool(gameId, gameType)}
                        >
                            JOIN POOL
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {gameType === 'QuickPick' && (
                <QuickPickJoinedModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    arePropsAvailable={propIdsToUse?.length > 0}
                    status={status}
                    sponsorLogo={sponsorLogo}
                    sponsorName={sponsorName}
                    gameId={mainGameId}
                    gameAddress={gameAddress}
                    joinModalText={joinModalText}
                />
            )}
            
            {gameType !== 'QuickPick' && (
                <TextAcceptanceModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    status={status}
                    logo={logo}
                    myUserId={myUserId}
                    gameId={gameId}
                />
            )}

                <Modal
                    open={joinOpen && status === "fail"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box 
                        sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        height: '210px',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, // Gap of 20px
                        overflow: 'scroll'
                        }}
                    >
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            marginBottom: '24px'
                        }}
                        >
                        Oops!
                        </Typography>
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '12px',
                            fontWeight: 700,
                            marginBottom: '16px'
                        }}
                        >
                        {errorMessage}
                        </Typography>
                        <Button
                        variant="contained"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            width: '152px',
                            height: '36px',
                            marginBottom: '8px'
                        }}
                        onClick={() => window.location.reload()} // Reload the page
                        >
                        Close
                        </Button>
                    </Box>
                </Modal>

        </div>
    );
}

export default JoinGameModal;