import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useResetPassword } from "../../hooks/auth";
import PasswordStrength from "../AccountCreation/PasswordStrength";

const requirements = {
  letters: 1,
  digits: 1,
  caseDiff: 1,
  special: 1,
  minLength: 8,
  maxLength: 40,
};

const resetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#()$!%*?&])[A-Za-z\d@$!%#%^()*?&_]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      ),
    confirmPassword: z.string().min(8),
    originalPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

const defaultValues = {
  originalPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export default function ResetPassword(props) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const form = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues,
  });

  const newPassword = useWatch({ control: form.control, name: "newPassword" });
  const confirmPassword = useWatch({
    control: form.control,
    name: "confirmPassword",
  });

  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] =
    useState(false);
  const [shouldShowOriginalPassword, setShouldShowOriginalPassword] =
    useState(false);
  const [originalPasswordIncorrect, setOriginalPasswordIncorrect] =
    useState(false);
  const { error: resetPasswordError, mutate: resetPassword } =
    useResetPassword();

  const submitResetPasswordForm = useCallback(() => {
    form.handleSubmit(async (formData) => {
      resetPassword(
        {
          originalPassword: formData.originalPassword,
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPassword,
        },
        {
          onSuccess: () => {
            setIsSnackbarOpen(true);
            setOriginalPasswordIncorrect(false);
          },
          onError: (err) => {
            if (err?.request?.response?.match(/password is incorrect/i)) {
              setOriginalPasswordIncorrect(true);
            }
          },
        }
      );
    })();
  }, [form, resetPassword]);

  return (
    <Box component="form" onSubmit={form.handleSubmit(submitResetPasswordForm)}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ bottom: "100px" }}
        open={isSnackbarOpen}
        autoHideDuration={135000}
        onClose={() => setIsSnackbarOpen(false)}
        message="Changes saved successfully."
        key={"top" + "right"}
      />
      <div
        className="hidden md:block w-full flex justify-end pr-6 text-right"
        style={{ marginTop: "-90px", paddingBottom: "60px" }}
      >
        <Button variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <TextField
          id="originalPassword"
          name="originalPassword"
          label="Original Password"
          variant="outlined"
          type={shouldShowOriginalPassword ? "text" : "password"}
          required
          error={originalPasswordIncorrect}
          helperText={
            originalPasswordIncorrect ? "Original password is incorrect" : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShouldShowOriginalPassword(!shouldShowOriginalPassword)
                  }
                  edge="end"
                >
                  {shouldShowOriginalPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...form.register("originalPassword")}
        />
        <TextField
          id="newPassword"
          name="newPassword"
          label="New Password"
          variant="outlined"
          type={shouldShowPassword ? "text" : "password"}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShouldShowPassword(!shouldShowPassword)}
                  edge="end"
                >
                  {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...form.register("newPassword")}
        />
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          variant="outlined"
          type={shouldShowConfirmPassword ? "text" : "password"}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShouldShowConfirmPassword(!shouldShowConfirmPassword)
                  }
                  edge="end"
                >
                  {shouldShowConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...form.register("confirmPassword")}
        />

        <div className="block md:hidden w-full flex justify-center">
          <Button variant="contained" size="large" type="submit">
            Save Changes
          </Button>
        </div>
        <PasswordStrength
          password={newPassword}
          confirmPassword={confirmPassword}
          requirements={requirements}
          onValidationChange={() => {}}
        />
      </Box>
    </Box>
  );
}
