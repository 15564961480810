import React, { useState, useCallback } from 'react';
import { Button, TextField, Modal } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useAddCommissioner } from '../../PrivateParty/hooks/useAddCommisioner';
import { useDemoteCommissioner } from '../../PrivateParty/hooks/useDemoteCommissioner';
import { useDeletePrivateParty } from '../../PrivateParty/hooks/useDeletePrivateParty';
import { useJoinPrivateParty } from '../../PrivateParty/hooks/useJoinPrivateParty';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import ClubEmailModal from './ClubEmailModal';
import "./PrivateGroup.scss";
import { set } from 'lodash';






const PrivateCommissioner = ({ handleClickState }) => {
    const [emails, setEmails] = useState("");
    const [modalEmails, setModalEmails] = useState("");
    const [emailsError, setEmailsError] = useState(false);
    const [helperText, setHelperText] = useState("Enter up to 50 comma-separated email addresses.");
    const [copyMessage, setCopyMessage] = useState(""); // State for copy message
    const [modalState, setModalState] = useState(null); // State for modal
    const [modalUserInfo, setModalUserInfo] = useState(null); // State for modal user info
    const [sentEmails, setSentEmails] = useState(false);
    const [sentEmailsColor, setSentEmailsColor] = useState(null);
    const [sentEmailsHelperText, setSentEmailsHelperText] = useState("");
    const [sentModalEmails, setSentModalEmails] = useState(false);
    const [sentModalEmailsColor, setSentModalEmailsColor] = useState(null);
    const [sentModalEmailsHelperText, setSentModalEmailsHelperText] = useState("");

    const { handlePrivatePartyInvite, userSubgroups, birdiePoolData, birdieLeaderboardData, groupState, setGroupState, clubLeaderboardInfo, myUserName, gameId, setSelectedUser, setSelectedUserId, setScorecardState, getUserScorecard, userScorecard, userData, removeUserFromPrivateParty, headerDisplayName, AllGameInfo, setClubLeaderboardInfo, myUserId  } = useBirdieContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Hooks for Private Party Requests
    const {mutate: addCommissioner} = useAddCommissioner();
    const {mutate: demoteCommissioner} = useDemoteCommissioner();
    const {mutate: deletePrivateParty} = useDeletePrivateParty();
    const {mutate: joinPrivateParty} = useJoinPrivateParty();

    //console.log("User Subgroups in Private Commissioner", userSubgroups, birdiePoolData, groupState, clubLeaderboardInfo, clubLeaderboardInfo.leaderboardInfo.length)
    const groupLength = clubLeaderboardInfo.leaderboardInfo.length;
    const memberInfo = clubLeaderboardInfo.leaderboardInfo;
    //console.log("Member Info", memberInfo);
    const subGroupInfo = userSubgroups.find(subgroup => subgroup.name === groupState);
    const subGroupId = subGroupInfo.subGroupID;
    //console.log("SUBGROUP ID:", subGroupId);

    const thisSubgroupInfo = birdiePoolData.subGroups.find(subgroup => subgroup.groupID === subGroupId);
    const inactiveUsers = thisSubgroupInfo.inactiveUsers;
    //console.log("This Subgroup Info", thisSubgroupInfo, inactiveUsers);

    const allClubUsers = AllGameInfo[0]?.leaderboardInfo;
    const myPartyUsers = clubLeaderboardInfo?.leaderboardInfo;

    let usersForList = [];
    for (let i = 0; i < allClubUsers?.length; i++) {
        const user = allClubUsers[i];
        const userInMyParty = myPartyUsers?.find(partyUser => partyUser.userId === user.userId);
        if (!userInMyParty) {
            usersForList.push(user);
        }
    }
    //console.log("Club Email Modal, Users for List:", usersForList);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    let inactiveUserDetails = [];
    if (inactiveUsers && inactiveUsers.length > 0) {
        inactiveUserDetails = birdieLeaderboardData[0]?.leaderboardInfo
            .filter(user => inactiveUsers.includes(user.userId))
            .map(user => ({
                userId: user.userId,
                userName: user.userName,
                email: user.email
            }));
        }
            //console.log("Inactive User Details", inactiveUserDetails);


    // Sort by creator first (true at the top), then by commissioner (true at the top)
    memberInfo.sort((a, b) => {
    if (a.creator && !b.creator) return -1; // 'a' is creator, 'b' is not
    if (!a.creator && b.creator) return 1;  // 'b' is creator, 'a' is not
    if (a.commissioner && !b.commissioner) return -1; // 'a' is commissioner, 'b' is not
    if (!a.commissioner && b.commissioner) return 1;  // 'b' is commissioner, 'a' is not
    return 0; // If both are equal in terms of creator and commissioner
    });

    const myInfo = memberInfo?.find(member => member.userName === myUserName && member.userId === myUserId);
    const amICreator = myInfo?.creator;
    const amICommissioner = myInfo?.commissioner;

    //console.log("MY INFO", myInfo, "Commisioner??", amICommissioner, "Creator???", amICreator);

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const validateEmails = (emails) => {
        const emailArray = emails.split(',');
        if (emailArray.length > 50) {
            setHelperText("You can only enter up to 50 email addresses.");
            setEmailsError(true);
            return false;
        }
        for (let email of emailArray) {
            email = email.trim();
            if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                setHelperText("Please enter valid email addresses separated by commas.");
                setEmailsError(true);
                return false;
            }
        }
        setHelperText("Enter up to 50 comma-separated email addresses.");
        setEmailsError(false);
        return true;
    };

    const debouncedValidateEmails = useCallback(debounce(validateEmails, 1000), []);

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmails(value);
        debouncedValidateEmails(value);
    };

    const handleSendEmails = async () => {
        //console.log("Send Email has to happen here");
        //console.log("Emails:", emails);
        const emailArray = emails.split(',');

        let inviteData = {
            invitedMembers:emailArray,
            gameId: gameId,
            subGroupID: subGroupId,
            invitationName: groupState,
            fromMember: userData.user_id
        }

        try {
        handlePrivatePartyInvite(inviteData);
        setSentEmails(true);
        setSentEmailsColor("green");
        setSentEmailsHelperText("Invitations sent successfully!");
        setEmails("");
        setTimeout(() => {
            setSentEmails(false);
            setSentEmailsHelperText("");
        }   , 3000);

        } catch (error) {
            console.error("An error occurred while sending the emails:", error);
            setSentEmails(true);
            setSentEmailsColor("red");
            setSentEmailsHelperText("Failed to send invitations. Please try again.");
            setTimeout(() => {
                setSentEmails(false);
                setSentEmailsHelperText("");
            }   , 3000);
        }
    };

    const handleSendModalEmails = async () => {
        //console.log("Send Email has to happen here");
        //console.log("Emails:", modalEmails);
        const emailArray = modalEmails.split(',');

        let inviteData = {
            invitedMembers:emailArray,
            gameId: gameId,
            subGroupID: subGroupId,
            invitationName: groupState,
            fromMember: userData.user_id
        }

        try {
        handlePrivatePartyInvite(inviteData);
        setSentModalEmails(true);
        setSentModalEmailsColor("green");
        setSentModalEmailsHelperText("Invitations sent successfully!");
        setTimeout(() => {
            setSentEmails(false);
            setSentEmailsHelperText("");
            setModalEmails("");
            setModalState(null);
            setModalUserInfo(null);
            window.location.href = window.location.href;
        }   , 3000);

        } catch (error) {
            console.error("An error occurred while sending the emails:", error);
            setSentModalEmails(true);
            setSentModalEmailsColor("red");
            setSentModalEmailsHelperText("Failed to send invitations. Please try again.");
            setTimeout(() => {
                setSentEmails(false);
                setSentEmailsHelperText("");
                window.location.href = window.location.href;
            }   , 3000);
        }
    };

    const handleCopyLink = () => {
        const link = `${process.env.REACT_APP_CLIENT_URL}/private-party-invite/${uriEncoded(gameId)}/${uriEncoded(subGroupId)}/${uriEncoded(userData.user_id)}`;
        navigator.clipboard.writeText(link).then(() => {
            setCopyMessage('Link copied to clipboard!');
            setTimeout(() => setCopyMessage(''), 3000); // Hide message after 3 seconds
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };


    const handleAddCommissioner = async (member) => {

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };

        try {
            await addCommissioner(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = window.location.href;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };

    const handleRemoveCommissioner = async (member) => {
        // Define your handleRemoveCommissioner function here
        //console.log("Find the Game with", gameId, "then find the SubGroup with groupID:", subGroupId, "And REMOVE this userID into co-commissioners:", member.userId)
        //console.log("Find the user wiht this userID:", member.userId, "then find the SubGroup with subGroupID:", subGroupId, "and change Commissioner to false")

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };


        try {
            await demoteCommissioner(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = window.location.href;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };


    const handleDeleteGame = async (member) => {
        // Define your handleDeleteGame function here
        //console.log("Find the game with gameID:", gameId, "then the subGroup with groupID:", subGroupId, "and gather all of the userIDs from that subGroups groupMembers.")
        //console.log("Then we have to go to find each individual users entry for that subgroup and remove it.")
        //console.log("Go into gameID:", gameId, "And find the subGroup with groupID:", subGroupId, "And delete that subGroup");

        let commissionerData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member
        };

        try {
            await deletePrivateParty(commissionerData, {
                onSuccess: async () => {
                    try {

                        window.location.href = `/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };

    const handleRemoveUser = async (member, removeSelf) => {
        let removedUserData = {
            gameId: gameId,
            subGroupId: subGroupId,
            member: member,
            removingId: myUserId
        };

        try {
            await removeUserFromPrivateParty(removedUserData, {
                onSuccess: async () => {
                    try {
                        if (removeSelf) {
                            window.location.href = `/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`;
                        } else {
                        window.location.href = window.location.href;
                        }
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }

    };

    const handleReInstateUser = async (member) => {
        let reInstateUserData = {
            gameID: gameId,
            groupID: subGroupId,
            groupName: groupState,
            userID: member.userId,
        };

        try {
            await joinPrivateParty(reInstateUserData, {
                onSuccess: async () => {
                    try {

                        window.location.href = window.location.href;
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }

    };


    const commissionerLogo = (
        <div className="commissioner-logo-circle">
            H
        </div>
    );

    const handleUserClick = (username, userId) => {
        setSelectedUser(username);
        setSelectedUserId(userId);
        if (username === myUserName && userId === myUserId) {
          setScorecardState(userScorecard);
        } else {
        const selectedUsersScorecard = getUserScorecard(username, userId);
        setScorecardState(selectedUsersScorecard);
        }
        handleClickState('SCORE_CARD');
      };


      const handleRemovedUserClick = (username, userId) => {
        setGroupState(headerDisplayName);
        const matchingEntry = AllGameInfo.find(entry => entry.name === headerDisplayName);
        //console.log("Matching Entry", matchingEntry.leaderboardInfo);
        setClubLeaderboardInfo(matchingEntry);
        setSelectedUser(username);
        setSelectedUserId(userId);
        const selectedUsersScorecard = getUserScorecard(username, userId);
        //console.log("Selected Users Scorecard for:", username, userId, selectedUsersScorecard);
        setScorecardState(selectedUsersScorecard);
        handleClickState('SCORE_CARD');
    };


    return (
        <div>
            <h3 style={{ color: "white", background: "#002F3A", fontWeight:'700', margin: '0 auto', padding:'5px 0', fontSize: isMobile ? '12px' : '14px',  }}>
                {amICommissioner ? `Manage ${groupState}` : `${groupState} Overview`}
            </h3>

            {amICommissioner &&
            <>
            <Typography variant="h2" style={{ fontSize: isMobile ? '20px' : '26px', fontWeight: '700', marginTop: '20px' }}>
                Invite Members
            </Typography>
            <Typography variant="h2" style={{ fontSize: isMobile ? '16px' : '20px', fontWeight: '400', marginTop: '25px' }}>
                Option 1: Invite Link
            </Typography>
            {copyMessage && (
                <Typography sx={{ color: 'green' }}>{copyMessage}</Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                endIcon={<ContentCopy />}
                onClick={handleCopyLink}
                sx={{ marginTop: '10px' }}
            >
                COPY INVITE LINK
            </Button>
            <br></br>
            <Typography variant="h2" style={{ fontSize: isMobile ? '16px' : '20px', fontWeight: '400', marginTop: '25px' }}>
                Option 2: Email Invite
            </Typography>
            {sentEmails && (
                <Typography sx={{ color: sentEmailsColor }}>{sentEmailsHelperText}</Typography>
            )}
            <TextField
                id="email-invites"
                label="Email Invites"
                variant="outlined"
                fullWidth
                className="private-group-input"
                value={emails}
                onChange={handleEmailChange}
                helperText={helperText}
                error={emailsError}
                sx={{ width: isMobile ? '90%' : '450px', marginTop: '10px' }}
                autoComplete="off"
            />
            <br></br>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSendEmails}
                disabled={emailsError || emails.length === 0}
                sx={{ marginTop: '10px' }}
            >
                INVITE MEMBERS
            </Button>
            {usersForList.length > 0 && (
            <>
                <Typography variant="h2" style={{ fontSize: isMobile ? '16px' : '20px', fontWeight: '400', marginTop: '25px' }}>
                    Option 3: Invite Registered Club Members
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setModalState("emailModal"); setEmails(""); }}
                    sx={{ marginTop: '10px' }}
                >
                    INVITE
                </Button>
            </>
            )}
            </>
            }

            <Typography variant="h2" style={{ fontSize: isMobile ? '20px' : '28px', fontWeight: '700', marginTop:'20px' }}>
            Pool Members ({groupLength})
            </Typography>
        <TableContainer component={Paper} style={{ width:'100%', maxWidth:'800px', margin:'0 auto', borderRadius:'0px', borderLeft:"2px solid #002129", borderRight: "2px solid #002129"}}>
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                            <TableCell className='private-commissioner-table' sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}>Player</TableCell>
                            <TableCell className='private-commissioner-table' align="center" sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}>Host</TableCell>
                            {amICommissioner && (
                            <TableCell className='private-commissioner-table' align="center" sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}>Remove</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberInfo?.map((memberInfo, index) => (
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                                >
                                <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                    <div
                                        style={{
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        overflow: 'hidden', // Added for truncation
                                        textOverflow: 'ellipsis', // Added for truncation
                                        whiteSpace: 'nowrap', // Added for truncation
                                        }}
                                        onClick={() => handleUserClick(memberInfo.userName, memberInfo.userId)}
                                    >
                                        {isMobile ? (
                                            memberInfo.userName.length > 28 ? `${memberInfo.userName.substring(0, 28)}...` : memberInfo.userName
                                            ) : (
                                            memberInfo.userName.length > 60 ? `${memberInfo.userName.substring(0, 60)}...` : memberInfo.userName
                                        )}
                                    </div>
                                    <div style={{ fontSize: '10px' }}>
                                    {isMobile ? (
                                            memberInfo.email.length > 28 ? `${memberInfo.email.substring(0, 28)}...` : memberInfo.email
                                            ) : (
                                            memberInfo.email.length > 60 ? `${memberInfo.email.substring(0, 60)}...` : memberInfo.email
                                        )}
                                    </div>
                                </TableCell>


                                <TableCell className='private-commissioner-table' align="center">
                                    {memberInfo.commissioner === true ? (
                                        <>
                                            {commissionerLogo}
                                            {memberInfo.creator !== true && amICommissioner && (
                                                <RemoveIcon
                                                    sx={{ color: '#CC293C', cursor: 'pointer', marginLeft: isMobile ? '0px' : '10px' }}
                                                    onClick={() => { setModalUserInfo(memberInfo); setModalState("confirmRemoveCommissioner")}}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        amICommissioner && (
                                            <AddIcon
                                                sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                onClick={() => { setModalUserInfo(memberInfo); setModalState("confirmAdd")}}
                                            />
                                        )
                                    )}
                                </TableCell>

                                {amICommissioner && (
                                    <TableCell className='private-commissioner-table' align="center">
                                        {memberInfo.creator === true ? (
                                            <div>N/A</div>
                                        ) : (
                                            <CloseIcon
                                                sx={{ color: '#CC293C', cursor: 'pointer' }}
                                                onClick={() => {setModalUserInfo(memberInfo); setModalState("confirmRemoveUser")}}
                                            />
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                { inactiveUserDetails && inactiveUserDetails.length === 0 && <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />}
            </TableContainer>
            { inactiveUserDetails && inactiveUserDetails.length > 0 &&
                <>
                {/* <Typography variant="h2" style={{ fontSize: isMobile ? '20px' : '28px', fontWeight: '700' }}>
                    Removed Members ({inactiveUserDetails.length})
                </Typography> */}
                <TableContainer component={Paper} style={{maxWidth:'800px', margin:'0 auto', borderRadius:'0px', borderLeft:"2px solid #002129"}}>
                    <Table aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#002129' }}>
                            <TableRow>
                                <TableCell className='private-commissioner-table' sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}>Removed Player</TableCell>
                                {amICommissioner && <TableCell className='private-commissioner-table' sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }} align="center">Reinstate</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inactiveUserDetails?.map((inactiveUserDetails, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F0F1F1' }}
                                    >
                                    <TableCell className='private-commissioner-table-no-border' component="th" scope="row">
                                        <div
                                            style={{
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontStyle: 'italic',
                                            color: 'red'
                                            }}
                                            onClick={() => {
                                            handleRemovedUserClick(inactiveUserDetails.userName, inactiveUserDetails.userId);
                                            }}
                                        >
                                            {isMobile ? (
                                                inactiveUserDetails.userName.length > 28
                                                ? `${inactiveUserDetails.userName.substring(0, 28)}...`
                                                : `${inactiveUserDetails.userName} (Inactive)`
                                            ) : (
                                                inactiveUserDetails.userName.length > 60
                                                ? `${inactiveUserDetails.userName.substring(0, 60)}...`
                                                : `${inactiveUserDetails.userName} (Inactive)`
                                            )}
                                        </div>
                                        <div style={{ fontSize: '10px', color: 'red' }}>
                                            {isMobile ? (
                                            inactiveUserDetails.email.length > 28
                                                ? `${inactiveUserDetails.email.substring(0, 28)}...`
                                                : inactiveUserDetails.email
                                            ) : (
                                            inactiveUserDetails.email.length > 60
                                                ? `${inactiveUserDetails.email.substring(0, 60)}...`
                                                : inactiveUserDetails.email
                                            )}
                                        </div>
                                    </TableCell>


                                    {amICommissioner && (
                                        <TableCell className='private-commissioner-table' align="center">
                                            {memberInfo.creator === true ? (
                                                <div>N/A</div>
                                            ) : (
                                                <AddIcon
                                                    sx={{ color: '#00AA72', cursor: 'pointer' }}
                                                    onClick={() => {setModalUserInfo(inactiveUserDetails); setModalState("confirmReinstateUser")}}
                                                />
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box sx={{ height: '4px', backgroundColor: '#FFC60A' }} />
                </TableContainer>
                </>
            }
            {amICreator ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(gameId); setModalState("confirmDelete")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    DELETE POOL
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {setModalUserInfo(myInfo); setModalState("confirmLeave")}}
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    LEAVE POOL
                </Button>
            )}

                <Modal
                    open={modalState === "confirmAdd"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box className="modal-container" style={{ height: '260px'}}>
                        Add {modalUserInfo?.userName}<br></br>as a Co-Host?
                        <br></br>
                        <Box className="button-container">
                            <Button
                                variant="contained"
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => {setModalUserInfo(null); setModalState(null)}}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {handleAddCommissioner(modalUserInfo); setModalState(null); setModalUserInfo(null)}}
                            >
                                ADD
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={modalState === "confirmRemoveCommissioner"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                        <Box className="modal-container" style={{ height: '260px'}}>
                        Remove {modalUserInfo?.userName} as <br></br>a Co-Host?
                        <br></br>
                            <Box className="button-container">
                                <Button
                                    variant='contained'
                                    sx={{
                                        bgcolor: 'white',
                                        color: '#002129',
                                        border: '1px solid #002129',
                                        '&:hover': {
                                        bgcolor: 'white', // Maintain white background on hover
                                        color: '#002129', // Maintain text color on hover
                                        border: '1px solid #002129', // Maintain border color on hover
                                        },
                                    }}
                                    onClick={() => {setModalUserInfo(null); setModalState(null)}}
                                    >
                                        CANCEL
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => { handleRemoveCommissioner(modalUserInfo); setModalState(null); setModalUserInfo(null)}}
                                    >
                                    REMOVE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmRemoveUser"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{ height: '260px', bgcolor: 'background.paper', p: 4 }}>
                            {(modalUserInfo?.userName === myInfo.userName && modalUserInfo?.userId === myUserId) ? (
                            <>
                                Do you want to leave <br /> {groupState}?
                            </>
                            ) : (
                            <>
                                Remove {modalUserInfo?.userName} <br /> from {groupState}?
                            </>
                            )}
                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleRemoveUser(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                {modalUserInfo?.userName === myInfo.userName ? (
                                <>
                                    LEAVE
                                </>
                                ) : (
                                <>
                                    REMOVE
                                </>
                                )}
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmLeave"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{ height: '260px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Do you want to leave <br /> {groupState}?
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleRemoveUser(modalUserInfo, true); setModalState(null); setModalUserInfo(null); }}
                                >
                                LEAVE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "confirmDelete"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{ height: '260px', fontSize: '18px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Are you sure you want to <br /> delete {groupState}? <br /> This CANNOT be undone.
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleDeleteGame(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                DELETE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                            open={modalState === "confirmReinstateUser"}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                        <Box className="modal-container" sx={{ height: '260px', bgcolor: 'background.paper', p: 4 }}>
                            <>
                                Do you want to reinstate <br /> {modalUserInfo?.userName}?
                            </>

                            <br />
                            <Box className="button-container">
                                <Button
                                variant='contained'
                                sx={{
                                    bgcolor: 'white',
                                    color: '#002129',
                                    border: '1px solid #002129',
                                    '&:hover': {
                                    bgcolor: 'white', // Maintain white background on hover
                                    color: '#002129', // Maintain text color on hover
                                    border: '1px solid #002129', // Maintain border color on hover
                                    },
                                }}
                                onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                CANCEL
                                </Button>
                                <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleReInstateUser(modalUserInfo); setModalState(null); setModalUserInfo(null); }}
                                >
                                REINSTATE
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={modalState === "emailModal"}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        >
                        <Box
                            sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px', // Adjust the width as needed
                            bgcolor: 'white',
                            border: '1px solid black',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '4px' // Optional: add border radius for rounded corners
                            }}
                        >
                            <ClubEmailModal setModalEmails={setModalEmails} usersForList={usersForList}/>
                            {sentModalEmails && (
                                <Typography sx={{ color: sentModalEmailsColor }}>{sentModalEmailsHelperText}</Typography>
                            )}
                            <Box
                            className="button-container"
                            sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                            >
                            {!sentModalEmails && (
                                <>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 4, mt: 2 }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        bgcolor: 'white',
                                        color: '#002129',
                                        border: '1px solid #002129',
                                        '&:hover': {
                                            bgcolor: 'white',
                                            color: '#002129',
                                            border: '1px solid #002129',
                                        },
                                    }}
                                    onClick={() => { setModalUserInfo(null); setModalState(null); }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => { handleSendModalEmails(); }}
                                >
                                    INVITE
                                </Button>
                            </Box>
                            </>
                            )}
                            {sentModalEmails && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }}>
                                    <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => { setModalState(null); setModalUserInfo(null); setSentEmails(false); setSentEmailsHelperText(""); setModalEmails(""); window.location.href = window.location.href;}}
                                    >
                                    CLOSE
                                    </Button>
                              </Box>
                            )}
                            </Box>
                        </Box>
                    </Modal>
    </div>
    );
}

export default PrivateCommissioner;
