import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useJoinGame = () => {
    return useMutation({
        mutationFn: joinGameRequest,
        onError: (error) => {
            console.error('Error joining game:', error);
        },
        onSuccess: (data) => {
            console.log('Joined game successfully:', data);
        }
    });
}

const joinGameRequest = async (poolData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
    //console.log(poolData);

    const response = await axios.post(`${apiUrl}/games/join-game`, poolData, {
        headers: {
            Authorization: authorizationHeader,  // Include the authorization header in the request
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}