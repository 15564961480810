import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import './BetReceipt.scss';
import BetReceiptHeader from "./BetReceiptComponents/BetReceiptHeader";
import BetReceiptDoneButton from "./BetReceiptComponents/BetReceiptDoneButton";
import BetReceiptDataBox from "./BetReceiptComponents/BetReceiptDataBox";
import BetReceiptGameInfoSingle from "./BetReceiptComponents/BetReceiptGameInfoSingle";
import BetReceiptGameInfoParlayTeaser from "./BetReceiptComponents/BetReceiptGameInfoParlayTeaser";
import BetReceiptParlayTeaserHeader from "./BetReceiptComponents/BetReceiptParlayTeaserHeader";
import BetReceiptUnsuccessfulBet from "./BetReceiptComponents/BetReceiptUnsuccessfulBet";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const BetReceipt = ({ betReceiptInfo }) => {
    //console.log("BetReceiptInfo: FROM BET RECEIPT", betReceiptInfo);
    const { picksToSave, gamesThatChanged, gamesThatStarted, gamesUnavailable, groupBetOdds, groupBetPayout, betStyle, teaserPoints } = betReceiptInfo;
    const anyBetsPlaced = picksToSave?.length > 0;
    const numberOfBetsPlaced = picksToSave?.length;
    const anyBetsNotPlaced = gamesUnavailable?.length > 0 || gamesThatChanged?.length > 0 || gamesThatStarted?.length > 0;
    const numberOfBetsNotPlaced = gamesUnavailable?.length + gamesThatChanged?.length + gamesThatStarted?.length;
    
     // Combine all bets into one list to determine the first and last bets
     const combinedBets = [
        ...(gamesUnavailable?.map(game => ({ ...game, status: 'unavailable' })) || []),
        ...(gamesThatChanged?.map(game => ({ ...game, status: 'changed' })) || []),
        ...(gamesThatStarted?.map(game => ({ ...game, status: 'started' })) || []),
        ...(picksToSave?.map(pick => ({ ...pick, status: 'placed' })) || [])
    ];
    
    //console.log("Combined Bets: ", combinedBets);
    // Determine the first and last bets, for the lines to be displayed on parlay/teaser bets
    const firstBetId = combinedBets?.length > 0 ? combinedBets[0]?.betId : null;
    const lastBetId = combinedBets?.length > 0 ? combinedBets[combinedBets.length - 1]?.betId : null;
    //console.log("First Bet ID: ", firstBetId, "Last Bet ID: ", lastBetId);

    return (
        <>
            {/* Overlay */}
            <Box className="modal-overlay" />

            {/* Main Receipt Container */}
            <Box className="sportsbook-bet-receipt-container">
                    
                        <BetReceiptHeader betReceiptInfo={betReceiptInfo} />

                        {betStyle !== 'straight' && (
                            <BetReceiptParlayTeaserHeader betReceiptInfo={betReceiptInfo} />
                        )}

                        {anyBetsNotPlaced && betStyle === 'straight' && (
                            <Box>
                                {gamesUnavailable?.map((pick, index) => (
                                    <BetReceiptGameInfoSingle
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        reason={"This game is unavailable."}
                                    />
                                ))}
                                {gamesThatChanged?.map((pick, index) => (
                                    <BetReceiptGameInfoSingle
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        reason={"The odds have changed."}
                                    />
                                ))}
                                {gamesThatStarted?.map((pick, index) => (
                                    <BetReceiptGameInfoSingle
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        reason={"The game has started."}
                                    />
                                ))}
                            </Box>
                        )}

                        {anyBetsPlaced && betStyle === 'straight' && (
                            <Box>
                                {picksToSave?.map((pick, index) => (
                                    <BetReceiptGameInfoSingle
                                        key={index}
                                        bet={pick}
                                        betMade={true}
                                        numberOfBetsPlaced={numberOfBetsPlaced}
                                        numberOfBetsNotPlaced={numberOfBetsNotPlaced}
                                    />
                                ))}
                            </Box>
                        )}

                        {anyBetsNotPlaced && betStyle !== 'straight' && (
                            <Box>
                                {gamesUnavailable?.map((pick, index) => (
                                    <BetReceiptGameInfoParlayTeaser
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        chosenBetStyle={betStyle}
                                        teaserPointValue={teaserPoints}
                                        reason={"This game is currently unavailable."}
                                        first={pick.betId === firstBetId}
                                        last={pick.betId === lastBetId}
                                    />
                                ))}
                                {gamesThatChanged?.map((pick, index) => (
                                    <BetReceiptGameInfoParlayTeaser
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        chosenBetStyle={betStyle}
                                        teaserPointValue={teaserPoints}
                                        reason={"The odds have changed."}
                                        first={pick.betId === firstBetId}
                                        last={pick.betId === lastBetId}
                                    />
                                ))}
                                {gamesThatStarted?.map((pick, index) => (
                                    <BetReceiptGameInfoParlayTeaser
                                        key={index}
                                        bet={pick}
                                        betMade={false}
                                        chosenBetStyle={betStyle}
                                        teaserPointValue={teaserPoints}
                                        reason={"The game has started."}
                                        first={pick.betId === firstBetId}
                                        last={pick.betId === lastBetId}
                                    />
                                ))}
                            </Box>
                        )}

                        {anyBetsPlaced && betStyle !== 'straight' && (
                            <Box>
                                {picksToSave?.map((pick, index) => (
                                    <BetReceiptGameInfoParlayTeaser
                                        key={index}
                                        bet={pick}
                                        betMade={true}
                                        chosenBetStyle={betStyle}
                                        teaserPointValue={teaserPoints}
                                        numberOfBetsPlaced={numberOfBetsPlaced}
                                        numberOfBetsNotPlaced={numberOfBetsNotPlaced}
                                        first={pick.betId === firstBetId}
                                        last={pick.betId === lastBetId}
                                    />
                                ))}
                            </Box>
                        )}

                        {((betStyle === 'straight' && anyBetsPlaced) ||
                            (betStyle !== 'straight' && !anyBetsNotPlaced)) && (
                            <BetReceiptDataBox betReceiptInfo={betReceiptInfo} />
                        )}

                        {anyBetsNotPlaced && <BetReceiptUnsuccessfulBet betReceiptInfo={betReceiptInfo} />}

                        <BetReceiptDoneButton />
                 
            </Box>
        </>
        
    );
}

export default BetReceipt;