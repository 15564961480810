import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useGetAllGameMembers = () => {
    return useMutation({
        mutationFn: getAllGameMembersRequest,
        onError: (error) => {
            console.error("Error fetching all game members:", error);
        },
        // onSuccess: (data) => {
        //     console.log("Fetched all game members data:", data); // Updated data is logged
        // }
    });
}

const getAllGameMembersRequest = async ({ gameId }) => {
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage

    const data = { gameId }; // Send the gameId in the request body
    const response = await axios.post(`${apiUrl}/games/get-all-game-members`, data, {
        headers: {
            Authorization: authorizationHeader, // Include the authorization header in the request
            "Content-Type": "application/json",
        },
    });

    // Return the array of game member objects
    return response.data.data; // Updated to match new response format
}