import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../context/BracketGamesContext';
import SweetSixteenBracket from '../../Brackets/SixteenTeamBracket/SweetSixteenBracket';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import TemplatedBracketNavBar from '../../NavigationBars/BracketNavigationBar/TemplatedBracketNavBar';
import { useMediaQuery } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';

const ViewEntrantSweetSixteen = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMobile = useMediaQuery("(max-width:1350px)");
    const navigateToTop = useNavigateToTop();

    const {
        tournamentSchedule,
        teams,
        selectedUserBracketInfo,
    } = useBracketGamesContext();

    const {
        myUserId,
        masterPoolId,
        pickIndex,
        masterPoolData,
        gameAddress,
        gameId,
        league,
        pickState,
        setPickState,
        tiebreaker,
        setTiebreaker,
        gameData,
        hasJoinDeadlinePassed,
        isUserInThisPool,
    } = useUserAndGameContext();

    const dispatch = useDispatch();

    const [regions, setRegions] = useState(null);
    const notReadyModal = masterPoolData?.notReadyModal;
    const showNotReadyModal = notReadyModal?.showModal;
    
    // Initialize roundDash from URL or default to 1
    const [roundDash, setRoundDash] = useState(() => {
        const navParam = searchParams.get('bracketNav');
        return navParam ? parseInt(navParam, 10) : 1;
    });

    const scrollToRound = (roundRef) => {
        if (!roundRef?.current) return;
        
        const gameElement = roundRef.current.querySelector('.regional-bracket-game');
        if (!gameElement) return;
        
        // Get element position
        const rect = gameElement.getBoundingClientRect();
        
        // Vertical scroll
        window.scrollTo({
            top: rect.top + window.pageYOffset - 150,
            behavior: 'smooth'
        });
        
        // Also handle horizontal scroll based on which round
        const container = document.querySelector('.regional-bracket-container');
        if (container) {
            // Find the column element that contains the game
            const columnElement = roundRef.current.closest('.regional-bracket-column');
            
            if (columnElement) {
                // Determine column position
                const columnRect = columnElement.getBoundingClientRect();
                
                // Calculate scroll position to center the column
                const containerRect = container.getBoundingClientRect();
                const centerOffset = (containerRect.width / 2) - (columnRect.width / 2);
                let targetScrollLeft = columnRect.left - containerRect.left - centerOffset + container.scrollLeft;
                
                // Special handling for round2Ref - add 30px to the scroll position
                if (roundRef === round2Ref) {
                    targetScrollLeft += 60;
                }
                
                // Scroll horizontally to center the column
                container.scrollTo({
                    left: Math.max(0, targetScrollLeft),
                    behavior: 'smooth'
                });
            } else {
                // If we can't find the column, reset to beginning
                container.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };
    
    // Special function for championship round
    const scrollToChampionship = () => {
        // Get the championship container and bracket container
        const champElement = document.querySelector('.regional-bracket-column-champion');
        const container = document.querySelector('.regional-bracket-container');
        
        if (champElement && container) {
            // First get the position of the championship element
            const rect = champElement.getBoundingClientRect();
            
            // Vertical scroll
            window.scrollTo({
                top: rect.top + window.pageYOffset + 250,
                behavior: 'smooth'
            });
            
            // Scroll all the way to the right for the championship column
            const scrollWidth = container.scrollWidth;
            container.scrollTo({
                left: scrollWidth - container.clientWidth,
                behavior: 'smooth'
            });
        }
    };

    // Add refs and state for sticky navigation
    const observerRef = useRef(null);
    const containerRef = useRef(null);
    const [isStuck, setIsStuck] = useState(false);

    const round1Ref = useRef(null);
    const round2Ref = useRef(null);
    const round3Ref = useRef(null);
    const round4Ref = useRef(null);
    const round5Ref = useRef(null);

    // Handle scroll for sticky navigation
    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (tournamentSchedule) {
            setRegions({
                topLeft: tournamentSchedule.LeftTopBracketConference,
                bottomLeft: tournamentSchedule.LeftBottomBracketConference,
                topRight: tournamentSchedule.RightTopBracketConference,
                bottomRight: tournamentSchedule.RightBottomBracketConference,
                finalFour: "Final Four",
            });
        }
    }, [tournamentSchedule]);

    // Update URL when roundDash changes while preserving other params
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('bracketNav', roundDash.toString());
        setSearchParams(newParams);
    }, [roundDash, setSearchParams, searchParams]);

    const setRoundDashPosition = useMemo(() => {
        return {
            1: () => {
                if (round1Ref.current) {
                    scrollToRound(round1Ref);
                }
            },
            2: () => {
                if (round2Ref.current) {
                    scrollToRound(round2Ref);
                }
            },
            3: () => {
                if (round3Ref.current) {
                    scrollToRound(round3Ref);
                }
            },
            4: () => {
                if (round4Ref.current) {
                    scrollToRound(round4Ref);
                }
            },
            5: () => {
                if (round5Ref.current) {
                    scrollToChampionship();
                }
            }
        };
    }, [round1Ref, round2Ref, round3Ref, round4Ref, round5Ref]);

    // Use Effect to go back to top left on bracket change
    useEffect(() => {
        if (round1Ref.current && regions) {
            setTimeout(() => {
                // Vertical scroll
                scrollToRound(round1Ref);
    
                // Add horizontal scroll reset
                const container = document.querySelector('.regional-bracket-container');
                if (container) {
                    container.scrollTo({
                        left: 0, // Reset to start of bracket
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    }, [regions]);
    

    // Update roundDash and bracket based on URL when component mounts
    useEffect(() => {
        const navParam = searchParams.get('bracketNav');
        if (navParam) {
            const roundNumber = parseInt(navParam, 10);
            setRoundDash(roundNumber);
            setRoundDashPosition[roundNumber]?.();
        } else {
            setRoundDash(1);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('bracketNav', '1');
            setSearchParams(newParams);
            setRoundDashPosition[1]?.();
        }
    }, [searchParams, setSearchParams, regions, setRoundDashPosition]);

    const tournamentGames = tournamentSchedule?.Games;
    const regionInfo = masterPoolData?.gameParameters?.regionInfo;

    const handleGameClick = (teamId, bracket, round, tournamentDisplayOrder, seedNumber, globalGameId) => {
        let newPicks = [...pickState];
        
        const existingPickIndex = newPicks.findIndex(pick => 
            pick.bracket === bracket && 
            pick.round === round && 
            pick.displayOrder === tournamentDisplayOrder
        );
        
        if (existingPickIndex !== -1 && newPicks[existingPickIndex].teamId === teamId) {
            return;
        }
        
        const newPick = {
            teamId,
            bracket,
            round,
            displayOrder: tournamentDisplayOrder,
            teamSeed: seedNumber,
            globalGameId
        };
        
        let finalPicks;
        if (existingPickIndex !== -1) {
            finalPicks = newPicks.filter(pick => 
                pick.round <= round || pick.teamId !== newPicks[existingPickIndex].teamId
            );
            finalPicks[existingPickIndex] = newPick;
        } else {
            finalPicks = [...newPicks, newPick];
        }
    
        const getMaxPicksForRound = (roundNum) => {
            // Find in regionInfo by interval
            const picksThisRound = regionInfo.find(r => r.interval === roundNum)?.games;
            return picksThisRound;
        };
    
        const isChampionshipRound = round === 6;
        
        const previousRoundPicks = pickState?.filter(pick => 
            pick.round === round
        )?.length;
    
        const nextRoundPicks = finalPicks?.filter(pick => 
            pick.round === round
        )?.length;
    
        const maxPicksForRound = getMaxPicksForRound(round);
 
        setPickState(finalPicks);
    };

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    useEffect(() => {
        if (!hasJoinDeadlinePassed) {
            navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home`);
        }
    }, [isUserInThisPool, gameAddress, masterPoolId, gameId, navigateToTop, hasJoinDeadlinePassed]);

    return (
        <Box>
            {!regions && tournamentGames?.length > 0 && !showNotReadyModal ? (
                <LoadingScreen />
            ) : (
                <>
                    <div style={{ position: 'relative', zIndex: 10 }}>
                        <div
                            ref={observerRef}
                            style={{
                                height: '1px',
                                position: 'relative',
                            }}
                        />
                        <div
                            ref={containerRef}
                            style={{
                                height: '25px',
                                backgroundColor: '#fff',
                                width: '100%',
                                position: isStuck ? 'fixed' : 'relative',
                                top: isStuck ? '80px' : '0',
                                marginBottom: '16px',
                                zIndex: 10,
                            }}
                        >
                            <TemplatedBracketNavBar
                                rounds={regions ? Object.values(regions).filter(Boolean) : []}
                                roundDash={roundDash}
                                setRoundDash={setRoundDash}
                                setRoundDashPosition={setRoundDashPosition}
                                pickState={pickState}
                                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                regionInfo={regionInfo}
                            />
                        </div>
                    </div>

                    <Typography
                        sx={{
                            marginTop: '40px',
                            fontSize: '24px',
                            fontWeight: '700',
                        }}
                    >
                        {selectedUserBracketInfo?.username}'s Bracket
                    </Typography>

                    <Box sx={{ position: 'relative' }}>
                        <SweetSixteenBracket
                            regions={regions}
                            games={tournamentGames}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            round1Ref={round1Ref}
                            round2Ref={round2Ref}
                            round3Ref={round3Ref}
                            round4Ref={round4Ref}
                            round5Ref={round5Ref}
                            setRegionCompleteModalOpen={null}
                            pickState={selectedUserBracketInfo?.picks}
                            overrideTiebreaker={true}
                            userTiebreaker={selectedUserBracketInfo?.tiebreaker}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ViewEntrantSweetSixteen;