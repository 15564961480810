import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import BracketTeamRow from "./BracketTeamRow";
import "./SuperPickemBracket.scss";

const teamData = [
    {
        "teamDisplay": "Ohio State (12-1)",
        "seed": 1,
        "teamDisplayCharacterCount": 17,
        "teamLogo": "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/98.png",
        "teamColor": "#CC293C",
        "isSelected": false,
        "isOppositeSelected": false,
    },
    {
        "teamDisplay" : "Alabama (13-0)",
        "seed" : 12,    
        "teamDisplayCharacterCount" : 15,
"teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/275.png",
"teamColor" : "#A32638",
"isSelected" : false,
"isOppositeSelected" : false,
    }
];

const BracketGame = () => {

    const [isTopSelected, setIsTopSelected] = useState(false);
    const [isBottomSelected, setIsBottomSelected] = useState(false);

    return (
        <Box className="super-pickem-bracket-game-container">
            {/* Top Team */}
            <BracketTeamRow
                seed={teamData[0].seed}
                teamDisplay={teamData[0].teamDisplay}
                teamDisplayCharacterCount={teamData[0].teamDisplayCharacterCount}
                teamLogo={teamData[0].teamLogo}
                teamColor={teamData[0].teamColor}
                isSelected={isTopSelected}
                setIsSelected={setIsTopSelected}
                isOppositeSelected={isBottomSelected}
            />

            {/* Game Information */}
            <Box>
                <Typography variant="h6" className="super-pickem-game-info">
                    Fri, Dec 20 @ 8:30pm ET
                </Typography>
            </Box>

            {/* Bottom Team */}
            <BracketTeamRow
                seed={teamData[1].seed}
                teamDisplay={teamData[1].teamDisplay}
                teamDisplayCharacterCount={teamData[1].teamDisplayCharacterCount}
                teamLogo={teamData[1].teamLogo}
                teamColor={teamData[1].teamColor}
                isSelected={isBottomSelected}
                setIsSelected={setIsBottomSelected}
                isOppositeSelected={isTopSelected}
            />
        </Box>
    );
};

export default BracketGame;
