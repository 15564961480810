import React from 'react';
import { Typography, Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HostNoteComponent = ({
    hostNote,
    setHostNote,
    hostNoteError,
    setHostNoteError,
    order,
    editing,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    // Improved sanitization to allow standard characters including currency symbols
    // but still block emojis and other non-text content
    const sanitizeText = (text) => {
        // This pattern includes Unicode categories for letters, numbers, punctuation, 
        // spaces, currency symbols, and allows newlines
        return text.replace(/[^\p{L}\p{N}\p{P}\p{Z}\p{Sc}\n]/gu, '');
    };
    
    const handleNoteChange = (event) => {
        const text = event.target.value;
        const sanitizedText = sanitizeText(text);
        
        // Check if text was sanitized (contained emoji or other unwanted characters)
        if (text !== sanitizedText) {
            setHostNoteError("Only text and currency characters are allowed");
        } else if (text.length <= 1000) {
            setHostNote(text);
            if (hostNoteError) setHostNoteError(false);
        }
    };
    
    // Handle paste events to strip out any non-text content
    const handlePaste = (event) => {
        // Prevent the default paste behavior
        event.preventDefault();
        
        // Get text-only content from clipboard
        const text = event.clipboardData.getData('text/plain');
        
        // Sanitize the pasted text
        const sanitizedText = sanitizeText(text);
        
        // Insert at cursor position
        const input = event.target;
        const selectionStart = input.selectionStart;
        const selectionEnd = input.selectionEnd;
        
        const currentValue = hostNote || '';
        const newValue = 
            currentValue.substring(0, selectionStart) + 
            sanitizedText + 
            currentValue.substring(selectionEnd);
        
        if (newValue.length <= 1000) {
            setHostNote(newValue);
            
            // If sanitized text is different from original, show error
            if (text !== sanitizedText) {
                setHostNoteError("Some special characters or emojis were removed");
            } else {
                if (hostNoteError) setHostNoteError(false);
            }
        }
    };
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
                marginTop: '16px',
            }}
        >
            {!editing && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '15px' : '20px',
                        fontWeight: '700',
                        marginBottom: '10px',
                        textAlign: 'left',
                    }}
                >
                    {order}. Host Note (Appears on home page.)
                </Typography>
            )}

            {editing && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '15px' : '20px',
                        fontWeight: '700',
                        marginBottom: '10px',
                        textAlign: 'center',
                    }}
                >
                    Edit Host Note
                </Typography>
            )}
            
            <TextField
                multiline
                rows={editing ? 10 : 4}
                value={hostNote || ''}
                onChange={handleNoteChange}
                onPaste={handlePaste}
                placeholder="Add message for pool members (text only, no emojis or media)"
                variant="outlined"
                error={!!hostNoteError}
                helperText={hostNoteError || ""}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: hostNoteError ? '#d32f2f' : '#002129',
                        },
                        '&:hover fieldset': {
                            borderColor: hostNoteError ? '#d32f2f' : '#002129',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: hostNoteError ? '#d32f2f' : '#002129',
                        },
                    },
                }}
                inputProps={{ 
                    maxLength: 1000,
                    accept: "text/plain",
                }}
            />
            
            <Typography
                sx={{
                    fontSize: '14px',
                    color: '#666',
                    textAlign: 'right',
                    marginTop: '4px',
                }}
            >
                {(hostNote?.length || 0)}/1000
            </Typography>
        </Box>
    );
};

export default HostNoteComponent;