import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useNFLOddsByWeek = () => {
  return useMutation({
    mutationFn: nflOddsByWeekRequest,
    onError: (error) => {
      console.error('Error getting NFL Odds By Week:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Get NFL Odds By Week:', data);
    // }
  });
}

const nflOddsByWeekRequest = async () => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.get(`${apiUrl}/sports-data/get-nfl-odds-by-week`, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};