import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NotInParty from "./NotInParty";
import MyPartyCreation from "./MyPartyCreation";
import MyPartyCommissionerPage from "./MyPartyCommissionerPage";
import MyPartyManageMembers from "./MyPartyManageMembers";


const MyParty = ({ 
    gameData,
    myUserId, 
    handleCreateSubgroup, 
    userSubgroups, 
    signUpState, 
    setSignUpState, 
    gameAddress, 
    leaderboardData, 
    fullLeaderboard, 
    partyState, 
    partyId, 
    defaultPartyId, 
    doesUserBelong, 
    joinOpen,
    setJoinOpen,
    isGameOver 
}) => {
    //console.log("MyParty gameData:", gameData, "myUserId:", myUserId, "userSubgroups:", userSubgroups, "GameAddress:", gameAddress);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const gameName = gameData?.gameName;

    const subgroupInfo = userSubgroups?.find(subgroup => subgroup.subGroupID === partyId);
    const amICommissioner = subgroupInfo?.commissioner;
    const amICreator = subgroupInfo?.creator;
    //console.log("MyParty subgroupInfo:", subgroupInfo, amICommissioner, amICreator);


    if (signUpState) {
        return (
            <MyPartyCreation
                gameData={gameData} 
                myUserId={myUserId} 
                handleCreateSubgroup={handleCreateSubgroup} 
                userSubgroups={userSubgroups}
                gameAddress={gameAddress}
            />
        );
    }

    if (userSubgroups.length === 0) {
        return (
            <NotInParty 
                gameName={gameName} 
                setSignUpState={setSignUpState} 
                gameAddress={gameAddress}
                doesUserBelong={doesUserBelong}
                setJoinOpen={setJoinOpen}
            />
        );
    }

    if (amICommissioner || amICreator) {
        return (
            <MyPartyCommissionerPage 
                gameData={gameData} 
                myUserId={myUserId} 
                userSubgroups={userSubgroups} 
                gameAddress={gameAddress} 
                leaderboardData={leaderboardData}
                partyState={partyState}
                amICommissioner={amICommissioner}
                amICreator={amICreator}
                subgroupInfo={subgroupInfo}
                fullLeaderboard={fullLeaderboard}
                doesUserBelong={doesUserBelong}
                setSignUpState={setSignUpState}
                joinOpen={joinOpen}
                setJoinOpen={setJoinOpen}
                isGameOver={isGameOver}
            />
        );
    }

    return (
        <MyPartyManageMembers 
            gameData={gameData} 
            myUserId={myUserId} 
            userSubgroups={userSubgroups} 
            gameAddress={gameAddress} 
            leaderboardData={leaderboardData} 
            partyState={partyState} 
            amICommissioner={amICommissioner} 
            amICreator={amICreator} 
            subgroupInfo={subgroupInfo} 
            fullLeaderboard={fullLeaderboard}
            doesUserBelong={doesUserBelong}
            setJoinOpen={setJoinOpen}
            setSignUpState={setSignUpState}
            gameName={gameName}
        />
    );
}

export default MyParty;