import React, { useState, useEffect, useRef} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useSportsBookContext } from './context/sportsBookContext';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from '../../contexts/ThemeProvider';

const BetSlip = (props) => {

  const { betsToDisplay, sportsBookNav, setSportsBookNav, userSelections, setUserSelections, submitBet, parlayMessage, setParlayMessage,  teaserMessage,  setTeaserMessage, bankrollAmount, setBankRollAmount,  sportsInUserSelections, filteredDropdownMenu, orderValueCount, teaserOddsCalculations, teaserDropDown,  setTeaserDropDown, winningTotal, setWinningTotal, createUserBets, groupWager, setGroupWager, teaserOdds, setTeaserOdds,   caclulateAmericanToDecimalOdds,
    caclulateDecimalOddsToAmerican} = useSportsBookContext();

  console.log('this should be an array of 3', )
  const lastBetRef = useRef(null);

  const defaultTheme = createTheme();

  const [isToggled, setIsToggled] = useState(false);
  const [teaser, setTeaser] = useState([]);
  const [mirroredTeaserValue, setMirroredTeaserValue] = useState();

  const handleChange = (event) => {
    setTeaser(event.target.value);
    setTeaserOdds(event.target.value);
  };

  console.log(winningTotal);
  useEffect(() => {
    setWinningTotal(userSelections.map(() => ({ wager: "", mirrored: "" })));
    if (lastBetRef.current) {
      lastBetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [userSelections, setWinningTotal]);

  console.log("betslip", userSelections, userSelections.length);
  const clearAll = () => {
    setUserSelections([]);
  };

  function formatDateTime(dateTime) {
    const date = new Date(dateTime);
    const options = {
      weekday: "short",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  function handleGroupWagerChange(e) {
    // let AmericanOdds = caclulateAmericanToDecimalOdds(value, teaserOdds);
    console.log('value', e.target.value, teaser);
    let value = e.target.value;
    let {AmericanOdds, DecimalOdds} = teaser;
    let groupBetOdds = caclulateDecimalOddsToAmerican(value,AmericanOdds, DecimalOdds);

    let groupWagerMirrored = caclulateAmericanToDecimalOdds(value,AmericanOdds, DecimalOdds);
    console.log('groupBetOdds', groupBetOdds, groupWagerMirrored);
    setMirroredTeaserValue(groupWagerMirrored);
    setGroupWager(value);

  }
function handleDropDown(item){
  console.log('item', item);
  setTeaserOdds(item);
}
  // Function to update mirrored value (potential winnings) when wager changes
  function handleWagerChange(index, wagerValue) {
    let decimalOdds = userSelections[index].DecimalOdds;

    let mirroredValue = (
      parseFloat(wagerValue) * decimalOdds -
      parseFloat(wagerValue)
    ).toFixed(2);

    if (/^[0-9]*$/.test(wagerValue)) {
      setWinningTotal((prevWinningTotal) =>
        prevWinningTotal.map((value, i) =>
          i === index
            ? {
                ...value,
                wager: wagerValue,
                mirrored: isNaN(mirroredValue) ? "" : mirroredValue,
              }
            : value
        )
      );
    }
    setBankRollAmount(bankrollAmount - wagerValue);
    userSelections[index].Wager = wagerValue;
    createUserBets( userSelections );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div
          style={{
            background: "#ffffff",
            padding: 20,
            borderRadius: 15,
            position: "fixed",
            width: "25%",
            maxHeight: 650,
            overflow: "scroll",
            top: "75px"
          }}
        >
          <h1>{userSelections.length} Bet Slip</h1>
          <div onClick={() => clearAll()}>CLEAR ALL</div>
          <div>Bank Roll: $ {bankrollAmount}</div>
          <hr></hr>
          {userSelections.length > 0
            ? userSelections.map((selection, i) => {
                const formattedOdds =
                  selection.Odds > 0 ? `+${selection.Odds}` : selection.Odds;
                const formattedLine =
                  selection.Line !== null
                    ? selection.Line > 0
                      ? `(+${selection.Line})`
                      : `(${selection.Line})`
                    : "";
                const teamName =
                  selection.BetChoice === "AWAY"
                    ? selection.AwayTeamName
                    : selection.HomeTeamName;

                const isLastItem = i === userSelections.length - 1;

                return (
                  <div
                    key={i}
                    ref={isLastItem ? lastBetRef : null} // Attach the ref to the last item
                  >
                    {selection.BetType === "TOTAL" ? (
                      <>
                        {selection.BetChoice} {selection.Line} {formattedOdds}
                        <br />
                        {selection.BetType}
                        <br />
                        {selection.AwayTeamName} @ {selection.HomeTeamName} (
                        {formatDateTime(selection.DateTime)})
                        <div className="input-container">
                          <span className="dollar-sign">$</span>
                          <TextField
                            type="text"
                            className="numeric-input"
                            value={winningTotal[i] ? winningTotal[i].wager : ""}
                            onChange={(e) =>
                              handleWagerChange(i, e.target.value)
                            }
                            placeholder="Enter wager"
                          />
                          {/* Mirrored Value (Potential Winnings) */}
                          <div style={{ width: "50%" }}>
                            Potential Winnings:{" "}
                            {winningTotal[i]?.mirrored
                              ? winningTotal[i]?.mirrored
                              : ""}
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    ) : null}
                    {selection.BetChoice === "AWAY" ||
                    selection.BetChoice === "HOME" ? (
                      <>
                        {teamName} {formattedLine} {formattedOdds}
                        <br />
                        {selection.BetType}
                        <br />
                        {selection.AwayTeamName} @ {selection.HomeTeamName} (
                        {formatDateTime(selection.DateTime)})
                        <div className="input-container">
                          <span className="dollar-sign">$</span>
                          <TextField
                            type="text"
                            className="numeric-input"
                            value={winningTotal[i] ? winningTotal[i].wager : ""}
                            onChange={(e) =>
                              handleWagerChange(i, e.target.value)
                            }
                            placeholder="Enter wager"
                          />
                          {/* Mirrored Value (Potential Winnings) */}
                          <div style={{ width: "50%" }}>
                            Potential Winnings:{" "}
                            {winningTotal[i]?.mirrored
                              ? winningTotal[i]?.mirrored
                              : ""}
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    ) : null}
                  </div>
                );
              })
            : null}
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant={isToggled ? "contained" : "outlined"}
                color="primary"
                onClick={() => setIsToggled(true)}
              >
                Parlay
              </Button>
              <Button
                variant={!isToggled ? "contained" : "outlined"}
                color="secondary"
                onClick={() => setIsToggled(false)}
              >
                Teaser
              </Button>
            </div>
            {isToggled ? <>{parlayMessage}</>
            : <>
              {teaserMessage}

                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={teaser}
                    label="Teaser"
                    onChange={(e)=>handleChange(e)}
                  >
                    {console.log('display the menu', teaserDropDown)}
                    {teaserDropDown.length > 2 && teaserDropDown.map((item, i) => {
                      return (

                        <MenuItem key={i} value={item} >
                          {item.Sport} {item.Points} {item.AmericanOdds}
                        </MenuItem>


                      );
                    })
                  }


              </Select>
              <TextField onChange={(e)=>{ handleGroupWagerChange(e)}}/>
              {mirroredTeaserValue}
            </>}
          </div>
          <button onClick={() => submitBet()}>Submit Bet</button>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default BetSlip;
