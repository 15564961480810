import React, { useState, useEffect } from "react";
import { Typography, Box, Container, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateAPoolCard from "./CreateAPoolCards/CreateAPoolCard";
import { useCreateAPoolContext } from "./context/CreateAPoolContext";
import { useGetCreateAPoolData } from "../../hooks/pools/useGetCreateAPoolData";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const CreateAPoolHomePage = () => {
    const {
        myUserId,
    } = useCreateAPoolContext();

    const { mutate: fetchCreateAPoolData, data: createAPoolData, loading: createAPoolLoading, error: createAPoolError } = useGetCreateAPoolData();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const location = useLocation();
    const navigate = useNavigate();

    // Get filter from URL query parameter
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get('filter');
    
    // Initialize filter state from URL or default to 'all'
    const [filter, setFilter] = useState(filterParam || 'all');
    
    // Update URL when filter changes
    const handleFilterChange = (newFilter) => {
        if (newFilter === 'all') {
            // Remove filter from URL when set to 'all'
            queryParams.delete('filter');
            navigate({ search: queryParams.toString() });
        } else {
            // Add filter to URL for other values
            queryParams.set('filter', newFilter);
            navigate({ search: queryParams.toString() });
        }
        setFilter(newFilter);
    };

    // Use Effect to fetch create a pool data
    useEffect(() => {
        fetchCreateAPoolData();
    }, [fetchCreateAPoolData]);

    //console.log("Create A Pool Data: ", createAPoolData);

    const filteredPools = createAPoolData?.filter(pool =>
        filter === 'all' ? true : pool?.filter === filter
    );

    // Render cards based on createAPoolData
    const renderPoolCards = () => {
        if (!createAPoolData || !createAPoolData?.length) {
            return <LoadingScreen />;
        }

        return (
            <Grid 
                container 
                spacing={3} // 28px spacing between cards (MUI spacing of 3 = 24px, so we'll add a bit more margin)
                sx={{ 
                    mt: 0.5,
                    justifyContent: 'center', // Center horizontally
                    px: 3.5 // Add 28px padding on the sides (3.5 = 28px in MUI)
                }}
            >
                {filteredPools?.map((pool, index) => (
                    <Grid 
                        item 
                        xs={12}
                        sx={{ 
                            display: 'flex',
                            justifyContent: 'center',
                            // Custom breakpoints for the specific widths you want
                            [theme.breakpoints.up(725)]: {
                                width: '50%', // 2 per row
                                maxWidth: '50%',
                                flexBasis: '50%'
                            },
                            [theme.breakpoints.up(1100)]: {
                                width: '33.333%', // 3 per row
                                maxWidth: '33.333%',
                                flexBasis: '33.333%'
                            }
                        }}
                        key={index}
                    >
                        <CreateAPoolCard 
                            poolInfo={pool} 
                            myUserId={myUserId}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <>
            <Typography
                sx={{
                    fontSize: isMobile ? '20px' : '28px',
                    fontWeight: '700',
                    color: '#002129',
                    textAlign: 'center',
                    padding: '16px'
                }}
            >
                CBB Tourney Takeover
            </Typography>
            
            <Box
                sx={{
                    marginBottom: '32px',
                }}
                >
                <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '350px', margin:'0px auto'}}>
                <span
                    style={{
                        display: 'block',
                        padding: '12px 8px',
                        borderRadius: '6px',
                        backgroundColor: filter === 'all' ? '#E8E8E8' : null,
                        color: '#002129',
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        marginRight: isMobile ? '0px' : '70px',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={() => handleFilterChange('all')} 
                >
                    All
                </span>
                <span
                    style={{
                        display: 'block',
                        padding: '12px 8px',
                        borderRadius: '6px',
                        backgroundColor: filter === 'mens' ? '#E8E8E8' : null,
                        color: '#002129',
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        marginRight: isMobile ? '0px' : '70px',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={() => handleFilterChange('mens')} 
                >
                    Mens
                </span>
                <span
                    style={{
                        display: 'block',
                        padding: '12px 8px',
                        borderRadius: '6px',
                        backgroundColor: filter === 'womens' ? '#E8E8E8' : null,
                        color: '#002129',
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        marginRight: isMobile ? '0px' : '70px',
                        whiteSpace: 'nowrap'
                    }}
                    onClick={() => handleFilterChange('womens')}
                >
                    Womens
                </span>

                </div>
            </Box>

            <Container 
                maxWidth="xl" 
                sx={{ 
                    px: { xs: 1, sm: 2, md: 3.5 },
                    pb: 3.5,
                }}
            >
                {renderPoolCards()}
            </Container>
        </>
    );
}

export default CreateAPoolHomePage;