import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./../BirdieRules.scss";
import PoolPartyTrophyGold from '../../../../assets/images/PoolPartyTrophyGold.svg';
import PoolPartyTrophyGray from '../../../../assets/images/PoolPartyTrophyGray.svg';
import BronzeTrophy from '../../../../assets/images/BronzeTrophy.svg';
import { useBirdieContext } from "../../context/BirdiePoolContext";


const ChartiersCCBirdieRules = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { headerDisplayName, groupState, birdiePoolData, clubLeaderboardInfo } = useBirdieContext();
  const isThisMainGame = headerDisplayName === groupState;
  const gameLeaderboard = clubLeaderboardInfo?.leaderboardInfo;
  const gameCreator = gameLeaderboard?.find(entry => entry.creator === true);
  const creatorName = gameCreator?.userName;
  const coHostsNames = gameLeaderboard?.filter(entry => entry.commissioner === true && entry.creator === false).map(entry => entry.userName);
  //console.log("HEADER DISPLAY NAME and GROUP STATE", headerDisplayName, groupState, isThisMainGame, clubLeaderboardInfo, gameCreator, creatorName, coHostsNames);


  return (
    <Box className="rules-container">
      {isThisMainGame && (
      <>
        <Typography variant="h4" className="club-headers">
          CCC <br /> Commisioner Rules
          </Typography>

        <Typography variant="h5" className="rules-headers" style={{ marginTop: '20px', fontWeight: '600'}}>
          Pool Objective
        </Typography>

        <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
          {isMobile ? (
            <>
              Be the first player to <br /> birdie each of the 18 holes.
            </>
          ) : (
            <>
              Be the first player to birdie each of the 18 holes.
            </>
          )}
        </Typography>
      </>
      )}

      {!isThisMainGame && (
        <>
          <Typography variant="h5" className="club-headers" style={{ fontWeight: '600'}}>
            {groupState} <br /> Party Rules
          </Typography>

          <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
            The following rules remain from the {headerDisplayName}.  Please reach out 
            to Host {creatorName} for any custom rules.
          </Typography>
        </>
      )}

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Duration
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        <b>Start Date:</b> Friday June 14th <br />
        <b>End Date:</b> Thursday October 31st
      </Typography>

        {isThisMainGame && (
          <>
      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Buy In
        </Typography>   

        <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
            $25 Entry (Added to account by the pro-shop.)
        </Typography>

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
        Pool Prizes
      </Typography>

      <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
        <img src={PoolPartyTrophyGold} alt="Gold Trophy" style={{ width: isMobile ? '15px' : '20px', display: 'inline-block', marginRight: '5px', marginBottom: '10px' }} />
        <b>1st Place</b> - 65% of the Pool Pot <br /> 
        <img src={PoolPartyTrophyGray} alt="Silver Trophy" style={{ width: isMobile ? '15px' : '20px', display: 'inline-block', marginRight: '5px', marginBottom: '10px'  }} />
        <b>2nd Place</b> - 25% of the Pool Pot <br /> 
        <img src={BronzeTrophy} alt="Bronze Trophy" style={{ width: isMobile ? '15px' : '20px', display: 'inline-block', marginRight: '5px', marginBottom: '10px'  }} />
        <b>3rd Place</b> - 10% of the Pool Pot
        </Typography>
        </>
        )}

      <Typography variant="h5" className="rules-headers" style={{ marginTop: '15px', fontWeight: '600'}}>
          Pool Rules  
        </Typography>

        <Box sx={{ maxWidth: '475px', margin: '0 auto', textAlign: 'left' }}>
          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>1. Gross</span> 
            <span> - Only birdies achieved on your gross score (without handicap adjustments) will be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>2. Birdies or Better</span> 
            <span> - Birdies, eagles, and albatrosses will count as eligible birdies.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>3. No solo round birdies</span> 
            <span> - You must be playing with at least 1 other person during your round for a birdie to be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>4. Official Rounds Only</span> 
            <span> - Only birdies that occur during an Official Round (9 or 18 holes) will be eligible.</span>
          </Typography>

          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>5. Tee Selection</span> 
            <span> - Eligible birdies must be from competition level tees.<br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>  <b>Non-Senior Men:</b> Blue or Burgundy Tees <br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>  <b>Senior Men:</b> White, Blue or Burgundy Tees <br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}><b>Women:</b> Orange Tees (or beyond)</span>
          </Typography>

            {isThisMainGame && (
          <Typography variant="body1" className="actual-rules-headers" style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>6. Tiebreaker</span> 
            <span> - If nobody birdies all 18 holes, the tiebreaker will be as follows:<br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>   <b>a.</b> Most Holes Birdied<br /></span>
            <span style={{ marginLeft: isMobile ? '0px' : '20px'}}>   <b>b.</b> Total Birdies</span>
          </Typography>
          )}
        </Box>

        {isThisMainGame && (
          <>
          <Typography variant="h4" className="club-headers" style={{ marginTop: '35px' }}>
            Pool Party Extras
          </Typography>

        <Typography variant="body1" className="rules-text" style={{ marginTop: '15px'}}>
          <span style={{ fontWeight: 'bold' }}>Private Parties</span> - In addition to the club-wide
          pool, you can create a competition with your custom group.
        </Typography>

        <Typography variant="body1" className="rules-text" style={{ marginTop: '20px', marginBottom: '30px'}}>
          <span style={{ fontWeight: 'bold' }}>Total Birdies</span> - Beyond tiebreakers,
          feel free to implement Total Birdies into your My Party Pools.
        </Typography>
        </>
        )}

    </Box>
  );
}

export default ChartiersCCBirdieRules;

