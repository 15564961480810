import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  INPUT_CHARACTERS_LIMIT,
  MESSAGE_CHARACTERS_LIMIT,
  VALIDATION_MESSAGES,
} from "../constants";
import { useDispatch } from "react-redux";
import { emailContactAction } from "../redux/actions/loginImport";

import greenGrass from "../assets/images/stadium-background.webp";

export default function ContactPage() {
  const [contactDetails, setContactDetails] = useState({
    fullName: "",
    email: "",
    contactType: "",
    message: "",
  });
  const [contactValidationDetails, setContactValidationDetails] = useState({
    fullName: {
      isError: false,
      message: "",
    },
    email: {
      isError: false,
      message: "",
    },
    contactType: {
      isError: false,
      message: "",
    },
    message: {
      isError: false,
      message: "",
    },
  });

  const [contactSubmission, setContactSubmission] = useState(
    localStorage.getItem("submitContact")
  );

  const dispatch = useDispatch();
  const emailValidationTimeoutRef = useRef(null); // Ref to store the timeout ID


  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function onContactDetailsChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    let tempContactDetails = contactDetails;

    setContactDetails({ ...contactDetails, [name]: value });
    tempContactDetails[name] = value;

    const sanitizedValue = value.trim();

    if (name === "message") {
      if (sanitizedValue.length > MESSAGE_CHARACTERS_LIMIT) {
        setContactValidationDetails({
          ...contactValidationDetails,
          [name]: {
            isError: true,
            message: VALIDATION_MESSAGES.CHARACTER_LIMIT(
              MESSAGE_CHARACTERS_LIMIT
            ),
          },
        });
      } else {
        setContactValidationDetails({
          ...contactValidationDetails,
          [name]: {
            isError: false,
            message: "",
          },
        });
      }
      return;
    }

    if (name === "email") {
      // Clear any existing timeout to reset the debounce timer
      if (emailValidationTimeoutRef.current) {
        clearTimeout(emailValidationTimeoutRef.current);
      }

      // Set a new timeout to validate the email after 1 second of inactivity
      emailValidationTimeoutRef.current = setTimeout(() => {
        if (!validateEmail(sanitizedValue)) {
          setContactValidationDetails({
            ...contactValidationDetails,
            [name]: {
              isError: true,
              message: "Please enter a valid email address.",
            },
          });
        } else {
          setContactValidationDetails({
            ...contactValidationDetails,
            [name]: { isError: false, message: "" },
          });
        }
      }, 1000);
      return;
    }

    if (sanitizedValue.length > INPUT_CHARACTERS_LIMIT) {
      setContactValidationDetails({
        ...contactValidationDetails,
        [name]: {
          isError: true,
          message: VALIDATION_MESSAGES.CHARACTER_LIMIT(),
        },
      });
    } else {
      setContactValidationDetails({
        ...contactValidationDetails,
        [name]: { isError: false, message: "" },
      });
    }
  }

  function onSubmit() {
    const sanitizedEmail = contactDetails.email.trim();
    const sanitizedFullName = contactDetails.fullName.trim();
    const sanitizedContactType = contactDetails.contactType.trim();
    const sanitizedMessage = contactDetails.message.trim();

    setContactDetails({
      fullName: sanitizedFullName,
      email: sanitizedEmail,
      contactType: sanitizedContactType,
      message: sanitizedMessage,
    });
    setContactValidationDetails({
      fullName: {
        isError: !Boolean(sanitizedFullName),
        message: Boolean(sanitizedFullName) ? "" : "Please enter full name.",
      },
      email: {
        isError: !validateEmail(sanitizedEmail),
        message: validateEmail(sanitizedEmail) ? "" : "Please enter a valid email address.",
      },
      contactType: {
        isError: !Boolean(sanitizedContactType),
        message: Boolean(sanitizedContactType) ? "" : "Please choose a contact type.",
      },
      message: {
        isError: !Boolean(sanitizedMessage),
        message: Boolean(sanitizedMessage) ? "" : "Please write your message.",
      },
    });

    if (!sanitizedFullName || !validateEmail(sanitizedEmail) || !sanitizedContactType || !sanitizedMessage) {
      return;
    }

    dispatch(emailContactAction({ contactDetails }));
    setContactSubmission("true");
  }

  return (
    <>
      <Box sx={{ minHeight: "calc(100lvh - 64px)" }}>
        {contactSubmission ? (
          <>
            <Box
              sx={{
                height: "40vh",
                background: `URL(${greenGrass}) center center/cover no-repeat`,
                minWidth: "calc(100vw - 33px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "55px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--color-white)",
                  fontSize: { xs: "3.2rem", lg: "5rem" },
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "800",
                  lineHeight: "150%",
                  letterSpacing: "-1.52px",
                }}
                variant="h1"
              >
                CONTACT US
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--color-black)",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "150%",
                fontSize: "48px",
                letterSpacing: "-0.87px",
                marginBottom: "28px",
                textAlign: "center",
              }}
              variant="h2"
            >
              Thank You for Reaching Out
            </Typography>
            <Typography>
              Thank you for your message.
              <br /> You will be hearing from The Pool Party team in the very
              near future.
            </Typography>
            <Button
              variant="contained"
              sx={{ width: "20%", minWidth: "155px", mt: 3, whiteSpace: "nowrap" }}
              href="/"
            >
              Pool Party Home
            </Button>
          </>
        ) : (
          <>
            <Box
              sx={{
                height: "40vh",
                background: `URL(${greenGrass}) center center/cover no-repeat`,
                minWidth: "calc(100vw - 33px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "var(--color-white)",
                  fontSize: { xs: "3.2rem", lg: "5rem" },
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "800",
                  lineHeight: "150%",
                  letterSpacing: "-1.52px",
                }}
                variant="h1"
              >
                CONTACT US
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", lg: "68%" },
                margin: "auto",
                textAlign: "center",
                padding: "2rem 0",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                px: 3,
              }}
            >
              <Typography
                sx={{
                  color: "var(--color-black)",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "800",
                  lineHeight: "150%",
                  letterSpacing: "-0.87px",
                  textAlign: "center",
                }}
                variant="h1"
              >
                We want to hear from you!
              </Typography>
              <Typography
                sx={{
                  color: "var(--color-text)",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "0.875rem",
                  lineHeight: "170%",
                  letterSpacing: "-0.266px",
                  maxWidth: "822px",
                  margin: "0 auto",
                }}
                variant="body1"
              >
                Having a technical issue? Any questions? Or maybe you have a
                unique Sports Pool idea?
                <br /> <br />Please, let’s chat. We are eager to engage with you and
                collaboratively create an extraordinary platform together.
              </Typography>

              <Box
                sx={{
                  width: { xs: "80%", sm: "60%" },
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.2rem",
                  pt: 2,
                }}
              >
                <TextField
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  variant="outlined"
                  type="text"
                  value={contactDetails.fullName}
                  error={contactValidationDetails.fullName.isError}
                  helperText={contactValidationDetails.fullName.message}
                  onInput={onContactDetailsChange}
                  required
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email address"
                  variant="outlined"
                  type="email"
                  value={contactDetails.email}
                  error={contactValidationDetails.email.isError}
                  helperText={contactValidationDetails.email.message}
                  onInput={onContactDetailsChange}
                  required
                />

                <FormControl
                  fullWidth
                  error={contactValidationDetails.contactType.isError}
                  required
                >
                  <InputLabel id="contactType-label">Contact Type</InputLabel>
                  <Select
                    labelId="contactType-label"
                    id="contactTypeOptions"
                    name="contactType"
                    value={contactDetails.contactType}
                    onChange={onContactDetailsChange}
                    sx={{
                      ".MuiSelect-select": {
                        // This targets the internal <div> element that holds the selected value
                        textAlign: "left",
                        alignItems: "center", // Ensures vertical centering if needed
                      },
                    }}
                    label="Contact Type" // This is necessary for proper accessibility and label floating
                  >
                    <MenuItem value={"Technical Issue"}>
                      Technical Issue
                    </MenuItem>
                    <MenuItem value={"Question"}>Question</MenuItem>
                    <MenuItem value={"Feedback"}>Feedback</MenuItem>
                    <MenuItem value={"Pool Idea"}>Pool Idea</MenuItem>
                    <MenuItem value={"Partnerships"}>Partnerships</MenuItem>
                  </Select>
                  {contactValidationDetails.contactType.isError && (
                    <Typography color="error" variant="caption" className="block text-left pl-4 pt-1" >
                      {contactValidationDetails.contactType.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  multiline
                  rows={4}
                  inputProps={{
                    maxLength: MESSAGE_CHARACTERS_LIMIT, // Setting maximum character length
                  }}
                  value={contactDetails.message}
                  error={contactValidationDetails.message.isError}
                  helperText={contactValidationDetails.message.message}
                  onInput={onContactDetailsChange}
                  required
                />
                <Button
                  variant="contained"
                  sx={{ width: "16%", minWidth: "6rem", mt: 3 }}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
