import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box  } from '@mui/material';
import ChampPicksTeamCard from './ChampPicksTeamCard/ChampPicksTeamCard';
import PercentagePillBoxWithText from '../../PercentagePillBox/PercentagePillBoxWithText';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';

const ChampPicks = ({
    leaderboard,
    teams,
    seeds,
    teamsOrNames,
    getChampInfo,
    hasJoinDeadlinePassed,
    countdownDeadline,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //Go through Seeds to make sure all have a GlobalTeamID
    const areSeedsIn = seeds.every(seed => seed.GlobalTeamID);
    //console.log("Are Seeds In:", areSeedsIn);


    // State to manage sorting
    const [sortConfig, setSortConfig] = useState({
        key: 'count', // Default sort by count
        direction: 'descending' // Default to descending order
    });

    // Use Effect To Sort by Seed originally if !hasJoinDeadlinePassed
    useEffect(() => {
        //console.log("Setting Sort Config");
        if (!hasJoinDeadlinePassed) {
            setSortConfig({
                key: 'seed',
                direction: 'ascending',
            });
        }
    }, [hasJoinDeadlinePassed]);



    const processChampStats = (leaderboard, seeds) => {
        const champStats = {};
        let totalPickCount = 0;
    
        // Initialize champStats with all seeds from 1 to 12
        for (let i = 1; i <= 12; i++) {
            const teamSeed = seeds.find(seed => seed.seed === i)?.GlobalTeamID;
            champStats[i] = {
                teamId: teamSeed,
                count: 0,
                result: null,
                seed: i,
            };
        }
    
        leaderboard?.forEach(entry => {
            // Skip if no champ
            if (!entry.champ) return;
    
            totalPickCount++;
            const teamId = entry.champ;
    
            // Increment the count for the champ team
            const champEntry = Object.values(champStats).find(stat => stat.teamId === teamId);
            if (champEntry) {
                champEntry.count++;
    
                // Update result if it hasn't been set or if current result is different
                if (!champEntry.result) {
                    champEntry.result = entry.champResult;
                }
            }
        });
    
        return {
            totalPickCount,
            champStats: Object.values(champStats),
        };
    };
    

    const champStats = processChampStats(leaderboard, seeds);
    const totalPickCount = champStats?.totalPickCount;
    const champStatsArray = champStats?.champStats;
    //console.log('champStatsArray:', champStatsArray);

    // Memoized sorted array that updates based on sortConfig
    const sortedChampStats = useMemo(() => {
        if (!champStatsArray) return [];

        return [...champStatsArray].sort((a, b) => {
            if (sortConfig.key === 'seed') {
                // For seed, lower seed number comes first
                return sortConfig.direction === 'ascending' 
                    ? (a.seed - b.seed) 
                    : (b.seed - a.seed);
            } else {
                // For count, sort by count
                return sortConfig.direction === 'ascending'
                    ? (a.count - b.count)
                    : (b.count - a.count);
            }
        });
    }, [champStatsArray, sortConfig]);
    
    // Handle sorting toggle
    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            // If sorting the same key, toggle direction
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
                };
            }
            // If new key, default to descending for count, ascending for seed
            return {
                key,
                direction: key === 'count' ? 'descending' : 'ascending'
            };
        });
    };


    const formatDate = (dateString) => {
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' }); // Use ET timezone
        return date.toFormat("EEE MM/dd '@' h:mma 'ET'");
    };

    return (
        <>
            {!hasJoinDeadlinePassed && (
                <Box>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '20px' : '24px',
                            fontWeight: '700',
                            marginTop: '24px',
                        }}
                    >
                        Champion Picks Distribution:<br /> Coming {formatDate(countdownDeadline)}
                    </Typography>
                </Box>
            )}

            <TableContainer 
                component={Paper}
                sx={{
                    maxWidth: '700px',
                    margin: '0 auto',
                    borderRadius: '0px',
                    marginTop: '24px',
                }}    
            >
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: '#002129',
                                height: '56px',
                            }}
                        >
                            <TableCell
                                sx={{
                                    width: '51%',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleSort('seed')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        textDecoration: 'underline',
                                        color: sortConfig.key === 'seed' ? '#FFC60A' : '#fff',
                                    }}
                                >
                                    CHAMPION
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '49%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    cursor: hasJoinDeadlinePassed ? 'pointer' : 'default', // Change cursor style
                                }}
                                onClick={() => {
                                    if (hasJoinDeadlinePassed) {
                                        handleSort('count'); // Only call if condition is true
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center',
                                        textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                        color: sortConfig.key === 'count' ? '#FFC60A' : '#fff',
                                    }}
                                >
                                    TIMES PICKED
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedChampStats?.map((champStat, index) => {
                            const teamId = champStat?.teamId;
                            const result = champStat?.result;
                            const count = champStat?.count;
                            const teamInfo = getChampInfo(teamId, teams, seeds, teamsOrNames);

                            return (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F4F4F4',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            padding: '16px 0px 16px 0px',
                                        }}
                                    >
                                        <ChampPicksTeamCard
                                            teamInfo={{
                                                seed: teamInfo?.seed,
                                                nameDisplay: teamInfo?.nameDisplay,
                                                teamDisplayCharacterCount: teamInfo?.teamDisplayCharacterCount,
                                                logo: teamInfo?.logo,
                                                teamColor: teamInfo?.teamColor,
                                            }}
                                            result={result}
                                            areSeedsIn={areSeedsIn}
                                            index={index}
                                        />
                                    </TableCell>
                                    {hasJoinDeadlinePassed ? (
                                        <TableCell
                                            sx={{
                                                padding: '16px 0px 16px 0px',
                                            }}
                                        >
                                            <PercentagePillBoxWithText
                                                count={count}
                                                total={totalPickCount}
                                                result={result}
                                            />
                                        </TableCell>
                                        ) : (
                                            <TableCell
                                                sx={{
                                                    padding: '16px 0px 16px 0px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '90%',
                                                        border: '1px solid #002129',
                                                        borderRadius: '8px',
                                                        margin: '0 auto',
                                                        height: '40px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            color: '#002129',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        TBD
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ChampPicks;