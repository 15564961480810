import React from "react";
import { UserAndGameProvider } from "../contexts/UserAndGameContext";
import { QuickPickGamesProvider } from "../components/QuickPickGames/context/QuickPickGamesContext";
import QuickPickGamesContainer from "../components/QuickPickGames/QuickPickGamesContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const QuickPickPage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <UserAndGameProvider>
                    <QuickPickGamesProvider>
                        <QuickPickGamesContainer />
                    </QuickPickGamesProvider>
                </UserAndGameProvider>
            </QueryClientProvider>
        </div>
    );
};

export default QuickPickPage;