import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';


const QuickPickNFLDisplay = ({ thisCurrentWeek, currentWeek, userPicks, picksPerInterval, lastInterval, usingTiebreaker, userRewards }) => {
    //console.log("This Current Week", thisCurrentWeek);
    const weekToUse = currentWeek > lastInterval ? lastInterval : currentWeek;
    //console.log("UserRewards", userRewards, "Week to Use", weekToUse, currentWeek, lastInterval, "UserPicks", userPicks, "Picks Per Interval", picksPerInterval, "Using Tiebreaker", usingTiebreaker);

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const seasonRecord = () => {
    let wins = 0;
    let losses = 0;
    let ties = 0;
    let points = 0;
    userPicks?.picks?.forEach((pick) => {
        if (pick.result === "Win") {
        wins++;
        } else if (pick.result === "Loss") {
        losses++;
        } else if (pick.result === "Push") {
        ties++;
        }
    });
    points = wins + (ties * 0.5);
    return { wins, losses, ties, points };
    };

    const { wins: seasonWins, losses: seasonLosses, ties: seasonTies, points: seasonPoints } = seasonRecord();

    const totalPicks = userPicks?.picks?.filter(
    (pick) => pick.interval === weekToUse
    )?.length ?? 0;
    
    const totalPicksToMake = usingTiebreaker ? picksPerInterval + 1 : picksPerInterval;

    const anyUnredeemedRewards = userRewards?.find(reward => {
        // Parse the expiration date and ensure it's in New York time
        const expirationDate = DateTime.fromISO(reward.expiration).setZone('America/New_York');
        return !reward.redeemed && expirationDate > timeNowInNewYork;
    });
    //console.log("Any Unredeemed Rewards", anyUnredeemedRewards);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width:'90%'
          }}>
            {isMobile ? (
              // These items will be displayed on mobile devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>POINTS</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800', marginTop: '3px'}}>{seasonPoints}</Typography>
                </Box>
            
                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {weekToUse} STATUS</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {totalPicks === totalPicksToMake ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalPicks}/{totalPicksToMake})
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalPicks}/{totalPicksToMake})
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>Reward?</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {anyUnredeemedRewards ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                      </>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              // These items will be displayed on desktop devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>POINTS</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{seasonPoints}</Typography>
                </Box>
               
                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {weekToUse} STATUS</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {totalPicks === totalPicksToMake ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '22px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalPicks}/{totalPicksToMake})
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '22px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalPicks}/{totalPicksToMake})
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>REWARD?</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {anyUnredeemedRewards ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '22px' }} />
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '22px' }} />
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
    );

}

export default QuickPickNFLDisplay;