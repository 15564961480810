import React from "react";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const MyPicksStatus = ({interval, userPicksFromDB, picksPerInterval, selectedGameId}) => {
    //console.log("Interval in MyPicksStatus: ", interval, "User Picks From DB in MyPicksStatus: ", userPicksFromDB, "Picks Per Interval in MyPicksStatus: ", picksPerInterval);
    const picksThisInterval = userPicksFromDB?.filter((pick) => Number(pick.interval) === Number(interval) && pick.gameId === selectedGameId);

    const allPicksMade = picksThisInterval?.length === picksPerInterval;
    //console.log("Picks This")
    //console.log("Picks This Interval", picksThisInterval, "Tiebreaker This Interval", tiebreakerThisInterval, "All Picks Made", allPicksMade);

    return (
        <Box
            sx={{
                borderBottom: '2px solid #002129',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: allPicksMade ? '#00AA72' : '#CC293C',
                    backgroundColor: '#fff',
                    padding: '4px',
                }}
            >
                My Picks: {allPicksMade ? 'Entered' : 'Incomplete'} {allPicksMade ? <CheckIcon sx={{ color: '#00AA72' }} /> : <CloseIcon sx={{ color: '#CC293C' }} />}
            </Typography>
        </Box>
    );
}

export default MyPicksStatus;