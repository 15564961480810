import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";
import MyPoolsCard from "../GamePageComponents/MyPoolsCard/MyPoolsCard";
import { useGetPoolHallData } from "../../hooks/pools/useGetPoolHallData";
import PoolHallArchive from "./PoolHallArchive";
import { useGetUserData } from "../BirdiePool/hooks";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useLocation, useNavigate } from "react-router-dom";

const PoolHallContainer = () => {

    const { mutate: fetchPoolHallData, data: poolHallData, loading: poolHallLoading, error: poolHallError } = useGetPoolHallData();
    const { mutate: fetchUserData, data: userData, loading: userLoading, error: userError } = useGetUserData();
    //console.log("Public Pools", data);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();
    const navigate = useNavigate();
    
    // Use useMemo to prevent queryParams from being recreated on every render
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const pageParam = queryParams.get('page') || 'cbb';
    
    // Initialize page state from URL or default to 'cbb'
    const [page, setPage] = useState(pageParam);
    
    // Ensure page parameter is always in URL
    useEffect(() => {
        // If page is missing from URL, add it with default value
        if (!queryParams.has('page')) {
            queryParams.set('page', 'cbb');
            navigate({ search: queryParams.toString() }, { replace: true });
        }
    }, [location.search, navigate, queryParams]);
    
    // Update URL when page changes
    const handlePageChange = (newPage) => {
        queryParams.set('page', newPage);
        navigate({ search: queryParams.toString() });
        setPage(newPage);
    };

    const myUserId = getUserDataFromLocalStorage()?.user_id;

    // Use Effect to fetch user data (if myUserId is available)
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    //Use Effect to fetch pool hall data
    useEffect(() => {
        fetchPoolHallData();
    }, [fetchPoolHallData]);
    
    //console.log("Pool Hall Data", poolHallData, "user data", userData);

    const activePools = userData?.Games?.filter(game => game.masterPoolId && game.status === 'active') || [];
    //console.log("Active Pools", activePools);

    if (poolHallLoading) {
        return <LoadingScreen />;
    }

    // Render cards based on poolHallData
    const renderPoolCards = () => {
        if (!poolHallData || !poolHallData?.length) {
            return <LoadingScreen />;
        }

        return (
            <Grid 
                container 
                spacing={3} // 28px spacing between cards (MUI spacing of 3 = 24px, so we'll add a bit more margin)
                sx={{ 
                    mt: 0.5,
                    justifyContent: 'center', // Center horizontally
                    px: 3.5 // Add 28px padding on the sides (3.5 = 28px in MUI)
                }}
            >
                {poolHallData?.map((pool, index) => (
                    <Grid 
                        item 
                        xs={12}
                        sx={{ 
                            display: 'flex',
                            justifyContent: 'center',
                            // Custom breakpoints for the specific widths you want
                            [theme.breakpoints.up(725)]: {
                                width: '50%', // 2 per row
                                maxWidth: '50%',
                                flexBasis: '50%'
                            },
                            [theme.breakpoints.up(1100)]: {
                                width: '33.333%', // 3 per row
                                maxWidth: '33.333%',
                                flexBasis: '33.333%'
                            }
                        }}
                        key={index}
                    >
                        <MyPoolsCard 
                            poolInfo={pool} 
                            activePools={activePools}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };
    
    return (
        <div>
            <StaticPoolHeaderBar navigationText="Home" headerText="Pool Hall" linkForNavigation="/" />
            
            {page === 'cbb' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '16px',
                        padding: '16px',
                    }}
                >
                    <img
                        src="https://poolpartyfilestorage.blob.core.windows.net/media/CBBTakeoverBadge.png"
                        alt="CBB Takeover"
                        style={{ 
                            height: isMobile ? '100px' : '200px', 
                            width: 'auto', 
                            objectFit: 'contain' ,
                            margin: '0 auto',
                            
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '18px',
                            fontWeight: '700',
                            textAlign: 'center',
                        }}
                    >
                        4 Men's Pools.  2 Women's Pools. Now that's Madness.
                    </Typography>
                </Box>
            )}

            {page === 'archive' && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: isMobile ? '165px' : '273px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '32px' : '48px',
                            fontWeight: '700',
                            textAlign: 'center',
                        }}
                    >
                        POOL <br/> ARCHIVE
                    </Typography>
                </Box>
            )}
            
             <Box
                sx={{
                    marginBottom: '32px',
                }}
             >
                <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '350px', margin:'15px auto'}}>
                    <span
                    style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'cbb' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap'  }}
                    onClick={() => handlePageChange('cbb')}
                    >
                        CBB Tourney Takeover
                    </span>
                    <span
                        style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: page === 'archive' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap' }}
                        onClick={() => handlePageChange('archive')}
                    >
                        Archive
                    </span>
                </div>
            </Box>

            <Container 
                maxWidth="xl" 
                sx={{ 
                    px: { xs: 1, sm: 2, md: 3.5 },
                    pb: 3.5,
                }}
                >
                {page === 'cbb' && renderPoolCards()}

                {page === 'archive' && (
                    <PoolHallArchive />
                )}
            </Container>
        </div>
    );
}

export default PoolHallContainer;