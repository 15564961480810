import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useGetPoolHallData = () => {
    return useMutation({
        mutationFn: getPoolHallData,
        onError: (error) => {
            console.error("Error fetching pool hall data:", error);
        },
        // onSuccess: (data) => {
        //     console.log("Fetched pool hall data:", data);
        // }
    });
}

const getPoolHallData = async () => {
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-pool-hall-data`, {
        headers: {
            Authorization: authorizationHeader,
            "Content-Type": "application/json"
        }
    });

    return response.data;
}