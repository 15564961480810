import React, { useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Select, MenuItem, FormControl } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const QuickPickLeaderboardDropdown = ({
  interval,
  setInterval,
  seasonText,
  currentInterval,
  firstInterval,
  lastInterval,
  intervalType,
  intervalInfo,
  setLoading,
  needToSetLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  
  console.log("Interval Type", intervalType);

  // Set the interval to `firstInterval` on mount if it's not set to start with the correct value
  useEffect(() => {
    // Prevent execution if the interval is 'Season Standings'
    if (interval === 'Season Standings') {
      return;
    }

    if (firstInterval > currentInterval) {
      setInterval(firstInterval);
    } else {
      setInterval(currentInterval);
    }
  }, []); // Still runs on mount as the dependency array is empty

  // Function to get the label for a given interval
  const getIntervalLabel = (intervalValue) => {
    // Don't process non-numeric values through this function
    if (typeof intervalValue === 'string' && isNaN(Number(intervalValue))) {
      return intervalValue;
    }
    
    if (intervalInfo && intervalInfo.info) {
      const item = intervalInfo.info.find(item => item.interval === Number(intervalValue));
      return item ? item.label : `Week ${intervalValue}`;
    }
    return `Week ${intervalValue}`;
  };

  const weekOptions = [];
  if (intervalType === 'Week') {
    // Start from the `firstInterval` and go to `currentInterval`, handling both ascending and descending ranges.
    if (firstInterval <= currentInterval) {
      for (let i = firstInterval; i <= currentInterval; i++) {
        weekOptions.push(
          <MenuItem key={i} value={i}>
            {getIntervalLabel(i)}
          </MenuItem>
        );
      }
    } else {
      weekOptions.push(
        <MenuItem key={firstInterval} value={firstInterval}>
          {getIntervalLabel(firstInterval)}
        </MenuItem>
      );
    }
  }

  const handleChange = (event) => {
    const value = event.target.value;
    setInterval(value);
    
    // Update URL with new interval
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('interval', value);
    
    // Navigate to the same path but with updated query params
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    }, { replace: true }); // Use replace to avoid adding to browser history

    // Optional loading state
    // if (needToSetLoading && setLoading) {
    //   setLoading(true);
    // }
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{
        marginTop: isMobile ? '15px' : '15px',
        marginBottom: isMobile ? '15px' : '15px',
        width: isMobile ? '163px' : '200px'
      }}
    >
      <Select
        value={interval}
        onChange={handleChange}
        style={{
          textAlign: "center",
          fontSize: isMobile ? '12px' : '14px',
          padding: isMobile ? '5px' : '10px',
          height: isMobile ? '24px' : '42px',
          border: "1px solid #000000",
          borderRadius: "4px",
          boxShadow: "none", // Remove default shadow if you want a flat look
          width: '100%'
        }}
        renderValue={(selected) => {
          // Special handling for "Season Standings"
          if (selected === seasonText) {
            return seasonText;
          }
          // Otherwise use the interval label lookup
          return getIntervalLabel(selected);
        }}
      >
        {seasonText && <MenuItem value={seasonText}>{seasonText}</MenuItem>}
        {weekOptions?.reverse()?.map((option) => (
          <MenuItem key={option.key} value={option.props.value}>
            {option.props.children}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default QuickPickLeaderboardDropdown;