import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetCommunityHubData = () => {
    return useMutation({
        mutationFn: getCommunityHubDataRequest,
        onError: (error) => {
            console.error('Error fetching community hub data:', error);
        },
        // onSuccess: (data) => {
        //     console.log('Fetched community hub data successfully:', data);
        // }
    });
};

const getCommunityHubDataRequest = async ({ organizationId }) => {
    //console.log('organizationId in getCommunityHubDataRequest: ', organizationId);
    const data = { organizationId };
    
    try {
        const response = await axios.post(`${apiUrl}/games/get-community-hub-data`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        //console.log('API Response:', response.data);
        
        // Return the full organization data without trying to access nested properties
        // that might not exist
        return response.data;
    } catch (error) {
        console.error('API call failed:', error);
        throw error;
    }
}