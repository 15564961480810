import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useQuickPickNFL = () => {
    return useMutation({
        mutationFn: quickPickNFLRequest,
        onError: (error) => {
        console.error('Error getting Quick Pick NFL:', error);
        },
        // onSuccess: (data) => {
        //   console.log('Get Quick Pick NFL:', data);
        // }
    });
}

const quickPickNFLRequest = async (season) => {
    //console.log("Season", season);
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-quick-pick-nfl`, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json'
        },
        params: { season } // Pass season as a query parameter
    });
    return response.data;
};

