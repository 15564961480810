import React, { useState } from 'react';
import PicksNotSaved from '../../PicksNotSaved/PicksNotSaved';
import '../NFLPickEmStyles/PickEmWeekNav.scss';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { MenuItem, Select, Modal, Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PickEmWeekNav = () => {
    const { 
        week, 
        setWeek, 
        pickEmState, 
        setPickEmState,
        gameAddress, 
    } = useNFLPickEmContext();
    const lowerBound = 1;
    const upperBound = 18;

    const [pickNotSavedModal, setPickNotSavedModal] = useState(false);
    const [pendingWeek, setPendingWeek] = useState(null);

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const { gameId } = useParams();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleWeekChange = (direction) => {
        let newWeek = week;
        if (direction === 'prev') {
            newWeek -= 1;
        } else {
            newWeek += 1;
        }

        if (newWeek >= lowerBound && newWeek <= upperBound) {
            if (pickEmState.length === 0) {
                setWeek(newWeek);
                //console.log("New Week: ", newWeek);
                navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PICKS')}/${newWeek}`);
            } else {
                setPickNotSavedModal(true);
                setPendingWeek(newWeek);
            }
        }
    };

    const handleWeekSelectChange = (event) => {
        const newWeek = event.target.value;
        if (pickEmState.length === 0) {
            setWeek(newWeek);
            navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PICKS')}/${newWeek}`);
        } else {
            setPickNotSavedModal(true);
            setPendingWeek(newWeek);
        }
    };

    return (
        <>
       <Box 
            sx={{ 
                width: '100%', // Full width container
                backgroundColor: '#002129', // Set the background color
                padding: '0 16px', // Adjust padding to ensure the inner content is centered
                boxSizing: 'border-box'
            }}
        >
            <Box sx={{ 
                margin: '0 auto',
                width: isMobile ? '310px' : '467px'
            }}>
                <div className="game-date-navigation-bar">
                    <button className="navigation-element-icon" onClick={() => handleWeekChange('prev')} disabled={week <= lowerBound}>
                        <ArrowBackIosNewIcon />
                    </button>
                    <div className="week-select-container">
                        <Select
                            value={week}
                            onChange={handleWeekSelectChange}
                            variant="outlined"
                            className="week-select"
                            sx={{
                                color: 'white',
                                '.MuiSelect-select': {
                                    color: 'white',
                                    fontSize: '24px',
                                    fontWeight: '500',
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                },
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                    fontSize: '32px',
                                    marginLeft: '8px',
                                    marginRight: '-8px',
                                },
                                '.MuiMenuItem-root': {
                                    color: 'black',
                                    '&.Mui-selected': {
                                        color: 'white',
                                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                    },
                                },
                            }}
                        >
                            {Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
                                const weekNumber = index + lowerBound;
                                return (
                                    <MenuItem key={weekNumber} value={weekNumber}>
                                        Week {weekNumber}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                    <button className="navigation-element-icon" onClick={() => handleWeekChange('next')} disabled={week >= upperBound}>
                        <ArrowBackIosNewIcon style={{ transform: 'rotate(180deg)' }} />
                    </button>
                </div>
            </Box>
        </Box>

            <Modal
                open={pickNotSavedModal}
                onClose={() => setPickNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
            >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPickNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickEmState([]);
                                    setWeek(pendingWeek);
                                    setPickNotSavedModal(false);
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
};

export default PickEmWeekNav;
