import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useCFBTeams } from '../../../hooks/cfb';
import CheckIcon from '@mui/icons-material/Check';

const CFBSurvivorDisplay = ({ gameName, partyInfo, league, gameType, logo, currentWeek, userPicks }) => {

    const { mutate: fetchCFBTeams, data: CFBTeams } = useCFBTeams();

    useEffect(() => {
        if (league === "NCAA") {
            fetchCFBTeams();
        }
    }, [fetchCFBTeams, league]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const conferences = [
        { name: 'ACC', hasLoss: false, lossWeek: null, losingPickLogo: null, currentPickLogo: null, result: null },
        { name: 'Big 12', hasLoss: false, lossWeek: null, losingPickLogo: null, currentPickLogo: null, result: null },
        { name: 'Big Ten', hasLoss: false, lossWeek: null, losingPickLogo: null, currentPickLogo: null, result: null },
        { name: 'SEC', hasLoss: false, lossWeek: null, losingPickLogo: null, currentPickLogo: null, result: null },
    ];

    // Process all picks for each conference
    conferences.forEach(conference => {
        const conferencePicks = userPicks?.picks?.filter(pick => pick.conference === conference.name);

        if (conferencePicks?.length) {
            //console.log(`Processing picks for ${conference.name}:`, conferencePicks);

            // Check for losses
            conferencePicks.forEach(pick => {
                if (pick.result === "Loss") {
                    conference.hasLoss = true;
                    conference.lossWeek = pick.week;
                    conference.losingPickLogo = CFBTeams?.find(team => team.GlobalTeamID === pick.teamId)?.TeamLogoUrl;
                }

                // Check for the current week's pick
                if (pick.week === currentWeek) {
                    if (pick.teamId === "No Pick") {
                        //console.log(`No Pick detected for ${conference.name} in week ${currentWeek}`);
                        conference.currentPickLogo = "No Pick";
                    } else {
                        conference.currentPickLogo = CFBTeams?.find(team => team.GlobalTeamID === pick.teamId)?.TeamLogoUrl;
                    }

                    //console.log("Current Pick Logo:", conference.currentPickLogo);
                    conference.result = pick.result;
                }
            });
        }
    });

    const allPicksThisWeek = userPicks?.picks?.filter(pick => pick.week === currentWeek);
    //console.log("All Picks This Week:", allPicksThisWeek);
    const currentWeekPicks = userPicks?.picks?.filter(pick => pick.week === currentWeek && pick.teamId !== "No Pick");
    //console.log("Current Week Picks:", currentWeekPicks);
    const currentPicksLength = currentWeekPicks?.length;
    //console.log("Current Week Picks:", currentWeekPicks, currentPicksLength);
    const lossesThisWeek = allPicksThisWeek?.filter(pick => pick.result === "Loss")?.length;
    //console.log("Losses This Week:", lossesThisWeek);
    const howManyLossesCount = conferences.filter(conference => conference.hasLoss).length;
    const howManyWinsCount = userPicks?.picks?.filter(pick => pick.result === "Win").length;
    //console.log("How many wins count:", howManyWinsCount);
    const picksAvailableThisWeek = 4 - howManyLossesCount + lossesThisWeek;
    //console.log("Picks Available This Week:", picksAvailableThisWeek);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width: '90%',
            flexDirection: 'row',
            gap: isMobile ? '16px' : '32px'
        }}>
            {/* Wins Section */}
            <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                <Typography className={"pick-em-header-headings"} variant='h4'>WINS</Typography>
                <Typography className={"pick-em-header-values"} variant={isMobile ? 'h3' : 'h4'} sx={{ fontWeight: '800', marginTop: isMobile ? '12px' : '13px' }}>{howManyWinsCount}</Typography>
            </Box>

            {/* My Status Section */}
            <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                <Typography className="pick-em-header-headings" variant="h4" style={{ whiteSpace: 'nowrap' }}>
                    WEEK {currentWeek} STATUS
                </Typography>
                {howManyLossesCount < 4 ? (
                    currentPicksLength === picksAvailableThisWeek ? (
                        <Typography
                            className="pick-em-header-values"
                            variant={isMobile ? 'h3' : 'h4'}
                            sx={{ color: '#00AA72', fontWeight: '800', marginTop: isMobile ? '8px' : '12px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                            <CheckIcon sx={{ marginRight: '8px' }} />
                            ({currentPicksLength}/{picksAvailableThisWeek})
                        </Typography>
                    ) : (
                        <Typography
                            className="pick-em-header-values"
                            variant={isMobile ? 'h3' : 'h4'}
                            sx={{ color: '#CC293C', fontWeight: '800', marginTop: isMobile ? '8px' : '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <CloseIcon sx={{ marginRight: '8px' }} />
                            ({currentPicksLength}/{picksAvailableThisWeek})
                        </Typography>
                    )
                ) : (
                    <Typography
                        className="pick-em-header-values"
                        variant={isMobile ? 'h3' : 'h4'}
                        sx={{ color: '#CC293C', fontWeight: '800', marginTop: isMobile ? '14px' : '20px' }}
                    >
                        ELIMINATED
                    </Typography>
                )}
            </Box>


            {/* Display Week X Picks */}
            <Box sx={{ textAlign: isMobile ? 'center' : 'left', minWidth: '140px' }}>
                <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek} PICKS</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-start',
                    alignItems: 'center',
                    gap: isMobile ? '8px' : '16px',
                    marginTop: isMobile ? '8px' : '8px'
                }}>
                    {conferences.map((conference, index) => (
                        <Box key={index} sx={{ textAlign: 'center' }}>
                            <Box
                                sx={{
                                    width: isMobile ? '24px' : '32px',
                                    height: isMobile ? '24px' : '32px',
                                    margin: '0 auto',
                                    border: conference.result === "Win"
                                        ? '2px solid #00AA72'
                                        : (conference.result === "Loss" || conference.hasLoss)
                                        ? '2px solid #CC293C'
                                        : '1px solid #002129',
                                    backgroundColor: conference.result === "Win" ? 'rgba(0, 170, 114, 0.20)' : conference.result === "Loss" ? 'rgba(204, 41, 60, 0.20)' : conference.result === "Pending" ? '#FFF' : 'transparent',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {(conference.currentPickLogo === "No Pick") || (conference.hasLoss && conference.lossWeek < currentWeek) ? (
                                    <CloseIcon sx={{ color: '#CC293C', fontSize: isMobile ? '16px' : '24px' }} />
                                ) : (
                                    conference.currentPickLogo && (
                                        <img
                                            src={conference.currentPickLogo}
                                            alt={`${conference.name} Team Logo`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    )
                                )}
                            </Box>
                            <Typography variant='body2' sx={{ marginTop: '4px', fontWeight: '600', fontSize: isMobile ? '10px' : '12px', color: conference.hasLoss ? '#CC293C' : '#002129' }}>
                                {conference.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default CFBSurvivorDisplay;
