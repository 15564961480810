import { apiClient } from '../../apiClient';
import { userLogoutAction } from './loginImport';
import {apiUrl} from '../../config';
// Action types
export const CREATE_GAME_REQUEST = 'CREATE_GAME_REQUEST';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';

export const EDIT_GAME_REQUEST = 'EDIT_GAME_REQUEST';
export const EDIT_GAME_FAIL = 'EDIT_GAME_FAIL';
export const EDIT_GAME_SUCCESS = 'EDIT_GAME_SUCCESS';

export const DELETE_GAME_REQUEST = 'DELETE_GAME_REQUEST';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';
export const DELETE_GAME_FAIL = 'DELETE_GAME_FAIL';

export const FETCH_GAMES_REQUEST = 'FETCH_GAMES_REQUEST';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_FAIL = 'FETCH_GAMES_FAIL';

export const FETCH_GAME_BY_ID_REQUEST = 'FETCH_GAME_BY_ID_REQUEST';
export const FETCH_GAME_BY_ID_SUCCESS = 'FETCH_GAME_BY_ID_SUCCESS';
export const FETCH_GAME_BY_ID_FAIL = 'FETCH_GAME_BY_ID_FAIL';

export const SAVE_PICKS_REQUEST = 'SAVE_PICKS_REQUEST';
export const SAVE_PICKS_SUCCESS = 'SAVE_PICKS_SUCCESS';
export const SAVE_PICKS_FAIL = 'SAVE_PICKS_FAIL';

export const JOIN_GAME_REQUEST = 'JOIN_GAME_REQUEST';
export const JOIN_GAME_SUCCESS = 'JOIN_GAME_SUCCESS';
export const JOIN_GAME_FAIL = 'JOIN_GAME_FAIL';

export const JOIN_LINK_REQUEST = 'JOIN_LINK_REQUEST';
export const JOIN_LINK_SUCCESS = 'JOIN_LINK_SUCCESS';
export const JOIN_LINK_FAIL = 'JOIN_LINK_FAIL';

export const MULLIGEN_MADNES_REQUEST = 'MULLIGEN_MADNES_REQUEST';
export const MULLIGEN_MADNES_SUCCESS = 'MULLIGEN_MADNES_SUCCESS';
export const MULLIGEN_MADNES_FAIL = 'MULLIGEN_MADNES_FAIL';

export const GAME_PICKS_SAVE_REQUEST = 'GAME_PICKS_SAVE_REQUEST';
export const GAME_PICKS_SAVE_SUCCESS = 'GAME_PICKS_SAVE_SUCCESS';
export const GAME_PICKS_SAVE_FAIL = 'GAME_PICKS_SAVE_FAIL';

// Action creators
export const createGamesRequest = (data) => ({
    type: CREATE_GAME_REQUEST,
    payload: data
});

export const createGamesSuccess = (data) => ({
    type: CREATE_GAME_SUCCESS,
    payload: data,
});

export const createGamesFail = (error) => ({
    type: CREATE_GAME_FAIL,
    payload: error,
});

export const editGamesRequest = (data) => ({
    type: EDIT_GAME_REQUEST,
    payload: data
});

export const editGamesSuccess = (data) => ({
    type: EDIT_GAME_SUCCESS,

    payload: data,
});

export const editGamesFail = (error) => ({
    type: EDIT_GAME_FAIL,
    payload: error,
});

export const deleteGamesRequest = (data) => ({
    type: DELETE_GAME_REQUEST,
    payload: data
});

export const deleteGamesSuccess = (data) => ({
    type: DELETE_GAME_SUCCESS,
    payload: data,
});

export const deleteGamesFail = (error) => ({
    type: DELETE_GAME_FAIL,
    payload: error,
});

export const fetchGamesRequest = (data) => ({
    type: FETCH_GAMES_REQUEST,
    payload: data,
});

export const fetchGamesSuccess = (data) => ({
    type: FETCH_GAMES_SUCCESS,
    payload: data,
});

export const fetchGamesFail = (error) => ({
    type: FETCH_GAMES_FAIL,
    payload: error,
});

export const fetchGameByIdRequest = (data) => ({
    type: FETCH_GAME_BY_ID_REQUEST,
    payload: data
});

export const fetchGameByIdSuccess = (data) => ({
    type: FETCH_GAME_BY_ID_SUCCESS,
    payload: data,
});

export const fetchGameByIdFail = (error) => ({
    type: FETCH_GAME_BY_ID_FAIL,
    payload: error,
});

export const savePicksRequest = (data) => ({
    type: SAVE_PICKS_REQUEST,
    payload: data
});

export const savePicksSuccess = (data) => ({
    type: SAVE_PICKS_SUCCESS,
    payload: data,
});

export const savePicksFail = (error) => ({
    type: SAVE_PICKS_FAIL,
    payload: error,
});

export const joinGameRequest = (data) => ({
    type: JOIN_GAME_REQUEST,
    payload: data
});

export const joinGameSuccess = (data) => ({
    type: JOIN_GAME_SUCCESS,
    payload: data,
});

export const joinGameFail = (error) => ({
    type: JOIN_GAME_FAIL,
    payload: error,
});


export const joinLinkRequest = (data) => ({
    type: JOIN_LINK_REQUEST,
    payload: data
});

export const joinLinkSuccess = (data) => ({
    type: JOIN_LINK_SUCCESS,
    payload: data,
});

export const joinLinkFail = (error) => ({
    type: JOIN_LINK_FAIL,
    payload: error,
});

export const mulligenMadnesRequest = (data) => ({
    type: MULLIGEN_MADNES_REQUEST,
    payload: data
});

export const mulligenMadnesSuccess = (data) => ({
    type: MULLIGEN_MADNES_SUCCESS,
    payload: data,
});

export const mulligenMadnesFail = (error) => ({
    type: MULLIGEN_MADNES_FAIL,
    payload: error,
});

export const GamePicksSaveRequest = (data) => ({
    type: GAME_PICKS_SAVE_REQUEST,
    payload: data
});

export const GamePicksSaveSuccess = (data) => ({
    type: GAME_PICKS_SAVE_SUCCESS,
    payload: data,
});

export const GamePicksSaveFail = (error) => ({
    type: GAME_PICKS_SAVE_FAIL,
    payload: error,
});

// Thunk

export const createGame = (data) => async (dispatch) => {
    dispatch(createGamesRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');
    try {
        await apiClient.post(`${apiUrl}/games/create-game`, data,  {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }}).then((response) => {
            dispatch(createGamesSuccess(response.data));
            let responseData = response.data.data.game
            setCurrentGameToLocalStorage(responseData)
            // Game creation successful, navigate to the game page
            window.location.href = `/game/${responseData.poolType}/${responseData._id}`;

        });
    } catch (e) {
        dispatch(createGamesFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const editGame = (data) => async (dispatch) => {
    dispatch(editGamesRequest(data));
    try {
        const response = await apiClient.put('/api/games', data);
        dispatch(editGamesSuccess(response.data));
    } catch (e) {
        dispatch(editGamesFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const deleteGame = (data) => async (dispatch) => {
    dispatch(deleteGamesRequest(data));
    try {
        const response = await apiClient.delete('/api/games', data);
        dispatch(deleteGamesSuccess(response.data));
    } catch (e) {
        dispatch(deleteGamesFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const fetchGames = (data) => async (dispatch) => {
    dispatch(fetchGamesRequest(data));

    let authorizationHeader = localStorage.getItem('authorization');

    let payload = {
        email: data.email,
        gameType: data.poolType,

    }

    try {
        const response = await apiClient.post(`${apiUrl}/games/get-all-games`, payload,  {headers: {
            Authorization: authorizationHeader,
            withCredentials: true,
            credentials: 'include', // Set this option to include cookies (for CORS with credentials)

        }});

        dispatch(fetchGamesSuccess(response.data));
    } catch (e) {
        dispatch(fetchGamesFail(e.response.data));
        // dispatch(userLogoutAction());
    }
}

export const fetchGameById = (data) => async (dispatch) => {
    dispatch(fetchGameByIdRequest(data));
    console.log('fetchGameById', data)
    let authorizationHeader = localStorage.getItem('authorization');

    let payload = {
        gameType: "Block_Pool",
        gameID: data.gameID,
        email: "giuntin1635@gmail.com"
    }

    try {

        const response = await apiClient.post(`${apiUrl}/games/get-game/${payload.gameType}/${payload.gameID}`, payload,  {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }});

        dispatch(fetchGameByIdSuccess(response.data));
    } catch (e) {
        dispatch(fetchGameByIdFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const savePicks = (data) => async (dispatch) => {
    dispatch(savePicksRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');

    try {
        await apiClient.post(`${apiUrl}/user/save-pick`, data, {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }});
        dispatch(savePicksSuccess("success"));
    } catch (e) {
        dispatch(savePicksFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const joinGame = (data) => async (dispatch) => {
    dispatch(joinGameRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');
    let userData = getUserDataFromLocalStorage();

    if(!authorizationHeader) {
        dispatch(userLogoutAction());
    }
    if(!data.gameID) {
        dispatch(joinGameFail("No game ID provided"));
    }
    if(data.gamePlayer !== userData.email) {
        dispatch(joinGameFail("Email does not match user email! Please log in with correct account."));
    }

    try {
        await apiClient.post(`${apiUrl}/games/join-game`, data, {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }}).then((response) => {

            let responseData = response.data.data.game
            setCurrentGameToLocalStorage(responseData)

            dispatch(joinGameSuccess("success"));

            window.location.href = `/game/${responseData.poolType}/${responseData._id}`;
        });

    } catch (e) {
        dispatch(joinGameFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const joinLink = (data) => async (dispatch) => {
    dispatch(joinLinkRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');
    let userData = getUserDataFromLocalStorage();

    if(!authorizationHeader) {
        dispatch(userLogoutAction());
    }
    if(!data.gameID) {
        dispatch(joinLinkFail("No game ID provided"));
    }
    if(data.gamePlayer !== userData.email) {
        dispatch(joinLinkFail("Email does not match user email! Please log in with correct account."));
    }

    try {
        await apiClient.post(`${apiUrl}/games/join-link`, data, {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }}).then((response) => {

            let responseData = response.data.data.game
            setCurrentGameToLocalStorage(responseData)

            dispatch(joinLinkSuccess("success"));

            window.location.href = `/game/${responseData.poolType}/${responseData._id}`;
        });

    } catch (e) {
        dispatch(joinLinkFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const mulligenMadnes = (data) => async (dispatch) => {
    dispatch(mulligenMadnesRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');

    try {
        await apiClient.post(`${apiUrl}/games/mulligen-madness`, data, {headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }}).then((response) => {
            dispatch(mulligenMadnesSuccess(response.data));
        });

    } catch (e) {
        dispatch(mulligenMadnesFail(e.response.data));
        dispatch(userLogoutAction());
    }
}

export const gamePicksSave = (data) => async (dispatch) => {
    dispatch(GamePicksSaveRequest(data));
    let authorizationHeader = localStorage.getItem('authorization');

    return apiClient.post(`${apiUrl}/games/save-picks`, data, { // Ensure return is here
        headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json' // Set the appropriate content type
        }
    }).then((response) => {
        console.log(response);
        dispatch(GamePicksSaveSuccess(response.data));
        localStorage.setItem('userData', JSON.stringify(response.data));
        return response; // Return the response explicitly
    }).catch((e) => {
        dispatch(GamePicksSaveFail(e.response ? e.response.data : "An error occurred"));
        dispatch(userLogoutAction());
        throw e; // Rethrow the error to handle it in the component
    });
}


export const getCurrentGameFromLocalStorage = () => {
  const currentGame = localStorage.getItem('currentGame');
  return currentGame ? JSON.parse(currentGame) : null;
}

export const setCurrentGameToLocalStorage = (data) => {
    localStorage.setItem('currentGame', JSON.stringify(data));
}

export const getUserDataFromLocalStorage = () => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null;
}