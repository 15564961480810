import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TraditionalBetCard from "../../SportsbookComponents/TraditionalBetCard/TraditionalBetCard";
import BetSlip from "../../SportsbookComponents/BetSlip/BetSlip";
import BetReceipt from "../../SportsbookComponents/BetReceipt/BetReceipt";
import SportsbookSportSelector  from "../../SportsbookComponents/Headers/SportsbookSportSelector";
import { useFantasySportsbookContext } from "../context/FantasySportsbookContext";
import { DateTime } from "luxon";
//import BetCalculations from "../../SportsbookComponents/BetCalculations";  //REMOVE THIS AFTER TESTING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import SetDailyRedis from "../../SportsbookComponents/SetDailyRedis";
import GetGameResults from "../../SportsbookComponents/GetGameResults";

const MyBets = () => {
    const { 
        myUserId,
        pickState,
        setPickState,
        //NFLGameOddsByWeek,
        NFLSportsbookOdds,
        NFLTeams,
        CFBGameOddsByWeek,
        CFBTeams,
        currentGameInterval,
        stopPicksWarning,
        warningModalText,
        bankroll,
        betStyles,
        maxParlayLegs,
        maxTeaserLegs,
        leagues,
    } = useFantasySportsbookContext();
    //console.log("myUserId: ", myUserId, NFLSportsbookOdds, NFLTeams);

    const [betSubmitOpen, setBetSubmitOpen] = useState(false);
    const [betReceiptInfo, setBetReceiptInfo] = useState([]);
    console.log("STARTING LEAGUE IN MY BETS: ", leagues[0]);

    const [currentLeague, setCurrentLeague] = useState('');
    const [currentGames, setCurrentGames] = useState([]);
    const [currentTeams, setCurrentTeams] = useState([]);
    const [currentSport, setCurrentSport] = useState("");

    useEffect(() => {
        if (leagues?.length > 0) {
            setCurrentLeague(leagues[0]);
        }
    }, []);

    useEffect(() => {
        if (currentLeague === "NFL") {
            setCurrentGames(NFLSportsbookOdds);
            setCurrentTeams(NFLTeams);
            setCurrentSport("Football");
        } else if (currentLeague === "CFB") {
            setCurrentGames(CFBGameOddsByWeek);
            setCurrentTeams(CFBTeams);
            setCurrentSport("Football");
        }
    }, [currentLeague, NFLSportsbookOdds, CFBGameOddsByWeek]);
    console.log("Current League: ", currentLeague);
    console.log("Current Games: ", currentGames);
    console.log("Current Teams: ", currentTeams);


    function nflBetInfo(GlobalGameId) {
        let game = currentGames?.find(game => game.GlobalGameId === GlobalGameId);
        //console.log("game: ", game);
        //let odds = game?.PregameOdds?.find(odds => odds.Sportsbook === "DraftKings" && odds.Unlisted === null) || null;//ADD THIS BACK IN!!!!!!!!!!!!!
        let odds = game?.PregameOdds?.find(odds => odds.Sportsbook === "DraftKings") || null;
        //console.log("odds: ", odds);
        let homeTeam = currentTeams?.find(team => team.GlobalTeamID === game?.GlobalHomeTeamId);
        let awayTeam = currentTeams?.find(team => team.GlobalTeamID === game?.GlobalAwayTeamId);
        //console.log("homeTeam: ", homeTeam, "awayTeam: ", awayTeam);
        const homeLogo = homeTeam?.WikipediaLogoURL || homeTeam?.TeamLogoUrl;
        const awayLogo = awayTeam?.WikipediaLogoURL || awayTeam?.TeamLogoUrl;
        const homeKey = homeTeam?.Key;
        const awayKey = awayTeam?.Key;
        const homeName = homeTeam?.Name;
        const awayName = awayTeam?.Name;
        return {
            gameId: game?.GlobalGameId,
            dateTime: game?.DateTime,
            homeLogo,
            awayLogo,
            homeDisplay: `${homeKey} ${homeName}`,
            awayDisplay: `${awayKey} ${awayName}`,
            odds: odds?.Unlisted === null ? odds : null,
            sport: currentSport,
            league: currentLeague,
        }
    }

    // Sort NFLSportsbookOdds by DateTime
    const sortedGames = currentGames?.slice().sort((a, b) => {
        const dateTimeA = DateTime.fromISO(a.DateTime, { zone: 'America/New_York' });
        const dateTimeB = DateTime.fromISO(b.DateTime, { zone: 'America/New_York' });
        return dateTimeA - dateTimeB; // Ascending order
    });

    return (
        <>
            {/* {Leagues?.length > 1 && ( */}
                <Box>
                    <SportsbookSportSelector leagues={leagues} setCurrentLeague={setCurrentLeague} />
                </Box>
            {/* )} */}
            <Typography>Available Bankroll: {bankroll}</Typography>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' },  // Use md for 960px and above
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: { xs: '0px', lg: '10px' },       // Use md for 960px and above
                }}
            >
                {/* <BetCalculations /> */}
                {/* <SetDailyRedis />
                <GetGameResults /> */}
                {/* Left side: Traditional Bet Cards */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {sortedGames?.map((game) => {
                        const betInfo = nflBetInfo(game.GlobalGameId);
                        return (
                            <React.Fragment key={game.GlobalGameId}>
                                <TraditionalBetCard 
                                    pickState={pickState} 
                                    setPickState={setPickState} 
                                    betInfo={betInfo} 
                                />
                            </React.Fragment>
                        );
                    })}
                </Box>

                {/* Right side: Bet Slip */}
                <Box 
                    className="sportsbook-bet-slip-container"
                    sx={{
                        width: { xs: '100%', md: '375px' },       // Use md for 960px breakpoint
                        marginLeft: { md: '20px' },               // Apply margin on 960px and above
                        position: { xs: 'fixed', md: 'static' },  // Fixed on mobile, static on desktop
                        bottom: { xs: 0, md: 'auto' },            // Stick to bottom on mobile
                        zIndex: { xs: 1000, md: 1 },
                    }}
                >
                    <BetSlip 
                        pickState={pickState} 
                        setPickState={setPickState} 
                        currentGameInterval={currentGameInterval} 
                        setBetReceiptInfo={setBetReceiptInfo} 
                        betSubmitOpen={betSubmitOpen} 
                        setBetSubmitOpen={setBetSubmitOpen}
                        stopPicksWarning={stopPicksWarning}
                        warningModalText={warningModalText}
                        bankroll={bankroll}
                        betStyles={betStyles}
                        maxParlayLegs={maxParlayLegs}
                        maxTeaserLegs={maxTeaserLegs}
                    />
                    
                </Box>
                {/* Bet Receipt */}
                {betSubmitOpen && 
                    <BetReceipt betReceiptInfo={betReceiptInfo} />
                }
            </Box>
        </>
    );
    }

export default MyBets;