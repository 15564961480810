import React from 'react';

const NFLTeamsByConference = [
    {
        "Conference": "NFC",
        "Divisions": {
            "West": {
                "Teams": [
                    {
                        "GlobalTeamID": 1,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/7/72/Arizona_Cardinals_logo.svg"
                    },
                    {
                        "GlobalTeamID": 32,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/8/8a/Los_Angeles_Rams_logo.svg"
                    },
                    {
                        "GlobalTeamID": 30,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/8/8e/Seattle_Seahawks_logo.svg"
                    },
                    {
                        "GlobalTeamID": 31,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/3/3a/San_Francisco_49ers_logo.svg"
                    }
                ]
            },
            "South": {
                "Teams": [
                    {
                        "GlobalTeamID": 2,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/c/c5/Atlanta_Falcons_logo.svg"
                    },
                    {
                        "GlobalTeamID": 5,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/1/1c/Carolina_Panthers_logo.svg"
                    },
                    {
                        "GlobalTeamID": 22,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/5/50/New_Orleans_Saints_logo.svg"
                    },
                    {
                        "GlobalTeamID": 33,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/a/a2/Tampa_Bay_Buccaneers_logo.svg"
                    }
                ]
            },
            "North": {
                "Teams": [
                    {
                        "GlobalTeamID": 6,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/1/15/Chicago_Bears_logo_primary.svg"
                    },
                    {
                        "GlobalTeamID": 11,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/7/71/Detroit_Lions_logo.svg"
                    },
                    {
                        "GlobalTeamID": 12,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/5/50/Green_Bay_Packers_logo.svg"
                    },
                    {
                        "GlobalTeamID": 20,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/4/48/Minnesota_Vikings_logo.svg"
                    }
                ]
            },
            "East": {
                "Teams": [
                    {
                        "GlobalTeamID": 9,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/1/15/Dallas_Cowboys.svg"
                    },
                    {
                        "GlobalTeamID": 23,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/6/60/New_York_Giants_logo.svg"
                    },
                    {
                        "GlobalTeamID": 26,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/8/8e/Philadelphia_Eagles_logo.svg"
                    },
                    {
                        "GlobalTeamID": 35,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/0/0c/Washington_Commanders_logo.svg"
                    }
                ]
            }
        }
    },
    {
        "Conference": "AFC",
        "Divisions": {
            "North": {
                "Teams": [
                    {
                        "GlobalTeamID": 3,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/1/16/Baltimore_Ravens_logo.svg"
                    },
                    {
                        "GlobalTeamID": 7,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/8/81/Cincinnati_Bengals_logo.svg"
                    },
                    {
                        "GlobalTeamID": 8,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/d/d9/Cleveland_Browns_logo.svg"
                    },
                    {
                        "GlobalTeamID": 28,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/d/de/Pittsburgh_Steelers_logo.svg"
                    }
                ]
            },
            "East": {
                "Teams": [
                    {
                        "GlobalTeamID": 4,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/7/77/Buffalo_Bills_logo.svg"
                    },
                    {
                        "GlobalTeamID": 19,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/3/37/Miami_Dolphins_logo.svg"
                    },
                    {
                        "GlobalTeamID": 21,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/b/b9/New_England_Patriots_logo.svg"
                    },
                    {
                        "GlobalTeamID": 24,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/6/6b/New_York_Jets_logo.svg"
                    }
                ]
            },
            "West": {
                "Teams": [
                    {
                        "GlobalTeamID": 10,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/4/44/Denver_Broncos_logo.svg"
                    },
                    {
                        "GlobalTeamID": 16,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/e/e1/Kansas_City_Chiefs_logo.svg"
                    },
                    {
                        "GlobalTeamID": 29,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/a/a6/Los_Angeles_Chargers_logo.svg"
                    },
                    {
                        "GlobalTeamID": 25,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/4/48/Las_Vegas_Raiders_logo.svg"
                    }
                ]
            },
            "South": {
                "Teams": [
                    {
                        "GlobalTeamID": 13,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/2/28/Houston_Texans_logo.svg"
                    },
                    {
                        "GlobalTeamID": 14,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/commons/0/00/Indianapolis_Colts_logo.svg"
                    },
                    {
                        "GlobalTeamID": 15,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/7/74/Jacksonville_Jaguars_logo.svg"
                    },
                    {
                        "GlobalTeamID": 34,
                        "WikipediaLogoURL": "https://upload.wikimedia.org/wikipedia/en/c/c1/Tennessee_Titans_logo.svg"
                    }
                ]
            }
        }
    }
];

export default NFLTeamsByConference;