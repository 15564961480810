import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useAddAffiliateTracking = () => {
    return useMutation({
        mutationFn: submitAffiliateTrackingRequest,
        onError: (error) => {
            console.error('Error adding affiliate tracking:', error);
        },
        // onSuccess: (data) => {
        // console.log('Affiliate tracking added successfully:', data);
        // }
    });
}

const submitAffiliateTrackingRequest = async (affiliateTrackingData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/add-affiliate-tracking`, affiliateTrackingData, {
        headers: {
            Authorization: authorizationHeader,  // Include the authorization header in the request
            'Content-Type': 'application/json'
        }
    });  
    console.log('Affiliate tracking added successfully:', response.data);
    return response.data.data;
}