import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import '../../NFLSurvivor/NFLSurvivorStyles/SurvivorLeaderboard.scss';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import Close from '@mui/icons-material/Close';
import LeaderboardConferenceSelection from '../DropdownMenus/LeaderboardConferenceSelection';
import CFBSurvivorOverallTable from './CFBSurvivorOverallTable';
import { DateTime } from 'luxon';
import CFBSurvivorUserStatsModal from '../../GameModals/SurvivorModals/CFBSurvivorUserStats/CFBSurvivorUserStatsModal';
import InfoIcon from '@mui/icons-material/Info';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';

const CFBSurvivorLeaderboard = ({ leaderboardData, currentWeeksDeadlineDateTime }) => {

    const { 
        startingWeekState, 
        CFBTeams, 
        myUsername, 
        myUserId,
        userPicksFromDB,
        CFBNames,
        teamFilter,
        mainGameId,
        partyState,
        isSurvivorOver,
        hasJoinDeadlinePassed,
    } = useCFBSurvivorContext();    


    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleRulesClick = () => {
        navigateToTop(`/cfb-survivor/${uriEncoded(mainGameId)}/RULES/RULES`);
    }


    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    //console.log("Current Week Deadline",currentWeeksDeadlineDateTime, "Time Now in New York:", timeNowInNewYork);

    //const dummyTimeForTesting = DateTime.fromISO('2024-07-29T13:00:00.000-04:00', { zone: 'America/New_York' });

    const hasThisWeeksDeadlinePassed = currentWeeksDeadlineDateTime ? timeNowInNewYork > currentWeeksDeadlineDateTime : false;
    //const hasThisWeeksDeadlinePassedDummy = timeNowInNewYork > dummyTimeForTesting;
    //console.log("Has This Week's Deadline Passed?", hasThisWeeksDeadlinePassed, "Has This Week's Deadline Passed Dummy?", hasThisWeeksDeadlinePassedDummy);

    const teamLogo = (teamId) => {
        return CFBTeams?.find((team) => team.GlobalTeamID === teamId)?.TeamLogoUrl;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [sortBy, setSortBy] = useState('timesPicked');
    const [sortOrder, setSortOrder] = useState('desc');
    const [leaderboardState, setLeaderboardState] = useState('Overall');
    const [userStatsOpen, setUserStatsOpen] = useState(false);
    const [userStatsInfo, setUserStatsInfo] = useState(null);
    const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);
    //console.log("Leaderboard State:", leaderboardState);

    const usersRow = leaderboardData.find((user) => user.username === myUsername);
    //console.log("Users Row:", usersRow);

    const handleSort = (columnName) => {
        if (columnName === 'username') {
          // Reset to default sorting by 'username'
          setSortBy('username');
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order for 'username'
        } else {
          // Handle other column sorts
          if (sortBy === columnName) {
            // Toggle sort order for the current column
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          } else {
            // Set default sorting order for new column
            setSortBy(columnName);
            // Default to descending order for numeric columns
            setSortOrder('asc');
          }
        }
      };


    // Sort leaderboard data based on the current conference state
    const sortedLeaderboardData = leaderboardData.slice().sort((a, b) => {
        let aAlive, bAlive, aWins, bWins, aMargin, bMargin;

        switch (leaderboardState) {
            case 'ACC':
                aAlive = !a.doesUserHaveACCLoss;
                bAlive = !b.doesUserHaveACCLoss;
                aWins = a.ACCWins;
                bWins = b.ACCWins;
                aMargin = a.ACCMargin;
                bMargin = b.ACCMargin;
                break;
            case 'Big 12':
                aAlive = !a.doesUserHaveBig12Loss;
                bAlive = !b.doesUserHaveBig12Loss;
                aWins = a.Big12Wins;
                bWins = b.Big12Wins;
                aMargin = a.Big12Margin;
                bMargin = b.Big12Margin;
                break;
            case 'Big Ten':
                aAlive = !a.doesUserHaveBigTenLoss;
                bAlive = !b.doesUserHaveBigTenLoss;
                aWins = a.BigTenWins;
                bWins = b.BigTenWins;
                aMargin = a.BigTenMargin;
                bMargin = b.BigTenMargin;
                break;
            case 'SEC':
                aAlive = !a.doesUserHaveSECLoss;
                bAlive = !b.doesUserHaveSECLoss;
                aWins = a.SECWins;
                bWins = b.SECWins;
                aMargin = a.SECMargin;
                bMargin = b.SECMargin;
                break;
            default:
                return 0;
        }

        // First sort by Alive status (Alive first)
        if (aAlive !== bAlive) return bAlive - aAlive;
        
        // Then sort by number of Wins (Descending)
        if (aWins !== bWins) return bWins - aWins;

        // Finally sort by Margin (Descending)
        return bMargin - aMargin;
    });

    //console.log("Sorted Leaderboard Data:", sortedLeaderboardData);

    // Aggregate counts for each conference and overall
    const conferenceCounts = sortedLeaderboardData.reduce((acc, player) => {
        acc.total++;
        if (!player.isUserEliminated) acc.alive++;
        else acc.eliminated++;

        if (!player.doesUserHaveACCLoss) acc.ACCAlive++;
        else acc.ACCEliminated++;

        if (!player.doesUserHaveBig12Loss) acc.Big12Alive++;
        else acc.Big12Eliminated++;

        if (!player.doesUserHaveBigTenLoss) acc.BigTenAlive++;
        else acc.BigTenEliminated++;

        if (!player.doesUserHaveSECLoss) acc.SECAlive++;
        else acc.SECEliminated++;

        return acc;
    }, {
        total: 0,
        alive: 0,
        eliminated: 0,
        ACCAlive: 0,
        ACCEliminated: 0,
        Big12Alive: 0,
        Big12Eliminated: 0,
        BigTenAlive: 0,
        BigTenEliminated: 0,
        SECAlive: 0,
        SECEliminated: 0,
    });

    function doesUserHaveConferenceLoss(userInfo) {
        if (leaderboardState === 'ACC') {
            return userInfo.doesUserHaveACCLoss;
        } else if (leaderboardState === 'Big 12') {
            return userInfo.doesUserHaveBig12Loss;
        }
        else if (leaderboardState === 'Big Ten') {
            return userInfo.doesUserHaveBigTenLoss;
        }
        else if (leaderboardState === 'SEC') {
            return userInfo.doesUserHaveSECLoss;
        }
    }

    //console.log("Sorted Leaderboard Data:", sortedLeaderboardData);

    // Function to get the correct alive and eliminated counts based on the leaderboardState
    const getDisplayCounts = (state) => {
        switch (state) {
            case 'ACC':
                return { alive: conferenceCounts.ACCAlive, eliminated: conferenceCounts.ACCEliminated };
            case 'Big 12':
                return { alive: conferenceCounts.Big12Alive, eliminated: conferenceCounts.Big12Eliminated };
            case 'Big Ten':
                return { alive: conferenceCounts.BigTenAlive, eliminated: conferenceCounts.BigTenEliminated };
            case 'SEC':
                return { alive: conferenceCounts.SECAlive, eliminated: conferenceCounts.SECEliminated };
            default:
                return { alive: conferenceCounts.alive, eliminated: conferenceCounts.eliminated };
        }
    };

    const { alive, eliminated } = getDisplayCounts(leaderboardState);

    const whosInFirst = sortedLeaderboardData[0]?.username;
    const whosInFirstCharacterCount = whosInFirst?.length;
    const howManyUsersAlive = sortedLeaderboardData?.filter(user => !user.isUserEliminated).length;
    //console.log("Whos in First:", whosInFirst, "Character Count:", whosInFirstCharacterCount, "Users Alive:", howManyUsersAlive);

    const lastWeekAPickWasMade = sortedLeaderboardData?.reduce((acc, user) => {
        // Filter out picks where teamId is "No Pick"
        const userPicks = (user?.picks || []).filter(pick => pick.teamId !== "No Pick" && pick.conference === leaderboardState);
        
        const lastPick = userPicks?.reduce((acc, pick) => {
            return pick.week > acc ? pick.week : acc;
        }, 0);
        
        return lastPick > acc ? lastPick : acc;
    }, 0);
    
    //console.log("LAST WEEK A PICK WAS MADE", lastWeekAPickWasMade);

    let weeksToShow = startingWeekState;
    const isThisGroupOver = howManyUsersAlive <= 1;
    if (isSurvivorOver || isThisGroupOver) {
        weeksToShow = lastWeekAPickWasMade > startingWeekState ? startingWeekState : lastWeekAPickWasMade === 0 ? 1 : lastWeekAPickWasMade;
    }

    return (
        <>
            <LeaderboardConferenceSelection leaderboardState={leaderboardState} setLeaderboardState={setLeaderboardState}/>

            {((isSurvivorOver || howManyUsersAlive <= 1) && hasJoinDeadlinePassed) ? (
                <Box 
                    sx={{
                        marginTop: '32px',
                    }}
                >
                    <ChampionDisplay
                        whosInFirst={whosInFirst}
                        whosInFirstCharacterCount={whosInFirstCharacterCount}
                        partyName={partyState}
                        line2={"2024 CFB Power 4 Survivor Champion"}
                    />
                </Box>
            ) : (

                <Box className="leaderboard-entrants-info-container">
                    <Box
                        className="leaderboard-entrants-info"
                        sx={{
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: isMobile ? '48px' : '175px',
                        }}
                    >
                        <Box className="leaderboard-entrants-info-text-container">
                            <Typography
                                className="leaderboard-entrants-header"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit' }}
                            >
                                {leaderboardState !== 'Overall' ? `${leaderboardState} Entrants` : 'Entrants'}
                            </Typography>
                            <Typography
                                className="leaderboard-entrants-text"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit' }}
                            >
                                {conferenceCounts.total}
                            </Typography>
                        </Box>
                        <Box className="leaderboard-entrants-info-text-container">
                            <Typography
                                className="leaderboard-entrants-header"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit' }}
                            >
                                {leaderboardState !== 'Overall' ? `${leaderboardState} Alive` : 'Alive'}
                            </Typography>
                            <Typography
                                className="leaderboard-entrants-text"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit', alignItems: 'center' }}
                            >
                                {alive}
                            </Typography>
                        </Box>
                        <Box
                            className="leaderboard-entrants-info-text-container"
                            sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', alignItems: 'center' }}
                        >
                            <Typography
                                className="leaderboard-entrants-header"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', textDecoration: 'underline', fontWeight: '500', color: 'inherit', minWidth: '60px' }}
                            >
                                {leaderboardState !== 'Overall' ? `${leaderboardState} Eliminated` : 'Eliminated'}
                            </Typography>
                            <Typography
                                className="leaderboard-entrants-text"
                                sx={{ width: isMobile ? '110px' : '200px', fontSize: isMobile ? '12px' : '22px', fontWeight: '500', color: 'inherit', alignItems: 'center' }}
                            >
                                {eliminated}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
            {leaderboardState === 'Overall' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: (isSurvivorOver || isThisGroupOver) ? '10px' : '0px',
                        marginBottom: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => setTiebreakInfoOpen(true)}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '18px',
                            fontWeight: '500',
                            textDecoration: 'underline',
                            color: '#00AAD1',
                            marginRight: '5px',
                        }}
                    >
                        Tiebreaker Info
                    </Typography>
                    <InfoIcon
                        sx={{
                            color: '#00AAD1',
                            fontSize: isMobile ? '16px' : '20px',
                        }}
                    />
                </Box>
            )}
            {leaderboardState !== 'Overall' && (
            <TableContainer 
                component={Paper} 
                className="leaderboard-table-container"
                sx={{
                    maxWidth: `calc(515px + ${weeksToShow * 58}px)`, // Limit the maximum width if needed
                    minWidth: isMobile ? 'auto' : '646px', // Set the minimum width
                    margin: '0 auto',
                    borderRadius: '0',
                    marginTop: (isSurvivorOver || isThisGroupOver) ? '20px' : '0px',
                }}
            >
                <Table sx={{ tableLayout: isMobile ? null : 'fixed' }}>
                {usersRow && (
                    <TableHead sx={{ backgroundColor: '#F0F1F1' }}>
                        <TableRow>
                            <TableCell
                            sx={{
                                color: '#002129',
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: isMobile ? '160px' : '200px',
                                maxWidth: isMobile ? '160px' : '200px',
                                position: 'sticky', // Make the column sticky
                                backgroundColor: '#F0F1F1', // Background color to prevent overlap
                                left: 0, // Position it on the left
                                zIndex: 1, // Ensure it stays above other cells
                                
                            }}
                            onClick={() => {
                                setUserStatsOpen(true);
                                setUserStatsInfo(usersRow); // Pass the entire row data
                              }}
                            >
                            <Typography
                                sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                fontWeight: '700',
                                color: doesUserHaveConferenceLoss(usersRow) ? '#CC293C' : '#002129',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                }}
                            >
                                {usersRow.username}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: '500',
                                    color: doesUserHaveConferenceLoss(usersRow) ? '#CC293C' : '#002129',
                                }}
                            >
                                {leaderboardState} Margin: {(() => {
                                        switch (leaderboardState) {
                                            case 'ACC':
                                                return usersRow.ACCMargin ?? 0; // Use default 0 if ACCMargin is undefined or null
                                            case 'Big 12':
                                                return usersRow.Big12Margin ?? 0; // Use default 0 if Big12Margin is undefined or null
                                            case 'Big Ten':
                                                return usersRow.BigTenMargin ?? 0; // Use default 0 if BigTenMargin is undefined or null
                                            case 'SEC':
                                                return usersRow.SECMargin ?? 0; // Use default 0 if SECMargin is undefined or null
                                            default:
                                                return usersRow.totalMargin ?? 0; // Use default 0 if totalMargin is undefined or null
                                        }
                                    })()}
                            </Typography>
                            </TableCell>
                            {[...Array(weeksToShow)].map((_, weekIndex) => {
                            const weekNumber = weeksToShow - weekIndex;
                            const conferenceName = leaderboardState.replace(/\s+/g, '');
                            const conferencePicks = userPicksFromDB?.filter(pick => pick.conference === leaderboardState) || [];
                            const lossInThisConference = usersRow[`doesUserHave${conferenceName}Loss`];
                            const lossInThisConferenceWeek = usersRow[`lossWeek${conferenceName}`];
                            const pick = conferencePicks?.find(pick => pick.week === weekNumber) || null;
                            //console.log("Pick", pick);
                            const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                            const isCurrentWeek = weekNumber === weeksToShow;
                            const hasDeadlinePassed = timeNowInNewYork > timeNowInNewYork;
                            const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                            //console.log("Pick Time", pickTime, "Has Deadline Passed?", hasDeadlinePassed, "Time Now in New York:", timeNowInNewYork, "Dummy Time for Testing:", dummyTimeForTesting, "Has Game Started?", hasGameStarted);

                            return (
                                <TableCell
                                key={weekIndex}
                                sx={{
                                    textAlign: 'center',
                                    minWidth: isMobile ? '48px' : '58px',
                                    width: isMobile ? '48px' : '58px',
                                    padding: 0,
                                }}
                                >
                                {pick ? (
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: pick.result === 'Win' ? '2px solid #00AA72' : (pick.result === 'Loss' ? '2px solid #CC293C' : '1px solid #ccc'),
                                        background: pick.result === 'Win' ? '#CCEEE3' : ((pick.result === 'Loss' && pick?.teamId !== 'No Pick') ? '#F5D4D8' : '#f9f9f9'),
                                        width: isMobile ? '32px' : '40px',
                                        height: isMobile ? '32px' : '40px',
                                        overflow: 'hidden',
                                        margin: '0 auto',
                                    }}
                                    >
                                    {pick.teamId === 'No Pick' ? (
                                        <Close
                                            sx={{
                                            color: '#CC293C',
                                            width: isMobile ? '18px' : '24px',
                                            height: isMobile ? '18px' : '24px',
                                            }}
                                        />
                                        ) : (
                                        <img
                                            src={teamLogo(pick.teamId)}
                                            alt={`Team ${pick.teamId}`}
                                            style={{
                                            width: '100%',
                                            height: 'auto',
                                            }}
                                        />
                                        )
                                    }
                                    </Box>
                                ) : (
                                    <Box
                                    sx={{
                                        width: isMobile ? '32px' : '40px',
                                        height: isMobile ? '32px' : '40px',
                                        border: (lossInThisConference && lossInThisConferenceWeek !== weekNumber) ? '' : '1px solid #ccc',
                                        backgroundColor: (lossInThisConference && lossInThisConferenceWeek !== weekNumber) ? 'transparent' : '#FFF',
                                        margin: '0 auto',
                                        display: 'flex',           // Flexbox to center the content
                                        justifyContent: 'center',  // Horizontally center
                                        alignItems: 'center'       // Vertically center
                                    }}
                                    >
                                    {/* Blank cell or placeholder */}
                                        <Typography 
                                            variant="body1" 
                                            sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}  // Example font size and color
                                        >
                                            {/* Your text here */}
                                            {lossInThisConference && lossInThisConferenceWeek !== weekNumber && weekNumber === weeksToShow ? `W${lossInThisConferenceWeek}` : ''}
                                        </Typography>
                                    </Box>
                                )}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        </TableHead>
                        )}
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                            <TableCell
                                // onClick={() => handleSort('name')}
                                sx={{ 
                                    width: isMobile ? '160px' : '200px', 
                                    maxWidth: isMobile ? '160px' : '200px',
                                    minWidth: isMobile ? '160px' : '200px',
                                    color: '#FFF', 
                                    fontWeight: 700, 
                                    textAlign: 'left', 
                                    position: 'sticky', // Make the column sticky
                                    left: 0, // Position it on the left
                                    backgroundColor: '#002129', // Background color to prevent overlap
                                    zIndex: 1, // Ensure it stays above other cells
                                    cursor: 'pointer', 
                                    fontSize: isMobile ? '14px' : '24px', 
                                    // textDecoration: 'underline',
                                }}
                            >
                                PLAYER
                            </TableCell>
                            {[...Array(weeksToShow)].map((_, index) => (
                                <TableCell 
                                    key={index} 
                                    sx={{
                                        minWidth: isMobile ? '48px' : '58px',
                                        maxWidth: isMobile ? '48px' : '58px',
                                        color: '#FFF',
                                        fontSize: isMobile ? '14px' : '16px',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        padding: '0',
                                    }}
                                >
                                    <div>
                                        {isMobile ? 'Wk' : 'Week'}
                                        <span style={{ display: 'block' }}>
                                            {weeksToShow - index}
                                        </span>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {sortedLeaderboardData?.map((player, playerIndex) => (
                            <TableRow
                                key={playerIndex}
                                sx={{
                                    backgroundColor:
                                        player.userId === myUserId
                                            ? '#FFF6DA' // Special color for the logged-in user
                                            : playerIndex % 2 === 0
                                            ? '#FFF'    // Color for even rows
                                            : '#F0F1F1',// Color for odd rows
                                }}
                            >
                            <TableCell
                                sx={{
                                    color: '#002129',
                                    fontWeight: 500,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    position: 'sticky', // Make the column sticky
                                    left: 0, // Position it on the left
                                    backgroundColor: player.userId === myUserId
                                    ? '#FFF6DA' // Special color for the logged-in user
                                    : playerIndex % 2 === 0
                                    ? '#FFF'    // Color for even rows
                                    : '#F0F1F1',// Color for odd rows
                                    zIndex: 1, // Ensure it stays above other cells
                                }}
                                onClick={() => {
                                    setUserStatsOpen(true);
                                    setUserStatsInfo(player); // Pass the entire row data
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        fontWeight: '700',
                                        width: isMobile ? '160px' : '200px', 
                                        maxWidth: isMobile ? '160px' : '200px',
                                        color: doesUserHaveConferenceLoss(player) ? '#CC293C' : '#002129',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                     {player.username}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        fontWeight: '500',
                                        color: doesUserHaveConferenceLoss(player) ? '#CC293C' : '#002129',
                                    }}
                                >
                                    {leaderboardState} Margin: {(() => {
                                        switch (leaderboardState) {
                                            case 'ACC':
                                                return player.ACCMargin ?? 0; // Use default 0 if ACCMargin is undefined or null
                                            case 'Big 12':
                                                return player.Big12Margin ?? 0; // Use default 0 if Big12Margin is undefined or null
                                            case 'Big Ten':
                                                return player.BigTenMargin ?? 0; // Use default 0 if BigTenMargin is undefined or null
                                            case 'SEC':
                                                return player.SECMargin ?? 0; // Use default 0 if SECMargin is undefined or null
                                            default:
                                                return player.totalMargin ?? 0; // Use default 0 if totalMargin is undefined or null
                                        }
                                    })()}

                                </Typography>
                            </TableCell>
                            {[...Array(weeksToShow)].map((_, weekIndex) => {
                                    const weekNumber = weeksToShow - weekIndex;
                                    const conferenceName = leaderboardState.replace(/\s+/g, '');
                                    //console.log("Player:", player, conferenceName);
                                    const lossInThisConference = player[`doesUserHave${conferenceName}Loss`];
                                    const lossInThisConferenceWeek = player[`lossWeek${conferenceName}`];
                                    //console.log("Loss in This Conference:", lossInThisConference, "Loss in This Conference Week:", lossInThisConferenceWeek);
                                    const areTheseMyPicks = player.username === myUsername;
                                    const conferencePicks = areTheseMyPicks ? userPicksFromDB?.filter(pick => pick.conference === leaderboardState) || [] : player?.picks?.filter(pick => pick.conference === leaderboardState) || [];
                                    const pick = conferencePicks.find(pick => pick.week === weekNumber) || null;
                                    //console.log("Pick", pick);
                                    const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                                    const isCurrentWeek = weekNumber === weeksToShow;
                                    const hasDeadlinePassed = timeNowInNewYork > currentWeeksDeadlineDateTime;
                                    const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                                   
                                
                                return (
                                    <TableCell
                                        key={weekIndex}
                                        sx={{
                                            textAlign: 'center',
                                            minWidth: isMobile ? '48px' : '58px',
                                            width: isMobile ? '48px' : '58px',
                                            padding: 0,
                                        }}
                                        >
                                        <Box
                                            sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: pick?.result === 'Win' ? '2px solid #00AA72' : (pick?.result === 'Loss' ? '2px solid #CC293C' : (lossInThisConference && lossInThisConferenceWeek !== weekNumber) ? '' : '1px solid #ccc'),
                                            background: pick?.result === 'Win' ? '#CCEEE3' : ((pick?.result === 'Loss' && pick.teamId !== 'No Pick') ? '#F5D4D8' :(lossInThisConference && lossInThisConferenceWeek !== weekNumber) ? 'transparent' : '#f9f9f9'),
                                            width: isMobile ? '32px' : '40px',
                                            height: isMobile ? '32px' : '40px',
                                            overflow: 'hidden',
                                            margin: '0 auto',
                                            }}
                                        >
                                            {pick && pick.teamId === 'No Pick' ? (
                                            <Close
                                                sx={{
                                                color: '#CC293C',
                                                width: isMobile ? '18px' : '24px',
                                                height: isMobile ? '18px' : '24px',
                                                }}
                                            />
                                            ) : (
                                                (isCurrentWeek && !hasDeadlinePassed && !hasGameStarted && !areTheseMyPicks) || !pick ? (
                                                    <Box
                                                        sx={{
                                                            width: isMobile ? '32px' : '40px',
                                                            height: isMobile ? '32px' : '40px',
                                                            backgroundColor: (lossInThisConference && lossInThisConferenceWeek !== weekNumber) ? 'transparent' : '#FFF',
                                                            display: 'flex',           // Flexbox to center the content
                                                            justifyContent: 'center',  // Horizontally center
                                                            alignItems: 'center'       // Vertically center
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="body1" 
                                                            sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}  // Example font size and color
                                                        >
                                                            {/* Your text here */}
                                                            {lossInThisConference && lossInThisConferenceWeek !== weekNumber && weekNumber === weeksToShow ? `W${lossInThisConferenceWeek}` : ''}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                <img
                                                src={teamLogo(pick.teamId)}
                                                alt={`Team ${pick.teamId}`}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                }}
                                                />
                                            )
                                            )}
                                        </Box>
                                        </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            )}
            {leaderboardState === 'Overall' && (
                <CFBSurvivorOverallTable 
                    leaderboardData={leaderboardData} 
                    sortBy={sortBy} 
                    sortOrder={sortOrder} 
                    handleSort={handleSort}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                    isSurvivorOver={isSurvivorOver}
                    isThisGroupOver={isThisGroupOver} 
                />
            )}
            {userStatsOpen && (
                <CFBSurvivorUserStatsModal 
                    statsModalOpen={userStatsOpen}
                    setStatsModalOpen={setUserStatsOpen}
                    myUsername={userStatsInfo.username}
                    startingWeekState={startingWeekState}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                    CFBTeams={CFBTeams}
                    CFBNames={CFBNames}
                    areTheseMyStats={userStatsInfo.username === myUsername}
                    usersPicks={userStatsInfo.username === myUsername ? userPicksFromDB : userStatsInfo.picks}
                    teamFilter={teamFilter}
                    doesUserHaveACCLoss={userStatsInfo.doesUserHaveACCLoss}
                    lossWeekACC={userStatsInfo.lossWeekACC}
                    doesUserHaveBig12Loss={userStatsInfo.doesUserHaveBig12Loss}
                    lossWeekBig12={userStatsInfo.lossWeekBig12}
                    doesUserHaveBigTenLoss={userStatsInfo.doesUserHaveBigTenLoss}
                    lossWeekBigTen={userStatsInfo.lossWeekBigTen}
                    doesUserHaveSECLoss={userStatsInfo.doesUserHaveSECLoss}
                    lossWeekSEC={userStatsInfo.lossWeekSEC}
                />
            )}
            {tiebreakInfoOpen && (
                <Modal
                open={tiebreakInfoOpen}
                onClose={() => setTiebreakInfoOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 'none',
                }}
            >
                <Box
                    sx={{
                        width: '323px',
                        height: '334px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '16px', // Optional padding
                        boxShadow: 24, // Optional shadow for better visibility
                    }}
                >
                    {/* Modal content goes here */}
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '800',
                            textAlign: 'center',
                        }}
                    >
                        Tiebreaker Info
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <span style={{ display: 'block' }}>
                            This pool will have a single outright winner. Tiebreakers will be used in the event:
                        </span>
                        <span style={{ display: 'block', marginTop: '10px' }}>
                            Multiple survivors remain at the end of Week 14.
                        </span>
                        <span style={{ display: 'block' }}>
                            OR
                        </span>
                        <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
                            The last survivors are eliminated in the same week.
                        </span>
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                textAlign: 'left',
                                marginTop: '20px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ display: 'block' }}>
                            <span style={{ fontWeight: 700 }}>
                                Tiebreaker #1:</span> Total Wins
                            </span>
                            <span style={{ display: 'block' }}>
                            <span style={{ fontWeight: 700 }}>
                                Tiebreaker #2:</span> Most Surviving Conferences
                            </span>
                            <span style={{ display: 'block' }}>
                            <span style={{ fontWeight: 700 }}>
                                Tiebreaker #3:</span> Largest Win Margin
                            </span>
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        See the pool{' '}
                        <span
                            style={{
                                textDecoration: 'underline',
                                color: '#00AAD1',
                                cursor: 'pointer',
                                fontWeight: '700',
                            }}
                            onClick={handleRulesClick}
                        >
                            RULES
                        </span>
                        {' '}for complete details!
                    </Typography>
                    <Button
                        sx={{
                            backgroundColor: '#00AAD1', // Primary color
                            height: '38px',
                            width: '115px',
                            color: 'white', // Text color
                            display: 'block', // Center horizontally
                            margin: '0 auto', // Horizontally centers the button
                            marginTop: '20px',
                            '&:hover': {
                                backgroundColor: '#00AAD1', // Ensure the background color stays the same on hover
                            },
                            '&:focus': {
                                outline: 'none', // Remove focus outline
                            },
                            '&:active': {
                                backgroundColor: '#00AAD1', // Keep the same color when the button is active
                            },
                        }}
                        onClick={() => setTiebreakInfoOpen(false)}
                    >
                        OK
                    </Button>

                </Box>
            </Modal>
            )}
        </>
    )
}

export default CFBSurvivorLeaderboard;
