import React, { useState } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const PoolScreenshots = ({ screenshots, backgroundColor, textColor}) => {

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const rows = screenshots?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);
        
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new row
        }
        
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    }

    const handleOpenModal = (image) => {
        setShowModal(true);
        setModalImage(image);
    }

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor || '#fff',
                padding: '16px 0px',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: textColor || '#002129',
                }}
            >
                POOL PAGES
            </Typography>

            <Box className="game-home-page-media-container">  
                <Box className="game-home-page-screen-capture-main-container">
                    <Box className="game-home-page-screen-capture-row">
                        {rows?.map((row, rowIndex) => (
                            <Box key={`row-${rowIndex}`} className="game-home-page-screen-capture-pair-container">
                                {row.map((item) => (
                                    <Box key={item.name} className="game-home-page-screen-capture-container">
                                        <Typography 
                                            className="game-home-page-screen-capture-title"
                                            sx={{
                                                color: textColor || '#002129',
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                        <Box 
                                            className="game-home-page-screen-capture-image-box"
                                            onClick={() => handleOpenModal(item.image)}
                                        >
                                            <img 
                                                src={item.image}
                                                alt={item.name}
                                                className="game-home-page-screen-capture-image" 
                                            />
                                        </Box>       
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
            
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 1rem',
                    }}
                >
                    <Box
                    sx={{
                        width: isMobile ? '300px' : '600px',
                        height: isMobile ? '500px' : '750px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxSizing: 'border-box', // Ensure padding is included in the dimensions
                    }}
                    >
                    <Box
                        sx={{
                        width: '100%',
                        height: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        }}
                    >
                        <img
                        src={modalImage}
                        alt="Image placeholder"
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 50px)', // Ensure button space
                        }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleCloseModal}
                            sx={{
                                marginTop: '10px',
                            }}
                        >
                            CLOSE
                        </Button>
                    </Box>
                    </Box>
                </Modal>
            )}
        </Box>

    );
}

export default PoolScreenshots;

