import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCommunityHubData } from '../../../hooks/general/useGetCommunityHubData';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const CommunityHubContext = createContext();

export const useCommunityHubContext = () => {
    const context = useContext(CommunityHubContext);
    if (!context) {
        throw new Error('useCommunityHubContext must be used within a CommunityHubProvider');
    }
    return context;
}

export const CommunityHubProvider = ({ children }) => {
    const dispatch = useDispatch();
    const navigateToTop = useNavigateToTop();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const organizationId = searchParams.get('oId');
    const page = searchParams.get('page');
    //console.log("organizationId in CommunityHubProvider: ", organizationId);

    const [hasDataFetched, setHasDataFetched] = useState(false);

    const { mutate: fetchCommunityHubData, data: communityHubData, isLoading: communityHubDataLoading, isError: communityHubDataError, error: communityHubDataErrorData } = useGetCommunityHubData();

    // useEffect to handle default page when no page parameter exists
    useEffect(() => {
        if (!page) {
            const newSearchParams = new URLSearchParams(window.location.search);
            newSearchParams.set('page', 'our-games');
            navigate({
                pathname: location.pathname,
                search: newSearchParams.toString()
            }, { replace: true });
        }
    }, [page, location.pathname, navigate]);

    // useEffect to fetch community hub data
    useEffect(() => {
        if (!hasDataFetched) {
            fetchCommunityHubData({ organizationId: organizationId });
            setHasDataFetched(true);
        }
    }, [hasDataFetched, fetchCommunityHubData, organizationId]);
    //console.log('communityHubData:', communityHubData);

    const navBarInfo = communityHubData?.navBarInfo;

    const CommunityHubContextValues = useMemo(() => {
        return {
            hasDataFetched,
            setHasDataFetched,
            communityHubData,
            organizationId,
            page: page || 'our-games', // Provide default value in context as well
            navBarInfo,
        };
    }, [hasDataFetched, communityHubData, organizationId, page, navBarInfo]);

    if (!hasDataFetched) {
        return <LoadingScreen />;
    }

    return (
        <CommunityHubContext.Provider value={CommunityHubContextValues}>
            {children}
        </CommunityHubContext.Provider>
    );
}