import React, { useState, useEffect } from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { useSurvivorGamesContext } from "./context/SurvivorGamesContext";   
import { Typography, Button, Box } from "@mui/material";
import JoinModal from "../GameModals/JoinGameModal/JoinModal";
import GameHeaderTemplate from "../PoolHeaderBar/GameHeaderTemplate";
import UserDash from "../UserDash/UserDash";
import ConnectionError from "../ConnectionErrorPage/ConnectionError";
import GameNavBar from "../NavigationBars/GameNavigationBar/GameNavBar";
import SurvivorMyPicksPage from "./MyPicks/SurvivorMyPicksPage";
import EntryDropdown from "../GamePageComponents/Dropdowns/EntryDropdown";
import SurvivorLeaderboard from "./SurvivorLeaderboard/SurvivorLeaderboard";
import GameHomePageVersionTwo from "../GameHomePage/GameHomePageVersionTwo";
import GameRulesPage from "../GameRulesPage/GameRulesPage";
import MembersPageContainerNew from "../MembersPage/MembersPageContainerNew";
import SponsorTab from "../SponsorTab/SponsorTab";
import ReferralsLeaderboard from "../ReferralsLeaderboard/ReferralsLeaderboardNew";
import SurvivorPickStats from "./SurvivorPickStats/SurvivorPickStats";
import PoolPartyPopUpModal from "../GameModals/PoolPartyPopUp/PoolPartyPopUpModal";
import ChampDisplayTemplate from "../ChampionDisplay/ChampDisplayTemplate";

const SurvivorGamesContainer = () => {
    const {
        page,
        gameData,
        isUserInThisPool,
        usersIndexesInThisPool,
        connectionError,
        joinOpen,
        setJoinOpen,
        popUpModalInfo,
        poolPartyPopUp,
        setPoolPartyPopUp,
        leaderboardData,
    } = useUserAndGameContext();
    //console.log("user Game/Pool Info in SurvivorGamesContainer: ", usersGameIdsInMasterPool, usersIndexesInThisPool);

    const {
        isGameOver,
    } = useSurvivorGamesContext();

    return (
        <>
            <GameHeaderTemplate />

            <GameNavBar />

            {isUserInThisPool && (
                <UserDash />
            )}

            {(page === 'home' || page === 'leaderboard') && isGameOver && (
                <ChampDisplayTemplate
                    leaderboardData={leaderboardData}
                />
            )}
            
            {(usersIndexesInThisPool?.length > 1 && page === 'my-picks') && (
                <EntryDropdown 
                    hideEntryDropdown={page !== 'my-picks'}
                />
            )}

            {page === "home" && (
                <GameHomePageVersionTwo />
            )}

            {page === "my-picks" && (
                <SurvivorMyPicksPage />    
            )}

            {page === "leaderboard" && (
                <SurvivorLeaderboard />
            )}

            {page === "rules" && (
                <GameRulesPage gameData={gameData} />
            )}

            {page === "pool-picks" && (
                <SurvivorPickStats />
            )}

            {page === 'members' && (
                <MembersPageContainerNew />
            )}

            {page === 'sponsor' && (
                <SponsorTab gameData={gameData} />
            )}

            {page === 'referrals' && (
                <ReferralsLeaderboard />
            )}

            {poolPartyPopUp && (
                <PoolPartyPopUpModal 
                    modalOpen={poolPartyPopUp}
                    setModalOpen={setPoolPartyPopUp}
                    modalInfo={popUpModalInfo}
                />
            )}

            {connectionError && (
                <ConnectionError />
            )}

            {joinOpen && (
                <JoinModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                />
            )}
        </>
    );
};

export default SurvivorGamesContainer;