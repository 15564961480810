import React from 'react';
import { Box, Typography } from '@mui/material'; // Import Box and Typography from Material-UI
import './GameRulesPage.scss'; // Import the SCSS file
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import trophyImage from '../../assets/images/Layer_1.svg';
import SilverTrophy from '../../assets/images/SilverTrophy.svg';
import BronzeTrophy from '../../assets/images/BronzeTrophy.svg';

const GameRulesPagePrizes = ({ prizes }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //console.log("Rules Page", prizes);
  return (
    <Box className="rules-page-prizes-container"> {/* Added className to Box */}
      
      <Typography 
        variant="body1" 
        className="home-page-prizes-header"
      >
        Pool Prizes
      </Typography>

      <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center', // Center the content horizontally
            alignItems: 'center', // Center the content vertically
            margin: '0 auto',
            marginTop: '10px',
            flexWrap: 'wrap', // Allow wrapping if needed
          }}
        >
            {prizes?.firstPlace && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: '20px', // Add some space between prizes
                }}
              >
                <Box
                  component='img'
                  sx={{
                    display: 'block',
                    width: isMobile ? 16 : 24,
                    marginRight: '10px',
                  }}
                  alt='Pool Party Trophy'
                  src={trophyImage}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: isMobile ? '14px' : '18px',
                  }}
                >
                  1st: {prizes?.firstPlace}
                </Typography>
              </Box>
            )}

            {/* Conditionally render the second, third prize, and last place text for "Pickem" */}
            {prizes?.secondPlace && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: '20px', // Add some space between prizes
                  }}
                >
                  <Box
                    component='img'
                    sx={{
                      display: 'block',
                      width: isMobile ? 16 : 24,
                      marginRight: '10px',
                    }}
                    alt='Pool Party Trophy'
                    src={SilverTrophy}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: isMobile ? '14px' : '18px',
                    }}
                  >
                    2nd: {prizes?.secondPlace}
                  </Typography>
                </Box>
            )}
                {prizes?.thirdPlace && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginRight: '20px', // Add some space between prizes
                    }}
                  >
                    <Box
                      component='img'
                      sx={{
                        display: 'block',
                        width: isMobile ? 16 : 24,
                        marginRight: '10px',
                      }}
                      alt='Pool Party Trophy'
                      src={BronzeTrophy} // Add your bronze trophy image here
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      3rd: {prizes?.thirdPlace}
                    </Typography>
                  </Box>
            )}
          </Box>
          {prizes?.lastPlace && (
            <Typography 
              variant="body1" 
              className="home-page-prizes-text"
            >
              <span className="prize-text">
                <span className="highlight">Last Place</span> - {prizes?.lastPlace}
              </span>
            </Typography>
          )}
        {prizes?.extraLines?.length > 0 && (
            <Box className="home-page-prizes-text">
              {prizes.extraLines.map((line, index) => (
                <Typography 
                  key={index} 
                  variant="body1"
                  sx={{
                    marginBottom: '10px',
                    fontWeight: 700,
                  }}
                >
                  {line}
                </Typography>
              ))}
            </Box>
          )}
          {!prizes?.extraLines && (
            <Box sx={{ marginBottom: '10px' }}></Box>
          )}
          </Box>
  );
}

export default GameRulesPagePrizes;
