import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useGetLeaderboardPageData = () => {
    return useMutation({
        mutationFn: getLeaderboardPageData,
        onError: (error) => {
            console.error("Error fetching leaderboard page data:", error);
        },
        // onSuccess: (data) => {
        //     console.log("Fetched leaderboard page data:", data);
        // }
    });
}

const getLeaderboardPageData = async () => {

    const response = await axios.get(`${apiUrl}/sports-data/get-leaderboard-page-data`, {
        headers: {
            "Content-Type": "application/json"
        }
    });

    return response.data;
}