import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import './CommunityPoolsCard.scss';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

// Create a simplified version for Community Hub use
const CommunityPoolsCard = ({ poolInfo }) => {
    const navigateToTop = useNavigateToTop();
    const masterPoolId = poolInfo?.masterPoolId;
    const gameIdForNavigation = poolInfo?.gameId;
    
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleButtonClick = () => {
        const url = `/${uriEncoded(poolInfo?.gameAddress)}?mId=${masterPoolId}&gId=${gameIdForNavigation}&page=default`;
        navigateToTop(url);
    }

    return (
        <Box className="community-pools-card-container">
            <Box>
                <Typography className="community-pools-card-title">
                    {poolInfo?.poolName}
                </Typography>
                <Box className="community-pools-card-text-row">
                    <Typography
                        className="community-pools-card-text-header"
                        sx={{ width: '68px' }}
                    >
                        Pool Starts
                    </Typography>
                    <Typography
                        className="community-pools-card-text-header"
                        sx={{ marginLeft: '16px'}}
                    >
                        Prizes
                    </Typography>
                </Box>
                <Box className="community-pools-card-text-row">
                    <Typography
                        className="community-pools-card-text-content"
                        sx={{ width: '68px' }}
                    >
                        {poolInfo?.poolStarts}
                    </Typography>
                    <Typography
                        className="community-pools-card-text-content"
                        sx={{ marginLeft: '16px' }}
                    >
                        {poolInfo?.prizes}
                    </Typography>
                </Box>
                <Box className="community-pools-card-button-container">
                    <Button
                        className="community-pools-card-button"
                        sx={{ cursor: poolInfo?.nonGameMemberButtonText === "Coming Soon" ? 'default' : 'pointer' }}
                        onClick={handleButtonClick}
                        disabled={poolInfo?.nonGameMemberButtonText === "Coming Soon"}
                    >
                        {poolInfo?.nonGameMemberButtonText}
                        {poolInfo?.showArrow && (
                            <EastIcon sx={{ fontSize: '16px' }} />
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CommunityPoolsCard;