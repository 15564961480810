import React, { useState } from 'react';
import { Typography, Box, Link } from '@mui/material';
import ImageModal from './ImageModal';

const SponsorText = ({ text }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  return (
    <>
      <Box sx={{ 
        maxWidth: '700px',
        margin: '0 auto',
        width: '100%',
        '& > *': { mb: 1 }
      }}>
        {text?.map((line, index) => (
          <Typography 
            key={index} 
            component={line.link ? 'div' : 'p'}
            sx={{ 
              fontSize: line.size || '16px',
              color: line.color || '#002129',
              fontWeight: line.fontWeight || 500,
              textAlign: line.alignment || 'center',
              fontStyle: line.italic ? 'italic' : 'normal',
              textDecoration: line.underline ? 'underline' : 'none',
              cursor: line.image ? 'pointer' : 'inherit',
              maxWidth: line.maxWidth || 'inherit',
              whiteSpace: line.whiteSpace || 'normal',
              justifyContent: line.justifyContent || 'center',
              marginTop: line.marginTop || 'inherit',
            }}
            onClick={() => line.image && handleImageClick(line.image)}
          >
            {line.link ? (
              <Link 
                href={line.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                {line.text}
              </Link>
            ) : line.text}
          </Typography>
        ))}
      </Box>
      
      <ImageModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        image={selectedImage}
      />
    </>
  );
};

export default SponsorText;