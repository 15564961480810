import React from 'react';

const PdfViewer = ({ url }) => {
    return (
        <div style={{ height: '750px' }}>
        <object data={url} type="application/pdf" width="100%" height="600px">
          <p>Alternative text - include a link <a href={url}>to the PDF!</a></p>
        </object>
      </div>
    );
};

export default PdfViewer;
