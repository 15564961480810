import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useGetPublicPools } from "./hooks/useGetPublicPools";
import PoolHallList from "./PoolHallList";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";
import { DateTime } from "luxon";

const PoolHallContainer = () => {

    const { data, error, isLoading, refetch } = useGetPublicPools();
    //console.log("Public Pools", data);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState('All');
    const [filteredPools, setFilteredPools] = useState([]);
    const [openPools, setOpenPools] = useState([]);
    const [closedPools, setClosedPools] = useState([]);
    
    const timeInNyNow = DateTime.now().setZone('America/New_York');

    const isPoolClosed = (dateTime) => {
    const poolDeadline = DateTime.fromISO(dateTime, { zone: 'America/New_York' });
    return poolDeadline <= timeInNyNow; // Returns true if the pool deadline has passed
    };

    const normalizeLeague = (league) => {
        if (!league) return '';
        
        // If it's stored as a string containing an array representation
        if (league?.startsWith('[') && league?.endsWith(']')) {
          // Remove the brackets and quotes
          return league?.slice(2, -2);
        }
        
        return league;
    };



    useEffect(() => {
        if (page === 'All') {
            setFilteredPools(data);
        } else if (page === 'NCAA') {
            setFilteredPools(data.filter(pool => {
                const league = normalizeLeague(pool?.league);
                return league === 'NCAA' || league === 'CFB';
            }));
        } else {
            setFilteredPools(data.filter(pool => normalizeLeague(pool?.league) === page));
        }
    }, [page, data]);
    

    useEffect(() => {
        if (filteredPools?.length > 0) {
            // Sort by createdOn (most recent first)
            const sortedPools = [...filteredPools].sort((a, b) => {
                return DateTime.fromISO(b.createdOn) - DateTime.fromISO(a.createdOn);
            });
            //console.log("Sorted Pools", sortedPools);
            // Set open and closed pools
            setOpenPools(sortedPools.filter(pool => !isPoolClosed(pool?.lastDayToJoin)));
            setClosedPools(sortedPools.filter(pool => isPoolClosed(pool?.lastDayToJoin)));
        }
    }, [filteredPools]);
    

    //console.log("Open Pools", openPools, "Closed Pools", closedPools);

    if (isLoading) {
        return <LoadingScreen />;
    }

    
    return (
        <div>
            <StaticPoolHeaderBar navigationText="Home" headerText="Pool Hall" linkForNavigation="/" />
            
             <Box
                sx={{
                    marginBottom: '32px',
                }}
             >
                <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '350px', margin:'15px auto'}}>
                    <span
                    style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Send Invites' ? 'underline' : 'none', backgroundColor: page === 'All' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap'  }}
                    onClick={() => setPage('All')}
                    >
                        All
                    </span>
                    <span
                        style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Manage Members' ? 'underline' : 'none', backgroundColor: page === 'NFL' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap' }}
                        onClick={() => setPage('NFL')}
                    >
                        NFL
                    </span>
                    <span
                        style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Email Members' ? 'underline' : 'none', backgroundColor: page === 'NCAA' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => setPage('NCAA')}
                    >
                        CFB
                    </span>
                </div>
            </Box>

            <Box>
                <Typography
                    sx={{
                        color: '#002129',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: isMobile ? '18px' : '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        letterSpacing: '-0.304px',
                        marginBottom: '16px'
                    }}
                >
                    Pool Entry: Open
                </Typography>
            </Box>

            <PoolHallList pools={openPools} />

            <Box>
                <Typography
                    sx={{
                        color: '#002129',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: isMobile ? '18px' : '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        letterSpacing: '-0.304px',
                        marginBottom: '16px',
                        marginTop: '40px'
                    }}
                >
                    Pool Entry: Closed
                </Typography>
            </Box>

            <PoolHallList pools={closedPools} />

        </div>
    );
}

export default PoolHallContainer;
    