// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-scroll-container {
  position: sticky;
  top: 80px; /* Freeze point from the top */
  z-index: 9999;
}

.sticky-scroll-container.stuck {
  position: fixed;
  top: 80px;
  width: 100%; /* Ensure full width when fixed */
}`, "",{"version":3,"sources":["webpack://./src/components/SuperPickem/CFB/CFBContainerStyles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,SAAA,EAAA,8BAAA;EACA,aAAA;AACJ;;AAEE;EACE,eAAA;EACA,SAAA;EACA,WAAA,EAAA,iCAAA;AACJ","sourcesContent":[".sticky-scroll-container {\n    position: sticky;\n    top: 80px; /* Freeze point from the top */\n    z-index: 9999;\n  }\n  \n  .sticky-scroll-container.stuck {\n    position: fixed;\n    top: 80px;\n    width: 100%; /* Ensure full width when fixed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
