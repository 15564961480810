import React, { useState } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../styles/birdiePool.scss';

const PoolBirdies = ({ handleClickState }) => {
  const { birdiePoolData, clubLeaderboardInfo, setSelectedUser, setSelectedUserId, setScorecardState, getUserScorecard, myUserName, userScorecard, myUserId } = useBirdieContext();
  //console.log("STARTING STATS", birdiePoolData?.courseScorecardInfo);
  const clubLeaderboard = clubLeaderboardInfo?.leaderboardInfo;
  //console.log("CLUB LEADERBOARD INFO", clubLeaderboard);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function extractBirdieInfo(entries) {
    const birdieInfo = [];

    entries.forEach(entry => {
        const { userName, scoreCard, userId } = entry;
        const userBirdies = [];

        scoreCard.forEach(({ hole, birdieDates }) => {
            birdieDates.sort((a, b) => new Date(a) - new Date(b)); // Sort birdieDates from oldest to newest
            const firstDate = birdieDates[0]; // Grab the first date only
            if (firstDate) {
            userBirdies.push({ userName, hole, date: firstDate, userId });
            }
        });

        userBirdies.sort((a, b) => new Date(a.date) - new Date(b.date));

        userBirdies.forEach((birdie, index) => {
            birdieInfo.push({ ...birdie, holesBirdied: index + 1 });
        });
    });

    return birdieInfo;
}



const birdieDates = extractBirdieInfo(clubLeaderboard);
//console.log("BIRDIE DATES INFO:!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ", birdieDates);
// Sort the birdie dates info by date
birdieDates?.sort((a, b) => {
    if (new Date(a.date) - new Date(b.date) === 0) {
        return b.hole - a.hole;
    }
    return new Date(b.date) - new Date(a.date);
});


//console.log("SORTED BIRDIE DATES INFO: ", birdieDates);

const handleUserClick = (username, userId) => {
    setSelectedUser(username);
    setSelectedUserId(userId);
    if (username === myUserName && userId === myUserId) {
      setScorecardState(userScorecard);
    } else {
    const selectedUsersScorecard = getUserScorecard(username, userId);
    setScorecardState(selectedUsersScorecard);
    }
    handleClickState('SCORE_CARD');
  };


  return (
    <>
    <Box>
    <Typography variant="h5" style={{ fontSize: isMobile ? '12px' : '18px', fontWeight: '700'}}>Latest Competition Birdies</Typography>
    <Typography variant="h5" style={{ fontSize: isMobile ? '12px' : '18px', fontWeight: '700'}}>(First Birdies on a Hole)</Typography>
    </Box>
    {birdieDates?.length === 0 && (
        <Box style={{ margin: '20px auto', textAlign: 'center' }}>
          <Typography variant="h2">No birdies have been recorded yet.</Typography>
        </Box>
      )}
      {birdieDates?.length > 0 && (
    <Box>
      <TableContainer style={{ width: '95%', maxWidth:'750px',  margin: '20px auto'}} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '50px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Date</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '140px', border: '2px solid #002129'}} classes={{ root: 'table-header' }}>Player</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '52px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Hole</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '70px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Birdie #</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {birdieDates?.map(({ userName, userId, hole, date, holesBirdied }, index) => (
                                <TableRow key={`${userName}-${userId}-${hole}-${date}-${index}`} className={index % 2 === 0 ? 'odd-row' : 'even-row'} sx={index === birdieDates.length - 1 ? { borderBottom: '2px solid #002129' } : {}}>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{date.split('/')[0]}/{date.split('/')[1]}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" sx={{ textAlign: 'left', fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleUserClick(userName, userId)} style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{userName}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{hole}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{holesBirdied}/18</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
    </Box>
    )}
    </>
  );
}

export default PoolBirdies;