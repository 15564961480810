import React from 'react';
// import './styles/authLayout.scss';


const AuthLayout = ({ children }) => {
  return (
    <div className='auth-screen-container'>
      {children}
    </div>
  );
};

export default AuthLayout;