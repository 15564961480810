import { createTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';

let theme = createTheme({
  palette: {
    primary: {
      main: '#07356B',
      light: '#FFFFFF',
      dark: '#57534e',
    },
    secondary: {
      main: '#00AAD1',
      light: '#FFFFFF',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.8rem',
    },
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 600, // down from 600px
      desktop: 1000, // up from 1000px
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.light,
          borderRadius: '4px',
          textTransform: 'none',
          letterSpacing: 0,
          fontFamily: 'Inter',
          fontWeight: 600,
          padding: '8px 22px',
        },
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: 'transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        },
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.light,
            border: 'none',
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.light,
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            fontSize: '1rem',
            backgroundColor: 'transparent',
            color: 'var(--color-text)',
            border: 'none',
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.light,
          top: 'auto',
          right: '0',
          left: '0',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'var(--color-grey)',
          '&.Mui-active': {
            color: 'var(--color-grey)',
          },
          '&.Mui-completed': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
            color: theme.palette.secondary.main,
            '&+.MuiSwitch-track': {
              backgroundColor: theme.palette.secondary.main,
              // width:'20%'
            },
          },
        },
      },
    },
  },
});

export default function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
