import React from "react";
import { Box, Typography } from "@mui/material";
import "./SuperPickemBracket.scss";
import { hexToRgba } from "../bracketUtils/HexToRgba.js";

const BracketTeamRowLive = ({ 
    teamInfo, 
    score, 
    winner, 
    result, 
    round, 
    top,
}) => {
//console.log("Winner: ", winner, "Team Info: ", teamInfo, "Result: ", result, "Round: ", round);
    const teamId = teamInfo?.teamId;
    const seed = teamInfo?.seed;
    const teamDisplay = teamInfo?.nameDisplay;
    const teamDisplayCharacterCount = teamInfo?.teamDisplayCharacterCount;
    const teamLogo = teamInfo?.logo;
    const teamColor = teamInfo?.teamColor;
    //console.log("Find Border Color: ", findBorderColor, "Find Background Color: ", findBackgroundColor);

    const findBorderColor = (round, result, top ) => {
        if (round === 1 || (round === 2 && !top) || result === "Pending") {
            return '';
        } else if (result === "Win") {
            return `3px solid #00AA72`;
        } else if (result === "Loss" || !result) {
            return `3px solid #CC293C`;
        } else {
            return '';
        }
    }

    const findBackgroundColor = (round, result, top) => {
        if (round === 1 || (round === 2 && !top) || result === "Pending") {
            return '';
        } else if (result === "Win") {
            return `${hexToRgba('#00AA72', 0.20)}`;
        } else if (result === "Loss" || !result) {
            return `${hexToRgba('#CC293C', 0.20)}`;
        } else {
            return '';
        }
    }

    const borderColor = findBorderColor(round, result, top);
    const backgroundColor = findBackgroundColor(round, result, top);
    //console.log("Border color:", borderColor, "Background color:", backgroundColor);
    

    return (
        <Box 
            className="super-pickem-bracket-team-row" 
            style={{
                border: borderColor,
                backgroundColor: backgroundColor,
                cursor: 'default',
            }}
        >
            {/* Team Seed */}
            <Box className="super-pickem-bracket-team-seed"
                style={{
                    marginLeft: '5px',
                }}
            >
                <Typography className="seed-number">
                    {seed}
                </Typography>
            </Box>

            {/* Team Logo */}
            <Box 
                className="super-pickem-bracket-team-logo-placeholder"
                style={{
                    position: 'relative', // Required for overlay positioning
                }}
            >
                <img 
                    src={teamLogo}
                    alt="Team Logo"
                    className="team-logo"
                />
            </Box>

            {/* Team Name/Record */}
            <Box className="super-pickem-live-bracket-team-name">
                <Typography 
                    className="team-name" 
                    style={{
                        color: teamColor,
                        fontSize: teamDisplayCharacterCount > 15 ? '10px' : teamDisplayCharacterCount > 11 ? '12px' : '14px',
                        lineHeight: teamDisplayCharacterCount > 15 ? '1.4' : teamDisplayCharacterCount > 11 ? '' : '1',
                    }}
                >
                    {teamDisplay}
                </Typography>
            </Box>
            {score && (
            <Box className="super-pickem-live-bracket-team-score">
                <Typography 
                    className="team-score"
                    sx={{
                        fontWeight: winner ? '700' : '400',
                    }}    
                >
                    {score}
                </Typography>
            </Box>
            )}
        </Box>
    );
};

export default BracketTeamRowLive;
