import React from 'react';
import { Box, Typography } from '@mui/material';

const JoinMainGameOptIn = ({ addMainPoolEntry, setAddMainPoolEntry }) => {

    return (
        <Box>
        <Typography 
                sx={{ 
                    mt: 2, 
                    mb: 1,
                    fontSize: '12px',
                    fontWeight: '500',
                }}
            >
                Add your first entry to the Master Pool?
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="radio"
                        checked={addMainPoolEntry}
                        onChange={() => setAddMainPoolEntry(true)}
                        name="addMainPoolEntry"
                    />
                    <Typography 
                        sx={{ 
                            ml: 1,
                            fontSize: '12px', 
                        }}
                    >
                        Yes
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="radio"
                        checked={!addMainPoolEntry}
                        onChange={() => setAddMainPoolEntry(false)}
                        name="addMainPoolEntry"
                    />
                    <Typography 
                        sx={{ 
                            ml: 1,
                            fontSize: '12px',  
                        }}
                    >
                        No
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default JoinMainGameOptIn;