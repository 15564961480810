// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-sponsor-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 8px;
  background-color: #fff;
}
@media (max-width: 900px) {
  .home-page-sponsor-header {
    padding: 8px;
  }
}

.home-page-sponsor-header-image {
  width: 200px;
  height: auto;
}
@media (max-width: 900px) {
  .home-page-sponsor-header-image {
    width: 151px;
  }
}

.home-page-sponsor-header-text {
  margin-top: 8px;
  color: #00AAD1;
  text-decoration: underline;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 900px) {
  .home-page-sponsor-header-text {
    font-size: 12px !important;
  }
}

.sponsor-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 24px;
}

.modal-close-button {
  margin-top: 16px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/PoolHeaderBar/GameSponsorHeader.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,sBAAA;AAAJ;AAGI;EAXJ;IAYM,YAAA;EAAJ;AACF;;AAGE;EACE,YAAA;EACA,YAAA;AAAJ;AAEI;EAJF;IAKI,YAAA;EACJ;AACF;;AAEE;EACE,eAAA;EACA,cAAA;EACA,0BAAA;EACA,0BAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EATF;IAUI,0BAAA;EAEJ;AACF;;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;;AACE;EACE,gBAAA;EACA,WAAA;AAEJ","sourcesContent":["// SPONSORS HEADER BANNER\n.home-page-sponsor-header {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%; // Ensures it spans full width of parent container\n    margin: 0 auto;\n    padding: 8px;\n    background-color: #fff; // Adjust background as needed\n  \n    // Mobile styles\n    @media (max-width: 900px) {\n      padding: 8px;\n    }\n  }\n  \n  .home-page-sponsor-header-image {\n    width: 200px; // Default image width\n    height: auto; // Keep the aspect ratio\n  \n    @media (max-width: 900px) {\n      width: 151px; // Adjust image size for mobile\n    }\n  }\n  \n  .home-page-sponsor-header-text {\n    margin-top: 8px; // Space between image and text\n    color: #00AAD1;\n    text-decoration: underline;\n    font-size: 16px !important;\n    font-weight: 700 !important;\n    text-align: center;\n    cursor: pointer;\n  \n    @media (max-width: 900px) {\n      font-size: 12px !important;\n    }\n  }\n\n  .sponsor-modal {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 300px;\n    background-color: white;\n    padding: 16px; // Add padding\n    border-radius: 4px; // Rounded corners\n    box-shadow: 24px; // Standard modal box shadow\n  }\n  \n  .modal-close-button {\n    margin-top: 16px;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
