import React from "react";
import { Typography, Box } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNFLSurvivorContext } from "../context/NFLSurvivorContext";

const SurvivorHeader = () => {
    const {
        clickState,
        setClickState,
        gameAddress
    } = useNFLSurvivorContext();

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { gameId } = useParams();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleClickState = (click) => {
        if (clickState === "HOME") {
            navigateToTop(`/pool-hall`);
        } else {
            setClickState("HOME");
            navigateToTop(`/${gameAddress}/${uriEncoded(gameId)}/${uriEncoded("HOME")}/${uriEncoded("HOME")}`);
        }
    }
    
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                backgroundColor: '#002129',
            }}
            className="pickem-header"
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    padding: '0px 20px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: isMobile ? '32px' : '68px',
                }}
            >
                <Box
                    onClick={() => handleClickState()}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            '& .MuiTypography-root': {
                                color: '#FFC60A'
                            },
                            '& .MuiSvgIcon-root': {
                                color: '#FFC60A'
                            }
                        }
                    }}
                >
                    <ArrowBackIosNewIcon 
                        sx={{ 
                            color: 'white',
                            fontSize: isMobile ? '10px' : '14px',
                            marginLeft: isMobile ? '0px' : '25px', 
                        }}  
                    />
                    <Typography 
                        variant="h1" 
                        sx={{ 
                            color: 'white', 
                            fontSize: isMobile ? '12px' : '16px', 
                            fontWeight: '500', 
                            marginLeft: '8px' 
                        }}
                    >
                        {clickState === "HOME" ? "Pool Hall" : "NFL Survivor Home"}
                    </Typography>
                </Box>
                <Typography 
                    variant="h1" 
                    sx={{ 
                        color: 'white', 
                        fontSize: isMobile ? '16px' : '28px',  
                        fontWeight: '700',
                        marginRight: isMobile ? '0px' : '32px' 
                    }}
                >
                    NFL Survivor
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 'var(--borderRadius, 4px)',
                    flexShrink: 0,
                    backgroundColor: '#FFC60A',
                }}
            />
             </Box>
    );
};

export default SurvivorHeader;
