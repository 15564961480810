import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';

const Buttons = ({ title, buttons }) => {
  const handleClick = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 'md', margin: '0 auto', mb: 4 }}>
      {title && (
        <Typography
          variant="h6"
          sx={{
            fontSize: title.fontSize || '16px',
            color: title.color || '#002129',
            fontWeight: title.weight || '700',
            textDecoration: title.underline ? 'underline' : 'none',
            mb: 2
          }}
        >
          {title.text}
        </Typography>
      )}
      
      <Stack spacing={2} width="100%" alignItems="center">
        {buttons?.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => handleClick(button.link)}
            sx={{
              width: '275px',
              height: '43.59px', // Fixed height for all buttons
              backgroundColor: button.color || '#00AAD1',
              color: '#FFFFFF',
              padding: '6px 16px', // Standard MUI button padding
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: button.color || '#00AAD1',
                opacity: 0.9
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px'
            }}>
              {button.image && (
                <Box
                  component="img"
                  src={button.image}
                  alt={button.text}
                  sx={{
                    width: 24, // Reduced image size
                    height: 24, // Reduced image size
                    objectFit: 'cover',
                    borderRadius: '4px'
                  }}
                />
              )}
              <Box>{button.text}</Box>
            </Box>
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default Buttons;