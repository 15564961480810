import react, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import '../styles/birdiePool.scss';
import { Button, Table, TableHead, TableContainer, TableRow, TableCell, TableBody, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MinusClickModal = () => {
  const { scorecardState, removedBirdiesIndex, setRemovedBirdiesIndex, setScorecardState, selectedHole, setSelectedHole, modalState, setModalState, startingScorecard, handleScoreCardSubmission, editHole, setEditHole, editDate, setEditDate, editIndex, setEditIndex } = useBirdieContext();
  const hole = selectedHole;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("USER SCORECARD IN ADD MODAL", scorecardState);
    const userHole = scorecardState?.find(h => h.hole === hole);
    //console.log("USER HOLE", userHole, hole, userHole?.birdieDates);
    const userBirdieDates = userHole?.birdieDates;

    const [holeBirdies, setHoleBirdies] = useState(null);

    useEffect(() => {
        if (userBirdieDates) {
            const sortedUserBirdies = userBirdieDates.sort((a, b) => new Date(b) - new Date(a));
            setHoleBirdies(sortedUserBirdies);
        }
    }, [userBirdieDates]);
    useEffect(() => {
        //console.log("Removed Birdies Index:", removedBirdiesIndex);
        //console.log("Removed Birdies Index Length:", removedBirdiesIndex.length);
    }, [removedBirdiesIndex]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
      };


      const handleCancel = () => {
        //console.log("Cancel Button Hit");
        setModalState(null);
      };

      const onChange = (index) => {
        const hasIndexBeenRemoved = removedBirdiesIndex.includes(index);
        //console.log("Has Index Been Removed:", hasIndexBeenRemoved, index);
        if (hasIndexBeenRemoved) {
          setRemovedBirdiesIndex(removedBirdiesIndex.filter(i => i !== index));
        } else {
          setRemovedBirdiesIndex([...removedBirdiesIndex, index]);
        }
      }


      const handleDeleteSelected = () => {
        const sortedRemovedBirdiesIndex = [...removedBirdiesIndex].sort((a, b) => b - a);
        //console.log("Sorted Removed Birdies Index:", sortedRemovedBirdiesIndex);

        const remainingBirdies = userBirdieDates.filter((birdie, index) => {
            //console.log("Birdie:", birdie, index);
            return !sortedRemovedBirdiesIndex.includes(index);
        });
        //console.log("Remaining Birdies:", remainingBirdies);
        remainingBirdies?.sort((a, b) => new Date(a) - new Date(b));

        // Find the index of the hole in scorecardState
        const holeIndex = scorecardState.findIndex(h => h.hole === hole);
        //console.log("HoleIndex:",holeIndex)

        // If the hole exists, update its birdieDates
        if (holeIndex !== -1) {
            const updatedUserScorecard = [...scorecardState]; // Make a copy of userScorecard
            updatedUserScorecard[holeIndex].birdieDates = remainingBirdies; // Update the state with the modified userScorecard
            //console.log("UpdatedScorecard:",updatedUserScorecard);
            setScorecardState(updatedUserScorecard); //Remove this line
            //Enter Save Logic Here
            //Add logic to refresh the page, so birdie(s) update
        }

        // setModalState(null);
        handleScoreCardSubmission();
        // window.location.href = window.location.href;
      };

      return (
        <>
        <h2>Delete Birdie</h2>
        <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: '240px', overflowY: 'auto' }}>
          <Table stickyHeader sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
              <TableHead>
                  <TableRow>
                      <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center', borderRight: 'none'}}>Hole</TableCell>
                      <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center', borderRight: 'none'}}>Date</TableCell>
                      <TableCell sx={{ backgroundColor: '#002129', color:'white', fontSize: isMobile ? '16px' : '20px', fontWeight: '700', textAlign: 'center', borderRight: 'none'}}>Delete?</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {userHole?.birdieDates?.map((date, index) => (
                      <TableRow key={index}>
                          <TableCell sx={{ textAlign: 'center'}}>{hole}</TableCell>
                          <TableCell sx={{ textAlign: 'center'}}>{formatDate(date)}</TableCell>
                          <TableCell sx={{ textAlign: 'center'}}><input onChange={() => onChange(index)} type="checkbox" /></TableCell>
                      </TableRow>
                  ))}
              </TableBody>
          </Table>
       </TableContainer>
      </Box>
        <div>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'15px' }}>
            <Button
              variant='contained'
              sx={{
              bgcolor: 'white',
              height: '40px', // Ensure uniform height
              padding: '0 16px', // Ensure uniform padding
              color: '#002129',
              border: '1px solid #002129',
              '&:hover': {
              bgcolor: 'white', // Maintain white background on hover
              color: '#002129', // Maintain text color on hover
              border: '1px solid #002129', // Maintain border color on hover
              }, }}
              onClick={handleCancel}
              >
                CANCEL
            </Button>
            <Button
              variant='contained'
              className={`submit-button birdie-mobile-btn ${removedBirdiesIndex.length > 0 ? 'active' : ''}`}
              sx={{
                marginLeft: '12px',
                height: '40px', // Ensure uniform height
                padding: '0 16px', // Ensure uniform padding
                marginBottom:'0px'
              }}
              onClick={handleDeleteSelected}
              disabled={removedBirdiesIndex.length === 0}
            >
              DELETE
            </Button>
        </Box>
        </div>
      </>
      );

};

export default MinusClickModal;