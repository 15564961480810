import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useGetBracketData } from "../../../hooks/data/useGetBracketData";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from 'luxon';

const BracketGamesContext = createContext();

export const useBracketGamesContext = () => {
    const context = useContext(BracketGamesContext);
    if (!context) {
        throw new Error("useBracketGamesContext must be used within a BracketGamesProvider");
    }
    return context;
}

export const BracketGamesProvider = ({ children }) => {
    const {
        masterPoolData,
        leaderboardData,
    } = useUserAndGameContext();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const redisKeys = masterPoolData?.redisKeys;
    //console.log("redisKeys in BracketGamesProvider: ", redisKeys);

    const [hasDataFetched, setHasDataFetched] = useState(false);
    const [selectedUserBracketInfo, setSelectedUserBracketInfo] = useState(null);
    //console.log("SelectedUserBracketInfo in BracketGamesProvider: ", selectedUserBracketInfo);

    // Check for user parameter in URL
    useEffect(() => {
        const userParam = searchParams.get('user');
        if (userParam && leaderboardData) {
            const selectedUserEntry = leaderboardData?.find(entry => entry.username === userParam);
            //console.log("selectedUserEntry in BracketGamesProvider: ", selectedUserEntry);
            
            // Completely replace the previous state instead of merging
            setSelectedUserBracketInfo({
                username: userParam,
                picks: selectedUserEntry?.picks || [],
                tiebreaker: selectedUserEntry?.tiebreaker || null,
            });
        } else if (!userParam) {
            // Clear selection when no user parameter is present
            setSelectedUserBracketInfo(null);
        }
    }, [searchParams, leaderboardData]);

    const { mutate: getBracketData, data: bracketData, isLoading: bracketDataLoading, isError: bracketDataError, error: bracketDataErrorData } = useGetBracketData();

    //UseEffect to get bracket data
    useEffect(() => {
        if (!hasDataFetched && redisKeys) {
            getBracketData({ redisKeys });
            setHasDataFetched(true);
        }
    }, [hasDataFetched, redisKeys, getBracketData]);

    // Error handling for bracket data
    useEffect(() => {
        if (bracketDataError) {
            console.error("Error fetching bracket data:", bracketDataErrorData);
            if (bracketDataErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                navigate('/login');
            } else {
                navigate('/404/pool-hall');
            }
        }
    }, [bracketDataError, bracketDataErrorData, dispatch, navigate]);

    const { tournamentSchedule, teams } = bracketData || {};
    //console.log("tournamentSchedule in BracketGamesProvider: ", tournamentSchedule);

    const rounds = masterPoolData?.gameParameters?.rounds;
    const lastRound = rounds ? rounds[rounds.length - 1] : null;
    //console.log("lastRound in BracketGamesProvider: ", lastRound);

    const lastGame = tournamentSchedule?.Games?.filter(game => game.Round === lastRound);
    //console.log("lastGame in BracketGamesProvider: ", lastGame);
    const lastGameTimeInNewYork = lastGame?.length ? DateTime.fromISO(lastGame[0]?.GameTime, { zone: 'America/New_York' }) : null;
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const hasFinalStarted = lastGameTimeInNewYork ? timeNowInNewYork > lastGameTimeInNewYork : false;
    //console.log("hasFinalStarted in BracketGamesProvider: ", hasFinalStarted);

    const maxPossiblePoints = masterPoolData?.gameParameters?.maxPossiblePoints;

    const BracketGamesContextValues = useMemo(() => ({
        tournamentSchedule,
        teams,
        hasFinalStarted,
        maxPossiblePoints,
        selectedUserBracketInfo,
        setSelectedUserBracketInfo,
    }), [
        tournamentSchedule, 
        teams, 
        hasFinalStarted, 
        maxPossiblePoints, 
        selectedUserBracketInfo, 
    ]); 

    if (!hasDataFetched) {
        return <LoadingScreen />;
    }

    return (
        <BracketGamesContext.Provider value={BracketGamesContextValues}>
            {children}
        </BracketGamesContext.Provider>
    );
}

export default BracketGamesContext;