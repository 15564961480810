import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Close from '@mui/icons-material/Close';
import { DateTime } from 'luxon';


const SurvivorUserStatsPickBox = ({ startingWeekState, usersPicks, NFLTeams, currentWeeksDeadlineDateTime, areTheseMyStats, firstWeek }) => {

    const teamLogo = (teamId) => {
        return NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    // Determine the range of weeks to display
    const highWeek = startingWeekState > firstWeek ? startingWeekState : firstWeek;
    const lowWeek = firstWeek;

    console.log("High Week", highWeek, "Low Week", lowWeek, (highWeek - lowWeek + 1));

    return (
        <Box>
            <TableContainer 
                component={Paper} 
                className="leaderboard-table-container"
                sx={{
                    maxWidth: isMobile ? '312px' : '800px',
                    margin: '0 auto',
                    borderRadius: '0',
                    minWidth: isMobile ? '144px' : '560px',
                }}
            >
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow
                            sx={{
                                height: isMobile ? '17px' : '66px',
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            {[...Array(highWeek - lowWeek + 1)].map((_, index) => (
                                <TableCell 
                                    key={index} 
                                    className="leaderboard-table-header"
                                    sx={{
                                        minWidth: isMobile ? '58px' : '58px',
                                        color: '#FFF',
                                        fontSize: isMobile ? '14px' : '16px',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        whiteSpace: isMobile ? 'nowrap' : 'normal',
                                        padding: 0,
                                        wordBreak: isMobile ? 'normal' : 'break-word',
                                    }}
                                >
                                    {isMobile ? (
                                        `W${highWeek - index}`
                                    ) : (
                                        <>
                                            Week <br /> {highWeek - index}
                                        </>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                height: isMobile ? '52px' : '58px',
                            }}
                        >
                            {[...Array(highWeek - lowWeek + 1)]?.map((_, weekIndex) => {
                                const weekNumber = highWeek - weekIndex;
                                const pick = usersPicks?.find(pick => pick.week === weekNumber);
                                const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                                const isCurrentWeek = weekNumber === startingWeekState;
                                console.log("Is Current Week", isCurrentWeek, "Week Number", weekNumber, "Starting Week State", startingWeekState);
                                // If the week is in the future, skip rendering
                                if (weekNumber > startingWeekState && !areTheseMyStats) {
                                    return (
                                        <TableCell 
                                            key={weekIndex} 
                                            sx={{ 
                                                textAlign: 'center', 
                                                minWidth: isMobile ? '58px' : '58px', 
                                                padding: 0 
                                            }}
                                        >
                                            <Box 
                                                sx={{ 
                                                    width: isMobile ? '32px' : '40px', 
                                                    height: isMobile ? '32px' : '40px', 
                                                    border: '1px solid #ccc', 
                                                    display: 'flex', // Added
                                                    justifyContent: 'center', // Added
                                                    alignItems: 'center', // Added
                                                    margin: '0 auto'
                                                }} 
                                            />
                                        </TableCell>
                                    );
                                }

                                const hasDeadlinePassed = timeNowInNewYork > currentWeeksDeadlineDateTime;
                                const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                                console.log("Has Deadline Passed", hasDeadlinePassed, "Has Game Started", hasGameStarted);
                                console.log((isCurrentWeek && !hasDeadlinePassed && !hasGameStarted && !areTheseMyStats && pick?.teamId !== "No Pick"));
                                
                                return (
                                    <TableCell
                                        key={weekIndex}
                                        sx={{
                                            textAlign: 'center',
                                            minWidth: isMobile ? '58px' : '58px',
                                            padding: 0,
                                        }}
                                    >
                                        {pick ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: pick?.result === 'Win' ? '2px solid #00AA72' : (pick?.result === 'Loss' ? '2px solid #CC293C' : '1px solid #ccc'),
                                                    background: pick?.result === 'Win' ? '#CCEEE3' : ((pick?.result === 'Loss' && pick?.teamId !== "No Pick") ? '#F5D4D8' : '#f9f9f9'),
                                                    width: isMobile ? '32px' : '40px',
                                                    height: isMobile ? '32px' : '40px',
                                                    overflow: 'hidden',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {isCurrentWeek && !hasDeadlinePassed && !hasGameStarted && !areTheseMyStats && pick?.teamId !== "No Pick" ? (
                                                    <Box
                                                        sx={{
                                                            width: isMobile ? '32px' : '40px',
                                                            height: isMobile ? '32px' : '40px',
                                                            backgroundColor: '#FFF',
                                                            border: '1px solid #ccc',
                                                        }}
                                                    />
                                                ) : (
                                                    pick.teamId === 'No Pick' ? (
                                                        <Close
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#CC293C',
                                                                width: isMobile ? '18px' : '24px',
                                                                height: isMobile ? '18px' : '24px',
                                                                margin: '0 auto',
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={teamLogo(pick.teamId)}
                                                            alt={`Team ${pick.teamId}`}
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: isMobile ? '32px' : '40px',
                                                    height: isMobile ? '32px' : '40px',
                                                    border: '1px solid #ccc',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {/* Blank cell or placeholder */}
                                            </Box>
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SurvivorUserStatsPickBox;
