import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CFBSurvivorConferencePick = ({ conferenceName, pick, result, logo, previousLoss, anyLoss }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Typography className="bracket-box-header-headings" variant='h4' style={{ color: anyLoss ? '#CC293C' : '#002129'}}>{conferenceName}</Typography>
            <Box className="bracket-box-header-values">
                {pick && pick.teamId !== "No Pick" ? (
                    <img
                        src={logo}
                        alt="Team Logo"
                        style={{
                            width: isMobile ? '24px' : '48px',
                            height: isMobile ? '24px' : '48px',
                            margin: '0 auto',
                            marginTop: '4px',
                            border: '1px solid',
                            borderColor: result === "Win" ? '#00AA72' : result === "Loss" ? '#CC293C' : result === "Pending" ? '#002129' : 'transparent',
                            backgroundColor: result === "Win" ? 'rgba(0, 170, 114, 0.20)' : result === "Loss" ? 'rgba(204, 41, 60, 0.20)' : result === "Pending" ? '#FFF' : 'transparent'
                        }}
                    />
                ) : (pick && pick.teamId === "No Pick") || previousLoss === true ? (
                    <Box
                        sx={{
                            width: isMobile ? '24px' : '48px',
                            height: isMobile ? '24px' : '48px',
                            margin: '0 auto',
                            marginTop: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #CC293C',
                            backgroundColor: '#FFF',
                        }}
                    >
                        <CloseIcon sx={{ color: '#CC293C', fontSize: isMobile ? '16px' : '40px' }} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: isMobile ? '24px' : '48px',
                            height: isMobile ? '24px' : '48px',
                            margin: '0 auto',
                            marginTop: '4px',
                            border: '1px solid #002129',
                            backgroundColor: '#FFF',
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CFBSurvivorConferencePick;
