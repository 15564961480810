import React, { useState } from 'react';
import { Box, Typography, Modal, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useSaveTextOptIn } from '../../../hooks/pools/useSaveTextOptIn';
import MerchModal from './MerchModal';

const TextAcceptanceModal = ({ 
    joinOpen, 
    setJoinOpen, 
    status, 
    logo, 
    myUserId, 
    gameId, 
    preChecked,
    isTeamGame,
    isHostGame,
    hostGameData,
    sponsorCircleLogo,
    sponsorAbbreviation,
    selectedGameName,
    selectedGameReward,
    tShirtReward,
    selectedGameShirt,
    tShirt,
    countdownBackground,
    textOptInMessage,
}) => {
    const [isOptIn, setIsOptIn] = useState(preChecked || false);
    const [isOptOut, setIsOptOut] = useState(false);
    const [merchModalOpen, setMerchModalOpen] = useState(false);

    const { mutate, isLoading, isError, error, isSuccess } = useSaveTextOptIn();

    const hostGameId = hostGameData?.gameID;

    const handleCancel = () => {
        setJoinOpen(false);
    };

    // Handler for the "Yes" checkbox
    const handleOptInChange = (e) => {
        setIsOptIn(e.target.checked);
        if (e.target.checked) {
            setIsOptOut(false);
        }
    };

    // Handler for the "No" checkbox
    const handleOptOutChange = (e) => {
        setIsOptOut(e.target.checked);
        if (e.target.checked) {
            setIsOptIn(false);
        }
    };

    const handleClose = async () => {
        if (!isOptIn) {
            if (isTeamGame || isHostGame) {
                setMerchModalOpen(true);
            } else {
                window.location.href = window.location.href;
            }
        } else {
            let data = {
                userId: myUserId,
                gameId: gameId,
            };
            
            if (isTeamGame) {
                data.gameId = hostGameId;
            }
    
            try {
                await mutate(data, {
                    onSuccess: () => {
                        //console.log("Successfully opted in for text messages");
                        if (isTeamGame || isHostGame) {
                            setMerchModalOpen(true);
                        } else {   
                            setJoinOpen(false);
                            window.location.href = window.location.href;
                        }
                    },
                    onError: (error) => {
                        console.log("Error opting in for text messages: ", error);
                        setJoinOpen(false);
                        window.location.href = window.location.href;
                    }
                });
            } catch (error) {
                console.log("Error opting in for text messages: ", error);
                setJoinOpen(false);
                window.location.href = window.location.href;
            }
        }
    };

    const isChoiceMade = isOptIn || isOptOut;

    return (
        <>
            <Modal
                open={joinOpen && status === "success" && !merchModalOpen}
                onClose={null}
                disableEscapeKeyDown
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '335px',
                        maxHeight: '765px',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, 
                        overflow: 'scroll'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: '20px',
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        {isTeamGame ? `Welcome to Team ${sponsorAbbreviation}!` : "Welcome!"}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <img 
                            src={logo} 
                            alt="logo" 
                            style={{
                                display: 'block',
                            }}
                        />
                        {isTeamGame && (
                            <img 
                                src={sponsorCircleLogo} 
                                alt="logo" 
                                style={{
                                    height: '112px',
                                }}
                            />
                        )}
                    </Box>
                
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: '20px',
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        Would you like to opt-in for pick reminder text messages for this pool?
                    </Typography>

                    {textOptInMessage && (
                        <Typography
                            sx={{
                                color: '#002129',
                                fontSize: '12px',
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            {textOptInMessage}
                        </Typography>
                    )}

                    {!textOptInMessage && (
                        <>
                            <Typography
                                sx={{
                                    color: '#002129',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                }}
                            >
                                By selecting "Yes", you agree to receive text messages related to your game picks. Standard message and data rates may apply. You can opt-out at any time by replying STOP.
                            </Typography>

                            <Typography
                                sx={{
                                    color: '#002129',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                }}
                            >
                                Pick reminder texts will only be sent on game days if your picks have not been submitted.
                            </Typography>
                        </>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 4 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOptIn}
                                    onChange={handleOptInChange}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ fontWeight: 700 }}>
                                    Yes
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOptOut}
                                    onChange={handleOptOutChange}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography sx={{ fontWeight: 700 }}>
                                    No
                                </Typography>
                            }
                        />
                    </Box>
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '16px',
                        }}
                    >
                        {preChecked && (
                            <Button
                                onClick={handleCancel}
                                variant="contained"
                            >
                                CANCEL
                            </Button>
                        )}
                        <Button 
                            onClick={handleClose}
                            variant="contained"
                            disabled={!isChoiceMade}
                        >
                            CONTINUE
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <MerchModal
                merchModalOpen={merchModalOpen}
                setMerchModalOpen={setMerchModalOpen}
                setJoinOpen={setJoinOpen}
                selectedGameName={selectedGameName}
                selectedGameReward={selectedGameReward}
                tShirtReward={tShirtReward}
                sponsorAbbreviation={sponsorAbbreviation}
                logo={logo}
                selectedGameShirt={selectedGameShirt}
                tShirt={tShirt}
                countdownBackground={countdownBackground}
            />
        </>
    );
}

export default TextAcceptanceModal;