import { apiClient } from '../../apiClient';
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from '../../config';

const forgotPasswordRequest = (email) =>
apiClient.post(`${apiUrl}/user/forgot-password`, { email });

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPasswordRequest,
  });
};
