import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PoolPartyTrophyGold from '../../assets/images/PoolPartyTrophyGold.svg';
import InfoModal from '../LeaderboardsPage/LeaderboardPages/InfoModal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ReferralsPrizesNew = ({ referralLeaderboardInfo }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const hanleModalClick = () => {
        setModalOpen(true);
    }

    return (
        <Box className="game-leaderboard-container">
            <Box className="game-leaderboard-content">
                <Typography className="game-leaderboard-title">
                    {referralLeaderboardInfo?.title}
                </Typography>

                {referralLeaderboardInfo?.subTitle && (
                    <Typography className="game-leaderboard-subtitle">
                        {referralLeaderboardInfo?.subTitle}
                    </Typography>
                )}

                {referralLeaderboardInfo?.prizeText && (
                    <Box className="game-leaderboard-trophy-container">
                        <img
                            src={PoolPartyTrophyGold}
                            alt="Gold Trophy"
                            className="game-leaderboard-trophy-left"
                        />
                        <Typography className="game-leaderboard-prize-text">
                            {referralLeaderboardInfo?.prizeText}
                        </Typography>
                        <img
                            src={PoolPartyTrophyGold}
                            alt="Gold Trophy"
                            className="game-leaderboard-trophy-right"
                        />
                    </Box>
                )}

                {referralLeaderboardInfo?.prizes && (
                    <Box className="game-leaderboard-prizes-list" >
                        {referralLeaderboardInfo?.prizes.map((prize, index) => (
                            <Typography key={index} className="game-leaderboard-prize-text" sx={{marginBottom: '16px', maxWidth: '500px', textAlign:isMobile?'left' : 'center'}}>
                                {prize}
                            </Typography>
                        ))}
                    </Box>
                )}

                {referralLeaderboardInfo?.modalLinkText && (
                    <Typography
                        className="game-leaderboard-modal-link"
                        onClick={hanleModalClick}
                    >
                        {referralLeaderboardInfo?.modalLinkText}
                    </Typography>
                )}
            </Box>

            <InfoModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                modalInfo={referralLeaderboardInfo?.modalInfo}
            />
        </Box>
    );
};

export default ReferralsPrizesNew;
