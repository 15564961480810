import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import unlockedIcon from '../../../assets/images/unlockedIcon.png';

const SurvivorMyPicksDisplay = ({ 
    interval, 
    thisIntervalsPicks, 
    isEntryEliminated, 
    eliminationInterval, 
    hasThisIntervalsDeadlinePassed, 
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Safely check if we have picks and get the first one
    const hasPicks = thisIntervalsPicks && thisIntervalsPicks.length > 0;
    const firstPick = hasPicks ? thisIntervalsPicks[0] : null;

    const textColor = 
        !hasPicks || 
        (isEntryEliminated && interval >= eliminationInterval) || 
        firstPick?.result === "loss"
            ? "#CC293C"  // Red for loss or eliminated
            : firstPick?.result === "win"
                ? "#00AA72"  // Green for win
                : (hasPicks && !hasThisIntervalsDeadlinePassed)
                    ? "#00AAD1"
                    : "#002129";  // Default color

    const text = 
        (isEntryEliminated && interval > eliminationInterval) 
            ? "Eliminated" 
            : (!hasPicks && hasThisIntervalsDeadlinePassed)
                ? "No Pick"
                : firstPick?.result === "loss"
                    ? "Loss"
                    : firstPick?.result === "win"
                        ? "Survived"
                        : (hasPicks && !hasThisIntervalsDeadlinePassed)
                            ? "Pending"
                            : (hasPicks && hasThisIntervalsDeadlinePassed)
                                ? "Locked"
                                : "No Pick";

    const icon =
        (isEntryEliminated && interval > eliminationInterval) ||
        (!hasPicks && hasThisIntervalsDeadlinePassed) ||
        firstPick?.result === "loss"
            ? <CloseIcon sx={{ color: textColor, width: isMobile ? '24px' : '36px', height: isMobile ? '24px' : '36px' }} />
            : firstPick?.result === "win" 
                ? <CheckIcon sx={{ color: textColor, width: isMobile ? '24px' : '36px', height: isMobile ? '24px' : '36px'  }} />
                : (!hasPicks && !hasThisIntervalsDeadlinePassed)
                    ? ''
                    : (hasPicks && !hasThisIntervalsDeadlinePassed)
                        ? <img src={unlockedIcon} alt="Unlocked" style={{ width: isMobile ? '18px' : '24px', height: isMobile ? '18px' : '24px' }} />
                        : <LockIcon sx={{ color: textColor, width: isMobile ? '20px' : '28px', height: isMobile ? '20px' : '28px' }} />;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px 0',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '16px' : '22px',
                    fontWeight: '700',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <span style={{ color: '#002129' }}>My Pick:</span> 
                <span style={{ color: textColor }}>{text}</span> 
                {icon} 
            </Typography>
        </Box>
    );
}

export default SurvivorMyPicksDisplay;