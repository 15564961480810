import { combineReducers } from 'redux';

import {
  FETCH_GAMES_BY_WEEK_SUCCESS,
  FETCH_GAMES_BY_WEEK_FAIL,
  FETCH_GAMES_BY_WEEK_REQUEST,
  FETCH_SEASON_SCHEDULE_REQUEST,
  FETCH_SEASON_SCHEDULE_FAIL,
  FETCH_SEASON_SCHEDULE_SUCCESS,
  FETCH_SEASON_SCHEDULE_BASICS_REQUEST,
  FETCH_SEASON_SCHEDULE_BASICS_FAIL,
  FETCH_SEASON_SCHEDULE_BASICS_SUCCESS,
  FETCH_NFL_TEAMS_REQUEST,
  FETCH_NFL_TEAMS_FAIL,
  FETCH_NFL_TEAMS_SUCCESS,
  FETCH_NFL_SCORES_REQUEST,
  FETCH_NFL_SCORES_FAIL,
  FETCH_NFL_SCORES_SUCCESS,
  FETCH_CBB_LEAGUE_HIERARCHY_REQUEST,
  FETCH_CBB_LEAGUE_HIERARCHY_FAIL,
  FETCH_CBB_LEAGUE_HIERARCHY_SUCCESS,
  FETCH_CBB_TEAMS_REQUEST,
  FETCH_CBB_TEAMS_FAIL,
  FETCH_CBB_TEAMS_SUCCESS,
  FETCH_NCAA_TOURNAMENT_HIERARCHY_REQUEST,
  FETCH_NCAA_TOURNAMENT_HIERARCHY_FAIL,
  FETCH_NCAA_TOURNAMENT_HIERARCHY_SUCCESS,
  FETCH_NBA_TEAMS_REQUEST,
  FETCH_NBA_TEAMS_FAIL,
  FETCH_NBA_TEAMS_SUCCESS,
  FETCH_NBA_STANDINGS_REQUEST,
  FETCH_NBA_STANDINGS_FAIL,
  FETCH_NBA_STANDINGS_SUCCESS,
  FETCH_NBA_GAME_ODDS_BY_DATE_REQUEST,
  FETCH_NBA_GAME_ODDS_BY_DATE_SUCCESS,
  FETCH_NBA_GAME_ODDS_BY_DATE_FAIL,
  FETCH_NBA_GAMES_BY_DATE_REQUEST,
  FETCH_NBA_GAMES_BY_DATE_SUCCESS,
  FETCH_NBA_GAMES_BY_DATE_FAIL,

} from '../actions/dataImport';

  const initialState = {
    sportsData: [],
    error: null,
    seasonData: [],
    nflTeams: [],
    seasonBasicsData: [],
    cbbTeamsInitialState: [],
    cbbLeagueHierarchyInitialState: [],
    ncaaTournamentHierarchyInitialState: []
  };

  const sportsDataInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const seasonInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nflTeamsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const seasonBasicsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nflScoresInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const cbbTeamsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const cbbLeagueHierarchyInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const ncaaTournamentHierarchyInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nbaTeamsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nbaStandingsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nbaGameOddsInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const nbaGamesByDateInitialState = {
    data: [],
    loading: false,
    error: null,
  };

  const sportsDataReducer = (state = sportsDataInitialState, action) => {
    console.log('dataReducer action.type: ', action);

  switch (action.type) {
    case FETCH_GAMES_BY_WEEK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GAMES_BY_WEEK_SUCCESS:
      return {
        ...state,
        sportsData: action.payload,
        loading: false,
      };
    case FETCH_GAMES_BY_WEEK_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
};

const seasonReducer = (state = seasonInitialState, action) => {
  console.log('seasonReducer action.type: ', action);

  switch (action.type) {
    case FETCH_SEASON_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SEASON_SCHEDULE_SUCCESS:
      return {
        ...state,
        seasonData: action.payload,
        loading: false,
      };
    case FETCH_SEASON_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,

        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
};

const seasonBasicsReducer = (state = seasonBasicsInitialState, action) => {
  console.log('seasonReducer action.type: ', action);

  switch (action.type) {
    case FETCH_SEASON_SCHEDULE_BASICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SEASON_SCHEDULE_BASICS_SUCCESS:
      return {
        ...state,
        seasonBasicsData: action.payload,
        loading: false,
      };
    case FETCH_SEASON_SCHEDULE_BASICS_FAIL:
      return {
        ...state,
        error: action.payload,

        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
};

const nflTeamsReducer = (state = nflTeamsInitialState, action) => {
  console.log('nflTeamsReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NFL_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NFL_TEAMS_SUCCESS:
      console.log('nflTeamsReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NFL_TEAMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
};

const nflScoresReducer = (state = nflScoresInitialState, action) => {
  console.log('nflScoresReducer action.type: ', action);
  switch (action.type) {
    case FETCH_NFL_SCORES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NFL_SCORES_SUCCESS:
      console.log('nflScoresReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NFL_SCORES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
};

const cbbTeamsReducer = (state = cbbTeamsInitialState, action) => {
  switch (action.type) {
    case FETCH_CBB_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CBB_TEAMS_SUCCESS:
      // Correctly updating the 'data' key instead of 'cbbTeamsInitialState'
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_CBB_TEAMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

const cbbLeagueHierarchyReducer = (state = cbbLeagueHierarchyInitialState, action) => {
  console.log('cbbLeagueHierarchyReducer action.type: ', state);
  switch (action.type) {
    case FETCH_CBB_LEAGUE_HIERARCHY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CBB_LEAGUE_HIERARCHY_SUCCESS:
      console.log('cbbLeagueHierarchyReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_CBB_LEAGUE_HIERARCHY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

const ncaaTournamentHierarchyReducer = (state = ncaaTournamentHierarchyInitialState, action) => {
  console.log('ncaaTournamentHierarchyReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NCAA_TOURNAMENT_HIERARCHY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NCAA_TOURNAMENT_HIERARCHY_SUCCESS:
      console.log('ncaaTournamentHierarchyReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NCAA_TOURNAMENT_HIERARCHY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

const nbaTeamsReducer = (state = nbaTeamsInitialState, action) => {
  console.log('nbaTeamsReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NBA_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NBA_TEAMS_SUCCESS:
      console.log('nbaTeamsReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NBA_TEAMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

const nbaStandingsReducer = (state = nbaStandingsInitialState, action) => {
  console.log('nbaStandingsReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NBA_STANDINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NBA_STANDINGS_SUCCESS:
      console.log('nbaStandingsReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NBA_STANDINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

const nbaGameOddsReducer = (state = nbaGameOddsInitialState, action) => {
  console.log('nbaGameOddsReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NBA_GAME_ODDS_BY_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NBA_GAME_ODDS_BY_DATE_SUCCESS:
      console.log('nbaGameOddsReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NBA_GAME_ODDS_BY_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

const nbaGamesByDateReducer = (state = nbaGamesByDateInitialState, action) => {
  console.log('nbaGamesByDateReducer action.type: ', state);
  switch (action.type) {
    case FETCH_NBA_GAMES_BY_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NBA_GAMES_BY_DATE_SUCCESS:
      console.log('nbaGamesByDateReducer action.payload: ', action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_NBA_GAMES_BY_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state; // Return the current state for the default case
  }
}

// Combine your reducers using combineReducers
const dataReducer = combineReducers({
  sportsData: sportsDataReducer,
  seasonData: seasonReducer,
  nflTeamsData: nflTeamsReducer,
  seasonBasicsData: seasonBasicsReducer,
  nflScoresData: nflScoresReducer,
  cbbTeamsData: cbbTeamsReducer,
  cbbLeagueHierarchyData: cbbLeagueHierarchyReducer,
  ncaaTournamentHierarchyData: ncaaTournamentHierarchyReducer,
  nbaTeamsData: nbaTeamsReducer,
  nbaStandingsData: nbaStandingsReducer,
  nbaGameOddsData: nbaGameOddsReducer,
  nbaGamesByDateData: nbaGamesByDateReducer,
});

export default dataReducer;