export const shortDayDateTimFormat = (isoString) => {
    const date = new Date(isoString);

    // Format day abbreviation, month, and day
    const formattedDate = new Intl.DateTimeFormat('en-US', { 
        weekday: 'short', 
        month: 'numeric', 
        day: 'numeric', 
        timeZone: 'America/New_York' 
    }).format(date);

    // Format time (12-hour format with AM/PM)
    const formattedTime = new Intl.DateTimeFormat('en-US', { 
        hour: 'numeric', 
        minute: '2-digit', 
        hour12: true, 
        timeZone: 'America/New_York' 
    }).format(date);

    return `${formattedDate.replace(',', '')} ${formattedTime} ET`;
};