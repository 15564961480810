import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { getUserDataFromLocalStorage } from '../../redux/actions/loginImport';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LeaderboardMobileHeader from '../../assets/images/LeaderboardMobileHeader.png';
import LeaderBoardTableHeader from '../../assets/images/LeaderBoardTableHeader.png';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateTime } from 'luxon';

const Leaderboard = (props) => {
  console.log('Leaderboard props',props)
  // let LeaderboardInfo = props?.leaderboardInfo?.data;
  let GamesByDate = props?.nbaGamesByDate;
  console.log('GamesByDate', GamesByDate);
  let LeaderboardInfo = props?.leaderboardInfo?.data?.results;
  let subGroupLeaderBoard = props?.subLeaderBoardResults;
  let [leaderboardType, setLeaderboardType] = useState("leaderboard");
  let whichLeaderboard = leaderboardType === "leaderboard" ? LeaderboardInfo : subGroupLeaderBoard;
  let [leaderboardUserData, setLeaderboardUserData] = useState(whichLeaderboard);

  //console.log("Leaderboard Info:", LeaderboardInfo);
  //console.log ("LEADERBOARD USER DATA", leaderboardUserData);

  const userData = getUserDataFromLocalStorage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isUserInSubGroup = userData?.Games[0]?.subGroup;

  function nbaGetLogo(globalTeamID) {
    const team = props?.NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
    if (team) {
    return team.WikipediaLogoUrl;
    } else {
    return null;
    }
    }

    const leaderboardData = [];

    if (leaderboardUserData && typeof leaderboardUserData === "object") {
        // Iterate over the values of leaderboardUserData object
        Object.values(leaderboardUserData).forEach((info) => {
            const champInfo = info?.bracketPicks.find(entry => entry.round === 4);
            const champID = champInfo?.id;
            const champResult = champInfo?.Result;
            const champLogo = nbaGetLogo(champID);
            const champBoxColor = champResult === "Win" ? "#00AA72" : champResult === "Loss" ? "#CC293C" : null;
            const player = info?.user_name; // Fixed username typo
            const overallRank = info?.stats?.overallRank; // Accessing overallRank from stats object
            const totalPoints = info?.stats?.totalOverallPoints; // Accessing totalOverallPoints from stats object
            const bracketPoints = info?.stats?.totalBracketPoints; // Accessing totalBracketPoints from stats object
            const pickEmPoints = info?.stats?.onlyDailyPoints; // Accessing onlyBracketPicksPoints from stats object
            const bracketRank = info?.stats?.bracketRank; // Accessing bracketRank from stats object
            const pickEmRank = info?.stats?.dailyPicksRank; // Accessing onlyBracketPicksRank from stats object

            // Push the transformed data for the current entry into the leaderboardData array
            leaderboardData.push({
                overalLRank: overallRank,
                player: player,
                champLogo: champLogo,
                champBoxColor: champBoxColor,
                totalPoints: totalPoints,
                bracketPoints: bracketPoints,
                pickEmPoints: pickEmPoints,
                bracketRank: bracketRank,
                pickEmRank: pickEmRank
            });
        });
    }

const getDuplicateOverallRanks = () => {
    const countMap = {};
    leaderboardData?.forEach(entry => {
        const rank = entry?.overalLRank;
        countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicateRanks = Object.entries(countMap)
        .filter(([rank, count]) => count > 1)
        .map(([rank]) => parseInt(rank));

    return duplicateRanks;
};

const duplicateOverallRanks = getDuplicateOverallRanks();

const isUserInDuplicateRanks = (overalLRank) => {
    return duplicateOverallRanks?.includes(overalLRank);
};
const getDuplicateBracketRanks = () => {
    const countMap = {};
    leaderboardData?.forEach(entry => {
        const rank = entry?.bracketRank;
        countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicateBracketRanks = Object.entries(countMap)
        .filter(([rank, count]) => count > 1)
        .map(([rank]) => parseInt(rank));

    return duplicateBracketRanks;
};

const duplicateBracketRanks = getDuplicateBracketRanks();
//console.log("Duplicate bracket ranks:", duplicateBracketRanks);

const isUserinDuplicateBracketRanks = (bracketRank) => {
    return duplicateBracketRanks?.includes(bracketRank);
};


const getDuplicatePickEmRanks = () => {
    const countMap = {};
    leaderboardData?.forEach(entry => {
        const rank = entry?.pickEmRank;
        countMap[rank] = (countMap[rank] || 0) + 1;
    });

    const duplicatePickEmRanks = Object.entries(countMap)
        .filter(([rank, count]) => count > 1)
        .map(([rank]) => parseInt(rank));

    return duplicatePickEmRanks;
};

const duplicatePickEmRanks = getDuplicatePickEmRanks();
//console.log("Duplicate pickEm ranks:", duplicatePickEmRanks);

const isUserInDuplicatePickEmRanks = (pickEmRank) => {
    return duplicatePickEmRanks?.includes(pickEmRank);
};

function getLoggedInUserRank() {
  // Get the user object from the data array
  const user = leaderboardData?.find(entry => entry.player === userData.user_name);
  return user;
}

  function handleLeaderBoardChange(e){
    //console.log("handleLeaderBoardChange", e.target.value);
    setLeaderboardType(e.target.value);
    if(e.target.value === "leaderboard"){
      setLeaderboardUserData(LeaderboardInfo);
    }else{
      setLeaderboardUserData(subGroupLeaderBoard);
    }
  }

  const loggedInUserRank = getLoggedInUserRank();
  //console.log("Logged in user rank:", loggedInUserRank)

  function findUserTiebreaker(username) {
    const userEntry = LeaderboardInfo.find(entry => entry.user_name === username);
    if (userEntry) {
        //console.log("User tiebreaker entry", userEntry)
        return userEntry.tiebreaker;
    }
  }

  //const userTiebreakerTest = findUserTiebreaker("BWaite3");
  //console.log("User tiebreaker test:", userTiebreakerTest);


  const [sortBy, setSortBy] = useState('overalLRank');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (columnName) => {
      if (sortBy === columnName) {
          // Toggle sorting order if the same column is clicked again
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
          // Sort by the clicked column in ascending order by default
          setSortBy(columnName);
          if (columnName === 'overalLRank') {
            setSortOrder('asc');
          } else {
          setSortOrder('dec');
          }
      }
  };

  const sortedData = [...leaderboardData].sort((a, b) => {
      if (sortBy) {
          const comparison = a[sortBy] - b[sortBy];
          return sortOrder === 'asc' ? comparison : -comparison;
      }
      return 0;
  });


  // Target date and time: Thursday, June 6 at 8:30 pm EST
  const targetDateTime = DateTime.fromObject(
    { year: 2024, month: 6, day: 14, hour: 20, minute: 30 },
    { zone: 'America/New_York' }
  );
  
  // Current date and time in EST
  const now = DateTime.now().setZone('America/New_York');

  // Check if it's before the target date and time
  const isBeforeTargetDateTime = now <= targetDateTime;


  function findFinalPointsTotal() {
    const cutoffDate = DateTime.fromISO("2024-06-06T00:00:00", { zone: 'America/New_York' });
  
    const filteredGames = GamesByDate.filter(game => {
      const gameDate = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
      return gameDate >= cutoffDate &&
             (game.Status === 'Final' || game.Status === 'F/OT') &&
             game.SeriesInfo && 
             (game.SeriesInfo.HomeTeamWins === 4 || game.SeriesInfo.AwayTeamWins === 4);
    });
  
    if (filteredGames.length === 0) {
      return null; // or any other indication that no game was found
    }
  
    return filteredGames.reduce((total, game) => total + game.HomeTeamScore + game.AwayTeamScore, 0);
  }
  const finalPointsTotal = findFinalPointsTotal();
  console.log('FINAL POINTS TOTAL', finalPointsTotal);

  



  /* Define the colors for the header cells */
    const rankHeaderColor = sortBy === 'overalLRank' ? '#FFC60A' : '#fff';
    const totalPointsHeaderColor = sortBy === 'totalPoints' ? '#FFC60A' : '#fff';
    const bracketPointsHeaderColor = sortBy === 'bracketPoints' ? '#FFC60A' : '#fff';
    const pickEmPointsHeaderColor = sortBy === 'pickEmPoints' ? '#FFC60A' : '#fff';

  return (
    <Grid container sx={{ borderRadius: '0px'}}>
      {isMobile?
      <div style={{width:'100%'}} ><img src={LeaderboardMobileHeader} style={{display: 'block',margin:'0 auto', width:'100%'}} alt='leaderboard header'/></div>
      :
      <div style={{width:'100%', maxWidth: '1116px', margin:'0 auto'}} ><img src={LeaderBoardTableHeader} style={{display: 'block',margin:'0 auto', width:'100%'}} alt='leaderboard header'/></div>
    }
     {isUserInSubGroup?

// <TableRow sx={{background: '#F0F1F1', color:'#002129'}}>
//    <TableCell sx={{textAlign:'center', fontSize:'12px', padding:'16px 0',width:"30px",}}size="small">
<> <div style={{display:'flex', alignItems:'center', justifyContent:'center', margin:'20px auto', width:'100%' }}>
    <h3 style={{marginRight:"10px"}}>Leaderboards: </h3>
    <select className='border border-solid border-gray-900 box-border rounded text-sm p-1' style={{textAlign:'center', padding:'5px 12px', borderRadius:'5px'}} value={leaderboardType} onChange={handleLeaderBoardChange}>
      {/* Option for the selected username (whoseLoggedIn) */}
      <option key={"leaderboard"} value="leaderboard">Pool Party</option>
      {/* Options for other usernames */}
      <option key={"sharps"} value="sharps">Sharps</option>

    </select>
  </div>
  </>
//   </TableCell>
// </TableRow>
:
<></>
}

{finalPointsTotal && (
    <TableContainer component={Paper} sx={{ maxWidth: '1116px', margin: '0 auto' }}>
            <Table>
                <TableHead sx={{ background: '#002129', color: 'white'}} size="small">
                    <TableRow>
                        <TableCell
                            sx={{
                                color: 'white',
                                width: '100%',
                                maxWidth: '1116px',
                                fontWeight: '900',
                                textAlign: 'center',
                                padding: '16px 0'
                            }}
                        >
                            Final Points Total: {finalPointsTotal}
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
    </TableContainer>
    )}

    <Grid item xs={12} sx={{overflow: 'scroll'}}>
        <TableContainer component={Paper}>
            <Table sx={{width: {xs:'670px', sm:'670px', md:'1116px', lg:'1116px'}, margin:'0 auto'}}>
               <TableHead sx={{
                    background: '#002129',
                    color:'#fff'
                }}>


                 {loggedInUserRank?.overalLRank ?
                   <TableRow sx={{ background: '#F0F1F1', color: '#002129' }}>
                   <TableCell sx={{ textAlign: 'center', fontSize: '12px', width: "0%" }}>
                       {isUserInDuplicateRanks(loggedInUserRank?.overalLRank) ? 'T' : ''}{loggedInUserRank?.overalLRank}
                   </TableCell>
                   <TableCell sx={{textAlign:'left', fontSize:'12px'}}>
                                {loggedInUserRank?.player} <br />
                                {findUserTiebreaker(loggedInUserRank?.player) && `Tiebreaker: ${findUserTiebreaker(loggedInUserRank?.player)} pts`}
                            </TableCell>
                   <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"5%", lg:"5%"}}} >
                       <img style={{ display: 'block', width: "24px", height: "24px", margin: '0 auto', border: `2px solid ${loggedInUserRank?.champBoxColor}`, }} src={loggedInUserRank?.champLogo} alt="TEAM LOGO" />
                   </TableCell>
                   <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{sm:"45%", md:"25%", lg:"25%"} }}>
                       {loggedInUserRank?.totalPoints}
                   </TableCell>
                   <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"} }}>
                       {isUserinDuplicateBracketRanks(loggedInUserRank?.bracketRank) ? `${loggedInUserRank?.bracketPoints} (T${loggedInUserRank?.bracketRank})` : `${loggedInUserRank?.bracketPoints} (${loggedInUserRank?.bracketRank})`}
                   </TableCell>
                   <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"}}}>
                       {isUserInDuplicatePickEmRanks(loggedInUserRank?.pickEmRank) ? `${loggedInUserRank?.pickEmPoints} (T${loggedInUserRank?.pickEmRank})` : `${loggedInUserRank?.pickEmPoints} (${loggedInUserRank?.pickEmRank})`}
                   </TableCell>
               </TableRow>
                    :
                    <></>
                  }

                    <TableRow sx={{

                    color:'#fff'
                }}>
                        <TableCell
                            onClick={() => handleSort('overalLRank')}
                            sx={{
                                color: sortBy === 'overalLRank' ? rankHeaderColor : '#FFFFFF',
                                fontWeight: 900,
                                textAlign: 'center',
                                // width: "30px",
                                position: 'relative'
                            }}
                            size="small"
                        >
                            RANK
                            {sortBy === 'overalLRank' && (
                                sortOrder === 'asc'
                                ? <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)', color: rankHeaderColor }} />
                                : <ArrowDropDownIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)', color: rankHeaderColor }} />
                            )}
                            {sortBy !== 'overalLRank' && (
                                <>
                                    <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)' }} />
                                </>
                            )}
                        </TableCell>

                        <TableCell sx={{ color: '#fff', fontWeight: 900, textAlign: 'left'}}>PLAYER</TableCell>
                        <TableCell sx={{ color: '#fff', fontWeight: 900, textAlign: 'center'}} >CHAMP</TableCell>
                        <TableCell
                        onClick={() => handleSort('totalPoints')}
                        sx={{
                            color: totalPointsHeaderColor,
                            fontWeight: 900,
                            textAlign: 'center',
                            // width: '97px',
                            position: 'relative', // Add relative positioning
                        }}
                        size="small"
                        >
                        TOT PTS
                        {sortBy === 'totalPoints' && (
                            sortOrder === 'asc' ?
                            <ArrowDropDownIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} /> :
                            <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} />
                        )}
                        {sortBy !== 'totalPoints' && (
                            <>
                                <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} />
                            </>
                        )}
                        </TableCell>

                        <TableCell
                        onClick={() => handleSort('bracketPoints')}
                        sx={{
                            color: bracketPointsHeaderColor,
                            fontWeight: 900,
                            textAlign: 'center',
                            position: 'relative', // Add relative positioning
                        }}
                        >
                        BRACKET PTS
                        {sortBy === 'bracketPoints' && (
                            sortOrder === 'asc' ?
                            <ArrowDropDownIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} /> :
                            <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} />
                        )}
                        {sortBy !== 'bracketPoints' && (
                            <>
                                <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} />
                            </>
                        )}
                        </TableCell>

                        <TableCell
                        onClick={() => handleSort('pickEmPoints')} sx={{ color: pickEmPointsHeaderColor, fontWeight: 900, textAlign: 'center', position: 'relative',}}
                        >
                        PICK 'EM PTS
                        {sortBy === 'pickEmPoints' && (
                            sortOrder === 'asc' ?
                            <ArrowDropDownIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} /> :
                            <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' , right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }} />
                        )}
                        {sortBy !== 'pickEmPoints' && (
                            <>
                                <ArrowDropUpIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right:{xs:'-3px', sm:'-3px', md:'unset', lg:'unset'} }}/>
                            </>
                        )}
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* BEGIN: Render table rows */}
                    {sortedData?.map((row, index) => (
                        <TableRow
                            key={index}
                            className={index % 2 === 0 ? 'evenRow' : ''}
                        >
                            <TableCell sx={{ textAlign: 'center', fontSize: '12px',  }}>
                                {isUserInDuplicateRanks(row?.overalLRank) ? 'T' : ''}{row?.overalLRank}
                            </TableCell>
                            <TableCell sx={{textAlign:'left', fontSize:'12px'}}>
                                {row?.player} <br />
                                {findUserTiebreaker(row?.player) && !isBeforeTargetDateTime && `Tiebreaker: ${findUserTiebreaker(row?.player)} pts`}
                            </TableCell>
                            <TableCell sx={{textAlign:'center', fontSize:'12px'}}><img style={{display:'block', width:"24px", height:"24px", margin: '0 auto', border: row?.champBoxColor ? `2px solid ${row?.champBoxColor}` : 'none',}} src={row?.champLogo} alt="TEAM LOGO"/></TableCell>
                            <TableCell sx={{textAlign:'center', fontSize:'12px',}}>{row?.totalPoints}</TableCell>
                            <TableCell sx={{ textAlign: 'center', fontSize: '12px'}}>
                                {isUserinDuplicateBracketRanks(row?.bracketRank) ? `${row?.bracketPoints} (T${row?.bracketRank})` : `${row?.bracketPoints} (${row?.bracketRank})`}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center', fontSize: '12px' }}>
                                {isUserInDuplicatePickEmRanks(row?.pickEmRank) ? `${row?.pickEmPoints} (T${row?.pickEmRank})` : `${row?.pickEmPoints} (${row?.pickEmRank})`}
                            </TableCell>
                        </TableRow>
                    ))}
                    {/* END: Render table rows */}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
</Grid>
  );
}


export default Leaderboard;