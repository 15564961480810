import React, { useEffect } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, } from 'react-redux';
import { Box } from '@mui/material';
import './App.css';
import { HelmetProvider } from "react-helmet-async";
import routeMeta from './routeMeta.json';

import { initializeGA, sendPageview } from './analytics'; // Adjust the import path accordingly
import About from './pages/about';
import BracketPage from './pages/bracketPage';
import CreateGames from './pages/createGames';
import ContactPage from './pages/contactPage';
import ForgotPassword from './pages/forgotPassword';
import GamePage from './pages/gamePage';
import Home from './pages/home';
import Invitation from './pages/invitation';
import JoinCard from './components/JoinCard';
import Legal from './pages/legal';
import Login from './pages/login';
import MainLayout from './layouts/mainLayouts';
import NBAPlayoffsPage from './pages/NBAPlayoffs';
import NotFound from './pages/notFound';
import ProfilePage from './pages/ProfilePage';
import ResetPassword from './pages/resetPassword';
import SearchGames from './pages/searchGames';
import Settings from './pages/settings';
import SignUp from './pages/signUp';
import SportsBookPage from './pages/sportsBookPage';
import AuthLayout from './layouts/authLayout';
import PrivatePools from './pages/PrivatePools';
import BirdiePoolPage from './pages/BirdiePoolPage';
import PrivateInvitationPage from './pages/PrivateInvitationPage';
import PublicInvitationPage from './pages/PublicInvitationPage';
import UserProfile from './pages/userProfile';
import UserWelcome from './pages/userWelcome';
import NFLSurvivorPage from './pages/NFLSurvivor';
import NFLPickEmPage from './pages/NFLPickEm';
import PoolHall from './pages/PoolHall';
import BirdiePoolDemoPage from './pages/BirdiePoolDemoPage';
import MyPoolsPage from './pages/MyPoolsPage';
import CFBSurvivorPage from './pages/CFBSurvivorPage';
import FantasySportsbook from './pages/FantasySportsbook';
import QuickPickGamesPage from './pages/QuickPickGamesPage';
import AdminDashPage from './pages/AdminDashPage';
import AdminFootballGames from './pages/AdminFootballGames';
import SuperPickemGamesPage from './pages/SuperPickemGamesPage';

import { userLogoutAction } from './redux/actions/loginImport';

const queryClient = new QueryClient()

const App = () => {
  const location = useLocation(); // This is to track page changes with react-router
  const dispatch = useDispatch();
  useEffect(() => {
    initializeGA();
  }, []);
  const navigate = useNavigate();
  // Handle expired tokens and redirect the user to the login page
  useEffect(() => {
    const handleTokenExpiry = () => dispatch(userLogoutAction({
      logoutType: 'token-expired'
    }));
    window.addEventListener('token-expired', handleTokenExpiry);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('token-expired', handleTokenExpiry);
    };
  }, [navigate, dispatch]);

  useEffect(() => {
    sendPageview(location.pathname);
  }, [location]);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>

          <Route path="/" element={<MainLayout meta={routeMeta.home}><Home /></MainLayout>} />

          <Route path="/game/:poolType/:gameId" element={<MainLayout><GamePage /></MainLayout>} />
          <Route path="/create-new-game" element={<MainLayout><CreateGames /></MainLayout>} />
          <Route path="/welcome/" element={<MainLayout><UserWelcome /></MainLayout>} />
          <Route path="/sportsbook" element={<MainLayout><SportsBookPage /></MainLayout>} />
          <Route path="/invitation/:game_id" element={<MainLayout><Invitation /></MainLayout>} />
          <Route path="/user/:displayName" element={<MainLayout><UserProfile /></MainLayout>} />
          <Route path="/mulligan-madness" element={<MainLayout><BracketPage /></MainLayout>} />
          <Route path="/contact" element={<MainLayout meta={routeMeta.contact}><ContactPage /></MainLayout>} />
          <Route path="/story" element={<MainLayout meta={routeMeta.about}><About /></MainLayout>} />
          <Route path="/login/*" element={<MainLayout meta={routeMeta.login}><JoinCard><Box sx={{ marginTop: '32px' }}><Login /></Box></JoinCard></MainLayout>} />
          <Route path="/register/*" element={<MainLayout meta={routeMeta.register}><SignUp /></MainLayout>} />
          <Route path="/forgot-password" element={<ForgotPassword meta={routeMeta.forgotPassword}/>} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/profile/:step" element={<ProfilePage/>}/>
          <Route path="/nba-super-pickem" element={<MainLayout><NBAPlayoffsPage /></MainLayout>} />
          <Route path="/nba-super-pickem/:poolType/:gameId" element={<MainLayout><NBAPlayoffsPage /></MainLayout>} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/search" element={<SearchGames />} />
          <Route path='/private-pools' element={<MainLayout meta={routeMeta.BirdiePool}><PrivatePools /></MainLayout>} />
          <Route path='/birdie-pool/:gameId/:component/:groupStateCheck' element={<MainLayout meta={routeMeta.BirdiePool} ><BirdiePoolPage /></MainLayout>} />
          <Route path='/private-party-invite/:gameId/:privatePartyId/:inviteeId' element={<MainLayout><PrivateInvitationPage /></MainLayout>} />
          <Route path='/public-party-invite/:gameId/:privatePartyId/:inviteeId' element={<MainLayout><PublicInvitationPage /></MainLayout>} />
          <Route path="/nfl-survivor" element={<MainLayout meta={routeMeta.NFLsurvivor}><NFLSurvivorPage /></MainLayout>} />
          <Route path="/nfl-pickem/:gameId/:component/:pageState/:optionalState?" element={<MainLayout meta={routeMeta.NFLpickFivePickem}><NFLPickEmPage /></MainLayout>} />
          <Route path="/nfl-survivor/:gameId/:component/:pageState/:optionalState?" element={<MainLayout meta={routeMeta.NFLsurvivor}><NFLSurvivorPage /></MainLayout>} />
          <Route path="/cfb-survivor/:gameId/:component/:pageState/:optionalState?" element={<MainLayout meta={routeMeta.CFBSurvivorPool}><CFBSurvivorPage /></MainLayout>} />
          <Route path="/pool-hall" element={<MainLayout meta={routeMeta.poolHall}><PoolHall /></MainLayout>} />
          <Route path="/my-pools" element={<MainLayout><MyPoolsPage /></MainLayout>} />
          {/* <Route path="/welcome/:userId" element={<UserWelcome />} /> */}
          <Route path='/birdie-pool-demo' element={<MainLayout meta={routeMeta.BirdiePoolDemo}><BirdiePoolDemoPage /></MainLayout>} />
          <Route path="/settings/:userId" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
          <Route path='/404/:redirect?' element={<NotFound />} />
          <Route path="/test-sportsbook" element={<MainLayout><FantasySportsbook /></MainLayout>} />
          <Route path="/quick-pick-nfl/:gameId/:component/:pageState/:optionalState?" element={<MainLayout meta={routeMeta.QuickPickNFL}><QuickPickGamesPage sport={'nfl'}/></MainLayout>} />
          <Route path="/quick-pick-cfb" element={<MainLayout><QuickPickGamesPage sport={'cfb'}/></MainLayout>} />
          <Route path="/super-pickem-cfb/:gameId/:component/:pageState/:optionalState?" element={<MainLayout><SuperPickemGamesPage sport={'cfb'}/></MainLayout>} />
          <Route path="/admin-dash/:page" element={<MainLayout><AdminDashPage /></MainLayout>} />
          {/* <Route path="/admin-football-games" element={<MainLayout><AdminFootballGames /></MainLayout>} /> */}
        </Routes>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
