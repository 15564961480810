import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useMyPoolsPageContext } from '../context/MyPoolsPageContext';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import MyPoolsCard from '../MyPoolsCard/MyPoolsCard';
import '../MyPoolsPage.scss';

const MyInactivePools = () => {
    const { myUserId, userData, gamesData } = useMyPoolsPageContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const userInactiveGames = userData?.Games?.filter(game => 
        game.pool?.dontShowInMyPools || game.settings?.dontShowInMyPools
    ).map(game => game.gameID || game.masterPoolId);

    const normalizeLeague = (league) => {
        if (!league) return '';

        if (league.startsWith('[') && league.endsWith(']')) {
            return league.slice(2, -2);
        }
        
        return league;
    };

    const myPoolsCardData = [];

    for (let i = 0; i < userInactiveGames?.length; i++) {
        const game = userInactiveGames[i];
        const matchingGame = gamesData?.find(pool => pool.gameID === game);

        if (matchingGame) {
            const gameData = {
                gameName: matchingGame?.gameName,
                partyInfo: game?.subGroups,
                league: normalizeLeague(matchingGame?.league),
                gameType: matchingGame?.gameType,
                logo: matchingGame?.clubLogo,
                // currentWeek: matchingGame?.gameType !== "BirdiePools"
                //     ? (matchingGame?.league === "NFL" ? nflCurrentWeek : 
                //         matchingGame?.league === "NCAA" ? cfbCurrentWeek : null)
                //     : null,
                userPicks: game?.pool,
                gameAddress: matchingGame?.gameAddress,
                gameId: matchingGame?.gameID,
                headerDisplayName: matchingGame?.headerDisplayName,
                sponsorLogo: matchingGame?.sponsorInfo?.sponsorLogo,
                startingWeek: matchingGame?.gameParameters?.[0]?.startingWeek,
                picksPerInterval: matchingGame?.gameParameters?.[0]?.picksPerInterval,
                firstInterval: matchingGame?.gameParameters?.[0]?.firstInterval,
                lastInterval: matchingGame?.gameParameters?.[0]?.lastInterval,
                rewards: matchingGame?.rewards,
                usingTiebreaker: matchingGame?.gameParameters?.[0]?.usingTiebreaker,
                userRewards: game?.rewards,
                hideSponsorInPoolHall: matchingGame?.sponsorInfo?.hideSponsorInPoolHall,
                didPoolPartyMakeInactive: matchingGame?.dontShowInMyPools
            };
            
            myPoolsCardData.push(gameData);
        }
    }

    myPoolsCardData.reverse();

    const hasData = myUserId !== null;

    return hasData ? (
        myPoolsCardData.length > 0 ? (
            myPoolsCardData.map((gameData, index) => (
                <MyPoolsCard 
                    key={index}
                    {...gameData}
                />
            ))
        ) : (
            <Box
                sx={{
                    display: 'flex',
                    padding: '16px',
                    width: isMobile ? '311px' : '500px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: '8px',
                    border: '2px solid #E8E8E8',
                    backgroundColor: '#FFF',
                    margin: '0 auto',
                    marginTop: '24px',
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '18px',
                        fontWeight: '800',
                    }}
                >
                    None
                </Typography>
            </Box>
        )
    ) : (
        <LoadingScreen />
    );
};

export default MyInactivePools;
