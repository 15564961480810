// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-pick-nav {
  width: 100%;
  background-color: #002129;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.quick-pick-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.quick-pick-nav-item {
  color: #C0C0C0;
  font-weight: 600;
  font-size: 24px;
  padding: 12px;
}
@media screen and (max-width: 900px) {
  .quick-pick-nav-item {
    font-size: 12px;
    padding: 12px 6px;
    font-weight: 700;
    white-space: nowrap;
  }
}

.active {
  color: #FFFFFF;
  background: #002F3A;
}`, "",{"version":3,"sources":["webpack://./src/components/QuickPickGames/QuickPickNav/QuickPickNav.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,2CAAA;AACJ;;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAEE;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;AACJ;AAAI;EALF;IAMI,eAAA;IACA,iBAAA;IACA,gBAAA;IACA,mBAAA;EAGJ;AACF;;AAAE;EACE,cAAA;EACA,mBAAA;AAGJ","sourcesContent":[".quick-pick-nav{\n    width: 100%;\n    background-color: #002129;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  }\n  \n  .quick-pick-nav-container{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin:0 auto;\n  }\n  \n  .quick-pick-nav-item{\n    color:#C0C0C0;\n    font-weight: 600;\n    font-size: 24px;\n    padding: 12px;\n    @media screen and (max-width: 900px) {\n      font-size: 12px;\n      padding: 12px 6px;\n      font-weight: 700;\n      white-space: nowrap;\n    }\n  }\n  \n  .active{\n    color: #FFFFFF;\n    background: #002F3A;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
