// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-ready-box {
  background-color: white;
  border: 2px solid #002129;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 32px;
  min-width: 300px;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.not-ready-box .header {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 16px;
  color: #002129;
}
.not-ready-box .text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #002129;
}
.not-ready-box .logo-container {
  margin: 24px 0;
  width: 150px;
  height: 150px;
  display: block;
  justify-content: center;
  align-items: center;
}
.not-ready-box .logo-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.not-ready-box .subheader {
  font-size: 18px;
  font-weight: 800;
  margin-top: 24px;
  color: #002129;
  text-decoration: underline;
}
.not-ready-box .subheader.no-logo {
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/GameModals/GameNotReadyModal/GameNotReadyBox.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,2CAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAAR;AAGI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AADR;AAII;EACI,cAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;AAFR;AAIQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AAFZ;AAMI;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,0BAAA;AAJR;AAMQ;EACI,gBAAA;AAJZ","sourcesContent":["// GameNotReadyBox.scss\n.not-ready-box {\n    background-color: white;\n    border: 2px solid #002129;\n    border-radius: 8px;\n    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\n    padding: 32px;\n    min-width: 300px;\n    max-width: 500px;\n    margin: 20px auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    .header {\n        font-size: 18px;\n        font-weight: 800;\n        margin-bottom: 16px;\n        color: #002129;\n    }\n\n    .text {\n        font-size: 18px;\n        font-weight: 400;\n        margin-bottom: 8px;\n        color: #002129;\n    }\n\n    .logo-container {\n        margin: 24px 0;\n        width: 150px;\n        height: 150px;\n        display: block;\n        justify-content: center;\n        align-items: center;\n\n        img {\n            max-width: 100%;\n            max-height: 100%;\n            object-fit: contain;\n        }\n    }\n\n    .subheader {\n        font-size: 18px;\n        font-weight: 800;\n        margin-top: 24px;\n        color: #002129;\n        text-decoration: underline;\n\n        &.no-logo {\n            margin-top: 32px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
