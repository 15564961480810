import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSaveSuperPickemBracketPicks } from "../../../hooks/pools/useSaveSuperPickemBracket";
import ErrorModal from "../../GameModals/ErrorModal/ErrorModal";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";  
import SuperPickemSubmitPicksModal from "../../GameModals/SuperPickemModals/SuperPickemSubmitModal";
import PicksNotSavedModal from "../../GameModals/ErrorModal/PicksNotSavedModal";
import SuperPickemPicksWarningModal from "../../GameModals/SuperPickemModals/SuperPickemPicksWarningModal";
import SuperPickemBracketSubmitModal from "../../GameModals/SuperPickemModals/SuperPickemBracketSubmitModal";

const MyBracketSubmitBar = ({
    myUserId,
    gameType,
    mainGameId,
    league,
    seeds,
    bracketState,
    setBracketState,  
    userBracketPicksFromDB,
    gameRewards,
    joinReward,
    season,
    logo,
    countdownDeadline,
}) => {
    
    const { mutate, isLoading, isError, error, isSuccess } = useSaveSuperPickemBracketPicks();

    const dispatch = useDispatch();

    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [checkerData, setCheckerData] = useState({});
    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [internalErrorOpen, setInternalErrorOpen] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState('');
    const [logoLoaded, setLogoLoaded] = useState(false); // Track logo load status
    const [picksSubmitted, setPicksSubmitted] = useState(false);

    const bracketPicksToMake = seeds?.length - 1;
    const howManyPickMadeThisInterval = bracketState?.length;
    //console.log("Bracket Picks To Make:", bracketPicksToMake, "How Many Picks Made:", howManyPickMadeThisInterval);

    // Preload the logo image when component mounts
    useEffect(() => {
        if (logo) {
            const img = new Image();
            img.src = logo;
            img.onload = () => setLogoLoaded(true); // Set logoLoaded to true when image loads
        }
    }, [logo]);

    const handleCancel = () => {
        setBracketState(userBracketPicksFromDB);
    }


    const handleSubmit = async () => {
        if (picksSubmitted) return;
        setPicksSubmitted(true);
        //console.log("Submitting Picks");
        const picksData = {
            picks: bracketState,
            userID: myUserId,
            gameType: gameType,
            gameId: mainGameId,
            league: league,
            season: season,
        }

        if (gameRewards?.length > 0 && !joinReward) {
            //console.log("Sending Join Reward");
            picksData.joinReward = true;
        }
        
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    const checkerData = response?.checkerData;
                    //console.log("Checker Data", checkerData);
                    if (checkerData?.success) {
                        setCheckerData(checkerData);
                        setSubmitModalOpen(true);
                        //console.log("SUCCESS");
                    } else {
                        setSubmitError(true);
                        setErrorMessage(checkerData?.message);
                        setSubmitModalOpen(true);
                    }
                },
                onError: (error) => {
                    if (error.response.status === 401) {
                        dispatch(userLogoutAction());
                    } else {
                        setInternalErrorMessage(error.response.data.error);
                        setInternalErrorOpen(true);
                    }
                }
            });
        } catch (error) {
            setInternalErrorMessage(error.message);
            setInternalErrorOpen(true);
        }
    }

    return (
        <>
            <Box className="quick-pick-submit-bar-container">
                {/* Content row */}
                <Box className="quick-pick-submit-bar-content-row">
                    <Box className="quick-pick-submit-bar-text-group">
                        <Typography className="quick-pick-submit-bar-typography">Picks:</Typography>
                        <Typography 
                            className="quick-pick-submit-bar-typography"
                            sx={{
                                color: howManyPickMadeThisInterval === bracketPicksToMake ? "#00AA72" : "#CC293C",
                                marginLeft: '5px'
                            }}
                        >
                            {howManyPickMadeThisInterval}/{bracketPicksToMake}
                        </Typography>
                        {howManyPickMadeThisInterval === bracketPicksToMake ? (
                            <CheckIcon className="quick-pick-submit-bar-icon" style={{ color: '#00AA72'}}/>
                        ) : (
                            <CloseIcon className="quick-pick-submit-bar-icon" style={{ color: '#CC293C'}}/>
                        )}
                    </Box>
                </Box>
        
                {/* Buttons */}
                <Box className="quick-pick-submit-bar-button-group">
                    <Button 
                        variant="contained" 
                        className="quick-pick-submit-bar-cancel-button" 
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmit}
                        disabled={picksSubmitted}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>

            {/* Modal for submitting picks */}
            {submitModalOpen && !submitError && logoLoaded && (
                <SuperPickemBracketSubmitModal
                    modalOpen={submitModalOpen}
                    setModalOpen={setSubmitModalOpen}
                    checkerData={checkerData}
                    logo={logo}
                    countdownDeadline={countdownDeadline}
                />
            )}

            {/* Error Modals */}
            {submitModalOpen && submitError && (
                <PicksNotSavedModal
                    submitModalOpen={submitModalOpen}
                    setSubmitModalOpen={setSubmitModalOpen}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}

            {internalErrorOpen && (
                <ErrorModal
                    internalErrorOpen={internalErrorOpen}
                    setInternalErrorOpen={setInternalErrorOpen}
                    internalErrorMessage={internalErrorMessage}
                    setInternalErrorMessage={setInternalErrorMessage}
                />
            )}
        </>
    );
}

export default MyBracketSubmitBar;
