import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Button, Modal, FormControl, Select, MenuItem } from '@mui/material';
import { useAddEntryTracking } from '../../../../hooks/pools/useAddEntryTracking';

const TrackingModal = ({ open, onClose, trackingInfo, logo, myUserId, gameId }) => {
  const [selectedReferral, setSelectedReferral] = useState('');
  const [selectedName, setSelectedName] = useState('');

  const { mutate: addEntryTracking } = useAddEntryTracking();
  
  const sortedMembers = useMemo(() => {
    return trackingInfo && trackingInfo.members 
      ? [...trackingInfo.members].sort((a, b) => 
          a.lastName.localeCompare(b.lastName) || 
          a.firstName.localeCompare(b.firstName)
        )
      : [];
  }, [trackingInfo]);

  // Set initial value based on hideNone property only when the modal first opens
  useEffect(() => {
    if (open) {
      // Only set default values if no selection has been made yet
      if (selectedReferral === '') {
        if (trackingInfo?.hideNone) {
          // If None is hidden and there are members, set first member as default
          if (sortedMembers?.length > 0) {
            setSelectedReferral(sortedMembers[0].id);
            setSelectedName(`${sortedMembers[0].firstName} ${sortedMembers[0].lastName}`);
          }
        } else {
          // Default to 'none' if it's not hidden
          setSelectedReferral('none');
          setSelectedName('None');
        }
      }
    } else {
      // Reset when modal closes
      setSelectedReferral('');
      setSelectedName('');
    }
  }, [open, trackingInfo?.hideNone, sortedMembers, selectedReferral]);

  const handleReferralChange = (e) => {
    const selectedId = e.target.value;
    setSelectedReferral(selectedId);
    
    if (selectedId === 'none') {
      setSelectedName('None');
    } else {
      const selectedMember = sortedMembers.find(member => member.id === selectedId);
      if (selectedMember) {
        setSelectedName(`${selectedMember.firstName} ${selectedMember.lastName}`);
      } else {
        // Handle case where member might not be found
        setSelectedName('');
      }
    }
  };

  const handleSubmit = () => {
    if (!selectedName) {
        onClose();
    } else {
        //console.log("Selected Referral: ", selectedName);
        const referralData = {
            userId: myUserId,
            gameId: gameId,
            referralName: selectedName,
        };

        addEntryTracking(referralData, {
            onSuccess: () => {
                onClose();
            },
            onError: () => {
                onClose();
            }
        });
    }
  };

  const extraText = trackingInfo?.extraText;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="referral-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '95%',
          maxWidth: '400px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '4px',
          outline: 'none'
        }}
      >
        <Box
          sx={{
            margin: '0 auto',
            marginBottom: '32px',
          }}
        >
          <img
            src={logo}
            alt={"Game Logo"}
            loading="lazy"
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              maxWidth: '350px',
              maxHeight: '150px',
              objectFit: 'contain',
              margin: '0 auto',
            }}
          />
        </Box>

        <Typography 
          id="referral-modal-title"
          variant="h6" 
          component="h2" 
          sx={{ 
            mb: 2,
            textAlign: 'center',
            fontWeight: 500
          }}
        >
          {extraText ?
            extraText :
          'Do you have a referral?'}
        </Typography>
        
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Select
            value={selectedReferral}
            onChange={handleReferralChange}
            displayEmpty
          >
            {!trackingInfo?.hideNone && (
              <MenuItem value="none">None</MenuItem>
            )}
            {sortedMembers?.map((member) => (
              <MenuItem 
                key={member.id} 
                value={member.id}
              >
                {`${member.lastName}, ${member.firstName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              height: '36px',
              width: '150px',
              fontSize: '14px',
              color: '#fff',
              backgroundColor: '#002129',
              '&:hover': {
                backgroundColor: '#002129',
              }
            }}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TrackingModal;