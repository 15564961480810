import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useGetGameReferrals } from '../../../hooks/pools/useGetGameReferrals';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const ReferralLeaderboard = ({ myUsername, gameId }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(true);

    const { mutate: fetchGameReferrals, data: gameReferrals, isLoading: gameReferralsLoading, isError: gameReferralsError, error: gameReferralsErrorData } = useGetGameReferrals();

    // Use Effect to fetch/refetch game referrals every time gameId changes
    useEffect(() => {
        if (gameId) {
            setLoading(true);
            fetchGameReferrals({ gameID: gameId });
            setLoading(false);
        }
    }, [gameId, fetchGameReferrals]);

    //console.log('gameReferralsData:', gameReferrals);

    // Find my referral only if gameReferrals exists and is iterable
    const myReferral = useMemo(() => {
        if (!gameReferrals || !Array.isArray(gameReferrals)) return null;
        return gameReferrals.find(referral => referral.username === myUsername);
    }, [gameReferrals, myUsername]);

    // Sorting state
    const [sort, setSort] = useState({
        column: 'referralCount',
        direction: 'desc'
    });

    // Sorting function
    const sortedReferrals = useMemo(() => {
        // Check if gameReferrals exists and is an array before sorting
        if (!gameReferrals || !Array.isArray(gameReferrals)) return [];
        
        return [...gameReferrals].sort((a, b) => {
            // Determine sorting logic based on current column
            switch (sort.column) {
                case 'username':
                    const nameCompare = a.username.localeCompare(b.username);
                    return sort.direction === 'asc' ? nameCompare : -nameCompare;
                
                case 'referralCount':
                    // Primary sort by referral count
                    if (a.referralCount !== b.referralCount) {
                        return sort.direction === 'asc'
                            ? a.referralCount - b.referralCount
                            : b.referralCount - a.referralCount;
                    }
                    // Secondary sort by new sign-ups if referral count is equal
                    return sort.direction === 'asc'
                        ? a.newSignUps - b.newSignUps
                        : b.newSignUps - a.newSignUps;
                
                case 'newSignUps':
                    // Primary sort by new sign-ups
                    if (a.newSignUps !== b.newSignUps) {
                        return sort.direction === 'asc'
                            ? a.newSignUps - b.newSignUps
                            : b.newSignUps - a.newSignUps;
                    }
                    // Secondary sort by referral count if new sign-ups are equal
                    return sort.direction === 'asc'
                        ? a.referralCount - b.referralCount
                        : b.referralCount - a.referralCount;
                
                default:
                    return 0;
            }
        });
    }, [gameReferrals, sort]);

    // Function to handle column click for sorting
    const handleColumnClick = (column) => {
        setSort(prevSort => ({
            column,
            // Toggle direction if same column, otherwise start with desc
            direction: prevSort.column === column 
                ? (prevSort.direction === 'asc' ? 'desc' : 'asc')
                : 'desc'
        }));
    };

    if (loading || gameReferralsLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            {myReferral && (
                <TableContainer
                    component={Paper}
                    sx={{
                        maxWidth: '600px',
                        margin: '0 auto',
                        backgroundColor: '#F0F1F1',
                    }}
                >
                    <Table
                        sx={{ borderRadius: '0px'}}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        width: '40%',
                                        color: '#002129',
                                    }}
                                >
                                    {myUsername}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                        color: '#002129',
                                    }}
                                >
                                    {myReferral?.referralCount}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                        color: '#002129',
                                    }}
                                >
                                    {myReferral?.newSignUps}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <TableContainer component={Paper} sx={{ maxWidth: '600px', margin: '0 auto', marginBottom: '32px', borderRadius: '0px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('username')}
                                sx={{
                                    width: '40%',
                                    color: sort?.column === 'username' ? '#FFC60A' : '#fff',
                                }}
                            >
                                PLAYER
                            </TableCell>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('referralCount')}
                                sx={{
                                    width: '30%',
                                    textAlign: 'center',
                                    color: sort?.column === 'referralCount' ? '#FFC60A' : '#fff',
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        Total<br />Referrals
                                    </>
                                ) : (
                                    'Total Referrals'
                                )}
                            </TableCell>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('newSignUps')}
                                sx={{
                                    width: '30%',
                                    textAlign: 'center',
                                    color: sort?.column === 'newSignUps' ? '#FFC60A' : '#fff',
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        New<br />Sign-Ups
                                    </>
                                ) : (
                                    'New Sign-Ups'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedReferrals.map((referral, index) => (
                            <TableRow
                                key={referral.username}
                                sx={{
                                    backgroundColor:
                                        referral?.username === myUsername
                                            ? '#FFF6DA'
                                            : index % 2 === 0
                                            ? '#FFF'
                                            : '#F0F1F1',
                                }}
                            >
                                <TableCell className='referrals-leaderboard-player-column'>
                                    {referral.username}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {referral.referralCount}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {referral.newSignUps}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {Array.isArray(gameReferrals) && gameReferrals.length === 0 && (
                <Box sx={{ textAlign: 'center', padding: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '700', color: '#002129' }}>
                        No referrals yet. <br />
                        Use the link above to take the lead!
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default ReferralLeaderboard;