import React from "react";

const GatherPick5Info = () => {

    const UserDB = {
        "user_id" : "ca019a56-50ec-473c-b830-dfbeb45e72b4",
        "first_name" : "Adam",
        "last_name" : "Braithwaite",
        "user_name" : "OchoCinco85",
        "phone" : "14124184554",
        "email" : "demo5@gmail.com",
        "password" : "$2b$10$rlC9CpSHpCKTxDmX6x9DaOWhUHFg5L2w4m7ZF8J6tUQYBvacCgNiS",
        "date_of_birth" : {
            "$date" : 397972800000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            }
        ],
        "__v" : 0
    }

    function gatherPickEmInfo(gameId) {
        const username = UserDB?.user_name;
        const userFullName = `${UserDB?.first_name} ${UserDB?.last_name}`;
        const email = UserDB?.email;
        const userId = UserDB?.user_id;
        const games = UserDB?.Games || [];
        console.log("GAMES:", games);
        const pickEmGame = games.find(game => game.gameID === gameId);
        const picks = pickEmGame?.pool?.picks || [];
        const tiebreaker = pickEmGame?.pool?.tiebreaker || 0;
        console.log("PICKS:", picks);

        let totalWins = 0;
        let totalLosses = 0;
        let totalPushes = 0;
        let totalPoints = 0;
        let noPicks = 0;

        picks.forEach(pick => {
            if (pick.teamId === "No Pick") {
                noPicks++;
            }
            if (pick.result === "Win") {
                totalWins++;
                totalPoints += 1;
            } else if (pick.result === "Loss") {
                totalLosses++;
            } else if (pick.result === "Push") {
                totalPushes++;
                totalPoints += 0.5;
            }
        });

        return {
            userId,
            totalWins,
            totalLosses,
            totalPushes,
            totalPoints,
            username,
            userFullName,
            email,
            picks,
            tiebreaker,
            noPicks
        }
    }

    const Test = gatherPickEmInfo("39ade231-4d99-4y78-a3l3-83eq6kne8475");
    console.log("NFL Pick Em Info:", Test);

    return (
        <div>
            <h1>Test</h1>
        </div>
    )
}

export default GatherPick5Info;