import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const InfoModal = ({ open, onClose, modalInfo }) => {
    // Default values in case modalInfo or its properties are undefined
    const header = modalInfo?.header || 'Information';
    const textGroups = modalInfo?.text || [];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '500px' },
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    outline: 'none',
                }}
            >
                <Typography
                    sx={{
                        mb: 2,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#002129',
                    }}
                >
                    {header}
                </Typography>
                
                <Box id="info-modal-description" sx={{ mt: 2, mb: 3 }}>
                    {/* If text is an array of arrays (grouped format) */}
                    {Array.isArray(textGroups) && textGroups.length > 0 && Array.isArray(textGroups[0]) ? (
                        textGroups.map((group, groupIndex) => (
                            <Box 
                                key={groupIndex} 
                                sx={{ 
                                    mb: 2, // Space between groups
                                    textAlign: 'center'
                                }}
                            >
                                {group.map((line, lineIndex) => (
                                    <Typography 
                                        key={lineIndex} 
                                        sx={{ 
                                            fontSize: isMobile ? '12px' : '14px',
                                            color: '#002129',
                                            display: 'block',
                                            mb: lineIndex < group.length - 1 ? 0.5 : 0 // Small space between lines in the same group
                                        }}
                                    >
                                        {line}
                                    </Typography>
                                ))}
                            </Box>
                        ))
                    ) : (
                        // Legacy format - flat array of strings
                        textGroups.map((line, index) => (
                            <Typography 
                                key={index} 
                                sx={{ 
                                    mb: 1,
                                    fontSize: isMobile ? '12px' : '14px',
                                    color: '#002129',
                                    textAlign: 'center'
                                }}
                            >
                                {line}
                            </Typography>
                        ))
                    )}
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{
                            backgroundColor: '#002129',
                            fontSize: '14px',
                            height: '30px',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#003a4a',
                            },
                            minWidth: '80px',
                            fontWeight: 'bold',
                        }}
                    >
                        CLOSE
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InfoModal;