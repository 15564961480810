import React from 'react';
import { Box, Typography } from '@mui/material'; // Import MUI components
import './SponsorTab.scss'; // Import SCSS file

const SponsorMerchPage = ({ merch, storyBottomText, highlightsText, sponsorSecondaryLogo }) => {
    //console.log("Dishes, Story Bottom Text, Highlights Text: ", merch, storyBottomText, highlightsText);
    return (
        <>
            <Box className="sponsor-highlights-container">
                {merch?.map((merchItem, index) => (
                    <Box
                        key={index}
                        className={`sponsor-highlight-item ${merch.length % 2 === 1 && index === merch.length - 1 ? 'last-item' : ''}`}
                    >
                        <a href={merchItem.link} target="_blank" rel="noopener noreferrer" className="sponsor-highlight-link">
                            <Typography className="sponsor-tab-catering-text" sx={{marginBottom: '8px'}}>{merchItem.name}</Typography>
                        </a>

                        <img
                            src={merchItem.image}
                            alt={merchItem.name}
                            className="sponsor-highlight-image"
                        />
                    </Box>
                ))}
            </Box>

            <img 
                src={sponsorSecondaryLogo}
                alt="Sponsor Logo"
                style={{
                    width:'40%',
                    maxWidth: '300px',
                    margin: '0 auto',
                }}
            />

            <Box className="sponsor-tab-container">

                {highlightsText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-main-text">
                        {text}
                    </Typography>
                ))}

                {storyBottomText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-bottom-text">
                        {text}
                    </Typography>
                ))}
            </Box>
        </>
    );
}

export default SponsorMerchPage;
