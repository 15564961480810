import React, { useState } from 'react';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import { Typography, Button, Modal, Checkbox, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import '../NFLPickEmStyles/PickEmStyles.scss'
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSavePickemPicks } from '../../../hooks/pools/useSavePickemPicks';
import { useNFLOddsByWeek } from '../../../hooks/nfl/useNFLOddsByWeek';
import PickEmPicksWarningModal from '../../GameModals/PickEmModals/PickEmPicksWarningModal';
import PickEmSubmitPicksModal from '../../GameModals/PickEmModals/PickEmSubmitPicksModal';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';


const UserSubmitBar = ({ weeklySchedule, userPicks }) => {
    const { 
        fetchUserData,
        myUserId,
        mainGameId,
        NFLTeams,
        NFLStadiums, 
        NFLGameOddsByWeek, 
        NFLSchedules, 
        NFLScoresBySeason, 
        pickEmState, 
        setPickEmState,
        week, 
        userPicksFromDB,
        stopPicksWarning,
        pendingBoxColor,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
     } = useNFLPickEmContext();

    const { mutate, isLoading, isError, error, isSuccess } = useSavePickemPicks();
    const { mutate: fetchNFLGameOddsByWeek, data: NFLGameOddsByWeekData, isLoading: NFLGameOddsByWeekLoading, isError: NFLGameOddsByWeekError, error: NFLGameOddsByWeekErrorData } = useNFLOddsByWeek();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [modal, setModal] = useState(null);
    const [updatedPickEmState, setUpdatedPickEmState] = useState([]);
    const [startedGames, setStartedGames] = useState([]);
    const [lineChanges, setLineChanges] = useState([]);
    const [submissionErrorMessage, setSubmissionErrorMessage] = useState(null);
    const [updatedLines, setUpdatedLines] = useState([]);
    const [isFetched, setIsFetched] = useState(false);

    const handleCheckboxChange = (event) => {
        setDontShowAgain(event.target.checked);
    }

    function nflKey (teamId) {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
    }

    function nflOpponentInfo (gameId, teamId)  {
        const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? nflKey(game?.GlobalAwayTeamID) : nflKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        const displayString = `${leadingSymbol} ${opponentKey}`;
        return displayString;
    }

    function weeksPicksInfo() {
        if (!userPicks) {
            return [];
        }
        return userPicks?.map(pick => {
            const { spread, teamId, gameId, isHomeSelected, result } = pick;
            const teamLogo = NFLTeams?.find(team => team.TeamID === teamId)?.WikipediaLogoURL;
            const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
            const isGameOver = game?.IsClosed;
            let borderBackgroundColor = "#FFFFFF";
            let borderColor = pendingBoxColor;
            if (result === "Win") {
                borderColor = winBoxColor;
                borderBackgroundColor = winBoxBackgroundColor;
            } else if (result === "Push") {
                borderColor = pushBoxColor;
                borderBackgroundColor = pushBoxBackgroundColor;
            } else if (result === "Loss") {
                borderColor = lossBoxColor;
                borderBackgroundColor = lossBoxBackgroundColor;
            }
            // if (isGameOver) {
            //     const isPickHome = game?.GlobalHomeTeamID === teamId;
            //     result = isPickHome ? (game?.HomeScore + spread) > game?.AwayScore ? "Win" : (game?.HomeScore + spread) === game?.AwayScore ? "Push" : "Loss" : (game?.AwayScore + spread) > game?.HomeScore ? "Win" : (game?.AwayScore + spread) === game?.HomeScore ? "Push" : "Loss";
            //     borderColor = result === "Win" ? winBoxColor : result === "Push" ? pushBoxColor : lossBoxColor;
            //     borderBackgroundColor = result === "Win" ? winBoxBackgroundColor : result === "Push" ? pushBoxBackgroundColor : lossBoxBackgroundColor;
            // }
            return { spread, teamLogo, borderColor, borderBackgroundColor, result};
        });
    }

    function weekPendingPicksInfo() {
        return pickEmState?.map(pick => {
            const { spread, teamId, gameId } = pick;
            const teamLogo = NFLTeams?.find(team => team.TeamID === teamId)?.WikipediaLogoURL;
            return { spread, teamLogo, gameId };
        });
    }

    const handleFirstSubmit = async () => {
        if (!stopPicksWarning) {
            setModalOpen(true);
            setModal("Warning");
        } else {
            handleSubmit();
        }
    };

    // const fetchData = async () => {
    //     try {
    //         const data = await fetchNFLGameOddsByWeek({}, {
    //             onSuccess: (data) => {
    //                 setIsFetched(true);
    //                 //console.log("Fetch Data is Home");
    //                 //console.log("NFL Game Odds By Week Check (DATA): is Home", data);
    //                 picksCheck(data);
    //             },
    //             onError: (error) => {
    //                 console.error("Error fetching data:", error);
    //                 throw error; // Throw the error to propagate it
    //             }
    //         });
    
    //         return data; // Return data if needed
    //     } catch (error) {
    //         console.error("Error in fetchData:", error);
    //         throw error; // Propagate the error if needed
    //     }
    // };
    

    

    const handleWarningSubmit = async () => {
        if (dontShowAgain) { 
            const stopPicksWarning = true;
            //console.log("Stop Picks Warning:", stopPicksWarning);
        }
        handleSubmit();
    };

    // const picksCheck = (data) => {
    //     //console.log("Picks Check:", data);
    //     handleSubmit(data);
    // }

    const handleSubmit = async () => {
        let picksData = {
            picks: pickEmState,
            userID: myUserId,
            gameType: 'Pickem',
            gameId: mainGameId,
        };
    
        if (dontShowAgain) {
            picksData.stopPicksWarning = true;
        }
    
        if (pickEmState?.length > 0) {
            try {
                await mutate(picksData, {
                    onSuccess: async (response) => {
                        try {
                            // Log the response to understand its structure
                            //console.log('Response from server:', response);
                    
                            const { savedPicks = [], invalidGames = {} } = response || {};  // Provide defaults for undefined data
                            
                                // Handle case where all picks were saved successfully without a 'message'
                                setModal("Submit");
                                setSubmissionErrorMessage("");  // Clear any errors
                                setUpdatedPickEmState(savedPicks);  // Ensure state update
                                setStartedGames(invalidGames?.started);  // Reset if no invalid games
                                setLineChanges(invalidGames?.changed);   // Reset if no invalid games
                            
                    
                            setModalOpen(true);  // Open the modal
                        } catch (error) {
                            console.error("An error occurred while processing the success response:", error);
                            setModal("submissionError");
                            setSubmissionErrorMessage(error.response?.data?.error || "An unknown error occurred");
                        }
                    },
                    onError: (error) => {
                        // Check if the error status is 401
                        if (error.response && error.response.status === 401) {
                            dispatch(userLogoutAction({
                                logoutType: 'token-expired'
                            }));
                        } else {
                            console.error("An error occurred while submitting the scorecard:", error);
                            setModal("submissionError");
                            setSubmissionErrorMessage(error.response.data.error || "An unknown error occurred");
                        }
                    },
                });
            } catch (error) {
                console.error("An error occurred while submitting the scorecard:", error);
                setModal("submissionError");
                setSubmissionErrorMessage(error.response?.data?.error || "An unknown error occurred");
            }
        } else {
            setModalOpen(true);
            setModal("Submit");
            setUpdatedPickEmState(updatedPickEmState);
            setStartedGames(startedGames);
            setLineChanges(lineChanges);
        }
    };
    
    
    const handleRemoveGameClick = (gameId) => {
        //console.log("Remove Game Clicked:", gameId);
        const newPickEmState = pickEmState.filter(game => game.gameId !== gameId);
        setPickEmState(newPickEmState);
    }

    const weekPicks = weeksPicksInfo();
    const weekPendingPicks = weekPendingPicksInfo();
    const maxPicks = 5;
    const currentPickCount = weekPicks?.length + weekPendingPicks?.length;
    const noPickPicks = userPicksFromDB?.filter(pick => pick.teamId === "No Pick" && pick.week === week);
    //console.log("No Pick Picks:", noPickPicks);
    const missesNeeded = noPickPicks?.length;
    
    const placeholdersNeeded = maxPicks - currentPickCount - missesNeeded;

    return (
        <div className="user-submit-bar">
        <>
        <div className="weekly-picks-bar" style={{ maxWidth: '1116px', margin: '0 auto'}}>
            {/* <Typography variant="h1">User Submit Bar</Typography> */}
            <div style={{ display: 'flex', maxWidth: '830px', margin: '0 auto', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', padding: isMobile ? '16px 32px' : '16px 160px' }}>
                {weekPicks?.map((pick, index) => (
                    <div key={index} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{ height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {pick.result !== "Pending" ? (
                                <Typography 
                                    variant="body1" 
                                    style={{ 
                                        fontSize: isMobile ? '12px' : '16px', 
                                        fontWeight: '700' 
                                    }}
                                >
                                    {pick.result}
                                </Typography>
                            ) : (
                                <LockIcon 
                                    style={{ 
                                        height: isMobile ? '10px' : '20px', 
                                    }} 
                                />
                            )}
                        </div>
                        <img
                            src={pick.teamLogo}
                            alt="Team Logo"
                            style={{
                                width: isMobile ? '32px' : '40px',
                                height: isMobile ? '32px' : '40px',
                                margin: '0 auto',
                                marginTop: isMobile ? '5px' : '16px',
                                marginBottom: isMobile ? '5px' : '16px',
                                objectFit: 'contain',
                                border: isMobile ? `1px solid ${pick.borderColor}` : `2px solid ${pick.borderColor}`, 
                                backgroundColor: pick.borderBackgroundColor,
                            }}
                        />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '16px', 
                                    fontWeight: '700', 
                                    display: 'flex', 
                                    alignItems: 'center' 
                                }}
                            >
                                {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                            </Typography>
                        </div>
                    </div>
                ))}

                {weekPendingPicks?.map((pick, index) => (
                    <div key={index} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRemoveGameClick(pick.gameId)}>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width={isMobile ? '10' : '16'}
                                height={isMobile ? '10' : '16'}
                                viewBox="0 0 10 10" 
                                fill="none">
                                <path d="M3.33247 0V0.555556H0.554688V1.66667H1.11024V8.88889C1.11024 9.18357 1.22731 9.46619 1.43568 9.67456C1.64405 9.88294 1.92667 10 2.22135 10H7.77691C8.0716 10 8.35421 9.88294 8.56258 9.67456C8.77096 9.46619 8.88802 9.18357 8.88802 8.88889V1.66667H9.44358V0.555556H6.6658V0H3.33247ZM2.22135 1.66667H7.77691V8.88889H2.22135V1.66667ZM3.33247 2.77778V7.77778H4.44358V2.77778H3.33247ZM5.55469 2.77778V7.77778H6.6658V2.77778H5.55469Z" fill="#CC293C"/>
                            </svg>
                        </div>
                        <img 
                            src={pick.teamLogo} 
                            alt="Team Logo" 
                            onClick={() => handleRemoveGameClick(pick.gameId)}
                            style={{ 
                                width: isMobile ? '32px' : '40px', 
                                height: isMobile ? '32px' : '40px',  
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '10px', 
                                marginBottom: isMobile ? '5px' : '16px',  
                                objectFit: 'contain', 
                                border: isMobile ? '1px solid' : '2px solid', 
                                borderColor: '#00AAD1' 
                        }} />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '16px', 
                                    fontWeight: '700' 
                                }}
                            >
                                {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500'
                            }}
                        >
                           &nbsp;
                        </Typography>
                        <div
                            style={{ 
                                width: isMobile ? '32px' : '40px', 
                                height: isMobile ? '32px' : '40px', 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px', 
                                marginBottom: isMobile ? '5px' : '20px', 
                                objectFit: 'contain', 
                                border: isMobile ? `1px solid #000000` : `2px solid #000000`, 
                                backgroundColor: '#FFFFFF' 
                            }} 
                        />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: '700' 
                                }}
                            >
                                Pick {currentPickCount + index + 1}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: missesNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500', 
                                color: '#CC293C', 
                                textDecoration: 'underline' 
                            }}
                        >
                           &nbsp;
                        </Typography>
                        <div
                            style={{ 
                                width: isMobile ? '32px' : '40px', 
                                height: isMobile ? '32px' : '40px', 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px', 
                                marginBottom: isMobile ? '5px' : '20px', 
                                border: isMobile ? `1px solid #CC293C` : `2px solid #CC293C`, 
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} 
                        >
                            <CloseIcon 
                                style={{ 
                                    width: isMobile ? '20px' : '32px', 
                                    height: isMobile ? '20px' : '32px', 
                                    fill: '#CC293C',
                                }} 
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            width: '30px',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '16px', 
                                    fontWeight: '700', 
                                    color:'#CC293C',
                                    whiteSpace: 'nowrap' 
                                }}
                            >
                            NO PICK
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            mt={2} 
            mb={2}
        >
            <Button
                variant="contained"
                disabled={pickEmState.length === 0}
                onClick={() => {
                    setModalOpen(false);
                    setPickEmState([]);
                }}
            >
                Cancel
            </Button>
            <Box width={15} /> 
            <Button 
                variant="contained"
                disabled={pickEmState.length === 0} 
                color="primary" 
                onClick={handleFirstSubmit}
            >
                Submit Picks
            </Button>
        </Box>
        </>

        {modalOpen && modal === "Warning" && (
            <PickEmPicksWarningModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                dontShowAgain={dontShowAgain}
                handleCheckboxChange={handleCheckboxChange}
                handleWarningSubmit={handleWarningSubmit}
            />
        )}

        {modalOpen && modal === "Submit" && (
            <PickEmSubmitPicksModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                updatedPickEmState={updatedPickEmState}
                userPicks={userPicks}
                startedGames={startedGames}
                lineChanges={lineChanges}
                week={week}
                NFLTeams={NFLTeams}
                NFLScoresBySeason={NFLScoresBySeason}
            />
        )}
        {modalOpen && modal === "submissionError" && (
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: '4px',
                        p: 4,
                    }}
                >
                    <Typography 
                        sx={{
                            fontSize: '24px',
                            fontWeight: '800',
                            textAlign: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        Error Submitting Picks
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#CC293C',
                            fontWeight: '700',
                            marginBottom: '20px',
                        }}
                    >
                    {typeof submissionErrorMessage === 'string' ? submissionErrorMessage : submissionErrorMessage.message || "An error occurred"}                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => window.location.href=window.location.href}
                        >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )}
        
     
        </div>
    );
}

export default UserSubmitBar;
