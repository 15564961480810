import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";
// import { BracketBox } from './bracketBox';

import { mulligenMadnes } from '../../../redux/actions/gameImport';
import { getUserDataFromLocalStorage, getUsersByMulligenMadnessAction } from '../../../redux/actions/loginImport';
import { fetchCBBTeams, fetchCBBLeagueHierarchy, fetchNCAATournamentHierarchy } from '../../../redux/actions/dataImport';
import Button from '@mui/material/Button';
import '@mui/material/styles';
import '../bracketStyles/marchMadnessBracket.scss';
import { Input, Typography } from '@mui/material';
import VectorCrown from '../../../assets/images/VectorCrown.svg';
import './BracketBox.scss';

import { getUsersForLeaderboardAction } from "../../../redux/actions/loginImport";


const MarchMadnessBracketLive = (props) => {
    const { sportsData, cbbTeamsData, ncaaTournamentHierarchyData, ncaaTournamentHierarchyReducer, getUsersByMulligenMadnessReducer, allUsers } = props;
    const CBBTeams = cbbTeamsData?.data;
    const newAllUsers = getUsersByMulligenMadnessReducer;
    const MulligenMadnessLeaderboard = allUsers;
    const ncaaTournamentHierarchy = ncaaTournamentHierarchyData?.data;
    const dispatch = useDispatch();
    const userData = getUserDataFromLocalStorage();
    let picks = userData?.MulligenMadness[0]?.picks || [];
    console.log(ncaaTournamentHierarchyData, ncaaTournamentHierarchyReducer, "GET USERS BY MULLIGEN MADNESS REDUCER");
    const [pickState, setPickState] = useState(picks);
    const [displayState, setDisplayState] = useState(userData?.MulligenMadness[0]?.display || {});
    const [tiebreaker, setTiebreaker] = useState(userData?.MulligenMadness[0]?.tieBreaker || {});//{LeftPoints: 0, RightPoints: 0}


    const [finalFourMatchups, setFinalFourMatchups] = useState({
      finalFourTeams: {}, // To hold the winning team from each bracket for the Final Four
      championshipTeams: [] // To hold the two teams competing in the Championship
    });

    console.log('MarchMadnessBracketLive', newAllUsers, MulligenMadnessLeaderboard);

    useEffect(() => {

            dispatch(getUsersByMulligenMadnessAction());
            dispatch(getUsersForLeaderboardAction());
            dispatch(fetchCBBTeams());
            dispatch(fetchCBBLeagueHierarchy());
            dispatch(fetchNCAATournamentHierarchy());

      // Log pickState when it changes to see the updates
      console.log('Updated PICKSTATE REMAINING', pickState);
      console.log('Updated DISPLAYSTATE', displayState);


    }, [pickState, displayState, tiebreaker]); // This useEffect will run every time pickState changes

/**
 * Gets the bracket name based on the team ID and NCAA tournament hierarchy.
 * @param {string} ID - The team ID.
 * @param {Object} ncaaTournamentHierarchy - The NCAA tournament hierarchy.
 * @returns {string|null} - The bracket name if found, or null if not found.
 */
const getBracketName = (ID, ncaaTournamentHierarchy) => {
    // Find the game where the teamID matches in GlobalHomeTeamID or GlobalAwayTeamID and Round === 1
    const game = ncaaTournamentHierarchy?.Games?.find(
      (entry) =>
        (entry.GlobalHomeTeamID === ID || entry.GlobalAwayTeamID === ID) &&
        entry.Round === 1
    );

    // If game not found or Bracket not specified, return null
    if (!game || !game.Bracket) {
      return null;
    }

    // Determine the bracket name based on the found game's Bracket
    if (
      game.Bracket === ncaaTournamentHierarchy.LeftTopBracketConference ||
      game.Bracket === ncaaTournamentHierarchy.LeftBottomBracketConference
    ) {
      return "Left";
    } else {
      return "Right";
    }
  };







  function getLogo(teamID, CBBTeams) {
  const team = CBBTeams?.find(team => team.GlobalTeamID === teamID);
  return team ? team.TeamLogoUrl : null;
  }

  // const teamTest = getLogo(60000003, CBBTeams);
  // console.log("TEAMTEST: ", teamTest);




  /**
   * Finds and returns information about a game in the NCAA tournament hierarchy.
   * @param {number} round - The round of the game.
   * @param {string} bracket - The bracket of the game.
   * @param {number} tournamentDisplayOrder - The display order of the game within its bracket.
   * @returns {Object|null} - Information about the game if found, or null if not found.
   */
  function ncaaTournamentDisplay(round, bracket, tournamentDisplayOrder) {
    // console.log(ncaaTournamentHierarchy, round, bracket, tournamentDisplayOrder);
    // Find the game that matches the provided round, bracket, and tournamentDisplayOrder
    const game = ncaaTournamentHierarchy?.Games?.find(game =>
      game.Round === round &&
      game.Bracket === bracket &&
      game.TournamentDisplayOrder === tournamentDisplayOrder
    );

    if (!game) {
      console.error('Game not found');
      return null;
    }

    // Determine the top team ID based on the value of TournamentDisplayOrderForHomeTeam
    const topTeamID = game.TournamentDisplayOrderForHomeTeam === "Top" ?
                      game.GlobalHomeTeamID :
                      game.GlobalAwayTeamID;

    const bottomTeamID = game.TournamentDisplayOrderForHomeTeam === "Top" ?
                      game.GlobalAwayTeamID :
                      game.GlobalHomeTeamID;

    const topTeamScore = game.TournamentDisplayOrderForHomeTeam === "Top" ?
                      game.HomeTeamScore :
                      game.AwayTeamScore;

    const bottomTeamScore = game.TournamentDisplayOrderForHomeTeam === "Top" ?
                      game.AwayTeamScore :
                      game.HomeTeamScore;


    // Return the desired properties of the found game
    return {
      Round: game.Round,
      Bracket: game.Bracket,
      TournamentDisplayOrder: game.TournamentDisplayOrder,
      TopTeamID: topTeamID,
      BottomTeamID: bottomTeamID,
      TopTeamScore: topTeamScore,
      BottomTeamScore: bottomTeamScore,
      Day: game.Day,
      Date: game.DateTime,
      Status: game.Status,
      IsClosed: game.IsClosed
    };
  }

  // const ncaaTest = ncaaTournamentDisplay(5, "East-West", 1);
  // console.log(ncaaTest, "NCAA TESTING!!!!")








  // const username = "Awaite03";
  // const TestPicks = findUserPicksAndTieBreaker(username, newAllUsers)
  // console.log(username, "'s TESTPICKS:", TestPicks)






  function bracketDisplayName( teamID, maxCharacters) {
    // Find the team with the matching GlobalTeamID
    const team = CBBTeams?.find(team => team.GlobalTeamID === teamID);

    if (team) {
    // Extract and check the length of the School name
    const school = team.School;
    const shortenedName = team.ShortDisplayName;
    if (school.length <= maxCharacters) {
        return school;
    } else {
        let modifiedSchool = school;
        if (school.includes('State')) {
            modifiedSchool = school.replace('State', 'St.');
        }
        if (modifiedSchool.length <= maxCharacters) {
            return modifiedSchool;
        } else {
            return shortenedName;
        }
    }
    } else {
    return null;
    }
    }





    function teamSeed(ncaaTournamentHierarchy, teamID) {
    // Find games where Round === 1
    const games = Object.values(ncaaTournamentHierarchy.Games).filter(game => game.Round === 1);

    for (const game of games) {
    // Check if the teamID matches the GlobalHomeTeamID or GlobalAwayTeamID
    if (game.GlobalHomeTeamID === teamID) {
        return game.HomeTeamSeed || null; // Return the HomeTeamSeed if the teamID matches the GlobalHomeTeamID
    } else if (game.GlobalAwayTeamID === teamID) {
        return game.AwayTeamSeed || null; // Return the AwayTeamSeed if the teamID matches the GlobalAwayTeamID
    }
    }
    return null; // Return null if the teamID is not found in any game with Round === 1
    }





    function getTeamLogo(CBBTeams, teamID) {
    // Find the team with matching GlobalTeamID
    const team = CBBTeams.find(team => team.GlobalTeamID === teamID);

    // Return the TeamLogoUrl if team is found, otherwise return null
    return team ? team.TeamLogoUrl : null;
    }






  function userPicksDisplay(userData, round, bracket, tournamentDisplayOrder) {
    //console.log(userData, round, bracket, tournamentDisplayOrder);
    let topUserChoiceID, bottomUserChoiceID;

    if (round === 4) {
      const leftTopBracket = ncaaTournamentHierarchy?.LeftTopBracketConference;
      const leftBottomBracket = ncaaTournamentHierarchy?.LeftBottomBracketConference;
      const rightTopBracket = ncaaTournamentHierarchy?.RightTopBracketConference;
      const rightBottomBracket = ncaaTournamentHierarchy?.RightBottomBracketConference;

      topUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        pick.Bracket === bracket &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder
      )?.UserChoice;

      bottomUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        pick.Bracket === bracket &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder * 2
      )?.UserChoice;
    } else if (round === 5) {
      const [firstBracket, secondBracket] = bracket.split('-');
      const topLeftBracket = ncaaTournamentHierarchy?.LeftTopBracketConference;
      const topRightBracket = ncaaTournamentHierarchy?.RightTopBracketConference;
      const bottomLeftBracket = ncaaTournamentHierarchy?.LeftBottomBracketConference;
      const bottomRightBracket = ncaaTournamentHierarchy?.RightBottomBracketConference;

      topUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        (pick.Bracket.includes(topLeftBracket) || pick.Bracket.includes(topRightBracket)) &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder
      )?.UserChoice;

      bottomUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        (pick.Bracket.includes(bottomLeftBracket) || pick.Bracket.includes(bottomRightBracket)) &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder
      )?.UserChoice;
    } else if (round === 6) {
      const topLeftBracket = ncaaTournamentHierarchy?.LeftTopBracketConference;
      const topRightBracket = ncaaTournamentHierarchy?.RightTopBracketConference;

      topUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        pick.Bracket.includes(topLeftBracket) &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder
      )?.UserChoice;

      bottomUserChoiceID = userData?.picks.find(pick =>
        pick.Round === round - 1 &&
        pick.Bracket.includes(topRightBracket) &&
        pick.TournamentDisplayOrder === tournamentDisplayOrder
      )?.UserChoice;
    }

    return { topUserChoiceID, bottomUserChoiceID };
  }






  const Brackets = ({game, id}) => {

    const whosLoggedIn = userData?.user_name;
    console.log(whosLoggedIn, newAllUsers, "is the logged in User!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedUser, setSelectedUser] = useState(whosLoggedIn);
    const [selectedUserPicks, setSelectedUserPicks] = useState();
    const [allUsernames, setAllUsernames] = useState([]);
    const [leftTiebreakerPoints, setLeftTiebreakerPoints] = useState();
    const [rightTiebreakerPoints, setRightTieBreakerPoints] = useState();

    console.log(selectedUser, whosLoggedIn, "LOOOOOK HERE")
    console.log("SELECTED USER PICKS TO DISPLAY:", selectedUserPicks)
    console.log("SELECTED USERPICKS ONCE STATE IS SET: ", selectedUserPicks);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = [];
                newAllUsers?.forEach(entry => {
                    // Check if the entry has the "user_name" property
                    if (entry.user_name) {
                      // Add the username to the array
                      result.push(entry.user_name);
                    }
                });
                const userPicksToDisplay = findUserPicksAndTieBreaker(selectedUser, newAllUsers)

                const leftTiebreakerPointsNew = userPicksToDisplay.tieBreaker.leftPoints;
                const rightTiebreakerPointsNew = userPicksToDisplay.tieBreaker.rightPoints;

                setLeftTiebreakerPoints(leftTiebreakerPointsNew);
                setRightTieBreakerPoints(rightTiebreakerPointsNew);
                setSelectedUserPicks(userPicksToDisplay);
                setData(result);
                setAllUsernames(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []); // Add any relevant dependencies here


    /**
   * Finds the user picks and tie breaker based on the provided username from the database of all users picks.
   * @param {string} username - The username to search for.
   * @param {Array} data - The array containing user data.
   * @returns {Object} - An object containing the user's picks and tie breaker, or null if the user is not found.
   */
  function findUserPicksAndTieBreaker(username, data) {
    // Find the user with the provided username
    const user = data.find(entry => entry.user_name === username);

    if (!user) {
      console.error('User not found');
      return null;
    }

    // Extract MulligenMadness picks and tieBreaker for the found user
    const { MulligenMadness } = user;
    const { picks, tieBreaker } = MulligenMadness && MulligenMadness.length > 0 ? MulligenMadness[0] : {};

    return { picks, tieBreaker };
  }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!data) {
        return <div>No data available</div>;
    }

    console.log("ALL Usernames:", allUsernames);

    // Define state for selected user





    // Event handler for dropdown change
    const handleUserChange = (e) => {
      const newUser = e.target.value;
      setSelectedUser(newUser);
      const newResultsState = findUserPicksAndTieBreaker(newUser, newAllUsers);
      setSelectedUserPicks(newResultsState);
    };

    const finalGame = ncaaTournamentHierarchy?.Games?.find(game => game.Round === 6);
    console.log("FINAL GAME INFO:", finalGame);
    const userChoices = selectedUserPicks?.picks?.find(pick => pick.Round === 6);
    const isFinalOver = finalGame?.IsClosed === true;
    console.log("FINAL GAME OVER:", isFinalOver);
    const finalWinner = isFinalOver ? (finalGame.AwayTeamScore > finalGame.HomeTeamScore ? finalGame.GlobalAwayTeamID : finalGame.GlobalHomeTeamID) : null;
    console.log("FINAL WINNER: ", finalWinner);
    const finalWinnerName = bracketDisplayName(CBBTeams, finalWinner);
    const finalChampTeamChoice = userChoices?.UserChoice
    const champResult = !isFinalOver ? 'Pending' : finalWinner === finalChampTeamChoice ? 'Win' : 'Loss';
    console.log('NEW CHAMP RESULT', champResult);  //******************REMOVE AND FIX THIS LATER****************** */
    const champID = isFinalOver ? finalWinner : null;
     //******************REMOVE AND FIX THIS LATER****************** */
    const userChampLogo = getLogo(finalChampTeamChoice, CBBTeams);
    const userChampName = bracketDisplayName(finalChampTeamChoice, ncaaTournamentHierarchy);

    let champLogo = null;
    if (!isFinalOver) {
      if (champResult !== "Loss") {
          champLogo = getLogo(finalChampTeamChoice, CBBTeams);
      } else {
          champLogo = <LossPlaceholder />;;
      }
        } else {
      champLogo = getLogo(finalWinner, CBBTeams);
       }




    const champColor = champResult !== "Pending" ? (champResult === "Win" ? "#00AA72" : "#CC293C") : null;
    console.log("CHAMP COLOR:", champColor);
    const textColor = champResult !== "Pending" ? "#FFF": null;
    const bottomTextColor = champResult === "Loss" ? "#CC293C" : null;
    const bottomStrikeout = champResult === "Loss" ? "line-through" : null;

    const topLeftName = ncaaTournamentHierarchy?.LeftTopBracketConference;
    const bottomLeftName = ncaaTournamentHierarchy?.LeftBottomBracketConference;
    const topRightName = ncaaTournamentHierarchy?.RightTopBracketConference;
    const bottomRightName = ncaaTournamentHierarchy?.RightBottomBracketConference;








    const LossPlaceholder = () => (
      <div
        style={{
          width: "75%",
          height: "75%",
          backgroundColor: "#00000040", // Gray background color
          border: "2px solid #00000040", // Gray border
          borderRadius: "8px", // Border radius
          boxSizing: "border-box", // Include border in width and height
          padding: "25px", // Add padding to create space between border and content
        }}
      ></div>
    );






    function formatDate(dateString, round) {
        if (dateString === null) {
          if (round === 5) {
            return "Sat 4/6";
          } else if (round === 6) {
            return "Mon 4/8";
          } else {
            return "TBD";
          }
        }
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date = new Date(dateString);

        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = monthsOfYear[date.getMonth()];
        const dayOfMonth = date.getDate();

        return `${dayOfWeek} ${date.getMonth() + 1}/${dayOfMonth}`;
      }

      function formatTime(timeString, dateString) {
        if (!timeString && !dateString) {
          return null;
        } else if (!timeString) {
          return "TBD";
        }

        const time = new Date(timeString);

        if (isNaN(time.getTime())) {
          return null; // Invalid time string
        }

        const hours = time.getHours();

        // Check if the time is before noon (i.e., before 12:00 PM)
        if (hours < 12) {
          return "TBD";
        }

        const minutes = time.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;

        return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
      }



      function bracketDisplayName(teamID, maxCharacters) {
        // Find the team with the matching GlobalTeamID
        const team = CBBTeams?.find(team => team.GlobalTeamID === teamID);

        if (team) {
        // Extract and check the length of the School name
        const school = team.School;
        const shortenedName = team.ShortDisplayName;
        if (school.length <= maxCharacters) {
            return school;
        } else {
            let modifiedSchool = school;
            if (school.includes('State')) {
                modifiedSchool = school.replace('State', 'St.');
            }
            if (modifiedSchool.length <= maxCharacters) {
                return modifiedSchool;
            } else {
                return shortenedName;
            }
        }
        } else {
        return null;
        }
        }
        function teamSeed(ncaaTournamentHierarchy, teamID) {
        // Find games where Round === 1
        const games = Object.values(ncaaTournamentHierarchy.Games).filter(game => game.Round === 1);

        for (const game of games) {
        // Check if the teamID matches the GlobalHomeTeamID or GlobalAwayTeamID
        if (game.GlobalHomeTeamID === teamID) {
            return game.HomeTeamSeed || null; // Return the HomeTeamSeed if the teamID matches the GlobalHomeTeamID
        } else if (game.GlobalAwayTeamID === teamID) {
            return game.AwayTeamSeed || null; // Return the AwayTeamSeed if the teamID matches the GlobalAwayTeamID
        }
        }
        return null; // Return null if the teamID is not found in any game with Round === 1
        }


        function getTeamLogo(CBBTeams, teamID) {
        // Find the team with matching GlobalTeamID
        const team = CBBTeams.find(team => team.GlobalTeamID === teamID);

        // Return the TeamLogoUrl if team is found, otherwise return null
        return team ? team.TeamLogoUrl : null;
        }





      const BracketBox = ({game, pick, pickState, setPickState}) => {
           //const [topTeamSelected, setTopTeamSelected] = useState(false);
           //const [bottomTeamSelected, setBottomTeamSelected] = useState(false);
          const [previousLosers, setPreviousLosers] = useState([]);

          if (!game) {
              return null;
            }




          // Dummy logo URL
          const round = game.Round;
          const bracket = game.Bracket;
          const tournamentDisplayOrder = game.TournamentDisplayOrder;
          const topUserChoiceID = pick.topUserChoiceID || null;
          const topUserChoiceLogo = topUserChoiceID ? getTeamLogo(CBBTeams, topUserChoiceID) : null;
          const topUserChoiceSeed = topUserChoiceID ? teamSeed(ncaaTournamentHierarchy, topUserChoiceID) : null;
          const topUserChoiceName = topUserChoiceID ? bracketDisplayName( topUserChoiceID) : null;
          const bottomUserChoiceID = pick.bottomUserChoiceID || null;
          const bottomUserChoiceLogo = bottomUserChoiceID ? getTeamLogo(CBBTeams, bottomUserChoiceID) : null;
          const bottomUserChoiceSeed = bottomUserChoiceID ? teamSeed(ncaaTournamentHierarchy, bottomUserChoiceID) : null;
          const bottomUserChoiceName = bottomUserChoiceID ? bracketDisplayName( bottomUserChoiceID) : null;
          const topTeamID = game.TopTeamID;
          const topTeamLogo = getTeamLogo(CBBTeams, topTeamID);
          const topTeamSeed = teamSeed(ncaaTournamentHierarchy, topTeamID);
          const topTeamName = bracketDisplayName( topTeamID);
          const bottomTeamID = game.BottomTeamID;
          const bottomTeamLogo = getTeamLogo(CBBTeams, bottomTeamID);
          const bottomTeamSeed = teamSeed(ncaaTournamentHierarchy, bottomTeamID);
          const bottomTeamName = bracketDisplayName( bottomTeamID);
          const topTeamScore = game.TopTeamScore;
          const bottomTeamScore = game.BottomTeamScore;
          const winningTeam = game.WinningTeam;
          const date = game.Day;
          const time = game.DateTime;
          const status = game.Status;
          const isClosed = game.IsClosed;
          const formattedDate = formatDate(date, round);
          const formattedTime = formatTime(time, date);
          //console.log("FORMATTED DATE: ", formattedDate, formattedTime)
          const topBoxText = status ==="Scheduled" ? formattedDate : topTeamScore;
          const bottomBoxText = status === "Scheduled" ? formattedTime: bottomTeamScore;


          let topUserResult = "Pending";
          if (topTeamID && topTeamID === topUserChoiceID){
            topUserResult = "Win"
          }
          if (topTeamID && topTeamID !== topUserChoiceID){
            topUserResult = "Loss"
          }

          let bottomUserResult = "Pending";
          if (bottomTeamID && bottomTeamID === bottomUserChoiceID) {
            bottomUserResult = "Win"
          }
          if (bottomTeamID && bottomTeamID !== bottomUserChoiceID) {
            bottomUserResult = "Loss"
          }


          const winColor = "#00AA72";






          return (
            <div className="bracket-game-container">
              {/* Top User Choice */}
              <div className="bracket-team-container">
        {/* Top User Choice */}
        {topUserChoiceLogo !== null && topUserResult !== "Win" && round !== 3 ? (
          <>
            <img className="team-logo-img" src={topUserChoiceLogo} alt="Team Logo" width="16" height="16" />
            <span className="team-seed" style={{ textDecoration: topUserResult === "Loss" ? "line-through" : null, color: topUserResult === "Loss" ? "red" : null }}>{topUserChoiceSeed}</span>
            <span className="team-name" style={{ textDecoration: topUserResult === "Loss" ? "line-through" : null, color: topUserResult === "Loss" ? "red" : null }}>{topUserChoiceName}</span>
          </>
        ) : (
          // Render an empty span to maintain the height of the container
          <span style={{ visibility: 'hidden' }}>Placeholder</span>
        )}
      </div>


              <div className="bracketBox">
                <div className="team-section">
                  {/* Top Actual Team */}
        <div className="bracket-team-container">
          {topTeamLogo && (
            <>
            <img className="team-logo-img" src={topTeamLogo} alt="Team Logo" width="16" height="16" />
            <span className="team-seed" style={{ fontWeight: topTeamName === winningTeam ? "bold" : null, color: topUserResult === "Win" ? winColor : null }}>{topTeamSeed}</span>
            <span className="team-name" style={{ fontWeight: topTeamName === winningTeam ? "bold" : null, color: topUserResult === "Win" ? winColor : null }}>{topTeamName}</span>
            </>

          )}
          {!topTeamLogo && (
            <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9"/>
          </svg>

          )}
           <div className="team-score-container">
          {/* Use a span to display topTeamScore */}
          <span className="team-score"style={{ fontWeight: isClosed && topTeamName === winningTeam ? "bold" : null }}>{topBoxText}</span>
            </div>
        </div>

      </div>
                <div className="team-section">
                {/* Bottom Actual Team */}
        <div className="bracket-team-container">
          {bottomTeamLogo && (
            <>
            <img className="team-logo-img" src={bottomTeamLogo} alt="Team Logo" width="16" height="16" />
            <span className="team-seed" style={{ fontWeight: bottomTeamName === winningTeam ? "bold" : null, color: bottomUserResult === "Win" ? winColor : null }}>{bottomTeamSeed}</span>
            <span className="team-name" style={{ fontWeight: bottomTeamName === winningTeam ? "bold" : null, color: bottomUserResult === "Win" ? winColor : null }}>{bottomTeamName}</span>
          </>

          )}
          {!bottomTeamLogo && (
            <svg className="no-team-name" xmlns="http://www.w3.org/2000/svg" width="120" height="12" viewBox="0 0 120 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M120 6C120 9.31371 117.612 12 114.667 12V12.0002H5.33333V12.0002C2.38781 12.0002 0 9.31394 0 6.00023C0 2.68652 2.38781 0.000228882 5.33333 0.000228882C5.33739 0.000228882 5.34144 0.00023397 5.34549 0.000244141H114.618C114.634 8.14949e-05 114.65 0 114.667 0C117.612 0 120 2.68629 120 6Z" fill="#D9D9D9"/>
          </svg>

          )}
            <div className="team-score-container">
                {/* Use a span to display topTeamScore */}
                <span className="team-score" style={{ fontWeight: isClosed && bottomTeamName === winningTeam ? "bold" : null }}>{bottomBoxText}</span>
            </div>
        </div>

                </div>
              </div>
              {/* Bottom User Choice */}
      <div className="bracket-team-container">
          {bottomUserResult !== "Win" && bottomUserChoiceLogo !== null && round !== 3 ? (
              <>
                  <img className="team-logo-img" src={bottomUserChoiceLogo} alt="Team Logo" width="16" height="16" />
                  <span className="team-seed" style={{ textDecoration: bottomUserResult === "Loss" ? "line-through" : null, color: bottomUserResult === "Loss" ? "red" : null }}>{bottomUserChoiceSeed}</span>
                  <span className="team-name" style={{ textDecoration: bottomUserResult === "Loss" ? "line-through" : null, color: bottomUserResult === "Loss" ? "red" : null }}>{bottomUserChoiceName}</span>
              </>
          ) : (
              // Render an empty span to maintain the height of the container
              <span style={{ visibility: 'hidden' }}>Placeholder</span>
          )}
      </div>



              </div>
          );
        }




    // console.log("NCAA HIERACHY RIGHT BEFORE RENDERING", ncaaTournamentHierarchy);
    console.log("TopLeft, etc:", topLeftName, bottomLeftName, topRightName, bottomRightName);
      return (
        <>
    <div>
      {/* User selection row */}
      <div className="user-selection-row">
        <select
          value={selectedUser}
          onChange={handleUserChange}
        >
          {/* Populate dropdown with values from allUsernames array */}
          {allUsernames.map((username, index) => (
            <option key={index} value={username}>{username}</option>
          ))}
        </select>
        <div>{selectedUser}'s Bracket</div> {/* Display "Username's Bracket" */}
      </div>

      {/* Existing JSX structure */}
      <div className="bracket">
        <div className="column">
        <div className="region-names">{topLeftName}</div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, topLeftName, 1)} pick={userPicksDisplay(selectedUserPicks, 3, topLeftName, 1)} />
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, topLeftName, 2)} pick={userPicksDisplay(selectedUserPicks, 3, topLeftName, 2)}/>
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, bottomLeftName, 1)} pick={userPicksDisplay(selectedUserPicks, 3, bottomLeftName, 1)}/>
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, bottomLeftName, 2)} pick={userPicksDisplay(selectedUserPicks, 3, bottomLeftName, 2)}/>
          </div>
          <div className="region-names">{bottomLeftName}</div>
        </div>
        <div className="column2">
          <div style={{ marginTop: '50px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(4, topLeftName, 1)} pick={userPicksDisplay(selectedUserPicks, 4, topLeftName, 1)}/>
          </div>
          <div style={{ marginTop: '111px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(4, bottomLeftName, 1)} pick={userPicksDisplay(selectedUserPicks, 4, bottomLeftName, 1)}/>
          </div>
        </div>
        <div className="column3">
        <div style={{ marginTop: '50px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(5, `${topLeftName}-${bottomLeftName}`, 1)} pick={userPicksDisplay(selectedUserPicks, 5, `${topLeftName}-${bottomLeftName}`, 1)}/>
          </div>
        </div>
        <div className="column4">
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="135"
      height="56"
      viewBox="0 0 135 56"
      fill={isFinalOver ? "#FFC60A" : "none"}
      className="crown-logo"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="135" height="56" viewBox="0 0 135 56" fill="none">
  <path d="M125.712 18.1054C128.428 15.4651 131.205 12.8395 134.287 10.4136C132.599 20.1174 130.905 29.8304 129.172 39.7671C128.273 44.9167 127.365 50.1264 126.441 55.426C86.9487 49.0856 48.0405 49.1664 8.70015 55.3823C6.03566 40.1372 3.44483 25.3224 0.751735 9.95848C2.67623 11.7758 4.54522 13.5852 6.38899 15.3702C11.0146 19.8483 15.4815 24.1728 20.2679 28.0834L20.268 28.0834C23.6937 30.8813 26.6324 32.9596 29.2869 34.2724C31.9466 35.5879 34.3486 36.1489 36.6876 35.8749C39.0263 35.601 41.2383 34.4999 43.5318 32.6095C45.821 30.7226 48.2156 28.0293 50.9236 24.5315L50.9238 24.5312C55.04 19.2077 58.8459 13.6332 62.6915 8.00056C64.3089 5.63151 65.9333 3.25217 67.5909 0.87688C69.0421 2.98647 70.4744 5.10557 71.905 7.22223C75.46 12.482 79.0049 17.7268 82.8057 22.7733C85.8432 26.8096 88.4903 29.9109 90.9987 32.0769C93.5111 34.2463 95.9143 35.5037 98.462 35.8019C101.01 36.1002 103.63 35.4304 106.559 33.8876C109.483 32.3473 112.749 29.9188 116.604 26.6519C119.271 24.3954 121.776 21.9485 124.295 19.4874C124.766 19.0268 125.238 18.5658 125.712 18.1054Z" fill="#EDEDED" stroke="#6D6D6D"/>
</svg>
    </svg>
        <div className="champion-container">
          <div className="top-box" style={{ borderColor: champColor }}>
            {!isFinalOver && champResult === "Loss" ? (
            <LossPlaceholder />
              ) : (
            <img src={champLogo} alt="Image" className="champ-logo" />
              )}
          </div>
          <div className="bottom-box" style={{ backgroundColor: champColor, color: textColor }}>
            {finalWinnerName}
          </div>
            {champResult !== "Win" && (
          <div className="text-row-container">
            <img src={userChampLogo} alt="Image" className="text-row-img" />
            <div className="text-row" style={{ color: bottomTextColor, textDecoration: bottomStrikeout }}>
            {userChampName}
          </div>
          </div>
            )}
        </div>

        <div style={{ marginTop: '50px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(6, null, 1)} pick={userPicksDisplay(selectedUserPicks, 6, null, 1)}/>
        </div>
        <div className="tiebreaker-box">
        <div className="top-row">Tiebreaker Points</div>
        <div className="bottom-row">{leftTiebreakerPoints} - {rightTiebreakerPoints}</div>
        </div>
        </div>

        <div className="column3">
        <div  style={{ marginTop: '50px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(5, `${topRightName}-${bottomRightName}`, 1)} pick={userPicksDisplay(selectedUserPicks, 5, `${topRightName}-${bottomRightName}`, 1)}/>
          </div>
        </div>
        <div className="column2">
        <div style={{ marginTop: '50px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(4, topRightName, 1)} pick={userPicksDisplay(selectedUserPicks, 4, topRightName, 1)}/>
          </div>
          <div style={{ marginTop: '111px', border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(4, bottomRightName, 1)} pick={userPicksDisplay(selectedUserPicks, 4, bottomRightName, 1)}/>
          </div>
        </div>
        <div className="column">
        <div className="region-names">{topRightName}</div>
        <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, topRightName, 1)} pick={userPicksDisplay(selectedUserPicks, 3, topRightName, 1)}/>
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, topRightName, 2)} pick={userPicksDisplay(selectedUserPicks, 3, topRightName, 2)}/>
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, bottomRightName, 1)} pick={userPicksDisplay(selectedUserPicks, 3, bottomRightName, 1)}/>
          </div>
          <div style={{ border: 'none', boxShadow: 'none' }}>
            <BracketBox game={ncaaTournamentDisplay(3, bottomRightName, 2)} pick={userPicksDisplay(selectedUserPicks, 3, bottomRightName, 2)}/>
          </div>
          <div className="region-names">{bottomRightName}</div>
        </div>
      </div>
    </div>

        </>
      );
}

    return (
        <>
        {newAllUsers.length === 0 || !newAllUsers ?

             <>Loading...</>
             :
             <Brackets/>
        }
       </>
    );
};


const mapStateToProps = (state) => {
    console.log('PROP STATES', state);
    return {
        games: state.games,
        game: state.game,
        user: state.user,
        sportsData: state.sportsData,
        cbbLeagueHierarchy: state.cbbLeagueHierarchy,
        ncaaTournamentHierarchy: state.ncaaTournamentHierarchy,
        ncaaTournamentHierarchyReducer: state.ncaaTournamentHierarchyReducer,
        cbbTeams: state.cbbTeams,
        allUsers: state.login.getUsersForLeaderboardReducer.data,
        getUsersByMulligenMadnessReducer:state.login.getUsersByMulligenMadnessReducer.data,
}
};

export default connect(mapStateToProps)(MarchMadnessBracketLive);
