import React, { useState, useEffect } from 'react';
import { useLeaderboardsPageContext } from './context/LeaderboardsPageContext';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import StaticPoolHeaderBar from '../PoolHeaderBar/StaticPoolHeaderBar';
import SimpleSelectionButtons from '../GamePageComponents/TabSelectionButtons/SimpleSelectionButtons';
import { useSearchParams } from 'react-router-dom';
import GameLeaderboard from './LeaderboardPages/GameLeaderboard';
import LeaderboardSponsorPage from './LeaderboardPages/LeaderboardSponsorPage';

const LeaderboardPageContainer = () => {
    const {
        leaderboardPageData,
        myUserId,
        myUsername,
    } = useLeaderboardsPageContext();
    //console.log("Leaderboard Page Data in LeaderboardPageContainer: ", leaderboardPageData);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const logo = leaderboardPageData?.logo;
    const headerText = leaderboardPageData?.headerText;

    const tabTexts = leaderboardPageData?.pages?.map(page => page.tabText);
    //console.log("Button Text", tabTexts);

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTab = searchParams.get('tab') || '0'; // Default to first tab
    
    // Add state for the current page data
    const [currentPageData, setCurrentPageData] = useState(null);
    const [sponsorPageOpen, setSponsorPageOpen] = useState(false);
    const [prevSelectedTab, setPrevSelectedTab] = useState(selectedTab);

    // Use effect to update currentPageData when selectedTab changes or when data loads
    useEffect(() => {
        if (leaderboardPageData?.pages && leaderboardPageData.pages.length > 0) {
            const tabIndex = parseInt(selectedTab, 10);
            // Make sure the index is valid
            if (tabIndex >= 0 && tabIndex < leaderboardPageData.pages.length) {
                setCurrentPageData(leaderboardPageData.pages[tabIndex]);
            } else {
                // Default to first tab if index is invalid
                setCurrentPageData(leaderboardPageData.pages[0]);
            }
            
            // Reset subTab to 0 when the main tab changes
            if (selectedTab !== prevSelectedTab) {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('subTab', '0');
                setSearchParams(newSearchParams);
                setPrevSelectedTab(selectedTab);
            }
        }
    }, [selectedTab, leaderboardPageData, searchParams, setSearchParams, prevSelectedTab]);

    // One curentPageData change, check if currentPageData?.sponsorPage is true
    // If so, open the sponsor page, otherwise make sure it is closed
    useEffect(() => {
        if (currentPageData?.sponsorPage) {
            setSponsorPageOpen(true);
        } else {
            setSponsorPageOpen(false);
        }
    }, [currentPageData]);

     // Handler for when the header text is clicked
     const handleHeaderTextClick = () => {
        if (leaderboardPageData?.pages) {
            // Find the index of the page with sponsorPage set to true
            const sponsorPageIndex = leaderboardPageData.pages.findIndex(page => page.sponsorPage === true);
            
            // If a sponsor page exists, update the selectedTab to that index
            if (sponsorPageIndex !== -1) {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('tab', sponsorPageIndex.toString());
                setSearchParams(newSearchParams);
                // This will trigger the useEffect above to update currentPageData and open the sponsor page
            }
        }
    };

    //console.log("Current Page Data", currentPageData);
    

    // Show loading screen if logo is not available yet
    if (!logo) {
        return <LoadingScreen />;
    }

    return (
        <>
            <StaticPoolHeaderBar
                navigationText={"Home"}
                headerText={leaderboardPageData?.headerBarText}
                linkForNavigation="/"
            />
            
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    margin: '0 auto',
                    padding: '16px 0',
                }}
            >
                <Box 
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',   
                        height: 'auto', 
                        margin: '0 auto', 
                    }}
                >
                    <img
                        src={logo}
                        alt="Community Hub Logo"
                        className="community-hub-logo"
                        style={{
                            maxHeight: isMobile ? '85px' : '150px',
                            display: 'block',
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        color: '#00AAD1',
                        textAlign: 'center',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        marginTop: '16px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    onClick={handleHeaderTextClick}
                >
                    {headerText}
                </Typography>
            </Box>

            <SimpleSelectionButtons 
                selectedTab={selectedTab} 
                tabTexts={tabTexts || []} 
            />

            {!sponsorPageOpen && (
                <>
                    <GameLeaderboard
                        gameData={currentPageData}
                        myUserId={myUserId}
                        myUsername={myUsername}
                    />
                </>
            )}

            {sponsorPageOpen && (
                <Box
                    sx={{
                        marginTop: '32px',
                    }}
                >
                    <LeaderboardSponsorPage
                        gameData={currentPageData}
                    />
                </Box>
            )}
        </>
    );
};

export default LeaderboardPageContainer;