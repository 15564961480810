import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { usePublicPartyContext } from './context/PublicPartyContext';
import { useNavigate } from 'react-router-dom';
import '../PrivateParty/PrivatePartyInviteStyles.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";


const UserIsRestricted = () => {
  const { inviteeDisplayName, clubLogo, foundSubgroupName, clubDisplayName, gameType, gameId, gameAddress, PrivatePartyId, sponsorLogo } = usePublicPartyContext();

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();


  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackToMainPool = () => { 
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`);
  }

  return (
    <Box className="container" style={{ maxWidth: '1116px', margin: '0 auto'}}>
        <Typography className="typography-main-message" style={{ padding: '15px 0', fontSize: isMobile ? '16px' : '20px', maxWidth: '90%', margin: '0 auto'}}>
            You were removed from the {foundSubgroupName} Party.  Reach out to the {inviteeDisplayName} to be reinstated.
        </Typography>
          {sponsorLogo && (
                <img
                    src={sponsorLogo}
                    alt="Sponsor Logo"
                    style={{
                        maxWidth: isMobile ? '300px' : '400px',
                        padding: '32px 20px',
                        margin: '0 auto',
                    }}
                />
            )}
            <img src={clubLogo} alt="club logo" className="club-logo" style={{ display: 'block', margin: '0 auto', width: '128px' }}/>
        <Box sx={{ display: 'block', mt: 2 }}>  {/* Added Box to wrap the button and adjusted the margin-top */}
            <Button variant="contained" style={{ fontSize: isMobile ? '16px' : '20px', marginTop: '10px'}} onClick={() => handleBackToMainPool()}>
                BACK TO MAIN POOL
            </Button>
        </Box>
     </Box>

  );
};

export default UserIsRestricted;
