import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import ReferralLinkButton from './ReferralLinkButton';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import useJoinGameClick from '../../../hooks/general/useJoinGameClick';


const ReferralsSection = ({ referrals, userInGameAdministration }) => {
    const {
        myUserId,
        masterPoolId,
        gameId,
        gameAddress,
        hasJoinDeadlinePassed,
        isUserInThisPool,
    } = useUserAndGameContext();

    const handleJoinGameClick = useJoinGameClick();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const referralLink = referrals?.referralLink;
    const referralLeaderboard = referrals?.referralLeaderboard;
    const referralLogo = referrals?.referralLogo;
    const referralLogoWidth = referrals?.referralLogoWidth;
    //console.log("Referral Logo Width: ", referralLogoWidth);
    const referralTextColor = referrals?.referralTextColor;
    const referralPrizes = referrals?.referralPrizes;
    const referralHeader = referrals?.referralHeader;
    const referralText = referrals?.referralText;
    const referralTitle = referralHeader ? referralHeader : (referralLeaderboard && referralPrizes) ? "REFERRAL REWARDS" : "REFERRAL LINK";
    const leaderboardButtonText = referrals?.leaderboardButtonText;
    const referralButtonText = referrals?.referralButtonText;
    const hideReferralTitle = referrals?.hideReferralTitle;
    //console.log("Show Referral Title: ", hideReferralTitle);

    const uriEncoded = (link) => {
        return encodeURI(link); // encodeURI is enough for full URLs
    };

    const handleLogin = () => {
        let url = `/login/${encodeURIComponent(gameAddress)}?mId=${encodeURIComponent(masterPoolId)}&gId=${encodeURIComponent(gameId)}&page=home`;

        //console.log("Encoded URL:", url);
        navigateToTop(url);
    };

    const handleReferralLeaderboardClick = () => {
        navigateToTop(`/${gameAddress}?mId=${masterPoolId}&gId=${gameId}&page=referrals`);
    }

    return (
        <Box
            sx={{
                padding: '20px 0px',
            }}
        >

            {referralLogo && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '16px',
                    }}
                >
                    <img
                        src={referralLogo}
                        alt="Referral Logo"
                        style={{ width: referralLogoWidth ? referralLogoWidth : '150px', objectFit: 'contain' }}
                    />
                </Box>
            )}

            {!hideReferralTitle && (
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        color: referralTextColor || '#002129',
                        marginBottom: '16px',
                    }}
                >
                    {referralTitle}
                </Typography>
            )}

            {(referralPrizes || !hasJoinDeadlinePassed) && (
                <Box>
                    {referralText?.map((line, index) => (
                        <Box key={index}>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '16px',
                                    color: '#002129',
                                    marginBottom: '8px',
                                }}
                            >
                                <Box component="span" sx={{ fontWeight: 700 }}>
                                    {line.boldText}
                                </Box>{' '}
                                {line.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '16px',
                    margin: '0 auto',
                    gap: isMobile ? '0px' : '16px',
                }}
            >
                {!hasJoinDeadlinePassed && referralLink && (
                    <Box sx={{ width: '298px', height: '36px' }}> {/* Wrapper to ensure consistent spacing */}
                        <ReferralLinkButton
                            masterPoolId={masterPoolId}
                            gameId={gameId}
                            gameAddress={gameAddress}
                            myUserId={myUserId}
                            referralButtonText={referralButtonText}
                            userInGameAdministration={userInGameAdministration}
                        />
                    </Box>
                )}

                {referralLeaderboard && (
                    <Button
                        sx={{
                            width: '298px',
                            height: '36px', // Set a consistent height
                            fontSize: '14px',
                            backgroundColor: '#07356B',
                            color: '#fff',
                            marginTop: isMobile ? '8px' : '0px', // Only add margin top on mobile
                            cursor: 'pointer',
                            boxShadow:
                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                            '&:hover': {
                                backgroundColor: '#07356B', // Keep the same color on hover
                            },
                        }}
                        onClick={handleReferralLeaderboardClick}
                    >
                        {leaderboardButtonText ? leaderboardButtonText : "REFERRAL LEADERBOARD"}
                    </Button>
                )}
            </Box>


        </Box>
    );
};

export default ReferralsSection;
