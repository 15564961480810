import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useCreatePool = () => {
    return useMutation({
        mutationFn: submitCreatePoolRequest,
        onError: (error) => {
            console.error('Error creating pool:', error);
        },
        onSuccess: (data) => {
            console.log('Pool created successfully:', data);
        }
    });
}

const submitCreatePoolRequest = async (poolData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/create-pool`, poolData, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });  
    console.log('Pool created successfully:', response.data);
    return response.data.data;
}