import React, { useState, useMemo } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getTeamLogo } from "../../../../utils/SportsData";
import './SurvivorGamesStyles.scss';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext.js";
import { useNavigate, useSearchParams } from "react-router-dom";

const SurvivorMultipleEntryView = ({
    entries,
    league,
    teams,
    lossesForElimination,
    picksPerInterval,
    theCurrentInterval,
    isEntryEliminated,
    intervalInfo,
    totalEntriesAlive,
    totalEntriesEliminated,
    totalEntriesCount,
    canIAddAnEntry,
    setJoinOpen,
    hasJoinDeadlinePassed,
    setIndexToRemove,
    setRemoveModalOpen,
    isUserAdmin,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
        masterPoolId,
        gameId,
        page,
        gameAddress,
    } = useUserAndGameContext();

    // Create a map to track loading states for each entry
    const [loadingStates, setLoadingStates] = useState(
        new Map(entries?.map(entry => [entry?.id || entry?.rank, { loading: true, error: false }]))
    );

    const handleImageLoad = (entryId) => {
        setLoadingStates(prev => new Map(prev).set(entryId, { loading: false, error: false }));
    };

    const handleImageError = (entryId) => {
        setLoadingStates(prev => new Map(prev).set(entryId, { loading: false, error: true }));
    };

    // Process all entries status at once
    const entriesStatus = useMemo(() => {
        if (!entries?.length) return [];
        
        return entries.map(entry => ({
            id: entry.id,
            name: entry.name,
            eliminated: isEntryEliminated(entry?.pool?.picks, lossesForElimination),
            picks: entry?.pool?.picks || []
        }));
    }, [entries, lossesForElimination, isEntryEliminated]);
    

    // Process eliminated picks for all entries at once
    const eliminatedPicksInfo = useMemo(() => {
        if (!entriesStatus?.length) return [];

        return entriesStatus.map(entry => {
            const eliminatedPicks = entry.picks.filter(pick => pick.result === "loss");
            const eliminatedPick = eliminatedPicks.length 
                ? eliminatedPicks.reduce((prev, curr) => curr.interval > prev.interval ? curr : prev, eliminatedPicks[0])
                : null;

            return {
                id: entry.id,
                name: entry.name,
                eliminatedPicks,
                eliminatedPick,
                eliminatedTeamLogo: eliminatedPick?.teamId !== 'No Pick' 
                    ? getTeamLogo(league, eliminatedPick?.teamId, teams)
                    : null
            };
        });
    }, [entriesStatus, league, teams]);

    // Process current interval picks for all entries at once
    const currentPicksInfo = useMemo(() => {
        if (!entriesStatus?.length) return [];

        return entriesStatus.map(entry => {
            // Just find the pick for the current interval
            const currentPick = entry.picks.find(pick => 
                pick.interval === theCurrentInterval
            );
            
            return {
                name: entry.name,
                currentPick,
                currentPickTeamLogo: currentPick
                    ? getTeamLogo(league, currentPick.teamId, teams)
                    : null
            };
        });
    }, [entriesStatus, theCurrentInterval, league, teams]);


    // Get the entry number from entry name
    const getEntryNumber = (entryName) => {
        const match = entryName?.match(/\((\d+)\)$/); // Find (x) at the end
        return match ? Number(match[1]) : 1; // Return (x) as a number or default to 1
    };

    const handleAddEntryClick = () => {
        setJoinOpen(true);
    };

    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    };

    const handleEntryChange = (index) => {
        // Get current pickIndex from URL or default to 0
        const currentPickIndex = Number(searchParams.get('pickIndex') || 0);
        
        // If the current pickIndex already matches the clicked entry, do nothing
        if (currentPickIndex === index) {
            return;
        }

        const newParams = new URLSearchParams(searchParams);
        // Update the parameters we want to change
        newParams.set('mId', masterPoolId);
        newParams.set('gId', gameId);
        newParams.set('page', page);
        newParams.set('pickIndex', index);

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(`/${gameAddress}?${newParams.toString()}`);
            setUnsavedPicksModalOpen(true);
            return;
        }
        
        navigate(`/${gameAddress}?${newParams.toString()}`);
    };

    // Get current pickIndex from URL or default to 0
    const pickIndex = Number(searchParams.get('pickIndex') || 0);

    return (
        <Box className="survivor-multiple-entry-container" sx={{ width: '100%' }}>
            {/* <Box className="survivor-multiple-entry-header" sx={{ mb: 2 }}>
                <Box className="survivor-single-entry-column">
                    <Typography className="survivor-single-entry-header-text">
                        Alive
                    </Typography>
                    <Typography className="survivor-single-entry-text">
                        {totalEntriesAlive}
                    </Typography>
                    <Typography className="survivor-single-entry-header-text">
                        Out
                    </Typography>
                    <Typography className="survivor-single-entry-text">
                        {totalEntriesEliminated}
                    </Typography>
                </Box>
            </Box> */}

            <Box className="survivor-multiple-entry-list" sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: isMobile ? '16px' : '64px',
                justifyContent: 'flex-start'
            }}>
                {entries?.map((entry, index) => {
                    const entryId = entry?.id || entry?.rank;
                    const entryNumber = getEntryNumber(entry?.name);
                    const selectedEntry = entryNumber === (pickIndex + 1);
                    //console.log("Entry ID: ", entryId, entry, entryNumber, selectedEntry);
                    const { loading, error } = loadingStates.get(entryId) || { loading: true, error: false };
                    
                    const entryStatus = entriesStatus.find(e => e.name === entry.name);
                    const isEliminated = entryStatus?.eliminated;
                    
                    const eliminatedInfo = eliminatedPicksInfo.find(e => e.name === entry.name);
                    const currentPickInfo = currentPicksInfo.find(e => e.name === entry.name);
                    
                    return (
                        <Box 
                            key={index} 
                            className="survivor-multiple-entry-content-wrapper"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box 
                                className="survivor-multiple-entry-content"
                                sx={{
                                    border: selectedEntry ? '2px solid #002129' : '2px solid #78838a',
                                    borderRadius: '8px',
                                    opacity: selectedEntry ? 1 : 0.5,
                                    cursor: 'pointer',
                                    padding: 1,
                                }}    
                                onClick={() => handleEntryChange(entryNumber - 1)}
                            >
                                <Typography className="survivor-multiple-entry-header-text" sx={{ 
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    mb: 1
                                }}>
                                    Entry ({entryNumber})
                                </Typography>
                                
                                {/* Status Row */}
                                <Box sx={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        alignItems: 'center', 
                                    }}>
                                        <Typography sx={{
                                            fontSize: isMobile ? '12px' : '14px',
                                            fontWeight: 600,
                                            color: isEliminated ? "#CC293C" : "#00AA72"
                                        }}>
                                            {isEliminated ? "Eliminated" : "Alive"}
                                        </Typography>
                                    </Box>
                                    
                                    {/* Elimination or Current Pick Row */}
                                    {isEliminated ? (
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {eliminatedInfo?.eliminatedPick?.teamId !== 'No Pick' ? (
                                                    <Box 
                                                        sx={{
                                                            border: '1px solid #CC293C',
                                                            backgroundColor: 'rgba(204, 41, 60, 0.2)',
                                                            width: isMobile ? '28px' : '36px',
                                                            height: isMobile ? '28px' : '36px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '4px',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        {loading && (
                                                            <CircularProgress
                                                                size={isMobile ? 16 : 20}
                                                                sx={{
                                                                    color: "#666",
                                                                    position: "absolute",
                                                                }}
                                                            />
                                                        )}
                                                        <img
                                                            src={eliminatedInfo?.eliminatedTeamLogo}
                                                            alt="Team Logo"
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '100%',
                                                                opacity: loading ? 0 : 1,
                                                                display: error ? "none" : "block",
                                                            }}
                                                            onLoad={() => handleImageLoad(entryId)}
                                                            onError={() => handleImageError(entryId)}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center' 
                                                    }}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid #CC293C',
                                                                backgroundColor: 'rgba(204, 41, 60, 0.2)',
                                                                width: isMobile ? '28px' : '36px',
                                                                height: isMobile ? '28px' : '36px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: '4px',
                                                            }}
                                                        >
                                                            <CloseIcon sx={{ color: "#CC293C", fontSize: isMobile ? '20px' : '24px' }} />
                                                        </Box>
                                                        <Typography 
                                                            sx={{
                                                                color: "#CC293C",
                                                                fontSize: isMobile ? '10px' : '12px',
                                                                fontWeight: 600,
                                                                ml: 0.5
                                                            }}
                                                        >
                                                            No Pick
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            {currentPickInfo?.currentPick ? (
                                                <Box 
                                                    sx={{
                                                        border: currentPickInfo.currentPick.result === 'win' ? '1px solid #00AA72' : 
                                                               currentPickInfo.currentPick.result === 'loss' ? '1px solid #CC293C' : 
                                                               '1px solid #002129',
                                                        backgroundColor: currentPickInfo.currentPick.result === 'win' ? 'rgba(0, 170, 114, 0.2)' : 
                                                                        currentPickInfo.currentPick.result === 'loss' ? 'rgba(204, 41, 60, 0.2)' : 
                                                                        '#fff',
                                                        width: isMobile ? '28px' : '36px',
                                                        height: isMobile ? '28px' : '36px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '4px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            size={isMobile ? 16 : 20}
                                                            sx={{
                                                                color: "#666",
                                                                position: "absolute",
                                                            }}
                                                        />
                                                    )}
                                                    <img
                                                        src={currentPickInfo.currentPickTeamLogo}
                                                        alt="Team Logo"
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '100%',
                                                            opacity: loading ? 0 : 1,
                                                            display: error ? "none" : "block",
                                                        }}
                                                        onLoad={() => handleImageLoad(entryId)}
                                                        onError={() => handleImageError(entryId)}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography 
                                                    sx={{
                                                        height: isMobile ? '28px' : '36px',
                                                        color: "#CC293C",
                                                        fontSize: isMobile ? '12px' : '14px',
                                                        fontWeight: 600,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    No Pick
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
                                {!selectedEntry ? (
                                    <Typography 
                                        onClick={() => handleEntryChange(entryNumber - 1)}
                                        sx={{
                                            color: "#07356B",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            fontSize: isMobile ? '12px' : '14px',
                                            fontWeight: "700",
                                            textAlign: 'center',
                                        }}
                                    >
                                        SELECT
                                    </Typography>
                                ) : (
                                    // Only show Remove option if join deadline hasn't passed
                                    (!hasJoinDeadlinePassed && !(isUserAdmin && entries?.length === 1)) && (
                                        <Typography 
                                            onClick={() => handleRemovedClick(entryNumber - 1)}
                                            sx={{
                                                color: "#CC293C",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                fontSize: isMobile ? '12px' : '14px',
                                                fontWeight: "700",
                                                textAlign: 'center',
                                            }}
                                        >
                                            REMOVE
                                        </Typography>
                                    )
                                )}
                            </Box>
                        </Box>
                    );
                })}

                {canIAddAnEntry && (
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '60px',
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleAddEntryClick}
                            sx={{
                                width: '80px',
                                backgroundColor: '#002129',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#002129',
                                },
                                height: '36px',
                                fontSize: isMobile ? '12px' : '14px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Add Entry
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SurvivorMultipleEntryView;