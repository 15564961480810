import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from './pickemModal';
import { v4 as uuidv4 } from 'uuid';

// import '../styles.scss';

const PickemPoolGameDetails = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedWeek, setSelectedWeek] = useState(''); // Add state for selected week
    const [pick, setPick] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalStates, setModalStates] = useState({});
    const [selectedGameWeek, setSelectedGameWeek] = useState(''); // Updated state name
    const [modalOpenForGame, setModalOpenForGame] = useState(''); // Updated state name
    const [rowsData, setRowsData] = useState([]); // State to store row data

    const handleSelectedGameWeek = (e) => {
        setSelectedGameWeek(Number(e));
    }
    const gameData = props?.game?.fetchGameByIdReducer?.game?.data?.gameData;
    const gamesPerPage = 1;

    const getTeamLogoUrl = (teamName) => {
        const nflTeamsData = props?.sportsData?.nflTeamsData?.data;
        const team = nflTeamsData.find((team) => team.Key === teamName);
        return team ? team.WikipediaLogoUrl : null;
    };
    const teamData = (teamName) => {
        const nflTeamsData = props?.sportsData?.nflTeamsData?.data;
        const team = nflTeamsData.find((team) => team.Key === teamName);
        return team ? team : null;
    };

    const handleSelectedWeek = (e) => {
        setSelectedGame(Number(e));
    }
    useEffect(() => {
        let seasonList = props.sportsData?.seasonData?.seasonData;

        let userData = props.userData;
        console.log('USER DATA', props);
        if (seasonList && seasonList.length > 0) {
            let seasonData = seasonList.filter((game) => game.Week >= props?.gameData.pool?.startingWeek);
            const firstGameWeek = seasonData.length > 0 ? seasonData[0].Week : null;
            setSelectedGame(firstGameWeek); // Set the first available week as initial selectedGame
        }
    }, []);

    useEffect(() => {
        if (gameData.pool.picksPerWeek === 'all') {
          // Check if there are saved picks in props.userData.gamePicks
          if (props?.userData?.gamePicks?.length > 0) {
            // Use the savedPicks data to create rows
            const newPick = [];
            const newRowsData = [];

            props?.userData?.gamePicks?.forEach((savedPicks, index) => {
              console.log('NEW', savedPicks);

              console.log("GAME ID FROM SAVED PICKS___________", savedPicks._id);
                if(savedPicks?._id === props?.gameData?._id){
                    savedPicks?.picks?.forEach((savedPick) => {
                        const rowkeys = Object.keys(savedPick)[0];
                        const { gameId, gameWeek, pickTeam, rowKey,rowNumber } = savedPick[rowkeys];
                        const newRowData = {
                            gameId,
                            gameWeek,
                            pickTeam,
                            rowKey,
                            rowNumber
                        };
                        newPick.push({ [rowkeys]: newRowData });
                        const existingRowIndex = newRowsData.findIndex((row) => row[rowKey]);
                        if (selectedGame && props?.gameData?.Week !== selectedGame) { // Updated condition
                            return null; // Skip rendering if week doesn't match selected week
                        }
                        if (existingRowIndex === -1) {
                          // Row with the same rowKey doesn't exist, add it
                          newPick.push({ [rowkeys]: newRowData });
                          newRowsData.push({ [rowkeys]: {} });
                        } else {
                          console.log(`Row with rowKey ${rowKey} already exists`);
                        }
                    });
                }else{
                    console.log("NO SAVED PICKS__________________________________")
                }
            });
            // Update the state with the new data
            if(newPick.length > 0){
                setPick(newPick);
                setRowsData(newRowsData);
            }else{
                const newRowKey = uuidv4();
                const newRowData = {}; // Initialize row data here

                // You can add your row creation process here if needed
                // For now, we'll just create an empty row
                setRowsData((prevRowsData) => [...prevRowsData, { [newRowKey]: newRowData }]);
            }
          } else {
            console.log("NO SAVED PICKS");
            // No savedPicks, go through the row creation process
            const newRowKey = uuidv4();
            const newRowData = {}; // Initialize row data here

            // You can add your row creation process here if needed
            // For now, we'll just create an empty row
            setRowsData((prevRowsData) => [...prevRowsData, { [newRowKey]: newRowData }]);
          }
        }
      }, [gameData.pool.picksPerWeek, props.userData.gamePicks]);
    let RenderGameHome = () => {
        let seasonList = props.sportsData?.seasonData?.seasonData;
        let seasonData = seasonList.filter((game) => game.Week >= props?.gameData?.pool?.startingWeek);

        const dateTime = (dateArg) => {
            const date = new Date(dateArg);
            const formattedDate = date.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZoneName: "short",
            });
            return formattedDate;
          }

        // Group the filtered games by week using an object
        const gamesByWeek = {};
        seasonData.forEach((game) => {
          if (!gamesByWeek[game.Week]) {
            gamesByWeek[game.Week] = [];
          }
          gamesByWeek[game.Week].push(game);
        });
        const weeks = Object.keys(gamesByWeek);
        const lastWeekIndex = currentPage * gamesPerPage;
        const firstWeekIndex = lastWeekIndex - gamesPerPage;
        const currentWeeks = weeks.slice(firstWeekIndex, lastWeekIndex);

        return currentWeeks.map((weekNumber) => {
            const gamesForWeek = gamesByWeek[weekNumber];
            return (
              <div key={weekNumber}>

                <div style={{display:'flex', width:'100%', justifyContent:
            "space-between", alignContent:"center"}}>
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                    <h2>GAMES FOR WEEK {weekNumber}</h2>
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage * gamesPerPage >= Object.keys(gamesByWeek).length}>Next</button>
                </div>
                {gamesForWeek.map((game, index) => {
                    if (game.gameKey === null || game.AwayTeam === 'BYE') {
                        return null; // Skip rendering this game
                    }
                    const homeTeamLogoUrl = getTeamLogoUrl(game.HomeTeam);
                    const awayTeamLogoUrl = getTeamLogoUrl(game.AwayTeam);

                    return (
                        <div key={index} style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(5, 1fr)",
                            gridGap: 10,
                            alignItems:'center'}}>
                                <div>PICKS</div>
                                <div style={{display:'flex', alignItems:'center'}}><img style={{disply:'block', width:'50px',}} src={homeTeamLogoUrl} alt={game.HomeTeam + 'logo'}/>{game.HomeTeam}{game.PointSpread}</div>
                                <div>{dateTime(game.DateTime)}</div>
                                <div  style={{display:'flex', alignItems:'center'}}>{game.AwayTeam}<img style={{disply:'block', width:'50px',}} src={awayTeamLogoUrl} alt={game.AwayTeam + 'logo'}/></div>
                                <div>PICKS</div>
                        </div>
                    );
                })}
              </div>
            );
        });
    }
    const RenderPickEntry = ({index, gamesForWeek, dateTime, pickLookup, rowsData, setRowsData, rows, rowKey, rowNumber, removeLastRow, submitRowEntry}) => {
        const thisRow = rows[index];
        // console.log(thisRow)
        // console.log('ROWS DATA', pick[rowNumber]);
        let rowKeys = Object.keys(rows);
        let rowKeyLookup = rowKeys[index];
        // console.log(rowKeyLookup);
        // console.log('MAYBE>>>>', pick)
        const [localPick, setLocalPick] = useState({}); // Local state for the pick

        let gameRowId = index;
        const picksForCurrentRow = pick.filter((pickItem) => pickItem[rowKey]);
        // const picksForCurrentRow = pick.filter((pickItem) => Object.keys(pickItem) === rowKeys[0]);
        const addLocalPick = (pickData) => {
            console.log('PICK DATA', pickData);

            setPick((prevPicks) => [...prevPicks, pickData]);
        };
        useEffect(() => {
            const addPick = (pickData) => {
            console.log('ADD PICK PICK DATA', pickData);
            // Update local pick state for this specific row
            // updateLocalPick(pickData);
            if (props?.userData?.gamePicks?.length > 0) {
                gameRowId = pickData.rowKey;
            };
            addGlobalPick(pickData, gameRowId);
            setModalOpenForGame('');
        };

            const checkPicks = () => {
                if (props?.userData?.gamePicks?.length > 0) {
                    props?.userData?.gamePicks?.forEach((savedPicks, index) => {
                        savedPicks?.picks?.forEach((savedPick) => {
                            // Ensure that addPick doesn't trigger the useEffect
                            let savedPickKey = Object.keys(savedPick)[0];
                            let savedPickData = savedPick[savedPickKey];
                            console.log('USEFFECT !!!!', savedPickData)
                            addPick(savedPickData);
                        });
                    });
                }
            };

            // Call checkPicks only once when the component mounts
            if (!props.userData.gamePicksChecked) {
                checkPicks();

                // Mark the check as completed to avoid infinite loops
                props.userData.gamePicksChecked = true;
            }
        }, [props?.userData?.gamePicks]); // Add props?.userData?.gamePicks as a dependency

        const addGlobalPick = (pickData, rowKeyData) => {
            console.log('GLOBAL PICK DATA', pickData)
            // Update local pick state for this specific row
            updateLocalPick(pickData);
            setPick((prevPicks) => [
                ...prevPicks,
                {
                    // ...prevPicks[rowKey], // Keep previous picks for other rows intact
                    [rowKeyData]: pickData, // Associate the pick with the correct rowKey
                },
            ]);
        };

        const updateLocalPick = (pickData) => {
            console.log('UPDATE LOCAL PICK DATA', pickData);
            setLocalPick((prevPicks) => ({
                // ...prevPicks,
                pickData
            }));
        };

        const addPick = (pickData) => {
            console.log('ADD PICK PICK DATA', pickData);
            // Update local pick state for this specific row
            // updateLocalPick(pickData);
            if (props?.userData?.gamePicks?.length > 0) {
                gameRowId = pickData.rowKey;
            };
            console.log(pickData, gameRowId);
            addGlobalPick(pickData, gameRowId);
            setModalOpenForGame('');
        };


        const isRowID = (rowKey) => {
            console.log('ROW KEY', rowKey, pick);

        };

        const getPickForGameAndRow = (gameId, rowKey) => {

            if (pickLookup[rowKey]) {
                const rowPicks = pickLookup[rowKey];
                // console.log('PICK LOOKUP',rowPicks);
                if(rowPicks.gameId === gameId) {
                    // console.log('PICK LOOKUP',rowPicks);
                    return rowPicks;
                }
            }
            return null; // Return null if no pick is found
        };

        const isGamePickedForRow = (gameId) => {
            for (const pickItem of pick) {
                if (pickItem[rowKey]?.gameId === gameId && pickItem[rowKey]?.rowKey === rowKey) {
                    return true;
                }
            }
            return false;
        };
        const testFunc = () => {
        }

        const pickCount = props.gameData.pool.picksPerWeek === 'unlimited'? gamesForWeek.length : props.gameData.pool.picksPerWeek;

        const openModalForGame = (gameId, rowKey, game) => {
            const homeTeamLogoUrl = getTeamLogoUrl(game.HomeTeam);
            const awayTeamLogoUrl = getTeamLogoUrl(game.AwayTeam);
            const homeTeamData = teamData(game.HomeTeam);
            const awayTeamData = teamData(game.AwayTeam);
            const pickCount = props.gameData.pool.picksPerWeek === 'unlimited'? gamesForWeek.length : props.gameData.pool.picksPerWeek;

            testFunc(rowKey)
            setModalOpenForGame({ gameId, rowKey, game, homeTeamLogoUrl, awayTeamLogoUrl, homeTeamData, awayTeamData, pickCount });
        };

        const closeModalForGame = () => {
            setModalOpenForGame('');
        };

        //START HERE //////////////////////////// ============= AUGUST 29 12:25AM
        // const filterDataByGameWeek = (dataObject, targetWeek) => {
        //     const filteredData = {};

        //     for (const index in dataObject) {
        //       const rowDataObject = dataObject[index];
        //       const [rowKey, rowData] = Object.entries(rowDataObject)[0];

        //       if (rowData.gameWeek === targetWeek) {
        //         filteredData[index] = { [rowKey]: rowData };
        //       }
        //     }

        //     return filteredData;
        // };
        // const filteredData = filterDataByGameWeek(yourDataObject, targetWeek);
        // console.log(filteredData);


        return (
            <>
            {/* {&& modalOpenForGame.gameId === game.GameKey ?}pickItem[rowKey]?.gameId === gameId  */}
             {modalOpenForGame && modalOpenForGame.rowKey === rowKey ? (
                <Modal
                    isOpen={true}
                    onClose={closeModalForGame}
                    // game={game}
                    gameData={modalOpenForGame.game}
                    homeTeamData={modalOpenForGame.homeTeamData}
                    awayTeamData={modalOpenForGame.awayTeamData}
                    homeTeamLogoUrl={modalOpenForGame.homeTeamLogoUrl}
                    awayTeamLogoUrl={modalOpenForGame.awayTeamLogoUrl}
                    addPick={(pickData) => addPick(pickData, modalOpenForGame.rowKey)}
                    pick={pick}
                    pickCount={modalOpenForGame.pickCount}
                    rowKey={rowKey}
                    rowKeyLookup={gameRowId} // Pass the rowKey to the Modal
                    rowNumber={rowNumber}
                />
            ) : null}
            <div

            style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: 'normal',
                textAlign: 'center',
                border: "1px solid black",
            }}>

                <div style={{maxWidth:140, width:'100%'}}>{props.userData.email}{rowNumber !== 0? "(" + rowNumber + ")":null}</div>
                {/* <div>{gameRowId}</div> */}
                <div style={{maxWidth:80, width:'100%'}} onClick={()=>{removeLastRow(rowKey)}}>REMOVE ENTRY</div>
                <div style={{maxWidth:80, width:'100%'}} onClick={()=>{submitRowEntry(rowKey)}}>SUBMIT ENTRY</div>
                {gamesForWeek.map((game, index) => {
                    if (game.GameKey === null || game.AwayTeam === 'BYE') {
                        return null; // Skip rendering this game
                    }
                    if (selectedGame && game.Week !== selectedGame) { // Updated condition
                        return null; // Skip rendering if week doesn't match selected week
                    }
                    const isGamePickedData = (gameId, rowKey) => {

                        for (const pickItem of pick) {
                            console.log('isGamePickedData', pick)
                            if (pickItem[rowKey]?.gameId !== undefined && pickItem[rowKey]?.gameId === gameId && pickItem[rowKey]?.rowKey === rowKey) {
                                // console.log(pickItem[rowKey])
                                return pickItem[rowKey].pickTeam;
                            }
                        }
                        return {};
                    };
                    const isGamePicked = (gameId, rowKey) => {
                        for (let i = 0; i < pick.length; i++) {
                            if(pick[i][rowKey] !== undefined && pick[i][rowKey]?.gameId === gameId){
                                // console.log('true', pick[i][rowKey])
                                // alert('true')
                                return pick[i][rowKey]?.gameId;
                            }
                        }
                        return '';
                    };
                        return (
                 <div key={index}>

                    <div style={{ borderRight: "1px solid black", }}>
                    {rowNumber === 0 ?
                    <>
                    <div style={{ border: "1px solid black", }}>{game.AwayTeam}@{game.HomeTeam}</div>
                    <div style={{ border: "1px solid black", }}>{dateTime(game.DateTime)}</div>
                    <div style={{ border: "1px solid black", }}>{game.HomeTeam}{game.PointSpread > 0 ? `+ ${game.PointSpread}` : game.PointSpread < 0 ?  game.PointSpread  : ""}</div>
                    <div onClick={() => openModalForGame(game.GameKey, rowKey, game)} style={{ border: "1px solid black", cursor: 'pointer' }}>VIEW DETAILS</div>
                    </>
                    :
                    null}


                    <div className={dateTime(new Date) <= dateTime(game.DateTime) ? "pickem-live" : dateTime(new Date) <= dateTime(game.DateTime) ? "pickem-win":"pickem-loss"} style={{ height: "65px", width:"65px", border: "1px solid black", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {isGamePicked(game.GameKey, rowKey) === game.GameKey ?(

                       <>
                            <div className={"pickem-pool-pick"}>
                                <div className={"pickem-change"} onClick={() => {if (dateTime(new Date) <= dateTime(game.DateTime)) {openModalForGame(game.GameKey, rowKey, game);}}}
                                >Change Pick</div>
                                {isGamePicked(game.GameKey, rowKey) === game.GameKey?
                                    <>

                                        {game.HomeTeam === isGamePickedData(game.GameKey, rowKey)? (
                                            <img style={{ display: 'block', width: '50px', margin:"0 auto" }} src={getTeamLogoUrl(game.HomeTeam)} alt={game.HomeTeam + 'logo'} />
                                        ) : (
                                            <img style={{ display: 'block', width: '50px', margin:"0 auto"  }} src={getTeamLogoUrl(game.AwayTeam)} alt={game.AwayTeam + 'logo'} />
                                        )}
                                    </>
                                :  <div style={{cursor:'pointer'}}  onClick={() => {if (dateTime(new Date) <= dateTime(game.DateTime)) { openModalForGame(game.GameKey, rowKey, game);}}}>HEY</div>}
                            </div>
                         </>
                        ) : (
                            props.gameData.pool.picksPerWeek !== 'all' && pickCount <= pick.length ?
                                <div>
                                    Picks Filled
                                </div>
                                :
                               <>{isGamePicked(game.GameKey, rowKey) === game.GameKey?
                                <>
                                    {game.HomeTeam === isGamePickedData(game.GameKey, rowKey)? (
                                        <img style={{ display: 'block', width: '50px', margin:"0 auto" }} src={getTeamLogoUrl(game.HomeTeam)} alt={game.HomeTeam + 'logo'} />
                                    ) : (
                                        <img style={{ display: 'block', width: '50px', margin:"0 auto"  }} src={getTeamLogoUrl(game.AwayTeam)} alt={game.AwayTeam + 'logo'} />
                                    )}
                                </>
                                :
                                <>{dateTime(new Date) <= dateTime(game.DateTime)?<div style={{cursor:'pointer'}} onClick={() => {if (dateTime(new Date) <= dateTime(game.DateTime)){openModalForGame(game.GameKey, rowKey, game);}}}>Make Pick</div>: "-"}</>

                                }
                                </>

                        )}

                    </div>

                </div>

            </div>

        );
    })}
    <div style={{ borderRight: "1px solid black", }}>
        <div style={{ border: "1px solid black", }}>WEEK</div>
        <div style={{ display:"flex"}}>
            <div style={{ border: "1px solid black", }}>Points</div>
            <div style={{ border: "1px solid black", }}>Record</div>
        </div>
        <div style={{ display:"flex",  border: "1px solid black"}}>

                <div style={{ border: "1px solid black", }} className='pickem-pool-pick'>
                   {}
                </div>


                <div style={{ border: "1px solid black", }} className='pickem-pool-pick'>
                    0-0-0
                </div>

        </div>
    </div>
    <div style={{ borderRight: "1px solid black", }}>
        <div style={{ border: "1px solid black", }}>SEASON</div>
        <div style={{ display:"flex"}}>
            <div style={{ border: "1px solid black", }}>Points</div>
            <div style={{ border: "1px solid black", }}>Record</div>
        </div>
        <div style={{ display:"flex", border: "1px solid black"}}>

                <div style={{ border: "1px solid black", }} className='pickem-pool-pick'>
                    0
                </div>


                <div style={{ border: "1px solid black", }} className='pickem-pool-pick'>
                    0-0-0
                </div>

        </div>
    </div>
    </div>
    </>
    );
    }

    let RenderMyPicks = () => {

        const [rows, setRows] = useState([]); // State to store rendered rows
        const [nextRowKey, setNextRowKey] = useState(0); // Counter for generating row keys
        const rowKeys = Object.keys(rowsData);
        const rowKey = rowKeys[0]; // Assuming you're working with the first row key
        // const row = rowsData[rowKey]; // Access the specific row data using the row key
        console.log("ROW KEYS___________________",rowKeys)
        let seasonList = props.sportsData?.seasonData?.seasonData;
        let seasonData = seasonList.filter((game) => game.Week >= props?.gameData.pool?.startingWeek);
        console.log(rowsData);

        const pickLookup = pick.reduce((lookup, pick) => {
            return pick;
        }, {});
        const dateTime = (dateArg) => {
            const date = new Date(dateArg);
            const formattedDate = date.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
            return formattedDate;
        }

        const clearAllPicks = () => {
            setPick([]);

            //TODO: Set Picks back to original state if submitted
            setPick()
        };

        // Group the filtered games by week using an object
        const gamesByWeek = {};
        seasonData.forEach((game) => {
            if (!gamesByWeek[game.Week]) {
                gamesByWeek[game.Week] = [];
            }
            gamesByWeek[game.Week].push(game);
        });

        const weeks = Object.keys(gamesByWeek);

        let selectedString = selectedGame?.toString()
        const selectedWeekIndex = weeks.indexOf(selectedString); // Find the index of the selected week

        const lastWeekIndex = selectedWeekIndex + 1; // Add 1 to get the index of the next week
        const firstWeekIndex = lastWeekIndex - gamesPerPage;
        const currentWeeks = weeks.slice(firstWeekIndex, lastWeekIndex);

   const addRow = () => {
        const rowIndex = Object.keys(rowsData).length + 1; // Increment the counter
        const newRowKey = uuidv4(); // Generate a UUID
        console.log(rows.length, rowIndex, gameData);

        // Check if the maximum number of entries has been reached
        if (rowIndex <= Number(gameData.pool.maxEntries)) {
            // Check if a row with the same gameData already exists
            const rowExists = rowsData.some((rowData) => {
            const row = rowData[Object.keys(rowData)[0]];
            return row.gameId === gameData.gameId; // Replace with the correct field you want to match
            });

            if (!rowExists) {
            // Add a new row if it doesn't exist
            setRowsData((prevRowsData) => [...prevRowsData, { [newRowKey]: {} }]);
            } else {
            alert('Row with the same game data already exists.');
            }
        } else {
            alert('Max Entries Reached');
        }
        };

        const submitRowEntry = (rowKey) => {
            console.log('SUBMIT ROW ENTRY', rowsData, rowKey, pick, gameData);
            let index = 1;
            pick.forEach((pickItem) => {
                let pickPayload = {
                    gameDataID: gameData._id,
                    pickItem
                }
                console.log('PAYLOAD!!!!!!_______________________', pickPayload);
                props.dispatch(props.savePicks(pickPayload));
                index += 1;
            });
        }

        const removeLastRow = (rowKeyToRemove) => {
            const newRowsData = rowsData.filter((rowDataObject) => {
              const [rowKey] = Object.keys(rowDataObject);
              return rowKey !== rowKeyToRemove;
            });

            setRowsData(newRowsData);
          };

        //   const handleRowChange = (e, rowIndex) => {
        //     // Update the corresponding entry data in rowsData
        //     const updatedRowsData = [...rowsData];
        //     updatedRowsData[rowIndex] = e.target.value;
        //     setRowsData(updatedRowsData);
        //   };

        return currentWeeks.map((weekNumber) => {
            const gamesForWeek = gamesByWeek[weekNumber];

            console.log(pick);
            const picksByRow = (rowKey) => {
                let pickByRow=[];
                for(let i = 0; i < pick.length; i++){
                    if(pick[i].rowKey === rowKey){
                        pickByRow.push(pick[i])
                    }
                }
                return pickByRow;
           }
            return (
                <div style={{fontSize:12}} key={weekNumber}>

                    <div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center" }}>
                        <select
                            value={selectedGame} // Updated line
                            onChange={(e) => handleSelectedWeek(e.target.value)} // Update function name
                        >
                            {weeks.map((weekNumber) => (
                                <option key={weekNumber} value={weekNumber}>
                                    Week {weekNumber}
                                </option>
                            ))}
                        </select>
                        <h2>GAMES {selectedGame ? `FOR WEEK ${selectedGame}` : 'FOR ALL WEEKS'}</h2>
                        <div onClick={()=>clearAllPicks()}>Clear All Picks</div>
                        {gameData.pool.picksPerWeek === 'all' ?
                            <button onClick={(e)=>addRow(e, )}>Add Entry</button>
                            :
                            null
                        }
                    </div>


                     {gameData.pool.picksPerWeek === 'all' ? (
                        rowsData.map((rowDataObject, index) => {
                            const [rowKey, rowData] = Object.entries(rowDataObject)[0];
                            // Now you can use rowKey and rowData to access the data for each row
                            return (
                                <div key={rowKey}>
                                   <RenderPickEntry
                                        index={rowKey}
                                        key={rowKey}
                                        rowKey={rowKey} // Pass the rowKey as a prop
                                        gamesForWeek={gamesForWeek}
                                        dateTime={dateTime}
                                        pickLookup={pickLookup}
                                        rowsData={rowsData[rowKey]} // Corrected this line
                                        setRowsData={(updatedData) => {
                                            setRowsData((prevRowsData) => ({
                                                ...prevRowsData,
                                                [rowKey]: updatedData,
                                            }));
                                        }}
                                        removeLastRow={removeLastRow}
                                        submitRowEntry={submitRowEntry}
                                        selectedGame={weekNumber}
                                        rows={rowsData}
                                        rowNumber={index}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <RenderPickEntry
                            gamesForWeek={gamesForWeek}
                            dateTime={dateTime}
                            pickLookup={pickLookup}
                            rowsData={rowsData[selectedGame]}
                            setRowsData={(updatedData) => {
                                setRowsData((prevRowsData) => ({
                                    ...prevRowsData,
                                    [selectedGame]: updatedData,
                                }));
                            }}
                            submitRowEntry={submitRowEntry}
                            selectedGame={selectedGame}
                            rows={rowsData}
                        />
                    )}
                </div>
            );
        });
    }
    return(
        <>
            <nav>
                <ul>
                    <li>GAME DETAILS</li>
                    <li>MY PICKS</li>
                    <li>STANDINGS</li>
                    <li>SETTINGS</li>
                </ul>
            </nav>
            <section style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div style={{flex:1, width:'45%', margin:'0 auto'}}>
                    <h2>Commissioner's Notes</h2>
                    <p>{props.gameData?.commissionerNotes}</p>
                </div>
            </section>
            <section>
                <h2>Pool Details</h2>
                <RenderGameHome/>
                <RenderMyPicks/>
            </section>
            <section>
                <h2>Pool Settings</h2>
                <ul>
                <li>Pool Duration - {gameData?.pool?.poolDuration}</li>
                <li>Starting Week - {gameData?.pool?.startingWeek}</li>
                <li>Picks Per Week - {gameData?.pool?.picksPerWeek}</li>
                <li>Pick Deadline - {gameData?.pool?.pickDeadline}</li>
                <li>Against the Spread/Straight Up - {gameData?.pool?.spreadBet}</li>
                <li>Spread Release: - {gameData?.pool?.spreadBet}</li>
                <li>Max Entries - {gameData?.pool?.maxEntries}</li>
                <li>Weekly Tiebreaker - {gameData?.pool?.tiebreaker}</li>
                <li>Scoring</li>
                </ul>
            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    games: state.games,
    game: state.game,
    user: state.user,
    sportsData: state.sportsData,
    seasonData: state.seasonData,
    nflTeams: state.nflTeamsData,
    seasonBasicsData: state.seasonBasicsData,
});

export default connect(mapStateToProps)(PickemPoolGameDetails);