import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useSurvivorGamesContext } from '../context/SurvivorGamesContext';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import { getTeamLogo } from '../../../utils/SportsData';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import TiebreakerInfo from '../../GamePageComponents/TiebreakerInfo/TiebreakerInfo';
import './SurvivorLeaderboard.scss';
import FilterSwitch from '../../GamePageComponents/FilterSwitch/FilterSwitch';


const SurvivorLeaderboard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const { 
        theCurrentInterval, 
        teams,
        schedule,
        totalEntriesEliminated,
        totalEntriesAlive,
        firstInterval,
        lastInterval,
    } = useSurvivorGamesContext();

    const {
        gameId,
        gameData,
        league,
        myUserId,
        intervalInfo,
        leaderboardData,
        allUsersEntries,
        usersIndexesInThisPool,
    } = useUserAndGameContext();
    //console.log("MY USER ID", myUserId);

    const [userStatsOpen, setUserStatsOpen] = useState(false);
    const [userStatsInfo, setUserStatsInfo] = useState(null);
    const [hostFilter, setHostFilter] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const hostInfo = gameData?.gameParameters?.hostInfo;

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    function hasGameStarted (gameId) {
        const gameTime = schedule?.find(game => game.GlobalGameID === gameId)?.DateTime;
        const dateTimeInNewYork = DateTime.fromISO(gameTime).setZone('America/New_York');
        return timeNowInNewYork > dateTimeInNewYork;
    }
    const usersEntries = leaderboardData?.filter(entry => entry.userId === myUserId);
    //console.log("Users Entries", usersEntries);

    // Get entries for this pool
    const entriesInThisPool = usersIndexesInThisPool
        ?.filter(index => index >= 0 && index < allUsersEntries?.length)
        ?.map(index => allUsersEntries[index]);
    //console.log("Entries in This Pool", entriesInThisPool);

    const findUserEntry = (entryName) => {
        return entriesInThisPool?.find(entry => entry.name === entryName)?.pool?.picks || null;
    };

    // Find this intervals deadline time from intervalInfo
    const currentIntervalDeadline = intervalInfo?.info?.find(interval => interval.interval === Number(theCurrentInterval))?.deadline;
    //console.log("Current Interval Deadline", currentIntervalDeadline);
    const currentWeeksDeadlineDateTime = DateTime.fromISO(currentIntervalDeadline, { zone: 'America/New_York' });

    const [sortBy, setSortBy] = useState("rank");
    const [sortOrder, setSortOrder] = useState("asc");
    const [tiebreakerInfoOpen, setTiebreakerInfoOpen] = useState(false);

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Reset page when data, rows per page, or host filter changes
    useEffect(() => {
        setPage(0);
    }, [leaderboardData, rowsPerPage, hostFilter]);
    
    const handleSort = () => {
        setSortBy("rank");
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    }

    const sortedLeaderboard = leaderboardData?.sort((a, b) => {
        if (sortBy === "rank") {
            return sortOrder === "asc" ? a.rank - b.rank : b.rank - a.rank;
        }

        return "asc";
    });

    // Filter data based on hostFilter
    const filteredData = React.useMemo(() => {
        if (!sortedLeaderboard) return [];
        
        if (hostFilter && hostInfo?.hostIds) {
            // Include only users that are hosts or the current user
            return sortedLeaderboard.filter(user => 
                hostInfo.hostIds.includes(user.userId) || user.userId === myUserId
            );
        }
        
        return sortedLeaderboard;
    }, [sortedLeaderboard, hostFilter, hostInfo, myUserId]);
    
    // Paginate the data
    const paginatedData = filteredData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    
    const intervalsToShow =  Number(theCurrentInterval) - (Number(firstInterval) - 1);
    const highInterval = Number(theCurrentInterval);
    //console.log("Intervals to Show:", intervalsToShow);

    function findIntervalName (interval) {
        if (intervalInfo?.type === 'weeks') {
            return isMobile ? `Wk ${interval}` : `Week ${interval}`;
        } else if (intervalInfo?.type === 'date') {
            return intervalInfo?.info?.find(entry => entry.interval === interval)?.shortLabel;
        }
    }

    function findIntervalAbbreviation (interval) {
        if (intervalInfo?.type === 'weeks') {
            return `W${interval}`;
        } else if (intervalInfo?.type === 'date') {
            return intervalInfo?.info?.find(entry => entry.interval === Number(interval))?.abbreviation;
        }
    }
    
    return (
        <>
            <TiebreakerInfo />
            
            <Box className="survivor-entry-info-container">
                <Box className="survivor-entry-info">
                    <Box className="status-section">
                        <Typography 
                            variant="h6" 
                            className="status-header">
                            Entrants Alive
                        </Typography>
                        <Typography variant="body1" className="status-details">
                            {totalEntriesAlive}
                        </Typography>
                    </Box>
                    <Box className="status-section">
                        <Typography variant="h6" className="status-header">
                            Entrants Eliminated
                        </Typography>
                        <Typography variant="body1" className="status-details">
                            {totalEntriesEliminated}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {hostInfo && (
                <FilterSwitch
                    hostName={hostInfo.hostName}
                    hostFilter={hostFilter}
                    setHostFilter={setHostFilter}
                />
            )}

            <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
                <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={filteredData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={Math.min(page, Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1))}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows"
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiTablePagination-toolbar': {
                            color: '#002129',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                            color: '#002129',
                        }
                    }}
                />
            </Box>

            <TableContainer
                component={Paper}
                className="leaderboard-table-container"
                sx={{
                    maxWidth: `calc(515px + ${intervalsToShow * 58}px)`, // Limit the maximum width if needed
                    minWidth: isMobile ? 'auto' : '646px', // Set the minimum width
                    margin: '0 auto',
                    borderRadius: '0',
                }}
            >
                <Table sx={{ tableLayout: isMobile ? null : 'fixed' }}>
                {usersEntries?.length >= 1 && (
                    <TableHead sx={{ backgroundColor: '#F0F1F1' }}>
                        {usersEntries?.map((userEntry, userIndex) => {
                            const usersPicks = findUserEntry(userEntry?.username);
                            
                            return (
                                <TableRow key={userEntry.username}>
                                    <TableCell className="survivor-leaderboard-username-column"
                                        sx={{
                                            backgroundColor: '#F0F1F1',
                                            zIndex: 3,
                                        }}
                                        onClick={() => {
                                            setUserStatsOpen(true);
                                            setUserStatsInfo(userEntry);
                                        }}
                                    >
                                        <Typography
                                            className='survivor-leaderboard-username-text'
                                            sx={{ color: userEntry.isUserEliminated ? '#CC293C' : '#002129' }}
                                        >
                                            {userEntry?.username}
                                        </Typography>
                                        <Typography
                                            className='survivor-leaderboard-second-row-text'
                                            sx={{ color: userEntry.isUserEliminated ? '#CC293C' : '#002129' }}
                                        >
                                            Win Margin: {userEntry?.totalMargin ?? 0}
                                        </Typography>
                                    </TableCell>
                                    {[...Array(intervalsToShow)].map((_, weekIndex) => {
                                        const intervalNumber = highInterval - weekIndex;
                                        const pick = usersPicks?.find(pick => pick.interval === intervalNumber) || null;
                                        const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                                        // const isCurrentWeek = intervalNumber === intervalsToShow;
                                        // // Fixed comparison - this was comparing timeNowInNewYork with itself
                                        // const hasDeadlinePassed = timeNowInNewYork > /* some deadline timestamp */; 
                                        // const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;

                                        return (
                                            <TableCell
                                                key={weekIndex}
                                                sx={{
                                                    textAlign: 'center',
                                                    minWidth: isMobile ? '48px' : '58px',
                                                    width: isMobile ? '48px' : '58px',
                                                    padding: 0,
                                                }}
                                            >
                                                {pick ? (
                                                    <Box
                                                        className='survivor-leaderboard-pick-box'
                                                        sx={{
                                                            border: pick.result === 'win' ? '2px solid #00AA72' : (pick.result === 'loss' ? '2px solid #CC293C' : '1px solid #ccc'),
                                                            background: pick.result === 'win' ? '#CCEEE3' : ((pick.result === 'loss' && pick.teamId !== 'No Pick') ? '#F5D4D8' : '#f9f9f9'),
                                                        }}
                                                    >
                                                        {pick.teamId === 'No Pick' ? (
                                                            <CloseIcon className='survivor-leaderboard-close-icon' />
                                                        ) : (
                                                            <img
                                                                src={getTeamLogo(league, pick.teamId, teams)}
                                                                alt={`Team ${pick.teamId}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto',
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        className='survivor-leaderboard-pick-box'
                                                        sx={{
                                                            border: (userEntry?.isUserEliminated && userEntry?.intervalEliminated !== intervalNumber) ? '' : '1px solid #ccc',
                                                            backgroundColor: (userEntry?.isUserEliminated && userEntry?.intervalEliminated !== intervalNumber) ? 'transparent' : '#FFF',
                                                        }}
                                                    >
                                                        <Typography 
                                                            variant="body1" 
                                                            sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}
                                                        >
                                                            {userEntry?.isUserEliminated && userEntry?.intervalEliminated !== intervalNumber && intervalNumber === intervalsToShow ? findIntervalAbbreviation(userEntry?.intervalEliminated) : ''}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableHead>
                )}
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                            <TableCell
                                className='survivor-leaderboard-table-header'
                                onClick={handleSort}
                                sx={{ zIndex: 3}}
                            >
                                STANDINGS
                            </TableCell>
                            {[...Array(intervalsToShow)].map((_, index) => (
                                <TableCell
                                    className='survivor-leaderboard-table-interval-columns'
                                    key={index}
                                >
                                    <div>
                                        {findIntervalName(highInterval - index)}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData?.map((user, userIndex) => (
                            <TableRow
                                key={`${user.userId}-${userIndex}`}
                                sx={{
                                    backgroundColor:
                                    user.userId === myUserId
                                        ? '#FFF6DA' // Highlight color for the user's row
                                        : hostInfo && hostInfo.hostIds && hostInfo.hostIds.includes(user.userId)
                                            ? hostInfo.hostBackgroundColor || '#FFF' // Host background color or default white
                                        : userIndex % 2 === 0
                                            ? '#FFFFFF' // White background for even rows
                                            : '#F0F1F1', // Grey background for odd rows
                                }}
                                >
                                <TableCell className="survivor-leaderboard-username-column"
                                    sx={{
                                        zIndex: 3,
                                    backgroundColor:
                                        user.userId === myUserId
                                            ? '#FFF6DA'
                                            : hostInfo && hostInfo.hostIds && hostInfo.hostIds.includes(user.userId)
                                                ? hostInfo.hostBackgroundColor || '#FFF'
                                            : userIndex % 2 === 0
                                                ? '#FFFFFF'
                                                : '#F0F1F1',
                                    }}
                                    onClick={() => {
                                        setUserStatsOpen(true);
                                        setUserStatsInfo(user); // Pass the entire row data
                                    }}
                                >
                                    <Typography
                                        className='survivor-leaderboard-username-text'
                                        sx={{ color: user.isUserEliminated ? '#CC293C' : '#002129' }}
                                    >
                                        {user.username}
                                    </Typography>
                                    <Typography
                                        className='survivor-leaderboard-second-row-text'
                                        sx={{ color: user.isUserEliminated ? '#CC293C' : '#002129' }}
                                    >
                                        Win Margin: {user.totalMargin ?? 0}
                                    </Typography>
                                </TableCell>
                                {[...Array(intervalsToShow)].map((_, weekIndex) => {
                                    const intervalNumber = highInterval - weekIndex;
                                    const userHaveALoss = user?.isUserEliminated || false;
                                    const userEliminationInterval = user?.intervalEliminated || null;
                                    const pick = user?.userId === myUserId ? findUserEntry(user?.username)?.find(pick => pick.interval === intervalNumber) : user?.picks?.find(pick => pick.interval === intervalNumber);
                                    //console.log("Pick", pick, user.username);
                                    const isCurrentWeek = intervalNumber === highInterval;
                                    const hasDeadlinePassed = timeNowInNewYork > currentWeeksDeadlineDateTime;
                                    const hasTheGameStarted = hasGameStarted(pick?.gameId);
                                    //console.log("Pick", pick, "Pick Time", pickTime, "Has Deadline Passed?", hasDeadlinePassed, "Time Now in New York:", timeNowInNewYork, "Current Weeks Deadline", currentWeeksDeadlineDateTime ,"Has Game Started?", hasGameStarted, "Is Current Week", isCurrentWeek, "High Interval", highInterval, "Interval Number", intervalNumber);

                                    return (
                                        <TableCell
                                            key={weekIndex}
                                            sx={{
                                                textAlign: 'center',
                                                minWidth: isMobile ? '48px' : '58px',
                                                width: isMobile ? '48px' : '58px',
                                                padding: 0,
                                            }}
                                        >
                                                <Box
                                                    className='survivor-leaderboard-pick-box'
                                                    sx={{
                                                        border: pick?.result === 'win' ? '2px solid #00AA72' : (pick?.result === 'loss' ? '2px solid #CC293C' : (userHaveALoss && userEliminationInterval !== intervalNumber) ? '' : '1px solid #ccc'),
                                                        background: pick?.result === 'win' ? '#CCEEE3' : ((pick?.result === 'loss' && pick?.teamId !== 'No Pick') ? '#F5D4D8' : (userHaveALoss && userEliminationInterval !== intervalNumber) ? 'transparent' : '#f9f9f9'),
                                                    }}
                                                >
                                                    {(isCurrentWeek && !hasDeadlinePassed && !hasTheGameStarted && pick?.teamId !== "No Pick" && (user?.userId !== myUserId)) || !pick ? (
                                                        <Box
                                                            className='survivor-leaderboard-pick-box'
                                                            sx={{ backgroundColor: (userHaveALoss && userEliminationInterval !== intervalNumber) ? 'transparent' : '#FFF'}}
                                                            >
                                                            <Typography 
                                                                variant="body1" 
                                                                sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: '700', color: '#CC293C' }}  // Example font size and color
                                                            >
                                                                {/* Your text here */}
                                                                {userHaveALoss && userEliminationInterval !== intervalNumber && intervalNumber === highInterval ? findIntervalAbbreviation(userEliminationInterval) : ''}
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        pick.teamId === 'No Pick' ? (
                                                            <CloseIcon className='survivor-leaderboard-close-icon' />
                                                        ) : (
                                                            <img
                                                                src={getTeamLogo(league, pick.teamId, teams)}
                                                                alt={`Team ${pick.teamId}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: 'auto',
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </Box>
                                            
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        
            <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
                <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={filteredData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={Math.min(page, Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1))}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows"
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiTablePagination-toolbar': {
                            color: '#002129',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                            color: '#002129',
                        }
                    }}
                />
            </Box>
        </>
    );
}

export default SurvivorLeaderboard;