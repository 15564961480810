import React from "react";
import { Box, Typography } from "@mui/material";
import BracketGameDesktop from "./BracketGameDesktop";
import BracketChamp from "./BracketChamp";
import "./FourTeamBracketDesktop.scss";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import TiebreakerTwoTextInput from "../../GamePageComponents/Tiebreakers/TiebreakerTwoTextInput";
import TiebreakerSingleInput from "../../GamePageComponents/Tiebreakers/TiebreakerSingleInput";

const ChampBracketDesktop = ({
    bracket,
    games,
    teams,
    league,
    handleGameClick,
    topLeft,
    bottomLeft,
    topRight,
    bottomRight,
    round1Ref,
    round2Ref,
    round3Ref,
    pickState,
    overrideTiebreaker,
    userTiebreaker,
}) => {
    const {
        // pickState,
        setPickState,
        hasJoinDeadlinePassed,
        intervalInfo,
    } = useUserAndGameContext();

    const firstFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topLeft}-${bottomLeft}`)?.teamId;
    const secondFinalist = pickState?.find(pick => pick.round === 5 && pick.bracket === `${topRight}-${bottomRight}`)?.teamId;

    return (
        // Add regional-bracket-desktop-container class for consistent navigation
        <Box className="final-four-container regional-bracket-desktop-container" sx={{marginLeft: '-15px'}}>
            <Box className="final-four regional-bracket-desktop" sx={{ marginTop: '35px'}}>

                {/*Championship Column*/}
                <Box
                    ref={round3Ref}
                    className="final-four-column final-four-column-games-second regional-bracket-desktop-column"
                    sx={{margin:'0 auto !important'}}
                >
                    <Box key={`round2-1`} className="final-four-game regional-bracket-desktop-game" sx={{ paddingLeft: '48px', margin: hasJoinDeadlinePassed ? '53px auto 270px auto' : '60px auto 270px auto' }}>
                        <BracketChamp
                            bracket={bracket}
                            round={6}
                            tournamentDisplayOrder={1}
                            pickState={pickState}
                            games={games}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                        />
                    </Box>
                      {/*Championship Column*/}
                    <Box className="final-four-column final-four-column-games-second regional-bracket-desktop-column" sx={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '24px', marginTop: hasJoinDeadlinePassed ? '-58px' : '-65px'}}>
                        
                        <Box ref={round2Ref}>
                            {Array.from({ length: 1 }, (_, index) => (
                                <Box key={`round2-${index}`} className="final-four-game regional-bracket-desktop-game" sx={{ marginLeft: '35px', marginRight: '12px'}}>
                                    <BracketGameDesktop
                                        bracket={bracket}
                                        round={6}
                                        tournamentDisplayOrder={1}
                                        games={games}
                                        teams={teams}
                                        league={league}
                                        handleGameClick={handleGameClick}
                                        hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                        topLeft={topLeft}
                                        bottomLeft={bottomLeft}
                                        topRight={topRight}
                                        bottomRight={bottomRight}
                                        pickState={pickState}
                                    />
                                </Box>
                            ))}
                        </Box>
                        
                    </Box>
                    <Box sx={{ marginTop: '-60px', marginLeft: '50px', width: '250px', justifyContent: 'center' }}>
                        <TiebreakerSingleInput
                            firstFinalist={firstFinalist}
                            secondFinalist={secondFinalist}
                            league={league}
                            teams={teams}
                            hasDeadlinePassed={hasJoinDeadlinePassed}
                            desktop={true}
                            overrideTiebreaker={overrideTiebreaker}
                            userTiebreaker={userTiebreaker}
                        />
                    </Box>
                </Box>
                <Box sx={{ minWidth: '75px', minHeight: '20px' }} />
            </Box>
        </Box>
    );
}

export default ChampBracketDesktop;