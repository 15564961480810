import React from 'react';
import { Box, Typography } from '@mui/material';

import PoolPartyTrophyGray from '../../assets/images/PoolPartyTrophyGray.svg';
import './LoadingScreen.scss';

const LoadingScreen = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      height="100vh"
      className="loading-screen"
      padding={2}
    >
      <img src={PoolPartyTrophyGray} alt="Loading" className="loading-screen__spinner" />
    </Box>
  );
};

export default LoadingScreen;
