import React, { useState} from "react";
import { Box, Modal, Button } from '@mui/material';
import './QuickPickNav.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";

const QuickPickNav = (props) => {
    const { clickState, setClickState, setSignUpState, doesUserBelong, isGameOver, userSubgroups, sponsorAbbreviation, unsavedPicks, setUnsavedPicks, userPicksFromDB, setPickState } = props;

    //console.log("Is Survivor Over: ", isGameOver, "User Subgroups Length: ", userSubgroups?.length);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);
    const [pendingPage, setPendingPage] = useState(null);

    const handleNavBarClick = (page) => {
        if (!unsavedPicks) {
            setClickState(page);
            if (page !== 'MY_PARTY') {
                setSignUpState(false);
            }
        } else {
            setPicksNotSavedModal(true);
            setPendingPage(page);
        }
    }


    return (
        <>
            <Box className={"quick-pick-nav"} style={{ margin: '0 auto'}}>
                <Box className={"quick-pick-nav-container"} style={{ width: '90%', maxWidth: doesUserBelong ? '960px' : isMobile ? '320px' : '575px' }}>
                    {doesUserBelong && (
                    <Box
                        className={`quick-pick-nav-item ${clickState === 'MY_PICKS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('MY_PICKS'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'MY_PICKS' ? '#FFC60A' : 'white' }}
                    >
                        My Picks
                    </Box>
                    )}
                    <Box
                        className={`quick-pick-nav-item ${clickState === 'LEADERBOARD' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('LEADERBOARD'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'LEADERBOARD' ? '#FFC60A' : 'white' }}
                    >
                        Leaderboard
                    </Box>
                    <Box
                        className={`quick-pick-nav-item ${clickState === 'POOL_PICKS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('POOL_PICKS'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'POOL_PICKS' ? '#FFC60A' : 'white' }}
                    >
                        Pool Picks
                    </Box>
                    {doesUserBelong && (
                    <Box
                        className={`quick-pick-nav-item ${clickState === 'REWARDS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('REWARDS'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'REWARDS' ? '#FFC60A' : 'white' }}
                    >
                        Rewards
                    </Box>
                    )}
                    {sponsorAbbreviation && (
                    <Box
                        className={`quick-pick-nav-item ${clickState === 'PRESENTED_BY' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('PRESENTED_BY'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'PRESENTED_BY' ? '#FFC60A' : 'white' }}
                    >
                        {sponsorAbbreviation}
                    </Box>
                    )}
                </Box>
            </Box>

            <Modal
                open={picksNotSavedModal}
                onClose={() => setPicksNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
                >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPicksNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickState(userPicksFromDB);
                                    setClickState(pendingPage);
                                    setPicksNotSavedModal(false);
                                    setUnsavedPicks(false);
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
}

export default QuickPickNav;
