import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCBBTeams, fetchCBBLeagueHierarchy, fetchNCAATournamentHierarchy } from '../../../redux/actions/dataImport';

// Create the context
const MarchMadnessBracketContext = createContext();

export const useMarchMadnessBracketContext = () => useContext(MarchMadnessBracketContext);
// Create the provider component
export const MarchMadnessBracketProvider = ({ children }) => {
    // Define the state variables
    const [bracketData, setBracketData] = useState(null);

    // Dispatch the Redux actions
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCBBTeams());
        dispatch(fetchCBBLeagueHierarchy());
        dispatch(fetchNCAATournamentHierarchy());
    }, [dispatch]);

    // Save the data to state
    const data = useSelector(state => state.data); // Replace 'state.data' with the actual path to your data in the Redux store
    useEffect(() => {
        setBracketData(data);
        console.log('Bracket Data:', data);
    }, [data]);

    // Define any other functions or state variables needed
    const bracketDataValues = {
        bracketData,
        setBracketData
    };

    // Return the provider with the context value
    return (
        <MarchMadnessBracketContext.Provider value={{ bracketDataValues }}>
            {children}
        </MarchMadnessBracketContext.Provider>
    );
};
