import React, {useState} from 'react';
import { useSportsBookContext } from './context/sportsBookContext';
// import './styles/styles.scss';
import nbaGamesByDate from './data/nbaTeamData.json'; // Import the JSON file
import { v4 as uuidv4 } from 'uuid';

const SportsBook = () => {
  const { betsToDisplay, setUserSelections, userSelections, checkParlay, checkTeaserConditions, parlayMessage, setParlayMessage,  teaserMessage,  setTeaserMessage,createDropdownValues } = useSportsBookContext();
  console.log(betsToDisplay);
  const [selectedBets, setSelectedBets] = useState([]);
  const newUUID = uuidv4();
//   const isBetSelected = (gameId, betType, homeOrAway) => {
//     return selectedBets.some(bet => bet.gameId === gameId && bet.betType === betType && bet.homeOrAway === homeOrAway);
//   };


  const formatNumber = (number) => (number >= 0 ? `+${number}` : `${number}`);
  const formatTimestamp = (timestamp) => {
    const options = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    weekday: 'short', // Specify 'short' for abbreviated day name
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
    return formattedDate;
    };
        const getWikipediaLogoUrl = (teamName) => {
        const team = nbaGamesByDate.find((team) => team.Name && teamName.includes(team.Name));
        console.log(team, teamName);
        return team ? team.WikipediaLogoUrl : null;
    };
    const toggleBetSelection = (checked, chosenBetType, homeOrAway) => {
    const uniqueId = `${checked.gameId}-${chosenBetType}-${homeOrAway}`;
    const isAlreadySelected = selectedBets.some(bet => bet === uniqueId);
    if (isAlreadySelected) {
        setSelectedBets(selectedBets.filter(bet => bet !== uniqueId));
        setUserSelections(prevSelections => prevSelections.filter(selection =>
            !(selection.GameID === checked.gameId && selection.BetType === chosenBetType && selection.HomeOrAway === homeOrAway)
        ));
    } else {
        setSelectedBets([...selectedBets, uniqueId]);
        // Your existing makePick logic here
    }
    };

    const makePick = (checked, chosenBetType, homeOrAway) => {
        console.log('CHECKED TEAM', checked);
        // toggleBetSelection(checked, chosenBetType, homeOrAway);
        let BetChoice = '';
        let individualBetId = newUUID;
        if(chosenBetType === 'SPREAD'){
            let homeAwayNum;
            if(homeOrAway === 'AWAY'){
                homeAwayNum = 0
            }
            if(homeOrAway === 'HOME'){
                homeAwayNum = 1
            }
            BetChoice = checked.BetType[chosenBetType][0][homeAwayNum];
        }
        if(chosenBetType === 'TOTAL'){
            let homeAwayNum;
            if(homeOrAway === 'OVER'){
                homeAwayNum = 0
            }
            if(homeOrAway === 'UNDER'){
                homeAwayNum = 1
            }
            BetChoice = checked.BetType[chosenBetType][0][homeAwayNum];
        }
        if(chosenBetType === 'MONEYLINE'){
            let homeAwayNum;
            if(homeOrAway === 'AWAY'){
                homeAwayNum = 0
            }
            if(homeOrAway === 'HOME'){
                homeAwayNum = 1
            }
            BetChoice = checked.BetType[chosenBetType][0][homeAwayNum];
        }
        console.log(BetChoice);

        const uniqueId = `${checked.gameId}-${chosenBetType}-${homeOrAway}`;
        const isAlreadySelected = selectedBets.includes(uniqueId);

        if (isAlreadySelected) {
            setUserSelections(prevSelections => prevSelections.filter(selection =>
                !(selection.GameID === checked.gameId && selection.BetType === chosenBetType && selection.HomeOrAway === homeOrAway)
            ));
        } else {
            setUserSelections(prevSelections => ([
                ...prevSelections,
                {
                    GameID: checked.gameId,
                    DateTime: checked.DateTime,
                    AwayTeamName: checked.AwayTeamName,
                    HomeTeamName: checked.HomeTeamName,
                    BetType: chosenBetType,
                    BetChoice: BetChoice.BetChoice,
                    Line: BetChoice.Line,
                    Odds: BetChoice.Odds,
                    DecimalOdds: BetChoice.DecimalOdds,
                    IndividualBetID: individualBetId,
                    Sport: checked.Sport,
                    League: checked.League,
                    LineChanged: false,
                    LineMovement: null,
                    OddsChanged: false,
                    OddsMovement: null,
                    lineInFavor: null,
                    oddsInFavor: null,
                    Wager: 0,
                    HomeOrAway: homeOrAway // Add this for unique identification
                }
            ]));
        }

        toggleBetSelection(checked, chosenBetType, homeOrAway);
        let updateParlayPostPick = checkParlay(userSelections);
        let updateTeaserPostPick = checkTeaserConditions(userSelections);
        console.log(updateParlayPostPick, updateTeaserPostPick);
        setParlayMessage(updateParlayPostPick);
        setTeaserMessage(updateTeaserPostPick);
        createDropdownValues();
    };

    const BetUi = () => (
    <div>
        <div style={{display:'flex', maxWidth: 800, margin:'0 auto', color:'#dbdbdb'}}>
            <p style={{width:225}}>GAME</p>
            <div style={{display:'flex', justifyContent:'space-evenly', width:'100%' }}>
                <p>SPREAD</p>
                <p>TOTAL</p>
                <p>MONEYLINE</p>
            </div>
        </div>

        {/* Map Bets */}
        {betsToDisplay.map((bet, index) => {

            //INDIVIDUAL BET IDS FOR EACH BET TYPE TO MAKE SURE ONLY ONE BET PER GAME IS SELECTED
            const betSpreadAwayId = `${bet.gameId}-SPREAD-AWAY`;
            const betSpreadHomeId = `${bet.gameId}-SPREAD-HOME`;
            const betOverId = `${bet.gameId}-TOTAL-OVER`;
            const betUnderId =  `${bet.gameId}-TOTAL-UNDER`;
            const betMoneyLineHomeId = `${bet.gameId}-MONEYLINE-HOME`;
            const betMoneyLineAwayId = `${bet.gameId}-MONEYLINE-AWAY`;

            return(
                <div className='bet-container' key={index}>
                    <div style={{display:'flex', alignItems:'center', marginBottom:10}}>
                        <p className='team-block' style={{color:'greenyellow', display:'flex', alignItems:'center'}}>
                            <img style={{display:'block', width:35, marginRight:10}} src={getWikipediaLogoUrl(bet.AwayTeamName)} alt={`Logo for ${bet.AwayTeamName}`} />{bet.AwayTeamName}</p>
                        <div className={`bet-block ${selectedBets.includes(betSpreadAwayId) ? 'bet-highlighted' : ''}`} onClick={() => makePick(bet, 'SPREAD', 'AWAY')}>
                            <p>{formatNumber(bet.BetType.SPREAD[0][0].Line)}  {formatNumber(bet.BetType.SPREAD[0][0].Odds)} </p>
                        </div>
                        <div className={`bet-block ${selectedBets.includes(betOverId) ? 'bet-highlighted' : ''}`}  onClick={()=> makePick(bet, 'TOTAL', 'OVER')}>
                            <p>O {bet.BetType.TOTAL[0][0].Line}  {formatNumber(bet.BetType.TOTAL[0][0].Odds)}</p>
                        </div>
                        <div className={`bet-block ${selectedBets.includes(betMoneyLineAwayId) ? 'bet-highlighted' : ''}`}   onClick={()=> makePick(bet, 'MONEYLINE', 'AWAY')}>
                            <p>{formatNumber(bet.BetType.MONEYLINE[0][0].Odds)}</p>
                        </div>
                    </div>

                    <div style={{display:'flex', alignItems:'center'}}>
                        <p className='team-block'  style={{color:'greenyellow', display:'flex', alignItems:'center'}}>
                            <img style={{display:'block', width:35, marginRight:10}} src={getWikipediaLogoUrl(bet.HomeTeamName)}  alt={`Logo for ${bet.HomeTeamName}`}/>{bet.HomeTeamName}</p>
                        <div className={`bet-block ${selectedBets.includes(betSpreadHomeId) ? 'bet-highlighted' : ''}`}
                            onClick={() => makePick(bet, 'SPREAD', 'HOME')}>
                            <p>{formatNumber(bet.BetType.SPREAD[0][1].Line)}  {formatNumber(bet.BetType.SPREAD[0][1].Odds)}</p>
                        </div>
                        <div className={`bet-block ${selectedBets.includes(betUnderId) ? 'bet-highlighted' : ''}`}  onClick={()=> makePick(bet, 'TOTAL', 'UNDER')}>
                            <p>U {bet.BetType.TOTAL[0][1].Line}  {formatNumber(bet.BetType.TOTAL[0][1].Odds)}</p>
                        </div>
                        <div className={`bet-block ${selectedBets.includes(betMoneyLineHomeId) ? 'bet-highlighted' : ''}`}  onClick={()=> makePick(bet, 'MONEYLINE', 'HOME')}>
                            <p>{formatNumber(bet.BetType.MONEYLINE[0][1].Odds)}</p>
                        </div>
                    </div>
                    <p style={{color:'greenyellow'}}>{formatTimestamp(bet.DateTime)}</p>

                </div>
            )
        })
    }
    </div>
  );

  return (
    <div>
      <h1>SportsBook </h1>
      <BetUi />
    </div>
  );
};

export default SportsBook;