import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import {getCurrentGameFromLocalStorage, fetchGameById} from '../../../redux/actions/gameImport';
import {fetchWeeklyGames, fetchSeason} from '../../../redux/actions/dataImport';
// import '../styles.scss';

const {useGameContext} = require('../context/gamePageContext');

 const GameDetails = (props) => {

    const { gameId } = useGameContext();
    const dispatch = useDispatch();
    console.log('game details', props.gameDetails);
    const [gameWeek, setGameWeek] = useState([]);
    // let selectedGames = gameDetails;
    // const gameDetailWeeks = selectedGames.map( (game, index) => {
    // const weekData = {
    //     weekNumber: game.Week,
    //     seasonYear: game.Season,
    //     // league: gameData.gameLeague
    //     league: 'nfl'
    // };
    // console.log('week data', weekData);
    // const weekGameData =  dispatch(fetchWeeklyGames(weekData));
    // console.log(weekGameData);
    // });
    // useEffect(() => {

    //     let data = {
    //         seasonYear:'2023',
    //         league: 'NFL'
    //       }

    //     const weekGameData = dispatch(fetchSeason(data));


    //     setGameWeek(weekGameData);

    //   }, [dispatch]);

    console.log('game week', gameWeek);
    return(<>
        <div className="game-details-container">
            hi
        </div>
    </>);
}

export default GameDetails;