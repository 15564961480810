import React from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useNavigate } from "react-router-dom";

const UnsavedPicksModal = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const {
        unsavedPicksModalOpen,
        setUnsavedPicksModalOpen,
        handleUnsavedPicks,
        urlForNav,
        setUnsavedPicks,
    } = useUserAndGameContext();

    const handleClose = () => {
        setUnsavedPicksModalOpen(false);
    }

    const handleLeaveClick = () => {
        handleUnsavedPicks();
        setUnsavedPicks(false);
        setUnsavedPicksModalOpen(false);
        navigate(urlForNav);
    }

    return (
        <Modal
            open={unsavedPicksModalOpen}
            onClose={handleClose}
            aria-labelledby="unsaved-picks-modal"
            aria-describedby="modal-showing-unsaved-picks-warning"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    width: '308px',
                    height: '206px',
                    padding: '16px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 'none', // Removes the default focus outline
                    boxShadow: 24, // Adds elevation to the modal
                }}
            >
                <Typography 
                    variant="h5" 
                    style={{ 
                        fontSize: '16px',
                        fontWeight: '800',
                        marginBottom: '20px' 
                    }}
                >
                    Unsaved Picks
                </Typography>
                <Typography
                    variant="h5"
                    style={{
                        fontSize: '16px',
                        fontWeight: '400',
                        marginBottom: '20px'
                    }}
                >
                    You have picks that have not been saved. If you leave this page, your picks will be lost.
                </Typography>
                
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => setUnsavedPicksModalOpen(false)}
                        sx={{ height: '36px', color: 'white', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' } }}
                    >
                        GO BACK
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleLeaveClick()}
                        sx={{ height: '36px', color: 'white', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' } }}
                    >
                        LEAVE PAGE
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UnsavedPicksModal;