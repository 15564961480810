import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

// Function to handle the POST request
export const useSaveTextOptIn = () => {
    return useMutation({
        mutationFn: submitTextOptInRequest,
        onError: (error) => {
        console.error('Error submitting text opt-in:', error);
        },
        onSuccess: (data) => {
        console.log('Text opt-in submitted successfully:', data);
        }
    });
};

const submitTextOptInRequest = async (optInData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/save-text-opt-in`, optInData, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });  
    console.log('Text opt-in submitted successfully:', response.data);
    return response.data.data;
};