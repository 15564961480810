import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import '../BetSlip.scss';
import GameInfoRow from "./GameInfoRow";
import ParlayTeaserWagerInput from "./ParlayTeaserWagerInput";
import ParlayTeaserInfo from "./ParlayTeaserInfo";
import TeaserPointsDropdown from "./TeaserPointsDropdown";

const TeaserBetBox = (
    {   
        bet, 
        removeBet, 
        pickState, 
        setPickState, 
        wagerState, 
        setWagerState, 
        bankrollExceeded, 
        toWinState, 
        setToWinState, 
        chosenBetStyle,
        selectedTeaserValue,
        setSelectedTeaserValue,
        teaserPointValue,
        setTeaserPointValue,
        teaserAmericanOdds,
        setTeaserAmericanOdds, 
        betOdds }) => {
    //console.log("PickState in Teaser Bet Box", pickState);

    // const [selectedTeaserValue, setSelectedTeaserValue] = useState('');
    // const [teaserPointValue, setTeaserPointValue] = useState('');
    // const [teaserAmericanOdds, setTeaserAmericanOdds] = useState(0);

    return (
        <Box className="sportsbook-staight-bet-container">

            <TeaserPointsDropdown 
                pickState={pickState} 
                selectedTeaserValue={selectedTeaserValue}
                setSelectedTeaserValue={setSelectedTeaserValue} 
                teaserPointValue={teaserPointValue}
                setTeaserPointValue={setTeaserPointValue} 
                teaserAmericanOdds={teaserAmericanOdds}
                setTeaserAmericanOdds={setTeaserAmericanOdds}
                />

             {/* Add a box around the mapped items and make it scrollable */}
            <Box className="picks-container">
                {pickState?.map((pick, index) => (
                    <GameInfoRow 
                        key={index} 
                        bet={pick} 
                        removeBet={removeBet}
                        chosenBetStyle={"teaser"}
                        teaserPointValue={teaserPointValue}
                    />
                ))}
            </Box>
            <Box className="spacer" />
            <ParlayTeaserInfo 
                chosenBetStyle={chosenBetStyle} 
                pickState={pickState} 
                betOdds={teaserAmericanOdds}
                teaserPoints={teaserPointValue} 
            />
            <ParlayTeaserWagerInput 
                wagerState={wagerState} 
                setWagerState={setWagerState} 
                bankrollExceeded={bankrollExceeded} 
                betOdds={teaserAmericanOdds} 
                toWinState={toWinState} 
                setToWinState={setToWinState}
                pickState={pickState}    
            />
        </Box>
    )
}

export default TeaserBetBox;