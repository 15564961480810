import React, { useState } from 'react';
import { useBirdieContext } from '../context/BirdiePoolContext';
import PrivateGroupHomePage from './PrivateGroupHomePage';
import SubGroupCreation from './SubGroupCreation';  
import PrivateCommissioner from './PrivateCommissioner';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "./PrivateGroup.scss";

const BirdiePrivateGroup = ({ handleClickState }) => {
    const { groupState, headerDisplayName } = useBirdieContext();
    const [signUpState, setSignUpState] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
        {!groupState || (groupState === headerDisplayName) ? (
            <div>
                {signUpState === false ? (
                <>
                <PrivateGroupHomePage />
                <Button 
                className="button" 
                variant="contained" 
                color="primary" 
                sx={{ marginBottom: isMobile ? '20px' : '200px' }}
                onClick={() => {
                setSignUpState(true);
                //console.log("Create Private Party");
                }}>CREATE A PARTY
            </Button>
            </>
                ) : (
                    <SubGroupCreation handleClickState={handleClickState} />
                )}
            </div>
        ) : (
            <>
            <PrivateCommissioner handleClickState={handleClickState}/>
            </>)}
        </>
    );
    }

export default BirdiePrivateGroup;