import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../context/BracketGamesContext';
import SweetSixteenBracket from '../../Brackets/SixteenTeamBracket/SweetSixteenBracket';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import TemplatedBracketNavBar from '../../NavigationBars/BracketNavigationBar/TemplatedBracketNavBar';
import UserSubmitBar from '../../GamePageComponents/SubmitBars/UserSubmitBar';
import { useSaveBracketPicks } from '../../../hooks/pools/useSaveBracketPicks';
import { useMediaQuery } from '@mui/material';
import isEqual from 'lodash/isEqual';
import GameNotReadyModal from '../../GameModals/GameNotReadyModal/GameNotReadyModal';
import BracketSubmissionModal from '../../GameModals/BracketGameModals/BracketSubmissionModal';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';

const MyBracketSweetSixteen = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMobile = useMediaQuery("(max-width:1350px)");
    const navigateToTop = useNavigateToTop();

    const {
        tournamentSchedule,
        teams,
    } = useBracketGamesContext();

    const {
        myUserId,
        masterPoolId,
        pickIndex,
        masterPoolData,
        gameAddress,
        gameId,
        league,
        pickState,
        originalPicks,
        setPickState,
        tiebreaker,
        setTiebreaker,
        originalTiebreaker,
        gameData,
        hasJoinDeadlinePassed,
        setUnsavedPicks,
        isUserInThisPool,
    } = useUserAndGameContext();

    const dispatch = useDispatch();

    const { mutate: saveBracketPicks } = useSaveBracketPicks();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [regions, setRegions] = useState(null);
    // const [bracket, setBracket] = useState(null);
    const [submitBarOpen, setSubmitBarOpen] = useState(false);
    const [regionCompleteModalOpen, setRegionCompleteModalOpen] = useState(false);
    const [preBracketModalOpen, setPreBracketModalOpen] = useState(false);
    const [submissionModalOpen, setSubmissionModalOpen] = useState(false);
    const [submissionData, setSubmissionData] = useState(null);

    const notReadyModal = masterPoolData?.notReadyModal;
    const showNotReadyModal = notReadyModal?.showModal;
    
    // Initialize roundDash from URL or default to 1
    const [roundDash, setRoundDash] = useState(() => {
        const navParam = searchParams.get('bracketNav');
        return navParam ? parseInt(navParam, 10) : 1;
    });

    const scrollToRound = (roundRef) => {
        if (!roundRef?.current) return;
        
        const gameElement = roundRef.current.querySelector('.regional-bracket-game');
        if (!gameElement) return;
        
        // Get element position
        const rect = gameElement.getBoundingClientRect();
        
        // Vertical scroll
        window.scrollTo({
            top: rect.top + window.pageYOffset - 150,
            behavior: 'smooth'
        });
        
        // Also handle horizontal scroll based on which round
        const container = document.querySelector('.regional-bracket-container');
        if (container) {
            // Find the column element that contains the game
            const columnElement = roundRef.current.closest('.regional-bracket-column');
            
            if (columnElement) {
                // Determine column position
                const columnRect = columnElement.getBoundingClientRect();
                
                // Calculate scroll position to center the column
                const containerRect = container.getBoundingClientRect();
                const centerOffset = (containerRect.width / 2) - (columnRect.width / 2);
                let targetScrollLeft = columnRect.left - containerRect.left - centerOffset + container.scrollLeft;
                
                // Special handling for round2Ref - add 30px to the scroll position
                if (roundRef === round2Ref) {
                    targetScrollLeft += 60;
                }
                
                // Scroll horizontally to center the column
                container.scrollTo({
                    left: Math.max(0, targetScrollLeft),
                    behavior: 'smooth'
                });
            } else {
                // If we can't find the column, reset to beginning
                container.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };
    
    // Special function for championship round
    const scrollToChampionship = () => {
        // Get the championship container and bracket container
        const champElement = document.querySelector('.regional-bracket-column-champion');
        const container = document.querySelector('.regional-bracket-container');
        
        if (champElement && container) {
            // First get the position of the championship element
            const rect = champElement.getBoundingClientRect();
            
            // Vertical scroll
            window.scrollTo({
                top: rect.top + window.pageYOffset + 250,
                behavior: 'smooth'
            });
            
            // Scroll all the way to the right for the championship column
            const scrollWidth = container.scrollWidth;
            container.scrollTo({
                left: scrollWidth - container.clientWidth,
                behavior: 'smooth'
            });
        }
    };
   

    // Use Effect to see if originalPicks is different from pickState
    // And if originalTiebreaker is different from tiebreaker
    // If so setSubmitBarOpen to true, otherwise false
    useEffect(() => {
        if (originalPicks && pickState) {
            const picksAreEqual = 
                originalPicks?.length === pickState?.length &&
                originalPicks?.every(originalPick =>
                    pickState?.some(currentPick => isEqual(originalPick, currentPick))
                );
                
            // Deep compare the tiebreaker arrays since they contain objects
            const tiebreakersAreEqual = isEqual(tiebreaker, originalTiebreaker);
            
            // Set submit bar to open if either picks or tiebreakers are different
            setSubmitBarOpen(!picksAreEqual || !tiebreakersAreEqual);
            setUnsavedPicks(!picksAreEqual || !tiebreakersAreEqual);
        }
    }, [originalPicks, pickState, tiebreaker, originalTiebreaker, setUnsavedPicks]);


    // Add refs and state for sticky navigation
    const observerRef = useRef(null);
    const containerRef = useRef(null);
    const [isStuck, setIsStuck] = useState(false);

    const round1Ref = useRef(null);
    const round2Ref = useRef(null);
    const round3Ref = useRef(null);
    const round4Ref = useRef(null);
    const round5Ref = useRef(null);
    const round6Ref = useRef(null);

    // Handle scroll for sticky navigation
    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (tournamentSchedule) {
            setRegions({
                topLeft: tournamentSchedule.LeftTopBracketConference,
                bottomLeft: tournamentSchedule.LeftBottomBracketConference,
                topRight: tournamentSchedule.RightTopBracketConference,
                bottomRight: tournamentSchedule.RightBottomBracketConference,
                finalFour: "Final Four",
            });
        }
    }, [tournamentSchedule]);

    // useEffect(() => {
    //     if (regions?.topLeft) {
    //         setBracket(regions.topLeft);
    //     }
    // }, [regions]);

    // Update URL when roundDash changes while preserving other params
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('bracketNav', roundDash.toString());
        setSearchParams(newParams);
    }, [roundDash, setSearchParams, searchParams]);

    const setRoundDashPosition = useMemo(() => {
        return {
            1: () => {
                if (round1Ref.current) {
                    scrollToRound(round1Ref);
                }
            },
            2: () => {
                if (round2Ref.current) {
                    scrollToRound(round2Ref);
                }
            },
            3: () => {
                if (round3Ref.current) {
                    scrollToRound(round3Ref);
                }
            },
            4: () => {
                if (round4Ref.current) {
                    scrollToRound(round4Ref);
                }
            },
            5: () => {
                if (round5Ref.current) {
                    scrollToChampionship();
                }
            }
        };
    }, [round1Ref, round2Ref, round3Ref, round4Ref, round5Ref]);

    // Use Effect to go back to top left on bracket change
    useEffect(() => {
        //console.log("Running Use Effect")
        if (round1Ref.current && regions) {
            setTimeout(() => {
                // Vertical scroll
                scrollToRound(round1Ref);
    
                // Add horizontal scroll reset
                const container = document.querySelector('.regional-bracket-container');
                if (container) {
                    container.scrollTo({
                        left: 0, // Reset to start of bracket
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    }, [regions]);
    

    // Update roundDash and bracket based on URL when component mounts
    useEffect(() => {
        //console.log("Round Dash Update: ", roundDash);
        const navParam = searchParams.get('bracketNav');
        if (navParam) {
            //console.log("Setting roundDash from URL: ", navParam);
            const roundNumber = parseInt(navParam, 10);
            setRoundDash(roundNumber);
            setRoundDashPosition[roundNumber]?.();
        } else {
            //console.log("Setting roundDash to 1");
            setRoundDash(1);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('bracketNav', '1');
            setSearchParams(newParams);
            setRoundDashPosition[1]?.();
        }
    }, [searchParams, setSearchParams, regions, setRoundDashPosition]);

    const tournamentGames = tournamentSchedule?.Games;

    const picksToMake = gameData?.gameParameters?.picksToMake ?? masterPoolData?.gameParameters?.picksToMake;
    const tiebreakerCount = gameData?.gameParameters?.tiebreakerCount ?? masterPoolData?.gameParameters?.tiebreakerCount;

    //const picksThisRegion = pickState?.filter(pick => pick.bracket === bracket)?.length;
    //const gamesThisRegion = tournamentGames?.filter(game => game.Bracket === bracket)?.length;

    const regionInfo = masterPoolData?.gameParameters?.regionInfo;

    const handleGameClick = (teamId, bracket, round, tournamentDisplayOrder, seedNumber, globalGameId) => {
        let newPicks = [...pickState];
        
        const existingPickIndex = newPicks.findIndex(pick => 
            pick.bracket === bracket && 
            pick.round === round && 
            (
                round <= 4 
                    ? pick.displayOrder === tournamentDisplayOrder 
                    : (round === 5 
                        ? (pick.displayOrder === tournamentDisplayOrder || pick.displayOrder === null) 
                        : pick.displayOrder === tournamentDisplayOrder)
            )
        );
        
        if (existingPickIndex !== -1 && newPicks[existingPickIndex].teamId === teamId) {
            return;
        }
        
        const newPick = {
            teamId,
            bracket,
            round,
            displayOrder: tournamentDisplayOrder,
            teamSeed: seedNumber,
            globalGameId
        };
        
        let finalPicks;
        if (existingPickIndex !== -1) {
            // Get the team that's being replaced
            const teamBeingReplaced = newPicks[existingPickIndex].teamId;
            
            // First, remove the existing pick from that position (to avoid duplicates)
            newPicks.splice(existingPickIndex, 1);
            
            // Then filter out any other instances of the team being replaced in later rounds
            finalPicks = newPicks.filter(pick => 
                pick.round < round || pick.teamId !== teamBeingReplaced
            );
            
            // Now add the new pick
            finalPicks.push(newPick);
            
            // Sort to maintain order
            finalPicks.sort((a, b) => {
                if (a.round !== b.round) return a.round - b.round;
                if (a.bracket !== b.bracket) return a.bracket.localeCompare(b.bracket);
                return (a.displayOrder ?? Infinity) - (b.displayOrder ?? Infinity);
            });
            
        } else {
            finalPicks = [...newPicks, newPick];
        }
        
        const getMaxPicksForRound = (roundNum) => {
            // Find in regionInfo by interval
            const picksThisRound = regionInfo.find(r => r.interval === roundNum)?.games;
            return picksThisRound;
        };
    
        const isChampionshipRound = round === 6;
        
        const previousRoundPicks = pickState?.filter(pick => 
            pick.round === round
        )?.length;
    
        const nextRoundPicks = finalPicks?.filter(pick => 
            pick.round === round
        )?.length;
    
        const maxPicksForRound = getMaxPicksForRound(round);
     
        // Update the state with the new picks
        setPickState(finalPicks);
    
        // Check if we've just completed a round and should scroll to the next round
        if (
            (isChampionshipRound && nextRoundPicks === maxPicksForRound) || 
            (!isChampionshipRound && nextRoundPicks === maxPicksForRound && previousRoundPicks < maxPicksForRound)
        ) {
            // Find the current interval information
            const currentInterval = regionInfo.find(r => r.interval === round);
            
            if (currentInterval) {
                // Find the next interval in the sequence
                const currentIndex = regionInfo.findIndex(r => r.interval === round);
                const nextInterval = regionInfo[currentIndex + 1];
    
                // Special handling for the last interval - scroll to championship
                if (!nextInterval) {
                    setTimeout(() => {
                        scrollToChampionship();
                    }, 300);
                    return;
                }
                
                if (nextInterval) {
                    // Special check for if this is the second-to-last interval (scrolling to championship)
                    const isLastInterval = currentIndex === regionInfo.length - 1;
                    
                    if (isLastInterval) {
                        setTimeout(() => {
                            scrollToChampionship();
                        }, 300);
                        return;
                    }
                    
                    // Map from roundDash values to round refs
                    const dashToRef = {
                        1: round1Ref,
                        2: round2Ref,
                        3: round3Ref,
                        4: round4Ref,
                        5: round5Ref
                    };
                    
                    // Get the ref for the next interval based on its roundDash value
                    const nextRoundRef = dashToRef[nextInterval.roundDash];
                    
                    if (nextRoundRef?.current) {
                        setTimeout(() => {
                            // Use the main scrollToRound function that includes the special handling for round2Ref
                            scrollToRound(nextRoundRef);
                        }, 300);
                    }
                }
            }
        }
    };


    const handleBracketSubmit = async () => {
        if (hasSubmitted) return;
        setHasSubmitted(true);
        const pickData = {
            userId: myUserId,
            masterPoolId: masterPoolId,
            index: Number(pickIndex),
            picks: pickState,
            ...(tiebreakerCount > 0 ? { tiebreaker } : {})
        };
    
        try {
            await saveBracketPicks(pickData, {
                onSuccess: (data) => {
                    //console.log("Picks submitted successfully:", data);
                    const checkerData = data.checkerData;
                    setSubmissionData(checkerData);
                    setSubmissionModalOpen(true);
                },
                onError: (error) => {
                    console.error("Error submitting picks:", error);
                    
                    // Check for token expiration (401 Unauthorized)
                    if (error?.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                        return;
                    }
                    
                    // Extract error message safely for other errors
                    const errorMessage = error?.response?.data?.message || "An unexpected error occurred";
                    console.log("Error Message:", errorMessage);
                
                    setSubmissionData({ message: errorMessage }); // Store just the message
                    setSubmissionModalOpen(true);
                }
            });
        } catch (error) {
            console.error("Error submitting picks:", error);
            
            // Check for token expiration (401 Unauthorized)
            if (error?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                return;
            }
            
            // Extract error message safely for other errors
            const errorMessage = error?.response?.data?.message || "An unexpected error occurred";
            console.log("Error Message:", errorMessage);    
    
            setSubmissionData({ message: errorMessage }); // Store just the message
            setSubmissionModalOpen(true);
        }
    };

    const handleCancel = () => {
        setPickState(originalPicks);
        setTiebreaker(originalTiebreaker);
    };

    const handleClearBracket = () => {
        setPickState([]);
        setTiebreaker([]);
    }

    const showCancelButton = (originalPicks?.length + originalTiebreaker?.length) > 0;

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    useEffect(() => {
        if (!isUserInThisPool) {
            navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home`);
        }
    }, [isUserInThisPool, gameAddress, masterPoolId, gameId, navigateToTop]);

    return (
        <Box>
            {!regions && tournamentGames?.length > 0 && !showNotReadyModal ? (
                <LoadingScreen />
            ) : (
                <>
                    {isMobile && !showNotReadyModal && (tournamentGames && tournamentGames?.length > 0) && (
                        <div style={{ position: 'relative', zIndex: 10 }}>
                            <div
                                ref={observerRef}
                                style={{
                                    height: '1px',
                                    position: 'relative',
                                }}
                            />
                            <div
                                ref={containerRef}
                                style={{
                                    height: '25px',
                                    backgroundColor: '#fff',
                                    width: '100%',
                                    position: isStuck ? 'fixed' : 'relative',
                                    top: isStuck ? '80px' : '0',
                                    marginBottom: '16px',
                                    zIndex: 10,
                                }}
                            >
                                <TemplatedBracketNavBar
                                    rounds={regions ? Object.values(regions).filter(Boolean) : []}
                                    roundDash={roundDash}
                                    setRoundDash={setRoundDash}
                                    setRoundDashPosition={setRoundDashPosition}
                                    pickState={pickState}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    regionInfo={regionInfo}
                                />
                            </div>
                        </div>
                    )}

                    <Box sx={{ position: 'relative' }}>
                        <SweetSixteenBracket
                            regions={regions}
                            games={tournamentGames}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            round1Ref={round1Ref}
                            round2Ref={round2Ref}
                            round3Ref={round3Ref}
                            round4Ref={round4Ref}
                            round5Ref={round5Ref}
                            setRegionCompleteModalOpen={setRegionCompleteModalOpen}
                            pickState={pickState}
                        />

                        {submitBarOpen && (
                            <UserSubmitBar 
                                picksToMake={picksToMake}
                                tiebreakerCount={tiebreakerCount}
                                handleSubmit={handleBracketSubmit}
                                handleCancel={handleCancel}
                                hasSubmitted={hasSubmitted}
                                handleClear={handleClearBracket}
                                showCancelButton={showCancelButton}
                            />
                        )}

                        {showNotReadyModal && (
                            <GameNotReadyModal 
                                modalOpen={preBracketModalOpen}
                                setModalOpen={setPreBracketModalOpen}
                                notReadyModal={notReadyModal}
                            />
                        )}

                        {submissionModalOpen && (
                            <BracketSubmissionModal
                                modalOpen={submissionModalOpen}
                                setModalOpen={setSubmissionModalOpen}
                                submissionData={submissionData}
                            />
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default MyBracketSweetSixteen;