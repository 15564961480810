import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import MyPoolsCard from './MyPoolsCard/MyPoolsCard';
import StaticPoolHeaderBar from '../PoolHeaderBar/StaticPoolHeaderBar';
import { useNFLCurrentWeek } from '../../hooks/nfl';
import { useCFBCurrentWeek } from '../../hooks/cfb';
import { useMyPoolsPageContext } from './context/MyPoolsPageContext';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import './MyPoolsPage.scss';
import MyInactivePools from './MyPoolsPages/MyInactivePools';
import MyActivePools from './MyPoolsPages/MyActivePools';

const MyPoolsPageContainer = () => {
    const { myUserId, userData, gamesData } = useMyPoolsPageContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const { mutate: fetchNFLCurrentWeek, data: nflCurrentWeek, isLoading: nflLoading, isError: nflError, error: nflErrorData } = useNFLCurrentWeek();
    const { mutate: fetchCFBCurrentWeek, data: cfbCurrentWeek, isLoading: cfbLoading, isError: cfbError, error: cfbErrorData } = useCFBCurrentWeek();

    const [myPoolsState, setMyPoolsState] = useState('active');

    const handleTabChange = (tab) => {
        setMyPoolsState(tab);
    };

    const userInactiveGames = userData?.Games?.filter(game => 
        game.pool?.dontShowInMyPools || game.settings?.dontShowInMyPools
    ).map(game => game.gameID || game.masterPoolId);
    
    //console.log("User Inactive Games:", userInactiveGames);
    
    const filteredGameData = gamesData?.filter(game => {
        if (myPoolsState === 'active') {
            // For active mode: Only show games not hidden by the webmaster
            return !game.dontShowInMyPools;
        } else if (myPoolsState === 'inactive') {
            // For inactive mode: Include games hidden by the webmaster or by the user
            return game.dontShowInMyPools || userInactiveGames?.includes(game.gameID);
        }
        return false; // Fallback (unlikely needed)
    });
    
    //console.log("Filtered Game Data:", filteredGameData);
    
  

  

    const userGames = userData?.Games?.filter(game =>
        myPoolsState === 'active' ? 
            !(game.pool?.dontShowInMyPools || game.settings?.dontShowInMyPools) 
            : true
    );
    //console.log("User Games:", userGames);

    

    const hasData = myUserId !== null;

    return (
        <>
            <StaticPoolHeaderBar navigationText="Home" headerText="My Pools" linkForNavigation="/" />
    
            <Box
                sx={{
                    marginTop: '32px',
                }}
            ></Box>    

            <Box 
                className="super-pickem-leaderboard-button-container"
                sx={{
                    marginBottom: '32px',
                }}    
            >
                <Button
                    className={`super-pickem-leaderboard-button ${myPoolsState === 'active' ? 'active' : ''}`}
                    onClick={() => handleTabChange('active')}
                >
                    Active
                </Button>

                <Button
                    className={`super-pickem-leaderboard-button ${myPoolsState === 'inactive' ? 'active' : ''}`}
                    onClick={() => handleTabChange('inactive')}
                >
                    Archive
                </Button>
            </Box>

            {myPoolsState === 'active' && (
                <MyActivePools />
            )}

            {myPoolsState === 'inactive' && (
                <MyInactivePools />
            )}
            
           
            <Box
                sx={{
                    marginTop: '32px',
                }}
            ></Box>  
        </>
  );
};

export default MyPoolsPageContainer;
