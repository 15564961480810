import React from "react";
import { Box, Typography } from "@mui/material";
import "./SponsorTab.scss";

const SponsorLinks = ({ sponsorLinks }) => {
  //console.log("Sponsor Links: ", sponsorLinks);

  return (
    <Box className="sponsor-tab-container">
      {/* Iterate over the dynamic categories in sponsorLinks */}
      {Object.entries(sponsorLinks)?.map(([category, links], index) => (
        <Box key={index} className="sponsor-tab-category">
          {/* Category Header */}
          <Typography className="sponsor-tab-header-text">
            {category}
          </Typography>

          {/* Links */}
          {links?.map((item, linkIndex) => (
            <Box key={linkIndex} className="sponsor-tab-link-box">
              <a
                href={item?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="sponsor-tab-catering-text"
              >
                {item?.message}
              </a>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default SponsorLinks;
