import React from 'react';
import { Box, Typography } from '@mui/material'; // Import Box and Typography from Material-UI
import './GameHomePageComponents.scss'; // Import the SCSS file

const HomePageHowTo = ({ handleRulesClick, howToPlayInfo }) => {
    //console.log(howToPlayInfo);
  return (
    <Box className="home-page-how-to">
      <Typography 
        variant="body1" 
        className="home-page-how-to-header"
      >
        How to Play
      </Typography>
      <Typography 
        variant="body1" 
        className="home-page-how-to-text"
      >
        {howToPlayInfo?.map((line, index) => (
          <span 
          key={index} 
          className={`home-page-how-to-text-block ${index === 0 ? 'no-top-margin' : ''}`}
        >
          {line}
        </span>
        ))}
        <span className="home-page-how-to-text-block">
          See the {' '}
          <span 
            className="home-page-how-to-rules-link" 
            onClick={handleRulesClick}
          >
            Rules
          </span> 
          {' '} for full details!
        </span>
      </Typography>
    </Box>
  );
}

export default HomePageHowTo;
