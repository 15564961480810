import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import '../NFLSurvivorStyles/SortByDropdown.scss'; 
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SortByDropdown = ({ sortBy, setSortBy }) => {
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        //console.log("Sort By:", event.target.value);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className="root">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '16px',
                    whiteSpace: 'nowrap',
                }}
            >
            <Typography 
                className="sort-by"
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: 500,
                    marginRight: '5px',
                }}  
            >
                Sort by:
            </Typography>
            <Select
                value={sortBy}
                onChange={handleSortByChange}
                variant="outlined"
                className="sort-by-select"
                sx={{
                    width: isMobile ? '136px' : '175px',
                    height: isMobile ? '24px' : '30px',
                    color: 'black',
                    '.MuiSelect-select': {
                        fontSize: isMobile ? '12px': '16px',
                        fontWeight: 500,
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                    },
                    '.MuiSvgIcon-root': {
                        color: 'black',
                        fontSize: '24px',
                    },
                }}
            >
                <MenuItem value="Game Time">Game Time</MenuItem>
                <MenuItem value="Largest Spread">Largest Spread</MenuItem>
            </Select>
            </Box>
        </div>
    );
}

export default SortByDropdown;
