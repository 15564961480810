import React from 'react';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch,connect } from 'react-redux';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { createGamesRequest } from '../../../redux/actions/gameImport';
import { useGameContext } from '../context/createGamesContext';
import BlockPoolChoices from '../createGamesAdditional/blockPoolChoices';
// import '../styles.scss';

const UserInviteForm = () => {
const { setGameMembers, } = useGameContext();

return(
    <>
        <p>Invite Members</p>
        <p>Enter the email addresses of the people you would like to invite to this game.  If you would like to invite more than one person, please seperate the email addresses with a comma.</p>
        <textarea type="input" onChange={(e) => setGameMembers(e.target.value)} placeholder='Enter email addresses here'/>

        {/*TODO: ADD OPTION FOR GRABBING SAME EMAILS FROM OTHER GAMES CREATED. */}
    </>
);
}

export default UserInviteForm;