import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MyPoolsPageContainer from '../components/MyPoolsPage/MyPoolsPageContainer';
import MyPoolsPageProvider from '../components/MyPoolsPage/context/MyPoolsPageContext';

const MyPoolsPage = () => {
  const queryClient = new QueryClient();

  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <MyPoolsPageProvider>
          <MyPoolsPageContainer />
        </MyPoolsPageProvider>
      </QueryClientProvider>
    </Box>
  );
}

export default MyPoolsPage;
