import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGetUserData, useGetGameHook, useCreateSubgroup } from '../../BirdiePool/hooks/index';
import { useGetSuperPickemPlayoffs } from '../../../hooks/superpickem/useGetSuperPickemPlayoffs';
import { useCFBTeams, useCFBNames, useGetCFBStadiums } from '../../../hooks/cfb/index';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { useGetPoolLeaderboard } from '../../../hooks/pools/useGetPoolLeaderboard';
import { DateTime } from 'luxon';
import { getWeeklyRound } from '../utils/getWeeklyRound';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

const CFBSuperPickemContext = createContext();

export const useCFBSuperPickemContext = () => {
    const context = useContext(CFBSuperPickemContext);
    if (!context) {
        throw new Error('useCFBSuperPickemContext must be used within a CFBSuperPickemProvider');
    }
    return context;
}

const CFBSuperPickemProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    console.log('myUserId', myUserId);

    const { mutate: fetchUserData, data: userData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchCFBTeams, data: cfbTeams } = useCFBTeams();
    const { mutate: fetchCFBNames, data: cfbNames } = useCFBNames();
    const { mutate: fetchCFBStadiums, data: cfbStadiums } = useGetCFBStadiums();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading, leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: fetchSuperPickemPlayoffs, data: superPickemPlayoffsData } = useGetSuperPickemPlayoffs();
    const { mutate: createSubgroup, data: createSubgroupData } = useCreateSubgroup();

    const { gameId, component, pageState } = useParams();

    const gameType = "SuperPickem";
    const league = "CFB";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    console.log("userData: ", userData);

     // Fetch game data second
     useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    console.log("gameData: ", gameData);
    const season = gameData?.season;

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
        }
    }, [fetchLeaderboardData, gameId, gameData])

    console.log("LEADERBOARD", leaderboardData);

    //Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);  
      

    // Error Handling for Leaderboard Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/404/pool-hall');
            }
        }
    }, [leaderboardData, leaderboardError, leaderboardErrorData,dispatch]);

    const [hasFetchedData, setHasFetchedData] = useState(false);

    // Use Effect to fetch all CFB Data
    useEffect(() => {
        if (gameData && !hasFetchedData && season) {
            fetchCFBTeams();
            fetchCFBNames();
            fetchCFBStadiums();
            fetchSuperPickemPlayoffs({ league, season });
            setHasFetchedData(true);
        }
    }, [fetchCFBTeams, fetchCFBNames, fetchSuperPickemPlayoffs, fetchCFBStadiums, gameData, hasFetchedData, league, season]);
    console.log("CFB Teams: ", cfbTeams, "CFB Names: ", cfbNames, "CFB Stadiums:", cfbStadiums, "Super Pickem Playoffs: ", superPickemPlayoffsData);

    const schedule = superPickemPlayoffsData?.schedule;
    const info = superPickemPlayoffsData?.info;
    const games = info?.games;
    const seeds = info?.seeds;
    const rounds = info?.rounds;
    const odds = superPickemPlayoffsData?.odds;
    
    let currentRound = getWeeklyRound(rounds);
    const highestRound = rounds?.length;
    console.log("Highest Round", highestRound);
    console.log("Current Round", currentRound);
    
    console.log("Schedule: ", schedule, "Games: ", games, "Seeds: ", seeds, "Rounds: ", rounds, "Odds: ", odds);

    const gameParameters = gameData?.gameParameters[0];
    const picking = gameParameters?.picking;
    const gameDescription = gameParameters?.gameDescription;
    const picksByInterval = gameParameters?.picksByInterval;


    // STATES
    const [pickState, setPickState] = useState([]);
    const [bracketState, setBracketState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(0);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState(gameId); // Use Effect this too?
    const [partyId, setPartyId] = useState(null);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    console.log("Pick State", pickState);

    let doesUserBelong = null;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    } else {
        doesUserBelong = false;
    }
    console.log("Does User Belong", doesUserBelong, "This Game", thisGame);

    // Use Effect to set interval once currentRound is set
    useEffect(() => {
        console.log("Setting Interval");
        if (currentRound) {
            setInterval(currentRound);
        }
    }, [currentRound]);


    useEffect(() => {
        console.log("Setting Pick State and Bracket State");
        if (doesUserBelong && thisGame) {
            const bracketPicks = thisGame.pool.bracketPicks;
            setBracketState(bracketPicks || []);
        }
    }, [doesUserBelong, thisGame]);


    const userSubgroups = userData?.subgroups || [];
    const referrals = userData?.referrals;
    const thisGamesReferrals = referrals?.find(referral => referral.gameId === gameId);
    console.log("Referrals: ", referrals, "This Game's Referral: ", thisGamesReferrals);

    const getReferralCounts = (gameReferrals) => {
        // Check if the input has the necessary structure
        if (!gameReferrals || !gameReferrals.referrals) {
            return { totalReferrals: 0, newSignUps: 0 };
        }
    
        const totalReferrals = gameReferrals?.referrals?.length;
        const newSignUps = gameReferrals?.referrals?.filter(referral => referral.newSignUp).length;
    
        return {
            totalReferrals,
            newSignUps
        };
    };

    const referralCounts = getReferralCounts(thisGamesReferrals);
    const { totalReferrals, newSignUps } = referralCounts;
    console.log("Referral Counts: ", totalReferrals, "New Sign Ups: ", newSignUps);
   

    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime).setZone('America/New_York');
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];

    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const userBracketPicksFromDB = thisGame?.pool?.bracketPicks || [];
    const stopPicksWarning = thisGame?.pool?.stopPicksWarning || false;
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards?.find(reward => reward.interval === 0);

    console.log("USer Picks From DB", userPicksFromDB, "User Bracket Picks From DB", userBracketPicksFromDB);

    // Utility function to check if defaultPartyIdInDB is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        return subgroups.some(subgroup => subgroup.subGroupID === partyId);
    };

    // Get the default party ID with the additional check
    let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
    ? thisGame.pool.defaultParty[0]
    : gameId;

    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB);


    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = sponsorName ? sponsorName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : sponsorName ? sponsorName : "Pool Party";
    }

    //Use effect for setting the interval and party state based on the component and page state
    useEffect(() => {
        console.log("Use Effect for Setting Interval and Party State");
        if (userData && gameData && component) {
            if (component === 'MY_PICKS') {
                if (pageState !== 'CURRENT') {
                    setInterval(Number(pageState));
                } else {
                    setInterval(currentRound);
                }

                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }

            if (component === 'LEADERBOARD' || component === 'POOL_PICKS') {
                if (pageState !== gameId) {
                    const pageStateInUserSubgroups = userSubgroups.find(subgroup => subgroup.subGroupID === pageState);
                    if (pageStateInUserSubgroups) {
                        setPartyId(pageState);
                        setPartyState(pageStateInUserSubgroups.name);
                    } else {
                        setPartyId(gameId);
                        setPartyState("Pool Party");
                        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`);
                    }
                }
            }

            if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
            }
        
            if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                    setPartyId(defaultPartyId);
                    setPartyState(defaultPartyName);
                    setSignUpState(true);
                } else if (pageState === "NEW") {
                    //console.log("Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);
                    setPartyId(lastSubgroupId);
                    setPartyState(lastSubgroupName);
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
                } else {
                    const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
                    setPartyId(pageState);
                    setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [userData, gameData, component, pageState, currentRound, defaultPartyId, firstSubgroupId, lastSubgroupId]);

    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    //Figure Out When Game is Over!!!!!!!!!!!!!!!!!
    const isGameOver = false;

    const nowInNewYork = DateTime.now().setZone('America/New_York');

    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin).setZone('America/New_York');

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;
    //console.log("Has Join Deadline Passed? CONTEXT", hasJoinDeadlinePassed, "Now In NY", nowInNewYork, "Last Day to Join", lastDayToJoinDateTime);

    //Use Effect to set loading to false (WHAT INFO GOES IN HERE?)
    useEffect(() => {
        if (gameData && cfbTeams && cfbNames && superPickemPlayoffsData && interval !== 0) {
            setLoading(false);
        }
    }, [gameData, cfbTeams, cfbNames, superPickemPlayoffsData]);

    const CFBSuperPickemContextValues = {
        myUserId,
        mainGameId: gameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        cfbTeams,
        cfbNames,
        cfbStadiums,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        totalReferrals,
        newSignUps,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup,
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
    }

    // ADD LOADING SCREEN HERE
    if (loading) {
        return <LoadingScreen />
    }


    return (
        <CFBSuperPickemContext.Provider value={CFBSuperPickemContextValues}>
            {children}
        </CFBSuperPickemContext.Provider>
    )
};

export default CFBSuperPickemProvider;