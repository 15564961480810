import React from "react";
import { Box, Typography, Button } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../BetSlip.scss";

const BetSlipHeader = ({ isNotDesktop, betSlipOpen, setBetSlipOpen, pickState }) => {
  return (
    <Box className="sportsbook-bet-slip-header">
      {/* Left Side: Circle with Number and Bet Slip Text */}
      <Box className="sportsbook-bet-slip-left-info">
        {/* Circle with Number */}
        <Box className="sportsbook-bet-slip-circle">
          <Typography className="sportsbook-bet-slip-circle-text">{pickState?.length}</Typography>
        </Box>

        {/* Bet Slip Text */}
        <Typography className="sportsbook-bet-slip-text">Bet Slip</Typography>
      </Box>

      {/* Right Side: Minimize Button with Icon */}
      {isNotDesktop && (
        <Button
          className="sportsbook-bet-slip-mobile-button"
          onClick={() => setBetSlipOpen(!betSlipOpen)}
          disableRipple // Disable button click ripple effect
        >
          <Typography className="sportsbook-bet-slip-text">
            {betSlipOpen ? "Minimize" : "View Bets"}
          </Typography>
          <KeyboardArrowDownIcon
            className={`sportsbook-bet-slip-mobile-toggle-icon ${betSlipOpen ? "open" : ""}`}
          />
        </Button>
      )}
    </Box>
  );
};

export default BetSlipHeader;
