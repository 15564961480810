import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import HomePageReferralLink from './HomePageReferralLink';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const ReferralRewards = ({
    mainGameId,
    gameAddress,
    myUserId,
    rewardsLeaderboard,
    rewardsText,
    hasJoinDeadlinePassed,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }


    const handleLogin = () => {
        let url = `/login/${gameAddress}/${uriEncoded(mainGameId)}/HOME/HOME`;
        navigateToTop(url);
    }

    const handleReferralLeaderboard = () => {
        //console.log("Navigate to Referral Leaderboard");
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/REFERRAL_LEADERBOARD/${uriEncoded(mainGameId)}`);
    }


    return (
        <Box
            sx={{
                padding: '20px 0px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#002129',
                    marginBottom: '16px',
                }}
            >
                REFERRAL REWARDS
            </Typography>

            <Box>
                {rewardsText?.map((reward, index) => (
                    <Box key={index}>
                        <Typography
                            sx={{
                                fontSize: isMobile ? '12px' : '16px',
                                color: '#002129',
                                marginBottom: '8px',
                            }}
                        >
                            <Box component="span" sx={{ fontWeight: 700 }}>
                                {reward.boldText}
                            </Box>{' '}
                            {reward.text}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '16px',
                    margin: '0 auto',
                    gap: isMobile ? '0px' : '16px',
                }}
            >
                {!hasJoinDeadlinePassed && (
                    <>
                        {!myUserId && (
                            <Button
                                sx={{
                                    width: '298px',
                                    fontSize: '14px',
                                    backgroundColor: '#FFC60A',
                                    color: '#002129',
                                    cursor: 'pointer',
                                    marginTop: '8px',
                                    boxShadow:
                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                                    '&:hover': {
                                        backgroundColor: '#FFC60A', // Keep the same color on hover
                                    },
                                }}
                                onClick={handleLogin}
                            >
                                LOG IN FOR REFERRAL INVITE LINK
                            </Button>
                        )}

                        {myUserId && (
                            <HomePageReferralLink
                                mainGameId={mainGameId}
                                gameAddress={gameAddress}
                                myUserId={myUserId}
                            />
                        )}
                    </>
                )}

                {rewardsLeaderboard && (
                    <Button
                        sx={{
                            width: '298px',
                            fontSize: '14px',
                            backgroundColor: '#00AAD1',
                            color: '#fff',
                            marginTop: '8px',
                            cursor: 'pointer',
                            boxShadow:
                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                            '&:hover': {
                                backgroundColor: '#00AAD1', // Keep the same color on hover
                            },
                        }}
                        onClick={handleReferralLeaderboard}
                    >
                        REFERRAL LEADERBOARD
                    </Button>
                )}
            </Box>


        </Box>
    )
}

export default ReferralRewards;