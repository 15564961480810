import React, { useState } from 'react';
import { Box, Typography, Modal, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useSaveTextOptIn } from '../../../hooks/pools/useSaveTextOptIn';

const TextAcceptanceModal = ({ joinOpen, setJoinOpen, status, logo, myUserId, gameId, preChecked }) => {
    const [isOptIn, setIsOptIn] = useState(preChecked || false);

    const { mutate, isLoading, isError, error, isSuccess } = useSaveTextOptIn();

    const handleClose = async () => {
        console.log("Has user opted in? ", isOptIn);
    
        if (!isOptIn) {
            // Reload the page if the user has not opted in
            window.location.href = window.location.href;
        } else {
            console.log("User has opted in for text messages");
    
            const data = {
                userId: myUserId,
                gameId: gameId,
            };
    
            try {
                await mutate(data, {
                    onSuccess: () => {
                        console.log("Successfully opted in for text messages");
                        setJoinOpen(false);
                        // Reload the page
                        window.location.href = window.location.href;
                    },
                    onError: (error) => {
                        console.log("Error opting in for text messages: ", error);
                        setJoinOpen(false);
                        // Reload the page
                        window.location.href = window.location.href;
                    }
                });
            } catch (error) {
                console.log("Error opting in for text messages: ", error);
                setJoinOpen(false);
                // Reload the page
                window.location.href = window.location.href;
            }
        }
    };
    


    return (
        <Modal
            open={joinOpen && status === "success"}
            onClose={() => setJoinOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: '308px',
                    maxHeight: '765px',
                    bgcolor: '#FFF', 
                    borderRadius: '8px', 
                    boxShadow: 24, 
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2, 
                    overflow: 'scroll'
                }}
            >
                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '20px',
                        fontWeight: 700,
                    }}
                >
                    Welcome!
                </Typography>

                <img 
                    src={logo} 
                    alt="logo" 
                    style={{
                        height: '112px',
                    }}
                />

                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '20px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    Would you like to opt-in for pick reminder text messages for this pool?
                </Typography>

                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '12px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    By selecting "Yes", you agree to receive text messages related to your game picks. Standard message and data rates may apply. You can opt-out at any time by replying STOP.
                </Typography>

                <Typography
                    sx={{
                        color: '#002129',
                        fontSize: '12px',
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    Pick reminder texts will only be sent on game days if your picks have not been submitted.
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isOptIn}
                            onChange={(e) => setIsOptIn(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={
                        <Typography
                            sx={{
                                fontWeight: 700, // Bold font
                            }}
                        >
                            Yes
                        </Typography>
                    }
                />
                
                <Button 
                    onClick={handleClose}
                    variant="contained"

                >
                    CONTINUE
                </Button>
            </Box>
        </Modal>
    );
}

export default TextAcceptanceModal;