import React from "react";

const DeletePropModal = ({ isOpen, onClose, prop, setPropsToAdd, propsToAdd, confirmClear, setConfirmClear, propFormat }) => {
    if (!isOpen || !prop) return null; // Only render the modal if it's open and a prop is selected

    const handleDelete = () => {
        const { propType, id } = prop;

        // Update propsToAdd by removing the selected prop by its propType and id
        const updatedProps = propsToAdd.map((group) => {
            if (group.propType === propType) {
                return {
                    ...group,
                    createdProps: group.createdProps.filter((item) => item.id !== id),
                };
            }
            return group;
        });

        setPropsToAdd(updatedProps); // Set the updated propsToAdd
        onClose(); // Close the modal
    };

    const handleCancel = () => {
        setConfirmClear(false);
        onClose();
    }

    const handleClear = () => {
        setPropsToAdd(propFormat);
        setConfirmClear(false);
        onClose();
    }

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
                <h2>Confirm Deletion</h2>
                {!confirmClear && (
                <>
                    <p>Are you sure you want to delete:</p>
                    <strong>{prop.propText}</strong>
                </>
                )}
                {confirmClear && (
                    <p>Are you sure you want to clear all props?</p>
                )}
                
                <div style={styles.buttonContainer}>
                    <button onClick={handleCancel} style={styles.cancelButton}>
                        Cancel
                    </button>
                    <button onClick={!confirmClear ? handleDelete : handleClear} style={styles.deleteButton}>
                        DELETE
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
    },
    modalContent: {
        background: "#fff",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "400px",
        textAlign: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    cancelButton: {
        backgroundColor: "#ccc",
        padding: "10px 20px",
        borderRadius: "4px",
        border: "none",
        cursor: "pointer",
    },
    deleteButton: {
        backgroundColor: "#d9534f",
        color: "#fff",
        padding: "10px 20px",
        borderRadius: "4px",
        border: "none",
        cursor: "pointer",
    },
};

export default DeletePropModal;
