import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import PrizesInfoBox from './PrizesInfoBox';

const ReferralsPrizes = ({ referralPrizes }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: isMobile ? '35px' : '75px',
                }}
            >
                <PrizesInfoBox prizes={referralPrizes?.prizeInfo[0]} />
                <PrizesInfoBox prizes={referralPrizes?.prizeInfo[1]} />
            </Box>

            <Box
                sx={{
                    maxWidth: isMobile ? '400px' : '475px',
                    margin: '0 auto',
                }}
            >
                {referralPrizes?.text.map((text, index) => (
                    <Typography
                        key={index}
                        sx={{
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            color: '#002129',
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}
                    >
                        {text}
                    </Typography>
                ))}
            </Box>
        </Box>
    );     
}

export default ReferralsPrizes;