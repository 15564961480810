import React, { useState, useEffect } from "react";
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@mui/material";
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ClubEmailModal = ({ setModalEmails, usersForList }) => {
    const { AllGameInfo, clubLeaderboardInfo } = useBirdieContext();
    const allClubUsers = AllGameInfo[0]?.leaderboardInfo;
    const myPartyUsers = clubLeaderboardInfo?.leaderboardInfo;
    //console.log("Club Email Modal, AllGameInfo:", AllGameInfo[0]?.leaderboardInfo, allClubUsers);
    //console.log("Club Email Modal, Club Leaderboard Info:", clubLeaderboardInfo?.leaderboardInfo, myPartyUsers);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [membersToEmail, setMembersToEmail] = useState([]);

    useEffect(() => {
        setModalEmails(membersToEmail.join(", "));
    }, [membersToEmail, setModalEmails]);

    

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        if (checked) {
            setMembersToEmail(prev => [...prev, name]);
        } else {
            setMembersToEmail(prev => prev.filter(member => member !== name));
        }
    };

    return (
        <Box sx={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc'  }}>
            <Table>
                <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: '#002129', zIndex: 1 }}>
                    <TableRow>
                        <TableCell sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}><Typography variant="h6">Member</Typography></TableCell>
                        <TableCell sx={{ color: 'white', fontSize: isMobile ? '14px' : '20px', fontWeight: '700' }}><Typography variant="h6">Invite</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersForList.map((user, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Typography>{user.userName}</Typography>
                                <Typography style={{ fontSize: '12px'}}>{user.email}</Typography>
                            </TableCell>
                            <TableCell>
                                <Checkbox 
                                    name={user.email} 
                                    onChange={handleCheckboxChange} 
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ClubEmailModal;
