import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useMyPoolsPageContext } from '../context/MyPoolsPageContext';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import MyPoolsCard from '../MyPoolsCard/MyPoolsCardNew';
import { useGetMyPoolsData } from '../../../hooks/pools/useGetMyPoolsData';

const MyActivePools = () => {
    const { myUserId, userData, gamesData } = useMyPoolsPageContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const [dataLoading, setDataLoading] = useState(true);

    const { mutate: fetchMyPoolsData, data: myPoolsData, loading: myPoolsLoading, error: myPoolsError } = useGetMyPoolsData();

    // Use Effect to get my pools data
    useEffect(() => {
        fetchMyPoolsData(myUserId);
        setDataLoading(false);
    }, [fetchMyPoolsData, myUserId]);
    //console.log("My Pools Data:", myPoolsData);

    const userActiveGames = userData?.Games?.filter(game =>
        game.masterPoolId && game.status === 'active'
    );
    //console.log("User Active Games:", userActiveGames, userData?.Games);

    if (!userActiveGames || userActiveGames?.length === 0) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                padding: '20px' 
            }}>
                <Typography variant="h6">No active pools found.</Typography>
            </Box>
        );
    }

    if (dataLoading) {
        return <LoadingScreen />;
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '20px', 
            width: '100%' 
        }}>
            {userActiveGames?.map((game, index) => (
                <MyPoolsCard 
                    key={index}
                    gameData={game}
                    myPoolsData={myPoolsData}
                />
            ))}
        </Box>
    );
};

export default MyActivePools;