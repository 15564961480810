import React, { useState } from "react";
import { Typography, Box, Button } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import './SuperPickemLeaderboard.scss';
import OverallLeaderboard from "./OverallLeaderboard";
import BracketLeaderboard from "./BracketLeaderboard";
import PickemLeaderboard from "./PickemLeaderboard";
import InfoIcon from '@mui/icons-material/Info';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModal';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { DateTime } from "luxon";
import { any } from "zod";

const SuperPickemLeaderboard = ({
    leaderboard,
    myUserId,
    hasBracketDeadlinePassed,
    teams,
    gatherLogo,
    tiebreakerInfo,
    gameAddress,
    mainGameId,
    selectedUserToView,
    setSelectedUserToView,
    userPicksFromDB,
    userBracketPicksFromDB,
    highestRound,
    firstBowlTime,
}) => {
    console.log("Leaderboard:", leaderboard);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const firstBowlTimeInNewYork = DateTime.fromISO(firstBowlTime).setZone('America/New_York');
    const hasFirstBowlStarted = timeNowInNewYork > firstBowlTimeInNewYork;
    console.log("Has Bracket Deadline Passed?", hasBracketDeadlinePassed);
    console.log("Has First Bowl Started?", hasFirstBowlStarted);

    const canShowPickemRanks = hasBracketDeadlinePassed || hasFirstBowlStarted;
    console.log("Can Show Pickem Ranks?", canShowPickemRanks);

    const location = useLocation();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();

    const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    let usersInfo = leaderboard?.find(user => user?.userId === myUserId) || {};
    console.log("usersInfo before update:", usersInfo);
    const usersChamp = usersInfo?.bracketPicks?.find(pick => pick.round === highestRound);
    
    // Ensure usersInfo is updated without overwriting the reference
    if (usersInfo) {
        usersInfo.bracketPicks = userBracketPicksFromDB;
        usersInfo.dailyPicks = userPicksFromDB;

        if (usersChamp) {
            usersInfo.champ = usersChamp.teamId;
            usersInfo.champResult = usersChamp.result;
        }
    } else {
      usersInfo = {
        bracketPicks: userBracketPicksFromDB,
        picks: userPicksFromDB,
      };
    }
    
    console.log("usersInfo after update:", usersInfo);
    

     // Initialize selectedTab based on URL query parameter
     const [selectedTab, setSelectedTab] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabParam = searchParams.get('tab');
        
        // Check if the tab parameter is valid (overall, bracket, or picks)
        const validTabs = ['overall', 'bracket', 'picks'];
        return validTabs.includes(tabParam) ? tabParam : 'overall';
    });

    const handleTabChange = (tab) => {
        // Update local state
        setSelectedTab(tab);

        // Update URL parameters
        const searchParams = new URLSearchParams(location.search);
        
        // If tab is overall, remove the tab parameter
        if (tab === 'overall') {
            searchParams.delete('tab');
        } else {
            searchParams.set('tab', tab);
        }

        // Update URL without triggering a page reload
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
    }
    

    function truncateUsername(username) {
        // Determine character count based on screen width
        let charCount;
        const screenWidth = window.innerWidth;
    
        if (screenWidth < 400) {
            charCount = 14; // Small screens
        } else if (screenWidth < 500) {
            charCount = 20; // Medium screens
        } else {
            charCount = 30; // Large screens
        }
    
        // Truncate username if it exceeds charCount
        if (username?.length > charCount) {
            return username.slice(0, charCount) + '...';
        }
        return username;
    }

    const handleUsernameClick = (userId, clickType) => {
        //console.log('Clicked on user:', userId, clickType);
        if (userId !== myUserId) {
            const user = leaderboard.find(user => user.userId === userId);
            //console.log("User:", user);
            setSelectedUserToView(user);
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/BRACKET/${uriEncoded(userId)}`);
            //console.log("Navigate to user's bracket");
        } else {
            //console.log("Navigate to my bracket");
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/MY_BRACKET/${uriEncoded(mainGameId)}`);
        }
    }


    const calculateRankForNewUsers = (leaderboard, rankKey, pointsKey) => {
        if (!leaderboard || leaderboard.length === 0) return 'N/A';
    
        // Check if any users have zero points
        const anyZeroPoints = leaderboard.some(user => user[pointsKey] === 0);
        console.log("Any Zero Points?", anyZeroPoints, rankKey, pointsKey);
    
        // Find users without a rank
        const usersWithoutRank = leaderboard.filter(user => user[rankKey] === undefined).length;
    
        // Find the highest existing rank
        const highestRank = leaderboard.reduce((maxRank, user) => {
            if (user[rankKey] !== undefined) {
                return Math.max(maxRank, user[rankKey]);
            }
            return maxRank;
        }, -Infinity);
    
        // Logic matching your original implementation
        if (anyZeroPoints) {
            return `T${highestRank}`;
        } else if (usersWithoutRank > 1) {
            return `T${leaderboard.length - usersWithoutRank + 1}`;
        } else {
            return `${leaderboard.length}`;
        }
    };
    
    // Usage would look like:
    const overallRankPlaceholder = calculateRankForNewUsers(leaderboard, 'overallRank', 'totalPoints');
    const bracketRankPlaceholder = calculateRankForNewUsers(leaderboard, 'bracketRank', 'bracketPoints');
    const pickemRankPlaceholder = calculateRankForNewUsers(leaderboard, 'dailyPicksRank', 'dailyPicksPoints');

    console.log("Overall Rank Placeholder:", overallRankPlaceholder, "Bracket Rank Placeholder:", bracketRankPlaceholder, "Pickem Rank Placeholder:", pickemRankPlaceholder);
    return (
        <>
         <Box className="super-pickem-leaderboard-button-container">

            <Button
                className={`super-pickem-leaderboard-button ${selectedTab === 'overall' ? 'active' : ''}`}
                onClick={() => handleTabChange('overall')}
            >
                Super Pick 'Em
            </Button>

            <Button
                className={`super-pickem-leaderboard-button ${selectedTab === 'bracket' ? 'active' : ''}`}
                onClick={() => handleTabChange('bracket')}
            >
                Bracket
            </Button>

            <Button
                className={`super-pickem-leaderboard-button ${selectedTab === 'picks' ? 'active' : ''}`}
                onClick={() => handleTabChange('picks')}
            >
                Pick 'Em
            </Button>

        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0 auto',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '0px',
                marginBottom: '12px',
                cursor: 'pointer',
            }}
            onClick={() => setTiebreakInfoOpen(true)}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '12px' : '18px',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    color: '#00AAD1',
                    marginRight: '5px',
                    marginTop: '10px',
                }}
            >
                Tiebreaker Info
            </Typography>
            <InfoIcon
                sx={{
                    color: '#00AAD1',
                    fontSize: isMobile ? '16px' : '20px',
                }}
            />
        </Box>

        {selectedTab === 'overall' && (
            <OverallLeaderboard
                usersInfo={usersInfo}
                hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                teams={teams}
                gatherLogo={gatherLogo}
                leaderboard={leaderboard}
                myUserId={myUserId}
                truncateUsername={truncateUsername}
                handleUsernameClick={handleUsernameClick}
                canShowPickemRanks={canShowPickemRanks}
                overallRankPlaceholder={overallRankPlaceholder}
                bracketRankPlaceholder={bracketRankPlaceholder}
                pickemRankPlaceholder={pickemRankPlaceholder}
            />
        )}

        {selectedTab === 'bracket' && (
            <BracketLeaderboard
                usersInfo={usersInfo}
                hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                teams={teams}
                gatherLogo={gatherLogo}
                leaderboard={leaderboard}
                myUserId={myUserId}
                truncateUsername={truncateUsername}
                handleUsernameClick={handleUsernameClick}
                bracketRankPlaceholder={bracketRankPlaceholder}
            />
        )}

        {selectedTab === 'picks' && (
            <PickemLeaderboard
                usersInfo={usersInfo}
                hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                leaderboard={leaderboard}
                myUserId={myUserId}
                truncateUsername={truncateUsername}
                handleUsernameClick={handleUsernameClick}
                canShowPickemRanks={canShowPickemRanks}
                pickemRankPlaceholder={pickemRankPlaceholder}
            />
        )}


        {tiebreakInfoOpen && (
            <TiebreakerModal
                tiebreakInfoOpen={tiebreakInfoOpen}
                setTiebreakInfoOpen={setTiebreakInfoOpen}
                tiebreakerInfo={tiebreakerInfo}
                handleRulesClick={handleRulesClick}
            />
        )}

</>

    )
}

export default SuperPickemLeaderboard;