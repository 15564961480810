// Team Records Function

// Record Config object that specifies which data source to use per league
const recordConfigs = {
    NCAAM: {
        source: 'teams',
        keys: {
            wins: 'Wins',
            losses: 'Losses'
        },
        idField: 'GlobalTeamID'
    },
    NFL: {
        source: 'standings',
        keys: {
            wins: 'OverallWins',
            losses: 'OverallLosses',
            ties: 'OverallTies'
        },
        idField: 'GlobalTeamID'
    },
    MLB: {
        source: 'standings',
        keys: {
            wins: 'Wins',
            losses: 'Losses'
        },
        idField: 'GlobalTeamID'
    }
    // Add other leagues as needed
};

/**
 * Gets team record based on league and team ID
 * @param {string} league - The sports league (NCAAM, NFL, etc.)
 * @param {number} teamId - The global team ID
 * @param {Object} teams - Teams data array
 * @param {Object} standings - Standings data array
 * @returns {Object|null} - Returns object with wins and losses or null if not found
 */
export const getTeamRecord = (league, teamId, teams, standings) => {
    // Early validation
    if (!league || !teamId) {
        return null;
    }
    
    // Get the config for this league
    const config = recordConfigs[league];
    if (!config) {
        return null;
    }
    
    // Determine which data source to use based on the league configuration
    let dataSource;
    if (config.source === 'teams') {
        dataSource = teams;
    } else if (config.source === 'standings') {
        dataSource = standings;
    } else {
        return null;
    }
    
    // Make sure the data source exists and is an array
    if (!dataSource || !Array.isArray(dataSource)) {
        return null;
    }
    
    // Find the team in the selected data source
    const team = dataSource.find(item => item[config.idField] === teamId);
    if (!team) {
        return null;
    }
    
    // Get the wins and losses using the configured key names
    const wins = team[config.keys.wins];
    const losses = team[config.keys.losses];
    const ties = team[config.keys.ties];
    
    // Only return a record if both wins and losses were found
    if (wins !== undefined && losses !== undefined) {
        return `${wins}-${losses} ${ties ? `-${ties}` : ''}`;
    }
    
    return null;
};