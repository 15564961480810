import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { set } from "lodash";

const TeamSelectionButtons = ({ team, setTeam, selectedGame }) => {    

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { homeLogo, awayLogo } = selectedGame;

    //const homeLogo = 'https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/26.png';
    //const awayLogo = 'https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/94.png';

    return (

        <Box>
  <Box
    display="flex"
    justifyContent="space-evenly"
    maxWidth={isMobile ? '300px' : '500px'}
    margin="15px auto"
  >
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            height: isMobile ? '43px' : '75px',
            padding: isMobile ? '8px' : '12px',
            borderRadius: '8px',
            backgroundColor: team === 'Away Schedule' ? '#E8E8E8' : 'transparent', // Conditional background color
            alignItems: 'center', // Center align items vertically
            cursor: 'pointer', // Pointer cursor for the entire container
            transition: 'background-color 0.3s ease', // Smooth transition for background color
        }}
        onClick={() => setTeam('Away Schedule')}
    >
        {/* Box to hold the Away Team Logo */}
        <Box
            sx={{
                width: isMobile ? '32px' : '50px',
                height: isMobile ? '32px' : '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: isMobile ? '8px' : '12px', // Margin between image and text
            }}
        >
            <img
                src={awayLogo}
                alt="Away Team Logo"
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                }}
            />
        </Box>
        <Typography
            component="span"
            sx={{
                display: 'block',
                padding: isMobile ? '8px 4px' : '12px 8px',
                borderRadius: '6px',
                color: '#002129',
                fontSize: isMobile ? '12px' : '18px',
                fontWeight: '700',
                whiteSpace: 'nowrap',
                textAlign: 'center',
            }}
        >
            Schedule
        </Typography>
    </Box>


    {/* Away Schedule */}
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            height: isMobile ? '43px' : '75px',
            padding: isMobile ? '8px' : '12px',
            borderRadius: '8px',
            backgroundColor: team === 'Home Schedule' ? '#E8E8E8' : 'transparent', // Conditional background color
            alignItems: 'center', // Center align items vertically
            cursor: 'pointer', // Pointer cursor for the entire container
            transition: 'background-color 0.3s ease', // Smooth transition for background color
        }}
        onClick={() => setTeam('Home Schedule')}
    >
        {/* Box to hold the Away Team Logo */}
        <Box
            sx={{
                width: isMobile ? '32px' : '50px',
                height: isMobile ? '32px' : '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: isMobile ? '8px' : '12px', // Margin between image and text
            }}
        >
            <img
                src={homeLogo}
                alt="Home Team Logo"
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                }}
            />
        </Box>
        <Typography
            component="span"
            sx={{
                display: 'block',
                padding: isMobile ? '8px 4px' : '12px 8px',
                borderRadius: '6px',
                color: '#002129',
                fontSize: isMobile ? '12px' : '18px',
                fontWeight: '700',
                whiteSpace: 'nowrap',
                textAlign: 'center',
            }}
        >
            Schedule
        </Typography>
    </Box>
  </Box>
</Box>

    );
}

export default TeamSelectionButtons;