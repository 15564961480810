import React from "react";
import { CardMedia  } from "@mui/material";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const PoolPreviewVideo = ({ poolPreviewVideo, doesUserBelong }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: doesUserBelong ? '#fff' : '#002129',
                padding: '16px 0px',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: doesUserBelong ? '#002129' : '#fff',
                    marginBottom: '16px',
                }}
            >
                POOL PREVIEW
            </Typography>

            <Box 
                className="game-home-page-video-container"
                sx={{
                    width: isMobile ? '192px' : '384px',
                    height: isMobile ? '294px' : '588px',
                }}
            >
                <CardMedia
                    component="video"
                    src={poolPreviewVideo}
                    allow="autoPlay"
                    controls
                    className="game-home-page-video"
                />
            </Box>
        </Box>
    );
}

export default PoolPreviewVideo;