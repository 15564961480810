import React, { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNFLSurvivorContext } from "../context/NFLSurvivorContext"; 
import "../NFLSurvivorStyles/SurvivorUserDash.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useParams } from "react-router-dom";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import SurvivorUserStatsModal from "../../GameModals/SurvivorModals/SurvivorUserStats/SurvivorUserStatsModal";
import { DateTime } from "luxon";

const SurvivorUserDash = ({ currentWeeksDeadlineDateTime }) => {

    const { 
      doesUserHaveALoss, 
      userPicksFromDB, 
      startingWeekState,
      setSignUpState, 
      NFLTeams,
      myUsername,
      lossWeek,
      losingGame, 
      gameAddress,
      gameType,
      gameData,
      mainGameId,
      myUserId,
      userData,
      doesUserBelong,
      joinOpen,
      setJoinOpen,
      firstWeek,
      hasJoinDeadlinePassed, 
    } = useNFLSurvivorContext();

    const { component } = useParams();

    const gameName = gameData?.gameName;
    const logo = gameData?.clubLogo;

    const [statsModalOpen, setStatsModalOpen] = useState(false);


    const sponsorInfo = gameData?.sponsorInfo;
    //console.log("Sponsor Info", sponsorInfo);
    const sponsorName = sponsorInfo?.sponsorName;
    const sponsorSecondaryLogo = sponsorInfo?.sponsorSecondaryLogo;
    //console.log("Sponsor Secondary Logo", sponsorSecondaryLogo);

  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const currentWeekAdjusted = startingWeekState < firstWeek ? firstWeek : startingWeekState;

    const currentWeekPick = userPicksFromDB?.find((pick) => pick.week === currentWeekAdjusted);
    //console.log("CURRENT WEEK PICK:", currentWeekPick);
    const currentPickLogo = NFLTeams?.find((team) => team.GlobalTeamID === currentWeekPick?.teamId)?.WikipediaLogoURL;
    //console.log("CURRENT PICK LOGO:", currentPickLogo);
    const losingPickLogo = NFLTeams?.find((team) => team.GlobalTeamID === losingGame?.teamId)?.WikipediaLogoURL;
    //console.log("LOSING PICK LOGO:", losingPickLogo, losingGame);

    // const nowInNewYork = DateTime.now().setZone('America/New_York');
    // // Define the date and time in New York timezone
    // const deadlineTimeInNewYork = DateTime.fromObject({
    //     year: 2024,
    //     month: 9, // September
    //     day: 8,
    //     hour: 13, // 1 PM in 24-hour format
    //     minute: 0,
    //     second: 0,
    //     millisecond: 0,
    // }, { zone: 'America/New_York' });
    // const hasJoinDeadlinePassed = nowInNewYork > deadlineTimeInNewYork;

    
  

  return (
    <>
    <Box className={"bracket-box-header"} sx={{ height: isMobile ? '87px' : '235px'}}>

        <Box sx={{ maxWidth: '1116px', margin: '0 auto', width: '100%' }}>
            <Box className="bracket-box-header-trophy" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <img className="bracket-box-trophy" src={bracketTrophy} alt='' />
                <Typography className={"bracket-box-header-username"} variant='h1' sx={{ display: 'flex', alignItems: 'center', fontSize: isMobile ? '16px' : '60px'}}>
                    {myUsername}
                    {doesUserBelong && (
                      <span 
                        style={{ 
                          color: '#00AAD1', 
                          fontSize: isMobile ? '12px' : '20px', 
                          fontWeight: 500, 
                          textDecoration: 'underline',
                          marginLeft: '16px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                        }}
                        onClick={() => setStatsModalOpen(true)}
                      >
                        My Picks
                      </span>
                    )}
                </Typography>
            </Box>
            {doesUserBelong && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: isMobile ? '250px' :'430px',
              margin: '0 auto',
              width:'90%'
            }}>
              {isMobile ? (
                // These items will be displayed on mobile devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>MY STATUS</Typography>
                    {!doesUserHaveALoss ? (
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ color: '#00AA72', fontWeight: '800', marginTop: '5px'}}>ALIVE</Typography>
                    ) : (
                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginTop: '9px'}}>ELIMINATED</Typography>
                    )}
                  </Box>
                  {!doesUserHaveALoss && (
                        <Box>
                            <Typography className={"bracket-box-header-headings"} variant='h4'>WEEK {currentWeekAdjusted} PICK</Typography>
                            <Box className="bracket-box-header-values">
                                {currentWeekPick ? (
                                    <img 
                                        src={currentPickLogo}
                                        alt="Team Logo" 
                                        style={{ 
                                          width: '24px', 
                                          height: '24px', 
                                          margin: '0 auto', 
                                          marginTop: '4px', 
                                          border: currentWeekPick?.result === "Win" ? '1px solid #00AA72' : '',
                                          backgroundColor: currentWeekPick?.result === "Win" ? 'rgba(0, 170, 114, 0.20)' :  ''
                                        }} 
                                    />
                                ) : (
                                    <Typography className={"bracket-box-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginTop: '5px'}}>NO PICK</Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    {doesUserHaveALoss && (
                        <Box>
                        <Typography className={"bracket-box-header-headings"} variant='h4'>ELIMINATION</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                          <Typography className={"bracket-box-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginRight: '8px'}}>
                            WEEK {lossWeek}
                          </Typography>
                          {losingPickLogo && (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                backgroundColor: 'rgba(204, 41, 60, 0.20)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '24px',
                                height: '24px'
                              }}
                            >
                              <img 
                                src={losingPickLogo} 
                                alt="Losing Team Logo" 
                                style={{ width: '24px', height: '24px' }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      
                    )}
                </>
              ) : (
                // These items will be displayed on desktop devices
                <>
                  <Box>
                    <Typography className={"bracket-box-header-headings"} variant='h4'>MY STATUS</Typography>
                    {!doesUserHaveALoss ? (
                    <Typography className={"bracket-box-header-values"} variant='h4' style={{ color: '#00AA72', fontWeight: '700', marginTop: '9px'}}>ALIVE</Typography>
                    ) : (
                    <Typography className={"bracket-box-header-values"} variant='h4' style={{color: '#CC293C', fontWeight: '700', marginTop: '10px'}}>ELIMINATED</Typography>
                    )}
                  </Box>
                    {!doesUserHaveALoss && (
                        <Box>
                            <Typography className={"bracket-box-header-headings"} variant='h4'>WEEK {currentWeekAdjusted} PICK</Typography>
                            <Box className="bracket-box-header-values">
                                {currentWeekPick ? (
                                    <img 
                                        src={currentPickLogo}
                                        alt="Team Logo" 
                                        style={{ 
                                          width: '44px', 
                                          height: '44px', 
                                          margin: '0 auto', 
                                          marginTop: '9px', 
                                          border: currentWeekPick?.result === "Win" ? '1px solid #00AA72' : '',
                                          backgroundColor: currentWeekPick?.result === "Win" ? 'rgba(0, 170, 114, 0.20)' :  ''
                                        }} 
                                    />
                                ) : (
                                    <Typography className={"bracket-box-header-values"} variant='h4' style={{ color: '#CC293C', marginTop: '9px', fontWeight: '700'}}>NO PICK</Typography>
                                )}

                        </Box>
                        </Box>
                    )}
                    {doesUserHaveALoss && (
                        <Box>
                        <Typography className={"bracket-box-header-headings"} variant='h4'>ELIMINATION</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                          <Typography className={"bracket-box-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '700', marginRight: '8px', marginTop: losingPickLogo ? '1px' : '2px' }}>
                            WEEK {lossWeek}
                          </Typography>
                          {losingPickLogo && (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                backgroundColor: 'rgba(204, 41, 60, 0.20)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '48px',
                                height: '48px'
                              }}
                            >
                              <img 
                                src={losingPickLogo} 
                                alt="Losing Team Logo" 
                                style={{ width: '48px', height: '48px' }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      
                    )}
                </>
              )}
            </Box>
            )}
            {!doesUserBelong && (
                !hasJoinDeadlinePassed ? (
                    <Button 
                    variant="contained" 
                    onClick={() => setJoinOpen(true)}
                    sx={{ 
                        fontSize: '14px', 
                        fontWeight: '700', 
                        width: '100px', 
                        height: '36px', 
                        whiteSpace: 'nowrap',
                        marginTop: isMobile ? '0px' : '24px'
                    }}
                    >
                    JOIN POOL
                    </Button>
                ) : (
                    <Typography 
                    variant="h6" 
                    sx={{ 
                        fontSize: isMobile ? '12px' : '24px', 
                        fontWeight: '700', 
                        marginTop: isMobile ? '6px' : '12px',
                        marginBottom: '12px',
                        color: '#CC293C'
                    }}
                    >
                    The pick deadline has passed. <br/> Pool entry is closed.
                    </Typography>
                )
            )}
          </Box>
      </Box>
      {statsModalOpen && (
        <SurvivorUserStatsModal 
          statsModalOpen={statsModalOpen} 
          setStatsModalOpen={setStatsModalOpen} 
          myUsername={myUsername}
          doesUserHaveALoss={doesUserHaveALoss}
          lossWeek={lossWeek}
          startingWeekState={startingWeekState}
          currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
          NFLTeams={NFLTeams}
          areTheseMyStats={true}
          usersPicks={userPicksFromDB}
          firstWeek={firstWeek}
        />
      )}
      {/* {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
            ponsorSecondaryLogo={sponsorSecondaryLogo}
            sponsorName={sponsorName}
        />
      )} */}
      </>
    );
}

export default SurvivorUserDash;