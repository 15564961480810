import './WeeklyWinnerDisplay.scss';
import { Box, Typography } from '@mui/material';
import trophyImage from '../../assets/images/Layer_1.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WeeklyWinnerDisplay = ({ whosInFirst, whosInFirstCharacterCount, selectedWeek}) => {
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    return (
    <Box
      className={`weekly-winner-display ${isMobile ? 'weekly-winner-display--mobile' : ''}`}
    >
      {/* Header */}
      <Box className="weekly-winner-display-header">
        <Typography
          className={`weekly-winner-display-header__text ${isMobile ? 'weekly-winner-display-header__text--mobile' : ''}`}
        >
          Top Week {selectedWeek} Performer
        </Typography>
      </Box>

      {/* Body */}
      <Box className="weekly-winner-display-body">
        <Box className="weekly-winner-display-content">
          {/* Left-side image */}
          <Box
            component="img"
            src={trophyImage}
            alt="Trophy Image"
            className="weekly-winner-display-image"
          />

          {/* Text in the middle */}
          <Box className="weekly-winner-display-text">
            <Typography
              className={`weekly-winner-display-text__title ${
                isMobile
                  ? whosInFirstCharacterCount > 17
                    ? 'weekly-winner-display-text__title--small-mobile'
                    : 'weekly-winner-display-text__title--mobile'
                  : ''
              }`}
            >
              {whosInFirst}
            </Typography>
          </Box>

          {/* Right-side image */}
          <Box
            component="img"
            src={trophyImage}
            alt="Trophy Image"
            className="weekly-winner-display-image"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WeeklyWinnerDisplay;
