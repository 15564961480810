import React from "react";
import { Box, Typography } from "@mui/material";
import "./SuperPickemBracket.scss";

const BracketTeamRow = ({ isOppositeSelected, seed, teamDisplay, teamDisplayCharacterCount, teamLogo, teamColor, isSelected, setIsSelected }) => {

    // const teamDisplay = "Ohio State (12-1)";
    // const teamDisplayCharacterCount = teamDisplay.length;
    // console.log("Team Display Character Count: ", teamDisplayCharacterCount);
    // const teamLogo = "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/98.png"
    // const teamColor = "#CC293C";
    // const isSelected = true;

    const hexToRgba = (hex, opacity) => {
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);
    
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };
    

    return (
        <Box 
            className="super-pickem-bracket-team-row" 
            style={{
                border: isSelected ? `3px solid ${teamColor}` : '',
                backgroundColor: isSelected ? `${hexToRgba(teamColor, 0.20)}` : '',
            }} 
            onClick={() => setIsSelected(!isSelected)}           
        >
            {/* First Part */}
            <Box className="super-pickem-bracket-team-seed"
                style={{
                    marginLeft: isSelected ? '3px' : '5px',
                }}
            >
                <Typography className="seed-number">
                    {seed}
                </Typography>
            </Box>

            {/* Second Part */}
            <Box 
    className="super-pickem-bracket-team-logo-placeholder"
    style={{
        position: 'relative', // Required for overlay positioning
    }}
>
    <img 
        src={teamLogo}
        alt="Team Logo"
        className="team-logo"
        style={{
            filter: isOppositeSelected ? 'grayscale(100%) opacity(70%)' : 'none', // Greyscale effect
        }}
    />
    {isOppositeSelected && (
        <Box 
            className="logo-overlay" 
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#D9D9D9',
                opacity: 0.5, // Semi-transparent overlay
                borderRadius: '4px', // Match the border radius of the logo placeholder
            }}
        />
    )}
</Box>

            {/* Third Part */}
            <Box className="super-pickem-bracket-team-name">
                <Typography 
                    className="team-name" 
                    style={{
                        color: isOppositeSelected ? '#B5B5B5' : teamColor,
                        fontSize: teamDisplayCharacterCount > 20 ? '10px' : teamDisplayCharacterCount > 16 ? '12px' : '14px',
                        lineHeight: teamDisplayCharacterCount > 20 ? '1.4' : teamDisplayCharacterCount > 16 ? '' : '1',
                    }}
                >
                    {teamDisplay}
                </Typography>
            </Box>
        </Box>
    );
};

export default BracketTeamRow;
