import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "../../../config";


export const useGetPublicPools = () => {
  return useQuery({
    queryKey: ['publicPools'],
    queryFn: fetchPublicPools,
    onError: (error) => console.error('Error fetching public pools:', error),
    // onSuccess: (data) => console.log('Fetched public pools data:', data)
  });
};


const fetchPublicPools = async () => {
  const response = await axios.get(`${apiUrl}/games/get-public-pools`);
  return response?.data;
};