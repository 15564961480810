import { current } from "@reduxjs/toolkit";
import React from "react";

const NFLPick5ResultLogic = () => {

    const gameResults = [
        {"GlobalGameID": 18672, "HomeScore": 31, "AwayScore": 17, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18669, "HomeScore": 33, "AwayScore": 27, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18671, "HomeScore": 24, "AwayScore": 23, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18471, "HomeScore": 24, "AwayScore": 23, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18676, "HomeScore": 31, "AwayScore": 17, "Status": "Cancelled", "IsClosed": true},
    ]
    

    const UserDB = {
        "user_id" : "b723f91e-a68b-4404-a605-0664f1e2e3da",
        "first_name" : "Eric",
        "last_name" : "Braithwaite",
        "user_name" : "BWaite3",
        "phone" : "+1 412 580 3277",
        "email" : "ebraithwaite3@gmail.com",
        "password" : "$2b$10$kQohDsVDUaPVlLdYLPDBZ.kHVVEyg2RKGOCxdURkd84piYe9KMWpK",
        "date_of_birth" : {
            "$date" : 465537600000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    "picks" : [
                        // {
                        //     "gameId" : 18944,
                        //     "teamId" : 21,
                        //     "spread" : -2.5,
                        //     "dateTime" : "2024-08-09T19:00:00",
                        //     "week" : 1,
                        //     "isHomeSelected" : true,
                        //     "result" : "Win"
                        // },
                        // {
                        //     "gameId" : 18945,
                        //     "teamId" : 2,
                        //     "spread" : 7,
                        //     "dateTime" : "2024-08-09T19:00:00",
                        //     "week" : 1,
                        //     "isHomeSelected" : false,
                        //     "result" : "Push"
                        // },
                        {
                            "gameId" : 18982,
                            "teamId" : 35,
                            "spread" : 5,
                            "dateTime" : "2024-08-10T12:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18983,
                            "teamId" : 22,
                            "spread" : -3,
                            "dateTime" : "2024-09-15T16:05:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18967,
                            "teamId" : 31,
                            "spread" : 1,
                            "dateTime" : "2024-08-18T20:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18963,
                            "teamId" : 19,
                            "spread" : -5,
                            "dateTime" : "2024-08-17T19:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18960,
                            "teamId" : 16,
                            "spread" : -7,
                            "dateTime" : "2024-08-17T16:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18958,
                            "teamId" : 7,
                            "spread" : 6.5,
                            "dateTime" : "2024-08-17T13:00:00",
                            "week" : 2,
                            "isHomeSelected" : false,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18690,
                            "teamId" : 3,
                            "spread" : -8,
                            "dateTime" : "2024-09-15T13:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18978,
                            "teamId" : 35,
                            "spread" : 4.5,
                            "dateTime" : "2024-08-25T20:00:00",
                            "week" : 3,
                            "isHomeSelected" : true,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18987,
                            "teamId" : 28,
                            "spread" : -6,
                            "dateTime" : "2024-08-24T13:00:00",
                            "week" : 3,
                            "isHomeSelected" : false,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18973,
                            "teamId" : 13,
                            "spread" : -6.5,
                            "dateTime" : "2024-08-24T13:00:00",
                            "week" : 3,
                            "isHomeSelected" : true,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18988,
                            "teamId" : 31,
                            "spread" : -5,
                            "dateTime" : "2024-08-23T22:00:00",
                            "week" : 3,
                            "isHomeSelected" : false,
                            "result" : "Pending"
                        },
                        {
                            "gameId" : 18970,
                            "teamId" : 15,
                            "spread" : -7,
                            "dateTime" : "2024-08-23T19:00:00",
                            "week" : 3,
                            "isHomeSelected" : false,
                            "result" : "Pending"
                        }
                    ],
                    "defaultParty" : "e708d4e4-4ab7-4dd4-9963-5c71132829f1",
                    "tiebreaker" : "52"
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [
                    {
                        "name" : "Eric PARTY",
                        "commissioner" : true,
                        "creator" : true,
                        "subGroupID" : "e708d4e4-4ab7-4dd4-9963-5c71132829f1"
                    },
                    {
                        "name" : "Eric 2Test",
                        "commissioner" : true,
                        "creator" : true,
                        "subGroupID" : "8927f4b1-ca17-4675-b28d-db7fcee7aa20"
                    },
                    {
                        "name" : "New Party For Marco Test",
                        "commissioner" : true,
                        "creator" : true,
                        "subGroupID" : "812d8e7d-61bb-4179-8ab2-4e72ddea477a"
                    }
                ]
            },
            {
                "gameID" : "97kcw983-7y45-2c90-l2x1-57dr3pmg7031",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [
                        {
                            "gameId" : 18982,
                            "teamId" : 35,
                            "spread" : 5,
                            "dateTime" : "2024-08-10T12:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win",
                            "margin" : 13
                        },
                        {
                            "gameId" : 18963,
                            "teamId" : 19,
                            "dateTime" : "2024-08-17T19:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Win",
                            "margin" : 7
                        },
                        {
                            "gameId" : 18732,
                            "teamId" : 9,
                            "dateTime" : "2024-09-26T20:15:00",
                            "week" : 4,
                            "result" : "Pending",
                            "isHomeSelected" : false
                        },
                        {
                            "gameId" : 18746,
                            "teamId" : 33,
                            "dateTime" : "2024-10-03T20:15:00",
                            "week" : 5,
                            "result" : "Pending",
                            "isHomeSelected" : false
                        }
                    ],
                    "defaultParty" : [
                        "6bfaac52-4a7d-4f85-8c00-d1dab134f1b5"
                    ]
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [
                    {
                        "name" : "Tests23",
                        "commissioner" : true,
                        "creator" : true,
                        "subGroupID" : "a0998da8-6db4-42d3-9d9e-2ac3484e4c10"
                    }
                ]
            },
            {
                "gameID" : "21pqx742-1r39-5y82-d3b0-92wk5izx1984",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [
                        {
                            "gameId" : 50013964,
                            "teamId" : 50000269,
                            "dateTime" : "2023-11-25T19:30:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Pending",
                            "conference" : "SEC"
                        },
                        {
                            "gameId" : 50013953,
                            "teamId" : 50000093,
                            "dateTime" : "2023-11-25T19:30:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Pending",
                            "conference" : "Big Ten"
                        },
                        {
                            "gameId" : 50014140,
                            "teamId" : 50000026,
                            "dateTime" : "2023-11-25T19:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Pending",
                            "conference" : "ACC"
                        },
                        {
                            "gameId" : 50014231,
                            "teamId" : 50000106,
                            "dateTime" : "2023-11-25T19:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win",
                            "conference" : "Big 12"
                        },
                        {
                            "gameId" : 50014618,
                            "teamId" : 50000104,
                            "dateTime" : "2024-09-07T12:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Pending",
                            "conference" : "Big Ten"
                        },
                        {
                            "gameId" : 50014641,
                            "teamId" : 50000271,
                            "dateTime" : "2024-09-07T19:00:00",
                            "week" : 2,
                            "isHomeSelected" : true,
                            "result" : "Pending",
                            "conference" : "SEC"
                        }
                    ],
                    "defaultParty" : [
                        "7e4539e3-de36-463b-866a-95fa33c1c9b5"
                    ]
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [
                    {
                        "name" : "Eric Test Party CFB",
                        "commissioner" : true,
                        "creator" : true,
                        "subGroupID" : "7e4539e3-de36-463b-866a-95fa33c1c9b5"
                    }
                ]
            }
        ],
        "__v" : 20,
        "forgotPasswordToken" : "6f134d97-0abf-456b-a1fa-9e2c8c2e9b65",
        "forgotPasswordTokenExpires" : "1723563853480"
    }

    // NFL PICK EM PICK NOTIFICATION EMAIL LOGIC
    // 1. First find the game in Azure DB, and return it's clubMembers object
    // 2. Find all of the users in the clubMembers object
    // 3. For each user, check if they have marketing_preferences.enable_pool_reminders set to true
    // 4. If they do NOT have it set to true, do not send them an email, so move on to the next user
    // 5. If they do have it set to true, check if they have made picks for the current week and how many.  THey should have 5 picks for the week
    // 6. If they have made less than 5 picks, push that user and the following information into an array with the other users who need to be notified
                // Information to gather, first_name, last_name, email, user_name, picks_left, current_week
                // Game Name? Game Logo?
    

    

    let membersToNotify = [];


    function gatherEmailInfoForPick5Notification(user, currentWeek) {
        const doesUserWantNotifications = user?.marketing_preferences?.enable_pool_reminders;
        console.log("Does User Want Notifications", doesUserWantNotifications);
        if (!doesUserWantNotifications) {
            console.log("User does not want notifications");
            return;
        }
        if (doesUserWantNotifications) {
            const game = user?.Games?.find(game => game.gameID === "39ade231-4d99-4y78-a3l3-83eq6kne8475");
            const picks = game?.pool?.picks || [];
            const currentWeekPicks = picks?.filter(pick => pick.week === currentWeek);
            const currentWeekPicksCount = currentWeekPicks?.length;
            const gamesRemaining = 5 - currentWeekPicksCount;
            if (gamesRemaining > 0) {
                membersToNotify.push({
                    fullName: user.first_name + " " + user.last_name,
                    email: user.email,
                    username: user.user_name,
                    picksLeft: gamesRemaining,
                    currentWeek: currentWeek
                });
            } else {
                console.log("User has made all picks for the week");
            }
            
        }
    }

    const emailInfo = gatherEmailInfoForPick5Notification(UserDB, 4);
    console.log("Email Info", emailInfo);
    console.log("Members To Notify", membersToNotify);




    // NFL SURVIVOR PICK NOTIFICATION EMAIL LOGIC
    // 1. First find the game in Azure DB, and return it's clubMembers object
    // 2. Find all of the users in the clubMembers object
    // 3. For each user, check if they have marketing_preferences.enable_pool_reminders set to true
    // 4. If they do NOT have it set to true, do not send them an email, so move on to the next user
    // 5. If they do have it set to true, check first if they have a loss, then check if they have made a pick for the current week
    // 6. If a user has a loss or has made their 1 pick for the week, do not send them an email, move on to the next user
    // 7. If the user is still alive and does NOT have 1 pick for the current week push the user and the following information into an array with the other users who need to be notified
                // Information to gather, first_name, last_name, email, user_name, current_week, deadline for pick
                // Game Name? Game Logo?

            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            const today = new Date();
            const dayOfWeek = today.getDay();

            // Calculate the number of days until the next Sunday
            const daysUntilSunday = (7 - dayOfWeek) % 7;

            // Get the date of this coming Sunday
            const thisComingSunday = new Date(today);
            thisComingSunday.setDate(today.getDate() + daysUntilSunday);

            // Format the date as "Sunday, August 25"
            const formattedSunday = `${daysOfWeek[thisComingSunday.getDay()]}, ${monthsOfYear[thisComingSunday.getMonth()]} ${thisComingSunday.getDate()}`;

            console.log("NFL Survivor Sunday Deadline Date:", formattedSunday); // Example output: "Sunday, August 25"





                let survivorMembersToNotify = [];


                function gatherEmailInfoForNFLSurvivorNotification(user, currentWeek) {
                    const doesUserWantNotifications = user?.marketing_preferences?.enable_pool_reminders;
                    console.log("Does User Want Notifications", doesUserWantNotifications);
                    if (!doesUserWantNotifications) {
                        console.log("User does not want notifications");
                        return;
                    }
                    if (doesUserWantNotifications) {
                        const game = user?.Games?.find(game => game.gameID === "97kcw983-7y45-2c90-l2x1-57dr3pmg7031");
                        const picks = game?.pool?.picks || [];
                        const doesUserHaveALoss = picks?.some(pick => pick.result === "Loss");
                        console.log("Does User Have A Loss", doesUserHaveALoss, picks);
                        if (doesUserHaveALoss) {
                            console.log("User has a loss");
                            return;
                        }
                        const currentWeekPick = picks?.filter(pick => pick.week === currentWeek);
                        const currentWeekPickCount = currentWeekPick?.length;
                        const gamesRemaining = 1 - currentWeekPickCount;
                        console.log("currentWeekPick", currentWeekPick, "currentWeekPickCount", currentWeekPickCount, "gamesRemaining", gamesRemaining);
                        if (gamesRemaining > 0) {
                            survivorMembersToNotify.push({
                                fullName: user.first_name + " " + user.last_name,
                                email: user.email,
                                username: user.user_name,
                                picksLeft: gamesRemaining,
                                currentWeek: currentWeek
                            });
                        } else {
                            console.log("User has made all picks for the week");
                        }
                        
                    }
                }
            
                const survivorEmailInfo = gatherEmailInfoForNFLSurvivorNotification(UserDB, 9);
                console.log("Survivor Email Info", survivorEmailInfo);
                console.log("Survivor Members To Notify", survivorMembersToNotify);
    



    // COLLEGE SURVIVOR PICK NOTIFICATION EMAIL LOGIC
    // 1. First find the game in Azure DB, and return it's clubMembers object
    // 2. Find all of the users in the clubMembers object
    // 3. For each user, check if they have marketing_preferences.enable_pool_reminders set to true
    // 4. If they do NOT have it set to true, do not send them an email, so move on to the next user
    // 5. If they do have it set to true, check first if they have 4 losses, then check if they have made a sum of 4 between losses and picks for the current week
    // 6. If a user has 4 losses or has made the correct number of picks for the week (based on losses), do not send them an email, move on to the next user
    // 7. If the user is still alive and does NOT have correct number of pick for the current week push the user and the following information into an array with the other users who need to be notified
                // Information to gather, first_name, last_name, email, user_name, current_week, deadline for pick, conferences with no picks?
                // Game Name? Game Logo?

                const daysOfWeekCFB = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const monthsOfYearCFB = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                const todayCFB = new Date();
                const dayOfWeekCFB = todayCFB.getDay();  // Use todayCFB.getDay() instead of today.getDay()

                // Calculate the number of days until the next Saturday
                const daysUntilSaturday = (6 - dayOfWeekCFB + 7) % 7;

                // Get the date of this coming Saturday
                const thisComingSaturday = new Date(todayCFB);
                thisComingSaturday.setDate(todayCFB.getDate() + daysUntilSaturday);

                // Format the date as "Saturday, August 24"
                const formattedSaturday = `${daysOfWeekCFB[thisComingSaturday.getDay()]}, ${monthsOfYearCFB[thisComingSaturday.getMonth()]} ${thisComingSaturday.getDate()}`;

                console.log("College Survivor Saturday Deadline Date:", formattedSaturday); // Example output: "Saturday, August 24"

    
    
    
    
    
                    let collegeSurvivorMembersToNotify = [];
    
    
                    function gatherEmailInfoForCollegeSurvivorNotification(user, currentWeek) {
                        
                        const doesUserWantNotifications = user?.marketing_preferences?.enable_pool_reminders;
                        console.log("Does User Want Notifications", doesUserWantNotifications);
                        if (!doesUserWantNotifications) {
                            console.log("User does not want notifications");
                            return;
                        }

                        const conferences = ["SEC", "Big Ten", "ACC", "Big 12"];
                        if (doesUserWantNotifications) {
                            const game = user?.Games?.find(game => game.gameID === "21pqx742-1r39-5y82-d3b0-92wk5izx1984");
                            const picks = game?.pool?.picks || [];
                            const userLosses = picks?.filter(pick => pick.result === "Loss" && pick.week < currentWeek); // Need to check if the week is less than the current week
                            const lossCount = userLosses?.length;
                            console.log("How many college losses??", userLosses, lossCount, picks);
                            if (userLosses.length === 4) {
                                console.log("User is OUT");
                                return;
                            }
                            const currentWeekPick = picks?.filter(pick => pick.week === currentWeek);
                            const currentWeekPickCount = currentWeekPick?.length;
                            const gamesRemaining = 4 - currentWeekPickCount - lossCount;
                            console.log("currentWeekPick", currentWeekPick, "currentWeekPickCount", currentWeekPickCount, "gamesRemaining", gamesRemaining);
                            if (gamesRemaining > 0) {
                                    // Filter out conferences that have been picked this week or have a loss in any week
                                    const conferencesNotPicked = conferences.filter(conference => {
                                        const conferencePickedThisWeek = currentWeekPick.some(pick => pick.conference === conference);
                                        const conferenceLostInAnyWeek = userLosses.some(loss => loss.conference === conference);
                                        return !conferencePickedThisWeek && !conferenceLostInAnyWeek;
                                    });

                                    collegeSurvivorMembersToNotify.push({
                                    fullName: user.first_name + " " + user.last_name,
                                    email: user.email,
                                    username: user.user_name,
                                    picksLeft: gamesRemaining,
                                    currentWeek: currentWeek,
                                    conferencesNotPicked: conferencesNotPicked
                                });
                            } else {
                                console.log("User has made all picks for the week");
                            }
                            
                        }
                    }
                
                    const collegeSurvivorEmailInfo = gatherEmailInfoForCollegeSurvivorNotification(UserDB, 2);
                    console.log("College Survivor Email Info", collegeSurvivorEmailInfo);
                    console.log("College Survivor Members To Notify", collegeSurvivorMembersToNotify);














    
    const updatePicksWithResults = (user, gameResults) => {
        const picks = user?.Games[0]?.pool?.picks || [];

        return picks?.map(pick => {
            const game = gameResults?.find(game => game?.GlobalGameID === pick?.gameId);
    
            if (game && pick?.result === 'Pending') {
                const { HomeScore, AwayScore, Status } = game;
                const spread = pick?.spread;
                let updatedResult = 'Pending';
    
                // Check for special game statuses
                if (Status === 'Cancelled' || Status === 'Postponed' || Status === 'Suspended' || Status === 'Forfeit') {
                    updatedResult = 'Push';
                    return { ...pick, result: updatedResult };
                }
    
                // Determine result based on selected team and score
                if (pick?.isHomeSelected) {
                    const adjustedHomeScore = HomeScore + spread;
                    if (adjustedHomeScore > AwayScore) {
                        updatedResult = 'Win';
                    } else if (adjustedHomeScore === AwayScore) {
                        updatedResult = 'Push';
                    } else {
                        updatedResult = 'Loss';
                    }
                } else {
                    const adjustedAwayScore = AwayScore + spread;
                    if (adjustedAwayScore > HomeScore) {
                        updatedResult = 'Win';
                    } else if (adjustedAwayScore === HomeScore) {
                        updatedResult = 'Push';
                    } else {
                        updatedResult = 'Loss';
                    }
                }
    
                return { ...pick, result: updatedResult };
            }
    
            return pick;
        });
    };
    
    
    const updatedPicks = updatePicksWithResults(UserDB, gameResults);
    console.log("NFL Pick Em Results", updatedPicks);

    
    

    function addPick5NoPicks(currentWeek, gamesRemaining, user) {
        const picks = user?.Games[0]?.pool?.picks || [];
        const noPick = {
            teamId : "No Pick",
            week : currentWeek,
            result: "Loss"
        }
        const currentWeekPicks = picks?.filter(pick => pick.week === currentWeek);
        const currentWeekPicksCount = currentWeekPicks?.length;
        const missingPickCount = 5 - currentWeekPicksCount;
        console.log("Current Week Picks", currentWeekPicks, "Current Week Picks Count", currentWeekPicksCount, "Missing Pick Count", missingPickCount);
        
        if (missingPickCount > gamesRemaining) {
            const noPicksToAdd = missingPickCount - gamesRemaining;
            for (let i = 0; i < noPicksToAdd; i++) {
                picks.push(noPick);
            }
        }
        
        return picks;

    }

    const addingNoPick5 = addPick5NoPicks(1, 0, UserDB);
    console.log("NFL Adding No Pick 5", addingNoPick5);

    return (
        <div>
            <h1>NFL Games With Spreads</h1>
        </div>
    );
}

export default NFLPick5ResultLogic;