import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import './TiersCard.scss';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TiersCard = ({ tier, backgroundColor, textColor, handleSelection, interval, selectedPlayers = [], lastCard }) => {
    // Find selected player for this tier
    const tierSelectedPlayers = selectedPlayers.filter(pick => 
        pick.interval === interval && pick.tier === tier.tier
    );
    
    const picksThisTier = tier.tierPicks || 1;
    const maxSelectionsReached = tierSelectedPlayers.length >= picksThisTier;
    const hasSelection = tierSelectedPlayers.length > 0;
    
    // Initialize cardOpen state
    const [cardOpen, setCardOpen] = useState(!maxSelectionsReached);
    
    // Update cardOpen state when interval changes
    useEffect(() => {
        // Always keep the card open when there's no selection
        // For tiers with existing selections, follow the maxSelectionsReached rule
        setCardOpen(!hasSelection || !maxSelectionsReached);
    }, [interval, hasSelection, maxSelectionsReached]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleSelectClick = () => {
        setCardOpen(!cardOpen);
    };
    
    console.log("Tier", tier, "Selected Players", selectedPlayers);

    const tierText = `Tier ${tier?.tier}`;
    const selectText = tier?.text;
    const players = tier?.players || [];
    

    // If a selection has already been made, change the button text to "Edit"
    const buttonText = cardOpen ? "Close" : "Edit";
    
    const myPickText = hasSelection 
        ? players.find(player => String(player.id) === String(tierSelectedPlayers[0].id))?.name 
        : "No Pick";

    const handlePlayerSelection = (event) => {
        const id = event.target.value;
        console.log("Selected player ID:", id, "Type:", typeof id);
        
        // Close the card if picks for this tier have been met
        if (maxSelectionsReached) {
            setCardOpen(false);
        }
        
        handleSelection(interval, tier.tier, picksThisTier, id);
    };

    const isPlayerSelected = (id) => {
        return tierSelectedPlayers.some(pick => String(pick.id) === String(id));
    };

    return (
        <Box 
            className="tiers-card-container"
            sx={{
                marginBottom: lastCard ? '60px' : '0',
            }}
        >
            <Box 
                className="tiers-card-top-border"
                sx={{ backgroundColor: backgroundColor }}
            >
                <Typography className="tiers-card-top-border-left-text" sx={{ color: textColor}}>{tierText}</Typography>
                <Typography className='tiers-card-top-border-right-text' sx={{ color: textColor}}>{selectText}</Typography>
                
                {/* Only show the "Edit" button if a selection has been made */}
                {hasSelection && (
                    <Button
                        sx={{
                            padding: '8px',
                            backgroundColor: '#00AAD1',
                            height: '22px',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#00AAD1',
                            },
                        }}
                        onClick={handleSelectClick}
                    >
                        {buttonText}
                    </Button>
                )}
            </Box>

            {cardOpen && (
                <Box className="tiers-card-list">
                    <RadioGroup 
                        value={tierSelectedPlayers.length > 0 ? tierSelectedPlayers[0].id : ""}
                        onChange={handlePlayerSelection}
                    >
                        {players.map((player) => (
                            <FormControlLabel
                                key={`${player.id}-${tier.tier}`}
                                value={player.id}
                                control={<Radio />}
                                label={[
                                    player.logo && `${player.logo} `,
                                    player.rank && `(${player.rank})`,
                                    player.name,
                                    player.record && `${player.record}`
                                ].filter(Boolean).join(" ")}
                                
                                sx={{
                                    height: '30px',
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: isMobile ? '12px' : '14px',
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            )}

            <Box className="tiers-card-bottom-border">
                <Typography className="tiers-card-bottom-border-text">
                    My Pick:&nbsp;
                    <span style={{ color: myPickText === "No Pick" ? "#CC293C" : "#002129" }}>
                        {myPickText}
                    </span>
                    {myPickText === "No Pick" && (
                        <CloseIcon sx={{ color: "#CC293C", marginTop: '-2px' }} />
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

export default TiersCard;