import React, { useEffect, useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useGetCreationLeaderboard } from '../../../hooks/pools/useGetCreationLeaderboard';

const GameCreationLeaderboard = ({ myUsername, masterPoolId }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(true);

    const { mutate: fetchCreationLeaderboard, data: creationLeaderboardData, isLoading: creationLeaderboardLoading, isError: creationLeaderboardError, error: creationLeaderboardErrorData } = useGetCreationLeaderboard();
    
    // useEffect to fetch creation leaderboard data when masterPoolId changes
    useEffect(() => {
        if (masterPoolId) {
            setLoading(true);
            fetchCreationLeaderboard({ gameID: masterPoolId });
            setLoading(false);
        }
    }, [masterPoolId, fetchCreationLeaderboard]);

    //console.log("Creation Leaderboard Data:", creationLeaderboardData);

    // Find my data in the leaderboard
    const myData = useMemo(() => {
        if (!creationLeaderboardData || !Array.isArray(creationLeaderboardData)) return null;
        return creationLeaderboardData.find(entry => entry.username === myUsername);
    }, [creationLeaderboardData, myUsername]);

    // Sorting state
    const [sort, setSort] = useState({
        column: 'poolMembers',
        direction: 'desc'
    });

    // Sorting function
    const sortedLeaderboard = useMemo(() => {
        // Check if creationLeaderboardData exists and is an array before sorting
        if (!creationLeaderboardData || !Array.isArray(creationLeaderboardData)) return [];
        
        return [...creationLeaderboardData].sort((a, b) => {
            // Determine sorting logic based on current column
            switch (sort.column) {
                case 'username':
                    const nameCompare = a.username.localeCompare(b.username);
                    return sort.direction === 'asc' ? nameCompare : -nameCompare;
                
                case 'poolMembers':
                    return sort.direction === 'asc'
                        ? a.poolMembers - b.poolMembers
                        : b.poolMembers - a.poolMembers;
                
                default:
                    return 0;
            }
        });
    }, [creationLeaderboardData, sort]);

    // Function to handle column click for sorting
    const handleColumnClick = (column) => {
        setSort(prevSort => ({
            column,
            // Toggle direction if same column, otherwise start with desc
            direction: prevSort.column === column 
                ? (prevSort.direction === 'asc' ? 'desc' : 'asc')
                : 'desc'
        }));
    };

    if (loading || creationLeaderboardLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            {myData && (
                <TableContainer
                    component={Paper}
                    sx={{
                        maxWidth: '600px',
                        margin: '0 auto',
                        backgroundColor: '#F0F1F1',
                    }}
                >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        width: '50%',
                                        color: '#002129',
                                    }}
                                >
                                    {myUsername}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                        color: '#002129',
                                    }}
                                >
                                    {myData?.poolMembers}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <TableContainer component={Paper} sx={{ maxWidth: '600px', margin: '0 auto', marginBottom: '32px', borderRadius: '0px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('username')}
                                sx={{
                                    width: '50%',
                                    color: sort?.column === 'username' ? '#FFC60A' : '#fff',
                                    backgroundColor: '#002129',
                                    cursor: 'pointer'
                                }}
                            >
                                POOL HOST
                            </TableCell>
                            <TableCell
                                className='referrals-leaderboard-header'
                                onClick={() => handleColumnClick('poolMembers')}
                                sx={{
                                    width: '50%',
                                    textAlign: 'center',
                                    color: sort?.column === 'poolMembers' ? '#FFC60A' : '#fff',
                                    backgroundColor: '#002129',
                                    cursor: 'pointer'
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        POOL<br />MEMBERS
                                    </>
                                ) : (
                                    'POOL MEMBERS'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedLeaderboard.map((entry, index) => (
                            <TableRow
                                key={`${entry.username}-${index}`}
                                sx={{
                                    backgroundColor:
                                        entry?.username === myUsername
                                            ? '#FFF6DA'
                                            : index % 2 === 0
                                            ? '#FFF'
                                            : '#F0F1F1',
                                }}
                            >
                                <TableCell className='referrals-leaderboard-player-column'>
                                    {entry.username}
                                </TableCell>
                                <TableCell
                                    className='referrals-leaderboard-player-column'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {entry.poolMembers}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {Array.isArray(creationLeaderboardData) && creationLeaderboardData.length === 0 && (
                <Box sx={{ textAlign: 'center', padding: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '700', color: '#002129' }}>
                        No pools created yet.  Create a pool to take the lead!
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default GameCreationLeaderboard;