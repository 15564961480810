import React from "react";
import { Box, Typography } from "@mui/material";

const TeamNameDisplayBar = ({ gatherDisplayBarInfo, teams, teamId }) => {
    const { teamLogo, teamNickname } = gatherDisplayBarInfo(teamId, teams);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center', // Center the inner box
                padding: '16px', // Optional padding for outer box
                backgroundColor: '#002129',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    maxWidth: '500px',
                    width: '100%', // Ensures full width up to 500px
                }}
            >
                {/* Left Logo */}
                <Box
                    component="img"
                    src={teamLogo}
                    alt={`${teamNickname} logo`}
                    sx={{
                        height: '32px',
                        width: '32px',
                        objectFit: 'contain',
                    }}
                />

                {/* Team Name */}
                <Typography
                    variant="h1"
                    sx={{
                        color: '#fff',
                        fontSize: '24px',
                        fontWeight: '700',
                        textAlign: 'center',
                        flexGrow: 1,
                        padding: '0 16px',
                    }}
                >
                    {teamNickname} Props
                </Typography>

                {/* Right Logo (Same as Left Logo) */}
                <Box
                    component="img"
                    src={teamLogo}
                    alt={`${teamNickname} logo`}
                    sx={{
                        height: '32px',
                        width: '32px',
                        objectFit: 'contain',
                    }}
                />
            </Box>
        </Box>
    );
};

export default TeamNameDisplayBar;
