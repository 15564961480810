import React from "react";
import { Box, Typography } from "@mui/material";
import "./SuperPickemBracket.scss";

const BracketLosingTeam = ({ noPick, teamInfo }) => {
    //console.log("NO PICK: ", noPick);
    const noPickMessage = "No Pick";

    const teamId = teamInfo?.teamId;
    const seed = teamInfo?.seed;
    const teamDisplay = teamInfo?.nameDisplay;
    const teamDisplayCharacterCount = teamInfo?.teamDisplayCharacterCount;
    //console.log("Team Display Character Count: ", teamDisplayCharacterCount);
    const teamLogo = teamInfo?.logo;
    const teamColor = teamInfo?.teamColor;


    return (
        <Box 
            className="super-pickem-bracket-team-row"
            sx={{
                border: 'none',
                justifyContent: 'center',
                width: '230px',
            }}        
        >
            {/* Team Logo */}
            {!noPick && (
                <Box 
                    className="super-pickem-bracket-team-logo-placeholder"
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                        <img 
                            src={teamLogo}
                            alt="Team Logo"
                            className="team-logo"
                        />
                </Box>
            )}

            {/* Team Name/Record */}
            <Box className="super-pickem-live-bracket-team-name-loser">
                <Typography 
                    className="team-name-loser" 
                    style={{
                        color: '#CC293C',
                        fontSize: teamDisplayCharacterCount > 20 ? '12px' : '14px',
                        lineHeight: teamDisplayCharacterCount > 15 ? '1.4' : teamDisplayCharacterCount > 11 ? '' : '1',
                        textDecoration: 'line-through',
                        width: '100%',
                    }}
                >
                    {noPick ? noPickMessage : teamDisplay}
                </Typography>
            </Box>
        </Box>
    );
};

export default BracketLosingTeam;
