import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useNFLScoresBySeason, useQuickPickNFL, useNFLCurrentWeek } from "../../../hooks/nfl";

const AdminDashContext = createContext();

export const useAdminDashContext = () => {
    const context = useContext(AdminDashContext);
    if (!context) {
        throw new Error("useAdminDashContext must be used within an AdminDashProvider");
    }
    return context;
}

const AdminDashProvider = ({ children }) => {
    const { mutate: fetchNFLScoresBySeason, data: NFLRegularSeasonSchedule } = useNFLScoresBySeason();
    const { mutate: fetchQuickPickNFL, data: quickPickNFL } = useQuickPickNFL();
    const { mutate: fetchNFLCurrentWeek, data: NFLCurrentWeek } = useNFLCurrentWeek();

    const [hasDataFetched, setHasDataFetched] = useState(false);
    const navigateToTop = useNavigateToTop();
    const { page } = useParams();
    const location = useLocation(); // Access query parameters

    const getQueryParams = () => new URLSearchParams(location.search);
    const league = getQueryParams().get("league"); 
    const selectedTab = getQueryParams().get("selectedTab"); 

    const [pageState, setPageState] = useState(page || "Home");
    const [leagueState, setLeagueState] = useState(league || null); // Default to null if no league
    const [selectedTabState, setSelectedTabState] = useState(selectedTab || null); // Default to null if no selectedTab

    useEffect(() => {
        console.log("Running Fetch Data");
        if (!hasDataFetched) {
            fetchNFLScoresBySeason();
            fetchQuickPickNFL("2024");
            fetchNFLCurrentWeek();
            setHasDataFetched(true);
        }
    }, [hasDataFetched, fetchNFLScoresBySeason, fetchQuickPickNFL, fetchNFLCurrentWeek]);

    console.log("NFL Regular Season Schedule", NFLRegularSeasonSchedule, "Quick Pick NFL", quickPickNFL, "NFL Current Week", NFLCurrentWeek);

    // Update state when page or query parameters change
    useEffect(() => {
        console.log("Running useEffect in AdminDashProvider");
        const currentLeague = league || null; // Default to null if no league
        const currentSelectedTab = selectedTab || null; // Default to null if no selectedTab
        
        if (page !== pageState || currentLeague !== leagueState || currentSelectedTab !== selectedTabState) {
            setPageState(page || "Home");
            setLeagueState(currentLeague);
            setSelectedTabState(currentSelectedTab);
            navigateToTop(`/admin-dash/${page || "Home"}?league=${currentLeague}&selectedTab=${currentSelectedTab}`); // Navigate including query
        }
    }, [page, league, selectedTab, pageState, leagueState, selectedTabState, navigateToTop]);

    const quickPickLeagues = ["NFL", "CFB"];

    const gameTypes = ['QuickPick', 'Pickem', 'Survivor', 'BirdiePool', 'Sportsbook', 'SuperPickem'];

    const handleBackClick = () => {
        setPageState("Home"); // Optionally update state
        navigateToTop('/admin-dash/Home'); // Navigate to Admin Home
    };

    const AdminDashContextValues = {
        pageState,
        setPageState,
        leagueState,
        setLeagueState,
        selectedTabState,
        setSelectedTabState,
        quickPickLeagues,
        NFLRegularSeasonSchedule,
        quickPickNFL,
        NFLCurrentWeek,
        handleBackClick,
        gameTypes,
    };

    return (
        <AdminDashContext.Provider value={AdminDashContextValues}>
            {children}
        </AdminDashContext.Provider>
    );
}

export default AdminDashProvider;
