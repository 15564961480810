import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const MyPicksNoGamesPlaceholder = ({rounds, interval}) => {

    const thisRound = rounds?.find(round => round.round === interval);
    //console.log('thisRound', thisRound);

    const gameInfo = thisRound?.gameInfo;
    const roundName = thisRound?.name;


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                marginTop: '24px',
                marginBottom: '50px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '20px' : '24px',
                    fontWeight: '700',
                }}
            >
                {roundName} Matchup{gameInfo?.length > 1 ? 's' : ''} TBD
            </Typography>
            {gameInfo?.map((game, index) => (
                <Box key={index}>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: '700',
                            marginTop: '20px',
                            textDecoration: 'underline'
                        }}
                    >
                        {game?.date}
                    </Typography>
                    {game?.games.map((game, index) => (
                        <Typography
                            key={index}
                            sx={{
                                fontSize: isMobile ? '16px' : '20px',
                                fontWeight: '700',
                            }}
                        >
                            {game}
                        </Typography>
                    ))}
                </Box>
            ))}
        </Box>
    )
};

export default MyPicksNoGamesPlaceholder;