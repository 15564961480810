import {
  Alert,
  Box,
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PasswordStrength from "../components/AccountCreation/PasswordStrength";
import { useResetPassword } from "../hooks/auth";
import MainLayout from "../layouts/mainLayouts";

const requirements = {
  letters: 1,
  digits: 1,
  caseDiff: 1,
  special: 1,
  minLength: 8,
  maxLength: 40,
};

const resetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#()$!%*?&])[A-Za-z\d@$!%#%^()*?&_]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      ),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

export default function ResetPassword() {
  const { token } = useParams();
  const form = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues,
  });

  const newPassword = useWatch({ control: form.control, name: "newPassword" });
  const confirmPassword = useWatch({
    control: form.control,
    name: "confirmPassword",
  });

  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [shouldShowConfirmPassword, setShouldShowConfirmPassword] =
    useState(false);
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const {
    status,
    error: resetPasswordError,
    mutate: resetPassword,
  } = useResetPassword();
  const resetPasswordErrorDetails = resetPasswordError?.response?.data?.error;

  ///CHECK IF THIS IS WORKING HERE!!!
  const submitResetPasswordForm = useCallback(() => {
    form.handleSubmit(async (formData) => {
      resetPassword({
        token,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });
    })();
  }, [form, resetPassword, token]);

  return (
    <MainLayout>
      <div className="flex-1 flex items-center justify-center flex-col">
        <Box
          component="form"
          onSubmit={form.handleSubmit(submitResetPasswordForm)}
          sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}
          className="max-w-md w-full"
        >
          <div className="text-2xl font-bold">Reset Password</div>
          <div className="text-gray-500">
            Choose a new password for your account.
          </div>

          <TextField
            id="newPassword"
            name="newPassword"
            label="New Password"
            variant="outlined"
            type={shouldShowPassword ? "text" : "password"}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShouldShowPassword(!shouldShowPassword)}
                    edge="end"
                  >
                    {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...form.register("newPassword")}
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            variant="outlined"
            type={shouldShowConfirmPassword ? "text" : "password"}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShouldShowConfirmPassword(!shouldShowConfirmPassword)
                    }
                    edge="end"
                  >
                    {shouldShowConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...form.register("confirmPassword")}
          />
          <Button
            disabled={form.formState.isSubmitting || !form.formState.isValid}
            variant="contained"
            type="submit"
          >
            Reset Password
          </Button>
          {status === "success" && (
            <div className="text-center  max-w-sm m-auto">
              <Alert severity="success" className="text-center">
                <div>Password reset successfully. </div>
                <div className="font-bold underline">
                  <Link to="/login">Back to login</Link>
                </div>
              </Alert>
            </div>
          )}
          {status === "error" && (
            <div className="text-center max-w-sm m-auto">
              <Alert severity="error" className="text-center">
                {resetPasswordErrorDetails?.match(/Token expired/) ? (
                  <>
                    <div>
                      The password reset link has expired. Please request a new
                      one.
                    </div>
                    <Link className="font-bold c pt-3 block underline" to="/forgot-password">
                      Request a new password reset link
                    </Link>
                  </>
                ) : (
                  <>
                    <div>An error occurred. Please try again later.</div>
                    {showErrorDetails && (
                      <div className="font-semibold pl-2">
                        {resetPasswordError?.message}
                      </div>
                    )}
                    <div className="mt-2">
                      <Button
                        variant="outlined"
                        size="xsmall"
                        onClick={() => setShowErrorDetails(!showErrorDetails)}
                      >
                        {showErrorDetails ? "Hide" : "Show"} Details
                      </Button>
                    </div>
                  </>
                )}
              </Alert>
            </div>
          )}
          <PasswordStrength
            password={newPassword}
            confirmPassword={confirmPassword}
            requirements={requirements}
            onValidationChange={() => {}}
          />
        </Box>
      </div>
    </MainLayout>
  );
}
