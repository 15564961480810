import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const QuickPickGameInfo = ({ gameData, myPoolsData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const currentInterval = myPoolsData?.currentInterval;

    const picksThisInterval = (pool) => {
        return pool?.picks?.filter(pick => pick?.interval === currentInterval)?.length || 0;
    };

    const picksToMakeThisInterval = myPoolsData?.picksToMake || 0;

    const arePicksIn = (pool) => {
        return picksThisInterval(pool) === picksToMakeThisInterval;
    }

    const totalPoints = (pool) => {
        return pool?.picks?.reduce((acc, pick) => {
            return acc + (pick?.points || 0);
        }, 0) || 0;
    }
    
    const pointsThisInterval = (pool) => {
        return pool?.picks?.filter(pick => pick?.interval === currentInterval)?.reduce((acc, pick) => {
            return acc + (pick?.points || 0);
        }, 0) || 0;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '8px',
            }}
        >
            {gameData?.entries?.map((entry, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center',
                            marginBottom: '4px'
                        }}
                    >
                        Entry ({index + 1})
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            marginTop: '4px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginRight: '12px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '14px',
                                    fontWeight: '700',
                                    color: '#002129',
                                    textAlign: 'center',
                                    marginBottom: '4px'
                                }}
                            >
                                Picks In?
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '14px',
                                        fontWeight: '700',
                                        color: arePicksIn(entry?.pool) ? '#00AA72' : '#CC293C',
                                        textAlign: 'center',
                                    }}
                                >
                                    {picksThisInterval(entry?.pool)}/{picksToMakeThisInterval}
                                </Typography>
                                {arePicksIn(entry?.pool) ? (
                                    <CheckIcon sx={{ color: '#00AA72' }} />
                                ) : (
                                    <CloseIcon sx={{ color: '#CC293C' }} />
                                )}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '14px',
                                    fontWeight: '700',
                                    color: '#002129',
                                    textAlign: 'center',
                                    marginBottom: '4px'
                                }}
                            >
                                Points
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: '24px'  // Match height with icon container
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '14px',
                                        fontWeight: '700',
                                        color: '#002129',
                                        textAlign: 'center',
                                    }}
                                >
                                    {totalPoints(entry?.pool)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                   
                </Box>
            ))}
        </Box>
    );
};

export default QuickPickGameInfo;