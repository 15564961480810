import React, { useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import "./TraditionalBetCard.scss";
import LockIcon from '@mui/icons-material/Lock';
import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';

const TraditionalBetCard = ({ betInfo, pickState, setPickState }) => {
    //console.log("BetINFO ", betInfo.odds);
    const betStyles = ["Spread", "Moneyline", "Total"];
    

    const betId = uuidv4();
    
    // Parse the string as a DateTime in the America/New_York timezone
    const dateTime = DateTime.fromISO(betInfo?.dateTime, { zone: 'America/New_York' });

    const handleBetClick = (betType, betChoice) => {
        // Create a bet object with basic details
        const bet = {
            betId: betId,
            gameId: betInfo?.gameId,
            dateTime: betInfo?.dateTime,
            betType,
            sport: betInfo?.sport,
            league: betInfo?.league,
            betChoice,
            awayName: betInfo?.awayDisplay,
            homeName: betInfo?.homeDisplay,
        };
    
        // Set line and juice based on the bet type and choice
        switch (betType) {
            case "Spread":
                bet.line = betChoice === "Away" ? betInfo?.odds?.AwayPointSpread : betInfo?.odds?.HomePointSpread;
                bet.juice = betChoice === "Away" ? betInfo?.odds?.AwayPointSpreadPayout : betInfo?.odds?.HomePointSpreadPayout;
                break;
            case "Moneyline":
                bet.juice = betChoice === "Away" ? betInfo?.odds?.AwayMoneyLine : betInfo?.odds?.HomeMoneyLine;
                break;
            case "Total":
                bet.line = betInfo?.odds?.OverUnder;
                bet.juice = betChoice === "Over" ? betInfo?.odds?.OverPayout : betInfo?.odds?.UnderPayout;
                break;
            default:
                // If betType is invalid, exit the function
                return;
        }
    
        // Update pickState
        setPickState(prevPickState => {
            // Check if the bet already exists in the state
            const existingBetIndex = prevPickState.findIndex(existingBet =>
                existingBet.gameId === bet.gameId &&
                existingBet.betType === bet.betType &&
                existingBet.betChoice === bet.betChoice
            );
    
            console.log("Existing Bet Index", existingBetIndex);
    
            if (existingBetIndex !== -1) {
                // If the bet exists, remove it from the state
                return prevPickState.filter((_, index) => index !== existingBetIndex);
            } else {
                // Otherwise, add the new bet to the state
                return [...prevPickState, bet];
            }
        });
    };
    


// Utility function to determine if a button is active
const isActive = (betType, betChoice) => {
    return pickState.some(bet =>
        bet.betType === betType && bet.betChoice === betChoice && bet.gameId === betInfo?.gameId
    );
};


    return (
        <Box
            sx={{
                minWidth: '375px',
                width: '100%', // Let it fill the container
                border: '1px solid black',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px',
                marginTop: '20px',
                '@media (min-width: 900px) and (max-width: 959px)': {
                width: '100%', // Ensure full width between 900px-959px
                },
                '@media (min-width: 960px)': {
                maxWidth: '959px', // Max width after 960px
                },
            }}
        >

            <Grid container sx={{ width: '100%', height: '25px', backgroundColor: '#002129', alignItems: 'center' }}>
                <Grid item xs={4} sx={{ textAlign: 'center', width: '40%' }}>
                    <Typography className="sportsbook-traditional-header-date">
                        {dateTime.toFormat("ccc MMM d - h:mma 'ET'")}
                    </Typography>

                </Grid>
                <Grid item xs={8} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                    {betStyles.map((style, index) => (
                        <Typography
                            key={index}
                            className="sportsbook-traditional-header-bet-types"
                        >
                            {style}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
            {/* Second Row */}
            <Grid container sx={{ width: '100%', height: '92px' }}>
                <Grid 
                    item 
                    xs={4} 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-start', // Align text to the left
                        justifyContent: 'center',
                        width: '40%' 
                    }}
                >
                    {/* First Box - AWAY TEAM */}
                    <Box 
                    sx={{ 
                        height: '36px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'flex-start', // Align items to the left
                        gap: '4px', // Space between the image and text
                        marginLeft: '4px' // Space between the edge of the box and the image
                    }}
                    >
                        {/* Image */}
                        <Box 
                            component="img"
                            src={betInfo?.awayLogo}
                            alt="Logo"
                            className="sportsbook-traditional-team-logo"
                        />

                        <Typography
                            className="sportsbook-traditional-team-name"
                        >
                            {betInfo?.awayDisplay}
                        </Typography>

                    </Box>

                    <Box 
                        sx={{ 
                            height: '14px', 
                            display: 'flex', 
                            alignItems: 'center', // Center items vertically
                            justifyContent: 'flex-start', // Align items to the left
                            width: '100%'  // Ensure the box takes up the full width of its container
                        }}
                    >
                        <Typography className="sportsbook-traditional-at-symbol">
                            @
                        </Typography>

                        <Box 
                            sx={{
                                flexGrow: 1,  // Ensures the line stretches to fill the remaining space
                                borderTop: '1px solid black'
                            }}
                        />
                    </Box>

                    {/* Third Box - HOME TEAM */}
                    <Box 
                    sx={{ 
                        height: '36px', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'flex-start', // Align items to the left
                        gap: '4px', // Space between the image and text
                        marginLeft: '4px' // Space between the edge of the box and the image
                    }}
                    >
                        {/* Image */}
                        <Box 
                            component="img"
                            src={betInfo?.homeLogo}
                            alt="Logo"
                            className="sportsbook-traditional-team-logo"
                        />
                         {/* Top Team Name (Away Team usually) */}
                        <Typography
                            className="sportsbook-traditional-team-name"
                        >
                            {betInfo?.homeDisplay}
                        </Typography>
                    </Box>
                </Grid>
 
                {/* Grid for button pairs */}
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%' }}>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Grid item xs={4}>
                            <Box className="sportsbook-traditional-button-pair">
                                
                            <Button 
                                className={`sportsbook-traditional-bet-button ${isActive("Spread", "Away") ? 'active' : ''}`}
                                onClick={() => betInfo?.odds?.AwayPointSpread !== undefined && handleBetClick("Spread", "Away")}
                                disabled={betInfo?.odds?.AwayPointSpread === undefined}
                            >
                                <span className={`sportsbook-traditional-bet-button-text1 ${isActive("Spread", "Away") ? 'active' : ''}`}>
                                    {betInfo?.odds?.AwayPointSpread !== undefined 
                                        ? (betInfo?.odds?.AwayPointSpread > 0 ? '+' + betInfo?.odds?.AwayPointSpread : betInfo?.odds?.AwayPointSpread === 0 ? 'Pk' : betInfo?.odds?.AwayPointSpread)
                                        : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    {betInfo?.odds?.AwayPointSpread !== undefined && (
                                        <span className="sportsbook-traditional-bet-button-text2">
                                            ({betInfo?.odds?.AwayPointSpreadPayout > 0 ? '+' + betInfo?.odds?.AwayPointSpreadPayout : betInfo?.odds?.AwayPointSpreadPayout})
                                        </span>
                                    )}
                                </span>
                            </Button>

                            <Button 
                                className={`sportsbook-traditional-bet-button ${isActive("Spread", "Home") ? 'active' : ''}`}
                                onClick={() => betInfo?.odds?.HomePointSpread !== undefined && handleBetClick("Spread", "Home")}
                                disabled={betInfo?.odds?.HomePointSpread === undefined}
                            >
                                <span className={`sportsbook-traditional-bet-button-text1 ${isActive("Spread", "Home") ? 'active' : ''}`}>
                                    {betInfo?.odds?.HomePointSpread !== undefined 
                                        ? (betInfo?.odds?.HomePointSpread > 0 ? '+' + betInfo?.odds?.HomePointSpread : betInfo?.odds?.HomePointSpread === 0 ? 'Pk' : betInfo?.odds?.HomePointSpread)
                                        : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    {betInfo?.odds?.HomePointSpread !== undefined && (
                                        <span className="sportsbook-traditional-bet-button-text2">
                                            ({betInfo?.odds?.HomePointSpreadPayout > 0 ? '+' + betInfo?.odds?.HomePointSpreadPayout : betInfo?.odds?.HomePointSpreadPayout})
                                        </span>
                                    )}
                                </span>
                            </Button>

                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className="sportsbook-traditional-button-pair">
                                <Button 
                                    className={`sportsbook-traditional-bet-button ${isActive("Moneyline", "Away") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.AwayMoneyLine !== undefined && handleBetClick("Moneyline", "Away")}
                                    disabled={betInfo?.odds?.AwayMoneyLine === undefined}
                                >
                                     <span className="sportsbook-traditional-bet-button-text2">
                                        {betInfo?.odds?.AwayMoneyLine !== undefined
                                            ? (betInfo?.odds?.AwayMoneyLine > 0 ? '+' + betInfo?.odds?.AwayMoneyLine : betInfo?.odds?.AwayMoneyLine)
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    </span>
                                </Button>
                                <Button
                                    className={`sportsbook-traditional-bet-button ${isActive("Moneyline", "Home") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.HomeMoneyLine !== undefined && handleBetClick("Moneyline", "Home")}
                                    disabled={betInfo?.odds?.HomeMoneyLine === undefined}
                                >
                                    <span className="sportsbook-traditional-bet-button-text2">
                                        {betInfo?.odds?.HomeMoneyLine !== undefined
                                            ? (betInfo?.odds?.HomeMoneyLine > 0 ? '+' + betInfo?.odds?.HomeMoneyLine : betInfo?.odds?.HomeMoneyLine)
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    </span>
                                </Button>

                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className="sportsbook-traditional-button-pair">
                            <Button 
                                className={`sportsbook-traditional-bet-button ${isActive("Total", "Over") ? 'active' : ''}`}
                                onClick={() => betInfo?.odds?.OverUnder !== undefined && handleBetClick("Total", "Over")}
                                disabled={betInfo?.odds?.OverUnder === undefined}
                            >
                                <span className={`sportsbook-traditional-bet-button-text1 ${isActive("Total", "Over") ? 'active' : ''}`}>
                                    {betInfo?.odds?.OverUnder !== undefined 
                                        ? `O ${betInfo?.odds?.OverUnder}` 
                                        : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    {betInfo?.odds?.OverUnder !== undefined && (
                                        <span className="sportsbook-traditional-bet-button-text2">
                                            ({betInfo?.odds?.OverPayout > 0 ? '+' + betInfo?.odds?.OverPayout : betInfo?.odds?.OverPayout})
                                        </span>
                                    )}
                                </span>
                            </Button>

                            <Button 
                                className={`sportsbook-traditional-bet-button ${isActive("Total", "Under") ? 'active' : ''}`}
                                onClick={() => betInfo?.odds?.OverUnder !== undefined && handleBetClick("Total", "Under")}
                                disabled={betInfo?.odds?.OverUnder === undefined}
                            >
                                <span className={`sportsbook-traditional-bet-button-text1 ${isActive("Total", "Under") ? 'active' : ''}`}>
                                    {betInfo?.odds?.OverUnder !== undefined 
                                        ? `U ${betInfo?.odds?.OverUnder}` 
                                        : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />}
                                    {betInfo?.odds?.OverUnder !== undefined && (
                                        <span className="sportsbook-traditional-bet-button-text2">
                                            ({betInfo?.odds?.UnderPayout > 0 ? '+' + betInfo?.odds?.UnderPayout : betInfo?.odds?.UnderPayout})
                                        </span>
                                    )}
                                </span>
                            </Button>


                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default TraditionalBetCard;
