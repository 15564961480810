import React from "react";
import { Typography, Box, Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import "./CreateAPoolCard.scss";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const CreateAPoolCard = ({ poolInfo, myUserId }) => {
    const navigateToTop = useNavigateToTop();

    const handleButtonClick = () => {
        if (!myUserId) {
            navigateToTop('/login/create-a-pool');
            return;
        }

        //console.log("Clicked", poolInfo?.masterPoolId, poolInfo?.gameType);
        
        // Build the query parameters
        const queryParams = new URLSearchParams();
        
        // Add gameType parameter if it exists
        if (poolInfo?.gameType) {
            queryParams.append('gameType', poolInfo.gameType);
        }
        
        // Add masterPoolId parameter if it exists
        if (poolInfo?.masterPoolId) {
            queryParams.append('mId', poolInfo.masterPoolId);
        }

        // Add gameTitle parameter if it exists
        if (poolInfo?.header) {
            queryParams.append('gameTitle', poolInfo.header);
        }
        
        // Create the URL with query parameters
        const url = `/create-a-pool${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
        
        // Navigate to the URL
        navigateToTop(url);
    };

    return (
        <Box 
            className="create-a-pool-card-container"
            onClick={() => {
                if (poolInfo?.open) {
                  handleButtonClick();
                }
              }}              
        >
            <Box>
                <Typography className="create-a-pool-card-title">
                    {poolInfo?.header}
                </Typography>
                <Box className="create-a-pool-card-text-row">
                    <Typography className="create-a-pool-card-text-content">
                        {poolInfo?.content}
                    </Typography>
                </Box>
            </Box>

            <Box className="create-a-pool-card-button-container">
                <Button
                    className="create-a-pool-card-button"
                    sx={{ cursor: poolInfo?.open ? 'pointer' : 'default' }}
                    onClick={handleButtonClick}
                    disabled={!poolInfo?.open}
                >
                    {poolInfo?.open ? (
                        <>
                            Create Pool
                            <EastIcon sx={{ fontSize: '16px' }} />
                        </>
                    ) : "Coming Soon"}
                </Button>
            </Box>
        </Box>
    );
}

export default CreateAPoolCard;