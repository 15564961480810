import React from 'react';
import { Box, Typography, Select, MenuItem, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const MaxEntriesComponent = ({ maxAllowedEntries, order, maxEntries, setMaxEntries }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Handle checkbox selection
    const handleCheckboxChange = (value) => {
        setMaxEntries(value);
    };

    // Handle dropdown selection
    const handleDropdownChange = (event) => {
        setMaxEntries(event.target.value);
    };

    // Generate array of numbers from 1 to maxAllowedEntries
    const generateOptions = () => {
        return Array.from({ length: maxAllowedEntries }, (_, i) => i + 1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
                marginTop: '16px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '15px' : '20px',
                    fontWeight: '700',
                    marginBottom: '10px',
                    textAlign: 'left',
                }}
            >
                {order}. Max Entries Per User?
            </Typography>

            {maxAllowedEntries <= 3 ? (
                // Show checkboxes if maxAllowedEntries is 3 or less
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                    }}
                >
                    <FormGroup row>
                        {generateOptions().map((value) => (
                            <FormControlLabel
                                key={value}
                                control={
                                    <Checkbox
                                        checked={maxEntries === value}
                                        onChange={() => handleCheckboxChange(value)}
                                        name={`maxEntries-${value}`}
                                        color="primary"
                                    />
                                }
                                label={value}
                                sx={{
                                    marginRight: '32px', // ✅ Adds spacing between each group
                                    '&:last-child': { marginRight: 0 } // ✅ Prevents extra margin on the last item
                                }}
                            />
                        ))}
                    </FormGroup>
                </Box>
            ) : (
                // Show dropdown if maxAllowedEntries is more than 3
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <Select
                        value={maxEntries || ''}
                        onChange={handleDropdownChange}
                        displayEmpty
                        fullWidth
                        sx={{ maxWidth: '200px' }}
                    >
                        <MenuItem value="" disabled>
                            <em>Select max entries</em>
                        </MenuItem>
                        {generateOptions().map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            )}
        </Box>
    );
};

export default MaxEntriesComponent;