import React from "react";
import CFBSurvivorContainer from "../components/CFBSurvivor/CFBSurvivorContainer";
import CFBSurvivorProvider from "../components/CFBSurvivor/context/CFBSurvivorContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const CFBPickEmPage = () => {
  const queryClient = new QueryClient();

  return (
    <div>
		<QueryClientProvider client={queryClient}>
			<CFBSurvivorProvider>
				<CFBSurvivorContainer />
			</CFBSurvivorProvider>
		</QueryClientProvider>
    </div>
  );
}

export default CFBPickEmPage;