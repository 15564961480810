// TiebreakerModal.jsx
import React from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';

const TiebreakerModal = ({ tiebreakerOpen, setTiebreakerOpen, tiebreakerValue, handleChange, handleTiebreakerSave, doesUserHaveTiebreaker, isBeforeTargetDateTime, message, tiebreakerError }) => {
    return (
        <Modal
            open={tiebreakerOpen}
            onClose={() => {}}
            aria-labelledby="tiebreaker-modal"
            aria-describedby="tiebreaker-modal-description"
        >
            <Box className="tiebreaker-modal" sx={{ p: 3 }}>
                <Typography style={{ textDecoration: 'underline' }} variant="h5" id="tiebreaker-modal">
                    Pool Tiebreaker
                </Typography>
                <Typography variant="body1" id="tiebreaker-modal-description" sx={{ textAlign: 'center', marginBottom: '15px' }}>
                    Total points scored in <br /> the last NBA Finals game.
                </Typography>
                <Typography variant="body1" id="tiebreaker-modal-description">
                    Enter Total Points Prediction:
                </Typography>
                <TextField
                    id="tiebreaker-input"
                    label="Tiebreaker"
                    variant="outlined"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={tiebreakerValue}
                    onChange={handleChange}
                    sx={{ marginBottom: '10px', marginTop: '10px' }} // Add some space above and below the input
                />
                <Typography style={{ fontSize: '12px', marginBottom: '10px' }} variant="body1" id="tiebreaker-modal-description">
                    (Tiebreaker must be a 3 digit number)
                </Typography>
                <Typography style={{ textDecoration: 'underline' }} variant="h5" id="tiebreaker-modal-description">
                    Tiebreaker Rules:
                </Typography>
                <Box sx={{ textAlign: 'left' }}>
                <Typography style={{ fontSize: '12px' }} variant="body1" id="tiebreaker-modal-description">
                    1. Tiebreaker locks Friday, June 14th
                </Typography>
                <Typography style={{ fontSize: '12px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                     @ 8:30pm EST, prior to Game 4.
                </Typography>
                <Typography style={{ fontSize: '12px' }} variant="body1" id="tiebreaker-modal-description">
                    2. Closest to the actual total points
                </Typography>
                <Typography style={{ fontSize: '12px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                     scored in the last NBA Finals game wins.
                </Typography>
                <Typography style={{ fontSize: '12px' }} variant="body1" id="tiebreaker-modal-description">
                    3. If multiple players are still tied following
                </Typography>
                <Typography style={{ fontSize: '12px', marginLeft: '15px' }} variant="body1" id="tiebreaker-modal-description">
                     Rule 1, the tiebreaker will go as follows: <br />
                    a. NBA Finals Pick 'Em Record <br />
                    b. Coin Flip
                </Typography>
                </Box>

                {tiebreakerError && (
                    <Typography color="error" variant="body2" sx={{ marginTop: '10px', textAlign: 'center' }}>
                        {tiebreakerError}
                    </Typography>
                )}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        {doesUserHaveTiebreaker && (
                            <Button
                                variant="contained"
                                onClick={() => setTiebreakerOpen(false)}
                                sx={{
                                    marginRight: '20px',
                                    color: 'black',
                                    backgroundColor: 'white',
                                }}
                            >
                                CANCEL
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            disabled={tiebreakerValue.length !== 3}
                            onClick={handleTiebreakerSave}
                        >
                            SUBMIT
                        </Button>
                    </Box>
            </Box>
        </Modal>
    );
};

export default TiebreakerModal;
