import React from 'react';
import { Box, Typography } from '@mui/material'; // Import MUI components
import './SponsorTab.scss'; // Import SCSS file

const SponsorHighlights = ({ dishes, storyBottomText, highlightsText }) => {
    //console.log("Dishes, Story Bottom Text, Highlights Text: ", dishes, storyBottomText, highlightsText);
    return (
        <>
            <Box className="sponsor-highlights-container">
                {dishes?.map((dish, index) => (
                    <Box
                        key={index}
                        className={`sponsor-highlight-item ${dishes.length % 2 === 1 && index === dishes.length - 1 ? 'last-item' : ''}`}
                    >
                        <Typography className="sponsor-highlight-name">{dish.name}</Typography>
                        <img
                            src={dish.image}
                            alt={dish.name}
                            className="sponsor-highlight-image"
                        />
                    </Box>
                ))}
            </Box>

            <Box className="sponsor-tab-container">

                {highlightsText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-main-text">
                        {text}
                    </Typography>
                ))}

                {storyBottomText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-bottom-text">
                        {text}
                    </Typography>
                ))}
            </Box>
        </>
    );
}

export default SponsorHighlights;
