import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';

const TopRow = ({ selectedGame, textLine1, textLine2, textLine3, awayNameAndRank, homeNameAndRank }) => {
  const { 
    homeLogo, 
    awayLogo, 
    homeName, 
    homeRank, 
    awayName, 
    awayRank, 
    homeRecord, 
    awayRecord, 
    homeScore, 
    awayScore, 
    gameTime, 
    gameDateTime, 
    gameLine, 
    status,
  } = selectedGame;
  //console.log("Selected Game: TOP ROW ", selectedGame);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  
  // const homeNameAndRank = homeRank? `(${homeRank}) ${homeName}` : homeName;
  // const awayNameAndRank = awayRank? `(${awayRank}) ${awayName}` : awayName;
  
  // let textLine1 = null;
  // if (status === 'Scheduled') {
  //   const datetime = DateTime.fromISO(gameTime);
  //   textLine1 = datetime.toFormat('EEE M/d');
  // } else if (status === 'In Progress') {
  //   textLine1 = "LIVE";
  // } else if (status === 'Final' || status === 'F/OT') {
  //   textLine1 = status;
  // }
  // let textLine2 = null;
  // if (status === 'Scheduled') {
  //   const gameTime = DateTime.fromISO(gameDateTime, { zone: 'America/New_York' });
    
  //   if (gameTime.hour === 0 && gameTime.minute === 0) {
  //     textLine2 = 'TBD';
  //   } else {
  //     textLine2 = gameTime.toFormat('h:mma').toLowerCase() + ' ET';
  //   }
  // } else if (status === 'In Progress') {
  //   textLine2 = gameLine;
  // }

  // let textLine3 = null; 
  // if (status === 'Scheduled') {
  //   textLine3 = gameLine;
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '318px' : '568px',
        height: isMobile ? '43px' : '75px',
      }}
    >
      {/* Box to hold the Away Team Logo and Record */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '10px',
          fontWeight: '700',
          color: '#002129',
        }}
      >
        {/* Box to hold the Away Team Logo */}
        <Box
          sx={{
            width: isMobile ? '32px' : '50px',
            height: isMobile ? '32px' : '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={awayLogo}
            alt="Away Team Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
        {/* Box to hold the Away Team Record */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '12px' : '16px',
              fontWeight: '700',
              color: '#002129',
              whiteSpace: 'nowrap',
            }}
          >
            {awayRecord}
          </Typography>
        </Box>
      </Box>

      {/* Box to hold the column for Away Team Name (Rank possible) then Score */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '66px' : '88px',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '10px',
          fontWeight: '700',
          color: '#002129',
          marginLeft: isMobile ? '8px' : '24px',
        }}
      >
        {/* Box to hold the Away Team Name and Rank */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '12px' : '16px',
              fontWeight: '700',
              color: '#002129',
            }}
          >
            {awayNameAndRank}
          </Typography>
        </Box>
        {/* Box to hold the Away Score */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '24px' : '32px',
              fontWeight: '700',
              color: '#002129',
            }}
          >
            {awayScore}
          </Typography>
        </Box>
      </Box>
          
        {/* Box to hold the Game Info */}

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobile ? '88px' : '140px',
                color: '#002129',
                marginLeft: isMobile ? '8px' : '54px',
                marginRight: isMobile ? '8px' : '54px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: '700',
                    color: textLine1 === "LIVE" ? '#CC293C' : '#002129',
                    whiteSpace: 'nowrap',
                }}
            >
                {textLine1 ? textLine1 : ''}
            </Typography>
            <Typography 
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: '700',
                    color: '#002129',
                    whiteSpace: 'nowrap',
                }}
            >
                {textLine2 ? textLine2 : ''}
            </Typography>
            <Typography
                sx={{
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: '700',
                    color: '#002129',
                    whiteSpace: 'nowrap',
                }}
            >
                {textLine3 ? textLine3 : ''}
            </Typography>
        </Box>

        {/* Box to hold the column for Home Team Name (Rank possible) then Score */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '66px' : '88px',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '10px',
          fontWeight: '700',
          color: '#002129',
          marginRight: isMobile ? '8px' : '16px',
        }}
      >
        {/* Box to hold the Home Team Name and Rank */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '12px' : '16px',
              fontWeight: '700',
              color: '#002129',
            }}
          >
            {homeNameAndRank}
          </Typography>
        </Box>
        {/* Box to hold the Home Score */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '24px' : '32px',
              fontWeight: '700',
              color: '#002129',
            }}
          >
            {homeScore}
          </Typography>
        </Box>
      </Box>

      {/* Box to hold the Home Team Logo and Record */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '10px',
          fontWeight: '700',
          color: '#002129',
        }}
      >
        {/* Box to hold the Home Team Logo */}
        <Box
          sx={{
            width: isMobile ? '32px' : '50px',
            height: isMobile ? '32px' : '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={homeLogo}
            alt="Home Team Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
        {/* Box to hold the Home Team Record */}
        <Box>
          <Typography
            style={{
              fontSize: isMobile ? '12px' : '16px',
              fontWeight: '700',
              color: '#002129',
              whiteSpace: 'nowrap',
            }}
          >
            {homeRecord}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TopRow;
