import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Select, MenuItem, FormControl } from '@mui/material';
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const TableDropdown = ({ seasonVsWeek, setSeasonVsWeek, seasonText, seasonPlain, weekOptions, firstWeek, startingWeekState }) => {
    //console.log("TableDropdown Props: ", seasonVsWeek, seasonText, seasonPlain, weekOptions);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //console.log("Season vs Week DROPDOWN", seasonVsWeek); // Add this log
    const handleChange = (event) => {
        const value = event.target.value;
        setSeasonVsWeek(value);
        //console.log("Season vs Week DROPDOWN", value); // Add this log
    };

    return (
        <FormControl
            variant="outlined"
            size="small"
            style={{ 
                marginTop: isMobile ? '15px' : '15px',
                marginBottom: isMobile ? '15px' : '15px',
                width: isMobile ? '163px' : '200px' 
            }}
        >
            <Select
                value={seasonVsWeek}
                onChange={handleChange}
                style={{
                    textAlign: "center",
                    fontSize: isMobile ? '12px' : '14px',
                    padding: isMobile ? '5px' : '10px',
                    height: isMobile ? '24px' : '42px',
                    border: "1px solid #000000",
                    borderRadius: "4px",
                    boxShadow: "none", // Remove default shadow if you want a flat look
                    width: '100%'
                }}
            >
                {seasonText && <MenuItem value={seasonText}>{seasonText}</MenuItem>}
                {weekOptions?.map((option, index) => (
                    <MenuItem key={index} value={option.props.value}>{option.props.children}</MenuItem>
                ))}
                {seasonPlain && <MenuItem value="Season">Season</MenuItem>}
            </Select>
        </FormControl>
    );
}

export default TableDropdown;
