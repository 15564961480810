import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './UserSubmitBar.scss';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import TwoButtonModal from '../Modals/TwoButtonModal';
import { set } from 'lodash';

const UserSubmitBar = ({
    picksToMake,
    tiebreakerCount,
    handleCancel,
    handleSubmit,
    hasSubmitted,
    handleClear,
    showCancelButton,
}) => {
    const {
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        tiebreaker,
        setTiebreaker,
        masterPoolData,
        gameData,
    } = useUserAndGameContext();
    //console.log("Pick State Tiebreaker in UserSubmitBar: ", pickState, tiebreaker);

    const [modalOpen, setModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    const handleClearClick = () => {
        setModalOpen(true);
    }

    const handleCancelClick = () => {
        setCancelModalOpen(true);
    }


    const title = "Are you sure you want to clear all of your bracket picks?";
    const text = "This cannot be undone.";
    const buttonOneText = "CANCEL";

    const buttonTwoText = "CLEAR PICKS";

    const cancelTitle = "Are you sure you want to cancel all of your unsaved picks?";
    const cancelText = "This cannot be undone.";
    const cancelButtonOneText = "GO BACK";
    const cancelButtonTwoText = "CONFIRM";



    return (
        <>
            <Box className="user-submit-bar-container">
                <Box className="user-submit-bar-content-row">
                        <Box className="user-submit-bar-text-group">
                            <Typography 
                                className="user-submit-bar-typography"
                            >
                                Picks:
                            </Typography>
                            <Typography 
                                className="user-submit-bar-typography"
                                sx={{
                                    color: pickState?.length === picksToMake ? "#00AA72" : "#CC293C",
                                    marginLeft: '5px'
                                }}
                            >
                                {pickState?.length}/{picksToMake}
                            </Typography>
                            {pickState?.length === picksToMake ? (
                                <CheckIcon className="user-submit-bar-icon" style={{ color: '#00AA72'}}/>
                            ) : (
                                <CloseIcon className="user-submit-bar-icon" style={{ color: '#CC293C'}}/>
                            )}
                            {tiebreakerCount > 0 && (
                                <>
                                    <Typography 
                                        className="user-submit-bar-typography"
                                        sx={{ marginLeft: '10px' }}
                                    >
                                        Tiebreaker:
                                    </Typography>
                                    {tiebreaker?.length === tiebreakerCount ? (
                                        <CheckIcon className="user-submit-bar-icon" style={{ color: '#00AA72'}}/>
                                    ) : (
                                        <CloseIcon className="user-submit-bar-icon" style={{ color: '#CC293C'}}/>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '10px',
                        gap: '20px',
                    }}
                >
                    <Button 
                        variant="contained"
                        onClick={handleClearClick}
                        disabled={hasSubmitted}
                        sx={{ height: '36px', whiteSpace: 'nowrap', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                    >
                        CLEAR PICKS
                    </Button>
                    {showCancelButton && (
                        <Button 
                            variant="contained"
                            onClick={handleCancelClick}
                            disabled={hasSubmitted}
                            sx={{ height: '36px', whiteSpace: 'nowrap', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                        >
                            CANCEL
                        </Button>
                    )}
                    <Button 
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={hasSubmitted}
                        sx={{ height: '36px', whiteSpace: 'nowrap', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Box>

            <TwoButtonModal
                modalOpen={cancelModalOpen}
                setModalOpen={setCancelModalOpen}
                headerText={cancelTitle}
                contentText={cancelText}
                buttonOneText={cancelButtonOneText}
                buttonTwoText={cancelButtonTwoText}
                buttonOneAction={() => {
                    setCancelModalOpen(false);
                }}
                buttonTwoAction={() => {
                    handleCancel();
                    setCancelModalOpen(false);
                }}
            />


            <TwoButtonModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                headerText={title}
                contentText={text}
                buttonOneText={buttonOneText}
                buttonTwoText={buttonTwoText}
                buttonOneAction={() => {
                    setModalOpen(false);
                }}
                buttonTwoAction={() => {
                    handleClear();
                    setModalOpen(false);
                }}
            />
        </>
    );
};

export default UserSubmitBar;