import React from "react";
import { Box, Typography } from "@mui/material";
import "./ChampPicksTeamCard.scss";
import { hexToRgba } from "../../../Brackets/bracketUtils/HexToRgba.js";

const ChampPicksTeamCard = ({ teamInfo, result, areSeedsIn, index }) => {
    const seed = teamInfo?.seed;
    const teamDisplay = teamInfo?.nameDisplay;
    const teamDisplayCharacterCount = teamInfo?.teamDisplayCharacterCount;
    const teamLogo = teamInfo?.logo;
    const teamColor = teamInfo?.teamColor;

    // Normalize the result to lowercase for case-insensitive comparison
    const normalizedResult = result?.toLowerCase();

    return (
        <Box
            className="super-pickem-champ-team-row"
            style={{
                border:
                    normalizedResult === "win"
                        ? "3px solid #00AA72"
                        : normalizedResult === "loss"
                        ? "3px solid #CC293C"
                        : "",
                backgroundColor:
                    normalizedResult === "win"
                        ? hexToRgba("#00AA72", 0.2)
                        : normalizedResult === "loss"
                        ? hexToRgba("#CC293C", 0.2)
                        : "transparent",
                cursor: "default",
            }}
        >
            {/* Team Seed */}
            <Box
                className="super-pickem-champ-team-seed"
                style={{
                    marginLeft: "5px",
                }}
            >
                <Typography className="seed-number">
                    {seed ? seed : index + 1}
                </Typography>
            </Box>

            {/* Team Logo */}
            {areSeedsIn && (
                <Box
                    className="super-pickem-champ-team-logo-placeholder"
                    style={{
                        position: "relative", // Required for overlay positioning
                    }}
                >
                    <img src={teamLogo} alt="Team Logo" className="team-logo" />
                </Box>
            )}

            {/* Team Name/Record */}
            <Box className="super-pickem-champ-team-name">
                <Typography
                    className="team-name"
                    style={{
                        color: areSeedsIn ? teamColor : "#002129",
                        fontSize:
                            teamDisplayCharacterCount > 15
                                ? "10px"
                                : teamDisplayCharacterCount > 11
                                ? "12px"
                                : "14px",
                        lineHeight:
                            teamDisplayCharacterCount > 15
                                ? "1.4"
                                : teamDisplayCharacterCount > 11
                                ? ""
                                : "1",
                    }}
                >
                    {areSeedsIn ? teamDisplay : "TBD"}
                </Typography>
            </Box>
        </Box>
    );
};

export default ChampPicksTeamCard;
