// utils/roundUtils.js
import { DateTime } from "luxon";

export function getWeeklyRound(rounds) {
    const now = DateTime.now();

    // Filter rounds to include only those with showUntil dates in the past
    const validRounds = rounds?.filter(round => DateTime.fromISO(round?.showUntil) < now) || [];

    // If there are no valid rounds (i.e., all showUntil dates are in the future), return round 1
    if (validRounds?.length === 0) return 1;

    // Find the round with the most recent showUntil date
    const mostRecentRound = validRounds?.reduce((latest, round) => {
        const showUntilDate = DateTime.fromISO(round?.showUntil);
        return showUntilDate > DateTime.fromISO(latest?.showUntil) ? round : latest;
    });

    return mostRecentRound?.round || 1;
}
