import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCFBTeams } from '../../../hooks/cfb/useCFBTeams';
import CloseIcon from '@mui/icons-material/Close';

const CFBSuperPickemDisplay = ({ league, userPicks }) => {
    const { mutate: fetchCFBTeams, data: CFBTeams, isLoading: CFBTeamsLoading, isError: CFBTeamsError, error: CFBTeamsErrorData } = useCFBTeams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (league === "CFB") {
            fetchCFBTeams();
        }
    }, [fetchCFBTeams, league]);

    console.log("USER PICKS", userPicks);

    const userChampPick = userPicks?.bracketPicks?.find(pick => pick.round === 4);
    console.log("USER CHAMP PICK", userChampPick);
    const champId = userChampPick?.teamId;
    const champResult = userChampPick?.result;

    let champLogo = null;
    if (champId && CFBTeams) {
        champLogo = CFBTeams?.find((team) => team.GlobalTeamID === champId)?.TeamLogoUrl;
    }
    console.log("CHAMP LOGO", champLogo, "CHAMP RESULT", champResult);

    const bracketPoints = userPicks?.bracketPicks?.reduce((acc, pick) => {
        if (pick.points) {
            return acc + pick.points;
        } else {
            return acc;
        }
    }, 0);

    const dailyPicksPoints = userPicks?.picks?.reduce((acc, pick) => {
        if (pick.points) {
            return acc + pick.points;
        } else {
            return acc;
        }
    }, 0);

    const totalPoints = bracketPoints + dailyPicksPoints;


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width:'90%',
            gap: isMobile ? '20px' : '48px'
          }}>
            {isMobile ? (
                // These items will be displayed on mobile devices
              <>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4'>CHAMP</Typography>
                    {champLogo ? (
                        <img 
                            src={champLogo} 
                            alt="Champ Logo" 
                            style={{ 
                                width: '24px', 
                                margin: '0 auto', 
                                border: champResult === "Win" ? '1px solid #00AA72' : champResult === "Loss" ? '1px solid #CC293C' : '', 
                                backgroundColor: champResult === "Win" ? '#CCEEE3' : champResult === "Loss" ? 'rgba(204, 41, 60, 0.20)' : ''
                            }} 
                        />
                    ) : (
                        <CloseIcon style={{ color: '#CC293C', fontSize: '20px', marginTop: '-3px' }} />
                    )}
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>TOTAL PTS</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '3px'}}>{totalPoints}</Typography>
                </Box>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>BRACKET PTS</Typography>
                    <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '3px'}}>{bracketPoints}</Typography>
                </Box>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>PICK 'EM PTS</Typography>
                    <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '3px'}}>{dailyPicksPoints}</Typography>
                </Box>
            </>
            ) : (
                // These items will be displayed on desktop devices
                <>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4'>CHAMP</Typography>
                    {champLogo ? (
                        <img 
                            src={champLogo} 
                            alt="Champ Logo" 
                            style={{ 
                                width: '32px', 
                                margin: '0 auto', 
                                border: champResult === "Win" ? '1px solid #00AA72' : champResult === "Loss" ? '1px solid #CC293C' : '', 
                                backgroundColor: champResult === "Win" ? '#CCEEE3' : champResult === "Loss" ? 'rgba(204, 41, 60, 0.20)' : ''
                            }} 
                        />
                    ) : (
                        <CloseIcon style={{ color: '#CC293C', fontSize: '28px', marginTop: '2px' }} />
                    )}
                </Box>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>TOTAL PTS</Typography>
                    <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '5px'}}>{totalPoints}</Typography>
                </Box>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>BRACKET PTS</Typography>
                    <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '5px'}}>{bracketPoints}</Typography>
                </Box>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4' sx={{ whiteSpace : 'nowrap'}}>PICK 'EM PTS</Typography>
                    <Typography className={"pick-em-header-values"} variant='h4' sx={{marginTop: '5px'}}>{dailyPicksPoints}</Typography>
                </Box>
            </>
            )}
        </Box>

    )
}

export default CFBSuperPickemDisplay;