import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Modal } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './PrivateGroup.scss';

const SubgroupCreation = ({ handleClickState }) => {
    const { birdiePoolData, myUserId, handleCreateSubgroup, userSubgroups, setGroupState, AllGameInfo, setClubLeaderboardInfo, setSelectedUser, setSelectedUserId, myUserName } = useBirdieContext();
    const gameID = birdiePoolData.gameID;
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState(false);
    const [creation, setCreation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const userSubgroupNames = userSubgroups.map(subgroup => subgroup.name);
    const allSubgroupNames = birdiePoolData.subGroups.map(subgroup => subgroup.groupName);
    //console.log("All Subgroup Names:", allSubgroupNames);

    const handleSave = () => {
        // Check if groupName is already in userSubgroupNames
        if (allSubgroupNames.includes(groupName)) {
            setGroupNameError(true);
            setErrorMessage('That Party Name is already in use.');
            return;
        }

        const pushIntoClubSubgroups = {
            groupName: groupName,
            groupMembers: [myUserId],
            groupComissioner: myUserId, // I think this can go away. The gameCreator is ALWAYS THE Main commissioner. And any other commissioners will be in coCommisioners
            coCommissioners: [],
            gameCreator: myUserId,
            inactiveUsers: [],
        }
        const pushIntoUsersSubgroups = {
            name: groupName,
            commissioner: true,
            creator: true
        }
        const dataForSubgroup = {
            gameID, pushIntoClubSubgroups, myUserId, pushIntoUsersSubgroups
        };
        //console.log("Push into Club Subgroup in game with GameID:", gameID, pushIntoClubSubgroups);
        //console.log("Push into Users Subgroup for userID:", myUserId, pushIntoUsersSubgroups);
        // Add logic here to save the group with the given name
        handleCreateSubgroup(dataForSubgroup);
        setCreation(true);
    }

    const handleChange = (event) => {
        const value = event.target.value;
        setGroupName(value);
        // Check if groupName length exceeds 40 characters
        if (value.length > 40) {
            setGroupNameError(true);
            setErrorMessage("Group name should not exceed 40 characters");
        } else {
            setGroupNameError(false);
            setErrorMessage('');
        }
    }

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    return (
        <>
            <h3 style={{ color: "white", background: "#002F3A",fontSize: isMobile ? '12px' : '18px', fontWeight: '700' }}>My Party Creation</h3>
            <h1 style={{ fontSize: '28px', fontWeight: '800', marginTop: '20px', marginBottom: '20px'}}>Party Name</h1>
            <div className="private-group-container">
                <TextField
                    id="private-party-name"
                    label="My Party Name"
                    variant="outlined"
                    fullWidth
                    className="private-party-input"
                    value={groupName}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && groupName) {
                            handleSave();
                        }
                    }}
                    error={groupNameError} // Set error state
                    helperText={errorMessage} // Display error message
                    sx={{ width: isMobile ? '90%' : '450px' }} // Set custom width
                    autoComplete="off" // Disable autocomplete dropdown
                />
            </div>

            <Button
                sx={{ marginTop: '20px', marginBottom: isMobile ? '0px' : '200px'  }}
                className="button"
                variant="contained"
                color="primary"
                disabled={!groupName || groupNameError} // Disable button if no text entered or if groupNameError is true
                onClick={handleSave}>CREATE A PARTY
            </Button>

            {creation && (
                <Modal
                    open={creation}
                    onClose={() => {}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <h2 id="modal-modal-title" style={{ fontSize: '28px', fontWeight: '800', marginBottom:'15px'}}>Party Time.</h2>

                        <p id="modal-modal-description" style={{ fontSize: '24px', fontWeight: '800', marginBottom: '15px'}}>You're the host of The {isMobile && groupName.length > 20 ? `${groupName.slice(0, 20)}...` : groupName} Party.</p>                        
                        <Button
                            className="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                window.location.href = `/birdie-pool/${uriEncoded(gameID)}/MY_PARTY/${uriEncoded(groupName)}`;
                                // setCreation(false);
                                // setGroupState("crazy crawdads"); // Set the groupState to the newly created groupName, making sure local storage is updated and leaderboard is updated
                                // const matchingEntry = AllGameInfo.find(entry => entry.name === "crazy crawdads");  //Change "crazy crawdads" to groupName
                                // setClubLeaderboardInfo(matchingEntry);
                                // setSelectedUser(myUserName);
                                // handleClickState('MY_PARTY');
                            }}>MY PARTY
                        </Button>
                        <p id="modal-modal-description" style={{ fontSize: '18px', marginTop: '15px'}}><b>For Help Building Your Party:</b></p>
                        <p id="modal-modal-description">
                            <a href="mailto:admin@poolpartygaming.com" style={{ fontSize: '20px', textDecoration: 'underline', color: '#00AAD1' }}>
                            admin@poolpartygaming.com
                            </a>
                        </p>
                        <p id="modal-modal-description" style={{ fontSize: '18px'}}>412-418-4554</p>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default SubgroupCreation;
