import React from "react";
import { Typography, Box } from '@mui/material';
import './PickStatsCard.scss';
import { has } from "lodash";

const PickStatsInfoBox = ({
    hasGameStarted,
    pickType,
    gameStatus,
    logo,
    pickCount,
    text,
    result,
    record,
}) => {

    return (
        <Box className="super-pickem-pick-stats-info-box-container">
                {pickType === 'spread' && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '8px',
                        }}
                    >
                        <img 
                            src={logo} 
                            alt="Logo" 
                            className="super-pickem-pick-stats-team-logo"
                        />
                    </Box>
                )}

                {pickType === 'total' && (
                    <Box className="super-pickem-pick-stats-info-total-text-container">
                        <Typography className="super-pickem-pick-stats-info-total-text">
                            {text}
                        </Typography>
                    </Box>
                )}

                <Typography className="super-pickem-pick-stats-info-header-text">
                    {gameStatus === 'Final' || gameStatus === 'F/OT' ? 'Pool Results' : 'Times Picked'}
                </Typography>

                <Typography 
                    className="super-pickem-pick-stats-info-main-text"
                    sx={{
                        color: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129',
                    }}    
                >
                    {!hasGameStarted ? 'TBD' : (gameStatus === 'Final' || gameStatus === 'F/OT') ? `${result}` : `${pickCount}` }
                </Typography>

                {(gameStatus === 'Final' || gameStatus === 'F/OT') && (
                    <Typography
                        className="super-pickem-pick-stats-info-conditional-text"
                        sx={{
                            color: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129',
                        }} 
                    >
                        {result !== 'Split' ? `${pickCount}` : `${record}`}
                    </Typography>
                )}
        </Box>

    );
}

export default PickStatsInfoBox;