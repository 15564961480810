import React from 'react';
import { Box, Typography } from '@mui/material';
import './GameNotReadyBox.scss';

const GameNotReadyBox = ({
    notReadyModal
}) => {
    //console.log("Not Ready Modal Data:", notReadyModal);
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
            <Box className="not-ready-box">
                {/* Header Section */}
                {notReadyModal?.headerText && (
                    <Typography className="header">
                        {notReadyModal.headerText}
                    </Typography>
                )}
                
                {/* Content Text */}
                {notReadyModal?.contentText && notReadyModal.contentText.map((text, index) => (
                    <Typography 
                        key={index} 
                        className="text"
                    >
                        {text}
                    </Typography>
                ))}
                
                {/* Logo */}
                {notReadyModal?.logo && (
                    <Box className="logo-container">
                        <img src={notReadyModal.logo} alt="Logo" />
                    </Box>
                )}
                
                {/* Subheader */}
                {notReadyModal?.subHeaderText && (
                    <Typography 
                        className={`subheader ${!notReadyModal?.logo ? 'no-logo' : ''}`}
                    >
                        {notReadyModal.subHeaderText}
                    </Typography>
                )}
                
                {/* Subcontent */}
                {notReadyModal?.subContentText && notReadyModal.subContentText.map((text, index) => (
                    <Typography 
                        key={index} 
                        className="text"
                    >
                        {text}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default GameNotReadyBox;