import React from 'react';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch,connect } from 'react-redux';
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { createGamesRequest } from '../../../redux/actions/gameImport';
import { useGameContext } from '../context/createGamesContext';
import BlockPoolChoices from '../createGamesAdditional/blockPoolChoices';
// import '../styles.scss';


const CreateGameUniversalFormElements = () => {

    const {     handleCreateGames,

        updateGameTypeData,
        LeagueSection,
        HandlePoolType,
        gameName, setGameName,
    gameCommissioner, setGameCommissioner,
    gamePlayers, setGamePlayers,
    gameExpirationDate, setGameExpirationDate,
    isLive, setIsLive,
    gameType, setGameType,
    gameSport, setGameSport,
    gameLeague, setGameLeague,
    duration, setDuration,
    gameTeams, setGameTeams,
    numberOfBlocks, setNumberOfBlocks,
    gameMembers, setGameMembers,
     setGameTypeData,handleSubmit } = useGameContext();

    return (
        <>
            {/* <p>Game Commissioner</p>
            <p>If you would like to create this game for another commisioner please add their email here.</p>
            <input type="input" placeholder="Alternative Commissioner" onChange={(e) => setGameCommissioner(e.target.value)} />
             */}
            <p>Sport:</p>
            <select onChange={(e) => setGameSport(e.target.value)}>
                <option value="default">Select Your Sport</option>
                <option value="basketball">Basketball</option>
                <option value="football">Football</option>
                <option value="baseball">Baseball</option>
                <option value="soccer">Soccer</option>
                <option value="hockey">Hockey</option>
                <option value="golf">Golf</option>
            </select>
            <LeagueSection/>
            <p>Game Type</p>
            <select onChange={(e) => setGameType(e.target.value)}>
                <option value="">Select Game Type</option>
                <option value="Pickem">Pickem</option>
                <option value="Survivor">Survivor</option>
                <option value="Block_Pool">Block Pool</option>
            </select>
        </>
    );
};
export default CreateGameUniversalFormElements;