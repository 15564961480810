import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useNavigate } from 'react-router-dom';
import ProcessNavBar from '../../../utils/ProcessNavBar.js'

const GameNavBar = () => {
    const {
        masterPoolId,
        gameId,
        page,
        pickIndex,
        gameData,
        isUserInThisPool,
        isUserAdmin,
        masterPoolData,
        isThisPoolPartyGame,
        unsavedPicks,
        unsavedBracketPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
    } = useUserAndGameContext();

    const [processedNav, setProcessedNav] = useState(null);

    useEffect(() => {
        if (masterPoolData?.navBarInfo) {
            const processed = ProcessNavBar(masterPoolData.navBarInfo, {
                isUserInThisPool,
                isUserAdmin,
                isThisPoolPartyGame,
                gameData
            });
            setProcessedNav(processed);
        }
    }, [gameId, masterPoolId, masterPoolData, isUserInThisPool, isUserAdmin, isThisPoolPartyGame, gameData]);


    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:767px)');
    const isSmallScreen = useMediaQuery('(max-width:360px)');
    const navigate = useNavigate();

    // Get Nav Bar Info from Master Pool Data
    const navBarInfo = masterPoolData?.navBarInfo;
    //console.log("NavBarInfo:", navBarInfo);

    // Early return if no navBarInfo
    if (!navBarInfo) {
        //console.log("No navBarInfo found, returning null");
        return null;
    }

    const handleNavClick = (navPage) => {
        const queryParams = new URLSearchParams();
        queryParams.set('mId', masterPoolId);
        queryParams.set('gId', gameId);
        queryParams.set('page', navPage);
        if (pickIndex) {
            queryParams.set('pickIndex', pickIndex);
        }

        const newUrl = `?${queryParams.toString()}`;

        if (unsavedPicks || unsavedBracketPicks) {
            setUrlForNav(newUrl);
            setUnsavedPicksModalOpen(true);
            return;
        }

        navigate(newUrl);
    };

    return (
        <Box
            sx={{
                margin: '0 auto',
                // borderBottom: `4px solid ${navBarInfo.navBarBorderColor || '#FF6C0A'}`,
                backgroundColor: navBarInfo.navBarBackgroundColor || '#07356B',
            }}
        >
            <Box
                sx={{
                    width: isMobile ? '98%' : '90%',
                    // maxWidth: '305px',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: processedNav?.items?.length === 1 ? 'center' : 'space-evenly',
                    alignItems: 'center',
                    gap: '2px',
                    height: '50px'
                }}
            >
                {processedNav?.items?.map((item, index) => (
                    <Box
                        key={`${item.page}-${index}`}
                        onClick={() => handleNavClick(item.page)}
                        sx={{
                            cursor: 'pointer',
                            color: page === item.page
                                ? navBarInfo.navBarActiveTextColor
                                : navBarInfo.navBarTextColor,
                            marginTop: '4px',
                            marginBottom: '4px',
                            padding: '4px',
                            border: `1px solid ${navBarInfo.navBarBorderColor}`,
                            borderRadius: '4px',
                            transition: 'all 0.2s ease',
                            ...(isMobile
                                ? {} // Remove hover styles for mobile
                                : {
                                    '&:hover': {
                                        bgcolor: '#07356B',
                                        color: '#ffffff',
                                        border: '1px solid #07356B',
                                    }
                                }),
                            width: isMobile ? '100%' : 'auto',
                            maxWidth: '33%',
                            textAlign: 'center',
                            flex: 1,
                            backgroundColor: page === item.page
                                ? navBarInfo.navBarActiveBackgroundColor
                                : navBarInfo.navBarBackgroundColor,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: isSmallScreen ? '11px' : isMobile ? '12px' : '16px',
                                fontWeight: 700,
                                whiteSpace: 'nowrap',
                                padding: isMobile ? '0px' : '0px 24px',
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default GameNavBar;