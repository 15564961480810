import React, { useState } from "react";
import { Box, Table, TableRow, TableHead, TableBody, TableCell, Typography, Checkbox } from "@mui/material";
import { useUpdatePool } from "../../../hooks/pools/useUpdatePool";
import { useNFLPickEmContext } from "../context/NFLPickEmContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

//const PoolRankingsTable = ({ myUserId, gameId, userSubgroups, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName } ) => {
const PoolRankingsTable = () => {
    const { mutate } = useUpdatePool();
    const { gameData, myUserId, gameAddress, mainGameId, userSubgroups, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, leaderboardInfo } = useNFLPickEmContext();
    //console.log("Leaderboard Info:", leaderboardInfo, userSubgroups);
    //console.log("Game Data:", gameData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("User Subgroups:", userSubgroups);

    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleGameClick = (id, name) => {
        //console.log("Game Clicked:", id, name);
        setPartyState(name);
        setPartyId(id);
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("LEADERBOARD")}/${uriEncoded(id)}`);
    }
        

    const handleDefaultPartyChange = async (updateValue, updateName) => {
        if (updateValue === partyId) {
            return;
        }

        let picksData = {
            updateKey: "defaultParty",
            updateValue: updateValue,
            userID: myUserId,
            gameId: mainGameId,
        };
        //console.log("Picks Data:", picksData);
            
        try {
            await mutate(picksData, {
                onSuccess: async () => {
                    try {
                        //console.log("Picks submitted successfully");
                        setPartyState(updateName);
                        setPartyId(updateValue);
                        setDefaultPartyId(updateValue);
                        setDefaultPartyName(updateName);
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                },
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
        }
    }

    function myRank(leaderboard, myUserId, subGroupId) {
        let leaderboardToUse = leaderboard;
        if (subGroupId) {
            const subGroupUserIds = gameData?.subGroups?.find(subGroup => subGroup.groupID === subGroupId)?.groupMembers;
            const subGroupLeaderboard = leaderboard?.filter(user => subGroupUserIds?.includes(user.userId));
            //console.log("Subgroup Leaderboard:", subGroupLeaderboard);
            leaderboardToUse = subGroupLeaderboard;
        }
        // Find the user's points
        const user = leaderboardToUse.find(user => user.userId === myUserId);
        if (!user) {
            return "User not found";
        }
    
        const myPoints = user.totalPoints;
    
        // Sort the leaderboard by points in descending order
        const sortedLeaderboard = leaderboardToUse
            .filter(user => user.totalPoints !== undefined) // Filter out any entries without points
            .sort((a, b) => b.totalPoints - a.totalPoints);
    
        // Find the rank of the user with tie handling
        let rank = 1; // Start ranking from 1
        for (let i = 0; i < sortedLeaderboard.length; i++) {
            if (i > 0 && sortedLeaderboard[i].totalPoints < sortedLeaderboard[i - 1].totalPoints) {
                rank = i + 1; // Update rank for new score
            }
            if (sortedLeaderboard[i].userId === myUserId) {
                return rank; // Return rank if this is the user
            }
        }
    
        return "User not found"; // Fallback in case user is not found
    }

    function subGroupLength(subGroupId) {
        const subGroupUserIds = gameData?.subGroups?.find(subGroup => subGroup.groupID === subGroupId)?.groupMembers;
        return subGroupUserIds?.length;
    }

    const overallRank = myRank(leaderboardInfo, myUserId);    
    const partyRank = myRank(leaderboardInfo, myUserId, '19e426ff-09ff-4cc7-b91d-560a40cb4aec');
    //console.log("Party Rank:", partyRank);
    const overallLeaderboardLength = leaderboardInfo?.length;
    //console.log("Overall Leaderboard Length:", overallLeaderboardLength, "Overall Rank:", overallRank);

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 32px 0 32px',
                flexDirection: 'column',
                alignSelf: 'stretch',
                alignContent: 'center',
                justifyContent: 'center',
                maxWidth: '500px',
                margin: '0 auto',
                marginTop: '32px',
            }}
        >
            <Typography
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: isMobile ? '16px' : '24px',
                    fontWeight: 700,
                    textDecorationLine: 'underline',
                }}
            >
                My Party
            </Typography>
            <Box>
                <Table sx={{ tableLayout: 'fixed', width: '100%', border: 'none' }}>
                    <TableHead>
                        <TableRow sx={{ borderBottom: 'none' }}>
                            <TableCell style={{ width: '142px', borderBottom: 'none' }}>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Party
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '83px', textAlign: 'center', borderBottom: 'none' }}>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Rank
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '108px', textAlign: 'center', borderBottom: 'none' }}>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textDecorationLine: 'underline',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    Default Pool
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* First static row */}
                        <TableRow sx={{ borderBottom: 'none' }}>
                            <TableCell style={{ width: '142px', padding: '0px', borderBottom: 'none' }}>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleGameClick(mainGameId, "Pool Party")}
                                >
                                    Pool Party Main Event
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '83px', padding: '0px', textAlign: 'center', borderBottom: 'none' }}>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {overallRank}
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#002129',
                                        fontSize: '8px',
                                        fontWeight: '500',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    Out of {overallLeaderboardLength}
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: '108px', padding: '0px', textAlign: 'center', borderBottom: 'none' }}>
                                <Checkbox
                                    checked={partyId === mainGameId}
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        padding: 0
                                    }}
                                    onChange={() => handleDefaultPartyChange(mainGameId, "Pool Party")}
                                />
                            </TableCell>
                        </TableRow>

                        {/* Dynamically created rows */}
                        {userSubgroups?.map((subgroup, index) => (
                            <TableRow key={index} sx={{ borderBottom: 'none' }}>
                                <TableCell style={{ width: '142px', padding: '0px', borderBottom: 'none' }}>
                                    <Typography
                                        style={{
                                            color: '#002129',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleGameClick(subgroup.subGroupID, subgroup.name)}
                                    >
                                        {subgroup.name}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '83px', padding: '0px', textAlign: 'center', borderBottom: 'none' }}>
                                    <Typography
                                        style={{
                                            color: '#002129',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {myRank(leaderboardInfo, myUserId, subgroup.subGroupID)}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: '#002129',
                                            fontSize: '8px',
                                            fontWeight: '500',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        Out of {subGroupLength(subgroup.subGroupID)}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '108px', padding: '0px', textAlign: 'center', borderBottom: 'none' }}>
                                    <Checkbox
                                        checked={partyId === subgroup.subGroupID}
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            padding: 0
                                        }}
                                        onChange={() => handleDefaultPartyChange(subgroup.subGroupID, subgroup.name)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

export default PoolRankingsTable;
