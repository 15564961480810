import React, {useEffect, useState,} from 'react';
import { useGameContext } from '../context/createGamesContext';
// import '../styles.scss';
import { connect } from 'react-redux';


const PickemChoices = (props) => {
    const { setGameName, GameSchedule, handleGetNFLSchedule, handleGetNFLScheduleBasics, commissionerNotes,  setCommissionerNotes, setSelectedGameData,handleGetNFLGamesByWeek, pickemChoices, setPickemChoices, updatePickem } = useGameContext();
    const { sportsData } = props;
    const [weekly, setWeekly] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedGames, setSelectedGames] = useState([]);
    const [messageBoardCheck, setMessageBoardCheck] = useState(false);
    const [seasonData, setSeasonData] = useState([]);

    useEffect(() => {
        try{
            handleGetNFLSchedule();
        }catch(err){
            console.log('error', err);
        }
    }, []);

    // // Inner useEffect to update the pickem with the fetched data
    useEffect(() => {
        if (sportsData?.seasonData?.seasonData) {
        // Data is available, update pickem here
        updatePickem({ allGames: sportsData.seasonData.seasonData });
        }
    }, [sportsData?.seasonData?.seasonData]);


    const handlePoolDuration = (e) => {
        updatePickem({ poolDuration: e });
    };

    const handleStartingWeek = (e) => {
        let seasonList = sportsData.seasonData.seasonData;
        let seasonData = seasonList.filter((game) => game.Week >= parseInt(e));

        updatePickem({ startingWeek: parseInt(e) , allGames: seasonData });
    }

    const handlePicksPerWeek = (e) => {
        console.log(e == "all");
        updatePickem({ picksPerWeek: e});
    };

    const handlePickDeadline = (e) => {
        updatePickem({ pickDeadline: e });
    };

    const handleSpreadBet = (e) => {
        updatePickem({ spreadBet: e });
    };

    const handleMaxEntries = (e) => {
        updatePickem({ maxEntries: e });
    };

    const handleTiebreaker = (e) => {
        updatePickem({ tiebreaker: e });
    };

    const handleSpreadInfo = (e) => {
        console.log(e);
        updatePickem({ spreadInfo: e });
    };
    const handleCommissionerNotesChange = (e) => {
        setCommissionerNotes(e.target.value);
      };

    const RenderWeekOptions = () => {
        if (!sportsData || !sportsData.seasonData.seasonData || !Array.isArray(sportsData.seasonData.seasonData)) {
            return <option>Loading...</option>; // Render a loading state or a placeholder option
          }
        // Function to filter data based on the season
        const filterDataBySeason = (array, season) => {
            return array.filter(obj => obj.Season === season);
        };

        const filteredData = filterDataBySeason(sportsData.seasonData.seasonData, 2023);
        const numberOfTeams = sportsData?.nflTeamsData?.nflTeams?.length;

        // Calculate how much data each team should receive
        const dataPerTeam = filteredData.length / numberOfTeams;

        let finalGameWeek = 0;
        for (const game of filteredData) {
        if (game.Week > finalGameWeek) {
            finalGameWeek = game.Week;
        }
        }
        console.log(finalGameWeek);

        // Create an array to hold the sliced data for each team
        const dataPerTeamArray = [];

        // Loop through the number of teams and slice the data accordingly
        for (let i = 0; i < finalGameWeek; i++) {
            const startIndex = Math.floor(i * dataPerTeam);
            const endIndex = Math.floor((i + 1) * dataPerTeam);
            const teamData = filteredData.slice(startIndex, endIndex);
            dataPerTeamArray.push(teamData);
        }
        return dataPerTeamArray.map((game, index) => (
            <option key={index + 1} value={index + 1}>
            Week {index + 1}
            </option>
        ));
    };

    const RenderSpreadInfo = () => {
        return(
            <>
                <label htmlFor="spread_info">Spread Info</label>
                <select id="spread_info" onChange={(e) => handleSpreadInfo(e.target.value)}>
                    <option value="true_spread">True Spread</option>
                    <option value="rounded_up">Rounded Up</option>
                </select>
            </>
        );
    };


    return(
        <>
            <label htmlFor="game_name">Pick 'em Pool Name</label>
            <input placeholder="Enter the title for your game" id="game_name" type="input" onChange={(e) => setGameName(e.target.value)} />
            <label htmlFor="pool_duration">Pool Duration</label>
            <select onChange={(e) => handlePoolDuration(e.target.value)}>
                <option value="regular">Regular Season Only</option>
                <option value="post">Regular Season and Post</option>
            </select>
            <label htmlFor="starting_week">Starting Week</label>
            <select onChange={(e)=>handleStartingWeek(e.target.value)} value={pickemChoices.startingWeek}>
                <RenderWeekOptions />
            </select>
            <label htmlFor="picks_per_week">Picks Per Week</label>
            <select id="picks_per_week" onChange={(e) => handlePicksPerWeek(e.target.value)}>
                <option value="5">5</option>
                <option value="all">All</option>
            </select>
            <label htmlFor="pick_deadline">Pick Deadline</label>
            <select id="pick_deadline" onChange={(e) => handlePickDeadline(e.target.value)}>
                <option value="kickoff">Kickoff of Each Game</option>
                <option value="sunday_at_one">Sunday 1:00 PM EST</option>
            </select>
            <label htmlFor="spread_bet">Spread Bet</label>
            <select id="spread_bet" onChange={(e) => handleSpreadBet(e.target.value)}>
                <option value="straight">Straight Up</option>
                <option value="against_the_spread">Against the Spread</option>
            </select>
            {pickemChoices.spreadBet === "against_the_spread" &&
                <RenderSpreadInfo/>
            }
            {pickemChoices.picksPerWeek == "all" ?
                <>
                    <label htmlFor="max_entries">Maximum Entries Per Entrant</label>
                    <select id="max_entries" onChange={(e) => handleMaxEntries(e.target.value)}>
                        <option value="unlimited">Unlimited</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                    </select>
                </>
                :
                null
            }
            <label htmlFor="tiebreaker">Weekly Tiebreaker</label>
            <select id="tiebreaker" onChange={(e)=>handleTiebreaker(e.target.value)}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <p>Commissioner's Notes</p>
          <textarea name="commissioner-notes" value={commissionerNotes} onChange={handleCommissionerNotesChange} />
        </>
    );
};


const mapStateToProps = (state) => ({

    sportsData: state.sportsData,
    seasonData: state.seasonData
});

// Connect the component to the Redux store
export default connect(mapStateToProps)(PickemChoices);
