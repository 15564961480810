import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BirdiePoolDisplay = ({ userPicks }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const birdieInfo = () => {
        let holesBirdied = 0;
        let totalBirdies = 0;
        userPicks?.forEach((pick) => { 
          if (pick.birdieDates.length > 0) {
            holesBirdied ++;
            totalBirdies += pick.birdieDates.length;
          }
        }
        );
        return { holesBirdied, totalBirdies };
      };

      const { holesBirdied, totalBirdies } = birdieInfo();

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width:'90%',
            gap: isMobile ? '32px' : '48px'
          }}>
            {isMobile ? (
              // These items will be displayed on mobile devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>HOLES BIRDIED</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{holesBirdied}/18</Typography>
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>TOTAL BIRDIES</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonWins}-{seasonLosses}-{seasonTies}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{totalBirdies}</Typography>

                </Box>
              </>
            ) : (
              // These items will be displayed on desktop devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>HOLES BIRDIED</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{holesBirdied}/18</Typography>
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>TOTAL BIRDIES</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{totalBirdies}</Typography>
                </Box>
              </>
            )}
          </Box>
    );

}

export default BirdiePoolDisplay;