import React from 'react';
import { LeaderboardsPageProvider } from '../components/LeaderboardsPage/context/LeaderboardsPageContext';
import LeaderboardPageContainer from '../components/LeaderboardsPage/LeaderboardPageContainer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const LeaderboardPage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <LeaderboardsPageProvider>
                    <LeaderboardPageContainer />
                </LeaderboardsPageProvider>
            </QueryClientProvider>
        </div>
    );
};

export default LeaderboardPage;