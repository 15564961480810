import React from "react";
import { Box, Typography } from "@mui/material";
import { CardMedia } from '@mui/material';
import { useGetPrivatePools } from '../components/PrivatePools/hooks/useGetPrivatePools';
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import PrivatePoolsList from "../components/PrivatePools/PrivatePoolsList";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from "../components/PoolHeaderBar/StaticPoolHeaderBar";

const BirdiePoolDemoPage = () => {
  const { data, error, isLoading } = useGetPrivatePools();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isLoading) return <LoadingScreen />
  if (error) return <div>An error occurred: {error.message}</div>;

  const filteredData = data?.filter(pool => pool.gameName === "Pool Party Demo Birdie Pool");

  return (
    <>
      <StaticPoolHeaderBar navigationText="Home" headerText="Birdie Pool Demo" linkForNavigation="/" />
      
      <Box>
        <Typography
          variant="h1"
          sx={{
            color: '#002129',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            marginTop: '24px',
            marginBottom: '12px'
          }}
        >
          Pool Party's Birdie Pool
        </Typography>

        <Typography
          sx={{
            color: '#002129',
            textAlign: 'center',
            fontSize: isMobile ? '18px' : '20px',
            fontWeight: 700,
            marginTop: '16px',
          }}
        >
          The race to birdie all 18 holes.
        </Typography>
        <Typography
          sx={{
            color: '#002129',
            textAlign: 'center',
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: 400,
            marginTop: '16px',
          }}
        >
          The Birdie Pool is a season long birdie<br/> challenge held within your club.
        </Typography>

        <Box
          sx={{
            maxWidth: '100%',
            margin: '0 auto',
            marginTop: '32px',
            marginBottom: '16px',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Column layout for mobile, row for desktop
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: isMobile ? '0px' : '128px',
            //alignItems: isMobile ? 'flex-start' : 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: isMobile ? '327px' : '375px',
              padding: isMobile ? '0 0 16px 0' : '0 0',
              margin: isMobile ? '0 auto' : '0',
            }}
          >
            <Typography
              style={{
                fontSize: isMobile ? '18px' : '20px',
                fontWeight: '700',
                textAlign: 'center',
                textDecoration: 'underline',
                color: '#292929'
              }}
            >
              Club-Wide Competitions
            </Typography>
            <Typography
              style={{
                fontSize: isMobile ? '16px' : '20px',
                fontWeight: '400',
                textAlign: 'center',
                color: '#292929',
                marginTop: '8px'
              }}
            >
              Golfers will compete to be the first to birdie every 
              hole on your course. Even after a champion is crowned, 
              golfers can continue the quest to conquer all 18 holes 
              and track their total birdies for the year.
            </Typography>
          </Box>

          <Box
            sx={{
              maxWidth: isMobile ? '327px' : '375px',
              padding: isMobile ? '0 0 16px 0' : '0 0',
              margin: isMobile ? '0 auto' : '0',
            }}
          >
            <Typography
              style={{
                fontSize: isMobile ? '18px' : '20px',
                fontWeight: '700',
                textAlign: 'center',
                textDecoration: 'underline',
                color: '#292929',
                marginTop: isMobile ? '32px' : 0, // Adjust margin only for mobile
              }}
            >
              My Party Competitions
            </Typography>
            <Typography
              style={{
                fontSize: isMobile ? '16px' : '20px',
                fontWeight: '400',
                textAlign: 'center',
                color: '#292929',
                marginTop: '8px'
              }}
            >
              The My Party feature allows any member to create 
              their own custom group competition. Be it your friends, 
              rivals with similar handicaps, or even head-to-head matchups - 
              this feature opens up the pool to accommodate golfers of all skill levels.
            </Typography>
          </Box>
        </Box>

        {!isMobile && ( 
        <Box
            sx={{
              borderBottom: '2px solid #000',
              width: '100%', // Optional: Set the width as needed
              marginTop: '40px',
              marginBottom: '32px'
            }}
        />
        )}


        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: isMobile ? 'center' : 'flex-start',
            gap: '130px',
            textAlign: 'center',
            marginBottom: '40px',
            maxWidth: isMobile ? '100%' : '1116px',
            margin: '0 auto'
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '360px' }}>
            <Typography
              variant="body1"
              sx={{
                color: '#292929',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '170%', // 40.8px
                letterSpacing: '-0.456px',
                marginBottom: '12px',
                marginTop: isMobile? '0px' : '27px'
              }}
            >
              Video Tutorial
            </Typography>
            <CardMedia
              component="video"
              src={"https://poolpartyfilestorage.blob.core.windows.net/media/birdie-pool-demo.mp4"}
              allow="autoPlay"
              controls
              sx={{ width: '100%', height: 'auto', marginBottom: '40px' }}
            />
          </Box>

          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center',maxWidth: '360px' }}>
            <PrivatePoolsList pools={filteredData} linkForLogin={'/login/birdie-pool-demo'} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BirdiePoolDemoPage;
