// Function to format the Game Date and Time for the SuperPickem Pick Card
export function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
  
    // Format the date to get the desired output: Sat Nov 16
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
    });
    const formattedDate = dateFormatter.format(date);
  
    // Format the time to get 7:30PM
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    const formattedTime = timeFormatter.format(date);
  
    return `${formattedDate} - ${formattedTime} ET`;
}


// Function to handle a pick selection
export const handlePick = (pickType, pickValue, gameId, interval, setPickState, setUnsavedPicks, pickState, line) => {
    const newPick = {
        gameId: gameId,
        pickType: pickType,
        pick: pickValue,
        interval: interval,
        line: line
    };

    setPickState(prevState => {
        // Find an existing pick with the same gameId and pickType
        const existingPickIndex = prevState.findIndex(
            pick => pick.gameId === gameId && pick.pickType === pickType
        );

        if (existingPickIndex !== -1) {
            // If existing pick has the same pick value, remove it from state
            if (prevState[existingPickIndex].pick === pickValue) {
                return prevState.filter((_, index) => index !== existingPickIndex);
            } else {
                // If existing pick has a different value, replace it with new pick
                return prevState.map((pick, index) =>
                    index === existingPickIndex ? newPick : pick
                );
            }
        }

        // No matching pick found; add new pick to state
        return [...prevState, newPick];
    });

    setUnsavedPicks(true); // Mark as having unsaved changes
    console.log("Updated pickState:", pickState);
};

// Function to determine the button colors for the SuperPickem Pick Card
export const buttonState = (type, choice, hasGameStarted, pickState, gameId, isGameOver, winningTeam, winningTotal, userPicksFromDB) => {
    // Check if the pick is in the current state
    if (pickState?.some(pick => pick.gameId === gameId && pick.pickType === type && pick.pick === choice)) {
        return 'active';
    }

    // Check user picks from DB
    const userPick = userPicksFromDB?.find(pick => pick.gameId === gameId && pick.pickType === type && pick.pick === choice);

    if (userPick) {
        if (userPick.result === 'Pending') return 'locked';
        if (userPick.result === 'Win') return 'winner';
        if (userPick.result === 'Loss') return 'loser';
        if (userPick.result === 'Push') return 'push';
    }

    // Check for other picks in the DB with the same gameId and pickType
    if (userPicksFromDB?.some(pick => pick.gameId === gameId && pick.pickType === type && pick.pick !== choice)) {
        return 'inactive';
    }

    // Handle game started but not over
    if (!isGameOver && hasGameStarted) {
        return 'not-chosen';
    }

    // Handle game over for spreads and totals
    if (isGameOver) {
        if (type === 'Spread') {
            return choice === winningTeam ? 'not-chosen' : 'inactive';
        }
        if (type === 'Total') {
            return choice === winningTotal ? 'not-chosen' : 'inactive';
        }
    }

    // Default state
    return '';
};


export const amITied = (rank, key, leaderboard) => {
    // Count how many times the given rank appears under the specified key
    const count = leaderboard?.filter(user => user[key] === rank).length;

    // Return true if count > 1 (there's a tie), otherwise false
    return count > 1;
};
