import React from 'react';
import { useBirdieContext } from "../context/BirdiePoolContext";
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const SubgroupDropdown = () => {
  const { userSubgroups, birdiePoolData, groupState, setGroupState, headerDisplayName, setClubLeaderboardInfo, AllGameInfo, setSelectedUser, myUserName, setSelectedUserId, myUserId } = useBirdieContext();

  const userGroups = userSubgroups?.map(subgroup => subgroup.name);
  const startingName = headerDisplayName;

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const { gameId, component, groupStateCheck } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // Create an array of all the group names plus the header display name
    const groupNames = [startingName, ...userGroups];

  // Handle change event for the Select component
  const handleChange = (e) => {
    setGroupState(e.target.value);
    //console.log("NEW STATE", e.target.value);
    const matchingEntry = AllGameInfo.find(entry => entry.name === e.target.value);
    //console.log("Matching Entry", matchingEntry);
    setClubLeaderboardInfo(matchingEntry);
    setSelectedUser(myUserName);
    setSelectedUserId(myUserId);
    navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(e.target.value)}`);

  };


  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: "#FFC60A", height: isMobile ? '39px' : '50px', borderBottom: "2px solid #000", margin: '0 auto'}}>
      <h2 style={{ fontSize: isMobile ? '20px' : '26px', fontWeight: '700', marginRight: '10px' }}>Party:</h2>
      <select
        className='border border-solid border-gray-900 box-border rounded text-sm p-1'
        value={groupState}
        onChange={handleChange}
        style={{ textAlign: 'center', padding: '5px', borderColor: '#002129', fontSize: isMobile ? '9px' : '12px', width: isMobile ? '162px' : '200px'}}
      >
        {groupNames.map(groupName => (
          <option key={groupName} value={groupName}>{isMobile && groupName.length > 30 ? `${groupName.slice(0, 30)}...` : groupName}</option>
        ))}
      </select>
    </div>
    );
};

export default SubgroupDropdown;
