import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useUpdateHostNotes = () => {
    return useMutation({
        mutationFn: updateHostNotesRequest,
        onError: (error) => {
        console.error("Error updating host notes:", error);
        },
        // onSuccess: (data) => {
        // console.log("Host notes updated successfully:", data);
        // },
    });
}

const updateHostNotesRequest = async (hostNotesData) => {
    //console.log("Host Notes Data:", hostNotesData);
    const authorizationHeader = localStorage.getItem("authorization"); // Get auth token from localStorage
    //console.log(hostNotesData);
    const response = await axios.post(
        `${apiUrl}/user/update-host-notes`,
        hostNotesData,
        {
        headers: {
            Authorization: authorizationHeader, // Include the authorization header in the request
            "Content-Type": "application/json",
        },
        }
    );
    return response.data;
};