import { apiClient } from "../../apiClient";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from '../../config';

const resetPasswordRequest = (opts) => {
  const { token, originalPassword, newPassword, confirmPassword } = opts;
  const authorizationHeader = localStorage.getItem('authorization');
  return apiClient.post(`${apiUrl}/user/reset-password`, {
    token,
    newPassword,
    confirmPassword,
    originalPassword,
  }, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPasswordRequest,
  });
};
