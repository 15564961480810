import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useFantasySportsbookContext } from "./context/FantasySportsbookContext";
import { Typography, Box } from "@mui/material";
import MyBets from "./MyBetsPages/MyBets";

const FantasySportsbookContainer = () => {
  const { 
    myUserId,
    pickState,
    setPickState,
    currentGameInterval,
    bankroll,
    betStyles,
    maxParlayLegs,
    maxTeaserLegs,
    leagues,
} = useFantasySportsbookContext();
 

  return (
    <MyBets />
  );
};

export default FantasySportsbookContainer;
