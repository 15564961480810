// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-requirements-container {
  width: 264px;
  height: 100px;
}
.password-requirements-container .password-requirements-list {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  list-style-type: none;
  padding-left: 0;
  font-size: 0.75rem;
  justify-items: left;
  padding-top: 20px;
}

.checkmark,
.text-pass {
  color: green;
}

.cross,
.text-invalid {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/AccountCreation/PasswordStrength.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,kCAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAGA;;EAEE,YAAA;AAAF;;AAGA;;EAEE,UAAA;AAAF","sourcesContent":[".password-requirements-container {\n  width: 264px;\n  height: 100px;\n\n  .password-requirements-list {\n    display: grid;\n    grid-template-rows: repeat(5, 1fr);\n    list-style-type: none;\n    padding-left: 0;\n    font-size: 0.75rem;\n    justify-items: left;\n    padding-top: 20px;\n  }\n}\n\n.checkmark,\n.text-pass {\n  color: green;\n}\n\n.cross,\n.text-invalid {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
