import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useAddEntryTracking = () => {
    return useMutation({
        mutationFn: submitEntryTrackingRequest,
        onError: (error) => {
        console.error('Error adding entry tracking:', error);
        },
        // onSuccess: (data) => {
        // console.log('Entry tracking added successfully:', data);
        // }
    });
}

const submitEntryTrackingRequest = async (entryTrackingData) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.post(`${apiUrl}/games/add-entry-tracking`, entryTrackingData, {
        headers: {
        Authorization: authorizationHeader,  // Include the authorization header in the request
        'Content-Type': 'application/json'
        }
    });  
    console.log('Entry tracking added successfully:', response.data);
    return response.data.data;
}