// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-submission-error-modal {
  position: absolute;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 328px;
  height: auto;
  background-color: #FFF;
  padding: 16px;
  outline: none;
}
.bet-submission-error-modal .modal-title {
  text-align: center;
  color: #002129;
  font-size: 20px;
  font-weight: 800;
  padding-bottom: 20px;
}
.bet-submission-error-modal .modal-description {
  text-align: center;
  color: #002129;
  font-size: 16px;
  font-weight: 600;
}
.bet-submission-error-modal .modal-button {
  width: 115px;
  height: 38px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/GameModals/SportsbookModals/BetSubmissionError.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AACN;AAEI;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAAN;AAGI;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AADN","sourcesContent":[".bet-submission-error-modal {\n    position: absolute;\n    border-radius: 8px;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 328px;\n    height: auto;\n    background-color: #FFF;\n    padding: 16px;\n    outline: none; // Remove default focus behavior\n  \n    .modal-title {\n      text-align: center;\n      color: #002129;\n      font-size: 20px;\n      font-weight: 800;\n      padding-bottom: 20px;\n    }\n  \n    .modal-description {\n      text-align: center;\n      color: #002129;\n      font-size: 16px;\n      font-weight: 600;\n    }\n  \n    .modal-button {\n      width: 115px;\n      height: 38px;\n      margin-top: 20px; // Add top margin\n      display: block;\n      margin-left: auto; // Center horizontally within a flex container\n      margin-right: auto; // Center horizontally within a flex container\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
