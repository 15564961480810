// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-dash-container {
  width: 100%;
  background-color: #E8E8E8;
  border-top: none !important;
  border-bottom: none !important;
  padding-bottom: 4px;
}
@media (max-width: 767px) {
  .user-dash-container {
    border-top: none !important;
    border-bottom: none !important;
  }
}

.user-dash-header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
}
@media (max-width: 767px) {
  .user-dash-header {
    padding: 8px;
  }
}

.user-dash-trophy {
  width: 48px;
}
@media (max-width: 767px) {
  .user-dash-trophy {
    width: 20px;
    padding-right: 5px;
  }
}

.user-dash-username {
  font-size: 40px !important;
  font-weight: 800 !important;
  margin-left: 8px !important;
}
@media (max-width: 767px) {
  .user-dash-username {
    color: #002129;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    line-height: normal;
    font-weight: 800 !important;
    margin-left: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UserDash/UserDash.scss"],"names":[],"mappings":"AAKA;EACI,WAAA;EACA,yBAAA;EACA,2BAAA;EACA,8BAAA;EACA,mBAAA;AAJJ;AAKI;EANJ;IAOM,2BAAA;IACA,8BAAA;EAFJ;AACF;;AAKA;EACE,WAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AAFF;AAGE;EAPF;IAQI,YAAA;EAAF;AACF;;AAGA;EACE,WAAA;AAAF;AACE;EAFF;IAGI,WAAA;IACA,kBAAA;EAEF;AACF;;AACA;EACI,0BAAA;EACF,2BAAA;EACA,2BAAA;AAEF;AADE;EAJF;IAKI,cAAA;IACA,kBAAA;IACA,0BAAA;IACA,kBAAA;IACA,mBAAA;IACA,2BAAA;IACA,gBAAA;EAIF;AACF","sourcesContent":["$breakpoint-xs: 480px;\n$breakpoint-sm: 767px;\n$breakpoint-md: 925px;\n$breakpoint-lg: 1366px;\n\n.user-dash-container{\n    width: 100%;\n    background-color: #E8E8E8;\n    border-top: none !important;\n    border-bottom: none !important;\n    padding-bottom: 4px;\n    @media (max-width: $breakpoint-sm) {\n      border-top: none !important;\n      border-bottom: none !important;\n    }\n  }\n\n.user-dash-header{\n  width:100%;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n  padding: 8px;\n  align-items: center;\n  @media (max-width: $breakpoint-sm) {\n    padding: 8px;\n  }\n}\n\n.user-dash-trophy{\n  width:48px;\n  @media (max-width: $breakpoint-sm) {\n    width: 20px;\n    padding-right: 5px;\n  }\n}\n\n.user-dash-username {\n    font-size: 40px !important;\n  font-weight: 800 !important;\n  margin-left: 8px !important;\n  @media (max-width: $breakpoint-sm) {\n    color: #002129;\n    font-family: Inter;\n    font-size: 16px !important;\n    font-style: normal;\n    line-height: normal;\n    font-weight: 800 !important;\n    margin-left: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
