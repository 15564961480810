import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography, Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useRemoveEntryFromGame } from '../../hooks/pools/useRemoveEntryFromGame';
import ResponseModal from './ResponseModal';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../redux/actions/loginImport';

const RemoveUserModal = ({ 
    open, 
    onClose, 
    userInfo, 
    myUserId, 
    masterPoolId,
    gameId, 
}) => {
    const dispatch = useDispatch();
    
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [modalStatus, setModalStatus] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const { mutate: removeEntryFromGame } = useRemoveEntryFromGame();
    
    // Create entries list with display names and actual entry values
    const entries = userInfo?.entries?.map((entryValue) => ({
        value: entryValue, // The actual value to send to the backend
        displayName: entryValue === 0 
        ? userInfo.username 
        : `${userInfo.username} (${Number(entryValue) + 1})`
    })) || [];

    
    const handleToggleEntry = (entry) => {
        setSelectedEntries(prev => {
        // Check if entry is already selected
        const isSelected = prev.some(item => item.value === entry.value);
        
        if (isSelected) {
            // Remove entry if already selected
            return prev.filter(item => item.value !== entry.value);
        } else {
            // Add entry if not selected
            return [...prev, entry];
        }
        });
    };
    
    const handleRemove = () => {
        let removalData;
        if (entries?.length === 1) {
            // Single entry case
            removalData = {
                masterPoolId,
                gameId,
                indicesToRemove: [entries[0].value],
                usernamesToRemove: [entries[0].displayName],
                userId: userInfo.userId,
                commissionerId: myUserId
            };
        } else {
            // Extract indices and usernames for the backend
            const indicesToRemove = selectedEntries?.map(entry => entry.value);
            const usernamesToRemove = selectedEntries?.map(entry => entry.displayName);
            
            removalData = {
                masterPoolId,
                gameId,
                indicesToRemove,
                usernamesToRemove,
                userId: userInfo.userId,
                commissionerId: myUserId
            };
        }
    
        console.log("Removal Data: ", removalData);
        removeEntryFromGame(removalData, {
            onSuccess: (response) => {
                // Handle success
                setSelectedEntries([]);
                setModalStatus('success');
                setModalMessage(response?.message);
                setModalOpen(true);
                console.log("Successfully removed entry from game");
            }, 
            onError: (error) => {
                // Check for token expiration (401 Unauthorized)
                if (error?.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                    return;
                }
                
                // Handle other errors
                console.error("Error removing entry from game: ", error);
                setModalStatus('error');
                setModalMessage(
                    error.response?.data?.message || 
                    error.message || 
                    'An error occurred while removing the entry. Please try again.'
                );
                setModalOpen(true);
            }
        });
    };
    
    // Disable the remove button if nothing is selected
    const isRemoveDisabled = selectedEntries?.length === 0;
    
    // When modal closes, reset selections
    const handleClose = () => {
        setSelectedEntries([]);
        onClose();
    };
  
  return (
    <>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="remove-user-modal-title"
        >
        <Box
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95%',
            maxWidth: '450px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            }}
        >
            {entries?.length === 1 ? (
            // Single entry case
            <>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#002129',
                        textAlign: 'center',
                    }}
                >
                     Do you want to remove {entries[0].displayName} from the pool?
                </Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleRemove}
                        sx={{
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                                backgroundColor: '#002129',
                            },
                        }}
                    >
                        Remove
                    </Button>
                </Box>
            </>
            ) : (
            // Multiple entries case
            <>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: '700',
                        color: '#002129',
                        textAlign: 'center',
                        mb: 2,
                    }}
                >
                Select entries to remove:
                </Typography>
                
                <FormGroup>
                {entries.map((entry, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox 
                            checked={selectedEntries.some(item => item.value === entry.value)}
                            onChange={() => handleToggleEntry(entry)}
                            />
                        }
                        label={entry.displayName}
                    />
                ))}
                </FormGroup>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={handleClose}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isRemoveDisabled}
                        onClick={handleRemove}
                        sx={{
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                            backgroundColor: '#002129',
                            },
                        }}
                    >
                        REMOVE
                    </Button>
                </Box>
            </>
            )}
        </Box>
        </Modal>

        <ResponseModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            status={modalStatus}
            message={modalMessage}
        />
    </>
  );
};

export default RemoveUserModal;