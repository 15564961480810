import React, { useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box
} from '@mui/material';
import { useAddAffiliateTracking } from '../../../../hooks/pools/useAddAffiliateTracking';


const AffiliatedModal = ({ 
    open,
    onClose,
    affiliateInfo,
    myUserId,
    gameId,
}) => {

    const { mutate: addAffiliateTracking } = useAddAffiliateTracking();
  
    // Form data state
    const [formData, setFormData] = useState({
        affiliation: '',
        foundBy: ''
    });

    // Questions data
    const questions = affiliateInfo?.questions || [];

    // Handle form input changes
    const handleChange = (key, value) => {
        setFormData(prev => ({
        ...prev,
        [key]: value
        }));
    };

    // Handle form submission
    const handleSubmit = () => {
        const data = {
            userId: myUserId,
            gameId: gameId,
            affiliateData: formData,
        };

        //console.log('Survey Results:', data);
        // Additional submission logic can go here
        addAffiliateTracking(data, {
            onSuccess: () => {
                onClose();
            },
            onError: () => {
                onClose();
            }
        });
    };

    return (
        <Dialog 
            open={open}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            // Prevent closing when clicking outside
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                return;
                }
            }}
        >
            <DialogContent>
                <Box sx={{ pt: 2 }}>
                {questions.map((question) => (
                    <Box key={question.key} sx={{ mb: 3 }}>
                        <Typography
                            sx={{ 
                                mb: 1,
                                fontSize: '14px',
                                fontWeight: '500', 
                            }}
                        >
                        {question.text}
                    </Typography>
                    
                    {question.type === "input" ? (
                        <TextField
                        fullWidth
                        variant="outlined"
                        value={formData[question.key]}
                        onChange={(e) => handleChange(question.key, e.target.value)}
                        />
                    ) : question.type === "dropdown" ? (
                        <FormControl fullWidth>
                        <Select
                            value={formData[question.key]}
                            onChange={(e) => handleChange(question.key, e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                            <em>Select an option</em>
                            </MenuItem>
                            {question.dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    ) : null}
                    </Box>
                ))}
                </Box>
            </DialogContent>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: 3, px: 3 }}>
                <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    sx={{
                        height: '36px',
                        color: '#fff',
                        backgroundColor: '#002129', 
                        '&:hover': {
                            backgroundColor: '#002129',
                        },
                    }}
                >
                    ENTER
                </Button>
            </Box>
        </Dialog>
    );
};

export default AffiliatedModal;