import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSaveQuickPickPicks } from "../../../hooks/pools/useSaveQuickPickPicks";
import './QuickPickSubmitBar.scss'
import QuickPickSubmitPicksModal from "../../GameModals/QuickPickModals/QuickPickSubmitPickModal";
import QuickPickNotSavedModal from "../../GameModals/QuickPickModals/QuickPickNotSavedModal";
import ErrorModal from "../../GameModals/ErrorModal/ErrorModal";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";

const QuickPickSubmitBar = ({ 
    myUserId, 
    gameType, 
    mainGameId, 
    picksPerInterval, 
    usingTiebreaker, 
    howManyPicksThisIntervalInState, 
    tiebreakerStateValue, 
    league, 
    pickState, 
    setPickState, 
    userPicksFromDB,
    intervalType,
    interval, 
    firstInterval,
    gameRewards,
    joinReward,
    voidedPicksCount,
    matchedGames = [],
}) => {
    
    const { mutate, isLoading, isError, error, isSuccess } = useSaveQuickPickPicks();

    const dispatch = useDispatch();

    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [checkerData, setCheckerData] = useState({});
    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [internalErrorOpen, setInternalErrorOpen] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState('');
    const [picksSubmitted, setPicksSubmitted] = useState(false);
    
    const handleCancel = () => {
        //console.log("Cancel Hit");
        setPickState(userPicksFromDB);
    }

    const handleSubmit = async () => {
        if (picksSubmitted) return;
        setPicksSubmitted(true);
        //console.log("Submit Hit")
        const picksData = {
            picks: pickState,
            userID: myUserId,
            gameType: gameType,
            gameId: mainGameId,
            league: league,

        }

        if (matchedGames?.length > 0) {
            const matchedIds = matchedGames.map(game => game?.gameId);
            picksData.matchedGames = matchedIds;
        }
     
        if (gameRewards?.length > 0 && !joinReward && gameRewards?.some(reward => reward.interval === 0)) {
            picksData.joinReward = true;
        }
        //console.log("Picks Data:", picksData);
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    //console.log("Response:", response);
                    const checkerData = response?.checkerData;
                    const success = checkerData?.success;
                    //console.log("Checker Data:", checkerData, "Success", success);
                   
                    if (success) {
                        setCheckerData(checkerData);
                        setSubmitModalOpen(true);
                    }
                    if (!success) {
                        const message = checkerData?.message;
                        setErrorMessage(message);
                        setSubmitError(true);
                        setSubmitModalOpen(true);
                    }
                },
                onError: (error) => {
                    console.log("Error submitting picks:", error);
                    const errorMessage = error.message;
                    console.log("ERROR MESSAGE:", errorMessage);
                    
                    // Check for token expiration
                    if (error.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired',
                        }));
                    } else {
                        setInternalErrorMessage(errorMessage);
                        setInternalErrorOpen(true);
                    }
                }
            });
        } catch (error) {
            console.log("Error Submitting Picks:", error);
            const errorMessage = error.message;
            console.log("ERROR MESSAGE:", errorMessage);
            setInternalErrorMessage(errorMessage);
            setInternalErrorOpen(true);
        }
    }
    //console.log("SubmitBar: COUNT", howManyPicksThisIntervalInState)

    return (
        <>
        <Box className="quick-pick-submit-bar-container">
            {/* Box for 3 texts and the icon, all in a row */}
            <Box className="quick-pick-submit-bar-content-row">
                <Box className="quick-pick-submit-bar-text-group">
                    <Typography className="quick-pick-submit-bar-typography">Picks:</Typography>
                    <Typography className="quick-pick-submit-bar-typography">
                        {howManyPicksThisIntervalInState}/{picksPerInterval - voidedPicksCount}
                    </Typography>
    
                    {usingTiebreaker && (
                        <>
                            <Typography className="quick-pick-submit-bar-typography quick-pick-submit-bar-tiebreaker-label">
                                Tiebreaker:
                            </Typography>
                            {tiebreakerStateValue !== undefined ? (
                                <CheckIcon className="quick-pick-submit-bar-icon" style={{ color: '#00AA72' }} />
                            ) : (
                                <CloseIcon className="quick-pick-submit-bar-icon" style={{ color: '#CC293C' }} />
                            )}
                        </>
                    )}
                </Box>
            </Box>
    
            {/* Cancel and Submit buttons */}
            <Box className="quick-pick-submit-bar-button-group">
                <Button 
                    variant="contained" 
                    className="quick-pick-submit-bar-cancel-button" 
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmit}
                    disabled={picksSubmitted}
                >
                    Submit
                </Button>
            </Box>
        </Box>

        {/* Modal for submitting picks */}
        {submitModalOpen && !submitError && (
            <QuickPickSubmitPicksModal
                submitModalOpen={submitModalOpen}
                setSubmitModalOpen={setSubmitModalOpen}
                checkerData={checkerData}
                usingTiebreaker={usingTiebreaker}
                picksPerInterval={picksPerInterval}
                intervalType={intervalType}
                howManyPicksThisIntervalInState={howManyPicksThisIntervalInState}
                voidedPicksCount={voidedPicksCount}
            />
        )}
        {submitModalOpen && submitError && (
            <QuickPickNotSavedModal
                submitModalOpen={submitModalOpen}
                setSubmitModalOpen={setSubmitModalOpen}
                submitError={submitError}
                setSubmitError={setSubmitError}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
        )}
        {internalErrorOpen && (
            <ErrorModal
                internalErrorOpen={internalErrorOpen}
                setInternalErrorOpen={setInternalErrorOpen}
                internalErrorMessage={internalErrorMessage}
                setInternalErrorMessage={setInternalErrorMessage}
            />
        )}
        </>
    );
    
}

export default QuickPickSubmitBar;
