import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

const DuplicatePickModal = ({ duplicatePickModalOpen, setDuplicatePickModalOpen, week, homeId, homeName, awayName, bottomAlreadySelected, topAlreadySelected, homeLogo, awayLogo, handleModalAccept, duplicatePickId  }) => {

    return (
        <Modal
            open={duplicatePickModalOpen}
            onClose={() => setDuplicatePickModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                className="modal-box" 
                sx={{
                    backgroundColor: 'white',
                    width: '308px',
                    maxWidth: '450px',
                    height: 'auto',
                    padding: '16px',
                    margin: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    borderRadius: 1
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize: '16px', fontWeight: '800', textAlign: 'center', marginBottom: '24px'}}>
                    Confirm Week Change 
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize: '16px', fontWeight: '400', textAlign: 'center'}}>
                    This will remove your {duplicatePickId === homeId ? homeName : awayName} selection in Week {duplicatePickId === homeId ? bottomAlreadySelected?.week : topAlreadySelected?.week}.  Are you sure you want to make the change?
                </Typography>
                <img src={duplicatePickId === homeId ? homeLogo : awayLogo} alt="Team Logo" style={{ width: '48px', height: 'auto', margin: '20px auto', display: 'block' }} />
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        gap: '16px', // Space between buttons
                        mt: 2       // Top margin for the box
                    }}
                >
                    <Button 
                        variant='contained'
                        onClick={() => setDuplicatePickModalOpen(false)} 
                        sx={{ 
                            backgroundColor: '#E5E5E5', // Set the background color
                            color: '#002129', 
                            width: '115px',
                            height: '42px',
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={() => {
                            setDuplicatePickModalOpen(false);
                            if (duplicatePickId === homeId) {
                                handleModalAccept("home");
                            } else {
                                handleModalAccept("away");
                            }
                        }}
                        sx={{
                            height: '42px',
                            width: '115px',
                        }}
                    >
                        CONFIRM
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default DuplicatePickModal;