import React, {useState, useEffect} from 'react';
import { useSportsBookContext } from './sportsBookContext';

const SportsBookNav = (props) => {
    const { betsToDisplay, sportsBookNav, setSportsBookNav} = useSportsBookContext();

    const navigateBetTypes = (type) => {
        setSportsBookNav(type);
    }

    return (
        <nav>
            <ul syle={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>

                <li onClick={()=>{navigateBetTypes('ALL')}}>ALL</li>
                <li onClick={()=>{navigateBetTypes('NBA')}}>NBA</li>
                <li onClick={()=>{navigateBetTypes('NFL')}}>NFL</li>
                <li onClick={()=>{navigateBetTypes('NHL')}}>NHL</li>
                <li onClick={()=>{navigateBetTypes('NCAA')}}>NCAA</li>
                <li onClick={()=>{navigateBetTypes('CFL')}}>CFL</li>
                <li onClick={()=>{navigateBetTypes('GOLF')}}>Golf</li>
            </ul>
        </nav>
    );
};

export default SportsBookNav;
