import React from "react";
import { Typography, Box } from '@mui/material';
import './PillBox.scss';

const PercentagePillBoxTwoSided = ({
    leftCount,
    rightCount,
    leftText,
    rightText,
    leftResult,
    rightResult,
}) => {

    // Total count
    const total = leftCount + rightCount;

    // Formatter for dynamic decimals (only shows decimal if necessary)
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1, // Allows up to 1 decimal place
    });

    // Handle division by zero
    const leftPercentage = total > 0 ? formatter.format((leftCount / total) * 100) : 0;
    const rightPercentage = 100 - leftPercentage;

    const overallResult = leftCount >= rightCount ? leftResult : rightResult;


    return (
        <Box className="pill-box-two-sided-container">
            <Box 
                className="pill-box-two-sided-left-half-container"
                sx={{
                    border: `1px solid ${
                        overallResult === 'Win' ? '#00AA72' : overallResult === 'Loss' ? '#CC293C' : '#002129'
                    }`,
                    width: `${leftPercentage}%`,
                    backgroundColor: leftPercentage < rightPercentage ? '#fff' : overallResult === 'Win' ? '#00AA72' : overallResult === 'Loss' ? '#CC293C' : '#002129',
                }}
            >
                <Typography className="pill-box-two-sided-text">
                    {leftCount >= rightCount ? `${leftText} ${leftPercentage}%` : ''}
                </Typography>
            </Box>
            <Box
                className="pill-box-two-sided-right-half-container"
                sx={{
                    border: `1px solid ${
                        overallResult === 'Win' ? '#00AA72' : overallResult === 'Loss' ? '#CC293C' : '#002129'
                    }`,
                    width: `${rightPercentage}%`,
                    backgroundColor: rightPercentage <= leftPercentage ? '#fff' : overallResult === 'Win' ? '#00AA72' : overallResult === 'Loss' ? '#CC293C' : '#002129',
                }}
            >
                <Typography className="pill-box-two-sided-text">
                    {rightCount > leftCount ? `${rightText} ${rightPercentage}%` : ''}
                </Typography>
            </Box>
        </Box>
    );
}

export default PercentagePillBoxTwoSided;