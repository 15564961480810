import React from 'react';
import { Box, Typography, backdropClasses } from '@mui/material';
import './GameHomePageComponents.scss';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import trophyImage from '../../../assets/images/Layer_1.svg';
import SilverTrophy from '../../../assets/images/SilverTrophy.svg';
import BronzeTrophy from '../../../assets/images/BronzeTrophy.svg';

const PoolPrizes = ({
    prizes,
    backgroundColor,
    textColor,
}) => {

    const whichTrophy = (trophy) => {
        if (trophy === 'gold') {
            return trophyImage;
        } else if (trophy === 'silver') {
            return SilverTrophy;
        } else {
            return BronzeTrophy;
        }
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor || '#002129',
                padding: '16px 12px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: textColor || '#fff',
                    marginBottom: '16px',
                    textAlign: 'center',
                }}
            >
                {prizes?.header || 'POOL PRIZES'}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    justifyContent: 'left', //
                    alignItems: 'baseline',
                    gap: '20px',
                    width: '50%',
                    margin: '0 auto',
                }}
            >
                {prizes?.prizes?.map((prize, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row', // Each prize is in a row
                            alignItems: 'center',
                            gap: '12px', // Space between trophy and text
                            maxWidth: isMobile ? "180px" : "350px",
                            margin: "0 auto"
                        }}
                    >
                        {/* Trophy */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={whichTrophy(prize.trophy)}
                                alt={prize.trophy}
                                style={{
                                    height: isMobile ? '24px' : '40px',
                                }}
                            />
                        </Box>

                        {/* Text */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                                width: isMobile ? '256px' : '350px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: textColor || '#fff',
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: '700',
                                    textDecoration: 'underline',
                                    textAlign: 'left',
                                }}
                            >
                                {prize.text}
                            </Typography>
                            {prize.prize && (
                                <Typography
                                    sx={{
                                        color: textColor || '#fff',
                                        fontSize: isMobile ? '12px' : '16px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {prize.prize}
                                </Typography>
                            )}
                        </Box>
                        {/* Trophy */}
                        {/* <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={whichTrophy(prize.trophy)}
                                alt={prize.trophy}
                                style={{
                                    height: isMobile ? '24px' : '32px',
                                }}
                            />
                        </Box> */}
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '16px',
                    padding: '0px 16px',
                }}
            >
                {prizes?.extraText?.map((text, index) => (
                    <Typography
                        key={index}
                        sx={{
                            color: textColor || '#fff',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            textAlign: 'center',
                            marginBottom: '8px',
                            maxWidth: '750px',
                            margin: '0 auto',
                        }}
                    >
                        {text.bold}{' '}
                        {text.text && (
                            <Typography
                                component="span"
                                sx={{
                                    color: textColor || '#fff',
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: 400,
                                }}
                            >
                                - {text.text}
                            </Typography>
                        )}
                    </Typography>
                ))}
            </Box>
        </Box>
    )
}

export default PoolPrizes;