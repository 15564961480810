import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetCreationLeaderboard = () => {
    return useMutation({
        mutationFn: getCreationLeaderboard,
        onError: (error) => {
            console.error('Error fetching game:', error);
        },
        // onSuccess: (data) => {
        //   console.log('Fetched game data:', data);
        // }
    });
}

const getCreationLeaderboard = async ({ gameID }) => {  
    const response = await axios.post(
        `${apiUrl}/games/get-creation-leaderboard`,
        { gameID }, // Send gameID in the body instead
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
};
