// SportsBookPage.js
import React from 'react';
import {SportsBookProvider} from '../components/sportsBook/context/sportsBookContext'; // Correct import
import SportsBookContainer from '../components/sportsBook/sportsBookContainer';
const SportsBookPage = () => {

    return (
        <SportsBookProvider>
            <SportsBookContainer />
        </SportsBookProvider>
    );
};

export default SportsBookPage;