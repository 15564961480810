import ReactGA from 'react-ga4';

export const initializeGA = () => {
  ReactGA.initialize('G-6S0M0Z8GFZ'); // Replace 'YOUR_MEASUREMENT_ID' with your actual Measurement ID
};

export const sendPageview = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const sendEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
