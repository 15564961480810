import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetUserData, useGetGameHook } from "../../src/components/BirdiePool/hooks/index";
import { useGetMasterPool } from "../hooks/general/useGetMasterPool";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../redux/actions/loginImport";
import { getUserDataFromLocalStorage } from "../redux/actions/loginImport";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { DateTime } from "luxon";
import { useGetPoolLeaderboard } from "../hooks/pools/useGetPoolLeaderboard";


const UserAndGameContext = createContext();

export const useUserAndGameContext = () => {
    const context = useContext(UserAndGameContext);
    if (!context) {
        throw new Error("useUserAndGameContext must be used within a UserAndGameProvider");
    }
    return context;
};

export const UserAndGameProvider = ({ children }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const masterPoolId = searchParams.get('mId');
    const gameId = searchParams.get('gId');
    const page = searchParams.get('page');
    const pickIndex = searchParams.get('pickIndex');
    const invite = searchParams.get('invite');
    const creation = searchParams.get('creation');
    //console.log("INVITE: ", invite);
    //console.log("masterPoolId in UserAndGameProvider: ", masterPoolId, gameId, page);

   

    const [isLoading, setIsLoading] = useState(true);
    const [pageReload, setPageReload] = useState(false);
    const [isUserInMasterPool, setIsUserInMasterPool] = useState(null);
    const [isUserInThisPool, setIsUserInThisPool] = useState(null);
    const [isUserInPoolPartyPool, setIsUserInPoolPartyPool] = useState(null);

    const [pickState, setPickState] = useState(null);
    const [originalPicks, setOriginalPicks] = useState([]);
    const [bracketState, setBracketState] = useState([]);
    const [tiebreaker, setTiebreaker] = useState(null);
    const [originalTiebreaker, setOriginalTiebreaker] = useState([]);
    const [clickState, setClickState] = useState([]);
    const [interval, setInterval] = useState(0);
    //console.log("Pick State: ", pickState, "tiebreaker: ", tiebreaker);

    const [unsavedPicks, setUnsavedPicks] = useState(false);
    const [unsavedBracketPicks, setUnsavedBracketPicks] = useState(false);
    const [unsavedPicksModalOpen, setUnsavedPicksModalOpen] = useState(false);
    const [urlForNav, setUrlForNav] = useState(null);
    //console.log("Unsaved Picks: ", unsavedPicks, "Unsaved Bracket Picks: ", unsavedBracketPicks);

    const [connectionError, setConnectionError] = useState(false);

    const [joinOpen, setJoinOpen] = useState(false);
    const [inviteOpen, setInviteOpen] = useState(false);

    const [poolPartyPopUp, setPoolPartyPopUp] = useState(false);

    const myUserId = getUserDataFromLocalStorage()?.user_id;
    
    const { mutate: fetchUserData, data: userData, isLoading: userDataLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGameData, data: gameData, isLoading: gameDataLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchMasterPool, data: masterPoolData, isLoading: masterPoolDataLoading, isError: masterPoolError, error: masterPoolErrorData } = useGetMasterPool();
    const { mutate: fetchPoolLeaderboard, data: leaderboardData, isLoading: poolLeaderboardLoading, isError: poolLeaderboardError, error: poolLeaderboardErrorData } = useGetPoolLeaderboard();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!masterPoolId || !gameId || !page) {
            navigate('/404/pool-hall');
        }
    // Ignore the warning, only want to run on load
    // Was causing issues with logout
    }, []);

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    // Fetch game data using gameId
    useEffect(() => {
        if (gameId) {
            fetchGameData({ gameID: gameId });
        }
    }, [fetchGameData, gameId]);

    // Fetch master pool data
    useEffect(() => {
        if (masterPoolId) {
            fetchMasterPool({ masterPoolId: masterPoolId });
        }
    }, [fetchMasterPool, masterPoolId]);

    const usingReferralLeaderboard = gameData?.referrals?.referralLeaderboard;
    //console.log("Using Referral Leaderboard: ", usingReferralLeaderboard);

    // If there is gameData fetch leaderboard data and conditionally fetch referrals
    useEffect(() => {
        if (gameData) {
            fetchPoolLeaderboard({ gameID: gameId });
        }
    }, [fetchPoolLeaderboard, gameId, gameData]);
    //console.log("Leaderboard Data: ", leaderboardData);

   // Error handling for user data, game data, and master pool data
   // Logout on 401, 404 page on 404, otherwise Connection Error
   useEffect(() => {
        if (userError || gameError || masterPoolError) {
            // Handle unauthorized (token expired) cases
            if (userError?.response?.status === 401 || 
                gameError?.response?.status === 401 || 
                masterPoolError?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                return;
            }

            // Handle true 404 cases (resource not found)
            if (gameError?.response?.status === 404 || 
                masterPoolError?.response?.status === 404) {
                navigate('/404/pool-hall');
                return;
            }

            // Handle connection/network errors
            if (userError?.message === 'Network Error' || 
                gameError?.message === 'Network Error' || 
                masterPoolError?.message === 'Network Error') {
                setConnectionError(true);
                return;
            }

            // Log the specific error for debugging
            if (userError) console.error("Error fetching user data:", userError);
            if (gameError) console.error("Error fetching game data:", gameError);
            if (masterPoolError) console.error("Error fetching master pool data:", masterPoolError);

            // For any other errors, show connection error
            setConnectionError(true);
        }
    }, [
        userError,
        gameError, 
        masterPoolError, 
        dispatch, 
        navigate
    ]);

    // Optimized implementation:
    const userPoolData = useMemo(() => {
        if (!userData?.Games || !masterPoolId || !gameId) {
            return {
                usersMasterPool: null,
                allUsersEntries: null,
                usersGameIdsInMasterPool: [],
                gameInfo: null,
                usersIndexesInThisPool: [],
                usersDefaultIndex: null,
                userOptedInToTexts: null
            };
        }

        const usersMasterPool = userData.Games.find(entry => entry.masterPoolId === masterPoolId);
        if (!usersMasterPool) {
            return {
                usersMasterPool: null,
                allUsersEntries: null,
                usersGameIdsInMasterPool: [],
                gameInfo: null,
                usersIndexesInThisPool: [],
                usersDefaultIndex: null,
                userOptedInToTexts: null
            };
        }

        const gameInfo = usersMasterPool.gameIds?.find(game => game.gameId === gameId);

        return {
            usersMasterPool,
            allUsersEntries: usersMasterPool.entries,
            usersGameIdsInMasterPool: usersMasterPool.gameIds || [],
            gameInfo,
            usersIndexesInThisPool: gameInfo?.index || [],
            usersDefaultIndex: gameInfo?.defaultIndex || null,
            userOptedInToTexts: usersMasterPool.settings?.textOptIn || null
        };
    }, [userData?.Games, masterPoolId, gameId]);

    const { 
        usersMasterPool,
        allUsersEntries, 
        usersGameIdsInMasterPool,
        usersIndexesInThisPool,
        usersDefaultIndex,
        userOptedInToTexts, 
    } = userPoolData;

    const howManyTotalEntries = allUsersEntries?.length || 0;
    const howManyEntriesInThisPool = usersIndexesInThisPool?.length || 0;

    // Gather info about Master Pool
    const maxMasterPoolEntries = masterPoolData?.maxEntries;
    const resetUnsavedPicksToEmptyArray = masterPoolData?.resetUnsavedPicksToEmptyArray;
    const poolPartyGame = masterPoolData?.poolPartyGame;
    const poolPartyGameId = poolPartyGame?.gameID;
    const poolPartyGameName = poolPartyGame?.gameName;
    const gameAddress = masterPoolData?.gameAddress;
    const league = masterPoolData?.gameParameters?.league;
    const firstGameTime = masterPoolData?.gameParameters?.firstGameTime;
    const revealTime = masterPoolData?.gameParameters?.revealTime;
    const defaultPage = masterPoolData?.defaultPage || 'home';

    // Gather info about this Pool
    const gameAdministrators = gameData?.gameAdministration?.administrators;
    const gameCommissioners = gameData?.gameAdministration?.commissioners;
    const isUserAdmin = gameAdministrators?.includes(myUserId) || gameCommissioners?.includes(myUserId);
    //console.log("isUserAdmin: ", isUserAdmin);
    const isThisPoolPartyGame = gameId === poolPartyGameId;
    const gameParameters = gameData?.gameParameters;
    const maxEntriesThisPool = gameParameters?.maxEntries;
    const password = gameParameters?.password;
    const gameName = gameData?.gameName;
    const lastDayToJoin = gameData?.lastDayToJoin || masterPoolData?.lastDayToJoin;
    const lastDayToJoinInNewYork = DateTime.fromISO(lastDayToJoin, { zone: 'America/New_York' });
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const hasJoinDeadlinePassed = timeNowInNewYork > lastDayToJoinInNewYork;
    //console.log("Max Entries This Pool: ", maxEntriesThisPool);

    // Get the My Picks Bar Info, could be in game data or master pool data
    const intervalInfo = gameData?.intervalInfo || masterPoolData?.intervalInfo;
    const myPicksNavBarInfo = gameData?.myPicksNavBarInfo || masterPoolData?.myPicksNavBarInfo;

    // Gather info about the User
    const myUsername = userData?.user_name;
    

    // Function to handle unsaved picks
    const handleUnsavedPicks = useCallback(() => {
        if (resetUnsavedPicksToEmptyArray) {
            setPickState([]);
        } else {
            const picks = allUsersEntries[pickIndex]?.pool?.picks;
            setPickState(picks);
        }
    }, [resetUnsavedPicksToEmptyArray, allUsersEntries, pickIndex, setPickState]);

    // Add this effect right after your other useEffects:
    useEffect(() => {
        // Always set pageReload to true when this effect runs
        setPageReload(true);
        
        // Set loading back to false after timeout
        const timer = setTimeout(() => {
            if (gameData && masterPoolData) {
                setPageReload(false);
            }
        }, 500);
        
        return () => clearTimeout(timer);
    }, [gameId, masterPoolId, gameData, masterPoolData]); // Add dependencies for initial load

    // Check user's pool participation
    useEffect(() => {
        // If user is not logged in, we can make immediate determinations
        if (!myUserId) {
            setIsUserInMasterPool(false);
            setIsUserInThisPool(false);
            setIsUserInPoolPartyPool(false);
            return;
        }
    
        // For logged in users, wait for all required data
        if (!masterPoolData || !gameData || !userData) return;
        
        if (usersMasterPool) {
            setIsUserInMasterPool(true);
            
            const isInThisGame = usersMasterPool.gameIds?.some(
                gameObj => gameObj.gameId === gameId
            ) || false;
            
            setIsUserInThisPool(isInThisGame);
    
            if (poolPartyGameId) {
                const isInPoolParty = usersMasterPool.gameIds?.some(
                    gameObj => gameObj.gameId === poolPartyGameId
                ) || false;
                setIsUserInPoolPartyPool(isInPoolParty);
            } else {
                setIsUserInPoolPartyPool(false);
            }
        } else {
            setIsUserInMasterPool(false);
            setIsUserInThisPool(false);
            setIsUserInPoolPartyPool(false);
        }
    }, [usersMasterPool, gameId, poolPartyGameId, masterPoolData, gameData, userData, myUserId]);

    // Effect to set Pick State - runs when pool loading completes or when pick-related data changes
    useEffect(() => {
        //console.log("Pick State Setting");
        
        // Wait for initial pool loading to complete
        if (isUserInThisPool !== null) {
            // If user isn't in this pool, set empty picks array and exit
            if (!isUserInThisPool) {
                setPickState([]);
                setTiebreaker([]);
                setOriginalPicks([]);
                setOriginalTiebreaker([]);
                return;
            }
            
            // At this point we know user is in the pool
            // Wait for entries data to be available
            if (!allUsersEntries) return;
            
            // First priority: Use picks from URL pickIndex if valid and belongs to this game
            if (pickIndex && usersIndexesInThisPool?.includes(Number(pickIndex))) {
                const picks = allUsersEntries[pickIndex]?.pool?.picks;
                const tiebreaker = allUsersEntries[pickIndex]?.pool?.tiebreaker || [];
                //console.log("Setting Pick State, picks and index: ", picks, pickIndex);
                if (picks) {
                    setPickState(picks);
                    setOriginalPicks(picks);
                    setTiebreaker(tiebreaker);
                    setOriginalTiebreaker(tiebreaker);
                    return;
                }
                // If pickIndex doesn't match or picks don't exist, we'll fall through to find the correct index
            }
            
            // Second priority: Use picks from user's default index
            if (usersDefaultIndex) {
                const picks = allUsersEntries[usersDefaultIndex]?.pool?.picks;
                const tiebreaker = allUsersEntries[usersDefaultIndex]?.pool?.tiebreaker || [];
                setPickState(picks);
                setOriginalPicks(picks);
                setTiebreaker(tiebreaker);
                setOriginalTiebreaker(tiebreaker);
                // Update URL to reflect the correct index
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('pickIndex', usersDefaultIndex);
                navigate(`${window.location.pathname}?${searchParams.toString()}`);
                return;
            }
            
            // Last resort: Use picks from first index in pool
            usersIndexesInThisPool?.sort((a, b) => a - b);
            const firstIndex = usersIndexesInThisPool[0];
            const picks = allUsersEntries[firstIndex]?.pool?.picks;
            const tiebreaker = allUsersEntries[firstIndex]?.pool?.tiebreaker || [];
            setPickState(picks);
            setOriginalPicks(picks);
            setTiebreaker(tiebreaker);
            setOriginalTiebreaker(tiebreaker);
            // Update URL to reflect the first index
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('pickIndex', firstIndex);
            navigate(`${window.location.pathname}?${searchParams.toString()}`);
        }
    }, [isUserInThisPool, allUsersEntries, masterPoolId, gameId, pickIndex, usersIndexesInThisPool, usersDefaultIndex, navigate]);

    // Use Effect, if creation = true, open the join modal
    useEffect(() => {
        //console.log("CHECKING CREATION");
        if (creation === "true") {
            setJoinOpen(true);
        }
    }, [creation]);

    // Use Effect, if invite = true && user is not in this pool and join deadline has not passed, open the join modal
    // Always strips invite out of url once processed regardless of conditions
    useEffect(() => {
        //console.log("CHECKING INVITE");
        
        if (invite === "true") {
            // First handle opening the modal if conditions are met
            if (isThisPoolPartyGame && !isUserInThisPool && !hasJoinDeadlinePassed) {
                setJoinOpen(true);
            } else
            if (!isUserInThisPool && !hasJoinDeadlinePassed) {
                setInviteOpen(true);
            }
            
            // Now remove the invite parameter from URL in all cases
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('invite');
            navigate(`${window.location.pathname}?${searchParams.toString()}`);
        }
    }, [invite, isUserInThisPool, hasJoinDeadlinePassed, navigate, isThisPoolPartyGame]);

    // Add this useEffect after the invite handling useEffect
    useEffect(() => {
        // Check if page parameter equals "default"
        //console.log("Default Page Check", defaultPage, isUserInThisPool);
        if (page === "default" && isUserInThisPool !== null) {
            const searchParams = new URLSearchParams(window.location.search);
            
            // Set the appropriate page based on user's pool membership
            if (isUserInThisPool) {
                // User is in the pool, use the defaultPage from masterPoolData
                searchParams.set('page', defaultPage);
            } else {
                // User is not in the pool, redirect to home
                searchParams.set('page', 'home');
            }
            
            // Update the URL while preserving all other parameters
            navigate(`${window.location.pathname}?${searchParams.toString()}`);
        }
    }, [page, isUserInThisPool, defaultPage, navigate]);

    // Memoize My Leaderboard Info
    // Get the total entry count and myInfo is all the entries that have myUserId as userId
    const myLeaderboardInfo = useMemo(() => {
        if (!leaderboardData || !myUserId) return null;
        
        const entryCount = leaderboardData.length;
        const myInfo = leaderboardData?.filter(entry => entry.userId === myUserId);
        
        return {
            entryCount,
            myInfo
        };
    }, [leaderboardData, myUserId]);
    //console.log("MY LEADERBOARD INFO: ", myLeaderboardInfo);

    // Local Storage key value
    const popUpModalInfo = masterPoolData?.modalInfo?.poolPartyPopUp;
    const popUpModalId = popUpModalInfo?.localStorageId;
    const popUpMasterPoolId = popUpModalInfo?.masterPoolId;
    const popUpModalStartDate = popUpModalInfo?.startDate;
    const popUpModalExpirationDate = popUpModalInfo?.expirationDate;
    const popUpStartDateInNewYork = DateTime.fromISO(popUpModalStartDate, { zone: 'America/New_York' });
    const popUpExpirationDateInNewYork = DateTime.fromISO(popUpModalExpirationDate, { zone: 'America/New_York' });
    const todayInNewYork = DateTime.now().setZone('America/New_York');
    //console.log("Pop Up Modal Info: ", popUpModalInfo);
    // Function to check if user has seen the modal, lookin in local storage
    const hasSeenModal = (id) => {
        const key = `poolParty_${id}`;
        
        try {
            const storedData = localStorage.getItem(key);
            // If entry exists in localStorage, user has seen it
            return !!storedData;
        } catch {
            return false;
        }
    };

    const userInOtherPool = (popUpMasterPoolId) => {
        const isUserInOtherPool = userData?.Games?.some(game => game.masterPoolId === popUpMasterPoolId);
        //console.log("Is User In Other Pool: ", isUserInOtherPool, userData?.Games, popUpMasterPoolId);
        return isUserInOtherPool;
    };

   // Use Effect to check if user has seen the modal
    useEffect(() => {
        //console.log("Running Use Effect for Pool Party Pop Up");
        if (
            popUpModalId &&
            popUpStartDateInNewYork <= todayInNewYork &&
            todayInNewYork <= popUpExpirationDateInNewYork
        ) {
            const seen = hasSeenModal(popUpModalId);
            if (!seen) {
                // Only check if user is in other pool if a game ID exists
                if (popUpMasterPoolId) {
                    const isUserInOtherPool = userInOtherPool(popUpMasterPoolId);
                    //console.log("Is User In Other Pool: ", isUserInOtherPool);
                    if (!isUserInOtherPool) {
                        //console.log("Setting Pool Party Pop Up");
                        setPoolPartyPopUp(true);
                    } else {
                        //console.log("User is in another pool");
                        setPoolPartyPopUp(false);
                    }
                } else {
                    // If no game ID to check, just show the modal
                    setPoolPartyPopUp(true);
                }
            }
        }
    }, [popUpModalId, popUpStartDateInNewYork, popUpExpirationDateInNewYork, popUpMasterPoolId, todayInNewYork]);
    
    const cleanupExpiredPoolPartyModals = (testTime = null) => {
        try {
          // Use provided test time or get current time in Eastern Time
          const now = testTime 
            ? DateTime.fromISO(testTime).setZone("America/New_York") 
            : DateTime.now().setZone("America/New_York");
                    
          // Find all localStorage keys that start with poolParty_
          const poolPartyKeys = Object.keys(localStorage)
            .filter(key => key.startsWith('poolParty_'));
                    
          let removedCount = 0;
          
          poolPartyKeys.forEach(key => {
            try {
              const data = JSON.parse(localStorage.getItem(key));
              
              // Check if data has a valid expirationDate
              if (data && data.expirationDate) {
                const expirationDate = DateTime.fromISO(data.expirationDate);
                                
                // If expired, remove it
                if (now >= expirationDate) {
                  localStorage.removeItem(key);
                  removedCount++;
                }
              }
            } catch (err) {
              // If we can't parse this item, it's likely corrupted - remove it
              localStorage.removeItem(key);
              removedCount++;
              console.error(`Error processing ${key}, removed item:`, err);
            }
          });
                    
        } catch (error) {
          console.error("Error cleaning up expired modals:", error);
        }
      };

      // Use Effect (on load or new masterPool) to clean up expired pool party modals
      // for now use 2025-04-06-12-00-00 as test time
        useEffect(() => {
            cleanupExpiredPoolPartyModals();
        }, [masterPoolData]);
    
    // Set loading state
    useEffect(() => {
        if (gameData && masterPoolData) {
            setIsLoading(false);
        }
    }, [gameData, masterPoolData]);

    const UserAndGameContextValues = useMemo(() => ({
        masterPoolId,
        page,
        gameId,
        pickIndex,
        userData,
        gameData,
        masterPoolData,
        myUserId,
        myUsername,
        isUserInMasterPool,
        isUserInThisPool,
        maxMasterPoolEntries,
        maxEntriesThisPool,
        howManyTotalEntries,
        howManyEntriesInThisPool,
        isThisPoolPartyGame,
        isUserInPoolPartyPool,
        allUsersEntries,
        usersIndexesInThisPool,
        usersGameIdsInMasterPool,
        poolPartyGameId,
        poolPartyGameName,
        password,
        gameName,
        gameAddress,
        pickState,
        setPickState,
        originalPicks,
        bracketState,
        setBracketState,
        tiebreaker,
        setTiebreaker,
        originalTiebreaker,
        clickState,
        setClickState,
        interval,
        setInterval,
        unsavedPicks,
        setUnsavedPicks,
        unsavedBracketPicks,
        setUnsavedBracketPicks,
        unsavedPicksModalOpen,
        setUnsavedPicksModalOpen,
        handleUnsavedPicks,
        urlForNav,
        setUrlForNav,
        connectionError,
        hasJoinDeadlinePassed,
        myLeaderboardInfo,
        myPicksNavBarInfo,
        intervalInfo,
        isUserAdmin,
        league,
        firstGameTime,
        revealTime,
        joinOpen,
        setJoinOpen,
        inviteOpen,
        setInviteOpen,
        userOptedInToTexts,
        leaderboardData,
        creation,
        usingReferralLeaderboard,
        lastDayToJoin,
        popUpModalInfo,
        poolPartyPopUp,
        setPoolPartyPopUp,
    }), [
        userData, 
        gameData, 
        masterPoolData, 
        myUserId, 
        myUsername,
        isUserInMasterPool, 
        isUserInThisPool, 
        maxMasterPoolEntries, 
        maxEntriesThisPool, 
        howManyTotalEntries, 
        howManyEntriesInThisPool, 
        isThisPoolPartyGame, 
        isUserInPoolPartyPool, 
        allUsersEntries, 
        usersIndexesInThisPool,
        usersGameIdsInMasterPool, 
        poolPartyGameId, 
        poolPartyGameName, 
        password, 
        gameName,
        gameAddress,
        pickState,
        setPickState,
        originalPicks,
        bracketState,
        setBracketState,
        tiebreaker,
        setTiebreaker,
        originalTiebreaker,
        clickState,
        setClickState,
        interval,
        setInterval,
        unsavedPicks,
        setUnsavedPicks,
        unsavedBracketPicks,
        setUnsavedBracketPicks,
        unsavedPicksModalOpen,
        setUnsavedPicksModalOpen,
        handleUnsavedPicks,
        urlForNav,
        setUrlForNav,
        page,
        gameId,
        masterPoolId,
        pickIndex,
        connectionError,
        hasJoinDeadlinePassed,
        myLeaderboardInfo,
        myPicksNavBarInfo,
        intervalInfo,
        isUserAdmin,
        league,
        firstGameTime,
        revealTime,
        joinOpen,
        setJoinOpen,
        inviteOpen,
        setInviteOpen,
        userOptedInToTexts,
        leaderboardData,
        creation,
        usingReferralLeaderboard,
        lastDayToJoin,
        popUpModalInfo,
        poolPartyPopUp,
        setPoolPartyPopUp,
    ]);

    if (isLoading || pageReload || isUserInMasterPool === null || isUserInThisPool === null || isUserInPoolPartyPool === null || !leaderboardData) return <LoadingScreen />;

    return (
        <UserAndGameContext.Provider value={UserAndGameContextValues}>
            {children}
        </UserAndGameContext.Provider>
    );
};