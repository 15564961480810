import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { getTeamLogo } from "../../../utils/SportsData";

const TiebreakerTwoTextInput = ({
    firstFinalist,
    secondFinalist,
    league,
    teams,
    hasDeadlinePassed,
}) => {
    const { 
        tiebreaker, 
        setTiebreaker 
    } = useUserAndGameContext();
    console.log("Second Finalist: ", secondFinalist, "First Finalist: ", firstFinalist);

    const handleChange = (event, key) => {
        const newValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (newValue.length <= 3) {
            const numberValue = newValue === "" ? null : parseInt(newValue, 10);

            // Convert tiebreaker to array if it's not one already
            let updatedTiebreaker = Array.isArray(tiebreaker) ? [...tiebreaker] : [];

            // Remove existing object of the same key
            updatedTiebreaker = updatedTiebreaker.filter(obj => !obj.hasOwnProperty(key));

            // Add the new value if it's not null
            if (numberValue !== null) {
                updatedTiebreaker.push({ [key]: numberValue });
            }

            setTiebreaker(updatedTiebreaker);
        }
    };

    // Get values safely
    const safeTiebreaker = Array.isArray(tiebreaker) ? tiebreaker : [];

    const value1 = safeTiebreaker.find(obj => obj.hasOwnProperty("tiebreaker1"))?.tiebreaker1 ?? "";
    const value2 = safeTiebreaker.find(obj => obj.hasOwnProperty("tiebreaker2"))?.tiebreaker2 ?? "";


    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, marginTop: '70px' }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>TIEBREAKER</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#555" }}>
                Total Combined Points
            </Typography>
            
            {/* Team Logos */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 6, mb: 1 }}>
                <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center" 
                }}>
                    {isNaN(firstFinalist) ? 
                    (
                        <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                            {firstFinalist}
                        </Typography>
                    ) :
                    (
                        <img 
                            src={getTeamLogo(league, firstFinalist, teams)} 
                            alt="First team logo"
                            style={{ 
                                maxWidth: "100%", 
                                maxHeight: "100%", 
                                objectFit: "contain" 
                            }}
                        />
                    )}
                </Box>
                <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center" 
                }}>
                    {isNaN(secondFinalist) ?
                    (
                        <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                            {secondFinalist}
                        </Typography>
                    ) :
                    (
                        <img 
                            src={getTeamLogo(league, secondFinalist, teams)} 
                            alt="Second team logo"
                            style={{ 
                                maxWidth: "100%", 
                                maxHeight: "100%", 
                                objectFit: "contain" 
                            }}
                        />
                    )}
                </Box>
            </Box>

            {/* Score Inputs/Display */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {hasDeadlinePassed ? (
                    // After deadline - show text or X icon
                    <>
                        {value1 ? (
                            <Box sx={{ 
                                width: "60px", 
                                height: "40px", 
                                display: "flex", 
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px"
                            }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                    {value1}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ 
                                width: "60px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px"
                            }}>
                                <CloseIcon sx={{ color: "#CC293C" }} />
                            </Box>
                        )}
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>-</Typography>
                        {value2 ? (
                            <Box sx={{ 
                                width: "60px", 
                                height: "40px", 
                                display: "flex", 
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px"
                            }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                    {value2}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ 
                                width: "60px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px"
                            }}>
                                <CloseIcon sx={{ color: "#CC293C" }} />
                            </Box>
                        )}
                    </>
                ) : (
                    // Before deadline - show input fields
                    <>
                        <TextField
                            type="text"
                            value={value1}
                            onChange={(e) => handleChange(e, "tiebreaker1")}
                            inputProps={{
                                maxLength: 3,
                                pattern: "[0-9]*",
                            }}
                            sx={{ width: "60px", textAlign: "center" }}
                        />
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>-</Typography>
                        <TextField
                            type="text"
                            value={value2}
                            onChange={(e) => handleChange(e, "tiebreaker2")}
                            inputProps={{
                                maxLength: 3,
                                pattern: "[0-9]*",
                            }}
                            sx={{ width: "60px", textAlign: "center" }}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TiebreakerTwoTextInput;