import React, { useState} from "react";
import { Box, Modal, Button } from '@mui/material';
import './SuperPickemNav.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";

const SuperPickemNav = (props) => {
    const { clickState, setClickState, setSignUpState, doesUserBelong, isGameOver, userSubgroups, sponsorAbbreviation, unsavedPicks, setUnsavedPicks, userPicksFromDB, setPickState, setBracketState, unsavedBracketPicks, setUnsavedBracketPicks, userBracketPicksFromDB, hasJoinDeadlinePassed } = props;

    //console.log("Is Survivor Over: ", isGameOver, "User Subgroups Length: ", userSubgroups?.length);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery('(max-width:400px)');
    //console.log("Is Mobile: ", isMobile, "Is Small Screen: ", isSmallScreen);

    const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);
    const [pendingPage, setPendingPage] = useState(null);

    const handleNavBarClick = (page) => {
        //console.log("Handle Nav Bar Click: ", page);
        if (!unsavedPicks && !unsavedBracketPicks) {
            setClickState(page);
            if (page !== 'MY_PARTY') {
                setSignUpState(false);
            }
        } else {
            setPicksNotSavedModal(true);
            setPendingPage(page);
        }
    }

    const handleLeavePage = () => {
        if (unsavedPicks) {
            setPickState([]);
            setUnsavedPicks(false);
        }
        if (unsavedBracketPicks) {
            setBracketState(userBracketPicksFromDB);
            setUnsavedBracketPicks(false);
        }
        setPicksNotSavedModal(false);
        setClickState(pendingPage);
    }


    return (
        <>
            <Box className={"super-pickem-nav"} style={{ margin: '0 auto'}}>
                <Box className={"super-pickem-nav-container"} style={{ width: isMobile ? '98%' : '90%', maxWidth: doesUserBelong ? '960px' : isMobile ? '320px' : '575px', justifyContent: doesUserBelong ? 'space-between' : 'center', gap: doesUserBelong ? '' : '40px' }}>
                    {doesUserBelong && (
                        <>
                            {!hasJoinDeadlinePassed && (
                                <Box
                                    className={`super-pickem-nav-item ${clickState === 'MY_BRACKET' ? 'active' : ''}`}
                                    onClick={() => { handleNavBarClick('MY_BRACKET'); setSignUpState(false); }}
                                    style={{ cursor: 'pointer', color: clickState === 'MY_BRACKET' ? '#FFC60A' : 'white' }}
                                >
                                    My Bracket
                                </Box>
                            )}

                            <Box
                                className={`super-pickem-nav-item ${clickState === 'MY_PICKS' ? 'active' : ''}`}
                                onClick={() => { handleNavBarClick('MY_PICKS'); setSignUpState(false); }}
                                style={{ cursor: 'pointer', color: clickState === 'MY_PICKS' ? '#FFC60A' : 'white' }}
                            >
                                My Picks
                            </Box>
                            
                            {hasJoinDeadlinePassed && (
                                <Box
                                    className={`super-pickem-nav-item ${clickState === 'MY_BRACKET' ? 'active' : ''}`}
                                    onClick={() => { handleNavBarClick('MY_BRACKET'); setSignUpState(false); }}
                                    style={{ cursor: 'pointer', color: clickState === 'MY_BRACKET' ? '#FFC60A' : 'white' }}
                                >
                                    My Bracket
                                </Box>
                            )}
                        </>
                    )}
                    <Box
                        className={`super-pickem-nav-item ${clickState === 'LEADERBOARD' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('LEADERBOARD'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'LEADERBOARD' ? '#FFC60A' : 'white' }}
                    >
                        Leaderboard
                    </Box>
                    
                    <Box
                        className={`super-pickem-nav-item ${clickState === 'POOL_PICKS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('POOL_PICKS'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'POOL_PICKS' ? '#FFC60A' : 'white' }}
                    >
                        Pool Picks
                    </Box>
                    
                    {doesUserBelong && (
                    <Box
                        className={`super-pickem-nav-item ${clickState === 'MY_PARTY' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('MY_PARTY'); setSignUpState(false); }}
                        style={{ cursor: 'pointer', color: clickState === 'MY_PARTY' ? '#FFC60A' : 'white' }}
                    >
                        My Party
                    </Box>
                    )}
                </Box>
            </Box>

            <Modal
                open={picksNotSavedModal}
                onClose={() => setPicksNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
                >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPicksNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleLeavePage}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
}

export default SuperPickemNav;
