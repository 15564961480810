import React from 'react';
import { TableCell } from '@mui/material';
import { getTeamLogo, getTeamNameShort, getTeamNickname } from '../../../utils/SportsData';
import CloseIcon from '@mui/icons-material/Close';

// TeamDisplayCell component that works for both mobile and desktop
const TeamDisplayCell = ({ team, highestResult, isMobile, teams, league, schedule }) => {
    //console.log("Team Display Cell: ", team);

    const teamLogo = (teamId) => {
        return getTeamLogo(league, teamId, teams);
    };

    const teamKey = (teamId) => {
        return getTeamNameShort(league, teamId, teams);
    };

    const teamName = (teamId) => {
        return getTeamNickname(league, teamId, teams);
    };

    const opponentInfo = (gameId, teamId) => {
        const game = schedule?.find(game => game.GlobalGameID === gameId);
        if (!game) return null;
        const opponentId = game?.GlobalAwayTeamID === teamId ? game?.GlobalHomeTeamID : game?.GlobalAwayTeamID;
        const symbol = game?.GlobalHomeTeamID === teamId ? 'vs' : '@';
        const opponentShortName = getTeamNameShort(league, opponentId, teams);
        return `${symbol} ${opponentShortName}`;
    };


    const isNoPick = team.teamId === "No Pick";
    
    // Common color logic
    const getTextColor = () => {
      if (isNoPick) return '#CC293C';
      return highestResult === "loss" ? '#CC293C' : 
             highestResult === "win" ? '#00AA72' : '#002129';
    };
  
    return (
      <TableCell>
        <div 
          className={isMobile ? "pick-stats-team-bar-mobile" : "pick-stats-team-bar-desktop"}
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '10px'
          }}
        >
          {isNoPick ? (
            <>
              <CloseIcon
                sx={{
                  color: '#CC293C',
                  width: isMobile ? '24px' : '40px',
                  height: isMobile ? '24px' : '40px',
                }}
              />
              <div 
                className={isMobile ? "" : "pick-stats-team-text-container-desktop"}
                style={{ 
                  display: 'flex', 
                  flexDirection: 'column'
                }}
              >
                <p 
                  className={isMobile ? "" : "selected-team-desktop"}
                  style={{ 
                    margin: 0, 
                    color: '#CC293C' 
                  }}
                >
                  NO PICK
                </p>
              </div>
            </>
          ) : (
            <>
              <img
                className={isMobile ? "" : "team-logo-desktop-img"}
                style={isMobile ? { 
                  width: '24px', 
                  height: '24px', 
                  objectFit: 'contain' 
                } : {}}
                loading="lazy"
                alt={`${team.teamKey} Logo`}
                src={teamLogo(team.teamId)}
              />
              <div 
                className={isMobile ? "" : "pick-stats-team-text-container-desktop"}
                style={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  color: getTextColor()
                }}
              >
                {isMobile ? (
                  <>
                    <p style={{ margin: 0, color: getTextColor() }}>
                      {`${teamKey(team.teamId)} ${teamName(team.teamId)}`}
                    </p>
                    <p style={{ margin: 0, color: getTextColor() }}>
                      {opponentInfo(team.gameId, team.teamId)}
                    </p>
                  </>
                ) : (
                  <div className="selected-team-desktop">
                    {`${teamKey(team.teamId)} ${teamName(team.teamId)} ${opponentInfo(team.gameId, team.teamId)}`}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </TableCell>
    );
  };

export default TeamDisplayCell;
  