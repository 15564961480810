import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import './TabSelectionButtons.scss';

const TabSelectionButtons = ({ selectedTab, tabs }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleTabChange = (tabId) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('tab', tabId);
        navigate(`?${newParams.toString()}`);
    };

    return (
        <Box className="tab-selection-button-container">
            {tabs.map((tabItem) => (
                <Button
                    key={tabItem.tab}
                    className={`tab-selection-button ${selectedTab === tabItem.tab ? 'active' : ''}`}
                    onClick={() => handleTabChange(tabItem.tab)}
                >
                    {tabItem.displayName}
                </Button>
            ))}
        </Box>
    );
}

export default TabSelectionButtons;