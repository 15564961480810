import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Button, Table, TableHead, TableContainer, TableRow, TableCell, TableBody } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const EditModalCaelndar = () => {
    const { birdiePoolData, scorecardState, setScorecardState, selectedHole, setSelectedHole, modalState, setModalState, startingScorecard, handleScoreCardSubmission, editHole, setEditHole, editDate, setEditDate, editIndex, setEditIndex } = useBirdieContext();

    //console.log("BirdiePoolData:", birdiePoolData.startDate);

    const handleDateChange = (newDate, hole, index) => {
        //console.log(`Date changed to ${newDate} for hole ${hole} at index ${index}`);
        // Implement your logic here to update the date
        setEditDate(newDate);
    };

    const handleCancel = () => {
        //console.log("Cancel Button Hit");
        setEditHole(null);
        setEditIndex(null);
        setEditDate(null);
        setModalState("edit");
    };

    const sortDates = (datesArray) => {
        datesArray.sort((a, b) => {
            // Convert dates to Date objects for comparison
            const dateA = new Date(a);
            const dateB = new Date(b);

            // Compare dates
            return dateA - dateB;
        });
    };


    const handleSave = () => {
        //console.log("Save Button Hit");
        //console.log("Edit Hole:", editHole, "Edit Index:", editIndex, "Edit Date:", editDate.toLocaleDateString());
        const updatedScorecard = [...scorecardState];
        updatedScorecard[editHole-1].birdieDates[editIndex] = editDate.toLocaleDateString();
        sortDates(updatedScorecard[editHole-1].birdieDates);
        //console.log("Updated Scorecard:", updatedScorecard);
        setScorecardState(updatedScorecard); //This gets removed
        //Enter Save Logic Here
        //Enter Logic to refresh the page, so birdie(s) update

        // setEditHole(null);
        // setEditIndex(null);
        // setEditDate(null);
        handleScoreCardSubmission();
        // setModalState("edit");
        // window.location.href = window.location.href;
    };

    const today = new Date()
    const startDate = new Date(birdiePoolData.startDate)
    const endDate = new Date(birdiePoolData.endDate)
    let maxDate = today;
    if (maxDate > endDate) {
        maxDate = endDate;
    }

    const MAX_VISIBLE_ROWS = 6;

    return (
        <>
        <h2 style={{ marginTop: '0px'}}>Edit Birdie</h2>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: '10px' }}>
                <div>Hole {editHole}</div>
                <div>
                    <DatePicker
                        selected={editDate}
                        onChange={newDate => handleDateChange(newDate, editHole, editIndex)}
                        inline
                        minDate={startDate}
                        maxDate={maxDate}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                    />
                </div>
                <div style={{marginTop:'15px'}}>
                    <Button
                        variant='contained'
                        sx={{
                        bgcolor: 'white',
                        color: '#002129',
                        border: '1px solid #002129',
                        '&:hover': {
                        bgcolor: 'white', // Maintain white background on hover
                        color: '#002129', // Maintain text color on hover
                        border: '1px solid #002129', // Maintain border color on hover
                        }, }}
                        className='birdie-mobile-btn'
                        onClick={handleCancel}
                        >
                            CANCEL
                    </Button>
                    <Button
                        variatnt='contained'
                        color='primary'
                        className='submit-button active birdie-mobile-btn'
                        style={{ marginLeft: '8px' }}
                        onClick={handleSave}
                        >
                            UPDATE
                    </Button>
                </div>
            </div>
        </div>
    </>


    );
};

export default EditModalCaelndar;
