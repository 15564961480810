import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { formatGameCardDate } from "../../../utils/DateFormatting/GameCardDateFormat";

const TiersSubmitModal = ({ open, handleClose, checkerData }) => {
    console.log("Checker Data", checkerData);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box className="modal-container" style={{ height: 'auto', width: '308px' }}>
                <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800' }}>
                    {checkerData?.newPicks === 1 ? 'Pick Submitted' : 'Picks Submitted'}
                </Typography>

                <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} />

                <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '500', color: '#002129' }}>
                    {checkerData?.newPicks} Pick{checkerData?.newPicks === 1 ? '' : 's'} Submitted for {checkerData?.label}
                </Typography>

                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '700', color: checkerData?.isComplete ? '#00AA72' : '#CC293C' }}>
                        {checkerData?.currentPicksCount}/{checkerData?.totalExpectedPicks}
                    </Typography>
                    {checkerData?.isComplete ? (
                        <CheckIcon style={{ width: '24px', height: '24px', fill: '#00AA72', marginLeft: '8px' }} />
                    ) : (
                        <CloseIcon style={{ width: '24px', height: '24px', fill: '#CC293C', marginLeft: '8px' }} />
                    )}
                </Box>

                <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '500', color: '#002129' }}>
                    Picks can be changed up until <br/>{formatGameCardDate(checkerData?.deadline)}
                </Typography>

                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button sx={{ width: '115px'}} variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default TiersSubmitModal;