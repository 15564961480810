import React from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AllowReferralsComponent = ({ allowReferrals, setAllowReferrals, order }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleYesCheckboxChange = (event) => {
        if (event.target.checked) {
            setAllowReferrals(true);
        } else {
            setAllowReferrals(null);
        }
    }

    const handleNoCheckboxChange = (event) => {
        if (event.target.checked) {
            setAllowReferrals(false);
        } else {
            setAllowReferrals(null);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
                marginTop: '16px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '15px' : '20px',
                    fontWeight: '700',
                    marginBottom: '10px',
                    textAlign: 'left',
                }}
            >
                {order}. Allow other users to refer friends to join your pool?
            </Typography>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 4 
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allowReferrals === true}
                            onChange={handleYesCheckboxChange}
                            name="allowReferralsYes"
                            color="primary"
                        />
                    }
                    label="Yes"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allowReferrals === false}
                            onChange={handleNoCheckboxChange}
                            name="allowReferralsNo"
                            color="primary"
                        />
                    }
                    label="No"
                />
            </Box>
        </Box>
    );
};

export default AllowReferralsComponent;