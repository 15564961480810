import React from "react";
import { UserAndGameProvider } from "../contexts/UserAndGameContext";
import { TiersGameProvider } from "../components/TiersGames/context/TiersGameContext";
import TiersGamesContainer from "../components/TiersGames/TiersGameContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const TiersGamePage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <UserAndGameProvider>
                    <TiersGameProvider>
                        <TiersGamesContainer />
                    </TiersGameProvider>
                </UserAndGameProvider>
            </QueryClientProvider>
        </div>
    );
};

export default TiersGamePage;