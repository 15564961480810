import React, { useEffect } from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import CommissionerPage from "./CommissionerPageNew";
import ManageMembers from "./ManageMembersNew";
import { useGetAllGameMembers } from "../../hooks/general/useGetAllGameMembers";
import { useNavigate } from "react-router-dom";


const MembersPageContainerNew = () => {
    const {
        myUserId,
        isUserAdmin,
        gameData,
        masterPoolId,
        gameId,
        hasJoinDeadlinePassed,
        isGameOver,
        gameName,
        gameAddress,
        isThisPoolPartyGame,
        isUserInThisPool,
    } = useUserAndGameContext();

    const gameCommissioners = gameData?.gameAdministration?.commissioners;
    const gameAdministrators = gameData?.gameAdministration?.administrators;

    const { mutate: getAllGameMembers, data: allGameMembers } = useGetAllGameMembers();

    const navigate = useNavigate();

    // If this is a Pool Party Game OR user is not in the pool, send them to home page
    useEffect(() => {
        if (isThisPoolPartyGame || (!isUserInThisPool && !isUserAdmin)) {
            navigate(`/${gameAddress}?mId=${masterPoolId}&gId=${gameId}&page=home`);
        }
    }, [isThisPoolPartyGame, isUserInThisPool, navigate, gameAddress, masterPoolId, gameId, isUserAdmin]);

    //UseEffect to get all game members
    useEffect(() => {
        getAllGameMembers({ gameId });
    }, [gameId, getAllGameMembers]);


    return (
        <>
            {isUserAdmin && (
                <CommissionerPage
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrators={gameAdministrators}
                    gameId={gameId}
                    masterPoolId={masterPoolId}
                    isGameOver={isGameOver}
                    isUserAdmin={isUserAdmin}
                    allGameMembers={allGameMembers}
                    gameAddress={gameAddress}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    gameName={gameName}
                />
            )}

            {!isUserAdmin && (
                <ManageMembers
                    gameData={gameData}
                    myUserId={myUserId}
                    gameCommissioners={gameCommissioners}
                    gameAdministrators={gameAdministrators}
                    mainGameId={gameId}
                    allGameMembers={allGameMembers}
                    gameName={gameName}
                    isGameOver={isGameOver}
                    doIHaveCommissionerAccess={isUserAdmin}
                    leaderboardInfo={allGameMembers}
                    gameAddress={gameAddress}
                />
            )}
        </>
    );
}

export default MembersPageContainerNew;