import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BracketTeamRow from "../SuperPickemComponents/BracketTeamRow";
import BracketGame from "../SuperPickemComponents/BracketGame";

const SuperPickemMyBracket = () => {

    const teamData = [
        {
            "teamDisplay" : "Ohio State (12-1)",
            "seed" : 1,
            "teamDisplayCharacterCount" : 17,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/98.png",
    "teamColor" : "#CC293C",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },{
            "teamDisplay" : "Ohio State (12-1)",
            "seed" : 1,
            "teamDisplayCharacterCount" : 17,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/98.png",
    "teamColor" : "#CC293C",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Alabama (13-0)",
            "seed" : 12,
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/275.png",
    "teamColor" : "#A32638",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Alabama (13-0)",
            "seed" : 12,    
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/275.png",
    "teamColor" : "#A32638",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Clemson (10-1)",
            "seed" : 3,
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/27.png",
    "teamColor" : "#F56600",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Clemson (10-1)",
            "seed" : 3,
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/27.png",
    "teamColor" : "#F56600",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Notre Dame (10-1)",
            "seed" : 4,
            "teamDisplayCharacterCount" : 18,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/25.png",
    "teamColor" : "#002B5C",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Notre Dame (10-1)",
            "seed" : 4,
            "teamDisplayCharacterCount" : 18,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/25.png",
    "teamColor" : "#002B5C",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Oregon (4-2)",
            "seed" : 5,
            "teamDisplayCharacterCount" : 10,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/245.png",
    "teamColor" : "#154733",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Oregon (4-2)",
            "seed" : 5,
            "teamDisplayCharacterCount" : 10,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/245.png",
    "teamColor" : "#154733",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Miami (8-2)",
            "seed" : 6,
            "teamDisplayCharacterCount" : 10,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/23.png",
    "teamColor" : "#F47321",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Miami (8-2)",
            "seed" : 6,
            "teamDisplayCharacterCount" : 10,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/23.png",
    "teamColor" : "#F47321",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Georgia (7-2)",
            "seed" : 7,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/272.png",
    "teamColor" : "#000000",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Georgia (7-2)",
            "seed" : 7,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/272.png",
    "teamColor" : "#000000",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Penn State (4-5)",
            "seed" : 8,
            "teamDisplayCharacterCount" : 14,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/101.png",
    "teamColor" : "#00265D",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Penn State (4-5)",
            "seed" : 8,
            "teamDisplayCharacterCount" : 14,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/101.png",
    "teamColor" : "#00265D",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Indiana (6-1)",
            "seed" : 9,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/93.png",
    "teamColor" : "#990000",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Indiana (6-1)",
            "seed" : 9,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/93.png",
    "teamColor" : "#990000",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Boise State (5-2)",
            "seed" : 10,
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/215.png",
    "teamColor" : "#09347A",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Boise State (5-2)",
            "seed" : 10,
            "teamDisplayCharacterCount" : 15,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/215.png",
    "teamColor" : "#09347A",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "BYU (11-1)",
            "seed" : 11,
            "teamDisplayCharacterCount" : 8,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/336.png",
    "teamColor" : "#002E5D",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "BYU (11-1)",
            "seed" : 11,
            "teamDisplayCharacterCount" : 8,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/336.png",
    "teamColor" : "#002E5D",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        {
            "teamDisplay" : "Colorado (4-1)",
            "seed" : 12,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/250.png",
    "teamColor" : "#000000",
    "isSelected" : true,
    "isOppositeSelected" : false,
        },
        {
            "teamDisplay" : "Colorado (4-1)",
            "seed" : 12,
            "teamDisplayCharacterCount" : 11,
    "teamLogo" : "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/250.png",
    "teamColor" : "#000000",
    "isSelected" : false,
    "isOppositeSelected" : true,
        },
        
    ]

    // <Box sx={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}>
    //         {teamData?.map((team, index) => (
    //             <>
    //                 <BracketTeamRow 
    //                     key={index}
    //                     seed={team.seed}
    //                     teamDisplay={team.teamDisplay}
    //                     teamDisplayCharacterCount={team.teamDisplayCharacterCount}
    //                     teamLogo={team.teamLogo}
    //                     teamColor={team.teamColor}
    //                     isSelected={team.isSelected}
    //                     isOppositeSelected={team.isOppositeSelected}
    //                 />
    //             </>
    //         ))} {/* Properly close the map function here */}
    //         </Box>

    return (
        <div>
            Super Pickem My Bracket

            <BracketGame />
            
        </div>
    );
    
}

export default SuperPickemMyBracket;