import React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./SurvivorUserStats.scss";
import SurvivorDivisionBox from "./SurvivorDivisionBox";

const SurvivorTeamsAvailable = ({ usersPicks, startingWeekState, areTheseMyStats, currentWeeksDeadlineDateTime }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const result = "Pending";

    return (
        <>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: isMobile ? '330px' : '560px',
                height: 'auto',
                margin: '0 auto',
                borderRadius: '0',
                color: '#002129',
                fontSize: isMobile ? '20px' : '24px',
                fontWeight: 700,
                textAlign: 'center',
                textDecoration: 'underline',
            }}
        >
            <Box
                sx={{
                    width: isMobile ? '150px' : '240px',
                    margin: '0',
                }}
            >
                AFC
            </Box>
            <Box
                sx={{
                    width: isMobile ? '150px' : '240px',
                    margin: '0',
                }}
            >
                NFC
            </Box>
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: isMobile ? '36px' : '80px',
            }}
        >
            <SurvivorDivisionBox 
                conference={"AFC"} 
                division={"East"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
            <SurvivorDivisionBox 
                conference={"NFC"} 
                division={"East"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: isMobile ? '36px' : '80px',
            }}
        >
            <SurvivorDivisionBox 
                conference={"AFC"} 
                division={"North"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
            <SurvivorDivisionBox 
                conference={"NFC"} 
                division={"North"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: isMobile ? '36px' : '80px',
            }}
        >
            <SurvivorDivisionBox 
                conference={"AFC"} 
                division={"South"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
            <SurvivorDivisionBox 
                conference={"NFC"} 
                division={"South"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: isMobile ? '36px' : '80px',
            }}
        >
            <SurvivorDivisionBox 
                conference={"AFC"} 
                division={"West"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
            <SurvivorDivisionBox 
                conference={"NFC"} 
                division={"West"} 
                usersPicks={usersPicks} 
                startingWeekState={startingWeekState} 
                areTheseMyStats={areTheseMyStats}
                currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}    
            />
        </Box>

       
</>


    );
};

export default SurvivorTeamsAvailable;