import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CheckboxBanner = ({ regionOptions, selectedRegion, onRegionChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#002129",
                padding: '8px',
            }}
        >
            <FormGroup row sx={{ gap: isMobile ? 1 : 5 }}>
                {regionOptions?.map((region) => (
                    <FormControlLabel
                        key={region}
                        control={
                            <Checkbox
                                checked={selectedRegion === region}
                                onChange={() => onRegionChange(region)}
                                sx={{
                                    padding: '5px',
                                    color: '#fff',
                                    '&.Mui-checked': {
                                        color: '#fff',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        width: isMobile ? '20px' : '32px',
                                        height: isMobile ? '20px' : '32px',
                                    },
                                }}
                            />
                        }
                        label={region}
                        sx={{
                            color: '#FFFFFF',
                            '& .MuiFormControlLabel-label': {
                                fontSize: isMobile ? '14px' : '24px',
                                fontWeight: 500,
                            },
                        }}
                    />
                ))}
            </FormGroup>
        </Box>
    );
};

export default CheckboxBanner;