import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useSaveBracketPicks = () => {
    return useMutation({
        mutationFn: submitBracketPicksRequest,
        onError: (error) => {
            console.error('Error submitting picks:', error);
        },
        // onSuccess: (data) => {
        //     console.log('Picks submitted successfully:', data);
        // }
    });
}

const submitBracketPicksRequest = async (pickData) => {
    const authorizationHeader = localStorage.getItem('authorization');

    const response = await axios.post(`${apiUrl}/games/save-bracket-picks`, pickData, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json'
        }
    });
    return response.data;
};