import React from "react";
import Box from '@mui/material/Box';
import './bracketHeaderNav.scss';

const BracketNav = (props) => {
    //console.log('BracketNav props:', props);
    const {setClickState, clickState} = props;
    return (
        <Box className={"bracket-nav"}>
          <Box className={"bracket-nav-container"}>
            <Box className={`bracket-nav-item ${clickState === 'BRACKET_VIEW'? 'active':''}`} onClick={() => setClickState('BRACKET_VIEW')}>Bracket</Box>
            <Box className={`bracket-nav-item ${clickState === 'PICKS_VIEW'? 'active':''}` } onClick={() => setClickState('PICKS_VIEW')}>Pick 'Em</Box>
            <Box className={`bracket-nav-item ${clickState === 'LEADER_BOARD_VIEW'? 'active':''}`} onClick={() => setClickState('LEADER_BOARD_VIEW')}>Leaderboard</Box>
            <Box className={`bracket-nav-item ${clickState === 'POOL_PICKS_VIEW'? 'active':''}`} onClick={() => setClickState('POOL_PICKS_VIEW')}>Pool Picks</Box>
            <Box className={`bracket-nav-item ${clickState === 'RULES_VIEW'? 'active':''}`} onClick={() => setClickState('RULES_VIEW')}>Rules</Box>
          </Box>
        </Box>
    );
}

export default BracketNav;