import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../../config";


export const useGetGameHook = () => {
  return useMutation({
    mutationFn: getGameHookRequest,
    onError: (error) => {
      console.error('Error fetching game:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Fetched game data:', data);
    // }
  });
};

const getGameHookRequest = async ({ gameType, gameID }) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
  //console.log(authorizationHeader, gameID, gameType);

  const data = { gameID };
  const response = await axios.post(`${apiUrl}/games/get-game`, data, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });

  //console.log('USEGETGAMEHOOK', response.data.data.gameData);
  return response.data.data.gameData;
};
