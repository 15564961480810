import React, { useState } from 'react';
import { Box, Typography, Checkbox, Divider, Button, Modal, TextField } from "@mui/material";


const quickPickData = [
    {
        "GlobalGameID": 18776,
        "Day": "2024-10-28T00:00:00",
        "Week": 8,
        "HomeTeam": "PIT",
        "GlobalHomeTeamID": 28,
        "AwayTeam": "NYG",
        "GlobalAwayTeamID": 23,
        "gameFinalized": false,
        "props": {
            "tiebreakers": [
                {
                    "id": "11y85369-b652-4f95-a75e-8e9fdf738be4",
                    "propChoice": "TotalPoints",
"tableHeader": "Total Points",
                    "propText": "How many total points will be scored in the game?",
                    "options": ["Input"],
                    "tiebreaker": true,
                    "propFinalized": false
                }
            ],
            "gameProps": [
                {
                    "id": "x74bda20-fc2d-459b-b954-ce138534b6de",
                    "propChoice": "TotalPoints",
"tableHeader": "Total Points",
                    "propText": "Total Points Scored in the Game",
                    "options": ["Over", "Under"],
                    "line": 45.5,
                    "actualTotal": 50,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "0985aa47-928c-4772-be25-30fab3badedd",
                    "propChoice": "CoinToss",
"tableHeader": "Coin Toss",
                    "propText": "What will be the result of the coin toss?",
                    "options": ["Heads", "Tails"],
                    "correctOption": "Heads",
                    "propFinalized": false,
"points":1
                }
            ],
            "homeProps": [
                {
                    "id": "4g2c0af1-843f-4efb-b83d-1f78160395a0",
                    "propChoice": "PassingYards"
,
"tableHeader": "Wilson Pass Yards",
                    "player": "Russell Wilson",
                    "propText": "Total Passing Yards by Russell Wilson",
                    "options": ["Over", "Under"],
                    "line": 250.5,
                    "actualTotal": 300,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "62yc0c00-d968-40e8-9e93-92a64a74193a",
                    "propChoice": "RushingYards",
"tableHeader": "Harris Rush Yards",
                    "player": "Najee Harris",
                    "propText": "Total Rushing Yards by Najee Harris",
                    "options": ["Over", "Under"],
                    "line": 75.5,
                    "actualTotal": 100,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "7hw56eea-285d-43de-b486-e64f20f74d10",
                    "propChoice": "ReceivingYards",
"tableHeader": "Claypool Rec Yards",
                    "player": "Chase Claypool",
                    "propText": "Total Receiving Yards by Chase Claypool",
                    "options": ["Over", "Under"],
                    "line": 50.5,
                    "actualTotal": 75,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "912b5cac-e282-492e-b399-59d35203936d",
                    "propChoice": "ReceivingYards",
"tableHeader": "Johnson Rec Yards",
                    "player": "Diontae Johnson",
                    "propText": "Total Receiving Yards by Diontae Johnson",
                    "options": ["Over", "Under"],
                    "line": 65.5,
                    "actualTotal": 80,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                }
            ],
            "awayProps": [
                {
                    "id": "03n7fa1b-9bfc-49bf-acf4-abf06565cffe",
                    "propChoice": "PassingYards",
"tableHeader": "Simms Pass Yards",
                    "player": "Phil Simms",
                    "propText": "How many passing yards by Phil Simms?",
                    "options": ["Less than 100", "101-175", "176-250", "More than 250"],
                    "actualTotal": 134,
                    "correctOption": "101-175",
                    "propFinalized": false,
"points":2
                },
                {
                    "id": "m40f32db-e26b-4792-a19b-d8f7917d3fed",
                    "propChoice": "RushingYards",
"tableHeader": "Carter Rush Yards",
                    "player": "Michael Carter",
                    "propText": "Total Rushing Yards by Michael Carter",
                    "options": ["Over", "Under"],
                    "line": 85.5,
                    "actualTotal": 75,
                    "correctOption": "Under",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "77v0b66d-384d-4988-8b8e-e88d30faf9e8",
                    "propChoice": "ReceivingYards",
"tableHeader": "Davis Rec Yards",
                    "player": "Corey Davis",
                    "propText": "Total Receiving Yards by Corey Davis",
                    "options": ["Over", "Under"],
                    "line": 50.5,
                    "actualTotal": 75,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                },
                {
                    "id": "j820f604-1050-4b64-a029-a6a75748a62a",
                    "propChoice": "ReceivingYards",
"tableHeader": "Moore Rec Yards",
                    "player": "Elijah Moore",
                    "propText": "Total Receiving Yards by Elijah Moore",
                    "options": ["Over", "Under"],
                    "line": 40.5,
                    "actualTotal": 55,
                    "correctOption": "Over",
                    "propFinalized": false,
"points":1
                }
            ]
        }
    }
]


const TestPropBuilder = () => {
    // Access the first element of the quickPickData array
    const { Day, Week, AwayTeam, HomeTeam, gameFinalized, props } = quickPickData[0] || {};
  
    const [isGameFinalized, setIsGameFinalized] = useState(gameFinalized || false);
    const [addPropOpen, setAddPropOpen] = useState(false); // For Edit Modal
    const [deletePropOpen, setDeletePropOpen] = useState(false); // For Delete Modal
    const [currentProp, setCurrentProp] = useState(null); // Store the selected prop for editing or deleting
  
    const handleGameFinalizedChange = (event) => {
      setIsGameFinalized(event.target.checked);
    };
  
    // Render Props function
    const renderProps = (propData) => {
      return (
        propData?.map((prop) => (
          <Box key={prop.id} p={2} border="1px solid #ddd" borderRadius={2} mb={2}>
            {Object.keys(prop).map((key) => (
              <Typography key={key} variant="body2">
                <strong>{key}</strong>: {Array.isArray(prop[key]) ? prop[key].join(', ') : typeof prop[key] === 'boolean' ? (prop[key] ? "True" : "False") : prop[key] || "N/A"}
              </Typography>
            ))}
  
            {/* Edit and Delete Buttons */}
            <Box mt={2} display="flex" justifyContent="center" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setCurrentProp(prop); // Set current prop for editing
                  setAddPropOpen(true); // Open Edit Modal
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setCurrentProp(prop); // Set current prop for deleting
                  setDeletePropOpen(true); // Open Delete Modal
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        )) || <Typography>No props available</Typography>
      );
    };
  
    // Modal for Editing Prop with Scroll
const renderEditModal = () => (
    <Modal open={addPropOpen} onClose={() => setAddPropOpen(false)}>
      <Box
        p={4}
        bgcolor="white"
        mx="auto"
        my="5%" // Reduce top margin to make more space for scrolling
        maxHeight="90vh" // Set max height of the modal
        width={400}
        borderRadius={2}
        overflow="auto" // Enable scrolling if content overflows
      >
        <Typography variant="h6">Edit Prop</Typography>
        {currentProp &&
          Object.keys(currentProp).map((key) => (
            <Box key={key} mb={2}>
              <Typography variant="body2" component="label" htmlFor={key}>
                <strong>{key}</strong>
              </Typography>
              <TextField
                fullWidth
                id={key}
                value={currentProp[key]}
                onChange={(e) =>
                  setCurrentProp({ ...currentProp, [key]: e.target.value })
                }
              />
            </Box>
          ))}
  
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={() => setAddPropOpen(false)}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setAddPropOpen(false)}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
  
  
    // Modal for Confirming Deletion
    const renderDeleteModal = () => (
      <Modal open={deletePropOpen} onClose={() => setDeletePropOpen(false)}>
        <Box p={4} bgcolor="white" mx="auto" my="15%" width={400} borderRadius={2}>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography mb={2}>Confirm deletion of prop, this cannot be undone.</Typography>
  
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="outlined" color="secondary" onClick={() => setDeletePropOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Confirm, DELETE"); // Handle deletion logic here
                setDeletePropOpen(false);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  
    return (
      <Box p={4}>
        {/* Game Info (Not Editable) */}
        <Box mb={2}>
          <Typography variant="h6">{`${AwayTeam || "Unknown"} at ${HomeTeam || "Unknown"}`}</Typography>
          <Typography variant="body1">{`Day: ${Day || "N/A"}`}</Typography>
          <Typography variant="body1">{`Week: ${Week || "N/A"}`}</Typography>
        </Box>
  
        {/* Game Finalized Checkbox */}
        <Box mb={2} display="flex" alignItems="center">
          <Typography variant="body1">Game Finalized:</Typography>
          <Checkbox
            checked={isGameFinalized}
            onChange={handleGameFinalizedChange}
            inputProps={{ 'aria-label': 'Game Finalized Checkbox' }}
          />
        </Box>
  
        {/* Add Prop Button */}
        <Box mb={2}>
          <Button variant="contained" color="primary" onClick={() => console.log("Add Prop Clicked")}>
            Add Prop
          </Button>
        </Box>
  
        {/* Tiebreakers Section */}
        <Box mb={2}>
          <Typography variant="h6">Tiebreakers</Typography>
          {renderProps(props?.tiebreakers)}
        </Box>
  
        {/* Game Props Section */}
        <Box mb={2}>
          <Typography variant="h6">Game Props</Typography>
          {renderProps(props?.gameProps)}
        </Box>
  
        {/* Home Props Section */}
        <Box mb={2}>
          <Typography variant="h6">Home Props</Typography>
          {renderProps(props?.homeProps)}
        </Box>
  
        {/* Away Props Section */}
        <Box mb={2}>
          <Typography variant="h6">Away Props</Typography>
          {renderProps(props?.awayProps)}
        </Box>
  
        <Divider />
  
        {/* Render Modals */}
        {renderEditModal()}
        {renderDeleteModal()}
      </Box>
    );
  };
  
  export default TestPropBuilder;