import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import './CardPieces.scss';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";


const QuickPicksMorePicksComing = () => {

    const navigateToTop = useNavigateToTop();
  
   

    
    return (
        <Box className="quick-pick-card-container">
            <Box 
                className="quick-pick-card-top-border"
                sx={{ backgroundColor: "#C0C0C0" }}    
            >
                <Typography 
                    className="quick-pick-card-top-border-text"
                    sx={{ color: '#002129' }}    
                >
                    More Props Coming Soon!
                </Typography>
            </Box>
            <Box className="quick-pick-card-info">
                <Typography 
                    className="quick-pick-card-info-text" 
                    sx={{
                        minHeight: '90px',
                        textAlign: 'center', // Center text horizontally
                        display: 'flex', // Make sure the flex behavior is applied
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Optionally center vertically within the minHeight
                      }}
                >
                    More props for this event are coming soon.  Make your selections above and check back soon for more props.
                </Typography>
            </Box>
            <Box className="quick-pick-card-bottom-border">
                <Typography className="quick-pick-card-bottom-border-text">
                    Prop Idea / Feedback: 
                </Typography>
                <Typography
                    className="quick-pick-card-bottom-border-text"
                    sx={{
                        color: '#00AAD1 !important', // Ensure priority
                        textDecoration: 'underline',
                        cursor: 'pointer', // Add pointer cursor
                    }}
                    onClick={() => navigateToTop('/contact')} 
                >
                    Contact Us
                </Typography>
            </Box>
        </Box>
    )
}

export default QuickPicksMorePicksComing;