// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-screen {
  background-color: #f5f5f5;
}

.loading-screen__spinner {
  width: 100px;
  height: 100px;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingScreen/LoadingScreen.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEE;EACE,YAAA;EACA,aAAA;EACA,kCAAA;AACJ;;AAEE;EACE;IAAK,uBAAA;EAEP;EADE;IAAO,yBAAA;EAIT;AACF","sourcesContent":[".loading-screen {\n    background-color: #f5f5f5;\n  }\n  \n  .loading-screen__spinner {\n    width: 100px;\n    height: 100px;\n    animation: spin 5s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
