import React from "react";
import { Box, Button } from "@mui/material";
import PoolPartyTrophyGold from "../../../../assets/images/PoolPartyTrophyGold.svg";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const FourRoundNav = ({ 
    rounds, 
    bracketRound, 
    setBracketRound,
    roundDash,
    setRoundDash,
    setRoundDashPosition, 
}) => {
    //console.log("Rounds: ", rounds);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    

    const handleNavClick = (round) => {
        //console.log("Round: ", round);
        
        // Use the function directly from the object
        const scrollFunction = setRoundDashPosition[round];
        
        if (scrollFunction && typeof scrollFunction === 'function') {
            scrollFunction(); // Call the function if it exists
        } 
        
        setRoundDash(round);
        setBracketRound(round);
    }

    return (
        <>
            <Box sx={{ 
                display: "flex", 
                flexDirection: "column", // Changed to column to stack rows vertically
                justifyContent: "space-between", 
                alignItems: "center", 
                padding: '8px', 
                backgroundColor: '#FFF', 
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' 
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        maxWidth: isMobile ? '99%' : '90%',
                        alignItems: 'center',
                        margin: '0 auto',
                    }}
                >
                    {rounds?.map((roundItem, index) => {
                        const isSelected = bracketRound === index + 1;
                        return (
                            <Button
                                key={index}
                                onClick={() => handleNavClick(index + 1)}
                                sx={{
                                    textTransform: "none",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "8px 8px",
                                    borderRadius: isSelected ? "4px" : "0px",
                                    backgroundColor: isSelected ? "#D9D9D9" : "transparent",
                                    fontFamily: "inherit",
                                    transition: "background-color 0.3s ease",
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                    ":hover": {
                                        backgroundColor: isSelected ? "#D9D9D9" : "transparent", 
                                    },
                                }}
                            >
                                <span style={{ fontWeight: 700 }}>{roundItem?.abbreviation}</span>{" "}
                                <span style={{ fontWeight: 500, marginLeft: '2px' }}>
                                    {roundItem?.points} Pt{roundItem?.points === 1 ? "" : "s"}
                                </span>
                            </Button>
                        );
                    })}
                    <Button
                        onClick={() => handleNavClick(rounds?.length + 1)}
                        sx={{
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: 500,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "2px",
                            minWidth: "30px",
                            borderRadius: bracketRound === (rounds?.length + 1) ? "4px" : "0px",
                            backgroundColor: bracketRound === (rounds?.length + 1)  ? "#D9D9D9" : "transparent",
                            fontFamily: "inherit",
                            transition: "background-color 0.3s ease",
                            cursor: "pointer",
                        }}
                    >
                        <img src={PoolPartyTrophyGold} alt="trophy" style={{ color: '#FFC60A', width: '25px' }} />
                    </Button>
                </Box>

            </Box>
        </>
    );
};

export default FourRoundNav;
