//Sports Name Function

const nameConfigs = {
    NCAAM: 'School',
    NCAAW: 'School',
};

// Modified getTeamName function with enhanced debugging
export const getTeamName = (league, teamId, teams) => {
    //console.log("getTeamName league: ", league, "teamId: ", teamId, "teams: ", teams);
    // Early return check with detailed logging
    if (!teams || !league || !teamId) {
        return null;
    }
    
    const propertyName = nameConfigs[league];
    
    if (!propertyName) {
        return null;
    }

    const team = teams?.find(team => team.GlobalTeamID === teamId);
    
    if (!team) {
        return null;
    }

    return team[propertyName];
};