import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import useJoinGameClick from '../../../hooks/general/useJoinGameClick';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const InviteModal = ({ open, setOpen }) => {
    const {
        masterPoolId,
        gameId,
        gameName,
        gameData,
    } = useUserAndGameContext();

    const handleJoinGameClick = useJoinGameClick();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorLogo = sponsorInfo?.sponsorLogo;
    const hostNote = gameData?.homePage?.hostNote;

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="invite-modal-title"
            aria-describedby="invite-modal-description"
        >
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: isMobile ? '90%' : '500px',
                    maxWidth: '500px',
                    bgcolor: 'background.paper', 
                    borderRadius: '8px', 
                    boxShadow: 24, 
                    p: 4, 
                }}>
                {sponsorLogo && (
                    <Box    
                        sx={{
                            display: 'block',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={sponsorLogo}
                            alt="Sponsor Logo"
                            style={{
                                width: 'auto',
                                height: '115px',
                                display: 'block',
                                margin: 'auto',
                                marginBottom: '20px',
                            }}
                        />
                    </Box>
                )}

                {sponsorLogo ? (
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            textAlign: 'center',
                        }}
                    >
                        Would you like to join {gameName}?
                    </Typography>
                ) : (
                    <Typography 
                        sx={{
                            fontSize: '20px',
                            fontWeight: '700',
                            textAlign: 'center',
                        }}
                    >
                        You have been invited to {gameName}
                    </Typography>
                )}

                {hostNote && (
                    <>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                textAlign: 'center',
                                mt: 2,
                            }}
                        >
                            Host Note
                        </Typography>
                        <Box
                            sx={{
                                mt: 1,
                                padding: '4px 16px',
                                border: '1px solid #C4C4C4',
                                borderRadius: '16px',
                                bgcolor: 'white',
                                height: 'calc(1.5em * 3.35)', // Height for approximately 3.5 rows
                                overflowY: 'auto',          // Enable vertical scrolling
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: 1.5,
                                }}
                            >
                                {hostNote}
                            </Typography>
                        </Box>
                    </>
                )}
                
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '16px',
                        mt: 4,
                    }}
                >
                    <Button 
                        variant="contained"
                        onClick={() => setOpen(false)}
                    >
                        NOT YET
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            handleJoinGameClick(); 
                        }}
                    >
                        JOIN POOL
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InviteModal;

