import React, { useCallback, useState } from "react";
import { Box, Button, Snackbar, Switch, Typography } from "@mui/material";
import { useUpdateUser } from "../../hooks/users";

export default function Preferences(props) {
  const { userData } = props;
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { mutate: updateUser } = useUpdateUser();
  const [formState, setFormState] = useState({
    enablePoolAnnouncements:
      userData.marketing_preferences?.enable_pool_announcements,
    enablePoolReminders: userData.marketing_preferences?.enable_pool_reminders,
    enablePoolUpdates: userData.marketing_preferences?.enable_pool_updates,
  });

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      updateUser(
        {
          id: userData._id,
          marketingPreferences: {
            enablePoolAnnouncements: formState.enablePoolAnnouncements,
            enablePoolReminders: formState.enablePoolReminders,
            enablePoolUpdates: formState.enablePoolUpdates,
          },
        },
        {
          onSuccess: () => {
            setIsSnackbarOpen(true);
          },
        }
      );
    },
    [formState, updateUser, userData._id]
  );

  return (
    <Box component="form" onSubmit={submitForm}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ bottom: "100px" }}
        open={isSnackbarOpen}
        autoHideDuration={135000}
        onClose={() => setIsSnackbarOpen(false)}
        message="Changes saved successfully."
        key={"top" + "right"}
      />
      <div
        className="hidden md:block w-full flex justify-end pr-6 text-right"
        style={{ marginTop: "-90px", paddingBottom: "60px" }}
      >
        <Button variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "800px",
          margin: "auto",
          minWidth: {
            xs: "100%",
            md: "500px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box className="w-full">
            <Typography
              sx={{ cursor: "pointer", fontWeight: "500" }}
              component="label"
            >
              <Box className="w-full flex justify-between items-center">
                Pool Release/Marketing Emails
                <Switch
                  checked={formState.enablePoolAnnouncements}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      enablePoolAnnouncements: e.target.checked,
                    })
                  }
                />
              </Box>
            </Typography>
          </Box>
          <Box className="w-full">
            <Typography
              sx={{ cursor: "pointer", fontWeight: "500" }}
              component="label"
            >
              <Box className="w-full flex justify-between items-center">
                Pick Reminder Emails
                <Switch
                  checked={formState.enablePoolReminders}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      enablePoolReminders: e.target.checked,
                    })
                  }
                />
              </Box>
            </Typography>
          </Box>
          <Box className="w-full">
            <Typography
              sx={{ cursor: "pointer", fontWeight: "500" }}
              component="label"
            >
              <Box className="w-full flex justify-between items-center">
                Pool Update Emails
                <Switch
                  checked={formState.enablePoolUpdates}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      enablePoolUpdates: e.target.checked,
                    })
                  }
                />
              </Box>
            </Typography>
          </Box>
        </Box>
        <div className="block md:hidden w-full flex justify-center">
          <Button variant="contained" size="large" type="submit">
            Save Changes
          </Button>
        </div>
      </Box>
    </Box>
  );
}
