import React, {useState, useEffect, useContext} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useMarchMadnessBracketContext } from '../bracketContext/marchMadnessBracketContext'
import './leaderBoardStyles.scss';
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage, getUsersForLeaderboardAction } from '../../../redux/actions/loginImport';
import { isCompositeComponent } from 'react-dom/test-utils';

const LeaderBoard = ({ cbbTeamsData, newAllUsers }) => {
    console.log(useMarchMadnessBracketContext(), cbbTeamsData, "MarchMadnessBracketContext");
    const [leaderBoardAdjusted, setLeaderBoardAdjusted] = useState([]);
    const [allUsers, setAllUsers] = useState(newAllUsers);
    const [cbbTeamsDataNew, setCbbTeamsDataNew] = useState([]);

    useEffect(() => {
        setLeaderBoardAdjusted(newAllUsers);
        setCbbTeamsDataNew(cbbTeamsData);
    }, [newAllUsers, cbbTeamsData]);

    const findTeamLogoUrlBySchoolName = (TeamID) => {
        console.log('pickState',TeamID)
        const team = cbbTeamsData?.find(team => team.GlobalTeamID === TeamID);
        return team ? team.TeamLogoUrl : null;
      };

    const handleTeamLogo = (TeamID) => {
        const teamLogoUrl = findTeamLogoUrlBySchoolName(TeamID);
        return teamLogoUrl; // Provide the path to your default image
    };
    console.log(newAllUsers, cbbTeamsDataNew, 'NEW ALL USERS');

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{
                            background: '#002129',
                            color:'#fff'
                        }}>
                            <TableRow sx={{

                            color:'#fff'
                        }}>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>RANK</TableCell>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>PLAYER</TableCell>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>TOTAL PTS</TableCell>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>MAX PTS</TableCell>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>TIEBREAKER</TableCell>
                                <TableCell sx={{color:'#fff', fontWeight:900}}>CHAMP</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* BEGIN: Render table rows */}
                            {leaderBoardAdjusted?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    className={index % 2 === 0 ? 'evenRow' : ''}
                                >
                                    <TableCell>{row.Rank}</TableCell>
                                    <TableCell>{row.Username}</TableCell>
                                    <TableCell>{row.TotalPoints}</TableCell>
                                    <TableCell>{row.MaxPoints}</TableCell>
                                    <TableCell>{row.TiebreakerPoints}</TableCell>
                                    <TableCell><img style={{display:'block', width:"25px"}} src={handleTeamLogo(row.Champ)} alt="TEAM LOGO"/></TableCell>
                                </TableRow>
                            ))}
                            {/* END: Render table rows */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default LeaderBoard;
