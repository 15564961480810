import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

const getPoolLeaderboard = async ({ gameID }) => {
  const data = { gameID };
  const response = await axios.post(`${apiUrl}/games/get-game-leaderboard`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  // console.log('RESPONSE FROM LB', response.data.data);
  return response.data;
};

export const useGetPoolLeaderboard = () => {
  return useMutation({
    mutationFn: getPoolLeaderboard,
    onError: (error) => {
      console.error('Error fetching game:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Fetched game data:', data);
    // }
  });
};
