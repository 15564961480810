import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import BracketTeamRowLive from "./BracketTeamRowLive";
import BracketLosingTeam from "./BracketLosingTeam";
import { hexToRgba } from "../bracketUtils/HexToRgba.js";
import ChampionDisplay from "../../ChampionDisplay/ChampionDisplay";

const BracketChampLive = ({ 
    games, 
    schedule, 
    rounds, 
    teams, 
    names, 
    seeds, 
    bracketState,
    getBracketInfoPostLock, 
}) => {

    
    const actualFinalId = games?.find((g) => g.round === 4)?.GlobalGameID;
    const actualFinalGame = schedule?.find((s) => s.GlobalGameID === actualFinalId);
    //console.log("Actual Final Id", actualFinalId, "Actual Final Game: ", actualFinalGame);
    const isFinalGameOver = actualFinalGame?.Status === "Final" || actualFinalGame?.Status === "F/OT";
    const actualWinnerId = isFinalGameOver ? (actualFinalGame?.HomeTeamScore > actualFinalGame?.AwayTeamScore ? actualFinalGame?.GlobalHomeTeamID : actualFinalGame?.GlobalAwayTeamID) : null;
    //console.log("Actual Winner Id: ", actualWinnerId);

    const teamId = bracketState?.find((p) => p.game === 1 && p.round === 4)?.teamId;
    const userResult = bracketState?.find((p) => p.game === 1 && p.round === 4)?.result;
    //console.log("USER RESULT: ", userResult);


    const champInfo = getBracketInfoPostLock(teamId, teams, seeds, names);
    //console.log("Champ Info: ", champInfo);
    const actualChampInfo = getBracketInfoPostLock(actualWinnerId, teams, seeds, names);

    const teamColor = champInfo?.teamColor;

    const VectorCrown = <svg width="155" height="56" viewBox="0 0 145 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M125.712 18.1054C128.428 15.4651 131.205 12.8395 134.287 10.4136C132.599 20.1174 130.905 29.8304 129.172 39.7671C128.273 44.9167 127.365 50.1264 126.441 55.426C86.9487 49.0856 48.0405 49.1664 8.70015 55.3823C6.03566 40.1372 3.44483 25.3224 0.751735 9.95848C2.67623 11.7758 4.54522 13.5852 6.38899 15.3702C11.0146 19.8483 15.4815 24.1728 20.2679 28.0834L20.268 28.0834C23.6937 30.8813 26.6324 32.9596 29.2869 34.2724C31.9466 35.5879 34.3486 36.1489 36.6876 35.8749C39.0263 35.601 41.2383 34.4999 43.5318 32.6095C45.821 30.7226 48.2156 28.0293 50.9236 24.5315L50.9238 24.5312C55.04 19.2077 58.8459 13.6332 62.6915 8.00056C64.3089 5.63151 65.9333 3.25217 67.5909 0.87688C69.0421 2.98647 70.4744 5.10557 71.905 7.22223C75.46 12.482 79.0049 17.7268 82.8057 22.7733C85.8432 26.8096 88.4903 29.9109 90.9987 32.0769C93.5111 34.2463 95.9143 35.5037 98.462 35.8019C101.01 36.1002 103.63 35.4304 106.559 33.8876C109.483 32.3473 112.749 29.9188 116.604 26.6519C119.271 24.3954 121.776 21.9485 124.295 19.4874C124.766 19.0268 125.238 18.5658 125.712 18.1054Z" fill={userResult === "Win" ? "#00AA72" : (userResult === "Loss" || !champInfo) ? "#CC293C" : '#fff'} stroke="#002129"/>
    </svg>;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    height: '45px',
                    width: '100%',
                    marginBottom: '150px',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                {VectorCrown}
            </Box>
            
            <Box 
                className="super-pickem-bracket-champ-container"
                sx={{
                    border: userResult === "Win" ? '4px solid #00AA72' : (userResult === "Loss" || !champInfo) ? '4px solid #CC293C' : '',
                }}
            >
                <Box
                    sx={{
                        height: '45px',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontWeight: '700',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        National Champs
                    </Typography>
                </Box>
                
                <Box
                    sx={{
                        width: '140px',
                        height: '140px',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        marginBottom: '8px',
                    }}
                >
                    {userResult !== "Loss" ? (
                        champInfo?.logo && (
                            <img
                                src={champInfo?.logo}
                                alt={champInfo?.nameDisplay}
                                style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        )
                    ) : (
                        actualChampInfo?.logo && (
                            <img
                                src={actualChampInfo?.logo}
                                alt={actualChampInfo?.nameDisplay}
                                style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        )
                    )}


                </Box>
                {teamId && userResult !== "Loss" ? (
                    <BracketTeamRowLive
                        teamInfo={champInfo}
                        seeds={seeds}
                        names={names}
                        teams={teams}
                        champ={true}
                        result={userResult}
                    />
                ) : actualWinnerId ? (
                    <BracketTeamRowLive
                        teamInfo={actualChampInfo}
                        seeds={seeds}
                        names={names}
                        teams={teams}
                        champ={true}
                        result={userResult}
                    />
                ) : (
                    <Box 
                        className="super-pickem-bracket-team-row-placeholder" 
                        sx={{ 
                            border: (userResult === "Loss" || !champInfo) ? '3px solid #CC293C' : '', 
                            backgroundColor: (userResult === "Loss" || !champInfo) ? `${hexToRgba('#CC293C', 0.20)}` : '', 
                        }}  
                    />
                )}

            </Box>
            {(userResult === "Loss" || !champInfo) && (
                //console.log("HIT CHAMP INFO: ", champInfo),
                <Box
                    sx={{
                        marginTop: '-79px',
                    }}
                >
                    <BracketLosingTeam 
                        teamInfo={champInfo} 
                        noPick={champInfo === null}
                    />
                </Box>
            )}
        </>
    );
}

export default BracketChampLive;