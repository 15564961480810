import React, { useState } from 'react';
import NFLPickEmCard from './NFLPickEmCard';
import WeeklyPicksBar from './WeeklyPicksBar';
import UserSubmitBar from './UserSubmitBar';
import PickEmWeekNav from './PickEmWeekNav';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Grid, Box } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const MyPicks = () => {
    const { NFLTeams,
        NFLStandings,
        NFLStadiums,
        NFLGameOddsByWeek,
        NFLScoresBySeason,
        week,
        userPicksFromDB,
        pickEmState,
        doesUserBelong
    } = useNFLPickEmContext();

    const [moreInfoOpen, setMoreInfoOpen] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);



    const weeklyRecord = () => {
        let wins = 0;
        let losses = 0;
        let ties = 0;
        userPicksFromDB?.forEach((pick) => {
          if (pick.week === week) {
            if (pick.result === "Win") {
              wins++;
            } else if (pick.result === "Loss") {
              losses++;
            } else if (pick.result === "Push") {
              ties++;
            }
          }
        });
        return { wins, losses, ties };
      };

      const { wins: weeklyWins, losses: weeklyLosses, ties: weeklyTies } = weeklyRecord();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("MY PICKS NFL Schedule:", NFLScoresBySeason, NFLTeams);
    const weeklySchedule = NFLScoresBySeason?.filter(game => game.Week === week);
    const sortedSchedule = weeklySchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
    //console.log("MY PICKS Weekly Schedule:", sortedSchedule);
    const userPicks = userPicksFromDB?.filter(pick => pick.week === week  && pick.teamId !== "No Pick");
    //console.log("MY PICKS User Picks:", userPicksFromDB, userPicks);


    const noPickPicks = userPicksFromDB?.filter(pick => pick.week === week && pick.teamId === "No Pick");

    const noPicksLength = noPickPicks?.length;
    return (
        <div>
            <WeeklyPicksBar
                weeklySchedule={sortedSchedule}
                userPicks={userPicks}
              />
            <PickEmWeekNav />
            {!doesUserBelong && (
                <Typography
                    variant="h6"
                    style={{
                        fontSize: '24px',
                        fontWeight: '700',
                        marginTop: '24px',
                        marginBottom: '12px',
                        color: '#CC293C'
                      }}
                >
                    To make picks, <br/>you must join the pool.
                </Typography>
            )}
            <Typography
                variant="h6"
                style={{
                    fontSize: isMobile ? '16px' : '24px',
                    fontWeight: '700',
                    marginTop: '32px',
                    marginBottom:  isMobile ? '16px': '48px'
                  }}
            >
              Record: {weeklyWins}-{weeklyLosses}-{weeklyTies}
            </Typography>
            <Box
                sx={{
                    maxWidth: '975px',
                    margin: '0 auto',
                    padding: '0 16px' // Optional padding to prevent content from touching the edges
                }}
            >
                <Grid container columnSpacing={1} rowSpacing={1} sx={{ marginBottom: '10px'}}> {/* Reduced spacing */}
                    {sortedSchedule?.map(game => (
                        <Grid item xs={12} sm={12} md={6} key={game.GlobalGameID}>
                            <NFLPickEmCard 
                              gameId={game.GlobalGameID} 
                              userPicks={userPicks} 
                              noPicksLength={noPicksLength}
                              moreInfoOpen={moreInfoOpen}
                              setMoreInfoOpen={setMoreInfoOpen}
                              selectedGame={selectedGame}
                              setSelectedGame={setSelectedGame} 
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {/* {(isMobile && pickEmState?.length > 0) || (!isMobile && userPicks?.length < 5) ? ( */}
              {pickEmState?.length > 0 ? (
                <UserSubmitBar weeklySchedule={sortedSchedule} userPicks={userPicks} />
            ) : null}
        </div>
    );
}

export default MyPicks;
