import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import './TabSelectionButtons.scss';

const IntervalSelectionButtons = ({ selectedInterval, intervals }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    console.log("Selected Interval", selectedInterval, "Intervals", intervals);

    const handleTabChange = (interval) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('interval', interval);
        navigate(`?${newParams.toString()}`);
    };

    return (
        <Box className="tab-selection-button-container">
            {intervals.map((interval) => (
                <Button
                    key={interval.interval}
                    className={`tab-selection-button ${selectedInterval === interval.interval ? 'active' : ''}`}
                    onClick={() => handleTabChange(interval.interval)}
                >
                    {interval.name}
                </Button>
            ))}
        </Box>
    );
}

export default IntervalSelectionButtons;