import React from 'react';
import './superPickemStyles/UserPick.scss';


const UserPickBar = ( {dateState, userAlreadyHasPick, pickEmState, nbaGamesByDate, NBATeams, allGamesAreOver} ) => {
  //console.log("PICK BAR DATE STATE:", dateState);
  //console.log("PICK BAR USER ALREADY HAS PICK:", userAlreadyHasPick);
  //console.log("PICK BAR PICK EM STATE:", pickEmState);
  //console.log("PICK BAR NBA GAMES BY DATE:", nbaGamesByDate);
  //console.log("PICK BAR NBA TEAMS:", NBATeams);
  //console.log("PICK BAR ALL GAMES ARE OVER:", allGamesAreOver);

    const userPick = pickEmState;
    //console.log("User Pick:", userPick);

    //const game = {"GameID":19427,"Season":2023,"SeasonType":3,"Status":"Final","Day":"2023-04-15T00:00:00","DateTime":"2023-04-15T18:00:00","AwayTeam":"NY","HomeTeam":"CLE","AwayTeamID":6,"HomeTeamID":12,"StadiumID":12,"Channel":"ESPN","Attendance":19432,"AwayTeamScore":101,"HomeTeamScore":97,"Updated":"2023-10-02T15:39:08","Quarter":null,"TimeRemainingMinutes":null,"TimeRemainingSeconds":null,"PointSpread":-6.0,"OverUnder":216.0,"AwayTeamMoneyLine":182,"HomeTeamMoneyLine":-222,"GlobalGameID":20019427,"GlobalAwayTeamID":20000006,"GlobalHomeTeamID":20000012,"PointSpreadAwayTeamMoneyLine":-114,"PointSpreadHomeTeamMoneyLine":-107,"LastPlay":null,"IsClosed":true,"GameEndDateTime":"2023-04-15T20:37:45","HomeRotationNumber":506,"AwayRotationNumber":505,"NeutralVenue":false,"OverPayout":-112,"UnderPayout":-110,"CrewChiefID":20000013,"UmpireID":20000029,"RefereeID":20000066,"AlternateID":20000007,"DateTimeUTC":"2023-04-15T22:00:00","InseasonTournament":false,"SeriesInfo":{"HomeTeamWins":0,"AwayTeamWins":1,"GameNumber":1,"MaxLength":7},"Quarters":[{"QuarterID":162692,"GameID":19427,"Number":1,"Name":"1","AwayScore":30,"HomeScore":24},{"QuarterID":162693,"GameID":19427,"Number":2,"Name":"2","AwayScore":20,"HomeScore":21},{"QuarterID":162694,"GameID":19427,"Number":3,"Name":"3","AwayScore":28,"HomeScore":25},{"QuarterID":162695,"GameID":19427,"Number":4,"Name":"4","AwayScore":23,"HomeScore":27}]};

    function nbaGetKey(globalTeamID) {
      const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
      return team?.Key;
    }

    function nbaGetLogo(globalTeamID) {
      const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
      return team?.WikipediaLogoUrl;
    }

    const userChoice = userPick[0]?.teamId;
    const userLine = userPick[0]?.spread;
    const userGameID = userPick[0]?.gameId;

    const game = nbaGamesByDate?.find(game => game.GlobalGameID === userGameID);

    const awayTeamID = game?.GlobalAwayTeamID;
    //console.log("AWAY TEAM ID:", awayTeamID);
    const homeTeamID = game?.GlobalHomeTeamID;
    const awayKey = nbaGetKey(game?.GlobalAwayTeamID);
    const homeKey = nbaGetKey(game?.GlobalHomeTeamID);
    //console.log("USER CHOICE:", userChoice, "USER LINE:", userLine, "USER GAME ID:", userGameID);
    const isUserHome = game?.GlobalHomeTeamID === userChoice;
    //console.log("IS USER HOME:", isUserHome);
    const isSpreadPositive = userLine > 0;
    //console.log("IS SPREAD POSITIVE:", isSpreadPositive);
    let homeLine = "";
    let awayLine = "";
    let homeLineDisplay = "";
    let awayLineDisplay = "";
    if (isUserHome && isSpreadPositive) {
      homeLine = `+ ${userLine}`;
      homeLineDisplay = `${homeKey} (${homeLine})`;
      //console.log("HOME LINE DISPLAY:", homeLineDisplay);
    } else if (isUserHome && !isSpreadPositive) {
      homeLine = userLine;
      homeLineDisplay = `${homeKey} (${homeLine})`;
      //console.log("HOME LINE DISPLAY:", homeLineDisplay);
    } else if (!isUserHome && isSpreadPositive) {
      awayLine = `+ ${userLine}`;
      awayLineDisplay = `${awayKey} (${awayLine})`;
      //console.log("AWAY LINE DISPLAY:", awayLineDisplay);
    } else {
      awayLine = userLine;
      //console.log("AWAY LINE:", awayLine);
      awayLineDisplay = `${awayKey} (${awayLine})`;
      //console.log("AWAY LINE DISPLAY:", awayKey, awayLine, awayLineDisplay);
    }


    const awayLogo = nbaGetLogo(awayTeamID);
    const homeLogo = nbaGetLogo(homeTeamID);
    const awayScore = game?.AwayTeamScore;
    const homeScore =  game?.HomeTeamScore;
    let gameDateTime = null;
    if (game) {
      gameDateTime = game?.DateTime;
    }
    //console.log("GAME DATE TIME:", gameDateTime);
        // Parse the date string into a Date object
    const date = new Date(gameDateTime);

      // Format the time part (hour and minute)
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric'
    };
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

    // Combine the formatted time with "pm ET" for Eastern Time zone
    const formattedTimeStr = `${formattedTime}pm ET`.replace(/(pm)?(?=\sET)/i, '');



    const gameTime = formattedTimeStr;
    //console.log("Formatted Game Time:", gameTime);
    const gameStatus = game?.Status;
    const isClosed = game?.IsClosed;
    let bottomText = "";
    if (gameStatus === "InProgress") {
      // If gameStatus is "InProgress", set bottomText to "LIVE" with the specified style
      bottomText = <span style={{ color: '#CC293C', fontWeight: 'bold' }}>LIVE</span>;
    } else if (isClosed) {
      bottomText = gameStatus;
    } else {
      bottomText = gameTime;
    }
    let userResult = "pending"
    if (isClosed) {
      if (isUserHome) {
        if (homeScore + userLine > awayScore) {
          userResult = "win"
        } else if (homeScore + userLine === awayScore) {
          userResult = "push"
        } else {
          userResult = "loss"
        }
      } else if (!isUserHome) {
        if (awayScore + userLine > homeScore) {
          userResult = "win"
        } else if (awayScore + userLine === homeScore) {
          userResult = "push"
        } else {
          userResult = "loss"
        }
      }
    }
    //console.log("USER RESULT:", userResult);




  return (
    <div className="teams-stat-bar-game-pending">
      <div className="team-lines">
        <b className="spreads">{awayLineDisplay}</b>
        <b className="spreads">{homeLineDisplay}</b>
      </div>
      <div className="layout">
        <div className="visuals">
          <img
            className={!isUserHome ? `team-logo-${userResult}` : 'team-logo'}
            loading="lazy"
            alt=""
            src={awayLogo}
          />
          <div className="caption-wrapper">
            <h1 className="caption">@</h1>
            <div className="game-state">{bottomText}</div>
          </div>
          <img
            className={isUserHome ? `team-logo-${userResult}` : 'team-logo'}
            loading="lazy"
            alt=""
            src={homeLogo}
          />
        </div>
      </div>
      <div className="bottom-container">
    <div className="score">{awayScore}</div>
    <div className="score">{homeScore}</div>
</div>

    </div>
  );
};

export default UserPickBar;