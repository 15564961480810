import React, { useState } from "react";
import { Box, Button, Snackbar, Alert, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const ReferralLinkButton = ({ masterPoolId, gameId, gameAddress, myUserId, referralButtonText, userInGameAdministration }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyClick = () => {
        // Base Link
        let link = `${process.env.REACT_APP_CLIENT_URL}/${gameAddress}?mId=${masterPoolId}&gId=${gameId}&page=home&invite=true`;

        // If the user is logged in, append the user ID to the link
        if (myUserId && !userInGameAdministration) {
            link += `&referralId=${myUserId}`;
        }

        // Copy the link to the clipboard
        navigator.clipboard.writeText(link).then(() => {
            setSnackbarOpen(true); // Open the snackbar when the link is copied
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    // Close the snackbar after 3 seconds
    const handleSnackbarClose = (event, reason) => {
        setSnackbarOpen(false); // Close the snackbar
    };

    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                endIcon={<ContentCopyIcon />}
                sx={{
                    width: '298px',
                    cursor: 'pointer',
                    color: '#fff',
                    backgroundColor: '#07356B',
                    '&:hover': {
                        backgroundColor: '#07356B',
                    },

                }}
                onClick={handleCopyClick}
            >
                {!myUserId ? "INVITE LINK" : referralButtonText ? referralButtonText : "REFERRAL INVITE LINK"}
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // The snackbar will automatically close after 3 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Link copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ReferralLinkButton;