import react, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Modal, Typography, Box } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import PlusClickModal from './PlusClickModal';
import MinusClickModal from './MinusClickModal';
import EditCalendarModal from './EditModalCalendar';
import PoolScorecardDropdown from './PoolScorecardDropdown';
import EditModal from './EditModal';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BirdieConfirmationModal from './BirdieConfirmationModal';
import BirdieAlertModal from './BirdieAlertModal';
import PoolNotStartedYetModal from './PoolNotStartedModal';
import { DateTime } from 'luxon';
import '../styles/birdiePool.scss';
import '../BirdieHeader/BirdieHeaderNav.scss';
import { Add } from '@mui/icons-material';



const MyScorecard = () => {

    const { clubLeaderboardInfo, scorecardState, setScorecardState, selectedHole, myUserId, setSelectedHole, modalState, setModalState, startingScorecard, birdiePoolData, selectedUser, setSelectedUser, selectedUserId, setSelectedUserId, myUserName, userScorecard, getUserScorecard, removedBirdiesIndex, editHole } = useBirdieContext();
    //console.log("STARTING SCORECARD IN MY SCORECARD", birdiePoolData?.courseScorecardInfo);
    const courseScorecardInfo = birdiePoolData?.courseScorecardInfo
    //console.log("Course Scorecard in My Scorecard", courseScorecardInfo);

    const data = clubLeaderboardInfo?.leaderboardInfo;
  //console.log("DATA1", data, myUserName);

  const usersEntry = data?.find(entry => entry.userName === selectedUser && entry.userId === selectedUserId);
  //console.log("USERS ENTRY", usersEntry);

  const getDuplicateOverallRanks = () => {
    const countMap = {};
    data?.forEach(entry => {
      const rank = entry?.rank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    return Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));
  };

  const duplicateOverallRanks = getDuplicateOverallRanks();
  const isUserInDuplicateRanks = (rank) => {
    return duplicateOverallRanks.includes(rank);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Fallback values for the user's entry
  const userRank = usersEntry?.rank ?? '-';
  const userHolesBirdied = usersEntry?.holesBirdied ?? '-';
  const userBirdieCount = usersEntry?.birdieCount ?? '-';
    // // //TODO: Replace this with the actual course scorecard
    // const courseScorecardInfo = [
    //     { holeNumber: 1, par: 4, handicap: 1 },
    //     { holeNumber: 2, par: 4, handicap: 2 },
    //     { holeNumber: 3, par: 3, handicap: 3 },
    //     { holeNumber: 4, par: 5, handicap: 4 },
    //     { holeNumber: 5, par: 4, handicap: 5 },
    //     { holeNumber: 6, par: 3, handicap: 6 },
    //     { holeNumber: 7, par: 4, handicap: 7 },
    //     { holeNumber: 8, par: 4, handicap: 8 },
    //     { holeNumber: 9, par: 5, handicap: 9 },
    //     { holeNumber: 10, par: 4, handicap: 10 },
    //     { holeNumber: 11, par: 4, handicap: 11 },
    //     { holeNumber: 12, par: 3, handicap: 12 },
    //     { holeNumber: 13, par: 5, handicap: 13 },
    //     { holeNumber: 14, par: 4, handicap: 14 },
    //     { holeNumber: 15, par: 3, handicap: 15 },
    //     { holeNumber: 16, par: 4, handicap: 16 },
    //     { holeNumber: 17, par: 4, handicap: 17 },
    //     { holeNumber: 18, par: 5, handicap: 18 }
    // ];

    function birdieCount(hole) {
        const birdieEntry = scorecardState?.find(birdie => birdie.hole === hole);
        return birdieEntry ? birdieEntry?.birdieDates?.length : 0;
    }


    function sumBirdieCount(startHole, endHole) {
        let sum = 0;
        for (let i = startHole; i <= endHole; i++) {
            sum += birdieCount(i);
        }
        return sum;
    }

    const frontNineBirdies = sumBirdieCount(1, 9);
    const backNineBirdies = sumBirdieCount(10, 18);
    const totalBirdies = frontNineBirdies + backNineBirdies;
    //console.log("Sum Test", frontNineBirdies, backNineBirdies, totalBirdies);

    function sumParCount(startHole, endHole) {
        let sum = 0
        for (let i = startHole; i <= endHole; i++) {
            const hole = courseScorecardInfo?.find(h => h.holeNumber === i);
            sum += hole?.par;
        }
        return sum;
    }

    const frontNinePar = sumParCount(1, 9);
    const backNinePar = sumParCount(10, 18);
    const totalPar = frontNinePar + backNinePar;
    //console.log("Par Test", frontNinePar, backNinePar, totalPar);

    function hasHoleBeenBirdied(hole) {
        const birdieEntry = scorecardState?.find(entry => entry.hole === hole);
        return birdieEntry ? birdieEntry?.birdieDates?.length > 0 : false;
    }

    function holesBirdiedCount(startHole, endHole) {
        let count = 0;
        for (let i = startHole; i <= endHole; i++) {
            if (hasHoleBeenBirdied(i)) {
                count++;
            }
        }
        return count;
    }

    const frontNineBirdied = holesBirdiedCount(1, 9);
    const backNineBirdied = holesBirdiedCount(10, 18);
    const totalBirdied = frontNineBirdied + backNineBirdied;
    //console.log("Birdied Test", frontNineBirdied, backNineBirdied, totalBirdied);

    const dateFormat = "yyyy/MM/dd"; // The format of the date in your DB

    // Parse the start date using Luxon's fromFormat
    const poolStartDate = birdiePoolData?.startDate
    ? DateTime.fromFormat(birdiePoolData.startDate, dateFormat).setZone('America/New_York')
    : DateTime.invalid("Invalid Date");

    //const testStartDate = "2024/06/11";
    //const testPoolStartDate = DateTime.fromFormat(testStartDate, dateFormat).setZone('America/New_York');
    const nowInNewYork = DateTime.now().setZone('America/New_York');
    //const testHasThePoolStarted = testPoolStartDate <= nowInNewYork;
    const hasPoolStarted = poolStartDate <= nowInNewYork;
    //const hasPoolStarted = false;  // USED FOR TESTING PURPOSES.  Actual code for it is right above this line.
    //console.log("Birdie Pool Data in My Scorecard:", birdiePoolData, poolStartDate, hasPoolStarted, nowInNewYork);



    const onPlusClick = (hole) => {
        if (hasPoolStarted) {
            setSelectedHole(hole);
            setModalState("plus");
            //console.log("Selected Hole", hole);
        } else {
            setModalState("notStarted");
        }
    };



    const onMinusClick = (hole) => {
        setSelectedHole(hole);
        setModalState("minus");
        //console.log("Selected Hole", hole);
    };


    const doesUserHaveAnyBirdies = scorecardState?.some(h => h.birdieDates.length > 0);
    //console.log("Does User Have Any Birdies:", doesUserHaveAnyBirdies);



    const handleMyScorecardClick = () => {
        setSelectedUser(myUserName);
        setSelectedUserId(myUserId);
        setScorecardState(userScorecard);
    }


    return (
        <div style={{margin:'0 auto', maxWidth:'750px'}}>

            <>
        <div>
        {(selectedUser === myUserName  && selectedUserId === myUserId) ? (
            <Typography variant='h5' style={{ textAlign: 'center', marginTop: '20px', textDecoration: 'underline', fontWeight:700 }}>My Scorecard</Typography>
        ) : (
            <Typography variant='h5' style={{ textAlign: 'center', marginTop: '20px', textDecoration: 'underline', fontWeight:700 }}>{selectedUser}'s Scorecard</Typography>
        )}
            <PoolScorecardDropdown />

            <Box display="flex" justifyContent="center">
                {(selectedUser !== myUserName || selectedUserId !== myUserId) && (
                    <Typography
                        onClick={handleMyScorecardClick}
                        sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#00AAD1',
                            fontSize: '13px',
                            fontWeight: '700',
                            marginBottom: '20px',
                        }}
                    >
                        Back to My Scorecard
                    </Typography>
                )}
            </Box>

            {(selectedUser === myUserName  && selectedUserId === myUserId) ? (
                <>
                    <div style={{fontWeight: '700', marginTop:'20px'}}>Click "+" to add a Birdie.</div>
                    <div style={{fontWeight: '700', marginBottom:'20px'}}>Click "-" to remove a Birdie.</div>
                    <Button
                        className={`submit-button ${!doesUserHaveAnyBirdies ? '' : 'active'}`}
                        style={{ width: '150px' }}
                        disabled={!doesUserHaveAnyBirdies}
                        variant="contained"
                        size="medium"
                        onClick={(e) => setModalState("edit")}
                    >
                        EDIT DATES
                    </Button>
                </>
            ) : (
                <>
                {isMobile ? (
                  <> {/* Mobile View */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between', maxWidth:'250px', margin:'0 auto'}}> {/* Flexbox for mobile */}
                      <Box>
                        <Typography className={"bracket-box-header-headings"} variant='h4'>RANK</Typography>
                        <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800' }}>{isUserInDuplicateRanks(userRank) ? 'T' : ''}{userRank}</Typography>
                      </Box>
                      <Box>
                        <Typography className={"bracket-box-header-headings"} variant='h4'>HOLES BIRDIED</Typography>
                        <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800' }}>{userHolesBirdied}/18</Typography>
                      </Box>
                      <Box>
                        <Typography className={"bracket-box-header-headings"} variant='h4'>TOTAL BIRDIES</Typography>
                        <Typography className={"bracket-box-header-values"} variant='h3' sx={{ fontWeight: '800' }}>{userBirdieCount}</Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <> {/* Desktop View */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between', maxWidth:'500px', margin:'0 auto' }}> {/* Flexbox for desktop */}
                      <Box>
                        <Typography className={"bracket-box-header-headings"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>RANK</Typography>
                        <Typography className={"bracket-box-header-values"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>{isUserInDuplicateRanks(userRank) ? 'T' : ''}{userRank}</Typography>
                      </Box>
                      <Box>
                        <Typography className={"bracket-box-header-headings"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>HOLES BIRDIED</Typography>
                        <Typography className={"bracket-box-header-values"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>{userHolesBirdied}/18</Typography>
                      </Box>
                      <Box>
                        <Typography className={"bracket-box-header-headings"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>TOTAL BIRDIES</Typography>
                        <Typography className={"bracket-box-header-values"} style={{ fontSize: isMobile ? '20px': '20px'}} variant='h4'>{userBirdieCount}</Typography>
                      </Box>
                    </Box>
                  </>
                )}
                <Button
                  className={`submit-button ${!doesUserHaveAnyBirdies ? '' : 'active'}`}
                  style={{ width: '150px', marginTop: '20px' }}
                  disabled={!doesUserHaveAnyBirdies}
                  variant="contained"
                  size="medium"
                  onClick={(e) => setModalState("edit")}
                >
                  VIEW BIRDIES
                </Button>
              </>

            )}
                <TableContainer style={{ width: '95%', marginBottom: '20px'}} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="scoreCardTableCells" style={{ width: '32px', border: '2px solid #002129', fontSize:'14px', fontWeight:'700'}} classes={{ root: 'table-header' }}>Hole</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ width: '39px', border: '2px solid #002129', fontSize:'14px', fontWeight:'700'}} classes={{ root: 'table-header' }}>Par</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ width: '48px', border: '2px solid #002129', fontSize:'14px', fontWeight:'700'}} classes={{ root: 'table-header' }}>HCP</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ width: '38px', borderTop: '2px solid #002129', borderBottom: '2px solid #002129', borderRight: '18px solid #002129', fontSize:'14px', fontWeight:'700'}} classes={{ root: 'table-header' }}>Birdie?</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ width: '110px', border: '2px solid #002129', fontWeight:'700'}} classes={{ root: 'custom-table-header' }}>Total Birdies</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courseScorecardInfo?.slice(0, 9).map(({ holeNumber, handicap, par }, index) => (
                                <TableRow key={holeNumber} className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{holeNumber}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{par}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{handicap}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderRight: '18px solid #002129' }}>{birdieCount(holeNumber) > 0 ? <CheckIcon sx={{ color: '#00AA72', height: '25px' }} /> : ''}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>
                                        <div className="counter">
                                            {(selectedUser === myUserName  && selectedUserId === myUserId) && (
                                                <button
                                                    disabled={birdieCount(holeNumber) <= 0}
                                                    onClick={() => onMinusClick(holeNumber)}
                                                    id="decrement"
                                                    style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', marginRight: '5px'}}
                                                >
                                                    <RemoveIcon />
                                                </button>
                                            )}
                                            <span id="count">{birdieCount(holeNumber)}</span>
                                            {(selectedUser === myUserName  && selectedUserId === myUserId) && (
                                                <button
                                                    onClick={() => onPlusClick(holeNumber)}
                                                    style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', marginLeft: '5px'}}
                                                >
                                                    <AddIcon />
                                                </button>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow className='even-row'>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>OUT</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>{frontNinePar}</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderRight: '18px solid #002129', borderTop: '3px solid #002129' }}>{frontNineBirdied}/9</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>{frontNineBirdies}</TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell className="scoreCardTableCells" style={{ border: '2px solid #002129', fontWeight:'700' }} classes={{ root: 'table-header' }}>Hole</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ border: '2px solid #002129' , fontWeight:'700'}} classes={{ root: 'table-header' }}>Par</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ border: '2px solid #002129', fontWeight:'700' }} classes={{ root: 'table-header' }}>HCP</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderTop: '2px solid #002129', fontWeight:'700', borderBottom: '2px solid #002129', borderRight: '18px solid #002129' }} classes={{ root: 'table-header' }}>Birdie?</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ border: '2px solid #002129', fontWeight:'700' }} classes={{ root: 'custom-table-header' }}>Total Birdies</TableCell>
                                </TableRow>
                            {courseScorecardInfo?.slice(9, 18).map(({ holeNumber, handicap, par }, index) => (
                                <TableRow key={holeNumber} className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{holeNumber}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{par}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>{handicap}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderRight: '18px solid #002129' }}>{birdieCount(holeNumber) > 0 ? <CheckIcon sx={{ color: '#00AA72', height: '25px' }} /> : ''}</TableCell>
                                    <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129' }}>
                                        <div className="counter">
                                            {(selectedUser === myUserName  && selectedUserId === myUserId) && (
                                                <button
                                                    disabled={birdieCount(holeNumber) <= 0}
                                                    onClick={() => onMinusClick(holeNumber)}
                                                    id="decrement"
                                                    style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', marginRight: '5px'}}
                                                >
                                                    <RemoveIcon />
                                                </button>
                                            )}
                                            <span id="count">{birdieCount(holeNumber)}</span>
                                            {(selectedUser === myUserName  && selectedUserId === myUserId) && (
                                                <button
                                                    onClick={() => onPlusClick(holeNumber)}
                                                    style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', marginLeft: '5px'}}
                                                >
                                                    <AddIcon />
                                                </button>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow className='even-row'>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>IN</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>{backNinePar}</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderRight: '18px solid #002129', borderTop: '3px solid #002129' }}>{backNineBirdied}/9</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}>{backNineBirdies}</TableCell>
                            </TableRow>
                            <TableRow className='bottom-row'>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}>TOT</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}>{totalPar}</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}></TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderRight: '18px solid #002129', borderTop: '4px solid #002129',borderBottom:'2px solid #002129' }}>{totalBirdied}/18</TableCell>
                                <TableCell className="scoreCardTableCells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}>{totalBirdies}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
        </div>
        <div>
            {modalState === "plus" && (
                <Modal
                    open={modalState === "plus"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ height: '425px', maxWidth: '375px' }}>
                        <PlusClickModal />
                    </div>
                </Modal>
            )}
            {modalState === "minus" && (
                <Modal
                    open={modalState === "minus"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ maxWidth: '375px'}}>
                        <MinusClickModal />
                    </div>
                </Modal>
            )}
            {modalState === "edit" && (
                <Modal
                    open={modalState === "edit"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ height: '450px', maxWidth: '350px'}}>
                        <EditModal />
                    </div>
                </Modal>
            )}
            {modalState === "confirmDuplicate" && (
                <Modal
                    open={modalState === "confirmDuplicate"}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ height: '360px', maxWidth: '350px'}}>
                        <BirdieConfirmationModal />
                    </div>
                </Modal>
            )}
            {modalState === "editCalendar" && (
                <Modal
                    open={modalState === "editCalendar"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ height:'450px', maxWidth: '350px' }}>
                        <EditCalendarModal />
                    </div>
                </Modal>
            )}
            {modalState === "submissionError" && (
                <Modal
                    open={modalState === "submissionError"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    BackdropProps={{
                        onClick: (e) => e.stopPropagation(),
                      }}
                >
                    <div className="modal-container">
                        <div style={{ marginTop: '10px' }}>An error occurred while updating your scorecard. Please try again.</div>
                        <br></br>
                        <Button style={{ marginTop: '18px' }} className='submit-button active' onClick={() => window.location.href = window.location.href} >BACK</Button>
                    </div>
                </Modal>
            )}
            {modalState === "birdieAlert" && (
                <Modal
                    open={modalState === "birdieAlert"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container" style={{ maxWidth: '350px'}}>
                        <BirdieAlertModal />
                    </div>
                </Modal>
            )}
            {modalState === 'deletedAlert' && (
                <Modal
                    open={modalState === 'deletedAlert'}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >

                    <div className="modal-container" style={{ maxWidth: '350px', height:'300px'}}>
                    <Typography variant='h4'>
                        {removedBirdiesIndex.length === 1 ? "Birdie Deleted" : "Birdies Deleted"}
                    </Typography>
                    <Typography variant='h4'>Hole {selectedHole}</Typography>
                    <Typography style = {{ marginTop: '25px'}} variant='h6'>Your {removedBirdiesIndex.length === 1 ? "birdie" : "birdies"} on Hole {selectedHole} {removedBirdiesIndex.length === 1 ? "has" : "have"} been removed. </Typography>
                    <Button style={{ marginTop: '35px' }} className='submit-button active' onClick={() => window.location.href = window.location.href} >EXIT</Button>
                    </div>
                </Modal>
            )}
            {modalState === 'editedAlert' && (
                <Modal
                    open={modalState === 'editedAlert'}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >

                    <div className="modal-container" style={{ maxWidth: '350px', height:'300px'}}>
                    <Typography variant='h4'>
                        Date Changed
                    </Typography>
                    <Typography variant='h4'>Hole {editHole}</Typography>
                    <Typography style = {{ marginTop: '25px'}} variant='h5'>Your birdie on Hole {editHole} has been edited. </Typography>
                    <Button style={{ marginTop: '35px' }} className='submit-button active' onClick={() => window.location.href = window.location.href} >EXIT</Button>
                    </div>
                </Modal>
            )}
            {modalState === "notStarted" && (
                <Modal
                    open={modalState === "notStarted"}
                    onClose={() => setModalState(null)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div className="modal-container">
                        <PoolNotStartedYetModal />
                    </div>
                </Modal>
            )}
        </div>

</>
</div>
    );
}
export default MyScorecard;