import React, { useEffect, useState } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import { v4 as uuidv4 } from "uuid";
import { set } from "lodash";

const EditPropsModal = ({ isOpen, onClose, setPropsToAdd, propsToAdd, propToEdit, setPropToEdit, propTypeToEdit, setPropTypeToEdit }) => {
    const { pageState, setPageState, leagueState, setLeagueState } = useAdminDashContext();

    // State to track the selected prop type
    const [propId, setPropId] = useState(propToEdit?.id);
    const [propType, setPropType] = useState(propTypeToEdit || "gameProps");
    const [propChoice, setPropChoice] = useState(propToEdit?.propChoice || "");
    const [tableHeader, setTableHeader] = useState(propToEdit?.tableHeader || "");
    const [propText, setPropText] = useState(propToEdit?.propText || "");
    const [player, setPlayer] = useState(propToEdit?.player || "");
    const [team, setTeam] = useState(propToEdit?.team || "");
    const [optionsCount, setOptionsCount] = useState(propToEdit?.options?.length || 2); // Track the number of options selected
    const [options, setOptions] = useState(propToEdit?.options || ["Over", "Under"]); // Default to ["Over", "Under"] if undefined
    const [optionsAbbrev, setOptionsAbbrev] = useState(propToEdit?.optionsAbbrev || ["O", "U"]); // Default to ["O", "U"] if undefined
    const [line, setLine] = useState(propToEdit?.line || "");
    const [points, setPoints] = useState(propToEdit?.points || 0);
    console.log("Prop Type" , propType, "Prop Choice", propChoice, "Table Header", tableHeader, "Prop Text", propText, "Player", player, "Team", team, "Options Count", optionsCount, "Options", options, "Options Abbrev", optionsAbbrev, "Line", line, "Points", points);
    console.log("Prop to Edit", propToEdit, "Prop Type to Edit", propTypeToEdit);
   
    useEffect(() => {
        if (propToEdit) {
            setPropId(propToEdit.id);
            setPropType(propToEdit.propType);
            setPropChoice(propToEdit.propChoice);
            setTableHeader(propToEdit.tableHeader);
            setPropText(propToEdit.propText);
            setPlayer(propToEdit.player || "");
            setTeam(propToEdit.team || "");
            setOptionsCount(propToEdit.options.length);
            setOptions(propToEdit.options);
            setOptionsAbbrev(propToEdit.optionsAbbrev);
            setLine(propToEdit.line || "");
            setPoints(propToEdit.points || 0);
        }
        if (propTypeToEdit) {
            setPropType(propTypeToEdit);
        }
    }, [propToEdit, propTypeToEdit]);
    

    // Function to reset the form fields
    const resetForm = () => {
        setPropType("gameProps");
        setPropChoice("");
        setTableHeader("");
        setPropText("");
        setPlayer("");
        setTeam("");
        setOptionsCount(2);
        setOptions(["Over", "Under"]);
        setOptionsAbbrev(["O", "U"]);
        setLine("");
        setPoints(0);
    };

    const handleOptionCountChange = (e) => {
        const count = parseInt(e.target.value);
        setOptionsCount(count);
        setOptions(Array.from({ length: count }, (_, index) => `Option ${index + 1}`));
        setOptionsAbbrev(Array.from({ length: count }, (_, index) => `Option ${index + 1}`));    
    };

    const handleAddProp = () => {
        const newProp = {
            id: propId,
            propChoice,
            tableHeader,
            propText,
            options: options.filter(option => option !== null), // Filter out null options
            optionsAbbrev: optionsAbbrev.filter(abbrev => abbrev !== null), // Filter out null abbreviations
            ...(line && { line }), // Conditionally add line only if it's truthy
            ...(player && { player }), // Conditionally add player only if it's truthy
            ...(team && { team }), // Conditionally add team only if it's truthy
            ...(points && { points: Number(points) }), // Always add points as a number if it exists and is truthy
        };
    
        if (propType === "tiebreakers") {
            newProp.tiebreaker = true;
        }
    
        setPropsToAdd((prevProps) => 
            prevProps.map((propGroup) => {
                // Remove the prop with the same id from other prop types
                const filteredProps = propGroup.createdProps.filter((prop) => prop.id !== propId);
    
                if (propGroup.propType === propType) {
                    // Check if the prop already exists in the current group
                    const propIndex = filteredProps.findIndex((prop) => prop.id === propId);
    
                    if (propIndex !== -1) {
                        // Replace the existing prop with the new one
                        const updatedCreatedProps = [...filteredProps];
                        updatedCreatedProps[propIndex] = newProp;
    
                        return {
                            ...propGroup,
                            createdProps: updatedCreatedProps,
                        };
                    } else {
                        // Add the new prop if it doesn't exist
                        return {
                            ...propGroup,
                            createdProps: [...filteredProps, newProp],
                        };
                    }
                }
    
                // Return the group with the prop removed if it existed
                return {
                    ...propGroup,
                    createdProps: filteredProps,
                };
            })
        );
    
        console.log("New Prop Added or Replaced:", newProp);
        resetForm(); // Clear the form after adding the prop
        setPropToEdit(null);
        setPropTypeToEdit(null);
        onClose();
    };
    
    

    // Reset form on cancel
    const handleClose = () => {
        resetForm(); // Clear the form on close as well
        setPropToEdit(null);
        setPropTypeToEdit(null);
        onClose();
    };
    

    if (!isOpen) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
               
                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Prop Type</label>
                    <select
                        value={propType}
                        onChange={(e) => setPropType(e.target.value)}
                        style={styles.input}
                    >
                        <option value="gameProps">Game Props</option>
                        <option value="homeProps">Home Props</option>
                        <option value="awayProps">Away Props</option>
                        <option value="tiebreakers">Tiebreakers</option>
                    </select>
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Prop Choice</label>
                    <input
                        type="text"
                        value={propChoice}
                        onChange={(e) => setPropChoice(e.target.value)}
                        style={styles.input}
                    />
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Table Header</label>
                    <input
                        type="text"
                        value={tableHeader}
                        onChange={(e) => setTableHeader(e.target.value)}
                        style={styles.input}
                    />
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Prop Text</label>
                    <input
                        type="text"
                        value={propText}
                        onChange={(e) => setPropText(e.target.value)}
                        style={styles.input}
                    />
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Player</label>
                    <input
                        type="text"
                        value={player}
                        onChange={(e) => setPlayer(e.target.value)}
                        style={styles.input}
                    />
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Team</label>
                    <input
                        type="text"
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                        style={styles.input}
                    />
                </div>

                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Options</label>
                    <select
                        value={optionsCount}
                        onChange={handleOptionCountChange}
                        style={styles.input}
                    >
                        {[...Array(10).keys()].map((num) => (
                            <option key={num + 1} value={num + 1}>{num + 1}</option>
                        ))}
                    </select>
                </div>

                {/* Render the dynamic text fields for options and their abbreviations */}
                {Array.from({ length: optionsCount }).map((_, index) => (
    <div style={styles.optionContainer} key={index}>
        <div style={styles.fieldContainer}>
            <label style={styles.label}>{`Option ${index + 1}`}</label>
            <input
                type="text"
                value={options[index] || ""}
                onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value; // Update the specific option
                    setOptions(newOptions);
                }}
                style={styles.input}
            />
        </div>
        <div style={styles.fieldContainer}>
            <label style={styles.label}>{`Abbrev ${index + 1}`}</label>
            <input
                type="text"
                value={optionsAbbrev[index] || ""}
                onChange={(e) => {
                    const newAbbrevs = [...optionsAbbrev];
                    newAbbrevs[index] = e.target.value; // Update the specific abbreviation
                    setOptionsAbbrev(newAbbrevs);
                }}
                style={styles.input}
            />
        </div>
    </div>
))}



                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Line</label>
                    <input
                        type="text"
                        value={line}
                        onChange={(e) => setLine(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Points</label>
                    <input
                        type="number"
                        value={points}
                        onChange={(e) => setPoints(e.target.value)}
                        style={styles.input}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button 
                        onClick={handleClose} 
                        style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", marginRight: '35px' }}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={handleAddProp} 
                        style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }}
                    >
                        Add Prop
                    </button>
                </div>

                {/* Additional modal content or action buttons */}
            </div>
        </div>
    );
};


const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10000,
    },
    modal: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "90%",
        maxWidth: "800px",
        position: "relative",
        maxHeight: "90%",
        overflowY: "auto",
    },
    optionContainer: {
        marginBottom: '10px', // Space between different option groups
    },
    fieldContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "5px" // 5px space between each line
    },
    label: {
        width: "100px", // Adjust width as needed
        marginRight: "8px",
    },
    input: {
        padding: "8px",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
    },
};

export default EditPropsModal;
