import React, { useState, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Box } from '@mui/material';
import { hexToRgba } from "../../Brackets/bracketUtils/HexToRgba";
import CloseIcon from '@mui/icons-material/Close';
import './SuperPickemLeaderboard.scss';
import { amITied } from "../utils/sharedUtils";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { has } from "lodash";

const BracketLeaderboard = ({
    leaderboard,
    myUserId,
    hasBracketDeadlinePassed,
    teams,
    gatherLogo,
    usersInfo,
    truncateUsername,
    handleUsernameClick,
    bracketRankPlaceholder,
}) => {
    const [sortConfig, setSortConfig] = useState({
        key: 'bracketRank', // Default sort by overallRank
        direction: 'asc'
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const sortedData = useMemo(() => {
        if (!leaderboard) return [];
    
        return [...leaderboard].sort((a, b) => {
            const rankColumns = ['overallRank', 'bracketRank', 'dailyPicksRank'];
            const pointsColumns = ['totalPoints', 'bracketPoints', 'dailyPicksPoints', 'maxBracketPoints', 'spreadWins', 'overUnderWins'];
    
            const key = sortConfig.key; // Current column being sorted
            const direction = sortConfig.direction; // Sort direction: 'asc' or 'desc'
    
            // Rank Columns Logic
            if (rankColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing ranks go to bottom in ascending, top in descending
                if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Points Columns Logic
            if (pointsColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'desc' ? 1 : -1; // Missing points go to bottom in descending, top in ascending
                if (bIsMissing) return direction === 'desc' ? -1 : 1; // Reverse for ascending
                return direction === 'asc' ? aValue - bValue : bValue - aValue; // Normal numeric sorting
            }
    
            // Fallback for String Columns
            const aValue = a[key];
            const bValue = b[key];
            const aIsMissing = aValue == null || aValue === ''; // Missing or empty
            const bIsMissing = bValue == null || bValue === '';
    
            if (aIsMissing && bIsMissing) return 0; // Both missing are equal
            if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing goes to bottom in ascending
            if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
            return direction === 'asc'
                ? (aValue || '').localeCompare(bValue || '')
                : (bValue || '').localeCompare(aValue || '');
        });
    }, [leaderboard, sortConfig]);

    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            // If sorting by the same column, toggle direction
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
                };
            }
            
            if (key === 'bracketRank') {
                return {
                    key,
                    direction: 'asc'
                };
            }

            // If sorting by a new column, default to ascending
            return {
                key,
                direction: 'desc'
            };
        });
    };

    const findChampColor = (result) => {
        if (result === 'Win') {
            return '#00AA72';
        } else if (result === 'Loss') {
            return '#CC293C';
        } else {
            return '#002129';
        }
    }

    const findChampBackgroundColor = (result) => {
        if (result === 'Win') {
            return hexToRgba('#00AA72', 0.2);
        } else if (result === 'Loss') {
            return hexToRgba('#CC293C', 0.2);
        } else {
            return 'transparent';
        }
    }

    const rankHeaderColor = sortConfig.key === 'overallRank' ? '#FFC60A' : '#FFFFFF';
    const bracketPointsHeaderColor = sortConfig.key === 'bracketPoints' ? '#FFC60A' : '#FFFFFF';
    const maxPointsHeaderColor = sortConfig.key === 'maxBracketPoints' ? '#FFC60A' : '#FFFFFF';
    const playerHeaderColor = sortConfig.key === 'username' ? '#FFC60A' : '#FFFFFF';

    return (
        <>
            {hasBracketDeadlinePassed && (
                <Box>
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            textAlign: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        To view a user's bracket, click on their username.
                    </Typography>
                </Box>
            )}

            <Grid item xs={12} sx={{overflow: 'scroll'}}>
                <TableContainer component={Paper}>
                    <Table className="super-pickem-leaderboard-table">
                        <TableHead sx={{
                                background: '#002129',
                                color:'#fff'
                            }}>


                            {usersInfo ?
                                <TableRow sx={{ background: '#F0F1F1', color: '#002129' }}>
                                    <TableCell 
                                        sx={{ 
                                            textAlign: 'center', 
                                            fontSize: '12px',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 100,
                                            backgroundColor: '#F0F1F1',
                                        }}
                                    >
                                        {!hasBracketDeadlinePassed ? (
                                            <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                T1
                                            </Typography>
                                        ) : !usersInfo?.bracketRank ? (
                                            <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                {bracketRankPlaceholder}
                                            </Typography>
                                        ) :
                                        amITied(usersInfo?.bracketRank, 'bracketRank', leaderboard) ? (
                                            <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                T{usersInfo?.bracketRank}
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                {usersInfo?.bracketRank}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    
                                    <TableCell 
                                        sx={{
                                            textAlign:'left', 
                                            fontSize:'12px',
                                            position: 'sticky',
                                            left: '75px',
                                            zIndex: 100,
                                            backgroundColor: '#F0F1F1',
                                            textDecoration: hasBracketDeadlinePassed ? 'underline' : 'none',
                                            cursor: hasBracketDeadlinePassed ? 'pointer' : 'default',
                                            fontWeight: hasBracketDeadlinePassed ? '700' : '400',
                                            }}
                                            onClick={() => hasBracketDeadlinePassed ? handleUsernameClick(usersInfo?.userId, 'bracket') : null}
                                        >
                                            {truncateUsername(usersInfo?.username)} <br />
                                            {/* {hasBracketDeadlinePassed ? 'Tiebreaker:' : null} */}
                                    </TableCell>
                                    
                                    <TableCell 
                                        sx={{ 
                                            textAlign: 'center', 
                                            fontSize: '12px', 
                                            width: { md: "5%", lg: "5%" } 
                                        }}
                                    >
                                        {usersInfo?.champ ? (
                                            <img
                                                className="super-pickem-leaderboard-champ-image"
                                                style={{
                                                    border: `1px solid ${findChampColor(usersInfo?.champResult)}`,
                                                    backgroundColor: findChampBackgroundColor(usersInfo?.champResult),
                                                }}
                                                src={gatherLogo(usersInfo?.champ, teams)}
                                                alt="TEAM LOGO"
                                            />
                                        ) : !hasBracketDeadlinePassed ? (
                                            <Box
                                                className="super-pickem-leaderboard-champ-placeholder"
                                            />
                                        ) : (
                                            <Box
                                                className="super-pickem-leader-no-champ"
                                            >
                                                <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                            </Box>
                                        )}
                                    </TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"} }}>
                                        {usersInfo?.bracketPoints || 0}
                                    </TableCell>
                                    
                                    <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"}}}>
                                        {usersInfo?.maxBracketPoints || 0}
                                    </TableCell>
                                </TableRow>
                                :
                                <></>
                            }

                            <TableRow 
                                sx={{
                                    color:'#fff'
                                }}>
                                        <TableCell
                                            onClick={() => handleSort('bracketRank')}
                                            sx={{
                                                color: rankHeaderColor,
                                                fontWeight: 900,
                                                textAlign: 'center',
                                                // width: "30px",
                                                textDecoration: 'underline',
                                                position: 'sticky',
                                                backgroundColor: '#002129',
                                                zIndex: 100,
                                                left: 0,
                                            }}
                                        >
                                            RANK
                                        </TableCell>

                                        <TableCell 
                                            onClick={() => handleSort('username')}
                                            sx={{ 
                                                color: playerHeaderColor, 
                                                fontWeight: 900, 
                                                textAlign: 'left',
                                                textDecoration: 'underline',
                                                position: 'sticky',
                                                backgroundColor: '#002129',
                                                zIndex: 100,
                                                left: '75px',
                                            }}
                                        >
                                            PLAYER
                                        </TableCell>
                                        
                                        <TableCell sx={{ color: '#fff', fontWeight: 900, textAlign: 'center'}} >CHAMP</TableCell>

                                        <TableCell
                                            onClick={() => handleSort('bracketPoints')}
                                            sx={{
                                                color: bracketPointsHeaderColor,
                                                fontWeight: 900,
                                                textAlign: 'center',
                                                position: 'relative',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            BRACKET PTS
                                        </TableCell>

                                        <TableCell
                                            onClick={() => handleSort('maxBracketPoints')} 
                                            sx={{ 
                                                color: maxPointsHeaderColor, 
                                                fontWeight: 900, 
                                                textAlign: 'center', 
                                                position: 'relative',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            POTENTIAL PTS
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                            <TableBody>
                                {sortedData?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                        }}
                                    >
                                            <TableCell 
                                                sx={{ 
                                                    textAlign: 'center', 
                                                    fontSize: '12px',
                                                    position: 'sticky',
                                                    left: 0,
                                                    zIndex: 100,
                                                    backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                                }}
                                            >
                                            {!hasBracketDeadlinePassed ? (
                                                "T1"
                                            ) : !row?.bracketRank ? (
                                                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                    {bracketRankPlaceholder}
                                                </Typography>
                                            ) :
                                            amITied(row?.bracketRank, 'bracketRank', leaderboard) ? (
                                                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                    T{row?.bracketRank}
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                    {row?.bracketRank}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        
                                        <TableCell 
                                            sx={{
                                                textAlign:'left', 
                                                fontSize:'12px',
                                                position: 'sticky',
                                                left: '75px',
                                                zIndex: 100,
                                                backgroundColor: row?.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                                textDecoration: hasBracketDeadlinePassed ? 'underline' : 'none',
                                                cursor: hasBracketDeadlinePassed ? 'pointer' : 'default',
                                                fontWeight: hasBracketDeadlinePassed ? '700' : '400',
                                            }}
                                            onClick={() => hasBracketDeadlinePassed ? handleUsernameClick(row?.userId, 'bracket') : null}
                                        >
                                            {truncateUsername(row?.username)} <br />
                                            {/* {hasBracketDeadlinePassed ? 'Tiebreaker:' : null} */}
                                        </TableCell>
                                        
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                fontSize: '12px', 
                                            }}
                                        >
                                            {(!hasBracketDeadlinePassed && (!row?.champ || row?.userId !== myUserId)) ? (
                                                <Box
                                                    className="super-pickem-leaderboard-champ-placeholder"
                                                />
                                            ) : row?.champ ? (
                                                <img
                                                    className="super-pickem-leaderboard-champ-image"
                                                    style={{
                                                        border: `1px solid ${findChampColor(row?.champResult)}`,
                                                        backgroundColor: findChampBackgroundColor(row?.champResult),
                                                    }}
                                                    src={gatherLogo(row?.champ, teams)}
                                                    alt="TEAM LOGO"
                                                />
                                            ) : (
                                                <Box
                                                    className="super-pickem-leader-no-champ"
                                                >
                                                    <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                </Box>
                                            )}
                                        </TableCell>
                                        
                                        <TableCell sx={{ textAlign: 'center', fontSize: '12px'}}>
                                            {row?.bracketPoints || 0}
                                        </TableCell>
                                        
                                        <TableCell sx={{ textAlign: 'center', fontSize: '12px' }}>
                                            {row?.maxBracketPoints || 0}
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
</>

    )
}

export default BracketLeaderboard;