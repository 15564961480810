import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';

const StaticDateDisplay = ({ date, deadlineInfo }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const formatDateString = (dateString) => {
        // Check if the dateString contains "@" which indicates it's already formatted
        if (typeof dateString === 'string' && dateString.includes('@')) {
            return dateString; // Return as is if it's already in the desired format
        }

        try {
            const parsedDate = DateTime.fromISO(dateString, { zone: 'America/New_York' });
            
            // Check if the date is valid before formatting
            if (parsedDate.isValid) {
                const formattedDate = parsedDate.toFormat("ccc M/d");
                const formattedTime = parsedDate.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');
                return `${formattedDate} @ ${formattedTime}`;
            } else {
                // If parsing failed, return the original string
                return dateString;
            }
        } catch (error) {
            console.error('Error parsing date:', error);
            return dateString; // Return original string if there's an error
        }
    };

    const formattedDate = formatDateString(date);

    const backgroundColor = deadlineInfo?.backgroundColor || '#FFC60A';
    const textColor = deadlineInfo?.textColor || '#002129';
    const topBorder = deadlineInfo?.topBorder;
    const bottomBorder = deadlineInfo?.bottomBorder;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px 0',
                backgroundColor: backgroundColor,
                borderTop: topBorder ? topBorder : 'none',
                borderBottom: bottomBorder ? bottomBorder : 'none',
                height: isMobile ? '44px' : '64px',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: textColor,
                    fontSize: isMobile ? '16px' : '22px',
                    fontWeight: '700',
                }}
            >
                Pick Deadline: {formattedDate}
            </Typography>
        </Box>
    );
}

export default StaticDateDisplay;