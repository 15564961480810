import axios from "axios";
import { useMutation } from '@tanstack/react-query';    
import { apiUrl } from "../../config";

// Function to handle the POST request
export const useSaveSportsbookPicks = () => {
  return useMutation({
    mutationFn: submitSportsbookPicksRequest,
    onError: (error) => {
      console.error('Error submitting picks:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Picks submitted successfully:', data);
    // }
  });
};

const submitSportsbookPicksRequest = async (pickData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.post(`${apiUrl}/games/save-sportsbook-picks`, pickData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  }); 
  console.log('Picks submitted successfully:', response.data);
  return response.data.data;
};