import React from 'react';
import { Box, Typography, Link } from '@mui/material'; // Import MUI components
import './SponsorTab.scss'; // Import SCSS file

const SponsorSisterLocationsPage = ({ sisterLocations, storyBottomText, highlightsText }) => {
    console.log("Sister Locations, Story Bottom Text, Highlights Text: ", sisterLocations, storyBottomText, highlightsText);
    return (
        <>
            <Box className="sponsor-sister-location-container">
                {sisterLocations?.map((location, index) => (
                    <Box
                        key={index}
                        className={`sponsor-sister-location-item ${sisterLocations.length % 2 === 1 && index === sisterLocations.length - 1 ? 'last-item' : ''}`}
                    >
                        <Typography className="sponsor-sister-location-name">{location?.name}</Typography>
                        <Box className="sponsor-sister-location-logo-container">
                            <img
                                src={location?.logo}
                                alt={location?.name}
                                className="sponsor-sister-location-image"
                            />
                        </Box>

                        <img
                            src={location?.image}
                            alt={location?.name}
                            className="sponsor-sister-location-secondary-image"
                        />
                        <Typography className="sponsor-tab-sponsor-link">
                            {location?.websiteText ? (
                                <Link 
                                href={location?.website} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: '#00AAD1', textDecoration: 'underline' }}
                                >
                                {location?.websiteText}
                                </Link>
                            ) : (
                                location?.address
                            )}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box className="sponsor-tab-container">

                {highlightsText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-main-text">
                        {text}
                    </Typography>
                ))}

                {storyBottomText?.map((text, index) => (
                    <Typography key={index} className="sponsor-tab-bottom-text">
                        {text}
                    </Typography>
                ))}
            </Box>
        </>
    );
}

export default SponsorSisterLocationsPage;
