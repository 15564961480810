import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useValidateUsernameIsUnique } from "../../hooks/auth";
import NextAndBackButtons from "./NextAndBackButtons";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const thirteenYearsAgo = dayjs().subtract(13, "years");

export default function BasicInfo({
  goToNextStep,
  goToPrevStep,
  setFirstName,
  setLastName,
  setUserName,
  userName,
  firstName,
  lastName,
  phone,
  setPhone,
  dateOfBirth,
  setDateOfBirth,
}) {
  const { mutate: validateUsernameIsUnique } = useValidateUsernameIsUnique();
  const [usernameIsNotUnique, setUsernameIsNotUnique] = useState(false);
  const [dobError, setDobError] = useState("");
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const [formValidation, setFormValidation] = useState({
    firstName: {
      isError: false,
      message: "",
    },
    lastName: {
      isError: false,
      message: "",
    },
    dateOfBirth: {
      isError: false,
      message: "",
    },
    phone: {
      isError: false,
      message: "",
    },
    username: {
      isError: false,
      message: "",
    },
  });

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              outlined: {
                backgroundColor: Boolean(dateOfBirth) ? "white" : "transparent",
                padding: "0 5px",
                borderRadius: "5px",
              },
            },
          },
        },
      }),
    [dateOfBirth]
  );

  const validateForm = useCallback(() => {
    const dobIsOldEnough = dateOfBirth?.isBefore(thirteenYearsAgo);
    const dobIsValid = dateOfBirth && dobIsOldEnough;
    let dateOfBirthError = "";
    if (!dateOfBirth && hasSubmittedForm) {
      dateOfBirthError = "Date of birth is required.";
    } else if (!dobIsOldEnough && dateOfBirth) {
      dateOfBirthError = "Players must be at least 13 years of age.";
    }

    if (dobIsValid) {
      setDobError("");
    } else {
      setDobError(dateOfBirthError);
    }

    const phoneNumberIsInvalid = !matchIsValidTel(phone);
    const newFormValidation = {
      firstName: {
        isError: hasSubmittedForm && !firstName,
        message: (!firstName && hasSubmittedForm) ? "First name is required." : "",
      },
      lastName: {
        isError: hasSubmittedForm && !lastName,
        message: (!lastName && hasSubmittedForm) ? "Last name is required." : "",
      },
      dateOfBirth: {
        isError: hasSubmittedForm && !dobIsValid,
        message: !dobIsValid ? dateOfBirthError : "",
      },
      username: {
        isError: hasSubmittedForm && !userName,
        message: !userName ? "Username is required." : "",
      },
      phone: {
        isError: hasSubmittedForm && phoneNumberIsInvalid,
        message: (phoneNumberIsInvalid && hasSubmittedForm) ? "Invalid phone number." : "",
      },
    };

    const allFieldsValid =
      firstName?.length &&
      lastName?.length &&
      userName?.length &&
      dateOfBirth &&
      dobIsValid &&
      !phoneNumberIsInvalid;

    setFormValidation(newFormValidation);
    setAllFieldsFilled(allFieldsValid);
  }, [firstName, lastName, userName, dateOfBirth, phone, hasSubmittedForm]);

  useEffect(() => {
    if (hasSubmittedForm) {
      validateForm();
    }
  }, [firstName, lastName, userName, dateOfBirth, phone, validateForm, hasSubmittedForm]);

  useEffect(() => {
    validateForm();
  }, [firstName, lastName, userName, dateOfBirth, phone]);

  const handleNextButtonClick = () => {
    setUsernameIsNotUnique(false);
    setHasSubmittedForm(true);

    validateForm();

    if (allFieldsFilled) {
      validateUsernameIsUnique(
        { username: userName },
        {
          onSuccess: (isUnique) => {
            if (isUnique) {
              goToNextStep();
            } else {
              setUsernameIsNotUnique(true);
            }
          },
        }
      );
    }
  };

  const handleBackButtonClick = () => {
    setHasSubmittedForm(false);
    goToPrevStep();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          justifyContent: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <TextField
          id="firstName"
          name="firstName"
          label="First Name"
          variant="outlined"
          type="text"
          value={firstName}
          error={formValidation.firstName.isError}
          helperText={formValidation.firstName.message}
          onChange={(e) => {
            setFirstName(e.target.value);
            if (hasSubmittedForm) {
              validateForm();
            }
          }}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Last Name"
          variant="outlined"
          type="text"
          value={lastName}
          error={formValidation.lastName.isError}
          helperText={formValidation.lastName.message}
          onChange={(e) => {
            setLastName(e.target.value);
            if (hasSubmittedForm) {
              validateForm();
            }
          }}
          sx={{
            marginTop: {
              xs: "10px",
              sm: "0",
            },
          }}
        />
      </Box>

      {dobError ? null : (
        <Typography
          pt={1}
          style={{ fontSize: "12px", textAlign: "left", width: "100%" }}
        >
          Players must be at least 13 years of age.
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <Box
            className={`w-full ${dobError ? "bg-red-50 mt-1" : ""}`}
            style={
              dobError ? { outline: "1px solid red", borderRadius: "4px" } : {}
            }
          >
            <DatePicker
              id="dateOfBirth"
              name="dateOfBirth"
              label="Date of Birth"
              variant="outlined"
              className="w-full"
              error={formValidation.dateOfBirth.isError}
              helperText={formValidation.dateOfBirth.message}
              value={dateOfBirth}
              onChange={(newValue) => {
                setDateOfBirth(newValue);
                if (hasSubmittedForm) {
                  validateForm();
                }
              }}
            />
          </Box>
          {Boolean(dobError) && (
            <div className="w-full text-left pl-4">
              <Typography
                color={"error"}
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                {dobError}
              </Typography>
            </div>
          )}
        </ThemeProvider>
      </LocalizationProvider>
      <Typography
        style={{ fontSize: "12px", textAlign: "left", width: "100%" }}
      >
        Usernames will be public and appear on leaderboards.
      </Typography>
      <TextField
        id="username"
        name="username"
        label="Username"
        variant="outlined"
        type="text"
        value={userName}
        error={formValidation.username.isError}
        helperText={formValidation.username.message}
        onChange={(e) => {
          setUserName(e.target.value);
          if (hasSubmittedForm) {
            validateForm();
          }
        }}
      />
      {usernameIsNotUnique && (
        <div className="text-center">
          <Alert severity="error" className="text-center w-full">
            <div>Sorry, that username already exists!</div>
          </Alert>
        </div>
      )}
      <MuiTelInput
        id="phone"
        name="phone"
        label="Phone Number"
        variant="outlined"
        type="text"
        value={phone}
        error={formValidation.phone.isError}
        helperText={formValidation.phone.message}
        autoComplete="off"  // Prevent autofill issues
        defaultCountry="US"  // Force US as the default country
        onChange={(value) => {
          // Ensure phone numbers always start with "+1" for the U.S.
          if (!value.startsWith("+1")) {
            value = "+1" + value.replace(/^\+?1/, '');  // Correctly prepend "+1" if missing
          }

          setPhone(value);

          // Validate form if the form was submitted before
          if (hasSubmittedForm) {
            validateForm();
          }
        }}
      />
      
      <NextAndBackButtons
        nextButtonText={"Continue"}
        onBackButtonClick={handleBackButtonClick}
        onNextButtonClick={handleNextButtonClick}
        disable={!allFieldsFilled}
      />
    </Box>
  );
}
