import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useJoinPublicPool } from './hooks/useJoinPublicPools';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import trophyImage from '../../assets/images/Layer_1.svg';
import SilverTrophy from '../../assets/images/SilverTrophy.svg';
import BronzeTrophy from '../../assets/images/BronzeTrophy.svg';
import SuperPickemTrophies from './SuperPickemTrophies';

const PoolHallList = (props) => {
  const { pools } = props;
  const { mutate: joinPool } = useJoinPublicPool();
  const userData = getUserDataFromLocalStorage();
  const navigateToTop = useNavigateToTop();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //console.log("Pools", pools);

  const [openPool, setOpenPool] = useState(null); // Track open pool
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  };

  // const joinGame = async (gameID, gameType) => {
  //   let data = {
  //     gameID: gameID,
  //     userID: userData.user_id,
  //     gameType: gameType
  //   };

  //   joinPool(data, {
  //     onSuccess: () => {
  //       window.location.href = `/nfl-pickem/${uriEncoded(gameID)}/MY_PICKS`;
  //     },
  //     onError: (error) => {
  //       setStatus("fail");
  //       const message = error.response?.data?.message || 'Sorry an error occurred. Please try again.';
  //       setErrorMessage(message);
  //       console.error("Join pool failed:", error);

  //       setTimeout(() => {
  //         setErrorMessage('');
  //         setStatus(null);
  //       }, 5000);
  //     }
  //   });
  // };

  const handleSelectClick = (gameID, gameAddress) => {
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameID)}/HOME/HOME`);
  };
  

  return (
    <>
      {pools?.map((pool) => (
        <Box
          key={pool?.gameID}
          sx={{ 
            width: '95%',
            maxWidth: '700px',
            height: 'auto',
            minHeight: isMobile ? '64px' : '68px',
            borderRadius: '8px',
            backgroundColor: '#fff',
            border: '2px solid #e8e8e8',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            gap: '16px',
            textAlign: 'left',
            fontSize: '16px',
            color: '#000',
            fontFamily: 'Inter',
            borderBottom: '1px solid #ddd',
            margin: '0 auto',
            alignSelf: 'stretch',
            marginBottom: '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {/* Arrow Down Icon */}
            <KeyboardArrowDownIcon 
              onClick={() => setOpenPool(openPool === pool?.gameID ? null : pool?.gameID)}
              sx={{ 
                marginRight: '8px',
                transition: 'transform 0.1s ease', 
                transform: openPool === pool?.gameID ? 'rotate(180deg)' : 'rotate(0deg)', 
              }} 
            />

            {/* Logo and Game Name - Centered */}
              <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                gap="4px" 
                sx={{ flexGrow: 1, textAlign: 'center' }} // Allow it to grow and take available space
              >
                {pool?.poolType === 'QuickPick' && pool?.sponsorInfo?.hideSponsorInPoolHall && (
                  <img
                    src={pool?.clubLogo}
                    alt="Club Logo"
                    style={{
                      height: '140px',
                      objectFit: 'contain',
                      maxWidth: isMobile ? '90%' : '100%',
                    }}
                  />
                )}
                {pool?.sponsorInfo?.sponsorLogo && !pool?.sponsorInfo?.hideSponsorInPoolHall && (
                  <img 
                    src={pool?.sponsorInfo?.sponsorLogo} 
                    alt="Sponsor Logo" 
                    style={{ 
                      //width: '160px', // Adjust size if necessary
                      height: pool?.poolType !== 'QuickPick' ? '60px' : '70px', 
                      objectFit: 'contain',
                      maxWidth: isMobile ? '90%' : '100%',
                    }} 
                  />
                )}

                {(pool?.poolType !== 'QuickPick' && pool?.poolType !== "SuperPickem") && (
                  <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{
                      fontSize: isMobile ? '16px' : '20px',
                      fontWeight: '800',
                      textAlign: 'center', // Ensure it's centered
                      color: pool?.sponsorInfo?.sponsorLogo ? '#CC293C' : '#002129'
                    }}>
                      {pool?.gameName}
                  </Typography>
                )}

                {pool?.poolType === 'SuperPickem' && (
                  <SuperPickemTrophies
                    logo={pool?.clubLogo}
                    prizes={pool?.prizes}
                  />
                )}

                {/* Parent container to hold the prize rows side by side */}
              {pool?.prizes && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center', // Center the content horizontally
                      alignItems: 'center', // Center the content vertically
                      margin: '0 auto',
                      // marginBottom: '15px',
                      flexWrap: 'wrap', // Allow wrapping if needed
                    }}
                  >
                      {pool?.prizes?.firstPlace && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginRight: '20px', // Add some space between prizes
                          }}
                        >
                          <Box
                            component='img'
                            sx={{
                              display: 'block',
                              width: isMobile ? 16 : 24,
                              marginRight: '10px',
                            }}
                            alt='Pool Party Trophy'
                            src={trophyImage}
                          />
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: isMobile ? '12px' : '16px',
                            }}
                          >
                            1st: {pool?.prizes?.firstPlace}
                          </Typography>
                        </Box>
                      )}

                      {/* Conditionally render the second, third prize, and last place text for "Pickem" */}
                      {pool?.prizes?.secondPlace && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginRight: '20px', // Add some space between prizes
                            }}
                          >
                            <Box
                              component='img'
                              sx={{
                                display: 'block',
                                width: isMobile ? 16 : 24,
                                marginRight: '10px',
                              }}
                              alt='Pool Party Trophy'
                              src={SilverTrophy}
                            />
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: isMobile ? '12px' : '16px',
                              }}
                            >
                              2nd: {pool?.prizes?.secondPlace}
                            </Typography>
                          </Box>
                      )}
                          {pool?.prizes?.thirdPlace && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginRight: '20px', // Add some space between prizes
                              }}
                            >
                              <Box
                                component='img'
                                sx={{
                                  display: 'block',
                                  width: isMobile ? 16 : 24,
                                  marginRight: '10px',
                                }}
                                alt='Pool Party Trophy'
                                src={BronzeTrophy} // Add your bronze trophy image here
                              />
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: isMobile ? '12px' : '16px',
                                }}
                              >
                                3rd: {pool?.prizes?.thirdPlace}
                              </Typography>
                            </Box>
                      )}
                    </Box>

                    {/* Line of text for Last Place */}
                    {pool?.prizes?.lastPlace && (
                      <Box
                        sx={{
                          textAlign: 'center', // Center the text
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: isMobile ? '12px' : '16px',
                          }}
                        >
                          Last Place*: {pool?.prizes?.lastPlace}
                        </Typography>
                      </Box>
                    )}

                    {pool?.prizes?.extraLines?.length > 0 && (
                        <Box
                          sx={{
                            textAlign: 'center', // Center the text
                          }}
                        >
                          {pool.prizes.extraLines.map((line, index) => (
                            <Typography 
                              key={index} 
                              variant="body1"
                              sx={{
                                fontWeight: 600,
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '5px', // Optional: Add some margin if needed
                              }}  
                            >
                              {line}
                            </Typography>
                          ))}
                        </Box>
                      )}

                      {pool?.prizes?.weeklyPrizes && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically in the center
                            marginBottom: '10px', // Optional: Add some margin if needed
                          }}
                        >
                          <Box
                            component='img'
                            sx={{
                              display: 'block',
                              width: isMobile ? 16 : 24,
                              marginRight: '10px',
                            }}
                            alt='Pool Party Trophy'
                            src={trophyImage}
                          />
                          
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: isMobile ? '12px' : '16px',
                                textAlign: 'center', // Optional: Center text if desired
                              }}
                            >
                              Weekly Prizes
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: isMobile ? '12px' : '14px',
                                textAlign: 'center', // Optional: Center text if desired
                              }}
                            >
                              ({pool?.prizes?.weeklyPrizeText})
                            </Typography>
                          </Box>

                          <Box
                            component='img'
                            sx={{
                              display: 'block',
                              width: isMobile ? 16 : 24,
                              marginLeft: '10px',
                            }}
                            alt='Pool Party Trophy'
                            src={trophyImage}
                          />
                        </Box>
                      )}


              </>
            )}
              </Box>

            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSelectClick(pool?.gameID, pool?.gameAddress)}
              sx={{ 
                width: '68px',
                height: '32px',
              }}
            >
              SELECT
            </Button>
          </Box>
          {openPool === pool?.gameID && (
            <>
              <Box
                sx={{
                  maxWidth: isMobile ? '80%' : '628px',
                  marginLeft: '32px',
                  marginRight: '1px',
                }}
              >
                {pool?.poolHall?.body?.map((text, index) => (
                  <Typography 
                    key={index}
                    variant="h6" 
                    component="div"
                    sx={{ 
                      textAlign: 'left',
                      fontSize: isMobile ? '12px' : '16px',
                      fontWeight: '500',
                      marginLeft: isMobile ? '4px' : '5px',  
                      marginBottom: '16px', // Add some space between paragraphs if needed
                    }}
                  >
                    {text}
                  </Typography>
                ))}
              </Box>
            </>
          )}




        </Box>
      ))}
    </>
  );
};

export default PoolHallList;
