import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../styles/birdiePool.scss';

const HoleStats = () => {
    const { birdiePoolData, clubLeaderboardInfo } = useBirdieContext();
    //console.log("STARTING STATS HOLE STATS", birdiePoolData?.courseScorecardInfo, clubLeaderboardInfo);
    const birdieStats = clubLeaderboardInfo?.birdieStats;
    const totalUsers = clubLeaderboardInfo?.totalUsers;
    //console.log("BIRDIE STATS", birdieStats, totalUsers);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    function birdiePercentage(usersWhoBirdedHole, totalUsers) {
        return Number(((usersWhoBirdedHole / totalUsers) * 100).toFixed(1));
    }
    

    function sumTotalBirdies(startHole, endHole) {
        if (startHole < 1 || endHole > birdieStats.length) {
            throw new Error('Hole range is out of bounds');
        }
    
        const totalBirdies = birdieStats
            .slice(startHole - 1, endHole)
            .reduce((sum, { totalBirdiesOnHole }) => sum + totalBirdiesOnHole, 0);
    
        return totalBirdies;
    }
    

    const frontNineTotal = sumTotalBirdies(1, 9);
    const backNineTotal = sumTotalBirdies(10,18);
    const overallTotal = frontNineTotal + backNineTotal;



  return (
    <Box>
        <Typography variant="h5" style={{ fontSize: isMobile ? '12px' : '18px', fontWeight: '700'}}>Birdie Statistics per Hole</Typography>
      <TableContainer style={{ width: '95%', maxWidth:'750px',  margin: '20px auto'}} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '48px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Hole</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '94px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Players with Birdie</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '75px', border: '2px solid #002129', textAlign:'center'}} classes={{ root: 'table-header' }}>Player Birdie %</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ width: '120px', border: '2px solid #002129', textAlign:'center', background: '#00AA72'}} classes={{ root: 'table-header' }}>Total Birdies</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {birdieStats?.slice(0, 9).map(({ Hole, usersWhoBirdedHole, totalBirdiesOnHole}, index) => (
                                <TableRow key={Hole} className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{Hole}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{usersWhoBirdedHole}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{birdiePercentage(usersWhoBirdedHole, totalUsers)}%</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{totalBirdiesOnHole}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow className='even-row'>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129', textAlign: 'center' }}>OUT</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129', textAlign: 'center' }}>{frontNineTotal}</TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell className="birdie-stats-table-cells"  style={{ border: '2px solid #002129', textAlign: 'center' }} classes={{ root: 'table-header' }}>Hole</TableCell>
                                    <TableCell className="birdie-stats-table-cells"  style={{ border: '2px solid #002129', textAlign: 'center' }} classes={{ root: 'table-header' }}>Players with Birdie</TableCell>
                                    <TableCell className="birdie-stats-table-cells"  style={{ border: '2px solid #002129', textAlign: 'center' }} classes={{ root: 'table-header' }}>Player Birdie %</TableCell>
                                    <TableCell className="birdie-stats-table-cells"  style={{ border: '2px solid #002129', textAlign: 'center', background: '#00AA72'}} classes={{ root: 'table-header' }}>Total Birdies</TableCell>
                                </TableRow>
                            {birdieStats?.slice(9, 18).map(({ Hole, usersWhoBirdedHole, totalBirdiesOnHole }, index) => (
                                <TableRow key={Hole} className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{Hole}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{usersWhoBirdedHole}</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{birdiePercentage(usersWhoBirdedHole, totalUsers)}%</TableCell>
                                    <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', textAlign:'center' }}>{totalBirdiesOnHole}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow className='even-row'>
                            <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129', textAlign: 'center' }}>IN</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderRight: '2px solid #002129', borderTop: '3px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderTop: '3px solid #002129', textAlign: 'center' }}>{backNineTotal}</TableCell>
                            </TableRow>
                            <TableRow className='bottom-row'>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129', textAlign: 'center' }}>TOT</TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129' }}></TableCell>
                                <TableCell className="birdie-stats-table-cells" style={{ borderLeft: '2px solid #002129', borderRight: '2px solid #002129', borderBottom:'2px solid #002129', borderTop: '4px solid #002129', textAlign: 'center' }}>{overallTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
    </Box>
  );
}

export default HoleStats;