import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../context/BracketGamesContext';
import SweetSixteenBracketDesktop from '../../Brackets/SixteenTeamBracket/SweetSixteenBracketDesktop';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import BracketNavBar from '../../NavigationBars/BracketNavigationBar/BracketNavBar';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import ChampBracketDesktop from '../../Brackets/SixteenTeamBracket/ChampBracketDesktop';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const ViewEntrantSweetSixteenDesktop = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width:900px)");
    const navigateToTop = useNavigateToTop();

    const {
        tournamentSchedule,
        teams,
        selectedUserBracketInfo,
    } = useBracketGamesContext();

    const {
        myUserId,
        masterPoolId,
        pickIndex,
        masterPoolData,
        gameAddress,
        gameId,
        league,
        pickState,
        setPickState,
        tiebreaker,
        setTiebreaker,
        gameData,
        hasJoinDeadlinePassed,
        revealTime,
        firstGameTime,
    } = useUserAndGameContext();

    const [regions, setRegions] = useState(null);
    const [bracket, setBracket] = useState(null);

    const notReadyModal = masterPoolData?.notReadyModal;
    const showNotReadyModal = notReadyModal?.showModal;

    // Initialize roundDash from URL or default to 1
    const [roundDash, setRoundDash] = useState(() => {
        const navParam = searchParams.get('bracketNav');
        return navParam ? parseInt(navParam, 10) : 1;
    });

    const scrollToRound = (roundRef) => {
        if (!roundRef?.current) return;

        const gameElement = roundRef.current.querySelector('.regional-bracket-game');
        if (!gameElement) return;

        // Since we can see the y and pageYOffset values, let's set a fixed target
        const targetPosition = 275; // Based on the pageYOffset we saw

        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
        });
    };

    // Add refs and state for sticky navigation
    const observerRef = useRef(null);
    const containerRef = useRef(null);
    const [isStuck, setIsStuck] = useState(false);

    const round1Ref = useRef(null);
    const round2Ref = useRef(null);
    const round3Ref = useRef(null);
    const round4Ref = useRef(null);
    const round5Ref = useRef(null);
    const round6Ref = useRef(null);

    // Handle scroll for sticky navigation
    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (tournamentSchedule) {
            setRegions({
                topLeft: tournamentSchedule.LeftTopBracketConference,
                bottomLeft: tournamentSchedule.LeftBottomBracketConference,
                topRight: tournamentSchedule.RightTopBracketConference,
                bottomRight: tournamentSchedule.RightBottomBracketConference,
                finalFour: "Final Four",
            });
        }
    }, [tournamentSchedule]);

    useEffect(() => {
        if (regions?.topLeft) {
            setBracket(regions.topLeft);
        }
    }, [regions]);

    // Update URL when roundDash changes while preserving other params
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('bracketNav', roundDash.toString());
        setSearchParams(newParams);
    }, [roundDash, setSearchParams, searchParams]);

    const setRoundDashPosition = useMemo(() => ({
        1: () => setBracket(regions?.topLeft),
        2: () => setBracket(regions?.bottomLeft),
        3: () => setBracket(regions?.topRight),
        4: () => setBracket(regions?.bottomRight),
        5: () => setBracket(regions?.finalFour)
    }), [regions]);

    // Update roundDash and bracket based on URL when component mounts
    useEffect(() => {
        const navParam = searchParams.get('bracketNav');
        if (navParam) {
            const roundNumber = parseInt(navParam, 10);
            setRoundDash(roundNumber);
            setRoundDashPosition[roundNumber]?.();
        } else {
            setRoundDash(1);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('bracketNav', '1');
            setSearchParams(newParams);
            setRoundDashPosition[1]?.();
        }
    }, [searchParams, setSearchParams, regions, setRoundDashPosition]);

    const tournamentGames = tournamentSchedule?.Games;
    const regionInfo = masterPoolData?.gameParameters?.regionInfo;

    const handleGameClick = (teamId, bracket, round, tournamentDisplayOrder, seedNumber, globalGameId) => {
        let newPicks = [...pickState];
        
        const existingPickIndex = newPicks.findIndex(pick => 
            pick.bracket === bracket && 
            pick.round === round && 
            pick.displayOrder === tournamentDisplayOrder
        );
        
        if (existingPickIndex !== -1 && newPicks[existingPickIndex].teamId === teamId) {
            return;
        }
        
        const newPick = {
            teamId,
            bracket,
            round,
            displayOrder: tournamentDisplayOrder,
            teamSeed: seedNumber,
            globalGameId
        };
        
        let finalPicks;
        if (existingPickIndex !== -1) {
            finalPicks = newPicks.filter(pick => 
                pick.round <= round || pick.teamId !== newPicks[existingPickIndex].teamId
            );
            finalPicks[existingPickIndex] = newPick;
        } else {
            finalPicks = [...newPicks, newPick];
        }
        
        setPickState(finalPicks);
    };

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    useEffect(() => {
        if (!hasJoinDeadlinePassed) {
            navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home`);
        }
    }, [gameAddress, masterPoolId, gameId, navigateToTop, hasJoinDeadlinePassed]);

    return (
        <>
        <Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: '32px',
                        fontWeight: '700',
                        marginTop: '16px',
                    }}
                >
                    {selectedUserBracketInfo?.username}'s Bracket
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '16px',
                    marginTop: '16px',
                    marginBottom: '16px',
                    marginLeft: '74px',
                    marginRight: '74px',
                    maxWidth: '1300px',
                    margin: '0 auto',
                    paddingBottom: '48px',
                }}
            >
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Sweet 16</Typography>
                    <Typography sx={{ fontWeight: '700'}}>1 Point</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Elite 8</Typography>
                    <Typography sx={{ fontWeight: '700'}}>2 Points</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Final 4</Typography>
                    <Typography sx={{ fontWeight: '700'}}>4 Points</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Natty</Typography>
                    <Typography sx={{ fontWeight: '700'}}>8 Points</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Final 4</Typography>
                    <Typography sx={{ fontWeight: '700'}}>4 Points</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Elite 8</Typography>
                    <Typography sx={{ fontWeight: '700'}}>2 Points</Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '700'}}>Sweet 16</Typography>
                    <Typography sx={{ fontWeight: '700'}}>1 Point</Typography>
                </Box>
            </Box>
            {!bracket && tournamentGames?.length > 0 && !showNotReadyModal ? (
                <LoadingScreen />
            ) : (
                <>
                    <Box sx={{ position: 'relative' }}>
                        <SweetSixteenBracketDesktop
                            bracket={bracket}
                            topLeftBracket={regions?.topLeft}
                            bottomLeftBracket={regions?.bottomLeft}
                            topRightBracket={regions?.topRight}
                            bottomRightBracket={regions?.bottomRight}
                            games={tournamentGames}
                            teams={teams}
                            league={league}
                            handleGameClick={handleGameClick}
                            round1Ref={round1Ref}
                            round2Ref={round2Ref}
                            round3Ref={round3Ref}
                            round4Ref={round4Ref}
                            round5Ref={round5Ref}
                            setRegionCompleteModalOpen={null}
                            showDesktopNavBar={false}
                            secondBracket={false}
                            pickState={selectedUserBracketInfo?.picks}
                        />
                    </Box>
                </>
            )}
        </Box>
        <Box
            sx={{
                marginTop: '-1197.5px',
                marginLeft: '74px',
            }}
        >
            <ChampBracketDesktop
                bracket={regions?.finalFour}
                games={tournamentGames}
                teams={teams}
                league={league}
                handleGameClick={handleGameClick}
                topLeft={regions?.topLeft}
                bottomLeft={regions?.bottomLeft}
                topRight={regions?.topRight}
                bottomRight={regions?.bottomRight}
                round1Ref={round1Ref}
                round2Ref={round2Ref}
                round3Ref={round3Ref}
                pickState={selectedUserBracketInfo?.picks}
                overrideTiebreaker={true}
                userTiebreaker={selectedUserBracketInfo?.tiebreaker}
            />
        </Box>
        </>
    );
};

export default ViewEntrantSweetSixteenDesktop;