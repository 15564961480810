import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NFLPickEmForHomePage = () => {

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

  return (
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
      <Box>
      <Box
        className="our-pools-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 17px',
          boxSizing: 'border-box',
          gap: '16px',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          fontSize: '18.3px',
          color: '#000',
          fontFamily: 'Inter',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '23.5px 0 0',
          }}
        >
          <Box
            sx={{
              height: '43px',
              position: 'relative',
              letterSpacing: '-0.02em',
              lineHeight: '150%',
              display: 'inline-block',
              marginBottom: isMobile ? '0' : '24px',
            }}
          >
            <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '18px' : '40px',
                fontWeight: 800, // Applying font-weight directly
              }}
            >
              <span>
                NFL Pick ‘Em - Fav 5
              </span>
            </Typography>
            <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '12px' : '20px',
                textAlign: isMobile ? 'left' : 'center', 
                fontWeight: 800, 
              }}
            >
              <i>Pool Entry Open</i>
            </Typography>
          </Box>
        </Box>
       {isMobile && (
        <img
          loading="lazy"
          alt="NFL Fav 5 Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyNFLFav5.png"
          style={{
            height: '90px',
            width: '72px',
            position: 'relative',
            objectFit: 'cover',
          }}
        />
        )}
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '295px' : '420px',
          margin: '0 auto',
          marginTop: isMobile ? '0' : '32px',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 700,
            marginTop: '24px',
          }}
        >
              The most strategic {isMobile && <br />}
              NFL Pick ‘Em competition.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
              marginTop: '10px',
              marginBottom: '8px',
            }}
          >
            <span style={{ fontWeight: 700 }}>Favorite 5</span> - Analyze and choose your 5 most confident picks from the full weekly slate.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
            }}
          >
            <span style={{ fontWeight: 700 }}>Live Lines</span> - Live lines create opportunities to lock in or wait for the best odds.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigateToTop(`/nfl-pickem/${uriEncoded('1bae0db5-c4b6-4e9d-ade0-7547fdc716b1')}/HOME/HOME`)
            }            sx={{
              marginTop: '24px',
              width: '120px',
              whiteSpace: 'nowrap',
            }}
          >
            POOL HOME
          </Button>
      </Box>
      </Box>
      {!isMobile && (
        <Box>
        <img
          loading="lazy"
          alt="NFL Fav 5 Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyNFLFav5.png"
          style={{
            height: '240px',
            width: '300px',
            position: 'relative',
            objectFit: 'contain',
            marginLeft: '117px',
          }}
        />
        </Box>
      )}
      </Box>
  );
};

export default NFLPickEmForHomePage;