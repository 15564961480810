import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { useNFLSurvivorContext } from "../context/NFLSurvivorContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const NFLSurvivorRules = () => {
    const { gameData } = useNFLSurvivorContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const gameLogo = gameData?.clubLogo;

    const [rulesPage, setRulesPage] = useState('How To Play');

    return (
        <Box className="rules-container">
            <img 
                src={gameLogo} 
                alt="game logo" 
                className="rules-logo" 
                style={{ 
                    width: '128px', 
                    height: '160px', 
                    objectFit: 'cover',
                    marginTop: '32px',
                    marginBottom: '32px', 
                }} 
            />

            <Typography 
                className="rules-header"
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: isMobile ? '24px' : '32px',
                    fontWeight: 700,
                    marginBottom: '32px',
                }}
            >
                Pool Rules
            </Typography>

            <div style={{display:'flex', justifyContent:'space-evenly', minWidth: isMobile ? '325px' : '750px', margin:'15px auto 30px auto'}}>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'How To Play' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('How To Play')}
              >
                How To Play
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'Competition' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('Competition')}
              >
                Competition
              </span>
              <span
                style={{display: 'block', padding: '12px 8px',borderRadius:'6px', backgroundColor: rulesPage === 'Other Rules' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap', flex:1 }}
                onClick={() => setRulesPage('Other Rules')}
              >
                Other Rules
              </span>
            </div>
            
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '83%',
                    minWidth: '311px',
                    maxWidth: '800px',
                }}
            >
                {rulesPage === 'How To Play' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            How To Play
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                            Each week during the NFL regular season, pool members will select one (1) 
                            team to win straight up. Pool members can only use each team one time during the competition.
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', margin: '16px 0 0 0' }}>
                                Survival
                            </span>
                            <span style={{ display: 'block' }}>
                                If the selected team wins, the pool member moves on to the next week.
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', margin: '16px 0 0 0' }}>
                                Elimination
                            </span>
                            <span style={{ display: 'block' }}>
                                If the selected team loses or their game ends in a tie, the pool member is eliminated from the pool.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                The goal is to be the last participant remaining by correctly picking winning teams each week.
                            </span>
                        </Typography>
                        
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Making Picks
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                Pick selections for all 18 weeks are available from the 
                                start of the competition. This will allow pool members the 
                                option to strategically plan their season selections.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Each week’s pick can be made and changed at any time up 
                                until that week's deadline. The deadline each week will be Sunday at 1:00 PM ET.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                            If a game is scheduled before Sunday at 1:00pm  ET, the pick availability for that 
                            game will lock at the scheduled game time as indicated on the matchup card.
                            </span>
                            <span style={{ display: 'block', }}>
                                If a pool member fails to make a pick before the weekly deadline, they will be eliminated from the pool.
                            </span>
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Cancellations/Postponement
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            In the event a pick is locked and that game is cancelled or postponed 
                            to a future week, pool members will automatically survive into the next
                            week. However, the selected team for the cancelled or postponed game will be 
                            considered used and cannot be selected again in future weeks.
                        </Typography>
                    </>
                )}

                {rulesPage === 'Competition' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Competition 
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span style={{ display: 'block', fontWeight: '700', textAlign: 'center' }}>
                                NFL Survivor
                            </span>
                            <span style={{ display: 'block',  textAlign: 'center' }}>
                                1st Place - $250
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Pool Party’s NFL Survivor can end in the following scenarios:
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', margin: '16px 0 0 0' }}>
                                Sole Survivor
                            </span>
                            <span style={{ display: 'block' }}>
                                If a week ends with only one pool member surviving, that pool member will be crowned the champion.
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', margin: '16px 0 0 0' }}>
                                Multiple Survivors
                            </span>
                            <span style={{ display: 'block' }}>
                            If multiple pool members survive all 18 weeks, the tiebreaker will be used to determine the 
                            champion out of the surviving members (see Tiebreaker section).
                            </span>
                            <span style={{ display: 'block', fontWeight: '700', margin: '16px 0 0 0' }}>
                                Simultaneous Elimination
                            </span>
                            <span style={{ display: 'block' }}>
                                If all of the final surviving pool members are all eliminated in the same week, 
                                the tiebreaker will be used to determine the champion out of the final surviving members (see Tiebreaker section).
                            </span>
                            
                        </Typography>
                        
                            
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Tiebreaker
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                <span style={{ fontWeight: '700'}}>Win Margin: </span>The tiebreaker will be determined by the win margin of the teams picked by each pool member throughout the season.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                The win margin is calculated by adding the point differences of all the games won or lost by the teams a player picked.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                For example, if a player picks Team A in Week 1 and they win by 7 points, and then picks Team B in Week 2 and they win by 21 points, the player's win margin would be +28 points. 
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                If the player picks Team C in Week 3 and they lose by 10 points, the win margin would now be +18 points.
                            </span>
                            <span style={{ display: 'block', }}>
                                The pool member with the highest total win margin will win the tiebreaker.
                            </span>
                        </Typography>
                    </>
                )}

                {rulesPage === 'Other Rules' && (
                    <>
                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Pool Entry
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                                Participation in the pool is free. Participants must be 13 years of age or older.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Each registered account can create up to one (1) entry into Pool Party’s NFL Survivor Pool.
                            </span>
                            <span style={{ display: 'block', margin: '16px 0' }}>
                                Each pool member must join the pool and make their week 1 selection prior to the
                                 pick deadline on Sunday September 8th at 1:00pm ET.
                            </span>
                        </Typography>

                        <Typography
                            style={{
                                fontSize: isMobile ? '16px' : '24px',
                                marginBottom: '16px',
                                fontWeight: 700,
                                textDecoration: 'underline',
                                textAlign: 'center',
                            }}
                        >
                            Pool Duration
                        </Typography>
                        <Typography
                            style={{
                                fontSize: isMobile ? '12px' : '16px',
                                marginBottom: '32px',
                                fontWeight: 500,
                                textAlign: 'left',
                            }}
                        >
                            <span>
                            The pool will run from Week 1 (starting September 5th, 2024) through 
                            the end of Week 18 (ending January 5th, 2025). The pool may end earlier 
                            than Week 18 if all members are eliminated.
                            </span>
                        </Typography>
                    </>
                )}

                <Typography
                    style={{
                        fontSize: isMobile ? '16px' : '24px',
                        marginBottom: '16px',
                        fontWeight: 700,
                        textDecoration: 'underline',
                        textAlign: 'center',
                    }}
                >
                    Rule Clarifications & Final Interpretations
                </Typography>
                <Typography
                    style={{
                        fontSize: isMobile ? '12px' : '16px',
                        marginBottom: '32px',
                        fontWeight: 500,
                        textAlign: 'left',
                    }}
                >
                    In the event of unforeseen circumstances, rule ambiguities, 
                    or situations not explicitly covered within the above established 
                    guidelines, the Pool Party team reserves the right to make final interpretations and rulings.
                </Typography>

            </Box>
        </Box>
    );
}

export default NFLSurvivorRules;