import React from 'react';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DisplayPicksRow = ({ weeklySchedule, userPicks, gamesNotStartedCount, hiddenPicks, selectedWeek }) => {
    const { NFLTeams, 
        NFLStandings, 
        NFLStadiums, 
        NFLGameOddsByWeek,
        NFLScoresBySeason, 
        pickEmState, 
        setPickEmState,
        week, 
        userPicksFromDB,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
     } = useNFLPickEmContext();
    //console.log("Weekly Picks Bar NFL Schedule:", NFLScoresBySeason, NFLTeams);
    //console.log("Weekly Schedule Sorted in WeeklyPicksBar:", weeklySchedule)
    
    const filteredPicks = userPicks?.filter(pick => pick.teamId !== "No Pick");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    function weeksPicksInfo() {
        if (!filteredPicks) {
            return [];
        }
        return filteredPicks?.map(pick => {
            const { spread, teamId, gameId, isHomeSelected, result } = pick;
            //console.log("Weeks Picks Info:", spread, teamId, gameId);
            const teamLogo = NFLTeams?.find(team => team.TeamID === teamId)?.WikipediaLogoURL;
            const game = weeklySchedule?.find(game => game.GlobalGameID === gameId);
            const isPickHome = isHomeSelected;
            let borderBackgroundColor = "#FFFFFF";
            let borderColor = "#002129";
            if (result === "Win") {
                borderColor = winBoxColor;
                borderBackgroundColor = winBoxBackgroundColor;
            } else if (result === "Push") {
                borderColor = pushBoxColor;
                borderBackgroundColor = pushBoxBackgroundColor;
            } else if (result === "Loss") {
                borderColor = lossBoxColor;
                borderBackgroundColor = lossBoxBackgroundColor;
            }
            const opponentKey = isPickHome ? game?.AwayTeam : game?.HomeTeam;
            const leadingSymbol = isPickHome ? 'vs' : '@';
            //console.log("Team Logo:", teamLogo, "Is Pick Home:", isPickHome, "Opponent Key:", opponentKey, "Leading Symbol:", leadingSymbol);
            return { spread, teamLogo, opponentKey, leadingSymbol, borderColor, borderBackgroundColor};
        });
    }

    const weekPicks = weeksPicksInfo();
    //console.log("WEEK PICKS:", weekPicks);
    const maxPicks = 5;
    const currentPickCount = weekPicks?.length + hiddenPicks;
    const picksRemaining = maxPicks - currentPickCount;
    // console.log("Current Pick Count:", currentPickCount, "Picks Remaining:", picksRemaining, "Hidden Picks:", hiddenPicks);
    
    const noPickPicks = userPicks?.filter(pick => pick.teamId === "No Pick" && pick.week === Number(selectedWeek));
    //console.log("No Pick Picks:", noPickPicks, "Week:", selectedWeek);
    const missesNeeded = noPickPicks?.length;
    const placeholdersNeeded = maxPicks - currentPickCount - missesNeeded;

    return (
        <div className="weekly-picks-bar" style={{ 
            maxWidth: '1116px', 
            height: '76px', 
            margin: '0 auto',
            display: 'flex', 
            alignItems: 'center', // Center vertically
            justifyContent: 'space-between', // Distribute space evenly
        }}>
            {weekPicks.map((pick, index) => (
                <div key={index} className="pick-info" style={{ 
                    textAlign: 'center', 
                    marginRight: '8px', 
                    marginLeft: '8px',
                    flex: '1', // Ensure items are flexible and take up equal space
                }}>
                    <div style={{
                        display: 'flex',
                        width: '24px',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: isMobile ? '10px' : '14px', 
                                fontWeight: '500' 
                            }}
                        >
                            {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                        </Typography>
                    </div>
                    <img 
                        src={pick.teamLogo} 
                        alt="Team Logo" 
                        style={{ 
                            width: isMobile ? '32px' : '32px', 
                            height: isMobile ? '32px' : '32px', 
                            margin: '0 auto', 
                            marginTop: isMobile ? '5px' : '10px', 
                            marginBottom: isMobile ? '5px' : '10px', 
                            objectFit: 'contain', 
                            border: isMobile ? `1px solid ${pick.borderColor}` : `2px solid ${pick.borderColor}`, 
                            backgroundColor: pick.borderBackgroundColor 
                        }} 
                    />
                    <div style={{
                        display: 'flex',
                        width: 'auto',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: isMobile ? '10px' : '14px', 
                                fontWeight: '500',
                                whiteSpace: 'nowrap', 
                            }}
                        >
                            {pick.leadingSymbol} {pick.opponentKey}
                        </Typography>
                    </div>
                </div>
            ))}
            {Array.from({ length: hiddenPicks }).map((_, index) => (
                <div key={`placeholder-${index}`} className="pick-info" style={{ 
                    textAlign: 'center', 
                    marginRight: '8px', 
                    marginLeft: '8px',
                    flex: '1', // Ensure items are flexible and take up equal space
                }}>
                    <div style={{
                        display: 'flex',
                        width: '24px',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500' 
                            }}
                        >
                            &nbsp;
                        </Typography>
                    </div>
                    <div
                        style={{ 
                            width: isMobile ? '32px' : '32px', 
                            height: isMobile ? '32px' : '32px', 
                            margin: '0 auto', 
                            marginTop: isMobile ? '5px' : '10px', 
                            marginBottom: isMobile ? '5px' : '10px', 
                            border: isMobile ? `1px solid #000000` : `2px solid #000000`, 
                            backgroundColor: '#FFFFFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} 
                    >
                        {/* <CheckIcon 
                            style={{ 
                                width: isMobile ? '20px' : '20px', 
                                height: isMobile ? '20px' : '20px', 
                                fill: '#00AA72',
                            }} 
                        /> */}
                    </div>
                    <div style={{
                        display: 'flex',
                        width: 'auto',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: isMobile ? '10px' : '14px', 
                                fontWeight: '500' 
                            }}
                        >
                            Pick {currentPickCount + index + 1 - hiddenPicks}
                        </Typography>
                    </div>
                </div>
            ))}
            {Array.from({ length: placeholdersNeeded }).map((_, index) => (
                <div key={`placeholder-${index}`} className="pick-info" style={{ 
                    textAlign: 'center', 
                    marginRight: '8px', 
                    marginLeft: '8px',
                    flex: '1', // Ensure items are flexible and take up equal space
                }}>
                    <div style={{
                        display: 'flex',
                        width: '24px',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500' 
                            }}
                        >
                            &nbsp;
                        </Typography>
                    </div>
                    <div
                        style={{ 
                            width: isMobile ? '32px' : '32px', 
                            height: isMobile ? '32px' : '32px', 
                            margin: '0 auto', 
                            marginTop: isMobile ? '5px' : '10px', 
                            marginBottom: isMobile ? '5px' : '10px', 
                            objectFit: 'contain', 
                            border: isMobile ? `1px solid #000000` : `2px solid #000000`, 
                            backgroundColor: '#FFFFFF' 
                        }} 
                    />
                    <div style={{
                        display: 'flex',
                        width: 'auto',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: isMobile ? '10px' : '14px', 
                                fontWeight: '500' 
                            }}
                        >
                            Pick {currentPickCount + index + 1}
                        </Typography>
                    </div>
                </div>
            ))}
            {Array.from({ length: missesNeeded }).map((_, index) => (
                <div key={`placeholder-${index}`} className="pick-info" style={{ 
                    textAlign: 'center', 
                    marginRight: '8px', 
                    marginLeft: '8px',
                    flex: '1', // Ensure items are flexible and take up equal space
                }}>
                    <div style={{
                        display: 'flex',
                        width: '24px',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500' 
                            }}
                        >
                            &nbsp;
                        </Typography>
                    </div>
                    <div
                        style={{ 
                            width: isMobile ? '32px' : '32px', 
                            height: isMobile ? '32px' : '32px', 
                            margin: '0 auto', 
                            marginTop: isMobile ? '5px' : '10px', 
                            marginBottom: isMobile ? '5px' : '10px', 
                            border: isMobile ? `1px solid #CC293C` : `2px solid #CC293C`, 
                            backgroundColor: '#FFFFFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} 
                    >
                        <CloseIcon 
                            style={{ 
                                width: isMobile ? '20px' : '20px', 
                                height: isMobile ? '20px' : '20px', 
                                fill: '#CC293C',
                            }} 
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        width: 'auto',
                        height: '10px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flexShrink: 0,
                        margin: '0 auto',
                    }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: isMobile ? '10px' : '14px', 
                                fontWeight: '500', 
                                color: '#CC293C'
                            }}
                        >
                            Miss
                        </Typography>
                    </div>
                </div>
            ))}
        </div>
        
    );
}

export default DisplayPicksRow;
