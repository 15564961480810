import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const QuickPickSubmitPicksModal = ({ open, handleClose, checkerData, picksThisInterval, usingTiebreaker, interval }) => { 

    const addedPicks = checkerData?.addedPicks;
    const removedPicks = checkerData?.removedPicks;
    const updatedPicks = checkerData?.updatedPicks;
    //console.log("Added Picks", addedPicks, "Removed Picks", removedPicks, "Updated Picks", updatedPicks, "Checker Data", checkerData, interval);

    const picksMadeThisInterval = updatedPicks?.filter(pick => Number(pick.interval) === Number(interval) && !pick.tiebreaker).length;
    const tiebreakerMadeThisInterval = updatedPicks?.filter(pick => Number(pick.interval) === Number(interval) && pick.tiebreaker).length;
    //console.log("Added Picks", addedPicks, "Removed PIcks", removedPicks)

    const picksThisIntervalWithTiebreaker = picksThisInterval - (usingTiebreaker ? 1 : 0);
    

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{ height: 'auto', width: '308px' }}>
                {addedPicks > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800' }}>
                            {addedPicks} Pick{addedPicks > 1 ? 's' : ''} Submitted
                        </Typography>

                        <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} />    
                    </>
                )}
                {addedPicks === 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800' }}>
                            {removedPicks} Pick{removedPicks > 1 ? 's' : ''} Removed
                        </Typography>
                        
                        <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} />    
                    </>
                )}
                
                <Typography variant="h1" style={{ fontSize: '16px' }}>
                    <span style={{ fontWeight: '700', color: (picksMadeThisInterval) === picksThisIntervalWithTiebreaker ? '#00AA72' : '#CC293C' }}>
                        {picksMadeThisInterval}/{picksThisIntervalWithTiebreaker}&nbsp;
                    </span> 
                    Picks Submitted
                </Typography>
                {usingTiebreaker && (
                    <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography  variant="h1" style={{ fontSize: '16px' }}>
                            Tiebreaker:
                        </Typography>
                        {tiebreakerMadeThisInterval ? (
                            <CheckIcon className="quick-pick-submit-bar-icon" style={{ color: '#00AA72' }} />
                        ) : (
                            <CloseIcon className="quick-pick-submit-bar-icon" style={{ color: '#CC293C' }} />
                        )}
                    </Box>
                    </>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button sx={{ width: '115px'}} variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </div>
            </div>
        </Modal>
    )
}

export default QuickPickSubmitPicksModal;