import { apiClient } from "../../apiClient";
import { useMutation } from "@tanstack/react-query";

const validateEmailRequest = (opts) => {
  const { email } = opts;
  return apiClient.post("/user/validate-email-address", {
    email,
  }).then((res) => res.data?.data);
};

export const useValidateEmailIsUnique = () => {
  return useMutation({
    mutationFn: validateEmailRequest,
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      return data;
    },
  });
};
