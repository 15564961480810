import React, { useState } from 'react'; 
import { Box, Typography } from '@mui/material'; 
import './GameRulesPage.scss'; 
import { useTheme } from '@mui/material/styles'; 
import { useMediaQuery } from '@mui/material'; 
import HomePageSponsor from '../GameHomePage/GameHomePageComponents/HomePageSponsor';
import GameRulesPagePrizes from './GameRulesPagePrizes';
import RulesPageSuperPickemPrizes from './RulesPageSuperPickemPrizes';
import PoolPrizes from '../GameHomePage/GameHomePageComponents/PoolPrizesNew';

const GameRulesPage = ({ gameData }) => {

    //console.log("Game Data", gameData);

    const sponsorLogo = gameData?.sponsorLogo;
    const sponsorName = gameData?.sponsorName;
    const sponsorWebsite = gameData?.sponsorWebsite;
    const gameLogo = gameData?.clubLogo;
    const rules = gameData?.rules;
    const prizes = gameData?.prizes;
    const gameType = gameData?.gameType;
    const superPickemPrizes = gameData?.homePage?.prizes;

    const [rulesPage, setRulesPage] = useState(rules[0]?.buttonName); // Default to the first rule button

    return (
        <>
            {gameLogo && (
                <img 
                    src={gameLogo} 
                    alt="game logo" 
                    className="game-rules-page-logo"
                />
            )}

            <Box className="game-rules-page-container">
                

                {sponsorName && (
                    <HomePageSponsor
                        sponsorName={sponsorName}
                        sponsorLogo={sponsorLogo}
                        sponsorWebsite={sponsorWebsite}
                    />
                )}

                {gameType === 'SuperPickem' && superPickemPrizes && (
                    <RulesPageSuperPickemPrizes
                        prizes={superPickemPrizes}
                    />
                )}

                {prizes && prizes?.oldPrizes && gameType !== 'SuperPickem' && (
                    <GameRulesPagePrizes
                        prizes={prizes}
                    />
                )}

                {prizes && !prizes?.oldPrizes && gameType !== 'SuperPickem' && (
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <PoolPrizes
                            prizes={prizes}
                            backgroundColor={prizes?.backgroundColor}
                            textColor={prizes?.textColor}
                        />
                    </Box>
                )}

                <Typography 
                    className="game-rules-page-header"
                    variant="h4" // Use variant to define font size
                    sx={{ marginTop: prizes ? '32px' : '0' }} // Use sx to define margin
                >
                    Pool Rules
                </Typography>

                {/* Dynamically generate navigation tabs */}
                <Box className="game-rules-page-navigation">
                    {rules?.map(rule => (
                        <Typography
                            key={rule?.buttonName}
                            className={`game-rules-page-tab ${rulesPage === rule?.buttonName ? 'active' : ''}`}
                            onClick={() => setRulesPage(rule?.buttonName)}
                        >
                            {rule?.buttonName}
                        </Typography>
                    ))}
                </Box>

                {/* Render the corresponding rule content */}
                <Box className="game-rules-page-content-container">
                    {rules?.filter(rule => rule?.buttonName === rulesPage)?.map(rule => (
                        <Box key={rule?.buttonName}>
                            {rule?.text.map((textGroup, index) => (
                                <Box key={index}>
                                    <Typography variant="h5" className="game-rules-page-content-header">
                                        {textGroup.header}
                                    </Typography>
                                    {textGroup.body?.map((item, idx) => (
                                        <Typography 
                                            key={idx}
                                            variant="body1"
                                            className={`game-rules-page-content-text ${item?.bold ? 'bold' : ''} ${item?.centered ? 'centered' : ''}`}
                                        >
                                            {item?.text}
                                        </Typography>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>

            </Box>
        </>
    );
}

export default GameRulesPage;
