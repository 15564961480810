import React from "react";
import Box from '@mui/material/Box';
import './BirdieHeaderNav.scss';
import { set } from "lodash";

const BirdieNav = (props) => {
    //console.log('BracketNav props:', props);
    const { setClickState, clickState, setSelectedUser, myUserName, setSelectedUserId, myUserId, setScorecardState, userScorecard } = props;
    return (
        <Box className={"bracket-nav"} style={{ margin: '0 auto'}}>
            <Box className={"bracket-nav-container"}>
                <Box
                    className={`bracket-nav-item ${clickState === 'SCORE_CARD' ? 'active' : ''}`}
                    onClick={() => { setClickState('SCORE_CARD'); setSelectedUser(myUserName); setSelectedUserId(myUserId); setScorecardState(userScorecard); }}
                    style={{ cursor: 'pointer', color: clickState === 'SCORE_CARD' ? '#FFC60A' : 'white' }}
                >
                    Scorecard
                </Box>
                <Box
                    className={`bracket-nav-item ${clickState === 'LEADERBOARD' ? 'active' : ''}`}
                    onClick={() => { setClickState('LEADERBOARD'); setSelectedUser(myUserName); setSelectedUserId(myUserId); setScorecardState(userScorecard); }}
                    style={{ cursor: 'pointer', color: clickState === 'LEADERBOARD' ? '#FFC60A' : 'white' }}
                >
                    Leaderboard
                </Box>
                <Box
                    className={`bracket-nav-item ${clickState === 'MY_PARTY' ? 'active' : ''}`}
                    onClick={() => { setClickState('MY_PARTY'); setSelectedUser(myUserName); setSelectedUserId(myUserId); setScorecardState(userScorecard); }}
                    style={{ cursor: 'pointer', color: clickState === 'MY_PARTY' ? '#FFC60A' : 'white' }}
                >
                    My Party
                </Box>
                <Box
                    className={`bracket-nav-item ${clickState === 'BIRDIE_STATS' ? 'active' : ''}`}
                    onClick={() => { setClickState('BIRDIE_STATS'); setSelectedUser(myUserName); setSelectedUserId(myUserId); setScorecardState(userScorecard); }}
                    style={{ cursor: 'pointer', color: clickState === 'BIRDIE_STATS' ? '#FFC60A' : 'white' }}
                >
                    Birdie Stats
                </Box>
                <Box
                    className={`bracket-nav-item ${clickState === 'RULES' ? 'active' : ''}`}
                    onClick={() => { setClickState('RULES'); setSelectedUser(myUserName); setSelectedUserId(myUserId); setScorecardState(userScorecard); }}
                    style={{ cursor: 'pointer', color: clickState === 'RULES' ? '#FFC60A' : 'white' }}
                >
                    Rules
                </Box>
            </Box>
        </Box>
    );
}

export default BirdieNav;
