import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "../CardPieces/CardPieces.scss";

const PickStatsChoicesCard = ({ hasGameStarted, prop, selectedGameId, correctOption }) => {
    //console.log("Props Inside PickStatsChoicesCard: ", prop);
    
    const counts = prop?.counts;
    const options = prop?.options || []; // Can handle 2 to 10 options dynamically
    //console.log("Counts: ", counts, "Options: ", options);
    const totalPicks = Object.values(counts).reduce((acc, count) => acc + count, 0); // Total counts
    //console.log("Total Picks: ", totalPicks);
    const line = prop?.line;
    //const correctOption = null;

    const getPercentage = (option) => {
        const optionCount = counts[option] || 0; // Get the count for the specified option
    
        // Calculate percentage
        const percentage = (optionCount / totalPicks) * 100;
        
        return totalPicks > 0 ? percentage.toFixed(1) : "0.0"; // Return formatted percentage, or "0.0" if totalPicks is 0
    };
    

    
    return (
        <Box className="quick-pick-card-multiple-choice-container">
        {options?.map((option, index) => {
            const isCorrect = correctOption === option;
            const optionLength = option?.length;
            //console.log("Option Length: ", optionLength, option);
            const percentage = getPercentage(option); // Calculate the percentage for each option

            return (
                <Box
                    key={index}
                    className="quick-pick-card-multiple-choice-button"
                    sx={{
                        border: isCorrect ? `2px solid #00AA72` : '2px solid #00212980',
                        backgroundColor: correctOption === 'Void' ? '#000000' : isCorrect ? '#CCFFEF' : '#fff',
                        marginBottom: '8px', // Optional: add some spacing between buttons
                        cursor: 'auto', // Optional: disable pointer cursor
                    }}
                >
                    <Typography
                        className="quick-pick-card-multiple-choice-answers-text"
                        sx={{ 
                            color: correctOption === 'Void' ? '#fff' : isCorrect ? '#002129' : '#00212980',
                            fontSize: optionLength > 14 ? '16px' : '20px',
                        }}
                    >
                        {option} {line ? ` ${line}` : ''} {correctOption !== 'Void' ? `- ${percentage}%` : ''}
                    </Typography>
                </Box>
            );
        })}
    </Box>
    );
};

export default PickStatsChoicesCard;
