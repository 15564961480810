import React, { useState, useRef } from "react";
import { Typography, Box, Modal, Button, TextField } from "@mui/material";
import { useJoinPublicPool } from "../../PublicPools/hooks/useJoinPublicPools";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import QuickPickJoinedModal from "./QuickPickJoinedModal";
import TextAcceptanceModal from "./TextAcceptanceModal";


const JoinGameModal = ({ 
    myUserId, 
    logo, 
    gameName, 
    gameType, 
    gameId, 
    gameAddress, 
    joinOpen, 
    setJoinOpen, 
    setSignUpState, 
    sponsorSecondaryLogo, 
    sponsorName, 
    sponsorLogo, 
    mainGameId, 
    propIdsToUse, 
    joinModalText, 
    referralId, 
    newSignUp,
    hideSponsorInPoolHall,
    isTeamGame,
    hostGameData, 
    sponsorCircleLogo,
    sponsorAbbreviation,
    tShirtReward,
    tShirt,
    countdownBackground,
    textOptInMessage,
    isMainGame = false,
    mainGameInfo,
    matchedGames = [],
    pinRequired,
}) => {
    console.log("Matched Games in Join Modal", matchedGames, "Pin Required", pinRequired);
    const navigateToTop = useNavigateToTop();

    const { mutate: joinPool } = useJoinPublicPool();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const [status, setStatus] = useState('confirmJoin');
    const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [passcode, setPasscode] = useState(new Array(4).fill(''));
    const [pinError, setPinError] = useState(false);
    const inputRefs = useRef([]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handlePasscodeChange = (element, index) => {
        const value = element.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const newPasscode = [...passcode];
            newPasscode[index] = value;
            setPasscode(newPasscode);
            if (value !== '' && index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && passcode[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    let hostGameId = null;
    if (isTeamGame) {
        hostGameId = hostGameData?.gameID;
    }
    //console.log("Host Game ID: ", hostGameId);

    let gameIdForExtraJoin = null;
    if (!isMainGame) {
        const mainGamesId = mainGameInfo && mainGameInfo[0]?.id;
        console.log("Main Game ID: ", mainGamesId);
        const isMatch = matchedGames?.some(game => game.gameId === mainGamesId);
        console.log("Is Match: ", isMatch);
        if (!isMatch) {
            gameIdForExtraJoin = mainGamesId;
        }
    }
    

    const joinGame = async (gameID, gameType) => {
        if (pinRequired && passcode.join('') !== pinRequired.toString()) {
            setPinError(true);
            return;
        }
        
        setPinError(false);
        setHasSubmitted(true);

        let data = {
            gameID: gameID,
            userID: myUserId,
            gameType: gameType,
             ...(referralId && { referralId }),
            ...(newSignUp && { newSignUp }),
            ...(isTeamGame && { hostGameID: hostGameId }),
            ...(pinRequired && { pin: passcode.join('') })
        };

        if (matchedGames?.length > 0) {
            const matchedIds = matchedGames.map(game => game?.gameId);
            data.matchedGames = matchedIds;
        }

        if (gameIdForExtraJoin !== null) {
            data.extraGameToJoin = gameIdForExtraJoin;
        }

        // if (referralId) {
        //     data.referralId = referralId;
        // }
        // if (newSignUp) {
        //     data.newSignUp = newSignUp;
        // }
        // if (isTeamGame) {
        //     data.hostGameID = hostGameId;
        // }
        console.log("Join Pool Data: ", data);
    
        joinPool(data, {
            onSuccess: () => {
                setJoinOpen(true);
                setStatus("success");
                // Optionally, navigate to the game page or handle success logic
            },
            onError: (error) => {
                // Dispatch logout action for any error
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
        
                // No need to handle status or error message since the user is logged out
                console.error("Join pool failed:", error);
            }
        });
        
    };
    
    
    
    const handleJoinPool = (gameID, gameType) => {
        if(!myUserId) {
            //Base URL for navigation
            let url = `/login/${gameAddress}/${uriEncoded(gameID)}/HOME/HOME`;
            
            //If there is a referral ID, append it to the URL
            if (referralId) {
                url += `?referralId=${referralId}`;
            }
            
            navigateToTop(url);
        } else {
            joinGame(gameID, gameType);
            //console.log("JOIN POOL HERE")
        }
    };

    return (
        <div>
            <Modal
                open={joinOpen && status === "confirmJoin"}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        minHeight: isMobile ? '330px' : '350px',
                        height: 'auto',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    {sponsorSecondaryLogo && !hideSponsorInPoolHall && !pinRequired && (
                        <img
                            src={sponsorSecondaryLogo}
                            alt={sponsorName}
                            style={{
                                width: '120px',
                                marginBottom: '16px'
                            }}
                        />
                    )}
                    <img 
                        src={logo}
                        alt={gameName}
                        style={{
                            display: 'block',
                            // height: '140px',
                            marginBottom: '16px'
                        }}
                    />
                   <Typography
                        variant="h6"
                        sx={{ 
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 400,
                            marginBottom: '16px'
                        }}
                    >
                        Would you like to join the {isTeamGame ? `Team ${sponsorName} in ` : ''} 
                        {sponsorName ? '' : "Pool Party's"} 
                        {!isTeamGame && <br />} 
                        {gameName}?
                    </Typography>

                    {joinModalText?.joinModalSecondLine && (
                        <Typography
                            sx={{ 
                                color: '#002129',
                                textAlign: 'center',
                                fontSize: '16px',
                                fontWeight: 400,
                                marginBottom: pinRequired ? '0px' : '16px'
                            }}
                        >
                            {joinModalText.joinModalSecondLine}
                        </Typography>
                    )}

                    {pinRequired && (
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography sx={{ 
                                color: '#002129',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: 400,
                                mb: 2
                            }}>
                                Enter PIN to join
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                {passcode.map((num, index) => (
                                    <TextField
                                        key={index}
                                        value={num}
                                        onChange={(e) => handlePasscodeChange(e.target, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        inputProps={{ 
                                            maxLength: 1, 
                                            style: { textAlign: 'center' } 
                                        }}
                                        sx={{ width: '50px' }}
                                        inputRef={(ref) => (inputRefs.current[index] = ref)}
                                    />
                                ))}
                            </Box>
                            {pinError && (
                                <Typography sx={{ 
                                    color: 'red',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    mt: 1
                                }}>
                                    Incorrect PIN. Please try again.
                                </Typography>
                            )}
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                mb: 1
                            }}
                            onClick={() => {
                                setJoinOpen(false);
                                if (pinRequired) setPasscode(new Array(4).fill(''));
                            }}
                            disabled={hasSubmitted}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                width: '115px',
                                height: '38px',
                                whiteSpace: 'nowrap',
                                mb: 1 // MarginBottom of 8px
                            }}
                            onClick={() => handleJoinPool(gameId, gameType)}
                            disabled={hasSubmitted}
                        >
                            JOIN POOL
                        </Button>
                    </Box>
                </Box>
            </Modal>
            
            {(
                <TextAcceptanceModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    status={status}
                    logo={logo}
                    myUserId={myUserId}
                    gameId={gameId}
                    isTeamGame={isTeamGame}
                    hostGameData={hostGameData}
                    sponsorCircleLogo={sponsorCircleLogo}
                    sponsorAbbreviation={sponsorAbbreviation}
                    tShirtReward={tShirtReward}
                    tShirt={tShirt}
                    countdownBackground={countdownBackground}
                    textOptInMessage={textOptInMessage}
                />
            )}

                <Modal
                    open={joinOpen && status === "fail"}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box 
                        sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '308px',
                        height: '210px',
                        bgcolor: '#FFF', 
                        borderRadius: '8px', 
                        boxShadow: 24, 
                        p: 2, // Padding of 16px
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, // Gap of 20px
                        overflow: 'scroll'
                        }}
                    >
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            marginBottom: '24px'
                        }}
                        >
                        Oops!
                        </Typography>
                        <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '12px',
                            fontWeight: 700,
                            marginBottom: '16px'
                        }}
                        >
                        {errorMessage}
                        </Typography>
                        <Button
                        variant="contained"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            width: '152px',
                            height: '36px',
                            marginBottom: '8px'
                        }}
                        onClick={() => window.location.reload()} // Reload the page
                        >
                        Close
                        </Button>
                    </Box>
                </Modal>

        </div>
    );
}

export default JoinGameModal;