import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Box, TablePagination, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../context/BracketGamesContext';
import { getTeamLogo } from '../../../utils/SportsData';
import TiebreakerInfo from '../../GamePageComponents/TiebreakerInfo/TiebreakerInfo';
import { hexToRgba } from '../../Brackets/bracketUtils/HexToRgba';
import CloseIcon from '@mui/icons-material/Close';
import './BracketLeaderboard.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterSwitch from '../../GamePageComponents/FilterSwitch/FilterSwitch';

const BracketLeaderboard = () => {
    const {
        masterPoolData,
        gameData,
        league,
        hasJoinDeadlinePassed,
        myUserId,
        leaderboardData,
        myLeaderboardInfo,
        allUsersEntries,
        usersIndexesInThisPool,
        masterPoolId,
        gameId,
    } = useUserAndGameContext();

    const {
        teams,
        hasFinalStarted,
        maxPossiblePoints,
    } = useBracketGamesContext();

    const navigate = useNavigate();
    const location = useLocation();

    const [hostFilter, setHostFilter] = useState(false);

    const handleUsernameClick = (username) => {
        // Set the username in the context        
        // Navigate to the bracket page with the username as a URL parameter
        navigate(`/bracket?mId=${encodeURIComponent(masterPoolId)}&gId=${encodeURIComponent(gameId)}&page=bracket&user=${encodeURIComponent(username)}`);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const leaderboardImage = gameData?.leaderboardImage || null;
    const usersWhoCantWin = gameData?.gameAdministration?.cantWin || []; 

    const isGameOver = masterPoolData?.gameParameters?.isGameOver || false;

    const hostInfo = gameData?.gameParameters?.hostInfo;
    //console.log("Host Info in BracketLeaderboard: ", hostInfo);

    // Get entries for this pool
    const entriesInThisPool = usersIndexesInThisPool
        ?.filter(index => index >= 0 && index < allUsersEntries?.length)
        ?.map(index => allUsersEntries[index]);
    //console.log("ENTRIES IN THIS POOL LEADERBOARD", entriesInThisPool);

    const findUserEntry = (entryName) => {
        return entriesInThisPool?.find(entry => entry.name === entryName)?.pool?.picks || null;
    };

    const rounds = masterPoolData?.gameParameters?.rounds;
    const lastRound = rounds ? rounds[rounds.length - 1] : null;

    function findChampId(picks) {
        if (!picks || !lastRound) return null;
        
        const champPick = picks?.find(pick => pick.round === lastRound);
        return champPick?.teamId;
    }

    function findChampResult(picks) {
        if (!picks || !lastRound) return null;
        
        const champPick = picks?.find(pick => pick.round === lastRound);
        return champPick?.result;
    }

    const [sortConfig, setSortConfig] = useState({
        key: 'bracketRank', // Default sort by overallRank
        direction: 'asc'
    });

    // Pagination State
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Use Effect to reset page to 0 if host filter changes
    useEffect(() => {
        //console.log("Resetting page to 0");
        setPage(0);
    }, [leaderboardData, rowsPerPage]);

    //  // Filter data based on hostFilter
    const filteredData = useMemo(() => {
        if (!leaderboardData) return [];
        
        if (hostFilter && hostInfo?.hostIds) {
            // Include only users that are hosts or the current user
            return leaderboardData.filter(user => 
                hostInfo.hostIds.includes(user.userId) || user.userId === myUserId
            );
        }
        
        return leaderboardData;
    }, [leaderboardData, hostFilter, hostInfo, myUserId]);

    const sortedData = useMemo(() => {
        if (!filteredData) return [];
    
        return [...filteredData].sort((a, b) => {
            const rankColumns = ['rank', 'bracketRank', 'dailyPicksRank'];
            const pointsColumns = ['totalPoints', 'bracketPoints', 'dailyPicksPoints', 'maxPossiblePoints', 'spreadWins', 'overUnderWins'];
    
            const key = sortConfig.key; // Current column being sorted
            const direction = sortConfig.direction; // Sort direction: 'asc' or 'desc'
    
            // Rank Columns Logic
            if (rankColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return direction === 'asc' ? 1 : -1; // Missing ranks go to bottom in ascending, top in descending
                if (bIsMissing) return direction === 'asc' ? -1 : 1; // Reverse for descending
                
                // Important fix: consistent sort direction for rank
                return direction === 'asc' 
                    ? aValue - bValue  // Lower ranks first for ascending
                    : bValue - aValue; // Higher ranks first for descending
            }
    
            // Points Columns Logic
            if (pointsColumns.includes(key)) {
                const aValue = a[key];
                const bValue = b[key];
                const aIsMissing = aValue == null; // Check for null or undefined
                const bIsMissing = bValue == null;
    
                if (aIsMissing && bIsMissing) return 0; // Both missing are equal
                if (aIsMissing) return 1; // Missing points always go to bottom regardless of direction
                if (bIsMissing) return -1; // Present values always go to top
                
                // Important fix: ensure maxBracketPoints sorts properly
                return direction === 'asc' 
                    ? aValue - bValue  // Lower values first for ascending
                    : bValue - aValue; // Higher values first for descending
            }
    
            // Fallback for String Columns
            const aValue = a[key];
            const bValue = b[key];
            const aIsMissing = aValue == null || aValue === ''; // Missing or empty
            const bIsMissing = bValue == null || bValue === '';
    
            if (aIsMissing && bIsMissing) return 0; // Both missing are equal
            if (aIsMissing) return 1; // Missing always go to bottom
            if (bIsMissing) return -1; // Present values always go to top
            
            return direction === 'asc'
                ? (aValue || '').localeCompare(bValue || '')
                : (bValue || '').localeCompare(aValue || '');
        });
    }, [filteredData, sortConfig]);

    const handleSort = (key) => {
        setPage(0); // Reset page to 0 when sorting
        setSortConfig(prevConfig => {
            // If sorting by the same column, toggle direction
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
                };
            }
            
            // Default direction for each type of column when first clicked
            if (key === 'bracketRank' || key === 'rank') {
                // For rank columns, default to ascending (lowest rank first)
                return {
                    key,
                    direction: 'asc'
                };
            } else {
                // For other columns like points, default to descending (highest value first)
                return {
                    key,
                    direction: 'desc'
                };
            }
        });
    };

    const findChampColor = (result) => {
        if (result === 'win'  || result === 'Win') {
            return '#00AA72';
        } else if (result === 'loss' || result === 'Loss') {
            return '#CC293C';
        } else {
            return '#002129';
        }
    }

    const findChampBackgroundColor = (result) => {
        if (result === 'win' || result === 'Win') {
            return hexToRgba('#00AA72', 0.2);
        } else if (result === 'loss' || result === 'Loss') {
            return hexToRgba('#CC293C', 0.2);
        } else {
            return 'transparent';
        }
    }

    const rankHeaderColor = sortConfig.key === 'rank' ? '#FFC60A' : '#FFFFFF';
    const bracketPointsHeaderColor = sortConfig.key === 'bracketPoints' ? '#FFC60A' : '#FFFFFF';
    const maxPointsHeaderColor = sortConfig.key === 'maxPossiblePoints' ? '#FFC60A' : '#FFFFFF';
    const playerHeaderColor = sortConfig.key === 'username' ? '#FFC60A' : '#FFFFFF';


    // Slice the sorted data for pagination
    const paginatedData = sortedData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    )




    return (
        <>
            <TiebreakerInfo />

            {hasJoinDeadlinePassed && (
                <Box>
                    <Typography
                        sx={{
                            color: '#002129',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700',
                            textAlign: 'center',
                            marginBottom: '5px',
                        }}
                    >
                        To view a user's bracket, click on their username.
                    </Typography>
                </Box>
            )}

            {hostInfo && (
                <FilterSwitch
                    hostName={hostInfo?.hostName}
                    hostFilter={hostFilter}
                    setHostFilter={setHostFilter}
                />
            )}

            <Grid item xs={12} sx={{overflow: 'scroll', maxWidth: '1100px', margin: '0 auto'}}>
                    <Box
                        sx={{
                            maxWidth: '900px',
                            margin: '0 auto',
                        }}
                    >
                        <TablePagination
                            rowsPerPageOptions={[50, 100]}
                            component="div"
                            count={sortedData?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={Math.min(page, Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1))} // Derive a valid page value
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Rows"
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiTablePagination-toolbar': {
                                    color: '#002129',
                                },
                                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                                    color: '#002129',
                                }
                            }}
                        />
                    </Box>
                <TableContainer sx={{ borderRadius: '0px'}} component={Paper}>
                    <Table>
                        <TableHead 
                            sx={{
                                background: '#002129',
                                color:'#fff'
                            }}
                        >
                            {myLeaderboardInfo?.myInfo?.length > 0 && (
                                <>
                                    {myLeaderboardInfo?.myInfo?.map((info, infoIndex) => {
                                        const usersPicks = findUserEntry(info?.username);
                                        //console.log("USER ENTRY", usersPicks);
                                        const championTeam = findChampId(usersPicks);
                                        //console.log("CHAMPION TEAM", championTeam);
                                        const championResult = findChampResult(usersPicks);
                                        
                                        return (
                                            <TableRow 
                                                key={`my-info-${infoIndex}`} 
                                                sx={{ background: '#F0F1F1', color: '#002129' }}
                                            >
                                                <TableCell 
                                                    sx={{ 
                                                        textAlign: 'center', 
                                                        fontSize: '12px',
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 8,
                                                        backgroundColor: '#F0F1F1',
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '12px', color: '#002129' }}>
                                                        {info?.rank}
                                                    </Typography>
                                                </TableCell>
                                                
                                                <TableCell 
                                                    sx={{
                                                        textAlign:'left', 
                                                        fontSize:'12px',
                                                        position: 'sticky',
                                                        left: '75px',
                                                        zIndex: 8,
                                                        backgroundColor: '#F0F1F1',
                                                        textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                                        cursor: hasJoinDeadlinePassed ? 'pointer' : 'default',
                                                        fontWeight: hasJoinDeadlinePassed ? '700' : '400',
                                                        width: { xs: '160px', sm: '175px', md: '40%' }, // Fixed width for mobile
                                                        maxWidth: { xs: '160px', sm: '175px', md: '40%' }, // Fixed max-width for mobile
                                                        minWidth: { xs: '160px', sm: '175px' }, // Add a minimum width
                                                    }}
                                                    onClick={() => hasJoinDeadlinePassed ? handleUsernameClick(info?.username) : null}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column', // Stack rows vertically
                                                            alignItems: 'flex-start', // Align rows to the left
                                                            width: '100%', // Take up full width of parent
                                                        }}
                                                    >
                                                        {/* Row 1: Username and Bar Logo */}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                width: '100%', // Take full width of parent
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    maxWidth: 'calc(100% - 35px)', // Reserve space for logo
                                                                    textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                                                    fontWeight: hasJoinDeadlinePassed ? '700' : '400',
                                                                }}
                                                            >
                                                                {info?.username}
                                                            </Typography>
                                                            {leaderboardImage && usersWhoCantWin?.includes(info?.userId) ? (
                                                                <img
                                                                    src={leaderboardImage}
                                                                    alt="Can't Win Logo"
                                                                    style={{ 
                                                                        maxHeight: '30px',
                                                                        minWidth: '30px',
                                                                        marginLeft: '5px',
                                                                        flexShrink: 0 // Prevent the image from shrinking
                                                                    }}
                                                                />
                                                            ) : info?.logo && (
                                                                <img
                                                                    src={info.logo}
                                                                    alt="User Logo"
                                                                    style={{ 
                                                                        maxHeight: '30px',
                                                                        minWidth: '30px',
                                                                        marginLeft: '5px',
                                                                        flexShrink: 0 // Prevent the image from shrinking
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>

                                                        {/* Row 2: Tiebreaker Points */}
                                                        {hasFinalStarted && (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px', // Adjust font size for this row
                                                                    fontWeight: 500,
                                                                    color: '#002129',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                {!isGameOver ? (
                                                                    <>Tiebreaker Pts: {info?.tiebreaker || 0}</>
                                                                ) : (
                                                                    <>Margin: {info?.tiebreakerMargin && info?.tiebreakerMargin !== 5000 ? info?.tiebreakerMargin : 'N/A'}</>
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                
                                                <TableCell 
                                                    sx={{ 
                                                        textAlign: 'center', 
                                                        fontSize: '12px', 
                                                        width: { md: "5%", lg: "5%" } 
                                                    }}
                                                >
                                                    {championTeam ? (
                                                        <img
                                                            className="super-pickem-leaderboard-champ-image"
                                                            style={{
                                                                border: `1px solid ${findChampColor(championResult)}`,
                                                                backgroundColor: findChampBackgroundColor(championResult),
                                                            }}
                                                            src={getTeamLogo(league, championTeam, teams)}
                                                            alt="TEAM LOGO"
                                                            loading='lazy'
                                                        />
                                                    ) : !hasJoinDeadlinePassed ? (
                                                        <Box
                                                            className="super-pickem-leaderboard-champ-placeholder"
                                                        />
                                                    ) : (
                                                        <Box
                                                            className="super-pickem-leader-no-champ"
                                                        >
                                                            <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                
                                                <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"} }}>
                                                    {info?.bracketPoints || 0}
                                                </TableCell>
                                                
                                                <TableCell sx={{ textAlign: 'center', fontSize: '12px', width:{md:"20%", lg:"20%"}}}>
                                                    {!hasJoinDeadlinePassed ? maxPossiblePoints : info?.maxPossiblePoints || 0}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </>
                            )}

                            <TableRow 
                                sx={{
                                    color:'#fff'
                                }}>
                                        <TableCell
                                            onClick={() => handleSort('rank')}
                                            sx={{
                                                color: rankHeaderColor,
                                                fontWeight: 900,
                                                textAlign: 'center',
                                                // width: "30px",
                                                textDecoration: 'underline',
                                                position: 'sticky',
                                                backgroundColor: '#002129',
                                                zIndex: 8,
                                                left: 0,
                                            }}
                                        >
                                            RANK
                                        </TableCell>

                                        <TableCell 
                                            onClick={() => handleSort('username')}
                                            sx={{ 
                                                color: playerHeaderColor, 
                                                fontWeight: 900, 
                                                textAlign: 'left',
                                                textDecoration: 'underline',
                                                position: 'sticky',
                                                backgroundColor: '#002129',
                                                zIndex: 8,
                                                left: '75px',
                                                width: { xs: '160px', sm: '175px', md: '40%' },
                                                maxWidth: { xs: '160px', sm: '175px', md: '40%' },
                                                minWidth: { xs: '160px', sm: '175px' },
                                            }}
                                        >
                                            PLAYER
                                        </TableCell>
                                        
                                        <TableCell sx={{ color: '#fff', fontWeight: 900, textAlign: 'center'}} >CHAMP</TableCell>

                                        <TableCell
                                            onClick={() => handleSort('bracketPoints')}
                                            sx={{
                                                color: bracketPointsHeaderColor,
                                                fontWeight: 900,
                                                textAlign: 'center',
                                                position: 'relative',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {isMobile ? 'PTS' : 'POINTS'}
                                        </TableCell>

                                        <TableCell
                                            onClick={() => handleSort('maxPossiblePoints')} 
                                            sx={{ 
                                                color: maxPointsHeaderColor, 
                                                fontWeight: 900, 
                                                textAlign: 'center', 
                                                position: 'relative',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            MAX
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                            <TableBody>
                                {paginatedData?.map((row, index) => {
                                    const usersPicks = row?.userId === myUserId ? findUserEntry(row?.username) : null;
                                    const championTeam = row?.userId === myUserId ? findChampId(usersPicks) : row?.champ;
                                    const championResult = row?.userId === myUserId ? findChampResult(usersPicks) : row?.champResult;
                                    
                                    return(
                                        <TableRow
                                            key={index}
                                            sx={{
                                                backgroundColor: 
                                                    row?.userId === myUserId 
                                                        ? '#FFF6DA' 
                                                        : hostInfo && hostInfo.hostIds && hostInfo.hostIds.includes(row?.userId)
                                                            ? hostInfo.hostBackgroundColor || '#FFF'
                                                            : index % 2 === 0 
                                                                ? '#FFF' 
                                                                : '#F0F1F1',
                                            }}
                                        >
                                                <TableCell 
                                                    sx={{ 
                                                        textAlign: 'center', 
                                                        fontSize: '12px',
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 8,
                                                        backgroundColor: 
                                                        row?.userId === myUserId 
                                                            ? '#FFF6DA' 
                                                            : hostInfo && hostInfo.hostIds && hostInfo.hostIds.includes(row?.userId)
                                                                ? hostInfo.hostBackgroundColor || '#FFF'
                                                                : index % 2 === 0 
                                                                    ? '#FFF' 
                                                                    : '#F0F1F1',
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                                        {row?.rank}
                                                    </Typography>
                                            </TableCell>
                                            
                                            <TableCell 
                                                sx={{
                                                    textAlign:'left', 
                                                    fontSize:'12px',
                                                    position: 'sticky',
                                                    left: '75px',
                                                    zIndex: 8,
                                                    backgroundColor: 
                                                        row?.userId === myUserId 
                                                            ? '#FFF6DA' 
                                                            : hostInfo && hostInfo.hostIds && hostInfo.hostIds.includes(row?.userId)
                                                                ? hostInfo.hostBackgroundColor || '#FFF'
                                                                : index % 2 === 0 
                                                                    ? '#FFF' 
                                                                    : '#F0F1F1',
                                                    textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                                    cursor: hasJoinDeadlinePassed ? 'pointer' : 'default',
                                                    fontWeight: hasJoinDeadlinePassed ? '700' : '400',
                                                    width: { xs: '160px', sm: '175px', md: '40%' }, // Fixed width for mobile
                                                    maxWidth: { xs: '160px', sm: '175px', md: '40%' }, // Fixed max-width for mobile
                                                    minWidth: { xs: '160px', sm: '175px' }, // Add a minimum width
                                                }}
                                                onClick={() => hasJoinDeadlinePassed ? handleUsernameClick(row?.username) : null}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <Box sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        width: '100%' // Take full width of parent
                                                    }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: row?.logo ? 'calc(100% - 35px)' : 'calc(100%)', // Reserve space for logo
                                                                textDecoration: hasJoinDeadlinePassed ? 'underline' : 'none',
                                                                fontWeight: hasJoinDeadlinePassed ? '700' : '400',
                                                            }}
                                                        >
                                                            {row?.username}
                                                        </Typography>
                                                        {leaderboardImage && usersWhoCantWin?.includes(row?.userId) ? (
                                                            <img
                                                                src={leaderboardImage}
                                                                alt="Can't Win Logo"
                                                                style={{ 
                                                                    maxHeight: '30px',
                                                                    minWidth: '30px',
                                                                    marginLeft: '5px',
                                                                    flexShrink: 0 // Prevent the image from shrinking
                                                                }}
                                                            />
                                                        ) : row?.logo && (
                                                            <img
                                                                src={row.logo}
                                                                alt="User Logo"
                                                                style={{ 
                                                                    maxHeight: '30px',
                                                                    minWidth: '30px',
                                                                    marginLeft: '5px',
                                                                    flexShrink: 0 // Prevent the image from shrinking
                                                                }}
                                                            />
                                                        )}
                                                    </Box>

                                                        {hasFinalStarted && (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: '#002129',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                Tiebreaker Pts: {row?.tiebreakerPoints || 0}
                                                            </Typography>
                                                        )}
                                                        {isGameOver && (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: '#002129',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                Margin: {row?.tiebreakerMargin && row?.tiebreakerMargin !== 5000 ? row?.tiebreakerMargin : 'N/A'}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            
                                            <TableCell 
                                                sx={{ 
                                                    textAlign: 'center', 
                                                    fontSize: '12px', 
                                                }}
                                            >
                                                {(!hasJoinDeadlinePassed && (!championTeam || row?.userId !== myUserId)) ? (
                                                    <Box
                                                        className="super-pickem-leaderboard-champ-placeholder"
                                                    />
                                                ) : championTeam ? (
                                                    <img
                                                        className="super-pickem-leaderboard-champ-image"
                                                        style={{
                                                            border: `1px solid ${findChampColor(championResult)}`,
                                                            backgroundColor: findChampBackgroundColor(championResult),
                                                        }}
                                                        src={getTeamLogo(league, championTeam, teams)}
                                                        alt="TEAM LOGO"
                                                        loading='lazy'
                                                    />
                                                ) : (
                                                    <Box
                                                        className="super-pickem-leader-no-champ"
                                                    >
                                                        <CloseIcon sx={{ color: '#CC293C', fontSize: '16px' }} />
                                                    </Box>
                                                )}
                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center', fontSize: '12px'}}>
                                                {row?.bracketPoints || 0}
                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center', fontSize: '12px' }}>
                                                {!hasJoinDeadlinePassed ? maxPossiblePoints : row?.maxPossiblePoints || 0}
                                            </TableCell>


                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                            sx={{
                                maxWidth: '900px',
                                margin: '0 auto',
                            }}
                        >
                            <TablePagination
                                rowsPerPageOptions={[50, 100]}
                                component="div"
                                count={sortedData?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={Math.min(page, Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1))} // Derive a valid page value
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Rows"
                                sx={{
                                    backgroundColor: 'white',
                                    '& .MuiTablePagination-toolbar': {
                                        color: '#002129',
                                    },
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                                        color: '#002129',
                                    }
                                }}
                            />
                        </Box>
                </Grid>
        </>
    );
}

export default BracketLeaderboard;