import React, {useEffect, useState} from 'react';
import { useGameContext } from '../context/createGamesContext';
// import '../styles.scss';
import { connect } from 'react-redux';

const BlockPoolChoices = (props) => {
  const { updateGameTypeData, gameTypeData, setGameName, GameSchedule, handleGetNFLSchedule, commissionerNotes,  setCommissionerNotes, setSelectedGameData } = useGameContext();
  const { sportsData } = props;
  const [weekly, setWeekly] = useState('');
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamOrSeries, setTeamOrSeries] = useState('team');
  const [selectedGames, setSelectedGames] = useState([]);
  const [messageBoardCheck, setMessageBoardCheck] = useState(false);
  console.log('sportsData', props);
  const handleGridNameChange = (e) => {
    updateGameTypeData({ gridName: e.target.value });
  };

  const handleNumberOfBlocksChange = (e) => {
    updateGameTypeData({ numberOfBlocks: parseInt(e.target.value, 10) });
  };

  const handlePickNumbersForChange = (e) => {
    updateGameTypeData({ pickNumbersFor: e.target.value });
    renderSetNumbers(e.target.value);
  };

  const handleMaxPicksChange = (e) => {
    updateGameTypeData({ maxPicks: e.target.value });
  };

  const handleNumbersGeneratedChange = (e) => {
    updateGameTypeData({ numbersGenerated: e.target.value });
  };

  // const handleWeekOfGameChange = (e) => {
  //   updateGameTypeData({ weekOfGame: e.target.value });
  //   handleGetNFLGamesByWeek();
  // };

  const handleNumberRevealChange = (e) => {
    updateGameTypeData({ revealNumbers: e.target.value });
  };

  const handleTeamSelectionChange = (e) => {
    updateGameTypeData({ teamSelection: e.target.value });
  };

  const handleCommissionerNotesChange = (e) => {
    setCommissionerNotes(e.target.value);
  };

  const handleAxisChange = (e) => {
    updateGameTypeData({axisConfiguration:e.target.value});
  };

  const handleHowNumbersGeneratedChange = (e) => {
    updateGameTypeData({howNumbersGenerated:e.target.value});
  };

  const handleMessageBoardChange = (e) => {
    updateGameTypeData({messageBoard:e.target.value});
    setMessageBoardCheck(!messageBoardCheck);
  };
  const renderSetNumbers = (winner) => {
    if(winner === 'halves'){
      return (
        <>
          <label>Sets of Numbers</label>
          <select onChange={handleNumbersGeneratedChange} value={gameTypeData.numbersGenerated}>
            <option value="">Select</option>
            <option value="1">1</option>
            <option value="2">2 Sets</option>
          </select>
        </>
      );
    }else if(winner === 'quarters'){
      return (
        <>
        <label>Sets of Numbers</label>
        <select onChange={handleNumbersGeneratedChange} value={gameTypeData.numbersGenerated}>
          <option value="1">1</option>
          <option value="4">4 Sets</option>
        </select>
        </>
      );
    }else{
      return null;
    }
  };

  // const handleGameAdds = (gameKeys) => {
  //   updateGameTypeData({
  //     gameSelections: [...selectedGames, gameKeys]
  //   });
  // };


  const dateTime = (dateArg) => {
    const date = new Date(dateArg);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit"
    });
    return formattedDate;
  }

  const handleTeamSchedule = (e) => {
    const teamKey = e.target.value;
    handleGetNFLSchedule();
    const seasonData = sportsData?.seasonData.seasonData;
    let seasonFiltered = seasonData.filter((game) => game.HomeTeam === teamKey || game.AwayTeam === teamKey);
    console.log('season filtered', seasonFiltered);
    setWeekly(seasonFiltered);
    setSelectedTeam(teamKey);
  }

  const handleGameScheduleChange = (e) => {
    const selectedGameId = e.target.value;
    const selectedGame = sportsData?.sportsData.sportsData?.find((game) => {
        return `${game.AwayTeam} @ ${game.HomeTeam}` === selectedGameId;
    });
    console.log(selectedGameId, selectedGame);
    if (selectedGame) {
      const { AwayTeam, HomeTeam,DateTimeUTC } = selectedGame;
      updateGameTypeData({
        gameSchedule: selectedGameId,
        awayTeam: AwayTeam,
        homeTeam: HomeTeam,
        gameStart: DateTimeUTC,
      });
    }
  };

 const RenderTeams = () => {
  //   const [selectedTeam, setSelectedTeam] = useState('');

    // Separate data by division
    const conferences = {};
    sportsData?.nflTeamsData?.nflTeams.forEach((team) => {
      const conference = team.Conference;
      const division = team.Division;

      if (!conferences.hasOwnProperty(conference)) {
        conferences[conference] = {};
      }

      if (!conferences[conference].hasOwnProperty(division)) {
        conferences[conference][division] = [];
      }

      conferences[conference][division].push(team);
    });
    console.log('divisions', conferences);

    // Render the divisions
    return Object.keys(conferences).map((conference) => {
      return (
        <div key={conference} style={{ display: 'flex' }}>
          {Object.keys(conferences[conference]).map((division) => {
            return (
              <div key={division} style={{ margin: '0 auto' }}>
                <h4 style={{ textAlign: 'center' }}>{conference} {division}</h4>
                {conferences[conference][division].map((team) => {
                  const isChecked = selectedTeam === team.Key;
                  const containerStyle = isChecked ? 'checked-teams' : '';

                  return (
                    <div className={`radio-button-container ${containerStyle}`} key={team.TeamID}>
                       <input
                        type="radio"
                        name="team"
                        value={`${team.Key}`}
                        id={`${team.Key}`}
                        onChange={(e) => {
                          setSelectedTeam(e.target.value);
                          handleTeamSchedule(e);
                          handleTeamSelectionChange(e);
                        }}
                        style={{opacity: "0", position: "absolute", zIndex: "-1"}}
                      />
                      <div style={{ width: 50, margin: '0 auto' }}>
                        <img style={{ width: '100%', display: 'block' }} src={team.WikipediaLogoUrl} alt={team.FullName} />
                      </div>
                      <label htmlFor={team.Key} style={{ textAlign: 'center' }}>{team.FullName}</label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });
  };
    // // Render the divisions
    // return Object.keys(divisions).map((division) => {
    //   <>
    //     <h3>{division}</h3>
    //   </>
    // });


  const RenderWeekOptions = () => {
    if (!sportsData || !sportsData.seasonData.seasonData || !Array.isArray(sportsData.seasonData.seasonData)) {
        return <option>Loading...</option>; // Render a loading state or a placeholder option
      }
    // Function to filter data based on the season
    const filterDataBySeason = (array, season) => {
        return array.filter(obj => obj.Season === season);
    };

        const filteredData = filterDataBySeason(sportsData.seasonData.seasonData, 2023);
        return filteredData.map((game, index) => (
          <option key={index + 1} value={index + 1}>
            Week {index + 1}
          </option>
        ));
      };



  const RenderWeeks = () => {
    const handleGameSelect = (gameKey) => {
      // Check if the game is already selected
      const index = selectedGames.indexOf(gameKey);
      console.log('index', index);
      if (index === -1) {
        // Game is not selected, add it to the selected games array
        setSelectedGames([...selectedGames, gameKey]);
        updateGameTypeData({
          gameSelections:[...selectedGames, gameKey],
        });
        setSelectedGameData([...selectedGames, gameKey]);
      } else {
        // Game is already selected, remove it from the selected games array
        const updatedSelectedGames = [...selectedGames];
        updatedSelectedGames.splice(index, 1);
        setSelectedGames(updatedSelectedGames);
        updateGameTypeData({
          gameSelections: updatedSelectedGames,
        });
        setSelectedGameData(updatedSelectedGames);
      }
    };

    const selectAllGames = () => {
      const selectedHomeGames = weekly.map((game) => game.GameKey);
      const selectedHomeGameData = weekly.map((game) => ({
        GameKey: game.GameKey,
        Week: game.Week,
        Date: game.Date,
        AwayTeam: game.AwayTeam,
        HomeTeam: game.HomeTeam,
        StadiumDetails: game.StadiumDetails,
        Status: game.Status,
        Season: game.Season
      }));


      setSelectedGames((prevSelectedGames) => {
        const updatedSelectedGames = [...selectedHomeGames];
        return [...new Set(updatedSelectedGames)]; // Remove duplicate game keys
      });

      updateGameTypeData({
        gameSelections: selectedHomeGames,
      });
      setSelectedGameData(selectedHomeGameData);
    };

    const selectHomeGames = () => {

      clearAllGames();
      const filteredHomeGames = weekly.filter((game) => game.HomeTeam === selectedTeam && game.GameKey !== null);
      const selectedHomeGames = filteredHomeGames.map((game) => game.GameKey);
      const selectedHomeGameData = filteredHomeGames.map((game) => ({
        GameKey: game.GameKey,
        Week: game.Week,
        Date: game.Date,
        AwayTeam: game.AwayTeam,
        HomeTeam: game.HomeTeam,
        StadiumDetails: game.StadiumDetails,
        Status: game.Status,
        Season: game.Season
      }));

      setSelectedGames((prevSelectedGames) => {
        const updatedSelectedGames = [...selectedHomeGames];
        return [...new Set(updatedSelectedGames)]; // Remove duplicate game keys
      });

      updateGameTypeData({ gameSelections: selectedHomeGames });
      setSelectedGameData(selectedHomeGameData);
    };

    const selectAwayGames = () => {
      clearAllGames();
      const noNullGames = weekly.filter((game) => game.GameKey !== null);
      console.log('noNullGames', noNullGames);
      const filteredAwayGames = noNullGames.filter((game) => game.HomeTeam !== selectedTeam);
      const selectedAwayGames = filteredAwayGames.map((game) => game.GameKey);
      const selectedAwayGameData = filteredAwayGames.map((game) => (
        console.log('game', game),{
        GameKey: game.GameKey,
        Week: game.Week,
        Date: game.Date,
        AwayTeam: game.AwayTeam,
        HomeTeam: game.HomeTeam,
        StadiumDetails: game.StadiumDetails,
        Status: game.Status,
        Season: game.Season
      }));
      console.log('selectedAwayGames', selectedAwayGameData);
      setSelectedGames((prevSelectedGames) => {
        const updatedSelectedGames = [...selectedAwayGames];
        return [...new Set(updatedSelectedGames)]; // Remove duplicate game keys
      });

      updateGameTypeData({ gameSelections: selectedAwayGames });
      setSelectedGameData(selectedAwayGameData);

    };

    const clearAllGames = () => {
      setSelectedGames([]);
      updateGameTypeData({ gameSelections: [] });
    };


    return (
      <>
        <p>Schedule</p>
        <div className="schedule-options">
          <div onClick={()=>selectAllGames()}>Select All</div>
          <div onClick={()=>selectHomeGames()}>Home Games Only</div>
          <div onClick={()=>selectAwayGames()}>Away Games Only</div>
          <div onClick={()=>clearAllGames()}>Clear All</div>
        </div>
        <div id={'scheduleList'}>
          {weekly && weekly.map((game, index) => {
            let disableGame = false;
            if(game.GameKey === null) {
              disableGame = true;
            }
            return (
              <div key={index} style={{display:'flex', alignItems:'center', margin:'0 0 10px 0'}}>
                <input
                  type="checkbox"
                  id={game.GameKey}
                  checked={selectedGames.includes(game.GameKey)}
                  onChange={() => handleGameSelect(game.GameKey)}
                  disabled={disableGame}
                  style={{display:'block',  width: '80px', margin:'0 20px 0 20px'}}
                  className="schedule-checkbox"
                  data-game-type={game.HomeTeam === selectedTeam ? 'home' : 'away'}
                  data-game-key={game.GameKey}
                />
                <label htmlFor={game.GameKey} style={{display:'block'}}>
                  Week {game.Week} ({dateTime(game.Date)}) {game.AwayTeam} @ {game.HomeTeam}
                </label>
              </div>
            );
          })}

        </div>
      </>
    );
  };

  const RenderGameOptions = () => {
    return sportsData?.sportsData?.sportsData.map((game) => (
        <option key={game.id} value={game.id}>
          {game.AwayTeam} @ {game.HomeTeam}
        </option>
    ));
  };

  const WeeklyGames = () => {
    return (
        <select name="week-of-game" value={gameTypeData.gameSchedule} onChange={handleGameScheduleChange}>
            {RenderGameOptions()}
        </select>
      );
  };


  const RenderGameSchedule = () => {
    const gameData = [
      // ... game data here
    ];

    // Function to filter games based on home team name
    const getGamesByHomeTeam = (teamName) => {
      return gameData.filter((game) => game.HomeTeam === teamName);
    };
  };

  const PickAxis = () => {
    return(
      <>
        {teamOrSeries === "team" ?
          <>
            <select name="axis" onChange={handleAxisChange}>
              <option value="HomeX">Home Team X-Axis/Away Team Y-Axis</option>
              <option value="HomeY">Home Team Y-Axis/Away Team X-Axis</option>
              <option value="AlwaysX">{selectedTeam} X-Axis</option>
              <option value="AlwaysY">{selectedTeam} Y-Axis</option>
            </select>
          </>
          :
          <>
            <select name="axis" onChange={handleAxisChange} >
              <option value="HomeX">Home Team X-Axis/Away Team Y-Axis</option>
              <option value="HomeY">Home Team Y-Axis/Away Team X-Axis</option>
            </select>
          </>
        }
      </>
    );
  };
  return (
    <>
      <hr style={{border:"1px solid #777", margin:"45px auto"}}/>
      <h3>Block Pool</h3>
      <p>Description of block pool</p>
      <div className="block-pool-choices">
        <div>
          <label htmlFor="game_name">Block Pool Name</label>
          <input placeholder="Enter the title for your game" id="game_name" type="input" onChange={(e) => setGameName(e.target.value)} />
          <RenderTeams/>

          <RenderWeeks />
          <p>Number of Blocks</p>
          <select name="number-of-blocks" value={gameTypeData.numberOfBlocks} onChange={handleNumberOfBlocksChange}>
            <option value="100">100 {"("}Recommended{")"}</option>
            <option value="50">50</option>
            <option value="25">25</option>
          </select>
          <p>Pick Winners For</p>
          <select name="change numbers" value={gameTypeData.pickNumbersFor} onChange={handlePickNumbersForChange}>
            <option value="full">Full Game</option>
            <option value="half">Every Half</option>
            <option value="quarter">Every Quarter</option>
          </select>

          {gameTypeData.pickNumbersFor === 'half'?
              <>
                <label>Sets of Numbers</label>
                <select onChange={handleNumbersGeneratedChange} value={gameTypeData.numbersGenerated}>
                  <option value="">Select</option>
                  <option value="1">1</option>
                  <option value="2">2 Sets</option>
                </select>
              </>
              :
              null
          }
          {gameTypeData.pickNumbersFor === 'quarter'?

              <>
              <label>Sets of Numbers</label>
              <select onChange={handleNumbersGeneratedChange} value={gameTypeData.numbersGenerated}>
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="4">4 Sets</option>
              </select>
              </>
            :
            null
          }
          <label htmlFor="number_reveal">Number Reveal</label>
          <select onChange={handleNumberRevealChange} value={gameTypeData.numberReveal} id="number_reveal">
            <option value="all_claimed">Once All Blocks have been claimed {"("}Default{")"}</option>
            <option value="commissioner">Commissioner</option>
          </select>
          <p>Maximum Blocks per Entrant</p>
          <select name="max-picks" value={gameTypeData.maxPicks} onChange={handleMaxPicksChange}>
            <option value="unlimited">Unlimited</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>

          <p>Numbers are generated</p>
          <select
            name="number-generated"
            value={gameTypeData.howNumbersGenerated}
            onChange={handleHowNumbersGeneratedChange}
          >
            <option value="random">Randomly</option>
            <option value="commissioner">By Commissioner</option>
          </select>

          <p>Axis Labels</p>
          {PickAxis()}

          <p>Commissioner's Notes</p>
          <textarea name="commissioner-notes" value={commissionerNotes} onChange={handleCommissionerNotesChange} />

          <p>Block Pool Message Board</p>
          <label className="switch">
            <input type="checkbox" onChange={handleMessageBoardChange} />
            <span className="slider round" ></span>
          </label>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({

    sportsData: state.sportsData,
    seasonData: state.seasonData
});

// Connect the component to the Redux store
export default connect(mapStateToProps)(BlockPoolChoices);