import React from "react";
import { Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import "./QuickPickGamesStyles.scss";

const QuickPickSingleEntryView = ({
    entry,
    findMyRank,
    theCurrentInterval,
    picksForTheCurrentInterval,
    getTotalPoints,
    getIntervalsPoints,
    setIndexToRemove,
    myLeaderboardInfo,
    setRemoveModalOpen,
    isThisPoolPartyGame,
    isUserAdmin,
    totalEntriesCount,
    oneWeekEvent,
    pickIndex,
    hasJoinDeadlinePassed,
    currentIntervalName,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    //console.log("Entry:", entry);
    const picks = entry?.pool?.picks;
    const picksThisInterval = picks?.filter(pick => Number(pick.interval) === Number(theCurrentInterval)).length;
    //console.log("Picks This Interval in QuickPickSingleEntryView: ", picksThisInterval, picks);
    const arePicksIn = picksThisInterval === picksForTheCurrentInterval;
    //console.log("Are Picks In in QuickPickSingleEntryView: ", arePicksIn, "My Leaderboard Info: ", myLeaderboardInfo);

    const handleRemovedClick = (index) => {
        setIndexToRemove(index);
        setRemoveModalOpen(true);
    };

    return (
        <Box>
            <Box className="quick-pick-single-entry-container">
                <Box className="quick-pick-single-entry-content">
                    <Box className="quick-pick-single-entry-column">
                        <Typography className="quick-pick-single-entry-header-text">
                            {oneWeekEvent ? 'Rank' : 'Total Points'}
                        </Typography>
                        {!oneWeekEvent && (
                            <Typography className="quick-pick-single-entry-text">
                                {getTotalPoints(picks)}
                            </Typography>
                        )}
                        <Typography className="quick-pick-single-entry-text">
                            {findMyRank(myLeaderboardInfo, entry?.name)} of {totalEntriesCount}
                        </Typography>
                    </Box>
                    <Box className="quick-pick-single-entry-column">
                        <Typography className="quick-pick-single-entry-header-text">
                            {!oneWeekEvent && currentIntervalName} Points
                        </Typography>
                        <Typography className="quick-pick-single-entry-text">
                            {getIntervalsPoints(picks, theCurrentInterval)}
                        </Typography>
                    </Box>
                    {picksForTheCurrentInterval > 0 && (
                        <Box className="quick-pick-single-entry-column">
                            <Typography className="quick-pick-single-entry-header-text">
                                Picks In?
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography 
                                    className="quick-pick-single-entry-text"
                                    sx={{
                                        color: picksThisInterval === picksForTheCurrentInterval ? "#00AA72" : "#CC293C",
                                    }}
                                >
                                    {picksThisInterval}/{picksForTheCurrentInterval}
                                </Typography>
                                {arePicksIn ? (
                                    <CheckIcon className="quick-pick-single-entry-icon" style={{ color: '#00AA72', fontSize: isMobile ? '20px' : '30px', marginTop: isMobile? '0px' : '8px'}}/>
                                ) : (
                                    <CloseIcon className="quick-pick-single-entry-icon" style={{ color: '#CC293C', fontSize: isMobile ? '20px' : '30px', marginTop: isMobile ? '0px' : '8px'}}/>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {!hasJoinDeadlinePassed && !isThisPoolPartyGame && !isUserAdmin && (
                <Typography 
                    onClick={() => handleRemovedClick(Number(pickIndex))}
                    sx={{
                        color: "#CC293C",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: "500",
                        width: '100%',
                        textAlign: 'center',
                        paddingBottom: '10px'
                    }}
                >
                    Remove
                </Typography>
            )}
        </Box>
    );
}

export default QuickPickSingleEntryView;