import React from "react";
import { UserAndGameProvider } from "../contexts/UserAndGameContext";
import { BracketGamesProvider } from "../components/BracketGames/context/BracketGamesContext";
import BracketGamesContainer from "../components/BracketGames/BracketGamesContainer";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const BracketGamePage = () => {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <UserAndGameProvider>
                    <BracketGamesProvider>
                        <BracketGamesContainer />
                    </BracketGamesProvider>
                </UserAndGameProvider>
            </QueryClientProvider>
        </div>
    );
};

export default BracketGamePage;