// utils/roundUtils.js
import { DateTime } from "luxon";

export function getWeeklyRound(rounds) {
    const now = DateTime.now();

    // Separate rounds into those with `showUntil` in the future and those in the past
    const futureRounds = rounds?.filter(round => DateTime.fromISO(round?.showUntil) > now) || [];
    const pastRounds = rounds?.filter(round => DateTime.fromISO(round?.showUntil) <= now) || [];

    if (futureRounds.length > 0) {
        // Find the round with the closest `showUntil` in the future
        return futureRounds.reduce((nearest, round) => {
            const roundDate = DateTime.fromISO(round?.showUntil);
            const nearestDate = DateTime.fromISO(nearest?.showUntil);
            return roundDate < nearestDate ? round : nearest;
        }).round;
    } else if (pastRounds.length > 0) {
        // If all rounds are in the past, return the last round
        return pastRounds.reduce((latest, round) => {
            const roundDate = DateTime.fromISO(round?.showUntil);
            const latestDate = DateTime.fromISO(latest?.showUntil);
            return roundDate > latestDate ? round : latest;
        }).round;
    }

    // Default to round 1 if no rounds are available
    return 1;
}
