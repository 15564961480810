// Sports Logos Function

const logoConfigs = {
    NCAAM: 'TeamLogoUrl',
    NCAAW: 'TeamLogoUrl',
    NFL: 'WikipediaLogoURL',
    NCAAF: 'TeamLogoUrl',
    MLB: 'WikipediaLogoUrl',
};

// Modified getTeamLogo function with enhanced debugging
export const getTeamLogo = (league, teamId, teams) => {
    //console.log("getTeamLogo league: ", league, "teamId: ", teamId, "teams: ", teams);
    // Early return check with detailed logging
    if (!teams || !league || !teamId) {
        return null;
    }
    
    const propertyName = logoConfigs[league];
    
    if (!propertyName) {
        return null;
    }

    const team = teams?.find(team => team.GlobalTeamID === teamId);
    
    if (!team) {
        return null;
    }

    return team[propertyName];
};
