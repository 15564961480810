import React from "react";
import { Box, Typography } from "@mui/material";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import './QuickPickRewards.scss';

const RewardsRedeemHeader = ({ gameAddress, mainGameId }) => {

    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    return (
        <Box className="quick-pick-rewards-redeem-header">
            <Typography 
                className="quick-pick-rewards-redeem-header-text"
                onClick={() => navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('REWARDS')}/${uriEncoded('ELIGIBLE')}`)}
            >
                Redeem Your Rewards
            </Typography>
        </Box>
    );
}

export default RewardsRedeemHeader;