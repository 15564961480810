import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetSuperPickemPlayoffs = () => {
    return useMutation({
        mutationFn: getSuperPickemPlayoffsRequest,
        onError: (error) => {
        console.error('Error getting Super Pickem Playoffs:', error);
        },
        // onSuccess: (data) => {
        //   console.log('Get Super Pickem Playoffs:', data);
        // }
    });
}

const getSuperPickemPlayoffsRequest = async ({league, season}) => {
    //console.log("League:", league, "Season:", season);
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

    const response = await axios.get(`${apiUrl}/sports-data/get-super-pickem-playoffs`, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type': 'application/json'
        },
        params: { league, season } // Pass league and season as query parameters
    });
    return response.data;
}


