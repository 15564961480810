import React from 'react';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { userSigninAction, userLogoutAction,getUserDataFromLocalStorage } from '../redux/actions/loginImport';
import { Link } from 'react-router-dom';
import UserDash from '../components/userWelcome/userDash';
// import '../components/userWelcome/styles.scss';
const UserWelcome = () => {

  // Call the function
  const userData = getUserDataFromLocalStorage();
  return (
    <>
      <UserDash />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserWelcome);