import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  userSigninAction,
  getUserDataFromLocalStorage,
} from "../../redux/actions/loginImport";
import Button from "@mui/material/Button";
import { TextField, InputAdornment, IconButton, Snackbar } from "@mui/material";

import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import { VALIDATION_MESSAGES } from "../../constants.js";

const LoginComponent = (props) => {
  const { redirectPath } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenExpired = queryParams.get("token-expired") === "true";
  const [isTokenSnackbarOpen, setIsTokenSnackbarOpen] = useState(tokenExpired);
  const { error } = useSelector((state) => state.login.loginReducer);

  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const [loginDetailsValidationDetails, setLoginDetailsValidationDetails] =
    useState({
      email: {
        isError: false,
        message: "",
      },
      password: {
        isError: false,
        message: "",
      },
    });
  const [showPassword, setShowPassword] = useState(false);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const userData = getUserDataFromLocalStorage();

  function onLoginDetailsChange(event) {
    const { name, value } = event.target;
    setUserHasInteracted(true);
    setLoginDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value.trim(),
    }));
    // Clear error message when user starts typing
    setLoginDetailsValidationDetails({
      email: {
        isError: false,
        message: "",
      },
      password: {
        isError: false,
        message: "",
      },
    });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function onLogin() {
    const { email, password } = loginDetails;

    if (!email || !password) {
      // If either email or password is empty, show the specific error message
      setLoginDetailsValidationDetails({
        ...loginDetailsValidationDetails,
        email: {
          isError: !email,
          message: email
            ? VALIDATION_MESSAGES.REQUIRED_FIELD
            : "A valid email is required.",
        },
        password: {
          isError: !password,
          message: password
            ? VALIDATION_MESSAGES.REQUIRED_FIELD
            : "A valid password is required.",
        },
      });
    } else {
      // If email and password are provided and within character limit, dispatch sign-in action
      dispatch(userSigninAction({ email: email.toLowerCase(), password }, redirectPath));
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      onLogin();
    }
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        style={{ bottom: "100px" }}
        open={isTokenSnackbarOpen}
        autoHideDuration={135000}
        onClose={() => setIsTokenSnackbarOpen(false)}
        message="For security reasons, your session has expired. Please sign in again."
        key={"top" + "right"}
      />

      {!userData ? (
        <Box
          component="form"
          className="text-center"
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <TextField
            id="email"
            name="email"
            label="Email address"
            variant="outlined"
            type="email"
            required
            value={loginDetails.email}
            error={loginDetailsValidationDetails.email.isError}
            onChange={onLoginDetailsChange}
            helperText={loginDetailsValidationDetails.email.message}
            onKeyDown={handleKeyPress}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            required
            value={loginDetails.password}
            error={loginDetailsValidationDetails.password.isError}
            onChange={onLoginDetailsChange}
            helperText={loginDetailsValidationDetails.password.message}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#CC293C",
                  whiteSpace: "pre-line",
                  maxWidth: "260px",
                }}
              >
                Invalid email/password combo or account has not been created
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "8px 22px",
              color: "var(--color-white)",
              backgroundColor: "var(--secondary-color)",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "26px",
              letterSpacing: "0.46px",

              "&:hover": {
                width: "100%",
                padding: "8px 22px",
                color: "var(--color-white)",
                backgroundColor: "var(--secondary-color)",
                opacity: "0.9",
              },
            }}
            onClick={onLogin}
          >
            SIGN IN
          </Button>
          <Link
            variant="contained"
            style={{
              width: "100%",
              padding: "8px 22px",
              backgroundColor: "var(--color-yellow)",
              color: "var(--primary-color)",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "26px",
              letterSpacing: "0.46px",
              "&:hover": {
                width: "100%",
                padding: "8px 22px",
                opacity: "0.9",
                backgroundColor: "var(--color-yellow)",
                color: "var(--primary-color)",
              },
              textDecoration: "none",
            }}
            to={redirectPath ? `/register/${redirectPath}` : '/register'}
          >
            CREATE A NEW ACCOUNT!
          </Link>
          <Link
            className="my-2 hover:font-bold transition-all"
            to="/forgot-password"
          >
            Forgot your password?
          </Link>
        </Box>
      ) : (
        <div>
          You already signed in,{" "}
          <Link to="/">jump back in and start playing!</Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  error: state.login.error,
});

export default connect(mapStateToProps)(LoginComponent);
