import React, { useState, useCallback } from "react";
import { Button, TextField, Typography } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "../BirdiePool/BirdiePrivateGroup/PrivateGroup.scss";
import { useParams } from "react-router-dom";

const CommissionerInvites = ({
    gameData,
    myUserId,
    gameCommissioners,
    leaderboardInfo,
    mainGameId,
    gameName,
    isGameOver,
    doIHaveCommissionerAccess,
    gameAddress,
}) => {
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    //console.log("GameData: ", gameData);

    const { gameId, pageState } = useParams();

    const [emails, setEmails] = useState("");
    const [emailsError, setEmailsError] = useState(false);
    const [helperText, setHelperText] = useState("Enter up to 50 comma-separated email addresses.");
    const [copyMessage, setCopyMessage] = useState("");
    const [sentEmails, setSentEmails] = useState(false);
    const [sentEmailsColor, setSentEmailsColor] = useState(null);
    const [sentEmailsHelperText, setSentEmailsHelperText] = useState("");


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleCopyLink = () => {
        const link = `${process.env.REACT_APP_CLIENT_URL}/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`;
        navigator.clipboard.writeText(link).then(() => {
            setCopyMessage('Link copied to clipboard!');
            setTimeout(() => setCopyMessage(''), 3000); // Hide message after 3 seconds
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const validateEmails = (emails) => {
        const emailArray = emails.split(',');
        if (emailArray.length > 50) {
            setHelperText("You can only enter up to 50 email addresses.");
            setEmailsError(true);
            return false;
        }
        for (let email of emailArray) {
            email = email.trim();
            if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                setHelperText("Please enter valid email addresses separated by commas.");
                setEmailsError(true);
                return false;
            }
        }
        setHelperText("Enter up to 50 comma-separated email addresses.");
        setEmailsError(false);
        return true;
    };

    const debouncedValidateEmails = useCallback(debounce(validateEmails, 1000), []);

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmails(value);
        debouncedValidateEmails(value);
    };

    const handleSendEmails = () => {
        //console.log("Sending emails to:", emails);
        const emailArray = emails.split(',');

        let inviteData = {
            invitedMembers: emailArray,
            gameId: gameId,
            invitationName: gameName,
            fromMember: myUserId,
            sponsor: gameData?.sponsorName ? gameData?.sponsorName : null,
        };

        console.log("Invite Data:", inviteData);

        // try {
        //     inviteUsersToPublicParty(inviteData);
        //     setSentEmails(true);
        //     setSentEmailsColor("green");
        //     setSentEmailsHelperText("Invitations sent successfully!");
        //     setEmails("");
        //     setTimeout(() => {
        //         setSentEmails(false);
        //         setSentEmailsHelperText("");
        //     }   , 3000);

        // } catch (error) {
        //     console.error("An error occurred while sending the emails:", error);
        //     setSentEmails(true);
        //     setSentEmailsColor("red");
        //     setSentEmailsHelperText("Failed to send invitations. Please try again.");
        //     setTimeout(() => {
        //         setSentEmails(false);
        //         setSentEmailsHelperText("");
        //     }   , 3000);
        // }
    };

    return (
        <>
            <Typography
                variant="h2"
                style={{
                    fontSize: isMobile ? '20px' : '26px',
                    fontWeight: '700',
                    marginTop: '20px'
                }}
            >
                Invite Members
            </Typography>

            <Typography
                variant="h2"
                style={{
                    fontSize: isMobile ? '16px' : '20px',
                    fontWeight: '400',
                    marginTop: '25px'
                }}
            >
                {/* Option 1: Invite Link */}
                Invite Link
            </Typography>

            {copyMessage && (
                <Typography
                    sx={{
                        color: 'green'
                    }}
                >
                    {copyMessage}
                </Typography>
            )}

            <Button
                variant="contained"
                color="primary"
                endIcon={<ContentCopy />}
                onClick={handleCopyLink}
                sx={{ marginTop: '10px', marginBottom: '96px' }}
            >
                COPY INVITE LINK
            </Button>

            <br />

            {/* <Typography
                variant="h2"
                style={{
                    fontSize: isMobile ? '16px' : '20px',
                    fontWeight: '400',
                    marginTop: '25px'
                }}
            >
                Option 2: Email Invite
            </Typography>

            {sentEmails && (
                <Typography
                    sx={{
                        color: sentEmailsColor
                    }}
                >
                    {sentEmailsHelperText}
                </Typography>
            )}

            <TextField
                id="email-invites"
                label="Email Invites"
                variant="outlined"
                fullWidth
                className="private-group-input"
                value={emails}
                onChange={handleEmailChange}
                helperText={helperText}
                error={emailsError}
                sx={{ width: isMobile ? '90%' : '450px', marginTop: '10px' }}
                autoComplete="off"
            />

            <br />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSendEmails}
                disabled={emailsError || emails.length === 0}
                sx={{ marginTop: '10px', marginBottom: '48px' }}
            >
                INVITE MEMBERS
            </Button> */}
        </>
    );
}

export default CommissionerInvites;