import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import BracketGameInfo from "../GameInfoComponents/BracketGameInfo";
import SurvivorGameInfo from "../GameInfoComponents/SurvivorGameInfo";
import QuickPickGameInfo from "../GameInfoComponents/QuickPickGameInfo";

const MyPoolsCard = ({ gameData, myPoolsData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleGoToPool = (gameAddress, masterPoolId, gameId) => {
        const url = `/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=default`;
        //console.log("URL", url);
        navigateToTop(url);
    };

    const myPoolsDataForThisGame = myPoolsData?.find(pool => pool.masterPoolId === gameData.masterPoolId) || null;
    

    if (!gameData) return null;

    const { masterPoolName, masterPoolLogo, gameIds, gameAddress, masterPoolId } = gameData;

    const returnGameInfo = (gameType) => {
        //console.log("Game Type: ", gameType);
        switch (gameType) {
            case "Bracket":
                return (
                    <BracketGameInfo
                        gameData={gameData}
                        myPoolsData={myPoolsDataForThisGame}
                    />
                );
            case "Survivor":
                return (
                    <SurvivorGameInfo
                        gameData={gameData}
                        myPoolsData={myPoolsDataForThisGame}
                    />
                );
            case "QuickPick":
                return (
                    <QuickPickGameInfo
                        gameData={gameData}
                        myPoolsData={myPoolsDataForThisGame}
                    />
                );
            default:
                return null;
        }
    };
    

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '95%' : '100%',
                maxWidth: '600px',
                height: '100%',
                border: '2px solid #002129',
                borderRadius: '8px',
                margin: '0 auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: '#002129',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px 6px 0 0',
                    padding: '8px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '16px' : '24px',
                        fontWeight: '700',
                        textAlign: 'center',
                        color: '#fff'
                    }}
                >
                    {masterPoolName || "Pool Name"}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#E8E8E8',
                    width: '100%',
                }}
            >
                {myPoolsDataForThisGame ? returnGameInfo(myPoolsDataForThisGame.gameType) : null}            
            </Box>
            {gameIds && gameIds.map((game, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '2px solid #002129',
                        height: isMobile ? '40px' : '60px',
                        padding: '0 8px',
                    }}
                >
                    <img
                        src={game?.image || masterPoolLogo || "https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyStackedLogo.png"}
                        alt="Logo"
                        style={{ 
                            height: isMobile ? '25px' : '40px',
                            width: isMobile ? '25px' : '40px',
                            objectFit: 'contain' 
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: isMobile ? '14px' : '18px',
                            fontWeight: '700',
                            textAlign: 'left',
                            color: '#002129',
                            marginLeft: '8px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {game?.name || "Game Name"}
                    </Typography>
                    <Button
                        onClick={() => handleGoToPool(gameAddress, masterPoolId, game.gameId)}
                        variant="contained"
                        size="small"
                        sx={{
                            height: isMobile ? '24px' : '30px',
                            width: isMobile ? '50px' : '60px',
                            backgroundColor: '#002129',
                            color: '#fff',
                            fontWeight: '700',
                            fontSize: isMobile ? '10px' : '14px',
                            padding: '0px',
                            marginLeft: 'auto', // Ensures button stays at far right
                            '&:hover': {
                                backgroundColor: '#002129',
                            }
                        }}
                    >
                        GO TO
                    </Button>
                </Box>
            ))}
        </Box>
    );
};

export default MyPoolsCard;