import React, { useState } from 'react';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import { Typography, Button, Modal, Checkbox, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import '../../NFLPickEm/NFLPickEmStyles/PickEmStyles.scss';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const UserSubmitBar = ({ weeklySchedule, userPicks }) => {
    const { 
        CFBTeams,
        CFBStadiums,
        CFBGamesByWeek,
        CFBGameOddsByWeek,
        pickEmState, 
        setPickEmState,
        week, 
        userPicksFromDB,
        stopPicksWarning,
        pendingBoxColor,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
     } = useCFBSurvivorContext();

     //console.log("User Picks in USER SUBMIT:", userPicks);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [modalOpen, setModalOpen] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [modal, setModal] = useState(false);
    const [newPickEmState, setNewPickEmState] = useState([]);
    const [startedGames, setStartedGames] = useState([]);
    const [lineChanges, setLineChanges] = useState([]);

    const handleCheckboxChange = (event) => {
        setDontShowAgain(event.target.checked);
    };

    function cfbKey (teamId) {
        return CFBTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
    }

    function cfbOpponentInfo (gameId, teamId) {
        const game = CFBGamesByWeek?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? cfbKey(game?.GlobalAwayTeamID) : cfbKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        const displayString = `${leadingSymbol} ${opponentKey}`;
        return displayString;
    }

    function weeksPicksInfo() {
        if (!userPicks) {
            return [];
        }
        return userPicks?.map(pick => {
            const { spread, teamId, gameId } = pick;
            //console.log("Weeks Picks Info:", spread, teamId, gameId);
            const teamLogo = CFBTeams?.find(team => team?.GlobalTeamID === teamId)?.TeamLogoUrl;
            //console.log("Team Logo:", teamLogo);
            const game = CFBGamesByWeek?.find(game => game?.GlobalGameID === gameId);
            //console.log("Game:", game);
            const isGameOver = game?.IsClosed;
            let borderBackgroundColor = null;
            let result = null;
            let borderColor = pendingBoxColor;
            if (isGameOver) {
                const isPickHome = game?.GlobalHomeTeamID === teamId;
                const result = isPickHome ? (game?.HomeScore + spread) > game?.AwayScore ? "Win" : (game?.HomeScore + spread) === game?.AwayScore ? "Push" : "Loss" : (game?.AwayScore + spread) > game?.HomeScore ? "Win" : (game?.AwayScore + spread) === game?.HomeScore ? "Push" : "Loss";
                borderColor = result === "Win" ? winBoxColor : result === "Push" ? pushBoxColor : lossBoxColor;
                borderBackgroundColor = result === "Win" ? winBoxBackgroundColor : result === "Push" ? pushBoxBackgroundColor : lossBoxBackgroundColor;
                //console.log("Result!!:", result, "Border Color:", borderColor, "Border Background Color:", borderBackgroundColor);
            }
            return { spread, teamLogo, borderColor, borderBackgroundColor, result};
        });
    }

    function weekPendingPicksInfo() {
        return pickEmState?.map(pick => {
            const { spread, teamId, gameId } = pick;
            //console.log("Weeks Picks Info:", spread, teamId, gameId);
            const teamLogo = CFBTeams?.find(team => team?.GlobalTeamID === teamId)?.TeamLogoUrl;
            //console.log("Team Logo:", teamLogo);
            return { spread, teamLogo, gameId };
        });
    }

    const handleFirstSubmit = () => {
        if (!stopPicksWarning) {
            //console.log("Warning Modal");
            setModalOpen(true);
            return;
        } else {
            handleSubmit();
        }
    }

    const handleWarningSubmit = () => {
        if (dontShowAgain) {
            const stopPicksWarning = true;
            //console.log("Push this into this games info in DB:", stopPicksWarning);
        }
        handleSubmit();
    }

    const handleSubmit = () => {

        //console.log("Submit Picks");
        const newPickEmState = [...pickEmState];
        const startedGames = [];
        const lineChanges = [];
    
        for (let i = newPickEmState?.length - 1; i >= 0; i--) {
            const gameTime = newPickEmState[i]?.dateTime;
            const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
            const nowInNY = DateTime.now().setZone('America/New_York');
            const specificDateTime = DateTime.fromObject({ year: 2024, month: 8, day: 8, hour: 13, minute: 30 }, { zone: 'America/New_York' });

            const hasGameStarted = gameDateTime < specificDateTime;
    
            if (hasGameStarted) {
                startedGames?.push(newPickEmState[i]);
                newPickEmState?.splice(i, 1);
            } else {
                const gameSpread = newPickEmState[i]?.spread;
                const currentSpread = -4;  //FIX THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                if (currentSpread > gameSpread) {
                    let newPick = newPickEmState[i];
                    newPick.spread = currentSpread;
                    newPickEmState[i] = newPick;
                } else if (currentSpread < gameSpread) {
                    let newPick = { ...newPickEmState[i], newSpread: currentSpread };
                    lineChanges?.push(newPick);
                    newPickEmState?.splice(i, 1);
                }
            }
        }
        setModalOpen(true);
        setModal("Submit");
        setNewPickEmState(newPickEmState);
        setStartedGames(startedGames);
        setLineChanges(lineChanges);
        //console.log("New Pick Em State:", newPickEmState, "Started Games:", startedGames, "Line Changes:", lineChanges);
    }
    
    const handleRemoveGameClick = (gameId) => {
        //console.log("Remove Game Clicked:", gameId);
        const newPickEmState = pickEmState?.filter(game => game.gameId !== gameId);
        setPickEmState(newPickEmState);
    }

    const weekPicks = weeksPicksInfo();
    const weekPendingPicks = weekPendingPicksInfo();
    const maxPicks = 5;
    const currentPickCount = weekPicks?.length + weekPendingPicks?.length;
    const picksRemaining = maxPicks - currentPickCount;
    const gamesNotStartedCount = weeklySchedule?.filter(game => game.Status === 'Scheduled').length;
    //console.log("Games Not Started Count Submit Bar:", gamesNotStartedCount);
    let missesNeeded = 0;
    if (gamesNotStartedCount < maxPicks) {
        missesNeeded = picksRemaining - gamesNotStartedCount;
    }
    const placeholdersNeeded = maxPicks - currentPickCount - missesNeeded;
    //console.log("Current Pick Count:", currentPickCount, "Misses Needed:", missesNeeded, "Placeholders Needed:", placeholdersNeeded);
    return (
        <div className="user-submit-bar">
        <>
        <div className="weekly-picks-bar" style={{ maxWidth: '1116px', margin: '0 auto'}}>
            {/* <Typography variant="h1">User Submit Bar</Typography> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', padding: '16px 32px' }}>
                {weekPicks?.map((pick, index) => (
                    <div key={index} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{ height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {pick.result ? (
                                <Typography 
                                    variant="body1" 
                                    style={{ 
                                        fontSize: isMobile ? '12px' : '24px', 
                                        fontWeight: '700' 
                                    }}
                                >
                                    {pick.result}
                                </Typography>
                            ) : (
                                <LockIcon 
                                    style={{ 
                                        height: isMobile ? '10px' : '20px', 
                                    }} 
                                />
                            )}
                        </div>
                        <img
                            src={pick.teamLogo}
                            alt="Team Logo"
                            style={{
                                width: isMobile ? '32px' : '64px',
                                height: isMobile ? '32px' : '64px',
                                margin: '0 auto',
                                marginTop: isMobile ? '5px' : '20px',
                                marginBottom: isMobile ? '5px' : '20px',
                                objectFit: 'contain',
                                border: isMobile ? `1px solid ${pick.borderColor}` : `4px solid ${pick.borderColor}`, 
                                backgroundColor: pick.borderBackgroundColor,
                            }}
                        />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px',
                                    fontWeight: '700', 
                                    display: 'flex', 
                                    alignItems: 'center' 
                                }}
                            >
                                {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                            </Typography>
                        </div>
                    </div>
                ))}

                {weekPendingPicks?.map((pick, index) => (
                    <div key={index} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRemoveGameClick(pick.gameId)}>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width={isMobile ? '10' : '20'}
                                height={isMobile ? '10' : '20'}
                                viewBox="0 0 10 10" 
                                fill="none">
                                <path d="M3.33247 0V0.555556H0.554688V1.66667H1.11024V8.88889C1.11024 9.18357 1.22731 9.46619 1.43568 9.67456C1.64405 9.88294 1.92667 10 2.22135 10H7.77691C8.0716 10 8.35421 9.88294 8.56258 9.67456C8.77096 9.46619 8.88802 9.18357 8.88802 8.88889V1.66667H9.44358V0.555556H6.6658V0H3.33247ZM2.22135 1.66667H7.77691V8.88889H2.22135V1.66667ZM3.33247 2.77778V7.77778H4.44358V2.77778H3.33247ZM5.55469 2.77778V7.77778H6.6658V2.77778H5.55469Z" fill="#CC293C"/>
                            </svg>
                        </div>
                        <img 
                            src={pick.teamLogo} 
                            alt="Team Logo" 
                            onClick={() => handleRemoveGameClick(pick.gameId)}
                            style={{ 
                                width: isMobile ? '32px' : '64px', 
                                height: isMobile ? '32px' : '64px',  
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '10px', 
                                marginBottom: isMobile ? '5px' : '20px',  
                                objectFit: 'contain', 
                                border: isMobile ? '1px solid' : '4px solid', 
                                borderColor: '#00AAD1'
                        }} />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px',  
                                    fontWeight: '700' 
                                }}
                            >
                                {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500' 
                            }}
                        >
                           &nbsp;
                        </Typography>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={isMobile ? '33' : '64'}
                            height={isMobile ? '32' : '64'}
                            viewBox="0 0 33 32"
                            fill="none"
                            style={{ 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px',  
                                marginBottom: isMobile ? '5px' : '20px',  
                            }}
                            >
                            <circle cx="16.5" cy="16" r="16" fill="#E5E5E5" />
                        </svg>
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700' 
                                }}
                            >
                                Pick {currentPickCount + index + 1}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: missesNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                fontSize: '8px', 
                                fontWeight: '500', 
                                color: '#CC293C', 
                                textDecoration: 'underline' 
                            }}
                        >
                           &nbsp;
                        </Typography>
                        <CloseIcon 
                            style={{ 
                                width: isMobile ? '32px' : '64px', 
                                height: isMobile ? '32px' : '64px', 
                                fill: '#CC293C', 
                                margin: '0 auto',
                                marginTop: isMobile ? '5px' : '20px',
                                marginBottom: isMobile ? '5px' : '20px', 
                            }} 
                        />
                        <div style={{
                            display: 'flex',
                            width: '30px',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700', 
                                    color:'#CC293C',
                                    whiteSpace: 'nowrap'  
                                }}
                            >
                            No Pick
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            mt={2} 
            mb={2}
        >
            <Button
                    variant="contained"
                    disabled={pickEmState.length === 0}
                    onClick={() => {
                        setModalOpen(false);
                        setPickEmState([]);
                    }}
                >
                    Cancel
            </Button>
            <Box width={15} /> 
            <Button 
                variant="contained"
                disabled={pickEmState.length === 0} 
                color="primary" 
                onClick={handleFirstSubmit}
            >
                Submit Picks
            </Button>
        </Box>

        </>
        
        {modalOpen && (
            <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container">
                <Typography variant="h1">Warning</Typography>
                <Typography variant="body1">All picks are FINAL once they are submitted and cannot be edited.</Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Checkbox
                        checked={dontShowAgain}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Typography variant="body2">Don't show this again.</Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant="contained" onClick={() => setModalOpen(false)}>Cancel</Button>
                    <Button variant="contained" onClick={handleWarningSubmit}>Submit Picks</Button>
                </div>
            </div>
        </Modal>
        )}
        {modalOpen && modal === "Submit" && (
            <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{ height: 'auto' }}>
                {newPickEmState?.length > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '23px', fontWeight: '800' }}>
                            {newPickEmState?.length} Pick{newPickEmState?.length > 1 ? 's' : ''} Submitted
                        </Typography>
                        {newPickEmState?.map((pick, index) => (
                            <Typography variant="h1" style={{ fontSize: '23px' }} key={index}>
                                <span style={{ fontWeight: 800 }}>
                                    {cfbKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                                </span>
                                {' '}
                                {cfbOpponentInfo(pick.gameId, pick.teamId)}
                            </Typography>
                        ))}
                <CheckIcon style={{ width: '82px', height: '50px', fill: '#00AA72', margin: '0 auto', marginTop: '3px', marginBottom: '3px' }} />    
                </>
                )}
                {(startedGames?.length > 0 || lineChanges?.length > 0) && (
                <Typography variant="h1" style={{ fontSize: '23px', fontWeight: '800'}}>ALERT <br/> {(startedGames?.length + lineChanges?.length)} Pick{(startedGames?.length + lineChanges?.length) > 1 ? 's' : '' } Not Submitted </Typography>
                )}
                {startedGames?.length > 0 && (
                    <Typography variant="h1" style={{ fontSize: '23px', textDecoration: 'underline' }}>
                      Game{startedGames?.length > 1 ? 's' : ''} Started
                    </Typography>
                )}    
                {startedGames?.map((pick, index) => (
                    <Typography variant="h1" style={{ fontSize: '23px' }} key={index}>
                        {cfbKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                    {' '}
                    {cfbOpponentInfo(pick.gameId, pick.teamId)}
                </Typography> 
                ))}
                {lineChanges?.length > 0 && (
                    <Typography variant="h1" style={{ fontSize: '23px', textDecoration: 'underline' }}>
                      Spread Change{lineChanges?.length > 1 ? 's' : ''}
                    </Typography>
                )}
                {lineChanges?.map((pick, index) => (
                    <Typography variant="h1" style={{ fontSize: '23px' }} key={index}>
                        {cfbKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                    {' '}
                    {cfbOpponentInfo(pick.gameId, pick.teamId)}
                </Typography>
                ))}
                {(startedGames?.length > 0 || lineChanges?.length > 0) && (
                    <CloseIcon style={{ width: '82px', height: '50px', fill: '#CC293C', margin: '0 auto', marginTop: '3px', marginBottom: '3px' }} />
                )}
                <Typography variant="h1" style={{ fontSize: '23px' }}>Week {week}</Typography>
                <Typography variant="h1" style={{ fontSize: '23px' }}>
                    <span style={{ color: (userPicks?.length + newPickEmState?.length) === 5 ? '#00AA72' : '#CC293C' }}>
                        {(userPicks?.length + newPickEmState?.length)}/5&nbsp;
                    </span> 
                    Pick Submitted
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </div>
            </div>
        </Modal>
        )}
        </div>
    );
}

export default UserSubmitBar;
