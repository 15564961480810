import React from "react";
import { Box, Typography } from "@mui/material";
import "../BetSlip.scss";

const BetTypeBar = ({ chosenBetStyle, setChosenBetStyle, betStyles }) => {


  return (
    <Box className={`sportsbook-bet-type-bar`}>
      {/* Straight Text */}
      {betStyles.includes("straight") && (
      <Box
        className={`sportsbook-bet-type-container ${
          chosenBetStyle === "straight" ? "active" : ""
        }`}
        onClick={() => setChosenBetStyle("straight")}
      >
        <Typography className={`sportsbook-bet-type-text ${
          chosenBetStyle === "straight" ? "active" : ""}`}
        >
          STRAIGHT
        </Typography>
      </Box>
      )}

      {/* Parlay Text */}
      {betStyles.includes("parlay") && (
      <Box
        className={`sportsbook-bet-type-container ${
          chosenBetStyle === "parlay" ? "active" : ""
        }`}
        onClick={() => setChosenBetStyle("parlay")}
      >
        <Typography className={`sportsbook-bet-type-text ${
          chosenBetStyle === "parlay" ? "active" : ""}`}
        >
          PARLAY
        </Typography>
      </Box>
      )}

      {/* Teaser Text */}
      {betStyles.includes("teaser") && (
      <Box
        className={`sportsbook-bet-type-container ${
          chosenBetStyle === "teaser" ? "active" : ""
        }`}
        onClick={() => setChosenBetStyle("teaser")}
      >
        <Typography className={`sportsbook-bet-type-text ${
          chosenBetStyle === "teaser" ? "active" : ""}`}
        >
          TEASER
        </Typography>
      </Box>
      )}
    </Box>
  );
};

export default BetTypeBar;
