import React from "react";
import { Box, Typography } from "@mui/material";
import { NavLink, useParams, useLocation } from "react-router-dom";

import {
  GeneralInformation,
  Preferences,
  ResetPassword,
  SocialsInformation,
} from "../components/Profile";
import MainLayout from "../layouts/mainLayouts";

import { getUserDataFromLocalStorage } from "../redux/actions/loginImport";

const DesktopSidebarNavLink = ({ to, label }) => {
  const location = useLocation();

  const isActive = location.pathname === to;
  const activeColoring = isActive
    ? "text-white bg-[color:var(--primary-color)]"
    : "text-[color:var(--primary-color)] ";
  return (
    <NavLink to={to}>
      <Typography className={`${activeColoring} px-4 py-2 rounded-lg`}>
        {label}
      </Typography>
    </NavLink>
  );
};

const MobileNavLink = ({ to, label }) => {
  const location = useLocation();

  const isActive = location.pathname === to;
  const activeColoring = isActive
    ? "text-white"
    : "text-gray-400 bg-[color:var(--primary-color)] ";
  return (
    <NavLink to={to} style={{ flexGrow: "1" }}>
      <Typography
        className={`${activeColoring} px-4 py-3 rounded`}
        sx={{ fontWeight: "600", fontSize: "14px" }}
      >
        {label}
      </Typography>
    </NavLink>
  );
};

export default function ProfilePage() {
  const params = useParams();
  const { step } = params;
  const userData = getUserDataFromLocalStorage();
  return (
    <MainLayout>
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          display: "flex",
          gap: "48px",
          flexDirection: "column",
        }}
        className="py-4 md:py-12 px-4 md:px-16"
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid var(--color-black)",
            width: "100%",
            minHeight: "100%",
            padding: "0 2rem",
            position: "relative",
          }}
          className="hidden md:flex"
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Inter",
              fontSize: "2.86rem",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "150%",
              letterSpacing: "-0.87px",
            }}
            variant="h1"
          >
            My Profile
          </Typography>
        </Box>
        <Box
          className="block md:hidden text-center"
          sx={{ marginLeft: "-16px", marginRight: "-16px", marginTop: "-16px" }}
        >
          <Box
            className="py-4"
            sx={{ borderBottom: "4px solid var(--color-yellow)" }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1.25rem",
              }}
            >
              My Profile
            </Typography>
          </Box>
          <Box>
            <Box className="flex justify-around items-center bg-[color:var(--primary-color)]">
              <MobileNavLink to="/profile/general" label="General" />
              <MobileNavLink to="/profile/preferences" label="Preferences" />
              <MobileNavLink to="/profile/socials" label="Socials" />
              <MobileNavLink to="/profile/reset-password" label="Password" />
            </Box>
          </Box>
        </Box>
        <Box className="flex justify-start">
          <Box
            className="hidden md:block text-left pr-2"
            sx={{ flexGrow: "1", maxWidth: "250px", minWidth: "250px" }}
          >
            <DesktopSidebarNavLink to="/profile/general" label="General" />
            <DesktopSidebarNavLink
              to="/profile/preferences"
              label="Preferences"
            />
            <DesktopSidebarNavLink to="/profile/socials" label="Socials" />
            <DesktopSidebarNavLink
              to="/profile/reset-password"
              label="Password"
            />
          </Box>
          <Box
            className="flex justify-center px-4 md:px-16"
            sx={{ flexGrow: "3" }}
          >
            {step === "general" && <GeneralInformation userData={userData} />}
            {step === "preferences" && <Preferences userData={userData} />}
            {step === "socials" && <SocialsInformation userData={userData} />}
            {step === "reset-password" && <ResetPassword userData={userData} />}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}
