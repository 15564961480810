import React, { useState } from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../MyPoolsPage.scss';
import { useMyPoolsPageContext } from '../context/MyPoolsPageContext';
import PickEmPick5Display from "../GameInfoDisplays/PickEmPick5Display";
import BirdiePoolDisplay from "../GameInfoDisplays/BirdiePoolDisplay";
import NFLSurvivorDisplay from "../GameInfoDisplays/NFLSurvivorDisplay";
import CFBSurvivorDisplay from "../GameInfoDisplays/CFBSurvivorDisplay";
import QuickPickNFLDisplay from "../GameInfoDisplays/QuickPickNFLDisplay";
import CFBSuperPickemDisplay from "../GameInfoDisplays/CFBSuperPickemDisplay";
import NFLSuperPickemDisplay from "../GameInfoDisplays/NFLSuperPickemDisplay";
import ActiveInactiveModal from "./ActiveInactiveModal";
import { useSaveActiveInactivePool } from "../../../hooks/pools/useSaveActiveInactivePools";

const MyPoolsCard = ({ 
    gameName, 
    partyInfo, 
    league, 
    gameType, 
    logo, 
    currentWeek, 
    userPicks, 
    gameAddress, 
    gameId, 
    headerDisplayName, 
    sponsorLogo, 
    startingWeek, 
    picksPerInterval, 
    lastInterval, 
    firstInterval,
    rewards,
    usingTiebreaker, 
    userRewards, 
    hideSponsorInPoolHall, 
    myPoolsState, 
    didPoolPartyMakeInactive 
}) => {
    //console.log("My Pools State", myPoolsState, "Did Pool Party Make Inactive", didPoolPartyMakeInactive);
    //console.log("Current Week", currentWeek);
    const thisCurrentWeek = currentWeek;
    //console.log("This Current Week", thisCurrentWeek);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [modalOpen, setModalOpen] = useState(false);

    const { mutate, isLoading, isError, error, isSuccess } = useSaveActiveInactivePool();

    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleEnterClick = () => {
        if (gameType === 'BirdiePool') {
            navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`);
            //console.log(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`);
        } else {
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`);
        }
    }

    const handleInactiveClick = (gameId, myPoolsState) => {
        //console.log("Inactive Click", gameId, myPoolsState);
        setModalOpen(true);
    }

    const handleMoveClick = async () => { // Make the function async
        //console.log("Move Click");
        const data = {
            gameId: gameId,
            userId: myUserId,
            markGame: myPoolsState === 'active' ? 'inactive' : 'active',
        };
        //console.log("Data", data);
    
        try {
            await mutate(data, { // Await mutate call
                onSuccess: () => {
                    //console.log("Successfully moved game to inactive");
                    setModalOpen(false);
                    // Reload the page
                    window.location.reload(); // Correct method to reload the page
                },
                onError: (error) => {
                    console.log("Error moving game to inactive: ", error);
                    setModalOpen(false);
                    // Reload the page
                    window.location.reload(); // Correct method to reload the page
                }
            });
        } catch (error) {
            console.log("Error moving game to inactive: ", error);
            setModalOpen(false);
            // Reload the page
            window.location.reload(); // Correct method to reload the page
        }
    };
    

    

    const { myUserId } = useMyPoolsPageContext();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 16px 8px 16px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '325px' : '500px',
                height: 'auto',
                gap: '24px',
                alignSelf: 'stretch',
                borderRadius: '8px', // Using a static value as CSS variables aren't directly supported
                border: '2px solid #E8E8E8',
                background: '#FFF',
                margin: '0 auto',
                marginBottom: '16px'
            }}
        >
            <Box
                sx={{
                display: 'flex',
                height: 'auto',
                width: '100%', // Set width to 100% or specific value if needed
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px', // Gap between image and text box
                }}
            >
                {(!hideSponsorInPoolHall || gameType !== 'QuickPick') && (
                    <Box className="game-name-and-parties-container">
                        {sponsorLogo && !hideSponsorInPoolHall && (
                            <img
                                src={sponsorLogo}
                                alt="Sponsor Logo"
                                style={{
                                    width: gameType === 'SuperPickem' ? '70%' : '',
                                }}
                            />
                        )}
                        {gameType !== 'QuickPick' && (
                            <>
                                {gameType !== 'SuperPickem' && (
                                    <Typography className="game-name" sx={{ color: sponsorLogo ? '#CC293C' : '#002129'}}>
                                        {gameName}
                                    </Typography>
                                )}

                                {gameType === 'SuperPickem' && (
                                    <img
                                        src={logo}
                                        alt="Game Logo"
                                        style={{
                                            width: '70%',
                                        }}
                                    />
                                )}
                                
                                <Box className="parties-container">
                                    <Typography component="a" className="parties">
                                        {partyInfo?.length} {partyInfo?.length !== 1 ? "Parties" : "Party"}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
                
                {gameType !== 'SuperPickem' && (
                    <img
                        src={!hideSponsorInPoolHall ? logo : sponsorLogo}
                        alt="Game Logo"
                        style={{
                            width: (hideSponsorInPoolHall && gameType === 'QuickPick') ? '135px' : '64px',
                        }}
                    />
                )}
            </Box>
            {/* {gameType === 'Pickem' && (
                <PickEmPick5Display
                    currentWeek={currentWeek}
                    userPicks={userPicks}
                />
            )}
            {gameType === 'SuperPickem' && league === 'CFB' && (
                <CFBSuperPickemDisplay
                    league={league}
                    userPicks={userPicks}
                />
            )}
            {gameType === 'SuperPickem' && league === 'NFL' && (
                <NFLSuperPickemDisplay
                    league={league}
                    userPicks={userPicks}
                />
            )}
            {gameType === 'BirdiePool' && (
                <BirdiePoolDisplay
                    userPicks={userPicks}
                />
            )}
            {gameType === 'Survivor' && league === 'NFL' && (
                <NFLSurvivorDisplay
                    gameName={gameName}
                    partyInfo={partyInfo}
                    league={league}
                    gameType={gameType}
                    logo={logo}
                    currentWeek={currentWeek}
                    userPicks={userPicks}
                    startingWeek={startingWeek}
                />
            )}
            {gameType === 'Survivor' && league === 'NCAA' && (
                <CFBSurvivorDisplay
                    gameName={gameName}
                    partyInfo={partyInfo}
                    league={league}
                    gameType={gameType}
                    logo={logo}
                    currentWeek={currentWeek}
                    userPicks={userPicks}
                />
            )}
            {gameType === 'QuickPick' && (
                <QuickPickNFLDisplay
                    gameName={gameName}
                    partyInfo={partyInfo}
                    league={league}
                    gameType={gameType}
                    logo={logo}
                    currentWeek={currentWeek}
                    thisCurrentWeek={thisCurrentWeek}
                    userPicks={userPicks}
                    picksPerInterval={picksPerInterval}
                    lastInterval={lastInterval}
                    usingTiebreaker={usingTiebreaker}
                    userRewards={userRewards}
                    firstInterval={firstInterval}
                    rewards={rewards}
                />
            )} */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                <Button
                    variant="contained"
                    color="primary"
                    onClick = {handleEnterClick}
                    sx={{
                    width: '62px',
                    height: '32px',
                    }}
                >
                    ENTER
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderTop: '2px solid #E8E8E8',
                    width: 'calc(100% + 32px)',  // Add the left and right padding (16px + 16px)
                    margin: '0 -16px -8px -16px', // Negative margins to counter the parent padding
                    padding: '8px 0',  // Add some vertical padding for the text
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: '700',
                        color: didPoolPartyMakeInactive ? '#002129' : '#00AAD1',
                        textDecoration: didPoolPartyMakeInactive ? 'none' : 'underline',
                        cursor: didPoolPartyMakeInactive ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                        if (!didPoolPartyMakeInactive) {
                          handleInactiveClick(gameId, myPoolsState);
                        }
                    }} 
                >
                    {didPoolPartyMakeInactive ? 'POOL ARCHIVED' : myPoolsState === 'active' ? 'MOVE TO ARCHIVE' : 'MOVE BACK TO ACTIVE'}
                </Typography>
            </Box>

            {modalOpen && (
                <ActiveInactiveModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleMoveClick}
                    myPoolsState={myPoolsState}
                    gameName={gameName}
                />
            )}
        </Box>
    );
}

export default MyPoolsCard;