import React from 'react';
import { Typography, Box, Modal, Button } from '@mui/material';
import { getTeamLogo } from '../../../utils/SportsData';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../../BracketGames/context/BracketGamesContext';
import { DateTime } from 'luxon';

const BracketSubmissionModal = ({ modalOpen, setModalOpen, submissionData }) => {
    //console.log("Submission Data in BracketSubmissionModal: ", submissionData);
    
    const {
        pickState,
        masterPoolData,
        league,
        lastDayToJoin,
    } = useUserAndGameContext();

    const {
        teams,
    } = useBracketGamesContext();
    //console.log("LAST DAY TO JOIN: ", lastDayToJoin);

    const formatDate = (isoString) => {
        return DateTime.fromISO(isoString, { zone: "America/New_York" })
            .toFormat("M/d '@' h:mma 'ET'");
    };
    const formattedDate = formatDate(lastDayToJoin);
    //console.log("Formatted Date: ", formattedDate);

    const success = submissionData?.success;
    const error = submissionData?.message;
    const bracketStatus = submissionData?.bracketStatus;
    const tiebreakerStatus = submissionData?.tiebreakerStatus;
    const allPicksMade = tiebreakerStatus?.isRequired ? (bracketStatus?.isComplete && tiebreakerStatus?.isComplete) : bracketStatus?.isComplete;

    const rounds = masterPoolData?.gameParameters?.rounds;
    const lastRound = rounds[rounds.length - 1];
    //console.log("Last Round: ", lastRound);
    const champion = pickState?.find(pick => pick.round === lastRound)?.teamId;
    //console.log("Champion: ", champion, league, teams);

    return (
        <Modal
            open={modalOpen}
            onClose={() => window.location.reload()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 310,
                bgcolor: 'background.paper',
                borderRadius: '8px',
                boxShadow: 24,
                p: 2,
            }}>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 800,
                        textAlign: 'center',
                        color: success ? '#002129' : '#CC293C'
                    }}
                >
                    {success ? allPicksMade ? "Congrats! Bracket Complete!" : "Your Picks Have Saved" : "Submission Error"}
                </Typography>
                {success && (
                    <>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                textAlign: 'center',
                                color: '#002129'
                            }}
                        >
                            {allPicksMade ? `Bracket Locks: ${formattedDate}` : "But your bracket is not complete!"}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                textAlign: 'center',
                                color: allPicksMade ? '#00AA72' : '#CC293C',
                                marginTop: '16px'
                            }}
                        >
                            TOTAL PICKS {bracketStatus?.submittedPicks}/{bracketStatus?.totalPossiblePicks}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                                textAlign: 'center',
                                color: '#002129',
                                marginTop: '16px'
                            }}
                        >
                            Champion
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: (champion && isNaN(champion)) ? 'none' : '2px solid #002129',
                                borderRadius: '4px',
                                width: '50px',
                                height: '50px',
                                margin: '0 auto',
                                marginTop: '8px',
                                marginBottom: '16px'
                            }}
                        >
                            {champion === null ? null : 
                                isNaN(champion) ? (
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                            color: '#002129'
                                        }}
                                    >
                                        {champion}
                                    </Typography>
                                ) : (
                                    <img 
                                        src={getTeamLogo(league, champion, teams)} 
                                        alt="Champion" 
                                        style={{ width: '50px', height: '50px', objectFit: 'contain' }} 
                                    />
                                )
                            }

                        </Box>
                    </>
                )}

                {!success && (
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            textAlign: 'center',
                            color: '#002129',
                            marginTop: '16px',
                            marginBottom: '16px'
                        }}
                    >
                        {error}
                    </Typography>
                )}


                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button 
                        variant="contained"
                        sx={{
                            justifyContent: 'center',
                            height: '36px',
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                                backgroundColor: '#002129',
                            },
                        }}
                        onClick={() => window.location.reload()}
                        
                    >
                        {success ? allPicksMade ? "Best of Luck!" : "More To Go" : "Close"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default BracketSubmissionModal;