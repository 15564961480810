import React from "react";

const CFBResultsLogic = () => {

    const gameResults = [
        {"GlobalGameID": 50014613, "HomeScore": 31, "AwayScore": 17, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 50014598, "HomeScore": 33, "AwayScore": 39, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 50014868, "HomeScore": 34, "AwayScore": 31, "Status": "F/OT", "IsClosed": true},
        {"GlobalGameID": 18471, "HomeScore": 24, "AwayScore": 23, "Status": "Final", "IsClosed": true},
    ]
    
    const UserDB = {
        "user_id" : "ca019a56-50ec-473c-b830-dfbeb45e72b4",
        "first_name" : "Adam",
        "last_name" : "Braithwaite",
        "user_name" : "OchoCinco85",
        "phone" : "14124184554",
        "email" : "demo5@gmail.com",
        "password" : "$2b$10$rlC9CpSHpCKTxDmX6x9DaOWhUHFg5L2w4m7ZF8J6tUQYBvacCgNiS",
        "date_of_birth" : {
            "$date" : 397972800000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    "picks" : [
                        {
                            "gameId" : 18680,
                            "teamId" : 19,
                            "spread" : -3.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18679,
                            "teamId" : 13,
                            "spread" : -2,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18677,
                            "teamId" : 6,
                            "spread" : -4.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18675,
                            "teamId" : 28,
                            "spread" : 1,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18671,
                            "teamId" : 8,
                            "spread" : -1,
                            "dateTime" : "2024-09-08T16:25:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        }
                    ]
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "97kcw983-7y45-2c90-l2x1-57dr3pmg7031",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [
                        {
                            "gameId" : 18862,
                            "teamId" : 9,
                            "dateTime" : "2024-11-28T16:30:00",
                            "week" : 13,
                            "result" : "Win",
                            "isHomeSelected" : true
                        },
                        {
                            "gameId" : 18865,
                            "teamId" : 16,
                            "dateTime" : "2024-12-08T20:20:00",
                            "week" : 14,
                            "result" : "Win",
                            "isHomeSelected" : true
                        },
                        {
                            "gameId" : 18888,
                            "teamId" : 24,
                            "dateTime" : "2024-12-15T13:00:00",
                            "week" : 15,
                            "result" : "Loss",
                            "isHomeSelected" : false
                        }
                    ]
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "21pqx742-1r39-5y82-d3b0-92wk5izx1984",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [],
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            }
        ],
        "__v" : 0
    }
    
    const picks = [
        {
            "gameId" : 50014613,
            "teamId" : 50000276,
            "dateTime" : "2024-08-29T19:30:00",
            "week" : 1,
            "isHomeSelected" : true,
            "result" : "Pending",
            "conference" : "SEC"
        },
        {
            "gameId" : 50014598,
            "teamId" : 50000245,
            "dateTime" : "2024-08-31T19:30:00",
            "week" : 1,
            "isHomeSelected" : true,
            "result" : "Pending",
            "conference" : "Big Ten"
        },
        {
            "teamId" : "No Pick",
            "week" : 1,
            "result" : "Loss",
            "conference" : "ACC"
        },
        {
            "gameId" : 50014904,
            "teamId" : 50000004,
            "dateTime" : "2024-09-07T12:00:00",
            "week" : 2,
            "isHomeSelected" : true,
            "result" : "Pending",
            "conference" : "Big 12"
        },
        {
            "gameId" : 50014618,
            "teamId" : 50000104,
            "dateTime" : "2024-09-07T12:00:00",
            "week" : 2,
            "isHomeSelected" : true,
            "result" : "Pending",
            "conference" : "Big Ten"
        },
        {
            "gameId" : 50014641,
            "teamId" : 50000271,
            "dateTime" : "2024-09-07T19:00:00",
            "week" : 2,
            "isHomeSelected" : true,
            "result" : "Pending",
            "conference" : "SEC"
        }
    ];
    
    const updateCFBSurvivorPicksWithResults = (user, gameResults) => {

        const picks = user.Games[2].pool.picks || [];
        // Sort picks by week
        const sortedPicks = picks.sort((a, b) => a.week - b.week);
    
        // Track conferences where a loss has been detected
        const lostConferences = new Set();
    
        const updatedPicks = sortedPicks.map(pick => {
            // If a loss was detected in this conference, skip future picks in the same conference
            if (lostConferences.has(pick.conference)) {
                return null; // Mark for removal
            }
    
            const game = gameResults.find(game => game.GlobalGameID === pick.gameId);
    
            if (game && pick.result === 'Pending') {
                const { HomeScore, AwayScore, Status } = game;
                let updatedResult = 'Pending';
                let margin = 0;
    
                // Check for special game statuses
                if (Status === 'Cancelled' || Status === 'Postponed' || Status === 'Suspended' || Status === 'Forfeit') {
                    updatedResult = 'Win';
                    return { ...pick, result: updatedResult };
                }
    
                // Determine result based on selected team and score
                if (pick.isHomeSelected) {
                    if (HomeScore > AwayScore) {
                        updatedResult = 'Win';
                        margin = HomeScore - AwayScore;
                    } else {
                        updatedResult = 'Loss';
                        margin = HomeScore - AwayScore;
                        lostConferences.add(pick.conference); // Stop further processing for this conference
                    }
                } else {
                    if (AwayScore > HomeScore) {
                        updatedResult = 'Win';
                        margin = AwayScore - HomeScore;
                    } else {
                        updatedResult = 'Loss';
                        margin = AwayScore - HomeScore;
                        lostConferences.add(pick.conference); // Stop further processing for this conference
                    }
                }
    
                return { ...pick, result: updatedResult, margin };
            }
    
            return pick;
        }).filter(pick => pick !== null); // Remove future weeks in the same conference
    
        return updatedPicks;
    };
    
    
    
    const updatedPicks = updateCFBSurvivorPicksWithResults(UserDB, gameResults);
    console.log("CFB Survivor Results", updatedPicks);

    function addCFBSurvivorNoPick(currentWeek, user) {
        const noPickTemplate = {
            teamId: "No Pick",
            week: currentWeek,
            result: "Loss"
        };
    
        // Define the list of conferences you need to handle
        const conferences = ["ACC", "Big 12", "Big Ten", "SEC"];
    
        let userPicks = user.Games[2].pool.picks || [];
    
        // If the user hasn't made any picks yet
        if (userPicks.length === 0) {
            // Create a "No Pick" for each conference
            userPicks = conferences.map(conference => {
                return { ...noPickTemplate, conference };
            });
            return userPicks;
        }
    
        // Track which conferences already have a loss
        const conferencesWithLoss = new Set(
            userPicks?.filter(pick => pick.result === 'Loss').map(pick => pick.conference)
        );
    
        // Group picks by conference
        const picksByConference = userPicks.reduce((acc, pick) => {
            if (!acc[pick.conference]) {
                acc[pick.conference] = [];
            }
            acc[pick.conference].push(pick);
            return acc;
        }, {});
    
        // Process each conference separately
        let updatedPicks = [...userPicks];
        Object.keys(picksByConference).forEach(conference => {
            if (conferencesWithLoss.has(conference)) {
                // Skip this conference if there's already a loss
                return;
            }
    
            const currentConferencePicks = picksByConference[conference];
            const currentWeeksPick = currentConferencePicks?.filter(pick => pick.week === currentWeek);
    
            if (currentWeeksPick.length === 0) {
                // Add "No Pick" for this conference
                const noPick = { ...noPickTemplate, conference };
                updatedPicks.push(noPick);
    
                // Remove future picks for this conference
                updatedPicks = updatedPicks.filter(pick => pick.conference !== conference || pick.week <= currentWeek);
            }
        });
    
        return updatedPicks;
    }
    
    

    const addingNoPickSurvivor = addCFBSurvivorNoPick(1, UserDB);
    console.log("Adding CFB Survivor NO PICK", addingNoPickSurvivor);
    


    return (
        <div>
            <h1>Games With No Spreads</h1>
        </div>
    );
}

export default CFBResultsLogic;