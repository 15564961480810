import React, { useEffect, useState } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import { v4 as uuidv4 } from "uuid";
import { set } from "lodash";

const AddResultsModal = ({ isOpen, onClose, setPropsToAdd, propsToAdd, selectedProp, setSelectedProp, selectedPropType, setSelectedPropType }) => {
    const { pageState, setPageState, leagueState, setLeagueState } = useAdminDashContext();

    // State to track the selected prop type
    
    const [correctOption, setCorrectOption] = useState("");
    const [actualTotal, setActualTotal] = useState("null");

    useEffect(() => {
        console.log("Setting Correct Option and Actual Total");
        if (selectedProp?.correctOption) {
            setCorrectOption(selectedProp.correctOption);
        } else {
            setCorrectOption(""); // Reset to empty string if not defined
        }
        if (selectedProp?.actualTotal) {
            setActualTotal(selectedProp.actualTotal);
        } else {
            setActualTotal(""); // Reset to empty string if not defined
        }
    }, [selectedProp]);

    // Function to reset the form fields
    const resetForm = () => {
        setCorrectOption("");
        setActualTotal("");
    };


    const handleAddProp = () => {
       
        if (correctOption) {
            selectedProp.correctOption = selectedPropType === 'tiebreakers' ? Number(correctOption) : correctOption;
        }

        if (actualTotal) {
            selectedProp.actualTotal = actualTotal;
        }

        
        setPropsToAdd((prevProps) =>
            prevProps.map((prop) =>
                prop.propType === selectedPropType // Match using selectedPropType
                    ? { ...prop, createdProps: [...prop.createdProps, selectedProp] }
                    : prop
            )
        );

        console.log("New Prop Added:", selectedProp);
        resetForm(); // Clear the form after adding the prop
        setSelectedProp(null); // Reset selectedProp
        setSelectedPropType(null); // Reset selectedPropType
        onClose();
    };

    // Reset form on cancel
    const handleClose = () => {
        resetForm(); // Clear the form on close as well
        onClose();
    };
    

    if (!isOpen) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
               
            <div>
                {selectedProp?.tiebreaker && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>TIEBREAKER</label>
                    </div>
                )}
                {selectedProp?.propType && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Prop Type:</label>
                        <span>{selectedProp.propType}</span>
                    </div>
                )}

                {selectedProp?.propChoice && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Prop Choice:</label>
                        <span>{selectedProp.propChoice}</span>
                    </div>
                )}

                {selectedProp?.tableHeader && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Table Header:</label>
                        <span>{selectedProp.tableHeader}</span>
                    </div>
                )}

                {selectedProp?.propText && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Prop Text:</label>
                        <span>{selectedProp.propText}</span>
                    </div>
                )}

                {selectedProp?.player && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Player:</label>
                        <span>{selectedProp.player}</span>
                    </div>
                )}

                {selectedProp?.team && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Team:</label>
                        <span>{selectedProp.team}</span>
                    </div>
                )}

                {/* Render the dynamic text fields for options */}
                {selectedProp?.options?.map((option, index) => (
                    <div style={styles.optionContainer} key={index}>
                        {option && (
                            <div style={styles.fieldContainer}>
                                <label style={styles.label}>{`Option ${index + 1}:`}</label>
                                <span>{option}</span>
                            </div>
                        )}
                    </div>
                ))}


                {selectedProp?.line && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Line:</label>
                        <span>{selectedProp.line}</span>
                    </div>
                )}

                {selectedProp?.points !== undefined && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Points:</label>
                        <span>{selectedProp.points}</span>
                    </div>
                )}

                {selectedPropType !== "tiebreakers" && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Correct Option:</label>
                        <select
                            value={correctOption || ""}
                            onChange={(e) => setCorrectOption(e.target.value)}
                            style={styles.input}
                        >
                            {/* Render an empty option if correctOption is null */}
                            <option value="" disabled={!correctOption}>Select an option</option>
                            {selectedProp?.options?.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                            {/* Add "Void" as the last option */}
                            <option value="Void">Void</option>
                        </select>
                    </div>
                )}


                {selectedPropType === "tiebreakers" && (
                    <div style={styles.fieldContainer}>
                        <label style={styles.label}>Correct Option:</label>
                        <input
                            type="number"
                            value={correctOption}
                            onChange={(e) => setCorrectOption(e.target.value)}
                            style={styles.input}
                        />
                    </div>
                )}


                <div style={styles.fieldContainer}>
                    <label style={styles.label}>Actual Total:</label>
                    <input
                        type="text"
                        value={actualTotal}
                        onChange={(e) => setActualTotal(e.target.value)}
                        style={styles.input}
                    />
                </div>
            </div>


                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button 
                        onClick={handleClose} 
                        style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", marginRight: '35px' }}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={handleAddProp} 
                        style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }}
                    >
                        Add Prop
                    </button>
                </div>

                {/* Additional modal content or action buttons */}
            </div>
        </div>
    );
};


const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10000,
    },
    modal: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "90%",
        maxWidth: "800px",
        position: "relative",
        maxHeight: "90%",
        overflowY: "auto",
    },
    optionContainer: {
        marginBottom: '10px', // Space between different option groups
    },
    fieldContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "5px" // 5px space between each line
    },
    label: {
        width: "100px", // Adjust width as needed
        marginRight: "8px",
    },
    input: {
        padding: "8px",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
    },
};

export default AddResultsModal;
