import React from "react";
import useNavigateToTop from "../hooks/general/useNavigateToTop";
import QuickPickNFLContainer from "../components/QuickPickGames/QuickPickNFL/QuickPickNFLContainer";
import QuickPickNFLProvider from "../components/QuickPickGames/QuickPickNFL/context/QuickPickNFLContext";
import QuickPickCFBContainer from "../components/QuickPickGames/QuickPickCFB/QuickPickCFBContainer";
import QuickPickCFBProvider from "../components/QuickPickGames/QuickPickCFB/context/QuickPickCFBContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const QuickPickGamesPage = ({ sport }) => {
    const queryClient = new QueryClient();
    const navigateToTop = useNavigateToTop();

    // Function to conditionally render the appropriate provider and container
    const renderQuickPickContainer = () => {
        switch (sport) {
            case "nfl":
                return (
                    <QuickPickNFLProvider>
                        <QuickPickNFLContainer />
                    </QuickPickNFLProvider>
                );
            case "cfb": // College Football
                return (
                    <QuickPickCFBProvider>
                        <QuickPickCFBContainer />
                    </QuickPickCFBProvider>
                );
            default:
                navigateToTop('/404/pool-hall'); // Navigate to custom 404 page if sport is not supported
                return null; // Return null since we're navigating away
        }
    };

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                {renderQuickPickContainer()}
            </QueryClientProvider>
        </div>
    );
};

export default QuickPickGamesPage;
