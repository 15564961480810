import React, { useRef, useEffect } from 'react';
import { Box, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PinCodeComponent = ({
    usePinCode,
    setUsePinCode,
    pinCode,
    setPinCode,
    order,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    // Create an array of refs for the pin input fields
    const inputRefs = useRef([]);
    
    // Initialize the refs array
    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 4);
    }, []);
    
    // Handle checkbox change for Yes
    const handleYesCheckboxChange = (event) => {
        if (event.target.checked) {
            setUsePinCode(true);
        } else {
            setUsePinCode(null);
        }
    };
    
    // Handle checkbox change for No
    const handleNoCheckboxChange = (event) => {
        if (event.target.checked) {
            setUsePinCode(false);
        } else {
            setUsePinCode(null);
        }
    };
    
    // Handle pin input change
    const handlePinChange = (input, index) => {
        const value = input.value;
        // Only allow numbers
        if (/^\d*$/.test(value)) {
            // Create an array from the current pinCode string
            const pinArray = pinCode ? pinCode.split('') : ['', '', '', ''];
            
            // Update the digit at the specified index
            pinArray[index] = value;
            
            // Update the main pinCode state
            setPinCode(pinArray.join(''));
            
            // Move to next field if this one is filled
            if (value && index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };
    
    // Handle keyboard navigation between fields
    const handleKeyDown = (e, index) => {
        const pinArray = pinCode ? pinCode.split('') : [];
        
        // Move to previous field on backspace if current field is empty
        if (e.key === 'Backspace' && !pinArray[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
        // Move to next field on right arrow if at the end of input
        else if (e.key === 'ArrowRight' && 
                 inputRefs.current[index].selectionStart === inputRefs.current[index].value.length && 
                 index < 3) {
            inputRefs.current[index + 1].focus();
        }
        // Move to previous field on left arrow if at the beginning of input
        else if (e.key === 'ArrowLeft' && 
                 inputRefs.current[index].selectionStart === 0 && 
                 index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Prepare the pin array for rendering
    const getPinValue = (index) => {
        if (!pinCode) return '';
        return pinCode.charAt(index) || '';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
                marginTop: '16px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '15px' : '20px',
                    fontWeight: '700',
                    marginBottom: '10px',
                    textAlign: 'left',
                }}
            >
                {order}. Would you like to add a Pin Code Protection for pool entry?
            </Typography>
            
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 4 
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={usePinCode === true}
                            onChange={handleYesCheckboxChange}
                            name="usePinCodeYes"
                            color="primary"
                        />
                    }
                    label="Yes"
                />
                
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={usePinCode === false}
                            onChange={handleNoCheckboxChange}
                            name="usePinCodeNo"
                            color="primary"
                        />
                    }
                    label="No"
                />
            </Box>
            
            {usePinCode === true && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '500px',
                        margin: '10px auto',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '15px' : '20px',
                            fontWeight: '700',
                            marginBottom: '10px',
                            textAlign: 'center',
                        }}
                    >
                        Create Numeric Pin Code
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                        {[0, 1, 2, 3].map((index) => (
                            <TextField
                                key={index}
                                value={getPinValue(index)}
                                onChange={(e) => handlePinChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputProps={{ 
                                    maxLength: 1, 
                                    style: { textAlign: 'center' } 
                                }}
                                sx={{ width: '50px' }}
                                inputRef={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default PinCodeComponent;