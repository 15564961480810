// Helper function to resolve nav item name
const resolveNavName = (nameConfig, gameData) => {
    //console.log("resolveNavName called with:", { nameConfig, gameData });
    if (!nameConfig) return 'Unknown';
    
    if (typeof nameConfig === 'string') {
        return nameConfig;
    }
    
    if (nameConfig.property === 'sponsorName' || nameConfig.property === 'sponsorPageContent') {
        // console.log("Sponsor info:", gameData?.sponsorInfo);
        return gameData?.sponsorInfo?.sponsorAbbreviation || 'Sponsor';
    }
    
    return 'Unknown';
};

// Rest of your code remains the same
const evaluateCondition = (condition, context) => {
    if (!condition || !condition.check || !context) return false;
    
    switch (condition.check) {
        case 'isUserInThisPool':
            return Boolean(context.isUserInThisPool);
        case 'isUserAdmin':
            //console.log("Is User Admin in evaluateCondition: ", context.isUserAdmin);
            return Boolean(context.isUserAdmin);
        case 'isThisPoolPartyGame':
            return Boolean(context.isThisPoolPartyGame);
        case 'isNotPoolPartyGame':
            //console.log("Is Not Pool Party Game in evaluateCondition: ", !Boolean(context.isThisPoolPartyGame));
            return !Boolean(context.isThisPoolPartyGame);
        case 'sponsorAbbreviation':
            return Boolean(context.gameData?.sponsorInfo?.sponsorAbbreviation);
        default:
            console.warn(`Unknown condition check: ${condition.check}`);
            return false;
    }
};

const evaluateConditionGroup = (visibilityConfig, context) => {
    if (!visibilityConfig || !Array.isArray(visibilityConfig.conditions)) {
        return true;
    }
    
    const conditions = visibilityConfig.conditions.map(condition => {
        if (condition.type) {
            return evaluateConditionGroup(condition, context);
        }
        return evaluateCondition(condition, context);
    });
    
    switch (visibilityConfig.type) {
        case 'AND':
            return conditions.every(Boolean);
        case 'OR':
            return conditions.some(Boolean);
        default:
            return true;
    }
};

const ProcessNavBar = (navBarInfo, userContext) => {
    //console.log("ProcessNavBar called with:", { navBarInfo, userContext });
    
    if (!navBarInfo || !userContext) {
        console.warn('Missing navBarInfo or userContext');
        return null;
    }
    
    const context = {
        gameData: userContext.gameData || {},
        isUserInThisPool: Boolean(userContext.isUserInThisPool),
        isUserAdmin: Boolean(userContext.isUserAdmin),
        isThisPoolPartyGame: Boolean(userContext.isThisPoolPartyGame)
    };
    
    const processedNav = {
        style: {
            backgroundColor: navBarInfo.navBarBackgroundColor || '#07356B',
            textColor: navBarInfo.navBarTextColor || '#fff',
            activeTextColor: navBarInfo.navBarActiveTextColor || '#FFC60A',
            activeBackgroundColor: navBarInfo.navBarActiveBackgroundColor || '#07356B',
            borderColor: navBarInfo.navBarBorderColor || '#FFC60A'
        },
        items: []
    };
    
    if (Array.isArray(navBarInfo.navBarOptions)) {
        navBarInfo.navBarOptions.forEach(option => {
            if (option && evaluateConditionGroup(option.visibility, context)) {
                processedNav.items.push({
                    name: resolveNavName(option.name, context.gameData),
                    page: option.page || ''
                });
            }
        });
    }
    
    return processedNav;
};

export default ProcessNavBar;