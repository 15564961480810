import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const BracketGameInfo = ({ gameData, myPoolsData}) => {
    //console.log("Game Data in BracketGameInfo: ", gameData, "My Pools Data in BracketGameInfo: ", myPoolsData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const hasDeadlinePassed = myPoolsData?.hasDeadlinePassed;
    //console.log("Has Deadline Passed: ", hasDeadlinePassed);
    const pointsStructure = myPoolsData?.points || [];

    const allPicksMade = (pool) => {
        //console.log("Pool: ", pool, "Picks Length: ", pool?.picks?.length, "Picks To Make: ", myPoolsData?.picksToMake);
        return pool?.picks?.length === myPoolsData?.picksToMake && pool?.tiebreaker !== null;
    };

    // Function to count points from winning picks
    const countMyPoints = (picks) => {
        // Return 0 if picks is undefined or not an array
        if (!picks || !Array.isArray(picks)) return 0;
        
        // Filter to only winning picks
        const winningPicks = picks.filter(pick => pick?.result === 'win');
        
        // Calculate total points
        return winningPicks.reduce((totalPoints, pick) => {
            // Find the points for the pick's round
            const roundPoints = pointsStructure.find(
                pointItem => pointItem.round === pick.round
            );
            
            // Add the points for this round, or 0 if round not found
            return totalPoints + (roundPoints ? roundPoints.points : 0);
        }, 0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '8px',
            }}
        >
            {gameData?.entries?.map((entry, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '8px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '700',
                            color: '#002129',
                            textAlign: 'center'
                        }}
                    >
                        Entry ({index + 1})
                    </Typography>
                    {!hasDeadlinePassed ? (
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center' 
                        }}>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '14px',
                                    fontWeight: '700',
                                    color: '#002129',
                                    textAlign: 'center',
                                }}
                            >
                                Picks In?
                            </Typography>
                            {allPicksMade(entry?.pool) ? (
                                <CheckIcon sx={{ color: '#00AA72' }} />
                            ) : (
                                <CloseIcon sx={{ color: '#CC293C' }} />
                            )}
                        </Box>
                    ) : (
                        <Typography
                            sx={{
                                fontSize: isMobile ? '12px' : '14px',
                                fontWeight: '700',
                                color: '#002129',
                                textAlign: 'center',
                            }}
                        >
                            Pts: {countMyPoints(entry?.pool?.picks)}
                        </Typography>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default BracketGameInfo;