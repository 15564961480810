import React, { useEffect, useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import './QuickPickRewards.scss';
import { useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import RewardsCard from "./RewardsCard";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const QuickPickRewards = ({ rewards, mainGameId, gameAddress, sponsorHeaderLogo, sponsorName, intervalType, schedule, myUserId }) => {
    const navigate = useNavigate();
    const { component, pageState } = useParams();
    const [selectedTab, setSelectedTab] = useState(pageState); // Default selected tab
    const [selectedRewards, setSelectedRewards] = useState([]);
    const [noRewardsText, setNoRewardsText] = useState("");
    const [eligibleRewards, setEligibleRewards] = useState([]);
    const [redeemedRewards, setRedeemedRewards] = useState([]);
    const [expiredRewards, setExpiredRewards] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New loading state

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleTabChange = (tab) => {
        setIsLoading(true); // Set loading to true on tab change
        setSelectedTab(tab);
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(component)}/${uriEncoded(tab)}`);
    };

    const noEligibleRewardsText = "You have no eligible rewards.";
    const noRedeemedRewardsText = "You have not redeemed a reward yet.";
    const noExpiredRewardsText = "You have no expired rewards.";

    useEffect(() => {
        const updateRewards = () => {
            const timeNowInNewYork = DateTime.now().setZone('America/New_York');
            let eligible = [];
            let redeemed = [];
            let expired = [];

            for (const reward of rewards) {
                const expirationDate = DateTime.fromISO(reward.expiration).setZone('America/New_York');
                if (expirationDate > timeNowInNewYork && !reward.redeemed) {
                    eligible.push(reward);
                } else if (reward.redeemed) {
                    redeemed.push(reward);
                } else {
                    expired.push(reward);
                }
            }

            setEligibleRewards(eligible);
            setRedeemedRewards(redeemed);
            setExpiredRewards(expired);

            // Set the selected rewards and no rewards text based on the selected tab
            if (selectedTab === 'ELIGIBLE') {
                setSelectedRewards(eligible);
                setNoRewardsText(noEligibleRewardsText);
            } else if (selectedTab === 'REDEEMED') {
                setSelectedRewards(redeemed);
                setNoRewardsText(noRedeemedRewardsText);
            } else if (selectedTab === 'EXPIRED') {
                setSelectedRewards(expired);
                setNoRewardsText(noExpiredRewardsText);
            }

            setIsLoading(false); // Set loading to false after updating rewards
        };

        // Adding a slight delay to ensure the state update fully completes before rendering
        const timeout = setTimeout(updateRewards, 200);

        return () => clearTimeout(timeout); // Clear timeout on cleanup to avoid memory leaks
    }, [rewards, selectedTab]);

    return (
        <>
            <Box className="quick-pick-rewards-container">
                <Button
                    className={`quick-pick-rewards-button ${selectedTab === 'ELIGIBLE' ? 'active' : ''}`}
                    onClick={() => handleTabChange('ELIGIBLE')}
                >
                    Eligible ({eligibleRewards.length})
                </Button>
                <Button
                    className={`quick-pick-rewards-button ${selectedTab === 'REDEEMED' ? 'active' : ''}`}
                    onClick={() => handleTabChange('REDEEMED')}
                >
                    Redeemed ({redeemedRewards.length})
                </Button>
                <Button
                    className={`quick-pick-rewards-button ${selectedTab === 'EXPIRED' ? 'active' : ''}`}
                    onClick={() => handleTabChange('EXPIRED')}
                >
                    Expired ({expiredRewards.length})
                </Button>
            </Box>

            <Box className="quick-pick-rewards-logo-container">
                <img src={sponsorHeaderLogo} alt="Sponsor Logo" className="quick-pick-rewards-sponsor-logo" />
                <Typography className="quick-pick-rewards-text">
                    Rewards
                </Typography>
            </Box>

            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
                   
                </Box>
            ) : selectedRewards.length === 0 ? (
                <>
                    <Typography
                        className="quick-pick-rewards-no-rewards-text"
                        sx={{ marginTop: '32px' }}
                    >
                        {noRewardsText}
                    </Typography>
                    <Typography
                        className="quick-pick-rewards-no-rewards-text"
                        sx={{ marginTop: '16px' }}
                    >
                        Pick to Win!
                    </Typography>
                </>
            ) : (
                <Box className="quick-pick-rewards-cards-container">
                    {selectedRewards.map((reward, index) => (
                        <RewardsCard
                            key={index}
                            reward={reward}
                            selectedTab={selectedTab}
                            intervalType={intervalType}
                            schedule={schedule}
                            sponsorName={sponsorName}
                            mainGameId={mainGameId}
                            myUserId={myUserId}
                        />
                    ))}
                </Box>
            )}
            <Box sx={{ height: '64px' }} />
        </>
    );
};

export default QuickPickRewards;
