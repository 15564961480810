import * as NCAAMSurvivorCardFormat from './NCAAMSurvivorCardFormat';

export const getSurvivorUtils = (league) => {
    switch (league) {
        case 'NCAAM':
            return NCAAMSurvivorCardFormat;
        default:
            return NCAAMSurvivorCardFormat;
    }
}

