import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

const getPoolDeadlines = async ({ league }) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
    
    // Pass the league as a query parameter
    const response = await axios.get(`${apiUrl}/sports-data/get-pick-deadlines`, {
        headers: {
            Authorization: authorizationHeader,  // Include the authorization header in the request
            'Content-Type': 'application/json'
        },
        params: { league }  // Send league as a query parameter
    });

    //console.log('RESPONSE FROM LB', response.data);
    return response.data;
};


export const useGetPoolDeadlines = () => {
    return useMutation({
        mutationFn: getPoolDeadlines,
        onError: (error) => {
            console.error('Error fetching game deadlines:', error);
        },
        // onSuccess: (data) => {
        //    console.log('Fetched game deadlines:', data);
        // }
    });
};
