import React from "react";
import { Box, Typography } from "@mui/material";
import '../BetReceipt.scss';

const BetReceiptParlayTeaserHeader = ({ betReceiptInfo }) => {
    const { saveSuccess, picksToSave, gamesThatChanged, gamesThatStarted, gamesUnavailable, groupBetOdds, groupBetPayout, betStyle } = betReceiptInfo;
   
    const anyGamesNotPlaced = gamesUnavailable?.length > 0 || gamesThatChanged?.length > 0 || gamesThatStarted?.length > 0;
    const howManyTotalPicks = picksToSave?.length + gamesUnavailable?.length + gamesThatChanged?.length + gamesThatStarted?.length;
    const teaserPoints = picksToSave?.map(pick => pick.teaserPoints);
    const uniqueTeaserPoints = [...new Set(teaserPoints)];
    //console.log("uniqueTeaserPoints: ", uniqueTeaserPoints);
    //console.log("Group Bet Odds: ", groupBetOdds);

    // Format teaser points with '-Point' suffix
    const formattedTeaserPoints = uniqueTeaserPoints?.map(point => `${point}-Point`).join(', ');

    // Capitalize the first letter of betStyle
    const capitalizeFirstLetter = (str) => str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
    const formattedBetStyle = capitalizeFirstLetter(betStyle);

    return (
        <Box className="sportsbook-bet-receipt-parlay-teaser-header">
            {/* Left Side: Parlay or Teaser Info */}
            <Box className="sportsbook-bet-receipt-left-info">
                <Typography className="sportsbook-bet-receipt-text" sx={{ color: anyGamesNotPlaced ? '#CC293C' : '' }}>
                    {howManyTotalPicks} Leg {betStyle === 'teaser' ? formattedTeaserPoints : ''} {formattedBetStyle}
                </Typography>          
            </Box>

            {/* Right Side: Group Bet Odds*/}
            <Box className="sportsbook-bet-receipt-right-info">
                <Typography className="sportsbook-bet-receipt-text">
                    {groupBetOdds > 0 ? `+${groupBetOdds}` : groupBetOdds}
                </Typography>
            </Box>
        </Box>
    );
};

export default BetReceiptParlayTeaserHeader;