// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportsbook-league-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
}

.sportsbook-league-button {
  border-radius: 50px;
  padding: 10px 20px;
  width: 150px;
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SportsbookComponents/Headers/SportsbookSportSelector.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AACJ;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;AACJ","sourcesContent":[".sportsbook-league-selector-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;  // Equivalent to gap={2} in MUI\n    padding: 16px;  // Equivalent to padding={2} in MUI\n}\n\n.sportsbook-league-button {\n    border-radius: 50px;  // Pill shape\n    padding: 10px 20px;  // Larger padding for the pill look\n    width: 150px;        // Adjust the width of the button\n    text-transform: none; // Keep the text casing as provided\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
