import React from "react";
import { Button, Modal, Typography, Box } from '@mui/material';

export const ModalConfig = {
  confirmAdd: {
    title: (userInfo, gameName) => `Add ${userInfo?.username} as a Co-Host?`,
    action: "ADD",
    handler: "handleAddCommissioner"
  },
  confirmRemoveCommissioner: {
    title: (userInfo, gameName) => `Remove ${userInfo?.username} as a Co-Host?`,
    action: "REMOVE",
    handler: "handleRemoveCommissioner"
  },
  confirmRemoveUser: {
    title: (userInfo, gameName, myUserId) => 
      userInfo?.userId === myUserId 
        ? `Do you want to leave ${gameName}?`
        : `Remove ${userInfo?.username} from ${gameName}?`,
    action: (userInfo, myUserId) => userInfo?.userId === myUserId ? "LEAVE" : "REMOVE",
    handler: "handleRemoveUser"
  },
  confirmLeave: {
    title: (userInfo, gameName) => `Do you want to leave ${gameName}?`,
    action: "LEAVE",
    handler: "handleRemoveUser"
  },
  confirmDelete: {
    title: (userInfo, gameName) => `Are you sure you want to delete ${gameName}?\nThis CANNOT be undone.`,
    action: "DELETE",
    handler: "handleDeleteGame"
  },
  confirmReinstateUser: {
    title: (userInfo, gameName) => `Do you want to reinstate ${userInfo?.username}?`,
    action: "REINSTATE",
    handler: "handleReInstateUser"
  }
};

const DynamicModal = ({ 
  open, 
  onClose, 
  modalState, 
  modalUserInfo, 
  gameName, 
  myUserId, 
  handlers 
}) => {
  if (!modalState || !ModalConfig[modalState]) return null;

  const config = ModalConfig[modalState];
  const title = config.title(modalUserInfo, gameName, myUserId);
  const action = typeof config.action === 'function' 
    ? config.action(modalUserInfo, myUserId) 
    : config.action;
  const handler = handlers[config.handler];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="dynamic-modal-title"
    >
      <Box className="modal-container" sx={{ 
        height: 'auto', 
        maxHeight: '350px', 
        maxWidth: '350px', 
        bgcolor: 'background.paper', 
        p: 4 
      }}>
        <Typography variant="h6" component="div" sx={{ whiteSpace: 'pre-line' }}>
          {title}
        </Typography>
        <Box className="button-container">
          <Button
            variant="contained"
            sx={{
              bgcolor: 'white',
              color: '#002129',
              border: '1px solid #002129',
              '&:hover': {
                bgcolor: 'white',
                color: '#002129',
                border: '1px solid #002129',
              },
            }}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handler(modalUserInfo);
              onClose();
            }}
            sx={{
              color: 'white',
              bgcolor: '#002129',
              '&:hover': {
                bgcolor: '#002129',
              },
            }}
          >
            {action}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DynamicModal;