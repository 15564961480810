import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const PickEmSubmitPicksModal = ({ modalOpen, setModalOpen, updatedPickEmState, week, userPicks, lineChanges, startedGames, NFLTeams, NFLScoresBySeason }) => { 

    function nflKey (teamId) {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
    }

    function nflOpponentInfo (gameId, teamId)  {
        const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? nflKey(game?.GlobalAwayTeamID) : nflKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        const displayString = `${leadingSymbol} ${opponentKey}`;
        return displayString;
    }

    return(
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >
            <div className="modal-container" style={{ height: 'auto', width: '308px' }}>
                {updatedPickEmState?.length > 0 && (
                    <>
                        <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '25px' }}>
                            {updatedPickEmState?.length} Pick{updatedPickEmState?.length > 1 ? 's' : ''} Submitted
                        </Typography>
                        {updatedPickEmState?.map((pick, index) => (
                            <Typography variant="h1" style={{ fontSize: '16px' }} key={index}>
                                <span style={{ fontWeight: 800 }}>
                                    {nflKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                                </span>
                                {' '}
                                {nflOpponentInfo(pick.gameId, pick.teamId)}
                            </Typography>
                        ))}
                <CheckIcon style={{ width: '85px', height: '45px', fill: '#00AA72', margin: '0 auto', marginTop: '20px', marginBottom: '20px' }} />    
                </>
                )}
                {updatedPickEmState?.length > 0 && (lineChanges?.length > 0 || startedGames?.length > 0) && (
                <div style={{ width: '206px', height: '2px', backgroundColor: '#000000', marginBottom: '20px', }}>
                 {/* No text content here */}
                </div>
                )}
                {(startedGames?.length > 0 || lineChanges?.length > 0) && (
                <Typography variant="h1" style={{ fontSize: '16px', fontWeight: '800', marginBottom: '25px'}}> {(startedGames?.length + lineChanges?.length)} Pick{(startedGames?.length + lineChanges?.length) > 1 ? 's' : '' } Not Submitted </Typography>
                )}
                {startedGames?.length > 0 && (
                    <Typography variant="h1" style={{ fontSize: '16px', textDecoration: 'underline' }}>
                      Game{startedGames?.length > 1 ? 's' : ''} Started
                    </Typography>
                )}    
                {startedGames?.map((pick, index) => (
                    <Typography variant="h1" style={{ fontSize: '16px', marginBottom: '20px' }} key={index}>
                        {nflKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                    {' '}
                    {nflOpponentInfo(pick.gameId, pick.teamId)}
                </Typography> 
                ))}
                {lineChanges?.length > 0 && (
                    <Typography variant="h1" style={{ fontSize: '16px', textDecoration: 'underline' }}>
                      Spread Change{lineChanges?.length > 1 ? 's' : ''}
                    </Typography>
                )}
                {lineChanges?.map((pick, index) => (
                    <Typography variant="h1" style={{ fontSize: '16px', marginBottom: '20px' }} key={index}>
                        {nflKey(pick.teamId)} ({pick.spread > 0 ? `+${pick.spread}` : pick.spread})
                    {' '}
                    {nflOpponentInfo(pick.gameId, pick.teamId)}
                </Typography>
                ))}
                {(startedGames?.length > 0 || lineChanges?.length > 0) && (
                    <CloseIcon style={{ width: '44px', height: '44px', fill: '#CC293C', margin: '0 auto', marginTop: '3px', marginBottom: '20px' }} />
                )}
                <Typography variant="h1" style={{ fontSize: '16px' }}>Week {week}</Typography>
                <Typography variant="h1" style={{ fontSize: '16px' }}>
                    <span style={{ fontWeight: '700', color: (userPicks?.length + updatedPickEmState?.length) === 5 ? '#00AA72' : '#CC293C' }}>
                        {(userPicks?.length + updatedPickEmState?.length)}/5&nbsp;
                    </span> 
                    Picks Submitted
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button sx={{ width: '115px'}} variant="contained" onClick={() => window.location.href=window.location.href}>OK</Button>
                </div>
            </div>
        </Modal>
    )
}

export default PickEmSubmitPicksModal;