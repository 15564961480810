import React from "react";

const NFLSurvivorResultLogic = () => {

    const gameResults = [
        {"GlobalGameID": 18672, "HomeScore": 31, "AwayScore": 17, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18669, "HomeScore": 33, "AwayScore": 39, "Status": "Final", "IsClosed": true},
        {"GlobalGameID": 18671, "HomeScore": 24, "AwayScore": 24, "Status": "Cancelled", "IsClosed": true},
        {"GlobalGameID": 18471, "HomeScore": 24, "AwayScore": 23, "Status": "Final", "IsClosed": true},
    ]
    
    const UserDB = {
        "user_id" : "ca019a56-50ec-473c-b830-dfbeb45e72b4",
        "first_name" : "Adam",
        "last_name" : "Braithwaite",
        "user_name" : "OchoCinco85",
        "phone" : "14124184554",
        "email" : "demo5@gmail.com",
        "password" : "$2b$10$rlC9CpSHpCKTxDmX6x9DaOWhUHFg5L2w4m7ZF8J6tUQYBvacCgNiS",
        "date_of_birth" : {
            "$date" : 397972800000
        },
        "marketing_preferences" : {
            "enable_pool_announcements" : true,
            "enable_pool_reminders" : true,
            "enable_pool_updates" : true
        },
        "usersAgreementsValid" : true,
        "Settings" : {
            "Verification" : {
                "userVerified" : false
            },
            "BirdiePool" : [
                {
                }
            ]
        },
        "gamePicks" : [
            {
            }
        ],
        "MulligenMadness" : [
            {
            }
        ],
        "Verification" : {
            "userVerified" : false
        },
        "ifGoogleUser" : {
            "isGoogleUser" : false
        },
        "Games" : [
            {
                "gameID" : "39ade231-4d99-4y78-a3l3-83eq6kne8475",
                "pool" : {
                    "stopPicksWarning" : false,
                    "picks" : [
                        {
                            "gameId" : 18680,
                            "teamId" : 19,
                            "spread" : -3.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18679,
                            "teamId" : 13,
                            "spread" : -2,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18677,
                            "teamId" : 6,
                            "spread" : -4.5,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Win"
                        },
                        {
                            "gameId" : 18675,
                            "teamId" : 28,
                            "spread" : 1,
                            "dateTime" : "2024-09-08T13:00:00",
                            "week" : 1,
                            "isHomeSelected" : false,
                            "result" : "Loss"
                        },
                        {
                            "gameId" : 18671,
                            "teamId" : 8,
                            "spread" : -1,
                            "dateTime" : "2024-09-08T16:25:00",
                            "week" : 1,
                            "isHomeSelected" : true,
                            "result" : "Loss"
                        }
                    ]
                },
                "poolType" : "Pickem",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "97kcw983-7y45-2c90-l2x1-57dr3pmg7031",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            },
            {
                "gameID" : "21pqx742-1r39-5y82-d3b0-92wk5izx1984",
                "pool" : {
                    "stopPicksWarning" : [
                        false
                    ],
                    "picks" : [],
                },
                "poolType" : "Survivor",
                "clubMembers" : [ ],
                "subGroups" : [ ]
            }
        ],
        "__v" : 0
    }


    const picks = [
        {
            "gameId" : 18672,
            "teamId" : 33,
            "spread" : -4,
            "dateTime" : "2024-09-08T16:25:00",
            "week" : 1,
            "isHomeSelected" : true,
            "result" : "Pending"
        },
        {
            "gameId" : 18669,
            "teamId" : 24,
            "spread" : 5.5,
            "dateTime" : "2024-09-09T20:20:00",
            "week" : 2,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18671,
            "teamId" : 8,
            "spread" : -1,
            "dateTime" : "2024-09-08T16:25:00",
            "week" : 3,
            "isHomeSelected" : true,
            "result" : "Pending"
        },
        {
            "gameId" : 18676,
            "teamId" : 4,
            "spread" : -7,
            "dateTime" : "2024-09-08T13:00:00",
            "week" : 5,
            "isHomeSelected" : true,
            "result" : "Pending"
        },
        {
            "gameId" : 18674,
            "teamId" : 10,
            "spread" : 8,
            "dateTime" : "2024-09-08T16:05:00",
            "week" : 6,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18687,
            "teamId" : 28,
            "spread" : -3,
            "dateTime" : "2024-09-15T16:25:00",
            "week" : 7,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18689,
            "teamId" : 32,
            "spread" : -3,
            "dateTime" : "2024-09-15T16:05:00",
            "week" : 8,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18696,
            "teamId" : 31,
            "spread" : -6.5,
            "dateTime" : "2024-09-15T13:00:00",
            "week" : 9,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18692,
            "teamId" : 9,
            "spread" : -6,
            "dateTime" : "2024-09-15T13:00:00",
            "week" : 10,
            "isHomeSelected" : true,
            "result" : "Pending"
        },
        {
            "gameId" : 18700,
            "teamId" : 4,
            "spread" : 1,
            "dateTime" : "2024-09-12T20:15:00",
            "week" : 11,
            "isHomeSelected" : false,
            "result" : "Pending"
        },
        {
            "gameId" : 18716,
            "teamId" : 24,
            "spread" : 1,
            "dateTime" : "2024-09-19T20:15:00",
            "week" : 12,
            "isHomeSelected" : true,
            "result" : "Pending"
        }
    ];
    
    const updateSurvivorPicksWithResults = (user, gameResults) => {

        const picks = user.Games[1].pool.picks || []; 
        // Sort picks by week
        const sortedPicks = picks.sort((a, b) => a.week - b.week);
    
        // Process picks and stop on first loss
        let lossDetected = false;
    
        const updatedPicks = sortedPicks.map(pick => {
            if (lossDetected) {
                return null; // Mark for removal
            }
    
            const game = gameResults.find(game => game.GlobalGameID === pick.gameId);
    
            if (game && pick.result === 'Pending') {
                const { HomeScore, AwayScore, Status } = game;
                let updatedResult = 'Pending';
                let margin = 0;
    
                // Check for special game statuses
                if (Status === 'Cancelled' || Status === 'Postponed' || Status === 'Suspended' || Status === 'Forfeit') {
                    updatedResult = 'Push';
                    return { ...pick, result: updatedResult };
                }
    
                // Determine result based on selected team and score
                if (pick.isHomeSelected) {
                    if (HomeScore > AwayScore) {
                        updatedResult = 'Win';
                        margin = HomeScore - AwayScore;
                    } else {
                        updatedResult = 'Loss';
                        margin = HomeScore - AwayScore;
                        lossDetected = true; // Stop further processing
                    }
                } else {
                    if (AwayScore > HomeScore) {
                        updatedResult = 'Win';
                        margin = AwayScore - HomeScore;
                    } else {
                        updatedResult = 'Loss';
                        margin = AwayScore - HomeScore;
                        lossDetected = true; // Stop further processing
                    }
                }
    
                return { ...pick, result: updatedResult, margin };
            }
    
            return pick;
        }).filter(pick => pick !== null); // Remove future weeks
    
        return updatedPicks;
    };
    
    
    const updatedPicks = updateSurvivorPicksWithResults(UserDB, gameResults);
    console.log("NFL Survivor Results", updatedPicks);

    function addSurvivorNoPick(currentWeek, user) {
        const noPick = {
            teamId : "No Pick",
            week : currentWeek,
            result: "Loss"
        }

        const userPicks = user.Games[1].pool.picks || [];

        const doesUserHaveALoss = userPicks?.some(pick => pick.result === 'Loss');
        console.log("Does User Have A Loss", doesUserHaveALoss);
        if (doesUserHaveALoss) {
            return userPicks;
        }
        const currentWeeksPick = userPicks?.filter(pick => pick.week === currentWeek);
        console.log("Current Weeks Pick", currentWeeksPick);
        
        let updatedPicks = userPicks;
        if (currentWeeksPick?.length === 0) {
            updatedPicks?.push(noPick);
            updatedPicks = userPicks?.filter(pick => pick.week <= currentWeek);
            
        }
        
        return updatedPicks;

    }

    const addingNoPickSurvivor = addSurvivorNoPick(1, UserDB);
    console.log("Adding Survivor NO PICK", addingNoPickSurvivor);
    


    return (
        <div>
            <h1>Games With No Spreads</h1>
        </div>
    );
}

export default NFLSurvivorResultLogic;