import React from 'react';
import { Typography, Box, Button, Paper, Divider } from '@mui/material';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MyPoolsDropdown = ({ userData, setMyPoolsOpen }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();
    const filteredGames = userData?.Games?.filter(game => game?.masterPoolId && game?.status === 'active');


    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleGoToPool = (gameAdress, masterPoolId, gameId) => {
        const url = `/${uriEncoded(gameAdress)}?mId=${masterPoolId}&gId=${gameId}&page=default`;
        //console.log("URL: ", url);
        navigateToTop(url);

        setMyPoolsOpen(false);
        // Close the dropdown (you'll need to add this functionality from parent)
    };

    const handleMyPoolsClick = () => {
        setMyPoolsOpen(false);
        navigateToTop('/my-pools');
    };

    const handleMorePoolClick = () => {
        setMyPoolsOpen(false);
        navigateToTop('/pool-hall');
    };

    return (
        <Paper
            elevation={3}
            sx={{
                position: 'fixed',
                top: '80px', // Positioned below the header which is 80px tall
                left: '0',
                width: '100%',
                borderRadius: '0px 0px 4px 4px',
                zIndex: 1100, // Higher than the AppBar (which is 1099)
                overflow: 'auto',
                backgroundColor: 'white',
                maxHeight: '80vh',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {filteredGames && filteredGames.length > 0 ? (
                    <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', borderBottom: '2px solid #EBEBEB' }}>
                        {filteredGames.map((game, index) => (
                            <Box key={game.masterPoolId || index}>
                                {/* Master Pool Header - Dark background with white text */}
                                <Box
                                    sx={{
                                        backgroundColor: '#07356B',
                                        padding: '8px 16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: isMobile ? '14px' : '18px',
                                            fontWeight: '700',
                                            color: '#fff',
                                            textAlign: 'center',
                                            flexGrow: 1
                                        }}
                                    >
                                        {game.masterPoolName || "Unnamed Pool"}
                                    </Typography>
                                </Box>

                                {/* Individual Game Entries */}
                                {game.gameIds && game.gameIds.map((gameEntry, gameIndex) => (
                                    <Box key={gameEntry.gameId || gameIndex}>
                                        <Box
                                            sx={{
                                                height: isMobile ? '40px' : '60px',
                                                padding: '0px 16px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1
                                            }}>
                                                {gameEntry.image && (
                                                    <Box
                                                        component="img"
                                                        src={gameEntry.image}
                                                        alt={gameEntry.name || "Game Logo"}
                                                        sx={{
                                                            maxHeight: isMobile ? '20px' : '30px',
                                                            display: 'block',
                                                            objectFit: 'contain',
                                                            marginRight: '12px'
                                                        }}
                                                    />
                                                )}
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        fontSize: isMobile ? '14px' : '16px',
                                                        fontWeight: '500',
                                                        color: '#002129',
                                                        textAlign: 'left',
                                                        flexGrow: 1
                                                    }}
                                                >
                                                    {gameEntry.name || "Unnamed Game"}
                                                </Typography>
                                            </Box>
                                            <Button
                                                onClick={() => handleGoToPool(game.gameAddress, game.masterPoolId, gameEntry.gameId)}
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    height: isMobile ? '24px' : '30px',
                                                    width: isMobile ? '50px' : '60px',
                                                    backgroundColor: '#002129',
                                                    color: '#fff',
                                                    fontWeight: '700',
                                                    fontSize: isMobile ? '10px' : '14px',
                                                    padding: '0px',
                                                    marginLeft: 'auto', // Ensures button stays at far right
                                                    '&:hover': {
                                                        backgroundColor: '#002129',
                                                    }
                                                }}
                                            >
                                                GO TO
                                            </Button>
                                        </Box>
                                        {gameIndex < game.gameIds.length - 1 && (
                                            <Divider sx={{ borderColor: '#EBEBEB', borderWidth: '1px' }} />
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#002129',
                            fontWeight: '700',
                            textAlign: 'center',
                            padding: '16px',
                        }}
                    >
                        You don't have any active pools.
                    </Typography>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '16px',
                        width: '100%',
                        justifyContent: 'space-evenly',
                        maxWidth: '500px',
                    }}
                >
                    <Button
                        onClick={handleMyPoolsClick}
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '25px',
                            backgroundColor: '#fff',
                            border: '1px solid #002129',
                            color: '#002129',
                            marginTop: '16px',
                            marginBottom: '16px',
                            boxShadow: 'none',
                            '&:hover': {
                                background: '#002129', // Maintain the same background color on hover
                                boxShadow: 'none', // Optionally remove box shadow on hover
                              },
                        }}
                    >
                        MY POOLS
                    </Button>
                    <Button
                        onClick={handleMorePoolClick}
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '25px',
                            backgroundColor: '#fff',
                            color: '#002129',
                            border: '1px solid #002129',
                            marginTop: '16px',
                            marginBottom: '16px',
                            boxShadow: 'none',
                            '&:hover': {
                                background: '#002129', // Maintain the same background color on hover
                                boxShadow: 'none', // Optionally remove box shadow on hover
                              },
                        }}
                    >
                        POOL HALL
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default MyPoolsDropdown;