import React from 'react';
import { Typography, Box } from '@mui/material';
import './PickStatsCard.scss';

const PickStatsHeader = ({
    awayLogo,
    homeLogo,
    awayName,
    homeName,
    gameStatus,
    awayScore,
    homeScore,
}) => {
    

    return (
        <Box 
            className="super-pickem-pick-stats-header" 
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1, // Add spacing between elements
            }}
        >
            {/* Away Team Logo */}
            <img 
                src={awayLogo} 
                alt={`${awayName} logo`} 
                className="super-pickem-pick-stats-team-logo"
            />

            {/* Away Team Name & Possible Score */}
            {(gameStatus === 'Final' || gameStatus === 'F/OT') && (
                <Box className="super-pickem-pick-stats-team-score-container">
                    <Typography 
                        className="super-pickem-pick-stats-team-score"
                        sx={{
                            fontWeight: awayScore > homeScore ? '800' : '500',
                        }}
                    >
                        {awayName}
                    </Typography>
                    <Typography 
                        className="super-pickem-pick-stats-team-score"
                        sx={{
                            fontWeight: awayScore > homeScore ? '800' : '500',
                        }}
                    >
                        {awayScore}
                    </Typography>
                </Box>
            )}

            {(gameStatus !== 'Final' && gameStatus !== 'F/OT') && (
                <Typography className="super-pickem-pick-stats-team-name">
                    {awayName}
                </Typography>
            )}

            {/* @ Symbol */}
            <Typography 
                className="super-pickem-pick-stats-at-symbol" 
                sx={{
                    color: gameStatus === 'InProgress' ? '#CC293C' : '#002129',
                }}
            >
                {gameStatus === 'InProgress' ? 'LIVE' : (gameStatus === 'Final' || gameStatus === 'F/OT') ? gameStatus : 'vs.'}
            </Typography>

            {/* Home Team Name and Possible Score */}
            {(gameStatus === 'Final' || gameStatus === 'F/OT') && (
                <Box className="super-pickem-pick-stats-team-score-container">
                    <Typography 
                        className="super-pickem-pick-stats-team-score"
                        sx={{
                            fontWeight: homeScore > awayScore ? '800' : '500',
                        }}
                    >
                        {homeName}
                    </Typography>
                    <Typography 
                        className="super-pickem-pick-stats-team-score"
                        sx={{
                            fontWeight: homeScore > awayScore ? '800' : '500',
                        }}
                    >
                        {homeScore}
                    </Typography>
                </Box>
            )}

            {(gameStatus !== 'Final' && gameStatus !== 'F/OT') && (
                <Typography className="super-pickem-pick-stats-team-name">
                    {homeName}
                </Typography>
            )}

            {/* Home Team Logo */}
            <img 
                src={homeLogo} 
                alt={`${homeName} logo`} 
                className="super-pickem-pick-stats-team-logo"
            />
        </Box>
    );
};

export default PickStatsHeader;
