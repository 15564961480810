import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../config";

export const useGetMasterPool = () => {
    return useMutation({
        mutationFn: getMasterPoolRequest,
        onError: (error) => {
            console.error('Error fetching master pool:', error);
        },
        // onSuccess: (data) => {
        //     console.log('Fetched master pool data:', data);
        // }
    });
};

const getMasterPoolRequest = async ({ masterPoolId }) => {
    const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage
    const data = { masterPoolId };
    const response = await axios.post(`${apiUrl}/games/get-master-pool`, data, {
        headers: {
          Authorization: authorizationHeader,  // Include the authorization header in the request
          'Content-Type': 'application/json'
        }
      });
    //console.log('USEGETMASTERPOOL', response.data.data.masterPoolData);
    return response.data.data.masterPool;
}