import React from 'react';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { userSigninAction, userLogoutAction,getUserDataFromLocalStorage } from '../../redux/actions/loginImport';
import { fetchGames } from '../../redux/actions/gameImport';
import { Link } from 'react-router-dom';
import UserBanner from './userBanner';
import GameLists from './gameLists';
// import './styles.scss';
const UserDash = (props) => {
    const dispatch = useDispatch();
    const userData = getUserDataFromLocalStorage();
    const gameData = [];
      const games = useSelector((state) => state.game.fetchGames.usersGames);

      useEffect(() => {
        dispatch(fetchGames({ email: userData.email }));
      }, [dispatch, userData.email]);

      return (
        <>
          <UserBanner userData={userData} />
          <main className="user-profile-main-header-content">
            {/* TODO: ADD GAMES UI HERE */}
            <h3>Start New Game</h3>
            <div className="main-dash-links">
              <Link className="game-links" to="/create-new-game">
                <div>
                  <p>Create New Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Join Existing Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Invite Friends</p>
                </div>
              </Link>
            </div>
            {/*User Created Games*/}
            <h3>Current Games</h3>
            <div className="main-dash-links">
              {games.map((game, index) => (
                <GameLists games={game} key={index} />
              ))}
            </div>

            {/*Featured Games*/}
            <h3>Featured Games</h3>
            <div className="main-dash-links">
              <Link className="game-links" to="/create-new-game">
                <div>
                  <p>Create New Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Join Existing Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Invite Friends</p>
                </div>
              </Link>
            </div>

            {/*My Community*/}
            <h3>My Community</h3>
            <div className="main-dash-links">
              <Link className="game-links" to="/create-new-game">
                <div>
                  <p>Create New Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Join Existing Game</p>
                </div>
              </Link>
              <Link className="game-links" to="/welcome">
                <div>
                  <p>Invite Friends</p>
                </div>
              </Link>
            </div>
          </main>
        </>
      );
    };


  const mapStateToProps = (state) => ({
    user: state.user,
    games: state.games,
    game: state.game,
  });

  export default connect(mapStateToProps)(UserDash);