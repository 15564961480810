import React from "react";
import CreatePartyInfo from "./CreatePartyInfo";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const NotInParty = ({ gameName, setSignUpState, gameAddress, doesUserBelong, setJoinOpen }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <div>
            <>
                {!doesUserBelong && (
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: '700',
                            marginTop: '32px',
                            color: '#CC293C'
                        }
                    }
                    >
                        To create a party, <br/> you must join the pool.
                    </Typography>
                )}
                <Typography
                    style={{
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 700,
                        textDecorationLine: 'underline',
                        padding: '32px'
                    }}
                >
                    My Party
                </Typography>
                <Typography
                    style={{
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 600,
                        marginBottom: '24px'
                    }}
                >
                    Create a competition with your private group.
                </Typography>

                <CreatePartyInfo 
                    setSignUpState={setSignUpState} 
                    gameAddress={gameAddress} 
                    doesUserBelong={doesUserBelong} 
                    gameName={gameName}
                    setJoinOpen={setJoinOpen}
                />

            </>
        </div>
    );
}

export default NotInParty;