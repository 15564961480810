import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import './SimpleSelectionButtons.scss';

const SimpleSelectionButtons = ({ 
    selectedTab, 
    tabTexts, 
    customHandler = null,
    paramName = 'tab'
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleTabChange = (tabIndex) => {
        if (customHandler) {
            // Use custom handler if provided
            customHandler(tabIndex);
        } else {
            // Default behavior - update URL parameter
            const newParams = new URLSearchParams(searchParams);
            newParams.set(paramName, tabIndex);
            navigate(`?${newParams.toString()}`);
        }
    };

    return (
        <Box className="simple-selection-button-container">
            {tabTexts.map((buttonText, index) => (
                <Button
                    key={index}
                    className={`simple-selection-button ${selectedTab === index.toString() ? 'active' : ''}`}
                    onClick={() => handleTabChange(index.toString())}
                >
                    {buttonText}
                </Button>
            ))}
        </Box>
    );
}

export default SimpleSelectionButtons;