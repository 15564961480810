import React from "react";
import axios from "axios";
import { DateTime } from "luxon";

// Function that will set redis cache data with ALL of the games for the day, in ALL sports that we are currently offering
const SetDailyRedis = () => {
    // Current Redis cache data, needs to be grabbed from Redis
  const currentRedisCache = {
    "NFL": [
      {
        "GlobalGameID": 18760,
        "Date": "2024-10-10T00:00:00",
        "homeTeam": "SEA",
        "awayTeam": "SF",
        "gameStatus": "Scheduled",
        "survivorFinalized": false,
        "pickemFinalized": false,
        "sportsbookFinalized": false
      }
    ],
    "CFB": [
      {
        "GlobalGameID": 50015145,
        "Date": "2024-10-10T00:00:00",
        "homeTeam": "LOUTCH",
        "awayTeam": "MTNST",
        "gameStatus": "Scheduled",
        "survivorFinalized": false,
        "sportsbookFinalized": false
      },
      {
        "GlobalGameID": 50015147,
        "Date": "2024-10-10T00:00:00",
        "homeTeam": "WKENT",
        "awayTeam": "UTEP",
        "gameStatus": "Scheduled",
        "survivorFinalized": true,
        "sportsbookFinalized": true
      },
      {
        "GlobalGameID": 50015229,
        "Date": "2024-10-10T00:00:00",
        "homeTeam": "JMAD",
        "awayTeam": "COAST",
        "gameStatus": "Scheduled",
        "survivorFinalized": true,
        "sportsbookFinalized": true
      }
    ]
  };

  // Get current date in EST
  //const estDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
  //const date = new Date(estDate).toISOString().slice(0, 10);
  const date = "2024-10-05";
  console.log("Today's Date:", date);

  // Array of sports and games we are currently offering, may be a better place to store this for ease of access to make changes (Redis?)
  const sportInfo = [
    { sport: "NFL", games: ["survivor", "pickem", "sportsbook"] },
    { sport: "CFB", games: ["survivor", "sportsbook"] }
  ];

    // Function to get the SportsDataIO URL based on the sports we are currently offering
  const getUrl = (sport) => {
    switch (sport) {
      case "NFL":
        return `https://api.sportsdata.io/v3/nfl/scores/json/ScoresByDate/${date}?key=78cc019b545c4decb0ee8ab4e8bf1ecb`;
      case "CFB":
        return `https://api.sportsdata.io/v3/cfb/scores/json/ScoresBasic/${date}?key=3135834e1ffa4a80a9739235a2918d28`;
      default:
        return null;
    }
  };

  // Function to check if any of the `Finalized` keys are false, so we know which games to remove from the Redis cache
  const hasAnyFalse = (game) => {
    return Object.keys(game).some((key) => key.endsWith("Finalized") && game[key] === false);
  };

  const fetchData = async () => {
    console.log("STARTING SET DAILY REDIS");
    // Start with existing Redis cache data
    const dataToSetInRedis = { ...currentRedisCache }; 

    // Loop through each sport and fetch the games for the day
    for (const sportItem of sportInfo) {
      const { sport, games } = sportItem;
      const url = getUrl(sport);

      if (!url) {
        console.error(`No URL found for sport: ${sport}`);
        continue;
      }

      try {
        const response = await axios.get(url);
        const gameData = response.data;
        let newGameInfo = [];

        if (!gameData || gameData.length === 0) {
            console.log(`No games found for this date for ${sport}`);
            
            // Filter existing games to keep only those with at least one Finalized key as false
            const existingGames = dataToSetInRedis[sport] || [];
            const filteredExistingGames = existingGames.filter((game) => hasAnyFalse(game));
        
            // Set the filtered existing game info for the current sport in the dataToSetInRedis object
            dataToSetInRedis[sport] = filteredExistingGames;
        
            console.log(`Filtered Existing Game Info for ${sport}:`, filteredExistingGames);
            
            continue;
        }
        

        console.log(`Games found for this date for ${sport}`);
        for (let i = 0; i < gameData.length; i++) {
          const game = gameData[i];

          // Create an object with the `Finalized` keys dynamically based on `games` array
          const finalizedKeys = games.reduce((acc, gameType) => {
            acc[`${gameType}Finalized`] = false; // Create keys like survivorFinalized, sportsbookFinalized
            return acc;
          }, {});

          // Add game info and finalized keys to each game
          newGameInfo.push({
            GlobalGameID: game.GlobalGameID,
            Date: game.Day,
            homeTeam: game.HomeTeam,
            awayTeam: game.AwayTeam,
            gameStatus: game.Status,
            ...finalizedKeys // Spread the finalized keys into the game object
          });
        }

        // Combine with existing Redis data
        const existingGames = dataToSetInRedis[sport] || [];
        console.log(`Existing Game Info for ${sport}:`, existingGames);
        const combinedGames = [
          ...existingGames.filter((game) => hasAnyFalse(game)), // Keep existing non-finalized games
          ...newGameInfo // Add new games from API
        ];
        console.log(`Combined Game Info for ${sport}:`, combinedGames);
        // Set combined game info for the current sport in the dataToSetInRedis object
        dataToSetInRedis[sport] = combinedGames;

        console.log(`Updated Game Info for ${sport}:`, combinedGames);
      } catch (error) {
        console.error(`Error fetching data for ${sport}:`, error);
      }
    }
    console.log("Final Data to set in Redis:", dataToSetInRedis);
    return dataToSetInRedis;
  };

  // Call fetchData when the component renders
  fetchData();

  return (
    <div>
      <h1>Set Daily Redis</h1>
    </div>
  );
};

export default SetDailyRedis;
