import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import './MyPoolsCard.scss';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';

const MyPoolsCard = ({ poolInfo, activePools}) => {

    const masterPoolId = poolInfo?.masterPoolId;
    const isUserInGame = activePools?.some(pool => pool.masterPoolId === masterPoolId) || false;

    let gameIdForNavigation = poolInfo?.gameId;

    // if (isUserInGame) {
    //     // Find the user's pool based on the masterPoolId
    //     const userPool = activePools?.find(pool => pool.masterPoolId === masterPoolId);

    //     // Get the first gameId from gameIds array if it exists
    //     if (userPool?.gameIds?.length > 0) {
    //         gameIdForNavigation = userPool.gameIds[0].gameId;
    //     }
    // }

    //console.log("Game ID for Navigation: ", gameIdForNavigation);
    //console.log("Master Pool ID: ", masterPoolId, "Is User In Game: ", isUserInGame);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    const navigateToTop = useNavigateToTop();

    const handleButtonClick = () => {
        const url = `/${uriEncoded(poolInfo?.gameAddress)}?mId=${masterPoolId}&gId=${gameIdForNavigation}&page=default`;
        navigateToTop(url);
    }

    return (
        <Box 
            className="my-pools-card-container"
            onClick={() => {
                if (poolInfo?.nonGameMemberButtonText !== "Coming Soon") {
                  handleButtonClick();
                }
              }}       
        >
            <Box>
                <img 
                    src={poolInfo?.logo}
                    alt="Pool Logo"
                    className='my-pools-card-logo'
                />
            </Box>

            <Box>
                <Typography className="my-pools-card-title">
                    {poolInfo?.poolName}
                </Typography>
                <Box className="my-pools-card-text-row">
                    <Typography
                        className="my-pools-card-text-header"
                        sx={{ width: '68px' }}
                    >
                        Pool Starts
                    </Typography>
                    <Typography
                        className="my-pools-card-text-header"
                        sx={{ marginLeft: '16px'}}
                    >
                        Prizes
                    </Typography>
                </Box>
                <Box className="my-pools-card-text-row">
                    <Typography
                        className="my-pools-card-text-content"
                        sx={{ width: '68px' }}
                    >
                        {poolInfo?.poolStarts}
                    </Typography>
                    <Typography
                        className="my-pools-card-text-content"
                        sx={{ marginLeft: '16px' }}
                    >
                        {poolInfo?.prizes}
                    </Typography>
                </Box>
                <Box className="my-pools-card-button-container">
                    <Button
                        className="my-pools-card-button"
                        sx={{ cursor: poolInfo?.nonGameMemberButtonText === "Coming Soon" ? 'default' : 'pointer' }}
                        onClick={handleButtonClick}
                        disabled={poolInfo?.nonGameMemberButtonText === "Coming Soon"}
                    >
                        {isUserInGame ? poolInfo?.gameMemberButtonText : poolInfo?.nonGameMemberButtonText}
                        {(isUserInGame || poolInfo?.showArrow) && (
                            <EastIcon sx={{ fontSize: '16px' }} />
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default MyPoolsCard;