import React from 'react';
import { Box, Typography, List, ListItem, Link } from '@mui/material';

const Links = ({ links }) => (
    <Box>
      <Typography 
        variant="h6" 
        gutterBottom
        sx={{
          fontSize: links?.headerSize || '20px',
          fontWeight: links?.headerWeight || 700,
          color: links?.headerColor || '#002129',
          textAlign: 'center',
        }}
      >
        {links?.header}
      </Typography>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {links?.links?.map((link, index) => (
          <ListItem
            key={index}
            sx={{
              justifyContent: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Link 
              href={link?.url}
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                color: '#00AAD1',
                textAlign: 'center',
              }}
            >
              {link?.text}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
);

export default Links;