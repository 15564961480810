import React from 'react';
import { Box, Typography } from '@mui/material';
import PoolPartyTrophyGold from '../../assets/images/PoolPartyTrophyGold.svg';

import PoolPartyTrophyGray from '../../assets/images/PoolPartyTrophyGray.svg';
import './LoadingScreen.scss';

const LoadingScreen = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      className="loading-screen"
    >
      <Typography variant="h5" gutterBottom>
        Loading...
      </Typography>
      <img src={PoolPartyTrophyGray} alt="Loading" className="loading-screen__spinner" />
    </Box>
  );
};

export default LoadingScreen;
