 import React from 'react';

 const Rules = () => {


  return(
    <>
      <div style={{width:'90%', maxWidth:'760px', margin:'0 auto', lineHeight:'1.8', textAlign:'left'}}>
        <h1 style={{textAlign:'center', marginBottom:'16px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>Premise</h1>
        <p style={{textAlign: 'center'}}>The NBA Super Pick ‘Em Pool combines the excitement of the bracket prediction challenge with the thrill of daily picks against the spread (ATS).</p>
        <p style={{textAlign: 'center', marginTop: '10px'}}>
        Earn points through the your bracket prediction and your daily picks and compete to be crowned the NBA Super Pick ‘Em Champion.
        </p>
        <h1 style={{textAlign:'center', marginTop: '16px', marginBottom:'5px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>How to Play</h1>
        <h3 style={{ textAlign: 'center', 'fontSize': '22px', fontWeight: '600'}}>Part 1: Bracket Prediction</h3>
        <ul style={{maxWidth:'735px', margin:'0 auto', textAlign: 'center'}}>
          <li> <b>Make Your Picks:</b> Similar to filling out an NCAA March Madness bracket, select the team you believe will win each NBA playoff series, starting in Round 1 through the NBA Finals.</li>
          <li><b>Bonus:</b> Predict the exact number of games each series will last (between 4-7 games). To earn the bonus point, your bracket must accurately predict all of the following:</li>
          <ul>
            <li>- Both teams in the series matchup</li>
            <li>- The winner of the series</li>
            <li>- The exact series length</li>
          </ul>
        </ul>
        <h3 style={{ textAlign: 'center', 'fontSize': '22px', fontWeight: '600', marginTop: '15px'}}>Part 2: Daily Pick ‘Em</h3>
        <ul style={{maxWidth:'735px', margin:'0 auto', textAlign: 'center'}}>
          <li><b>Daily Selections:</b> Each day featuring a playoff game(s), select one team to win against the spread that day.
</li>
          <ul>
            <li><b>Submission Deadline:</b> Picks must be submitted before the tip-off of the chosen game.</li>
            <li><b>Dynamic Spreads:</b> Betting spreads will continuously update in real-time until the start of each game. Multiple players may select the same team to win against the spread, but their picks may lock at different spread values based on the timing of their selection.
</li>
          </ul>
        </ul>
        <h1 style={{textAlign:'center', marginTop: '16px', marginBottom:'5px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>Scoring</h1>
        <h3 style={{ textAlign: 'center', 'fontSize': '22px', fontWeight: '600'}}>Part 1: Bracket Points</h3>
        <ul style={{textAlign:'center'}}>
          <li><b>Correct 1st Round Pick:</b> 1 Point</li>
          <li><b>Correct 2nd Round Pick:</b> 2 Points</li>
          <li><b>Correct Conference Finals Pick:</b> 4 Points </li>
          <li><b>Correct NBA Finals Pick:</b> 8 Points</li>
          <li><b>Bonus:</b> 1 Point</li>
        </ul>
        <h3 style={{ textAlign: 'center', 'fontSize': '22px', fontWeight: '600', marginTop:'10px'}}>Part 2: Pick ‘Em Points</h3>
        <ul style={{textAlign:'center'}}>
          <li><b>Correct ATS Pick:</b> 1 Point</li>
          <li><b>Tied ATS Pick:</b> ½ Point</li>
          <li><b>Incorrect ATS Pick:</b> 0 Points</li>
        </ul>
        <h1 style={{textAlign:'center', marginTop: '16px', marginBottom:'5px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>Competitions</h1>
        <h2 style={{textAlign:'center', fontSize: '24px'}}>NBA Super Pick ‘Em</h2>
        <h3 style={{textAlign:'center', fontSize: '20px'}}>Champion Competition</h3>
        <p style={{textAlign:'center'}}>The champion of the NBA Super Pick ‘Em will be determined by the participant with the most Total Points. Total Points combines both Bracket Points and Pick ‘Em Points.</p>
        <p style={{textAlign:'center'}}><b><u>Prizes:</u></b> <br/> 1st Place: $200<br/>    2nd Place: $100<br/>    3rd Place: $50</p>
        <h2 style={{textAlign:'center', fontSize: '24px', marginTop: '15px'}}>Bracket Only</h2>
        <h2 style={{textAlign:'center', fontSize: '20px'}}>Bonus Competition</h2>
        <p style={{textAlign:'center'}}>A bonus winner will be awarded based solely on Bracket Points. The participant with the most Bracket Points alone will be crowned the Bracket Winner.</p>
        <p style={{textAlign:'center'}}><b><u>Prize:</u></b><br/> 1st Place: $100</p>
        <h2 style={{textAlign:'center', fontSize: '24px', marginTop: '15px'}}>Pick ‘Em Only</h2>
        <h2 style={{textAlign:'center', fontSize:'20px'}}>Bonus Competition</h2>
        <p style={{textAlign:'center'}}>A bonus winner will be awarded based solely on Pick ‘Em Points. The participant with the most Pick ‘Em Points alone will be crowned the Pick ‘Em Winner.</p>
        <p style={{textAlign:'center'}}><b><u>Prize:</u></b><br/> 1st Place: $100</p>
        <h1 style={{textAlign:'center', marginBottom:'16px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>Rules</h1>
        <div style={{ textAlign: 'center' }}>
            <ol>
              <li><b>1. One Entry Per Player:</b> Each registered account can create one (1) entry into the NBA Super Pick ‘Em Pool.</li>
              <li><b>2. Bracket Submissions:</b> All brackets must be submitted before the scheduled game time of the first NBA playoff game on Saturday April 20th. No late entries or bracket changes will be accepted after this time.</li>
              <li><b>3. Pick ‘Em:</b> Daily picks must be submitted before the scheduled game time of your selected game for that day.
                <ul>
                  <li><b>a. Game Lock:</b> Each game will only be selectable up until the scheduled game time.</li>
                  <li><b>b. No Changes:</b> Once a pick is submitted for a particular day, it is final. It cannot be changed or altered.</li>
                </ul>
              </li>
              <li>
                <p><b>4. Tiebreaker:</b> The Tiebreaker will be used in the event there is a tie between two or more players in any of the Prize positions listed below.</p>
                <p>a. Players will predict the total combined score for the last NBA Finals game. Closest to the actual total combined points scored in the last NBA Finals game will win the tiebreaker.</p>
                <p>b. This submission of the tiebreaker will be made available once the NBA Finals matchup is determined prior to NBA Finals start date of June 6, 2024.</p>
              </li>
              <h1 style={{textAlign:'center', marginTop: '16px', marginBottom:'5px', fontSize:'32px', fontWeight: '800', textDecoration: 'underline'}}>Rule Clarifications & Final Interpretations</h1>
              <p style={{ textAlign: 'center', marginBottom: '25px' }}>
                In the event of unforeseen circumstances, rule ambiguities, or situations not explicitly covered within the established guidelines ("Rules", "Scoring", etc.), the Pool Party team reserves the right to make final interpretations and rulings.
              </p>
            </ol>
        </div>
      </div>
    </>
  );


 }

 export default Rules;