import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

//Function to handle the POST request
export const useMarkRewardsAsRedeemed = () => {
    return useMutation({
        mutationFn: markRewardsAsRedeemedRequest,
        onError: (error) => {
            console.error('Error marking rewards as redeemed:', error);
        },
        onSuccess: (data) => {
            console.log('Rewards marked as redeemed successfully:', data);
        }
    });
};

const markRewardsAsRedeemedRequest = async (rewardData) => {
    const authorizationHeader = localStorage.getItem('authorization');

    const response = await axios.post(`${apiUrl}/games/mark-rewards-as-redeemed`, rewardData, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type' : 'application/json'
        }
    });
    console.log('Rewards marked as redeemed successfully:', response.data);
    return response.data.data;
}