import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

export const useGetCreateAPoolData = () => {
    return useMutation({
        mutationFn: getCreateAPoolData,
        onError: (error) => {
            console.error("Error fetching create a pool data:", error);
        },
        // onSuccess: (data) => {
        //     console.log("Fetched create a pool data:", data);
        // }
    });
}

const getCreateAPoolData = async () => {

    const response = await axios.get(`${apiUrl}/sports-data/get-create-a-pool-data`, {
        headers: {
            "Content-Type": "application/json"
        }
    });

    return response.data;
}