export const getRankSuffix = (number) => {
    if (typeof number !== "number" || isNaN(number)) return ""; // Ensure valid number

    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    // Handle exceptions for 11, 12, 13 which always get "th"
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${number}th`;
    }

    // Assign suffix based on last digit
    return `${number}${suffixes[lastDigit] || "th"}`;
};