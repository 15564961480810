import React, { useState, useEffect } from 'react';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import '../../NFLSurvivor/NFLSurvivorStyles/SurvivorWeeklyPicks.scss';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WeeklyPicksBar = ({ weeklySchedule, userPicks, deadline }) => {
    const { 
        CFBTeams,
        CFBNames,
        CFBGamesByWeek,
        userPicksFromDB,
        doesUserHaveACCLoss,
        lossWeekACC,
        doesUserHaveBig12Loss,
        lossWeekBig12,
        doesUserHaveBigTenLoss,
        lossWeekBigTen,
        doesUserHaveSECLoss,
        lossWeekSEC,
        week,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
     } = useCFBSurvivorContext();
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [ACCGame, setACCGame] = useState(null);
    const [BigTenGame, setBigTenGame] = useState(null);
    const [Big12Game, setBig12Game] = useState(null);
    const [SECGame, setSECGame] = useState(null);

    useEffect(() => {
        const ACCGame = userPicksFromDB?.find(game => game.conference === "ACC" && game.week === week);
        const BigTenGame = userPicksFromDB?.find(game => game.conference === "Big Ten" && game.week === week);
        const Big12Game = userPicksFromDB?.find(game => game.conference === "Big 12" && game.week === week);
        const SECGame = userPicksFromDB?.find(game => game.conference === "SEC" && game.week === week);

        setACCGame(doesUserHaveACCLoss && lossWeekACC < week ? "ELIMINATED" : ACCGame || "NO PICK");
        setBigTenGame(doesUserHaveBigTenLoss && lossWeekBigTen < week ? "ELIMINATED" : BigTenGame || "NO PICK");
        setBig12Game(doesUserHaveBig12Loss && lossWeekBig12 < week ? "ELIMINATED" : Big12Game || "NO PICK");
        setSECGame(doesUserHaveSECLoss && lossWeekSEC < week ? "ELIMINATED" : SECGame || "NO PICK");
    }, [userPicksFromDB, week]);

    //console.log("ACC GAME:", ACCGame, "Big Ten Game:", BigTenGame, "Big 12 Game:", Big12Game, "SEC Game:", SECGame);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const day = date.getDate();
        return `${month} ${day}`;
    }

    const cfbLogo = (teamId) => {
        return CFBTeams?.find(team => team.GlobalTeamID === teamId)?.TeamLogoUrl;
    }

    const cfbKey = (teamId) => {
        const displayName = CFBNames?.find(team => team.GlobalTeamID === teamId)?.ShortDisplayName || '';
        return displayName;
    }
    

    const cfbOpponentInfo = (gameId, teamId) => {
        const game = CFBGamesByWeek?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? cfbKey(game?.GlobalAwayTeamID) : cfbKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        return `${leadingSymbol} ${opponentKey}`;
    }

    const conferenceMap = {
        ACC: ACCGame,
        'Big 12': Big12Game,
        'Big Ten': BigTenGame,
        SEC: SECGame
    };

    

    return (
        <div className="weekly-picks-bar" style={{ height: isMobile ? '100px' : '186px', maxWidth: isMobile ? '365px' : '952px', margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: isMobile ? '0' : '20px', paddingRight: isMobile ? '0' : '20px' }}>
            {Object.keys(conferenceMap).map(conference => {
                const game = conferenceMap[conference];
                const isEliminated = game === "ELIMINATED" || game?.teamId === "No Pick";
                const isNoPick = game === "NO PICK";

                return (
                    <div className={`selected-container${!isMobile ? '-desktop' : ''}`} style={{ height: isMobile ? '85px' : '138px', justifyContent: 'center', marginTop: isMobile ? '7px' : '24px'}} key={conference}>
                        <div className={`selected${!isMobile ? '-desktop' : ''}`}>
                            <div className={`selected-top-text-container${!isMobile ? '-desktop' : ''}`}>
                                <a className={`selected-top-text${!isMobile ? '-desktop' : ''}`} style={{ color: (isEliminated || game?.result === "Loss") ? '#CC293C' : ''}}>{conference}</a>
                            </div>
                            <div className={`selected-logo-container${!isMobile ? '-desktop' : ''}`}>
                                {isEliminated ? 
                                    <div
                                        style={{
                                            border: '1px solid #CC293C',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CloseIcon style={{ margin: '0 auto', color: '#CC293C', fontSize: isMobile ? '32px' : '56px' }} />
                                    </div> : 
                                    isNoPick ? 
                                    <div
                                        style={{ 
                                            width: isMobile ? '32px' : '56px',
                                            height: isMobile ? '32px' : '56px',
                                            objectFit: 'contain', 
                                            border: `1px solid #757575`,
                                            backgroundColor: '#E5E5E5' 
                                        }} 
                                    /> :
                                    <img
                                        className={`selected-logo${!isMobile ? '-desktop' : ''}`}
                                        loading="lazy"
                                        alt=""
                                        src={cfbLogo(game?.teamId)}
                                        style={{
                                            objectFit: 'contain', 
                                            border: `1px solid ${game?.result === "Win" ? '#00AA72' : game?.result === "Loss" ? '#CC293C' : game?.result === "Pending" ? '#002129' : '#002129'}`,
                                            backgroundColor: game?.result === "Win" ? 'rgba(0, 170, 114, 0.20)' : game?.result === "Loss" ? 'rgba(204, 41, 60, 0.20)' : game?.result === "Pending" ? '#FFF' : 'transparent'
                                        }} 
                                    />
                                }
                            </div>
                            <div className={`selected-bottom-text-container${!isMobile ? '-desktop' : ''}`}>
                                <a className={`selected-bottom-text${!isMobile ? '-desktop' : ''}`} style={{ color: (isEliminated || game?.result === "Loss" ) ? '#CC293C' : 'inherit' }}>
                                    {game?.teamId === "No Pick" 
                                        ? "NO PICK" 
                                        : isEliminated 
                                            ? "OUT" 
                                            : isNoPick 
                                                ? formatDate(deadline) 
                                                : cfbOpponentInfo(game?.gameId, game?.teamId)
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default WeeklyPicksBar;
