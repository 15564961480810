import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useBracketGamesContext } from '../context/BracketGamesContext';
import SixteenTeamBracket from '../../Brackets/SixteenTeamBracket/SixteenTeamBracket';
import FourTeamBracket from '../../Brackets/SixteenTeamBracket/FourTeamBracket';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import BracketNavBar from '../../NavigationBars/BracketNavigationBar/BracketNavBar';
import UserSubmitBar from '../../GamePageComponents/SubmitBars/UserSubmitBar';
import { useSaveBracketPicks } from '../../../hooks/pools/useSaveBracketPicks';
import { useMediaQuery } from '@mui/material';
import isEqual from 'lodash/isEqual';
import RegionCompleteModal from '../BracketModal/RegionCompleteModal';
import GameNotReadyModal from '../../GameModals/GameNotReadyModal/GameNotReadyModal';
import BracketSubmissionModal from '../../GameModals/BracketGameModals/BracketSubmissionModal';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';

const ViewEntrantBracket = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMobile = useMediaQuery("(max-width:900px)");
    const navigateToTop = useNavigateToTop();

    const {
        tournamentSchedule,
        teams,
        selectedUserBracketInfo,
    } = useBracketGamesContext();

    const {
        myUserId,
        masterPoolId,
        pickIndex,
        masterPoolData,
        gameAddress,
        gameId,
        league,
        pickState,
        originalPicks,
        setPickState,
        tiebreaker,
        setTiebreaker,
        originalTiebreaker,
        gameData,
        hasJoinDeadlinePassed,
        setUnsavedPicks,
        isUserInThisPool,
    } = useUserAndGameContext();

    const dispatch = useDispatch();

    

    const [regions, setRegions] = useState(null);
    const [bracket, setBracket] = useState(null);
    const notReadyModal = masterPoolData?.notReadyModal;
    const showNotReadyModal = notReadyModal?.showModal;
    
    // Initialize roundDash from URL or default to 1
    const [roundDash, setRoundDash] = useState(() => {
        const navParam = searchParams.get('bracketNav');
        return navParam ? parseInt(navParam, 10) : 1;
    });

    const scrollToRound = (roundRef) => {
        if (!roundRef?.current) return;
        
        const gameElement = roundRef.current.querySelector('.regional-bracket-game');
        if (!gameElement) return;
        
        // Since we can see the y and pageYOffset values, let's set a fixed target
        const targetPosition = 275; // Based on the pageYOffset we saw
        
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
        });
    };
   
    // Add refs and state for sticky navigation
    const observerRef = useRef(null);
    const containerRef = useRef(null);
    const [isStuck, setIsStuck] = useState(false);

    const round1Ref = useRef(null);
    const round2Ref = useRef(null);
    const round3Ref = useRef(null);
    const round4Ref = useRef(null);
    const round5Ref = useRef(null);
    const round6Ref = useRef(null);

    // Handle scroll for sticky navigation
    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (tournamentSchedule) {
            setRegions({
                topLeft: tournamentSchedule.LeftTopBracketConference,
                bottomLeft: tournamentSchedule.LeftBottomBracketConference,
                topRight: tournamentSchedule.RightTopBracketConference,
                bottomRight: tournamentSchedule.RightBottomBracketConference,
                finalFour: "Final Four",
            });
        }
    }, [tournamentSchedule]);

    useEffect(() => {
        if (regions?.topLeft) {
            setBracket(regions.topLeft);
        }
    }, [regions]);

    // Update URL when roundDash changes while preserving other params
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('bracketNav', roundDash.toString());
        setSearchParams(newParams);
    }, [roundDash, setSearchParams, searchParams]);

    const setRoundDashPosition = useMemo(() => ({
        1: () => setBracket(regions?.topLeft),
        2: () => setBracket(regions?.bottomLeft),
        3: () => setBracket(regions?.topRight),
        4: () => setBracket(regions?.bottomRight),
        5: () => setBracket(regions?.finalFour)
    }), [regions]);

    // Use Effect to go back to top left on bracket change
    useEffect(() => {
        if (round1Ref.current && bracket) {
            setTimeout(() => {
                // Vertical scroll
                scrollToRound(round1Ref);
    
                // Add horizontal scroll reset
                const container = document.querySelector('.regional-bracket-container');
                if (container) {
                    container.scrollTo({
                        left: 0, // Reset to start of bracket
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    }, [bracket]);
    

    // Update roundDash and bracket based on URL when component mounts
    useEffect(() => {
        const navParam = searchParams.get('bracketNav');
        if (navParam) {
            const roundNumber = parseInt(navParam, 10);
            setRoundDash(roundNumber);
            setRoundDashPosition[roundNumber]?.();
        } else {
            setRoundDash(1);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('bracketNav', '1');
            setSearchParams(newParams);
            setRoundDashPosition[1]?.();
        }
    }, [searchParams, setSearchParams, regions, setRoundDashPosition]);

    const tournamentGames = tournamentSchedule?.Games;

    const picksToMake = gameData?.gameParameters?.picksToMake ?? masterPoolData?.gameParameters?.picksToMake;
    const tiebreakerCount = gameData?.gameParameters?.tiebreakerCount ?? masterPoolData?.gameParameters?.tiebreakerCount;

    //const picksThisRegion = pickState?.filter(pick => pick.bracket === bracket)?.length;
    const gamesThisRegion = tournamentGames?.filter(game => game.Bracket === bracket)?.length;

    const regionInfo = masterPoolData?.gameParameters?.regionInfo;

    const handleGameClick = (teamId, bracket, round, tournamentDisplayOrder, seedNumber, globalGameId) => {
        let newPicks = [...pickState];
        
        const existingPickIndex = newPicks.findIndex(pick => 
            pick.bracket === bracket && 
            pick.round === round && 
            pick.displayOrder === tournamentDisplayOrder
        );
        
        if (existingPickIndex !== -1 && newPicks[existingPickIndex].teamId === teamId) {
            return;
        }
        
        const newPick = {
            teamId,
            bracket,
            round,
            displayOrder: tournamentDisplayOrder,
            teamSeed: seedNumber,
            globalGameId
        };
        
        let finalPicks;
        if (existingPickIndex !== -1) {
            finalPicks = newPicks.filter(pick => 
                pick.round <= round || pick.teamId !== newPicks[existingPickIndex].teamId
            );
            finalPicks[existingPickIndex] = newPick;
        } else {
            finalPicks = [...newPicks, newPick];
        }
    
        const getMaxPicksForRound = (roundNum) => {
            // For Final Four bracket
            if (bracket?.includes('-')) {
                switch(roundNum) {
                    case 5: return 2;  // Each Final Four game
                    case 6: return 1;  // Championship game
                    default: return 0;
                }
            }
            // For Championship game (when bracket is null)
            if (!bracket && roundNum === 6) {
                return 1;
            }
            
            // For regular brackets
            switch(roundNum) {
                case 1: return 8;
                case 2: return 4;
                case 3: return 2;
                case 4: return 1;
                case 5: return 2;
                case 6: return 1;
                default: return 0;
            }
        };
    
        const isChampionshipRound = round === 6;
        
        const previousRoundPicks = pickState?.filter(pick => 
            isChampionshipRound ? pick.round === 6 : round === 5 ? pick.round === 5 : (pick.bracket === bracket && pick.round === round)
        )?.length;
    
        const nextRoundPicks = finalPicks?.filter(pick => 
            isChampionshipRound ? pick.round === 6 : pick.round === 5 ? pick.round === 5 : (pick.bracket === bracket && pick.round === round)
        )?.length;
    
        const maxPicksForRound = getMaxPicksForRound(round);
 
        setPickState(finalPicks);

        if (round === 6) {
        requestAnimationFrame(() => {
            const championshipElement = document.querySelector('.regional-bracket-column-games-second .regional-bracket-game');
            const container = document.querySelector('.regional-bracket-container');
            
            if (championshipElement && container) {
                // Vertical scroll
                window.scrollTo({
                    top: championshipElement.getBoundingClientRect().top + window.pageYOffset - 150,
                    behavior: 'smooth'
                });

                // Horizontal scroll
                const containerRect = container.getBoundingClientRect();
                const elementRect = championshipElement.getBoundingClientRect();
                
                container.scrollTo({
                    left: elementRect.left - containerRect.left + container.scrollLeft,
                    behavior: 'smooth'
                });
            }
        });
    }

        if (
            (isChampionshipRound && nextRoundPicks === maxPicksForRound) || 
            (!isChampionshipRound && nextRoundPicks === maxPicksForRound && previousRoundPicks < maxPicksForRound)
        ) {
            const roundRefs = {
                1: round2Ref,
                2: round3Ref,
                3: round4Ref,
                4: round5Ref,
                5: round2Ref,
                6: round3Ref
            };
        
            const nextRoundRef = roundRefs[round];
            
            if (nextRoundRef?.current) {
                setTimeout(() => {
                    const gameElement = nextRoundRef.current.querySelector('.regional-bracket-game');
                    const container = document.querySelector('.regional-bracket-container');
                    
                    if (gameElement && container) {
                        // Vertical scroll
                        container.scrollTo({
                            top: 0,
                            behavior: 'instant'
                        });
        
                        requestAnimationFrame(() => {
                            const rect = gameElement.getBoundingClientRect();
                            document.documentElement.scrollTo({
                                top: rect.top + window.pageYOffset - 150,
                                behavior: 'smooth'
                            });
                        });
        
                        // Horizontal scroll
                        const nextColumnRect = nextRoundRef.current.getBoundingClientRect();
                        const scrollLeft = container.scrollLeft + nextColumnRect.width + 75;
                        
                        container.scrollTo({
                            left: scrollLeft,
                            behavior: 'smooth'
                        });
                    }
                }, 200);
            }
        }
        
    
        const currentPicksCount = pickState.filter(pick => pick.bracket === bracket).length;
        const finalPicksCount = finalPicks.filter(pick => pick.bracket === bracket).length;
        
        const becomingComplete = currentPicksCount < gamesThisRegion && finalPicksCount === gamesThisRegion;
     
    };

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    useEffect(() => {
        if (!hasJoinDeadlinePassed) {
            navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home`);
        }
    }, [isUserInThisPool, gameAddress, masterPoolId, gameId, navigateToTop, hasJoinDeadlinePassed]);

    return (
        <Box>
            {!bracket && tournamentGames?.length > 0 && !showNotReadyModal ? (
                <LoadingScreen />
            ) : (
                <>
                        <div style={{ position: 'relative', zIndex: 10 }}>
                            <div
                                ref={observerRef}
                                style={{
                                    height: '1px',
                                    position: 'relative',
                                }}
                            />
                            <div
                                ref={containerRef}
                                style={{
                                    height: '25px',
                                    backgroundColor: '#fff',
                                    width: '100%',
                                    position: isStuck ? 'fixed' : 'relative',
                                    top: isStuck ? '80px' : '0',
                                    marginBottom: '16px',
                                    zIndex: 10,
                                }}
                            >
                                <BracketNavBar
                                    rounds={regions ? Object.values(regions).filter(Boolean) : []}
                                    roundDash={roundDash}
                                    setRoundDash={setRoundDash}
                                    setRoundDashPosition={setRoundDashPosition}
                                    pickState={pickState}
                                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                                    regionInfo={regionInfo}
                                />
                            </div>
                        </div>
                    
                    {/* {!isMobile && tournamentGames?.length > 0 && (
                            <BracketNavBar
                                rounds={regions ? Object.values(regions).filter(Boolean) : []}
                                roundDash={roundDash}
                                setRoundDash={setRoundDash}
                                setRoundDashPosition={setRoundDashPosition}
                            />
                        )} */}

                    <Typography
                        sx={{
                            marginTop: '40px',
                            fontSize: '24px',
                            fontWeight: '700',
                        }}
                    >
                        {selectedUserBracketInfo?.username}'s Bracket
                    </Typography>

                    <Box sx={{ position: 'relative' }}>
                        {bracket !== "Final Four" && (
                            <SixteenTeamBracket
                                bracket={bracket}
                                games={tournamentGames}
                                teams={teams}
                                league={league}
                                handleGameClick={handleGameClick}
                                round1Ref={round1Ref}
                                round2Ref={round2Ref}
                                round3Ref={round3Ref}
                                round4Ref={round4Ref}
                                round5Ref={round5Ref}
                                setRegionCompleteModalOpen={null}
                                pickState={selectedUserBracketInfo?.picks}
                                
                            />
                        )}
                        {bracket === "Final Four" && (
                            <FourTeamBracket
                                bracket={bracket}
                                games={tournamentGames}
                                teams={teams}
                                league={league}
                                handleGameClick={handleGameClick}
                                topLeft={regions?.topLeft}
                                bottomLeft={regions?.bottomLeft}
                                topRight={regions?.topRight}
                                bottomRight={regions?.bottomRight}
                                round1Ref={round1Ref}
                                round2Ref={round2Ref}
                                round3Ref={round3Ref}
                                pickState={selectedUserBracketInfo?.picks}
                                overrideTiebreaker={true}
                                userTiebreaker={selectedUserBracketInfo?.tiebreaker}
                            />
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ViewEntrantBracket;