import React, { useState } from "react";
import { Typography, Button, Box, Modal } from "@mui/material";
import PinCodeEntry from "../PinCodeEntry";
import JoinMainGameOptIn from "./JoinMainGameOptIn";
import CheckboxRadioGroup from "./CheckboxRadioGroup";



/**
 * Third page of the Join Modal that handles final confirmation and additional options
 * @param {Object} props
 * @param {Function} props.setPage - Function to change the current page of the modal
 * @param {Function} props.handleJoin - Function to handle the join action
 * @param {boolean} props.textOptIn - Whether user wants text notifications
 * @param {Function} props.setTextOptIn - Function to update text notification preference
 * @param {boolean} props.hasSubmitted - Whether the form has been submitted
 * @param {boolean} props.isUserInMasterPool - Whether user is already in the main game
 * @param {number} props.startingPage - Initial page of the modal
 * @param {number} props.entriesToEnterCount - Number of entries user wants to enter
 * @param {number} props.howManyTotalEntries - Total number of entries user has
 * @param {Function} props.setAddMainPoolEntry - Function to update main pool entry preference
 * @param {boolean} props.addMainPoolEntry - Whether to add entry to main pool
 * @param {boolean} props.isThisPoolPartyGame - Whether this is a party game
 * @param {string} props.password - PIN code for protected pools
 * @param {Array} props.pin - Current PIN entry
 * @param {Function} props.setPin - Function to update PIN
 * @param {boolean} props.forceMainGameEntry - Whether main game entry is mandatory
 * @param {string} props.gameName - Name of the game being joined
 * @param {string} props.mainGameJoinLogo - Logo for main game
 * @param {string} props.gameJoinLogo - Logo for specific game
 * @param {string} props.mainGameName - Name of the main game
 * @param {boolean} props.sharingInfo - Whether info sharing is enabled
 * @param {string} props.sponsor - Name of the sponsor
 * @param {boolean} props.joinEmailList - Whether user wants to join email list
 * @param {Function} props.setJoinEmailList - Function to update email list preference
 */
const JoinModalPageThree = ({
    setPage,
    handleJoin,
    textOptIn,
    setTextOptIn,
    hasSubmitted,
    isUserInMasterPool,
    startingPage,
    entriesToEnterCount,
    howManyTotalEntries,
    setAddMainPoolEntry,
    addMainPoolEntry,
    isThisPoolPartyGame,
    password,
    pin,
    setPin,
    forceMainGameEntry,
    gameName,
    mainGameJoinLogo,
    gameJoinLogo,
    mainGameName,
    sharingInfo,
    sponsor,
    joinEmailList,
    setJoinEmailList,
    masterPoolData,
    isUserInThisPool,
    creation,
}) => {
    //console.log("Force Main Game Entry: ", forceMainGameEntry);
    
    //const theme = useTheme();
    //const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // State for PIN validation
    const [pinError, setPinError] = useState(false);
    const [pinSuccess, setPinSuccess] = useState(false);
    const [textOptInInfoOpen, setTextOptInInfoOpen] = useState(false);
    //console.log("Join Email List: ", joinEmailList, "Text Modal Open: ", textOptInInfoOpen);

    const textOptInInfo = masterPoolData?.textOptInInfo;

    /**
     * Handles PIN code completion
     * Validates PIN against password and updates error/success states
     */
    const handlePinComplete = (completedPin, isPinComplete) => {
        if (!isPinComplete) {
            setPinSuccess(false);
            setPinError(false);
            return;
        }
        
        if (completedPin === password) {
            setPinError(false);
            setPinSuccess(true);
        } else {
            setPinError(true);
            setPinSuccess(false);
        }
    };

    /**
     * Handles back button click
     * Returns to page 1 if user has no entries, otherwise to page 2
     * Resets PIN entry when navigating back
     */
    const handleBackClick = () => {
        //console.log("howManyTotalEntries: ", howManyTotalEntries);
        if (howManyTotalEntries === 0) {
            //console.log("Setting Page to 1");
            setPage(1);
            setPin(new Array(4).fill(''));
        } else {
            //console.log("Setting Page to 2");
            setPage(2);
            setPin(new Array(4).fill(''));
        }
    }

    return (
        <>
            <Box>
                {/* Game Logo Display */}
                <Box
                    sx={{
                        margin: '0 auto',
                    }}
                >
                    <img
                        src={gameJoinLogo ? gameJoinLogo : mainGameJoinLogo}
                        alt={gameName}
                        loading="lazy"
                        style={{
                            display: 'block',
                            width: '90%',
                            height: 'auto',
                            maxWidth: '320px',
                            maxHeight: '125px',
                            objectFit: 'contain', 
                            marginBottom: '16px',
                            margin: '0 auto',
                        }}
                    />
                </Box>

                {/* Confirmation Message */}
                {(!password || isUserInThisPool) && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        textAlign: 'center',
                        marginBottom: '16px',
                    }}
                >
                    {(creation && startingPage === 3)
                        ? `Congratulations, you are now the host of ${gameName}!`
                        : (!isUserInThisPool 
                            ? `Join ${gameName}?` 
                            : `Would you like to add ${entriesToEnterCount} ${entriesToEnterCount > 1 ? 'entries' : 'entry'} to ${gameName}?`
                        )
                    }
                </Typography>
                )}

                {/* Optional Main Game Opt-in Section */}
                {!isUserInMasterPool && !isThisPoolPartyGame && !forceMainGameEntry && (
                    <JoinMainGameOptIn
                        addMainPoolEntry={addMainPoolEntry}
                        setAddMainPoolEntry={setAddMainPoolEntry}
                    />
                )}

                {/* PIN Entry Section for Protected Pools */}
                {password && !isUserInThisPool && (
                    <PinCodeEntry
                        pin={pin}
                        setPin={setPin}
                        error={pinError}
                        onComplete={handlePinComplete}
                        gameName={gameName}
                    />
                )}

                {/* Text Reminder Opt-in */}
                {!isUserInMasterPool && (
                    <CheckboxRadioGroup
                        text={'Opt In to Text Reminders?'}
                        choice={textOptIn}
                        setChoice={setTextOptIn}
                        infoIcon={true}
                        setOpen={setTextOptInInfoOpen}
                    />
                )}

                {/* Navigation Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 3,
                        marginTop: '16px',
                        marginBottom: '16px',
                    }}
                >
                    {startingPage < 3 && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={hasSubmitted}
                            onClick={handleBackClick}
                            sx={{
                                height: '36px',
                                width: '100px',
                                color: '#fff',
                                backgroundColor: '#002129',
                                '&:hover': {
                                    backgroundColor: '#002129',
                                },

                            }}
                        >
                            BACK
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={hasSubmitted || (password && !isUserInThisPool && !pinSuccess)}
                        onClick={handleJoin}
                        sx={{
                            height: '36px',
                            width: '100px',
                            color: '#fff',
                            backgroundColor: '#002129',
                            '&:hover': {
                                backgroundColor: '#002129',
                            },
                        }}
                    >
                        JOIN
                    </Button>
                </Box>

                 {/* Forced Main Game Entry Section */}
                 {!isUserInMasterPool && !isThisPoolPartyGame && forceMainGameEntry && (
                <>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'center',
                            }}
                        >
                            BONUS
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '400',
                                textAlign: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            Your {entriesToEnterCount > 1 ? 'first' : ''} entry will be automatically entered into the {mainGameName} - our FREE sitewide contest with prizes.
                        </Typography>
                        {/* Conditional Email List Opt-in */}
                        {sharingInfo && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        textAlign: 'center',
                                        marginTop: '16px',
                                    }}
                                >
                                    Would you like to join {sponsor}'s email list?
                                </Typography>
                                <CheckboxRadioGroup
                                    choice={joinEmailList}
                                    setChoice={setJoinEmailList}
                                />
                            </>
                        )}
                    </>
                )}
            </Box>

            {/* Text Opt-in Info Modal */}
            <Modal
                open={textOptInInfoOpen}
                onClose={() => setTextOptInInfoOpen(false)}
                aria-labelledby="text-opt-in-info-modal"
                aria-describedby="text-opt-in-info-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        id="text-opt-in-info-modal"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '16px',
                            textAlign: 'center',
                        }}
                    >
                        Text Reminder Opt-in
                    </Typography>
                    {textOptInInfo && textOptInInfo?.text.map((text, index) => (
                        <Typography
                            key={index}
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                marginBottom: '16px',
                                textAlign: 'center',
                            }}
                        >
                            {text}
                        </Typography>
                    ))}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setTextOptInInfoOpen(false)}
                        sx={{
                            margin: '0 auto',
                        }}
                    >
                        CLOSE
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default JoinModalPageThree;