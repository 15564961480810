import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "../../config";

// Function to handle the POST request
export const useSaveTiersPicks = () => {
    return useMutation({
        mutationFn: submitTierPicksRequest,
        onError: (error) => {
            console.error('Error submitting picks:', error);
        },
        // onSuccess: (data) => {
        //     console.log('Picks submitted successfully:', data);
        // }
    });
}

const submitTierPicksRequest = async (pickData) => {
    const authorizationHeader = localStorage.getItem('authorization');

    const response = await axios.post(`${apiUrl}/games/save-tiers-picks`, pickData, {
        headers: {
            Authorization: authorizationHeader,
            'Content-Type' : 'application/json'
        }
    });
    // console.log('Picks submitted successfully:', response.data);
    return response.data.data;
}