import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModalNew';

const TiebreakerInfo = () => {

    const [tiebreakInfoOpen, setTiebreakInfoOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '0px',
                    marginBottom: '12px',
                    cursor: 'pointer',
                }}
                onClick={() => setTiebreakInfoOpen(true)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '18px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        color: '#00AAD1',
                        marginRight: '5px',
                        marginTop: '10px',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <InfoIcon
                    sx={{
                        color: '#00AAD1',
                        fontSize: isMobile ? '16px' : '20px',
                    }}
                />
            </Box>

            <TiebreakerModal
                tiebreakInfoOpen={tiebreakInfoOpen}
                setTiebreakInfoOpen={setTiebreakInfoOpen}
            />
        </>
    );
}

export default TiebreakerInfo;