import React from 'react';
import { useSportsBookContext } from './context/sportsBookContext';
// import './styles/styles.scss';
import nhlGameOddsByDate from './data/nhlData/nhlGameOdds.json'; // Import the JSON file
import nhlTeams from './data/nhlData/nhlTeams.json'; // Import the JSON file
import nhlGamesByDate from './data/nhlData/nhlGamesByDate.json'; // Import the JSON file
import nhlStadiums from './data/nhlData/nhlStadiums.json'; // Import the JSON file

const NHLSportsBook = () => {

    const formatNumber = (number) => (number >= 0 ? `+${number}` : `${number}`);


  function filterGamesForDisplay(gameOddsArray) {
    const currentDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);

    return gameOddsArray
      .filter(game => {
        const gameDate = new Date(game.DateTime);

        // Check if the game is scheduled for today and hasn't started
        if (
          (gameDate.getDate() === currentDate.getDate() && gameDate > currentDate) ||
          (gameDate.getDate() === tomorrow.getDate() && gameDate.getMonth() === tomorrow.getMonth() && gameDate.getFullYear() === tomorrow.getFullYear())
        ) {
          // Check if the required odds are not all null for the specified sportsbook (SportsbookId: 22)
          const consensusOdds = game.PregameOdds.find(odd => odd.SportsbookId === 22);

          if (consensusOdds &&
            consensusOdds.HomeMoneyLine !== null &&
            consensusOdds.AwayMoneyLine !== null &&
            consensusOdds.HomePointSpread !== null &&
            consensusOdds.AwayPointSpread !== null &&
            consensusOdds.OverUnder !== null) {
            return true;
          }
        }

        return false;
      })
      .map(game => game.GlobalGameId); // Extract GameIds from the filtered games
  }

  // Example usage
  const filteredGameIds = filterGamesForDisplay(nhlGameOddsByDate);
  console.log(filteredGameIds);



  function NhlGamblingDisplay(globalTeamID, nhlTeams) {
    const team = nhlTeams.find(team => team.GlobalTeamID === globalTeamID);

    if (team) {
      let key = team.Key;

      // Check if the first two letters of Key are "NY"
      if (key.startsWith("NY")) {
        key = key.substring(0, 2); // Take only the first two letters
      } else if (key === "VGK") {
        key = "VEG"; // Replace "VGK" with "VEG"
      }

      return `${key} ${team.Name}`;
    } else {
      return 'Team not found';
    }
  }

  //Example usage
  //const globalTeamIDToDisplay = 30000014; // Replace with the desired GlobalTeamID
  //const displayString = NhlGamblingDisplay(globalTeamIDToDisplay, nhlTeams);
  //console.log(displayString);

  function getChannelByGameId(gameId, nhlGamesByDate) {
    const game = nhlGamesByDate.find(game => game.GlobalGameID === gameId);

    if (game) {
      const channel = game.Channel;
       return channel;
      }

    return null; // Return null if stadium information is not found
  }

  // Example usage of getChannelByGameId
  //const gameIdToFind = 30020739 ; // Replace with the actual gameId you want to find
  //const channel = getChannelByGameId(gameIdToFind, nhlGamesByDate);

  //if (channel !== null) {
    //console.log(`The channel for game ID ${gameIdToFind} is: ${channel}`);
  //} else {
    //console.log(`Channel information not found for game ID ${gameIdToFind}`);
  //};

  function getStadiumInfoByGameId(gameId, nhlGamesByDate, nhlStadiums) {
    const game = nhlGamesByDate.find(game => game.GlobalGameID === gameId);

    if (game) {
      const stadiumId = game.StadiumID;

      if (stadiumId) {
        const stadium = nhlStadiums.find(stadium => stadium.StadiumID === stadiumId);

        if (stadium) {
          return {
            Name: stadium.Name,
            City: stadium.City,
            State: stadium.State,
            Country: stadium.Country,
          };
        }
      }
    }

    return null; // Return null if stadium information is not found
  }

  // Example usage of the function
  //const gameId = 30020747; // Replace with the actual game ID you want to query
  //const stadiumInfo = getStadiumInfoByGameId(gameId, nhlGamesByDate, nhlStadiums);

  //if (stadiumInfo) {
    //console.log('Stadium Information:');
    //console.log('Name:', stadiumInfo.Name);
    //console.log('City:', stadiumInfo.City);
    //console.log('State:', stadiumInfo.State);
    //console.log('Country:', stadiumInfo.Country);
  //} else {
    //console.log('Stadium information not found for the specified game ID.');
  //};

  // Function to convert American odds to decimal odds
  function americanToDecimalOdds(americanOdds) {
    return americanOdds >= 0 ? (americanOdds / 100) + 1 : (100 / Math.abs(americanOdds)) + 1;
  };

  // Function to construct betsToDisplay object
  function constructBetsToDisplay(gameIds, gameOddsArray, nhlTeams, nhlGamesByDate, nhlStadiums) {
    const betsToDisplay = [];

    gameIds.forEach(gameId => {
      const game = gameOddsArray.find(game => game.GlobalGameId === gameId);

      if (game) {
        const {
          DateTime,
          GlobalAwayTeamId,
          GlobalHomeTeamId,
          PregameOdds,
        } = game;

        const awayTeamName = NhlGamblingDisplay(GlobalAwayTeamId, nhlTeams);
        const homeTeamName = NhlGamblingDisplay(GlobalHomeTeamId, nhlTeams);

        // Filter odds for SportsbookId 22
        const odds22 = PregameOdds.filter(odd => odd.SportsbookId === 22);

        let gamesAvailable = {};

        gamesAvailable = {
          DateTime,
          Channel: getChannelByGameId(gameId, nhlGamesByDate),
          GlobalAwayTeamId,
          GlobalHomeTeamId,
          AwayTeamName: awayTeamName,
          HomeTeamName: homeTeamName,
          StadiumInfo: getStadiumInfoByGameId(gameId, nhlGamesByDate, nhlStadiums),
          Sport: "Hockey",
          League: "NHL",
          BetType: {
            SPREAD: odds22
              .filter(odd => odd.OddType === "pregame" && odd.HomePointSpreadPayout !== null && odd.AwayPointSpreadPayout !== null)
              .map(odd => ([
                {
                  BetChoice: "AWAY",
                  Line: odd.AwayPointSpread,
                  Odds: odd.AwayPointSpreadPayout,
                  DecimalOdds: americanToDecimalOdds(odd.AwayPointSpreadPayout),
                },
                {
                  BetChoice: "HOME",
                  Line: odd.HomePointSpread,
                  Odds: odd.HomePointSpreadPayout,
                  DecimalOdds: americanToDecimalOdds(odd.HomePointSpreadPayout),
                },
              ])),
            MONEYLINE: odds22
              .filter(odd => odd.OddType === "pregame" && odd.HomeMoneyLine !== null && odd.AwayMoneyLine !== null)
              .map(odd => ([
                {
                  BetChoice: "AWAY",
                  Line: null,
                  Odds: odd.AwayMoneyLine,
                  DecimalOdds: americanToDecimalOdds(odd.AwayMoneyLine),
                },
                {
                  BetChoice: "HOME",
                  Line: null,
                  Odds: odd.HomeMoneyLine,
                  DecimalOdds: americanToDecimalOdds(odd.HomeMoneyLine),
                },
              ])),
            TOTAL: odds22
              .filter(odd => odd.OddType === "pregame" && odd.OverPayout !== null && odd.UnderPayout !== null)
              .map(odd => ([
                {
                  BetChoice: "OVER",
                  Line: odd.OverUnder,
                  Odds: odd.OverPayout,
                  DecimalOdds: americanToDecimalOdds(odd.OverPayout),
                },
                {
                  BetChoice: "UNDER",
                  Line: odd.OverUnder,
                  Odds: odd.UnderPayout,
                  DecimalOdds: americanToDecimalOdds(odd.UnderPayout),
                },
              ])),
          },
        };
        betsToDisplay.push(gamesAvailable);
      }


    });

    console.log('NHL BETS', betsToDisplay);

    return betsToDisplay;
  }

  //const filteredGameIds = [30020747];
  // Example usage
  const betsToDisplay = constructBetsToDisplay(filteredGameIds, nhlGameOddsByDate, nhlTeams, nhlGamesByDate, nhlStadiums);
  console.log(betsToDisplay);


  const formatTimestamp = (timestamp) => {
    const options = {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      weekday: 'short', // Specify 'short' for abbreviated day name
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp));
    return formattedDate;
  };


  const getWikipediaLogoUrl = (teamName) => {
    const team = nhlTeams.find((team) => team.Name && teamName.includes(team.Name));
    console.log(team, teamName);
    return team ? team.WikipediaLogoUrl : null;
  };
  const BetUi = () => (
    <div>
        <div style={{display:'flex', maxWidth: 800, margin:'0 auto', color:'#dbdbdb'}}>
            <p style={{width:225}}>GAME</p>
            <div style={{display:'flex', justifyContent:'space-evenly', width:'100%' }}>
                <p>SPREAD</p>
                <p>TOTAL</p>
                <p>MONEYLINE</p>
            </div>
        </div>
        {betsToDisplay.map((bet, index) => {

            return(
                <div className='bet-container' key={index}>
                    <div style={{display:'flex', alignItems:'center', marginBottom:10}}>
                        <p className='team-block' style={{color:'greenyellow', display:'flex', alignItems:'center'}}><img style={{display:'block', width:35, marginRight:10}} src={getWikipediaLogoUrl(bet.AwayTeamName)} alt={`Logo for ${bet.AwayTeamName}`} />{bet.AwayTeamName}</p>
                        <div className='bet-block'>
                            <p>{formatNumber(bet.BetType.SPREAD[0][0].Line)}  {formatNumber(bet.BetType.SPREAD[0][0].Odds)} </p>
                        </div>
                        <div className='bet-block'>
                            <p>O {bet.BetType.TOTAL[0][0].Line}  {formatNumber(bet.BetType.TOTAL[0][0].Odds)}</p>
                        </div>
                        <div className='bet-block'>
                            <p>{formatNumber(bet.BetType.MONEYLINE[0][0].Odds)}</p>
                        </div>
                    </div>

                    <div style={{display:'flex', alignItems:'center'}}>
                        <p className='team-block'  style={{color:'greenyellow', display:'flex', alignItems:'center'}}><img style={{display:'block', width:35, marginRight:10}} src={getWikipediaLogoUrl(bet.HomeTeamName)}/>{bet.HomeTeamName}</p>
                        <div className='bet-block'>
                            <p>{formatNumber(bet.BetType.SPREAD[0][1].Line)}  {formatNumber(bet.BetType.SPREAD[0][1].Odds)}</p>
                        </div>
                        <div className='bet-block'>
                            <p>U {bet.BetType.TOTAL[0][1].Line}  {formatNumber(bet.BetType.TOTAL[0][1].Odds)}</p>
                        </div>
                        <div className='bet-block'>
                            <p>{formatNumber(bet.BetType.MONEYLINE[0][1].Odds)}</p>
                        </div>
                    </div>
                    <p style={{color:'greenyellow'}}>{formatTimestamp(bet.DateTime)}</p>


                </div>
            )
        })
    }
    </div>
  );

  return (
    <div>
      <h1>NHLSportsBook </h1>
      <BetUi />
    </div>
  );
};

export default NHLSportsBook;