import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useCreateAPoolContext } from '../context/CreateAPoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GameNameComponent from '../CreationComponents/GameNameComponent';
import PinCodeComponent from '../CreationComponents/PinCodeComponent';
import MaxEntriesComponent from '../CreationComponents/MaxEntriesComponent';
import AllowReferralsComponent from '../CreationComponents/AllowReferralsComponent';
import HostNoteComponent from '../CreationComponents/HostNoteComponent';
import { Create } from '@mui/icons-material';

const CreateAQuickPickGame = () => {

    const {
        handleCreatePool,
        groupName,
        setGroupName,
        groupNameError,
        setGroupNameError,
        usePinCode,
        setUsePinCode,
        pinCode,
        setPinCode,
        maxEntries,
        setMaxEntries,
        allowReferrals,
        setAllowReferrals,
        hostNote,
        setHostNote,
        hostNoteError,
        setHostNoteError,
        hasSubmitted,
    } = useCreateAPoolContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //console.log("PIN CODE", pinCode);

    const maxAllowedEntries = 1; // FIX THIS LATER!!!!!!!!!!!!!!!!!!!

    // Validate if user can Create Group
    // 1. Group Name is required and not groupNameError
    // 2. If usePinCode is true, pinCode must be 4 digits
    // 3. maxEntries must be greater than 0
    // 4. allowReferrals must be true or false
    const canCreateGroup = () => {
        if (groupName && !groupNameError) {
            if (usePinCode === true && pinCode.length !== 4) {
                return false;
            }
            if (maxEntries <= 0 && maxAllowedEntries > 1) {
                return false;
            }
            if (allowReferrals === null) {
                return false;
            }
            return true;
        }
        return false;
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '32px',
            }}
        >
            <Typography
                sx={{
                    fontSize: isMobile ? '24px' : '32px',
                    fontWeight: '700',
                    marginBottom: '16px',
                }}
            >
                Create Your Quick Pick Pool
            </Typography>

            <GameNameComponent
                groupName={groupName}
                setGroupName={setGroupName}
                groupNameError={groupNameError}
                setGroupNameError={setGroupNameError}
                order={1}
            />

            <PinCodeComponent
                usePinCode={usePinCode}
                setUsePinCode={setUsePinCode}
                pinCode={pinCode}
                setPinCode={setPinCode}
                order={2}
            />

            {/* <MaxEntriesComponent
                maxAllowedEntries={maxAllowedEntries}
                maxEntries={maxEntries}
                setMaxEntries={setMaxEntries}
                order={3}
            /> */}

            <AllowReferralsComponent
                allowReferrals={allowReferrals}
                setAllowReferrals={setAllowReferrals}
                order={3}
            />

            <HostNoteComponent
                hostNote={hostNote}
                setHostNote={setHostNote}
                hostNoteError={hostNoteError}
                setHostNoteError={setHostNoteError}
                order={4}
            />

            <Button
                variant="contained"
                color="primary"
                disabled={!canCreateGroup() || hasSubmitted}
                onClick={handleCreatePool}
                sx={{
                    width: '120px',
                    margin: '16px auto',
                    whiteSpace: 'nowrap',
                    color: 'white',
                    backgroundColor: '#002129',
                    '&:hover': {
                        backgroundColor: '#002129',
                    },
                }}
            >
                CREATE POOL
            </Button>
        </Box>
    )
};

export default CreateAQuickPickGame;