import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import navbarLogo from "../../assets/images/navbar-logo.svg";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../redux/actions/loginImport";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useGetUserData } from "../BirdiePool/hooks/index";
import { useGetPrivatePools } from "./../PrivatePools/hooks/useGetPrivatePools"
import { useGetPublicPools } from "./../PublicPools/hooks/useGetPublicPools"
import { fetchGames } from '../../redux/actions/gameImport';
import UserDropDown from "../cornerDropDown/dropDownMenu";
import { useTheme } from '@mui/material/styles';

import { NavLink } from "react-router-dom";
import {
  ListItem,
  Drawer,
  List,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
// import './styles.scss';
import ThemeProvider from "../../contexts/ThemeProvider";
import MyPools from "./MyPools";
import BirdiePoolsDropdown from "./BirdiePoolsDropdown";
import { useNavigate } from "react-router-dom";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import MyPoolsDropdown from "./MyPoolsDropdown";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useMediaQuery from '@mui/material/useMediaQuery';

const SubNav = () => {
   const theme = useTheme();
  const activeStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    background: "transparent",
    color: "#FFC60A", // Change as needed for active link
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const NavLinkItem = (props) => {
    const { label, path } = props;
    const navigate = useNavigate();

    const handleLinkClick = (e) => {
      e.preventDefault(); // Prevent the default anchor behavior
      window.scrollTo(0, 0); // Scroll to the top of the page
      navigate(path); // Navigate to the specified path
    };

    return (
      <NavLink
        style={({ isActive }) =>
          isActive && path
            ? activeStyle
            : {
                color: "var(--color-white)",
                textDecoration: "none",
                height: 38,
                alignItems: "center",
                display: "flex",
                background: "transparent",
              }
        }
        variant="text"
        to={path}
        onClick={handleLinkClick} // Attach the custom click handler
      >
        {label}
      </NavLink>
    );
  };
  return (
    <>
    {isMobile?
     <></>
     :
      <ThemeProvider>
        <Box position="fixed" className="sub-nav"
          sx={{
            height: "56px",
            minHeight:"56px",
            alignSelf: "stretch",
            backgroundColor: "#1A1C1E",
            zIndex: "9",
            width: "100%",
            padding:" 0 24px",
            color: "#FFFFFF",
            fontWeight: "600",
            top: '80px'
          }}>
          <Box className="sub-nav-toolbar" sx={{
                minHeight:"56px",
                height: "56px",
              }}>
            <Box
              className="sub-nav-links"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "422px",
                justifyContent: "space-between",
                minHeight:"56px",
                height: "56px",
              }}
            >
              <div style={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
                  <NavLinkItem label="Pool Hall" path="/pool-hall" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
                  <NavLinkItem label="Create Pool" path="/create-a-pool" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
                  <NavLinkItem label="Community Pools" path="/community-pools" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '20px'}}>
                  <NavLinkItem label="Leaderboards" path="/leaderboards" />
                </div>
                {/* <div style={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
                  <NavLinkItem label="Store" path="/merch" />
                </div> */}
                <div style={{ whiteSpace: 'nowrap', marginRight: '20px'}}>
                  <NavLinkItem label="Contact Us" path="/contact" />
                </div>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
            }
    </>
  );
};

export default SubNav;