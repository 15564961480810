import React, { useState, useEffect, useRef } from 'react';
import './BracketBox.scss'; // Import CSS file
import { useDispatch, connect } from 'react-redux';
import {fetchNBAStandings, fetchNBATeams} from '../../redux/actions/dataImport.js';
import {gamePicksSave} from '../../redux/actions/gameImport.js';
import BracketSelectionBox from './BracketBoxWithRadio.js';
import { getUserDataFromLocalStorage } from '../../redux/actions/loginImport.js';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import noTeamLogo from '../../assets/images/no-team-logo.svg';
import BracketRoundNav from '../NBAPlayoffs/bracket/bracketRoundNav.js';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const Brackets = (props) => {
    console.log('NBAPlayoffsContainer props:', props);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();
    const roundOneRef = useRef(null);
    const roundTwoRef = useRef(null);
    const confRef = useRef(null);
    const finalsRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    // const userData = getUserDataFromLocalStorage();
    const { gameId } = useParams(); // Extract the gameId from the URL
  console.log('GameID:', gameId);
    const { NBAStandings, NBATeams } = props;

    const [pickState, setPickState] = useState([]);
    const [seriesLength, setSeriesLength] = useState([]);
    const [deadlineUI, setDeadlineUI] = useState(false);
    const [roundDash, setRoundDash] = useState('ROUND_ONE');
    useEffect(() => {
      if (gameId) {
        const userData = getUserDataFromLocalStorage();
        const existingGameIndex = userData.Games.find(game => game.gameID === gameId);
        console.log("Existing Game Index:", existingGameIndex);
        setPickState(existingGameIndex?.pool?.bracketPicks || []);
        setSeriesLength(existingGameIndex?.pool?.seriesLengths || []);
      } // Pass the gameId to fetchGameById
    }, [dispatch, gameId]);

    function nbaGetKey(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return '';
      }
      const team = NBATeams.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        if(team.Key.startsWith("LA")){
          return "LA";
        } else {
        return team.Key;
      }
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }

    function nbaGetName(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return 'TBD';
      }
      const team = NBATeams.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        if (team.Name.startsWith("Timber")) {
          return "T'Wolves";
        } else {
          return team.Name;
        }
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }

    function nbaGetLogo(globalTeamID) {
      if(String(globalTeamID).startsWith('TBD')){
        return noTeamLogo
      }
      const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
      if (team) {
        return team.WikipediaLogoUrl;
      } else {
        //console.log(`Team with ID ${globalTeamID} not found`);
        return null; // Or handle the missing data appropriately
      }
    }


    // Function to look up anything in NBA Teams by GlobalTeamID.  Can be used for Logo: WikipediaLogoUrl, Key: Key, Name: Name, City: City, and Colors: PrimaryColor, Secondary Color
    function nbaTeamsLookup(globalTeamID) {
      const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
      return {
        Key: team.Key,
        Name: team.Name,
        City: team.City,
        Logo: team.WikipediaLogoUrl,
      }
    }

    const teamInfo = nbaTeamsLookup(20000002);
    console.log("TeamInfo: ", teamInfo);

    const generateUUID = () => {
      const newUUID = uuidv4(); // Generates a new UUID
      console.log(newUUID); // You can use this UUID as needed
      return newUUID;
    };


    function getGameInfoToDisplay(round, conference, displayOrder, pickState) {
      let topTeamPick, bottomTeamPick;

      if (round === 4) {
          topTeamPick = pickState.find(item =>
              item.round === round - 1 &&
              item.conference === "Western"
          );
          bottomTeamPick = pickState.find(item =>
              item.round === round - 1 &&
              item.conference === "Eastern"
          );
      } else {
          // Find the top team for the current game
          topTeamPick = pickState.find(item =>
              item.round === round - 1 &&
              item.conference === conference &&
              item.displayOrder === (displayOrder * 2) - 1
          );

          // Find the bottom team for the current game
          bottomTeamPick = pickState.find(item =>
              item.round === round - 1 &&
              item.conference === conference &&
              item.displayOrder === displayOrder * 2
          );
      }

      // Construct the game info object
      const gameInfo = {
          round: round,
          conference: conference,
          displayOrder: displayOrder,
          topTeamID: topTeamPick ? topTeamPick.id : null,
          topTeamSeed: topTeamPick ? topTeamPick.seed : null, // Assuming seed is stored in the pick object
          bottomTeamID: bottomTeamPick ? bottomTeamPick.id : null,
          bottomTeamSeed: bottomTeamPick ? bottomTeamPick.seed : null, // Assuming seed is stored in the pick object
      };

      return gameInfo;
    }





    function getFirstRoundMatchups(topSeed, bottomSeed, conference, displayOrder) {
      const topTeamID = NBAStandings?.find(team => team.ConferenceRank === topSeed && team.Conference === conference)?.GlobalTeamID;
      let bottomTeamID = NBAStandings?.find(team => team.ConferenceRank === bottomSeed && team.Conference === conference)?.GlobalTeamID;
      const date = new Date();
      const playInCompletionTime = new Date("2024-04-20T02:00:00-04:00");

      if(bottomSeed === 8 && date < playInCompletionTime){
        bottomTeamID = `TBD ${conference}`;
      }

      return {
        round: 1,
        conference: conference,
        displayOrder: displayOrder,
        topTeamID: topTeamID,
        topTeamSeed: topSeed,
        bottomTeamID: bottomTeamID,
        bottomTeamSeed: bottomSeed
      };
    }



    console.log("ON PAGE LOAD!!!!!!!   PickState:", pickState, "SeriesLength:", seriesLength);

    const handleSubmit = async () => {
      const date = new Date();
      const deadline = new Date("2024-04-20T13:00:00-04:00");
      const hasTheDeadlinePassed = date >= deadline;

      if (hasTheDeadlinePassed) {
        console.error("Sorry, the deadline to submit your bracket has passed.");
        setDeadlineUI(true);
        return; // Exit the function early
      }

      let userPicks = {
        gameType: "SuperPickem",
        bracketPicks: pickState,
        seriesLengths: seriesLength,
        gameName: "NBA Playoffs",
        gameId: gameId || generateUUID() // Use existing gameId or generate a new one if not present
      };

      console.log("User Picks:", userPicks);

      try {
        const response = await dispatch(gamePicksSave(userPicks));
        console.log("Picks saved successfully:", response);
        if (!gameId) { // Check if it's a new game creation scenario
          console.log("New game created with ID:", userPicks.gameId);
          navigateToTop(`/nba-super-pickem/SuperPickem/${userPicks.gameId}`, { replace: true });
          window.location.reload(); // Force reload of the page
        } else {
          console.log("Updated existing game");
          window.location.reload(); // Force reload of the page
        }
      } catch (error) {
        console.error("Failed to save picks:", error);
        // Optionally handle error, e.g., show a notification
      }
    };

    const East2nd1 = getGameInfoToDisplay(2, "Eastern", 1, pickState);
    const East2nd2 = getGameInfoToDisplay(2, "Eastern", 2, pickState);
    const East3rd1 = getGameInfoToDisplay(3, "Eastern", 1, pickState);
    const Finals = getGameInfoToDisplay(4, "Finals", 1, pickState);
    const champSelected = pickState.find(item => item.round === 4);
    console.log("ChampSelected: ", champSelected);
    let champName, champLogo, champDisplayName;
    if (champSelected) {
      const champKey = nbaGetKey(champSelected.id);
      champName = nbaGetName(champSelected.id);
      champDisplayName = `${champKey} ${champName}`;
      champLogo = nbaGetLogo(champSelected.id);
        console.log(`Champion: ${champDisplayName} (${champLogo})`);
    }
    const West2nd1 = getGameInfoToDisplay(2, "Western", 1, pickState);
    const West2nd2 = getGameInfoToDisplay(2, "Western", 2, pickState);
    const West3rd1 = getGameInfoToDisplay(3, "Western", 1, pickState);
    const East1st1 = getFirstRoundMatchups(1, 8, "Eastern", 1);
    //console.log("Test Matchups:", East1);
    const East1st2 = getFirstRoundMatchups(4, 5, "Eastern", 2);
    const East1st3 = getFirstRoundMatchups(3, 6, "Eastern", 3);
    const East1st4 = getFirstRoundMatchups(2, 7, "Eastern", 4);
    const West1st1 = getFirstRoundMatchups(1, 8, "Western", 1);
    const West1st2 = getFirstRoundMatchups(4, 5, "Western", 2);
    const West1st3 = getFirstRoundMatchups(3, 6, "Western", 3);
    const West1st4 = getFirstRoundMatchups(2, 7, "Western", 4);

    return (
      <>
      <BracketRoundNav  roundDash={roundDash} setRoundDash={setRoundDash} setRoundDasPosition={{
        ROUND_ONE: () => roundOneRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest' // Use 'start', 'center', 'end', or 'nearest'
        }),
        ROUND_TWO: () => roundTwoRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        }),
        CONF: () => confRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        }),
        FINALS: () => finalsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      }} />

      <div className="conference-title-container">
        <h2 className="conference-title">WEST</h2>
        <h2 className="conference-title right">EAST</h2>
      </div>
      <div className={'bracket-box-container'}>
          <div style={{display:'flex', marginRight: '12px' }}>
              <div className='column-one' ref={roundOneRef}>
                <BracketSelectionBox game={West1st1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength} NBATeams={NBATeams} boxLineClass={"bracket-line-top"} bracketBoxCustom={""}  bracketRightCustom={""} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={West1st2} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={""}  bracketRightCustom={""} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={West1st3} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-top"} bracketBoxCustom={""}  bracketRightCustom={""} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={West1st4} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={""}  bracketRightCustom={""} backBoxLine={"back-line"}/>
              </div>
              <div className='column-two' ref={roundTwoRef}>
                <BracketSelectionBox game={West2nd1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-top"} bracketBoxCustom={"round-two-box-one"}  bracketRightCustom={""} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={West2nd2} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={"round-two-box-two"}  bracketRightCustom={""} backBoxLine={"back-line"}/>
              </div>
              <div className='column-three' ref={confRef}>
                <BracketSelectionBox game={West3rd1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={"round-three-box-one"}  bracketRightCustom={""} backBoxLine={"back-line"}/>
              </div>

          </div>


          <div className={'bottom-bracket'} style={{display:'flex',}}>
            <div className='column-one'>
                <BracketSelectionBox game={East1st1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-top"} bracketBoxCustom={""}  bracketRightCustom={"bracket-right-side-top"} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={East1st2} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={""}  bracketRightCustom={"bracket-right-side-bottom"} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={East1st3} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-top"} bracketBoxCustom={""}  bracketRightCustom={"bracket-right-side-top"} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={East1st4} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={""}  bracketRightCustom={"bracket-right-side-bottom"} backBoxLine={"back-line"}/>
            </div>
            <div className='column-two'>
                <BracketSelectionBox game={East2nd1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-top"}  bracketBoxCustom={"round-two-box-one"} bracketRightCustom={"bracket-right-side-top"} backBoxLine={"back-line"}/>
                <BracketSelectionBox game={East2nd2} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"}  bracketBoxCustom={"round-two-box-two"}  bracketRightCustom={"bracket-right-side-bottom"} backBoxLine={"back-line"}/>
            </div>
            <div className='column-three'>
                <BracketSelectionBox game={East3rd1} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={"round-three-box-one"}  bracketRightCustom={"bracket-right-side-top"} backBoxLine={"back-line"}/>
            </div>
            <div className='column-final' ref={finalsRef}>
              {/* Render champion's name and logo if champSelected */}
              {champSelected ? (
                <>
                  <div className="champ-box" style={{marginTop: '20px',}}>
                      <img src={champLogo} alt="Champion Logo"/>
                  </div>
                  <div className='champ-name'>{champName}</div>
                </>
              ):(
                <>
                  <div className="champ-box" style={{marginTop: '20px' }}>
                    <div className='champ-placeholder'></div>
                  </div>
                  <div className='champ-name'>Champion</div>
                </>
              )
              }
              <BracketSelectionBox  game={Finals} pickState={pickState} setPickState={setPickState} seriesLength={seriesLength} setSeriesLength={setSeriesLength}  NBATeams={NBATeams} boxLineClass={"bracket-line-bottom"} bracketBoxCustom={"round-final"} bracketRightCustom={"bracket-right-side-top"} backBoxLine={"back-line"}/>

            {/* Submit button */}
            <div style={{ marginTop: '20px' }}>
              {/* <Button className={'save-picks-btn'} onClick={handleSubmit}>SAVE PICKS</Button> */}
              {deadlineUI?
                <p>Sorry, the deadline to submit your bracket has passed.</p>
                :
                null
              }
            </div>
          </div>
        </div>
        <div className="conference-title-container mobile">
          <h2 className="conference-title mobile">EAST</h2>
        </div>
      </div>

      </>
  );





  }
  const mapStateToProps = (state) => {
  console.log('PROP STATES', state);
  return {
      games: state.games,
      game: state.game,
      user: state.user,
      sportsData: state.sportsData,
      allUsers: state.login.getUsersForLeaderboardReducer.data,
      nbaTeamsData: state.nbaTeamsReducer,
      nbaStandingsData: state.nbaStandingsReducer,
  }
};

export default Brackets;
