import React from "react";
import { Box, Typography } from "@mui/material";
import "./BracketGame.scss";
import { getTeamLogo, getTeamName } from "../../../utils/SportsData/index.js";
import { DateTime } from "luxon";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext.js";


const BracketGameSweet16 = ({
    bracket,
    round,
    tournamentDisplayOrder,
    games,
    teams,
    league,
    handleGameClick,
    hasJoinDeadlinePassed,
    topLeft,
    bottomLeft,
    topRight,
    bottomRight,
    pickState,
    hideUserPicksOutsideBoxRound = 1,
}) => {
    // const { pickState } = useUserAndGameContext()
    const thisBracketsGame = round < 5
    ? games?.find(g =>
        g.Bracket === bracket &&
        g.Round === round &&
        g.TournamentDisplayOrder === tournamentDisplayOrder
    )
    : round === 5 && tournamentDisplayOrder === 1
    ? games?.find(g =>
        g.Bracket === `${topLeft}-${bottomLeft}` &&
        g.Round === round
    )
    : round === 5 && tournamentDisplayOrder === 2
    ? games?.find(g =>
        g.Bracket === `${topRight}-${bottomRight}` &&
        g.Round === round
    )
    : round === 6
    ? games?.find(g =>
        g.Round === round
    )
    : null;

    const usersTopPick = round === 1
        ? null
        : round === 5
        ? pickState?.find(pick =>
            pick.bracket === (tournamentDisplayOrder === 1 ? topLeft : topRight) &&
            pick.round === round - 1
        )
        : round === 6
        ? pickState?.find(pick =>
            pick.bracket === `${topLeft}-${bottomLeft}` &&
            pick.round === round - 1
        )
        : pickState?.find(pick =>
            pick.bracket === bracket &&
            pick.round === round - 1 &&
            pick.displayOrder === tournamentDisplayOrder * 2 - 1
        );

    const usersBottomPick = round === 1
        ? null
        : round === 5
        ? pickState?.find(pick =>
            pick.bracket === (tournamentDisplayOrder === 1 ? bottomLeft : bottomRight) &&
            pick.round === round - 1,
        )
        : round === 6
        ? pickState?.find(pick =>
            pick.bracket === `${topRight}-${bottomRight}` &&
            pick.round === round - 1
        )
        : pickState?.find(pick =>
            pick.bracket === bracket &&
            pick.round === round - 1 &&
            pick.displayOrder === tournamentDisplayOrder * 2
        );



    const isTopTeamHome = thisBracketsGame?.TournamentDisplayOrderForHomeTeam === "Top";
    const homeTeam = thisBracketsGame?.GlobalHomeTeamID;
    const awayTeam = thisBracketsGame?.GlobalAwayTeamID;
    const isGameClosed = thisBracketsGame?.IsClosed;

    const formatDate = (isoString) => {
        const dt = DateTime.fromISO(isoString, { zone: "America/New_York" });
        
        // Check if time is exactly 12:00:00 or 00:00:00
        if ((dt.hour === 12 || dt.hour === 0) && dt.minute === 0 && dt.second === 0) {
            return "TBD";
        }
        
        return dt.toFormat("M/d '@' h:mma 'ET'");
    };


    const handleTeamName = (thisBracketsGame, teamId, isTop) => {
        if (thisBracketsGame?.Round === 1 && !teamId) {
            if (isTop && isTopTeamHome) {
                return thisBracketsGame?.HomeTeam;
            } else if (isTop && !isTopTeamHome) {
                return thisBracketsGame?.AwayTeam;
            } else if (!isTop && isTopTeamHome) {
                return thisBracketsGame?.AwayTeam;
            } else if (!isTop && !isTopTeamHome) {
                return thisBracketsGame?.HomeTeam;
            } else {
                return getTeamName(league, teamId, teams);
            }
        } else {
            return getTeamName(league, teamId, teams);
        }
    };

    const getFontSize = (teamName, score) => {
        if (!teamName) return {
            fontSize: '14px !important'
        };
    
        // Adjust thresholds based on whether there's a score
        const longThreshold = score ? 18 : 20;
        const mediumThreshold = score ? 13 : 15;
    
        if (teamName.length > longThreshold) {
            return {
                fontSize: '10px !important',
                lineHeight: '1.4 !important'
            };
        } else if (teamName.length > mediumThreshold) {
            return {
                fontSize: '12px !important',
                lineHeight: '1.4 !important'
            };
        } else {
            return {
                fontSize: '14px !important'
            };
        }
    };

    const renderTeamRow = (isTopRow) => {
        // If the game is closed, show the score, otherwise return score as null
        const score = !isGameClosed ? null 
            : isTopTeamHome ?
                (isTopRow ? thisBracketsGame?.HomeTeamScore : thisBracketsGame?.AwayTeamScore) :
                (isTopRow ? thisBracketsGame?.AwayTeamScore : thisBracketsGame?.HomeTeamScore);
        
        const didThisTeamWin = isTopTeamHome ?
            (isTopRow ? thisBracketsGame?.HomeTeamScore > thisBracketsGame?.AwayTeamScore : thisBracketsGame?.AwayTeamScore > thisBracketsGame?.HomeTeamScore) :
            (isTopRow ? thisBracketsGame?.AwayTeamScore > thisBracketsGame?.HomeTeamScore : thisBracketsGame?.HomeTeamScore > thisBracketsGame?.AwayTeamScore);
        
            // For round 1, use the actual game data
        if (round === hideUserPicksOutsideBoxRound) {
            const teamId = isTopTeamHome ? 
                (isTopRow ? homeTeam : awayTeam) : 
                (isTopRow ? awayTeam : homeTeam);
            
            const seedNumber = isTopTeamHome ? 
                (isTopRow ? thisBracketsGame?.HomeTeamSeed : thisBracketsGame?.AwayTeamSeed) :
                (isTopRow ? thisBracketsGame?.AwayTeamSeed : thisBracketsGame?.HomeTeamSeed);
            
            const teamName = handleTeamName(thisBracketsGame, teamId, isTopRow);
            const showTeamInfo = teamId || teamName;
            
            // Check if picked, allowing for both teamId and teamName
            const isPicked = pickState?.some(pick => 
                (pick.teamId === teamId || pick.teamId === teamName) &&
                pick.round === round &&
                (round <= 4 
                    ? pick.displayOrder === tournamentDisplayOrder 
                    : (round === 5 
                        ? (pick.displayOrder === 1 || pick.displayOrder === null) 
                        : pick.displayOrder === 1)
                )
            );
            
            return renderTeamContent(teamId || teamName, seedNumber, teamName, showTeamInfo, isPicked, "pending", score, didThisTeamWin);
        }
    
        // For later rounds, use different logic based on join deadline
        if (!hasJoinDeadlinePassed) {
            // Use usersTopPick and usersBottomPick when join deadline hasn't passed
            const userPick = isTopRow ? usersTopPick : usersBottomPick;
            
            if (!userPick) {
                return renderTeamContent(null, null, null, false, false, "loss", score, didThisTeamWin);
            }
    
            const teamId = userPick?.teamId;
            const seedNumber = userPick?.teamSeed;
            const teamName = isNaN(teamId) && teamId != null 
                ? teamId // Use teamId if it's NaN and not null/undefined
                : getTeamName(league, teamId, teams);
            
            // Check if picked, allowing for both teamId and teamName
            const isPicked = pickState?.some(pick => 
                (pick.teamId === teamId || pick.teamId === teamName) &&
                pick.round === round &&
                (round <= 4 
                    ? pick.displayOrder === tournamentDisplayOrder 
                    : (round === 5 
                        ? (pick.displayOrder === 1 || pick.displayOrder === null) 
                        : pick.displayOrder === 1)
                )
            );
            
            return renderTeamContent(teamId || teamName, seedNumber, teamName, true, isPicked, userPick?.result, score, didThisTeamWin);
        }
    
        // Behavior for when join deadline has passed
        // First, check if there's an actual team in the game
        const teamId = isTopTeamHome ? 
            (isTopRow ? homeTeam : awayTeam) : 
            (isTopRow ? awayTeam : homeTeam);
        
        const seedNumber = isTopTeamHome ? 
            (isTopRow ? thisBracketsGame?.HomeTeamSeed : thisBracketsGame?.AwayTeamSeed) :
            (isTopRow ? thisBracketsGame?.AwayTeamSeed : thisBracketsGame?.HomeTeamSeed);
        
        const gameTeamName = handleTeamName(thisBracketsGame, teamId, isTopRow);

         // If no game team, check the user's pick
         const userPick = isTopRow ? usersTopPick : usersBottomPick;
        
        
        const isPickedNextRound = pickState?.some(pick => 
            (teamId ? (pick.teamId === teamId) : (pick.teamId === userPick?.teamId && userPick?.result !== 'loss')) &&
            pick.round === round
        );
            //console.log("Is Picked Next Round: ", isPickedNextRound, "Seed Number: ", seedNumber, "Team Name: ", gameTeamName, "Round:" , round, "User Pick: ", userPick);  

        // If there's a team in the game, display it
        if (teamId || gameTeamName) {
            return renderTeamContent(
                teamId || gameTeamName, 
                seedNumber, 
                gameTeamName, 
                true, 
                false,
                !userPick ? "loss" : userPick?.result || "pending", 
                score, 
                didThisTeamWin,
                isPickedNextRound
            );
        }

       
        
        // If user has a pick and it's not a loss, display it
        if (userPick && userPick?.result !== "loss") {
            const pickTeamId = userPick.teamId;
            const pickTeamName = getTeamName(league, pickTeamId, teams);
            
            return renderTeamContent(
                pickTeamId, 
                userPick.teamSeed, 
                pickTeamName, 
                true, 
                true,
                userPick?.result || "pending", 
                score, 
                didThisTeamWin,
                isPickedNextRound,
            );
        }

        // If no game team and no valid pick, leave it blank
        return renderTeamContent(null, null, null, false, false, "loss", score, didThisTeamWin);
    };

    const renderTeamContent = (teamId, seedNumber, teamName, showTeamInfo, isPicked, result, score, didThisTeamWin, isPickedNextRound = false) => {
        //console.log("Seed Number: ", seedNumber, "Score: ", score, "Is Picked: ", isPicked, "Result: ", result, "Did This Team Win: ", didThisTeamWin);
        
        return (
            <Box 
                className="super-pickem-bracket-team-row"
                onClick={() => 
                    !hasJoinDeadlinePassed && teamName && 
                    handleGameClick(teamId || teamName, thisBracketsGame?.Bracket, round, thisBracketsGame?.TournamentDisplayOrder, seedNumber, thisBracketsGame?.GlobalGameID)
                }
                sx={{ 
                    cursor: hasJoinDeadlinePassed ? 'default' : 'pointer',
                    backgroundColor: !hasJoinDeadlinePassed 
                    ? (isPicked ? 'rgba(0, 170, 114, 0.3)'  : 'white') 
                    : (result === "win" ? 'rgba(0, 170, 114, 0.3)' 
                    : result === "pending" ? 'white' 
                    : 'rgba(204, 41, 60, 0.3)'),
                }}
            >
                {/* Team Seed */}
                <Box className="super-pickem-bracket-team-seed" sx={{ marginLeft: '4px'}}>
                    {showTeamInfo && (
                        <Typography className="seed-number">
                            {seedNumber}
                        </Typography>
                    )}
                </Box>

                {/* Team Logo */}
                <Box 
                    className="super-pickem-bracket-team-logo-placeholder"
                    style={{
                        position: 'relative',
                    }}
                >
                    {teamId && typeof teamId === 'number' ? (
                        <img 
                            src={getTeamLogo(league, teamId, teams)}
                            alt="Team Logo"
                            className="team-logo"
                        />
                    ) : (
                        <Box 
                            style={{ 
                                width: '100%', 
                                height: '100%', 
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                         </Box>
                    )
                    }
                </Box>

                {/* Team Name/Record */}
                <Box className="super-pickem-bracket-team-name">
                    {showTeamInfo && (
                        <>
                            <Typography 
                                className="team-name"
                                sx={{
                                    ...getFontSize(teamName, score), // Spread the returned object
                                    color: ((score && round === hideUserPicksOutsideBoxRound)  
                                    ? (!isPicked && !didThisTeamWin) 
                                        ? '#002129' 
                                        : (isPicked && didThisTeamWin) 
                                            ? '#007B5E' 
                                            : (isPicked && !didThisTeamWin) 
                                                ? '#CC293C'
                                                : '#002129'
                                    : (score && round !== hideUserPicksOutsideBoxRound && !didThisTeamWin)
                                        ? '#002129' 
                                        : '#002129') + ' !important',
                                    textDecoration: (hasJoinDeadlinePassed && ((round === hideUserPicksOutsideBoxRound && isPicked) || (round !== hideUserPicksOutsideBoxRound && isPickedNextRound))) ? 'underline' : 'none',                  
                                }}
                            >
                                {teamName}
                            </Typography>
                            {score && (
                                <Typography 
                                    className="team-score"
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: didThisTeamWin ? '800' : '400',
                                        marginRight: '4px',
                                        color: (round === hideUserPicksOutsideBoxRound && isPicked) 
                                            ? (didThisTeamWin ? '#007B5E' : '#CC293C') 
                                            : '#002129',
                                    }}    
                                >
                                    {score}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>

            </Box>
        );
    };

    const renderLossContent = (isTopRow) => {
        const usersPick = isTopRow ? usersTopPick : usersBottomPick;
        
        // Create a consistent height container
        return (
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'left',
                    width: '100%',
                    height: '20px', // Match the height of content when present
                    marginLeft: '107px',
                    marginBottom: isTopRow ? '4px' : '0px',
                    marginTop: isTopRow ? '0px' : '4px',
                }}
            >
                {usersPick?.result === "loss" && (
                    <>
                        <img
                            src={getTeamLogo(league, usersPick?.teamId, teams)}
                            alt="Team Logo"
                            style={{ width: '20px', height: '20px', marginRight: '4px' }}
                        />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '700',
                                color: '#CC293C',
                                textDecoration: 'line-through',
                            }}
                        >
                            {usersPick?.teamSeed}
                            <span style={{ marginLeft: '4px' }}>
                                {getTeamName(league, usersPick?.teamId, teams)}
                            </span>
                        </Typography>
                    </>
                )}
                {!usersPick && hasJoinDeadlinePassed && (
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '700',
                            color: '#CC293C',
                        }}
                    >
                        No Pick
                    </Typography>
                )}
            </Box>
        );
    }

    return (
        <>
            {round !== hideUserPicksOutsideBoxRound && (
                renderLossContent(true)
            )}
            <Box className="super-pickem-bracket-game-container">
                {/* Top Team */}
                {renderTeamRow(true)}
                
                {/* Game Information */}
                <Box>
                    <Typography variant="h6" className="super-pickem-game-info">
                        {thisBracketsGame?.IsClosed ? thisBracketsGame?.Status : thisBracketsGame?.DateTime ? formatDate(thisBracketsGame?.DateTime) : "TBD"}
                    </Typography>
                </Box>

                {/* Bottom Team */}
                {renderTeamRow(false)}
            </Box>
            {round !== hideUserPicksOutsideBoxRound && (
                renderLossContent(false)
            )}
        </>
    );
};

export default BracketGameSweet16;