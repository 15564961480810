import React from 'react';
import { useCommunityHubContext } from '../context/CommunityHubContext';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CommunityPoolsCard from './CommunityPoolsCard';

const CommunityOurGames = () => {
    const {
        communityHubData,
    } = useCommunityHubContext();

    const logo = communityHubData?.logo;
    const abbreviation = communityHubData?.abbreviation;
    const sponsorPrimaryColor = communityHubData?.sponsorPrimaryColor;
    const games = communityHubData?.games;
    //console.log("Games in CommunityOurGames: ", games);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 'auto',
                    margin: '0 auto',
                    padding: '24px 0',
                }}
            >
                <img
                    src={logo}
                    alt="Community Hub Logo"
                    style={{
                        maxHeight: isMobile ? '85px' : '150px',
                        display: 'block',
                        margin: '0 auto',
                    }}
                />
                <Typography
                    sx={{
                        color: sponsorPrimaryColor || '#002129',
                        fontSize: isMobile ? '24px' : '32px',
                        fontWeight: '700',
                        textAlign: 'center',
                        marginTop: '16px',
                    }}
                >
                    {abbreviation} Pools
                </Typography>
            </Box>

            {games && games.length > 0 && (
                <Grid
                    container 
                    spacing={3} // 28px spacing between cards (MUI spacing of 3 = 24px, so we'll add a bit more margin)
                    sx={{ 
                        mt: 0.5,
                        justifyContent: 'center', // Center horizontally
                        px: 3.5 // Add 28px padding on the sides (3.5 = 28px in MUI)
                    }}
                >
                    {games?.map((game, index) => (
                        <Grid 
                            item 
                            xs={12}
                            sx={{ 
                                display: 'flex',
                                justifyContent: 'center',
                                // Custom breakpoints for the specific widths you want
                                [theme.breakpoints.up(725)]: {
                                    width: '50%', // 2 per row
                                    maxWidth: '50%',
                                    flexBasis: '50%'
                                },
                                [theme.breakpoints.up(1100)]: {
                                    width: '33.333%', // 3 per row
                                    maxWidth: '33.333%',
                                    flexBasis: '33.333%'
                                }
                            }}
                            key={index}
                        >
                            <CommunityPoolsCard 
                                poolInfo={game} 
                                activePools={[]}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

        </>
    );
};

export default CommunityOurGames;
