import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './UserSubmitBar.scss';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import TwoButtonModal from '../Modals/TwoButtonModal';

const QuickPicksSubmitBar = ({
    handleCancel,
    handleSubmit,
    usingTiebreaker,
    picksThisInterval,
    hasSubmitted,
}) => {
    const {
        pickState,
        interval,
    } = useUserAndGameContext();

    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    const handleCancelClick = () => {
        setCancelModalOpen(true);
    }

    const cancelTitle = "Are you sure you want to cancel all of your unsaved picks?";
    const cancelText = "This cannot be undone.";
    const cancelButtonOneText = "GO BACK";
    const cancelButtonTwoText = "CONFIRM";

    const picksCount = pickState?.filter(pick => Number(pick.interval) === Number(interval) && !pick.tiebreaker)?.length;
    //console.log("Picks Count in QuickPicksSubmitBar: ", picksCount);
    const tiebreakerCount = pickState?.filter(pick => Number(pick.interval) === Number(interval) && pick.tiebreaker)?.length;
    //console.log("Tiebreaker Count in QuickPicksSubmitBar: ", tiebreakerCount);

    const picksThisIntervalWithTiebreaker = picksThisInterval - (usingTiebreaker ? 1 : 0);

    return (
        <>
            <Box className="user-submit-bar-container">
                <Box className="user-submit-bar-content-row">
                    <Box className="user-submit-bar-text-group">
                        <Typography 
                            className="user-submit-bar-typography"
                        >
                            Picks:
                        </Typography>
                        <Typography 
                            className="user-submit-bar-typography"
                            sx={{
                                color: picksCount === picksThisIntervalWithTiebreaker ? "#00AA72" : "#CC293C",
                                marginLeft: '5px'
                            }}
                        >
                            {picksCount}/{picksThisIntervalWithTiebreaker}
                        </Typography>
                        {picksCount=== picksThisIntervalWithTiebreaker ? (
                            <CheckIcon className="user-submit-bar-icon" style={{ color: '#00AA72'}}/>
                        ) : (
                            <CloseIcon className="user-submit-bar-icon" style={{ color: '#CC293C'}}/>
                        )}
                        {usingTiebreaker && (
                            <>
                                <Typography 
                                    className="user-submit-bar-typography"
                                    sx={{ marginLeft: '10px' }}
                                >
                                    Tiebreaker:
                                </Typography>
                                { tiebreakerCount > 0 ? (
                                    <CheckIcon className="user-submit-bar-icon" style={{ color: '#00AA72'}}/>
                                ) : (
                                    <CloseIcon className="user-submit-bar-icon" style={{ color: '#CC293C'}}/>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
                <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            padding: '10px',
                            gap: '20px',
                        }}
                    >
                        <Button 
                            variant="contained"
                            onClick={handleCancelClick}
                            disabled={hasSubmitted}
                            sx={{ height: '36px', whiteSpace: 'nowrap', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                        >
                            CANCEL
                        </Button>
                        
                        <Button 
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={hasSubmitted}
                            sx={{ height: '36px', whiteSpace: 'nowrap', color: '#fff', backgroundColor: '#002129', '&:hover': { backgroundColor: '#002129' }}}
                        >
                            SUBMIT
                        </Button>
                    </Box>
            </Box>

            <TwoButtonModal
                modalOpen={cancelModalOpen}
                setModalOpen={setCancelModalOpen}
                headerText={cancelTitle}
                contentText={cancelText}
                buttonOneText={cancelButtonOneText}
                buttonTwoText={cancelButtonTwoText}
                buttonOneAction={() => {
                    setCancelModalOpen(false);
                }}
                buttonTwoAction={() => {
                    handleCancel();
                    setCancelModalOpen(false);
                }}
            />
        </>
    );
}

export default QuickPicksSubmitBar;