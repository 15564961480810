import React, { createContext, useContext, useState, useEffect } from "react";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useSubmitScoreCard, useGetGameHook, useGetLeaderBoardData, useCreateSubgroup, useGetUserData } from "../hooks/index";
import { useInviteUsersToPrivateParty } from "../../PrivateParty/hooks/useInviteUsersToPrivateParty";
import { useRemoveUserFromPrivateParty } from "../../PrivateParty/hooks/useRemoveUserFromPrivateParty";
import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useDispatch, } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';

const BirdiePoolContext = createContext();

export const useBirdieContext = () => useContext(BirdiePoolContext);

const BirdiePoolProvider = ({ children }) => {
    const userDataId = getUserDataFromLocalStorage()?.user_id;
    //console.log("USER DATA ID", userDataId);
    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchLeaderBoardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetLeaderBoardData();
    const { mutate: createSubgroup, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();
    const { mutate, isLoading, isError, error, isSuccess } = useSubmitScoreCard();
    const { mutate: inviteUsersToPrivateParty } = useInviteUsersToPrivateParty();
    const { mutate: removeUserFromPrivateParty } = useRemoveUserFromPrivateParty();
    const { gameId, component, groupStateCheck } = useParams();
    const gameType = "BirdiePool";
    const AllGameInfo = leaderboardData;
    const [clubLeaderboardInfo, setClubLeaderboardInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    //console.log("ClubleaderboardInfo:", clubLeaderboardInfo);

    const navigate = useNavigate();
    const navigateToTop = useNavigateToTop();
    const dispatch = useDispatch();

    const [groupState, setGroupState] = useState("");
    // Fetch user data first
    useEffect(() => {
        if (userDataId) {
            fetchUserData(userDataId);
        }
    }, [fetchUserData, userDataId]);

    // Fetch game and leaderboard data after user data is fetched
    useEffect(() => {
        if (gameType && gameId && userData) {
            fetchGame({ gameType, gameID: gameId });
            fetchLeaderBoardData({ gameType, gameID: gameId, userData });
        }
    }, [gameType, gameId, fetchGame, fetchLeaderBoardData, userData]);

    // Update clubLeaderboardInfo and set loading to false when leaderboardData is received
    useEffect(() => {
        if (leaderboardData && gameData) {
          let matchingEntry;
    
          if (groupStateCheck) {
            setGroupState(groupStateCheck);
            matchingEntry = leaderboardData.find(entry => entry.name === groupStateCheck);
          } else {
            setGroupState(gameData.headerDisplayName);
            matchingEntry = leaderboardData.find(entry => entry.name === gameData.headerDisplayName);
          }
    
          if (matchingEntry) {
            //console.log("Matching Entry", matchingEntry);
            setClubLeaderboardInfo(matchingEntry);
            setLoading(false);
          } else {
            // If no matching entry is found, navigate to the 404 page
            navigateToTop('/404/private-pools');
          }
        }
      }, [leaderboardData, gameData, groupStateCheck, setGroupState, setClubLeaderboardInfo, setLoading]);

    // Error handling for game fetching
    useEffect(() => {
        if (gameError) {
            console.error("An error occurred while fetching the game:", gameErrorData);
            if (gameErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/login');
            }
        }

        if (gameData) {
            //console.log("Game data received:");
        }
    }, [gameData, gameError, gameErrorData, navigateToTop, dispatch]);

    // Error handling for leaderboard fetching
    useEffect(() => {
        if (leaderboardError) {
            console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
            if (leaderboardErrorData.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
            } else {
                navigateToTop('/login');
            }
        }

        if (leaderboardData) {
            //console.log("Leaderboard data received:", leaderboardData);
        }
    }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

    const userScorecard = userData?.Games?.find(game => game.gameID === gameId)?.pool;
    const userSubgroups = userData?.Games?.find(game => game.gameID === gameId)?.subGroups;
    const userFirstName = userData?.first_name;
    const userLastName = userData?.last_name;
    const myUserName = `${userFirstName} ${userLastName}`;
    const myUserId = userData?.user_id;
    //console.log("User Scorecard!!!!!!:", userScorecard);

    const headerDisplayName = gameData?.headerDisplayName;
    const clubName = gameData?.clubName;

    function createBlankScorecard(startHole, endHole) {
        let newScorecard = [];
        for (let i = startHole; i <= endHole; i++) {
            newScorecard.push({ hole: i, birdieDates: [] });
        }
        return newScorecard;
    }

    const doesUserHaveScorecard = userScorecard?.length > 0 ? true : false;

    let startingScorecard;

    if (!doesUserHaveScorecard) {
        const newScorecard = createBlankScorecard(1, 18);
        startingScorecard = newScorecard;
    } else {
        startingScorecard = userScorecard;
    }

    const [scorecardState, setScorecardState] = useState(startingScorecard);
    const [selectedHole, setSelectedHole] = useState(null);
    const [modalState, setModalState] = useState(null);
    const [editHole, setEditHole] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editDate, setEditDate] = useState(null);
    const [confirmDate, setConfirmDate] = useState(null);
    const [selectedUser, setSelectedUser] = useState(myUserName);
    const [selectedUserId, setSelectedUserId] = useState(myUserId);
    const [firstLoad, setFirstLoad] = useState(true);
    const [removedBirdiesIndex, setRemovedBirdiesIndex] = useState([]);

    

    const handleScoreCardSubmission = async () => {
        let data = {
            gameID: gameId,
            scoreCard: scorecardState,
            userID: userData.user_id,
            poolType: "BirdiePool",
            gameType: "Birdie Test", // Change this if necessary
        };

        if (modalState === "minus") {
            setModalState("deletedAlert")
            }
    
        try {
            await mutate(data, {
                onSuccess: async () => {
                    try {
                        // await fetchUserData(userData.user_id); // Ensure this completes before reloading
                        // await fetchLeaderBoardData({gameType, gameID: gameId, userData}); // Assuming this function fetches the updated leaderboard data
                        // window.location.href = window.location.href;
                        if (modalState === "plus" || modalState === "confirmDuplicate") {
                        setModalState("birdieAlert")
                        return;
                        } 
                        if (modalState === "minus") {
                            return;
                        }
                        if (modalState === "editCalendar") {
                            setModalState("editedAlert")
                            return;
                        }
                        else {
                            await fetchUserData(userData.user_id); // Ensure this completes before reloading
                            window.location.href = window.location.href;
                        }
                    } catch (error) {
                        console.error("An error occurred while fetching user data:", error);
                    }
                },
                onError: (error) => {
                    console.error("An error occurred while submitting the scorecard:", error);
                    setModalState("submissionError");
                }
            });
        } catch (error) {
            console.error("An error occurred while submitting the scorecard:", error);
            setModalState("submissionError");
        }
    };
    

    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };

    const handlePrivatePartyInvite = (inviteData) => {
        inviteUsersToPrivateParty(inviteData);
    };

    function getUserScorecard(username, userId) {
        const user = leaderboardData[0]?.leaderboardInfo?.find(member => member.userName === username && member.userId === userId);
        return user ? user.scoreCard : null;
    }

    const birdiePoolContextValue = {
        selectedUser,
        setSelectedUser,
        selectedUserId,
        setSelectedUserId,
        scorecardState,
        setScorecardState,
        selectedHole,
        setSelectedHole,
        modalState,
        setModalState,
        startingScorecard,
        handleScoreCardSubmission,
        editHole,
        setEditHole,
        editIndex,
        setEditIndex,
        editDate,
        setEditDate,
        birdiePoolData: gameData,
        birdieLeaderboardData: leaderboardData,
        clubLeaderboardInfo,
        setClubLeaderboardInfo,
        userSubgroups,
        myUserName,
        myUserId,
        groupState,
        setGroupState,
        headerDisplayName,
        AllGameInfo,
        gameId,
        handleCreateSubgroup,
        userData,
        handlePrivatePartyInvite,
        userScorecard,
        firstLoad,
        setFirstLoad,
        getUserScorecard,
        removeUserFromPrivateParty,
        fetchUserData,
        clubName,
        confirmDate,
        setConfirmDate,
        removedBirdiesIndex,
        setRemovedBirdiesIndex
    };

    // Render loading state while fetching data
    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <BirdiePoolContext.Provider value={birdiePoolContextValue}>
            {children}
        </BirdiePoolContext.Provider>
    );
};

export default BirdiePoolProvider;