import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "./CardPieces.scss";

const MultipleChoiceCard = ({ 
    pickState, 
    setPickState, 
    isPropSelected, 
    interval, 
    hasGameStarted, 
    isGameFinal, 
    prop, 
    selectedGameId, 
    result,
    customBorderColor, 
}) => {
    let id = prop?.id;
    const globalGameId = selectedGameId;
    const propChoice = prop?.propChoice;
    const options = prop?.options || [];
    const line = prop?.line;
    const correctOption = prop?.correctOption;
    const correctOptions = prop?.correctOptions || []; // New prop for multiple correct answers

    const [selectedOptions, setSelectedOptions] = useState(Array(options.length).fill(false));

    const colors = {
        loss: "#CC293C",
        lossBackground: "#CC293C33",
        win: "#00AA72",
        winBackgroundColor: "#CCFFEF",
        pendingNotLocked: "#00AAD1",
        pendingNotLockedBackground: "#00AAD133",
        pendingLocked: "#002129",
        pendingLockedBackground: "#00212933",
        notSelected: "#00212980",
        winNotSelected: "#00AA72",
        defaultBackground: "#fff",
        textColor: "#002129",
        voidedBackground: "#000000",
        voidedText: "#fff",
    };

    const isCorrectOption = (option) => {
        if (correctOption === 'Multiple Answers') {
            return correctOptions.includes(option);
        }
        return correctOption === option;
    };

    const getButtonStyles = (isSelected, option, index) => {
        const isCorrect = isCorrectOption(option);
        let styles = {
            borderColor: colors.notSelected,
            backgroundColor: colors.defaultBackground,
            textColor: colors.notSelected,
        };

        if (correctOption === 'Void') {
            styles = {
                borderColor: colors.notSelected,
                backgroundColor: colors.voidedBackground,
                textColor: colors.voidedText,
            };
        } else if (isSelected && !hasGameStarted) {
            styles = {
                borderColor: colors.pendingNotLocked,
                backgroundColor: colors.pendingNotLockedBackground,
                textColor: colors.textColor,
            };
        } else if (isSelected && hasGameStarted && result === "Pending") {
            styles = {
                borderColor: colors.pendingLocked,
                backgroundColor: colors.pendingLockedBackground,
                textColor: colors.textColor,
            };
        } else if (isSelected && result !== "Pending") {
            styles = {
                textColor: colors.textColor,
                borderColor:  (result === "Win" || result === "win") ? colors.win : colors.loss,
                backgroundColor:  (result === "Win" || result === "win") ? colors.winBackgroundColor : colors.lossBackground,
            };
        } else if (isPropSelected) {
            if (!isSelected && !isCorrect) {
                styles = {
                    borderColor: colors.notSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.notSelected,
                };
            } else if (!isSelected && isCorrect) {
                styles = {
                    borderColor: colors.winNotSelected,
                    backgroundColor: colors.defaultBackground,
                    textColor: colors.winNotSelected,
                };
            }
        } else if (!isPropSelected && !hasGameStarted) {
            styles = {
                borderColor: '#002129',
                backgroundColor: '#fff',
                textColor: '#002129',
            };
        } else if (!isPropSelected && hasGameStarted && isCorrect) {
            styles = {
                borderColor: colors.win,
                backgroundColor: colors.defaultBackground,
                textColor: colors.win,
            };
        }
    
        return styles;
    };

    useEffect(() => {
        const updatedSelectedOptions = options.map(option => 
            !!pickState?.find(pick => pick.gameId === globalGameId && pick.id === id && pick.option === option)
        );
        setSelectedOptions(updatedSelectedOptions);
    }, [pickState, options]);

    const handleClick = (option, index) => {
        const newPick = {
            id: id,
            propChoice: propChoice,
            option: option,
            gameId: globalGameId,
            interval: Number(interval),
        };

        const existingPickIndex = pickState?.findIndex(
            (pick) => pick.propChoice === propChoice && pick.gameId === globalGameId && pick.id === id
        );

        if (existingPickIndex !== -1) {
            const existingPick = pickState[existingPickIndex];
            if (existingPick.option === option) {
                const updatedPicks = pickState.filter((_, idx) => idx !== existingPickIndex);
                setPickState(updatedPicks);
                // Update selected options to remove the selection
                setSelectedOptions(prev => prev.map((val, idx) => idx === index ? false : val));
            } else {
                const updatedPicks = [...pickState];
                updatedPicks[existingPickIndex] = newPick;
                setPickState(updatedPicks);
                // Update selected options to show new selection
                setSelectedOptions(prev => prev.map((val, idx) => idx === index));
            }
        } else {
            setPickState([...pickState, newPick]);
            // Update selected options to add new selection
            setSelectedOptions(prev => prev.map((val, idx) => idx === index));
        }
    };

    return (
        <Box 
            className="quick-pick-card-multiple-choice-container"
            sx={{
                borderRight: customBorderColor ? `1px solid ${customBorderColor}` : '',
                borderLeft: customBorderColor ? `1px solid ${customBorderColor}` : '',
            }} 
        >
            {options.map((option, index) => {
                const isSelected = selectedOptions[index];
                const styles = getButtonStyles(isSelected, option, index);

                return (
                    <Box
                        key={index}
                        className="quick-pick-card-multiple-choice-button"
                        onClick={() => !hasGameStarted && correctOption !== 'Void' && handleClick(option, index)}
                        sx={{
                            border: `2px solid ${styles.borderColor}`,
                            backgroundColor: styles.backgroundColor,
                            pointerEvents: hasGameStarted || correctOption === 'Void' ? "none" : "auto",
                        }}
                    >
                        <Typography
                            className="quick-pick-card-multiple-choice-button-text"
                            sx={{ color: styles.textColor }}
                        >
                            {option}
                        </Typography>
                        {line &&
                            <Typography
                                className="quick-pick-card-multiple-choice-button-text"
                                sx={{ color: styles.textColor }}
                            >
                                {line}
                            </Typography>
                        }
                    </Box>
                );
            })}
        </Box>
    );
};

export default MultipleChoiceCard;