import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import './CardPieces.scss';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";


const QuickPickNoGamesCard = ({ league, isThereAGame, teamName, sponsorName, picksComing, playerPicksComing }) => {

    const navigateToTop = useNavigateToTop();
  
    const topText = playerPicksComing ? 'Player Picks Coming Soon!' : picksComing ? 'Pool Picks Coming Soon!' : isThereAGame ? 'Props Coming Soon!' : (league === 'NFL' || league === 'CFB') ? 'Bye Week' : 'No Game Today';

    const bodyText = playerPicksComing ? 
    'Player picks will be available as soon as the props are ready.' :
    picksComing ? 
    'Pool Picks will be available at the start of the game.' :
    isThereAGame ? 
    'The props for this game are cooking. They will be ready the week of game!' : 
    sponsorName ? `With no ${teamName} game, what do we do?  Alright, we'll see you at ${sponsorName}.` :
    `With no ${teamName} game, what do we do?`;


    
    return (
        <Box className="quick-pick-card-container">
            <Box 
                className="quick-pick-card-top-border"
                sx={{ backgroundColor: "#C0C0C0" }}    
            >
                <Typography 
                    className="quick-pick-card-top-border-text"
                    sx={{ color: '#002129' }}    
                >
                    {topText}
                </Typography>
            </Box>
            <Box className="quick-pick-card-info">
                <Typography 
                    className="quick-pick-card-info-text" 
                    sx={{
                        minHeight: '90px',
                        textAlign: 'center', // Center text horizontally
                        display: 'flex', // Make sure the flex behavior is applied
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Optionally center vertically within the minHeight
                      }}
                >
                    {bodyText}
                </Typography>
            </Box>
            <Box className="quick-pick-card-bottom-border">
                <Typography className="quick-pick-card-bottom-border-text">
                    Prop Idea / Feedback: 
                </Typography>
                <Typography
                    className="quick-pick-card-bottom-border-text"
                    sx={{
                        color: '#00AAD1 !important', // Ensure priority
                        textDecoration: 'underline',
                        cursor: 'pointer', // Add pointer cursor
                    }}
                    onClick={() => navigateToTop('/contact')} 
                >
                    Contact Us
                </Typography>
            </Box>
        </Box>
    )
}

export default QuickPickNoGamesCard;