import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { apiUrl } from "../../../config";

// Function to handle the POST request
export const useSubmitScoreCard = () => {
  return useMutation({
    mutationFn: submitScoreCardRequest,
    onError: (error) => {
      console.error('Error submitting score card:', error);
    },
    // onSuccess: (data) => {
    //   console.log('Score card submitted successfully:', data);
    // }
  });
};

const submitScoreCardRequest = async (scoreCardData) => {
  const authorizationHeader = localStorage.getItem('authorization');  // Get auth token from localStorage

  const response = await axios.post(`${apiUrl}/games/submit-score-card`, scoreCardData, {
    headers: {
      Authorization: authorizationHeader,  // Include the authorization header in the request
      'Content-Type': 'application/json'
    }
  });  return response.data;
};