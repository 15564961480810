import React from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ThemeProvider from "../contexts/ThemeProvider";

export default function MainLayout({ children, meta }) {
  const {
    url = "",
    title = "Game Changing Sports Pools - Classic & New Pools | Pool Party",
    focusKeyword = "Sports Pools",
    description = "Join the most innovative sports pool platform & elevate your sports experience with Pool Party Gaming. NFL, NCAA, NBA, MLB, NHL and beyond - our platform provides the highest quality classic sports pools along with new and innovative contests. Join the sitewide party or create your own. Your sports fandom is waiting."
  } = meta || {};

  return (
    <ThemeProvider>
       {/* Add Helmet for metadata */}
        {/* Add Helmet for metadata */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content={focusKeyword} />
        <link rel="canonical" href={url} />
      </Helmet>
      <Header />
      <Box
        sx={{
          minHeight: "calc(var(--main-content-min-height))",
          width: "100%",
          margin: "0 auto",
          marginTop: "var(--navbar-height)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="overflow-y-auto max-w-full w-full flex flex-col flex-1">
          {children}
        </div>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
