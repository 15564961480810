import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const validateUsernameRequest = (opts) => {
  const { username } = opts;
  return axios.post("/user/validate-username", {
    username,
  }).then((res) => res.data?.data);
};

export const useValidateUsernameIsUnique = () => {
  return useMutation({
    mutationFn: validateUsernameRequest,
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      return data;
    },
  });
};
