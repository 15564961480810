import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PrivatePartyInviteContainer from '../components/PrivateParty/PrivatePartyInviteContainer';
import PrivatePartyProvider from '../components/PrivateParty/context/PrivatePartyContext';

const PrivateInvitationPage = () => {
  const queryClient = new QueryClient();
  //console.log(PrivatePartyProvider);
  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <PrivatePartyProvider>
          <PrivatePartyInviteContainer />
        </PrivatePartyProvider>
      </QueryClientProvider>
    </Box>
  );
}

export default PrivateInvitationPage;
