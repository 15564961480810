import React, { useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import PicksNotSaved from '../../PicksNotSaved/PicksNotSaved';

const PropIdeaText = ({ unsavedPicks, userPicksFromDB, setPickState }) => {
    //console.log("Unsaved Picks In Prop Idea Text: ", unsavedPicks);
    const navigateToTop = useNavigateToTop();

    const [picksNotSavedModal, setPicksNotSavedModal] = useState(false);

    const contactUsClick = () => {
        if (unsavedPicks) {
            setPicksNotSavedModal(true);
        } else {
            setPickState(userPicksFromDB);
            setPicksNotSavedModal(false);
            navigateToTop('/contact');
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0 auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px'
                }}
            >
                <Typography className="quick-pick-card-bottom-border-text" sx={{ marginRight: 1 }}>
                    Prop Idea / Feedback: 
                </Typography>
                <Typography
                    className="quick-pick-card-bottom-border-text"
                    sx={{
                        color: '#00AAD1 !important', // Ensure priority
                        textDecoration: 'underline',
                        cursor: 'pointer', // Add pointer cursor
                    }}
                    onClick={() => contactUsClick()} 
                >
                    Contact Us
                </Typography>
            </Box>

            <Modal
                open={picksNotSavedModal}
                onClose={() => setPicksNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
            >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPicksNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickState(userPicksFromDB);
                                    setPicksNotSavedModal(false);
                                    navigateToTop('/contact');
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
}

export default PropIdeaText;