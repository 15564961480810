import React from 'react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PrivatePoolsContainer from '../components/PrivatePools/PrivatePoolsContainer';
const PrivatePools = () => {
  const queryClient = new QueryClient();

  return (
    <Box>
      <QueryClientProvider client={queryClient}>
        <PrivatePoolsContainer />
      </QueryClientProvider>
    </Box>
  );
}

export default PrivatePools;