// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survivor-my-picks-page-remove-text {
  color: #CC293C;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-decoration: underline;
  margin-bottom: 8px !important;
  cursor: pointer;
}
@media (max-width: 767px) {
  .survivor-my-picks-page-remove-text {
    font-size: 12px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SurvivorGames/MyPicks/SurvivorMyPicksPage.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,0BAAA;EACA,2BAAA;EACA,0BAAA;EACA,6BAAA;EACA,eAAA;AACF;AACE;EARF;IASI,0BAAA;EAEF;AACF","sourcesContent":[".survivor-my-picks-page-remove-text {\n  color: #CC293C;\n  font-size: 16px !important;\n  font-weight: 700 !important;\n  text-decoration: underline;\n  margin-bottom: 8px !important;\n  cursor: pointer;\n\n  @media (max-width: 767px) {\n    font-size: 12px !important;\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
