import React from "react";
import { Typography, Button, Box } from "@mui/material";

/**
 * First page of the Join Modal that allows users to select how many entries they want to add
 * @param {Object} props
 * @param {Function} props.setPage - Function to change the current page of the modal
 * @param {string} props.gameName - Name of the game being joined
 * @param {number} props.maxEntriesThisPool - Maximum number of entries allowed in this pool
 * @param {number} props.entriesToEnterCount - Current number of entries user wants to enter
 * @param {Function} props.setEntriesToEnterCount - Function to update number of entries to enter
 * @param {number} props.howManyTotalEntries - Total number of entries user has across all pools
 * @param {Function} props.setNewEntries - Function to set number of new entries to create
 * @param {number} props.howManyEntriesInThisPool - Number of entries user already has in this pool
 */
const JoinModalPageOne = ({
    setPage,
    gameName,
    maxEntriesThisPool,
    entriesToEnterCount,
    setEntriesToEnterCount,
    howManyTotalEntries,
    setNewEntries,
    howManyEntriesInThisPool,
    creation,
    pageOneExtraText,
}) => {
    /**
     * Handles the next button click
     * If user has no entries, skips to page 3 and sets all entries as new
     * Otherwise, goes to page 2 to let user choose between existing and new entries
     */
    const handleNextClick = () => {
        //console.log("howManyTotalEntries: ", howManyTotalEntries);
        if (howManyTotalEntries === 0) {
            //console.log("Setting Page to 3");
            setPage(3);
            setNewEntries(entriesToEnterCount);
        } else {
            //console.log("Setting Page to 2");
            setPage(2);
        }
    }

    return (
        <Box>
            {/* Title text that changes based on whether user already has entries */}
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '800',
                    textAlign: 'center',
                }}
            >
                {creation
                    ? `Congratulations, you are now the host of ${gameName}!  How many entries would you like to create?`
                    : howManyEntriesInThisPool > 0 
                    ? `You already have ${howManyEntriesInThisPool} ${howManyEntriesInThisPool === 1 ? 'entry' : 'entries'}. ${gameName} allows up to ${maxEntriesThisPool} ${maxEntriesThisPool === 1 ? 'entry' : 'entries'}. How many more would you like to enter?` 
                    : `${gameName} allows up to ${maxEntriesThisPool} ${maxEntriesThisPool === 1 ? 'entry' : 'entries'}. How many would you like to enter?`}
            </Typography>

            {/* Extra text for page one */}
            {pageOneExtraText && (
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '800',
                        textAlign: 'center',
                        color: '#002129',
                    }}
                >
                    {pageOneExtraText}
                </Typography>
            )}

            {/* Entry count selector with increment/decrement buttons */}
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                my: 3
            }}>
                <Button 
                    onClick={() => setEntriesToEnterCount(prev => Math.max(1, prev - 1))}
                    disabled={entriesToEnterCount <= 1}
                    sx={{ fontSize: '24px', minWidth: '40px', height: '40px', p: 0 }}
                >
                    -
                </Button>
                <Box sx={{ 
                    border: '2px solid #002129',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    minWidth: '60px',
                    textAlign: 'center'
                }}>
                    {entriesToEnterCount}
                </Box>
                <Button 
                    onClick={() => setEntriesToEnterCount(prev => Math.min(maxEntriesThisPool, prev + 1))}
                    disabled={entriesToEnterCount >= maxEntriesThisPool - howManyEntriesInThisPool}
                    sx={{ fontSize: '24px', minWidth: '40px', height: '40px', p: 0 }}
                >
                    +
                </Button>
            </Box>

            {/* Next button */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={handleNextClick}
                    sx={{ 
                        mt: 2,
                        height: '36px',
                        width: '100px',
                        backgroundColor: '#002129',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#002129',
                        },
                    }}
                >
                    NEXT
                </Button>
            </Box>

        </Box>
    );
};

export default JoinModalPageOne;