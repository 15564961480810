import React from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { useBracketGamesContext } from "./context/BracketGamesContext";
import JoinModal from "../GameModals/JoinGameModal/JoinModal";
import GameHeaderTemplate from "../PoolHeaderBar/GameHeaderTemplate";
import EntryDropdown from "../GamePageComponents/Dropdowns/EntryDropdown";
import UserDash from "../UserDash/UserDash";
import ConnectionError from "../ConnectionErrorPage/ConnectionError";
import GameNavBar from "../NavigationBars/GameNavigationBar/GameNavBar";
import MyBracket from "./MyBracket/MyBracket";
import MyBracketDesktop from "./MyBracket/MyBracketDesktop";
import BracketLeaderboard from "./BracketLeaderboard/BracketLeaderboard";
import BracketPoolPicks from "./BracketPoolPicks/BracketPoolPicks";
import UnsavedPicksModal from "../GameModals/UnsavedPicksModal/UnsavedPicksModal";
import GameHomePageVersionTwo from "../GameHomePage/GameHomePageVersionTwo";
import MembersPageContainerNew from "../MembersPage/MembersPageContainerNew";
import InviteModal from "../GameModals/JoinGameModal/InviteModal";
import SponsorTab from "../SponsorTab/SponsorTab";
import GameRulesPage from "../GameRulesPage/GameRulesPage";
import ReferralsLeaderboard from "../ReferralsLeaderboard/ReferralsLeaderboardNew";
import ViewEntrantBracket from "./MyBracket/ViewEntrantBracket";
import ViewEntrantBracketDesktop from "./MyBracket/ViewEntrantBracketDesktop";
import MyBracketSweetSixteen from "./MyBracket/MyBracketSweetSixteen";
import { useMediaQuery } from "@mui/material";
import MyBracketSweetSixteenDesktop from "./MyBracket/MyBracketSweetSixteenDesktop";
import ViewEntrantSweetSixteen from "./MyBracket/ViewEntrantSweetSixteen";
import ViewEntrantSweetSixteenDesktop from "./MyBracket/ViewEntrantSweetSixteenDesktop";
import PoolPartyPopUpModal from "../GameModals/PoolPartyPopUp/PoolPartyPopUpModal";
import ChampDisplayTemplate from "../ChampionDisplay/ChampDisplayTemplate";

const BracketGamesContainer = () => {
    const {
        page, 
        gameData,
        masterPoolData,
        isUserInThisPool,
        usersIndexesInThisPool,
        usersGameIdsInMasterPool,
        connectionError,
        unsavedPicksModalOpen,
        joinOpen,
        setJoinOpen,
        inviteOpen,
        setInviteOpen,
        popUpModalInfo,
        poolPartyPopUp,
        setPoolPartyPopUp,
        leaderboardData,
    } = useUserAndGameContext();
    //console.log("user Game/Pool Info in BracketGamesContainer: ", usersGameIdsInMasterPool, usersIndexesInThisPool);

    const {
        isGameOver,
    } = useBracketGamesContext();

    const isMobile = useMediaQuery("(max-width: 1350px)");

    const bracketType = masterPoolData?.gameParameters?.bracketType;
    //console.log("bracketType in BracketGamesContainer: ", bracketType);

    return (
        <>
            <GameHeaderTemplate />

            <GameNavBar />

            {isUserInThisPool && (
                <UserDash />
            )}

            {(page === 'home' || page === 'leaderboard') && isGameOver && (
                <ChampDisplayTemplate
                    leaderboardData={leaderboardData}
                />
            )}

            {(usersIndexesInThisPool?.length > 1 && page === 'my-bracket') && (
                <EntryDropdown 
                    hideEntryDropdown={page !== 'my-bracket'}
                />
            )}

            {page === "home" && (
                <GameHomePageVersionTwo />
            )}

            {page === "my-bracket" && (
                bracketType === "SweetSixteen" ? (
                    (!isMobile ? (
                        <MyBracketSweetSixteenDesktop />
                    ) : (
                        <MyBracketSweetSixteen />
                    ))
                ) :
                isMobile ? (
                    <MyBracket />
                ) : (
                    <MyBracketDesktop />
                )
            )}

            {page === 'bracket' && (
                bracketType === "SweetSixteen" ? (
                    isMobile ? <ViewEntrantSweetSixteen /> : <ViewEntrantSweetSixteenDesktop />
                ) : (
                    isMobile ? <ViewEntrantBracket /> : <ViewEntrantBracketDesktop />
                )
            )}


            {page === "leaderboard" && (
                <BracketLeaderboard />
            )}

            {page === "pool-picks" && (
                <BracketPoolPicks />
            )}

            {page === "members" && (
                <MembersPageContainerNew />
            )}

            {page === "sponsor" && (
                <SponsorTab gameData={gameData} />
            )}

            {page === "rules" && (
                <GameRulesPage gameData={gameData} />
            )}

            {page === "referrals" && (
                <ReferralsLeaderboard />
            )}

            {connectionError && (
                <ConnectionError />
            )}

            {unsavedPicksModalOpen && (
                <UnsavedPicksModal />
            )}

            {poolPartyPopUp && (
                <PoolPartyPopUpModal
                    modalOpen={poolPartyPopUp}
                    setModalOpen={setPoolPartyPopUp}
                    modalInfo={popUpModalInfo}
                />
            )}
        
            {joinOpen && (
                <JoinModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                />
            )}

            {inviteOpen && (
                <InviteModal
                    open={inviteOpen}
                    setOpen={setInviteOpen}
                />
            )}
        </>
    );
};

export default BracketGamesContainer;