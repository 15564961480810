// Twelve Team Bracket Functions

export const getBracketHeaderInfo = (rounds, userBracketPicksFromDB) => {
    const headerInfo = [];
    rounds?.forEach((round, index) => {
        const roundGames = userBracketPicksFromDB?.filter((game) => game.round === index + 1) || [];
        const totalPoints = roundGames.reduce((acc, game) => acc + (game.points || 0), 0); // Default to 0 if points are undefined
        const roundName = round.name;
        const gamesThisRound = round.games || 0; // Default to 0 if undefined
        const pointsThisRound = round.points || 0; // Default to 0 if undefined
        const roundsPointsAvailable = gamesThisRound * pointsThisRound;

        headerInfo.push({
            name: roundName,
            pointsThisRound: pointsThisRound,
            points: totalPoints,
            total: roundsPointsAvailable,
        });
    });
    return headerInfo;
};

