import React from "react";
import AdminDashContainer from "../components/AdminDash/AdminDashContainer";
import AdminDashProvider from "../components/AdminDash/context/AdminDashContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const AdminDashPage = () => {
    const queryClient = new QueryClient();
    
    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <AdminDashProvider>
                    <AdminDashContainer />
                </AdminDashProvider>
            </QueryClientProvider>
        </div>
    );
}

export default AdminDashPage;