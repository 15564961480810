import React, { useState, useEffect, useMemo } from 'react';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { useQuickPickGamesContext } from '../context/QuickPickGamesContext';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Modal, Button, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { DateTime } from 'luxon';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import InfoIcon from '@mui/icons-material/Info';
import ChampionDisplay from '../../ChampionDisplay/ChampionDisplay';
import WeeklyWinnerDisplay from '../../ChampionDisplay/WeeklyWinnerDisplay';
import Top3DisplayNew from '../../ChampionDisplay/Top3DisplayNew';
import QuickPickLeaderboardDropdown from './QuickPickLeaderboardDropdownNew';
import QuickPickNoGamesCard from '../CardPieces/QuickPickNoGamesCard';
import TiebreakerModal from '../../GameModals/TiebreakerInfoModal/TiebreakerModal';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { get } from 'lodash';

const QuickPickLeaderboard = () => {
    const {
        masterPoolData,
        leaderboardData,
        myLeaderboardInfo,
        myUserId,
        gameAddress,
        masterPoolId,
        gameId,
        intervalInfo,
    } = useUserAndGameContext();

    const {
        quickPickGames,
        theCurrentInterval,
        firstInterval,
        lastInterval,
        oneWeekEvent,
        isGameOver,
        filteredSchedule,
        filteredQuickPickGames,
        hasTheCurrentIntervalStarted,
        hasTheLastGameOfCurrentIntervalStarted,
    } = useQuickPickGamesContext();
//console.log("Filtered Schedule in QuickPickLeaderboard: ", filteredSchedule, filteredQuickPickGames);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const [sortBy, setSortBy] = useState('rank');
    const [sortedColumn, setSortedColumn] = useState('rank');
    const [sortOrder, setSortOrder] = useState('asc');
    const [leaderboardInterval, setLeaderboardInterval] = useState('');
    const [tiebreakerInfoOpen, setTiebreakerInfoOpen] = useState(false);
    //console.log("Leaderboard Interval in QuickPickLeaderboard: ", leaderboardInterval);
    // Use Effect to set the leaderboard interval
    useEffect(() => {
        if ((isGameOver && !oneWeekEvent) || oneWeekEvent) {
            setLeaderboardInterval('Season Standings');
        } else {
            setLeaderboardInterval(theCurrentInterval);
        }
    }, [theCurrentInterval, isGameOver, oneWeekEvent]);

    const tiebreakerInfo = masterPoolData?.tiebreakerInfo;

    const getRank = (entry) => {
        
        if (leaderboardInterval === 'Season Standings' || oneWeekEvent) {
            return entry?.rank;
        } else {
            const foundRanking = entry?.intervalRankings?.find(rank => {
                return Number(rank.interval) === Number(leaderboardInterval);
            });
            return foundRanking?.rank || 'N/A';
        }
    };

    const getPoints = (entry) => {
        if (leaderboardInterval === 'Season Standings' || oneWeekEvent) {
            return entry?.totalPoints;
        } else {
            const foundPoints = entry?.intervalRankings?.find(points => {
                return Number(points.interval) === Number(leaderboardInterval);
            });
            //console.log("Found Points in QuickPickLeaderboard: ", foundPoints);
            return foundPoints?.points ?? 0;
        }
    };

    const getRecord = (entry) => {
        if (leaderboardInterval === 'Season Standings' || oneWeekEvent) {
            return `${entry?.totalWins || 0}-${entry?.totalLosses || 0}` || '0-0';
        } else {
            const foundRecord = entry?.intervalRankings?.find(record => {
                return Number(record.interval) === Number(leaderboardInterval);
            });
            return `${foundRecord?.wins || 0}-${foundRecord?.losses || 0}` ?? '0-0';
        }
    };

    // Pagination State
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    // Add pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Add useEffect to reset page when filters change
    useEffect(() => {
        setPage(0);
    }, [leaderboardInterval, sortBy, sortOrder]);

    const handleSort = (column) => {
        // If clicking the same column, toggle the sortOrder
        if (sortBy === column) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(column);
          setSortOrder('asc'); // Reset to ascending when sorting a new column
        }
    };

    // Sort the leaderboard data based on the current sort settings
const sortedLeaderboardData = useMemo(() => {
    if (!leaderboardData) return [];
    
    return [...leaderboardData].sort((a, b) => {
        if (leaderboardInterval === 'Season Standings' || oneWeekEvent) {
            // Season standings sort logic
            if (sortBy === 'rank') {
                return sortOrder === 'asc' ? a.rank - b.rank : b.rank - a.rank;
            } else if (sortBy === 'points') {
                return sortOrder === 'asc' ? a.totalPoints - b.totalPoints : b.totalPoints - a.totalPoints;
            } else if (sortBy === 'record' || sortBy === 'totalWins') {
                return sortOrder === 'asc' ? a.totalWins - b.totalWins : b.totalWins - a.totalWins;
            } else if (sortBy === 'username') {
                return sortOrder === 'asc' ? a.username.localeCompare(b.username) : b.username.localeCompare(a.username);
            }
        } else {
            // Interval-specific sort logic
            if (sortBy === 'rank') {
                const aRank = a.intervalRankings?.find(rank => Number(rank.interval) === Number(leaderboardInterval))?.rank || 0;
                const bRank = b.intervalRankings?.find(rank => Number(rank.interval) === Number(leaderboardInterval))?.rank || 0;
                return sortOrder === 'asc' ? aRank - bRank : bRank - aRank;
            } else if (sortBy === 'points') {
                const aPoints = a.intervalRankings?.find(points => Number(points.interval) === Number(leaderboardInterval))?.points || 0;
                const bPoints = b.intervalRankings?.find(points => Number(points.interval) === Number(leaderboardInterval))?.points || 0;
                return sortOrder === 'asc' ? aPoints - bPoints : bPoints - aPoints;
            } else if (sortBy === 'record' || sortBy === 'totalWins') {
                const aWins = a.intervalRankings?.find(wins => Number(wins.interval) === Number(leaderboardInterval))?.wins || 0;
                const bWins = b.intervalRankings?.find(wins => Number(wins.interval) === Number(leaderboardInterval))?.wins || 0;
                return sortOrder === 'asc' ? aWins - bWins : bWins - aWins;
            } else if (sortBy === 'username') {
                return sortOrder === 'asc' ? a.username.localeCompare(b.username) : b.username.localeCompare(a.username);
            }
        }
        return 0; // Default case
    });
}, [leaderboardData, leaderboardInterval, sortBy, sortOrder, oneWeekEvent]);

    const paginatedData = sortedLeaderboardData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const rankHeaderColor = sortedColumn === 'rank' ? '#FFC60A' : '#fff';
    const pointsHeaderColor = sortedColumn === 'points' ? '#FFC60A' : '#fff';
    const recordHeaderColor = sortedColumn === 'record' ? '#FFC60A' : '#fff';
    const playerHeaderColor = sortedColumn === 'username' ? '#FFC60A' : '#fff';
    

    const usersEntry = myLeaderboardInfo?.myInfo?.find(entry => entry?.username === myLeaderboardInfo?.username);

    const hasWeekStarted = (leaderboardInterval !== 'Season Standings' && Number(theCurrentInterval) > Number(leaderboardInterval)) || (Number(leaderboardInterval) === Number(theCurrentInterval) && hasTheLastGameOfCurrentIntervalStarted) || (leaderboardInterval === 'Season Standings' && Number(theCurrentInterval) === Number(lastInterval) && hasTheLastGameOfCurrentIntervalStarted);
    //console.log("hasWeekStarted in QuickPickLeaderboard: ", hasWeekStarted, "leaderboardInterval: ", leaderboardInterval, "theCurrentInterval: ", theCurrentInterval, "lastInterval: ", lastInterval, "hasTheLastGameOfCurrentIntervalStarted: ", hasTheLastGameOfCurrentIntervalStarted);
    const thisIntervalsTiebreaker = filteredQuickPickGames?.find(game => 
        game.props.tiebreakers && 
        game.props.tiebreakers.length > 0
      )?.props.tiebreakers[0];
      //console.log("This Intervals Tiebreaker in QuickPickLeaderboard: ", thisIntervalsTiebreaker);
    
    const timeToRevealSeasonTiebreaker = useMemo(() => {
        if (leaderboardInterval !== 'Season Standings' || (Number(theCurrentInterval) !== Number(lastInterval))) {
            return false;
        } else {
            return hasTheLastGameOfCurrentIntervalStarted;
        }
    }, [leaderboardInterval, theCurrentInterval, lastInterval, hasTheLastGameOfCurrentIntervalStarted]);
    //console.log("Time to Reveal Season Tiebreaker in QuickPickLeaderboard: ", timeToRevealSeasonTiebreaker);

    const findTiebreakerPick = (entry) => {
        if (!entry || !entry.picks) return null;
        const pick = leaderboardInterval === 'Season Standings' ? entry.picks.find(pick => Number(pick.interval) === Number(theCurrentInterval) && pick.tiebreaker) : entry.picks.find(pick => Number(pick.interval) === Number(leaderboardInterval) && pick.tiebreaker);
        //console.log("Pick in QuickPickLeaderboard: ", pick, leaderboardInterval, entry);
        return pick?.margin ? `Tiebreaker Margin: ${pick.margin}` : pick?.option ? `Tiebreaker: ${pick.option}` : 'Tiebreaker: N/A';
    };


    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=rules`);
    };

    if (leaderboardInterval === '') {
        return <LoadingScreen />;
    }


    return (
        <>
            <Box>
                {!oneWeekEvent && (
                    <QuickPickLeaderboardDropdown
                        interval={leaderboardInterval}
                        setInterval={setLeaderboardInterval}
                        seasonText="Season Standings"
                        currentInterval={theCurrentInterval}
                        firstInterval={firstInterval}
                        lastInterval={lastInterval}
                        intervalType="Week"
                        intervalInfo={intervalInfo}
                    />
                )}

                {((leaderboardInterval === 'Season Standings' && Number(theCurrentInterval) === Number(lastInterval)) || leaderboardInterval !== 'Season Standings') && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0 auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setTiebreakerInfoOpen(true)}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '12px' : '18px',
                                    fontWeight: '500',
                                    textDecoration: 'underline',
                                    color: '#00AAD1',
                                    marginRight: '5px',
                                    marginTop: '10px',
                                }}
                            >
                                Tiebreaker Info
                            </Typography>
                            <InfoIcon
                                sx={{
                                    color: '#00AAD1',
                                    fontSize: isMobile ? '16px' : '20px',
                                }}
                            />
                        </Box>

                        {thisIntervalsTiebreaker && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '12px' : '16px',
                                        fontWeight: '500',
                                        textAlign: 'center',
                                        marginBottom: '10px',
                                        padding: '0px 8px',
                                    }}
                                >
                                    Tiebreaker: {thisIntervalsTiebreaker?.propText}
                                </Typography>
                                {thisIntervalsTiebreaker?.correctOption && (
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '12px' : '16px',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Result: {thisIntervalsTiebreaker?.correctOption}
                                    </Typography>
                                )}
                            </>
                        )}
                    </>
                )}

                <Box sx={{ maxWidth: '750px', margin: '0 auto' }}>
                    <TablePagination
                        rowsPerPageOptions={[50, 100]}
                        component="div"
                        count={sortedLeaderboardData?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(page, Math.max(0, Math.ceil((sortedLeaderboardData?.length || 0) / rowsPerPage) - 1))}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows"
                        sx={{
                            backgroundColor: 'white',
                            '& .MuiTablePagination-toolbar': {
                                color: '#002129',
                            },
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                                color: '#002129',
                            }
                        }}
                    />
                </Box>

                <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', marginBottom: '20px'}}>
                    <Table>
                    {myLeaderboardInfo?.myInfo?.length > 0 && (
                        <>
                            {myLeaderboardInfo?.myInfo?.map((entry, index) => (
                                <TableHead key={index} sx={{ backgroundColor: '#F0F1F1'}}>
                                    <TableRow>
                                        <TableCell className="pickem-leaderboard-table-cells" style={{width:'57px', textAlign:'center', fontSize: isMobile ? '12px' : '14px'}}>
                                            {getRank(entry)}
                                        </TableCell>
                                        <TableCell
                                            className="pickem-leaderboard-table-cells"
                                            style={{ width: '160px', fontSize: isMobile ? '12px' : '16px' }}
                                        >
                                             <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span
                                                    style={{ textDecoration: 'underline', fontWeight: 700 }}
                                                >
                                                    {entry?.username}
                                                </span>
                                                {entry?.logo && (
                                                    <img
                                                        src={entry?.logo}
                                                        alt="User Logo"
                                                        style={{ width: isMobile ? '25px' : '30px', height: isMobile ? '25px' : '30px', marginLeft: '5px' }}
                                                    />
                                                )}
                                            </div>
                                            {leaderboardInterval !== 'Season Standings' || (!timeToRevealSeasonTiebreaker && oneWeekEvent) && (
                                                <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                                                    {findTiebreakerPick(entry)}
                                                </div>
                                            )}

                                            {timeToRevealSeasonTiebreaker ? (
                                                    <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                                                        {findTiebreakerPick(entry)}
                                                    </div>
                                                ) : 
                                                null
                                            }
                                        </TableCell>
                                        <TableCell
                                            className="pickem-leaderboard-table-cells"
                                            style={{ width: '81px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                                        >
                                            {getPoints(entry) ?? 0}
                                        </TableCell>
                                        <TableCell
                                            className="pickem-leaderboard-table-cells"
                                            style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                                        >
                                            {getRecord(entry)}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            ))}
                        </>
                    )}
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                        <TableRow>
                        <TableCell className="pickem-leaderboard-table-cells"
                            onClick={() => {
                            handleSort('rank');
                            setSortedColumn('rank');
                            }}
                            sx={{ width: '57px', color: rankHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
                        >
                            RANK
                        </TableCell>
                        <TableCell className="pickem-leaderboard-table-cells"
                            onClick={() => {
                            handleSort('username');
                            setSortedColumn('username');
                            }}
                            sx={{ width: '160px', color: playerHeaderColor, fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'}}
                        >
                            PLAYER
                        </TableCell>
                        <TableCell className="pickem-leaderboard-table-cells"
                            onClick={() => {
                            handleSort('rank');
                            setSortedColumn('points');
                            }}
                            sx={{ width: '81px', color: pointsHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px',  textDecoration: 'underline'} }
                        >
                            {leaderboardInterval === "Season Standings" ? "TOT PTS" : "PTS"}
                        </TableCell>
                        <TableCell className="pickem-leaderboard-table-cells"
                            onClick={() => {
                            handleSort('totalWins');
                            setSortedColumn('record');
                            }}
                            sx={{ width: '77px', color: recordHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
                        >
                            W-L
                        </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData?.map((row, index) => (
                        //console.log("ROW", row),
                            <TableRow
                                key={`${row.userId}-${row.username}`}
                                sx={{
                                backgroundColor: row.userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                                }}
                            >
                                <TableCell className="pickem-leaderboard-table-cells" style={{ width: '57px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px'}}>
                                    {getRank(row)}
                                </TableCell>
                                <TableCell className="pickem-leaderboard-table-cells" style={{ width: '162px', fontSize: isMobile ? '12px' : '16px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ textDecoration: 'underline', fontWeight: 700 }}>                      
                                            {row?.username}
                                        </span>
                                        {row?.logo && (
                                            <img
                                                src={row?.logo}
                                                alt="User Logo"
                                                style={{ width: isMobile ? '25px' : '30px', height: isMobile ? '25px' : '30px', marginLeft: '5px' }}
                                            />
                                        )}
                                    </div>
                                    {hasWeekStarted && leaderboardInterval !== 'Season Standings' && (
                                        <div style={{ fontSize: isMobile ? '12px' : '16px'}}>{findTiebreakerPick(row)}</div>
                                    )}
                                    {timeToRevealSeasonTiebreaker ? (
                                        <div style={{ fontSize: isMobile ? '12px' : '16px' }}>
                                            {findTiebreakerPick(row)}
                                        </div>
                                        ) : 
                                        null
                                    }
                                </TableCell>
                                <TableCell
                                className="pickem-leaderboard-table-cells"
                                style={{ width: '77px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                                >
                                {getPoints(row) ?? 0}
                                </TableCell>
                                <TableCell
                                className="pickem-leaderboard-table-cells"
                                style={{ width: '47px', textAlign: 'center', fontSize: isMobile ? '12px' : '16px' }}
                                >
                                {getRecord(row)}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ maxWidth: '750px', margin: '0 auto' }}>
                    <TablePagination
                        rowsPerPageOptions={[50, 100]}
                        component="div"
                        count={sortedLeaderboardData?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(page, Math.max(0, Math.ceil((sortedLeaderboardData?.length || 0) / rowsPerPage) - 1))}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows"
                        sx={{
                        backgroundColor: 'white',
                        '& .MuiTablePagination-toolbar': {
                            color: '#002129',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input, & .MuiTablePagination-actions': {
                            color: '#002129',
                        }
                        }}
                    />
                </Box>

            </Box>

            {tiebreakerInfo && (
                <TiebreakerModal
                    tiebreakInfoOpen={tiebreakerInfoOpen}
                    setTiebreakInfoOpen={setTiebreakerInfoOpen}
                    tiebreakerInfo={tiebreakerInfo}
                    handleRulesClick={handleRulesClick}
                />
            )}
        </>
    );
};

export default QuickPickLeaderboard;