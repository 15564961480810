import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PoolPartyGamingStackedGray from "../assets/images/PoolPartyGamingStackedGray.svg";
import { useNavigate, useParams } from 'react-router-dom';
import useNavigateToTop from "../hooks/general/useNavigateToTop";

const NotFound = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const { redirect } = useParams();

  // Handle back navigation based on the presence of the redirect parameter
  const handleBack = () => {
    if (redirect) {
      navigateToTop(`/${redirect}`); // Redirect to the specified route
    } else {
      window.history.back(); // Navigate back if there is no redirect parameter
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: 'background.paper',
        padding: '20px'
      }}
    >
      <img 
        src={PoolPartyGamingStackedGray} 
        alt="Pool Party Gaming Logo" 
        style={{
          width: '410px',
          height: '326px',
          flexShrink: 0,
          marginBottom: '20px'
        }} 
      />
      <Typography
        sx={{
          color: '#000',
          fontFamily: 'Inter',
          fontSize: isMobile ? '24px' : '45.78px',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: '150%', // 36px for mobile
          letterSpacing: isMobile ? '-0.456px' : '-0.87px',
          marginBottom: '20px'
        }}
      >
        Come on Ref, Bad Call!
      </Typography>
      <Typography
        sx={{
          width: isMobile ? '321px' : 'auto',
          color: '#292929',
          textAlign: 'center',
          fontFamily: 'Inter',
          fontSize: isMobile ? '12px' : '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '170%', // 20.4px for mobile
          letterSpacing: isMobile ? '-0.228px' : '-0.266px',
          marginBottom: '20px'
        }}
      >
        We had a bad call moment. It happens to the best of us.<br />
        Please try refreshing the page or go back to the previous page you were visiting.
      </Typography>
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => handleBack()}
      >
        Take Me Back!
      </Button>
    </Box>
  );
};

export default NotFound;
