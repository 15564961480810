import React, { useState } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useBirdieContext } from '../context/BirdiePoolContext';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../styles/birdiePool.scss';


const BirdieLeaderboard = ({ handleClickState }) => {
  const { birdiePoolData, clubLeaderboardInfo, myUserName, groupState, headerDisplayName, setSelectedUser, setSelectedUserId, setScorecardState, getUserScorecard, userScorecard, myUserId } = useBirdieContext();
  //console.log("STARTING SCORECARD IN MY SCORECARD", birdiePoolData?.courseScorecardInfo);
  //console.log("STARTING LEADERBOARD INFO IN LEADERBOARD", clubLeaderboardInfo);
  const data = clubLeaderboardInfo?.leaderboardInfo;
  //console.log("DATA1", data, myUserName);

  const usersEntry = data?.find(entry => entry.userName === myUserName && entry.userId === myUserId);
  //console.log("USERS ENTRY", usersEntry);


  const [sortBy, setSortBy] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));



  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      // Toggle sorting order if the same column is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Sort by the clicked column
      setSortBy(columnName);
      if (columnName === 'userName') {
        // For player name, always start with ascending order
        setSortOrder('asc');
      } else {
        // For other columns, use the current sort order
        setSortOrder(sortOrder);
      }
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortBy === 'userName') {
      // Sort alphabetically by player names
      const nameA = a[sortBy].toUpperCase(); // ignore upper and lowercase
      const nameB = b[sortBy].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0; // names must be equal
    } else
    if (sortBy === 'birdieCount') {
      // Sort by total birdies
      const comparison = a.birdieCount - b.birdieCount;
      return sortOrder === 'asc' ? -comparison : comparison;
    } else {
      // For numeric columns, use numeric comparison
      const comparison = a[sortBy] - b[sortBy];
      return sortOrder === 'asc' ? comparison : -comparison;
    }
  });
  //console.log("SORTED DATA", sortedData);


  const getDuplicateOverallRanks = () => {
    const countMap = {};
    data?.forEach(entry => {
      const rank = entry?.rank;
      countMap[rank] = (countMap[rank] || 0) + 1;
    });

    return Object.entries(countMap)
      .filter(([rank, count]) => count > 1)
      .map(([rank]) => parseInt(rank));
  };

  const duplicateOverallRanks = getDuplicateOverallRanks();
  const isUserInDuplicateRanks = (rank) => {
    return duplicateOverallRanks.includes(rank);
  };

  const isThisUsersRow = (row) => {
    return row.userName === myUserName && row.userId === myUserId;
  };



  const handleUserClick = (username, userId) => {
    setSelectedUser(username);
    setSelectedUserId(userId);
    if (username === myUserName && userId === myUserId) {
      setScorecardState(userScorecard);
    } else {
    const selectedUsersScorecard = getUserScorecard(username, userId);
    setScorecardState(selectedUsersScorecard);
    }
    handleClickState('SCORE_CARD');
  };






  const rankHeaderColor = sortBy === 'rank' ? '#FFC60A' : '#fff';
  const holesBirdiedHeaderColor = sortBy === 'holesBirdied' ? '#FFC60A' : '#fff';
  const birdieCountHeaderColor = sortBy === 'birdieCount' ? '#002129' : '#fff';
  const playerHeaderColor = sortBy === 'userName' ? '#FFC60A' : '#fff';

  return (
    <Box>
    {/* <h1 style={{ textDecoration: 'underline' }}>{groupState ?  groupState : headerDisplayName} Leaderboard</h1> */}
    <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto', borderLeft:'2px solid #002129', borderRadius:"0px"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="birdie-leaderboard-table-cells" style={{width:'57px', textAlign:'center', fontSize: '12px'}}>{isUserInDuplicateRanks(usersEntry.rank) ? 'T' : ''}{usersEntry.rank}</TableCell>
            <TableCell className="birdie-leaderboard-table-cells" style={{width:'162px', fontSize: '12px'}}>{usersEntry.userName}</TableCell>
            <TableCell className="birdie-leaderboard-table-cells" style={{width:'77px', textAlign:'center', fontSize: '12px'}}>{usersEntry.holesBirdied}/18</TableCell>
            <TableCell className="birdie-leaderboard-table-cells" style={{width:'47px', textAlign:'center', fontSize: '12px'}}>{usersEntry.birdieCount}</TableCell>
          </TableRow>
        </TableHead>

        <TableHead sx={{ backgroundColor: '#002129' }}>
          <TableRow>
            <TableCell className="birdie-leaderboard-table-cells"
              onClick={() => handleSort('rank')}
              sx={{ color: rankHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: '13px'}}
            >
              Rank
              {sortBy === 'rank' ? (
                sortOrder === 'asc' ?
                  <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '20px', transform: 'translateY(-50%)', color: rankHeaderColor }} /> :
                  <ArrowDropDownIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '20px', transform: 'translateY(-50%)', color: rankHeaderColor }} />
              ) : (
                <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '20px', transform: 'translateY(-50%)', color: rankHeaderColor }} />
              )}
            </TableCell>
            <TableCell className="birdie-leaderboard-table-cells"
              onClick={() => handleSort('userName')}
              sx={{ color: playerHeaderColor, fontWeight: 700, textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: '13px' }}
            >
              Player
              {sortBy === 'userName' ? (
                sortOrder === 'asc' ?
                  <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', color: playerHeaderColor }} /> :
                  <ArrowDropDownIcon style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', color: playerHeaderColor }} />
              ) : (
                <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', color: playerHeaderColor }} />
              )}
            </TableCell>
            <TableCell className="birdie-leaderboard-table-cells"
              onClick={() => handleSort('holesBirdied')}
              sx={{ color: holesBirdiedHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: '13px' }}
            >
              Holes Birdied
              {sortBy === 'holesBirdied' ? (
                sortOrder === 'asc' ?
                  <ArrowDropDownIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '12px', transform: 'translateY(-50%)', color: holesBirdiedHeaderColor }} /> :
                  <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '12px',transform: 'translateY(-50%)', color: holesBirdiedHeaderColor }} />
              ) : (
                <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: isMobile ? '-5px' : '12px', transform: 'translateY(-50%)', color: holesBirdiedHeaderColor }} />
              )}
            </TableCell>
            <TableCell className="birdie-leaderboard-table-cells"
              onClick={() => handleSort('birdieCount')}
              sx={{ backgroundColor: "#00AA72", color: birdieCountHeaderColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: '13px' }}
            >
              Total Birdies
              {sortBy === 'birdieCount' ? (
                sortOrder === 'asc' ?
                  <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)', color: birdieCountHeaderColor }} /> :
                  <ArrowDropDownIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)', color: birdieCountHeaderColor }} />
              ) : (
                <ArrowDropUpIcon style={{ position: 'absolute', top: '50%', right: '-5px', transform: 'translateY(-50%)', color: birdieCountHeaderColor }} />
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedData.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: isThisUsersRow(row) ? 'rgba(0, 170, 114, 0.5)' : (index % 2 === 0 ? '#FFFFFF' : '#F0F0F0')
              }}
            >
              <TableCell className="birdie-leaderboard-table-cells" sx={{ textAlign: 'center', fontSize: '12px', position: 'relative' }}>
                {isUserInDuplicateRanks(row.rank) ? 'T' : ''}{row.rank}
                {/* {isThisUsersRow(row) && (
                  <ArrowLeftIcon style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateY(-50%)', color: '#002129' }} />
                )} */}
              </TableCell>
              <TableCell className="birdie-leaderboard-table-cells" sx={{ textAlign: 'left', fontSize: '12px', textDecoration: 'underline', cursor: 'pointer', fontWeight:'500' }} onClick={() => handleUserClick(row.userName, row.userId)}>{row.userName}</TableCell>
              <TableCell className="birdie-leaderboard-table-cells" sx={{ textAlign: 'center', fontSize: '12px' }}>{row.holesBirdied}/18</TableCell>
              <TableCell className="birdie-leaderboard-table-cells" sx={{ textAlign: 'center', fontSize: '12px' }}>{row.birdieCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/* <BirdieLeaderboardFunctions /> */}
  </Box>

  );
};

export default BirdieLeaderboard;