import React from "react";
import NFLSurvivorContainer from "../components/NFLSurvivor/NFLSurvivorContainer";
import NFLSurvivorProvider from "../components/NFLSurvivor/context/NFLSurvivorContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const NFLSurvivorPage = () => {
  const queryClient = new QueryClient();

  return (
    <div>
		<QueryClientProvider client={queryClient}>
			<NFLSurvivorProvider>
				<NFLSurvivorContainer />
			</NFLSurvivorProvider>
		</QueryClientProvider>
    </div>
  );
}

export default NFLSurvivorPage;