// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bracket-round-nav {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 0;
}

.bracket-round-nav-container {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  align-items: center;
  width: 95%;
  max-width: 1160px;
}

.bracket-round-nav-item {
  font-size: 12px;
  color: #002129;
  font-weight: 700;
  padding: 5px;
}

.bracket-round-nav-item.active {
  background-color: #E8E8E8 !important;
  border-radius: 4px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/NBAPlayoffs/bracket/bracketRoundNav.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,2CAAA;EAEA,cAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;EACA,iBAAA;AAAF;;AAGA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,oCAAA;EACA,kBAAA;EACA,YAAA;AAAF","sourcesContent":[".bracket-round-nav{\n  background-color: #FFFFFF;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n\n  padding:5px 0;\n}\n\n.bracket-round-nav-container{\n  display: flex;\n  justify-content: space-between;\n  margin: 0px auto;\n  align-items: center;\n  width: 95%;\n  max-width: 1160px;\n}\n\n.bracket-round-nav-item{\n  font-size: 12px;\n  color: #002129;\n  font-weight: 700;\n  padding: 5px;\n}\n\n.bracket-round-nav-item.active{\n  background-color:#E8E8E8 !important;\n  border-radius: 4px;\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
