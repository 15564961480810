import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useGetUserData } from "../../BirdiePool/hooks";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useNavigate, useLocation } from "react-router-dom";
import { useCreatePool } from "../../../hooks/general/useCreatePool";

const CreateAPoolContext = createContext();

export const useCreateAPoolContext = () => {
    const context = useContext(CreateAPoolContext);
    if (!context) {
        throw new Error("useCreateAPoolContext must be used within a CreateAPoolProvider");
    }
    return context;
}

export const CreateAPoolProvider = ({ children }) => {
    const { mutate: fetchUserData, data: userData, loading: userLoading, error: userError } = useGetUserData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const myUserId = getUserDataFromLocalStorage()?.user_id;

    const { mutate: createPool } = useCreatePool();

    // Use Effect to fetch user data (if myUserId is available)
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    // Error handling for user data
    useEffect(() => {
        if (userError) {
            console.error("Error fetching user data:", userError);
            if (userError?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                return;
            } else {
                navigate('/404/pool-hall');
                return;
            }
        }
    }, [userError, dispatch, navigate]);

    const [gameType, setGameType] = useState(null);
    const [masterPoolId, setMasterPoolId] = useState(null);
    const [gameTitle, setGameTitle] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState(false);
    const [usePinCode, setUsePinCode] = useState(null);
    const [pinCode, setPinCode] = useState('');
    const [maxEntries, setMaxEntries] = useState(0);
    const [allowReferrals, setAllowReferrals] = useState(null);
    const [hostNote, setHostNote] = useState('');
    const [hostNoteError, setHostNoteError] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [createdPoolError, setCreatedPoolError] = useState(false);
    const [createdPoolErrorMessage, setCreatedPoolErrorMessage] = useState(null);

    // Parse URL parameters and set state values
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        
        // Check for gameType parameter
        const gameTypeParam = searchParams.get('gameType');
        if (gameTypeParam) {
            setGameType(gameTypeParam);
        }
        
        // Check for masterPoolId parameter (mId)
        const masterPoolIdParam = searchParams.get('mId');
        if (masterPoolIdParam) {
            setMasterPoolId(masterPoolIdParam);
        }

        // Check for gameTitle parameter
        const gameTitleParam = searchParams.get('gameTitle');
        if (gameTitleParam) {
            setGameTitle(gameTitleParam);
        }
    }, [location.search]);

    const handleCreatePool = useCallback(async () => {
        //console.log("Creating Pool");
        setHasSubmitted(true);

        // Ensure maxEntries is at least 1
        const finalMaxEntries = maxEntries <= 0 ? 1 : maxEntries;
    
        const createData = {
            groupName,
            ...(usePinCode && { pinCode }),
            maxEntries: finalMaxEntries,
            allowReferrals,
            hostNote,
            gameTitle,
            masterPoolId,
            gameType,
            myUserId,
        };
    
        //console.log("Create Pool Data", createData);
    
        try {
            await createPool(createData, { 
                onSuccess: (data) => {
                    //console.log('Pool created successfully:', data);
                    const gameAddress = data?.gameAddress;
                    const masterPoolId = data?.masterPoolID;
                    const gameId = data?.gameID;
                    const url = `/${encodeURIComponent(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=home&creation=true`;
                    window.location.href = url;
                },
                onError: (error) => {
                    console.error('Error creating pool:', error.response?.data?.message);
                    
                    // Check for token expiration (401 Unauthorized)
                    if (error?.response?.status === 401) {
                        dispatch(userLogoutAction({
                            logoutType: 'token-expired'
                        }));
                        return;
                    }
                    
                    // Handle other errors
                    setCreatedPoolError(true);
                    setCreatedPoolErrorMessage(error?.response?.data?.message);
                }
            }); 
        } catch (error) {
            console.error('Error creating pool:', error.response?.data?.message);
            
            // Check for token expiration (401 Unauthorized)
            if (error?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                }));
                return;
            }
            
            // Handle other errors
            setCreatedPoolError(true);
            setCreatedPoolErrorMessage(error?.response?.data?.message);
        }
    }, [createPool, groupName, usePinCode, pinCode, maxEntries, allowReferrals, hostNote, masterPoolId, gameType, myUserId, gameTitle, dispatch]);
    
    const CreateAPoolContextValues = useMemo(() => ({
        myUserId,
        userData,
        gameType,
        setGameType,
        masterPoolId,
        setMasterPoolId,
        groupName,
        setGroupName,
        groupNameError,
        setGroupNameError,
        usePinCode,
        setUsePinCode,
        pinCode,
        setPinCode,
        maxEntries,
        setMaxEntries,
        allowReferrals,
        setAllowReferrals,
        hostNote,
        setHostNote,
        hostNoteError,
        setHostNoteError,
        handleCreatePool,
        hasSubmitted,
        setHasSubmitted,
        createdPoolError,
        setCreatedPoolError,
        createdPoolErrorMessage,
        setCreatedPoolErrorMessage,
    }), [myUserId, userData, gameType, masterPoolId, groupName, groupNameError, usePinCode, pinCode, maxEntries, allowReferrals, hostNote, hostNoteError, handleCreatePool, hasSubmitted, createdPoolError, createdPoolErrorMessage]);
    

    return (
        <CreateAPoolContext.Provider value={CreateAPoolContextValues}>
            {children}
        </CreateAPoolContext.Provider>
    );
};

export default CreateAPoolProvider;