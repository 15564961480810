import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import { getTeamName, getTeamLogo } from "../../../utils/SportsData";

const DuplicatePickModal = ({ open, onClose, removePickData, setRemovePickData, teams, league, intervalInfo, handleSurvivorSubmit }) => {

    console.log("Remove Pick Data: ", removePickData, "Teams", teams?.length, "League", league, "Interval Info", intervalInfo);

    const removedPickInterval = Number(removePickData?.hasPickAlreadyBeenSelected?.interval);
    console.log("Removed Pick Interval: ", removedPickInterval);
    const intervalName = intervalInfo?.info.find(interval => interval.interval === removedPickInterval)?.label;
    console.log("Interval Name: ", intervalName);
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                className="modal-box" 
                sx={{
                    backgroundColor: 'white',
                    width: '308px',
                    maxWidth: '450px',
                    height: 'auto',
                    padding: '16px',
                    margin: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    borderRadius: 1
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize: '16px', fontWeight: '800', textAlign: 'center', marginBottom: '24px'}}>
                    Confirm Change 
                </Typography>

                <img src={getTeamLogo(league, removePickData?.hasPickAlreadyBeenSelected?.teamId, teams)} alt="Team Logo" style={{ width: '48px', height: 'auto', margin: '20px auto', display: 'block' }} />

                <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize: '16px', fontWeight: '400', textAlign: 'center'}}>
                    This will remove your {intervalName} selection of {getTeamName(league, removePickData?.hasPickAlreadyBeenSelected?.teamId, teams)}.  Are you sure you want to make the change?
                </Typography>

                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        gap: '16px', // Space between buttons
                        mt: 2       // Top margin for the box
                    }}
                >
                    <Button 
                        variant='contained'
                        onClick={onClose}
                        sx={{ 
                            backgroundColor: '#E5E5E5', // Set the background color
                            color: '#002129', 
                            width: '115px',
                            height: '42px',
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={() => handleSurvivorSubmit(removePickData?.teamId, removePickData?.gameId, removePickData?.interval, removePickData?.hasGameStarted, removePickData?.action, removePickData?.hasPickAlreadyBeenSelected)}
                        sx={{
                            height: '42px',
                            width: '115px',
                        }}
                    >
                        CONFIRM
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default DuplicatePickModal;