import React, { useState, useEffect, useCallback } from 'react';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import './MyPicksNavBar.scss';
import { set } from 'lodash';

/**
 * Navigation bar component that handles both week-based (NFL style) and date-based (Tournament style) navigation
 * Can be controlled via URL parameters and falls back to smart defaults
 */
const MyPicksNavBar = ({ theCurrentInterval }) => {
    const {
        masterPoolData,
        gameData,
        userData,
        currentWeek, //ADD THIS TO CONTEXT OR MAYBE PROPS
        intervalInfo,
        myPicksNavBarInfo,
        gameId,
        masterPoolId,
        page,
        gameAddress,
        pickIndex,
        interval,
        unsavedPicks,
        setUnsavedPicksModalOpen,
        setUrlForNav,
    } = useUserAndGameContext();
    console.log("MyPicksNavBar: ", interval, theCurrentInterval, myPicksNavBarInfo);

    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    
    //console.log("MyPicksNavBarInfo: ", myPicksNavBarInfo);
    const [selectedValue, setSelectedValue] = useState(null);
    
    // Determine if we're using week-based or date-based navigation
    const isWeekType = intervalInfo?.type === 'weeks';

    // For week-based navigation, generate array of week options from lowerBound to upperBound
    const weekOptions = isWeekType ? 
        Array.from(
            { length: intervalInfo?.upperBound - intervalInfo?.lowerBound + 1 }, 
            (_, index) => ({
                value: index + intervalInfo?.lowerBound,
                label: `Week ${index + intervalInfo?.lowerBound}`
            })
        ) : [];
            console.log("Week Options: ", weekOptions);
     /**
     * Determines the appropriate value to display based on:
     * 1. URL parameters (if present)
     * 2. For weeks: currentWeek from context or lowerBound as fallback
     * 3. For dates: Current date (adjusted for 6AM cutoff) or closest future date
     */
     const findAppropriateValue = useCallback(() => {
        if (!intervalInfo) return null;
    
        const intervalParam = searchParams.get('interval');
        
        // First try to use URL parameter if it exists and is valid
        if (intervalParam !== null) {
            const interval = parseInt(intervalParam);
            if (!isNaN(interval)) {
                if (isWeekType) {
                    if (interval >= intervalInfo?.lowerBound && interval <= intervalInfo?.upperBound) {
                        return interval;
                    }
                } else {
                    const dateInfo = intervalInfo?.info?.find(item => item.interval === interval);
                    if (dateInfo) {
                        return dateInfo.date;
                    }
                }
            }
        }
    
        // If no valid URL parameter, use theCurrentInterval and convert to appropriate format
        if (theCurrentInterval !== null && theCurrentInterval !== undefined) {
            if (isWeekType) {
                return theCurrentInterval;
            } else {
                const dateInfo = intervalInfo?.info?.find(item => item.interval === theCurrentInterval);
                // Only need to convert to date for date-based navigation
                return dateInfo?.date;
            }
        }
    
        // Fallback to first valid interval if somehow nothing else works
        return isWeekType ? intervalInfo?.lowerBound : intervalInfo?.info[0]?.date;
    }, [intervalInfo, isWeekType, searchParams, theCurrentInterval]);
    
    // Initialize the selected value and URL parameters
    useEffect(() => {
        //console.log("Running Selected Value Effect");
        if (intervalInfo && !selectedValue) {
            const appropriateValue = findAppropriateValue();
            setSelectedValue(appropriateValue);
            
            if (isWeekType) {
                searchParams.set('interval', appropriateValue.toString());
            } else {
                const dateInfo = intervalInfo?.info?.find(item => item.date === appropriateValue);
                if (dateInfo) {
                    searchParams.set('interval', dateInfo.interval.toString());
                }
            }
            setSearchParams(searchParams);
        }
    }, [intervalInfo, selectedValue, isWeekType, searchParams, setSearchParams, findAppropriateValue, gameId, masterPoolId, pickIndex]);

    /**
     * Watch for external URL parameter changes and update the selected value
     * This ensures the component stays in sync when other components modify the URL
     */
    useEffect(() => {
        //console.log("Checking for external URL changes");
        if (intervalInfo && selectedValue) {
            const appropriateValue = findAppropriateValue();
            if (appropriateValue !== selectedValue) {
                //console.log("Updating selected value due to external URL change:", appropriateValue);
                setSelectedValue(appropriateValue);
            }
        }
    }, [searchParams, intervalInfo, findAppropriateValue, selectedValue, gameId, masterPoolId]);

    /**
     * Helper function to get current index for either navigation type
     * For weeks: Calculates offset from lowerBound
     * For dates: Finds index in date array
     */
    const getCurrentIndex = () => {
        if (isWeekType) {
            return selectedValue - intervalInfo?.lowerBound;
        }
        return intervalInfo?.info?.findIndex(item => item.date === selectedValue);
    };

    /**
     * Handles arrow button navigation
     * Updates both the selected value and URL parameters
     */
    const handleChange = (direction) => {
        if (unsavedPicks) {
            setUnsavedPicksModalOpen(true);
            let newInterval;
            if (direction === 'next') {
                newInterval = Math.min(selectedValue + 1, intervalInfo?.upperBound);
            } else {
                newInterval = Math.max(selectedValue - 1, intervalInfo?.lowerBound);
            }
            setUrlForNav(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=${page}&interval=${newInterval}&pickIndex=${pickIndex}`);
            return;
        }
        if (isWeekType) {
            const newValue = direction === 'next' 
                ? Math.min(selectedValue + 1, intervalInfo?.upperBound)
                : Math.max(selectedValue - 1, intervalInfo?.lowerBound);
            setSelectedValue(newValue);
            searchParams.set('interval', newValue.toString());
        } else {
            const currentIndex = intervalInfo?.info?.findIndex(item => item.date === selectedValue);
            const newIndex = direction === 'next' 
                ? Math.min(currentIndex + 1, intervalInfo?.info?.length - 1)
                : Math.max(currentIndex - 1, 0);
            const newDateInfo = intervalInfo?.info[newIndex];
            setSelectedValue(newDateInfo.date);
            searchParams.set('interval', newDateInfo.interval.toString());
        }
        setSearchParams(searchParams);
    };

    /**
     * Handles dropdown selection changes
     * Updates both the selected value and URL parameters
     */
    const handleSelectChange = (event) => {
        if (unsavedPicks) {
            setUnsavedPicksModalOpen(true);
            setUrlForNav(`/${uriEncoded(gameAddress)}?mId=${masterPoolId}&gId=${gameId}&page=${page}&interval=${event.target.value}&pickIndex=${pickIndex}`);
            return;
        }

        const newValue = event.target.value;
        setSelectedValue(newValue);
        
        if (isWeekType) {
            searchParams.set('interval', newValue.toString());
        } else {
            const dateInfo = intervalInfo?.info?.find(item => item.date === newValue);
            if (dateInfo) {
                searchParams.set('interval', dateInfo.interval.toString());
            }
        }
        setSearchParams(searchParams);
    };

    if (!intervalInfo || !selectedValue) return null;

    const currentIndex = getCurrentIndex();

    return (
        <Box 
            className="my-picks-nav-bar"
            sx={{ 
                backgroundColor: myPicksNavBarInfo?.backgroundColor,
                color: myPicksNavBarInfo?.textColor,
            }}
        >
            <Box className="my-picks-nav-bar-container">
                <Box className="my-picks-nav-bar-content">
                    <IconButton 
                        onClick={() => handleChange('prev')}
                        disabled={isWeekType ? selectedValue <= intervalInfo?.lowerBound : currentIndex === 0}
                    >
                        <ArrowBackIosNewIcon
                            className="my-picks-nav-bar-arrow-icon" 
                            sx={{ color: myPicksNavBarInfo?.textColor || '#fff' }}
                        />
                    </IconButton>
                    <Box className="my-picks-nav-bar-select-container">
                        <Select
                            value={selectedValue}
                            onChange={handleSelectChange}
                            variant="outlined"
                            className="my-picks-nav-bar-select"
                            sx={{ 
                                color: myPicksNavBarInfo?.textColor || '#fff',
                                '& .MuiSvgIcon-root': {
                                    color: myPicksNavBarInfo?.textColor || '#fff'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        >
                            {isWeekType ? (
                                weekOptions?.map(week => (
                                    <MenuItem 
                                        key={week.value} 
                                        value={week.value}
                                        sx={{ color: '#000000' }}
                                    >
                                        {week.label}
                                    </MenuItem>
                                ))
                            ) : (
                                intervalInfo?.info?.map((item) => (
                                    <MenuItem 
                                        key={item.date} 
                                        value={item.date}
                                        sx={{ color: '#000000' }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </Box>
                    <IconButton 
                        onClick={() => handleChange('next')}
                        disabled={isWeekType ? selectedValue >= intervalInfo?.upperBound : currentIndex === intervalInfo?.info?.length - 1}
                    >
                        <ArrowBackIosNewIcon 
                            className="my-picks-nav-bar-arrow-icon"
                            sx={{ color: myPicksNavBarInfo?.textColor || '#fff', transform: 'rotate(180deg)' }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default MyPicksNavBar;