import { useBirdieContext } from '../context/BirdiePoolContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BirdieClubHeader = () => {
    const { birdiePoolData } = useBirdieContext();
    //console.log("Birdie Club Header Data", birdiePoolData);
    const headerDisplayName = birdiePoolData?.headerDisplayName;
    const clubLogo = birdiePoolData?.clubLogo;
    //console.log("Header Display Name", headerDisplayName, "Club Logo", clubLogo);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            margin: isMobile? '20px auto': '30px auto',
            }}>
            <img style={{
                width: isMobile ? '30px' : '45px'
                }}
                src={clubLogo}
                alt={headerDisplayName}
            />
            <h1
                style={{
                    fontSize: isMobile ? '20px' : '36px',
                    fontWeight: '700',
                    marginLeft: '15px',
                    marginRight: '15px'
                    }}>
                    {headerDisplayName}
            </h1>
            <img style={{
                width: isMobile ? '30px' : '45px'
                }}
                src={clubLogo}
                alt={headerDisplayName}
            />
        </div>
    )
};

export default BirdieClubHeader;