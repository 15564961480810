import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Modal, Typography } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useJoinPrivatePool } from './hooks/useJoinPrivatePool';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrivatePoolPasscode from './privatePoolPasscode';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const PrivatePoolsList = (props) => {
  const { pools, linkForLogin } = props;
  //console.log("Private Pools", pools);
  const { mutate: joinPool } = useJoinPrivatePool();
  const userData = getUserDataFromLocalStorage();
  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const [passcode, setPasscode] = useState(new Array(4).fill(''));

  const [open, setOpen] = useState(false);
  const [gameID, setGameID] = useState(null);
  const [gameType, setGameType] = useState(null);
  const [clubName, setClubName] = useState(null);

  const [status, setStatus] = useState(null);//UI Purposes
  const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const joinGame = async (gameID, gameType, poolName) => {
    let data = {
      gameID: gameID,
      password: passcode,
      userID: userData.user_id,
      gameType: gameType
    };

    joinPool(data, {
      onSuccess: () => {
        window.location.href = `/birdie-pool/${uriEncoded(gameID)}/SCORE_CARD/${uriEncoded(poolName)}`;
        //navigateToTop(`/birdie-pool/${uriEncoded(gameID)}/SCORE_CARD/${uriEncoded(poolName)}`);  // Navigate to the game page
      },
      onError: (error) => {
        setStatus("fail");
        const message = error.response?.data?.message || 'Invalid passcode. Please try again.';
        setErrorMessage(message);
        console.error("Join pool failed:", error);

        // Clear the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage('');
          setStatus(null);
        }, 5000);
      }
    });
  };


  const handleJoinPool = (gameID, gameType, poolName) => {
    joinGame(gameID, gameType, poolName);
  };

  const handleModal = (gameID, gameType, poolName) => {
    if(!userData) {
      navigateToTop(linkForLogin);
    } else {
    if (poolName === "Pool Party Demo Birdie Pool") {
      setPasscode(['2', '0', '2', '3']);
    }
    setOpen(true);
    setGameID(gameID);
    setGameType(gameType);
    setClubName(poolName);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Box>
      {pools.map((pool, i) => {
        //console.log("POOL", pool); // This will log the entire pool object
        const doesUserBelong = pool.clubMembers.filter(members => members === userData?.user_id);
        //console.log(doesUserBelong);
        return (
          <Box key={i} mb={4} sx={{width:'100%'}}>
            <Typography variant="h4" component="h2" fontWeight="700" fontSize="24px" style={{ margin: '30px auto' }}>{pool.gameName}</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" mt={2} sx={{marginBottom:"25px"}}>
              <img src={pool.clubLogo} alt={pool.clubName} style={{ width: '100%', maxWidth:'150px', maxHeight: '100%' }} />
            </Box>
            <Typography variant="body1" sx={{ margin: '10px auto' }}><b>Pool Start:</b> {formatDate(pool.startDate)}</Typography>
            <Typography variant="body1"><b>Pool End:</b> {formatDate(pool.endDate)}</Typography>
            {open && (
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                  onClick: (e) => e.stopPropagation(),
                }}
              >
                <Box className="modal-container" sx={{ height: '240px', maxWidth: '380px', padding: '5px'}}>
                  <Typography className="modal-title" style={{ fontSize: isMobile ? '28px' : '30px', fontWeight: '700', marginTop: '5px', marginBottom: '20px'}}>Pool Pin</Typography>
                  {status === "fail" && <Typography className="error-message" style={{ color: 'red' }}>{errorMessage}</Typography>}
                  {clubName === "Pool Party Demo Birdie Pool" && (
                    <Typography variant="body2" style={{ color: 'green', marginBottom: '10px' }}>
                      Demo Pool Passcode Pre-filled: 2023
                    </Typography>
                  )}
                  <PrivatePoolPasscode
                    handleJoinPool={handleJoinPool}
                    gameType={gameType}
                    gameID={gameID}
                    passcode={passcode}
                    setPasscode={setPasscode}
                    setOpen={setOpen}
                    setStatus={setStatus}
                    poolName={clubName}
                  />
                </Box>
              </Modal>
            )}
            {doesUserBelong.length > 0 ?
              <Button style={{ margin: '30px auto' }} variant="contained" onClick={() => navigateToTop(`/birdie-pool/${uriEncoded(pool.gameID)}/SCORE_CARD/${uriEncoded(pool.headerDisplayName)}`)}>
                VIEW POOL
              </Button>
              :
              <Button style={{ margin: '30px  auto' }} variant="contained" onClick={() => handleModal(pool.gameID, pool.gameType, pool.headerDisplayName)}>
                JOIN POOL
              </Button>
            }
          </Box>
        );
      })}
    </Box>
  );
};

export default PrivatePoolsList;
