import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PickEmCard from './pickEmCard';
// import './styles.scss';
// import nbaGameOddsByDate from './nbaGameOddsByDate';
// import nbaGamesByDate from './nbaGamesByDate';
import { getUserDataFromLocalStorage } from '../../redux/actions/loginImport';
import DateNavBar from './DateNavBar';
import { useDispatch } from 'react-redux';
import { gamePicksSave } from '../../redux/actions/gameImport';
import { useParams, useNavigate } from 'react-router-dom';
import UserPickBar from './UserPick';
import Button from '@mui/material/Button';
import  { DateTime } from "luxon";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const SuperPickem = (props) => {

  const { NBAStandings, NBATeams, nbaGamesByDate, nbaGameOddsByDate } = props;

  const userData = getUserDataFromLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const { gameId } = useParams(); // Extract the gameId from the URL

  const isUserPartOfGame = userData?.Games?.find(game => game.gameID === gameId);
  //console.log("Is User Part of Game:", isUserPartOfGame);
  
  function doesUserHaveAPickForSelectedDay() {
    // Find the user's pick for the selected day
    let Games = userData?.Games;
    let selectedPick = null;

    // Iterate over Games to find the first matching pick
    Games.some(game => {
        // Find the pick that matches the date
        const pick = game?.pool?.dailyPicks?.find(pick => pick.dateTime.startsWith(dateState));
        if (pick) {
            selectedPick = pick;
            return true; // Stop the iteration once a matching pick is found
        }
        return false;
    });

    return selectedPick;
  }
  function getUserPickForSelectedDay() {
    // Find the user's pick for the selected day
    let Games = userData?.Games;
    let selectedPick = null;

    // Iterate over Games to find the first matching pick
    Games.some(game => {
        // Find the pick that matches the date
        const pick = game?.pool?.dailyPicks?.find(pick => pick.dateTime.startsWith(dateState));
        if (pick) {
            selectedPick = pick;
            return true; // Stop the iteration once a matching pick is found
        }
        return false;
    });

    return selectedPick;
}


  const generateUUID = () => {
    const newUUID = uuidv4(); // Generates a new UUID
    console.log(newUUID); // You can use this UUID as needed
    return newUUID;
  };

  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getTodayOrYesterdayInEST = () => {
    const currentDate = new Date(); // Current date in local time zone
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // January is 0, so add 1 to get the actual month
    const currentDay = currentDate.getDate();
    // Check if the current date is after June 23, 2024
    if (currentYear > 2024 || (currentYear === 2024 && currentMonth > 6) || (currentYear === 2024 && currentMonth === 6 && currentDay > 23)) {
      // Return "2024-06-23" if the date is after June 23, 2024
      return "2024-06-23";
    }
    // Check if the current date is before April 20, 2024
    if (currentYear < 2024 || (currentYear === 2024 && currentMonth < 4) || (currentYear === 2024 && currentMonth === 4 && currentDay < 20)) {
      // Return "2024-04-20" if the date is before April 20, 2024
      return "2024-04-20";
    } else {
      // Continue with the original logic to return today's date or yesterday's date in EST
      const currentHour = currentDate.getHours(); // Get current hour in local time zone
      // Check if it's before 3 AM local time
      if (currentHour < 3) {
        // If it's before 3 AM local time, adjust the date to yesterday in EST
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        return formatDateString(yesterday);
      } else {
        // Otherwise, return today's date in EST
        return formatDateString(currentDate);
      }
    }
  };
  const today = getTodayOrYesterdayInEST();
  //console.log("Today's date:", today);
  //UPDATE THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const [dateState, setDateState] = useState(today);
  const userAlreadyHasPick = doesUserHaveAPickForSelectedDay();
  //console.log("Does user have a pick for today?", userAlreadyHasPick);
  let userPickForSelectedDay = {};
  if (userAlreadyHasPick) {
    userPickForSelectedDay = getUserPickForSelectedDay();
  }
  //console.log("User's pick for selected day:", userPickForSelectedDay);
  //console.log("Date State:", dateState);
  // Set the initial state of pickEmState based on whether the user already has a pick
const [pickEmState, setPickEmState] = useState(userPickForSelectedDay ? [userPickForSelectedDay] : []);
// ADD USER PICKS HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


function areThereGamesToday() {
  // Check if there are games today and their status is not "NotNecessary"
  return nbaGamesByDate.some(game => game.Day.startsWith(dateState) && game.Status !== "NotNecessary");
}


const gamesToday = areThereGamesToday();

//console.log("Are there games today?", gamesToday);

// Update pickEmState when dateState changes
useEffect(() => {
  // Find the user's pick for the selected day
  const userPickForSelectedDay = getUserPickForSelectedDay();

  // Update pickEmState based on whether the user already has a pick
  setPickEmState(userPickForSelectedDay ? [userPickForSelectedDay] : []);
}, [dateState]);


  //console.log("DATE:", dateState);
  const games = getGamesByDate(dateState);
  //console.log("TODAYS GAMES: ", games);


  function allGamesOver(games) {
    const currentTime = new Date(); // Get the current time

    // Iterate through each game
    for (const game of games) {
      //console.log("Game Date Time:", game.DateTime);
        if (game.DateTime === null) {
          return false;
        }
        // Parse the game's DateTime string into a Date object
        const gameDateTime = new Date(game.DateTime);
        //console.log("Game Date Time:", gameDateTime, game.DateTime);

        // Compare the game's DateTime with the current time
        if (gameDateTime > currentTime) {
            // If any game is in the future, return false
            return false;
        }
    }

    // If all games are in the past, return true
    return true;
  }
  const allGamesAreOver = allGamesOver(games);
  // const allGamesAreOver = false;
  //console.log("Are all games over?", allGamesAreOver);


  function getGamesByDate(date) {
    return nbaGamesByDate?.filter(game => game.Day.startsWith(date) && game.Status !== 'NotNecessary');
  }
  function dateStateForDisplay() {
    const date = new Date(dateState + "T00:00:00"); // Ensures local midnight is used

    // Define the options for formatting the date
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const dateForDisplay = dateStateForDisplay();
  const handleSubmit = async () => {
    const currentTimeInNY = DateTime.now().setZone("America/New_York");
    const gameTimeInNY = DateTime.fromISO(pickEmState.dateTime).setZone("America/New_York");

    const hasThisGameStarted = currentTimeInNY >= gameTimeInNY;

    let userPicks = {
      gameType: "SuperPickem",
      dailyPicks: [pickEmState],
      gameName: "NBA Playoffs",
      gameId: gameId || generateUUID() // Use existing gameId or generate a new one if not present
    };

    try {
      if (!hasThisGameStarted) {
        const response = await dispatch(gamePicksSave(userPicks));
        console.log("Picks saved successfully:", response);
        if (!gameId) {
          console.log("New game created with ID:", userPicks.gameId);
          navigateToTop(`/nba-super-pickem/SuperPickem/${userPicks.gameId}`, { replace: true });
          window.location.reload(); // Force reload of the page
        } else {
          console.log("Updated existing game");
          navigateToTop(`/nba-super-pickem/SuperPickem/${gameId}`, { replace: true });
          window.location.reload(); // Force reload of the page
        }
      } else {
        console.log("This game has already started, please make another selection.");
      }
    } catch (error) {
      console.error("Failed to save picks:", error);
      // Optionally handle error, e.g., show a notification
    }
};
  function nbaAwayPointSpread(globalGameID) {
    // Search for the game object with the specified GlobalGameID
    const game = nbaGameOddsByDate?.find(game => game.GlobalGameId === globalGameID);

    // If the game object is found
    if (game) {
      // Find the PregameOdds object with the Sportsbook as "Consensus"
      const consensusOdds = game.PregameOdds.find(odds => odds.Sportsbook === "Consensus");

      // If the odds object is found, return the AwayPointSpread
      if (consensusOdds) {
        return consensusOdds.AwayPointSpread;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

// Function to find AwayPointSpread for a specific GlobalGameID
function nbaHomePointSpread(globalGameID) {
    // Search for the game object with the specified GlobalGameID
    const game = nbaGameOddsByDate?.find(game => game.GlobalGameId === globalGameID);

    // If the game object is found
    if (game) {
      // Find the PregameOdds object with the Sportsbook as "Consensus"
      const consensusOdds = game.PregameOdds.find(odds => odds.Sportsbook === "Consensus");

      // If the odds object is found, return the AwayPointSpread
      if (consensusOdds) {
        return consensusOdds.HomePointSpread;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };


function formatDate(dateString) {
    const months = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const suffixes = ['TH', 'ST', 'ND', 'RD'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const suffix = suffixes[(dayOfMonth % 10 > 0 && dayOfMonth % 10 < 4 && (dayOfMonth < 11 || dayOfMonth > 13)) ? dayOfMonth % 10 : 0];
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneMinutes = Math.abs(timezoneOffset % 60);
    const timezone = timezoneOffset <= 0 ? 'EST' : 'EDT'; // Assuming EST/EDT for Eastern Time

    let formattedDate = null;
    if (ampm === 'PM') {
    formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}${suffix} ${formattedHours}:${minutes} ${ampm} ${timezone}`;
    } else {
    formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}${suffix} TBD`;
    }
    return formattedDate;
  };

function quarterForDisplay(quarter) {
    if (quarter === 1) {
        return "1st";
    } else if (quarter === 2) {
        return "2nd";
    } else if (quarter === 3) {
        return "3rd";
    } else if (quarter === 4) {
        return "4th";
    } else if (quarter === "OT") {
        return "OT";
    } else {
        return null;
    }
}

function nbaGetConference(globalTeamID) {
    const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
    if (team.Conference === 'Eastern') {
        return 'East';
    } else if (team.Conference === 'Western') {
        return 'West';
    } else {
        // If the conference is neither Eastern nor Western, you can handle it accordingly
        return 'Unknown';
    }
}


function nbaGetKey(globalTeamID) {
    const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
    return team.Key;
}

function nbaGetLogo(globalTeamID) {
    const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
    return team.WikipediaLogoUrl;
    }


function nbaGetShortDisplayName(globalTeamID) {
        const team = NBATeams?.find(team => team.GlobalTeamID === globalTeamID);
        let teamKey = team.Key.startsWith("LA") ? "LA" : team.Key;
        let teamName = team.Name.startsWith("Timberwolves") ? "T'Wolves" : team.Name;
        return `${teamKey} ${teamName}`;
    }
// FIX THE STANDINGS HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
function nbaGetTeamSeed(globalTeamID) {
    const team = NBAStandings?.find(team => team.GlobalTeamID === globalTeamID);
    return team.ConferenceRank;
}
const handleDateChange = (direction) => {
  const currentDate = new Date(dateState);
  const newDate = new Date(currentDate);

  if (direction === 'prev') {
    newDate.setDate(currentDate.getDate() - 1);
  } else {
    newDate.setDate(currentDate.getDate() + 1);
  }

  // Check lower and upper bounds UPDATE THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const lowerBound = new Date('2024-04-20');
  const upperBound = new Date('2024-06-23');


  if (newDate >= lowerBound && newDate <= upperBound) {
    setDateState(newDate.toISOString().split('T')[0]);
  }
  setPickEmState([]);
};
  return (
    <div>
        {!isUserPartOfGame ? (
            <div className="awaiting-pick">Game Entry Closed</div>
          ) : userAlreadyHasPick ? (
            <UserPickBar
              dateState={dateState}
              userAlreadyHasPick={userAlreadyHasPick}
              pickEmState={pickEmState}
              nbaGamesByDate={nbaGamesByDate}
              NBATeams={NBATeams}
              allGamesAreOver={allGamesAreOver}
            />
          ) : (
            !allGamesAreOver ? (
              <div className="awaiting-pick">AWAITING PICK</div>
            ) : !gamesToday ? (
              <div className="awaiting-pick">NO GAMES TODAY</div>
            ) : (
              <div className="no-pick">PICK MISSED</div>
            )
        )}

      <DateNavBar dateState={dateState} setDateState={setDateState} dateForDisplay={dateForDisplay} setPickEmState={setPickEmState} handleDateChange={handleDateChange}/>
      {(!userAlreadyHasPick && games.length > 0 && isUserPartOfGame) ? <h1 style={{marginTop:'15px', marginBottom:'25px'}}>Make One Selection</h1>: (<div style={{ height: '40px' }} /> // Add a div with some height as a placeholder
      )}
      {games.map((game, index) => (
        <PickEmCard key={index} game={game} pickEmState={pickEmState} setPickEmState={setPickEmState} userAlreadyHasPick={userAlreadyHasPick} userPickForSelectedDay={userPickForSelectedDay} nbaAwayPointSpread={nbaAwayPointSpread} nbaHomePointSpread={nbaHomePointSpread} formatDate={formatDate} quarterForDisplay={quarterForDisplay} nbaGetConference={nbaGetConference} nbaGetKey={nbaGetKey} nbaGetLogo={nbaGetLogo} nbaGetShortDisplayName={nbaGetShortDisplayName} nbaGetTeamSeed={nbaGetTeamSeed} allGamesAreOver={allGamesAreOver}/>
      ))}
      {!isUserPartOfGame ? <h3 style={{marginBottom:'12px'}}>
          Sorry, it's too late to join <br></br> this game and make picks.
        </h3>: <></>}
        {userAlreadyHasPick || allGamesAreOver || !isUserPartOfGame ? null : (
        <>
        {!userAlreadyHasPick ? <h3 style={{marginBottom:'12px'}}>
         Once you submit,<br></br>your pick is final.
         </h3>: <></>}
        <Button

          className={`submit-button ${pickEmState.length === 0 ? '' : 'active'}`}
          onClick={handleSubmit}
          sx={{ width: '150px'}}
          disabled={pickEmState.length === 0} // Disable the button if pickEmState is empty
        >
          SUBMIT PICK
        </Button>
        </>
    )}


    </div>
  );
};

export default SuperPickem;