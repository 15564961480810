import React from "react";
// import '../styles.scss';

const BlockPoolGameDetails = (props) => {
    console.log('block pool game details', props);
    const { gameData, allGames, sportsGameData, nflTeamsData } = props;
    const gridSizeOne = gameData.pool.numberOfBlocks;

    const generateHeaderItems = (numbers, headerIndex) => {

        let background = '';

        if(headerIndex === 0) {
             background = 'red';
        }else if(headerIndex === 1) {
             background = 'blue';
        }else if(headerIndex === 2) {
             background = 'orange';
        }else if(headerIndex === 3) {
             background = 'green';
        }
        return numbers.map((number, index) => (
            <th key={index} className="header-item" style={{background: background, borderColor: background }}>
                {number}
            </th>
        ));
    };

    const sideHeaders = (games) => {
        const gridRows = [];
        const newArray = [];
        const gameArrays = [];

        games.forEach((game, index) => {
            gameArrays[index] = game.gameYAxis.map((element) => Number(element));
        });

        for (let i = 0; i < gameArrays[0].length; i++) {
            const rowData = [];

            gameArrays.forEach((array) => {
                rowData.push(array[i]);
            });

            newArray.push(rowData);

            for (let j = 1; j <= 10; j++) {
                newArray.push(i * 10 + j);
            }
        }

        const rowSize = 11; // Number of cells in each row
        let currentRow = [];


        newArray.forEach((row, index) => {


        if (row.length === 4) {

            row.forEach((value, index) => {
                let background = '';
                if(index === 0) {
                    background = 'red'
                }else if(index === 1) {
                    background = 'blue'
                }else if(index === 2) {
                    background = 'orange'
                }else if(index === 3) {
                    background = 'green'
                }
                currentRow.push(<th  style={{display:'block', width:25,
                padding: '25px 0', background:background }}>{Number(value)}</th>);
            });

        } else {
            currentRow.push(<td  style={{ flex:1, textAlign:'center', padding: `${row > 99? '25px 21px': '25px'}`, background:'#bdbdbd', border:'1px solid #8a8a8a'}}><div>{row}</div></td>);
        }

        if ((index + 1) % rowSize === 0) {
            // Split into a new row
            gridRows.push(<tr className="table-row" key={index / rowSize}>{currentRow}</tr>);
            currentRow = [];
        }
        });

        if (currentRow.length > 0) {
            // Handle remaining cells in the last row
            gridRows.push(<tr key="lastRow" className="table-row">{currentRow}</tr>);
        }
        return gridRows;
    };

    const generateScoreCards = (numbers, headerIndex) => {
        const scoreCards = [];
        if(headerIndex === 0){
            scoreCards.push(<div>
                <div>Q{headerIndex + 1}</div>
                <div></div>
            </div>);
        }else if(headerIndex === 1){
            scoreCards.push(<div>
                <div>Q{headerIndex + 1}</div>
                <div></div>
            </div>);
        }else if(headerIndex === 2){
            scoreCards.push(<div>
                <div>Q{headerIndex + 1}</div>
                <div></div>
            </div>);
        }else if(headerIndex === 3){
            scoreCards.push(<div>
                <div>Q{headerIndex + 1}</div>
                <div></div>
            </div>);
        }
    };

    //   const  gameDate = dateTime(game.generatedGame[0]?.gameDate);
    return (
        <div className="game-details-container">
            {allGames.map((game, index) => {
                 const lookupGameByKey = (gameKey) => {
                    let sportsReduced = sportsGameData.filter(game => game.GameKey === gameKey) || null;
                    return {...sportsReduced["0"]};
                  };
                 const filterTeams = (gameTeams) => {
                    let homeTeamData = nflTeamsData.filter(games => games.Key === gameTeams.HomeTeam) || null;
                    let awayTeamData = nflTeamsData.filter(games => games.Key === gameTeams.AwayTeam) || null;
                    let xAxisTeam = [];
                    let yAxisTeam = [];
                    nflTeamsData.forEach(games => {if(games['Key'] === game.generatedGame[0].teamXAxis){xAxisTeam.push(games);}});
                    nflTeamsData.forEach(games =>{if(games['Key'] === game.generatedGame[0].teamYAxis){yAxisTeam.push(games);}});
                    return {homeData:{...homeTeamData["0"]}, awayData:{...awayTeamData["0"]}, xAxisTeam:{...xAxisTeam["0"]}, yAxisTeam:{...yAxisTeam["0"]}}
                 }
                //   const newgameData = lookupGameByKey(game.generatedGame[0].gameId);

                    let gameDataNE = lookupGameByKey(game.generatedGame[0].gameId);
                    let teamData = filterTeams(gameDataNE);

                  return (
                    <>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', maxWidth:850, margin:'0 auto', color:'#fff', marginBottom:"25px", padding:20, background:'#232323', borderRadius:20, boxShadow:'0 0 8px #000',overflowX:'auto'}}>

                    <div style={{transform: 'rotate(-90deg)', display: 'flex', alignItems:'center'}}>
                        <img src={teamData.yAxisTeam.WikipediaLogoUrl} style={{width:50, height:'auto', display:'block'}}/>
                        <div style={{position: 'absolute', right: -126, fontWeight:900}}>{teamData.yAxisTeam.FullName}</div>
                    </div>
                    <div>
                    <div style={{textAlign:'center', display: 'flex', alignItems:'center', justifyContent:'center'}}><img style={{width:50, height:'auto', display:'block'}} src={teamData.xAxisTeam.WikipediaLogoUrl} />{teamData.xAxisTeam.FullName}</div>
                        <table key={index}>
                            <thead className="grid-row header-row">
                                {game.generatedGame.map((generatedGame, headerIndex) => (
                                    <tr style={{display:'flex'}} key={headerIndex}>
                                        {headerIndex === 0 && (
                                            <>
                                            <div style={{ background: 'red', width: 100, marginLeft:'auto',padding:'1px 0'  }}>
                                                Q1
                                            </div>
                                            {generateHeaderItems(generatedGame.gameXAxis, headerIndex)}
                                            </>
                                        )}
                                            {headerIndex === 1 && (
                                            <>
                                            <div style={{ background: 'blue', width:75, marginLeft:'auto',padding:'1px 0'}}>
                                                Q2
                                            </div>
                                            {generateHeaderItems(generatedGame.gameXAxis, headerIndex)}
                                            </>
                                        )}
                                            {headerIndex === 2 && (
                                            <>
                                            <div style={{ background: 'orange', width: 50, marginLeft:'auto',padding:'1px 0' }}>
                                                Q3
                                            </div>
                                            {generateHeaderItems(generatedGame.gameXAxis, headerIndex)}
                                            </>
                                        )}
                                        {headerIndex === 3 && (
                                            <>
                                            <div style={{ background: 'green', width:25, marginLeft:'auto',padding:'1px 0' }}>
                                                Final
                                            </div>
                                            {generateHeaderItems(generatedGame.gameXAxis, headerIndex)}
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {sideHeaders(game.generatedGame)}
                            </tbody>
                        </table>
                    </div>
               </div>
            </>
            )})}
        </div>
    );
};

export default BlockPoolGameDetails;