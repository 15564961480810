import React, { useState, useRef } from 'react';
import { Box, Button, TextField } from '@mui/material';

const PrivatePoolPasscode = (props) => {
  const { handleJoinPool, gameID, passcode, setPasscode, gameType, setOpen, setStatus, poolName } = props;
  const inputRefs = useRef([]);

  const handlePasscodeChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newPasscode = [...passcode];
      newPasscode[index] = value;
      setPasscode(newPasscode);
      //console.log('New passcode:', newPasscode);
      // Focus the next input field
      if (value !== '' && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && passcode[index] === '') {
      // Focus the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        {passcode.map((num, index) => (
          <TextField
            key={index}
            id={`passcode-${index}`}
            value={num}
            onChange={(e) => handlePasscodeChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
            sx={{ width: '50px' }}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2}}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(false);
          setPasscode(new Array(4).fill(''));
          setStatus(null);
        }}
        sx={{ 
          mt: 2, 
          backgroundColor: 'white', 
          color: 'black',
          '&:hover': {
            backgroundColor: 'white',
            color: 'black' // Optional: change the hover color if needed
          }
        }}
      >
        CANCEL
      </Button>
      <Button variant="contained" onClick={() => handleJoinPool(gameID, gameType, poolName)} sx={{ mt: 2 }}>
        SUBMIT
      </Button>
      </Box>
    </>
  );
};

export default PrivatePoolPasscode;
