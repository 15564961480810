import React from "react";
import { Box, Typography } from "@mui/material";
import "./PickEmUserStatsModal.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PickEmUserStatsTeamBox = ({ spread, logo, teamText, backgroundColor, borderColor }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isCloseIcon = logo === "X";
  return (
    <Box className="pick-container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box className="line-container" sx={{ marginBottom: '8px' }}>
        <Typography className="line-text" sx={{ textAlign: 'center', fontSize: isMobile ? '10px' : '14px' }}>
          {spread !== null ? (spread === 0 ? 'Pk' : (spread > 0 ? `+${spread}` : spread)) : '\u00A0'}
        </Typography>
      </Box>
      <Box className="logo-container" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box 
          className="logo-border" 
          sx={{ 
            borderColor: borderColor, 
            backgroundColor: backgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
           {isCloseIcon ? (
            <CloseIcon sx={{ fontSize: 'inherit', width: '29px', height: '32px', color: '#CC293C' }} />
          ) : (
            <img
              className="team-logo-img"
              loading="lazy"
              alt=""
              src={logo}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Box>
      </Box>
      <Typography className="team-text" sx={{ marginTop: '8px', textAlign: 'center', whiteSpace: 'nowrap', color: teamText === 'Miss' ? '#CC293C' : '#002129' }}>
        {teamText !== null ? teamText : '\u00A0'}
      </Typography>
    </Box>

  );
};

export default PickEmUserStatsTeamBox;
