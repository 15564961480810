import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import LoginComponent from '../components/login/login';

const Login = () => {
  const { '*': redirectPath } = useParams(); // Captures everything after /login/
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  // Get referralId from query parameters
  const referralId = queryParams.get('referralId');
  
  // Initialize loginUrl to redirectPath or empty string if undefined
  let loginUrl = redirectPath || '';
  
  // Create a new URLSearchParams object to handle all query parameters
  const newQueryParams = new URLSearchParams();
  
  // Add referralId if it exists
  if (referralId) {
    newQueryParams.append('referralId', referralId);
  }
  
  // Copy all other query parameters from the original URL
  for (const [key, value] of queryParams.entries()) {
    if (key !== 'referralId') { // Skip referralId as we've already handled it
      newQueryParams.append(key, value);
    }
  }
  
  // Construct the final URL
  // If redirectPath includes ?, we need to merge the query parameters
  if (loginUrl.includes('?')) {
    // Split the URL at ? to separate the path from existing query params
    const [path, existingParams] = loginUrl.split('?');
    
    // Parse existing query params
    const existingQueryParams = new URLSearchParams(existingParams);
    
    // Merge existing params with our new params
    for (const [key, value] of existingQueryParams.entries()) {
      if (!newQueryParams.has(key)) {
        newQueryParams.append(key, value);
      }
    }
    
    // Reconstruct the URL
    loginUrl = path;
  }
  
  // Append query parameters if there are any
  const queryString = newQueryParams.toString();
  if (queryString) {
    loginUrl += (loginUrl.includes('?') ? '&' : '?') + queryString;
  }

  return <LoginComponent redirectPath={loginUrl} />;
};

export default Login;