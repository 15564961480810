import React from "react";
import { Typography, Box } from '@mui/material';
import './PickStatsCard.scss';
import PickStatsInfoBox from "./PickStatsInfoBox";
import PercentagePillBoxTwoSided from "../../../GamePageComponents/PercentagePillBox/PercentagePillBoxTwoSided";

const PickStatsStatBox = ({
    hasGameStarted,
    pickType,
    awayLogo,
    homeLogo,
    gameStatus,
    leftText,
    rightText,
    leftCount,
    rightCount,
    leftResult,
    rightResult,
    leftRecord,
    rightRecord,
}) => {

    return (
        <Box className="super-pickem-pick-stats-stat-box-container">
            {hasGameStarted && (
                <PercentagePillBoxTwoSided
                    leftCount={leftCount}
                    rightCount={rightCount}
                    leftText={leftText}
                    rightText={rightText}
                    leftResult={leftResult}
                    rightResult={rightResult}
                />
            )}

            <Box className="super-pickem-pick-stats-stat-box">
                <Box
                    sx={{
                        width: '50%',
                    }}
                >
                    <PickStatsInfoBox
                        hasGameStarted={hasGameStarted}
                        pickType={pickType}
                        gameStatus={gameStatus}
                        logo={awayLogo}
                        text={leftText}
                        pickCount={leftCount}
                        result={leftResult}
                        record={leftRecord}
                    />    
                </Box>
                <Box
                    sx={{
                        width: '50%',
                    }}
                >
                    <PickStatsInfoBox
                        hasGameStarted={hasGameStarted}
                        pickType={pickType}
                        gameStatus={gameStatus}
                        logo={homeLogo}
                        text={rightText}
                        pickCount={rightCount}
                        result={rightResult}
                        record={rightRecord}
                    />
                </Box>

            </Box>
              
        </Box>
    );
}

export default PickStatsStatBox;