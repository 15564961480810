import React from 'react';
import { Typography, Box, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const GameTimeVsSpreadSort = ({ sortBy, handleSortChange }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
        }}>
            <RadioGroup
                row
                name="sort-by"
                value={sortBy}
                onChange={handleSortChange}
            >
                <FormControlLabel
                    value="Game Time"
                    control={<Radio />}
                    label={<Typography>Game Time</Typography>}
                />
                <FormControlLabel
                    value="Largest Spread"
                    control={<Radio />}
                    label={<Typography>Largest Spread</Typography>}
                />
            </RadioGroup>
        </Box>
    );
};

export default GameTimeVsSpreadSort;