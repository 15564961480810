import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { usePrivatePartyContext } from "./context/PrivatePartyContext";
import './PrivatePartyInviteStyles.scss';
import PoolPartyGaming from '../../assets/images/pool-party-gaming.svg';
import AlreadyInPrivateParty from './AlreadyInPrivateParty';
import NotInPrivateParty from './NotInPrivateParty';
import UserIsRestricted from './UserIsRestricted';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PrivatePartyInviteContainer = () => {
  const { partyData, joinParty, inviteeDisplayName, clubLogo, foundSubgroupName, displayName, poolStartDate, poolEndDate, isUserInThisGame, isUserInThisSubgroup, amIRestricted } = usePrivatePartyContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  console.log("PrivatePartyInviteContainer", );
  console.log("Do user have data", inviteeDisplayName, clubLogo, foundSubgroupName, displayName, poolStartDate, poolEndDate, isUserInThisGame, isUserInThisSubgroup, amIRestricted);

  const hasData = inviteeDisplayName && clubLogo && foundSubgroupName && displayName && poolStartDate && poolEndDate && isUserInThisGame !== null && isUserInThisSubgroup !== null && amIRestricted !== null;

  return (
    <>
      {hasData ? (
        <>
          <h1 className="h1-private-party-invite" style={{ margin: '0 auto', padding: '20px 0', fontSize: isMobile ? '24px': '32px'}}>My Party Invite</h1>
          <hr className="line" style={{ margin: '0 auto'}} />
          {amIRestricted ? <UserIsRestricted /> : isUserInThisSubgroup ? <AlreadyInPrivateParty /> : <NotInPrivateParty />}
        </>
      ) : (
        <Box>
          Loading...
        </Box>
      )}
    </>
  );
};

export default PrivatePartyInviteContainer;
