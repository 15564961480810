import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from '../../config';

const validateUsernameRequest = (opts) => {
  const { username } = opts;
  return axios.post(`${apiUrl}/user/validate-username`, {
    username,
  }).then((res) => res.data?.data);
};

export const useValidateUsernameIsUnique = () => {
  return useMutation({
    mutationFn: validateUsernameRequest,
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      return data;
    },
  });
};
