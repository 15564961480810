import React from "react";
import { Box, Button, Typography } from "@mui/material";
import './JoinDeadlineHasPassed.scss';
import { DateTime } from 'luxon';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const JoinDeadlineHasPassed = ({ gameName, deadline, sponsorLogo, gameLogo }) => {
    console.log("GameName: ", gameName, "Deadline: ", deadline, "Sponsor Logo: ", sponsorLogo, "Game Logo: ", gameLogo);

    const navigateToTop = useNavigateToTop();

    const handleNavigateToPoolHall = () => {
        navigateToTop('/pool-hall');
    };
    
    const formattedDate = DateTime.fromISO(deadline, { zone: 'America/New_York' }).toFormat("EEEE M/d 'at' h:mma 'ET'");
    console.log("Formatted Date: ", formattedDate);
    return (
        <Box className="public-party-join-deadline-has-passed">
            <Typography className="public-party-join-deadline-has-passed-text">
                Apologies! Entry for {gameName} is now closed.
            </Typography>
            <Typography className="public-party-join-deadline-has-passed-text">
                The deadline for entry was {formattedDate}.
            </Typography>
            {sponsorLogo && (
                <img
                    src={sponsorLogo}
                    alt="Sponsor Logo"
                    className="public-party-join-deadline-has-passed-sponsor-logo"
                />
            )}
            <img
                src={gameLogo}
                alt="Game Logo"
                className="public-party-join-deadline-has-passed-game-logo"
            />
            <Typography className="public-party-join-deadline-has-passed-text">
                We hope to catch you in our upcoming pools!
            </Typography>
            <Button
                variant="contained"
                color="primary"
                className="public-party-join-deadline-has-passed-button"
                onClick={handleNavigateToPoolHall} // Set onClick handler
            >
                POOL HALL
            </Button>
        </Box>
    );
    }

export default JoinDeadlineHasPassed;