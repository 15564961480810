import React, { useEffect, useState, useCallback } from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useQuickPickGamesContext } from "../context/QuickPickGamesContext";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import './QuickPickPickStats.scss'; // Import your SCSS file
import QuickPickLeaderboardDropdown from "../Leaderboard/QuickPickLeaderboardDropdownNew";
import PlayerPicks from "./PlayerPicksNew";
import PickStatsTab from "./PickStatsTabNew";
import { DateTime } from "luxon";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MiniLoadingScreen from "../../LoadingScreen/MiniLoadingScreen";
import { useNavigate, useSearchParams } from "react-router-dom";

const QuickPickPickStats = ({ 
    gatherDisplayBarInfo, 
    teamsToInclude,
    sponsorName, 
    userPicksFromDB,
    partyId,
    rankForUsersWithoutRank,
}) => {
    const {
        leaderboardData,
        myUserId,
        league,
        intervalInfo,
        originalPicks,
        interval,
        masterPoolData,
    } = useUserAndGameContext();

    const {
        teams,
        schedule,
        quickPickGames,
        filteredQuickPickGames,
        filteredPropIds,
        firstInterval,
        lastInterval,
        theCurrentInterval,
        propIdsToUse,
        oneWeekEvent,
        standings,
    } = useQuickPickGamesContext();
    //console.log("QuickPickGames", quickPickGames, "Interval", interval, "Filtered Quick Pick Games", filteredQuickPickGames, "Filtered Prop Ids", filteredPropIds, "First Interval", firstInterval, "Last Interval", lastInterval, "The Current Interval", theCurrentInterval, "Prop Ids To Use", propIdsToUse, "One Week Event", oneWeekEvent, "Standings", standings);

    const [selectedTab, setSelectedTab] = useState('player-picks'); // Default selected tab
    const [picksTabInterval, setPicksTabInterval] = useState(interval); // Default interval for the Picks tab
    const [loading, setLoading] = useState(false);
    const [weeklyData, setWeeklyData] = useState([]);

    const intervalType = intervalInfo?.type;
    //console.log("Loading", loading);

    // Use Effect, that will timer for 1 second, and then set the loading to false
    // will run when loading is set to true
    // useEffect(() => {
    //     if (loading) {
    //         const timer = setTimeout(() => {
    //             setLoading(false);
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [loading]);

    useEffect(() => {
        if (theCurrentInterval && firstInterval) {
            if (theCurrentInterval < firstInterval) {
                setPicksTabInterval(firstInterval);
            } else if (theCurrentInterval > lastInterval) {
                setPicksTabInterval(lastInterval);
            } else {
                setPicksTabInterval(theCurrentInterval);
            }
        }
    }, [theCurrentInterval, firstInterval, lastInterval]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    const weekOptions = [];
    if (intervalType === 'weeks') {
        for (let i = firstInterval; i <= theCurrentInterval; i++) {
            weekOptions.push(
                <MenuItem key={i} value={i}>
                    Week {i}
                </MenuItem>
            );
        }
    }
    //console.log("Week Options", weekOptions);

    const handleTabChange = (tab) => {
    setSelectedTab(tab);
    };

    let findTeamName;

    if (teamsToInclude && teamsToInclude.length > 0) {
        findTeamName = gatherDisplayBarInfo(teamsToInclude[0], teams);
    }    
    const teamName = findTeamName?.teamNickname;


    if (picksTabInterval === '') {
        return <LoadingScreen />;
    }

        

  return (
    <>
        <Box className="quick-pick-pick-stats-container">

            <Button
                className={`quick-pick-pick-stats-button ${selectedTab === 'player-picks' ? 'active' : ''}`}
                onClick={() => handleTabChange('player-picks')}
            >
                Player Picks
            </Button>

            <Button
                className={`quick-pick-pick-stats-button ${selectedTab === 'pick-stats' ? 'active' : ''}`}
                onClick={() => handleTabChange('pick-stats')}
            >
                Pick Stats
            </Button>

        </Box>

        {/* {oneWeekEvent && (
            <Typography
            sx={{
              fontSize: isMobile ? '16px' : '20px',
              fontWeight: '700',
              textAlign: 'center',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            Week {picksTabInterval} Picks
          </Typography>
          )} */}
        {!oneWeekEvent && (
            <QuickPickLeaderboardDropdown
                interval={picksTabInterval}
                setInterval={setPicksTabInterval}
                currentInterval={theCurrentInterval}
                firstInterval={firstInterval}
                lastInterval={lastInterval}
                intervalType="Week"
                setLoading={setLoading}
                needToSetLoading={true}
                intervalInfo={intervalInfo}
            />
        )}

        {selectedTab === 'player-picks' && (
            !loading ? (
                <PlayerPicks
                    leaderboardInfo={leaderboardData}
                    teams={teams}
                    schedule={schedule}
                    selectedQuickPickGames={filteredQuickPickGames}
                    interval={picksTabInterval}
                    timeNowInNewYork={timeNowInNewYork}
                    currentPropIds={filteredPropIds}
                    sponsorName={sponsorName}
                    league={league}
                    teamName={teamName}
                    myUserId={myUserId}
                    userPicksFromDB={originalPicks}
                    oneWeekEvent={oneWeekEvent}
                    masterPoolData={masterPoolData}
                />
            ) : (
                <MiniLoadingScreen />
            )
        )}

        {selectedTab === 'pick-stats' && (
            <PickStatsTab
                leaderboardInfo={leaderboardData}
                teams={teams}
                standings={standings}
                schedule={schedule}
                selectedQuickPickGames={filteredQuickPickGames}
                interval={picksTabInterval}
                timeNowInNewYork={timeNowInNewYork}
                currentPropIds={filteredPropIds}
                sponsorName={sponsorName}
                league={league}
                teamName={teamName}
                oneWeekEvent={oneWeekEvent}
                masterPoolData={masterPoolData}
                propIdsToUse={propIdsToUse}
            />
        )}

    </>
  );
};

export default QuickPickPickStats;
