import React from 'react';
import { Button, Modal, Typography, Box } from '@mui/material';

const JoinSuccessModal = ({ 
  open, 
  onClose,
  successModalData,
  myUsername,
  masterPoolId,
  gameId, 
  gameName,
  masterPoolData,
  gameAddress
}) => {
  //console.log("Success Modal Data: ", successModalData, myUsername, masterPoolId, gameId);

  if (!open) return null;

  // Take myUsername and the successModalData.entryIndices and
  // Use it to create the usernames for the message.
  // If entry is 0 add nothing, otherwise add (index + 1) to the username.
  const entryIndices = successModalData?.data?.entryIndices;
  //console.log("Entry Indices: ", entryIndices);
  const usernames = entryIndices?.map((entryIndex, index) => {
    if (entryIndex === 0) return myUsername;
    return `${myUsername} (${entryIndex + 1})`;
  });
  //console.log("Usernames: ", usernames);
  
  let formattedUsernames;
  if (usernames.length === 1) {
      formattedUsernames = usernames[0]; // Single username
  } else if (usernames.length === 2) {
      formattedUsernames = usernames.join(" and "); // Two usernames → "A and B"
  } else {
      formattedUsernames = `${usernames.slice(0, -1).join(", ")} and ${usernames[usernames.length - 1]}`;
      // Three+ usernames → "A, B, C, and D"
  }

  const extraPoolData = successModalData?.data?.extraPoolData;
  //console.log("Extra Data: ", extraPoolData);

  const buttonText = masterPoolData?.joinModalInfo?.buttonText;
  const buttonPage = masterPoolData?.joinModalInfo?.buttonPage;

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  };

  return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="response-modal-title"
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
          <Box className="modal-container" sx={{ 
              height: 'auto', 
              maxHeight: '350px', 
              maxWidth: '350px', 
              bgcolor: 'background.paper', 
              p: 4 
          }}>
              <Typography variant="h6" component="div" sx={{ 
                  whiteSpace: 'pre-line',
                  fontSize: '16px',
                  color: '#002129',
                  marginBottom: 2,
                  fontWeight: '700'
              }}>
                {formattedUsernames} {usernames?.length === 1 ? 'has' : 'have'} been added to {gameName}!
              </Typography>
              {extraPoolData && (
                <Typography variant="body1" sx={{ marginBottom: 3, whiteSpace: 'pre-line' }}>
                  Note: {myUsername} has also been added to {extraPoolData.name}!
                </Typography>
              )}
              <Box className="button-container">
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onClose();
                        window.location.href = `/${gameAddress}?mId=${masterPoolId}&gId=${gameId}&page=home`;
                      }}
                      sx={{
                        whiteSpace: 'nowrap',
                        padding: '8px 16px',
                        height: '36px',
                        color: 'white',
                        backgroundColor: '#002129',
                        '&:hover': {
                            backgroundColor: '#002129',
                        }
                      }}
                  >
                    POOL HOME
                  </Button>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onClose();
                        window.location.href = `/${gameAddress}?mId=${masterPoolId}&gId=${gameId}&page=${buttonPage}`;
                      }}
                      sx={{
                          whiteSpace: 'nowrap',
                          padding: '8px 16px',
                          color: 'white',
                          backgroundColor: '#002129',
                          '&:hover': {
                              backgroundColor: '#002129',
                          },
                          height: '36px',
                      }}
                  >
                    {buttonText}
                  </Button>
              </Box>
          </Box>
      </Modal>
  );
};

export default JoinSuccessModal;