import React from "react";
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { useTiersGameContext } from "./context/TiersGameContext";
import JoinModal from "../GameModals/JoinGameModal/JoinModal";
import GameHeaderTemplate from "../PoolHeaderBar/GameHeaderTemplate";
import UserDash from "../UserDash/UserDash";
import EntryDropdown from "../GamePageComponents/Dropdowns/EntryDropdown";
import ConnectionError from "../ConnectionErrorPage/ConnectionError";
import GameNavBar from "../NavigationBars/GameNavigationBar/GameNavBar";
import GameHomePageVersionTwo from "../GameHomePage/GameHomePageVersionTwo";
import GameRulesPage from "../GameRulesPage/GameRulesPage";
import MembersPageContainerNew from "../MembersPage/MembersPageContainerNew";
import SponsorTab from "../SponsorTab/SponsorTab";
import ReferralsLeaderboard from "../ReferralsLeaderboard/ReferralsLeaderboardNew";
import PoolPartyPopUpModal from "../GameModals/PoolPartyPopUp/PoolPartyPopUpModal";
import TiersMyPicks from "./MyPicks/TiersMyPicks";
import TiersLeaderboard from "./TiersLeaderboard/TiersLeaderboard";


const TiersGamesContainer = () => {
    const {
        page,
        gameData,
        isUserInThisPool,
        usersIndexesInThisPool,
        connectionError,
        joinOpen,
        setJoinOpen,
        popUpModalInfo,
        poolPartyPopUp,
        setPoolPartyPopUp,
    } = useUserAndGameContext();


    return (
        <>
            <GameHeaderTemplate />

            <GameNavBar />

            {isUserInThisPool && (
                <UserDash />
            )}

            {(usersIndexesInThisPool?.length > 1 && page === 'my-picks') && (
                <EntryDropdown />
            )}

            {page === 'home' && (
                <GameHomePageVersionTwo />
            )}

            {page === 'my-picks' && (
                <TiersMyPicks />
            )}

            {page === 'leaderboard' && (
                <TiersLeaderboard />
            )}

            {page === 'rules' && (
                <GameRulesPage />
            )}

            {page === 'members' && (
                <MembersPageContainerNew />
            )}

            {page === 'sponsor' && (
                <SponsorTab gameData={gameData} />
            )}

            {page === 'referrals' && (
                <ReferralsLeaderboard />
            )}

            {poolPartyPopUp && (
                <PoolPartyPopUpModal 
                    modalOpen={poolPartyPopUp}
                    setModalOpen={setPoolPartyPopUp}
                    modalInfo={popUpModalInfo}
                />
            )}

            {connectionError && (
                <ConnectionError />
            )}

            {joinOpen && (
                <JoinModal 
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                />
            )}
        </>
    );
};

export default TiersGamesContainer;