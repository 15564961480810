import React from "react";
import { Typography, Box, Button } from '@mui/material';
import { useUserAndGameContext } from "../../contexts/UserAndGameContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTime } from "luxon";
import CountdownClock from "./GameHomePageComponents/CountdownClock";
import ReferralsSection from "./GameHomePageComponents/ReferralsSection";
import TextMessageReminder from "./GameHomePageComponents/TextMessageReminder";
import CreateAPool from "./GameHomePageComponents/CreateAPool";
import PoolPreviewVideo from "./GameHomePageComponents/PoolPreviewVideoNew";
import PoolScreenshots from "./GameHomePageComponents/PoolScreenshotsNew";
import HowToPlay from "./GameHomePageComponents/HowToPlayNew";
import PoolPrizes from "./GameHomePageComponents/PoolPrizesNew";
import HostNote from "./GameHomePageComponents/HostNote";
import PageComponentsContainer from "../PageComponents/PageComonentsContainer";
import useJoinGameClick from "../../hooks/general/useJoinGameClick";


const GameHomePageVersionTwo = () => {
    const {
        gameData,
        masterPoolData,
        setJoinOpen,
        gameAddress,
        myUserId,
        isUserInThisPool,
        maxEntriesThisPool,
        howManyEntriesInThisPool,
        gameId,
        masterPoolId,
        hasJoinDeadlinePassed,
        firstGameTime,
        revealTime,
        intervalInfo,
        userOptedInToTexts,
        isThisPoolPartyGame,
    } = useUserAndGameContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const gameName = gameData?.gameName;

    const joinGameClick = useJoinGameClick();

    const gameAdministration = gameData?.gameAdministration;
    const isUserInGameAdministration = (gameAdministration, myUserId) => {
        if (!myUserId || !gameAdministration) return false; // ✅ Return false if myUserId is missing
    
        return Object.values(gameAdministration || {}).some(array => 
            Array.isArray(array) && array.includes(myUserId)
        );
    };
    const userInGameAdministration = isUserInGameAdministration(gameAdministration, myUserId);
    
    const countdownInfo = gameData?.countdownInfo || masterPoolData?.countdownInfo;

    const timeNowInNewYork = DateTime.now().setZone("America/New_York");
    const revealTimeInNewYork = DateTime.fromISO(revealTime, { zone: "America/New_York" });
    const firstGameTimeInNewYork = DateTime.fromISO(firstGameTime, { zone: "America/New_York" });

    const revealTimePassed = timeNowInNewYork > revealTimeInNewYork;
    const firstGameTimePassed = timeNowInNewYork > firstGameTimeInNewYork;

    const countdownText = (revealTime && !revealTimePassed) ? countdownInfo?.revealText : (firstGameTime && !firstGameTimePassed) ? (isUserInThisPool ? countdownInfo?.countdownTextInGame : countdownInfo?.countdownTextNotInGame) : countdownInfo?.intervalCountdown ? countdownInfo?.intervalText : null;
    const countdownTime = (revealTime && !revealTimePassed) ? revealTimeInNewYork : (firstGameTime && !firstGameTimePassed) ? firstGameTimeInNewYork : countdownInfo?.intervalCountdown ? intervalInfo?.nextIntervalStart : null;

    const referrals = gameData?.referrals;

    const homePage = gameData?.homePage;
    const showCreatePool = homePage?.showCreatePool;

    const poolPreviewVideo = homePage?.poolPreviewVideo || masterPoolData?.poolPreviewVideo;
    const screenshots = homePage?.screenshots || masterPoolData?.screenshots;

    const prizes = gameData?.prizes;
    //console.log("Prizes: ", prizes);

    const hostNote = homePage?.hostNote;
    const amICreator = gameData?.gameAdministration?.administrators?.includes(myUserId);

    const poolMessage = homePage?.poolMessage;

    const showJoinButton = (!isUserInThisPool || (isUserInThisPool && howManyEntriesInThisPool < maxEntriesThisPool)) && !hasJoinDeadlinePassed;
    //console.log("Show Join Button: ", showJoinButton, "Is User In This Pool: ", isUserInThisPool, "How Many Entries In This Pool: ", howManyEntriesInThisPool, "Max Entries This Pool: ", maxEntriesThisPool, "Has Join Deadline Passed: ", hasJoinDeadlinePassed);

   // Helper function to evaluate visibility conditions
const shouldShow = (hideConditions) => {
    //console.log("Hide Conditions: ", hideConditions);
    if (!hideConditions || hideConditions.length === 0) {
      return true; // No conditions means always show
    }
    
    // Return false if ANY condition is true (meaning we should hide)
    return !hideConditions.some(condition => {
      switch (condition) {
        case "deadlinePassed":
            //console.log("Has Join Deadline Passed: ", hasJoinDeadlinePassed);
          return hasJoinDeadlinePassed; // Boolean state in your component
        case "inPool":
            //console.log("Is User In This Pool: ", isUserInThisPool);
          return isUserInThisPool; // Boolean indicating if user is in the pool
        case "notInPool":
            //console.log("Is User In This Pool: ", isUserInThisPool);
          return !isUserInThisPool; // Boolean indicating if user is NOT in the pool
        // Add more cases as needed
        default:
          console.warn(`Unknown hide condition: ${condition}`);
          return false;
      }
    });
  };
  
  // Process the message data to filter out hidden elements
  const processPoolMessage = (data) => {
    //console.log("Data: ", data);
    if (!data || !Array.isArray(data) || data.length === 0) return null;
    
    // Keep the first item (settings) and filter the rest
    const settings = data[0];
    const filteredContent = data.slice(1).filter(item => {
      // Check if this item should be hidden based on conditions
      return shouldShow(item.hide);
    });
    
    // If we have content to show, return the processed data
    if (filteredContent.length > 0) {
      return [settings, ...filteredContent];
    }
    return null;
  };
  
  // In your component:
  const filteredMessage = processPoolMessage(poolMessage);
   
    return (
        <>      
            {countdownText && (
                <CountdownClock
                    text={countdownText}
                    targetTimeISO={countdownTime}
                    countdownTextColor={countdownInfo?.textColor}
                    countdownBackgroundColor={countdownInfo?.backgroundColor}
                />
            )}

            {filteredMessage && (
                <PageComponentsContainer content={filteredMessage} />
            )}

            {!poolMessage && !isThisPoolPartyGame && (
                <Typography
                    sx={{
                        fontSize: isMobile ? '24px' : '32px',
                        fontWeight: '700',
                        color: '#002129',
                        textAlign: 'center',
                        padding: '16px 0',
                    }}
                >
                    {gameName}
                </Typography>
            )}

            {hostNote && (
                <HostNote
                    hostNote={hostNote}
                    amICreator={amICreator}
                    myUserId={myUserId}
                    gameId={gameId}
                />
            )}

            {showJoinButton && (
                <Box
                    sx={{
                        backgroundColor: poolMessage ? poolMessage[0]?.backgroundColor || '#fff' : '#fff',
                        paddingBottom: '16px',
                        paddingTop: (!hostNote && !poolMessage) ? '16px' : '0px',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#00AAD1',
                            '&:hover': {
                                backgroundColor: '#00AAD1' // Prevent hover changes
                            }
                        }}
                        onClick={() => joinGameClick()}
                    >
                        {!isUserInThisPool ? 'JOIN POOL' : 'ADD ENTRY'}
                    </Button>
                </Box>
            )}

            {/* {isUserInThisPool && !userOptedInToTexts && (
                <TextMessageReminder />
            )} */}

            {referrals && referrals?.referralLink && (
                <ReferralsSection
                    referrals={referrals}
                    userInGameAdministration={userInGameAdministration}
                />
            )}

            {showCreatePool && !hasJoinDeadlinePassed && (
                <CreateAPool />
            )}

            {prizes && (
                <PoolPrizes
                    prizes={prizes}
                    backgroundColor={prizes?.backgroundColor}
                    textColor={prizes?.textColor}
                />
            )}

            <HowToPlay
                isUserInThisPool={isUserInThisPool}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                setJoinOpen={setJoinOpen}
                masterPoolId={masterPoolId}
                gameId={gameId}
                gameAddress={gameAddress}
                howToPlayInfo={homePage?.howToPlayInfo}
                backgroundColor={null}
                textColor={null}
            />

            {poolPreviewVideo && (
                <PoolPreviewVideo
                    poolPreviewVideo={poolPreviewVideo}
                    backgroundColor={null}
                    textColor={null}
                />
            )}

            {screenshots && (
                <PoolScreenshots
                    screenshots={screenshots}
                    backgroundColor={null}
                    textColor={null}
                />
            )}
        </>
    );
};

export default GameHomePageVersionTwo;