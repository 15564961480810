import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import BirdiePoolProvider from '../context/BirdiePoolContext';
import { useBirdieContext } from '../context/BirdiePoolContext';
import { Button } from '@mui/material';

const PlusClickModal = () => {
  const { birdiePoolData, scorecardState, setScorecardState, selectedHole, setSelectedHole, modalState, setModalState, startingScorecard, handleScoreCardSubmission, editHole, setEditHole, editDate, setEditDate, editIndex, setEditIndex, setConfirmDate } = useBirdieContext();
  const hole = selectedHole;

    const scorecardBackup = scorecardState;

    //console.log("USER SCORECARD IN ADD MODAL", scorecardState);
    const userHole = scorecardState?.find(h => h.hole === hole);
    //console.log("USER HOLE", userHole, hole,);




    // USE LUXON DATES HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const today = new Date()
    const [pushDate, setPushDate] = useState(today);

    //console.log('Today:', today);
    const startDate = new Date(birdiePoolData.startDate)
    const endDate = new Date(birdiePoolData.endDate)
    let maxDate = today;
    if (maxDate > endDate) {
        maxDate = endDate;
    }

    const onChange = (date) => {
        //console.log("Date Change:", date);
        setPushDate(date);

    }
  const onSubmit = (e) => {
    e.preventDefault();
    // Find the index of the hole in scorecardState
    const holeIndex = scorecardState.findIndex(h => h.hole === hole);
    //console.log("HoleIndex:",holeIndex, "Push Date:", pushDate);
    const formattedDate = pushDate?.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
    //console.log("Formatted Date:", formattedDate);
    // If the hole exists, update its birdieDates
    if (holeIndex !== -1) {
        const updatedUserScorecard = [...scorecardState]; // Make a copy of userScorecard
        const hasHoleBeenBirdiedToday = updatedUserScorecard[holeIndex].birdieDates.includes(formattedDate);
        //console.log("Has Hole Been Birdied Today:", hasHoleBeenBirdiedToday);
        updatedUserScorecard[holeIndex].birdieDates.push(formattedDate); // Push pushDate into birdieDates
       //console.log("UpdatedScorecard:",updatedUserScorecard);

       setScorecardState(updatedUserScorecard);
       //Enter Save Logic Here
       if (hasHoleBeenBirdiedToday) {
        //console.log("Hole has already been birdied today");
        setModalState("confirmDuplicate");
        setConfirmDate(formattedDate);
        return;
    }
       // Refresh the page so birdie(s) update
    }

    //console.log('Selected date:', pushDate);
    //console.log('Max date:', maxDate);
    // setModalState(null);
    handleScoreCardSubmission();
    // window.location.href = window.location.href;
  };

  const onCancel = () => {
    //console.log('Cancel clicked');
    setModalState(null);
  };

  return (
      <div className="modal-overlay">
        <div className="modal-calendar">
          <h2 style={{ marginTop: '-10px' }}>Add Birdie</h2>
          <h2>Hole {hole}</h2>
          <DatePicker
            selected={pushDate}
            onChange={onChange}
            inline
            minDate={startDate} // May 1, 2024
            maxDate={maxDate} // October 31, 2024
          />
           <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
              <Button
                variant='contained'
                sx={{
                  height: '35px',
                  bgcolor: 'white',
                  color: '#002129',
                  border: '1px solid #002129',
                  '&:hover': {
                    bgcolor: 'white', // Maintain white background on hover
                    color: '#002129', // Maintain text color on hover
                    border: '1px solid #002129', // Maintain border color on hover
                  },
                }}
                onClick={onCancel}
              >
                CANCEL
              </Button>
              <Button
                variant='contained'
                className='submit-button active'
                style={{ marginLeft: '10px' }}
                onClick={(e) => onSubmit(e)}
              >
                SUBMIT
              </Button>
           </div>
        </div>
      </div>
  );
};

export default PlusClickModal;
