import React from "react";
import NFLPickEmContainer from "../components/NFLPickEm/NFLPickEmContainer";
import NFLPickEmProvider from "../components/NFLPickEm/context/NFLPickEmContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const NFLPickEmPage = () => {
  const queryClient = new QueryClient();

  return (
    <div>
		<QueryClientProvider client={queryClient}>
			<NFLPickEmProvider>
				<NFLPickEmContainer />
			</NFLPickEmProvider>
		</QueryClientProvider>
    </div>
  );
}

export default NFLPickEmPage;