import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import MyPoolsCard from './MyPoolsCard/MyPoolsCard';
import StaticPoolHeaderBar from '../PoolHeaderBar/StaticPoolHeaderBar';
import { useNFLCurrentWeek } from '../../hooks/nfl';
import { useCFBCurrentWeek } from '../../hooks/cfb';
import { useMyPoolsPageContext } from './context/MyPoolsPageContext';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';
import './MyPoolsPage.scss';

const MyPoolsPageContainer = () => {
    const { myUserId, userData, gamesData } = useMyPoolsPageContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();

    const { mutate: fetchNFLCurrentWeek, data: nflCurrentWeek, isLoading: nflLoading, isError: nflError, error: nflErrorData } = useNFLCurrentWeek();
    const { mutate: fetchCFBCurrentWeek, data: cfbCurrentWeek, isLoading: cfbLoading, isError: cfbError, error: cfbErrorData } = useCFBCurrentWeek();
  
    useEffect(() => {
        fetchNFLCurrentWeek();
        fetchCFBCurrentWeek();
    }, [fetchCFBCurrentWeek, fetchNFLCurrentWeek]);
    //console.log("NFL Current Week:", nflCurrentWeek, "CFB Current Week:", cfbCurrentWeek);

    //console.log("PrivatePartyInviteContainer", );
    //console.log("Does user have data", myUserId, userData, gamesData);

    const userGames = userData?.Games;
    //console.log("User Games:", userGames);

    const normalizeLeague = (league) => {
        if (!league) return '';
        
        // If it's stored as a string containing an array representation
        if (league.startsWith('[') && league.endsWith(']')) {
          // Remove the brackets and quotes
          return league.slice(2, -2);
        }
        
        return league;
    };

    const myPoolsCardData = [];

    for (let i = 0; i < userGames?.length; i++) {
        const game = userGames[i];
        const matchingGame = gamesData?.find(pool => pool.gameID === game.gameID);
        //console.log("Matching Game:", matchingGame);
        if (matchingGame) {
            const gameName = matchingGame?.gameName;
            const partyInfo = game?.subGroups;
            const league = normalizeLeague(matchingGame?.league);
            //console.log("League", league);
            const gameType = matchingGame?.gameType;
            const logo = matchingGame?.clubLogo;
            const gameAddress = matchingGame?.gameAddress;
            const gameId = matchingGame?.gameID;
            const headerDisplayName = matchingGame?.headerDisplayName;
            const sponsorLogo = matchingGame?.sponsorInfo?.sponsorLogo;
            const gameParameters = matchingGame?.gameParameters?.[0];
            const startingWeek = gameParameters?.startingWeek;
            const picksPerInterval = gameParameters?.picksPerInterval;
            const lastInterval = gameParameters?.lastInterval;
            const usingTiebreaker = gameParameters?.usingTiebreaker;
            //console.log("Picks Per Interval", picksPerInterval, "Last Interval", lastInterval, "Using Tiebreaker", usingTiebreaker, "League", league);
            let currentWeek = null;
            if (gameType !== "BirdiePools") {
                if (league === "NFL") {
                    currentWeek = nflCurrentWeek;
                } else if (league === "NCAA") {
                    currentWeek = cfbCurrentWeek;
                }
            }
            const userPicks = game?.pool;
            const userRewards = game?.rewards;
            const gameData = {
                gameName,
                partyInfo,
                league,
                gameType,
                logo,
                currentWeek,
                userPicks,
                gameAddress,
                gameId,
                headerDisplayName,
                sponsorLogo,
                startingWeek,
                picksPerInterval,
                lastInterval,
                usingTiebreaker,
                userRewards
            };
            
            myPoolsCardData.push(gameData);
        }
    }

    // Reverse the array to put the most recently joined games at the top
    myPoolsCardData.reverse();
    //console.log("My Pools Card Data:", myPoolsCardData);



    const hasData = myUserId !== null;

    return (
        <>
            <StaticPoolHeaderBar navigationText="Home" headerText="My Pools" linkForNavigation="/" />
    
            <Box
                sx={{
                    marginTop: '32px',
                }}
            ></Box>    
            
            {hasData ? (
                myPoolsCardData?.length > 0 ? (
                myPoolsCardData?.map((gameData, index) => (
                    <MyPoolsCard 
                        key={index}
                        gameName={gameData?.gameName}
                        partyInfo={gameData?.partyInfo}
                        league={gameData?.league}
                        gameType={gameData?.gameType}
                        logo={gameData?.logo}
                        currentWeek={gameData?.currentWeek}
                        userPicks={gameData?.userPicks}
                        gameAddress={gameData?.gameAddress}
                        gameId={gameData?.gameId}
                        headerDisplayName={gameData?.headerDisplayName}
                        sponsorLogo={gameData?.sponsorLogo}
                        startingWeek={gameData?.startingWeek}
                        picksPerInterval={gameData?.picksPerInterval}
                        lastInterval={gameData?.lastInterval}
                        usingTiebreaker={gameData?.usingTiebreaker}
                        userRewards={gameData?.userRewards}
                    />
                ))
            ) : (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '16px',
                            width: isMobile ? '311px' : '500px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            borderRadius: '8px', // Adjusted to a fixed value; replace with your CSS variable if needed
                            border: '2px solid #E8E8E8',
                            backgroundColor: '#FFF',
                            margin: '0 auto',
                            marginTop: '24px',
                        }}
                        >
                        <Typography
                            sx={{
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '18px',
                            fontWeight: '800',
                            }}
                        >
                            None
                        </Typography>
                    </Box>

                    <Button
                        variant='contained'
                        onClick = {() => navigateToTop('/pool-hall')}
                        sx={{
                            width: '137px',
                            height: '32px',
                            whiteSpace: 'nowrap',
                            fonstSize: '12px',
                            fontWeight: '700',
                            marginTop: '40px'
                        }}
                    >
                        ENTER POOL HALL
                    </Button>
                </>
            )
            ) : (
                <LoadingScreen />
            )}
            <Box
                sx={{
                    marginTop: '32px',
                }}
            ></Box>  
        </>
  );
};

export default MyPoolsPageContainer;
