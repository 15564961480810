// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referrals-leaderboard-header {
  background-color: #002129 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  cursor: pointer;
  text-decoration: underline !important;
  padding: 16px !important;
}
@media (max-width: 900px) {
  .referrals-leaderboard-header {
    font-size: 14px !important;
    padding: 8px 16px !important;
  }
}

.referrals-leaderboard-player-column {
  font-size: 14px !important;
  font-weight: 500;
}
@media (max-width: 900px) {
  .referrals-leaderboard-player-column {
    font-size: 12px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ReferralsLeaderboard/ReferralsLeaderboard.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,0BAAA;EACA,2BAAA;EACA,eAAA;EACA,qCAAA;EACA,wBAAA;AACJ;AACI;EARJ;IASQ,0BAAA;IACA,4BAAA;EAEN;AACF;;AACA;EACI,0BAAA;EACA,gBAAA;AAEJ;AAAI;EAJJ;IAKQ,0BAAA;EAGN;AACF","sourcesContent":[".referrals-leaderboard-header {\n    background-color: #002129 !important;\n    font-size: 20px !important;\n    font-weight: 700 !important;\n    cursor: pointer;\n    text-decoration: underline !important;\n    padding: 16px !important;\n\n    @media (max-width: 900px) {\n        font-size: 14px !important;\n        padding: 8px 16px !important;\n    }\n}\n\n.referrals-leaderboard-player-column {\n    font-size: 14px !important;\n    font-weight: 500;\n\n    @media (max-width: 900px) {\n        font-size: 12px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
