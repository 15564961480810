import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const Images = ({ images, title, smallButtons }) => {
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (link, e) => {
    e.preventDefault();
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  // Determine grid layout based on number of images and smallButtons
  const getGridStyles = () => {
    if (smallButtons) {
      return {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.25rem',
        justifyContent: 'center',
      };
    }

    // For regular images
    const columns = images?.length === 1 ? 1 : 
                   images?.length === 2 ? 2 :
                   images?.length === 4 ? 2 : 3;

    // Calculate container width based on image sizes for 2-column layout
    const totalWidth = images?.length === 2 
      ? `${parseInt(images[0].desktopWidth || '350') + parseInt(images[1].desktopWidth || '350') + 12}px` 
      : '100%';

    return {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : `repeat(${columns}, minmax(0, 1fr))`,
      gap: '60px',
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: totalWidth
    };
  };

  // Get container styles for individual image boxes
  const getImageContainerStyles = (index) => {
    const baseStyles = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    };

    if (smallButtons) {
      return {
        ...baseStyles,
        width: '175px',
        margin: '0.25rem',
      };
    }

    // For 2-column layout, constrain the image container width
    if (images?.length === 2 && !isMobile) {
      return {
        ...baseStyles,
        maxWidth: '350px', // or whatever max width you prefer
        margin: '0 auto',
      };
    }

    return {
      ...baseStyles,
      margin: '0 auto',
    };
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-full" style={{ maxWidth: images?.length === 2 
        ? `${parseInt(images[0].desktopWidth || '350') + parseInt(images[1].desktopWidth || '350') + 12}px` 
        : '100%' }}>
        {title && (
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            {title}
          </h2>
        )}
        
        <div style={getGridStyles()}>
          {images?.map((image, index) => (
            <div 
              key={index}
              style={getImageContainerStyles(index)}
            >
              <div className="text-center w-full">
                {/* Header Text */}
                {image?.headerText?.map((header, idx) => (
                  <div 
                    key={`header-${idx}`}
                    onMouseEnter={() => header.link && setHoveredGroup(`header-${index}-${idx}`)}
                    onMouseLeave={() => setHoveredGroup(null)}
                  >
                    {Array.isArray(header.text) 
                      ? header.text.map((line, lineIndex) => (
                          <p
                            key={`header-line-${lineIndex}`}
                            onClick={header.link ? (e) => handleClick(header.link, e) : undefined}
                            className={`
                              mb-2
                              ${header.link ? 'cursor-pointer text-blue-500' : ''}
                              ${hoveredGroup === `header-${index}-${idx}` ? 'underline' : ''}
                            `}
                            style={{
                              fontSize: header.size || '16px',
                              color: header.color || '#002129',
                              fontWeight: header.fontWeight || 500,
                            }}
                          >
                            {line}
                          </p>
                        ))
                      : (
                          <p
                            onClick={header.link ? (e) => handleClick(header.link, e) : undefined}
                            className={`
                              mb-2
                              ${header.link ? 'cursor-pointer text-blue-500' : ''}
                              ${hoveredGroup === `header-${index}-${idx}` ? 'underline' : ''}
                            `}
                            style={{
                              fontSize: header.size || '16px',
                              color: header.link ? '#00AAD1' : header.color || '#002129',
                              fontWeight: header.fontWeight || 500,
                            }}
                          >
                            {header.text}
                          </p>
                        )}
                  </div>
                ))}

                {/* Image */}
                <div className="w-full">
                  {image.link ? (
                    <a
                      href={image.link}
                      onClick={(e) => handleClick(image.link, e)}
                      className="block hover:opacity-90"
                    >
                      <img
                        src={image?.src}
                        alt={image?.alt}
                        className="w-full h-auto rounded"
                        style={{
                          maxWidth: isMobile ? (image.mobileWidth || '350px') : (image.desktopWidth || '350px'),
                          margin: '0 auto',
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      src={image?.src}
                      alt={image?.alt}
                      className="w-full h-auto rounded"
                      style={{
                        maxWidth: isMobile ? (image.mobileWidth || '350px') : (image.desktopWidth || '350px'),
                        margin: '0 auto',
                      }}
                    />
                  )}
                </div>

                {/* Footer Text */}
                {image?.footerText?.map((footer, idx) => (
                  <div 
                    key={`footer-${idx}`}
                    onMouseEnter={() => footer.link && setHoveredGroup(`footer-${index}-${idx}`)}
                    onMouseLeave={() => setHoveredGroup(null)}
                  >
                    {Array.isArray(footer.text) 
                      ? footer.text.map((line, lineIndex) => (
                          <p
                            key={`footer-line-${lineIndex}`}
                            onClick={footer.link ? (e) => handleClick(footer.link, e) : undefined}
                            className={`
                              mt-2
                              ${footer.link ? 'cursor-pointer' : ''}
                              ${hoveredGroup === `footer-${index}-${idx}` ? 'underline' : ''}
                            `}
                            style={{
                              fontSize: footer.size,
                              color: footer.color,
                              fontWeight: footer.fontWeight || 500,
                            }}
                          >
                            {line}
                          </p>
                        ))
                      : (
                          <p
                            onClick={footer.link ? (e) => handleClick(footer.link, e) : undefined}
                            className={`
                              mt-2
                              ${footer.link ? 'cursor-pointer' : ''}
                              ${hoveredGroup === `footer-${index}-${idx}` ? 'underline' : ''}
                            `}
                            style={{
                              fontSize: footer.size,
                              color: footer.color,
                              fontWeight: footer.fontWeight || 500,
                            }}
                          >
                            {footer.text}
                          </p>
                        )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Images;