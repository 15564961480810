import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import { useMarkRewardsAsRedeemed } from "../../../hooks/pools/useMarkRewardsAsRedeemed";

const QuickPickRedeemModal = ({ open, setOpen, reward, sponsorName, mainGameId, myUserId }) => {

    const { mutate, isLoading, isError, error, isSuccess } = useMarkRewardsAsRedeemed();

    const handleRedeem = async () => {
        //console.log("Redeem Hit");
        const rewardData = {
            rewardId: reward.id,
            rewardInterval: reward.interval,
            gameId: mainGameId,
            userId: myUserId,
        }

        try {
            await mutate(rewardData, {
                onSuccess: async (response) => {
                    window.location.reload();
                }, onError: async (error) => {
                    console.log("Error", error);
                }
            });
        } catch (error) {
            console.log("Error", error);
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    bgcolor: '#fff',
                    boxShadow: 24,
                    borderRadius: '4px',
                    p: 4,
                }}
            >
                <Typography 
                    sx={{
                        fontSize: '20px',
                        fontWeight: '700',
                        textAlign: 'center',
                        color: '#CC293C',
                    }}
                >
                    NOTE <br/> You must redeem with a {sponsorName} staff member!
                </Typography>
                <Typography
                    sx={{
                        fontSize: '20px',
                        fontWeight: '700',
                        textAlign: 'center',
                        color: '#002129',
                        marginTop: '16px',
                        marginBottom: '32px',
                    }}
                >
                    Are you sure you want to redeem? <br/><br/> This CANNOT be undone.
                </Typography>
                <Box 
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '16px', // Space between buttons
                    }}
                >
                    <Button 
                        onClick={() => setOpen(false)}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => handleRedeem()}
                        variant="contained"
                    >
                        Redeem
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default QuickPickRedeemModal;