import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const TeamsAvailableBox = ({ logo, result, weekPicked }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
            }}
        >
            <Box
                component="img"
                src={logo}
                alt="Team Logo"
                sx={{
                    width: isMobile ? '32px' : '48px',
                    height: isMobile ? '32px' : '48px',
                    flexShrink: 0,
                    border: result === 'Win' ? '2px solid #00AA72' : (result === 'Loss' ? '2px solid #CC293C' : '2px solid #002129'),
                    padding: '2px', // Add padding to create space for the border
                    backgroundColor: result === 'Win' ? '#CCEEE3' : result === 'Loss' ? '#F7DDE0' : result === 'Pending' ? '#E5E5E5': '#FFF',
                    boxSizing: 'border-box', // Include padding and border in the size of the box
                    objectFit: 'contain',
                }}
            />
            <Typography
                sx={{
                    alignSelf: 'stretch',
                    color: '#002129',
                    textAlign: 'center',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: isMobile ? '12px' : '16px',
                    color: result === 'Win' ? '#00AA72' : result === 'Loss' ? '#CC293C' : '#002129',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                }}
            >
                {weekPicked !== null ? isMobile ? `W${weekPicked}` : `Wk ${weekPicked}` : ''}
            </Typography>
        </Box>
    )
}

export default TeamsAvailableBox;
