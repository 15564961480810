import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useUserAndGameContext } from "../../../contexts/UserAndGameContext";
import { useTiersGameContext } from "../context/TiersGameContext";
import { Typography, Button, Box } from "@mui/material";
import TiersCard from "../TiersCard/TiersCard";
import IntervalSelectionButtons from "../../GamePageComponents/TabSelectionButtons/IntervalSelectionButtons";
import { useNavigate, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import QuickPicksSubmitBar from "../../GamePageComponents/SubmitBars/QuickPicksSubmitBar";
import { useSaveTiersPicks } from "../../../hooks/pools/useSaveTiersPicks";
import ResponseModal from "../../GameModals/ResponseModal/ResponseModal";
import TiersSubmitModal from "../../GameModals/TiersModals/TiersSubmitModal";
import { DateTime } from 'luxon';
import { shortDayDateTimFormat } from "../../../utils/DateFormatting/ShortDayDateTimeFormat";
import PickDeadline from "../../QuickPickGames/CardPieces/PickDeadline";
import QuickPickGamesComing from "../../QuickPickGames/CardPieces/QuickPickGamesComing";

const TiersMyPicks = () => {
    const {
        interval,
        pickState,
        setPickState,
        originalPicks,
        unsavedPicks,
        setUnsavedPicks,
        masterPoolId,
        myUserId,
        pickIndex,
        intervalInfo,
        masterPoolData,
    } = useUserAndGameContext();
    console.log("Pick State", pickState, "Original Picks", originalPicks);

    const {
        tiersData,
        theCurrentInterval,
        intervalOptions,
    } = useTiersGameContext();
    
    const navigate = useNavigate();
    const location = useLocation();

    const [submitBarOpen, setSubmitBarOpen] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [checkerData, setCheckerData] = useState(null);

    const { mutate: saveTiersPicks } = useSaveTiersPicks();

    const tiersComingTitle = masterPoolData?.modalInfo?.myPicks?.title;
    const tiersComingBody = masterPoolData?.modalInfo?.myPicks?.body;

    const handleCancel = useCallback(() => {
        console.log("Cancel");
        setPickState(originalPicks);
        setSubmitBarOpen(false);
    }, [originalPicks, setPickState]);

    const handleSubmit = useCallback(async () => {
        if (hasSubmitted) return;
        console.log("Submit", pickState);
        setHasSubmitted(true);
        
        const newPicks = pickState?.filter(pick => !originalPicks?.some(originalPick => isEqual(pick, originalPick)));
        console.log("New Picks", newPicks);
        const removedPicks = originalPicks?.filter(originalPick => !pickState?.some(pick => isEqual(originalPick, pick)));
        console.log("Removed Picks", removedPicks);
        
        const pickData = {
            masterPoolId,
            myUserId,
            pickIndex: Number(pickIndex),
            newPicks,
            removedPicks,
            interval: Number(interval),
        };
        console.log("Pick Data", pickData);
        
        try {
            await saveTiersPicks(pickData, {
                onSuccess: (response) => {
                    setUnsavedPicks(false);
                    setSubmitBarOpen(false);
                    setSuccessModalOpen(true);
                    console.log("Response", response);
                    
                    if (response) {
                        setCheckerData(response.checkerData.data);
                    }
                },
                onError: (error) => {
                    console.error('Error submitting picks:', error);
                    const errorMessage = error?.response?.data?.message || 'An error occurred while submitting your picks. Please try again.';
                    setErrorMessage(errorMessage);
                    setErrorModalOpen(true);
                    setSubmitBarOpen(false);
                    setUnsavedPicks(true);
                }
            });
        } catch (error) {
            console.error("Error saving picks: ", error);
            setErrorMessage("An unexpected error occurred");
            setErrorModalOpen(true);
            setSubmitBarOpen(false);
            setUnsavedPicks(true);
        }
    }, [pickState, originalPicks, masterPoolId, myUserId, pickIndex, interval, hasSubmitted, saveTiersPicks, setUnsavedPicks, setSubmitBarOpen]);
    
    // Check if interval param exists in URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const intervalParam = queryParams.get('interval');
        
        // If interval exists in context but not in URL, add it to URL
        if (interval && !intervalParam) {
            queryParams.set('interval', interval);
            navigate({
                pathname: location.pathname,
                search: queryParams.toString()
            }, { replace: true }); // Use replace to avoid adding to history stack
        }
    }, [interval, location.search, navigate, location.pathname]);

    console.log("Tiers", tiersData, "Current Interval", theCurrentInterval, "Interval in My Picks", interval);

    // Memoize filtered tiers data
    const thisIntervalsTiersData = useMemo(() => 
        tiersData?.find((tiersData) => Number(tiersData.interval) === Number(interval)),
        [tiersData, interval]
    );
    
    // Memoize picks calculation
    const picksToMakeThisInterval = useMemo(() => 
        thisIntervalsTiersData?.tiers?.reduce((acc, tier) => acc + tier.picks, 0),
        [thisIntervalsTiersData]
    );
    console.log("This Intervals Tiers Data", thisIntervalsTiersData, "Picks to Make This Interval", picksToMakeThisInterval);

    // This intervals deadline
    const thisIntervalsInfo = useMemo(() =>
        intervalInfo?.info?.find((info) => Number(info.interval) === Number(interval)),
        [intervalInfo, interval]
    );
    console.log("This Intervals Info", thisIntervalsInfo);
    const thisIntervalsDeadlineInNewYork = DateTime.fromISO(thisIntervalsInfo?.deadline, { zone: 'America/New_York' });
    const formattedDeadlineTime = shortDayDateTimFormat(thisIntervalsInfo?.deadline);
    const timeInNewYorkNow = DateTime.now().setZone('America/New_York');
    const hasDeadlinePassed = thisIntervalsDeadlineInNewYork < timeInNewYorkNow;
    console.log("This Intervals Deadline", thisIntervalsDeadlineInNewYork, "Time in New York Now", timeInNewYorkNow, "Has Deadline Passed", hasDeadlinePassed, "Formatted Deadline Time", formattedDeadlineTime);

    const handleSelection = useCallback((interval, tierNumber, tierPicks, id) => {
        // Check if this player is already selected in any tier for this interval
        const pickAlreadySelected = pickState?.some(pick => 
            pick.interval === interval && pick.id === id
        );
        
        if (pickAlreadySelected) {
            // If player is already selected, just return (prevent selecting the same player twice)
            return;
            
            // Alternative option: Remove the existing selection if uncommenting below
            // const newPickState = pickState.filter(pick => 
            //     !(pick.interval === interval && pick.id === id)
            // );
            // setPickState(newPickState);
            // return;
        }
        
        // Count how many picks already exist for this tier
        const currentPicksForTier = pickState?.filter(pick => 
            pick.interval === interval && pick.tier === tierNumber
        ).length;
        
        // Check if we've reached the maximum picks for this tier
        if (currentPicksForTier >= tierPicks) {
            // We need to remove the oldest pick for this tier
            const pickIndexToRemove = pickState?.findIndex(pick => 
                pick.interval === interval && pick.tier === tierNumber
            );
            
            if (pickIndexToRemove !== -1) {
                const newPickState = [...pickState];
                newPickState.splice(pickIndexToRemove, 1);
                setPickState([...newPickState, { interval, tier: tierNumber, id }]);
            }
        } else {
            // Add the new pick (we haven't reached the maximum yet)
            setPickState([...pickState, { interval, tier: tierNumber, id }]);
        }
    }, [pickState, setPickState]);


    // Use Effect to see if orignalPicks is different from pickState
    // If it is, setUnsavedPicks to true and setSubmitBarOpen to true
    useEffect(() => {
        if (originalPicks && pickState) {
            const picksAreEqual =
                originalPicks?.length === pickState?.length &&
                originalPicks?.every(originalPick =>
                    pickState?.some(pick => isEqual(originalPick, pick))
            );

            setUnsavedPicks(!picksAreEqual);
            setSubmitBarOpen(!picksAreEqual);
        }
    }, [originalPicks, pickState, setUnsavedPicks, setSubmitBarOpen]);

    const cardColors = useMemo(() => ({
        backgroundColor: thisIntervalsTiersData?.backgroundColor,
        textColor: thisIntervalsTiersData?.textColor
    }), [thisIntervalsTiersData]);
    
    // Memoize the tier cards rendering
    const tierCards = useMemo(() => {
        if (!thisIntervalsTiersData?.tiers?.length) return null;
        
        return thisIntervalsTiersData.tiers.map((tier, index) => (
            <TiersCard
                key={index}
                tier={tier}
                backgroundColor={cardColors?.backgroundColor}
                textColor={cardColors?.textColor}
                handleSelection={handleSelection}
                interval={interval}
                selectedPlayers={pickState}
                lastCard={index === thisIntervalsTiersData?.tiers?.length - 1}
            />
        ));
    }, [thisIntervalsTiersData, cardColors, handleSelection, interval, pickState]);


    return (
        <>
            {!hasDeadlinePassed && (
                <PickDeadline
                    gameTime={thisIntervalsDeadlineInNewYork}
                    customBackgroundColor={"#000000"}
                    customTextColor={"#fff"}
                />
            )}

            {intervalOptions && intervalOptions.length > 0 && (
                <IntervalSelectionButtons
                    selectedInterval={interval}
                    intervals={intervalOptions}
                />
            )}

            {thisIntervalsTiersData?.tiers?.length === 0 && (
                <QuickPickGamesComing
                    page={'my-picks'}
                    title={tiersComingTitle}
                    body={tiersComingBody}
                />
            )}

            {tierCards}

            {successModalOpen && (
                <TiersSubmitModal
                    open={successModalOpen}
                    handleClose={() => {
                        setSuccessModalOpen(false);
                        window.location.reload();
                    }}
                    checkerData={checkerData}
                />
            )}

            {errorModalOpen && (
                <ResponseModal
                    open={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                    status="error"
                    message={errorMessage}
                />
            )}

            {submitBarOpen && (
                <QuickPicksSubmitBar
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    usingTiebreaker={false}
                    picksThisInterval={picksToMakeThisInterval}
                    hasSubmitted={hasSubmitted}
                />
            )}
        </>
    );
};

export default TiersMyPicks;