import React, { useEffect, useMemo } from "react";
import { Typography, Button, Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

/**
 * Second page of the Join Modal that handles entry selection
 * Allows users to select existing entries or create new ones within pool limits
 */
const JoinModalPageTwo = ({
    gameName,
    setPage,
    textOptIn,
    setTextOptIn,
    startingPage,
    entriesToEnterCount,
    allUsersEntries,
    howManyEntriesInThisPool,
    howManyTotalEntries,
    usersIndexesInThisPool,
    maxMasterPoolEntries,
    maxEntriesThisPool,
    newEntries,
    setNewEntries,
    pickIndexesToEnter,
    setPickIndexesToEnter,
    isThisPoolPartyGame,
    creation,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Memoize the available entries
    const availableEntries = useMemo(() => {
        return howManyTotalEntries > 0 && isThisPoolPartyGame
            ? allUsersEntries ? [{ entry: allUsersEntries[0], originalIndex: 0 }] : []
            : allUsersEntries?.reduce((acc, entry, index) => {
                if (!usersIndexesInThisPool?.includes(index)) {
                    acc.push({ entry, originalIndex: index });
                }
                return acc;
            }, []);
    }, [
        howManyTotalEntries,
        isThisPoolPartyGame,
        allUsersEntries,
        usersIndexesInThisPool
    ]);

    // Calculate remaining new entries based on both master pool limit and this pool's limit
    const remainingNewEntries = useMemo(() => {
        const remainingInMasterPool = maxMasterPoolEntries - howManyTotalEntries;
        const remainingInThisPool = maxEntriesThisPool - howManyEntriesInThisPool;
        const potentialNewEntries = Math.min(remainingInMasterPool, remainingInThisPool);
        // We also need to consider entriesToEnterCount to not show more options than needed
        return Math.min(potentialNewEntries, entriesToEnterCount);
    }, [maxMasterPoolEntries, howManyTotalEntries, maxEntriesThisPool, howManyEntriesInThisPool, entriesToEnterCount]);

    // Generate array of new entry options based on calculated remaining entries
    const newEntryOptions = Array.from(
        { length: remainingNewEntries },
        (_, i) => `New Entry ${i + 1}`
    );

    useEffect(() => {
        //console.log("Running useEffect in JoinModalPageTwo");
        if (howManyTotalEntries > 0 && isThisPoolPartyGame) {
            // For party games, only allow selection of the first entry
            setPickIndexesToEnter([0]);
            setNewEntries(0);
        } else if (startingPage === 1) {
            // Reset selections when coming from page 1
            setPickIndexesToEnter([]);
            setNewEntries(0);
        }
    }, [
        startingPage,
        howManyTotalEntries,
        isThisPoolPartyGame,
        setPickIndexesToEnter,
        setNewEntries
    ]);

    /**
     * Handles the selection/deselection of entries
     * @param {Object} entry - The entry being selected/deselected
     * @param {number} index - The index of the entry
     * @param {boolean} isExistingEntry - Whether this is an existing entry or new entry
     */
    const handleEntrySelect = (entry, index, isExistingEntry) => {
        if (isExistingEntry) {
            // Handle existing entry selection
            if (pickIndexesToEnter?.includes(index)) {
                setPickIndexesToEnter(prev => prev.filter(i => i !== index));
            } else if (pickIndexesToEnter?.length + newEntries < entriesToEnterCount) {
                setPickIndexesToEnter(prev => [...prev, index]);
            }
        } else {
            // Handle new entry selection
            const newEntryIndex = newEntryOptions?.indexOf(entry);
            if (newEntryIndex !== -1) {
                if (entry === `New Entry ${newEntries}`) {
                    setNewEntries(prev => prev - 1);
                } else if (newEntries < entriesToEnterCount - pickIndexesToEnter?.length) {
                    setNewEntries(prev => prev + 1);
                }
            }
        }
    };

    /**
     * Determines if an entry is currently selected
     * @param {Object} entry - The entry to check
     * @param {number} index - The index of the entry
     * @param {boolean} isExistingEntry - Whether this is an existing entry or new entry
     * @returns {boolean} Whether the entry is selected
     */
    const isEntrySelected = (entry, index, isExistingEntry) => {
        if (isExistingEntry) {
            return pickIndexesToEnter?.includes(index);
        } else {
            const newEntryIndex = newEntryOptions?.indexOf(entry);
            return newEntryIndex < newEntries;
        }
    };

    return (
        <Box>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '800',
                    textAlign: 'center',
                    mb: 3
                }}
            >
                {(creation && startingPage === 2)
                    ? `Congratulations, you are now the host of ${gameName}! `
                    : (startingPage === 2 && maxEntriesThisPool === 1)
                    ? `${gameName} allows only 1 entry per user. `
                    : (startingPage === 2 && maxEntriesThisPool > 1) 
                    ? `You already have ${howManyEntriesInThisPool} ${howManyEntriesInThisPool === 1 ? 'entry' : 'entries'}. ${gameName} allows up to ${maxEntriesThisPool} ${maxEntriesThisPool === 1 ? 'entry' : 'entries'}. `
                    : ''}
                {entriesToEnterCount === 1
                    ? "Select the entry you would like to enter."
                    : `Select the ${entriesToEnterCount} entries you would like to enter.`}
            </Typography>

            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 3,
                    padding: isMobile ? '0px' : '0px 32px' 
                }}
            >
                {/* Existing entries */}
                {availableEntries?.map(({ entry, originalIndex }) => (
                    <FormControlLabel
                        key={`existing-${originalIndex}`}
                        control={
                            <Checkbox
                                checked={isEntrySelected(entry, originalIndex, true)}
                                onChange={() => handleEntrySelect(entry, originalIndex, true)}
                                disabled={
                                    !isEntrySelected(entry, originalIndex, true) &&
                                    pickIndexesToEnter.length + newEntries >= entriesToEnterCount
                                }
                            />
                        }
                        label={entry.name || `Entry ${originalIndex + 1}`}
                    />
                ))}
                
                {/* New entries */}
                {newEntryOptions?.map((entry, index) => (
                    <FormControlLabel
                        key={`new-${index}`}
                        control={
                            <Checkbox
                                checked={isEntrySelected(entry, index, false)}
                                onChange={() => handleEntrySelect(entry, index, false)}
                                disabled={
                                    !isEntrySelected(entry, index, false) &&
                                    pickIndexesToEnter.length + newEntries >= entriesToEnterCount
                                }
                            />
                        }
                        label={entry}
                    />
                ))}
            </Box>

            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    gap: 3, 
                    mt: 2 
                }}
            >
                {startingPage !== 2 && (
                    <Button
                        variant="contained"
                        onClick={() => setPage(startingPage === 2 ? 3 : 1)}
                        sx={{
                            width: '100px',
                            height: '36px',
                            backgroundColor: '#002129',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#002129',
                            },
                        }}
                    >
                        BACK
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={() => setPage(3)}
                    disabled={pickIndexesToEnter.length + newEntries !== entriesToEnterCount}
                    sx={{
                        width: '100px',
                        height: '36px',
                        backgroundColor: '#002129',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#002129',
                        },
                    }}
                >
                    NEXT
                </Button>
            </Box>
        </Box>
    );
};

export default JoinModalPageTwo;