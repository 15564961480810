import React, { useState, useEffect } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";

const GetPropIds = ({ selectedWeek, selectedSeason }) => {
    const { quickPickNFL, leagueState } = useAdminDashContext();

    const [selectedGame, setSelectedGame] = useState(null);
    const [propIds, setPropIds] = useState([]);
    console.log("Prop IDs", propIds);

    const thisWeeksGames = quickPickNFL?.filter((game) => game.Week === selectedWeek) || [];
    console.log("This Week's Games", thisWeeksGames);

    useEffect(() => {
        console.log("Resetting Selected Game");
        setSelectedGame(null);
    }, [selectedWeek]);

    const handleGameSelect = (event) => {
        const gameId = event.target.value;
        const game = thisWeeksGames?.find((g) => g.GlobalGameID.toString() === gameId);
        console.log("Event", event.target.value);
        console.log("Selected Game", game);
        setSelectedGame(game || null);
    };

    const formatGameDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    const handleCheckboxChange = (id) => {
        setPropIds((prevPropIds) => 
            prevPropIds.includes(id)
                ? prevPropIds.filter((propId) => propId !== id)
                : [...prevPropIds, id]
        );
    };

    const handleSubmit = () => {
        const propInfo ={
            interval: selectedWeek,
            propIdsToUse: propIds,
        }

        console.log("Prop Info", propInfo);
    }

    return (
        <div>
            {/* Game Selection and Details */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label htmlFor="game-select" style={{ marginRight: '10px' }}>
                    Game:
                </label>
                <select 
                    id="game-select" 
                    value={selectedGame ? selectedGame.GlobalGameID : ""}
                    onChange={handleGameSelect}
                    style={{ padding: '5px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        {thisWeeksGames?.length === 0 ? "No Games" : "Select Game"}
                    </option>
                    {thisWeeksGames.map((game) => (
                        <option key={game?.GlobalGameID} value={game?.GlobalGameID}>
                            {game?.AwayTeam} at {game?.HomeTeam}
                        </option>
                    ))}
                </select>
            </div>

            {/* Render Props if a Game is Selected */}
            {selectedGame && (
                <div style={{ marginBottom: '10px', maxWidth: '800px', margin: '0 auto' }}>
                    <div>{formatGameDate(selectedGame?.Day)}</div>
                    <div>{selectedGame?.AwayTeam} at {selectedGame?.HomeTeam}</div>

                    {propIds?.length > 0 && (
                        <div style={{ marginBottom: '10px', maxWidth: '800px', margin: '0 auto' }}>
                            <h3 style={{ fontWeight: '700'}}>Selected Props: {propIds?.length}</h3>
                        </div>
                    )}

                    {/* Game Props */}
                    {['gameProps', 'homeProps', 'awayProps', 'tiebreakers'].map((propType) => (
                        selectedGame.props[propType]?.length > 0 && (
                            <div key={propType} style={{ marginTop: '20px' }}>
                                <h3 style={{ fontWeight: '700' }}>
                                    {propType === 'gameProps' ? 'Game Props' : propType === 'homeProps' ? 'Home Props' : propType === 'awayProps' ? 'Away Props' : 'Tiebreakers'}
                                </h3>
                                {selectedGame.props[propType].map((prop) => (
                                    <div key={prop.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}>
                                        <input
                                            type="checkbox"
                                            checked={propIds.includes(prop.id)}
                                            onChange={() => handleCheckboxChange(prop.id)}
                                            style={{ marginRight: '10px' }}
                                        />
                                        {propIds.includes(prop.id) && (
                                            <span style={{ marginRight: '10px', color: '#888', whiteSpace: 'nowrap' }}>
                                                # {propIds.indexOf(prop.id) + 1}
                                            </span>
                                        )}
                                        <span>{prop.propText}</span>
                                    </div>
                                ))}
                            </div>
                        )
                    ))}

                </div>
            )}

            {/* Clear and Submit Buttons */}
            {propIds?.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <button 
                        style={{ 
                            padding: '10px 10px', 
                            border: '2px solid #002129',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            color: '#002129',
                            cursor: 'pointer',
                            marginRight: '10px'
                        }}
                        onClick={() => setPropIds([])}
                        disabled={propIds?.length === 0}
                    >
                        Clear
                    </button>
                    <button 
                        style={{ 
                            padding: '10px 10px', 
                            border: '2px solid #002129',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            color: '#002129',
                            cursor: 'pointer'
                        }}
                        disabled={propIds?.length === 0}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
}

export default GetPropIds;
