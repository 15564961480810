import React, { useState, useEffect } from 'react';
import { useUserAndGameContext } from '../../../contexts/UserAndGameContext';
import { Box, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useJoinGame } from '../../../hooks/general/useJoinGame';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from '../../../redux/actions/loginImport';
import ResponseModal from '../../GameModals/ResponseModal/ResponseModal';
import JoinSuccessModal from './JoinSuccessModal';
import JoinModalPageOne from './JoinModalPages/JoinModalPageOne';
import JoinModalPageTwo from './JoinModalPages/JoinModalPageTwo';
import JoinModalPageThree from './JoinModalPages/JoinModalPageThree';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import CloseIcon from '@mui/icons-material/Close';
import TrackingModal from './JoinModalPages/TrackingModal';
import AffiliatedModal from './JoinModalPages/AffiliatedModal';

const JoinModal = ({ joinOpen, setJoinOpen }) => {
    const {
        masterPoolData,
        gameData,
        myUserId,
        isUserInMasterPool,
        gameName,
        maxMasterPoolEntries,
        maxEntriesThisPool,
        howManyTotalEntries,
        howManyEntriesInThisPool,
        isThisPoolPartyGame,
        isUserInThisPool,
        isUserInPoolPartyPool,
        allUsersEntries,
        usersIndexesInThisPool,
        poolPartyGameId,
        poolPartyGameName,
        password,
        masterPoolId,
        gameId,
        myUsername,
        gameAddress,
        creation,
    } = useUserAndGameContext();
    //console.log("Game Name in JoinModal: ", gameName, "usersIndexesInThisPool: ", usersIndexesInThisPool);
    //console.log("Max Entries This Pool: ", maxEntriesThisPool, "How Many Entries In This Pool: ", howManyEntriesInThisPool);

    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState({
        open: false,
        message: ''
    });
    const [successModal, setSuccessModal] = useState(false);
    const [textOptIn, setTextOptIn] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [page, setPage] = useState(null);
    const [startingPage, setStartingPage] = useState(null);
    const [entriesToEnterCount, setEntriesToEnterCount] = useState(1);
    const [newEntries, setNewEntries] = useState(0);
    const [pickIndexesToEnter, setPickIndexesToEnter] = useState([]);
    const [addMainPoolEntry, setAddMainPoolEntry] = useState(null);
    const [joinEmailList, setJoinEmailList] = useState(true);
    const [pin, setPin] = useState(new Array(4).fill(''));
    const [successModalData, setSuccessModalData] = useState(null);
    const [trackingInfoModalOpen, setTrackingInfoModalOpen] = useState(false);
    const [affiliateInfoModalOpen, setAffiliateInfoModalOpen] = useState(false);

    const [referralId, setReferralId] = useState(null);
    const [newSignUp, setNewSignUp] = useState(null);
    //console.log("Entries to Enter Count", entriesToEnterCount, "New Entries: ", newEntries, "Pick Indexes To Enter: ", pickIndexesToEnter, "Add Main Pool Entry: ", addMainPoolEntry);
    //console.log("Text Opt In: ", textOptIn);
    // Use Effect to Reset New Entries and Pick Indexes to Enter if Entries to Enter Count Changes
    useEffect(() => {
        setNewEntries(0);
        setPickIndexesToEnter([]);
    }, [entriesToEnterCount]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { mutate: joinGame } = useJoinGame();

    //Use Effect to set pin as password, if user is in this pool already
    useEffect(() => {
        if (isUserInThisPool && password) {
            setPin(password.split(''));
        }
    }, [isUserInThisPool, password]);

    // Use effect to look for referralId and newSignUp in URL
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const referralIdParam = searchParams.get('referralId');
        const newSignUpParam = searchParams.get('signUp');
        
        if (referralIdParam) {
            setReferralId(referralIdParam);
            //console.log("Referral ID from URL:", referralIdParam);
        }
        
        if (newSignUpParam) {
            setNewSignUp(newSignUpParam === 'true');
            //console.log("New Sign Up from URL:", newSignUpParam);
        }
    }, []);

    const forceMainGameEntry = masterPoolData?.forceMainGameEntry;
    const mainGameJoinLogo = masterPoolData?.joinModalInfo?.joinModalLogo;
    const mainGameName = masterPoolData?.poolPartyGame?.gameName;
    const sharingInfo = masterPoolData?.poolPartyGame?.sharingInfo;
    const mainGameMyPoolsLogo = masterPoolData?.poolPartyGame?.myPoolsLogo;
    const sponsor = masterPoolData?.poolPartyGame?.sponsor;
    const gameJoinLogo = gameData?.joinModalLogo;
    const trackingInfo = gameData?.trackingInfo;
    const affiliateInfo = gameData?.affiliateInfo;
    const joinModalText = gameData?.joinModalText;
    const pageOneExtraText = joinModalText?.pageOneExtraText;
    //console.log.log("Page One Extra Text: ", pageOneExtraText);
    //console.log("Tracking Info: ", trackingInfo);
    //Use Effect to set Add Main Pool Entry, if !isUserInMasterPool
    useEffect(() => {
        if (!isUserInMasterPool && !isThisPoolPartyGame) {
            setAddMainPoolEntry(true);
        } else {
            setAddMainPoolEntry(false);
        }
    }, [isUserInMasterPool, isThisPoolPartyGame]);


    useEffect(() => {
        // console.log("MODAL LOGIC - Current State:", { 
        //     maxEntriesThisPool, 
        //     howManyEntriesInThisPool,
        //     howManyTotalEntries,
        //     maxMasterPoolEntries,
        //     availableSlotsInThisPool: maxEntriesThisPool - howManyEntriesInThisPool,
        //     availableTotalEntries: maxMasterPoolEntries - howManyTotalEntries
        // });

        // First, check if the user has already reached the max number of entries
        if (maxEntriesThisPool === howManyEntriesInThisPool) {
            setErrorModal({
                open: true,
                message: 'Sorry, but you have already entered the maximum number of entries for this pool'
            });
            return; // Exit early to prevent further execution
        }

        // Calculate available slots in this pool and available total entries
        const availableSlotsInThisPool = maxEntriesThisPool - howManyEntriesInThisPool;
        const availableTotalEntries = maxMasterPoolEntries - howManyTotalEntries;
        
        // Check if the user has any existing entries that are NOT in this pool
        // This is where the bug was - it was calculating incorrectly whether there were
        // entries that could be added to this pool vs needing to create new ones
        const entriesNotInThisPool = howManyTotalEntries - howManyEntriesInThisPool;
        
        // console.log("MODAL LOGIC - Analysis:", {
        //     entriesNotInThisPool,
        //     availableSlotsInThisPool,
        //     availableTotalEntries
        // });

        // FIXED LOGIC:
        // If the user has NO entries outside this pool BUT has entries overall,
        // AND they have room for more entries in both this pool and overall
        if (entriesNotInThisPool === 0 && howManyTotalEntries > 0 && 
            availableSlotsInThisPool > 0 && availableTotalEntries > 0) {
            
            // console.log("MODAL LOGIC: All entries are already in this pool, must create new entries");
            
            // Calculate how many new entries to create - minimum of available slots and total available
            const newEntriesToCreate = Math.min(availableSlotsInThisPool, availableTotalEntries);
            
            // Don't automatically select 2 entries - default to 1
            const entriesToAdd = 1;
            
            setEntriesToEnterCount(entriesToAdd);
            setNewEntries(entriesToAdd);
            
            // Start at page 1 so user can select how many entries to add
            setPage(1);
            setStartingPage(1);
        }
        // If max entries for this pool is 1 
        else if (maxEntriesThisPool === 1) {
            if (howManyTotalEntries > 0 && howManyEntriesInThisPool === 0) {
                // console.log("MODAL LOGIC: Max entries for this pool is 1, user has entries elsewhere");
                setPage(2);
                setStartingPage(2);
            } else if (howManyTotalEntries === 0) {
                // console.log("MODAL LOGIC: Max entries for this pool is 1, user has no entries");
                setPage(3);
                setStartingPage(3);
            }
        }
        // If user has only one slot left in this pool
        else if (availableSlotsInThisPool === 1) {
            // console.log("MODAL LOGIC: User has one slot left in this pool");
            setPage(2);
            setStartingPage(2);
        }
        // If user has more than one slot left in this pool
        else if (availableSlotsInThisPool > 1) {
            // console.log("MODAL LOGIC: User has multiple slots left in this pool");
            setPage(1);
            setStartingPage(1);
        }
    }, [maxEntriesThisPool, howManyEntriesInThisPool, howManyTotalEntries, maxMasterPoolEntries]);
    

    const handleJoin = async () => {
        setHasSubmitted(true);
        const data = {
            gameId,
            masterPoolId,
            userId: myUserId,
            textOptIn,
            entriesToEnterCount,
            newEntries,
            pickIndexesToEnter,
        };

        if (joinEmailList && !isUserInMasterPool) {
            data.joinEmailList = true;
        }

        if (addMainPoolEntry) {
            data.extraPoolData = {
                gameId: poolPartyGameId,
                index: 0,
                name: poolPartyGameName,
                image: mainGameMyPoolsLogo,
            };
        }

        if (password) {
            data.pinCode = pin.join('');
        }

        // Add referralId and newSignUp to data if they exist
        if (referralId) {
            data.referralId = referralId;
        }

        if (newSignUp !== null) {
            data.newSignUp = newSignUp;
        }
        //console.log('Joining game with data:', data);

        // Clean URL by removing 'invited' or 'created' parameters
        const url = new URL(window.location.href);
        const params = url.searchParams;
        
        if (params.has('invited') || params.has('creation')) {
            params.delete('invited');
            params.delete('creation');
            
            // Update the URL without refreshing the page
            window.history.replaceState({}, '', url.toString());
        }

        joinGame(data, {
            onSuccess: (data) => {
                if (trackingInfo && !isUserInThisPool) {
                    setTrackingInfoModalOpen(true);
                    setSuccessModalData(data);
                } else if (affiliateInfo && !isUserInThisPool) {
                    setAffiliateInfoModalOpen(true);
                    setSuccessModalData(data);
                } else {
                    //console.log('Joined game successfully:', data);
                    setSuccessModal(true);
                    setSuccessModalData(data);
                }
            },
            onError: (error) => {
                if (error.response?.status === 401) {
                    dispatch(userLogoutAction({
                        logoutType: 'token-expired'
                    }));
                } else {
                    setErrorModal({
                        open: true,
                        message: error.response?.data?.error || 'An error occurred'
                    });
                }
            }
        });
    }

    return (
        <>
            <Modal
                open={joinOpen && !successModal && !errorModal.open}
                onClose={() => setJoinOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        boxShadow: 24,
                        p: 4,
                        width: isMobile ? '90%' : '500px',
                        maxWidth: '500px',
                    }}
                >
                    <Button
                        onClick={() => setJoinOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            minWidth: 'auto',
                            p: '0px 8px 8px 8px',
                        }}
                        disabled={hasSubmitted}
                    >
                        <CloseIcon sx={{ color: '#CC293C'}} />
                    </Button>
                    {(page === null || startingPage === null) && <LoadingScreen />}
                    {page === 1 && (
                        <JoinModalPageOne
                            setPage={setPage}
                            isUserInMasterPool={isUserInMasterPool}
                            gameName={gameName}
                            maxEntriesThisPool={maxEntriesThisPool}
                            entriesToEnterCount={entriesToEnterCount}
                            setEntriesToEnterCount={setEntriesToEnterCount}
                            howManyTotalEntries={howManyTotalEntries}
                            setNewEntries={setNewEntries}
                            howManyEntriesInThisPool={howManyEntriesInThisPool}
                            creation={creation}
                            pageOneExtraText={pageOneExtraText}
                        />
                    )}
                    {page === 2 && (
                        <JoinModalPageTwo
                            gameName={gameName}
                            textOptIn={textOptIn}
                            setPage={setPage}
                            setTextOptIn={setTextOptIn}
                            startingPage={startingPage}
                            entriesToEnterCount={entriesToEnterCount}
                            allUsersEntries={allUsersEntries}
                            usersIndexesInThisPool={usersIndexesInThisPool}
                            maxMasterPoolEntries={maxMasterPoolEntries}
                            maxEntriesThisPool={maxEntriesThisPool}
                            howManyEntriesInThisPool={howManyEntriesInThisPool}
                            howManyTotalEntries={howManyTotalEntries}
                            newEntries={newEntries}
                            setNewEntries={setNewEntries}
                            pickIndexesToEnter={pickIndexesToEnter}
                            setPickIndexesToEnter={setPickIndexesToEnter}
                            isThisPoolPartyGame={isThisPoolPartyGame}
                            creation={creation}
                        />
                    )}
                    {page === 3 && (
                        <JoinModalPageThree
                            setPage={setPage}
                            handleJoin={handleJoin}
                            textOptIn={textOptIn}
                            setTextOptIn={setTextOptIn}
                            hasSubmitted={hasSubmitted}
                            isUserInMasterPool={isUserInMasterPool}
                            startingPage={startingPage}
                            entriesToEnterCount={entriesToEnterCount}
                            howManyTotalEntries={howManyTotalEntries}
                            newEntries={newEntries}
                            pickIndexesToEnter={pickIndexesToEnter}
                            setAddMainPoolEntry={setAddMainPoolEntry}
                            addMainPoolEntry={addMainPoolEntry}
                            isThisPoolPartyGame={isThisPoolPartyGame}
                            password={password}
                            pin={pin}
                            setPin={setPin}
                            forceMainGameEntry={forceMainGameEntry}
                            gameName={gameName}
                            mainGameJoinLogo={mainGameJoinLogo}
                            gameJoinLogo={gameJoinLogo}
                            mainGameName={mainGameName}
                            sharingInfo={sharingInfo}
                            sponsor={sponsor}
                            joinEmailList={joinEmailList}
                            setJoinEmailList={setJoinEmailList}
                            masterPoolData={masterPoolData}
                            isUserInThisPool={isUserInThisPool}
                            creation={creation}
                        />
                    )}
                    
                </Box>
            </Modal>

            <AffiliatedModal
                open={affiliateInfoModalOpen}
                onClose={() => {
                    setAffiliateInfoModalOpen(false);
                    setSuccessModal(true);
                }}
                affiliateInfo={affiliateInfo}
                myUserId={myUserId}
                gameId={gameId}
            />

            <TrackingModal
                open={trackingInfoModalOpen}
                onClose={() => {
                    setTrackingInfoModalOpen(false);
                    setSuccessModal(true);
                }}
                trackingInfo={trackingInfo}
                logo={gameJoinLogo || mainGameJoinLogo}
                myUserId={myUserId}
                gameId={gameId}
            />


            <JoinSuccessModal
                open={successModal}
                onClose={() => {
                    setSuccessModal(false);
                    setJoinOpen(false);
                }}
                successModalData={successModalData}
                myUsername={myUsername}
                masterPoolId={masterPoolId}
                gameId={gameId}
                gameName={gameName}
                masterPoolData={masterPoolData}
                gameAddress={gameAddress}
            />

            <ResponseModal
                open={errorModal.open}
                onClose={() => {
                    setErrorModal({ open: false, message: '' });
                    setJoinOpen(false);
                }}
                status="error"
                message={errorModal.message}
            />
        </>
    );
}

export default JoinModal;