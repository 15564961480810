import React from "react";
import { Box, Switch, Typography } from "@mui/material";

const FilterSwitch = ({ hostName, hostFilter, setHostFilter }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
            }}
        >
            <Typography
                sx={{
                    fontWeight: '500',
                    color: '#002129',
                }}
            >
                {hostName} Filter:
            </Typography>
            <Switch 
                checked={hostFilter}
                onChange={() => setHostFilter(!hostFilter)}
            />
        </Box>
    );
};

export default FilterSwitch;