import React from 'react';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WeeklyPicksBar = ({ weeklySchedule, userPicks }) => {
    const { NFLTeams, 
        NFLStandings, 
        NFLStadiums, 
        NFLGameOddsByWeek,
        NFLScoresBySeason, 
        pickEmState, 
        setPickEmState, 
        userPicksFromDB,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
        week
     } = useNFLPickEmContext();
    //console.log("Weekly Picks Bar NFL Schedule:", NFLScoresBySeason, NFLTeams);
    //console.log("Weekly Schedule Sorted in WeeklyPicksBar:", weeklySchedule)
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function weeksPicksInfo() {
        if (!userPicks) {
            return [];
        }
        return userPicks?.map(pick => {
            const { spread, teamId, gameId, isHomeSelected, result } = pick;
            //console.log("Weeks Picks Info:", spread, teamId, gameId);
            const teamLogo = NFLTeams?.find(team => team.TeamID === teamId)?.WikipediaLogoURL;
            const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
            const isPickHome = isHomeSelected;
            let borderBackgroundColor = "#FFFFFF";
            let borderColor = "#002129";
            if (result === "Win") {
                borderColor = winBoxColor;
                borderBackgroundColor = winBoxBackgroundColor;
            } else if (result === "Push") {
                borderColor = pushBoxColor;
                borderBackgroundColor = pushBoxBackgroundColor;
            } else if (result === "Loss") {
                borderColor = lossBoxColor;
                borderBackgroundColor = lossBoxBackgroundColor;
            }
            // if (isGameOver) {
            //     const result = isPickHome ? (game?.HomeScore + spread) > game?.AwayScore ? "Win" : (game?.HomeScore + spread) === game?.AwayScore ? "Push" : "Loss" : (game?.AwayScore + spread) > game?.HomeScore ? "Win" : (game?.AwayScore + spread) === game?.HomeScore ? "Push" : "Loss";
            //     borderColor = result === "Win" ? winBoxColor : result === "Push" ? pushBoxColor : lossBoxColor;
            //     borderBackgroundColor = result === "Win" ? winBoxBackgroundColor : result === "Push" ? pushBoxBackgroundColor : lossBoxBackgroundColor;
            //     //console.log("Result!!:", result, "Border Color:", borderColor, "Border Background Color:", borderBackgroundColor);
            // }
            const opponentKey = isPickHome ? game?.AwayTeam : game?.HomeTeam;
            const leadingSymbol = isPickHome ? 'vs' : '@';
            //console.log("Team Logo:", teamLogo, "Is Pick Home:", isPickHome, "Opponent Key:", opponentKey, "Leading Symbol:", leadingSymbol);
            return { spread, teamLogo, opponentKey, leadingSymbol, borderColor, borderBackgroundColor};
        });
    }

    const weekPicks = weeksPicksInfo();
    const maxPicks = 5;
    const currentPickCount = weekPicks?.length;
    const noPickPicks = userPicksFromDB?.filter(pick => pick.teamId === "No Pick" && pick.week === week);
    //console.log("No Pick Picks:", noPickPicks);
    const missesNeeded = noPickPicks?.length;
    
    
    const placeholdersNeeded = maxPicks - currentPickCount - missesNeeded;

    return (
        <div className="weekly-picks-bar" style={{ maxWidth: '1116px', margin: '0 auto'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 32px' }}>
                {weekPicks.map((pick, index) => (
                    <div key={index} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{
                            display: 'flex',
                            width: '24px',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700' 
                                }}
                            >
                                {pick.spread === 0 ? 'Pk' : pick.spread > 0 ? `+${pick.spread}` : pick.spread}
                            </Typography>
                        </div>
                        <img 
                            src={pick.teamLogo} 
                            alt="Team Logo" 
                            style={{ 
                                width: isMobile ? '32px' : '64px', 
                                height: isMobile ? '32px' : '64px', 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px', 
                                marginBottom: isMobile ? '5px' : '20px', 
                                objectFit: 'contain', 
                                border: isMobile ? `1px solid ${pick.borderColor}` : `4px solid ${pick.borderColor}`, 
                                backgroundColor: pick.borderBackgroundColor 
                            }} 
                        />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700' 
                                }}
                            >
                                {pick.leadingSymbol} {pick.opponentKey}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{
                            display: 'flex',
                            width: '24px',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: '8px', 
                                    fontWeight: '500' 
                                }}
                            >
                                &nbsp;
                            </Typography>
                        </div>
                        <div
                            style={{ 
                                width: isMobile ? '32px' : '64px', 
                                height: isMobile ? '32px' : '64px', 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px', 
                                marginBottom: isMobile ? '5px' : '20px', 
                                objectFit: 'contain', 
                                border: isMobile ? `1px solid #000000` : `4px solid #000000`, 
                                backgroundColor: '#FFFFFF' 
                            }} 
                        />
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700' 
                                }}
                            >
                            Pick {currentPickCount + index + 1}
                            </Typography>
                        </div>
                    </div>
                ))}
                {Array.from({ length: missesNeeded }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="pick-info" style={{ textAlign: 'center' }}>
                        <div style={{
                            display: 'flex',
                            width: '24px',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: '8px', 
                                    fontWeight: '500' 
                                }}
                            >
                                &nbsp;
                            </Typography>
                        </div>
                        <div
                            style={{ 
                                width: isMobile ? '32px' : '64px', 
                                height: isMobile ? '32px' : '64px', 
                                margin: '0 auto', 
                                marginTop: isMobile ? '5px' : '20px', 
                                marginBottom: isMobile ? '5px' : '20px', 
                                border: isMobile ? `1px solid #CC293C` : `4px solid #CC293C`, 
                                backgroundColor: 'transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} 
                        >
                            <CloseIcon 
                                style={{ 
                                    width: isMobile ? '20px' : '32px', 
                                    height: isMobile ? '20px' : '32px', 
                                    fill: '#CC293C',
                                }} 
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            width: 'auto',
                            height: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            flexShrink: 0,
                            background: '#FFF',
                            margin: '0 auto',
                        }}>
                            <Typography 
                                variant="body1" 
                                style={{ 
                                    fontSize: isMobile ? '12px' : '24px', 
                                    fontWeight: '700', 
                                    color:'#CC293C' 
                                }}
                            >
                                NO PICK
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WeeklyPicksBar;
