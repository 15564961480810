import React, { useEffect } from "react";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import "../bracketStyles/marchMadnessBracketHeading.scss";
import { useSelector, useDispatch, connect } from "react-redux";
import { getUserDataFromLocalStorage } from '../../../redux/actions/loginImport';
import { getUsersForLeaderboardAction } from "../../../redux/actions/loginImport";

const BracketUserHeader = (props, allUsers) => {
    const userData = getUserDataFromLocalStorage();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsersForLeaderboardAction());
    }, [dispatch]);

    // let userStats = props.allUsers?.find(user => user.Username === userData.user_name);
    let userStats = props.allUsers?.find(user => user.Username === userData.user_name);

    console.log('PROPS AND USERDATA IN BRACKET HEADER', userStats, props, userData, allUsers);


    return(
        <>
            <div className="bracket-user-header">
                <div>
                    <img src={bracketTrophy} alt="Bracket Trophy" style={{marginLeft:'27px', marginRight:'75px'}} />
                </div>
                <div className="user-info">
                    <div className="user-name">{userData.user_name} Bracket</div>
                    <div className="user-stats">
                        <div className="user-stat rank">
                            <div className="stat-heading">RANK</div>
                            <div className="stat-value">{userStats?.Rank}</div>
                        </div>
                        <div className="user-stat total-pts">
                            <div className="stat-heading">TOTAL PTS </div>
                            <div className="stat-value">{userStats?.TotalPoints}</div>
                        </div>
                        <div className="user-stat total-pts">
                            <div className="stat-heading">MAX PTS</div>
                            <div className="stat-value">{userStats?.MaxPoints}</div>
                        </div>
                        <div className="user-stat total-pts">
                            <div className="user-stat stat-heading">CHAMP</div>
                            <img style={{width:"75px", margin:'0 auto'}} src={userStats?.ChampLogo} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

const mapStateToProps = (state) => ({
    games: state.games,
    game: state.game,
    user: state.user,
    sportsData: state.sportsData,
    cbbLeagueHierarchy: state.cbbLeagueHierarchy,
    ncaaTournamentHierarchy: state.ncaaTournamentHierarchy,
    cbbTeams: state.cbbTeams,
    allUsers: state.login.getUsersForLeaderboardReducer.data,
});
export default connect(mapStateToProps)(BracketUserHeader);
