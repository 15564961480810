import React, { useState } from "react";
import { useCFBSurvivorContext } from "../context/CFBSurvivorContext";
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams } from "react-router-dom";

const TeamStatsConferenceSelection = ({ conferenceSelection, setConferenceSelection}) => {
    const { teamFilter, setTeamFilter, gameAddress, mainGameId } = useCFBSurvivorContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { pageState } = useParams();

    

    const navigate = useNavigate();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

    const handleConferenceClick = (conference) => {
        setConferenceSelection(conference);
    }

    return (
    <Box
        sx={{
            borderBottom: '4px solid #002129',
        }}
    >
        <Box
            display="flex"
            justifyContent="space-evenly"
            maxWidth={isMobile ? '300px' : '500px'}
            margin="15px auto"
        >
            {["ACC", "Big 12", "Big Ten", "SEC"].map((conference) => (
                <Typography
                    key={conference}
                    component="span"
                    onClick={() => handleConferenceClick(conference)}
                    sx={{
                        display: 'block',
                        padding: isMobile ? '8px 4px' : '12px 8px',
                        borderRadius: '6px',
                        backgroundColor: conferenceSelection === conference ? '#E8E8E8' : 'transparent',
                        color: '#002129',
                        fontSize: isMobile ? '16px' : '24px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        marginRight: isMobile ? '0px' : '20px',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    }}
                >
                    {conference}
                </Typography>
            ))}
        </Box>
    </Box>
    );
}

export default TeamStatsConferenceSelection;
