import React, { useState, useEffect } from "react";
import { useUserAndGameContext } from "../../../../contexts/UserAndGameContext";
import { useSurvivorGamesContext } from "../../../SurvivorGames/context/SurvivorGamesContext";
import { Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import SurvivorSingleEntryView from "./SurvivorSingleEntryView";
import SurvivorMultipleEntryView from "./SurvivorMultipleEntryView";

const SurvivorGamesUserDash = ({ canIAddAnEntry, setIndexToRemove, setRemoveModalOpen }) => {
    const {
        allUsersEntries,
        usersIndexesInThisPool,
        myLeaderboardInfo,
        masterPoolData,
        pickIndex,
        intervalInfo,
        league,
        isThisPoolPartyGame,
        setJoinOpen,
        isUserAdmin,
        hasJoinDeadlinePassed,
    } = useUserAndGameContext();

    const { 
        teams,
        theCurrentInterval,
        lossesForElimination,
        picksPerInterval, 
        totalEntriesEliminated,
        totalEntriesAlive,
    } = useSurvivorGamesContext();

    const [imageLoading, setImageLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    function isEntryEliminated(picks, lossesForElimination) {
        const losses = picks?.filter(pick => pick.result === "loss");
        return losses.length >= lossesForElimination;
    }

    const handleImageLoad = () => {
        setImageLoading(false);
        setImageError(false);
    };

    const handleImageError = () => {
        setImageLoading(false);
        setImageError(true);
    };

    // Get entries for this pool
    const entriesInThisPool = usersIndexesInThisPool
    .filter(index => index >= 0 && index < allUsersEntries.length)
    .map(index => allUsersEntries[index]);

    return (
        <Box>
            {entriesInThisPool?.length === 1 && !canIAddAnEntry && (
                <SurvivorSingleEntryView
                    entry={entriesInThisPool[0]}
                    league={league}
                    teams={teams}
                    lossesForElimination={lossesForElimination}
                    picksPerInterval={picksPerInterval}
                    theCurrentInterval={theCurrentInterval}
                    isEntryEliminated={isEntryEliminated}
                    handleImageError={handleImageError}
                    handleImageLoad={handleImageLoad}
                    imageError={imageError}
                    imageLoading={imageLoading}
                    setImageError={setImageError}
                    setImageLoading={setImageLoading}
                    intervalInfo={intervalInfo}
                    totalEntriesAlive={totalEntriesAlive}
                    totalEntriesEliminated={totalEntriesEliminated}
                    setIndexToRemove={setIndexToRemove}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isThisPoolPartyGame={isThisPoolPartyGame}
                    pickIndex={pickIndex}
                    isUserAdmin={isUserAdmin}
                />
            )}

            {(entriesInThisPool?.length > 1 || canIAddAnEntry) && (
                <SurvivorMultipleEntryView
                    entries={entriesInThisPool}
                    league={league}
                    teams={teams}
                    lossesForElimination={lossesForElimination}
                    picksPerInterval={picksPerInterval}
                    theCurrentInterval={theCurrentInterval}
                    isEntryEliminated={isEntryEliminated}
                    handleImageError={handleImageError}
                    handleImageLoad={handleImageLoad}
                    imageError={imageError}
                    imageLoading={imageLoading}
                    setImageError={setImageError}
                    setImageLoading={setImageLoading}
                    intervalInfo={intervalInfo}
                    totalEntriesAlive={totalEntriesAlive}
                    totalEntriesEliminated={totalEntriesEliminated}
                    canIAddAnEntry={canIAddAnEntry}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    setIndexToRemove={setIndexToRemove}
                    setRemoveModalOpen={setRemoveModalOpen}
                    isUserAdmin={isUserAdmin}
                />
            )}
        </Box>
    );
}

export default SurvivorGamesUserDash;